import React, {useReducer} from "react";
import {ActionButtonType, GridAction} from "../../../../../../components/grid/GridAction";
import DeleteIcon from "@mui/icons-material/Delete";
import Api from "../../../../../../common/Api";
import SucceededAlert from "../../../../../../components/SucceededAlert";
import NotSucceededAlert from "../../../../../../components/NotSucceededAlert";
import ConfirmationDialog from "../../../../../../components/dialogs/ConfirmationDialog";
import {PersistentStateId} from "../../../../../../store/common/Grid";
import Constants from "../../../../../../common/Constants";
import {RelationshipDto} from "../../../../../../common/apis/relationship/RelationshipDto";
import {RelationshipSearchType} from "../../../../../../common/apis/relationship/RelationshipSearchType";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../../ElementDetailTranslationKey";
import {createRelationshipsPanelColDef} from "./RelationsPanelColumnDefinition";
import ExtGridWrapper from "../../../../../../components/grid/ExtGridWrapper";
import {formatCommonElementName} from "../../../elements/CommonElementNameFormatter";


interface State {
    isSucceededAlertIsVisible: boolean,
    isNotSucceededAlertIsVisible: boolean,
    deleteDialog?: {
        selectedRowId: string,
    },
}

const initialState: State = {
    isNotSucceededAlertIsVisible: false,
    isSucceededAlertIsVisible: false,
}

enum ActionType {
    UPDATE_NOT_SUCCEEDED_ALERT_VISIBLE = "UPDATE_NOT_SUCCEEDED_ALERT_VISIBLE",
    UPDATE_SUCCEEDED_ALERT_VISIBLE = "UPDATE_SUCCEEDED_ALERT_VISIBLE",
    UPDATE_DELETE_DIALOG_VISIBLE = "UPDATE_DELETE_DIALOG_VISIBLE",
}

type Action =
    { type: ActionType.UPDATE_NOT_SUCCEEDED_ALERT_VISIBLE, payload: boolean } |
    { type: ActionType.UPDATE_SUCCEEDED_ALERT_VISIBLE, payload: boolean } |
    { type: ActionType.UPDATE_DELETE_DIALOG_VISIBLE, payload: { selectedRowId: string } | undefined };

function createShowDeleteDialogVisibleAction(selectedRowId: string): Action {
    return {
        type: ActionType.UPDATE_DELETE_DIALOG_VISIBLE,
        payload: {
            selectedRowId: selectedRowId,
        }
    }
}

function reducer(state: State, action: Action) {
    switch (action.type) {
        case ActionType.UPDATE_NOT_SUCCEEDED_ALERT_VISIBLE:
            return {
                ...state,
                isNotSucceededAlertIsVisible: action.payload,
            }
        case ActionType.UPDATE_SUCCEEDED_ALERT_VISIBLE:
            return {
                ...state,
                isSucceededAlertIsVisible: action.payload,
            }
        case ActionType.UPDATE_DELETE_DIALOG_VISIBLE:
            return {
                ...state,
                deleteDialog: action.payload,
            }
    }
    return state;
}

interface RelationshipsPanelProps {
    elementId: string,
    onPropertyUpdate: () => void,
    relationships: Array<RelationshipDto>,
    isEditable: boolean,
}

export default function RelationshipsPanel(props: RelationshipsPanelProps) {

    const {elementId, onPropertyUpdate, relationships, isEditable} = props;

    const [{
        isNotSucceededAlertIsVisible,
        isSucceededAlertIsVisible,
        deleteDialog
    }, dispatch] = useReducer(reducer, initialState);

    const rows = createTableRows(relationships);

    function deleteRow() {
        if (deleteDialog) {
            const rowId = deleteDialog.selectedRowId;
            cancelDeleteDialog();
            Api.relationships.deleteRelationship(rowId)
                .subscribe({
                    next: () => {
                        dispatch({type: ActionType.UPDATE_SUCCEEDED_ALERT_VISIBLE, payload: true});
                        setTimeout(() => dispatch({
                            type: ActionType.UPDATE_SUCCEEDED_ALERT_VISIBLE,
                            payload: true
                        }), Constants.FE_APP_ALERT_DELAY);
                        onPropertyUpdate();
                    },
                    error: () => {
                        dispatch({type: ActionType.UPDATE_NOT_SUCCEEDED_ALERT_VISIBLE, payload: true});
                        setTimeout(() => dispatch({
                            type: ActionType.UPDATE_NOT_SUCCEEDED_ALERT_VISIBLE,
                            payload: true
                        }), Constants.FE_APP_ALERT_DELAY);
                        onPropertyUpdate();
                    }
                });
        }
    }

    function cancelDeleteDialog() {
        dispatch({type: ActionType.UPDATE_DELETE_DIALOG_VISIBLE, payload: undefined});
    }

    function hideNotSucceededDialog() {
        dispatch({type: ActionType.UPDATE_NOT_SUCCEEDED_ALERT_VISIBLE, payload: false});
    }

    function hideSucceededDialog() {
        dispatch({type: ActionType.UPDATE_SUCCEEDED_ALERT_VISIBLE, payload: false});
    }

    function getDirection(relation: RelationshipDto) {
        if (relation.source.identifier === relation.target.identifier) {
            return RelationshipSearchType.RECURSIVE;
        } else if (relation.source.identifier === elementId) {
            return RelationshipSearchType.SOURCE;
        } else return RelationshipSearchType.TARGET;
    }

    function getOppositeElementName(relation: RelationshipDto) {
        return relation.source.identifier === elementId ? formatCommonElementName(relation.target) : formatCommonElementName(relation.source);
    }

    function createTableRows(relationships: RelationshipDto[]) {
        return relationships.map(rel => ({
            identifier: rel.identifier,
            direction: getDirection(rel),
            type: rel.type,
            name: rel.name,
            description: rel.description,
            oppositeElementName: getOppositeElementName(rel),
            acl: rel.acl,
        }));
    }


    return (
        <>
            {deleteDialog && <ConfirmationDialog open={true}
                                                 title={_transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_TITLE)}
                                                 confirmationText={_transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_CONFIRMATION)}
                                                 onConfirm={() => deleteRow()}
                                                 onReject={() => cancelDeleteDialog()}/>
            }
            {isSucceededAlertIsVisible &&
                <SucceededAlert text={_transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_SUCCEEDED)}
                                opened={true}
                                onClose={hideSucceededDialog}/>
            }
            {isNotSucceededAlertIsVisible &&
                <NotSucceededAlert text={_transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_FAILED)}
                                   opened={true}
                                   onClose={hideNotSucceededDialog}/>
            }
            <ExtGridWrapper
                columns={createRelationshipsPanelColDef(_transl)}
                rows={rows}
                rowCount={rows.length}
                getRowId={row => row.identifier}
                hideActionButtonsPanel={!isEditable}
                actions={[
                    GridAction.buttonBuilder("ITEMS_RELATIONSHIP_REMOVE_BUTTON", ActionButtonType.IMMEDIATE, _transl(ElementDetailTranslationKey.RELATIONSHIPS_DELETE_BUTTON),
                        <DeleteIcon/>)
                        .onClick(selectedRowIds => dispatch(createShowDeleteDialogVisibleAction(selectedRowIds[0] as string)))
                        .isEnabled(selectedRows => selectedRows.every(relationship => relationship.acl.canDelete))
                        .build()
                ]}
                peristentStateId={PersistentStateId.ELEMENT_DETAIL_PAGE_RELATIONSHIPS_GRID}
                resourceId={elementId}/>
        </>
    );
}
