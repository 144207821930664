import {_transl} from "../../../../store/localization/TranslMessasge";
import {ButtonLayout, NegativeButtons, PositiveButtons} from "../../../../components/button/ButtonLayout";
import {DiagramBackupInfo} from "../DiagramBackupInfo";
import {Button} from "../../../../components/button/Button";
import TranslationKey from "../../TranslationKey";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {Box} from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        diagramName: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(.5),
            fontWeight: "bold",
            fontStyle: "italic",
        }
    })
);

interface EditDiagramBackupDialogProps {
    diagramBackupInfo: DiagramBackupInfo;
    onEditBackup: () => void;
    onDiscardBackup: () => void;
}

export default function EditDiagramBackupDialog(props: EditDiagramBackupDialogProps) {
    const classes = useStyles();

    return (
        <Dialog open={true} maxWidth={"sm"}>
            <DialogTitle title={_transl(TranslationKey.DIAGRAMS_BACKUP_DIALOG_TITLE)} onDialogClosed={props.onDiscardBackup} />
            <DialogContent>
                <Box marginTop={2}>
                    <div>{_transl(TranslationKey.DIAGRAMS_BACKUP_DIALOG_TEXT_BACKUP_FOUND)}:</div>
                    <div className={classes.diagramName}>{props.diagramBackupInfo.getDiagramName()}</div>
                    <div>{_transl(TranslationKey.DIAGRAMS_BACKUP_DIALOG_TEXT_BACKUP_ACTION)}</div>
                </Box>
            </DialogContent>
            <DialogActions>
                <ButtonLayout>
                    <PositiveButtons>
                        <Button label={_transl(TranslationKey.DIAGRAMS_BACKUP_DIALOG_ACTION_EDIT)} onClick={props.onEditBackup} variant={"contained"} />
                    </PositiveButtons>
                    <NegativeButtons>
                        <Button label={_transl(TranslationKey.DIAGRAMS_BACKUP_DIALOG_ACTION_DISCARD)} onClick={props.onDiscardBackup} variant={"outlined"} />
                    </NegativeButtons>
                </ButtonLayout>
            </DialogActions>
        </Dialog>
    );
}
