import React from "react";
import {FeatureStatusesDto} from "../configuration/FeatureStatusesDto";

export interface Configuration {
    featureStatuses: FeatureStatusesDto;
    setFeatureStatuses: (featureStatuses: FeatureStatusesDto) => void;
}

const ConfigurationContext = React.createContext<Configuration>({
    featureStatuses: {
        aiEnabled: false,
        graphQueryEnabled: false,
    },
    setFeatureStatuses: () => {}
});

export default ConfigurationContext;