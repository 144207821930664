import Constants from "../Constants";
import Api from "../Api";
import {Observable} from "rxjs";
import {AjaxError, AjaxResponse} from "rxjs/ajax";
import {catchError, map} from "rxjs/operators";
import {MetamodelValidationReportDto} from "./MetamodelService";
import {IModelDto} from "./model/IModelDto";
import {NotFoundError} from "../Errors";

export interface ViewpointDto {
    identifier: string,
    name: string,
    description?: string,
    viewpoint?: string;
}

export interface ViewpointDefinitionDto {
    viewpoint: ViewpointDto,
    elementTypes?: string[]
}

export interface ViewpointService {
    getAll(): Observable<AjaxResponse>,
    findAll(): Promise<ViewpointDto[]>,
    findById(identifier: string): Promise<ViewpointDefinitionDto>,
    validate(viewpointId: string, model: IModelDto): Promise<MetamodelValidationReportDto>
}

class RxJsViewpointService implements ViewpointService {

    public static ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/viewpoints";

    /**
     * @deprecated Will be removed after replacing Epics
     */
    getAll(): Observable<AjaxResponse> {
        return Api.createAjax({
            url: RxJsViewpointService.ENDPOINT_URL + "/",
            method: "GET",
        });
    }

    findAll(): Promise<ViewpointDto[]> {
        const request = {
            url: RxJsViewpointService.ENDPOINT_URL + "/",
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ViewpointDto[])
            )
            .toPromise();
    }

    findById(identifier: string): Promise<ViewpointDefinitionDto> {
        const request = {
            url: RxJsViewpointService.ENDPOINT_URL + "/" + identifier,
            method: "GET"
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ViewpointDefinitionDto)
            )
            .pipe(
                catchError(err => {
                    if (err instanceof AjaxError && err.status === 404) {
                        throw new NotFoundError(err.message);
                    } else {
                        throw err;
                    }
                })
            )
            .toPromise();
    }

    validate(identifier: string, model: IModelDto): Promise<MetamodelValidationReportDto> {
        const request = {
            url: RxJsViewpointService.ENDPOINT_URL + `/${identifier}:validate`,
            method: "POST",
            body: model
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as MetamodelValidationReportDto)
            )
            .pipe(
                catchError(err => {
                    if (err instanceof AjaxError && err.status === 404) {
                        throw new NotFoundError(err.message);
                    } else {
                        throw err;
                    }
                })
            )
            .toPromise();
    }
}

const viewpointService = new RxJsViewpointService();
export default viewpointService;