import React, {useRef} from "react";
import {Paper} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import CollectionsGrid from "./collectionsgrid/CollectionsGrid";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {CollectionsTranslationKey} from "./CollectionsTranslationKey";
import constructCollectionsController, {CollectionsController} from "./controller/CollectionsController";
import collectionsService from "./service/CollectionsService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
    })
);

export default function CollectionsPage() {
    const classes = useStyles();
    const collectionsController = useRef<CollectionsController>(constructCollectionsController(collectionsService));

    return (
        <Paper className={classes.page}>
            <div className={classes.headerPageSegment}>
                <Typography variant="h6">
                    {_transl(CollectionsTranslationKey.TITLE)}
                </Typography>
            </div>
            <Divider/>
            <Divider/>
            <div className={classes.controlPageSegment}>
                <CollectionsGrid controller={collectionsController.current}/>
            </div>
        </Paper>
    )
}
