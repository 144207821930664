import {MenuItem} from "@mui/material";
import React from "react";
import {LabelTreeViewMode} from "../../../../service/LabelTreeViewMode";
import TextField from "../../../../../../../../components/fields/textfield/TextField";
import {HierarchyTreeTranslationKey} from "../../../../HierarchyTreeTranslationKey";
import {_transl} from "../../../../../../../../store/localization/TranslMessasge";

class LabelViewModeOption {
    public static readonly [LabelTreeViewMode.FOLDERS] = new LabelViewModeOption(
        HierarchyTreeTranslationKey.LABEL_VIEW_MODE_FOLDERS, LabelTreeViewMode.FOLDERS);

    public static readonly [LabelTreeViewMode.FLAT] = new LabelViewModeOption(
        HierarchyTreeTranslationKey.LABEL_VIEW_MODE_FLAT, LabelTreeViewMode.FLAT);

    translationKey: string;
    viewMode: LabelTreeViewMode;

    constructor(translationKey: string, viewMode: LabelTreeViewMode) {
        this.translationKey = translationKey;
        this.viewMode = viewMode;
    }

    public static values(): LabelViewModeOption[] {
        return Object.keys(LabelTreeViewMode)
            .map(key => LabelViewModeOption[key as LabelTreeViewMode]);
    }
}

export interface LabelViewModeSelectProps {
    value: LabelTreeViewMode,
    onChange: (viewMode: LabelTreeViewMode) => void
}

export default function LabelViewModeSelect({value, onChange}: LabelViewModeSelectProps) {
    return (
        <>
            <TextField
                label={_transl(HierarchyTreeTranslationKey.LABEL_VIEW_MODE)}
                select
                required={true}
                value={value}
                onChange={e => onChange(e as LabelTreeViewMode)}
            >
                {LabelViewModeOption.values().map((viewModeOption) => (
                    <MenuItem key={viewModeOption.viewMode} value={viewModeOption.viewMode}>
                        {_transl(viewModeOption.translationKey)}
                    </MenuItem>
                ))}
            </TextField>
        </>
    )
}


