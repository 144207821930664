import Constants from "../Constants";
import Api from "../Api";
import {KeycloakHolder} from "../../keycloak/KeycloakHolder";
import {UserRoleType} from "../access/UserRoleType";
import {UserDto} from "./user/UserDto";
import {map} from "rxjs/operators";
import {CollectionPermissionLevelDto} from "./user/CollectionPermissionLevelDto";
import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import {CollectionPermissionDto} from "./user/CollectionPermissionDto";
import {CollectionPermissionLevelTranslator} from "../../pages/main/content/users/CollectionPermissionLevelTranslator";

const LOGIN = ":login";
const COLLECTION_CODE = ":collectionCode"
const USERS_ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/users";
const CONCRETE_USER_URL: string = `${USERS_ENDPOINT_URL}/${LOGIN}`;
const PUT_ACTIVATE_USER: string = `${USERS_ENDPOINT_URL}/${LOGIN}:activate`;
const UPDATE_ROLE_BY_LOGIN: string = `${USERS_ENDPOINT_URL}/${LOGIN}:change-role`;
const COLLECTION_PERMISSIONS_BY_LOGIN: string = `${USERS_ENDPOINT_URL}/${LOGIN}/permissions`;
const PUT_CHANGE_PASSWORD: string = `${USERS_ENDPOINT_URL}/${LOGIN}:change-password`;
const PUT_CREATE_USER_COLLECTION_PERMISSION: string = `${USERS_ENDPOINT_URL}/${LOGIN}/permissions/${COLLECTION_CODE}`;
const DELETE_USER_COLLECTION_PERMISSION:string = `${USERS_ENDPOINT_URL}/${LOGIN}/permissions/${COLLECTION_CODE}`;
const GET_ALL_USERS_PERMISSIONS_LEVEL: string = `${USERS_ENDPOINT_URL}/permissions/levels`;
const RESET_USER_PASSWORD_URL: string = `${USERS_ENDPOINT_URL}:reset-password`;
const CHANGE_USER_PASSWORD_VIA_TOKEN_URL: string = `${USERS_ENDPOINT_URL}:change-password-via-token`;

export interface UserService {
    activateUserByLogin(login: string, isUserActive: boolean): Promise<void>,
    changePassword(userLogin: string, oldPassword: string, newPassword: string): Promise<void>,
    changeUserPasswordViaToken(passwordToken: string, newCreatedPassword: string): Promise<void>,
    createUser(newCreatedLogin: string, firstName: string, lastName: string, email: string, role: UserRoleType, newCreatedPassword: string, userActive: boolean): Promise<void>,
    createUserCollectionPermission(login: string, collectionCode: string, permissionCode: string): Promise<void>,
    getAllPermissionLevels(): Promise<Array<CollectionPermissionLevelDto>>,
    getAllUsers(): Promise<Array<UserDto>>,
    getAllUsersObservable(): Observable<AjaxResponse>,
    getCollectionPermissionsByLogin(login: string): Promise<Array<CollectionPermissionDto>>,
    getUserByLogin(login: string): Promise<UserDto>,
    getUserByToken(): Promise<UserDto>,
    removeCollectionPermissionByLogin(login: string, collectionCode: string): Promise<void>,
    resetUsersPassword(email: string): Promise<void>,
    updateDescriptionByLogin(login: string, description: string): Promise<void>,
    updateEmailByLogin(login: string, email: string): Promise<void>,
    updateFirstNameByLogin(login: string, firstName: string): Promise<void>,
    updateLastNameByLogin(login: string, lastName: string): Promise<void>,
    updatePhoneByLogin(login: string, phone: string): Promise<void>,
    updateRoleByLogin(login: string, role: UserRoleType): Promise<void>,
}

class RxJsUserService implements UserService {

    activateUserByLogin(login: string, isUserActive: boolean): Promise<void> {
        return Api.createAjax(
            {
                url: PUT_ACTIVATE_USER.replace(LOGIN, login),
                method: "PUT",
                body: {
                    "active": isUserActive,
                }
            }).pipe(
                map(response => {})
        ).toPromise();
    }

    changePassword(userLogin: string, oldPassword: string, newPassword: string): Promise<void> {
        return Api.createAjax(
            {
                url: PUT_CHANGE_PASSWORD.replace(LOGIN, userLogin),
                method: "PUT",
                body: {
                    "oldPassword": oldPassword,
                    "newPassword": newPassword,
                }
            }).pipe(
            map(response => {})
        ).toPromise();
    }

    changeUserPasswordViaToken(passwordToken: string, newCreatedPassword: string): Promise<void> {
        return Api.createAjax({
            url: CHANGE_USER_PASSWORD_VIA_TOKEN_URL,
            method: "POST",
            body: {
                "passwordToken": passwordToken,
                "newPassword": newCreatedPassword
            },
        }).pipe(
            map(response => {})
        ).toPromise();
    }

    createUser(newCreatedLogin: string, firstName: string, lastName: string, email: string, role: UserRoleType, newCreatedPassword: string, userActive: boolean): Promise<void> {
        return Api.createAjax(
            {
                url: USERS_ENDPOINT_URL,
                method: "POST",
                body: {
                    "login": newCreatedLogin,
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email,
                    "role": role,
                    "password": newCreatedPassword,
                    "userActive": userActive,
                }
            }).pipe(
            map(response => {})
        ).toPromise();
    }

    createUserCollectionPermission(login: string, collectionCode: string, permissionCode: string): Promise<void> {
        return Api.createAjax(
            {
                url: PUT_CREATE_USER_COLLECTION_PERMISSION
                    .replace(LOGIN, login)
                    .replace(COLLECTION_CODE, collectionCode),
                method: "PUT",
                body: {
                    permissionLevelCode: permissionCode,
                }
            }).pipe(
            map(response => {})
        ).toPromise();
    }

    getAllPermissionLevels(): Promise<Array<CollectionPermissionLevelDto>> {
        const request = {
            url: GET_ALL_USERS_PERMISSIONS_LEVEL,
            method: "GET",
        }

        return Api.createAjax(request).pipe(
            map(response => {
                const permissionLevels = response.response as Array<CollectionPermissionLevelDto>

                const translatedPermissionLevels = permissionLevels.map(level => {
                    const translatedName = CollectionPermissionLevelTranslator.get().translateCollectionPermissionLevel(level.code) || level.code;
                    return {...level, name: translatedName};
                });

                return translatedPermissionLevels;
            }))
            .toPromise();
    }

    getAllUsers(): Promise<Array<UserDto>> {
        return Api.createAjax(
            {
                url: USERS_ENDPOINT_URL,
                method: "GET",
            }).pipe(
            map(response => response.response as Array<UserDto>)
        ).toPromise();
    }

    getAllUsersObservable(): Observable<AjaxResponse> {
        return Api.createAjax(
            {
                url: USERS_ENDPOINT_URL,
                method: "GET",
            });
    }

    getCollectionPermissionsByLogin(login: string): Promise<Array<CollectionPermissionDto>> {
        return Api.createAjax(
            {
                url: COLLECTION_PERMISSIONS_BY_LOGIN.replace(LOGIN, login),
                method: "GET",
            }).pipe(
            map(response => response.response as Array<CollectionPermissionDto>)
        ).toPromise();
    }

    getUserByLogin(login: string): Promise<UserDto> {
        return Api.createAjax(
            {
                url: CONCRETE_USER_URL.replace(LOGIN, login),
                method: "GET",
            }).pipe(
            map(response => response.response as UserDto)
        ).toPromise();
    }

    getUserByToken(): Promise<UserDto> {
        const login = Api.parseUsername(KeycloakHolder.getToken() as string);
        return this.getUserByLogin(login);
    }

    removeCollectionPermissionByLogin(login: string, collectionCode: string): Promise<void> {
        return Api.createAjax(
            {
                url: DELETE_USER_COLLECTION_PERMISSION
                    .replace(LOGIN, login)
                    .replace(COLLECTION_CODE, collectionCode),
                method: "DELETE",
            }).pipe(
            map(response => {})
        ).toPromise();
    }

    resetUsersPassword(email: string): Promise<void> {
        return Api.createAjax({
            url: RESET_USER_PASSWORD_URL,
            method: "POST",
            body: email,
        }).pipe(
            map(response => {})
        ).toPromise();
    }

    updateDescriptionByLogin(login: string, description: string): Promise<void> {
        return Api.createAjax({
            url: Api.replaceUrlParams(CONCRETE_USER_URL, {[LOGIN]: login}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["description", description]
            ])),
        }).pipe(
            map(response => {})
        ).toPromise();
    }

    updateEmailByLogin(login: string, email: string): Promise<void> {
        return Api.createAjax({
            url: Api.replaceUrlParams(CONCRETE_USER_URL, {[LOGIN]: login}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["email", email]
            ])),
        }).pipe(
            map(response => {})
        ).toPromise();
    }

    updateFirstNameByLogin(login: string, firstName: string): Promise<void> {
        return Api.createAjax({
            url: Api.replaceUrlParams(CONCRETE_USER_URL, {[LOGIN]: login}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["firstName", firstName]
            ])),
        }).pipe(
            map(response => {})
        ).toPromise();
    }

    updateLastNameByLogin(login: string, lastName: string): Promise<void> {
        return Api.createAjax({
            url: Api.replaceUrlParams(CONCRETE_USER_URL, {[LOGIN]: login}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["lastName", lastName]
            ])),
        }).pipe(
            map(response => {})
        ).toPromise();
    }

    updatePhoneByLogin(login: string, phone: string): Promise<void> {
        return Api.createAjax({
            url: Api.replaceUrlParams(CONCRETE_USER_URL, {[LOGIN]: login}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["phone", phone]
            ])),
        }).pipe(
            map(response => {})
        ).toPromise();
    }

    updateRoleByLogin(login: string, role: UserRoleType): Promise<void> {
        return Api.createAjax(
            {
                url: UPDATE_ROLE_BY_LOGIN.replace(LOGIN, login),
                method: "PUT",
                body: role != null ? role : "",
            }).pipe(
            map(response => {})
        ).toPromise();
    }

}

const userService: UserService = new RxJsUserService();
export default userService;
