import {DateNullable, StringNullable} from "../../common/Types";
import {Action, Reducer} from "redux";
import {ActionsObservable, StateObservable} from "redux-observable";
import {IApplicationState} from "../Store";
import {catchError, filter, mergeMap, startWith, switchMap} from "rxjs/operators";
import {merge, Observable, of, Subject, Subscriber} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";

// Resource type

export enum UploadableResourceType {
    // Page specific
    ELEMENTS_DETAIL_PAGE_ATTACHMENT = "ELEMENTS_DETAIL_PAGE_ATTACHMENT",
    DIAGRAM_DETAIL_PAGE_ATTACHMENT = "DIAGRAM_DETAIL_PAGE_ATTACHMENT",
}

// Action

export enum UploadActionType {
    UPLOAD = "ACTION/COMMON/UPLOAD",
    UPLOAD_PROGRESS = "ACTION/COMMON/UPLOAD_PROGRESS",
    UPLOAD_SUCCESSFUL = "ACTION/COMMON/UPLOAD_SUCCESSFUL",
    UPLOAD_FAILED = "ACTION/COMMON/UPLOAD_FAILED",
    UPLOAD_ALREADY_STARTED = "ACTION/COMMON/UPLOAD_ALREADY_STARTED",
    UPLOAD_REMOVE_ITEM = "ACTION/COMMON/UPLOAD_REMOVE_ITEM",
}

export enum UploadStatusType {
    NOT_STARTED = "NOT_STARTED",
    STARTED = "STARTED",
    SUCCESSFUL = "SUCCESSFUL",
    FAILED = "FAILED",
}

export interface IUploadableResourceAction<R> {
    type: UploadActionType;
    resourceType: UploadableResourceType;
    itemId: string,
    resource: File;
    status: UploadStatusType;
    progress?: number,
    error?: string;
    uploadResult?: R;
}

// Action creators

export function getResourceUploadAlreadyStartedAction(resourceType: UploadableResourceType, itemId: string, resource: File): IUploadableResourceAction<unknown> {
    return {
        type: UploadActionType.UPLOAD_ALREADY_STARTED,
        resourceType: resourceType,
        itemId: itemId,
        resource: resource,
        status: UploadStatusType.STARTED,
    }
}

export function getResourceUploadAction(resourceType: UploadableResourceType, itemId: string, resource: File): IUploadableResourceAction<unknown> {
    return {
        type: UploadActionType.UPLOAD,
        resourceType: resourceType,
        itemId: itemId,
        resource: resource,
        status: UploadStatusType.STARTED,
    }
}

export function getResourceUploadProgressAction(resourceType: UploadableResourceType, itemId: string, resource: File, progress: number): IUploadableResourceAction<unknown> {
    return {
        ...getResourceUploadAction(resourceType, itemId, resource),
        type: UploadActionType.UPLOAD_PROGRESS,
        progress: progress,
    }
}

export function getResourceUploadCustomAction<T extends IUploadableResourceAction<unknown>>(resourceType: UploadableResourceType, itemId: string, resource: File, params: any): T {
    return {
        ...getResourceUploadAction(resourceType, itemId, resource),
        ...params,
    } as T;
}

export function getResourceUploadRemoveItemAction(resourceType: UploadableResourceType, itemId: string, resource: File): IUploadableResourceAction<unknown> {
    return {
        type: UploadActionType.UPLOAD_REMOVE_ITEM,
        resourceType: resourceType,
        itemId: itemId,
        resource: resource,
        status: UploadStatusType.SUCCESSFUL,
    }
}

export function getResourceUploadProgressCustomAction<T extends IUploadableResourceAction<unknown>>(resourceType: UploadableResourceType, itemId: string, resource: File, progress: number, params: any): T {
    return {
        ...getResourceUploadProgressAction(resourceType, itemId, resource, progress),
        ...params,
    } as T;
}

export function getResourceUploadSuccessfulAction<R>(resourceType: UploadableResourceType, itemId: string, resource: File, uploadResult: R): IUploadableResourceAction<R> {
    return {
        type: UploadActionType.UPLOAD_SUCCESSFUL,
        resourceType: resourceType,
        itemId: itemId,
        resource: resource,
        status: UploadStatusType.SUCCESSFUL,
        uploadResult: uploadResult,
    }
}

export function getResourceUploadFailedAction<R>(resourceType: UploadableResourceType, itemId: string, resource: File, error: string): IUploadableResourceAction<R> {
    return {
        type: UploadActionType.UPLOAD_FAILED,
        resourceType: resourceType,
        itemId: itemId,
        resource: resource,
        status: UploadStatusType.FAILED,
        error: error,
    }
}

// State

export interface IUploadStatusState {
    status: UploadStatusType;
    progress: number,
    start: Date,
    end: DateNullable,
    error: StringNullable;
    uploadResult?: any;
}

export interface IUploadableResourceState {
    uploadStatus: IUploadStatusState;
    itemId: string,
    resource?: File;
}

// Reducer

class ReducerUtils {

    public static replaceItem(array: Array<IUploadableResourceState>, oldItem: IUploadableResourceState, newItem: IUploadableResourceState) {
        return array.map(item => {
            return (item === oldItem) ? newItem : item
        })
    }

    public static findItemByItemIdAndResource(array: Array<IUploadableResourceState>, itemId: string, resource: File | undefined) {
        return array.filter(item => item.itemId === itemId && item.resource === resource)[0];
    }

    static removeItemByItemIdAndResource(array: Array<IUploadableResourceState>, itemId: string, resource: File) {
        return array.filter(item => !(item.itemId === itemId && item.resource === resource));
    }

}

export function createUploadableResourceArrayReducer<R, U extends IUploadableResourceAction<R>>(
    resourceType: U["resourceType"], initialState: Array<IUploadableResourceState>
): Reducer<Array<IUploadableResourceState>, U>
{
    return (
        state= initialState,
        action
    ) => {
        if (action.resourceType === resourceType) {
            let item, newItem;
            switch (action.type) {
                case UploadActionType.UPLOAD:
                    return [
                        {
                            uploadStatus: {
                                status: action.status,
                                progress: 0,
                                start: new Date(),
                                end: null,
                                error: null,
                            },
                            itemId: action.itemId,
                            resource: action.resource,
                        },
                        ...state
                    ]

                case UploadActionType.UPLOAD_PROGRESS:
                    item = ReducerUtils.findItemByItemIdAndResource(state, action.itemId, action.resource);
                    newItem = {
                        ...item,
                        uploadStatus: {
                            ...item.uploadStatus,
                            status: action.status,
                            progress: action.progress,
                        },
                    } as IUploadableResourceState;
                    return ReducerUtils.replaceItem(state, item, newItem);

                case UploadActionType.UPLOAD_SUCCESSFUL :
                    item = ReducerUtils.findItemByItemIdAndResource(state, action.itemId, action.resource);
                    newItem = {
                        ...item,
                        uploadStatus: {
                            ...item.uploadStatus,
                            status: action.status,
                            progress: 100,
                            end: new Date(),
                            error: null,
                            uploadResult: action.uploadResult,
                        },
                    } as IUploadableResourceState;
                    return ReducerUtils.replaceItem(state, item, newItem);

                case UploadActionType.UPLOAD_FAILED :
                    item = ReducerUtils.findItemByItemIdAndResource(state, action.itemId, action.resource);
                    newItem = {
                        ...item,
                        uploadStatus: {
                            ...item.uploadStatus,
                            status: action.status,
                            progress: 0,
                            end: new Date(),
                            error: action.error,
                        },
                    } as IUploadableResourceState;
                    return ReducerUtils.replaceItem(state, item, newItem);

                case UploadActionType.UPLOAD_REMOVE_ITEM :
                    return ReducerUtils.removeItemByItemIdAndResource(state, action.itemId, action.resource);
            }
        }
        return state;
    };
}

// Epic

export function createUploadableResourceEpic<R, A extends IUploadableResourceAction<R>>(
    resourceType: UploadableResourceType,
    apiCall: (action: A, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) => Observable<AjaxResponse>,
    onSuccessfulUploadAction?: (action: A) => Action<unknown>,
    trackProgress: boolean = true,
) {
    return composeUploadableResourceEpic<R, A>(
        UploadActionType.UPLOAD,
        resourceType,
        apiCall,
        (action) => action.resourceType,
        onSuccessfulUploadAction,
        trackProgress,
    );
}

export function createCustomActionUploadableResourceEpic<R, A extends IUploadableResourceAction<R>>(
    resourceType: UploadableResourceType,
    apiCall: (action: A, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) => Observable<AjaxResponse>,
    onSuccessfulUploadAction?: (action: A) => Action<unknown>,
    trackProgress: boolean = true,
) {
    return composeUploadableResourceEpic<R, A>(
        UploadActionType.UPLOAD,
        resourceType,
        apiCall,
        (action) => action.resourceType,
        onSuccessfulUploadAction,
        trackProgress,
    );
}

export function composeUploadableResourceEpic<R, A extends IUploadableResourceAction<R>>(
    actionType: A["type"],
    requiredResourceType: UploadableResourceType,
    apiCall: (action: A, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) => Observable<AjaxResponse>,
    resourceType: (action: A) => UploadableResourceType,
    onSuccessfulUploadAction?: (action: A) => Action<unknown>,
    trackProgress: boolean = true,
) {
    return (action$: ActionsObservable<A>, $state: StateObservable<IApplicationState>) =>
        action$.ofType(actionType)
            .pipe(
                filter((action) => resourceType(action) === requiredResourceType),
                mergeMap((action) => {
                    const startAction = actionType === UploadActionType.UPLOAD ?
                        getResourceUploadAlreadyStartedAction(resourceType(action), action.itemId, action.resource) :
                        getResourceUploadAction(resourceType(action), action.itemId, action.resource);
                    const progressSubject = new Subject();

                    let progressSubscriber = undefined;

                    if (trackProgress) {
                        progressSubscriber = Subscriber.create<ProgressEvent>((e: ProgressEvent | undefined) => {
                            if (e != null && e.loaded < e.total) {
                                const percentComplete = Math.round((e.loaded / e.total) * 100)
                                progressSubject.next(getResourceUploadProgressAction(resourceType(action), action.itemId, action.resource, percentComplete))
                            }
                        })
                    }

                    const apiCallObservable = apiCall(action, "blob", progressSubscriber).pipe(
                        switchMap((xhr: AjaxResponse) => {
                            const actions: Array<Action<unknown>> = [getResourceUploadSuccessfulAction(resourceType(action), action.itemId, action.resource, xhr.response)];
                            if (onSuccessfulUploadAction) {
                                actions.push(onSuccessfulUploadAction(action));
                            }
                            return of(...actions)
                        }),
                        catchError((error) => of(getResourceUploadFailedAction(resourceType(action), action.itemId, action.resource, "Data se nezdařilo přenést."))),
                        startWith(startAction),
                    )

                    return merge(apiCallObservable, progressSubject);
                }),
            );
}