import React, {useEffect, useState} from "react";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {UsersTranslationKey} from "./UsersTranslationKey";
import {Tab, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import BasicInfoPanel from "../userdetail/BasicInfoPanel";
import constructUserDetailController from "../userdetail/controller/UserDetailController";
import userService from "../../../../common/apis/UserService";
import {UserValidator} from "./UserValidation";
import UserPermissionPanel from "../userdetail/UserPermissionPanel";
import {UserDto} from "../../../../common/apis/user/UserDto";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogContent from "../../../../components/dialogs/DialogContent";
import Grid from "../../../../components/dialogs/Grid";
import DialogTitle from "../../../../components/dialogs/DialogTitle";

enum BasicInfoTabId {
    BASIC = "BASIC",
}

enum AdditionalInfoTabId {
    ROLES = "ROLES",
}

interface UserDetailDialogProps {
    opened: boolean,
    onClose: (updated: boolean) => void,
    user: UserDto,
}

export default function UserDetailDialog(props: UserDetailDialogProps) {
    const {opened, onClose, user} = props;
    const [basicInfoTabId] = useState<BasicInfoTabId>(BasicInfoTabId.BASIC);
    const [additionalInfoTabId] = useState<AdditionalInfoTabId>(AdditionalInfoTabId.ROLES);

    const [updated, setUpdated] = useState<boolean>(false);

    useEffect(() => {
        if (!opened) {
            setUpdated(false);
        }
    }, [opened]);

    function onPropertyUpdate() {
        setUpdated(true);
    }

    return (
        <Dialog open={opened}
                onClose={() => onClose(updated)}
                maxWidth={"lg"}
        >
            <DialogTitle id="user-detail-dialog-title"
                         title={_transl(UsersTranslationKey.USER_DETAIL)}
                         onDialogClosed={onClose}
            />
            <DialogContent>
                <Grid container noStyle>
                    <Grid item xs={12} md={6}>
                        <TabContext value={basicInfoTabId}>
                            <Tabs
                                value={basicInfoTabId}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab value={BasicInfoTabId.BASIC}
                                     label={_transl(UsersTranslationKey.BASIC_DATA)}
                                />
                            </Tabs>
                            <TabPanel value={BasicInfoTabId.BASIC}>
                                <BasicInfoPanel login={user.login}
                                                controller={constructUserDetailController(userService)}
                                                onPropertyUpdate={() => onPropertyUpdate()}
                                                userValidator={new UserValidator()}
                                />
                            </TabPanel>
                        </TabContext>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TabContext value={additionalInfoTabId}>
                            <Tabs
                                value={additionalInfoTabId}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab value={AdditionalInfoTabId.ROLES}
                                     label={_transl(UsersTranslationKey.DETAIL_PERMISSIONS)}
                                />
                            </Tabs>
                            <TabPanel value={AdditionalInfoTabId.ROLES}>
                                <UserPermissionPanel login={user.login}
                                                     controller={constructUserDetailController(userService)}/>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
