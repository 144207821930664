import {MetamodelDto} from "../../../pages/main/content/metamodel/extraction/MetamodelService";
import {ArchimateElement} from "../../archimate/ArchimateElement";
import {ArchimateRelationship} from "../../archimate/ArchimateRelationship";

export default class MetamodelManager {

    public findAllowedRelationshipTypes(sourceType: ArchimateElement, targetType: ArchimateElement, metamodel: MetamodelDto | undefined): ArchimateRelationship[] {
        if (!metamodel) {
            return ArchimateRelationship.valuesSortedByVisibleName();
        }
        const connection = metamodel.connections
            .find(conn => conn.sourceType === sourceType.standardName && conn.targetType === targetType.standardName);
        if (connection) {
            return connection.relationshipTypes
                .map(relationshipStandardName => ArchimateRelationship.findByStandardName(relationshipStandardName))
                .filter(relationshipType => relationshipType != null) as ArchimateRelationship[];
        } else {
            return [];
        }
    }

}