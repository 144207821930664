import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../Api";
import Constants from "../Constants";

export interface IAppInfoDto {
    name: string;
    url: string;
    email: string;
    version: string;
    buildNumber: string;
    buildDate: Date;
    releaseNotes: string;
}

export default class Common {

    public static APP_INFO_URL: string = `${Constants.API_HOST}/rest-api/application-info/`;

    fetchAppInfo(): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Common.APP_INFO_URL,
            method: "GET",
        }, true);
    }

    fetchAppInfoDto(okCallback: (data: IAppInfoDto) => void, errorCallback?: () => void): void {
        this.fetchAppInfo()
            .subscribe(
                (resp) => okCallback(resp.response),
                () => errorCallback && errorCallback(),
            )
    }

}