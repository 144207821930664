import UserAclDto from "../../../../../common/apis/user/UserAclDto";
import {UserService} from "../../../../../common/apis/UserService";
import {UserDto} from "../../../../../common/apis/user/UserDto";
import {UserRoleType} from "../../../../../common/access/UserRoleType";

export interface UserDetailController {
    getUserByLogin(login: string): Promise<UserDto>;

    updateDescriptionByLogin(login: string, description: string): Promise<void>,

    updateEmailByLogin(login: string, email: string): Promise<void>,

    updateFirstNameByLogin(login: string, firstName: string): Promise<void>,

    updateLastNameByLogin(login: string, lastName: string): Promise<void>,

    updatePhoneByLogin(login: string, phone: string): Promise<void>,

    updateRoleByLogin(login: string, role: UserRoleType): Promise<void>,

    findInitialValues(loggedUser: UserDto, userRole: UserRoleType, userLogin: string): UserRoleType[],

    changePassword(userLogin: string, oldPassword: string, newPassword: string): Promise<void>,

    createPermission(login: string, collectionCode: string, permissionCode: string): Promise<void>

    canCreatePermissions(acl: UserAclDto): boolean

    canUpdatePermissions(acl: UserAclDto): boolean
}

class UserDetailControllerImpl implements UserDetailController {
    constructor(private userService: UserService) {
    }

    async getUserByLogin(login: string): Promise<UserDto> {
        return this.userService.getUserByLogin(login);
    }

    async updateDescriptionByLogin(login: string, description: string): Promise<void> {
        return this.userService.updateDescriptionByLogin(login, description);
    }

    async updateEmailByLogin(login: string, email: string): Promise<void> {
        return this.userService.updateEmailByLogin(login, email);
    }

    async updateFirstNameByLogin(login: string, firstName: string): Promise<void> {
        return this.userService.updateFirstNameByLogin(login, firstName);
    }

    async updateLastNameByLogin(login: string, lastName: string): Promise<void> {
        return this.userService.updateLastNameByLogin(login, lastName);
    }

    async updatePhoneByLogin(login: string, phone: string): Promise<void> {
        return this.userService.updatePhoneByLogin(login, phone);
    }

    async updateRoleByLogin(login: string, role: UserRoleType): Promise<void> {
        return this.userService.updateRoleByLogin(login, role);
    }

    async changePassword(userLogin: string, oldPassword: string, newPassword: string): Promise<void> {
        return this.userService.changePassword(userLogin, oldPassword, newPassword);
    }

    async createPermission(login: string, collectionCode: string, permissionCode: string): Promise<void> {
        return this.userService.createUserCollectionPermission(login, collectionCode, permissionCode);
    }

    canCreatePermissions(acl: UserAclDto): boolean {
        return acl.canCreatePermissions;
    }

    canUpdatePermissions(acl: UserAclDto): boolean {
        return acl.canUpdatePermissions;
    }

    findInitialValues(loggedUser: UserDto, userRole: UserRoleType, userLogin: string): UserRoleType[] {
        if (loggedUser.login === userLogin) {
            return [loggedUser.role];
        }
        if (loggedUser.role === UserRoleType.ROLE_ADMIN) {
            return [UserRoleType.ROLE_ADMIN, UserRoleType.ROLE_SPRAVCE, UserRoleType.ROLE_OPERATOR];
        }
        if (loggedUser.role === UserRoleType.ROLE_SPRAVCE) {
            if (userRole === UserRoleType.ROLE_ADMIN) {
                return [UserRoleType.ROLE_ADMIN]
            } else {
                return [UserRoleType.ROLE_SPRAVCE, UserRoleType.ROLE_OPERATOR];
            }
        } else return [userRole];
    }
}

export default function constructUserDetailController(userService: UserService): UserDetailController {
    return new UserDetailControllerImpl(userService);
}
