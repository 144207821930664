export default interface DiagramContentFilterDto {
    elementIds: Array<string>,
}

export class DiagramContentFilterDtoBuilder {
    private _elementIds?: Array<string>;

    public elementIds(elementIds: Array<string>) {
        this._elementIds = elementIds;
        return this;
    }

    public build(): DiagramContentFilterDto {
        return {
            elementIds: this._elementIds || [],
        }
    }

    static builder() {
        return new DiagramContentFilterDtoBuilder();
    }
}
