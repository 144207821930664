import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {UsersTranslationKey} from "../users/UsersTranslationKey";
import DialogContent from "../../../../components/dialogs/DialogContent";
import Grid from "../../../../components/dialogs/Grid";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import React, {useEffect, useState} from "react";
import collectionsService from "../collections/service/CollectionsService";
import userService from "../../../../common/apis/UserService";
import {CollectionPermissionLevelDto} from "../../../../common/apis/user/CollectionPermissionLevelDto";
import {CommonTranslation} from "../CommonTranslation";
import {CollectionDto} from "../../../../common/apis/collection/CollectionDto";
import Snackbar from "../snackbar/Snackbar";
import SingleSelectField from "../../../../components/fields/textfield/SingleSelectField";

interface CreatePermissionDialogProps {
    login: string,
    onClose: () => void,
    reloadUserPermissionsGrid: () => void,
}

export default function CreatePermissionDialog(props: CreatePermissionDialogProps) {
    const {login, reloadUserPermissionsGrid, onClose} = props;

    const [collections, setCollections] = useState<CollectionDto[]>([]);
    const [permissions, setPermissions] = useState<CollectionPermissionLevelDto[]>([]);

    const [isUnsuccessfullyValidated, setUnsuccessfullyValidated] = useState<boolean>(false);
    const [collectionCode, setCollectionCode] = useState<string | undefined>(undefined);
    const [permissionCode, setPermissionCode] = useState<string | undefined>(undefined);
    const [collectionError, setCollectionError] = useState<string | undefined>(undefined);
    const [permissionError, setPermissionError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const fetchCollectionsAndPermissions = async () => {
            const collections = await getCollections();
            const permissions = await getPermissions();
            setCollections(collections);
            setPermissions(permissions);
        }
        fetchCollectionsAndPermissions();
    }, []);

    const getCollections = async (): Promise<CollectionDto[]> => {
        try {
            const collections = await collectionsService.getAll();
            return collections;
        } catch (error) {
            return [];
        }
    }

    const getPermissions = async (): Promise<CollectionPermissionLevelDto[]> => {
        try {
            const permissions = await userService.getAllPermissionLevels();
            return permissions;
        } catch (error) {
            return [];
        }
    }

    function validatePermission(permissionCode: string | undefined): boolean {
        let isValid;
        const isPermissionError = permissionCode === undefined;
        if (isPermissionError) {
            setPermissionError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isValid = false;
        } else {
            setPermissionError(undefined);
            isValid = true;
        }
        return isValid;
    }

    function validateCollection(collectionCode: string | undefined): boolean {
        let isValid;
        const isCollectionNameError = collectionCode === undefined;
        if (isCollectionNameError) {
            setCollectionError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isValid = false;
        } else {
            setCollectionError(undefined)
            isValid = true;
        }
        return isValid;
    }

    function isFormValid() {
        const isPermissionValid = validatePermission(permissionCode);
        const isCollectionValid = validateCollection(collectionCode);
        return isPermissionValid && isCollectionValid;
    }

    function onDialogSave() {
        setUnsuccessfullyValidated(true)
        if (isFormValid()) {
            (async () => {
                try {
                    await userService.createUserCollectionPermission(login, collectionCode!, permissionCode!);
                    onClose();
                    reloadUserPermissionsGrid();
                    Snackbar.success(_transl(UsersTranslationKey.CREATE_PERMISSION_SUCCEEDED));
                } catch (e) {
                    Snackbar.error(_transl(UsersTranslationKey.CREATE_PERMISSION_FAILED));
                }
            })();
        }
    }

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(UsersTranslationKey.ADD_PERMISSION_DIALOG)}
                         onDialogClosed={onClose}/>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <SingleSelectField<CollectionDto>
                            id="collection-field"
                            label={_transl(UsersTranslationKey.ADD_PERMISSION_COLLECTION)}
                            errorMessage={collectionError}
                            value={collectionCode || ""}
                            onChange={(collectionCode) => {
                                isUnsuccessfullyValidated && validateCollection(collectionCode)
                                setCollectionCode(collectionCode)
                            }}
                            selectValue={(collection: CollectionDto) => collection.code}
                            renderName={(collection: CollectionDto) => collection.name}
                            items={collections}
                            required={true}/>

                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelectField<CollectionPermissionLevelDto>
                            id="userPermission-field"
                            label={_transl(UsersTranslationKey.ADD_PERMISSION_PERMISSION_LEVEL)}
                            errorMessage={permissionError}
                            value={permissionCode || ""}
                            onChange={(permissionCode) => {
                                isUnsuccessfullyValidated && validatePermission(permissionCode)
                                setPermissionCode(permissionCode)
                            }}
                            selectValue={(permission: CollectionPermissionLevelDto) => permission.code}
                            renderName={(permission: CollectionPermissionLevelDto) => permission.name}
                            items={permissions}
                            required={true}/>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <SaveButton onClick={onDialogSave}/>
                <CancelButton onClick={onClose}/>
            </DialogActions>

        </Dialog>
    );
}
