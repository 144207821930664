import {Event} from "../../../event/Event";

export enum SelectionEventType {
    SELECT_ALL = "SELECT_ALL",
    DESELECT_ALL = "DESELECT_ALL",
}

export interface SelectAllEvent extends Event {
    type: SelectionEventType.SELECT_ALL;
}

export interface DeselectAllEvent extends Event {
    type: SelectionEventType.DESELECT_ALL;
}