import * as React from "react";
import * as ReactDOM from "react-dom";
import "@fontsource/roboto";
import "./index.css";
import App from "./App";
import {LicenseInfo} from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey("8880cde512fa62f93d1e2df90b224172Tz04MzUzOCxFPTE3Mzg1NzI1MzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

ReactDOM.render(
    <App />,
    document.getElementById("root") as HTMLElement
);

