import RenderContext from "../../../../context/RenderContext";
import SelectionManagerUtils from "../../SelectionManagerUtils";
import * as d3 from "d3";
import {Area} from "../../../../util/GeometryUtils";
import {DEFAULT_LINE_COLOR} from "../../../../common/UIConstants";

export default class SelectionOverlay {

    showOverlay(area: Area,
                context?: RenderContext) {
        if (context) {
            context.svgElementManager.getDiagramGroupSelection().append("rect")
                .attr("id", SelectionManagerUtils.createDragSelectionOverlayId())
                .attr("fill", "none")
                .attr("stroke", DEFAULT_LINE_COLOR)
                .attr("stroke-dasharray", "3 2")
                .attr("pointer-events", "none")
                .attr("x", area.x)
                .attr("y", area.y)
                .attr("width", area.w)
                .attr("height", area.h);
        }
    }

    updateOverlay(area: Area,
                  context?: RenderContext) {
        if (context) {
            const overlay = d3.select(SelectionManagerUtils.createDragSelectionOverlayId(true)) as d3.Selection<SVGRectElement, unknown, any, unknown>;
            overlay
                .attr("x", area.x)
                .attr("y", area.y)
                .attr("width", area.w)
                .attr("height", area.h);
        }
    }

    hideOverlay() {
        d3.select(SelectionManagerUtils.createDragSelectionOverlayId(true))
            .remove();
    }
}