import React from "react";
import {HierarchyTreeItemDetailDto} from "../service/HierarchyTreeItemDetailDto";
import Typography from "@mui/material/Typography";
import {ArchimateElement} from "../../../../../common/archimate/ArchimateElement";
import ElementTypeIcon from "../../../../../components/fields/ElementTypeIcon";

const ARCHIMATE_ELEMENT_PREFIX = "ArchiMate";

export interface ElementTreeItemLabelProps {
    treeItem: HierarchyTreeItemDetailDto;
}

export function ElementTreeItemLabel(props: ElementTreeItemLabelProps) {
    const {treeItem} = props;

    function formatStandardName(title: string | undefined): string {
        if (title) {
            if (title.startsWith(ARCHIMATE_ELEMENT_PREFIX)) {
                return ArchimateElement.findByStandardName(title)?.visibleName || title;
            } else {
                return title;
            }
        } else {
            return "";
        }
    }

    return (
        <>
            <Typography component={"span"} sx={{display: "flex", alignItems: "center", mr: 1}}>
                <ElementTypeIcon name={treeItem.elementType!}/>
            </Typography>
            <Typography component={"span"} sx={{display: "flex", alignItems: "center"}}>
                {formatStandardName(treeItem.title)}
            </Typography>
        </>
    );
}