import EventManager, {Unsubscriber} from "../../event/EventManager";
import {
    EventProperty,
    EventType,
    IChartEvent,
    IChartGridSnappingFunctionUpdatedEvent,
    IItemCreateMenuEvent, IModelUpdatedOnItemsCreatedEvent,
} from "../../event/Event";
import RenderContext from "../context/RenderContext";
import {ISnappingFunction} from "./GridManager";
import {Point} from "../util/GeometryUtils";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import {NodeType} from "../../apis/diagram/NodeType";
import {ObjectType} from "../../apis/editor/ObjectType";
import {IEditMode} from "../editor/IEditMode";
import {StereotypeDto} from "../../apis/stereotype/StereotypeDto";

export interface ElementDefinition {
    newElement?: {
        id: string,
        elementStandardName: string,
        stereotype?: StereotypeDto,
    },
    existingElement?: {
        id: string
    },
}

export default class ElementCreateManager {

    private eventManager: EventManager;
    private renderContext?: RenderContext;
    private snappingFunction?: ISnappingFunction;

    private nodeType?: NodeType;
    private elementDefinition?: ElementDefinition;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_CREATE_MENU_ACTIVATED, this.handleItemCreateMenuEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.ELEMENT_CREATE_MENU_DEACTIVATED, this.handleItemCreateMenuEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_MOUSE_UP, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_GRID_SNAPPING_FUNCTION_UPDATED, this.handleChartGridSnappingFunctionUpdatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_CREATED, this.handleModelUpdatedOnItemsCreatedEvent.bind(this)));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    private handleItemCreateMenuEvent(event: IItemCreateMenuEvent) {
        if (event.type === EventType.NODE_CREATE_MENU_ACTIVATED) {
            if (this.renderContext) {
                this.nodeType = event.nodeType;
                this.elementDefinition = event.elementDefinition;
                this.renderContext.svgElementManager.getSvgSelection()
                    .attr("cursor", "crosshair");
            }
        }
        if (event.type === EventType.ELEMENT_CREATE_MENU_DEACTIVATED) {
            this.clean();
        }
    }

    private handleChartGridSnappingFunctionUpdatedEvent(event: IChartGridSnappingFunctionUpdatedEvent) {
        this.snappingFunction = event.snappingFunction;
    }

    private handleChartEvent(event: IChartEvent) {
        if (event.type === EventType.CHART_MOUSE_UP) {
            if (this.elementDefinition != null && this.renderContext?.isEdit() === true) {
                const point = this.computeDiagramGroupPoint(event);

                const api = (this.renderContext.renderMode as IEditMode).diagramApi;
                api.generateIdentifiers(
                    [ObjectType.ELEMENT, ObjectType.NODE],
                    (ids: string[]) => {
                        if (this.elementDefinition?.newElement) {
                            // set newly created element id
                            this.elementDefinition.newElement.id = ids[0];
                        }
                        this.onCreateNode(ids[1],this.elementDefinition as ElementDefinition, point, event.event)
                    },
                    (error: any) => {
                    });
            }
        }
    }

    private handleModelUpdatedOnItemsCreatedEvent(event: IModelUpdatedOnItemsCreatedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_CREATED) {
            this.clean();
        }
    }

    private computeDiagramGroupPoint(event: IChartEvent) {
        let diagramGroupX = event.event[EventProperty.TRANSFORMED_X_COORDINATE];
        let diagramGroupY = event.event[EventProperty.TRANSFORMED_Y_COORDINATE];
        if (diagramGroupX == null && diagramGroupY == null) {
            const diagramGroupNode = this.renderContext?.svgElementManager.getDiagramGroupSelection().node() as SVGGElement;
            const svgNode = this.renderContext?.svgElementManager.getSvg() as SVGSVGElement;
            const diagramGroupPoint = DiagramEditorUtils.convertOuterPointToGroup(new Point(event.event.x, event.event.y), diagramGroupNode, svgNode);
            diagramGroupX = diagramGroupPoint.x;
            diagramGroupY = diagramGroupPoint.y;
        }
        const snappedX = this.snappingFunction ? this.snappingFunction.snap(diagramGroupX) : diagramGroupX;
        const snappedY = this.snappingFunction ? this.snappingFunction.snap(diagramGroupY) : diagramGroupY;
        return new Point(snappedX, snappedY);
    }

    private onCreateNode(nodeId: string, elementDefinition: ElementDefinition, diagramGroupPoint: Point, event: any) {
        this.eventManager.publishEvent(
            {
                type: EventType.NODE_CREATE,
                nodeType: this.nodeType as NodeType,
                nodeId: nodeId,
                elementDefiniton: elementDefinition,
                diagramGroupPoint: diagramGroupPoint,
                event: event
            });
    }

    private clean() {
        this.elementDefinition = undefined;
        this.nodeType = undefined;
        this.renderContext?.svgElementManager.getSvgSelection()
            .attr("cursor", "auto");
    }
}
