export enum ValidateModelTranslationKey {
    VALIDATE_AGAINST_METAMODEL = "tools.validateModel.validateAgainstMetamodel",
    VALIDATE_MODEL_BUTTON = "tools.validateModel.validateModelButton",
    MODEL_VALIDATING_IN_PROGRESS = "tools.validateModel.lastStepActionInProgressText",
    VALIDATION_IS_DONE = "tools.validateModel.lastStepActionSuccessfulText",
    VALIDATION_IS_DONE_ADDITIONAL_INFO = "tools.validateModel.lastStepActionSuccessfulTextAdditionalInfo",
    MODEL_VALIDATING_FAILED = "tools.validateModel.lastStepActionFailedText",
    MODEL_DIAGRAM_SELECTION_LABEL = "tools.validateModel.chooseDiagramLabel",
    ADD_DIAGRAM = "tools.validateModel.addDiagram",
    REMOVE_DIAGRAM = "tools.validateModel.removeDiagram",
    SELECT_METAMODEL = "tools.validateModel.chooseMetamodel",
    SELECT_MODEL = "tools.validateModel.chooseModel",
    VALIDATION_RESULT = "tools.validateModel.validationResult",
    NAME = "tools.validateModel.validationResult",
    IDENTIFIER = "tools.validateModel.identifier",
}