import React, {useState} from "react";
import EditablePickListComponent from "../EditablePickListComponent";
import {_transl} from "../../../store/localization/TranslMessasge";
import {ILabelDto} from "../../../common/apis/label/ILabelDto";
import {EditableLabelsComponentTranslationKey} from "./EditableLabelsComponentTranslationKey";

interface EditableLabelsComponentProps {
    textFieldLabel?: string,
    textFieldId?: string,
    allLabels: Array<ILabelDto>,
    initiallyAssignedLabels: Array<ILabelDto>,
    doUpdate: (labels: Array<ILabelDto>) => Promise<any>,
    onSuccessfulUpdate?: (labels: Array<ILabelDto>) => void,
    readonly?: boolean,
    publicLabelsAssigningPermitted?: boolean,
}

export function EditableLabelsComponent(props: EditableLabelsComponentProps) {
    const {
        textFieldLabel, textFieldId, readonly, publicLabelsAssigningPermitted, allLabels,
        doUpdate, onSuccessfulUpdate
    } = props;

    const [assignedLabels, setAssignedLabels] = useState<ILabelDto[]>(props.initiallyAssignedLabels);

    const getOptionText: (item: unknown) => string = (item) => (item as ILabelDto).name;
    const update = doUpdate as (items: unknown[]) => Promise<any>;

    function canTransferLabel(label: ILabelDto): boolean {
        return (label.publicLabel && publicLabelsAssigningPermitted) || !label.publicLabel;
    }

    function subtractLabels(originalArray: ILabelDto[], subtractingArray: ILabelDto[]): ILabelDto[] {
        return originalArray.filter(originalArrayItem => !subtractingArray.some(subtractingArrayItem => subtractingArrayItem.code === originalArrayItem.code));
    }


    return (
        <EditablePickListComponent
            textFieldLabel={textFieldLabel || _transl(EditableLabelsComponentTranslationKey.LABELS_TITLE)}
            textFieldId={textFieldId}
            pickListAvailableOptions={subtractLabels(allLabels, assignedLabels)}
            pickListPickedOptions={assignedLabels}
            pickListGetOptionText={getOptionText}
            getId={(item) => item ? (item as ILabelDto).id : ""}
            pickListDialogTitle={_transl(EditableLabelsComponentTranslationKey.LABELS_ASSIGNING)}
            pickListAvailableOptionsTitle={_transl(EditableLabelsComponentTranslationKey.AVAILABLE_LABELS)}
            pickListPickedOptionsTitle={_transl(EditableLabelsComponentTranslationKey.ASSIGNED_LABELS)}
            doUpdate={update}
            onSuccessfulUpdate={(options) => {
                if (onSuccessfulUpdate != null) {
                    onSuccessfulUpdate(options as Array<ILabelDto>);
                }
                setAssignedLabels(options as Array<ILabelDto>);
            }}
            readonly={readonly}
            pickListIsAvailableOptionDisabled={(label) => !canTransferLabel(label as ILabelDto)}
            pickListIsPickedOptionDisabled={(label) => !canTransferLabel(label as ILabelDto)}
        />
    );
}
