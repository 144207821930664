import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ErrorIcon from '@mui/icons-material/Error';
import DialogTitle from "./DialogTitle";
import Dialog, {DialogProps} from "./Dialog";
import DialogContent from "./DialogContent";
import DialogActions from "./DialogActions";
import {ButtonLayout, PositiveButtons} from "../button/ButtonLayout";
import DialogContentText from "./DialogContentText";
import theme from "../../common/Theme";
import {OkButton} from "../button/OkButton";

export class AlertDialogType {

    public static readonly INFO: AlertDialogType = new AlertDialogType((theme: Theme) => <InfoIcon
        style={{color: theme.palette.info.main}}/>);
    public static readonly WARNING: AlertDialogType = new AlertDialogType((theme: Theme) => <ReportProblemIcon
        style={{color: theme.palette.warning.main}}/>);
    public static readonly ERROR: AlertDialogType = new AlertDialogType((theme: Theme) => <ErrorIcon
        style={{color: theme.palette.error.main}}/>);

    private constructor(private icon: (theme: Theme) => JSX.Element) {
    }

    public getIcon(theme: Theme): JSX.Element {
        return this.icon(theme);
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            display: "flex",
            alignItems: "center",
        },
        dialogContentIcon: {
            marginRight: "1em",
            "& .MuiSvgIcon-root": {
                fontSize: "3em",
            }
        }
    })
);

export interface AlertDialogProps {
    open: boolean;
    type: AlertDialogType;
    title: string;
    text: string;
    onClose: () => void;
    role?: string;
    maxWidth?: DialogProps["maxWidth"];
}

export default function AlertDialog(props: AlertDialogProps) {

    const {open, type, title, text, onClose, role, maxWidth} = props;
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            role={role}
            maxWidth={maxWidth}
            aria-labelledby="alert-dialog">

            <DialogTitle title={title} onDialogClosed={onClose}/>
            <DialogContent>
                <DialogContentText>
                    <span className={classes.dialogContent}>
                        <span className={classes.dialogContentIcon}>
                            {type.getIcon(theme)}
                        </span>
                        {text}
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonLayout>
                    <PositiveButtons>
                        <OkButton onClick={onClose}/>
                    </PositiveButtons>
                </ButtonLayout>
            </DialogActions>
        </Dialog>
    );
}
