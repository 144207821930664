import React, {Dispatch} from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {connect} from "react-redux";
import {IApplicationState} from "../../store/Store";
import {IElementStateDto} from "../../common/apis/Elements";
import EditableSelectComponent from "./EditableSelectComponent";
import stateColorResolver from "../../common/StateColorResolver";
import ElementState from "../ElementState";

const styles = (theme: Theme) => createStyles({
});

interface IProps extends WithStyles<typeof styles> {
    // textfield props
    label?: string,
    id?: string

    // select props
    selectedState?: IElementStateDto,
    selectedStateCode?: string,
    isOptional: boolean,

    // edit callback
    doUpdate: (value?: IElementStateDto) => Promise<any>,
    onSuccessfulUpdate?: (value?: IElementStateDto) => void,

    // state props
    states: Array<IElementStateDto>,
    readonly?: boolean,
}

interface IState {
    selectedState?: IElementStateDto,
}

class EditableStateComponent extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedState: this.getSelectedState(props),
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const prevSelectedState = this.getSelectedState(prevProps);
        const actualSelectedState = this.getSelectedState(this.props);

        if (prevSelectedState?.code !== actualSelectedState?.code) {
            this.updateSelectedState(actualSelectedState);
        }
    }

    render() {
        const { label, id, readonly } = this.props;
        const { states } = this.props;
        const { doUpdate, onSuccessfulUpdate } = this.props;
        const { selectedState } = this.state;

        const getValueLabel: (item: unknown) => string = (item) => item != null ? (item as IElementStateDto).code : " ";
        const update = doUpdate as (state?: unknown) => Promise<any>;

        return (
            <EditableSelectComponent label={label || "Stav"}
                                     id={id}
                                     initialValues={states}
                                     initialSelectedValue={selectedState}
                                     isOptional={true}
                                     getValueLabel={getValueLabel}
                                     renderValueLabel={(value) => value ? this.renderValueLabel(value as IElementStateDto, getValueLabel) : <span></span>}
                                     getValueId={(item) => item ? (item as IElementStateDto).code : ""}
                                     doUpdate={update}
                                     onSuccessfulUpdate={(state?) => {
                                         if (onSuccessfulUpdate != null) {
                                             onSuccessfulUpdate(state as IElementStateDto);
                                         }
                                         this.updateSelectedState(state as IElementStateDto);
                                     }}
                                     readonly={readonly}
            />
        );
    }

    private renderValueLabel(value: IElementStateDto, getValueLabel: (item: unknown) => string): JSX.Element {
        return <ElementState stateCode={value.code} stateColorResolver={stateColorResolver} />;
    }

    private getSelectedState(props: IProps): IElementStateDto | undefined {
        const { states, selectedState, selectedStateCode } = props;

        let state = selectedState;
        if (!state && selectedStateCode) {
            state = this.findStateByCode(selectedStateCode, states);
        }
        return state;
    }

    private updateSelectedState(selectedState?: IElementStateDto) {
        this.setState(state => {
            return {
                selectedState: selectedState,
            }
        })
    }

    private findStateByCode(code: string, states: Array<IElementStateDto>) {
        return states.filter(state => state.code === code)[0];
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    states: state.pages.common.options.states.resource || [],
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EditableStateComponent));
