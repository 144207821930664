import RouteDefinition from "./RouteDefinition";
import {
    ELEMENTS_PAGE_PRESETS_ID_QUERY_PARAM,
} from "../../pages/main/content/elements/ElementsPage";
import {diagramEditorPage, diagramsPage, elementsPage} from "../routes/AppRoutes";
import Constants from "../Constants";


export default class RouteDefinitionUtils {

    public static resolvePath(definition: RouteDefinition, queryParams?: Array<{ name: string; value: string }>): string {
        let path = definition.path;
        if (queryParams) {
            path += "?";
            queryParams.forEach(param => {
                path += `${param.name}=${param.value}&`;
            });
        }
        return path;
    }

    public static resolveDiagramDetailPath(identifier: string) {
        return Constants.FE_APP_MAIN_ROUTE + "/" + diagramsPage.path + "/" + identifier;
    }

    public static resolveElementDetailPath(identifier: string) {
        return Constants.FE_APP_MAIN_ROUTE + "/" + elementsPage.path + "/" + identifier;
    }

    public static resolveElementPagePath(pagePresetsId: string) {
        const queryParam = {
            name: ELEMENTS_PAGE_PRESETS_ID_QUERY_PARAM,
            value: pagePresetsId,
        };
        return Constants.FE_APP_MAIN_ROUTE + "/" + RouteDefinitionUtils.resolvePath(elementsPage, [queryParam]);
    }

    public static resolveDiagramEditorPath(queryParams: Array<{ name: string; value: string }>) {
        let paramsPath = "?";
        queryParams.forEach(param => {
            paramsPath += `${param.name}=${param.value}&`;
        });
        return Constants.FE_APP_MAIN_ROUTE + "/" + diagramEditorPage.path + paramsPath;
    }
}
