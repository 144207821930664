import React from "react";
import {ClickAwayListener, Menu, MenuItem} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import clsx from "clsx";

export interface ContextMenuItem<T> {
    icon: JSX.Element,
    label: string,
    onClick: (data?: T) => void,
    isEnabled?: (data?: T) => boolean,
}

export interface ContextMenuProps<T> {
    menuItems: ContextMenuItem<T>[],
    data?: T,
    open: boolean,
    menuTop: number,
    menuLeft: number,
    onClose: (isClose: boolean) => void,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contextMenu: { pointerEvents: 'none'},
        contextMenuPaper: { pointerEvents: 'auto' },
        menuItem: {
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(2),
                color: '#9e9e9e',
            }
        }
    })
);

/**
 * @deprecated
 */
export default function ContextMenu<T>(props: ContextMenuProps<T>) {
    const classes = useStyles();

    function onItemClick(item: ContextMenuItem<T>) {
        item.onClick(props.data);
        close();
    }

    function isItemDisabled(item: ContextMenuItem<T>) {
        if (item.isEnabled) {
            return !item.isEnabled(props.data);
        } else {
            return false;
        }
    }

    function close() {
        props.onClose(false);
    }

    function renderMenuItems() {
        const {menuItems} = props;

        return menuItems.map((item => {
            return <MenuItem onClick={() => onItemClick(item)}
                             className={classes.menuItem}
                             key={item.label}
                             disabled={isItemDisabled(item)}
            >
                {item.icon}
                {item.label}
            </MenuItem>
        }))
    }

    const {open, menuTop, menuLeft} = props;

    return (
        <ClickAwayListener onClickAway={() => close()}>
            <Menu open={open}
                  PopoverClasses={{ root: clsx(classes.contextMenu), paper: clsx(classes.contextMenuPaper) }}
                  onClose={() => close()}
                  anchorReference="anchorPosition"
                  anchorPosition={{
                      top: menuTop,
                      left: menuLeft,
                  }}
            >
                {renderMenuItems()}
            </Menu>
        </ClickAwayListener>
    );
}
