import {TranslationFunction} from "../../../../store/localization/TranslMessasge";
import {CommonTranslation} from "../CommonTranslation";

export const GraphQueryFormatter = {
    formatIsPrivate: (isPrivate: boolean | undefined, _transl: TranslationFunction): string => {
        if (isPrivate) {
            return _transl(CommonTranslation.PRIVATE);
        }
        if (!isPrivate) {
            return _transl(CommonTranslation.PUBLIC);
        }
        return "";
    }
}
