import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import SingleselectElementTypesComboBox from "../../../../components/fields/SingleselectElementTypesComboBox";
import {IElementTypeDto} from "../../../../common/apis/Elements";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {StereotypesTranslationKey} from "./StereotypesTranslationKey";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import TextField from "../../../../components/fields/textfield/TextField";
import DialogActions from "../../../../components/dialogs/DialogActions";
import Grid from "../../../../components/dialogs/Grid";
import {CommonTranslation} from "../CommonTranslation";
import Snackbar from "../snackbar/Snackbar";
import stereotypeService, {StereotypeCreateDto} from "../../../../common/apis/StereotypeService";
import {ValidationError} from "../../../../common/ValidationError";
import {StereotypeErrorCode} from "./StereotypeErrorCodes";


interface StereotypeCreateDialogProps {
    open: boolean,
    onClosed: () => void,
    onLabelAdded: () => void,
}

export default function StereotypeCreateDialog(props: StereotypeCreateDialogProps) {
    const {onClosed, open, onLabelAdded} = props;

    const [name, setName] = useState<string>("");
    const [type, setType] = useState<IElementTypeDto | null>(null);
    const [nameError, setNameError] = useState<string | undefined>(undefined);
    const [typeError, setTypeError] = useState<string | undefined>(undefined);
    const typeOptions = useSelector((state: IApplicationState) => state.pages.common.options.types.resource);

    useEffect(() => {
        if (!open) {
            setName("");
            setNameError(undefined);
            setType(null);
            setTypeError(undefined);

        }
    }, [open]);

    const validateName = (name: string): boolean => {
        let isNameValid;

        if (name.trim().length === 0) {
            setNameError(CommonTranslation.FILL_OUT_THIS_FIELD);
            isNameValid = false;
        } else {
            setNameError(undefined);
            isNameValid = true;
        }

        return isNameValid;
    }

    const validateType = (type: IElementTypeDto | null): boolean  => {
        let isTypeValid;

        if (!type || type.name.trim().length === 0) {
            setTypeError(CommonTranslation.FILL_OUT_ITEM_FROM_LIST);
            isTypeValid = false;
        } else {
            setTypeError(undefined);
            isTypeValid = true;
        }

        return isTypeValid;
    }

    const isFormValid = () => {
        const isNameValid = validateName(name);
        const isTypeValid = validateType(type);

        return isNameValid && isTypeValid;
    }

    function saveChanges() {
        if (isFormValid() && type) {
            const stereotypeCreate: StereotypeCreateDto = {
                name: name,
                elementType: type.name
            };
            stereotypeService.createStereotype(stereotypeCreate)
                .then(() => {
                    onClosed();
                    onLabelAdded();
                    Snackbar.success(_transl(StereotypesTranslationKey.CREATE_STEREOTYPE_SUCCEEDED));
                })
                .catch((err) => {
                    if (err instanceof ValidationError && err.error.code === StereotypeErrorCode.CONFLICTING_NAME) {
                        setNameError(_transl(StereotypesTranslationKey.CREATE_STEREOTYPE_CONFLICTING_NAME));
                    } else {
                        Snackbar.error(_transl(StereotypesTranslationKey.CREATE_STEREOTYPE_NOT_SUCCEEDED), err);
                    }
                });
        }
    }

    return (
         <Dialog open={open} onClose={onClosed} >

            <DialogTitle id="scroll-dialog-title"
                         title={_transl(StereotypesTranslationKey.ADD_STEREOTYPE_TITLE)}
                         onDialogClosed={onClosed}/>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="name-field"
                                   label={_transl(StereotypesTranslationKey.ADD_STEREOTYPE_NAME)}
                                   value={name}
                                   errorMessage={nameError && _transl(nameError)}
                                   onChange={(name) => setName(name)}
                                   required={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <SingleselectElementTypesComboBox id="name-field"
                                                          label={_transl(StereotypesTranslationKey.ADD_STEREOTYPE_DERIVED_FROM)}
                                                          errorMessage={typeError && _transl(typeError)}
                                                          options={typeOptions}
                                                          selectedValue={type}
                                                          handleOnChange={(type) => {
                                                              setType(type)
                                                          }}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={saveChanges}/>
                <CancelButton onClick={onClosed}/>
            </DialogActions>
        </Dialog>
    );
}
