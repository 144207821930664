import {NavLinkProps} from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import DrawerListItem from "./DrawerListItem";
import DrawerMenu from "./DrawerMenu";

export type MobileMenuItems = (DrawerMenuItemModel | DrawerMenuModel | DrawerDividerModel)[];

export interface DrawerDividerModel {
    divider: true;
}

export interface DrawerMenuItemModel {
    icon?: React.ReactNode;
    label: string;
    navigateTo: NavLinkProps["to"];
    onClick?: () => void;
    visible?: boolean;
}

export interface DrawerMenuModel {
    icon?: React.ReactNode;
    label: string;
    items: MobileMenuItems;
}

export interface DrawerListProps {
    onClose: () => void;
    level: number;
    items: MobileMenuItems;
    disablePadding?: boolean;
}

export default function DrawerList(props: Readonly<DrawerListProps>) {
    const {onClose, items, level, disablePadding} = props;

    function onClick(item: DrawerMenuItemModel) {
        if (item.onClick) {
            item.onClick();
        }
        onClose();
    }

    return (
        <List disablePadding={disablePadding}>
            {items.map((item, index) => {
                if ("divider" in item) {
                    return <Divider key={index} />;
                } else if ("navigateTo" in item) {
                    if (item.visible === false) {
                        return null;
                    }
                    return (
                        <DrawerListItem key={index}
                                        icon={item.icon}
                                        text={item.label}
                                        to={item.navigateTo}
                                        indentationLevel={level}
                                        onClick={() => onClick(item)} />
                    );
                } else {
                    return (
                        <DrawerMenu key={index}
                                    menu={item}
                                    level={level}
                                    onClose={onClose} />
                    )
                }
            })}
        </List>
    );
}