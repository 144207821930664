import {NavLink, NavLinkProps} from "react-router-dom";
import AppBarIconButton from "./AppBarIconButton";
import {ElementType} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";

export interface AppBarIconLinkProps {
    icon: ElementType<SvgIconProps>;
    tooltip?: string;
    to: NavLinkProps["to"];
}

export default function AppBarIconLink(props: AppBarIconLinkProps) {
    const {icon, tooltip, to} = props;

    return (
        <NavLink to={to}>
            {({isActive}) =>
                <AppBarIconButton tooltip={tooltip} isActive={isActive} icon={icon} />
            }
        </NavLink>
    );
}