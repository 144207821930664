import {MetamodelValidationReportDto} from "../../../../common/apis/MetamodelService";
import {Box, Typography} from "@mui/material";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ViewpointValidationTranslationKey} from "./ViewpointValidationTranslationKey";


export interface ViewpointReportSummaryProps {
    report: MetamodelValidationReportDto,
}

export function ValidationReportSummary({report}: ViewpointReportSummaryProps) {
    if (report.reportedElements.length > 0) {
        return (
            <Box marginTop={2}>
                <Typography variant={"body1"}>
                    {_transl(ViewpointValidationTranslationKey.DIAGRAM_INVALID)}
                </Typography>
                <ul>
                    <li>{_transl(ViewpointValidationTranslationKey.INCORRECT_ELEMENTS_COUNT) + " " +  report.reportedElements.length}</li>
                </ul>
            </Box>
        );
    } else {
        return (
            <Box marginTop={2}>
                <Typography variant={"body1"}>
                    {_transl(ViewpointValidationTranslationKey.DIAGRAM_VALID)}
                </Typography>
            </Box>
        );
    }

}
