export enum DashboardTranslationKey {
    IFRAME_TITLE = "dashboard.iFrame.title",
    NAME = "dashboard.name",
    ADMINISTRATION = "dashboard.pageAdministration",
    DIAGRAM = "dashboard.textField.diagram",
    URL = "dashboard.textField.url",
    EMPTY_PAGE = "dashboard.textField.emptyPage",
    SEARCH_DIAGRAM = "dashboardAdministration.tooltip.searchDiagram",
    SUCCESSFUL_SAVE = "dashboardAdministration.succeededAlert.alertText",
    NOT_SUCCESSFUL_SAVE = "dashboardAdministration.notSucceededAlert.alertText"
}