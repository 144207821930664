export enum ChatTranslationKey {
    DELETE_CONFIRMATION_TEXT = "chat.deleteConfirmation.text",
    DELETE_CONFIRMATION_TITLE = "chat.deleteConfirmation.title",
    RESOLVE = "chat.resolve",
    UNRESOLVE = "chat.unresolve",
    NO_CHATS_FOUND = "chat.noChatsFound",
    NEW_CHAT_PLACEHOLDER = "chat.newChatPlaceholder",
    REPLY_TO_CHAT = "chat.reply",
    REMOVE_CHAT = "chat.removeChat",
    SHOW_DIAGRAM = "chat.showDiagram",
    SHOW_ELEMENT_DETAIL = "chat.showElementDetail",
    SHOW_REPLIES = "chat.showReplies",
    HIDE_REPLIES = "chat.hideReplies",
    LAST_REPLY = "chat.lastReply",
}
