export enum AppInfoTranslationKey {
    BASIC_INFO = "appInfo.basicInfo",
    COMPILATION = "appInfo.compilation",
    DATA_LOAD_FAILED = "appInfo.dataLoadFailed",
    DATE_OF_COMPILATION = "appInfo.dateOfCompilation",
    HELPDESK = "appInfo.helpDesk",
    LOADING = "appInfo.loading",
    PRODUCT = "appInfo.product",
    RELEASE_NOTES = "appInfo.releaseNotes",
    VERSION_AND_COMPILATION = "appInfo.versionAndCompilation",
    WEB = "appInfo.web",
}