import React from "react";
import {SnackbarProvider as MuiSnackbarProvider, SnackbarProviderProps} from "notistack";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Constants from "../../../../common/Constants";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar, {SnackbarConfigurator} from "./Snackbar";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        snackbarContainer: {
            top: theme.spacing(theme.logoContainer.heightSpacing) + 13,
        },
    })
);
export default function SnackbarProvider(props: SnackbarProviderProps) {
    const classes = useStyles();

    return (
        <MuiSnackbarProvider classes={{containerRoot: classes.snackbarContainer}}
                             anchorOrigin={{vertical: "top", horizontal: "right"}}
                             autoHideDuration={Constants.FE_APP_ALERT_DELAY}
                             maxSnack={3}
                             action={(snackbarKey) => (
                                 <CloseIcon onClick={() => Snackbar.onClose(snackbarKey)}></CloseIcon>
                             )}>
            <SnackbarConfigurator/>
            {props.children}
        </MuiSnackbarProvider>
    )
}
