import {DateNullable, StringNullable} from "../../common/Types";
import {Reducer} from "redux";
import {ActionsObservable, StateObservable} from "redux-observable";
import {IApplicationState} from "../Store";
import {catchError, filter, mergeMap, startWith, switchMap} from "rxjs/operators";
import {Observable, of, Subject, Subscriber, merge} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";

// Resource type

export enum DownloadableResourceType {
    // Page specific
    ELEMENTS_DETAIL_PAGE_ATTACHMENT = "ELEMENTS_DETAIL_PAGE_ATTACHMENT",
    DIAGRAM_DETAIL_PAGE_ATTACHMENT = "DIAGRAM_DETAIL_PAGE_ATTACHMENT",
}

// Action

export enum DownloadActionType {
    DOWNLOAD = "ACTION/COMMON/DOWNLOAD",
    DOWNLOAD_PROGRESS = "ACTION/COMMON/DOWNLOAD_PROGRESS",
    DOWNLOAD_SUCCESSFUL = "ACTION/COMMON/DOWNLOAD_SUCCESSFUL",
    DOWNLOAD_FAILED = "ACTION/COMMON/DOWNLOAD_FAILED",
    DOWNLOAD_ALREADY_STARTED = "ACTION/COMMON/DOWNLOAD_ALREADY_STARTED",
    DOWNLOAD_REMOVE_ITEM = "ACTION/COMMON/DOWNLOAD_REMOVE_ITEM",
}

export enum DownloadStatusType {
    NOT_STARTED = "NOT_STARTED",
    STARTED = "STARTED",
    SUCCESSFUL = "SUCCESSFUL",
    FAILED = "FAILED",
}

export interface IDownloadableResourceAction<T> {
    type: DownloadActionType;
    resourceType: DownloadableResourceType;
    resourceId: T;
    status: DownloadStatusType;
    progress?: number,
    error?: string;
    resourceFileName?: string,
    resource?: Blob;
}

// Action creators

export function getResourceDownloadAlreadyStartedAction<T>(resourceType: DownloadableResourceType, resourceId: T): IDownloadableResourceAction<T> {
    const action = {
        ...getResourceDownloadAction(resourceType, resourceId),
        type: DownloadActionType.DOWNLOAD_ALREADY_STARTED,
    }

    return action;
}

export function getResourceDownloadAction<T>(resourceType: DownloadableResourceType, resourceId: T, resourceFileName?: string): IDownloadableResourceAction<T> {
    const action = {
        type: DownloadActionType.DOWNLOAD,
        resourceType: resourceType,
        resourceId: resourceId,
        status: DownloadStatusType.STARTED,
    } as IDownloadableResourceAction<T>;

    if (resourceFileName != null) {
        action.resourceFileName = resourceFileName;
    }
    return action;
}

export function getResourceDownloadProgressAction<T>(resourceType: DownloadableResourceType, resourceId: T, progress: number): IDownloadableResourceAction<T> {
    return {
        ...getResourceDownloadAction(resourceType, resourceId),
        type: DownloadActionType.DOWNLOAD_PROGRESS,
        progress: progress,
    }
}

export function getResourceDownloadCustomAction<I, T extends IDownloadableResourceAction<I>>(resourceType: DownloadableResourceType, resourceId: T, params: any): T {
    return {
        ...getResourceDownloadAction(resourceType, resourceId),
        ...params,
    } as T;
}

export function getResourceDownloadRemoveItemAction<T>(resourceType: DownloadableResourceType, resourceId: T): IDownloadableResourceAction<T> {
    return {
        type: DownloadActionType.DOWNLOAD_REMOVE_ITEM,
        resourceType: resourceType,
        resourceId: resourceId,
        status: DownloadStatusType.SUCCESSFUL,
    }
}

export function getResourceDownloadProgressCustomAction<I, T extends IDownloadableResourceAction<I>>(resourceType: DownloadableResourceType, resourceId: unknown, progress: number, params: any): T {
    return {
        ...getResourceDownloadProgressAction(resourceType, resourceId, progress),
        ...params,
    } as T;
}

export function getResourceDownloadSuccessfulAction<T>(resourceType: DownloadableResourceType, resourceId: T, resource: Blob, resourceFileName: string): IDownloadableResourceAction<T> {
    return {
        type: DownloadActionType.DOWNLOAD_SUCCESSFUL,
        resourceType: resourceType,
        resourceId: resourceId,
        status: DownloadStatusType.SUCCESSFUL,
        resource: resource,
        resourceFileName: resourceFileName,
    }
}

export function getResourceDownloadFailedAction<T>(resourceType: DownloadableResourceType, resourceId: T, error: string): IDownloadableResourceAction<T> {
    return {
        type: DownloadActionType.DOWNLOAD_FAILED,
        resourceType: resourceType,
        resourceId: resourceId,
        status: DownloadStatusType.FAILED,
        error: error,
    }
}

// State

export interface IDownloadStatusState {
    status: DownloadStatusType;
    progress: number,
    start: Date,
    end: DateNullable,
    error: StringNullable;
    resourceFileName?: string,
    resource?: any;
}

export interface IDownloadableResourceState<T> {
    downloadStatus: IDownloadStatusState;
    resourceId: T;
}

// Reducer

class ReducerUtils {

    public static replaceItem<T>(array: Array<IDownloadableResourceState<T>>, oldItem: IDownloadableResourceState<T>, newItem: IDownloadableResourceState<T>) {
        return array.map(item => {
            return (item === oldItem) ? newItem : item
        })
    }

    public static findItemByResourceId<T>(array: Array<IDownloadableResourceState<T>>, resourceId: T | undefined) {
        return array.filter(item => JSON.stringify(item.resourceId) === JSON.stringify(resourceId))[0];
    }

    static removeItemByResourceId<T>(array: Array<IDownloadableResourceState<T>>, resourceId: T) {
        return array.filter(item => JSON.stringify(item.resourceId) !== JSON.stringify(resourceId));
    }
}

export function createDownloadableResourceArrayReducer<T>(
    resourceType: IDownloadableResourceAction<T>["resourceType"],
    initialState: Array<IDownloadableResourceState<T>>
): Reducer<Array<IDownloadableResourceState<T>>, IDownloadableResourceAction<T>>
{
    return (
        state= initialState,
        action
    ) => {
        if (action.resourceType === resourceType) {
            let item, newItem;
            switch (action.type) {
                case DownloadActionType.DOWNLOAD:
                    return [
                        ...state,
                        {
                            downloadStatus: {
                                status: action.status,
                                progress: 0,
                                start: new Date(),
                                end: null,
                                error: null,
                                resource: null,
                            },
                            resourceId: action.resourceId,
                        },
                    ]

                case DownloadActionType.DOWNLOAD_PROGRESS:
                    item = ReducerUtils.findItemByResourceId(state, action.resourceId);
                    if (item.downloadStatus.progress !== action.progress) {
                        newItem = {
                            ...item,
                            downloadStatus: {
                                ...item.downloadStatus,
                                status: action.status,
                                progress: action.progress,
                            },
                        } as IDownloadableResourceState<T>;
                        return ReducerUtils.replaceItem(state, item, newItem);
                    } else {
                        return state;
                    }

                case DownloadActionType.DOWNLOAD_SUCCESSFUL :
                    item = ReducerUtils.findItemByResourceId(state, action.resourceId);
                    if (item != null) {
                        newItem = {
                            ...item,
                            downloadStatus: {
                                ...item.downloadStatus,
                                status: action.status,
                                progress: 100,
                                end: new Date(),
                                error: null,
                                resource: action.resource,
                                resourceFileName: action.resourceFileName as string,
                            },
                        } as IDownloadableResourceState<T>;
                        return ReducerUtils.replaceItem(state, item, newItem);
                    } else {
                        return state;
                    }

                case DownloadActionType.DOWNLOAD_FAILED :
                    item = ReducerUtils.findItemByResourceId(state, action.resourceId);
                    newItem = {
                        ...item,
                        downloadStatus: {
                            ...item.downloadStatus,
                            status: action.status,
                            progress: 0,
                            end: new Date(),
                            error: action.error,
                        },
                    } as IDownloadableResourceState<T>;
                    return ReducerUtils.replaceItem(state, item, newItem);

                case DownloadActionType.DOWNLOAD_REMOVE_ITEM :
                    return ReducerUtils.removeItemByResourceId(state, action.resourceId);

            }
        }
        return state;
    };
}

// Epic

export function createDownloadableResourceEpic<T>(
    resourceType: DownloadableResourceType,
    apiCall: (action: IDownloadableResourceAction<T>, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) => Observable<AjaxResponse>,
    trackProgress: boolean = true,
) {
    return composeDownloadableResourceEpic<IDownloadableResourceAction<any>>(
        DownloadActionType.DOWNLOAD,
        resourceType,
        apiCall,
        (action) => action.resourceType,
        trackProgress,
    );
}

export function createCustomActionDownloadableResourceEpic<A extends IDownloadableResourceAction<any>>(
    resourceType: DownloadableResourceType,
    apiCall: (action: A, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) => Observable<AjaxResponse>,
    trackProgress: boolean = true,
) {
    return composeDownloadableResourceEpic<A>(
        DownloadActionType.DOWNLOAD,
        resourceType,
        apiCall,
        (action) => action.resourceType,
        trackProgress,
    );
}

export function composeDownloadableResourceEpic<T extends IDownloadableResourceAction<any>>(
    actionType: T["type"],
    requiredResourceType: DownloadableResourceType,
    apiCall: (action: T, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) => Observable<AjaxResponse>,
    resourceType: (action: T) => DownloadableResourceType,
    trackProgress: boolean = true,
) {
    return (action$: ActionsObservable<T>, $state: StateObservable<IApplicationState>) =>
        action$.ofType(actionType)
            .pipe(
                filter((action) => resourceType(action) === requiredResourceType),
                mergeMap((action) => {
                    const startAction = actionType === DownloadActionType.DOWNLOAD ?
                        getResourceDownloadAlreadyStartedAction(resourceType(action), action.resourceId) :
                        getResourceDownloadAction(resourceType(action), action.resourceId);
                    const progressSubject = new Subject();

                    let progressSubscriber = undefined;

                    if (trackProgress) {
                        progressSubscriber = Subscriber.create<ProgressEvent>((e: ProgressEvent | undefined) => {
                            if (e != null && e.loaded < e.total) {
                                const percentComplete = Math.round((e.loaded / e.total) * 100)
                                progressSubject.next(getResourceDownloadProgressAction(resourceType(action), action.resourceId, percentComplete))
                            }
                        })
                    }

                    const apiCallObservable = apiCall(action, "blob", progressSubscriber).pipe(
                        switchMap((xhr: AjaxResponse) => {
                            return of(getResourceDownloadSuccessfulAction(resourceType(action), action.resourceId, xhr.response, action.resourceFileName as string))
                        }),
                        catchError((error) => of(getResourceDownloadFailedAction(resourceType(action), action.resourceId, "Data se nezdařilo načíst."))),
                        startWith(startAction),
                    )

                    return merge(apiCallObservable, progressSubject);
                }),
            );
}
