import {useEffect, useRef} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import * as d3 from 'd3';
import Constants from "../../../common/Constants";

const MENU_RESIZER_WIDTH = "5px";

export enum ResizeDirection {
    TO_LEFT = "TO_LEFT",
    TO_RIGHT = "TO_RIGHT",
}

interface IMenuResizerProps {
    menuId: string,
    leftOffset: number,
    rightOffset: number,
    resizeDirection: ResizeDirection,
}

const useStyles = makeStyles(
    createStyles({
        root: {
            height: "100%",
            width: MENU_RESIZER_WIDTH,
            cursor: "ew-resize",
            backgroundColor: Constants.MENU_BACKGROUND_COLOR,
        },
    }),
);

function getMenuWidth(elementId: string): number {
    const menuSelection = d3.select("#"+elementId) as d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
    return menuSelection.node()?.getBoundingClientRect().width as number;
}

export default function MenuResizer({menuId, resizeDirection, leftOffset, rightOffset}: IMenuResizerProps) {
    const classes = useStyles();
    const resizerRef = useRef<HTMLDivElement>(null);
    const initialWidth = useRef(0);

    useEffect(() => {
        initialWidth.current = getMenuWidth(menuId);
    }, [menuId])

    useEffect(() => {
        let selection: any = null;
        if (resizerRef.current != null) {
            selection = d3.select(resizerRef.current as Element);
            selection.call(d3.drag()
                .on("drag", function(event,d) {
                    const menuSelection = d3.select("#"+menuId) as d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
                    const menuDomRect = menuSelection.node()?.getBoundingClientRect() as DOMRect;
                    const newWidth = menuDomRect.width + ((resizeDirection === ResizeDirection.TO_RIGHT ? 1 : -1) * event.dx);
                    const leftWidth = Math.max(newWidth, initialWidth.current - leftOffset);
                    const rightWidth = Math.min(newWidth, initialWidth.current + rightOffset);
                    menuSelection.style("width", (newWidth >= initialWidth.current ? rightWidth : leftWidth) + "px");
                })
            );

            return () => selection && selection.on(".drag", null);
        }

    }, [menuId, resizeDirection, resizerRef, leftOffset, rightOffset])

    return <div className={classes.root} ref={resizerRef}>

    </div>
}
