import {Tooltip} from "@mui/material";
import {ReactElement} from "react";

export interface OptionalTooltipProps {
    title?: string,
    disabled?: boolean,
    children: ReactElement;
}

export function OptionalTooltip({title, disabled, children}: OptionalTooltipProps) {
    if (title && !disabled) {
        return (
            <Tooltip title={title}>
                {children}
            </Tooltip>
        );
    } else {
        return (
            <>
                {children}
            </>
        );
    }
}
