import React, {useEffect, useRef, useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import TreeView from '@mui/lab/TreeView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Constants from "../../../../../common/Constants";
import ModelTreeViewItem from "./ModelTreeViewItem";
import {FolderViewType} from "../options/FolderViewType";
import TreeViewItemFactory from "./factory/TreeViewItemFactory";
import Item, {ItemType} from "./factory/Item";
import clsx from "clsx";
import IDiagramEditorApi from "../../../../../common/diagrameditor/api/IDiagramEditorApi";
import {OptionsPanelSelectedItem} from "../../LeftMenu";
import {fromEvent, Subscription} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import * as d3 from 'd3';
import {EventType, IModelUpdatedOnItemsCreatedEvent} from "../../../../../common/event/Event";
import {ElementDefinition} from "../../../../../common/diagrameditor/manager/ElementCreateManager";
import {ArchimateElement} from "../../../../../common/archimate/ArchimateElement";
import {NodeType} from "../../../../../common/apis/diagram/NodeType";
import {RelationshipDto} from "../../../../../common/apis/relationship/RelationshipDto";
import {DiagramDto} from "../../../../../common/apis/diagram/DiagramDto";
import {ElementDto} from "../../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../../../DiagramEditorTranslationKey";
import RenderMode from "../../../../../common/diagrameditor/context/RenderMode";
import TextField from "../../../../fields/textfield/TextField";

declare module 'csstype' {
    interface Properties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        treeview: {
            marginTop: theme.spacing(.5),
            height: 264,
            flexGrow: 1,
            maxWidth: 400,
        },
        itemUsedInNoDiagramText: {
            color: d3.color(Constants.MENU_BACKGROUND_COLOR_DARKER)?.darker(.8).formatRgb(),
            fontStyle: "italic",
        },
        textField: {
            marginTop: theme.spacing(1.5),
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            flexGrow: 1,
            "& *": {
                fontSize: ".92em"
            },
            "& .MuiSvgIcon-root": {
                fontSize: "1.5em"
            }
        },
    }),
);

interface IProps {
    folderViewType: FolderViewType,
    elements: Array<ElementDto>,
    relationships: Array<RelationshipDto>,
    diagrams: Array<DiagramDto>,
    diagramEditorApi: IDiagramEditorApi | undefined,
    getElementById: (id: string) => ElementDto | undefined,
    selectItems: (elementIds: Array<string>, relationshipIds: Array<string>) => void,
    optionsPanelSelectedItem?: OptionsPanelSelectedItem,
    showFilterField: boolean,
    onFilterFieldClosed: () => void,
    mode: RenderMode,
}


// MODEL TREE FUNCTIONALITY

export interface ModelTreeUtils {
    getElementById: (id: string) => ElementDto | undefined,
    getElementNameById: (elementId: string) => string,
    getElementName: (element: ElementDto) => string,
    getElementTypeName: (element: ElementDto) => string,
    getRelationshipNameComponent: (relationhip: RelationshipDto) => any,
    getRelationshipNameTitle: (relationhip: RelationshipDto) => string,
    isRelationshipUsedInAnyDiagram(relationhipId: string): boolean;
    isElementUsedInAnyDiagram(elementId: string): boolean;
}

function createModelTreeUtils(diagramEditorApi: IDiagramEditorApi | undefined): ModelTreeUtils {

    function isRelationshipUsedInAnyDiagram(relationhipId: string): boolean {
        const isUsed = diagramEditorApi?.getModelAccessor().isRelationshipUsedInAnyDiagram(relationhipId);
        return isUsed != null ? isUsed : true;
    }

    function isElementUsedInAnyDiagram(elementId: string): boolean {
        const isUsed = diagramEditorApi?.getModelAccessor().isElementUsedInAnyDiagram(elementId);
        return isUsed != null ? isUsed : true;
    }

    function getElementById(elementId: string) {
        return diagramEditorApi?.getModelAccessor().getElementById(elementId);
    }

    function getElementNameById(elementId: string) {
        const element = getElementById(elementId);
        return element ? getElementName(element) : elementId;
    }

    function getElementName(element: ElementDto): string {
        return element.name || element.type;
    }

    function getRelationshipNameComponent(relationhip: RelationshipDto): any {
        return <span>
            <span style={{marginRight: "20px"}}>{(relationhip.name || relationhip.type)}</span>
            <span>({getElementNameById(relationhip.source.identifier) + " -> " + getElementNameById(relationhip.target.identifier)})</span>
        </span>;
    }

    function getRelationshipNameTitle(relationhip: RelationshipDto): string {
        return `${(relationhip.name || relationhip.type)} (${getElementNameById(relationhip.source.identifier)} -> ${getElementNameById(relationhip.target.identifier)})`;
    }

    function getElementTypeName(element: ElementDto): string {
        return ArchimateElement.findByStandardName(element.type)?.visibleName as string;
    }

    return {
        getElementById: getElementById,
        getElementName: getElementName,
        getElementTypeName: getElementTypeName,
        getElementNameById: getElementNameById,
        getRelationshipNameComponent: getRelationshipNameComponent,
        getRelationshipNameTitle: getRelationshipNameTitle,
        isElementUsedInAnyDiagram: isElementUsedInAnyDiagram,
        isRelationshipUsedInAnyDiagram: isRelationshipUsedInAnyDiagram,
    }
}



// ITEM DRAG FUNCTIONALITY

interface ItemDragStatus {
    draggedItemMouseMoveDone: boolean,
    containerMouseLeaveDone: boolean,
    draggedTarget: HTMLElement,
    draggedTargetInitialCursor: string,
    draggedItem: Item,
}

function cancelItemDrag(event: any,
                        itemDragStatus: React.MutableRefObject<ItemDragStatus | undefined>,
                        itemDragContainer: React.MutableRefObject<HTMLDivElement | null>,
                        diagramEditorApi?: IDiagramEditorApi) {
    if (itemDragStatus.current) {
        resetItemDrag(itemDragStatus, itemDragContainer);
        diagramEditorApi?.onNodeCreateMenuDeactivated(event);
    }
}

function onItemDragMouseMove(event: any,
                             itemDragStatus: React.MutableRefObject<ItemDragStatus | undefined>,
                             itemDragContainer: React.MutableRefObject<HTMLDivElement | null>,
                             diagramEditorApi?: IDiagramEditorApi) {
    if (itemDragStatus.current) {
        const itemDrag = itemDragStatus.current;
        itemDrag.draggedItemMouseMoveDone = true;
        itemDrag.draggedTarget.style.cursor = "move";
        if (itemDragContainer.current) {
            itemDragContainer.current.style.cursor = "move";
        }
        const elementDefinition: ElementDefinition = {
            existingElement: {
                id: itemDrag.draggedItem.elementId as string,
            }
        }
        diagramEditorApi?.onNodeCreateMenuActivated(event, NodeType.ELEMENT, elementDefinition);
    }
}

function resetItemDrag(itemDragStatus: React.MutableRefObject<ItemDragStatus | undefined>,
                       itemDragContainer: React.MutableRefObject<HTMLDivElement | null>) {
    if (itemDragStatus.current) {
        const itemDrag = itemDragStatus.current;
        itemDrag.draggedTarget.style.cursor = itemDrag.draggedTargetInitialCursor;
        itemDragStatus.current = undefined;
        if (itemDragContainer.current) {
            itemDragContainer.current.style.cursor = "auto";
        }
    }
}

function onItemDragMouseDown(item: Item,
                             target: HTMLElement,
                             itemDragStatus: React.MutableRefObject<ItemDragStatus | undefined>) {
    itemDragStatus.current = {
        draggedItem: item,
        draggedTarget: target,
        draggedTargetInitialCursor: target.style.cursor,
        draggedItemMouseMoveDone: false,
        containerMouseLeaveDone: false,
    }
}

function onItemDragMouseUp(event: any,
                           itemDragStatus: React.MutableRefObject<ItemDragStatus | undefined>,
                           itemDragContainer: React.MutableRefObject<HTMLDivElement | null>,
                           diagramEditorApi?: IDiagramEditorApi) {
    cancelItemDrag(event, itemDragStatus, itemDragContainer, diagramEditorApi);
}

function onItemDragContainerMouseLeave(itemDragStatus: React.MutableRefObject<ItemDragStatus | undefined>) {
    if (itemDragStatus.current) {
        itemDragStatus.current.containerMouseLeaveDone = true;
    }
}

function isDragInProcess(itemDragStatus: React.MutableRefObject<ItemDragStatus | undefined>) {
    return itemDragStatus.current?.draggedItemMouseMoveDone === true;
}



// COMPONENT

export default function ModelTreeView(props: IProps) {
    const {folderViewType, elements, relationships, diagrams, diagramEditorApi, optionsPanelSelectedItem,
        showFilterField, onFilterFieldClosed} = props;
    const classes = useStyles();

    // refs
    const selectedItemTypes = useRef<{[id: string]: ItemType}>({});
    const itemParents = useRef<{[id: string]: string}>({});
    const itemDragStatus = useRef<ItemDragStatus>();
    const itemDragContainer = useRef<HTMLDivElement>(null);
    const filterFieldRef = useRef<HTMLInputElement>();
    const field = filterFieldRef.current;

    // state
    const [selectedItemIds, setSelectedItemIds] = useState<Array<string>>([]);
    const [expandedItemIds, setExpandedItemIds] = useState<Array<string>>(["model"]);
    const [useFilterField, setUseFilterField] = useState<boolean>(false);
    const [filterFieldText, setFilterFieldText] = useState<string>("");

    // effects
    useEffect(() => {
        let subscription: Subscription;
        if (field) {
            subscription = fromEvent(field, "keyup")
                .pipe(
                    map(event => (event?.target as HTMLInputElement).value as string),
                    debounceTime(300),
                )
                .subscribe({
                    next: (value) => setFilterFieldText(value),
                });
        }
        return () => subscription && subscription.unsubscribe();
    }, [field]);

    useEffect(() => {
        setUseFilterField(showFilterField != null ? showFilterField : false);
        if (filterFieldRef.current) {
            filterFieldRef.current.value = "";
        }
        if (!showFilterField) {
            setFilterFieldText("");
        }
    }, [showFilterField]);

    useEffect(() => {
        if (optionsPanelSelectedItem) {
            const parentId = itemParents.current[optionsPanelSelectedItem.id];
            putSelectedItemType(optionsPanelSelectedItem.id, optionsPanelSelectedItem.type);
            setExpandedItemIds(["model", parentId, optionsPanelSelectedItem.id]);
            setSelectedItemIds([optionsPanelSelectedItem.id]);
        }
    }, [optionsPanelSelectedItem])

    useEffect(() => {
        const elementIds = selectedItemIds.filter((itemId: string) => selectedItemTypes.current[itemId] === ItemType.ELEMENT);
        const relationshipIds = selectedItemIds.filter((itemId: string) => selectedItemTypes.current[itemId] === ItemType.RELATIONSHIP);
        diagramEditorApi?.selectItems(elementIds, relationshipIds);
    }, [selectedItemIds, diagramEditorApi]);

    useEffect(() => {
        const itemsCreatedUnsubscriber = diagramEditorApi?.addModelUpdatedOnItemsCreatedListener(
            (event: IModelUpdatedOnItemsCreatedEvent) => {
                if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_CREATED) {
                    resetItemDrag(itemDragStatus, itemDragContainer);
                }
            }
        );

        return () => {
            itemsCreatedUnsubscriber?.();
        }
    }, [diagramEditorApi]);



    const utils = createModelTreeUtils(diagramEditorApi);

    const lowerCaseTrimmedFilterText = filterFieldText?.trim().toLowerCase();
    const nonEmptyItems = TreeViewItemFactory.getItems(folderViewType, elements, relationships, diagrams, utils)
        .map(folder => {
            if (lowerCaseTrimmedFilterText) {
                folder.children = folder.children.filter(child => {
                    if (child.labelTitle) {
                        return child.labelTitle.toLowerCase().indexOf(lowerCaseTrimmedFilterText) !== -1;
                    } else if (child.labelText) {
                        return child.labelText.toLowerCase().indexOf(lowerCaseTrimmedFilterText) !== -1;
                    } else {
                        return false;
                    }
                });
            }
            return folder;
        })
        .filter(item => item.children.length > 0);

    function putSelectedItemType(id: string, type: ItemType) {
        const map = selectedItemTypes.current;
        map[id] = type;
        selectedItemTypes.current = map;
    }

    function getClassNames(item: Item) {
        let itemUsedInNoDiagram = false;
        if (item.itemType === ItemType.RELATIONSHIP) {
            itemUsedInNoDiagram = !utils.isRelationshipUsedInAnyDiagram(item.nodeId);
        } else if (item.itemType === ItemType.ELEMENT) {
            itemUsedInNoDiagram = !utils.isElementUsedInAnyDiagram(item.nodeId);
        }

        return clsx({[classes.itemUsedInNoDiagramText]: itemUsedInNoDiagram})
    }

    function putItemParent(id: string, parentId: string) {
        const map = itemParents.current;
        map[id] = parentId;
        itemParents.current = map;
    }

    const onContainerMouseLeave = (event: any) => onItemDragContainerMouseLeave(itemDragStatus);
    const onContainerMouseUp = (event: any) => onItemDragMouseUp(event, itemDragStatus, itemDragContainer, diagramEditorApi);

    return (
        <div style={{minHeight: "95%"}} onMouseLeave={onContainerMouseLeave} onMouseUp={onContainerMouseUp} ref={itemDragContainer}>

            <div style={{display: useFilterField ? "flex" : "none"}}>
                <TextField
                    inputRef={filterFieldRef}
                    className={classes.textField}
                    placeholder={_transl(DiagramEditorTranslationKey.MODEL_TREE_FILTER)}
                    value={filterFieldText}
                    type={"text"}
                    onClearButtonClick={onFilterFieldClosed}
                    clearable
                    autoComplete={"off"}
                />
            </div>
            <TreeView
                className={classes.treeview}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
                multiSelect={true}
                onNodeToggle={(changeEvent, items) => {
                    setExpandedItemIds(items);
                }}
                onNodeSelect={(changeEvent, items) => {
                    const selectedItems = [];
                    if (items != null) {
                        if (Array.isArray(items)) {
                            selectedItems.push(...items);
                        } else {
                            selectedItems.push(items);
                        }
                    }
                    setSelectedItemIds(selectedItems);
                }}
                expanded={expandedItemIds}
                selected={selectedItemIds}
            >
                {nonEmptyItems.map(item => {
                        return <ModelTreeViewItem nodeId={item.nodeId}
                                                  labelText={item.labelText}
                                                  labelTitle={item.labelTitle}
                                                  labelIcon={item.labelIcon}
                                                  labelIconColor={item.labelIconColor}
                                                  labelIconBgColor={item.labelIconBgColor}
                                                  className={getClassNames(item)}
                                                  onClick={(event) => {
                                                      putSelectedItemType(item.nodeId, item.itemType);
                                                  }}
                                                  key={item.nodeId}>
                            {item.children.map(child => {
                                    putItemParent(child.nodeId, item.nodeId);
                                    return <ModelTreeViewItem nodeId={child.nodeId}
                                                              labelText={child.labelText}
                                                              labelTitle={child.labelTitle}
                                                              labelIcon={child.labelIcon}
                                                              labelIconColor={child.labelIconColor}
                                                              labelIconBgColor={child.labelIconBgColor}
                                                              className={getClassNames(child)}
                                                              onClick={(event) => {
                                                                  putSelectedItemType(child.nodeId, child.itemType);
                                                              }}
                                                              onMouseDown={(event) => {
                                                                  if (props.mode === RenderMode.EDIT) {
                                                                      if (child.itemType === ItemType.ELEMENT && !isDragInProcess(itemDragStatus)) {
                                                                          onItemDragMouseDown(child, event.target as HTMLElement, itemDragStatus);
                                                                      }
                                                                  }
                                                              }}
                                                              onMouseMove={(event) => {
                                                                  if (props.mode === RenderMode.EDIT) {
                                                                      if (child.itemType === ItemType.ELEMENT && !isDragInProcess(itemDragStatus)) {
                                                                          onItemDragMouseMove(event, itemDragStatus, itemDragContainer, diagramEditorApi);
                                                                      }
                                                                  }
                                                              }}
                                                              key={child.nodeId}
                                    />
                                }
                            )}
                        </ModelTreeViewItem>
                    }
                )}
            </TreeView>
        </div>
    );
}
