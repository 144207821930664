export enum ElementTranslationKey {
    GRID_HEADER_TYPE = "elements.grid.header.type",
    GRID_HEADER_NAME = "elements.grid.header.name",
    GRID_HEADER_IDENTIFIER = "elements.grid.header.identifier",
    GRID_HEADER_STEREOTYPE = "elements.grid.header.stereotype",
    GRID_HEADER_VALID_FROM = "elements.grid.header.validFrom",
    GRID_HEADER_VALID_THRU = "elements.grid.header.validThru",
    GRID_HEADER_STATE = "elements.grid.header.state",
    GRID_HEADER_COLLECTIONS = "elements.grid.header.collections",
    GRID_HEADER_LABELS = "elements.grid.header.labels",
    GRID_HEADER_LAST_COMMENTED_ON = "elements.grid.header.lastCommentedOn",
    GRID_HEADER_ATTACHMENT_COUNT = "elements.grid.header.attachmentCount",
    GRID_HEADER_DATE_CREATED = "elements.grid.header.dateCreated",
    GRID_HEADER_DATE_UPDATED = "elements.grid.header.dateUpdated",
    GRID_HEADER_USER_CREATED = "elements.grid.header.userCreated",
    GRID_HEADER_USER_UPDATED = "elements.grid.header.userUpdated",
    GRID_HEADER_DESCRIPTION = "elements.grid.header.description",
    GRID_HEADER_READ_WRITE = "elements.grid.header.readWrite",

    FILTER_SEARCH = "elements.filter.search",
    FILTER_SEARCH_PLACEHOLDER = "elements.filter.search.placeholder",
    FILTER_IDENTIFIER = "elements.filter.identifier",
    FILTER_STEREOTYPE = "elements.filter.stereotype",
    FILTER_TYPES = "elements.filter.types",
    FILTER_NAME = "elements.filter.name",
    FILTER_LABELS = "elements.filter.labels",
    FILTER_COLLECTIONS = "elements.filter.collections",
    FILTER_STATES = "elements.filter.states",
    FILTER_AUTHOR = "elements.filter.author",
    FILTER_VALID_TO_DATE = "elements.filter.validToDate",

    ALERT_DIALOG_REMOVE_BULK_LABELS_TITLE = "elements.alertDialog.removeBulkLabels.title",
    ALERT_DIALOG_REMOVE_BULK_LABELS_TEXT = "elements.alertDialog.removeBulkLabels.text",
    ALERT_DIALOG_REMOVE_BULK_COLLECTIONS_TITLE = "elements.alertDialog.removeBulkCollections.title",
    ALERT_DIALOG_REMOVE_BULK_COLLECTIONS_TEXT = "elements.alertDialog.removeBulkCollections.text",
    ACTION_BUTTON_BULK_LABEL_ASSIGNMENT_TITLE = "elements.actionButton.bulkLabelAssignment.title",
    ACTION_BUTTON_BULK_LABEL_REMOVAL_TITLE = "elements.actionButton.bulkLabelRemoval.title",
    ACTION_BUTTON_BULK_COLLECTION_ASSIGNMENT_TITLE = "elements.actionButton.bulkCollectionAssignment.title",
    ACTION_BUTTON_BULK_COLLECTION_REMOVAL_TITLE = "elements.actionButton.bulkCollectionRemoval.title",
    DIALOG_ELEMENT_BINDING_MESSAGE = "elements.dialog.elementBinding.message",
    ASSIGNED_LABELS_TITLE = "elements.assignedLabels.title",
    ASSIGNED_COLLECTIONS_TITLE = "elements.assignedCollections.title",
    LABELS_TO_REMOVE_TITLE = "elements.labelsToRemove.title",
    COLLECTIONS_TO_REMOVE_TITLE = "elements.collectionsToRemove.title",
    ALERT_DIALOG_MERGE_TITLE = "elements.alertDialog.merge.title",
    ALERT_DIALOG_MERGE_TEXT = "elements.alertDialog.merge.text",
    ELEMENT_ADDED_MESSAGE = "elements.elementAdded.message",
    COLLECTIONS_AVAILABLE_OPTIONS_TITLE = "elements.collectionsAvailableOptions.title",
    LABELS_AVAILABLE_OPTIONS_TITLE = "elements.labelsAvailableOptions.title",
    URL_COPIED = "elements.urlCopied",
    ACTION_BUTTON_ADD_ELEMENT = "elements.actionButton.addElement",
    ACTION_BUTTON_REMOVE_ELEMENT = "elements.actionButton.removeElement",
    ACTION_BUTTON_BULK_LABEL_ACTIONS = "elements.actionButton.bulkLabelActions",
    ACTION_BUTTON_BULK_LABEL_ACTIONS_ASSIGNMENT = "elements.actionButton.bulkLabelActions.labelAssignment",
    ACTION_BUTTON_BULK_LABEL_ACTIONS_REMOVAL = "elements.actionButton.bulkLabelActions.labelRemoval",
    ACTION_BUTTON_BULK_COLLECTION_ACTIONS = "elements.actionButton.bulkCollectionActions",
    ACTION_BUTTON_BULK_COLLECTION_ACTIONS_ASSIGNMENT = "elements.actionButton.bulkCollectionActions.collectionAssignment",
    ACTION_BUTTON_BULK_COLLECTION_ACTIONS_REMOVAL = "elements.actionButton.bulkCollectionActions.collectionRemoval",
    ACTION_BUTTON_CREATE_DIAGRAM = "elements.actionButton.createDiagram",
    ACTION_BUTTON_MERGE_ELEMENTS = "elements.actionButton.mergeElements",
    ACTION_BUTTON_SHOW_NEIGHBOURHOOD_GRAPH = "elements.actionButton.showNeighbourhoodGraph",
    ACTION_BUTTON_SHOW_RELATIONSHIPS = "elements.actionButton.showRelationships",
    ACTION_BUTTON_CHANGE_ELEMENT_TYPE = "elements.actionButton.changeElementType",
    ACTION_BUTTON_ADD_RELATIONSHIP = "elements.actionButton.addRelationship",
    ACTION_BUTTON_EXPORT_FILTERED_ELEMENTS = "elements.actionButton.exportFilteredElements",
    GRAPH_OF_NEIGHBOURHOOD = "elements.graphOfNeighbourhood",
    GRAPH_OF_RELATIONSHIPS = "elements.graphOfRelationships",
    CONFIRMATION_DIALOG_BULK_DELETE_TITLE = "elements.confirmationDialog.bulkDelete.title",
    CONFIRMATION_DIALOG_DELETE_TITLE = "elements.confirmationDialog.delete.title",
    CONFIRMATION_DIALOG_BULK_DELETE_CONFIRMATION_TEXT = "elements.confirmationDialog.bulkDelete.confirmationText",
    CONFIRMATION_DIALOG_DELETE_CONFIRMATION_TEXT = "elements.confirmationDialog.delete.confirmationText",
    BULK_REMOVAL_SUCCEED_TEXT = "elements.bulkRemovalSucceed.text",
    REMOVAL_SUCCEED_TEXT = "elements.removalSucceed.text",
    BULK_REMOVAL_FAILED_TEXT = "elements.bulkRemovalFailed.text",
    REMOVAL_FAILED_TEXT = "elements.removalFailed.text",
    BULK_LABEL_ASSIGNMENT_FAILED_TEXT = "elements.bulkLabelAssignmentFailed.text",
    BULK_LABEL_REMOVAL_FAILED_TEXT = "elements.bulkLabelRemovalFailed.text",
    BULK_COLLECTION_ASSIGNMENT_FAILED_TEXT = "elements.bulkCollectionAssignmentFailed.text",
    BULK_COLLECTION_REMOVAL_FAILED_TEXT = "elements.bulkCollectionRemovalFailed.text",
    EXPORT_FAILED_TEXT = "elements.exportFailed.text",
    EXPORT = "elements.export",

    CUSTOM_PROPERTY_COLUMN_ADD_DIALOG_COLUMN_ALREADY_EXISTS = "elements.customPropertyColumnAddDialog.columnAlreadyExists",
    CUSTOM_PROPERTY_COLUMN_ADD_DIALOG_NAME = "elements.customPropertyColumnAddDialog.attributeName",
    CUSTOM_PROPERTY_COLUMN_ADD_DIALOG_TITLE = "elements.customPropertyColumnAddDialog.title",

    DIALOG_CREATE_ELEMENT_SUCCEEDED = "elements.createElement.succeeded.message",
    DIALOG_CREATE_ELEMENT_NOT_SUCCEEDED = "elements.createElement.notSucceeded.message",
    DIALOG_CREATE_ELEMENT_FIELD_NAME = "elements.createElement.field.name",
    DIALOG_CREATE_ELEMENT_FIELD_TYPE = "elements.createElement.field.type",
    DIALOG_CREATE_ELEMENT_FIELD_DESCRIPTION = "elements.createElement.field.description",

    DIALOG_MERGE_ELEMENTS_NOT_SELECTED_TITLE = "elements.mergeElementsDialog.notSelected.title",
    DIALOG_MERGE_ELEMENTS_NOT_SELECTED_MESSAGE = "elements.mergeElementsDialog.notSelected.message",
    DIALOG_MERGE_ELEMENTS_MERGE_NOT_SUCCESSFUL = "elements.mergeElementsDialog.mergeNotSuccessful",
    DIALOG_MERGE_ELEMENTS_CHOOSING_TARGET = "elements.mergeElementsDialog.target",
    DIALOG_MERGE_ELEMENTS_MERGING_OF_ELEMENTS = "elements.mergeElementsDialog.mergingOfElements",
    DIALOG_MERGE_ELEMENTS_MERGE_ELEMENTS = "elements.mergeElementsDialog.mergeElements",

    DIALOG_CHANGE_ELEMENT_TYPE_MESSAGE = "elements.changeElementTypeDialog.message",
    DIALOG_CHANGE_ELEMENT_TYPE_SUCCEEDED = "elements.changeElementTypeDialog.succeeded",
    DIALOG_CHANGE_ELEMENT_TYPE_NOT_SUCCEEDED = "elements.changeElementTypeDialog.notSucceeded",
    DIALOG_CHANGE_ELEMENT_TYPE_ELEMENT_TYPE = "elements.changeElementTypeDialog.elementType",

    ELEMENT_PICK_DIALOG_TITLE = "elements.elementPickDialog.title",
    ELEMENT_PICK_DIALOG_SAVE = "elements.elementPickDialog.save",

    ELEMENTS_TITLE = "elements.title",

    ERROR_MESSAGE_TEXT = "elements.deleteConfirmationInfoDialog.errorMessage",
    RELATIONSHIP_COUNT_TEXT = "elements.deleteConfirmationInfoDialog.relationshipCountText",
    DIAGRAMS_COUNT_TEXT = "elements.deleteConfirmationInfoDialog.diagramsCountText",

    ELEMENTS_FETCH_ERROR = "elements.fetch.error",

    ELEMENTS_DELETE_DATE_NOT_SUCCEEDED = "elements.deleteDate.notSucceeded"
}
