import {Model} from "../model/Model";

export class DiagramBackup {

    constructor(private _diagramId: string,
                private _diagramModel: Model,
                private _date: Date | string,
                private _login:  string) {
    }

    public getDiagramId() {
        return this._diagramId;
    }

    public getDiagramModel() {
        return this._diagramModel;
    }

    public getDate(): Date | string {
        return this._date;
    }

    public setDate(date: Date | string): void {
        this._date = date;
    }

    public getLogin(): string {
        return this._login;
    }

    static from(backup: DiagramBackup) {
        return new DiagramBackup(backup._diagramId, backup._diagramModel, backup._date, backup._login);
    }
}
