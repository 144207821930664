import React, {useEffect, useState} from "react";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {PersistentStateId} from "../../../../store/common/Grid";
import {PagePresetsTranslationKey} from "./PagePresetsTranslationKey";
import {PagePresetsDto} from "./client/PagePresetsDto";
import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import {CommonTranslation} from "../CommonTranslation";
import Snackbar from "../../../main/content/snackbar/Snackbar";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import pagePresetsClient from "./client/PagePresetsClient";
import {UserInfoDto} from "../../../../common/apis/user/UserInfoDto";
import UserFormatter from "../users/UserFormatter";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {Button} from "../../../../components/button/Button";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

interface PagePresetsSelectDialogProps {
    gridId: string;
    open: boolean;
    onPresetsSelected: (selectedPresets: PagePresetsDto) => void;
    onClose: () => void;
}

export default function PagePresetsSelectDialog(props: PagePresetsSelectDialogProps) {

    const {gridId, open, onPresetsSelected, onClose} = props;
    const [availablePresets, setAvailablePresets] = useState<PagePresetsDto[]>([]);
    const [selectedPresets, setSelectedPresets] = useState<PagePresetsDto>();

    useEffect(() => {
        if (open) {
            setSelectedPresets(undefined);
            pagePresetsClient.findAllPresets(gridId)
                    .then(availablePresets => setAvailablePresets(availablePresets.filter(presets => !presets.isPrivate)))
                    .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA, err)));
        }
    }, [gridId, open]);

    const columns: GridColDef<PagePresetsDto>[] = [
        {
            field: 'name', headerName: _transl(CommonTranslation.TITLE), width: 250
        },
        {
            field: 'description', headerName: _transl(CommonTranslation.DESCRIPTION), width: 300
        },
        {
            field: 'isPrivate',
            headerName: _transl(CommonTranslation.PRIVATE),
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                switch (params.value) {
                    case true:
                        return _transl(CommonTranslation.YES);
                    case false:
                        return _transl(CommonTranslation.NO);
                    default:
                        return "";
                }
            }
        },
        {
            field: 'user',
            headerName: _transl(CommonTranslation.USER),
            width: 200,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (!params.value) {
                    return "";
                } else {
                    const user = params.value as UserInfoDto;
                    return UserFormatter.formatFullName(user.firstName, user.lastName);
                }
            }
        },
    ];

    function onSelectClicked() {
        if (selectedPresets) {
            onPresetsSelected(selectedPresets);
            onClose();
        }
    }

    return (
        <>
            <Dialog
                    open={open}
                    aria-labelledby="grid-presets-select-dialog"
                    onClose={onClose}
                    fullWidth={true}
                    maxWidth={"md"}
            >
                <DialogTitle
                        id="grid-presets-select-dialog"
                        title={_transl(PagePresetsTranslationKey.PRESETS_SELECTION)}
                        onDialogClosed={onClose}
                />
                <DialogContent>
                    <ExtGridWrapper
                            rows={availablePresets}
                            rowCount={availablePresets.length}
                            columns={columns}
                            getRowId={row => row.id}
                            disableMultipleSelection={true}
                            onSelectionChanged={(selectedRowIds, selectedRows) => setSelectedPresets(selectedRows[0] as PagePresetsDto)}
                            peristentStateId={PersistentStateId.PRESETS_GRID}
                            resourceId={"page-presets-selection"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button label={_transl(CommonTranslation.CHOOSE)}
                            disabled={selectedPresets === undefined}
                            variant={"contained"}
                            onClick={onSelectClicked}/>
                </DialogActions>
            </Dialog>
        </>
    );
}
