import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React from "react";
import {IUploadableResourceState} from "../../../store/common/UploadableResource";
import Divider from "@mui/material/Divider";
import FileUtils from "../../../common/FileUtils";
import IconResolver from "../../../common/IconResolver";
import {Chip} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import {_transl} from "../../../store/localization/TranslMessasge";
import {AttachmentTranslationKey} from "../AttachmentTranslationKey";

const itemPadding = 3;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        item: {
            display: "flex",
            alignItems: "center",
            paddingLeft: 0,
            padding: theme.spacing(itemPadding),
        },
        chip: {
            color: "white",
            backgroundColor: theme.palette.success.main,
            "&:hover": {
                backgroundColor: theme.palette.success.main,
            },
            marginLeft: "auto",
        },
        icon: {
            fontSize: "2.5em",
            marginRight: theme.spacing(1),
        },
        fileName: {
            fontSize: "1.2em",
            marginRight: theme.spacing(2),
        }
    })
);

interface Props {
    item: IUploadableResourceState,
    onDownloadedItemRemoval?: (item: IUploadableResourceState) => void,
}

export default function SuccessfulUpload({item, onDownloadedItemRemoval}: Props) {

    const classes = useStyles();
    const file = item.resource as File;

    return <div className={classes.root}>
        <div className={classes.item}>
                <span>
                    {IconResolver.resolveIcon(file.name, file.type, {className: classes.icon})}
                </span>
            <span className={classes.fileName}>
                    {item.resource?.name}
                </span>
            <span>
                    {FileUtils.createFileSizeLabel(file.size)}
                </span>
            <Chip
                icon={<DoneIcon fontSize={"small"} style={{color: "white"}}/>}
                label={_transl(AttachmentTranslationKey.UPLOAD_SUCCEEDED)}
                clickable
                onDelete={onDownloadedItemRemoval}
                className={classes.chip}
            />
        </div>
        <Divider />
    </div>
}
