import EventManager, {Unsubscriber} from "../../event/EventManager";
import RenderContext from "../context/RenderContext";
import {
    EventProperty,
    EventType,
    IChartEvent,
    IModelUpdatedOnConnectionCreatedEvent,
    IModelUpdatedOnItemsRemovedEvent,
    INodeEvent,
    INodeResizeEvent,
    NodeSyntheticAttributeUpdatedEvent
} from "../../event/Event";
import NodeRendererUtils from "../renderer/node/NodeRendererUtils";
import {DiagramEditorUtils, SELECTED_NODE_GROUP_STROKE_WIDTH} from "../util/DiagramEditorUtils";
import {Area, Point} from "../util/GeometryUtils";
import * as d3 from 'd3';
import ConnectionRendererUtils from "../renderer/connection/ConnectionRendererUtils";
import {ModelManager} from "./ModelManager";
import {ArchiMateAccessType, ArchimateRelationshipType} from "../../archimate/ArchimateRelationship";
import ConnectionHandle from "./connectioncreate/ConnectionHandle";
import {HANDLE_FILL_COLOR} from "../common/UIConstants";
import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";
import {IEditMode} from "../editor/IEditMode";
import {DiagramNode} from "../model/Model";
import {ElementAclDto} from "../../apis/element/ElementAclDto";
import {ArchimateElement} from "../../archimate/ArchimateElement";
import MetamodelManager from "./MetamodelManager";

enum Status {
    NOT_STARTED,
    FIRST_ELEMENT_SELECTION_STARTED,
    SECOND_ELEMENT_SELECTION_STARTED,
    CONNECTION_TYPE_SELECTION_STARTED
}

export interface NewConnectionDefinition {
    connectionIdentifier: string,
    existingRelationship?: {
        identifier: string
    },
    newRelationship?: {
        identifier: string,
        type: ArchimateRelationshipType,
        accessType?: ArchiMateAccessType,
        directed?: boolean,
    }
}

export default class ConnectionCreateManager {

    private eventManager: EventManager;
    private renderContext?: RenderContext;
    private metamodelManager: MetamodelManager;

    private connectionCreateStatus: Status;
    private firstSelectedNode?: IDiagramNodeDto;
    private secondSelectedNodeCandidate?: IDiagramNodeDto;
    private secondSelectedNode?: IDiagramNodeDto;
    private connectionCreateHandle: ConnectionHandle;
    private forbiddenNodes?: Array<DiagramNode>;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener<INodeEvent>(EventType.NODE_MOUSE_ENTER, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<INodeEvent>(EventType.NODE_MOUSE_LEAVE, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<INodeEvent>(EventType.NODE_MOUSE_UP, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<INodeEvent>(EventType.NODE_CONNECTION_CREATE_BY_HANDLE_ACTIVATED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<INodeEvent>(EventType.NODE_CONNECTION_CREATE_BY_HANDLE_IN_PROGRESS, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<INodeEvent>(EventType.NODE_CONNECTION_CREATE_BY_HANDLE_FINISHED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<INodeEvent>(EventType.NODE_CONNECTION_CREATE_BY_HANDLE_CANCELLED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<IChartEvent>(EventType.CHART_MOUSE_MOVE, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<IChartEvent>(EventType.CHART_MOUSE_UP, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<IModelUpdatedOnConnectionCreatedEvent>(EventType.MODEL_UPDATED_ON_CONNECTION_CREATED, this.handleModelUpdatedOnConnectionCreatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<INodeEvent>(EventType.NODE_MOVE_STARTED, this.handleNodeMoveStartedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<INodeResizeEvent>(EventType.NODE_RESIZE_STARTED, this.handleNodeResizeStartedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_REMOVED, this.handleModelUpdatedOnItemsNodesRemovedEvent.bind(this)));

        this.metamodelManager = new MetamodelManager();

        this.connectionCreateHandle = new ConnectionHandle();
        this.connectionCreateStatus = Status.NOT_STARTED;
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        this.connectionCreateHandle.init(renderContext, this.eventManager);
    }

    handleNodeEvent(event: INodeEvent) {
        if (this.connectionCreateStatus === Status.NOT_STARTED) {
            this.handleConnectionCreateByHandleEvent(event);
        } else if (this.connectionCreateStatus === Status.FIRST_ELEMENT_SELECTION_STARTED ||
            this.connectionCreateStatus === Status.SECOND_ELEMENT_SELECTION_STARTED)
        {
            this.handleProgressingConnectionCreateByHandleEvent(event);
            this.handleElementSelection(event);
        }
    }

    private handleModelUpdatedOnItemsNodesRemovedEvent(event: IModelUpdatedOnItemsRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_REMOVED) {
            ConnectionHandle.removeHandles();
            ConnectionHandle.removeHandleRemoveUnderlay();
        }
    }

    private handleProgressingConnectionCreateByHandleEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_CONNECTION_CREATE_BY_HANDLE_IN_PROGRESS) {
            const point = new Point(event.event[EventProperty.TRANSFORMED_X_COORDINATE], event.event[EventProperty.TRANSFORMED_Y_COORDINATE]);
            this.updateConnectionPathOnMouseMove(event, point);
        } else if (event.type === EventType.NODE_CONNECTION_CREATE_BY_HANDLE_FINISHED) {
            if (this.secondSelectedNodeCandidate == null) {
                this.eventManager.publishEvent({type: EventType.NODE_CONNECTION_CREATE_BY_HANDLE_CANCELLED, event: event.event});
            } else {
                this.onSecondElementSelected(this.secondSelectedNodeCandidate, new Point(event.event.sourceEvent.clientX, event.event.sourceEvent.clientY));
            }
        } else if (event.type === EventType.NODE_CONNECTION_CREATE_BY_HANDLE_CANCELLED) {
            this.onCancel(event);
        }
    }

    private handleConnectionCreateByHandleEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_CONNECTION_CREATE_BY_HANDLE_ACTIVATED) {
            this.startConnectionCreateByHandle(event);
        } else if (event.type === EventType.NODE_MOUSE_ENTER) {
            this.handleConnectionCreateHandleVisibility(event);
        }
    }

    private startConnectionCreateByHandle(event: INodeEvent) {
        this.startElementSelection(event.node);
        this.updateForbiddenNodes(event.node);
    }

    private startElementSelection(node: IDiagramNodeDto) {
        if (this.renderContext) {
            this.clean();
            this.connectionCreateHandle.onConnectionCreateStarted();
            this.onFirstElementSelected(node);
            this.connectionCreateStatus = Status.SECOND_ELEMENT_SELECTION_STARTED;

            this.renderContext.svgElementManager.getSvgSelection()
                .attr("cursor", "crosshair");
        }
    }

    private handleConnectionCreateHandleVisibility(event: INodeEvent) {
        if (event.type === EventType.NODE_MOUSE_ENTER) {
            this.connectionCreateHandle.showHandles(event.node);
        }
    }

    private handleElementSelection(event: any) {
        const node = event.node;

        if (event.type === EventType.NODE_MOUSE_ENTER) {
            if (!this.isForbiddenNode(node)) {
                NodeRendererUtils.setNodePointerEventsRectStroke(node, HANDLE_FILL_COLOR, SELECTED_NODE_GROUP_STROKE_WIDTH);
                if (this.connectionCreateStatus === Status.SECOND_ELEMENT_SELECTION_STARTED) {
                    this.secondSelectedNodeCandidate = node;
                }
            }
        }
        if (event.type === EventType.NODE_MOUSE_LEAVE) {
            if (event.node !== this.firstSelectedNode) {
                NodeRendererUtils.removeNodePointerEventsRectStroke(node);
            }
            if (this.connectionCreateStatus === Status.SECOND_ELEMENT_SELECTION_STARTED) {
                this.secondSelectedNodeCandidate = undefined;
            }
        }
        if (event.type === EventType.NODE_MOUSE_UP) {
            if (this.connectionCreateStatus === Status.SECOND_ELEMENT_SELECTION_STARTED) {
                this.onSecondElementSelected(event.node, new Point((event.event as any).clientX, (event.event as any).clientY));
            }

        }
    }

    handleChartEvent(event: IChartEvent) {
        if (this.connectionCreateStatus === Status.SECOND_ELEMENT_SELECTION_STARTED)
        {
            if (event.type === EventType.CHART_MOUSE_MOVE) {
                this.updateConnectionPathOnMouseMove(event, this.convertEventToDiagramGroupPoint(event));
            }
            if (event.type === EventType.CHART_MOUSE_UP && this.secondSelectedNodeCandidate == null) {
                this.eventManager.publishEvent({type: EventType.NODE_CONNECTION_CREATE_BY_HANDLE_CANCELLED, event: event.event});
            }
        }
    }

    private updateConnectionPathOnMouseMove(event: IChartEvent | INodeEvent, targetAreaStartPoint: Point) {
        const sourceBbox = NodeRendererUtils.getNodeOrderGroup(this.firstSelectedNode as IDiagramNodeDto).node()?.getBBox() as DOMRect;
        const sourceArea = new Area(sourceBbox.x, sourceBbox.y, sourceBbox.width, sourceBbox.height);
        let targetArea;
        if (this.secondSelectedNodeCandidate != null) {
            const targetBbox = NodeRendererUtils.getNodeOrderGroup(this.secondSelectedNodeCandidate as IDiagramNodeDto).node()?.getBBox() as DOMRect;
            targetArea = new Area(targetBbox.x, targetBbox.y, targetBbox.width, targetBbox.height);
        } else {
            targetArea = new Area(targetAreaStartPoint.x, targetAreaStartPoint.y, 1, 1);
        }
        const path = ConnectionRendererUtils.createLinePathForAreas(sourceArea, targetArea, []);
        this.updateConnectionPath(path?.path as string);
    }

    handleModelUpdatedOnConnectionCreatedEvent(event: IModelUpdatedOnConnectionCreatedEvent) {
        this.clean();
    }

    private handleNodeMoveStartedEvent(event: INodeEvent) {
        this.connectionCreateHandle.removeHandles();
    }

    private handleNodeResizeStartedEvent(event: INodeResizeEvent) {
        this.connectionCreateHandle.removeHandles();
    }

    private onFirstElementSelected(node: IDiagramNodeDto) {
        NodeRendererUtils.setNodePointerEventsRectStroke(node, HANDLE_FILL_COLOR, SELECTED_NODE_GROUP_STROKE_WIDTH);
        this.firstSelectedNode = node;
        this.connectionCreateStatus = Status.SECOND_ELEMENT_SELECTION_STARTED;
        this.appendConnectionPath(node);

        this.updateForbiddenNodes(node);
    }

    private onSecondElementSelected(node: IDiagramNodeDto, dialogTopLeftPoint: Point) {
        NodeRendererUtils.setNodePointerEventsRectStroke(node, HANDLE_FILL_COLOR, SELECTED_NODE_GROUP_STROKE_WIDTH);
        this.secondSelectedNode = node;
        this.connectionCreateStatus = Status.CONNECTION_TYPE_SELECTION_STARTED;
        this.renderContext?.svgElementManager.getSvgSelection()
            .attr("cursor", "auto");

        const modelManager = this.renderContext?.modelManager as ModelManager;

        const firstNode = this.firstSelectedNode as IDiagramNodeDto;
        const secondNode = this.secondSelectedNode as IDiagramNodeDto;
        const sourceType = this.getReferencedElementType(firstNode, modelManager);
        const targetType = this.getReferencedElementType(secondNode, modelManager);

        let allowedRelationshipTypes = this.findAllowedRelationshipTypes(sourceType, targetType);

        const hiddenRelationships = modelManager.getHiddenRelationshipsBetweenNodes(firstNode, secondNode);

        (this.renderContext?.renderMode as IEditMode).diagramApi.showConnectionTypeSelectionDialog(
            sourceType,
            targetType,
            allowedRelationshipTypes,
            hiddenRelationships,
            dialogTopLeftPoint,
            (definition: NewConnectionDefinition, event: any) => this.createConnection(definition, firstNode, secondNode, event),
            (event: any) => this.onCancel(event));
    }

    private getReferencedElementType(node: IDiagramNodeDto, modelManager: ModelManager): ArchimateElement | undefined {
        const standardName = node.elementIdentifier ? modelManager.getElementById(node.elementIdentifier).type : undefined;
        if (standardName) {
            return ArchimateElement.findByStandardName(standardName) || undefined;
        } else {
            return undefined;
        }
    }

    private findAllowedRelationshipTypes(sourceType: ArchimateElement | undefined, targetType: ArchimateElement | undefined) {
        if (sourceType && targetType) {
            const metamodel = this.renderContext?.modelManager?.getMetamodel();
            return this.metamodelManager.findAllowedRelationshipTypes(sourceType, targetType, metamodel);
        } else {
            return [];
        }
    }

    private convertEventToDiagramGroupPoint(event: any) {
        const diagramGroupNode = this.renderContext?.svgElementManager.getDiagramGroupSelection().node() as SVGGElement;
        const svgNode = this.renderContext?.svgElementManager.getSvg() as SVGSVGElement;

        return DiagramEditorUtils.convertOuterPointToGroup(new Point(event.event.x, event.event.y), diagramGroupNode, svgNode);
    }

    private appendConnectionPath(node: IDiagramNodeDto) {
        if (this.renderContext) {
            this.renderContext.svgElementManager.getDiagramGroupSelection()
                .append("path")
                .raise()
                .attr("id", this.getConnectionPathId())
                .attr("stroke", HANDLE_FILL_COLOR)
                .attr("stroke-width", SELECTED_NODE_GROUP_STROKE_WIDTH)
                .attr("stroke-dasharray", SELECTED_NODE_GROUP_STROKE_WIDTH);
        }
    }

    private updateConnectionPath(path: string) {
        d3.select(this.getConnectionPathId(true))
            .attr("d", path);
    }

    private removeConnectionPath() {
        d3.select(this.getConnectionPathId(true))
            .remove();
    }

    private getConnectionPathId(selector?: boolean) {
        return `${selector === true ? "#" : ""}__diagram-editor-temporary-connection-line-${this.firstSelectedNode?.identifier}`;
    }

    private clean() {
        if (this.firstSelectedNode) {
            NodeRendererUtils.removeNodePointerEventsRectStroke(this.firstSelectedNode);
        }
        if (this.secondSelectedNode) {
            NodeRendererUtils.removeNodePointerEventsRectStroke(this.secondSelectedNode);
        }
        this.removeConnectionPath();
        this.firstSelectedNode = undefined;
        this.secondSelectedNode = undefined;
        this.secondSelectedNodeCandidate = undefined;
        this.connectionCreateStatus = Status.NOT_STARTED;

        this.renderContext?.svgElementManager.getSvgSelection()
            .attr("cursor", "auto");

        this.cleanForbiddenNodes();
    }

    private cleanForbiddenNodes() {
        if (this.forbiddenNodes) {
            for (const node of this.forbiddenNodes) {
                node.forbidden = undefined;
            }
            this.eventManager.publishEvent<NodeSyntheticAttributeUpdatedEvent>({
                type: EventType.NODE_SYNTHETIC_ATTRIBUTE_UPDATED,
                attributeNames: ["forbidden"],
                nodes: this.forbiddenNodes,
            })
            this.forbiddenNodes = undefined;
        }
    }

    private createConnection(definition: NewConnectionDefinition, firstNode: IDiagramNodeDto, secondNode: IDiagramNodeDto, event: any) {
        this.eventManager.publishEvent({type: EventType.CONNECTION_CREATE, connectionDefinition: definition, firstNode: firstNode, secondNode: secondNode, event: event});
    }

    private onCancel(event: any) {
        this.clean();
    }

    private updateForbiddenNodes(selectedNode: DiagramNode) {
        if (this.renderContext?.modelManager && selectedNode.elementIdentifier) {
            const modelManager = this.renderContext.modelManager;
            const selectedElement = modelManager.getElementById(selectedNode.elementIdentifier);
            this.forbiddenNodes = this.collectForbiddenNodes(modelManager, selectedElement.acl);
            for (const forbiddenNode of this.forbiddenNodes) {
                forbiddenNode.forbidden = true;
            }
            if (this.forbiddenNodes) {
                this.eventManager.publishEvent<NodeSyntheticAttributeUpdatedEvent>({
                    type: EventType.NODE_SYNTHETIC_ATTRIBUTE_UPDATED,
                    attributeNames: ["forbidden"],
                    nodes: this.forbiddenNodes,
                })
            }
        }
    }

    private collectForbiddenNodes(modelManager: ModelManager, elementAcl: ElementAclDto) {
        let forbiddenNodes: Array<DiagramNode>;
        if (elementAcl.canRead) {
            if (elementAcl.canUpdate) {
                forbiddenNodes = this.collectReadableNodes(modelManager);
            } else {
                forbiddenNodes = this.collectUpdateableNodes(modelManager);
            }
        } else {
            forbiddenNodes = this.collectAllNodes(modelManager);
        }
        return forbiddenNodes;
    }

    private collectReadableNodes(modelManager: ModelManager) {
        return modelManager.getDiagramNodes()
            .filter(node => {
                const element = node.elementIdentifier ? modelManager.getElementById(node.elementIdentifier) : null;
                return element && !element.acl.canRead;
            });
    }

    private collectUpdateableNodes(modelManager: ModelManager) {
        return modelManager.getDiagramNodes()
            .filter(node => {
                const element = node.elementIdentifier ? modelManager.getElementById(node.elementIdentifier) : null;
                return element && !element.acl.canUpdate;
            });
    }

    private collectAllNodes(modelManager: ModelManager) {
        return modelManager.getDiagramNodes()
            .filter(node => {
                return node.elementIdentifier ? modelManager.getElementById(node.elementIdentifier) : null;
            });
    }

    private isForbiddenNode(node: IDiagramNodeDto) {
        return (this.forbiddenNodes || [])
            .filter(forbiddenNode => forbiddenNode.identifier === node.identifier)
            .length > 0;
    }
}
