import {DiagramBackup} from "./DiagramBackup";

export interface DiagramBackupStorage {
    load(): Map<string, DiagramBackup>,
    save(backups: Map<string, DiagramBackup>): void,
}

export class LocalStorageBasedDiagramBackupStorage implements DiagramBackupStorage {

    private static DIAGRAM_BACKUPS_STORAGE_KEY = "DIAGRAM_BACKUPS";
    
    private storage: Storage;

    constructor(storage: Storage) {
        this.storage = storage;
    }

    load(): Map<string, DiagramBackup> {
        const backups = this.storage.getItem(LocalStorageBasedDiagramBackupStorage.DIAGRAM_BACKUPS_STORAGE_KEY);
        return this.parse(backups || "[]");
    }

    save(backups: Map<string, DiagramBackup>): void {
        this.storage.setItem(LocalStorageBasedDiagramBackupStorage.DIAGRAM_BACKUPS_STORAGE_KEY, this.stringify(backups));
    }

    private stringify(backups: Map<string, DiagramBackup>): string {
        return JSON.stringify(Array.from(backups.entries()));
    }

    private parse(backupsString: string): Map<string, DiagramBackup> {
        const parsedBackups = new Map<string, DiagramBackup>(JSON.parse(backupsString));
        const backups = new Map<string, DiagramBackup>();
        parsedBackups.forEach((backup, diagramId) => {
            backups.set(diagramId, DiagramBackup.from(backup));
        })
        return backups;
    }

}
