import React, {useEffect, useState} from "react";
import {createHierarchyTreeUpdateDto, HierarchyTreeUpdateDto} from "../service/HierarchyTreeUpdateDto";
import {SaveButton} from "../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../components/button/CancelButton";
import {HierarchyTreeTranslationKey} from "../HierarchyTreeTranslationKey";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {HierarchyTreeDataDto} from "../service/HierarchyTreeDataDto";
import {DialogValidationResult} from "./DialogValidationResult";
import {TitleSubformValidator} from "./subforms/title/TitleSubformValidator";
import {HierarchyTreeDataTypeEnum} from "./subforms/HierarchyTreeDataTypeEnum";
import TextField from "../../../../../components/fields/textfield/TextField";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import Grid from "../../../../../components/dialogs/Grid";
import {ValidationResult} from "../../../../../common/validation/ValidationResult";
import hierarchyTreeService from "../service/HierarchyTreeService";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import Snackbar from "../../snackbar/Snackbar";

export interface HierarchyTreeUpdateDialogProps {
    open: boolean;
    treeId?: string;
    onConfirm: (treeId: string, treeUpdate: HierarchyTreeUpdateDto<any>) => void;
    onCancel: () => void;
}

export function HierarchyTreeUpdateDialog({open, treeId, onConfirm, onCancel}: HierarchyTreeUpdateDialogProps) {

    const [treeUpdate, setTreeUpdate] = useState<HierarchyTreeUpdateDto<any>>();
    const [dialogValidationResult, setDialogValidationResult] = useState<DialogValidationResult>();

    useEffect(() => {
        if (open && treeId) {
            hierarchyTreeService.findTree(treeId)
                .then((tree) => {
                    setTreeUpdate(createHierarchyTreeUpdateDto(tree.title, tree.data));
                })
                .catch((err) => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA), err));
        } else {
            setTreeUpdate(undefined);
        }
    }, [open, treeId]);

    function onDialogClosed() {
        onCancel();
    }

    function onDialogSaved() {
        if (isValid()) {
            onConfirm(treeId!, treeUpdate!);
        }
    }

    function isValid() {
        const titleValidator = new TitleSubformValidator();
        const titleValidationResult = titleValidator.validate(treeUpdate!.title);

        const dataType = HierarchyTreeDataTypeEnum.getFromType(treeUpdate!.data.type)!.dataType;
        const dataValidator = HierarchyTreeDataTypeEnum[dataType].validator;
        const dataValidationResult = dataValidator.validate(treeUpdate!.data);

        if (titleValidationResult.errors.length === 0 && dataValidationResult.errors.length === 0) {
            setDialogValidationResult(undefined);
            return true;
        } else {
            setDialogValidationResult({
                titleValidationResult: titleValidationResult,
                dataValidationResult: dataValidationResult,
            })
            return false;
        }
    }

    function onDataTypeSubformUpdated(data: HierarchyTreeDataDto) {
        setTreeUpdate({...treeUpdate!, data});
    }

    function onTitleChanged(title: string) {
        setTreeUpdate({...treeUpdate!, title});
    }

    function getTitleErrorMessage(validationResult?: ValidationResult) {
        if (validationResult && validationResult.errors.length > 0) {
            return validationResult.errors[0].errorMessage;
        } else {
            return undefined;
        }
    }

    const titleErrorMessage = getTitleErrorMessage(dialogValidationResult?.titleValidationResult);

    if (!treeUpdate) {
        return null;
    }

    const dataType = HierarchyTreeDataTypeEnum.getFromType(treeUpdate.data.type)!.dataType;
    const Subform = HierarchyTreeDataTypeEnum[dataType].subform;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            maxWidth={"lg"}
        >
            <DialogTitle
                id="tree-update-dialog-title"
                onDialogClosed={onDialogClosed}
                title={_transl(HierarchyTreeTranslationKey.STRUCTURE_UPDATE_TITLE)}
            />

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <TextField label={_transl(HierarchyTreeTranslationKey.COMMON_SUBFORM_TITLE)}
                                   value={treeUpdate.title}
                                   onChange={(event) => onTitleChanged(event)}
                                   errorMessage={titleErrorMessage ? _transl(titleErrorMessage) : undefined}
                                   maxLength={TitleSubformValidator.TREE_CREATION_TITLE_MAX_LENGTH}
                                   required={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Subform treeId={treeId}
                             data={treeUpdate.data}
                             onChange={(data: HierarchyTreeDataDto) => onDataTypeSubformUpdated(data)}
                             validationResult={dialogValidationResult?.dataValidationResult}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={onDialogSaved}/>
                <CancelButton onClick={onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );
}
