import React from "react";
import MultiselectComboBox from "./MultiselectComboBox";
import {UserDto} from "../../common/apis/user/UserDto";


interface Props {
    options: UserDto[],
    selectedValues: UserDto[],
    className?: string,
    id: string,
    label: string,
    handleOnChange: (changedValues: UserDto[]) => void
}

export default function MultiselectAuthorComboBox(props: Props) {

    const {selectedValues, className, id, label, handleOnChange, options} = props;

    return (
        <MultiselectComboBox options={getSortedOptions(options)}
                             selectedValues={selectedValues}
                             className={className}
                             id={id}
                             label={label}
                             getRenderLabel={(value) => {
                                 return value.login || "";
                             }}
                             handleOnChange={handleOnChange}
        />
    )
}

function getSortedOptions(options: UserDto[]) {
    return options.sort((a, b) => {
        return a.login.localeCompare(b.login);
    });
}

