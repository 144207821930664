import React, {useState} from 'react';
import {Badge, Collapse, Fab} from "@mui/material";
import ChatPinPreview from "./ChatPinPreview";
import ChatAvatarGroup from "./ChatAvatarGroup";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {ChatCommentDto, ChatDto} from "../../common/apis/chat/ChatService";
import UserFormatter from "../../pages/main/content/users/UserFormatter";
import {UserInfoDto} from "../../common/apis/user/UserInfoDto";
import clsx from "clsx";

const CHAT_PIN_Z_INDEX = 10;

const useStyles = (positionLeft: number, positionTop: number) =>
    makeStyles((theme: Theme) =>
        createStyles({
            chatPinPosition: {
                position: "absolute",
                left: positionLeft,
                top: positionTop,
            },
            chatPin: {
                position: "absolute",
                bottom: 0,
                padding: "3px",
                width: "auto",
                height: "auto",
                minWidth: "32px",
                minHeight: "32px",
                borderBottomLeftRadius: 0,
                zIndex: CHAT_PIN_Z_INDEX,
            },
            chatPinPreview: {
                zIndex: CHAT_PIN_Z_INDEX + 1,
            },
            highlighted: {
                borderStyle: "solid",
                borderWidth: "2px",
                borderColor: theme.palette.primary.main
            }
        })
    );

interface ChatPinProps {
    chat: ChatDto;
    lastRead?: Date;
    x: number;
    y: number;
    highlighted?: boolean;
    onClick: (chat: ChatDto) => void
}

export function getUniqueUserFullNames(comments: ChatCommentDto[]): string[] {
    return getUniqueUsers(comments)
        .map((user) => formatFullName(user));
}

function getUniqueUsers(comments: ChatCommentDto[]): UserInfoDto[] {
    const users = comments.map(comment => comment.user);
    const uniqueLogins = new Set();
    return users.filter(user => {
        if (uniqueLogins.has(user.login)) {
            return false;
        } else {
            uniqueLogins.add(user.login);
            return true;
        }
    });
}

function formatFullName(user: UserInfoDto): string {
    return UserFormatter.formatFullName(user.firstName, user.lastName);
}

function computeNumberOfUnreadComments(comments: ChatCommentDto[], lastRead: Date | undefined): number {
    if (!lastRead) {
        return comments.length;
    }
    return comments.filter(comment => isCommentUnread(comment, lastRead)).length;
}

function isCommentUnread(comment: ChatCommentDto, lastRead: Date): boolean {
    return lastRead < new Date(comment.created);
}

export default function ChatPin(props: ChatPinProps) {
    const {chat, lastRead, x, y, highlighted, onClick} = props
    const [hover, setHover] = useState(false);
    const classes = useStyles(x, y)();

    const initialComment = chat.chatComments[0];
    const userNames = getUniqueUserFullNames(chat.chatComments);

    const chatPinClasses = [classes.chatPin];
    if (hover) {
        chatPinClasses.unshift(classes.chatPinPreview);
    }
    if (highlighted) {
        chatPinClasses.unshift(classes.highlighted);
    }

    const numberOfUnreadComments = computeNumberOfUnreadComments(chat.chatComments, lastRead);

    return (
        <>
            <div className={classes.chatPinPosition}>
                <div style={{position: "relative"}}>
                    <Fab key={chat.id}
                         variant={"extended"}
                         color={"default"}
                         size="large"
                         onMouseEnter={() => setHover(true)}
                         onMouseLeave={() => setHover(false)}
                         onClick={() => onClick(chat)}
                         className={clsx(chatPinClasses)}>

                        <Badge color={"error"}
                               badgeContent={numberOfUnreadComments}>
                            <ChatAvatarGroup key={chat.id}
                                             authorNames={userNames}/>

                            <Collapse in={hover} orientation="horizontal" collapsedSize={0}>
                                {hover &&
                                    <ChatPinPreview key={chat.id}
                                                    authorName={formatFullName(initialComment.user)}
                                                    comment={initialComment.message}
                                                    created={new Date(chat.chatComments[0].created)}/>
                                }
                            </Collapse>
                        </Badge>
                    </Fab>
                </div>
            </div>
        </>
    );
}
