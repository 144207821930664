import {Drawer as MuiDrawer, Toolbar, useTheme} from "@mui/material";
import DrawerList, {DrawerMenuModel, DrawerDividerModel, DrawerMenuItemModel} from "./DrawerList";


export interface DrawerProps {
    open: boolean;
    onClose: () => void;
    items: (DrawerMenuItemModel | DrawerMenuModel | DrawerDividerModel)[];
}

export default function Drawer(props: Readonly<DrawerProps>) {
    const {open, onClose, items} = props;

    const theme = useTheme();

    return (
        <MuiDrawer open={open}
                onClose={onClose}
                anchor={"top"}>
            <Toolbar sx={{height: theme.spacing(8)}} />
            <DrawerList onClose={onClose}
                        level={0}
                        items={items} />
        </MuiDrawer>
    );
}