import {ElementDto} from "../../../../../common/apis/element/ElementDto";

export class RelationshipPermissionResolver {

    public checkCanDeleteRelationship(sourceElement: ElementDto, targetElement: ElementDto): boolean {
        if (!sourceElement.acl.canRead || !targetElement.acl.canRead) {
            return false;
        } else if (!sourceElement.acl.canDelete && !targetElement.acl.canDelete) {
            return false;
        }
        return true;
    }

}