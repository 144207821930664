import React from "react";

export default class StickyNoteOutlined extends React.Component<any, any> {
    render() {
        return <svg viewBox={"0 0 24 24"} width={15} height={15}>
            <g fill={"black"}>
                <path d={"M19 5v9h-5v5H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h10l6-6V5c0-1.1-.9-2-2-2zm-7 11H7v-2h5v2zm5-4H7V8h10v2z"} />
            </g>
        </svg>
    }
}