import React from "react";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {CommonTranslation} from "../CommonTranslation";
import {YesButton} from "../../../../components/button/YesButton";
import {NoButton} from "../../../../components/button/NoButton";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogContentText from "../../../../components/dialogs/DialogContentText";
import DialogActions from "../../../../components/dialogs/DialogActions";

interface IProps {
    opened: boolean,
    onNoClicked: () => void,
    onYesClicked: () => void,
    text: string,
}

export default function ActivateUserDialog(props: IProps) {

    const {opened, onYesClicked, onNoClicked, text} = props;

    return (
        <Dialog
            open={opened}
            onClose={() => onNoClicked()}
            aria-labelledby="simple-dialog-title"
        >
            <DialogTitle id="simple-dialog-title" title={_transl(CommonTranslation.WARNING)} onDialogClosed={() => onNoClicked()} />
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <YesButton onClick={() => onYesClicked()} variant={"contained"}/>
                <NoButton onClick={() => onNoClicked()} variant={"outlined"}/>
            </DialogActions>
        </Dialog>
    )
}
