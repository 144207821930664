import EventManager, {Unsubscriber} from "../../event/EventManager";
import RenderContext from "../context/RenderContext";
import NodeRendererFactory from "./node/NodeRendererFactory";
import * as d3 from "d3";
import {
    EventType,
    IChartScrolledEvent,
    IModelUpdatedOnItemsCreatedEvent,
    IModelUpdatedOnItemsRemovedEvent,
    IModelUpdatedOnNodeLabelUpdateEvent,
    IModelUpdatedOnNodesMovedEvent,
    IModelUpdatedOnNodesResizedEvent,
    INodesPointerEventsEvent,
    IScrollRequestEvent,
    ModelBackupEvent,
    ModelUpdatedOnNodesAlignedEvent,
    NodeRenderedOnNodeCreatedEvent,
    NodeSyntheticAttributeUpdatedEvent,
} from "../../event/Event";
import NodeRendererUtils from "./node/NodeRendererUtils";
import {POINTER_EVENTS_CLASS_NAME, POINTER_EVENTS_RECT_ATTR_VALUE} from "./node/ConfigurableNodeRenderer";
import PositionManagerUtils from "../manager/nodeposition/PositionManagerUtils";
import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";
import GeometryUtils, {Area, Point} from "../util/GeometryUtils";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import ChartGroup, {ChartGroupType} from "../common/ChartGroup";
import {PositionUpdateType} from "../manager/model/eventhandlers/positionupdater/PositionUpdate";
import NodeChildNodes from "../manager/model/NodeChildNodes";
import {ModelManager} from "../manager/ModelManager";
import {ModelUpdatedOnNodesPositionUpdatedEvent} from "../../event/ModelUpdatedOnNodesPositionUpdatedEvent";
import {StyleEventType, StylesUpdatedEvent} from "../../../diagram/editor/style/StyleEvents";
import {DiagramNode} from "../model/Model";
import {
    ReportedObjectsHighlightedEvent,
    ReportedObjectsUnhighlightedEvent,
    ValidationEventType
} from "../../../diagram/editor/validation/ValidationEvents";

export default class NodesRenderer {

    private readonly eventManager: EventManager;
    private nodeRendererFactory?: NodeRendererFactory;
    private renderContext?: RenderContext;

    private svgScrollArea?: Area;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_MOVED, this.handleModelUpdatedOnNodesMovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_RESIZED, this.handleModelUpdatedOnNodesResizedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE, this.handleModelUpdatedOnNodeLabelUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_CREATED, this.handleModelUpdatedOnItemsCreatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_REMOVED, this.handleModelUpdatedOnItemsRemovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODES_POINTER_EVENTS_DISABLE, this.handleNodesPointerEventsEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODES_POINTER_EVENTS_ENABLE, this.handleNodesPointerEventsEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(StyleEventType.STYLES_UPDATED, this.handleNodesStyleEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(ValidationEventType.REPORTED_OBJECTS_HIGHLIGHTED, this.handleHighlightedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(ValidationEventType.REPORTED_OBJECTS_UNHIGHLIGHTED, this.handleUnhighlightedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_SCROLLED, this.handleChartScrolledEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelUpdatedOnNodesAlignedEvent>(EventType.MODEL_UPDATED_ON_NODES_ALIGNED, this.handleModelUpdatedOnNodesAligned.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelUpdatedOnNodesPositionUpdatedEvent>(EventType.MODEL_UPDATED_ON_NODES_POSITION_UPDATED, this.handleModelUpdatedOnNodesPositionUpdated.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelBackupEvent>(EventType.MODEL_UPDATED_ON_MODEL_BACKUP_LOADED, this.handleModelUpdatedOnModelBackupLoadedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<NodeSyntheticAttributeUpdatedEvent>(EventType.NODE_SYNTHETIC_ATTRIBUTE_UPDATED, this.handleNodeSyntheticAttributeUpdatedEvent.bind(this)));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    public init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        this.nodeRendererFactory = NodeRendererFactory.getInstance(renderContext);
    }

    public render() {
        if (this.renderContext) {
            this.renderContext.modelManager.getDiagramNodes()
                .forEach(node => {
                    this.initialRenderNode(node);
                })
        }
    }

    private initialRenderNode(node: IDiagramNodeDto) {
        const nodeOrderGroup = NodesRenderer.renderNodeOrderGroup(node);
        this.renderNode(node, nodeOrderGroup);
    }

    private static clearNodesGroup() {
        d3.select(NodeRendererUtils.createNodesGroupId(true))
            .selectAll("*")
            .remove();
    }

    private static renderNodeOrderGroup(
        node: IDiagramNodeDto,
    ) {
        const nodesGroup = NodeRendererUtils.getNodesGroup();
        const group: d3.Selection<SVGGElement, IDiagramNodeDto, any, undefined> = nodesGroup.append("g")
            .datum(node)
            .attr("id", NodeRendererUtils.createNodeOrderGroupId(node));
        return group;
    }

    private renderNode(
        node: DiagramNode,
        nodeOrderGroup: d3.Selection<SVGGElement, IDiagramNodeDto, any, undefined>,
    ) {
        if (this.renderContext) {
            const nodeType = node.type;
            const elementId = node.elementIdentifier;
            const elementType = this.renderContext.modelManager.getElementType(elementId);

            const nodeRenderer = this.nodeRendererFactory?.get(nodeType, elementType, node);
            nodeRenderer?.render(node, nodeOrderGroup, this.renderContext, this.eventManager);
        }
    }

    handleModelUpdatedOnNodesMovedEvent(event: IModelUpdatedOnNodesMovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODES_MOVED) {
            this.synchronizeNodeOrderGroups();
            const nodes = event.nodeDimensionsNew.map(nodeDimension => nodeDimension.node);
            if (event.parentNode) {
                nodes.push(event.parentNode);
            }
            this.rerenderNodes(nodes);
            this.eventManager.publishEvent({type: EventType.CHART_NODES_RERENDERED, event: {}});
        }
    }

    handleModelUpdatedOnNodesResizedEvent(event: IModelUpdatedOnNodesResizedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODES_RESIZED) {
            this.rerenderNodes(event.nodeDimensionsNew.map(dimensions => dimensions.node));
            this.eventManager.publishEvent({type: EventType.CHART_NODES_RERENDERED, event: {}})
        }
    }

    handleModelUpdatedOnNodeLabelUpdateEvent(event: IModelUpdatedOnNodeLabelUpdateEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE) {
            this.rerenderNodes([event.node]);
            this.eventManager.publishEvent({type: EventType.CHART_NODES_RERENDERED, event: {}})
        }
    }

    handleModelUpdatedOnItemsCreatedEvent(event: IModelUpdatedOnItemsCreatedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_CREATED) {
            const renderedNodesCount = event.createdNodes.length;
            event.createdNodes.forEach(node => {
                this.initialRenderNode(node);
                this.ensureNodeIsVisible(node);
                this.eventManager.publishEvent<NodeRenderedOnNodeCreatedEvent>({
                    type: EventType.NODE_RENDERED_ON_NODE_CREATED,
                    node: node,
                    elementCreated: event.isNewElement,
                    renderedNodesCount: renderedNodesCount,
                    isUndoRedoResult: event.undoRedoType != null
                });
                this.eventManager.publishEvent({type: EventType.CHART_NODES_RERENDERED, event: {}})
            });
        }
    }

    private handleModelUpdatedOnNodesPositionUpdated(event: ModelUpdatedOnNodesPositionUpdatedEvent) {
        if (this.renderContext?.modelManager) {
            const modelManager = this.renderContext.modelManager;
            const nodeIdToChildNodesMap = modelManager.createNodeIdToChildNodesMap(event.nodes);
            const nodeIdToParentNodeDirectChildNodesMap = modelManager.getNodeIdToParentNodeDirectChildNodesMap(event.nodes);

            const newNodePositions = event.newNodePositions;
            for (const newNodePosition of newNodePositions) {
                const updatedNodeId = newNodePosition.nodeId;
                this.updateNodePosition(
                    newNodePosition,
                    nodeIdToChildNodesMap.get(updatedNodeId) as Array<IDiagramNodeDto>,
                    nodeIdToParentNodeDirectChildNodesMap.get(updatedNodeId) as NodeChildNodes
                );
            }
        }
    }

    private updateNodePosition(newNodePosition: PositionUpdateType,
                               nodeChildNodes: Array<IDiagramNodeDto>,
                               parentNodeDirectChildNodes: NodeChildNodes) {
        const updatedNodeId = newNodePosition.nodeId;
        const updatedNodeChildIndex = newNodePosition.parentNodePosition.parentNodeChildrenIndex;
        if (updatedNodeChildIndex === 0) {
            const referenceNodeId = this.getNodeIdAtIndex(parentNodeDirectChildNodes, 1);
            this.moveBefore(updatedNodeId, referenceNodeId);
        } else {
            const referenceNodeId = this.getNodeIdAtIndex(parentNodeDirectChildNodes, updatedNodeChildIndex - 1);
            const referenceNodeLastChildId = this.getLastChildNodeId(referenceNodeId);
            this.moveAfter(updatedNodeId, referenceNodeLastChildId);
        }
        this.moveChildrenAfter(updatedNodeId, nodeChildNodes);
    }

    private moveAfter(nodeId: string, referenceNodeId: string) {
        const nodeGroupElement = NodeRendererUtils.getNodeOrderGroupByNodeId(nodeId).node() as SVGSVGElement;
        const referenceNodeGroupElement = NodeRendererUtils.getNodeOrderGroupByNodeId(referenceNodeId).node() as SVGSVGElement;
        nodeGroupElement.parentNode?.insertBefore(nodeGroupElement, referenceNodeGroupElement.nextSibling);
    }

    private moveBefore(nodeId: string, referenceNodeId: string) {
        const nodeGroupElement = NodeRendererUtils.getNodeOrderGroupByNodeId(nodeId).node() as SVGSVGElement;
        const referenceNodeGroupElement = NodeRendererUtils.getNodeOrderGroupByNodeId(referenceNodeId).node() as SVGSVGElement;
        nodeGroupElement.parentNode?.insertBefore(nodeGroupElement, referenceNodeGroupElement);
    }

    private moveChildrenAfter(nodeId: string, nodeChildNodes: Array<IDiagramNodeDto>) {
        let lastInsertedNodeId = nodeId;
        for (const node of nodeChildNodes) {
            this.moveAfter(node.identifier, lastInsertedNodeId);
            lastInsertedNodeId = node.identifier;
        }
    }

    private getNodeIdAtIndex(nodes: NodeChildNodes, index: number) {
        const nodeIds = nodes.childNodes
            .map(node => node.identifier);
        return nodeIds[index];
    }

    private handleChartScrolledEvent(event: IChartScrolledEvent) {
        this.svgScrollArea = Area.from(event.scrollLeft, event.scrollTop, event.clientWidth, event.clientHeight);
    }

    private handleModelUpdatedOnNodesAligned(event: ModelUpdatedOnNodesAlignedEvent) {
        this.rerenderNodes(event.nodes);
    }

    private ensureNodeIsVisible(node: IDiagramNodeDto) {
        if (this.svgScrollArea) {
            const nodeLeftBottomSvgPoint = NodesRenderer.getNodeLeftBottomPointRelativeToSvgArea(node);
            if (!GeometryUtils.areaContainsPoint(this.svgScrollArea, nodeLeftBottomSvgPoint)) {
                const increment = GeometryUtils.getPointIncrementSoThatAreaContainsPoint(this.svgScrollArea, nodeLeftBottomSvgPoint);
                this.eventManager.publishEvent<IScrollRequestEvent>({
                    type: EventType.SCROLL_BY_REQUEST,
                    top: increment.y,
                    left: increment.x
                });
            }
        }
    }

    private static getNodeLeftBottomPointRelativeToSvgArea(node: IDiagramNodeDto) {
        return DiagramEditorUtils.convertPointFromGroupToGroup(
            new Point(node.x, node.y + node.h),
            ChartGroup.getChartGroupNode(ChartGroupType.CHART_GROUP),
            ChartGroup.getSvgNode(),
            ChartGroup.getSvgNode());
    }

    handleModelUpdatedOnItemsRemovedEvent(event: IModelUpdatedOnItemsRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_REMOVED) {
            event.removedNodes.forEach(node => {
                NodeRendererUtils.getNodeOrderGroup(node).remove();
            })
            this.eventManager.publishEvent({type: EventType.CHART_NODES_RERENDERED, event: {}})
        }
    }

    handleNodesPointerEventsEvent(event: INodesPointerEventsEvent) {
        if (event.type === EventType.NODES_POINTER_EVENTS_DISABLE) {
            d3.selectAll(this.createDisableNodeGroupsSelector(event)).attr("pointer-events", "none");
            d3.selectAll(this.createDisableNodePointerEventGroupsSelector(event)).attr("pointer-events", "none");
        }
        if (event.type === EventType.NODES_POINTER_EVENTS_ENABLE) {
            d3.selectAll(this.createDisableNodeGroupsSelector(event)).attr("pointer-events", null);
            d3.selectAll(this.createDisableNodePointerEventGroupsSelector(event)).attr("pointer-events", POINTER_EVENTS_RECT_ATTR_VALUE);
        }
    }

    handleNodesStyleEvent(event: StylesUpdatedEvent) {
        this.rerenderNodes(event.nodes);
    }

    handleHighlightedEvent(event: ReportedObjectsHighlightedEvent) {
        this.rerenderNodes(event.nodes);
    }

    handleUnhighlightedEvent(event: ReportedObjectsUnhighlightedEvent) {
        this.rerenderNodes(event.nodes);
    }

    private handleModelUpdatedOnModelBackupLoadedEvent(event: ModelBackupEvent) {
        if (this.renderContext) {
            NodesRenderer.clearNodesGroup();
            for (const node of event.nodesToAdd) {
                this.initialRenderNode(node);
            }
            this.eventManager.publishEvent({type: EventType.CHART_NODES_RERENDERED, event: {}});
        }
    }

    private handleNodeSyntheticAttributeUpdatedEvent(event: NodeSyntheticAttributeUpdatedEvent) {
        this.rerenderNodes(event.nodes);
    }

    private createDisableNodeGroupsSelector(event: INodesPointerEventsEvent) {
        return event.nodes
            .map(node => {
                return `${NodeRendererUtils.createNodeOrderGroupId(node, true)}, ${PositionManagerUtils.createNodeHandleGroupId(node, true)}`;
            })
            .join(", ")
    }

    private createDisableNodePointerEventGroupsSelector(event: INodesPointerEventsEvent) {
        return event.nodes
            .map(node => {
                return `${NodeRendererUtils.createNodeOrderGroupId(node, true)} .${POINTER_EVENTS_CLASS_NAME}`;
            })
            .join(", ")
    }

    private rerenderNodes(nodes: Array<IDiagramNodeDto>) {
        nodes.forEach(node => {
            if (this.renderContext) {
                const orderGroup = NodeRendererUtils.getNodeOrderGroup(node);
                orderGroup.select("*").remove();
                this.renderNode(node, orderGroup);
            }
        });
    }

    private synchronizeNodeOrderGroups() {
        if (this.renderContext?.modelManager) {
            const nodesGroup = NodeRendererUtils.getNodesGroup();
            const nodes = this.renderContext.modelManager.getDiagramNodes();
            const nodeIdToNode: {[nodeId: string]: SVGGElement} = {};
            nodes.forEach((node) => {
                const childNode = NodeRendererUtils.getNodeOrderGroup(node).node() as SVGGElement;
                nodeIdToNode[node.identifier] = nodesGroup.node()?.removeChild(childNode) as SVGGElement;
            });
            nodes.forEach((node) => {
                nodesGroup.node()?.append(nodeIdToNode[node.identifier]);
            });
        }
    }

    private getLastChildNodeId(nodeId: string) {
        const childNodes: Array<IDiagramNodeDto> = [];
        if (this.renderContext?.modelManager) {
            const modelManager = this.renderContext?.modelManager as ModelManager;
            const node = modelManager.getDiagramNodeById(nodeId);
            childNodes.push(...modelManager.getChildNodesExclusive(node));
        }
        if (childNodes.length > 0) {
            return childNodes[childNodes.length - 1].identifier;
        } else {
            return nodeId;
        }
    }
}
