export enum OpenGroupExchangeFormatType {
    ARCHIMATE_VERSION_2_1 = "ARCHIMATE_VERSION_2_1",
    ARCHIMATE_VERSION_3_1 = "ARCHIMATE_VERSION_3_1",
}

export class OpenGroupExchangeFormat {

    public static readonly [OpenGroupExchangeFormatType.ARCHIMATE_VERSION_2_1] = new OpenGroupExchangeFormat(OpenGroupExchangeFormatType.ARCHIMATE_VERSION_2_1, "ArchiMate_v2.1", 21, "ArchiMate 2.1", ".xml");
    public static readonly [OpenGroupExchangeFormatType.ARCHIMATE_VERSION_3_1] = new OpenGroupExchangeFormat(OpenGroupExchangeFormatType.ARCHIMATE_VERSION_3_1, "ArchiMate_v3.1", 31, "ArchiMate 3.1", ".xml");

    public static values(): Array<OpenGroupExchangeFormat> {
        return Object.keys(OpenGroupExchangeFormatType)
            .map(key => OpenGroupExchangeFormat[key as OpenGroupExchangeFormatType])
            .sort((a, b) => {
                let res = 0;
                if (a.version < b.version) {
                    res = 1;
                } else if (a.version > b.version) {
                    res = -1;
                }
                return res;
            });
    }

    public static valueOf(formatTypeName: string) {
        const existingValues = Object.keys(OpenGroupExchangeFormatType)
            .filter((name) => name === formatTypeName);
        return existingValues.length === 1 ? OpenGroupExchangeFormat[existingValues[0] as OpenGroupExchangeFormatType] : null;
    }

    constructor(public readonly archimateFileFormat: OpenGroupExchangeFormatType,
                public readonly id: string,
                public readonly version: number,
                public readonly caption: string,
                public readonly fileExtension: string,) {
    }
}