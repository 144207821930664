import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../Api";
import Elements from "./Elements";
import {IFilter} from "../../store/elements/Elements";
import Relationships from "./Relationships";
import Constants from "../Constants";
import {IModelDto} from "./model/IModelDto";
import {map} from "rxjs/operators";

export enum FileFormatType {
    CSV = "CSV",
    EXCEL = "EXCEL",
    PNG = "PNG",
}

export enum ModelExportFormatType {
    ARCHIMATE_VERSION_3_1 = "ARCHIMATE_VERSION_3_1",
    ARCHIMATE_VERSION_2_1 = "ARCHIMATE_VERSION_2_1",
}

export class FileFormat {

    public static readonly CSV = new FileFormat("CSV", FileFormatType.CSV, ".csv");
    public static readonly EXCEL = new FileFormat("Excel", FileFormatType.EXCEL, ".xlsx");

    public static readonly PNG = new FileFormat("PNG", FileFormatType.PNG, ".png");

    private constructor(private readonly caption: string,
                        private readonly fileFormatType: FileFormatType,
                        private readonly extension: string) {}

    public getCaption() {
        return this.caption;
    }

    public getFileFormatType() {
        return this.fileFormatType;
    }

    public getExtension() {
        return this.extension;
    }

    public static findByFileFormatType(type: FileFormatType) {
        switch (type) {
            case FileFormatType.CSV: return FileFormat.CSV;
            case FileFormatType.EXCEL: return FileFormat.EXCEL;
            case FileFormatType.PNG: return FileFormat.PNG;
            default: throw new Error(`Unsupported format type: '${type}'`)
        }
    }
}

export interface IElementsExportDto {
    filter: IFilter,
    format: FileFormatType,
}

export interface IRelationshipMatrixExportDto {
    rowElementIdentifiers: Array<string>,
    columnElementIdentifiers: Array<string>,
    format: FileFormatType,
}

export interface IModelExportDto {
    format: ModelExportFormatType,
    diagramIdentifiers: Array<string>,
    elementIdentifiers: Array<string>,
    exportUnreferencedElementsAndRelationships: boolean,
}

export interface IModelDataExportDto {
    diagramIdentifiers: Array<string>,
    elementIdentifiers: Array<string>,
    exportUnreferencedElementsAndRelationships: boolean,
    exportOrganizations: boolean,
}

export default class Exports {

    public static ELEMENTS_EXPORT_DATA_URL: string = `${Elements.ENDPOINT_URL}:export`;
    public static RELATIONSHIP_EXPORT_MATRIX_BYIDS_URL: string = `${Relationships.ENDPOINT_URL}/matrix/export/byidentifiers`;
    public static MODEL_EXPORT_URL = `${Constants.API_HOST}/rest-api/repository/model:export-xml`;
    public static MODEL_EXPORT_DATA_URL = `${Constants.API_HOST}/rest-api/repository/model:export-json`;
    public static PERMISSIONS_EXPORT_URL = `${Constants.API_HOST}/rest-api/users/permissions:export`;

    exportElements(exportDto: IElementsExportDto): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Exports.ELEMENTS_EXPORT_DATA_URL,
            method: "POST",
            responseType: "blob",
            body: {
                filter: Elements.createSearchFilter(exportDto.filter),
                format: exportDto.format,
            },
        });
    }

    exportRelationshipMatrix(exportDto: IRelationshipMatrixExportDto): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Exports.RELATIONSHIP_EXPORT_MATRIX_BYIDS_URL,
            method: "POST",
            responseType: "blob",
            body: exportDto,
        });
    }

    exportModel(exportDto: IModelExportDto): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Exports.MODEL_EXPORT_URL,
            method: "POST",
            responseType: "blob",
            body: exportDto,
        });
    }

    exportPermissions(): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Exports.PERMISSIONS_EXPORT_URL,
            method: "POST",
            responseType: "blob",
        });
    }

    exportModelData(exportDto: IModelDataExportDto): Observable<IModelDto> {
        return Api.createAjax({
            url: Exports.MODEL_EXPORT_DATA_URL,
            method: "POST",
            body: exportDto,
        }).pipe(
            map(response => response.response as IModelDto)
        );
    }
}