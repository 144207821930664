import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import React from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";

export default class IconResolver {

    public static resolveIcon(fileName: string, mimeType: string, props?: SvgIconProps): JSX.Element {
        // mimeType not supported yet => use fileName
        if (fileName != null &&
            (fileName.indexOf(".png") > -1 ||
            fileName.indexOf(".jpg") > -1 ||
            fileName.indexOf(".svg") > -1 ||
            fileName.indexOf(".bmp") > -1))
        {
            return <ImageIcon {...props} />;
        } else {
            return <DescriptionIcon {...props} />;
        }
    }

}
