import INodeRenderer from "./INodeRenderer";
import ConfigurableNodeRenderer, {Decorations} from "./ConfigurableNodeRenderer";
import {ShapeType} from "./AbstractNodeRenderer";
import RenderContext from "../../context/RenderContext";
import NodeRendererUtils from "./NodeRendererUtils";
import {DiagramEditorUtils} from "../../util/DiagramEditorUtils";
import * as d3 from 'd3';
import {ArchimateLayerType} from "../../../archimate/ArchiMateLayer";
import {ArchimateElement, ArchimateElementType} from "../../../archimate/ArchimateElement";
import store from "../../../../store/Store";
import {NodeType} from "../../../apis/diagram/NodeType";
import {DiagramNode} from "../../model/Model";
import {getDefaultElementsColor} from "../../../apis/diagram/DefaultColorsDto";

type ArchiMateTypeRendererMap = {
    [prop in keyof typeof ArchimateElementType]: INodeRenderer;
}

export default class NodeRendererFactory {

    public static readonly EXTERNAL_LINK_ICON_ID = "__diagram-editor-external-link-icon__";

    private static factory: NodeRendererFactory;

    private archiMateTypeRendererMap: ArchiMateTypeRendererMap;
    private renderContext: RenderContext;
    private containerRenderer: INodeRenderer;
    private diagramLinkRenderer: INodeRenderer;
    private noteRenderer: INodeRenderer;
    private defaultNodeRenderer: INodeRenderer;

    constructor(renderContext: RenderContext) {
        this.renderContext = renderContext;

        const emptyDecorations = Decorations.of(null, null, null);

        const rectWithIcon = new ConfigurableNodeRenderer(ShapeType.RECTANGLE, emptyDecorations, true);
        const roundedRectWithIcon = new ConfigurableNodeRenderer(ShapeType.ROUNDED_RECTANGLE, emptyDecorations, true);
        const bevelledRectWithIcon = new ConfigurableNodeRenderer(ShapeType.BEVELLED_RECTANGLE, emptyDecorations, true);
        const dashedFolder = new ConfigurableNodeRenderer(ShapeType.DASHED_FOLDER, emptyDecorations, false);
        const circle = new ConfigurableNodeRenderer(ShapeType.CIRCLE, emptyDecorations, false);

        this.archiMateTypeRendererMap = {
            // Application layer type mappings
            APPLICATION_COMPONENT: rectWithIcon,
            APPLICATION_COLLABORATION: rectWithIcon,
            APPLICATION_EVENT: roundedRectWithIcon,
            APPLICATION_INTERACTION: roundedRectWithIcon,
            APPLICATION_INTERFACE: rectWithIcon,
            APPLICATION_FUNCTION: roundedRectWithIcon,
            APPLICATION_PROCESS: roundedRectWithIcon,
            APPLICATION_SERVICE: roundedRectWithIcon,
            DATA_OBJECT: rectWithIcon,

            // Business layer types
            BUSINESS_ACTOR: rectWithIcon,
            BUSINESS_COLLABORATION: rectWithIcon,
            BUSINESS_EVENT: roundedRectWithIcon,
            BUSINESS_FUNCTION: roundedRectWithIcon,
            BUSINESS_INTERACTION: roundedRectWithIcon,
            BUSINESS_INTERFACE: rectWithIcon,
            BUSINESS_OBJECT: rectWithIcon,
            BUSINESS_PROCESS: roundedRectWithIcon,
            BUSINESS_ROLE: rectWithIcon,
            BUSINESS_SERVICE: roundedRectWithIcon,
            CONTRACT: rectWithIcon,
            PRODUCT: rectWithIcon,
            REPRESENTATION: rectWithIcon,

            // Implementation and migration layer types
            DELIVERABLE: rectWithIcon,
            GAP: rectWithIcon,
            IMPLEMENTATION_EVENT: roundedRectWithIcon,
            PLATEAU: rectWithIcon,
            WORK_PACKAGE: roundedRectWithIcon,

            // Motivation layer types
            ASSESSMENT: bevelledRectWithIcon,
            CONSTRAINT: bevelledRectWithIcon,
            DRIVER: bevelledRectWithIcon,
            GOAL: bevelledRectWithIcon,
            MEANING: bevelledRectWithIcon,
            OUTCOME: bevelledRectWithIcon,
            PRINCIPLE: bevelledRectWithIcon,
            REQUIREMENT: bevelledRectWithIcon,
            STAKEHOLDER: bevelledRectWithIcon,
            VALUE: bevelledRectWithIcon,

            // Physical layer types
            DISTRIBUTION_NETWORK: rectWithIcon,
            EQUIPMENT: rectWithIcon,
            FACILITY: rectWithIcon,
            MATERIAL: rectWithIcon,

            // Strategy layer types
            RESOURCE: rectWithIcon,
            CAPABILITY: roundedRectWithIcon,
            COURSE_OF_ACTION: roundedRectWithIcon,
            VALUE_STREAM: roundedRectWithIcon,

            // Technology layer types
            ARTIFACT: rectWithIcon,
            COMMUNICATION_NETWORK: rectWithIcon,
            DEVICE: rectWithIcon,
            NODE: rectWithIcon,
            PATH: rectWithIcon,
            SYSTEM_SOFTWARE: rectWithIcon,
            TECHNOLOGY_COLLABORATION: rectWithIcon,
            TECHNOLOGY_EVENT: roundedRectWithIcon,
            TECHNOLOGY_FUNCTION: roundedRectWithIcon,
            TECHNOLOGY_INTERACTION: roundedRectWithIcon,
            TECHNOLOGY_INTERFACE: rectWithIcon,
            TECHNOLOGY_PROCESS: roundedRectWithIcon,
            TECHNOLOGY_SERVICE: roundedRectWithIcon,

            // Composite types - no specific layer (possibly multiple layers)
            GROUPING: dashedFolder,
            LOCATION: rectWithIcon,

            // Relationship connectors - no specific layer
            AND_JUNCTION: circle,
            OR_JUNCTION: circle,
        };

        this.containerRenderer = new ConfigurableNodeRenderer(ShapeType.SOLID_FOLDER, emptyDecorations, false);
        this.diagramLinkRenderer = new ConfigurableNodeRenderer(ShapeType.RECTANGLE, emptyDecorations, true);
        this.noteRenderer = new ConfigurableNodeRenderer(ShapeType.RIGHT_BOTTOM_CLIPPED_RECTANGLE, emptyDecorations, false);

        this.defaultNodeRenderer = rectWithIcon;

        this.init();
    }

    static getInstance(renderContext: RenderContext) {
        NodeRendererFactory.factory = new NodeRendererFactory(renderContext);
        return NodeRendererFactory.factory;
    }

    private init() {
        const defsGroup = this.renderContext.svgElementManager.getDefsSelection();
        // append diagram symbol
        defsGroup.append("symbol")
            .attr("id", NodeRendererUtils.DIAGRAM_SYMBOL_ID)
            .attr("viewBox", "0 0 24 24")
            .append("path")
            .attr("d", "M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z");
        ArchimateElement.values().forEach(element => {
            const elementTypeColor = getDefaultElementsColor(element.standardName, store.getState().diagramDefaults.defaultColors);
            const brighterColor = d3.color(elementTypeColor)?.brighter(NodeRendererFactory.getBrightnessCoeficient(element.layerType, element.elementType)).formatRgb();
            const gradientSelection = defsGroup.append("linearGradient")
                .attr("id", NodeRendererFactory.getElementShapeGradientId(element.elementType));
            gradientSelection.append("stop")
                .attr("offset", "0%")
                .attr("stop-color", brighterColor as string);
            gradientSelection.append("stop")
                .attr("offset", "100%")
                .attr("stop-color", elementTypeColor as string);
        });
        const svg = this.renderContext.svgElementManager.getSvgSelection();
        const externalLinkSvgGroup = svg.append("svg")
            .attr("id", NodeRendererFactory.EXTERNAL_LINK_ICON_ID)
            .attr("viewBox", "0 0 512 512")
            .attr("width", 0)
            .attr("height", 0)
            .append("g");
        externalLinkSvgGroup.append("path")
            .attr("stroke", "#4396ea")
            .attr("fill", "#4396ea")
            .attr("d", "M455.1,97l-293,293c-10.3,11.9-28.3,13.1-40.1,2.8c-11.8-10.3-13.1-28.3-2.8-40.1l3.1-3.1L414.7,56.9H312.9 c-15.7,0-28.4-12.7-28.4-28.4S297.2,0,312.9,0h170.7C499.3,0,512,12.7,512,28.4v170.7c0,15.7-12.7,28.4-28.4,28.4 c-15.7,0-28.4-12.7-28.4-28.4V97z M398.2,312.9c0-15.7,12.7-28.4,28.4-28.4c15.7,0,28.4,12.7,28.4,28.4v142.2 c0,31.4-25.5,56.9-56.9,56.9H56.9C25.5,512,0,486.5,0,455.1V113.8c0-31.3,25.6-56.9,56.9-56.9h142.2c15.7,0,28.4,12.7,28.4,28.4 s-12.7,28.4-28.4,28.4H56.9v341.3h341.3V312.9z");
        externalLinkSvgGroup.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("fill", "none")
            .attr("stroke", "none")
            .attr("pointer-events", "all");

    }

    private static getBrightnessCoeficient(layerType: ArchimateLayerType, elementType: ArchimateElementType) {
        switch (layerType) {
            case ArchimateLayerType.APPLICATION:
                return 1.5;
            case ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION:
                const isGapOrPlateau = elementType === ArchimateElementType.GAP || elementType === ArchimateElementType.PLATEAU;
                return isGapOrPlateau ? .2 : .4;
            case ArchimateLayerType.MOTIVATION: return .3;
            default: return .5;
        }
    }

    static getElementShapeGradientId(elementType: ArchimateElementType) {
        return `__gradient-${elementType.toLocaleLowerCase()}__`;
    }

    get(nodeType: NodeType, type: ArchimateElementType | null, node: DiagramNode): INodeRenderer {
        let renderer = null;
        if (nodeType === NodeType.ELEMENT) {
            if (type) {
                renderer = this.archiMateTypeRendererMap[type];
            }
        } else if (nodeType === NodeType.LABEL) {
            if (DiagramEditorUtils.isDiagramReferenceNode(node)) {
                renderer = this.diagramLinkRenderer;
            } else {
                renderer = this.noteRenderer;
            }
        } else if (nodeType === NodeType.CONTAINER) {
            renderer = this.containerRenderer;
        }
        return renderer || this.defaultNodeRenderer;
    }

}
