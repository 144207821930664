import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../Api";
import Constants from "../Constants";
import {map} from "rxjs/operators";
import {StereotypeDto} from "./stereotype/StereotypeDto";

export interface StereotypeCreateDto {
    name: string;
    elementType: string;
}

export class StereotypeService {

    public static ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/stereotypes";

    findAll(): Promise<StereotypeDto[]> {
        const request = {
            url: StereotypeService.ENDPOINT_URL + "/",
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map((response) => response.response as StereotypeDto[])
            ).toPromise();
    }

    createStereotype(stereotype: StereotypeCreateDto): Promise<any> {
        const request = {
            url: StereotypeService.ENDPOINT_URL + "/",
            method: "POST",
            body: stereotype,
        };
        return Api.createAjax(request).toPromise();
    }

    deleteStereotypeById(id: string): Observable<AjaxResponse> {
        return Api.createAjax({
            url: `${StereotypeService.ENDPOINT_URL}/${id}`,
            method: "DELETE",
        });
    }
}

export default new StereotypeService();
