import {Grid as MuiGrid, GridProps as MuiGridProps, useTheme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    gridItem: {
        padding: theme.spacing(2, 0, 1, 0),
    },
    dialogContainer: {
        display: "flex",
        paddingTop: "1em",
        paddingBottom: "1em",
    },
}));

export interface GridProps extends MuiGridProps {
    noStyle?: boolean
}

export default function Grid(props: GridProps) {
    const classes = useStyles(useTheme());
    const {noStyle, ...otherProps} = props;

    let appliedStyle = undefined;
    if (!props.noStyle) {
        if (props.item) {
            appliedStyle = classes.gridItem;
        } else if (props.container) {
            appliedStyle = classes.dialogContainer;
        }
    }

    return (
        <MuiGrid
            className={appliedStyle}
            {...otherProps}
        >
            {props.children}
        </MuiGrid>
    );
}
