import React from "react";
import ConfirmationDialog from "./dialogs/ConfirmationDialog";

interface IProps {
}

interface IState {
    showConfirmReloadDialog: boolean;
}

export default class PageReloadChecker extends React.Component<IProps, IState> {

    initialOnKeyDown: ((this: GlobalEventHandlers, ev: KeyboardEvent) => any) | null;

    constructor(props: IProps) {
        super(props);
        this.initialOnKeyDown = document.onkeydown;
        this.state = {
            showConfirmReloadDialog: false,
        }
    }

    componentDidMount() {
        document.onkeydown = (e) => {
            if(e.code === "F5") {
                setTimeout(() => this.setState({showConfirmReloadDialog: true}), 0);
                e.preventDefault();
                e.stopImmediatePropagation();
                return false;
            }
        };
    }

    componentWillUnmount() {
        document.onkeydown = this.initialOnKeyDown;
    }

    render() {
        const { showConfirmReloadDialog } = this.state;

        return (
            <ConfirmationDialog open={showConfirmReloadDialog}
                                title={"Opuštění stránky"}
                                confirmationText={"Přejete si odhlásit a přesměrovat na login stránku?"}
                                onReject={() => {this.setState({showConfirmReloadDialog: false})}}
                                onConfirm={() => window.location.reload()}/>
        );
    }

}