import {UserInfoDto} from "../user/UserInfoDto";
import Api from "../../Api";
import {catchError, map} from "rxjs/operators";
import Constants from "../../Constants";
import {AjaxError} from "rxjs/ajax";
import {of} from "rxjs";

export interface ChatCommentDto {
    id: number;
    message: string;
    created: string;
    updated: string;
    user: UserInfoDto;
    acl: ChatCommentAclDto;
}

export interface ChatCommentAclDto {
    canUpdate: boolean;
    canDelete: boolean;
}

export interface ChatCommentCreateDto {
    message: string;
}

export interface ChatCommentUpdateDto {
    message: string;
}

export interface ChatNodeDto {
    x: number;
    y: number;
    nodeId?: string;
}

export interface ChatNodeCreateDto {
    x: number;
    y: number;
    nodeId?: string;
}

export interface ChatNodeUpdateDto {
    x: number;
    y: number;
}

export enum ChatState {
    UNRESOLVED = "UNRESOLVED",
    RESOLVED = "RESOLVED",
}

export interface ChatDto {
    id: number;
    elementId?: string;
    diagramId?: string;
    state: ChatState;
    chatComments: ChatCommentDto[];
    chatNode?: ChatNodeDto;
    acl: ChatAclDto;
}

export interface ChatStatsDto {
    lastCommentedOn?: string;
}

export class ChatComparator {
    static compareByCreationDate(c1: ChatDto, c2: ChatDto): number {
        const d1 = new Date(c1.chatComments[0].created);
        const d2 = new Date(c2.chatComments[0].created);
        return d1.getTime() - d2.getTime();
    }
}

export interface ChatAclDto {
    canCreateComments: boolean;
    canChangeState: boolean;
    canDelete: boolean;
}

export interface ChatCreateDto {
    elementId?: string;
    diagramId?: string;
    chatNode?: ChatNodeCreateDto;
    initialComment: ChatCommentCreateDto;
}

export interface ChatFilterDto {
    elementId?: string;
    diagramId?: string;
    state?: ChatState;
    containsChatNode?: boolean;
}

export interface ChatReadDto {
    time: string;
}

export class ChatService {

    async findChatById(chatId: number): Promise<ChatDto | undefined> {
        const request = {
            url: Constants.REST_API_URL + `/chats/${chatId}`,
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ChatDto),
                catchError(err => {
                    if (err instanceof AjaxError && err.status === 404) {
                        return of(undefined);
                    } else {
                        throw err;
                    }
                })
            )
            .toPromise();
    }

    async createChat(chatCreate: ChatCreateDto): Promise<ChatDto> {
        const request = {
            url: Constants.REST_API_URL + "/chats/",
            method: "POST",
            body: chatCreate
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ChatDto)
            )
            .toPromise();
    }

    async deleteChat(chatId: number): Promise<void> {
        const request = {
            url: Constants.REST_API_URL + `/chats/${chatId}`,
            method: "DELETE"
        };
        return Api.createAjax(request)
            .pipe(
                map(response => undefined)
            )
            .toPromise();
    }

    async searchChatsByFilter(filter: ChatFilterDto): Promise<ChatDto[]> {
        const request = {
            url: Constants.REST_API_URL + "/chats:search",
            method: "POST",
            body: filter
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ChatDto[])
            )
            .toPromise();
    }

    async countChatsByFilter(filter: ChatFilterDto): Promise<number> {
        const request = {
            url: Constants.REST_API_URL + "/chats:search/count",
            method: "POST",
            body: filter
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as number)
            )
            .toPromise();
    }

    async updateChatState(chatId: number, state: ChatState): Promise<void> {
        const request = {
            url: Constants.REST_API_URL + `/chats/${chatId}:update-state`,
            method: "POST",
            body: {
                state: state
            }
        };
        return Api.createAjax(request)
            .pipe(
                map(response => undefined)
            )
            .toPromise();
    }

    async updateChatCoordinates(chatId: number, nodeUpdate: ChatNodeUpdateDto): Promise<void> {
        const request = {
            url: Constants.REST_API_URL + `/chats/${chatId}:update-coordinates`,
            method: "POST",
            body: nodeUpdate
        };
        return Api.createAjax(request)
            .pipe(
                map(response => undefined)
            )
            .toPromise();
    }

    async createComment(chatId: number, commentCreate: ChatCommentCreateDto): Promise<ChatCommentDto> {
        const request = {
            url: Constants.REST_API_URL + `/chats/${chatId}/comments/`,
            method: "POST",
            body: commentCreate
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ChatCommentDto)
            )
            .toPromise();
    }

    async updateComment(chatId: number, commentId: number, commentUpdate: ChatCommentUpdateDto): Promise<ChatCommentDto> {
        const request = {
            url: Constants.REST_API_URL + `/chats/${chatId}/comments/${commentId}`,
            method: "PUT",
            body: commentUpdate
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ChatCommentDto)
            )
            .toPromise();
    }

    async deleteComment(chatId: number, commentId: number): Promise<void> {
        const request = {
            url: Constants.REST_API_URL + `/chats/${chatId}/comments/${commentId}`,
            method: "DELETE"
        };
        return Api.createAjax(request)
            .pipe(
                map(response => undefined)
            )
            .toPromise();
    }

    async findLastRead(chatId: number): Promise<ChatReadDto | undefined> {
        const request = {
            url: Constants.REST_API_URL + `/chats/${chatId}/last-read`,
            method: "GET"
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ChatReadDto),
                catchError(err => {
                    if (err instanceof AjaxError && err.status === 404) {
                        return of(undefined);
                    } else {
                        throw err;
                    }
                })
            )
            .toPromise();
    }

    async markAsRead(chatId: number): Promise<void> {
        const request = {
            url: Constants.REST_API_URL + `/chats/${chatId}:mark-as-read`,
            method: "POST"
        };
        return Api.createAjax(request)
            .pipe(
                map(response => undefined)
            )
            .toPromise();
    }
}

export default new ChatService();
