import {TranslationFunction} from "../../../../../store/localization/TranslMessasge";
import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import {CommonTranslation} from "../../CommonTranslation";

export function createLabelsGridColDef(_transl: TranslationFunction): GridColDef[] {
    return [
        {field: 'name', headerName: _transl(CommonTranslation.TITLE), width: 300},
        {field: 'code', headerName: _transl(CommonTranslation.CODE), width: 150},
        {field: 'description', headerName: _transl(CommonTranslation.DESCRIPTION), width: 300},
        {
            field: 'publicLabel',
            headerName: _transl(CommonTranslation.PRIVATE),
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                switch (params.value) {
                    case true:
                        return _transl(CommonTranslation.NO);
                    case false:
                        return _transl(CommonTranslation.YES);
                    default:
                        return "";
                }
            }
        },
        {field: 'user', headerName: _transl(CommonTranslation.USER), width: 150},
    ]
}
