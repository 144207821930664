import * as d3 from "d3";
import {Area} from "../../util/GeometryUtils";
import {ISnappingFunction} from "../GridManager";
import ChartGroup, {ChartGroupType} from "../../common/ChartGroup";
import {PointIncrement} from "../../common/PointIncrement";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";

export class UpdateInfo {
    constructor(public snappedPointerIncrement: PointIncrement,
                public updatedArea: Area) {}
}

export default class PositionManagerUtils {

    static createNodesHandleOverlayGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.NODES_HANDLE_OVERLAY_GROUP, selector);
    }

    static createNodesHandleGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.NODES_HANDLE_GROUP, selector);
    }

    static createNodesOverlayGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.NODES_OVERLAY_GROUP, selector);
    }

    static createNodeHandleGroupId(node: IDiagramNodeDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__node-id-handle-group-" + node.identifier + "__";
    }

    static createNodeOverlayId(node: IDiagramNodeDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__node-id-overlay-" + node.identifier + "__";
    }

    static getPositionGroup() {
        const id = PositionManagerUtils.createNodesHandleOverlayGroupId(true);
        return d3.select(id) as d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    }

    static getNodesHandleGroup() {
        const id = PositionManagerUtils.createNodesHandleGroupId(true);
        return d3.select(id) as d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    }

    static getNodesOverlayGroup() {
        const id = PositionManagerUtils.createNodesOverlayGroupId(true);
        return d3.select(id) as d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    }

    // NODE MOVE

    static computeDimensionsOnNodesMove(nodes: Array<IDiagramNodeDto>,
                                        increment: PointIncrement,
                                        snappingFunction: ISnappingFunction) {
        const dimensionsMap: {[id: string]: Area} = {};
        nodes.forEach(node => {
            dimensionsMap[node.identifier] = PositionManagerUtils.computeUpdateInfoOnNodeMove(node, increment, snappingFunction).updatedArea;
        })
        return dimensionsMap;
    }

    static computeUpdateInfoOnNodeMove(node: IDiagramNodeDto, increment: PointIncrement, snappingFunction: ISnappingFunction) {
        const snappedX = snappingFunction.snap(node.x + increment.incrementX);
        const snappedY = snappingFunction.snap(node.y + increment.incrementY);
        const snappedIncrementX = snappedX - node.x;
        const snappedIncrementY = snappedY - node.y;

        return new UpdateInfo(new PointIncrement(snappedIncrementX, snappedIncrementY), new Area(snappedX, snappedY, node.w, node.h));
    }

}
