import Constants from "../../../../../common/Constants";
import Api from "../../../../../common/Api";
import {map} from "rxjs/operators";
import {ILabelDto} from "../../../../../common/apis/label/ILabelDto";
import {LabelCreateDto} from "../../../../../common/apis/label/LabelCreateDto";

const LABEL_CODE_PARAM = ":code";
const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/labels";
const PUT_UPDATE_LABEL_URL: string = `${ENDPOINT_URL}/${LABEL_CODE_PARAM}`;
const DELETE_LABEL_URL: string = `${ENDPOINT_URL}/${LABEL_CODE_PARAM}`;

export interface LabelsService {
    updateLabel(code: string, name: string, description: string): Promise<any>

    getAll(): Promise<ILabelDto[]>

    delete(code: string): Promise<any>

    create(labelCreate: LabelCreateDto): Promise<any>
}

class RxJsLabelsService implements LabelsService {

    updateLabel(code: string, name: string, description: string): Promise<any> {
        const request = {
            url: PUT_UPDATE_LABEL_URL.replace(LABEL_CODE_PARAM, code),
            method: "PUT",
            body: {
                name: name,
                description: description,
            }
        }
        return Api.createAjax(request).toPromise();
    }

    getAll(): Promise<ILabelDto[]> {
        return Api.createAjax({
            url: ENDPOINT_URL + "/",
            method: "GET",
        }).pipe(
            map(response => response.response as ILabelDto[])
        ).toPromise();
    }

    delete(code: string): Promise<any> {
        return Api.createAjax({
            url: DELETE_LABEL_URL.replace(LABEL_CODE_PARAM, code),
            method: "DELETE",
        }).toPromise();
    }


    create(labelCreate: LabelCreateDto): Promise<any> {
        return Api.createAjax({
            url: ENDPOINT_URL + "/",
            method: "POST",
            body: {
                code: labelCreate.code,
                name: labelCreate.name,
                description: labelCreate.description,
                publicLabel: labelCreate.publicLabel,
            }
        }).toPromise();
    }

}

export default new RxJsLabelsService() as LabelsService;
