import React, {useCallback, useEffect, useState} from "react";
import {Box, CircularProgress, IconButton, InputBase, Paper, Slide, SxProps} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import SearchIcon from '@mui/icons-material/Search';
import Divider from "@mui/material/Divider";
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {of, Subject} from 'rxjs';
import {catchError, debounceTime, filter, startWith, switchMap} from "rxjs/operators";
import {Action} from "redux";
import UIUtils from "../../../../common/UIUtils";
import ContextAreaSelect from "./ContextAreaSelect";
import {SearchProvider, SearchResultItem} from "./SearchProvider";
import SearchResultArea, {SEARCH_RESULT_AREA_ACTION_ROLE} from "./SearchResultArea";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {AppBarTranslationKey} from "../AppBarTranslationKey";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

// styles

const useStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        color: "white",
        backgroundColor: "transparent",
        padding: 0,
        display: 'inline-flex',
        flexGrow: 1,
        alignItems: 'center',
        minWidth: 150,
    },
    appBarSearchMenus: {
        position: "relative",
        flexGrow: 1,
        maxWidth: 450,
    },
    appBarSearch: {
        display: 'inline-flex',
        flexGrow: 1,
        alignItems: 'center',
        overflow: "hidden",
        borderRadius: 50,
    },
    searchBarIconsPadding: {
        padding: theme.spacing(0.8),
    },
    searchBarInProgressIcon: {
        color: "white",
    },
    closedBgColor: {
        backgroundColor: theme.palette.primary.main,
    },
    openedBgColor: {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
    },
    contextArea: {
        height: "100%",
        paddingLeft: theme.spacing(2),
        backgroundColor: "rgba(255, 255, 255, 0.17)",
        cursor: "pointer",
        userSelect: "none",
    },
    visible: {
        visibility: "visible",
    },
    hidden: {
        visibility: "hidden",
    },
    input: {
        color: "white",
        marginLeft: theme.spacing(1),
        flex: 1,
    },
}));


const INPUT_BASE_ID = "app-bar-search-id";

export enum SearchStatus {
    NOT_STARTED = "NOT_STARTED",
    IN_PROGRESS = "IN_PROGRESS",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
}

export interface SearchState {
    status: SearchStatus,
    items: Array<unknown>,
}

function createSearchStatus(status: SearchStatus, items: Array<unknown>): SearchState {
    return {
        status: status,
        items: items,
    }
}

// props & state

interface SearchProps {
    searchProviders: SearchProvider[];
    sx?: SxProps;
}

// component class

export default function Search(props: SearchProps) {
    const {searchProviders} = props;

    const [searchResultAreaOpened, setSearchResultAreaOpened] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchBarOpened, setSearchBarOpened] = useState(false);
    const [contextAreaOpened, setContextAreaOpened] = useState(false);
    const [searchProviderId, setSearchProviderId] = useState(searchProviders[0].id);

    const [searchState, setSearchState] = useState<SearchState>(createSearchStatus(SearchStatus.NOT_STARTED, []));
    const [searchSubject] = useState(new Subject<string>());

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const findProviderById = useCallback((providerId: string) => {
        return searchProviders
            .find((provider) => provider.id === providerId) as SearchProvider;
    }, [searchProviders]);

    const updateSearchState = useCallback((status: SearchStatus, items: unknown[]) => {
        setSearchResultAreaOpened(status === SearchStatus.SUCCEEDED || status === SearchStatus.FAILED);
        setSearchState(createSearchStatus(status, items));
    }, []);

    useEffect(() => {
        const subscription = searchSubject
            .pipe(
                filter(str => str != null && str.length >= 3),
                debounceTime(700))
            .subscribe((term) => {
                const searchTerm = term || "";
                const {resultAreaConfig} = findProviderById(searchProviderId);

                resultAreaConfig.search(searchTerm).pipe(
                    switchMap((resp) => of(resultAreaConfig.extractItemsFromResponse(resp.response))),
                    switchMap((items) => of(createSearchStatus(SearchStatus.SUCCEEDED, items))),
                    catchError(err => of(createSearchStatus(SearchStatus.FAILED, []))),
                    startWith(createSearchStatus(SearchStatus.IN_PROGRESS, []))
                ).subscribe(result => {
                    updateSearchState(result.status, result.items);
                })
            });
        return () => {
            subscription.unsubscribe();
        }
    }, [searchProviderId, findProviderById, searchSubject, updateSearchState]);

    function updateSearchBarOpened() {
        if (searchBarOpened) {
            setSearchBarOpened(false);
            resetState(props.searchProviders[0].id);

        } else {
            setSearchBarOpened(true);
        }
    }

    function resetState(defaultProviderId: string) {
        setSearchTerm("");

        setContextAreaOpened(false);
        setSearchProviderId(defaultProviderId);
        setSearchResultAreaOpened(false);
        setSearchState(createSearchStatus(SearchStatus.NOT_STARTED, []));
    }

    function updateSearchTerm(newTerm: string) {
        setSearchTerm(newTerm);
        search();
    }

    function search() {
        searchSubject.next(searchTerm);
    }

    function updateSelectedSearchProviderId(id: string) {
        setContextAreaOpened(false);
        setSearchProviderId(id);
        UIUtils.requestFocus(INPUT_BASE_ID);
    }

    function onShowAllItemsSelected() {
        const { resultAreaConfig } = findProviderById(searchProviderId);

        const action: Action<unknown> = resultAreaConfig.getSearchAction(searchTerm);
        dispatch(action);

        const newPath = resultAreaConfig.getRedirectUrl();
        navigate(newPath);

        updateSearchBarOpened();
    }

    function onSearchResultItemSelected(item: SearchResultItem) {
        const { resultAreaConfig } = findProviderById(searchProviderId);

        const newPath = resultAreaConfig.getDetailRedirectUrl(item);

        navigate(newPath);

        updateSearchBarOpened();
    }

    const classes = useStyle();
    const {sx} = props;

    const bgColor = searchBarOpened ? classes.openedBgColor : classes.closedBgColor;
    const inProgressIconClass = searchState.status === SearchStatus.IN_PROGRESS ? classes.visible : classes.hidden;
    const searchProvider = findProviderById(searchProviderId);

    return (
        <Box className={classes.appBarSearchMenus}
             sx={sx}>
            <div className={`${bgColor} ${classes.appBarSearch}`}>
                <Slide in={searchBarOpened} direction={"left"} onEntered={() => UIUtils.requestFocus(INPUT_BASE_ID)}>
                    <Paper component="form" className={classes.root} elevation={0}>
                        <div className={classes.contextArea} onClick={e => setContextAreaOpened(!contextAreaOpened)} >
                            { _transl(searchProvider.contextAreaConfig.primaryTextKey) }
                            <IconButton
                                className={`${classes.searchBarIconsPadding}`}
                                aria-label="menu"
                                color="inherit"
                                size="large">
                                <ArrowDropDownIcon />
                            </IconButton>
                        </div>
                        <InputBase
                            id={INPUT_BASE_ID}
                            autoComplete='off'
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    search();
                                }
                            }}
                            className={classes.input}
                            value={searchTerm}
                            onChange={e => updateSearchTerm(e.target.value)}
                            placeholder={ _transl(AppBarTranslationKey.SEARCH_BAR_ENTER_AT_LEAST_3_CHARS) }
                            onBlur={e => {
                                const relatedTarget: HTMLElement = e.relatedTarget as HTMLElement;
                                const relatedTargetRole = relatedTarget != null ? relatedTarget.getAttribute('role') : "";
                                if (relatedTargetRole !== SEARCH_RESULT_AREA_ACTION_ROLE) {
                                    setSearchResultAreaOpened(false);
                                }
                            }}
                        />

                        <CircularProgress size={20} className={`${classes.searchBarInProgressIcon} ${inProgressIconClass}`} />
                        <Divider />
                    </Paper>
                </Slide>
                <IconButton
                    className={classes.searchBarIconsPadding}
                    aria-label="search bar"
                    color="inherit"
                    onClick={e => updateSearchBarOpened()}
                    size="large">
                    { searchBarOpened ? <CloseIcon />  : <SearchIcon />}
                </IconButton>
            </div>

            <ContextAreaSelect searchProviders={searchProviders} contextAreaSelectOpened={contextAreaOpened} onContextAreaUpdated={(id: string) => updateSelectedSearchProviderId(id)} />

            <SearchResultArea searchProvider={searchProvider} searchResultAreaOpened={searchResultAreaOpened} searchState={searchState}
                              onItemSelected={(item) => onSearchResultItemSelected(item)} onShowAllItemsSelected={() => onShowAllItemsSelected()} />
        </Box>
    );
}
