import {CommonTranslation} from "../CommonTranslation";

export enum FileFormat {
    CSV = "CSV",
    EXCEL = "EXCEL",
}

export class FileType {
    public static readonly [FileFormat.CSV] = new FileType(CommonTranslation.CSV_LABEL, ["csv"], FileFormat.CSV);
    public static readonly [FileFormat.EXCEL] = new FileType(CommonTranslation.EXCEL_LABEL, ["xls", "xlsx"], FileFormat.EXCEL);

    label: string;
    extensions: Array<string>;
    format: FileFormat;

    constructor(label: string, extensions: Array<string>, format: FileFormat) {
        this.label = label;
        this.extensions = extensions;
        this.format = format;
    }

    public static values(): Array<FileType> {
        return Object.keys(FileFormat)
            .map(key => FileType[key as FileFormat]);
    }

    static findFileTypeByFileName(fileName: string | undefined) {
        let fileType = null;

        if (fileName != null) {
            const lastDotIndex = fileName.lastIndexOf(".");
            if (lastDotIndex !== -1) {
                const fileExtension = fileName.substring(lastDotIndex + 1);
                const foundTypes = this.values().filter(fileType => fileType.extensions.indexOf(fileExtension) !== -1);
                if (foundTypes.length > 0) {
                    fileType = foundTypes[0];
                }
            }
        }
        return fileType;
    }

    static findFileTypeByFormat(format: FileFormat) {
        return this.values().filter(value => value.format === format)[0];
    };

}
