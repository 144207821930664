import React, {Dispatch} from "react";
import {connect} from "react-redux";
import SingleselectComboBox from "../../../../components/fields/SingleselectComboBox";
import {IApplicationState} from "../../../../store/Store";
import {FilterOptionsState, createFilterOptions} from "@mui/material/useAutocomplete";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {ViewpointDto} from "../../../../common/apis/ViewpointService";

interface IProps {
    id: string;
    label: string;
    assignedViewpoint: string,
    viewpoints: ViewpointDto[],
    className?: string,
    doUpdate: (item: string) => Promise<any>,
    onSuccessfulUpdate: (items: string) => void,
    readonly?: boolean,
}

function EditableViewpointComponent(props: IProps) {

    const {id, label, viewpoints, className} = props;
    const {doUpdate, onSuccessfulUpdate} = props;
    const filterOptions = createFilterOptions<ViewpointDto>();

    const viewpointDefault = {} as ViewpointDto;
    viewpointDefault.identifier = getAssignedViewpoint(props);

    function getAssignedViewpoint(props: Readonly<IProps> & Readonly<{ children?: React.ReactNode }>) {
        const {assignedViewpoint} = props;
        return assignedViewpoint;
    }

    function setupFilterOptions(options: ViewpointDto[], params: FilterOptionsState<ViewpointDto>) {
        const filtered = filterOptions(options, params);

        const {inputValue} = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.identifier);
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                name: _transl(DiagramTranslationKey.FILTER_ADD) + ` "${inputValue}"`,
                identifier: inputValue,
            });
        }

        return filtered;
    }

    function updateAssignedViewpoint(changedValue: ViewpointDto | string) {
        let viewpointDto = {} as ViewpointDto;
        if (!changedValue) {
            viewpointDto.identifier = '';
        } else if (typeof changedValue === 'string') {
            viewpointDto.identifier = changedValue;
        } else {
            viewpointDto = changedValue;
        }

        (async () => {
            try {
                await doUpdate(viewpointDto.identifier)
                onSuccessfulUpdate(viewpointDto.identifier);
            } catch (error) {
                console.log(error)
            }
        })();
    }

    return (
        <SingleselectComboBox
            label={label}
            id={id}
            className={className}
            options={viewpoints}
            freesolo={true}
            required={false}
            defaultValue={viewpointDefault}
            getRenderLabel={(value) => {
                return typeof value === 'string' ? value :
                    value.name ? value.name : value.identifier;
            }}
            filterOptions={(options, params) => setupFilterOptions(options, params)}
            handleOnChange={(changedValue) => {
                updateAssignedViewpoint(changedValue);
            }}
            readonly={props.readonly}
        />
    );
}

const mapStateToProps = (state: IApplicationState) => ({
    viewpoints: state.pages.common.options.viewpoints.resource || [],
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditableViewpointComponent);
