import {EditOffOutlined, EditOutlined} from "@mui/icons-material";
import {grey} from "@mui/material/colors";
import {GridRenderCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import * as React from "react";

export function readWriteFlagCellRenderer(params: GridRenderCellParams): React.ReactNode {
    const canUpdate = params.value;
    return canUpdate
        ? <EditOutlined sx={{color: grey[400],  fontSize: 14}}/>
        : <EditOffOutlined sx={{color: grey[400],  fontSize: 14}}/>;
}