import Constants from "../../Constants";
import {LocationProvider} from "./LocationProvider";

export interface RouteProvider {
    getCurrentRoute(): string,
}

export class ProviderRouteProvider implements RouteProvider {

    constructor(private locationProvider: LocationProvider) {}

    getCurrentRoute(): string {
        const location = this.locationProvider.getCurrent();
        const route = location.split('#')[0];
        return route.replace(Constants.APP_HOST, "");
    }
}
