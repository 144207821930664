export default class RouteDefinition {
    constructor(private _path: string,
                private _element?: React.ReactNode,) {
    }

    public get path(): string {
        return this._path;
    }

    public get element() {
        return this._element;
    }
}