import React from "react";
import {IFilter} from "../../store/elements/Elements";
import {ElementDto} from "../../common/apis/element/ElementDto";
import Constants from "../../common/Constants";
import ElementsGrid, {ElementsGridType} from "../../pages/main/content/elements/ElementsGrid";
import {_transl} from "../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import {PersistentStateId} from "../../store/common/Grid";
import Dialog from "../dialogs/Dialog";
import DialogTitle from "../dialogs/DialogTitle";
import DialogContent from "../dialogs/DialogContent";

interface IProps {
    isOpened: boolean,
    elementDtos: Array<ElementDto>,

    onDialogClosed: () => void,
}

export default function DiagramsElementChartListDialog(props: IProps) {

    const {isOpened} = props;
    const {onDialogClosed} = props;

    const filter: IFilter = {
        identifiers: props.elementDtos.map(dto => dto.identifier),
    };

    return (
        <Dialog
            open={isOpened}
            aria-labelledby="diagrams-pick-dialog-title"
            onClose={onDialogClosed}
            fullWidth={true}
            maxWidth={"xl"}
        >
            <DialogTitle id="picklist-dialog-title"
                         title={_transl(CommonTranslation.LIST_OF_DISPLAYED_ELEMENTS)}
                         onDialogClosed={onDialogClosed} />
            <DialogContent>
                <ElementsGrid
                    filter={{...filter, maxPageSize: Constants.GRID_DEFAULT_PAGE_SIZE}}
                    persistentStateId={PersistentStateId.ELEMENTS_LIST_GRID}
                    elementsGridType={ElementsGridType.LISTING}
                    refetchData={() => {
                    }}
                />
            </DialogContent>
        </Dialog>
    );

}
