import {MarkerType} from "../renderer/marker/MarkerDefinitionFactory";
import * as d3 from 'd3';

export default class MarkerUtils {

    public static createMarkerId(markerType: MarkerType, lineColor: string, selector?: boolean) {
        const colorId = d3.color(lineColor)?.formatHex()
            .replace("#", "")
        return (selector ? "#" : "") + `marker-id-${markerType.toLowerCase()}_${colorId}`;
    }

}