import React, {useState} from "react";
import {Button,} from "@mui/material";
import Divider from "@mui/material/Divider";
import DiagramsFilter from "./DiagramsFilter";
import RenderMode from "./RenderMode";
import DiagramsPickGrid from "./DiagramsPickGrid";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../../../../components/diagrameditor/DiagramEditorTranslationKey";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";

interface Props {
    // state
    isOpened: boolean,
    isMultiSelection: boolean,

    // callbacks
    onDiagramsPicked: (diagrams: Array<DiagramInfoDto>) => void,
    onDialogClosed: () => void,

    // other
    onDiagramsPickedButtonLabel?: string,
}


export default function DiagramsPickDialog(props: Props) {

    const [pickedDiagrams, setPickedDiagrams] = useState<DiagramInfoDto[]>([])
    const {isOpened, onDiagramsPickedButtonLabel, isMultiSelection} = props;
    const {onDialogClosed, onDiagramsPicked} = props;


    const chooseButtonLabel = onDiagramsPickedButtonLabel || _transl(DiagramEditorTranslationKey.CHOOSE_BUTTON_LABEL);

    return (
        <Dialog
            open={isOpened}
            aria-labelledby="diagrams-pick-dialog-title"
            onClose={onDialogClosed}
            fullWidth={true}
            maxWidth={"xl"}
        >
            <DialogTitle id="picklist-dialog-title"
                         title={_transl(DiagramEditorTranslationKey.CHOICE_OF_DIAGRAMS_TITLE)}
                         onDialogClosed={onDialogClosed} />
            <DialogContent>
                <DiagramsFilter renderMode={RenderMode.DIALOG} doSearchOnLoad={true} />
                <Divider/>
                <DiagramsPickGrid onSelectionChanged={(rowIds, rows) => updatePickedDiagrams(rows)}
                                  isMultiSelection={isMultiSelection} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onDiagramsPicked(pickedDiagrams)}
                        variant={"contained"}
                        color="primary"
                        disabled={pickedDiagrams.length === 0}
                >
                    {chooseButtonLabel}
                </Button>
                <CancelButton onClick={onDialogClosed} />
            </DialogActions>
        </Dialog>
    );


    function updatePickedDiagrams(pickedDiagrams: Array<DiagramInfoDto>) {
        setPickedDiagrams(pickedDiagrams);
    }

}
