import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React, {useEffect, useRef, useState} from "react";
import {
    IconButton,
    ListItemButton, ListItemSecondaryAction, ListItemText,
    ListSubheader, Tooltip, Zoom
} from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import clsx from "clsx";
import {_transl} from "../../store/localization/TranslMessasge";
import {PickListTranslationKey} from "./PickListTranslationKey";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridItem: {
            height: "300px",
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        list: {
            width: "100%",
            height: "100%",
            overflow: "scroll",
        },
        listSubheader: {
            marginRight: "0.5em",
        },
        buttons: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        button: {
            display: "flex",
            justifyContent: "center",
            marginBottom: theme.spacing(1),
        },
        errorBox: {
            width: "100%",
            height: "100%",
            color: "white",
            fontWeight: "bold",
            padding: theme.spacing(2),
            backgroundColor: theme.palette.error.light,
        },
        spinner: {
            color: "inherit",
        },
        displayNone: {
            display: "none",
        }
    })
);

interface Props<T> {
    // state
    showError?: boolean,

    // options
    initialAvailableOptions: Array<T>,
    initialPickedOptions: Array<T>,

    // option rendering
    getOptionText: (item: T) => string,
    optionContentRenderer?: (item: T) => JSX.Element,
    isAvailableOptionDisabled?: (item: T) => boolean,
    isPickedOptionDisabled?: (item: T) => boolean,

    // titles
    availableOptionsTitle?: string,
    pickedOptionsTitle?: string,

    // callbacks
    onPickedOptionsUpdated?: (picked: Array<T>) => void,

    // custom styles
    backgroundColor?: string,
    listSubheaderBackgroundColor?: string,
}

function getSortedFilteredOptions<T>(array: Array<T>, filteredOutArray: Array<T>, getOptionText: (item: T) => string) {
    return sort([...array.filter(option => filteredOutArray.indexOf(option) === -1)], getOptionText)
}

function getSortedMergedOptions<T>(array1: Array<T>, array2: Array<T>, getOptionText: (item: T) => string) {
    return sort([...array1, ...array2], getOptionText);
}

function sort<T>(array: Array<T>, getOptionText: (item: T) => string): Array<T> {
    return array.sort((option1, option2) => getOptionText(option1).localeCompare(getOptionText(option2)));
}

function arraysEqual<T>(arr1: Array<T>, arr2: Array<T>, getOptionText: (item: T) => string): boolean {
    return JSON.stringify(arr1.map(option => getOptionText(option))) === JSON.stringify(arr2.map(option => getOptionText(option)));
}

export default function PickList<T>(props: Props<T>) {

    const initialPickedOptions = useRef<Array<T>>(props.initialPickedOptions);
    const initialAvailableOptions = useRef<Array<T>>(props.initialAvailableOptions);
    const [availableOptions, setAvailableOptions] = useState<Array<T>>(getSortedFilteredOptions(props.initialAvailableOptions, props.initialPickedOptions, props.getOptionText));
    const [pickedOptions, setPickedOptions] = useState<Array<T>>(sort([...props.initialPickedOptions], props.getOptionText));
    const [selectedAvailableOptions, setSelectedAvailableOptions] = useState<Array<T>>([]);
    const [selectedPickedOptions, setSelectedPickedOptions] = useState<Array<T>>([]);

    const classes = useStyles();

    useEffect(() => {
        if (!arraysEqual(props.initialPickedOptions, initialPickedOptions.current, props.getOptionText) ||
            !arraysEqual(props.initialAvailableOptions, initialAvailableOptions.current, props.getOptionText)) {
            initialPickedOptions.current = props.initialPickedOptions;
            initialAvailableOptions.current = props.initialAvailableOptions;
            setAvailableOptions(getSortedFilteredOptions(props.initialAvailableOptions, props.initialPickedOptions, props.getOptionText));
            setPickedOptions(sort([...props.initialPickedOptions], props.getOptionText));
        }
    }, [props.initialAvailableOptions, props.initialPickedOptions, props.getOptionText]);

    function updateSelectedOptions(option: T, selectedOptions: Array<T>, selectedOptionsSetter: React.Dispatch<React.SetStateAction<Array<T>>>) {
        let newSelectedOptions: Array<T>;
        if (selectedOptions.indexOf(option) === -1) {
            newSelectedOptions = [...selectedOptions, option];
        } else {
            newSelectedOptions = selectedOptions.filter(currOption => currOption !== option);
        }
        selectedOptionsSetter(newSelectedOptions);
    }

    function moveToPicked() {
        setAvailableOptions(getSortedFilteredOptions(availableOptions, selectedAvailableOptions, props.getOptionText));
        const newPickedOptions = getSortedMergedOptions(pickedOptions, selectedAvailableOptions, props.getOptionText);
        setPickedOptions(newPickedOptions);
        setSelectedAvailableOptions([]);
        props.onPickedOptionsUpdated && props.onPickedOptionsUpdated(newPickedOptions);
    }

    function moveToAvailable() {
        setAvailableOptions(getSortedMergedOptions(availableOptions, selectedPickedOptions, props.getOptionText));
        const newPickedOptions = getSortedFilteredOptions(pickedOptions, selectedPickedOptions, props.getOptionText);
        setPickedOptions(newPickedOptions);
        setSelectedPickedOptions([]);
        props.onPickedOptionsUpdated && props.onPickedOptionsUpdated(newPickedOptions);

    }

    function getListSubheaderBackgroundColor() {
        const {backgroundColor, listSubheaderBackgroundColor} = props;
        return listSubheaderBackgroundColor || backgroundColor || "white";
    }

    function createListItems(options: Array<T>,
                             isSelected: (option: T) => boolean,
                             onClicked: (option: T) => void,
                             isOptionDisabled: (option: T) => boolean): JSX.Element[] {
        const {optionContentRenderer, getOptionText} = props;

        const itemRenderer = optionContentRenderer || ((item: T) => <ListItemText
            data-testid={`option-text-${getOptionText(item)}`} primary={getOptionText(item)}/>);

        return options.map(option => {
            return (
                <ListItemButton key={getOptionText(option)} onClick={(() => onClicked(option))} disabled={isOptionDisabled(option)}>
                    {
                        itemRenderer(option)
                    }
                    <ListItemSecondaryAction>
                        {
                            isSelected(option) &&
                            <IconButton edge="end" aria-label="selected" size="large">
                                <CheckIcon color={"primary"}/>
                            </IconButton>
                        }
                    </ListItemSecondaryAction>
                </ListItemButton>
            );
        });
    }

    const listSubheaderStyles = {backgroundColor: getListSubheaderBackgroundColor()};

    const resolvedAvailableOptionsTitle = props.availableOptionsTitle || _transl(PickListTranslationKey.AVAILABLE_ITEMS);
    const resolvedPickedOptionsTitle = props.pickedOptionsTitle || _transl(PickListTranslationKey.ASSIGNED_ITEMS);

    return (
        <Grid container spacing={3}>
            <Zoom in={props.showError === true}>
                <Grid item xs={12} className={clsx({[classes.displayNone]: !props.showError})}>
                    <div className={classes.errorBox}>
                        {_transl(PickListTranslationKey.CHANGES_NOT_SUCCESSFULL)}
                    </div>
                </Grid>
            </Zoom>
            <Grid item xs={12} sm={5} className={classes.gridItem}>
                <List subheader={<ListSubheader className={classes.listSubheader}
                                                style={listSubheaderStyles}>{resolvedAvailableOptionsTitle}</ListSubheader>}
                      className={classes.list}
                >
                    {
                        createListItems(availableOptions.filter(option => pickedOptions.indexOf(option) === -1),
                            (option) => selectedAvailableOptions.indexOf(option) > -1,
                            (option) => updateSelectedOptions(option, selectedAvailableOptions, setSelectedAvailableOptions),
                            (option) => props.isAvailableOptionDisabled ? props.isAvailableOptionDisabled(option) : false)
                    }
                </List>
            </Grid>
            <Grid item xs={12} sm={2} className={clsx(classes.gridItem, classes.buttons)}>
                <div className={classes.button}>
                    <Tooltip title={_transl(PickListTranslationKey.ADD)} placement={"right"}>
                                    <span>
                                        <IconButton
                                            aria-label="pick"
                                            data-testid={"button-pick"}
                                            disabled={selectedAvailableOptions.length === 0}
                                            onClick={() => moveToPicked()}
                                            size="large">
                                            <ArrowForwardIosIcon/>
                                        </IconButton>
                                    </span>
                    </Tooltip>
                </div>
                <div className={classes.button}>
                    <Tooltip title={_transl(PickListTranslationKey.REMOVE)} placement={"right"}>
                                    <span>
                                        <IconButton
                                            aria-label="unpick"
                                            data-testid={"button-unpick"}
                                            disabled={selectedPickedOptions.length === 0}
                                            onClick={() => moveToAvailable()}
                                            size="large">
                                            <ArrowBackIosIcon/>
                                        </IconButton>
                                    </span>
                    </Tooltip>
                </div>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.gridItem}>
                <List subheader={<ListSubheader className={classes.listSubheader}
                                                style={listSubheaderStyles}>{resolvedPickedOptionsTitle}</ListSubheader>}
                      className={classes.list}
                >
                    {
                        createListItems(pickedOptions,
                            (option) => selectedPickedOptions.indexOf(option) > -1,
                            (option) => updateSelectedOptions(option, selectedPickedOptions, setSelectedPickedOptions),
                            (option) => props.isPickedOptionDisabled ? props.isPickedOptionDisabled(option) : false)
                    }
                </List>
            </Grid>
        </Grid>
    );
}
