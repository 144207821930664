import {GridSortItem} from "@mui/x-data-grid-pro";

export interface SortingCriterionDto {
    property: string;
    order: SortingOrderDto;
}

export enum SortingOrderDto {
    ASC = "ASC",
    DESC = "DESC"
}

export function convertToSortingCriterionDto(gridSortItem: GridSortItem): SortingCriterionDto {
    return {
        property: gridSortItem.field,
        order: gridSortItem.sort === "asc" ? SortingOrderDto.ASC : SortingOrderDto.DESC
    }
}
