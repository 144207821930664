import Constants from "../../common/Constants";
import {Divider} from "@mui/material";
import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbarDivider: {
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "2px",
            marginRight: "2px",
            color: Constants.MENU_BACKGROUND_COLOR_DARKER,
        },
    })
);

export function ToolbarDivider() {
    const classes = useStyles();
    return (
        <Divider orientation={"vertical"}
                 className={classes.toolbarDivider}
                 flexItem={true}/>
    );
}
