import React, {useState} from "react";
import {Checkbox, FormControlLabel,} from "@mui/material";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogActions from "../../../../components/dialogs/DialogActions";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import TextField from "../../../../components/fields/textfield/TextField";
import PasswordTextField from "../../../../components/fields/textfield/PasswordTextField";
import Grid from "../../../../components/dialogs/Grid";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {UsersTranslationKey} from "./UsersTranslationKey";
import {UserRoleTranslator} from "./UserRoleTranslator";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import userService from "../../../../common/apis/UserService";
import {UserDto} from "../../../../common/apis/user/UserDto";
import {UsersPageController} from "./controller/UsersPageController";
import {CommonTranslation} from "../CommonTranslation";
import {
    USER_EMAIL_MAX_LENGTH,
    USER_FIRSTNAME_MAX_LENGTH,
    USER_LASTNAME_MAX_LENGTH,
    USER_LOGIN_MAX_LENGTH,
    USER_PASSWORD_MAX_LENGTH,
    UserValidator
} from "./UserValidation";
import SingleSelectField from "../../../../components/fields/textfield/SingleSelectField";
import {UserRole} from "../../../../common/access/UserRole";
import Snackbar from "../snackbar/Snackbar";
import {ValidationError} from "../../../../common/ValidationError";
import {UserErrorCode} from "./UserErrorCode";

interface Props {
    opened: boolean,
    onClose: (created: boolean) => void,
    onCreateUserSuccess?: () => void,
    loggedUser: UserDto,
    controller: UsersPageController,
}

export default function CreateUserDialog(props: Props) {

    const alertSuccessMessage = _transl(UsersTranslationKey.CREATE_USER_SUCCESS);
    const alertNotSuccessMessage = _transl(UsersTranslationKey.CREATE_USER_FAILED);
    const userActiveLabel = _transl(UsersTranslationKey.CREATE_USER_IS_ACTIVE)

    const [newPassword, setNewPassword] = useState<string>("");
    const [repeatedPassword, setRepeatedPassword] = useState<string>("");
    const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState<string | undefined>(undefined);
    const [repeatedPasswordErrorMsg, setRepeatedPasswordErrorMsg] = useState<string | undefined>(undefined);
    const [login, setLogin] = useState<string>("");
    const [loginErrorMsg, setLoginErrorMsg] = useState<string | undefined>(undefined);
    const [firstName, setFirstName] = useState<string>("");
    const [firstNameErrorMsg, setFirstNameErrorMsg] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string>("");
    const [lastNameErrorMsg, setLastNameErrorMsg] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string>("");
    const [emailErrorMsg, setEmailErrorMsg] = useState<string | undefined>(undefined);
    const [role, setRole] = useState<string>("");
    const [roleErrorMsg, setRoleErrorMsg] = useState<string | undefined>(undefined);
    const [userActive, setUserActive] = useState<boolean>(true);

    function removeAllErrorMessages() {
        setLoginErrorMsg(undefined);
        setFirstNameErrorMsg(undefined);
        setLastNameErrorMsg(undefined);
        setEmailErrorMsg(undefined);
        setRoleErrorMsg(undefined);
        setNewPasswordErrorMsg(undefined);
        setRepeatedPasswordErrorMsg(undefined);
    }

    function validate(newCreatedLogin: string, newCreatedFirstName: string, newCreatedLastName: string, newCreatedEmail: string, newCreatedRole: UserRoleType, newCreatedPassword: string, newCreatedRepeatedPassword: string) {
        removeAllErrorMessages();

        let validator = new UserValidator();
        let isAllFieldsValid = true;

        if (validator.isLoginError(newCreatedLogin)) {
            setLoginErrorMsg(_transl(UsersTranslationKey.USERS_CREATEUSER_LOGIN_VALIDATION_ERROR));
            isAllFieldsValid = false;
        }
        if (validator.isFirstnameError(newCreatedFirstName)) {
            setFirstNameErrorMsg(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isAllFieldsValid = false;
        }
        if (validator.isLastnameError(newCreatedLastName)) {
            setLastNameErrorMsg(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isAllFieldsValid = false;
        }
        if (validator.isEmailError(newCreatedEmail)) {
            setEmailErrorMsg(_transl(UsersTranslationKey.USERS_CREATEUSER_EMAIL_VALIDATION_ERROR));
            isAllFieldsValid = false;
        }
        if (validator.isRoleError(newCreatedRole)) {
            setRoleErrorMsg(_transl(CommonTranslation.FILL_OUT_AT_LEAST_ONE));
            isAllFieldsValid = false;
        }
        if (validator.isNewPasswordError(newCreatedPassword)) {
            setNewPasswordErrorMsg(_transl(UsersTranslationKey.USERS_CREATEUSER_PASSWORD_VALIDATION_ERROR));
            isAllFieldsValid = false;
        }
        if (validator.isRepeatedPasswordError(newCreatedPassword, newCreatedRepeatedPassword)) {
            setRepeatedPasswordErrorMsg(_transl(UsersTranslationKey.USERS_CREATEUSER_REPEATED_PASSWORD_VALIDATION_ERROR));
            isAllFieldsValid = false;
        }
        return isAllFieldsValid;
    }

    function saveChanges() {
        const newCreatedRole = role.trim() as UserRoleType;
        const newCreatedFirstName = firstName.trim();
        const newCreatedLastName = lastName.trim();
        const newCreatedEmail = email.trim();
        const newCreatedLogin = login.trim();
        const newCreatedPassword = newPassword.trim();
        const newCreatedRepeatedPassword = repeatedPassword.trim();

        const isAllFieldsValid = validate(newCreatedLogin, newCreatedFirstName, newCreatedLastName, newCreatedEmail, newCreatedRole, newCreatedPassword, newCreatedRepeatedPassword);

        if (isAllFieldsValid) {
            removeAllErrorMessages();
            userService.createUser(newCreatedLogin, newCreatedFirstName, newCreatedLastName, newCreatedEmail, newCreatedRole, newCreatedPassword, userActive)
                .then(() => {
                    onDialogClosed(true);
                    Snackbar.success(alertSuccessMessage);
                })
                .catch((error) => {
                    if (error instanceof ValidationError && error.error.code === UserErrorCode.EMAIL_ALREADY_IN_USE) {
                        Snackbar.error(_transl(UsersTranslationKey.USER_EMAIL_ALREADY_IN_USE));
                    } else {
                        Snackbar.error(alertNotSuccessMessage);
                    }
                });
        }
    }

    function createInitialState() {
        setNewPassword("");
        setRepeatedPassword("");
        setLogin("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setRole("");
        setUserActive(true);
        removeAllErrorMessages();
    }

    function onDialogClosed(created: boolean) {
        createInitialState();
        props.onClose(created);
    }

    return (
        <Dialog open={props.opened}
                onClose={() => onDialogClosed(false)}>

            <DialogTitle id="scroll-dialog-title"
                         title={_transl(UsersTranslationKey.CREATE_USER_TITLE)}
                         onDialogClosed={onDialogClosed}
            />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="login-field"
                                   label={_transl(UsersTranslationKey.USER_LOGIN)}
                                   required={true}
                                   maxLength={USER_LOGIN_MAX_LENGTH}
                                   errorMessage={loginErrorMsg}
                                   onChange={(value) => setLogin(value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="firstName-field"
                                   label={_transl(UsersTranslationKey.USER_FIRST_NAME)}
                                   maxLength={USER_FIRSTNAME_MAX_LENGTH}
                                   errorMessage={firstNameErrorMsg}
                                   onChange={(value) => setFirstName(value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="lastName-field"
                                   label={_transl(UsersTranslationKey.USER_LAST_NAME)}
                                   maxLength={USER_LASTNAME_MAX_LENGTH}
                                   errorMessage={lastNameErrorMsg}
                                   onChange={(value) => setLastName(value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelectField id="role-field"
                                           label={_transl(UsersTranslationKey.USER_ROLE)}
                                           required={true}
                                           errorMessage={roleErrorMsg}
                                           onChange={(value) => setRole(value)}
                                           value={role}
                                           items={props.controller.visibleRoles(props.loggedUser)}
                                           renderName={(userRole: UserRole) => UserRoleTranslator.get().translateUserRole(userRole.nameOfAuthority) ?? ""}
                                           selectValue={(userRole: UserRole) => String(userRole.nameOfAuthority)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="email-field"
                                   label={_transl(UsersTranslationKey.USER_EMAIL)}
                                   maxLength={USER_EMAIL_MAX_LENGTH}
                                   required={true}
                                   errorMessage={emailErrorMsg}
                                   onChange={(value) => setEmail(value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordTextField id="newPassword-field"
                                           label={_transl(UsersTranslationKey.CREATE_USER_PASSWORD)}
                                           required={true}
                                           errorMessage={newPasswordErrorMsg}
                                           onChange={(value) => setNewPassword(value)}
                                           maxLength={USER_PASSWORD_MAX_LENGTH}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordTextField id="repeatedPassword-field"
                                           label={_transl(UsersTranslationKey.CREATE_USER_REPEATED_PASSWORD)}
                                           required={true}
                                           errorMessage={repeatedPasswordErrorMsg}
                                           onChange={(value) => setRepeatedPassword(value)}
                                           maxLength={USER_PASSWORD_MAX_LENGTH}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={userActive}
                                          onChange={event => setUserActive(event.target.checked)}
                                          name="labelUserActive"
                                          color="primary"/>
                            }
                            label={userActiveLabel}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={saveChanges}/>
                <CancelButton onClick={onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );
}
