import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {TranslationFunction} from "../../../../../../store/localization/TranslMessasge";
import React from "react";
import {ElementDetailTranslationKey} from "../../ElementDetailTranslationKey";
import {ArchimateRelationship} from "../../../../../../common/archimate/ArchimateRelationship";
import {RelationshipSearchType} from "../../../../../../common/apis/relationship/RelationshipSearchType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {RefreshRounded} from "@mui/icons-material";


export function createRelationshipsPanelColDef(_transl: TranslationFunction): GridColDef[] {
    return [
        {
            field: 'direction',
            headerName: _transl(ElementDetailTranslationKey.RELATIONSHIPS_DIRECTION),
            width: 90,
            align: "center",
            renderCell: (params) => directionRenderer(params)
        },
        {
            field: 'type',
            headerName: _transl(ElementDetailTranslationKey.TYPE),
            width: 150,
            renderCell: (params) => formatRelationshipType(params.row.type)
        },
        {
            field: 'oppositeElementName',
            headerName: _transl(ElementDetailTranslationKey.RELATIONSHIPS_CONNECTED_ELEMENT),
            width: 250
        },
        {
            field: 'name',
            headerName: _transl(ElementDetailTranslationKey.RELATIONSHIPS_NAME),
            width: 250
        },
        {
            field: 'description',
            headerName: _transl(ElementDetailTranslationKey.DESCRIPTION),
            width: 350
        },
    ];
}

function formatRelationshipType(standardName: string) {
    return <>{ArchimateRelationship.findByStandardName(standardName)?.visibleName || standardName}</>;
}

const directionRenderer = (params: GridCellParams) => {
    switch (params.row.direction) {
        case RelationshipSearchType.TARGET :
            return <ArrowBackIcon fontSize={"small"}/>;
        case RelationshipSearchType.SOURCE :
            return <ArrowForwardIcon fontSize={"small"}/>;
        case RelationshipSearchType.RECURSIVE :
            return <RefreshRounded fontSize={"small"}/>;
    }
}
