import {Typography} from "@mui/material";
import {ChatCommentDto} from "../../../../common/apis/chat/ChatService";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        body: {
            display: "table",
            tableLayout: "fixed",
            width: "100%",
            wordWrap: "break-word"
        }
    })
);

export interface CommentBodyProps {
    comment: ChatCommentDto;
    preview?: boolean;
}

export default function ChatCommentBody({comment, preview}: CommentBodyProps) {
    const classes = useStyles();

    let message = comment.message;
    if (preview && message.length > 100) {
        message = message.slice(0, 100) + "...";
    }

    return (
        <div className={classes.body}>
            <Typography variant="body1">{message}</Typography>
        </div>
    );
}