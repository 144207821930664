import React from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"

export enum Position {
    CENTERED = "CENTERED",
    TOP_RIGHT_MINIFIED = "TOP_RIGHT_MINIFIED"
}

const secondaryIconDefaultBgColor = "rgba(255,255,255,0.7)";

interface IProps {
    icon: JSX.Element,
    secondaryIcon: JSX.Element,
    secondaryIconPosition?: Position,
    secondaryIconBgColor?: string,
    secondaryIconRounded?: boolean,
    onHoverBgColor?: string,
}

const styles = (theme: Theme) => createStyles({
    wrapper: {
        position: "relative",
        display: "flex",
        "&:hover .MuiSvgIcon-root": {
            backgroundColor: (props: IProps) => props.onHoverBgColor || "lightgray",
        }
    },
    positionCentered: {
        position: "absolute",
        top: 0,
        left: 0,
    },
    positionTopRightMinified: {
        position: "absolute",
        top: "-2px",
        right: "-3px",
        width: "1rem",
        height: "1rem",
        backgroundColor: secondaryIconDefaultBgColor,
        "& .MuiSvgIcon-root": {
            position: "absolute",
            left: 0,
            fontSize: "1rem",
        },
    },
});

interface IPropsWithStyles extends IProps, WithStyles<typeof styles> {}

class StackedIcon extends React.Component<IPropsWithStyles, {}> {

    render() {
        const {icon, secondaryIcon, secondaryIconPosition, secondaryIconBgColor, classes, secondaryIconRounded} = this.props;
        const secondaryIconBackgroundColor = secondaryIconBgColor || secondaryIconDefaultBgColor;
        return <span className={classes.wrapper}>
            {icon}
            <span className={this.resolveClassName(secondaryIconPosition)} style={{backgroundColor: secondaryIconBackgroundColor, borderRadius: secondaryIconRounded ? "50em": 0, overflow: "hidden"}}>
                {secondaryIcon}
            </span>
        </span>
    }

    private resolveClassName(position?: Position): string {
        const {classes} = this.props;
        switch (position) {
            case Position.TOP_RIGHT_MINIFIED: return classes.positionTopRightMinified;
            case Position.CENTERED: return classes.positionCentered;
            default: return classes.positionCentered;
        }
    }
}

export default withStyles(styles, { withTheme: true })(StackedIcon);
