enum RenderMode {
    PREVIEW = "PREVIEW",
    VIEW = "VIEW",
    PRE_EDIT = "PRE_EDIT",
    EDIT = "EDIT",
    DASHBOARD = "DASHBOARD",
    EXPORT = "EXPORT",
}

export default RenderMode;
