import {adaptV4Theme, createTheme} from '@mui/material/styles';
import {csCZ} from '@mui/material/locale';

declare module '@mui/material/styles' {
    interface Theme {
        graph: {
            controls: {
                edgeOffset: number,
                borderRadius: number,
            }
        },
        logoContainer: {
            heightSpacing: number,
        }
    }

    interface DeprecatedThemeOptions {
        graph: {
            controls: {
                edgeOffset: number,
                borderRadius: number,
            }
        },
        logoContainer: {
            heightSpacing: number,
        }
    }
}

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        deleteBackgroundColor: Palette['primary'];
    }

    interface PaletteOptions {
        deleteBackgroundColor: PaletteOptions['primary'];
    }
}

export default createTheme(adaptV4Theme({
    palette: {
        primary: {
            main: '#3d4977'
        },
        secondary: {
            main: '#c0cffa',
        },
        deleteBackgroundColor: {
            main: "#ab003c",
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        }
    },
    graph: {
        controls: {
            edgeOffset: 10,
            borderRadius: 30
        }
    },
    logoContainer: {
        heightSpacing: 9,
    },

}), csCZ);
