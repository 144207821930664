import React, {useEffect, useState} from "react";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {HierarchyTreeTranslationKey} from "../HierarchyTreeTranslationKey";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import Grid from "../../../../../components/dialogs/Grid";
import {SaveButton} from "../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../components/button/CancelButton";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import TextField from "../../../../../components/fields/textfield/TextField";
import hierarchyTreeService from "../service/HierarchyTreeService";
import Snackbar from "../../snackbar/Snackbar";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import {HierarchyTreeCreateDto} from "../service/HierarchyTreeCreateDto";
import {HierarchyTreeUpdateDto} from "../service/HierarchyTreeUpdateDto";
import {CommonTranslation} from "../../CommonTranslation";
import {ReferenceTreeDataDto, ReferenceType} from "../service/HierarchyTreeDataDto";

const EMPTY_URL = "https://";

export interface HierarchyTreeUrlReferenceUpdateDialogProps {
    open: boolean;
    treeId?: string;
    parentTreeId?: string | null;
    onUpdated?: (parentTreeId?: string | null) => void;
    onClose: () => void;
}

export default function HierarchyTreeUrlReferenceUpdateDialog(props: HierarchyTreeUrlReferenceUpdateDialogProps) {
    const {open, treeId, parentTreeId, onUpdated, onClose} = props;

    const [name, setName] = useState("");
    const [nameErrorMessage, setNameErrorMessage] = useState<string>();
    const [url, setUrl] = useState(EMPTY_URL);
    const [urlErrorMessage, setUrlErrorMessage] = useState<string>();

    useEffect(() => {
        if (open) {
            setName("");
            setUrl(EMPTY_URL);

            if (treeId) {
                hierarchyTreeService.findTree(treeId)
                    .then((tree) => {
                        setName(tree.title);
                        const treeData = tree.data as ReferenceTreeDataDto;
                        setUrl(treeData.reference);
                    })
                    .catch((err) => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA), err));
            }
        }
    }, [open, treeId]);

    function save() {
        if (validate()) {
            if (!treeId) {
                createTree();
            } else {
                updateTree(treeId);
            }
        }
    }

    function validate(): boolean {
        let isValid = true;
        if (name === "") {
            setNameErrorMessage(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isValid = false;
        } else {
            setNameErrorMessage(undefined);
        }

        if (url === "") {
            setUrlErrorMessage(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isValid = false;
        } else {
            setUrlErrorMessage(undefined);
        }
        return isValid;
    }

    function createTree() {
        const treeCreate: HierarchyTreeCreateDto<ReferenceTreeDataDto> = {
            parentId: parentTreeId ?? undefined,
            title: name,
            data: buildTreeData(url)
        };
        hierarchyTreeService.createTree(treeCreate)
            .then(() => {
                onSuccess();
            })
            .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), err));
    }

    function updateTree(treeId: string) {
        const treeUpdate: HierarchyTreeUpdateDto<ReferenceTreeDataDto> = {
            title: name,
            data: buildTreeData(url)
        };
        hierarchyTreeService.updateTree(treeId, treeUpdate)
            .then(() => {
                onSuccess();
            })
            .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), err));
    }

    function buildTreeData(url: string): ReferenceTreeDataDto {
        return {
            type: "Reference",
            referenceType: ReferenceType.URL,
            reference: url,
        };
    }

    function onSuccess() {
        onUpdated?.(parentTreeId);
        onClose();
        Snackbar.success(_transl(CommonTranslation.DATA_SAVED_SUCESSFULLY));
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose}
            maxWidth={"sm"}
        >
            <DialogTitle
                title={_transl(HierarchyTreeTranslationKey.REFERENCE_URL_LINK)}
                onDialogClosed={onClose}
            />

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField label={_transl(HierarchyTreeTranslationKey.REFERENCE_NAME)}
                                   required={true}
                                   value={name}
                                   errorMessage={nameErrorMessage}
                                   onChange={value => setName(value.trim())} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label={_transl(HierarchyTreeTranslationKey.REFERENCE_URL)}
                                   required={true}
                                   value={url}
                                   errorMessage={urlErrorMessage}
                                   onChange={value => setUrl(value.trim())} />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <SaveButton onClick={save}/>
                <CancelButton onClick={onClose}/>
            </DialogActions>
        </Dialog>
    );
}
