import {ChatDto} from "../../../../../common/apis/chat/ChatService";
import {Info} from "./ChatAccordion";
import ChatAccordionHeader from "./ChatAccordionHeader";
import ChatAccordionBody from "./ChatAccordionBody";
import ChatAccordionFooter from "./ChatAccordionFooter";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatAccordionSummary: {
            display: 'flex', flexDirection: 'column'
        },
    })
);

interface ChatAccordionSummaryProps {
    chat: ChatDto;
    expanded: boolean;
    info: Info | undefined;
    onChatsUpdated: () => void;
    onCommentEdited: (commentId: number, message: string) => void;
    openElementDetail?: (id: string) => void;
}

export default function ChatAccordionSummary(props: ChatAccordionSummaryProps) {
    const {chat, expanded, info, onChatsUpdated, onCommentEdited, openElementDetail} = props;

    const classes = useStyles();

    return (
        <div className={classes.chatAccordionSummary}>
            <ChatAccordionHeader chat={chat} info={info} onChatUpdated={onChatsUpdated} onChatDeleted={onChatsUpdated}
                                 openElementDetail={openElementDetail}/>
            <ChatAccordionBody chat={chat} expanded={expanded} onCommentEdited={onCommentEdited}/>
            <ChatAccordionFooter chat={chat} expanded={expanded}/>
        </div>
    );
}