import {Configuration, ExternalSystemsApi, TasksApi, TopicsProjectsApi} from "@dain/tracker-client";
import {TrackerConfigDto} from "../../../../../configuration/TrackerConfigDto";

export default class TrackerService {
    private readonly tasksApi: TasksApi;
    private readonly topicsProjectsApi: TopicsProjectsApi;
    private readonly extSystemsApi: ExternalSystemsApi

    constructor(private readonly trackerConfig: TrackerConfigDto) {
        const configuration = new Configuration({
            basePath: trackerConfig.baseUrl,
            username: trackerConfig.username,
            password: trackerConfig.password,
            headers: {
                "Authorization": "Basic " + btoa(trackerConfig.username + ":" + trackerConfig.password),
            }
        });
        this.tasksApi = new TasksApi(configuration);
        this.topicsProjectsApi = new TopicsProjectsApi(configuration);
        this.extSystemsApi = new ExternalSystemsApi(configuration);
    }

    get api(): TasksApi {
        return this.tasksApi;
    }

    get topicsApi(): TopicsProjectsApi {
        return this.topicsProjectsApi;
    }

    get externalSystemsApi(): ExternalSystemsApi {
        return this.extSystemsApi;
    }

    public getTaskUrl(taskKey: string): string {
        return (this.trackerConfig.taskUrl ?? "").replace("{{taskKey}}", taskKey);
    }
}
