import {CollectionsService} from "../service/CollectionsService";
import {CollectionDto} from "../../../../../common/apis/collection/CollectionDto";
import {CollectionCreateDto} from "../../../../../common/apis/collection/CollectionCreateDto";


export interface CollectionsController {
    getAll(): Promise<CollectionDto[]>

    delete(collectionId: string): Promise<any>

    update(code: string, name: string, description: string): Promise<any>

    create(collectionCreate : CollectionCreateDto) : Promise<any>
}

class CollectionsControllerImpl implements CollectionsController {

    constructor(private service: CollectionsService) {
    }

    async getAll(): Promise<CollectionDto[]> {
        return this.service.getAll();
    }

    async delete(code: string): Promise<any> {
        return this.service.deleteCollection(code);
    }

    async update(code: string, name: string, description: string): Promise<any> {
        return this.service.updateCollection(code, name, description)
    }

    async create(collectionCreate:CollectionCreateDto): Promise<any> {
        return this.service.createCollection(collectionCreate)
    }
}

export default function constructCollectionsController(service: CollectionsService): CollectionsController {
    return new CollectionsControllerImpl(service);
}
