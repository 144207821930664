import {IFetchStatusState} from "../../../../store/common/FetchableResource";
import {IApplicationState} from "../../../../store/Store";
import {Dispatch} from "react";
import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import ArrayUtils from "../../../../common/ArrayUtils";
import {CollectionDto} from "../../../../common/apis/collection/CollectionDto";
import {
    getFilterRefetchAction as getDiagramsFilterRefetchAction,
    getFilterUpdateAction as getDiagramsFilterUpdateAction,
    IFilter
} from "../../../../store/diagrams/Diagrams";
import {getDialogFilterUpdateAction} from "../../../../store/diagrams/DiagramsDialog";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {Tooltip} from "@mui/material";
import {ILabelDto} from "../../../../common/apis/label/ILabelDto";
import {readWriteFlagCellRenderer} from "../../../../components/grid/ReadWriteFlagCellRenderer";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import {DateFormatter} from "../../../../common/DateFormatter";


function createOptionsFormatter<T>(getOptionLabel: (option: T) => string) {
    return ((params: GridValueFormatterParams) => ArrayUtils.joinOrElse((params.value as Array<T> || []).map(param => getOptionLabel(param)), ", ", ""));
}

export class GridDef {

    public static getGridColDef(): GridColDef<DiagramInfoDto>[] {
        return [
            {
                field: "#read-write", headerName: _transl(DiagramTranslationKey.GRID_HEADER_READ_WRITE), hide: true,
                headerClassName: 'datagrid-column', width: 50, valueGetter: params => params.row.acl.canUpdate,
                renderCell: readWriteFlagCellRenderer, sortable: false
            },
            {
                field: 'identifier', headerName: _transl(DiagramTranslationKey.RENDER_MODE_IDENTIFIER),
                headerClassName: 'datagrid-column', width: 200, renderCell: (params: any) => GridDef.getTooltip(params)
            },
            {
                field: 'name', headerName: _transl(DiagramTranslationKey.RENDER_MODE_NAME),
                headerClassName: 'datagrid-column', width: 600, renderCell: (params: any) => GridDef.getTooltip(params)
            },
            {
                field: 'labels', headerName: _transl(DiagramTranslationKey.RENDER_MODE_LABEL_CODES),
                headerClassName: 'datagrid-column', width: 130, renderCell: (params: any) => GridDef.getTooltip(params),
                valueFormatter: createOptionsFormatter<ILabelDto>((label) => label.name),
                sortable: false
            },
            {
                field: 'collections', headerName: _transl(DiagramTranslationKey.RENDER_MODE_COLLECTION_CODES),
                headerClassName: 'datagrid-column', width: 130, renderCell: (params: any) => GridDef.getTooltip(params),
                valueFormatter: createOptionsFormatter<CollectionDto>((label) => label.name),
                sortable: false
            },
            {
                field: 'viewpoint', headerName: _transl(DiagramTranslationKey.RENDER_MODE_VIEWPOINT),
                headerClassName: 'datagrid-column', width: 130, renderCell: (params: any) => GridDef.getTooltip(params),
                sortable: false
            },
            {
                field: 'chatStats.lastCommentedOn',
                type: "dateTime",
                headerName: _transl(DiagramTranslationKey.RENDER_MODE_LAST_COMMENTED_ON),
                headerClassName: 'datagrid-column',
                width: 170,
                renderCell: (params: any) => GridDef.getTooltip(params),
                valueGetter: (params) => params.row.chatStats?.lastCommentedOn,
                valueFormatter: ({value}) => DateFormatter.formatDateTime(value)
            },
            {
                field: 'elementsCount', headerName: _transl(DiagramTranslationKey.RENDER_MODE_ELEMENT_COUNT),
                headerClassName: 'datagrid-column', width: 130, renderCell: (params: any) => GridDef.getTooltip(params),
                sortable: false
            },
            {
                field: 'relationshipsCount', headerName: _transl(DiagramTranslationKey.RENDER_MODE_RELATIONSHIP_COUNT),
                headerClassName: 'datagrid-column', width: 130, renderCell: (params: any) => GridDef.getTooltip(params),
                sortable: false
            },
            {
                field: 'attachmentsCount', headerName: _transl(DiagramTranslationKey.RENDER_MODE_ATTACHMENTS_COUNT),
                headerClassName: 'datagrid-column', width: 130, renderCell: (params: any) => GridDef.getTooltip(params),
                sortable: false
            },
            {
                field: 'description', headerName: _transl(DiagramTranslationKey.RENDER_MODE_DESCRIPTION),
                headerClassName: 'datagrid-column', width: 170, renderCell: (params: any) => GridDef.getTooltip(params),
                sortable: false
            },
            {
                field: 'created', headerName: _transl(DiagramTranslationKey.RENDER_MODE_CREATED),
                headerClassName: 'datagrid-column', width: 170, renderCell: (params: any) => GridDef.getTooltip(params),
                valueFormatter: ({value}) => DateFormatter.formatDateTime(value)
            },
            {
                field: 'userCreated', headerName: _transl(DiagramTranslationKey.RENDER_MODE_USER_CREATED),
                headerClassName: 'datagrid-column', width: 130, renderCell: (params: any) => GridDef.getTooltip(params),
                sortable: false
            },
            {
                field: 'updated', headerName: _transl(DiagramTranslationKey.RENDER_MODE_UPDATED),
                headerClassName: 'datagrid-column', width: 170, renderCell: (params: any) => GridDef.getTooltip(params),
                valueFormatter: ({value}) => DateFormatter.formatDateTime(value)
            },
            {
                field: 'userUpdated', headerName: _transl(DiagramTranslationKey.RENDER_MODE_USER_UPDATED),
                headerClassName: 'datagrid-column', width: 160, renderCell: (params: any) => GridDef.getTooltip(params),
                sortable: false
            },
        ];
    }

    private static getTooltip(params: any) {
        return <Tooltip title={params.formattedValue || ""}>
            <span>{params.formattedValue}</span>
        </Tooltip>;
    }

}

enum RenderMode {
    PAGE = "PAGE",
    DIALOG = "DIALOG",
}

interface FilterConfig {
    getLastFilter: (state: IApplicationState) => IFilter,
    getLastFilterUpdateDate: (state: IApplicationState) => Date | undefined,
    getGridFetchStatus: (state: IApplicationState) => IFetchStatusState,
    dispatchRefetchFilter: (dispatch: Dispatch<any>) => void,
    dispatchUpdateFilter: (dispatch: Dispatch<any>, filter: IFilter) => void,
}

const pageFilterConfig: FilterConfig = {
    getLastFilter: state => state.pages.diagrams.filter.submittedFilter.lastFilter,
    getLastFilterUpdateDate: state => state.pages.diagrams.filter.submittedFilter.lastFilterUpdate,
    getGridFetchStatus: state => state.pages.diagrams.grid.fetchStatus,
    dispatchRefetchFilter: (dispatch: Dispatch<any>) => dispatch(getDiagramsFilterRefetchAction()),
    dispatchUpdateFilter: (dispatch: Dispatch<any>, filter: IFilter) => dispatch(getDiagramsFilterUpdateAction(filter)),
}

const dialogFilterConfig: FilterConfig = {
    getLastFilter: state => state.pages.diagrams.dialogFilter.submittedFilter.lastFilter,
    getLastFilterUpdateDate: state => state.pages.diagrams.dialogFilter.submittedFilter.lastFilterUpdate,
    getGridFetchStatus: state => state.pages.diagrams.dialogGrid.fetchStatus,
    dispatchRefetchFilter: (dispatch: Dispatch<any>) => {},
    dispatchUpdateFilter: (dispatch: Dispatch<any>, filter: IFilter) => dispatch(getDialogFilterUpdateAction(filter)),
}

export class RenderModeUtils {

    public static getFilterConfig(mode: RenderMode): FilterConfig {
        return mode === RenderMode.PAGE ? pageFilterConfig : dialogFilterConfig;
    }

}

export default RenderMode;
