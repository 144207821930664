import React, {useState} from "react";
import TextField, {TextFieldProps} from "./TextField";
import PasswordVisibilityInputAdornment from "../adornment/PasswordVisibilityInputAdornment";

type PasswordTextFieldProps = Omit<TextFieldProps, "type" | "InputProps"> & {};

export default function PasswordTextField({...rest}: PasswordTextFieldProps) {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    return (
        <TextField
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment: <PasswordVisibilityInputAdornment
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    showPassword={showPassword}/>
            }}
            {...rest}
        />
    )
}
