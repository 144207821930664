import StateCircle from "../common/StateCircle";
import {StateColorResolver} from "../common/StateColorResolver";

interface Props {
    stateCode: string,
    stateColorResolver: StateColorResolver,
}

export default function ElementState({stateCode, stateColorResolver}: Props) {
    return <span style={{display: "flex", alignItems: "center"}}><StateCircle stateCode={stateCode} stateColorResolver={stateColorResolver} />&nbsp;({stateCode})</span>;
}
