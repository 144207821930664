export enum ImportTranslationKey {
    ADD_COLLECTIONS = "tools.import.configuration.assignmentOfCollections",
    ADD_COLLECTIONS_ICON = "tools.import.configuration.assignmentOfCollectionsIcon",
    ADD_LABELS = "tools.import.configuration.assignmentOfLabels",
    ADD_LABELS_ICON = "tools.import.configuration.assignmentOfLabelsIcon",
    CHANGE_IMPORT_TYPE = "tools.import.type.changeImportType",
    CHOOSE_FILE = "tools.import.configuration.chooseFile",
    CHOOSE_FILE_AND_TYPE_ICON = "tools.import.configuration.chooseFileAndTypeIcon",
    CHOOSE_FILE_TITLE = "tools.import.configuration.chooseFileTitle",
    CHOOSE_FILE_TYPE = "tools.import.configuration.chooseFileType",
    IGNORE_CONCURRENT_UPDATE_DIALOG_TITLE = "tools.import.model.ignoreConcurrentUpdateDialogTitle",
    IGNORE_CONCURRENT_UPDATE_DIALOG_TEXT = "tools.import.model.ignoreConcurrentUpdateDialogText",
    CHANGED_DIAGRAMS_TITLE = "tools.import.model.changedDiagramsTitle",
    IGNORE_FONT_SETTINGS = "tools.import.model.configuration.ignoreFontSettings",
    IMPORT_CONFIGURATION_LABEL = "tools.import.configurationLabel",
    IMPORT_ELEMENTS_DESCRIPTION = "tools.import.type.importElementsDescription",
    IMPORT_ELEMENTS_LABEL = "tools.import.type.importElementsLabel",
    IMPORT_FROM_ARCHIMATE_DESCRIPTION = "tools.import.type.importFromArchimateDescription",
    IMPORT_FROM_ARCHIMATE_LABEL = "tools.import.type.importFromArchimateLabel",
    IMPORT_PERMISSIONS_DESCRIPTION = "tools.import.type.importPermissionsDescription",
    IMPORT_PERMISSIONS_LABEL = "tools.import.type.importPermissionsLabel",
    IMPORT_PROPERTIES = "tools.import.model.configuration.importProperties",
    IMPORT_RELATIONSHIPS_DESCRIPTION = "tools.import.type.importRelationshipsDescription",
    IMPORT_RELATIONSHIPS_LABEL = "tools.import.type.importRelationshipsLabel",
    IMPORT_RELATIONSHIP_MATRIX_DESCRIPTION = "tools.import.relationshipMatrix.description",
    IMPORT_RELATIONSHIP_MATRIX_ELEMENT_IDENTIFIER_MISSING = "tools.import.relationshipMatrix.elementIdentifierMissing",
    IMPORT_RELATIONSHIP_MATRIX_EMPTY_COLUMN_NOT_ALLOWED = "tools.import.relationshipMatrix.emptyColumnNotAllowed",
    IMPORT_RELATIONSHIP_MATRIX_EMPTY_ROW_NOT_ALLOWED = "tools.import.relationshipMatrix.emptyRownNotAllowed",
    IMPORT_RELATIONSHIP_MATRIX_LABEL = "tools.import.relationshipMatrix.label",
    IMPORT_RELATIONSHIP_MATRIX_UNKNOWN_FORMAT = "tools.import.relationshipMatrix.unknownFormat",
    IMPORT_RELATIONSHIP_ERROR = "tools.import.relationshipMatrix.parse.error",
    LAST_STEP_ACTION_IN_PROGRESS = "tools.import.configuration.lastStepActionInProgress",
    LAST_STEP_ACTION_SUCCESSFUL = "tools.import.configuration.lastStepActionSuccessful",
    LAST_STEP_BUTTON_LABEL = "tools.import.configuration.lastStepButtonLabel",
    LAST_STEP_FAILED = "tools.import.configuration.lastStepFailed",
    LAST_STEP_LABEL = "tools.import.configuration.lastStepLabel",
    OTHER_CHOICES = "tools.import.model.configuration.otherOptions",
    OTHER_CHOICES_ICON = "tools.import.model.configuration.otherOptionsIcon",
    PICK_LIST_AVAILABLE_OPTIONS_COLLECTIONS = "tools.import.configuration.pickListAvailableOptionsCollections",
    PICK_LIST_AVAILABLE_OPTIONS_LABELS = "tools.import.configuration.pickListAvailableOptionsLabels",
    PICK_LIST_SELECTED_OPTIONS_COLLECTIONS = "tools.import.configuration.pickListSelectedOptionsCollections",
    PICK_LIST_SELECTED_OPTIONS_LABELS = "tools.import.configuration.pickListSelectedOptionsLabels",
    REMOVE_DELETED_ELEMENTS_AND_RELATIONSHIPS = "tools.import.model.configuration.removeDeletedElementsAndRelationships",
    SKIP_SCHEMA_VALIDATION = "tools.import.model.configuration.skipSchemaValidation",
    TITLE = "tools.import.title",
    TYPE = "tools.import.configuration.type",
    TYPE_LABEL = "tools.import.typeLabel",
    TYPE_TYPOGRAPHY = "tools.import.typeTypography",
    IMPORT_ELEMENT_DUPLICATE_HEADER_ERROR = "tools.import.type.importElements.duplicateHeaderError",
    IMPORT_ELEMENTS_INSUFFICIENT_RIGHTS = "tools.import.type.importElements.insufficientRights",
    IMPORT_RELATIONSHIP_INSUFFICIENT_RIGHTS = "tools.import.type.importRelationship.insufficientRights",
    IMPORT_RELATIONSHIP_MATRIX_INSUFFICIENT_RIGHTS_SOURCE = "tools.import.type.importRelationshipMatrix.insufficientRights.source",
    IMPORT_RELATIONSHIP_MATRIX_INSUFFICIENT_RIGHTS_SOURCE_OR_TARGET = "tools.import.type.importRelationshipMatrix.insufficientRights.sourceOrTarget",
}
