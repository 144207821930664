import {Event} from "../../../../common/event/Event"
import {IDiagramNodeDto} from "../../../../common/apis/diagram/IDiagramNodeDto";

export enum ChatEventType {
    CREATE_NEW_CHAT_REQUEST = "CREATE_NEW_CHAT_REQUEST",
    CHANGE_CHAT_LAYER_VISIBILITY = "CHANGE_CHAT_LAYER_VISIBILITY",
    CHAT_LAYER_VISIBILITY_CHANGED = "CHAT_LAYER_VISIBILITY_CHANGED",
}

export interface CreateNewChatRequestEvent extends Event {
    type: ChatEventType.CREATE_NEW_CHAT_REQUEST;
    node?: IDiagramNodeDto;
    transformedClientX: number;
    transformedClientY: number;
}

export interface ChangeChatLayerVisibilityEvent extends Event {
    type: ChatEventType.CHANGE_CHAT_LAYER_VISIBILITY;
    chatLayerVisible: boolean;
}

export interface ChatLayerVisibilityChangedEvent extends Event {
    type: ChatEventType.CHAT_LAYER_VISIBILITY_CHANGED;
    chatLayerVisible: boolean;
}