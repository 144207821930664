import {HierarchyTreeDataDto} from "./HierarchyTreeDataDto";

export interface HierarchyTreeUpdateDto<TreeData extends HierarchyTreeDataDto> {
    title: string;
    data: TreeData;
}

export function createHierarchyTreeUpdateDto<TreeData extends HierarchyTreeDataDto>(title: string, data: TreeData): HierarchyTreeUpdateDto<TreeData> {
    return {
        title: title,
        data: data
    }
}
