import {
    GridColumns,
} from "@mui/x-data-grid-pro";
import {GridPresets} from "./presets/GridPresets";
import React, {useRef, useState} from "react";
import ExtGrid, {ExtGridProps} from "./ExtGrid";
import {GridPresetsProcessor} from "./presets/GridPresetsProcessor";


export interface ExtGridWrapperProps extends Omit<ExtGridProps, "presets" | "onPresetsChanged"> {
}

export default function ExtGridWrapper(props: ExtGridWrapperProps) {

    const {...rest} = props;

    const presetsExtractor = useRef<GridPresetsProcessor>(new GridPresetsProcessor());

    const createNormalizedPresets = (columns: GridColumns, presets?: GridPresets) => {
        const normalizationResult = presetsExtractor.current.normalizePresets(columns, presets);
        return normalizationResult.normalizedPresets;
    };

    const [gridPresets, setGridPresets] = useState<GridPresets>(createNormalizedPresets(props.columns, undefined));

    return (
        <React.Fragment>
            <ExtGrid
                {...rest}
                presets={gridPresets}
                onPresetsChanged={presets => setGridPresets(createNormalizedPresets(props.columns, presets))}
            />
        </React.Fragment>
    );
}