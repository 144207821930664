import {Action, Reducer} from "redux";

export enum UiComponentsStateType {
    // item detail page
    ITEM_DETAIL_DETAIL_SELECTED_TAB = "ITEM_DETAIL_DETAIL_SELECTED_TAB",
    ITEM_DETAIL_SIDE_SELECTED_TAB = "ITEM_DETAIL_SIDE_SELECTED_TAB",
    // user detail page
    USER_DETAIL_SELECTED_TABS = "USER_DETAIL_SELECTED_TABS",
    // diagram detail page
    DIAGRAM_DETAIL_LEFTPANEL_SELECTED_TAB = "DIAGRAM_DETAIL_LEFTPANEL_SELECTED_TAB",
    DIAGRAM_DETAIL_RIGHTPANEL_SELECTED_TAB = "DIAGRAM_DETAIL_RIGHTPANEL_SELECTED_TAB",

}

// Redux actions

export enum UiComponentsStateActionType {
    UPDATE = "ACTION/UICOMPONENTSSTATE/UPDATE",
}

export interface UiComponentsStateAction<T> extends Action<UiComponentsStateActionType> {
    type: UiComponentsStateActionType,
    stateType: UiComponentsStateType,
    value: T,
}

// Redux action creators

export function getUiComponentsStateUpdateAction<T>(stateType: UiComponentsStateType, value: T): UiComponentsStateAction<T> {
    return {
        type: UiComponentsStateActionType.UPDATE,
        stateType: stateType,
        value: value,
    }
}

// Redux state

export type IUiComponentsState = {
    [prop in keyof typeof UiComponentsStateType]?: any;
}

const initialState: IUiComponentsState = {};

// Redux redurer

export const uiComponentsStateReducer: Reducer<IUiComponentsState, UiComponentsStateAction<unknown>> = (
    state = initialState,
    action
) => {
    if (action.type === UiComponentsStateActionType.UPDATE) {
        return {
            ...state,
            [action.stateType]: action.value,
        };
    }
    return state;
};