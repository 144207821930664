import {ItemFactory} from "./ItemFactory";
import Item, {ItemType} from "./Item";
import ElementTypeIcon from "../../../../../fields/ElementTypeIcon";
import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import Constants from "../../../../../../common/Constants";
import {ModelTreeUtils} from "../ModelTreeView";
import TreeViewItemFactory from "./TreeViewItemFactory";
import {ArchimateLayer, ArchimateLayerType} from "../../../../../../common/archimate/ArchiMateLayer";
import {ArchimateElement} from "../../../../../../common/archimate/ArchimateElement";
import store from "../../../../../../store/Store";
import {RelationshipDto} from "../../../../../../common/apis/relationship/RelationshipDto";
import {DiagramDto} from "../../../../../../common/apis/diagram/DiagramDto";
import {ElementDto} from "../../../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {getDefaultElementsBgColor} from "../../../../../../common/apis/diagram/DefaultColorsDto";

export default class LayerItemFactory implements ItemFactory {

    getItems(elements: Array<ElementDto>,
             relationships: Array<RelationshipDto>,
             diagrams: Array<DiagramDto>,
             utils: ModelTreeUtils): Array<Item> {

        const items = new Array<Item>();
        items.push(this.createLayerItem(ArchimateLayerType.MOTIVATION, elements, utils));
        items.push(this.createLayerItem(ArchimateLayerType.STRATEGY, elements, utils));
        items.push(this.createLayerItem(ArchimateLayerType.BUSINESS, elements, utils));
        items.push(this.createLayerItem(ArchimateLayerType.APPLICATION, elements, utils));
        items.push(this.createLayerItem(ArchimateLayerType.TECHNOLOGY, elements, utils));
        items.push(this.createLayerItem(ArchimateLayerType.PHYSICAL, elements, utils));
        items.push(this.createLayerItem(ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION, elements, utils));
        items.push(this.createLayerItem(ArchimateLayerType.COMPOSITE_ELEMENTS, elements, utils));
        items.push(this.createLayerItem(ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS, elements, utils));
        items.push(TreeViewItemFactory.createRelationshipsItem(elements, relationships, diagrams, utils));
        return items;
    }

    private createLayerItem(layerType: ArchimateLayerType, elements: Array<ElementDto>, utils: ModelTreeUtils): Item {
        const item: Item = {
            nodeId: layerType,
            itemType: ItemType.FOLDER,
            labelText: _transl(ArchimateLayer[layerType].translationKey),
            labelIcon: <FolderIcon />,
            labelIconColor: Constants.MODEL_FOLDER_ICON_COLOR,
            children: [],
        }
        item.children.push(...this.filterElementsOfLayerType(layerType, elements, utils));
        return item;
    }

    private filterElementsOfLayerType(layerType: ArchimateLayerType, elements: Array<ElementDto>, utils: ModelTreeUtils): Array<any> {
        return elements.filter(element => ArchimateElement.findByStandardName(element.type)?.layerType === layerType)
            .map(element => {
                const item: Item = {
                    nodeId: element.identifier,
                    itemType: ItemType.ELEMENT,
                    elementId: element.identifier,
                    labelIcon: <ElementTypeIcon name={element.type} isMenuIcon={true} />,
                    labelText: utils.getElementName(element),
                    labelIconColor: "black",
                    labelIconBgColor: getDefaultElementsBgColor(element.type, store.getState().diagramDefaults.defaultColors),
                    children: [],
                }
                return item;
            });
    }

}
