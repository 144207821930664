import {DataSubformValidator} from "../DataSubformValidator";
import {HierarchyTreeDataDto} from "../../../../service/HierarchyTreeDataDto";
import {ValidationError, ValidationResult} from "../../../../../../../../common/validation/ValidationResult";

export class EmptySubformValidator implements DataSubformValidator {
    validate(data: HierarchyTreeDataDto): ValidationResult {
        const errors: ValidationError[] = [];

        return {
            errors: errors
        };
    }
}
