import EventManager from "./EventManager";
import {INodeEventListener} from "../event/Listener";
import {EventType, INodeEvent} from "../event/Event";
import * as d3 from "d3";
import NodesRenderer from "../renderer/NodesRenderer";

export default class NodeSelectionManager implements INodeEventListener {

    private eventManager: EventManager;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MOUSE_ENTER);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MOUSE_LEAVE);
    }

    handleNodeEvent(event: INodeEvent): void {
        if (event.type === EventType.NODE_MOUSE_ENTER) {
            d3.select("#"+NodesRenderer.createSelectionNodeId(event.node)).style("visibility", "visible")
        }
        if (event.type === EventType.NODE_MOUSE_LEAVE) {
            const node = d3.select("#"+NodesRenderer.createSelectionNodeId(event.node));
            if (node.attr("data-type") !== "root") {
                node.style("visibility", "hidden");
            }
        }
    }

}