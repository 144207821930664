import {GridColDef} from "@mui/x-data-grid";
import {TranslationFunction} from "../../../../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../CommonTranslation";

export function createCollectionsGridColDef(_transl: TranslationFunction): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: _transl(CommonTranslation.TITLE),
            width: 300
        },
        {
            field: 'description',
            headerName: _transl(CommonTranslation.DESCRIPTION),
            width: 300
        },
        {
            field: 'code',
            headerName: _transl(CommonTranslation.CODE),
            width: 150,
            hide: true
        },
    ]
}
