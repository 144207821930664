import {IDiagramNodeDto} from "../../../../apis/diagram/IDiagramNodeDto";
import {AlignmentType} from "../../../common/AlignmentType";
import {NodeDimensionsType} from "../../../../event/NodeDimensionsType";
import GeometryUtils, {Area} from "../../../util/GeometryUtils";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import TranslationKey from "../../../TranslationKey";
import areAlignable from "./alignnodes/AreAlignable";

type AlignerType = (nodes: Array<IDiagramNodeDto>, alignmentTargetArea: Area) => Array<NodeDimensionsType>;

export interface Manager {
    createNodeDimensions: (node: IDiagramNodeDto) => NodeDimensionsType,
    getChildNodesExclusive: (node: IDiagramNodeDto) => Array<IDiagramNodeDto>,
    getNodeIdToParentNodeIdMap: (nodes: Array<IDiagramNodeDto>) => Map<string, string | undefined>,
    alignNodes: (oldNodeDimensions: Array<NodeDimensionsType>,
                 newNodeDimensions: Array<NodeDimensionsType>,
                 alignmentType: AlignmentType,
                 alignmentTarget: IDiagramNodeDto | undefined) => void,
    showError: (text: string) => void,
}

export default class NodesAligner {

    private modelManager: Manager;
    private alignmentTypeToAlignerMap: Record<AlignmentType, AlignerType>;

    constructor(modelManager: Manager) {
        this.modelManager = modelManager;

        this.alignmentTypeToAlignerMap = {
            [AlignmentType.TOP]: this.alignTop.bind(this),
            [AlignmentType.CENTER]: this.alignCenter.bind(this),
            [AlignmentType.BOTTOM]: this.alignBottom.bind(this),
            [AlignmentType.LEFT]: this.alignLeft.bind(this),
            [AlignmentType.MIDDLE]: this.alignMiddle.bind(this),
            [AlignmentType.RIGHT]: this.alignRight.bind(this),
        }
    }

    public align(nodes: Array<IDiagramNodeDto>, alignmentType: AlignmentType, alignmentTarget?: IDiagramNodeDto) {
        try {
            const nodeIdToParentNodeIdMap = this.modelManager.getNodeIdToParentNodeIdMap(nodes);
            if (areAlignable(nodes, nodeIdToParentNodeIdMap)) {

                const alignmentTargetArea: Area = this.resolveAlignmentArea(nodes, alignmentTarget);
                const alignedNodesNewDimensions = this.alignmentTypeToAlignerMap[alignmentType](nodes, alignmentTargetArea);
                const allNodesNewDimensions = this.moveChildNodes(alignedNodesNewDimensions);
                const allNodesOldNodeDimensions: Array<NodeDimensionsType> = allNodesNewDimensions.map(dim => this.modelManager.createNodeDimensions(dim.node));

                this.modelManager.alignNodes(allNodesOldNodeDimensions, allNodesNewDimensions, alignmentType, alignmentTarget);
            }
        } catch (error) {
            this.modelManager.showError(_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_EDITOR_ALIGN_NODES_FAILED));
        }
    }

    private moveChildNodes(nodesNewDimensions: Array<NodeDimensionsType>) {
        const allNodesNewDimensions = [...nodesNewDimensions];
        for (const nodeNewDimensions of nodesNewDimensions) {
            const childNodes = this.modelManager.getChildNodesExclusive(nodeNewDimensions.node);
            const parentNodeNewDimensions = nodeNewDimensions.area;
            const parentNodeOldDimensions = this.modelManager.createNodeDimensions(nodeNewDimensions.node).area;
            const childNodesNewDimensions = this.moveChildNodesForParentNode(childNodes, parentNodeOldDimensions, parentNodeNewDimensions);
            allNodesNewDimensions.push(...childNodesNewDimensions);
        }
        return allNodesNewDimensions;
    }

    private moveChildNodesForParentNode(childNodes: Array<IDiagramNodeDto>, parentNodeOldDimensions: Area, parentNodeNewDimensions: Area) {
        const xShift = parentNodeNewDimensions.x - parentNodeOldDimensions.x;
        const yShift = parentNodeNewDimensions.y - parentNodeOldDimensions.y;

        const childrenNewDimensions: Array<NodeDimensionsType> = [];
        for (const childNode of childNodes) {
            childrenNewDimensions.push({
                node: childNode,
                area: Area.from(childNode.x + xShift, childNode.y + yShift, childNode.w, childNode.h),
            })
        }
        return childrenNewDimensions;
    }

    private alignTop(nodes: Array<IDiagramNodeDto>, alignmentTargetArea: Area): Array<NodeDimensionsType> {
        const minY = alignmentTargetArea.y;
        return nodes.map(node => ({
                node: node,
                area: Area.from(node.x, minY, node.w, node.h)}
        ));
    }

    private alignCenter(nodes: Array<IDiagramNodeDto>, alignmentTargetArea: Area): Array<NodeDimensionsType> {
        const minX = alignmentTargetArea.x;
        const maxX = alignmentTargetArea.x + alignmentTargetArea.w;
        const centerX = (minX + maxX) / 2;
        return nodes.map(node => {
            const nodeX = centerX - (node.w / 2);
            return {
                node: node,
                area: Area.from(nodeX, node.y, node.w, node.h)
            }
        });
    }

    private alignBottom(nodes: Array<IDiagramNodeDto>, alignmentTargetArea: Area): Array<NodeDimensionsType> {
        let maxY = alignmentTargetArea.y + alignmentTargetArea.h;
        return nodes.map(node => {
            const nodeY = maxY - node.h;
            return {
                node: node,
                area: Area.from(node.x, nodeY, node.w, node.h)
            }
        });
    }

    private alignLeft(nodes: Array<IDiagramNodeDto>, alignmentTargetArea: Area): Array<NodeDimensionsType> {
        let minX = alignmentTargetArea.x;
        return nodes.map(node => ({
                node: node,
                area: Area.from(minX, node.y, node.w, node.h)}
        ));
    }

    private alignMiddle(nodes: Array<IDiagramNodeDto>, alignmentTargetArea: Area): Array<NodeDimensionsType> {
        const minY = alignmentTargetArea.y;
        const maxY = alignmentTargetArea.y + alignmentTargetArea.h;
        const centerY = (minY + maxY) / 2;
        return nodes.map(node => {
            const nodeY = centerY - (node.h / 2);
            return {
                node: node,
                area: Area.from(node.x, nodeY, node.w, node.h)
            }
        });
    }

    private alignRight(nodes: Array<IDiagramNodeDto>, alignmentTargetArea: Area): Array<NodeDimensionsType> {
        let maxX = alignmentTargetArea.x + alignmentTargetArea.w;
        return nodes.map(node => {
            const nodeX = maxX - node.w;
            return {
                node: node,
                area: Area.from(nodeX, node.y, node.w, node.h)
            }
        });
    }

    private resolveAlignmentArea(nodes: Array<IDiagramNodeDto>, alignmentTarget: IDiagramNodeDto | undefined) {
        if (alignmentTarget) {
            return Area.fromNode(alignmentTarget);
        } else {
            return GeometryUtils.getMinimalBoundingArea(nodes.map(node => Area.fromNode(node)));
        }
    }

}