import TextField from "./TextField";
import {MenuItem} from "@mui/material";
import React from "react";

interface SingleSelectFieldProps<T> {
    id: string,
    label: string,
    errorMessage?: string,
    value: string,
    items: T[],
    onChange: (text: string) => void,
    required: boolean;
    selectValue: (value: T) => string,
    renderName: (name: T) => string,
}

const SingleSelectField = <T extends {}> (props: SingleSelectFieldProps<T>) => {
    const { id, label, errorMessage, onChange, value, selectValue, renderName, required, items, ...rest} = props;

    return (
        <TextField select
                   label={label}
                   value={value}
                   errorMessage={errorMessage}
                   id={id}
                   onChange={onChange}
                   required={required}
                   {...rest}>

            {items.map((item, key) => (
                <MenuItem key={key} value={selectValue(item)} >
                    {renderName(item)}
                </MenuItem>
            ))}

        </TextField>
    )

}

export default SingleSelectField
