import React from 'react';
import {AvatarGroup} from "@mui/material";
import ChatAvatar, {DEFAULT_AVATAR_SIZE} from "./ChatAvatar";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

interface StyleProps {
    size: number;
}

const useStyles = (props: StyleProps) => makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            display: 'block',
            "& .MuiAvatarGroup-avatar": {
                width: props.size,
                height: props.size
            }
        }
    })
)();

interface ChatAvatarProps {
    authorNames: string[];
    avatarSize?: number;
}

export default function ChatAvatarGroup(props: ChatAvatarProps) {
    const {authorNames} = props;
    const avatarSize = props.avatarSize ?? DEFAULT_AVATAR_SIZE;

    const style = useStyles({
        size: avatarSize
    });

    return (
        <div className={style.avatar}>
            <AvatarGroup max={3}>
                {
                    authorNames.map((authorName, index) => <ChatAvatar key={index} name={authorName} avatarSize={avatarSize} />)
                }
            </AvatarGroup>
        </div>
    );
}
