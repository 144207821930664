import {DEFAULT_COLOR} from "../../common/UIConstants";
import {ColorDto} from "../../../apis/Color";

export class ColorRenderer {

    public static renderColor(color: ColorDto | null | undefined, defaultCss?: string) {
        if (color) {
            return color.a
                ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a / 100.0})`
                : `rgb(${color.r}, ${color.g}, ${color.b})`;
        } else if (defaultCss) {
            return defaultCss;
        } else {
            return DEFAULT_COLOR;
        }
    }
}