import React from "react";
import MultiselectComboBox, {LabeledComponent} from "./MultiselectComboBox";
import {IElementTypeDto} from "../../common/apis/Elements";
import ElementTypeIcon from "./ElementTypeIcon";
import {ArchimateLayer, ArchimateLayerType} from "../../common/archimate/ArchiMateLayer";
import {ArchimateElement} from "../../common/archimate/ArchimateElement";
import {_transl} from "../../store/localization/TranslMessasge";

interface Props {
    options: IElementTypeDto[],
    selectedValues: IElementTypeDto[],
    className?: string,
    id: string,
    label: string,
    handleOnChange: (changedValues: IElementTypeDto[]) => void
}

export default function MultiselectElementTypesComboBox(props: Props) {

    function getVisibleName(elementStandardName: string) {
        const layerType = ArchimateElement.findByStandardName(elementStandardName)?.layerType || ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS;
        return _transl(ArchimateLayer[layerType].translationKey);
    }

    function getSortedOptions(options: IElementTypeDto[]) {
        return options.sort((a, b) => {
            const aName = getVisibleName(a.name);
            const bName = getVisibleName(b.name);
            if (aName === bName) {
                return a.code.localeCompare(b.code)
            } else {
                return aName.localeCompare(bName);
            }
        });
    }

    const { selectedValues, className, id, label, handleOnChange, options } = props;

    return (
        <MultiselectComboBox options={getSortedOptions(options)}
                             selectedValues={selectedValues}
                             className={className}
                             id={id}
                             label={label}
                             getRenderLabel={(value) => {
                                 const label = ArchimateElement.findByStandardName(value.name)?.visibleName || "";
                                 const labeledComponent: LabeledComponent = {
                                     label: label,
                                     component: <span><ElementTypeIcon name={(value.name)}/> <span>{label}</span></span>
                                 }
                                 return labeledComponent;
                             }}
                             handleOnChange={handleOnChange}
                             groupBy={(option) => getVisibleName(option.name)}


        />
    )

}
