import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const elementsGridDivBorderColor = "lightgray";
const elementsGridDivBackgroundColor = "white";
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemsGridDiv: {
            color: "gray",
            backgroundColor: elementsGridDivBackgroundColor,
            padding: theme.spacing(1),
        },
        settingsDiv: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
            border: "1px solid " + elementsGridDivBorderColor,
            backgroundColor: elementsGridDivBackgroundColor,
            width: "100%",
            height: "100%",
        },
        wizardElementSelectionDescription: {
            display: "flex",
            alignItems: "center",
            gap: "1em",
        },
        explicitElementSelectionDiv: {
            marginTop: "1em",
        }
    })
);