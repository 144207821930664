import {ChatDto} from "../../../../../common/apis/chat/ChatService";
import {AvatarGroup, IconButton, Tooltip} from "@mui/material";
import ChatAvatar from "../../../../../components/chat/ChatAvatar";
import {ChatBubble, KeyboardArrowDown, KeyboardArrowRight} from "@mui/icons-material";
import {getUniqueUserFullNames} from "../../../../../components/chat/ChatPin";
import React, {useContext} from "react";
import {TimeAgoFormatter} from "../../../../../common/TimeAgoFormatter";
import LocaleContext, {Locale} from "../../../../../common/LocaleContext";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {ChatTranslationKey} from "../ChatTranslationKey";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatAccordionFooter: {
            display: 'flex',
            paddingTop: '12px',
        },
        avatarGroup: {
            display: 'flex',
            alignItems: 'end'
        },
        chatInfo: {
            paddingLeft: '8px',
            display: 'flex',
            alignItems: 'end'
        },
        chatBubbleSpan: {
            display: 'flex',
            alignContent: 'end',
            color: 'gray'
        },
        chatBubbleCommentsCount: {
            marginLeft: '4px',
        },
        lastReply: {
            color: 'gray',
            marginLeft: '8px'
        }
    })
);

interface IProps {
    chat: ChatDto,
    expanded: boolean
}

export default function ChatAccordionFooter(props: IProps) {
    const {chat, expanded} = props;

    const classes = useStyles();

    const {language} = useContext<Locale>(LocaleContext);
    const timeAgoFormatter = new TimeAgoFormatter(language);

    function getLastCommentTime() {
        const lastComment = chat.chatComments[chat.chatComments.length - 1];
        return new Date(lastComment.created);
    }

    function getTimeAgoForLastComment(): string {
        const lastComment = chat.chatComments[chat.chatComments.length - 1];
        return timeAgoFormatter.formatAsTimeAgo(new Date(lastComment.created));
    }

    return (
        <div className={classes.chatAccordionFooter}>
            {!expanded &&
                <Tooltip title={_transl(ChatTranslationKey.SHOW_REPLIES)} placement={"top"}>
                    <IconButton
                        color={"default"}
                        size={"small"}>
                        <KeyboardArrowRight/>
                    </IconButton>
                </Tooltip>}
            {expanded &&
                <Tooltip title={_transl(ChatTranslationKey.HIDE_REPLIES)} placement={"top"}>
                    <IconButton
                        color={"default"}
                        size={"small"}>
                        <KeyboardArrowDown/>
                    </IconButton>
                </Tooltip>}
            <AvatarGroup className={classes.avatarGroup}>
                {getUniqueUserFullNames(chat.chatComments).map((authorName, index) =>
                    <ChatAvatar avatarSize={24} key={index} name={authorName}/>)
                }
            </AvatarGroup>
            <div className={classes.chatInfo}>
                <span className={classes.chatBubbleSpan}>
                    <ChatBubble fontSize={"medium"}/>
                        <span className={classes.chatBubbleCommentsCount}>
                            {chat.chatComments.length - 1}
                        </span>
                </span>
                <Tooltip
                    title={chat.chatComments.length > 1 ? getLastCommentTime().toLocaleString(language) : ""}
                    placement={"top"}>
                <span className={classes.lastReply}>
                    {chat.chatComments.length > 1 &&
                        <span>
                            {_transl(ChatTranslationKey.LAST_REPLY) + " " + getTimeAgoForLastComment()}
                        </span>}
                </span>
                </Tooltip>
            </div>

        </div>
    );
}