

export function is(code: number, codeType: HTTPStatusCodeType) {
    return HTTPStatusCode[codeType].getCode() === code;
}

export function is1xx(code: number) {
    return code >= 100 && code < 200;
}

export function is2xx(code: number) {
    return code >= 200 && code < 300;
}

export function is3xx(code: number) {
    return code >= 300 && code < 400;
}

export function is4xx(code: number) {
    return code >= 400 && code < 500;
}

export function is5xx(code: number) {
    return code >= 500 && code < 600;
}


export enum HTTPStatusCodeType {
    CONTINUE = "CONTINUE",
    SWITCHING_PROTOCOLS = "SWITCHING_PROTOCOLS",
    PROCESSING = "PROCESSING",
    EARLY_HINTS = "EARLY_HINTS",
    OK = "OK",
    CREATED = "CREATED",
    ACCEPTED = "ACCEPTED",
    NON_AUTHORITATIVE_INFORMATION = "NON_AUTHORITATIVE_INFORMATION",
    NO_CONTENT = "NO_CONTENT",
    RESET_CONTENT = "RESET_CONTENT",
    PARTIAL_CONTENT = "PARTIAL_CONTENT",
    MULTI_STATUS = "MULTI_STATUS",
    ALREADY_REPORTED = "ALREADY_REPORTED",
    IM_USED = "IM_USED",
    MULTIPLE_CHOICES = "MULTIPLE_CHOICES",
    MOVED_PERMANENTLY = "MOVED_PERMANENTLY",
    FOUND = "FOUND",
    SEE_OTHER = "SEE_OTHER",
    NOT_MODIFIED = "NOT_MODIFIED",
    USE_PROXY = "USE_PROXY",
    SWITCH_PROXY = "SWITCH_PROXY",
    TEMPORARY_REDIRECT = "TEMPORARY_REDIRECT",
    PERMANENT_REDIRECT = "PERMANENT_REDIRECT",
    BAD_REQUEST = "BAD_REQUEST",
    UNAUTHORIZED = "UNAUTHORIZED",
    PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
    FORBIDDEN = "FORBIDDEN",
    NOT_FOUND = "NOT_FOUND",
    METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED",
    NOT_ACCEPTABLE = "NOT_ACCEPTABLE",
    PROXY_AUTHENTICATION_REQUIRED = "PROXY_AUTHENTICATION_REQUIRED",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
    CONFLICT = "CONFLICT",
    GONE = "GONE",
    LENGTH_REQUIRED = "LENGTH_REQUIRED",
    PAYLOAD_TOO_LARGE = "PAYLOAD_TOO_LARGE",
    URI_TOO_LONG = "URI_TOO_LONG",
    UNSUPPORTED_MEDIA_TYPE = "UNSUPPORTED_MEDIA_TYPE",
    RANGE_NOT_SATISFIABLE = "RANGE_NOT_SATISFIABLE",
    EXPECTATION_FAILED = "EXPECTATION_FAILED",
    I_AM_A_TEAPOT = "I_AM_A_TEAPOT",
    MISDIRECTED_REQUEST = "MISDIRECTED_REQUEST",
    UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY",
    LOCKED = "LOCKED",
    FAILED_DEPENDENCY = "FAILED_DEPENDENCY",
    TOO_EARLY = "TOO_EARLY",
    UPGRADE_REQUIRED = "UPGRADE_REQUIRED",
    PRECONDITION_FAILED = "PRECONDITION_FAILED",
    TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
    REQUEST_HEADER_FIELDS_TOO_LARGE = "REQUEST_HEADER_FIELDS_TOO_LARGE",
    UNAVAILABLE_FOR_LEGAL_REASONS = "UNAVAILABLE_FOR_LEGAL_REASONS",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
    BAD_GATEWAY = "BAD_GATEWAY",
    SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
    GATEWAY_TIMEOUT = "GATEWAY_TIMEOUT",
    HTTP_VERSION_NOT_SUPPORTED = "HTTP_VERSION_NOT_SUPPORTED",
    VARIANT_ALSO_NEGOTIATES = "VARIANT_ALSO_NEGOTIATES",
    INSUFFICIENT_STORAGE = "INSUFFICIENT_STORAGE",
    LOOP_DETECTED = "LOOP_DETECTED",
    NOT_EXTENDED = "NOT_EXTENDED",
    NETWORK_AUTHENTICATION_REQUIRED = "NETWORK_AUTHENTICATION_REQUIRED",
}

export default class HTTPStatusCode {

    public static readonly [HTTPStatusCodeType.CONTINUE] = new HTTPStatusCode(100);
    public static readonly [HTTPStatusCodeType.SWITCHING_PROTOCOLS] = new HTTPStatusCode(101);
    public static readonly [HTTPStatusCodeType.PROCESSING] = new HTTPStatusCode(102);
    public static readonly [HTTPStatusCodeType.EARLY_HINTS] = new HTTPStatusCode(103);
    public static readonly [HTTPStatusCodeType.OK] = new HTTPStatusCode(200);
    public static readonly [HTTPStatusCodeType.CREATED] = new HTTPStatusCode(201);
    public static readonly [HTTPStatusCodeType.ACCEPTED] = new HTTPStatusCode(202);
    public static readonly [HTTPStatusCodeType.NON_AUTHORITATIVE_INFORMATION] = new HTTPStatusCode(203);
    public static readonly [HTTPStatusCodeType.NO_CONTENT] = new HTTPStatusCode(204);
    public static readonly [HTTPStatusCodeType.RESET_CONTENT] = new HTTPStatusCode(205);
    public static readonly [HTTPStatusCodeType.PARTIAL_CONTENT] = new HTTPStatusCode(206);
    public static readonly [HTTPStatusCodeType.MULTI_STATUS] = new HTTPStatusCode(207);
    public static readonly [HTTPStatusCodeType.ALREADY_REPORTED] = new HTTPStatusCode(208);
    public static readonly [HTTPStatusCodeType.IM_USED] = new HTTPStatusCode(226);
    public static readonly [HTTPStatusCodeType.MULTIPLE_CHOICES] = new HTTPStatusCode(300);
    public static readonly [HTTPStatusCodeType.MOVED_PERMANENTLY] = new HTTPStatusCode(301);
    public static readonly [HTTPStatusCodeType.FOUND] = new HTTPStatusCode(302);
    public static readonly [HTTPStatusCodeType.SEE_OTHER] = new HTTPStatusCode(303);
    public static readonly [HTTPStatusCodeType.NOT_MODIFIED] = new HTTPStatusCode(304);
    public static readonly [HTTPStatusCodeType.USE_PROXY] = new HTTPStatusCode(305);
    public static readonly [HTTPStatusCodeType.SWITCH_PROXY] = new HTTPStatusCode(306);
    public static readonly [HTTPStatusCodeType.TEMPORARY_REDIRECT] = new HTTPStatusCode(307);
    public static readonly [HTTPStatusCodeType.PERMANENT_REDIRECT] = new HTTPStatusCode(308);
    public static readonly [HTTPStatusCodeType.BAD_REQUEST] = new HTTPStatusCode(400);
    public static readonly [HTTPStatusCodeType.UNAUTHORIZED] = new HTTPStatusCode(401);
    public static readonly [HTTPStatusCodeType.PAYMENT_REQUIRED] = new HTTPStatusCode(402);
    public static readonly [HTTPStatusCodeType.FORBIDDEN] = new HTTPStatusCode(403);
    public static readonly [HTTPStatusCodeType.NOT_FOUND] = new HTTPStatusCode(404);
    public static readonly [HTTPStatusCodeType.METHOD_NOT_ALLOWED] = new HTTPStatusCode(405);
    public static readonly [HTTPStatusCodeType.NOT_ACCEPTABLE] = new HTTPStatusCode(406);
    public static readonly [HTTPStatusCodeType.PROXY_AUTHENTICATION_REQUIRED] = new HTTPStatusCode(407);
    public static readonly [HTTPStatusCodeType.REQUEST_TIMEOUT] = new HTTPStatusCode(408);
    public static readonly [HTTPStatusCodeType.CONFLICT] = new HTTPStatusCode(409);
    public static readonly [HTTPStatusCodeType.GONE] = new HTTPStatusCode(410);
    public static readonly [HTTPStatusCodeType.LENGTH_REQUIRED] = new HTTPStatusCode(411);
    public static readonly [HTTPStatusCodeType.PRECONDITION_FAILED] = new HTTPStatusCode(412);
    public static readonly [HTTPStatusCodeType.PAYLOAD_TOO_LARGE] = new HTTPStatusCode(413);
    public static readonly [HTTPStatusCodeType.URI_TOO_LONG] = new HTTPStatusCode(414);
    public static readonly [HTTPStatusCodeType.UNSUPPORTED_MEDIA_TYPE] = new HTTPStatusCode(415);
    public static readonly [HTTPStatusCodeType.RANGE_NOT_SATISFIABLE] = new HTTPStatusCode(416);
    public static readonly [HTTPStatusCodeType.EXPECTATION_FAILED] = new HTTPStatusCode(417);
    public static readonly [HTTPStatusCodeType.I_AM_A_TEAPOT] = new HTTPStatusCode(418);
    public static readonly [HTTPStatusCodeType.MISDIRECTED_REQUEST] = new HTTPStatusCode(421);
    public static readonly [HTTPStatusCodeType.UNPROCESSABLE_ENTITY] = new HTTPStatusCode(422);
    public static readonly [HTTPStatusCodeType.LOCKED] = new HTTPStatusCode(423);
    public static readonly [HTTPStatusCodeType.FAILED_DEPENDENCY] = new HTTPStatusCode(424);
    public static readonly [HTTPStatusCodeType.TOO_EARLY] = new HTTPStatusCode(425);
    public static readonly [HTTPStatusCodeType.UPGRADE_REQUIRED] = new HTTPStatusCode(426);
    public static readonly [HTTPStatusCodeType.PRECONDITION_FAILED] = new HTTPStatusCode(428);
    public static readonly [HTTPStatusCodeType.TOO_MANY_REQUESTS] = new HTTPStatusCode(429);
    public static readonly [HTTPStatusCodeType.REQUEST_HEADER_FIELDS_TOO_LARGE] = new HTTPStatusCode(431);
    public static readonly [HTTPStatusCodeType.UNAVAILABLE_FOR_LEGAL_REASONS] = new HTTPStatusCode(451);
    public static readonly [HTTPStatusCodeType.INTERNAL_SERVER_ERROR] = new HTTPStatusCode(500);
    public static readonly [HTTPStatusCodeType.NOT_IMPLEMENTED] = new HTTPStatusCode(501);
    public static readonly [HTTPStatusCodeType.BAD_GATEWAY] = new HTTPStatusCode(502);
    public static readonly [HTTPStatusCodeType.SERVICE_UNAVAILABLE] = new HTTPStatusCode(503);
    public static readonly [HTTPStatusCodeType.GATEWAY_TIMEOUT] = new HTTPStatusCode(504);
    public static readonly [HTTPStatusCodeType.HTTP_VERSION_NOT_SUPPORTED] = new HTTPStatusCode(505);
    public static readonly [HTTPStatusCodeType.VARIANT_ALSO_NEGOTIATES] = new HTTPStatusCode(506);
    public static readonly [HTTPStatusCodeType.INSUFFICIENT_STORAGE] = new HTTPStatusCode(507);
    public static readonly [HTTPStatusCodeType.LOOP_DETECTED] = new HTTPStatusCode(508);
    public static readonly [HTTPStatusCodeType.NOT_EXTENDED] = new HTTPStatusCode(510);
    public static readonly [HTTPStatusCodeType.NETWORK_AUTHENTICATION_REQUIRED] = new HTTPStatusCode(511);


    public static values(): Array<HTTPStatusCode> {
        return Object.keys(HTTPStatusCodeType)
            .map(key => HTTPStatusCode[key as HTTPStatusCodeType])
            .sort((a, b) => a.getCode().toString().localeCompare(b.getCode().toString()));
    }

    public static valueOf(codeType: string): HTTPStatusCode | null {
        const existingValues = Object.keys(HTTPStatusCodeType)
            .filter((name) => name === codeType);
        return existingValues.length === 1 ? HTTPStatusCode[existingValues[0] as HTTPStatusCodeType] : null;
    }

    public static findByCode(code: number): HTTPStatusCode | null {
        const codes = HTTPStatusCode.values()
            .filter(value => value.getCode() === code);
        return codes.length === 0 ? codes[0]: null;
    }

    constructor(private readonly _code: number) {}

    public getCode(): number {
        return this._code;
    }

    public is1xx() {
        return is1xx(this.getCode());
    }

    public is2xx() {
        return is2xx(this.getCode());
    }

    public is3xx() {
        return is3xx(this.getCode());
    }

    public is4xx() {
        return is4xx(this.getCode());
    }

    public is5xx() {
        return is5xx(this.getCode());
    }

}
