import {IModelDto} from "./model/IModelDto";
import {ModelConverter} from "./model/ModelConverter";
import Api from "../Api";
import Imports from "./Imports";
import userService from "./UserService";
import {UserRoleType} from "../access/UserRoleType";
import {CollectionDto} from "./collection/CollectionDto";
import {UserDto} from "./user/UserDto";
import {PermissionLevelCode} from "../access/PermissionLevelCode";
import Constants from "../Constants";
import {AjaxResponse} from "rxjs/ajax";

class ImportsService {

    importModelJSON(model: IModelDto, user: UserDto, onSuccess: Function, onFailure: Function) {
        const diagramCollections = model.diagrams[0].diagramInfo.collections;
        if (user?.role === UserRoleType.ROLE_OPERATOR) {
            userService.getCollectionPermissionsByLogin(user.login)
                .then(value => {
                    let userCollections = value
                        .filter(collection => collection.permissionLevel.code === PermissionLevelCode.EDITOR)
                        .map(collection => collection.collection);
                    return this.importModel(model, this.intersection(userCollections, diagramCollections), onSuccess, onFailure);

                })
        } else {
            return this.importModel(model, diagramCollections, onSuccess, onFailure);
        }
    }

    private importModel(model: IModelDto, collectionDtos: CollectionDto[], onSuccess: Function, onFailure: Function) {
        const modelInputDto = ModelConverter.convertToModelInput(model,
            collectionDtos.map(collections => collections.code));
        return Api.createAjax({
            url: Imports.MODEL_JSON_IMPORT_URL,
            method: "POST",
            body: modelInputDto,
        }).toPromise()
            .then(() => onSuccess())
            .catch((error: any) => onFailure(error));
    }

    createCopyOfModelJSON(model: IModelDto, user: UserDto, onSuccess: Function, onFailure: Function) {
        const diagramCollections = model.diagrams[0].diagramInfo.collections;
        if (user?.role === UserRoleType.ROLE_OPERATOR) {
            userService.getCollectionPermissionsByLogin(user.login)
                .then(value => {
                    let userCollections = value
                        .filter(collection => collection.permissionLevel.code === PermissionLevelCode.EDITOR)
                        .map(collection => collection.collection);
                    return this.createCopyOfModel(model, this.intersection(userCollections, diagramCollections), onSuccess, onFailure);

                })
        } else {
            return this.createCopyOfModel(model, diagramCollections, onSuccess, onFailure);
        }
    }

    private createCopyOfModel(model: IModelDto, collectionDtos: CollectionDto[], onSuccess: Function, onFailure: Function) {
        const modelInputDto = ModelConverter.convertToModelInput(model,
            collectionDtos.map(collections => collections.code));
        return Api.createAjax({
            url: `${Constants.API_HOST}/rest-api/repository/model/copy`,
            method: "POST",
            body: modelInputDto,
        }).toPromise()
            .then((response: AjaxResponse) => {
                const responseBody = response.response;
                onSuccess(responseBody);
            })
            .catch(() => onFailure());
    }

    private intersection(arr1: CollectionDto[], arr2: CollectionDto[]): CollectionDto[] {
        return arr1.filter((val1) => {
            for (let val2 of arr2) {
                if (val1.code === val2.code) {
                    return true;
                }
            }
            return false;
        });
    }

}

const importsService = new ImportsService();
export default importsService;