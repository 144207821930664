import React from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import ArrayUtils from "../../common/ArrayUtils";
import {TextField} from "@mui/material";
import PickListDialog from "../dialogs/PickListDialog";
import EditableComponent, {EDIT_ROOT_SUBCOMPONENT_ROLE} from "./EditableComponent";

const styles = (theme: Theme) => createStyles({
    root: {
        position: "relative",
        display: "flex",
        "&> *": {
            flexGrow: 1,
        }
    },
});

interface IProps<T> extends WithStyles<typeof styles> {
    textFieldLabel: string,
    textFieldId?: string
    textFieldValueRenderer?: (values: Array<T>) => string,

    getId: (item: T) => string,
    pickListAvailableOptions: Array<T>,
    pickListPickedOptions: Array<T>,
    pickListGetOptionText: (item: T) => string,
    pickListDialogTitle?: string,
    pickListAvailableOptionsTitle?: string,
    pickListPickedOptionsTitle?: string,
    pickListOptionContentRenderer?: (item: T) => JSX.Element,
    pickListIsAvailableOptionDisabled?: (item: T) => boolean,
    pickListIsPickedOptionDisabled?: (item: T) => boolean,

    doUpdate: (items: Array<T>) => Promise<any>,
    onSuccessfulUpdate?: (items: Array<T>) => void,
    readonly?: boolean,
}

interface IState<T> {
    pickListOpened: boolean,
    changedOptions: Array<T>,
}

class EditablePickListComponent<T> extends React.Component<IProps<T>, IState<T>> {

    editInputRef = React.createRef<HTMLInputElement>();
    editRootRole: string;

    constructor(props: IProps<T>) {
        super(props);
        this.editRootRole = `${EDIT_ROOT_SUBCOMPONENT_ROLE}_${Math.random()}`;
        this.state = {
            pickListOpened: false,
            changedOptions: [...this.props.pickListPickedOptions],
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps<T>>, prevState: Readonly<IState<T>>, snapshot?: any) {
        const prevPickedOptions = prevProps.pickListPickedOptions;
        const actualPickedOptions = this.props.pickListPickedOptions;
        const optionsEqual = ArrayUtils.arraysEqualIgnoreOrder(prevPickedOptions, actualPickedOptions, this.props.getId);

        if (!optionsEqual) {
            this.updateState([{"propertyName": "changedOptions", value: actualPickedOptions}]);
        }
    }

    updateState<V extends keyof IState<T>>(newPropertyValues: Array<{propertyName: V, value: IState<T>[V]}>, callback?: () => void) {
        const stateCopy = {...this.state};
        newPropertyValues.forEach(propertyValue => stateCopy[propertyValue.propertyName] = propertyValue.value);
        this.setState(stateCopy, callback);
    }

    render() {
        // view props
        const { textFieldId, textFieldLabel, textFieldValueRenderer, getId, readonly } = this.props;
        // edit props
        const { pickListPickedOptions, pickListGetOptionText, doUpdate, onSuccessfulUpdate } = this.props;
        // state
        const { changedOptions } = this.state;

        const renderer = textFieldValueRenderer ||
            ((array: Array<T>) => ArrayUtils.joinOrElse(array.map(value => pickListGetOptionText(value)), ", ", " "));

        return (
            <EditableComponent label={textFieldLabel}
                               initialValue={pickListPickedOptions}
                               getEditedValue={() => changedOptions}
                               valueToViewString={renderer as (values: unknown) => string}
                               valuesEqual={(items, newItems) => ArrayUtils.arraysEqualIgnoreOrder(items as Array<T>, newItems as Array<T>, getId)}
                               getEditComponent={(cancelUpdate, saveChanges) => this.renderEditComponent(cancelUpdate, saveChanges)}
                               onEditModeEntered={() => this.updateState([{propertyName: "pickListOpened", value: true}])}
                               focusEditComponent={() => this.editInputRef.current?.focus()}
                               doUpdate={doUpdate as (value: unknown) => Promise<any>}
                               onSuccessfulUpdate={onSuccessfulUpdate as (value: unknown) => void}
                               onFailedUpdate={() => {}}
                               onCancelChanges={() => this.updateState([{propertyName: "changedOptions", value: pickListPickedOptions}])}
                               id={textFieldId}
                               editRootRole={this.editRootRole}
                               readonly={readonly}
            />
        );
    }

    renderEditComponent(cancelUpdate: () => void, saveChanges: () => void) {
        const { classes } = this.props;
        const { pickListOpened } = this.state;

        return (
            <div className={classes.root}>
                {this.renderTextField()}
                {
                    pickListOpened && this.renderPickList(cancelUpdate, saveChanges)
                }
            </div>
        );
    }

    private renderTextField(): JSX.Element {
        // view props
        const { textFieldId, textFieldLabel, textFieldValueRenderer } = this.props;
        // edit props
        const { pickListGetOptionText } = this.props;
        const { changedOptions } = this.state;

        const renderer = textFieldValueRenderer ||
            ((array: Array<T>) => ArrayUtils.joinOrElse(array.map(value => pickListGetOptionText(value)), ", ", " "));

        const value = renderer(changedOptions);

        return <TextField id={textFieldId}
                          key={value}
                          ref={this.editInputRef}
                          label={textFieldLabel}
                          defaultValue={value}
                          InputProps={{
                              readOnly: true,
                          }}
                          variant="outlined"
                          size={"small"}
                          onClick={() => () => this.updateState([{propertyName: "pickListOpened", value: true}])}/>
    }

    private renderPickList(cancelUpdate: () => void, saveChanges: () => void): JSX.Element {
        const { pickListOpened } = this.state;
        const { pickListDialogTitle, pickListAvailableOptionsTitle, pickListPickedOptionsTitle, pickListGetOptionText } = this.props;
        const { pickListAvailableOptions, pickListPickedOptions } = this.props;
        const { pickListOptionContentRenderer } = this.props;

        return <PickListDialog isOpened={pickListOpened}
                               role={this.editRootRole}
                               dialogTitle={pickListDialogTitle}
                               availableOptionsTitle={pickListAvailableOptionsTitle}
                               pickedOptionsTitle={pickListPickedOptionsTitle}
                               initialAvailableOptions={pickListAvailableOptions}
                               initialPickedOptions={pickListPickedOptions}
                               isPickedOptionDisabled={this.props.pickListIsPickedOptionDisabled}
                               isAvailableOptionDisabled={this.props.pickListIsAvailableOptionDisabled}
                               getOptionText={pickListGetOptionText as (item: unknown) => string}
                               optionContentRenderer={pickListOptionContentRenderer as (item: unknown) => JSX.Element}
                               onDialogClosed={() => cancelUpdate()}
                               onSaveChanges={(options) => this.saveChanges(options as T[], saveChanges)} />
    }

    private saveChanges(options: Array<T>, callback: () => void) {
        this.updateState([
            {propertyName: "changedOptions", value: options},
            {propertyName: "pickListOpened", value: false}
        ], callback)
    }

    private getItemIdsString(itemsArray: Array<T>) {
        const {getId} = this.props;
        let idsString;
        if (itemsArray == null) {
            idsString = "";
        } else {
            idsString = itemsArray.map(item => getId(item)).sort().join(",");
        }
        return idsString;
    }
}

export default withStyles(styles, { withTheme: true })(EditablePickListComponent);
