import React, {useState} from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderGroupParams,
    Checkbox,
    Chip,
    ClickAwayListener,
    Tooltip
} from "@mui/material";
import TextField from "./textfield/TextField";
import {FilterOptionsState} from "@mui/material/useAutocomplete";

export type LabeledComponent = {
    label: string,
    component: JSX.Element | string,
}


interface IProps<T> {
    options: T[],
    selectedValues: T[],
    className?: string,
    id: string,
    label?: string,
    helperText?: string;
    getRenderLabel: (value: T) => LabeledComponent | string,
    handleOnChange: (changedValues: T[]) => void,
    groupBy?: (option: T) => string,
    renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
    freeSolo?: boolean,
    filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[],
    required?: boolean,
    errorMessage?: string,
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small" color={"primary"}/>;

export default function MultiselectComboBox<T>(props: IProps<T>) {

    const [opened, setOpened] = useState<boolean>(false);

    function shouldShowTooltip() {
        return !opened && props.selectedValues.length > 0;
    }

    function constructTooltipTitle() {
        return <div>
            {props.selectedValues
                .map(option => renderLabel(option))
                .map(label => <div key={label}>{label}</div>)
            }
        </div>
    }

    function normalizeRenderLabel(option: T): LabeledComponent {
        if (props.getRenderLabel) {
            const renderedLabel = props.getRenderLabel(option);
            if (typeof renderedLabel === 'string') {
                return {
                    label: renderedLabel,
                    component: renderedLabel,
                }
            } else {
                return renderedLabel;
            }
        } else {
            const label = JSON.stringify(option);
            return {
                label: label,
                component: label,
            }
        }
    }

    function renderLabel(option: T): string {
        return normalizeRenderLabel(option).label;
    }

    function renderComponent(option: T): JSX.Element | string {
        return normalizeRenderLabel(option).component;
    }

    function getKey(option: T) {
        return renderLabel(option);
    }

    const isTooltipShown = shouldShowTooltip();

    function getTags(options: T[],
                     getTagProps: AutocompleteRenderGetTagProps) {
        const optionsLength = options.length;
        if (optionsLength > 0) {
            const firstOption = options[0];
            const label = renderComponent(firstOption) || renderLabel(firstOption);
            return <>
                <Chip
                    size={'small'}
                    label={label}
                    sx={createChipStyles()}
                    {...getTagProps({index: 0})}
                />
                {optionsLength > 1 && <span>+{optionsLength - 1}</span>}
            </>
        }
        return null;
    }

    function createChipStyles() {
        return {
            '& .MuiChip-label': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        };
    }
    function createTextFieldStyles() {
        return {
            '& .MuiOutlinedInput-root': {
                paddingRight: "85px!important",
                display: 'inline-flex',
                flexWrap: 'nowrap'
            },
        };
    }

    const autocomplete = <Autocomplete
        open={opened}
        groupBy={props.groupBy}
        renderGroup={props.renderGroup}
        disableCloseOnSelect={true}
        value={props.selectedValues}
        size={"small"}
        className={props.className}
        multiple={true}
        limitTags={1}
        freeSolo={props.freeSolo}
        id={props.id}
        options={props.options}
        forcePopupIcon={true}
        filterOptions={props.filterOptions}
        renderOption={(autProps, option, {selected}) => (
            <li {...autProps} key={`option_${getKey(option)}`}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 8}}
                    checked={selected}
                    color={"primary"}
                    key={`checkbox_${getKey(option)}`}
                />
                <span key={`label_${getKey(option)}`}>{renderComponent(option)}</span>
            </li>
        )}
        getOptionLabel={(option) => renderLabel(option as T)}
        onChange={(e, values) => {
            props.handleOnChange(values as T[]);
            e.stopPropagation();
        }}
        renderTags={(options, getTagProps) => {
            return getTags(options, getTagProps);
        }}
        renderInput={(params) => (
            <TextField {...params}
                       helperText={props.helperText}
                       variant="outlined"
                       required={props.required}
                       errorMessage={props.errorMessage}
                       label={props.label}
                       placeholder=""
                       InputLabelProps={{shrink: true}}
                       sx={createTextFieldStyles()}
            />
        )}
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
    />

    return (
        <ClickAwayListener onClickAway={() => setOpened(false)}>
            <div>
                {isTooltipShown &&
                    <Tooltip title={constructTooltipTitle()} arrow={true}>
                        {autocomplete}
                    </Tooltip>
                }
                {!isTooltipShown && autocomplete}
            </div>
        </ClickAwayListener>
    )
}
