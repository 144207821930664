import {ModelManager} from "../manager/ModelManager";
import SvgElementManager from "../manager/SvgElementManager";
import RenderMode from "./RenderMode";
import {IMode} from "../model/IMode";

export default class RenderContext {
    constructor(public readonly renderMode: IMode,
                public readonly svgElementManager: SvgElementManager,
                public readonly modelManager: ModelManager) {
    }

    public isEdit() {
        return this.renderMode.mode === RenderMode.EDIT;
    }

    public isPreEdit() {
        return this.renderMode.mode === RenderMode.PRE_EDIT;
    }

    public isEditOrPreEdit() {
        return this.isEdit() || this.isPreEdit();
    }

    public isPreviewOrDashboard() {
        return this.renderMode.mode === RenderMode.PREVIEW || this.renderMode.mode === RenderMode.DASHBOARD;
    }

}
