export enum ModelManagerTranslationKey {
    ALIGNMENT_ELEMENTS = "modelManager.alignmentElements",
    CREATE_ITEMS_IN_MODEL = "modelManager.createItemsInModel",
    REMOVE_ITEMS_IN_MODEL = "modelManager.removeItemsInModel",
    TRANSFER_OF_ELEMENTS = "modelManager.transferOfElements",
    CHANGE_SIZE_OF_ELEMENTS = "modelManager.changeSizeOfElements",
    CREATE_BENDPOINT = "modelManager.createBendPoint",
    REMOVE_BENDPOINT = "modelManager.removeBendPoint",
    TRANSFER_BENDPOINT = "modelManager.transferBendPoint",
    CHANGE_TITLE = "modelManager.changeTitle",
    CREATE_RELATIONSHIP = "modelManager.createRelationship",
    REMOVE_RELATIONSHIP = "modelManager.removeRelationship",
}