import {HierarchyTreeItemDetailDto} from "../service/HierarchyTreeItemDetailDto";
import hierarchyTreePathParser from "./HierarchyTreePathParser";
import {HierarchyTreeItemType} from "../service/HierarchyTreeItemType";

export interface HierarchyTreeController {
    findTreeItemByPath(treePathId: string, treeModelItems: HierarchyTreeItemDetailDto[]): HierarchyTreeItemDetailDto | null;

    findParentTreeAndRelativePath(rootTreeId: string, rootTreePath: string, treeModelItems: HierarchyTreeItemDetailDto[]): [string, string];

    filterOutChildrenOfCollapsedParents(oldExpanded: string[], newExpanded: string[]): string[];

    appendTreeItemsToPath(treeModelItemsToAppend: HierarchyTreeItemDetailDto[], treePathId: string, treeModelItems: HierarchyTreeItemDetailDto[]): HierarchyTreeItemDetailDto[];
}

class HierarchyTreeControllerImpl implements HierarchyTreeController {

    findTreeItemByPath(treePathId: string, treeModelItems: HierarchyTreeItemDetailDto[]) {
        const pathIds = hierarchyTreePathParser.getPathItems(treePathId);
        let pathId = pathIds.shift();
        let pathModelItems = treeModelItems;
        let treeModelItemToAppendTo: HierarchyTreeItemDetailDto | null = null;
        while (pathId != null) {
            const innerPathId = pathId;
            treeModelItemToAppendTo = pathModelItems.filter(item => item.id === innerPathId)[0];
            if (pathIds.length === 0) {
                break;
            } else {
                pathModelItems = treeModelItemToAppendTo.children;
                pathId = pathIds.shift();
            }

        }
        return treeModelItemToAppendTo;
    }

    findParentTreeAndRelativePath(rootTreeId: string, pathFromRoot: string, treeModelItems: HierarchyTreeItemDetailDto[]): [string, string] {
        const pathItems = hierarchyTreePathParser.getPathItems(pathFromRoot);
        let parentTreeId = rootTreeId;
        let relativePathItems = [];
        let treeItems = treeModelItems;
        for (const pathItem of pathItems) {
            relativePathItems.push(pathItem);
            const treeItem = treeItems.find(item => item.id === pathItem);
            if (treeItem) {
                if (treeItem.type === HierarchyTreeItemType.SUBTREE) {
                    parentTreeId = treeItem.id;
                    relativePathItems = [];
                }
                treeItems = treeItem.children;
            } else {
                break;
            }
        }

        return [parentTreeId, hierarchyTreePathParser.createPath(relativePathItems)];
    }

    filterOutChildrenOfCollapsedParents(oldExpanded: string[], newExpanded: string[]) {
        const collapsedIds = oldExpanded.filter(id => newExpanded.indexOf(id) === -1);
        for (const collapsedId of collapsedIds) {
            newExpanded = newExpanded.filter(id => id.indexOf(hierarchyTreePathParser.appendPathDelimiter(collapsedId)) === -1);
        }
        return newExpanded;
    }

    appendTreeItemsToPath(treeModelItemsToAppend: HierarchyTreeItemDetailDto[], treePathId: string, treeModelItems: HierarchyTreeItemDetailDto[]) {
        if (treePathId === "") {
            return treeModelItemsToAppend;
        }
        const treeModelItemsCopy = JSON.parse(JSON.stringify(treeModelItems));
        let treeModelItemToAppendTo = this.findTreeItemByPath(treePathId, treeModelItemsCopy);
        if (treeModelItemToAppendTo) {
            treeModelItemToAppendTo.children = treeModelItemsToAppend;
            treeModelItemToAppendTo.leaf = (treeModelItemsToAppend.length === 0);
        }
        return treeModelItemsCopy;
    }
}

const createHierarchyTreeController = function () {
    return new HierarchyTreeControllerImpl()
}
export default createHierarchyTreeController;
