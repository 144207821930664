import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {ConnectableConceptInfoDto} from "../../../../common/apis/relationship/ConnectableConceptInfoDto";

export function formatCommonElementName(pickedElement: ElementDto | ConnectableConceptInfoDto | undefined): string {
    if (pickedElement === undefined) {
        return "";
    } else if (!pickedElement.name) {
        return "[" + pickedElement.identifier + "]";
    } else {
        return pickedElement.name + " [" + pickedElement.identifier + "]";
    }
}