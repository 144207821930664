export class ClickHandler {
    private static readonly DBL_CLICK_TIMEOUT_MS = 400;
    private dblClickTimer: number | null = null;

    constructor(private _onClick?: (event: any) => void,
                private _onDblClick?: (event: any) => void) {}


    onClick(event: React.MouseEvent) {
        if (this.dblClickTimer)
        {
            clearTimeout(this.dblClickTimer);
            this.dblClickTimer = null;
            this._onDblClick && this._onDblClick(event);
        }
        else {
            this.dblClickTimer = window.setTimeout( () => {
                this.dblClickTimer = null;
                this._onClick && this._onClick(event);
            }, ClickHandler.DBL_CLICK_TIMEOUT_MS);
        }
    }
}

export class ClickHandlerBuilder {

    private _onClick?: (event: any) => void;
    private _onDblClick?: (event: any) => void;

    setOnClick(onClick: (event: any) => void) {
        this._onClick = onClick;
        return this;
    }

    setOnDblClick(onDblClick: (event: any) => void) {
        this._onDblClick = onDblClick;
        return this;
    }

    build() {
        return new ClickHandler(this._onClick, this._onDblClick);
    }

    public static builder() {
        return new ClickHandlerBuilder();
    }

}
