import RenderContext from "../context/RenderContext";
import MarkerDefinitionFactory, {MarkerType, USE_LINE_COLOR_FLAG} from "./marker/MarkerDefinitionFactory";
import MarkerUtils from "../util/MarkerUtils";
import * as d3 from 'd3';

export default class MarkersRenderer {

    private static markerDefinitionFactory: MarkerDefinitionFactory = MarkerDefinitionFactory.getInstance();

    static renderMarker(markerType: MarkerType,
                        lineColor: string,
                        context: RenderContext) {
        const existingMarker = d3.select(MarkersRenderer.getId(markerType, lineColor, true));

        if (existingMarker.empty()) {
            const defsGroup = context.svgElementManager.getDefsSelection();
            const markerDefinition = MarkersRenderer.markerDefinitionFactory.get(markerType);

            const marker = defsGroup.append("marker")
                .attr("id", d => MarkersRenderer.getId(markerType, lineColor))
                .attr("viewBox", markerDefinition.viewBox)
                .attr("refX", markerDefinition.refX)
                .attr("refY", markerDefinition.refY)
                .attr("markerWidth", markerDefinition.markerWidth)
                .attr("markerHeight", markerDefinition.markerHeight)
                .attr("orient", "auto")
                .attr("markerUnits", "userSpaceOnUse");

            let fill = "none";
            if (markerDefinition.fill) {
                fill = markerDefinition.fill === USE_LINE_COLOR_FLAG ? lineColor : markerDefinition.fill;
            }
            let stroke = "none";
            if (markerDefinition.stroke) {
                stroke = markerDefinition.stroke === USE_LINE_COLOR_FLAG ? lineColor : markerDefinition.stroke;
            }

            const path = marker.append("path");
            path.attr("d", markerDefinition.d);
            path.attr("fill", fill);
            path.attr("stroke", stroke);
            path.attr("stroke-width", 0.8)
            path.attr("stroke-dasharray", "none");
        }
    }

    static getId(markerType: MarkerType,
                 lineColor: string,
                 selector?: boolean) {
        return MarkerUtils.createMarkerId(markerType, lineColor, selector);
    }
}