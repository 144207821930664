import {ArchimateElement, ArchimateElementType} from "../../archimate/ArchimateElement";
import {ColorDto, Colors} from "../Color";
import Constants from "../../Constants";
import {ColorRenderer} from "../../diagrameditor/renderer/color/ColorRenderer";


export interface DefaultColorsDto {
    elementColors: {[key: string]: ColorDto};
}

export function getDefaultElementsColor(elementStandardName: string, defaultColors: DefaultColorsDto) {
    let substituteColor = Constants.ELEMENT_NOT_FOUND_COLOR;
    const archimateElement = ArchimateElement.findByStandardName(elementStandardName);
    const elementColor = archimateElement ? defaultColors.elementColors[archimateElement.elementType] : null;
    const existingColor = elementColor ? ColorRenderer.renderColor(elementColor) : null;

    return existingColor ? existingColor : substituteColor;
}

export function getDefaultElementsBgColor(elementStandardName: string, defaultColors: DefaultColorsDto) {
    let substituteColor = Constants.ELEMENT_NOT_FOUND_COLOR;
    const archimateElement = ArchimateElement.findByStandardName(elementStandardName);
    let elementColor: ColorDto | null = null;
    if (archimateElement) {
        if (archimateElement.elementType === ArchimateElementType.AND_JUNCTION) {
            elementColor = Colors.WHITE;
        } else {
            elementColor = defaultColors.elementColors[archimateElement.elementType];
        }
    }
    const existingColor = elementColor ? ColorRenderer.renderColor(elementColor) : null;

    return existingColor ? existingColor : substituteColor;
}
