import Constants from "../../../../../common/Constants";
import Api from "../../../../../common/Api";
import {AjaxRequest} from "rxjs/internal/observable/dom/AjaxObservable";
import {map} from "rxjs/operators";

export interface MetamodelConnectionDto {
    sourceType: string;
    targetType: string;
    relationshipTypes: string[];
}

export interface MetamodelDto {
    id: string;
    elements: string[];
    connections: MetamodelConnectionDto[];
}

export interface MetamodelService {
    findById(id: string): Promise<MetamodelDto>;
    generateMetamodel(selectedFile: File, selectedFileType: string): Promise<any>;
}

class RxJsGenerateMetamodelService implements MetamodelService {

    async findById(id: string): Promise<MetamodelDto> {
        const request: AjaxRequest = {
            url: `${Constants.REST_API_URL}/metamodels/${id}`,
            method: "GET"
        };
        return Api.createAjax(request).pipe(
                map(response => response.response as MetamodelDto)
            )
            .toPromise();
    }

    async generateMetamodel(selectedFile: File, formatId: string): Promise<any> {
        const request: AjaxRequest = {
            url: `${Constants.REST_API_URL}/metamodels/extract/${formatId}`,
            method: "POST",
            headers: {"Content-Type": "application/xml"},
            responseType: "blob",
            body: selectedFile,
        };
        return Api.createAjax(request).toPromise();
    }
}

export default new RxJsGenerateMetamodelService() as MetamodelService;