import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React, {useState} from "react";
import ElementsPickDialog from "../../elements/ElementsPickDialog";
import {
    GridAction,
    ActionButtonType,
    EnabledPolicy
} from "../../../../../components/grid/GridAction";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {PersistentStateId} from "../../../../../store/common/Grid";
import AlertDialog, {AlertDialogType} from "../../../../../components/dialogs/AlertDialog";
import {CancelButton} from "../../../../../components/button/CancelButton";
import {ElementDto} from "../../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../DiagramTranslationKey";
import diagramService from "../service/DiagramService";
import MultiselectComboBox from "../../../../../components/fields/MultiselectComboBox";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../../store/Store";
import {CollectionDto} from "../../../../../common/apis/collection/CollectionDto";
import {GridColumnDefinition} from "./GridColumnDefinition";
import {CommonTranslation} from "../../CommonTranslation";
import {UserRoleType} from "../../../../../common/access/UserRoleType";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import Grid from "../../../../../components/dialogs/Grid";
import {Button} from "@mui/material";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import CircularProgress from "../../../../../components/progress/CircularProgress";
import TextField from "../../../../../components/fields/textfield/TextField";
import ExtGridWrapper from "../../../../../components/grid/ExtGridWrapper";

enum ApiCallStatus {
    NOT_STARTED = "NOT_STARTED",
    STARTED = "STARTED",
    SUCCESSFUL = "SUCCESSFUL",
    FAILED = "FAILED",
}

enum ButtonId {
    ADD = "ADD",
    REMOVE = "REMOVE",
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addElementsLabel: {
            marginTop: "1em",
            marginBottom: "0.2em",
            fontWeight: "bolder",
            color: "gray",
        },
    }),
);

interface Props {
    // state
    isOpened: boolean,
    initiallyPickedElements?: Array<ElementDto>;

    // callbacks
    onDiagramCreated: (diagramIdentifier: string) => void,
    onDialogClosed: () => void,
}

type DiagramCreateDto = {
    name: string,
    description?: string,
    elementIds: Array<string>,
    collectionCodes: Array<string>,
}

type CreateDiagram = (diagramDto: DiagramCreateDto, onSuccess?: (identifier: string) => void, onFailure?: () => void) => void;

function useCreateDiagram(): [ApiCallStatus, CreateDiagram] {
    const [apiCallStatus, setApiCallStatus] = useState<ApiCallStatus>(ApiCallStatus.NOT_STARTED);

    function createDiagram(diagramDto: DiagramCreateDto, onSuccess?: (identifier: string) => void, onFailure?: () => void) {
        (async () => {
            setApiCallStatus(ApiCallStatus.STARTED);
            try {
                const name = diagramDto.name;
                const description = diagramDto.description ? diagramDto.description : "";
                const elementIds = diagramDto.elementIds;
                const collectionCodes = diagramDto.collectionCodes;
                const identifier = await diagramService.createDiagram(name, description, elementIds, collectionCodes);
                setApiCallStatus(ApiCallStatus.SUCCESSFUL);
                onSuccess && onSuccess(identifier);
            } catch (error) {
                setApiCallStatus(ApiCallStatus.FAILED);
                onFailure && onFailure();
            }
        })();
    }

    return [apiCallStatus, createDiagram];
}

export default function CreateDiagramDialog(props: Props) {

    const [apiCallStatus, createDiagram] = useCreateDiagram();
    const [name, setName] = useState<string>("");
    const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>();
    const [description, setDescription] = useState<string>("");
    const [selectedCollections, setSelectedCollections] = useState<CollectionDto[]>([]);
    const [collectionErrorMessage, setCollectionErrorMessage] = useState<string | undefined>();
    const [pickedElements, setPickedElements] = useState<Array<ElementDto>>(props.initiallyPickedElements || []);
    const [elementsPickDialogOpened, setElementsPickDialogOpened] = useState<boolean>(false);
    const [diagramFailedDialogOpened, setDiagramFailedDialogOpened] = useState<boolean>(false);
    const collections = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);
    const resolvedDialogTitle = _transl(DiagramTranslationKey.DIALOG_DIAGRAM_CREATE_TITLE);
    const saveButtonLabel = _transl(DiagramTranslationKey.BUTTON_CREATE_DIAGRAM_LABEL);
    const {isOpened, onDialogClosed, onDiagramCreated} = props;
    const classes = useStyles();
    const user = useSelector((state: IApplicationState) => state.user.userData);
    const isUserOperator = user?.role === UserRoleType.ROLE_OPERATOR;


    function areAllRequiredFieldsFilled() {
        let isValid = true;
        setNameErrorMessage(undefined);
        if (name.trim().length === 0) {
            setNameErrorMessage(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isValid = false;
        }
        setCollectionErrorMessage(undefined);
        if (isUserOperator && selectedCollections.length === 0) {
            setCollectionErrorMessage(_transl(CommonTranslation.FILL_OUT_AT_LEAST_ONE));
            isValid = false;
        }
        return isValid;
    }

    function getUniqueElements(existingElements: Array<ElementDto>, newElements: Array<ElementDto>) {
        const existingIds = existingElements.map(element => element.identifier);
        const filteredNewElements = newElements.filter(element => existingIds.indexOf(element.identifier) === -1);

        return [...existingElements, ...filteredNewElements];
    }

    function onDeletePickedElement(idsToRemove: Array<string>) {
        setPickedElements(pickedElements.filter(element => idsToRemove.indexOf(element.identifier) === -1))
    }

    function showElementsPickDialog(shown: boolean) {
        setElementsPickDialogOpened(shown);
    }

    function onCreateDiagram() {
        const collectionCodes = selectedCollections.map((collection) => collection.code)
        const dto: DiagramCreateDto = {
            name: name,
            description: description,
            collectionCodes: collectionCodes,
            elementIds: pickedElements.map(element => element.identifier),
        };
        if (areAllRequiredFieldsFilled()) {
            createDiagram(dto, (identifier: string) => onDiagramCreated(identifier), () => setDiagramFailedDialogOpened(true));
        }
    }

    return (
        <Dialog
            open={isOpened}
            aria-labelledby="create-diagram-dialog"
            onClose={onDialogClosed}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogTitle title={resolvedDialogTitle}
                         id="create-diagram-dialog-title"
                         onDialogClosed={onDialogClosed}
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField label={_transl(DiagramTranslationKey.DIALOG_DIAGRAM_CREATE_FIELD_NAME)}
                                   value={name}
                                   onChange={value => setName(value)}
                                   required={true}
                                   errorMessage={nameErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MultiselectComboBox
                            label={_transl(DiagramTranslationKey.FILTER_COLLECTIONS)}
                            id="collections-multiselectcombobox"
                            options={collections.filter(collection => collection.acl.canAssignObjectsToCollection)}
                            selectedValues={selectedCollections}
                            getRenderLabel={(value) => value.name}
                            handleOnChange={(changedValues) => setSelectedCollections(changedValues)}
                            required={isUserOperator}
                            errorMessage={collectionErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label={_transl(DiagramTranslationKey.DIALOG_DIAGRAM_CREATE_FIELD_DESCRIPTION)}
                                   value={description}
                                   multiline={true}
                                   rows={5}
                                   onChange={value => setDescription(value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {diagramFailedDialogOpened && <AlertDialog open={true}
                                                                   type={AlertDialogType.ERROR}
                                                                   title={_transl(DiagramTranslationKey.ALERT_DIALOG_CREATE_DIAGRAM_TITLE)}
                                                                   text={_transl(DiagramTranslationKey.ALERT_DIALOG_CREATE_DIAGRAM_TEXT)}
                                                                   onClose={() => setDiagramFailedDialogOpened(false)}/>}

                        {elementsPickDialogOpened && <ElementsPickDialog isOpened={true}
                                                                         isMultiSelection={true}
                                                                         onElementsPicked={(elements) => {
                                                                             setPickedElements(getUniqueElements(pickedElements, elements));
                                                                             showElementsPickDialog(false);
                                                                         }}
                                                                         onDialogClosed={() => showElementsPickDialog(false)}/>}
                        <div className={classes.addElementsLabel}>
                            {_transl(DiagramTranslationKey.DIALOG_DIAGRAM_CREATE_FIELD_ELEMENTS_SHOWN)}
                        </div>
                        <ExtGridWrapper
                            columns={GridColumnDefinition.getDefinition()}
                            rows={pickedElements}
                            rowCount={pickedElements.length}
                            getRowId={row => row.identifier}
                            peristentStateId={PersistentStateId.ADD_DIALOG_GRID}
                            resourceId={"elements"}
                            actions={[
                                GridAction.buttonBuilder(ButtonId.ADD, ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.ADD_ELEMENT),
                                    <AddIcon/>)
                                    .enabledPolicy(EnabledPolicy.ALWAYS)
                                    .onClick((selectedRowIds) => {
                                        showElementsPickDialog(true);
                                    }).build(),
                                GridAction.buttonBuilder(ButtonId.REMOVE, ActionButtonType.IMMEDIATE, _transl(DiagramTranslationKey.REMOVE_ELEMENT),
                                    <DeleteIcon/>)
                                    .onClick(((selectedRowIds) => {
                                        onDeletePickedElement(selectedRowIds as Array<string>);
                                    })).build(),
                            ]}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCreateDiagram()}
                        variant={"contained"}
                        color="primary"
                        disabled={apiCallStatus === ApiCallStatus.STARTED}
                >
                    {apiCallStatus === ApiCallStatus.STARTED &&
                        <CircularProgress/>}
                    {saveButtonLabel}
                </Button>
                <CancelButton onClick={onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );

}

