import React from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import ErrorIcon from '@mui/icons-material/Error';

const useLoginFailedPageStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        messageBox: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
            "& .MuiSvgIcon-root": {
                fill: theme.palette.error.dark,
                fontSize: "7rem",
            }
        },
        message: {
            display: "inline-block",
            textAlign: "center",
            width: "20em",
            fontSize: "1.3rem",
        },
    })
);

export default function LoginFailedPage() {
    const classes = useLoginFailedPageStyles();

    return <div className={classes.root}>
        <div className={classes.messageBox}>
            <span className={classes.message}>
                Single sign-on initialization failed
            </span>
            <ErrorIcon />
        </div>
    </div>

}
