import RenderContext from "../context/RenderContext";
import EventManager from "../../event/EventManager";
import {EventProperty, EventType} from "../../event/Event";
import * as d3 from "d3";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import GeometryUtils, {Point} from "../util/GeometryUtils";

export const CUSTOM_DRAG_HANDLER = "__DIAGRAM_CUSTOM_DRAG_HANDLER__"

export interface ICustomDragHandler {
    useSnappingFunction: boolean,
    publishStartEvent?: (event: DragEvent) => void,
    publishInProgressEvent?: (event: DragEvent) => void,
    publishEndEvent?: (event: DragEvent) => void,
    publishCancelEvent?: (event: DragEvent) => void,
    /*
     * When set then drag start event is published when drag distance is >= dragStartThreshold.
     * When not set and clickThreshold is set then defaults to clickThreshold + 1, otherwise defaults to SvgElementDragManager.DEFAULT_DRAG_START_THRESHOLD.
     * This means that when publishClickEvent handle is set and clickThreshold is NOT set then when dragStart = dragEnd and clickThreshold has not been exceeded
     * (i.e. when a click occurs) then both publishEndEvent and publishClickEvent handlers are called.
    */
    dragStartThreshold?: number,
    /*
     * When set and publishClickEvent is also set then click event is published when drag distance is <= clickThreshold.
     * When not set and publishClickEvent is set then defaults to Math.max(dragStartThreshold - 1, 0), otherwise
     * defaults to SvgElementDragManager.DEFAULT_CLICK_THRESHOLD
    */
    clickThreshold?: number,  // when dragStartThreshold is set then defaults to dragStartThreshold - 1
    publishClickEvent?: (event: DragEvent) => void,
    /* Coordinates of the specified event are computed relative to the specified coordinatesTarget, otherwise relative to the diagram group. */
    getCoordinatesTarget?: (event: any) => any,
    setTransformedEventCoordinates?: (event: any, transformedX: number, transformedY: number) => void,
}

export interface ICustomDragOnlyHandler extends ICustomDragHandler {
    publishStartEvent: (event: DragEvent) => void,
    publishInProgressEvent: (event: DragEvent) => void,
    publishEndEvent: (event: DragEvent) => void,
}

export interface ICustomClickOnlyHandler extends ICustomDragHandler {
    publishClickEvent: (event: DragEvent) => void,
}

export default class SvgElementDragManager {

    public static readonly DEFAULT_CLICK_THRESHOLD = 0;
    public static readonly DEFAULT_DRAG_START_THRESHOLD = 0;

    public static readonly CANVAS_DRAG_CLICK_THRESHOLD = 1;
    public static readonly CANVAS_DRAG_START_THRESHOLD = SvgElementDragManager.CANVAS_DRAG_CLICK_THRESHOLD + 1;

    private eventManager: EventManager;
    private dragHandler?: ICustomDragHandler;

    private startPointerPoint: Point;
    private endPointerPoint: Point;

    private isStartEventPublished: boolean;
    private dragStartThresholdExceeded: boolean;
    private clickThresholdExceeded: boolean;

    private isDragDisabled: boolean;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;

        this.startPointerPoint = new Point(0, 0);
        this.endPointerPoint = new Point(0, 0);
        this.dragStartThresholdExceeded = false;
        this.clickThresholdExceeded = false;
        this.isStartEventPublished = false;
        this.isDragDisabled = false;
    }

    destroy() {
    }

    public init(renderContext: RenderContext) {
        if (renderContext.isEditOrPreEdit()) {
            const svgElement = renderContext.svgElementManager.getSvgSelection();
            const svgElementNode = renderContext.svgElementManager.getSvg();
            const diagramNode = renderContext.svgElementManager.getDiagramGroupSelection().node() as SVGGElement;

            const drag = d3.drag()
                .filter(e => {
                    if (this.isDragDisabled) {
                        return false;
                    }
                    return true;
                })
                .on("start", (event: any) => {
                    if (this.dragHandler != null) {
                        // new drag started (e.g. right mouse button clicked) -> cancel old one and do not start new one, i.e. cancel both
                        // this can happen when cancalling node / connection resize using right mouse click
                        if (this.isStartEventPublished && SvgElementDragManager.isDragEventCancelled(event)) {
                            this.setTransformedEventCoordinates(event, this.endPointerPoint.x, this.endPointerPoint.y);
                            this.dragHandler?.publishCancelEvent && this.dragHandler.publishCancelEvent(event);
                        }
                    } else {
                        this.resolveDragHandler(event, diagramNode, svgElementNode);

                        this.setStartPointerPoint(event, diagramNode);
                        this.publishStartEventWhenThresholdExceeded(event);
                    }
                })
                .on("drag", (event: DragEvent) => {
                    this.setEndPointerPoint(event, diagramNode);

                    if (this.isStartEventPublished) {
                        this.setTransformedEventCoordinates(event, this.endPointerPoint.x, this.endPointerPoint.y);
                        this.dragHandler?.publishInProgressEvent && this.dragHandler.publishInProgressEvent(event);
                    } else {
                        this.publishStartEventWhenThresholdExceeded(event);
                    }
                })
                .on("end", (event: DragEvent) => {
                    this.setEndPointerPoint(event, diagramNode);

                    this.setTransformedEventCoordinates(event, this.endPointerPoint.x, this.endPointerPoint.y);
                    this.eventManager.publishEvent({type: EventType.CHART_MOUSE_UP, event: event});

                    if (!this.isStartEventPublished) {
                        this.publishStartEventWhenThresholdExceeded(event);
                    }
                    // resolve cancel / end
                    if (this.isStartEventPublished) {
                        if (SvgElementDragManager.isDragEventCancelled(event)) {
                            this.setTransformedEventCoordinates(event, this.endPointerPoint.x, this.endPointerPoint.y);
                            this.dragHandler?.publishCancelEvent && this.dragHandler.publishCancelEvent(event);
                        } else {
                            this.setTransformedEventCoordinates(event, this.endPointerPoint.x, this.endPointerPoint.y);
                            this.dragHandler?.publishEndEvent && this.dragHandler.publishEndEvent(event);
                        }
                    }
                    // resolve click
                    if (this.dragHandler?.publishClickEvent) {
                        if (!this.clickThresholdExceeded) {
                            this.setTransformedEventCoordinates(event, this.startPointerPoint.x, this.startPointerPoint.y);
                            this.dragHandler.publishClickEvent(event);
                        }
                    }

                    this.clean();
                });
            // @ts-ignore  <- TODO nevim jak to typove vyresit
            svgElement.call(drag);
        }
    }

    private static isDragEventCancelled(event: any) {
        if (!DiagramEditorUtils.isTouchDevice()) {
            // let user cancel drag action using non-left mouse button click
            return event.sourceEvent && event.sourceEvent.which !== 1;
        }
        // cancelling is not supported on touch devices as for now
        return false;
    }

    private setStartPointerPoint(event: any, diagramNode: SVGGElement) {
        const xy = this.getEventCoordinates(event, diagramNode);
        this.startPointerPoint.x = xy[0];
        this.startPointerPoint.y = xy[1];
        this.endPointerPoint.x = this.startPointerPoint.x;
        this.endPointerPoint.y = this.startPointerPoint.y;

        this.updateClickThresholdExceeded(event);
    }

    private setEndPointerPoint(event: any, diagramNode: SVGGElement) {
        let xy = this.getEventCoordinates(event, diagramNode);
        if (xy[0] == null || xy[1] == null) {
            // some touch screens fire drag end without coordinates -> use latest known
            xy = [this.endPointerPoint.x, this.endPointerPoint.y];
        }
        this.endPointerPoint.x = xy[0];
        this.endPointerPoint.y = xy[1];

        this.updateClickThresholdExceeded(event);
    }

    private getEventCoordinates(event: any, diagramNode: SVGGElement): [number, number] {
        const coordinatesTarget = this.resolveCoordinatesTarget(event, diagramNode);
        const pointers = d3.pointers(event, coordinatesTarget);
        return pointers[0];
    }

    private getActualDragDistance() {
        return GeometryUtils.getPointToPointDistance(this.startPointerPoint, this.endPointerPoint);
    }

    private clean() {
        this.startPointerPoint.x = 0;
        this.startPointerPoint.y = 0;
        this.endPointerPoint.x = 0;
        this.endPointerPoint.y = 0;
        this.dragStartThresholdExceeded = false;
        this.clickThresholdExceeded = false;
        this.isStartEventPublished = false;

        this.dragHandler = undefined;
    }

    private resolveDragHandler(event: any, diagramNode: SVGGElement, svgElementNode: SVGSVGElement) {
        const target = event.sourceEvent.target
        if (target && target[CUSTOM_DRAG_HANDLER]) {
            this.dragHandler = target[CUSTOM_DRAG_HANDLER];
        } else {
            this.dragHandler = this.createDefaultDragHandler(this.eventManager, diagramNode, svgElementNode);
        }
    }

    private createDefaultDragHandler(eventManager: EventManager,
                                     diagramNode: SVGGElement,
                                     svgElementNode: SVGSVGElement): ICustomDragHandler {
        return {
            useSnappingFunction: false,
            dragStartThreshold: SvgElementDragManager.CANVAS_DRAG_START_THRESHOLD,
            clickThreshold: SvgElementDragManager.CANVAS_DRAG_CLICK_THRESHOLD,
            publishStartEvent: (event: DragEvent) => {
                eventManager.publishEvent({
                    type: EventType.CHART_CANVAS_DRAG_STARTED,
                    event: event,
                });
            },
            publishInProgressEvent: (event: DragEvent) => {
                d3.select(svgElementNode).attr("cursor", "crosshair");
                eventManager.publishEvent({
                    type: EventType.CHART_CANVAS_DRAG_IN_PROGRESS,
                    event: event,
                });
            },
            publishEndEvent: (event: DragEvent) => {
                d3.select(svgElementNode).attr("cursor", "auto");
                eventManager.publishEvent({
                    type: EventType.CHART_CANVAS_DRAG_FINISHED,
                    event: event,
                });
            },
            publishCancelEvent: (event: DragEvent) => {
                d3.select(svgElementNode).attr("cursor", "auto");
                eventManager.publishEvent({
                    type: EventType.CHART_CANVAS_DRAG_CANCELLED,
                    event: event,
                });
            },
            publishClickEvent: (event: DragEvent) => {
                eventManager.publishEvent({
                    type: EventType.CHART_CANVAS_CLICKED,
                    event: event,
                });
            },
            getCoordinatesTarget: (event: DragEvent) => {
                return diagramNode;
            },
            setTransformedEventCoordinates: (event: any, transformedX: number, transformedY: number) => {
                event[EventProperty.TRANSFORMED_X_COORDINATE] = transformedX;
                event[EventProperty.TRANSFORMED_Y_COORDINATE] = transformedY;
            }
        };
    }

    private resolveCoordinatesTarget(event: DragEvent, diagramNode: SVGGElement) {
        if (this.dragHandler && this.dragHandler.getCoordinatesTarget) {
            return this.dragHandler.getCoordinatesTarget(event);
        } else {
            return diagramNode;
        }
    }

    private publishStartEventWhenThresholdExceeded(event: DragEvent) {
        if (this.dragHandler?.publishStartEvent) {
            if (!this.isStartEventPublished) {
                const dragStartThreshold = this.resolveDragStartThreshold(event);
                const actualDistance = this.getActualDragDistance();
                if (actualDistance >= dragStartThreshold) {
                    this.setTransformedEventCoordinates(event, this.startPointerPoint.x, this.startPointerPoint.y);
                    this.dragHandler.publishStartEvent(event);
                    this.isStartEventPublished = true;
                }
            }
        }
    }

    private updateClickThresholdExceeded(event: DragEvent) {
        if (this.dragHandler && this.dragHandler.publishClickEvent) {
            const clickThreshold = this.resolveClickThreshold(event);
            if (GeometryUtils.getPointToPointDistance(this.startPointerPoint, this.endPointerPoint) > clickThreshold) {
                this.clickThresholdExceeded = true;
            }
        }

    }

    private resolveDragStartThreshold(event: DragEvent) {
        if (this.dragHandler?.dragStartThreshold != null) {
            return this.dragHandler.dragStartThreshold;
        } else {
            if (this.dragHandler?.clickThreshold != null) {
                return this.dragHandler?.clickThreshold + 1;
            } else {
                return SvgElementDragManager.DEFAULT_DRAG_START_THRESHOLD;
            }
        }
    }

    private resolveClickThreshold(event: DragEvent) {
        if (this.dragHandler?.clickThreshold != null) {
            return this.dragHandler.clickThreshold;
        } else {
            if (this.dragHandler?.dragStartThreshold != null) {
                return Math.max(this.dragHandler.dragStartThreshold - 1, 0);
            } else {
                return SvgElementDragManager.DEFAULT_CLICK_THRESHOLD;
            }
        }
    }

    private setTransformedEventCoordinates(event: DragEvent, x: number, y: number) {
        if (this.dragHandler?.setTransformedEventCoordinates) {
            this.dragHandler.setTransformedEventCoordinates(event, x, y);
        }
    }

}
