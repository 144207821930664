import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React from "react";
import { Transition } from 'react-transition-group';

const defaultDuration = 200;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            overflow: "hidden",
        },
    })
);

interface Props {
    opened: boolean,
    duration?: number,
    children: any,
}

export default function TableCellActionPanel({ opened, children, duration }: Props) {

    const classes = useStyles();

    function getDefaultTransitionStyles() {
        const duration = getDuration();
        return {
            transition: `width ${duration}ms ease-in-out`,
            width: 0,
        }
    }

    function getTransitionStyles() {
        return {
            entering: { width: "100%" },
            entered:  { width: "100%" },
            exiting:  { width: 0 },
            exited:  { width: 0 },
            unmounted: {},
        };
    }

    function getDuration() {
        return duration || defaultDuration;
    }

    return (
        <Transition in={opened} timeout={getDuration()}>
            {state => (
                <div className={classes.root} style={{
                    ...getDefaultTransitionStyles(),
                    ...getTransitionStyles()[state],
                }}>
                    {children}
                </div>
            )}
        </Transition>
    )


}
