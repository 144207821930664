import * as d3 from "d3";
import {GraphDataChart} from "../GraphDataChart";
import {ILink} from "../manager/ModelManager";
import EventManager from "../manager/EventManager";
import {_transl} from "../../../store/localization/TranslMessasge";
import {GraphChartTranslationKey} from "../GraphChartTranslationKey";

export default class LinksRenderer {

    public static readonly LINK_STROKE_WIDTH = 1.5;

    private eventManager: EventManager;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
    }

    public render(chartGroup: d3.Selection<any, unknown, null, undefined>,
                  links: ILink[])
    {
        const linkElems = chartGroup.append("g")
            .attr("fill", "none")
            .attr("stroke-width", LinksRenderer.LINK_STROKE_WIDTH)
            .selectAll("path")
            .data(links)
            .join("path")
            .attr("id", d => LinksRenderer.createLinkId(d))
            .attr("stroke", GraphDataChart.LINK_COLOR)
            .attr("marker-end", d => `url(${new URL(`#arrow`, window.location.href)})`)
            .on("mouseover", (event: any, link) => {
                d3.select("#" + LinksRenderer.createLinkId(link)).attr("stroke", "black");
            })
            .on("mouseout", (event, link) => {
                d3.select("#" + LinksRenderer.createLinkId(link)).attr("stroke", GraphDataChart.LINK_COLOR);
            });
        // append title to links
        linkElems.append("title")
            .text(d => LinksRenderer.formatLink(d));

        // append texts to links
        chartGroup.selectAll("text")
            .data(links)
            .join("text")
            .append("textPath")
            .attr("text-anchor", "middle")
            .attr("startOffset", "50%")
            .attr("font-size", "80%")
            .attr("xlink:href",d => "#" + LinksRenderer.createLinkId(d))
            .text(d => LinksRenderer.formatRelationshipType(d))
            .clone(true).lower()
            .attr("fill", "none")
            .attr("stroke", "white")
            .attr("stroke-width", 2);

        // append title to link texts
        chartGroup.selectAll("text")
            .data(links)
            .append("title")
            .text(d => LinksRenderer.formatLink(d));

        return linkElems;
    }

    public static formatLink(d: ILink) {
        return LinksRenderer.formatRelationshipType(d) + "\n" + LinksRenderer.formatSourceAndTarget(d);
    }

    public static formatRelationshipType(d: ILink) {
        if (d.archimateRelationship) {
            return d.archimateRelationship.visibleName;
        } else {
            return d.type;
        }
    }

    public static formatSourceAndTarget(d: ILink) {
        const source = _transl(GraphChartTranslationKey.SOURCE);
        const sourceName = d.source.name || "";
        const target = _transl(GraphChartTranslationKey.TARGET);
        const targetName = d.target.name || "";
        return `${source}: ${sourceName}\n${target}: ${targetName}`;
    }

    public static createLinkId(link: ILink) {
        return "linkid-"+link.id;
    }

}