import diagramService from "../diagrams/service/DiagramService";
import chatService, {ChatDto} from "../../../../common/apis/chat/ChatService";
import {ChatCoordinatesCorrector} from "./ChatCoordinatesCorrector";
import {IDiagramNodeDto} from "../../../../common/apis/diagram/IDiagramNodeDto";

export class ChatCoordinatesManager {

    private chatCoordinatesCorrector = new ChatCoordinatesCorrector();

    public async fixAllChatCoordinatesForDiagram(diagramId: string) {
        const results = await Promise.all([
            diagramService.findDiagramById(diagramId),
            chatService.searchChatsByFilter({diagramId: diagramId})
        ]);
        const diagram = results[0];
        const chats = results[1];

        await this.fixChatCoordinates(chats, diagram.nodes);
    }

    public async fixChatCoordinatesForDiagram(diagramId: string, diagramNodes: IDiagramNodeDto[]) {
        const chats = await chatService.searchChatsByFilter({diagramId: diagramId});
        await this.fixChatCoordinates(chats, diagramNodes);
    }

    private async fixChatCoordinates(chats: ChatDto[], diagramNodes: IDiagramNodeDto[]) {
        const fixedChatCoordinates = this.chatCoordinatesCorrector.correctChatCoordinates(diagramNodes, chats);
        const updatePromises = fixedChatCoordinates.map(chatCoordinates => {
            return chatService.updateChatCoordinates(chatCoordinates.chatId, {
                x: chatCoordinates.x,
                y: chatCoordinates.y
            });
        });
        return Promise.all(updatePromises);
    }
}