import React, {useContext, useState} from "react";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {CommonTranslation} from "../CommonTranslation";
import Snackbar from "../snackbar/Snackbar";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import diagramService from "./service/DiagramService";
import {getLabelForPropertyType, propertyTypes} from "../property/PropertyType";
import DialogActions from "../../../../components/dialogs/DialogActions";
import Grid from "../../../../components/dialogs/Grid";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogContent from "../../../../components/dialogs/DialogContent";
import TextField from "../../../../components/fields/textfield/TextField";
import {IPropertyDefinition} from "../../../../common/apis/CommonTypes";
import SingleselectComboBox from "../../../../components/fields/SingleselectComboBox";
import SelectField from "../../../../components/fields/SelectField";
import DiagramPropertyDefinitionContext, {
    DiagramPropertyDefinition
} from "../../../../common/DiagramPropertyDefinitionContext";

export interface DiagramPropertyCreateDialogProps {
    open: boolean;
    diagramId: string;
    onPropertyCreated: () => void;
    onClosed: () => void;
    existingPropertyNames: Set<string>;
}

export function DiagramPropertyCreateDialog(props: DiagramPropertyCreateDialogProps) {
    const {open, diagramId, onClosed, onPropertyCreated, existingPropertyNames} = props;

    const [propertyDefinitionIdentifier, setPropertyDefinitionIdentifier] = useState<string | undefined>(undefined);
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [value, setValue] = useState("");

    const [nameValidationMsg, setNameValidationMsg] = useState<string | undefined>(undefined);
    const [typeValidationMsg, setTypeValidationMsg] = useState<string | undefined>(undefined);
    const [valueValidationMsg, setValueValidationMsg] = useState<string | undefined>(undefined);

    const diagramPropertyDefinitionContext = useContext<DiagramPropertyDefinition>(DiagramPropertyDefinitionContext);
    const propertyDefinitions = diagramPropertyDefinitionContext.propertyDefinitions;

    function onDialogClosed() {
        setName("");
        setNameValidationMsg(undefined);
        setTypeValidationMsg(undefined);
        setValue("");
        setType("");
        onClosed();
    }

    function validateName(name: string): boolean {
        let isValid = !(name.trim().length === 0);
        setNameValidationMsg(isValid ? undefined : _transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        if (isValid && existingPropertyNames.has(name)) {
            setNameValidationMsg(_transl(ErrorTranslationKey.FAILED_TO_ADD_DUPLICATE_ATTRIBUTE));
            isValid = false;
        }
        return isValid;
    }

    function validateValue(text: string): boolean {
        const isValid = !(value.trim().length === 0);
        setValueValidationMsg(isValid ? undefined : _transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        return isValid;
    }

    function validateType(text: string): boolean {
        const isValid = !(type.trim().length === 0);
        setTypeValidationMsg(isValid ? undefined : _transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        return isValid;
    }

    function isFormValid() {
        const isNameValid = validateName(name);
        const isTypeValid = validateType(type);
        const isValueValid = validateValue(value);

        return isNameValid && isTypeValid && isValueValid;
    }

    function saveChanges() {
        if (isFormValid()) {
            diagramService.createDiagramProperty(diagramId, name, type, value, propertyDefinitionIdentifier)
                .then(() => {
                    diagramPropertyDefinitionContext.updatePropertyDefinitions();
                    onDialogClosed();
                    onPropertyCreated();
                })
                .catch(() => {
                    Snackbar.error(_transl(ErrorTranslationKey.UNEXPECTED_ERROR_OCCURRED));
                });
        }
    }

    function updateName(newValue: IPropertyDefinition | string) {
        if (typeof newValue === 'string') {
            setName(newValue);
            setPropertyDefinitionIdentifier(undefined);
        } else {
            const propertyDefinition = newValue as IPropertyDefinition;
            setPropertyDefinitionIdentifier(propertyDefinition.identifier);
            setName(propertyDefinition.name);
            setType(propertyDefinition.type.toLowerCase());
        }
    }

    return (
        <React.Fragment>
            {propertyDefinitions &&
                <Dialog
                    open={open}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    onClose={() => onDialogClosed()}
                    fullWidth={true}
                    maxWidth={"xs"}
                >
                    <DialogTitle id="scroll-dialog-title"
                                 title={_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_ADDITION)}
                                 onDialogClosed={onDialogClosed}/>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <SelectField
                                    id="name-field"
                                    value={name}
                                    label={_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_NAME)}
                                    freeSolo={true}
                                    required={true}
                                    errorMessage={nameValidationMsg}
                                    options={propertyDefinitions}
                                    onChange={(newValue: IPropertyDefinition | string) => updateName(newValue)}
                                    getOptionLabel={option => (option as IPropertyDefinition).name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SingleselectComboBox id={"type-field"}
                                                      options={propertyTypes.map(propertyType => propertyType.value)}
                                                      selectedValue={type}
                                                      getRenderLabel={propertyTypeValue => getLabelForPropertyType(propertyTypeValue)!}
                                                      label={_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_TYPE)}
                                                      handleOnChange={(type) => setType(type)}
                                                      errorMessage={typeValidationMsg}
                                                      required={true}
                                                      disabled={propertyDefinitionIdentifier !== undefined}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="value-field"
                                           label={_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_VALUE)}
                                           variant="outlined"
                                           size={"small"}
                                           required={true}
                                           errorMessage={valueValidationMsg}
                                           onChange={(value) => setValue(value)}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton onClick={(e) => saveChanges()}/>
                        <CancelButton onClick={() => onDialogClosed()} variant={"outlined"}/>
                    </DialogActions>
                </Dialog>}
        </React.Fragment>
    );
}
