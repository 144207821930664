export const USER_LOGIN_MAX_LENGTH = 200;
export const USER_FIRSTNAME_MAX_LENGTH = 200;
export const USER_LASTNAME_MAX_LENGTH = 200;
export const USER_EMAIL_MAX_LENGTH = 256;
export const USER_ROLE_MAX_LENGTH = 128;
export const USER_PASSWORD_MAX_LENGTH = 128;

const LOGIN_MATCHER = /^[A-Za-z0-9.@_-]*$/;
const EMAIL_MATCHER = /^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,6}$/;

export class UserValidator {
    isLoginError(login : string) {
        return login.length < 1 || login.length > USER_LOGIN_MAX_LENGTH || login.toUpperCase().match(LOGIN_MATCHER) === null;
    }
    isEmailError(email : string) {
        return email.length > USER_EMAIL_MAX_LENGTH || email === "" || email.toUpperCase().match(EMAIL_MATCHER) === null;
    }

    isFirstnameError(firstname : string) {
        return firstname.length > USER_FIRSTNAME_MAX_LENGTH;
    }

    isLastnameError(lastname : string) {
        return lastname.length > USER_LASTNAME_MAX_LENGTH;
    }

    isRoleError(role : string) {
        return role.length < 1 || role.length > USER_ROLE_MAX_LENGTH;
    }

    isNewPasswordError(password : string) {
        return password.length < 4 || password.length > USER_PASSWORD_MAX_LENGTH;
    }

    isRepeatedPasswordError(password : string, repeatedPassword : string) {
        return password.normalize("NFC") !== repeatedPassword.normalize("NFC") || password === "";
    }

}