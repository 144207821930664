import {CircularProgress as MuiCircularProgress, Grid, Typography} from "@mui/material";

export interface CircularProgressProps {
    variant?: "indeterminate" | "determinate",
    value?: number,
    text?: string
}

export default function CircularProgress({variant, value, text}: CircularProgressProps) {
    return <>
    <Grid container alignItems={"center"} justifyContent={"center"} spacing={1}>
        <Grid item>
            <MuiCircularProgress variant={variant} value={value} />
        </Grid>
        {text &&
            <Grid item xs={12}>
                <Typography variant="body2" color={"textSecondary"} align={"center"}>
                    {text}
                </Typography>
            </Grid>
        }
    </Grid>
    </>;
}
