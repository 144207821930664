import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import React, {useState} from "react";
import GraphQueryGrid, {GraphQueryGridProps} from "./GraphQueryGrid";
import RenderMode from "../diagrams/RenderMode";
import GraphQueryFilter from "./GraphQueryFilter";
import {GraphQueryFilterDto} from "../../../../common/apis/query/GraphQueryFilterDto";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
    })
);

export interface GraphQueryGridWithFilterProps extends GraphQueryGridProps {
}

export default function GraphQueryGridWithFilter({mode, onGridRowChange, resourceId}: GraphQueryGridWithFilterProps) {

    const classes = useStyles();
    const [queryFilter, setQueryFilter] = useState<GraphQueryFilterDto>();

    return (
        <>
            <div className={classes.controlPageSegment}>
                <GraphQueryFilter onFilterChange={setQueryFilter}
                                  renderMode={RenderMode.PAGE}/>
            </div>
            <Divider/>
            <div className={classes.controlPageSegment}>
                <GraphQueryGrid queryFilter={queryFilter}
                                mode={mode}
                                onGridRowChange={onGridRowChange}
                                resourceId={resourceId}
                />
            </div>
        </>
    );
}
