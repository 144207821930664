import {ModelManager} from "../../manager/ModelManager";
import * as d3 from "d3";
import {Area} from "../../util/GeometryUtils";
import {TEXTAREA_CLASS_NAME} from "./AbstractNodeRenderer";
import {POINTER_EVENTS_CLASS_NAME} from "./ConfigurableNodeRenderer";
import {DiagramEditorUtils} from "../../util/DiagramEditorUtils";
import ChartGroup, {ChartGroupType} from "../../common/ChartGroup";
import {ArchimateElementType} from "../../../archimate/ArchimateElement";
import store from "../../../../store/Store";
import Constants from "../../../Constants";
import {
    DEFAULT_FONT_COLOR,
    DEFAULT_FONT_FAMILY,
    DEFAULT_FONT_SIZE,
    DEFAULT_FONT_STYLE,
    DEFAULT_LINE_HEIGHT
} from "../../common/UIConstants";
import {PointIncrement} from "../../common/PointIncrement";
import {NodeType} from "../../../apis/diagram/NodeType";
import {ColorRenderer} from "../color/ColorRenderer";
import {DiagramNode} from "../../model/Model";
import {getDefaultElementsColor} from "../../../apis/diagram/DefaultColorsDto";

export default class NodeRendererUtils {

    public static readonly DIAGRAM_SYMBOL_ID = "__symbol_diagram__";

    private static readonly NODE_AREA_PROPERTY_NAME = "__node-area__";

    public static readonly CONTAINER_FILL_COLOR = "rgb(210, 214, 214)";
    public static readonly GROUP_BACKGROUND_COLOR = "rgb(196, 196, 196)";
    public static readonly NOTE_FILL_COLOR = "white";
    public static readonly DIAGRAM_REF_COLOR = "rgb(220, 235, 235)";

    public static getNodeFillColor(node: DiagramNode, modelAccessor: ModelManager) {
        if (node.type === NodeType.CONTAINER) {
            return NodeRendererUtils.CONTAINER_FILL_COLOR;
        } else if (DiagramEditorUtils.isNoteNode(node)) {
            return NodeRendererUtils.NOTE_FILL_COLOR;
        } else if (DiagramEditorUtils.isDiagramReferenceNode(node)) {
            return NodeRendererUtils.DIAGRAM_REF_COLOR;
        } else {
            const elementId = node.elementIdentifier;
            const element = elementId ? modelAccessor.getElementById(elementId) : null;

            return element ? getDefaultElementsColor(element.type, store.getState().diagramDefaults.defaultColors) : Constants.ELEMENT_NOT_FOUND_COLOR;
        }
    }

    static moveNodes(nodes: Array<DiagramNode>, increment: PointIncrement) {
        nodes.forEach(node => {
            const nodeGroupId = NodeRendererUtils.createNodeOrderGroupId(node, true);
            const existingTransform = d3.select(nodeGroupId)
                .attr("transform") || "";
            d3.select(nodeGroupId)
                .attr("transform", `${existingTransform} translate(${increment.incrementX} ${increment.incrementY})`);
        })
    }

    static getNodesGroup() {
        const nodesGroup = NodeRendererUtils.createNodesGroupId(true);
        return d3.select(nodesGroup) as d3.Selection<SVGGElement, DiagramNode, HTMLElement, undefined>;
    }

    static getNodeOrderGroup(node: DiagramNode) {
        return NodeRendererUtils.getNodeOrderGroupByNodeId(node.identifier);
    }

    static getNodeOrderGroupByNodeId(nodeIdentifier: string) {
        const nodeGroupId = NodeRendererUtils.createNodeOrderGroupIdByNodeId(nodeIdentifier, true);
        return d3.select(nodeGroupId) as d3.Selection<SVGGElement, DiagramNode, HTMLElement, undefined>;
    }

    static createNodesGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.NODES_GROUP, selector);
    }

    static createNodeOrderGroupId(node: DiagramNode, selector?: boolean) {
        return NodeRendererUtils.createNodeOrderGroupIdByNodeId(node.identifier, selector);
    }

    static createNodeShapeGroupId(node: DiagramNode, selector?: boolean) {
        return (selector === true ? "#" : "") + "__node-shape-group-" + node.identifier + "__";
    }

    static createNodeOrderGroupIdByNodeId(nodeIdentifier: string, selector?: boolean) {
        return (selector === true ? "#" : "") + "__node-order-group-" + nodeIdentifier + "__";
    }

    public static createFontSize(node: DiagramNode) {
        const size = node.style?.font?.size;
        if (size) {
            return size as number;
        }
        return DEFAULT_FONT_SIZE as number;
    }

    public static createFontColor(node: DiagramNode) {
        const color = node.style?.font?.color;
        return ColorRenderer.renderColor(color, DEFAULT_FONT_COLOR);
    }

    public static createFontFamily(node: DiagramNode) {
        const fontName = node.style?.font?.name;
        if (fontName) {
            return fontName + ", " + DEFAULT_FONT_FAMILY.join(", ");
        }
        return DEFAULT_FONT_FAMILY.join(", ");
    }

    public static createLineHeight() {
        return DEFAULT_LINE_HEIGHT;
    }

    public static createFontStyle(node: DiagramNode) {
        const styles = node.style?.font?.styles;
        if (styles && styles.length > 0) {
            return styles.join(" ");
        }
        return DEFAULT_FONT_STYLE;
    }

    static appendAreaProperty(shapeGroup: d3.Selection<any, DiagramNode, null, undefined>,
                              area: Area) {
        shapeGroup.property(NodeRendererUtils.NODE_AREA_PROPERTY_NAME, area);
    }

    static extractAreaProperty(node: DiagramNode): Area | null {
        const area = d3.select(NodeRendererUtils.createNodeShapeGroupId(node, true))
            .property(NodeRendererUtils.NODE_AREA_PROPERTY_NAME) as Area;
        return area || null;
    }

    static getNodeClientBounds(nodeDto: DiagramNode) {
        const nodeOrderGroup = NodeRendererUtils.getNodeOrderGroup(nodeDto);
        const rect = nodeOrderGroup.node()?.getBoundingClientRect() as DOMRect;
        return new Area(rect.x, rect.y, rect.width, rect.height);
    }

    static getNodeTextClientBounds(nodeDto: DiagramNode) {
        const nodeTextArea = NodeRendererUtils.getNodeTextArea(nodeDto);
        if (!nodeTextArea.empty()) {
            const rect = nodeTextArea.node()?.getBoundingClientRect() as DOMRect;
            return new Area(rect.x, rect.y, rect.width, rect.height);
        } else {
            const nodeOrderGroup = NodeRendererUtils.getNodeOrderGroupByNodeId(nodeDto.identifier);
            const rect = nodeOrderGroup.node()?.getBoundingClientRect() as DOMRect;
            return new Area(rect.x, rect.y, rect.width, rect.height);
        }

    }

    static isTextAware(elementType: ArchimateElementType | null) {
        return elementType !== ArchimateElementType.AND_JUNCTION && elementType !== ArchimateElementType.OR_JUNCTION;
    }

    private static getNodeTextArea(nodeDto: DiagramNode) {
        return NodeRendererUtils.getNodeOrderGroupByNodeId(nodeDto.identifier)
            .select("." + TEXTAREA_CLASS_NAME) as d3.Selection<SVGForeignObjectElement, DiagramNode, HTMLElement, undefined>
    }

    static hideNodeText(nodeDto: DiagramNode) {
        NodeRendererUtils.getNodeTextArea(nodeDto)
            .style("visibility", "hidden");
    }

    static showNodeText(nodeDto: DiagramNode) {
        NodeRendererUtils.getNodeTextArea(nodeDto)
            .style("visibility", "visible");
    }

    static setNodePointerEventsRectStroke(node: DiagramNode, color: string, width: number) {
        NodeRendererUtils.getNodeOrderGroupByNodeId(node.identifier).select("." + POINTER_EVENTS_CLASS_NAME)
            .attr("stroke", color)
            .attr("stroke-width", width);
    }

    static removeNodePointerEventsRectStroke(node: DiagramNode) {
        NodeRendererUtils.getNodeOrderGroupByNodeId(node.identifier).select("." + POINTER_EVENTS_CLASS_NAME)
            .attr("stroke", "none")
            .attr("stroke-width", 0);
    }
}
