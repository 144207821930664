import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React, {useContext, useEffect, useRef, useState} from "react";
import RenderMode from "../../common/diagrameditor/context/RenderMode";
import {Observable} from "rxjs";
import ReactDOM from "react-dom";
import {DiagramEditor} from "../../common/diagrameditor/DiagramEditor";
import {FetchStatusType} from "../../store/common/FetchableResource";
import clsx from "clsx";
import {CircularProgress,} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SvgCanvas from "./SvgCanvas";
import * as d3 from "d3";
import TopMenu from "./menu/TopMenu";
import PalettePanel from "./menu/PalettePanel";
import LeftMenu from "./menu/LeftMenu";
import MenuResizer, {ResizeDirection} from "./menu/MenuResizer";
import Constants from "../../common/Constants";
import {SaveButtonStatus} from "../../pages/main/content/diagrams/DiagramEditorDialog";
import Ruler, {RulerType} from "./menu/left/ruler/Ruler";
import {IModelDto} from "../../common/apis/model/IModelDto";
import {IEditMode} from "../../common/diagrameditor/editor/IEditMode";
import {IMode} from "../../common/diagrameditor/model/IMode";
import {DiagramEditorTranslationKey} from "./DiagramEditorTranslationKey";
import {_transl} from "../../store/localization/TranslMessasge";
import {useDiagramBackupService} from "../../common/diagrameditor/backup/provider/DiagramBackupProvider";
import {useQuery} from "../../common/hooks/useQuery";
import {DiagramBackup} from "../../common/diagrameditor/backup/DiagramBackup";
import EventManager, {Unsubscriber} from "../../common/event/EventManager";
import {IUndoRedoEvent} from "../../common/event/Event";
import DiagramDetailDialog from "../../pages/main/content/diagrams/detail/DiagramDetailDialog";
import {DiagramsGridAction, DiagramsGridActionType} from "../../pages/main/content/diagrams/DiagramsGridAction";
import MetamodelService, {MetamodelDto} from "../../pages/main/content/metamodel/extraction/MetamodelService";
import ChatLayer from "../../pages/main/content/chat/ChatLayer";
import EventManagerContext from "../../common/event/EventManagerContext";
import {
    ChangeChatLayerVisibilityEvent,
    ChatEventType,
    ChatLayerVisibilityChangedEvent
} from "../../pages/main/content/chat/ChatEvents";
import {UserDto} from "../../common/apis/user/UserDto";

export const CONTENT_AREA_ID = "__diagram-editor-content-area__";
export const LEFT_MENU_ID = "__diagram-editor-left-menu__";
export const RIGHT_MENU_ID = "__diagram-editor-right-menu__";

export const RULER_SIZE_NUMBER = 15;
export const RULER_SIZE = `${RULER_SIZE_NUMBER}px`;

const LEFT_MENU_LEFT_OFFSET = 100;
const LEFT_MENU_RIGHT_OFFSET = 250;

type IgnoreEventsType = { ignoreEvents: boolean };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoPanel: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        infoLabel: {
            fontSize: "1.4em",
            color: "gray",
            marginBottom: theme.spacing(4),
        },
        infoIcon: {
            "& .MuiSvgIcon-root": {
                width: "5em",
                height: "5em",
            },
        },
        fetchStartedIcon: {
            color: theme.palette.primary.main,
        },
        fetchFailedIcon: {
            color: theme.palette.error.main,
        },
        editorGrid: {
            position: "absolute",
            inset: 0,
            display: "grid",
            gridTemplateAreas: "'header header header' 'left center right' 'left footer right'",
            gridTemplateColumns: "auto 1fr auto",
            gridTemplateRows: "auto 1fr auto",
        },
        headerArea: {
            gridArea: "header",
        },
        leftArea: {
            gridArea: "left",
            display: "flex",
        },
        centerArea: {
            position: "relative",
            backgroundColor: Constants.MENU_BACKGROUND_COLOR,
        },
        transparentCenterArea: {
            position: "relative",
        },
        contentArea: {
            position: "absolute",
            right: 0,
            bottom: 0,
        },
        rightArea: {
            gridArea: "right",
            backgroundColor: Constants.MENU_BACKGROUND_COLOR,
            display: "flex",
        },
        rightAreaDiv: {
            height: "6em",
            width: "2em",
            stroke: "black",
        },
        footerArea: {
            gridArea: "footer"
        },
        menuIcon: {
            display: "flex",
            width: "1.5em",
            "& > *": {
                width: "0.875rem",
                height: "0.875rem",
            }
        },
        checkMenuIcon: {
            "& > svg": {
                color: "white",
                backgroundColor: d3.color(theme.palette.primary.main)?.brighter(1).formatRgb(),
                borderColor: d3.color('green')?.darker(0.5).formatRgb(),
            }
        },
        menuText: {
            width: 150
        },
        ruler: {
            position: "absolute",
            backgroundColor: Constants.MENU_BACKGROUND_COLOR_DARKER,
        },
        rulerHorizontal: {
            height: RULER_SIZE,
            top: 0,
            left: RULER_SIZE,
            right: 0,
        },
        rulerVertical: {
            width: RULER_SIZE,
            top: RULER_SIZE,
            left: 0,
            bottom: 0,
        },
    })
);

interface IProps {
    diagramId: string;
    fetchDiagram: () => Observable<IModelDto>;
    onDiagramModelFetched?: (model: IModelDto) => void;
    mode: IMode;
    saveButtonStatus?: SaveButtonStatus;
    chatLayerVisible?: boolean;
    onChatsUpdated?: () => void;
    transparentPaper?: boolean;
    user?: UserDto;
}

function isEditOrPreEdit(mode: IMode) {
    return mode.mode === RenderMode.EDIT || mode.mode === RenderMode.PRE_EDIT;
}

export default function DiagramEditorComponent(props: IProps) {

    const {mode, diagramId, fetchDiagram, onDiagramModelFetched, saveButtonStatus, transparentPaper, user} = props;
    const [chatLayerVisible, setChatLayerVisible] = useState<boolean>(false);
    const {onChatsUpdated} = props;
    const diagramBackupService = useDiagramBackupService();

    const topMenuRef = useRef<HTMLDivElement | null>(null);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const editorRef = useRef<DiagramEditor | null>(null);

    const [dataFetchStatus, setDataFetchStatus] = useState<FetchStatusType>(FetchStatusType.NOT_STARTED);
    const [rulerVisible, setRulerVisible] = useState<boolean>(true);
    const [viewpointIdentifier, setViewpointIdentifier] = useState<string>();
    const [diagramDetailDialogOpen, setDiagramDetailDialogOpen] = useState<boolean>();

    const eventManager = useContext<EventManager>(EventManagerContext);

    const query = useQuery();

    useEffect(() => {
        let destroyed = false;

        function loadBackup(diagramId: string) {
            if (query.get(DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDIT_BACKUP))) {
                return diagramBackupService.findBackup(diagramId);
            }
            return null;
        }

        function createEditor() {
            const diagramEditor = initEditor();
            editorRef.current = diagramEditor;
            fetchDiagramModel(diagramEditor);
        }

        function destroyEditor() {
            editorRef?.current?.destroy();
        }

        function initEditor() {
            const editor = DiagramEditor.create(diagramId, mode, eventManager);
            if (isEditOrPreEdit(mode)) {
                (mode as IEditMode).onDiagramEditorApiCreated(editor.getDiagramEditorApi());
            }
            if (transparentPaper !== undefined) {
                editor.setTransparentPaper(transparentPaper);
            }
            return editor;
        }

        function fetchDiagramModel(diagramEditor: DiagramEditor) {
            setDataFetchStatus(FetchStatusType.STARTED);

            Promise.allSettled([
                fetchDiagram().toPromise(),
                MetamodelService.findById("1")
            ])
                .then(values => {
                    if (values[0].status === 'rejected') {
                        setDataFetchStatus(FetchStatusType.FAILED);
                        return;
                    }
                    const model = values[0].value;

                    const metamodel = values[1].status === 'fulfilled' ? values[1].value : undefined;

                    if (destroyed) {
                        return;
                    }
                    setTimeout(() => onDiagramModelFetched && onDiagramModelFetched(model), 0);
                    setDataFetchStatus(FetchStatusType.SUCCESSFUL);
                    const backup = loadBackup(model.diagrams[0].diagramInfo.identifier);
                    renderEditor(diagramEditor, svgRef.current as SVGSVGElement, model, metamodel, backup);
                    if (model.diagrams && model.diagrams[0].diagramInfo && model.diagrams[0].diagramInfo.viewpoint) {
                        setViewpointIdentifier(model.diagrams[0].diagramInfo.viewpoint);
                    }
                })
                .catch(
                    () => setDataFetchStatus(FetchStatusType.FAILED)
                );
        }

        setRulerVisible(mode.mode !== RenderMode.EXPORT);

        createEditor();

        return () => {
            destroyed = true;
            destroyEditor();
        }
    }, [diagramId, mode, eventManager, fetchDiagram, onDiagramModelFetched, diagramBackupService, query, transparentPaper]);

    useEffect(() => {
        if (editorRef.current != null && isEditOrPreEdit(mode)) {
            (mode as IEditMode).onDiagramEditorApiCreated(editorRef.current?.getDiagramEditorApi());
        }
    }, [mode, diagramBackupService]);

    useEffect(() => {
        const ignoreEvents: IgnoreEventsType = {ignoreEvents: false};
        let unsubscribers: Array<Unsubscriber> = [];

        function addUndoRedoListener() {
            return editorRef.current!.getDiagramEditorApi().addUndoRedoListener((event: IUndoRedoEvent) => {
                if (!ignoreEvents.ignoreEvents) {
                    if (event.saveNeeded) {
                        diagramBackupService.doBackup(new DiagramBackup(diagramId, editorRef.current!.getDiagramEditorApi().getModel(), new Date(), user!.login));
                    } else {
                        diagramBackupService.removeBackup(diagramId);
                    }
                }
            });
        }

        if (isEditOrPreEdit(mode) && editorRef.current) {
            unsubscribers.push(addUndoRedoListener());
        }
        return () => {
            ignoreEvents.ignoreEvents = true;
            for (const unsubscriber of unsubscribers) {
                unsubscriber();
            }
        }
    }, [editorRef, diagramBackupService, diagramId, mode, user]);

    useEffect(() => {
        const unsubscribe = eventManager.subscribeListener(ChatEventType.CHANGE_CHAT_LAYER_VISIBILITY, (event: ChangeChatLayerVisibilityEvent) => {
            if (event.chatLayerVisible !== chatLayerVisible) {
                setChatLayerVisible(event.chatLayerVisible);
                const visibilityChangedEvent: ChatLayerVisibilityChangedEvent = {
                    type: ChatEventType.CHAT_LAYER_VISIBILITY_CHANGED,
                    chatLayerVisible: event.chatLayerVisible
                };
                eventManager.publishEvent(visibilityChangedEvent);
            }
        });

        return () => unsubscribe();
    }, [eventManager, chatLayerVisible]);

    function getBackgroundColorByMode(renderMode: RenderMode): string {
        switch (renderMode) {
            case RenderMode.EXPORT:
                return 'transparent';
            case RenderMode.PREVIEW:
                return 'white';
            default:
                return Constants.EDITOR_BACKGROUND_COLOR;
        }
    }

    // local vars
    const classes = useStyles(mode.mode);
    const overflow = mode.mode === RenderMode.PREVIEW ? "hidden" : "auto";
    const contentAreaBgColor = getBackgroundColorByMode(mode.mode);
    const editor = editorRef.current;
    const paddingTopLeft = (isEditOrPreEdit(mode) && rulerVisible) ? RULER_SIZE : 0;

    return <React.Fragment>
        {diagramDetailDialogOpen && <DiagramDetailDialog diagramId={diagramId}
                                                         opened={diagramDetailDialogOpen}
                                                         onClosed={() => setDiagramDetailDialogOpen(false)}
                                                         hideDiagramPreviewTab={true}
                                                         hideShowAndEditDiagramIcons={true}/>
        }
        {dataFetchStatus === FetchStatusType.SUCCESSFUL && editor &&
            <div className={classes.editorGrid}>
                <div className={classes.headerArea} ref={topMenuRef}>
                    {isEditOrPreEdit(mode) &&
                        <TopMenu mode={mode as IEditMode}
                                 eventManager={eventManager}
                                 editor={editor}
                                 saveButtonStatus={saveButtonStatus || SaveButtonStatus.SAVE_NOT_NEEDED}
                                 openDiagramDetailDialog={() => setDiagramDetailDialogOpen(true)}
                                 diagramId={diagramId}
                        />
                    }
                </div>
                <div className={classes.leftArea}>
                    {isEditOrPreEdit(mode) &&
                        <React.Fragment>
                            <LeftMenu menuId={LEFT_MENU_ID} diagramId={diagramId} mode={mode as IEditMode}
                                      editor={editor}/>
                            <MenuResizer menuId={LEFT_MENU_ID} resizeDirection={ResizeDirection.TO_RIGHT}
                                         leftOffset={LEFT_MENU_LEFT_OFFSET} rightOffset={LEFT_MENU_RIGHT_OFFSET}/>
                        </React.Fragment>
                    }
                </div>
                <div className={mode.mode === RenderMode.EXPORT ? classes.transparentCenterArea : classes.centerArea} style={{paddingTop: paddingTopLeft, paddingLeft: paddingTopLeft}}>
                    {rulerVisible &&
                        <Ruler type={RulerType.HORIZONTAL} className={clsx(classes.ruler, classes.rulerHorizontal)}
                               editorApi={editorRef.current?.getDiagramEditorApi()}/>}
                    {rulerVisible &&
                        <Ruler type={RulerType.VERTICAL} className={clsx(classes.ruler, classes.rulerVertical)}
                               editorApi={editorRef.current?.getDiagramEditorApi()}/>}
                    <div id={CONTENT_AREA_ID}
                         className={classes.contentArea}
                         style={{
                             overflow: overflow,
                             backgroundColor: contentAreaBgColor,
                             top: paddingTopLeft,
                             left: paddingTopLeft
                         }}
                    >

                        <SvgCanvas id={diagramId} svgRef={svgRef}/>
                        <ChatLayer open={chatLayerVisible ?? false}
                                   diagramId={diagramId}
                                   editor={editorRef.current}
                                   onChatsUpdated={onChatsUpdated}/>

                    </div>
                </div>
                <div className={classes.rightArea}>
                    {mode.mode === RenderMode.EDIT &&
                        <PalettePanel menuId={RIGHT_MENU_ID} mode={mode as IEditMode} editor={editor}
                                      viewpointIdentifier={viewpointIdentifier}/>
                    }
                </div>
                <div className={classes.footerArea}>
                </div>
            </div>
        }
        {dataFetchStatus === FetchStatusType.FAILED &&
            <div className={classes.infoPanel}>
                <div className={classes.infoLabel}>{_transl(DiagramEditorTranslationKey.LOADING_FAILED)}</div>
                <div className={clsx(classes.infoIcon, classes.fetchFailedIcon)}><CancelOutlinedIcon/></div>
            </div>
        }
        {(dataFetchStatus === FetchStatusType.NOT_STARTED || dataFetchStatus === FetchStatusType.STARTED) &&
            <div className={classes.infoPanel}>
                <div className={classes.infoLabel}>{_transl(DiagramEditorTranslationKey.LOADING_IN_PROGRESS)}</div>
                <div className={clsx(classes.infoIcon, classes.fetchStartedIcon)}><CircularProgress size={"5em"}
                                                                                                    color="primary"/>
                </div>
            </div>
        }
    </React.Fragment>

}

// module functions

function renderEditor(editor: DiagramEditor, svgElem: SVGSVGElement, data: IModelDto, metamodel: MetamodelDto | undefined, backup: DiagramBackup | null) {
    if (!editor.isInitialised()) {
        // init model only when it hasn't been initialised yet -> otherwise eventual model changes would be lost
        editor.init(data, backup?.getDiagramModel(), metamodel);
    }
    const parentRect = ReactDOM.findDOMNode(svgElem)?.parentElement?.getBoundingClientRect() as DOMRect;
    editor.render(svgElem, parentRect);
}
