import {AjaxRequest} from "rxjs/internal/observable/dom/AjaxObservable";
import Api from "../../../../common/Api";
import Constants from "../../../../common/Constants";

export interface ModelsComparisonService {
    compareModels(firstModelFile: File, secondModelFile: File): Promise<BlobPart>,
}

const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/repository/models:compare";

class RxJsModelsComparisonService implements ModelsComparisonService {

    async compareModels(firstModelFile: File, secondModelFile: File): Promise<BlobPart> {
        let formData: FormData = new FormData();
        formData.append('firstModel', firstModelFile);
        formData.append('secondModel', secondModelFile);

        const request: AjaxRequest = {
            url: ENDPOINT_URL,
            method: "POST",
            responseType: "blob",
            body: formData,
        };
        try {
            const response = await Api.createAjax(request, true).toPromise();
            return new Promise((resolve, reject) => resolve(response.response as BlobPart));
        } catch (error) {
            return Promise.reject(error);
        }
    }

}

export default new RxJsModelsComparisonService() as ModelsComparisonService;
