import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import ArrayUtils from "../../../../common/ArrayUtils";
import {CollectionDto} from "../../../../common/apis/collection/CollectionDto";
import {ArchimateElement} from "../../../../common/archimate/ArchimateElement";
import {ElementTranslationKey} from "./ElementTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {Tooltip} from "@mui/material";
import {ILabelDto} from "../../../../common/apis/label/ILabelDto";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import stateColorResolver from "../../../../common/StateColorResolver";
import ElementState from "../../../../components/ElementState";
import {DiagramTranslationKey} from "../diagrams/DiagramTranslationKey";
import {readWriteFlagCellRenderer} from "../../../../components/grid/ReadWriteFlagCellRenderer";
import {stereotypeGetter} from "../stereotypes/StereotypeGetter";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {DateFormatter} from "../../../../common/DateFormatter";

const stateFormatter = (params: GridValueFormatterParams) => {
    return params.value != null ? <ElementState stateCode={params.value} stateColorResolver={stateColorResolver}/> : "";
}

function createOptionsFormatter<T>(getOptionLabel: (option: T) => string) {
    return ((params: GridValueFormatterParams) => ArrayUtils.joinOrElse((params.value as Array<T> || []).map(param => getOptionLabel(param)), ", ", ""));
}

const typeGetter = (params: GridValueGetterParams) => {
    return ArchimateElement.findByStandardName(params.row.type)?.visibleName;
}

export class ElementsGridDef {

    public static getGridColDef(): GridColDef<ElementDto>[] {
        return [
            {
                field: '#read-write',
                headerName: _transl(DiagramTranslationKey.GRID_HEADER_READ_WRITE),
                headerClassName: 'datagrid-column',
                width: 50,
                valueGetter: params => params.row.acl.canUpdate,
                renderCell: readWriteFlagCellRenderer,
                hide: true,
                sortable: false
            },
            {
                field: 'type', headerName: _transl(ElementTranslationKey.GRID_HEADER_TYPE),
                headerClassName: 'datagrid-column', width: 180, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueGetter: typeGetter,
            },
            {
                field: 'stereotype', headerName: _transl(ElementTranslationKey.GRID_HEADER_STEREOTYPE),
                headerClassName: 'datagrid-column', width: 180, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueGetter: stereotypeGetter,
            },
            {
                field: 'identifier', headerName: _transl(ElementTranslationKey.GRID_HEADER_IDENTIFIER),
                headerClassName: 'datagrid-column', width: 150, renderCell: (params: any) => ElementsGridDef.getTooltip(params)
            },
            {
                field: 'name', headerName: _transl(ElementTranslationKey.GRID_HEADER_NAME),
                headerClassName: 'datagrid-column', width: 300, renderCell: (params: any) => ElementsGridDef.getTooltip(params)
            },
            {
                field: 'validFrom', headerName: _transl(ElementTranslationKey.GRID_HEADER_VALID_FROM),
                headerClassName: 'datagrid-column', width: 120, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueFormatter: ({value}) => DateFormatter.formatDate(value)
            },
            {
                field: 'validThru', headerName: _transl(ElementTranslationKey.GRID_HEADER_VALID_THRU),
                headerClassName: 'datagrid-column', width: 120, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueFormatter: ({value}) => DateFormatter.formatDate(value)
            },
            {
                field: 'state', headerName: _transl(ElementTranslationKey.GRID_HEADER_STATE),
                headerClassName: 'datagrid-column', width: 100, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueFormatter: stateFormatter
            },
            {
                field: 'labels', headerName: _transl(ElementTranslationKey.GRID_HEADER_LABELS),
                headerClassName: 'datagrid-column', width: 240, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueFormatter: createOptionsFormatter<ILabelDto>((label) => label.name),
                sortable: false
            },
            {
                field: 'collections', headerName: _transl(ElementTranslationKey.GRID_HEADER_COLLECTIONS),
                headerClassName: 'datagrid-column', width: 240, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueFormatter: createOptionsFormatter<CollectionDto>((collection) => collection.name),
                sortable: false
            },
            {
                field: 'chatStats.lastCommentedOn', type: "dateTime", headerName: _transl(ElementTranslationKey.GRID_HEADER_LAST_COMMENTED_ON),
                headerClassName: 'datagrid-column', width: 170, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueGetter: (params) => params.row.chatStats?.lastCommentedOn,
                valueFormatter: ({value}) => DateFormatter.formatDateTime(value)
            },
            {
                field: 'attachmentsCount',
                headerName: _transl(ElementTranslationKey.GRID_HEADER_ATTACHMENT_COUNT),
                headerClassName: 'datagrid-column',
                headerAlign: "left",
                width: 100,
                renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                type: "number",
                sortable: false
            },
            {
                field: 'created', headerName: _transl(ElementTranslationKey.GRID_HEADER_DATE_CREATED),
                headerClassName: 'datagrid-column', width: 170, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueFormatter: ({value}) => DateFormatter.formatDateTime(value)
            },
            {
                field: 'userCreated', headerName: _transl(ElementTranslationKey.GRID_HEADER_USER_CREATED),
                headerClassName: 'datagrid-column', width: 140, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                sortable: false
            },
            {
                field: 'updated', headerName: _transl(ElementTranslationKey.GRID_HEADER_DATE_UPDATED),
                headerClassName: 'datagrid-column', width: 170, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                valueFormatter: ({value}) => DateFormatter.formatDateTime(value)
            },
            {
                field: 'userUpdated', headerName: _transl(ElementTranslationKey.GRID_HEADER_USER_UPDATED),
                headerClassName: 'datagrid-column', width: 140, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                sortable: false
            },
            {
                field: 'description', headerName: _transl(ElementTranslationKey.GRID_HEADER_DESCRIPTION),
                headerClassName: 'datagrid-column', width: 200, renderCell: (params: any) => ElementsGridDef.getTooltip(params),
                sortable: false
            },
        ];
    }

    private static getTooltip(params: any) {
        return <Tooltip title={params.formattedValue || ""}>
            <span>{params.formattedValue}</span>
        </Tooltip>;
    }
}
