import React from "react";
import {YesButton} from "../button/YesButton";
import {NoButton} from "../button/NoButton";
import {ButtonLayout, NegativeButtons, PositiveButtons} from "../button/ButtonLayout";
import {CancelButton} from "../button/CancelButton";
import DialogTitle from "./DialogTitle";
import Dialog from "./Dialog";
import DialogContent from "./DialogContent";
import DialogActions from "./DialogActions";
import DialogContentText from "./DialogContentText";

export interface ConfirmationDialogProps {
    open: boolean,
    title: string,
    confirmationText: string,
    onConfirm: (e: any) => void,
    onReject: (e: any) => void,
    onCancel?: (e: any) => void,
    isModal?: boolean,
    role?: string,
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {

    const {open, title, confirmationText, onConfirm, onReject, onCancel, role} = props;

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if (props.isModal && reason) {
                    return;
                }
                onReject(e);
            }}
            role={role}
            aria-labelledby="yes-no-dialog"
        >
            <DialogTitle title={title} onDialogClosed={onCancel ? onCancel : onReject}/>
            <DialogContent>
                <DialogContentText>
                    {confirmationText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonLayout>
                    <PositiveButtons>
                        <YesButton onClick={onConfirm} variant="contained"/>
                    </PositiveButtons>
                    <NegativeButtons>
                        {onCancel ? (
                            <>
                                <NoButton onClick={onReject} variant="outlined"/>
                                <CancelButton onClick={onCancel} variant="outlined"/>
                            </>
                        ) : (
                            <NoButton onClick={onReject} variant="outlined"/>
                        )}
                    </NegativeButtons>
                </ButtonLayout>
            </DialogActions>
        </Dialog>
    )
}
