import {Action, Reducer} from "redux";
import {TrackerConfigDto} from "../../configuration/TrackerConfigDto";


export enum TrackerConfigInitActionType {
    TRACKER_CONFIG_INIT = "tracker.config.init",
}

export interface TrackerConfigInitAction extends Action<TrackerConfigInitActionType> {
    trackerConfig: TrackerConfigDto;
}

export function initializeTrackerConfigAction(trackerConfig: TrackerConfigDto): TrackerConfigInitAction {
    return {
        type: TrackerConfigInitActionType.TRACKER_CONFIG_INIT,
        trackerConfig: trackerConfig
    };
}

export const trackerConfigReducer: Reducer<TrackerConfigDto, TrackerConfigInitAction> = (trackerConfig = { trackerEnabled: false }, action) => {
    switch(action.type) {
        case TrackerConfigInitActionType.TRACKER_CONFIG_INIT:
            return action.trackerConfig
    }
    return trackerConfig;
};