import Utils from "./Utils";
import moment from "moment/moment";

export default class Constants {
    // BE host
    public static readonly API_HOST: string = Utils.isDevelopment() ? "http://localhost:8090": window.location.href.split('/').slice(0, 3).join('/');
    // FE host
    public static readonly APP_HOST: string = Utils.isDevelopment() ? "http://localhost:3000" : Constants.API_HOST;
    // REST API url
    public static readonly REST_API_URL: string = Constants.API_HOST + "/rest-api";
    // FE App login
    public static readonly FE_APP_LOGIN_ROUTE = "/app/login";
    // FE App login failed
    public static readonly FE_APP_LOGIN_FAILED_ROUTE = "/app/loginFailed";

    public static readonly FE_APP_DIAGRAM_EXPORT_VIEW_ROUTE = "/app/diagramExportView";
    // FE App main
    public static readonly FE_APP_MAIN_ROUTE = "/app/main";
    // FE App dashboard
    public static readonly FE_APP_DASHBOARD_ROUTE = `${Constants.FE_APP_MAIN_ROUTE}/dashboard`;
    public static readonly FE_APP_STRUCTURES_ROUTE = `${Constants.FE_APP_MAIN_ROUTE}/structures`;
    //FE alert delay
    public static readonly FE_APP_ALERT_DELAY = 3000;
    //FE type of Objects
    public static readonly DIAGRAM_OBJECT = "DiagramObject";
    public static readonly ITEM_OBJECT = "ItemObject";

    public static readonly DATE_FORMAT = "DD.MM.yyyy";

    // Error color constant
    public static readonly DEFAULT_ERROR_COLOR = "#f44336";

    public static readonly ELEMENT_NOT_FOUND_COLOR = "rgb(128, 128, 128)";

    //FE labels code formatting delay
    public static readonly FE_APP_CODE_FORMATTING_DELAY = 1000;

    public static readonly MENU_BACKGROUND_COLOR = "rgb(243, 243, 243)";
    public static readonly MENU_BACKGROUND_COLOR_DARKER = "rgb(218, 218, 218)";
    public static readonly MENU_OPTIONS_BACKGROUND_COLOR = "rgb(226, 230, 236)";
    public static readonly MODEL_FOLDER_ICON_COLOR = "rgb(255, 234, 182)";
    public static readonly EDITOR_BACKGROUND_COLOR = "rgb(230, 230, 230)";

    public static readonly TRANSPARENT_MENU_MOUSE_LEAVE_BG_COLOR = "rgba(235, 235, 235, 0.3)";
    public static readonly TRANSPARENT_MENU_MOUSE_ENTER_BG_COLOR = "rgb(242, 242, 242)";

    public static readonly GRID_DEFAULT_PAGE_SIZE = 10;
    public static readonly LARGE_GRID_PAGE_SIZE = 20;


    public static formatDate(date: Date, format?: string): string {
        return moment(date).format(format ? format : Constants.DATE_FORMAT);
    }

    public static attachAppHost(url: string): string {
        return Constants.APP_HOST + url;
    }
}
