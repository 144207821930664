import Button from "@mui/material/Button";
import {_transl} from "../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import React from "react";
import {ButtonPropTypes} from "./ButtonPropTypes";

interface Props {
    onClick: (event?: any) => void,
    variant?: ButtonPropTypes.Variant,
}

export function NoButton(props: Props) {

    const {onClick, variant} = props;

    return (
        <Button
            onClick={onClick}
            variant={variant}
        >
            {_transl(CommonTranslation.NO)}
        </Button>
    )
}

