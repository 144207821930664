export enum AttachmentTranslationKey {
    DOWNLOAD_FAILED = "attachments.download.failed",
    DOWNLOAD_SAVE = "attachments.download.save",
    INDICATOR_PANEL_BUTTON = "attachments.indicator.button",
    LIST_ATTACHMENTS_TABLE = "attachments.list.attachmentsTable",
    LIST_DOWNLOADING = "attachments.list.downloading",
    LIST_EMPTY = "attachments.list.empty",
    LIST_ERROR = "attachments.list.error",
    LIST_SIZE = "attachments.list.size",
    UPLOAD_AREA = "attachments.upload.area",
    UPLOAD_AREA_NO_FILES = "attachments.upload.areaNoFiles",
    UPLOAD_CLEAR = "attachments.upload.clear",
    UPLOAD_FAILED = "attachments.upload.failed",
    UPLOAD_SUCCEEDED = "attachments.upload.succeeded",
    UPLOAD_TITLE = "attachments.upload.title",
}
