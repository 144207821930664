import EventManager, {Unsubscriber} from "../../../common/event/EventManager";
import {ModelManager} from "../../../common/diagrameditor/manager/ModelManager";
import {
    HighlightReportedObjectsRequestEvent,
    UnhighlightReportedObjectsRequestEvent,
    ValidationEventType,
} from "./ValidationEvents";
import {MetamodelValidationReportDto} from "../../../common/apis/MetamodelService";

export class ValidationReportHighlighter {

    private unsubscribers: Array<Unsubscriber> = [];
    constructor(private readonly eventManager: EventManager,
                private readonly modelManager: ModelManager) {
        this.unsubscribers.push(this.eventManager.subscribeListener(ValidationEventType.HIGHLIGHT_REPORTED_OBJECTS_REQUEST, (event: HighlightReportedObjectsRequestEvent) => {this.highlightReportedObjects(event.report)}));
        this.unsubscribers.push(this.eventManager.subscribeListener(ValidationEventType.UNHIGHLIGHT_REPORTED_OBJECTS_REQUEST, (event: UnhighlightReportedObjectsRequestEvent) => {this.unhighlightReportedObjects()}));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    private highlightReportedObjects(report: MetamodelValidationReportDto) {
        const elementIds = report.reportedElements.map(element => element.identifier);
        const nodes = this.modelManager.getDiagramNodesByElementIds(elementIds);
        this.modelManager.highlightNodes(nodes.map(node => node.identifier));

        const event = {
            type: ValidationEventType.REPORTED_OBJECTS_HIGHLIGHTED,
            nodes: nodes
        }
        this.eventManager.publishEvent(event);
        this.modelManager.publishModelEvent(event.type, () => {});
    }

    private unhighlightReportedObjects() {
        const nodes = this.modelManager.unhighlightNodes();

        const event = {
            type: ValidationEventType.REPORTED_OBJECTS_UNHIGHLIGHTED,
            nodes: nodes
        }
        this.eventManager.publishEvent(event);
        this.modelManager.publishModelEvent(event.type, () => {});
    }
}