import React, {useContext, useRef, useState} from "react";
import EditableTextField from "../../../../../components/fields/EditableTextField";
import EditableCollectionsComponent from "../../../../../components/fields/EditableCollectionsComponent";
import EditableStateComponent from "../../../../../components/fields/EditableStateComponent";
import {ArchimateElement} from "../../../../../common/archimate/ArchimateElement";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {TranslationKey} from "../../../../../store/localization/TranslationKey";
import {ElementDto} from "../../../../../common/apis/element/ElementDto";
import {ElementDetailTranslationKey} from "../ElementDetailTranslationKey";
import {ILabelDto} from "../../../../../common/apis/label/ILabelDto";
import {EditableLabelsComponent} from "../../../../../components/fields/label/EditableLabelsComponent";
import {IApplicationState} from "../../../../../store/Store";
import {useSelector} from "react-redux";
import constructElementDetailController, {ElementDetailController} from "../controller/ElementDetailController";
import elementService from "../service/ElementService";
import diagramService from "../../diagrams/service/DiagramService";
import Grid from "../../../../../components/dialogs/Grid";
import TextField from "../../../../../components/fields/textfield/TextField";
import EditableDatePicker from "../../../../../components/fields/date/EditableDatePicker";
import {ElementTranslationKey} from "../../elements/ElementTranslationKey";
import GenerateDescriptionButton from "./GenerateDescriptionButton";
import ElementDescriptionGenerationDialog from "./ElementDescriptionGenerationDialog";
import ConfigurationContext, {Configuration} from "../../../../../common/ConfigurationContext";
import {DateFormatter} from "../../../../../common/DateFormatter";

interface ElementBasicInfoPanelProps {
    element: ElementDto;
    onPropertyUpdate: () => void;
    onElementUpdated?: (id: string) => void;
}

export default function ElementBasicInfoPanel({element, onPropertyUpdate, onElementUpdated}: ElementBasicInfoPanelProps) {

    const elementDetailController = useRef<ElementDetailController>(constructElementDetailController(elementService, diagramService));
    const labels = useSelector((state: IApplicationState) => state.pages.common.options.labels.resource);

    const { featureStatuses } = useContext<Configuration>(ConfigurationContext);

    const [description, setDescription] = useState(element.description);
    const [descGenDialogOpen, setDescGenDialogOpen] = useState(false);

    return (
        <>
            <ElementDescriptionGenerationDialog open={descGenDialogOpen}
                                                elementIdentifier={element.identifier} onClosed={() => setDescGenDialogOpen(false)} onDescriptionGenerated={setDescription} />
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <EditableTextField id={"item-detail-basic-panel-name"}
                                           label={_transl(TranslationKey.Elements_BasicInfoPanel_name)}
                                           initialValue={element?.name || ""}
                                           readonly={!element.acl.canUpdate}
                                           doUpdate={(text) => elementDetailController.current.updateNameById(element.identifier, text)}
                                           onSuccessfulUpdate={(text) => {
                                                    onPropertyUpdate();
                                                    onElementUpdated?.(element.identifier);
                                                }
                                           }
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextField
                        id="item-detail-basic-panel-id"
                        label={_transl(ElementDetailTranslationKey.IDENTIFIER)}
                        value={element.identifier || ""}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6} >
                    <TextField
                        id="item-detail-basic-panel-type"
                        label={_transl(ElementDetailTranslationKey.TYPE)}
                        value={ArchimateElement.findByStandardName(element.type)?.visibleName || ""}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6} >
                    <TextField
                        id="item-detail-basic-panel-stereotype"
                        label={_transl(ElementDetailTranslationKey.STEREOTYPE)}
                        value={element.stereotype?.name || ""}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sx={{position: 'relative'}}>
                    <EditableTextField
                        rows={8}
                        id="item-detail-basic-panel-desc"
                        label={_transl(ElementDetailTranslationKey.DESCRIPTION)}
                        initialValue={description || ""}
                        readonly={!element.acl.canUpdate}
                        doUpdate={(text) => elementDetailController.current.updateDescriptionById(element.identifier, text)}
                        onSuccessfulUpdate={(text) => onPropertyUpdate()}
                    />
                    {featureStatuses.aiEnabled &&
                        <GenerateDescriptionButton tooltip={_transl(ElementDetailTranslationKey.DESCRIPTION_GENERATE_USING_AI)}
                                                   onClick={() => setDescGenDialogOpen(true)}
                                                   inProgress={descGenDialogOpen} />
                    }
                </Grid>
                <Grid item xs={4} >
                    <EditableStateComponent id={"item-detail-basic-panel-state"}
                                            label={_transl(ElementDetailTranslationKey.STATE)}
                                            isOptional={true}
                                            readonly={!element.acl.canUpdate}
                                            selectedStateCode={element.state}
                                            doUpdate={(state) => elementDetailController.current.updateStateById(element.identifier, state)}
                                            onSuccessfulUpdate={() => onPropertyUpdate()}/>
                </Grid>
                <Grid item xs={4} >
                    <EditableDatePicker
                        id={"item-detail-basic-panel-validFrom"}
                        initialValue={element.validFrom ? new Date(element.validFrom) : null}
                        readOnly={!element.acl.canUpdate}
                        doUpdate={(date) => elementDetailController.current.updateValidFromById(element.identifier, date)}
                        label={_transl(ElementDetailTranslationKey.VALID_FROM)}
                        onSuccessfulUpdate={() => onPropertyUpdate()}
                        deleteDateErrorMsg={_transl(ElementTranslationKey.ELEMENTS_DELETE_DATE_NOT_SUCCEEDED)}
                    />
                </Grid>
                <Grid item xs={4} >
                        <EditableDatePicker
                            id={"item-detail-basic-panel-validThru"}
                            initialValue={element.validThru ? new Date(element.validThru) : null}
                            readOnly={!element.acl.canUpdate}
                            doUpdate={(date) => elementDetailController.current.updateValidToById(element.identifier, date)}
                            label={_transl(ElementDetailTranslationKey.VALID_THRU)}
                            onSuccessfulUpdate={() => onPropertyUpdate()}
                            deleteDateErrorMsg={_transl(ElementTranslationKey.ELEMENTS_DELETE_DATE_NOT_SUCCEEDED)}
                        />
                </Grid>
                <Grid item xs={12} >
                    <EditableLabelsComponent textFieldId={"item-detail-basic-panel-labels"}
                                             publicLabelsAssigningPermitted={element.acl.canUpdatePublicLabels}
                                             allLabels={labels}
                                             initiallyAssignedLabels={element.labels}
                                             doUpdate={(labels: ILabelDto[]) => elementDetailController.current.updateLabels(element.identifier, labels)}
                                             onSuccessfulUpdate={(labels: ILabelDto[]) => onPropertyUpdate()}
                                             readonly={false}
                    />
                </Grid>
                <Grid item xs={12} >
                    <EditableCollectionsComponent
                        textFieldId={"item-detail-basic-panel-collections"}
                        assignedCollectionCodes={element.collections.map(collection => collection.code)}
                        doUpdate={(items) => elementDetailController.current.updateCollections(element.identifier, items)}
                        onSuccessfulUpdate={(collections) => onPropertyUpdate()}
                        readonly={!element.acl.canUpdateCollections}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="item-detail-basic-panel-created"
                        label={_transl(ElementTranslationKey.GRID_HEADER_DATE_CREATED)}
                        value={DateFormatter.formatDateTime(element.created)}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="item-detail-basic-panel-userCreated"
                        label={_transl(ElementTranslationKey.GRID_HEADER_USER_CREATED)}
                        value={element.userCreated || ""}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="item-detail-basic-panel-updated"
                        label={_transl(ElementTranslationKey.GRID_HEADER_DATE_UPDATED)}
                        value={DateFormatter.formatDateTime(element.updated)}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="item-detail-basic-panel-userUpdated"
                        label={_transl(ElementTranslationKey.GRID_HEADER_USER_UPDATED)}
                        value={element.userUpdated || ""}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}


