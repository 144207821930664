import {INode} from "../ModelManager";
import * as d3 from "d3";
import {BaseType} from "d3";
import EventManager from "../EventManager";
import {ChartRenderContext, ElementApi, GraphDataChartApi} from "../../GraphDataChart";
import {EventType, INodeEvent} from "../../event/Event";
import NodesRenderer from "../../renderer/NodesRenderer";
import NodeMenuFactory, {INodeMenu, INodeMenuItem, NodeMenuIconType} from "./NodeMenuFactory";
import {DEFAULT_LINE_COLOR} from "../../../../common/diagrameditor/common/UIConstants";

interface IMenuGroupItem {
    node: INode,
    nodeMenuItem: INodeMenuItem;
    lastPosition: {
        x: number,
        y: number,
    }
}


export default class NodeMenu {

    public static readonly MENU_PATH_ID = "__node-menu-path-id";
    public static readonly MENU_ITEM_FILL_COLOR = "#FAFAFA";
    public static readonly MENU_ITEM_STROKE_COLOR = d3.color("#f5f5f5")?.darker(0.5).formatRgb() || DEFAULT_LINE_COLOR;
    public static readonly MENU_ITEM_CIRCLE_RADIUS = 15;
    public static readonly MENU_ITEM_TOOLTIP_FONT_SIZE = "0.8em";
    public static readonly MENU_ITEM_TOOLTIP_GROUP_CLASS_NAME = "nodeMenuTooltipGroup";
    public static readonly MENU_ITEM_TOOLTIP_SHOW_HIDE_DURATION = 200;
    public static readonly MENU_ITEM_ICON_PADDING = 6;

    private eventManager: EventManager;
    private explodedElementIds?: Array<string>;
    private chartApi: GraphDataChartApi;
    private elementApi: ElementApi;

    private nodeMenu?: INodeMenu;
    private defsElement?: d3.Selection<SVGDefsElement, unknown, any, any>;
    private chartGroup?: d3.Selection<SVGGElement, unknown, null, undefined>;

    private menuPathGroup?: d3.Selection<SVGPathElement, INode, null, undefined>;
    private menuGroup?: d3.Selection<BaseType | SVGGElement, IMenuGroupItem, SVGGElement, undefined>;

    constructor(eventManager: EventManager, chartApi: GraphDataChartApi, elementApi: ElementApi) {
        this.chartApi = chartApi;
        this.elementApi = elementApi;
        this.eventManager = eventManager;
    }

    public init(defsElement: d3.Selection<SVGDefsElement, unknown, any, any>,
                chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
                renderingContext: ChartRenderContext) {
        this.defsElement = defsElement;
        this.chartGroup = chartGroup;
        this.explodedElementIds = renderingContext.explodedElementIds;
        this.nodeMenu = NodeMenuFactory.create(defsElement, this.chartApi, this.elementApi);
    }

    public getSelectedNode() {
        return (this.menuPathGroup && this.menuPathGroup.datum() && this.menuPathGroup.datum()) || null;
    }

    public hide() {
        this.menuPathGroup?.remove();
        this.menuPathGroup = undefined;
        this.menuGroup?.remove();
        this.menuGroup = undefined;
        this.hideTooltips();
    }

    public show(event: INodeEvent, runAnimation: boolean) {
        if (this.chartGroup && this.nodeMenu) {
            const node = event.node;
            this.eventManager.publishNodeEvent({type: EventType.NODE_MENU_OPENED, event: event.event, node: node})

            let menuItems = this.nodeMenu.items
                .map(item => {
                    return {
                        node: node,
                        nodeMenuItem: item,
                    } as IMenuGroupItem
                });
            if (node.anchored) {
                menuItems = menuItems.filter(item => item.nodeMenuItem.iconType !== NodeMenuIconType.ANCHOR);
            } else {
                menuItems = menuItems.filter(item => item.nodeMenuItem.iconType !== NodeMenuIconType.UNANCHOR);
            }
            if (this.explodedElementIds?.indexOf(node.id) !== -1) {
                menuItems = menuItems.filter(item => item.nodeMenuItem.iconType !== NodeMenuIconType.EXPLODE);
            }

            const path = d3.path();
            path.arc(node.x as number, node.y as number, NodesRenderer.NODE_RADIUS + NodeMenu.MENU_ITEM_CIRCLE_RADIUS + 8, Math.PI, 0);

            this.menuPathGroup = this.chartGroup.append("path")
                .datum(node)
                .lower()
                .classed("nodeMenuPath", true)
                .attr("id", NodeMenu.MENU_PATH_ID)
                .attr("stroke", "none")
                .attr("fill", "none")
                .attr("d", path.toString());
            const menuPath = this.menuPathGroup;

            const startPoint = this.menuPathGroup.node()?.getPointAtLength(1) as DOMPoint;
            const pathNodePathLength = this.menuPathGroup.node()?.getTotalLength() as number; // Get the length of the path

            this.menuGroup = this.chartGroup.selectAll("g.nodeMenuGroup")
                .data(menuItems)
                .join("g")
                .attr("id", def => NodeMenu.createNodeMenuId(def.nodeMenuItem.iconType))
                .classed("nodeMenuGroup", true)
                .style("opacity", 0)
                .attr("transform", `translate(${startPoint.x}, ${startPoint.y})`)
                .attr("cursor", "pointer")
                .on("click", (event, def) => {
                    this.doAction(event, def);
                })
                .on("mouseenter", (event, def) => {
                    this.showTooltip(def);
                })
                .on("mouseleave", (event, def) => {
                    this.hideTooltip(def);
                })

            this.menuGroup.append("circle")
                .classed("nodeMenu svgNodeMenuShadow", true)
                .attr("fill", NodeMenu.MENU_ITEM_FILL_COLOR)
                .attr("stroke", NodeMenu.MENU_ITEM_STROKE_COLOR)
                .attr("cx", NodeMenu.MENU_ITEM_CIRCLE_RADIUS)
                .attr("cy", NodeMenu.MENU_ITEM_CIRCLE_RADIUS)
                .attr("r", NodeMenu.MENU_ITEM_CIRCLE_RADIUS);

            this.menuGroup.append("use")
                .classed("nodeMenu", true)
                .attr("x", NodeMenu.MENU_ITEM_ICON_PADDING)
                .attr("y", NodeMenu.MENU_ITEM_ICON_PADDING)
                .attr("width", NodeMenu.MENU_ITEM_CIRCLE_RADIUS * 2 - (2 * NodeMenu.MENU_ITEM_ICON_PADDING))
                .attr("height", NodeMenu.MENU_ITEM_CIRCLE_RADIUS * 2 - (2 * NodeMenu.MENU_ITEM_ICON_PADDING))
                .attr("fill", DEFAULT_LINE_COLOR)
                .attr("pointer-events", "none")
                .attr("xlink:href", def => "#" + NodeMenuFactory.createIconSymbolId(def.nodeMenuItem.iconType));

            this.menuGroup.transition()
                .ease(d3.easeExpInOut)
                .delay(20)
                .duration(runAnimation ? 400 : 0)
                .style("opacity", 1)
                .tween("pathTween", (menuItem) => {
                        let menuItemPathLength = (pathNodePathLength / (menuItems.length + 1)) * (menuItems.indexOf(menuItem) + 1)
                        if (menuItemPathLength < 1) {
                            menuItemPathLength = 1;
                        }
                        const r = d3.interpolate(0, menuItemPathLength);
                        return function (t: number) {
                            const point = menuPath?.node()?.getPointAtLength(r(t)) as SVGPoint;
                            const x = point.x - NodeMenu.MENU_ITEM_CIRCLE_RADIUS;
                            const y = point.y - NodeMenu.MENU_ITEM_CIRCLE_RADIUS;
                            menuItem.lastPosition = {x: x, y: y};
                            d3.select(this)
                                .attr("transform", `translate(${x}, ${y})`)
                        }
                    }
                )
        }
    }

    public showAnchorIndicator(node: INode) {
        if (this.chartGroup) {
            const path = d3.path();
            path.arc(node.x as number, node.y as number, NodesRenderer.NODE_RADIUS + NodeMenu.MENU_ITEM_CIRCLE_RADIUS + 8, Math.PI, 0);

            const anchorIndicatorPath = this.chartGroup.append("path")
                .datum(node)
                .lower()
                .classed("anchorIndicatorPath", true)
                .attr("stroke", "none")
                .attr("fill", "none")
                .attr("d", path.toString());

            const menuPath = anchorIndicatorPath?.node();

            const anchorIndicatorPoint = menuPath?.getPointAtLength(40) as DOMPoint;
            const x = anchorIndicatorPoint.x - (NodeMenu.MENU_ITEM_CIRCLE_RADIUS / 2);
            const y = anchorIndicatorPoint.y - (NodeMenu.MENU_ITEM_CIRCLE_RADIUS / 2);

            const indicatorGroup = this.chartGroup.append("g")
                .classed("anchorIndicator", true)
                .attr("fill", DEFAULT_LINE_COLOR)
                .attr("transform", `translate(${x}, ${y})`)
                .style("opacity", 0);

            indicatorGroup.append("use")
                .attr("x", NodeMenu.MENU_ITEM_ICON_PADDING)
                .attr("y", NodeMenu.MENU_ITEM_ICON_PADDING)
                .attr("width", NodeMenu.MENU_ITEM_CIRCLE_RADIUS * 2 - (2 * NodeMenu.MENU_ITEM_ICON_PADDING))
                .attr("height", NodeMenu.MENU_ITEM_CIRCLE_RADIUS * 2 - (2 * NodeMenu.MENU_ITEM_ICON_PADDING))
                .attr("pointer-events", "none")
                .attr("xlink:href", def => "#" + NodeMenuFactory.createIconSymbolId(NodeMenuIconType.ANCHOR));

            indicatorGroup.transition()
                .duration(200)
                .style("opacity", 1)
                .on("end", () => {
                    indicatorGroup.transition()
                        .delay(350)
                        .style("opacity", 0)
                        .on("end", () => {
                            anchorIndicatorPath.remove();
                            indicatorGroup.remove();
                        })
                })
        }
    }

    private doAction(event: any, def: IMenuGroupItem) {
        const node = def.node;

        if (def.nodeMenuItem.iconType === NodeMenuIconType.ANCHOR) {
            this.eventManager.publishNodeEvent({type: EventType.NODE_ANCHOR_MENUITEM_CLICKED, event: event, node: node})
        } else if (def.nodeMenuItem.iconType === NodeMenuIconType.UNANCHOR) {
            this.eventManager.publishNodeEvent({type: EventType.NODE_UNANCHOR_MENUITEM_CLICKED, event: event, node: node})
        }
        this.eventManager.publishNodeEvent({type: EventType.NODE_MENUITEM_CLICKED, event: event, node: node})
        def.nodeMenuItem.action(node, event);
    }

    private static createTooltipGroupId(iconType: NodeMenuIconType) {
        return "node-menu-tooltip-id-" + iconType.toString();
    }


    private static createNodeMenuId(iconType: NodeMenuIconType) {
        return "node-menu-id-" + iconType.toString()
    }

    private showTooltip(def: IMenuGroupItem) {
        if (this.chartGroup) {
            const nodeMenu = d3.select("#" + NodeMenu.createNodeMenuId(def.nodeMenuItem.iconType)).node() as SVGGElement;
            const nodeMenuArea = nodeMenu.getBBox();

            const nodeMenuCenterX = def.lastPosition.x + (nodeMenuArea.width / 2);

            const text = this.elementApi.iconTooltips[def.nodeMenuItem.iconType];
            const textBBox = NodeMenu.getTextBBox(text, this.chartGroup) as DOMRect;

            const paddingTop = 3;
            const paddingLeft = 8;
            const tooltipGroupX = nodeMenuCenterX - ((textBBox.width / 2) + paddingLeft)
            const tooltipGroupY = def.lastPosition.y - (textBBox.height + (2 * paddingTop) + 10);
            const tooltipWidth = textBBox.width + (2 * paddingLeft);
            const tooltipHeight = textBBox.height + (2 * paddingTop);
            const textX = paddingLeft;
            const textY = textBBox.height;

            const tooltipGroup = this.chartGroup.append("g")
                .classed("nodeMenuTooltipGroup", true)
                .attr("id", NodeMenu.createTooltipGroupId(def.nodeMenuItem.iconType))
                .attr("transform", `translate(${tooltipGroupX}, ${tooltipGroupY})`)
                .style("opacity", 0);

            tooltipGroup.append("rect")
                .attr("fill", DEFAULT_LINE_COLOR)
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", tooltipWidth)
                .attr("height", tooltipHeight)
                .attr("rx", 10);

            tooltipGroup.append("text")
                .attr("x", textX)
                .attr("y", textY)
                .attr("fill", "#FAFAFA")
                .attr("font-size", NodeMenu.MENU_ITEM_TOOLTIP_FONT_SIZE)
                .text(text);

            tooltipGroup.transition()
                .duration(NodeMenu.MENU_ITEM_TOOLTIP_SHOW_HIDE_DURATION)
                .style("opacity", 1);
        }
    }

    private hideTooltip(def: IMenuGroupItem) {
        d3.select("#" + NodeMenu.createTooltipGroupId(def.nodeMenuItem.iconType))
            .transition()
            .delay(10)
            .duration(NodeMenu.MENU_ITEM_TOOLTIP_SHOW_HIDE_DURATION)
            .style("opacity", 0)
            .on("end", function() {
                d3.select("#" + NodeMenu.createTooltipGroupId(def.nodeMenuItem.iconType)).remove();
            })
    }

    private hideTooltips() {
        d3.select("g."+NodeMenu.MENU_ITEM_TOOLTIP_GROUP_CLASS_NAME).remove();
    }

    private static getTextBBox(text: string, chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>) {
        const element = chartGroup.append("text")
            .attr("fill", "none")
            .attr("stroke", "none")
            .attr("font-size", NodeMenu.MENU_ITEM_TOOLTIP_FONT_SIZE)
            .text(text);

        const textBBox = element.node()?.getBBox();
        element.remove();

        return textBBox;
    }
}