import chatService, {ChatCommentDto} from "../../../../common/apis/chat/ChatService";
import {Grid, IconButton} from "@mui/material";
import UserFormatter from "../users/UserFormatter";
import ChatAvatar from "../../../../components/chat/ChatAvatar";
import ChatCommentHeader from "./ChatCommentHeader";
import ChatCommentBody from "./ChatCommentBody";
import Snackbar from "../snackbar/Snackbar";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {useState} from "react";
import ChatCommentEditor from "./ChatCommentEditor";
import {Clear, Done} from "@mui/icons-material";
import {ErrorTranslationKey} from "../ErrorTranslationKey";

export interface ChatCommentProps {
    chatId: number;
    comment: ChatCommentDto;
    onDeleted: (commentId: number) => void;
    onEdited: (commentId: number, message: string) => void;
    hideDeleteButton?: boolean;
}

enum ChatCommentState {
    VIEW,
    EDIT,
    IN_PROGRESS,
}

export default function ChatComment({chatId, comment, onDeleted, onEdited, hideDeleteButton}: ChatCommentProps) {
    const [state, setState] = useState<ChatCommentState>(ChatCommentState.VIEW);
    const [message, setMessage] = useState<string>(comment.message);

    const name = UserFormatter.formatFullName(comment.user.firstName, comment.user.lastName);

    function onDelete(comment: ChatCommentDto) {
        chatService.deleteComment(chatId, comment.id)
            .then(() => {
                onDeleted(comment.id);
            })
            .catch(() => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_REMOVE_DATA)));
    }

    function switchToEditMode(comment: ChatCommentDto) {
        setMessage(comment.message);
        setState(ChatCommentState.EDIT);
    }

    function onSaved() {
        setState(ChatCommentState.IN_PROGRESS)
        chatService.updateComment(chatId, comment.id, {message: message})
            .then(() => {
                setState(ChatCommentState.VIEW);
                onEdited(comment.id, message);
            })
            .catch((err) => {
                Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), err);
                setState(ChatCommentState.EDIT);
            });
    }

    function onCanceled() {
        setMessage(comment.message);
        setState(ChatCommentState.VIEW);
    }

    return (
        <>
            <Grid container spacing={1} paddingBottom={2}>
                <Grid item xs={"auto"}>
                    <ChatAvatar name={name} />
                </Grid>
                <Grid item xs>
                    <ChatCommentHeader comment={comment}
                                       hideDeleteButton={hideDeleteButton}
                                       onDelete={onDelete}
                                       onEdit={switchToEditMode} />
                    {state === ChatCommentState.VIEW &&
                        <ChatCommentBody comment={comment} />
                    }
                    {state !== ChatCommentState.VIEW &&
                        <Grid container justifyContent={"flex-end"}>
                            <ChatCommentEditor value={message} onValueChanged={setMessage} />
                            <IconButton onClick={onSaved} disabled={state === ChatCommentState.IN_PROGRESS}>
                                <Done/>
                            </IconButton>
                            <IconButton onClick={onCanceled} disabled={state === ChatCommentState.IN_PROGRESS}>
                                <Clear/>
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
}