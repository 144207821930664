export enum ItemType {
    ELEMENT = "ELEMENT",
    RELATIONSHIP = "RELATIONSHIP",
    DIAGRAM = "DIAGRAM",
    FOLDER = "FOLDER",
}

export default interface Item {
    nodeId: string,
    itemType: ItemType,
    elementId?: string,
    labelText: string,
    labelTitle?: string,
    labelIcon: any,
    labelIconColor?: string,
    labelIconBgColor?: string,

    children: Array<Item>,
}