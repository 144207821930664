import {DialogTitle as MuiDialogTitle, Divider, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: 0,
            color: "gray",
            textAlign: "center",
        },
        titleBar: {
            padding: theme.spacing(1.5, 1, 1, 3),
        },
        divider: {
            margin: theme.spacing(0, 2, 0, 2),
        },
        closeButton: {
            marginLeft: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    })
);
interface DialogTitleProps {
    title?: string,
    id?: string,
    onDialogClosed: (e: any) => void,
    left?: React.ReactElement | React.ReactElement[]
    right?: React.ReactElement | React.ReactElement[]
}

export default function DialogTitle(props: DialogTitleProps) {
    const {title, id, left, right, onDialogClosed} = props;
    const classes = useStyles();

    return (
        <>
            <MuiDialogTitle id={id}
                            className={classes.dialogTitle}>
                <Grid container alignItems={"center"} spacing={1} className={classes.titleBar}>
                    <Grid item xs={"auto"} sx={{padding: 0}}>
                        {title}
                    </Grid>
                    <Grid item xs sx={{padding: 0}}>
                        {left}
                    </Grid>
                    <Grid item xs={"auto"} sx={{padding: 0}}>
                        {right}
                    </Grid>
                    <Grid item xs={"auto"}>
                        <IconButton aria-label="close"
                                    className={classes.closeButton}
                                    onClick={onDialogClosed}
                                    size="large">
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </MuiDialogTitle>
        </>
    );
}
