import RenderContext from "../../context/RenderContext";
import ConnectionRendererUtils from "../../renderer/connection/ConnectionRendererUtils";
import * as d3 from "d3";
import ChartGroup, {ChartGroupType} from "../../common/ChartGroup";
import ConnectionBendpointCreateHandle from "./handles/connection/ConnectionBendpointCreateHandle";
import ConnectionDashedLineHandle from "./handles/connection/ConnectionDashedLineHandle";
import ConnectionHandle from "./handles/connection/ConnectionHandle";
import BendpointMoveHandle from "./handles/bendpoint/BendpointMoveHandle";
import BendpointHandle from "./handles/bendpoint/BendpointHandle";
import HiddenConnectionVisualizeHandle from "./handles/connection/HiddenConnectionVisualizeHandle";
import {IDiagramConnectionDto} from "../../../apis/diagram/IDiagramConnectionDto";
import EventManager, {Unsubscriber} from "../../../event/EventManager";
import {EventType, DiagramZoomEvent} from "../../../event/Event";

export default class Handles {

    private eventManager: EventManager;
    private renderContext?: RenderContext;
    private scale: number;

    private connectionBendpointCreateHandle: ConnectionHandle;
    private connectionDashedLineHandle: ConnectionHandle;
    private hiddenConnectionVisualizeHandle: ConnectionHandle;

    private bendpointMoveHandle: BendpointHandle;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.DIAGRAM_ZOOM_UPDATED, this.handleChartZoomEvent.bind(this)));

        this.connectionBendpointCreateHandle = new ConnectionBendpointCreateHandle(this.eventManager);
        this.connectionDashedLineHandle = new ConnectionDashedLineHandle(this.eventManager);
        this.hiddenConnectionVisualizeHandle = new HiddenConnectionVisualizeHandle(this.eventManager);

        this.bendpointMoveHandle = new BendpointMoveHandle(this.eventManager);

        this.scale = 1;
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    initConnectionsHandleGroup(renderContext: RenderContext) {
        this.renderContext = renderContext;
        this.initHandles(renderContext);
    }

    private initHandles(renderContext: RenderContext) {
        this.getAllHandles().forEach(handle => handle.init(renderContext));
    }

    private getAllHandles(): Array<ConnectionHandle | BendpointHandle> {
        return [
            this.connectionBendpointCreateHandle,
            this.connectionDashedLineHandle,
            this.hiddenConnectionVisualizeHandle,
            this.bendpointMoveHandle,
        ]
    }

    private static createConnectionsHandleGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.CONNECTIONS_HANDLE_GROUP, selector);
    }

    initConnectionHandleGroup(connection: IDiagramConnectionDto) {
        const id = Handles.createConnectionHandleGroupId(connection);
        const group = Handles.getConnectionHandleGroup(connection);
        if (group.empty()) {
            Handles.getConnectionsHandleGroup().append("g")
                .attr("id", id);
        }
    }

    private static createConnectionHandleGroupId(connection: IDiagramConnectionDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-handle-group-" + connection.identifier + "__";
    }

    private static getConnectionHandleGroup(connection: IDiagramConnectionDto) {
        const id = Handles.createConnectionHandleGroupId(connection, true);
        return d3.select(id) as d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    }

    removeConnectionHandleGroup(connection: IDiagramConnectionDto) {
        d3.select(Handles.createConnectionHandleGroupId(connection, true)).remove();
    }

    showHiddenConnectionHandles(connection: IDiagramConnectionDto) {
        this.renderHiddenConnectionHandles(connection);
    }

    renderHiddenConnectionHandles(connection: IDiagramConnectionDto) {
        if (this.renderContext) {
            const linePath = ConnectionRendererUtils.createLinePath(connection, this.renderContext.modelManager);
            if (linePath) {
                const connectionHandleGroup = Handles.getConnectionHandleGroup(connection);
                this.connectionDashedLineHandle.render(connection, linePath, connectionHandleGroup, true, this.eventManager);
                this.hiddenConnectionVisualizeHandle.render(connection, linePath, connectionHandleGroup, true, this.eventManager);
            }
        }
    }

    showHandles(connections: Array<IDiagramConnectionDto>, bendpointsOnly?: boolean) {
        connections.forEach(connection => {
            if (bendpointsOnly) {
                this.renderBendpointHandles(connection);
            } else {
                this.renderConnectionHandles(connection);
                this.renderBendpointHandles(connection);
            }
        });
    }

    private renderConnectionHandles(connection: IDiagramConnectionDto) {
        if (this.renderContext) {
            const linePath = ConnectionRendererUtils.createLinePath(connection, this.renderContext.modelManager);
            if (linePath) {
                const connectionHandleGroup = Handles.getConnectionHandleGroup(connection);
                this.connectionDashedLineHandle.render(connection, linePath, connectionHandleGroup, false, this.eventManager);
            }
        }
    }

    private renderBendpointHandles(connection: IDiagramConnectionDto) {
        if (this.renderContext) {
            const linePath = ConnectionRendererUtils.createLinePath(connection, this.renderContext.modelManager);
            if (linePath) {
                const connectionHandleGroup = Handles.getConnectionHandleGroup(connection);
                this.connectionBendpointCreateHandle.render(connection, linePath, connectionHandleGroup, false, this.eventManager);
                if (connection.bendpoints) {
                    const connectionHandleGroup = Handles.getConnectionHandleGroup(connection);

                    connection.bendpoints.forEach((bendpoint, index) => {
                        this.bendpointMoveHandle.render(connection, bendpoint, index, connectionHandleGroup);
                    });
                }
            }
        }
    }

    hideHiddenConnectionHandles(connection: IDiagramConnectionDto) {
        Handles.clearConnectionHandleGroup(connection);
    }

    hideHandles(connections: Array<IDiagramConnectionDto>) {
        connections.forEach(connection => {
            Handles.clearConnectionHandleGroup(connection);
        })
    }


    private static clearConnectionHandleGroup(connection: IDiagramConnectionDto) {
        Handles.getConnectionHandleGroup(connection)
            .selectAll("*")
            .remove();
    }

    synchronizeConnectionHandlesVisibility(connections: Array<IDiagramConnectionDto>, selectedConnections: Array<IDiagramConnectionDto>) {
        this.hideHandles(connections);
        connections.forEach(connection => {
            if (selectedConnections.indexOf(connection) !== -1) {
                this.showHandles([connection]);
            }
        });
    }

    private static getConnectionsHandleGroup() {
        const id = Handles.createConnectionsHandleGroupId(true);
        return d3.select(id) as d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    }

    private handleChartZoomEvent(event: DiagramZoomEvent) {
        if (event.type === EventType.DIAGRAM_ZOOM_UPDATED) {
            const scale = event.actualZoom;
            this.updateScale(scale);
        }
    }

    private updateScale(scale: number) {
        this.scale = scale;

        this.updateHandlesScaleFactor();
    }

    private updateHandlesScaleFactor() {
        this.getAllHandles().forEach(handle => handle.updateScaleFactor(this.getHandleScaleFactor()));
    }

    private getHandleScaleFactor(): number {
        if (this.scale === 1) {
            return this.scale;
        } else if (this.scale > 1) {
            return .5 * this.scale;
        } else {
            return 1 / this.scale;
        }
    }
}
