import {Autocomplete} from "@mui/material";
import React from "react";
import TextField from "./textfield/TextField";

interface SelectFieldProps<T> {
    id: string;
    value: string;
    label: string;
    errorMessage: string | undefined;
    options: T[];
    onChange: (newValue: T | string) => void;
    getOptionLabel: (option: T) => string;
    freeSolo?: boolean;
    required?: boolean;
}

export default function SelectField<T>(props: SelectFieldProps<T>) {
    const {
        id, value, label, errorMessage, options, onChange, getOptionLabel,
        freeSolo, required
    } = props;

    function handleOnChange(newValue: T | string | null) {
        let fixedValue: string | T;
        if (newValue === null) {
            fixedValue = "";
        } else {
            const foundOption = typeof newValue === 'string' ?
                options.find(option => getOptionLabel(option) === newValue) : undefined;
            fixedValue = foundOption || newValue;
        }
        onChange(fixedValue);
    }

    return (
        <Autocomplete
            id={id}
            size={"small"}
            freeSolo={freeSolo}
            autoSelect={true}
            options={options}
            onChange={(event: any, newValue: T | string | null) => {
                handleOnChange(newValue);
            }}
            value={value}
            getOptionLabel={(option: T | string) =>
                typeof option === 'string' ? option : getOptionLabel(option)}
            renderInput={(params) =>
                <TextField {...params}
                           label={label}
                           required={required}
                           errorMessage={errorMessage}
                />}
        />
    );
}