import {createStyles, WithStyles, withStyles} from "@mui/styles";
import React from "react";
import {IGraphDataDto} from "../../common/apis/GraphData";
import ReactDOM from "react-dom";
import {ElementApi, GraphDataChart, GraphDataChartApi} from "./GraphDataChart";
import {AjaxResponse} from "rxjs/ajax";
import {Observable} from "rxjs";
import {IconButton, Tooltip} from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import UIUtils from "../../common/UIUtils";
import Constants from "../../common/Constants";
import {_transl} from "../../store/localization/TranslMessasge";
import {GraphChartTranslationKey} from "./GraphChartTranslationKey";
import {Theme} from "@mui/material/styles";
import {ViewList} from "@mui/icons-material";


const styles = (theme: Theme) => createStyles({
    svgDiv: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    svg: {
        minWidth: "300px",
        minHeight: "500px",
    },
    menuBottom: {
        position: "absolute",
        right: theme.graph.controls.edgeOffset,
        bottom: theme.graph.controls.edgeOffset,
        backgroundColor: Constants.TRANSPARENT_MENU_MOUSE_LEAVE_BG_COLOR,
        padding: theme.spacing(1),
        borderRadius: theme.graph.controls.borderRadius,
    },
    menuTop: {
        position: "absolute",
        right: theme.graph.controls.edgeOffset,
        top: theme.graph.controls.edgeOffset,
        backgroundColor: Constants.TRANSPARENT_MENU_MOUSE_LEAVE_BG_COLOR,
        padding: theme.spacing(1),
        borderRadius: theme.graph.controls.borderRadius,
    }
});

interface IProps extends WithStyles<typeof styles> {
    entityId: string,
    initialExplodedElementIds: Array<string>,
    onShowElementDetail: (id: string) => void,
    refreshGraphData: (elementIds: Array<string>) => Observable<AjaxResponse>,
    backendGraphDataChangedDate: Date,
    onShowElementsGrid: (elementDtoIds: Array<string>) => void,
    allowMaximize?: boolean,
}

interface IState {
    neighboursData: IGraphDataDto,
    explodedElementIds: Array<string>,
    maximized: boolean;
}

export const GRAPH_DATA_CHART_SVG_DIV_ID = "__graph-data-panel__";

class GraphDataChartPanel extends React.Component<IProps, IState> {

    private bottomMenuRef: React.RefObject<HTMLDivElement>;
    private topMenuRef: React.RefObject<HTMLDivElement>;
    private svgRef: React.RefObject<SVGSVGElement>;
    private chartApi?: GraphDataChartApi;

    constructor(props: IProps) {
        super(props);
        this.bottomMenuRef = React.createRef<HTMLDivElement>();
        this.topMenuRef = React.createRef<HTMLDivElement>();
        this.svgRef = React.createRef<SVGSVGElement>();
        this.state = this.createInitialState();
    }

    createInitialState() {
        return {
            explodedElementIds: this.props.initialExplodedElementIds,
            neighboursData: this.createInitialNeighboursData(),
            maximized: true,
        }
    }

    createInitialNeighboursData() {
        return {
            elementsData: [],
            relationshipsData: [],
            maxResultsLimit: Number.MAX_SAFE_INTEGER,
            maxResultsLimitExceeded: false,
        };
    }

    componentDidMount() {
        this.createChart();
    }

    createChart() {
        const api: ElementApi = {
            iconTooltips: {
                ANCHOR: _transl(GraphChartTranslationKey.ANCHOR),
                UNANCHOR: _transl(GraphChartTranslationKey.UNANCHOR),
                EXPLODE: _transl(GraphChartTranslationKey.EXPLODE),
                SHOW_DETAIL: _transl(GraphChartTranslationKey.SHOW_DETAIL),
            },
            showElementDetail: (id) => this.props.onShowElementDetail(id),
            explodeElement: (id) => this.onExplodeElement(id),
        }
        this.chartApi = GraphDataChart.create(this.state.explodedElementIds, api);
        this.fetchChartData();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.entityId !== this.props.entityId) {
            // reset state and fetch data
            this.createChart();
        }
        else if (prevState.explodedElementIds !== this.state.explodedElementIds ||
            prevProps.backendGraphDataChangedDate !== this.props.backendGraphDataChangedDate) {
            // fetch data
            this.fetchChartData();
        }
        // always render chartApi into newly created svg element
        const parentRect = ReactDOM.findDOMNode(this)?.parentElement?.getBoundingClientRect() as DOMRect;
        this.chartApi?.renderChart(this.props.entityId, this.state.neighboursData, this.state.explodedElementIds, this.svgRef.current as SVGSVGElement, parentRect);
    }

    componentWillUnmount() {
        this.chartApi?.destroyChart();
    }

    render() {
        const {classes, onShowElementsGrid, allowMaximize} = this.props;
        const {maximized} = this.state;
        const allowMaximizeResolved = allowMaximize == null ? true : allowMaximize;

        return (
            <div id={GRAPH_DATA_CHART_SVG_DIV_ID} className={classes.svgDiv}>
                <div ref={this.topMenuRef}
                     className={classes.menuTop}
                     onMouseEnter={() => UIUtils.animateBackgroundColorTo(Constants.TRANSPARENT_MENU_MOUSE_ENTER_BG_COLOR, this.topMenuRef)}
                     onMouseLeave={() => UIUtils.animateBackgroundColorTo(Constants.TRANSPARENT_MENU_MOUSE_LEAVE_BG_COLOR, this.topMenuRef)}
                >
                    <Tooltip title={_transl(GraphChartTranslationKey.LIST_ELEMENTS)} placement={"top"}>
                        <IconButton
                            onClick={(e) => {
                                onShowElementsGrid(this.state.neighboursData.elementsData.map(data => data.id));
                            }}
                            size="large">
                            <ViewList />
                        </IconButton>
                    </Tooltip>
                    {allowMaximizeResolved && !maximized &&
                    <Tooltip title={_transl(GraphChartTranslationKey.MINIMIZE)} placement={"top"}>
                        <IconButton
                            onClick={(e) => {
                                this.chartApi?.minimize(e);
                                this.setState({maximized: true});
                            }}
                            size="large">
                            <FullscreenExitIcon />
                        </IconButton>
                    </Tooltip>
                    }
                    {allowMaximizeResolved && maximized &&
                    <Tooltip title={_transl(GraphChartTranslationKey.MAXIMIZE)} placement={"top"}>
                        <IconButton
                            onClick={(e) => {
                                this.chartApi?.maximize(e);
                                this.setState({maximized: false});
                            }}
                            size="large">
                            <FullscreenIcon />
                        </IconButton>
                    </Tooltip>
                    }
                </div>
                <svg ref={this.svgRef} width={"100%"} height={"100%"} className={classes.svg}/>
                <div ref={this.bottomMenuRef}
                     className={classes.menuBottom}
                     onMouseEnter={() => UIUtils.animateBackgroundColorTo(Constants.TRANSPARENT_MENU_MOUSE_ENTER_BG_COLOR, this.bottomMenuRef)}
                     onMouseLeave={() => UIUtils.animateBackgroundColorTo(Constants.TRANSPARENT_MENU_MOUSE_LEAVE_BG_COLOR, this.bottomMenuRef)}
                >
                    <Tooltip title={_transl(GraphChartTranslationKey.RESET_VIEW)} placement={"top"}>
                        <IconButton onClick={(e) => this.chartApi?.resetZoom(e)} size="large">
                            <FindReplaceIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={_transl(GraphChartTranslationKey.ZOOM_IN)} placement={"top"}>
                        <IconButton onClick={(e) => this.chartApi?.zoomIn(e)} size="large">
                            <ZoomInIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={_transl(GraphChartTranslationKey.ZOOM_OUT)} placement={"top"}>
                        <IconButton onClick={(e) => this.chartApi?.zoomOut(e)} size="large">
                            <ZoomOutIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );
    }

    fetchChartData() {
        const {explodedElementIds} = this.state;
        const {refreshGraphData} = this.props;
        refreshGraphData(explodedElementIds).subscribe(
            (response: AjaxResponse) => {
                const data = response.response as IGraphDataDto;
                this.setState({neighboursData: data});
            }
        )
    }

    onExplodeElement(id: string) {
        this.setState({explodedElementIds: [...this.state.explodedElementIds, id]});
    }



}

export default withStyles(styles, { withTheme: true })(GraphDataChartPanel);
