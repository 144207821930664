import React, {useState} from "react";
import EditableSelectComponent from "./EditableSelectComponent";
import {UserRoleTranslator} from "../../pages/main/content/users/UserRoleTranslator";
import {UserRoleType} from "../../common/access/UserRoleType";


interface Props {
    // textfield props
    label?: string,
    id?: string,
    initialValues:  UserRoleType[],

    // select props
    selectedRole?: UserRoleType,
    isOptional: boolean,

    // edit callback
    doUpdate: (value: UserRoleType) => Promise<any>,
    onSuccessfulUpdate?: (value: UserRoleType) => void,
    readonly?: boolean,
}

export default function EditableRoleComponent(props: Props) {

    const {label, id, selectedRole, isOptional, doUpdate, onSuccessfulUpdate, readonly, initialValues } = props
    const role = selectedRole ? selectedRole : UserRoleType.ROLE_OPERATOR;
    const [newRole, setNewRole] = useState<UserRoleType>(role);
    const labelFormatter = (role: unknown) => UserRoleTranslator.get().translateUserRole(role as string) ?? "";
    const update = doUpdate as (state?: unknown) => Promise<any>;

        return (
            <EditableSelectComponent label={label || "Role"}
                                     id={id}
                                     initialValues={initialValues}
                                     initialSelectedValue={newRole}
                                     isOptional={isOptional}
                                     getValueLabel={labelFormatter}
                                     getValueId={(role) => role ? (role as UserRoleType) : ""}
                                     doUpdate={update}
                                     onSuccessfulUpdate={(role?) => {
                                         if (onSuccessfulUpdate != null) {
                                             onSuccessfulUpdate(role as UserRoleType);
                                         }
                                         setNewRole(role as UserRoleType);
                                     }}
                                     readonly={readonly}
            />
        );
}
