import {_transl} from "../../../../../store/localization/TranslMessasge";
import {HierarchyTreeTranslationKey} from "../HierarchyTreeTranslationKey";
import ConfirmationDialog from "../../../../../components/dialogs/ConfirmationDialog";
import React from "react";

export interface HierarchyTreeDeleteDialogProps {
    open: boolean;
    treeId?: string;
    onConfirm: (treeId: string) => void;
    onCancel: () => void;
}

export function HierarchyTreeDeleteDialog({open, treeId, onConfirm, onCancel}: HierarchyTreeDeleteDialogProps) {
    return (
        <ConfirmationDialog open={open}
                            title={_transl(HierarchyTreeTranslationKey.STRUCTURE_DELETING_TITLE)}
                            confirmationText={_transl(HierarchyTreeTranslationKey.STRUCTURE_DELETING_CONFIRMATION_TEXT)}
                            onConfirm={() => onConfirm(treeId!)}
                            onReject={() => onCancel()} />
    );
}
