import React, {useEffect, useState} from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from '@mui/icons-material/Create';
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../../components/grid/GridAction";
import {PersistentStateId} from "../../../../../store/common/Grid";
import ConfirmationDialog from "../../../../../components/dialogs/ConfirmationDialog";
import {CollectionDto} from "../../../../../common/apis/collection/CollectionDto";
import {CollectionsTranslationKey} from "../CollectionsTranslationKey";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {CollectionsController} from "../controller/CollectionsController";
import {FetchableResourceType, getResourceFetchAction} from "../../../../../store/common/FetchableResource";
import {useDispatch} from "react-redux";
import Snackbar from "../../snackbar/Snackbar";
import {UpdateCollectionDialog} from "../dialog/UpdateCollectionDialog";
import {createCollectionsGridColDef} from "./CollectionsGridColumnDefinition";
import {CreateCollectionDialog} from "../dialog/CreateCollectionDialog";
import {CollectionErrorCode} from "../service/CollectionErrorCode";
import ExtGridWrapper from "../../../../../components/grid/ExtGridWrapper";
import {ValidationError} from "../../../../../common/ValidationError";

interface CollectionsGridProps {
    controller: CollectionsController;
}

export default function CollectionsGrid({controller}: CollectionsGridProps) {
    const dispatch = useDispatch();

    const [collections, setCollections] = useState<CollectionDto[]>([]);
    const [isCreateCollectionsDialogOpen, setIsCreateCollectionsDialogOpen] = useState<boolean>(false);
    const [isUpdateCollectionsDialogOpen, setIsUpdateCollectionsDialogOpen] = useState<boolean>(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [selectedCollection, setSelectedCollection] = useState<CollectionDto | null>(null);
    const [reload, setReload] = useState<number>(0);

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            try {
                const allCollections = await controller.getAll();
                if (!isUnmounted) {
                    setCollections(allCollections);
                    dispatch(getResourceFetchAction(FetchableResourceType.COMMON_COLLECTION_OPTIONS));
                }
            } catch (error) {
                Snackbar.error(_transl(CollectionsTranslationKey.NOT_FETCHED));
            }
        })();
        return () => {
            isUnmounted = true;
        }
    }, [reload, controller, dispatch]);

    function reloadCollections() {
        setReload(reload + 1);
    }

    async function deleteRow() {
        if (selectedCollection !== undefined && selectedCollection !== null) {
            try {
                await controller.delete(selectedCollection.code);
                reloadCollections();
                Snackbar.success(_transl(CollectionsTranslationKey.DELETE_SUCCEEDED));
            } catch (error: any) {
                if (error instanceof ValidationError) {
                    const validationError = error as ValidationError;
                    if (validationError.error.code === CollectionErrorCode.COLLECTION_IS_REFERENCED) {
                        Snackbar.error(_transl(CollectionsTranslationKey.COLLECTION_IS_REFERENCED));
                    }
                } else {
                    Snackbar.error(_transl(CollectionsTranslationKey.DELETE_FAILED));
                }
            }
        }
        setIsConfirmationDialogOpen(false);
    }

    function onCollectionUpdate(collection: CollectionDto) {
        setIsUpdateCollectionsDialogOpen(false)
        reloadCollections();
        setSelectedCollection(collection);
    }

    function onCollectionCreate(){
        setIsCreateCollectionsDialogOpen(false);
        reloadCollections();
    }

    return (
        <React.Fragment>
            <ConfirmationDialog open={isConfirmationDialogOpen}
                                title={_transl(CollectionsTranslationKey.DELETE_TITLE)}
                                confirmationText={_transl(CollectionsTranslationKey.DELETE_CONFIRMATION)}
                                onConfirm={() => deleteRow()}
                                onReject={() => setIsConfirmationDialogOpen(false)}
            />
            {isCreateCollectionsDialogOpen &&
                <CreateCollectionDialog onClosed={() => setIsCreateCollectionsDialogOpen(false)}
                                        onCollectionCreated={() => onCollectionCreate()}
                />
            }
            {selectedCollection && isUpdateCollectionsDialogOpen &&
                <UpdateCollectionDialog onClosed={() => setIsUpdateCollectionsDialogOpen(false)}
                                        collection={selectedCollection}
                                        onCollectionUpdate={(collection) => onCollectionUpdate(collection)}
                />
            }

            <ExtGridWrapper
                columns={createCollectionsGridColDef(_transl)}
                rows={collections}
                rowCount={collections.length}
                getRowId={row => row.code}
                onRowClick={(param) => setSelectedCollection(param.row as CollectionDto)}
                actions={[
                    GridAction.buttonBuilder("ADD_COLLECTION_BUTTON", ActionButtonType.IMMEDIATE, _transl(CollectionsTranslationKey.CREATE_BUTTON))
                        .icon(<AddIcon/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .onClick(() => setIsCreateCollectionsDialogOpen(true))
                        .build(),
                    GridAction.buttonBuilder("UPDATE_COLLECTION_BUTTON", ActionButtonType.IMMEDIATE, _transl(CollectionsTranslationKey.UPDATE_BUTTON))
                        .icon(<CreateIcon/>)
                        .onClick(() => setIsUpdateCollectionsDialogOpen(true))
                        .build(),
                    GridAction.buttonBuilder("DELETE_COLLECTION_BUTTON", ActionButtonType.IMMEDIATE, _transl(CollectionsTranslationKey.DELETE_BUTTON))
                        .icon(<DeleteIcon/>)
                        .onClick(() => setIsConfirmationDialogOpen(true))
                        .build()
                ]}
                peristentStateId={PersistentStateId.COLLECTIONS_PAGE_GRID}
                resourceId={"collections"}
            />
        </React.Fragment>
    );
}
