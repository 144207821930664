import {INode} from "../ModelManager";
import {ElementApi, GraphDataChartApi} from "../../GraphDataChart";
import * as d3 from "d3";
import {hideMainPageOverlay} from "../../../../pages/MainPage";

export enum NodeMenuIconType {
    ANCHOR = "ANCHOR",
    UNANCHOR = "UNANCHOR",
    SHOW_DETAIL = "SHOW_DETAIL",
    EXPLODE = "EXPLODE",
}

export interface INodeMenuItem {
    iconType: NodeMenuIconType,
    action: (node: INode, event: any) => void,
}

export interface INodeMenu {
    items: Array<INodeMenuItem>,
}

interface IIconDefinition {
    iconType: NodeMenuIconType,
    viewBox: string,
    d: string,
}

export default class NodeMenuFactory {

    private static readonly ICON_DEFINITIONS: Array<IIconDefinition> = [
        {
            iconType: NodeMenuIconType.ANCHOR,
            viewBox: "0 0 24 24",
            d: "m17 15 1.55 1.55c-.96 1.69-3.33 3.04-5.55 3.37V11h3V9h-3V7.82C14.16 7.4 15 6.3 15 5c0-1.65-1.35-3-3-3S9 3.35 9 5c0 1.3.84 2.4 2 2.82V9H8v2h3v8.92c-2.22-.33-4.59-1.68-5.55-3.37L7 15l-4-3v3c0 3.88 4.92 7 9 7s9-3.12 9-7v-3l-4 3zM12 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z",
        },
        {
            iconType: NodeMenuIconType.UNANCHOR,
            viewBox: "0 0 24 24",
            d: "m17 15 1.55 1.55c-.96 1.69-3.33 3.04-5.55 3.37V11h3V9h-3V7.82C14.16 7.4 15 6.3 15 5c0-1.65-1.35-3-3-3S9 3.35 9 5c0 1.3.84 2.4 2 2.82V9H8v2h3v8.92c-2.22-.33-4.59-1.68-5.55-3.37L7 15l-4-3v3c0 3.88 4.92 7 9 7s9-3.12 9-7v-3l-4 3zM12 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z  M 0 23 L 22 0 23 1 1 24 z",
        },
        {
            iconType: NodeMenuIconType.SHOW_DETAIL,
            viewBox: "0 0 24 24",
            d: "M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z",
        },
        {
            iconType: NodeMenuIconType.EXPLODE,
            viewBox: "0 0 24 24",
            d: "m14 4 2.29 2.29-2.88 2.88 1.42 1.42 2.88-2.88L20 10V4zm-4 0H4v6l2.29-2.29 4.71 4.7V20h2v-8.41l-5.29-5.3z",
        }
    ];

    public static create(defsElement: d3.Selection<SVGDefsElement, unknown, any, any>,
                         chartApi: GraphDataChartApi,
                         elementApi: ElementApi): INodeMenu {

        defsElement?.selectAll("symbol.nodeMenuIcon")
            .data(NodeMenuFactory.ICON_DEFINITIONS)
            .join("symbol")
            .classed("nodeMenuIcon", true)
            .attr("id", def => NodeMenuFactory.createIconSymbolId(def.iconType))
            .attr("viewBox", def => def.viewBox)
            .append("path")
            .attr("d", def => def.d);

        return {
            items: [
                {
                    iconType: NodeMenuIconType.ANCHOR,
                    action: (node, event) => chartApi.anchor(node, event),
                },
                {
                    iconType: NodeMenuIconType.UNANCHOR,
                    action: (node, event) => chartApi.unanchor(node, event),
                },
                {
                    iconType: NodeMenuIconType.SHOW_DETAIL,
                    action: (node, event) => {
                        hideMainPageOverlay();
                        elementApi.showElementDetail(node.id)
                    },
                },
                {
                    iconType: NodeMenuIconType.EXPLODE,
                    action: (node, event) => elementApi.explodeElement(node.id),
                }
            ]
        }
    }

    public static createIconSymbolId(iconType: NodeMenuIconType) {
        return "node-menu-icon-symbol-" + iconType.toString().replaceAll("_", "-");
    }

    public static renderIcon(iconType: NodeMenuIconType, rect: DOMRect, group: d3.Selection<SVGGElement, any, any, any>) {
        return group.selectAll("use.nodeMenuIcon")
            .datum(iconType)
            .join("use")
            .classed("nodeMenuIcon", true)
            .attr("href", "#" + NodeMenuFactory.createIconSymbolId(iconType))
            .attr("x", rect.x)
            .attr("y", rect.y)
            .attr("width", rect.width)
            .attr("height", rect.height);
    }

}