import {ModelManager} from "../../manager/ModelManager";
import {DiagramEditorUtils} from "../../util/DiagramEditorUtils";
import NodeRendererUtils from "./NodeRendererUtils";
import AbstractNodeRenderer, {ColorAttributes, DecorationType, ShapeType} from "./AbstractNodeRenderer";
import * as d3 from "d3";
import RenderMode from "../../context/RenderMode";
import RenderContext from "../../context/RenderContext";
import EventManager from "../../../event/EventManager";
import {EventType} from "../../../event/Event";
import PositionHandle from "../../manager/nodeposition/PositionHandle";
import {Area, Point} from "../../util/GeometryUtils";
import NodeRendererFactory from "./NodeRendererFactory";
import {LAYER_DEFAULT_COLOR} from "../../../archimate/ArchiMateLayer";
import {ArchimateElement, ArchimateElementType} from "../../../archimate/ArchimateElement";
import {ColorRenderer} from "../color/ColorRenderer";
import {DiagramNode} from "../../model/Model";
import StateIndicatorRenderer from "./StateIndicatorRenderer";
import stateColorResolver from "../../../StateColorResolver";
import ExternalLinkRenderer from "./ExternalLinkRenderer";

export const POINTER_EVENTS_CLASS_NAME = "PointerEvents";
export const POINTER_EVENTS_RECT_ATTR_VALUE = "all";

type TopDecorationsType = DecorationType.HORIZONTAL_LINE_TOP | DecorationType.HALF_RECT_TOP_LEFT | null;
type LeftDecorationsType = DecorationType.APP_BARS | null;
type BottomDecorationsType = DecorationType.HORIZONTAL_LINE_BOTTOM | null;

export class Decorations {
    constructor(public top: TopDecorationsType,
                public left: LeftDecorationsType,
                public bottom: BottomDecorationsType) {
        this.top = top;
        this.left = left;
        this.bottom = bottom;
    }

    public static of(top: TopDecorationsType, left: LeftDecorationsType, bottom: BottomDecorationsType) {
        return new Decorations(top, left, bottom);
    }

    public static ofTop(top: TopDecorationsType) {
        return new Decorations(top, null, null);
    }

    public static ofLeft(left: LeftDecorationsType) {
        return new Decorations(null, left, null);
    }

    public static ofBottom(bottom: BottomDecorationsType) {
        return new Decorations(null, null, bottom);
    }

    isEmpty() {
        return this.top == null && this.left == null && this.bottom == null;
    }

    toArray() {
        const array = [];
        if (this.top != null) {
            array.push(this.top);
        }
        if (this.left != null) {
            array.push(this.left);
        }
        if (this.bottom != null) {
            array.push(this.bottom);
        }
        return array;
    }
}

export default class ConfigurableNodeRenderer extends AbstractNodeRenderer {

    // do interpret drag <= 2 units as a click
    public static readonly NODE_CLICK_THRESHOLD = 2;

    private shapeType: ShapeType;
    private decorations: Array<DecorationType>;
    private isIconRendered: boolean;
    private stateIndicatorRenderer: StateIndicatorRenderer;
    private externalLinkRenderer: ExternalLinkRenderer;

    constructor(shapeType: ShapeType, decorations: Decorations, isIconRendered: boolean) {
        super();
        this.shapeType = shapeType;
        this.decorations = decorations.toArray();
        this.isIconRendered = isIconRendered;
        this.stateIndicatorRenderer = new StateIndicatorRenderer(stateColorResolver);
        this.externalLinkRenderer = new ExternalLinkRenderer();
    }

    init(diagramGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
         defsGroup: d3.Selection<SVGDefsElement, unknown, null, undefined>,
         modelAccessor: ModelManager): void {
    }

    render(node: DiagramNode,
           nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
           renderContext: RenderContext,
           eventManager: EventManager): void
    {
        const mode = renderContext.renderMode.mode;
        const modelManager = renderContext.modelManager;

        const elementId = node.elementIdentifier;
        const element = modelManager.getElementById(elementId as string);
        const elementType = element && ArchimateElement.findByStandardName(element.type);

        const label = DiagramEditorUtils.getNodeLabel(node, modelManager);

        // append shape group
        const shapeGroup = nodeGroup.append("g")
            .attr("id", NodeRendererUtils.createNodeShapeGroupId(node))
            .datum(node);

        // append shape to shape group
        this.renderShapeType(node, shapeGroup, modelManager);

        // append decorations to shape group
        this.renderDecorations(node, shapeGroup, modelManager);

        // append state indicator
        this.renderStateIndicator(node, shapeGroup, modelManager);

        if (mode !== RenderMode.PREVIEW) {
            if (NodeRendererUtils.isTextAware(elementType?.elementType || null)) {
                // append text to shape group
                const elementStereotype = element?.stereotype?.name;
                this.renderText(label, shapeGroup, node, elementType, elementStereotype, this.shapeType, this.decorations);
            }
        }

        // append icon to shape group
        if (this.isIconRendered) {
            this.renderIcon(node, elementType, shapeGroup, this.shapeType, this.decorations);
        }

        const overlay = this.appendPointerEventsRect(shapeGroup, node, eventManager, mode, renderContext);

        // append title to pointer events overlay
        const formattedType = (elementType && elementType.visibleName);
        overlay.append("title")
            .text(d =>  label + "\n" + (formattedType || d.type) + "\n" + (d.description || ""));

        const domRect = shapeGroup.node()?.getBBox() as DOMRect;
        NodeRendererUtils.appendAreaProperty(shapeGroup, Area.fromDOMRect(domRect));

        // append external link
        this.renderExternalLink(node, shapeGroup, modelManager);

        eventManager.publishEvent({type: EventType.NODE_RENDERED, node: node, event: {}})
    }

    protected renderShapeType(node: DiagramNode,
                              shapeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                              modelManager: ModelManager)
    {
        let shape;
        const colorAttributes = ConfigurableNodeRenderer.createColorAttributes(node, modelManager);
        switch(this.shapeType) {
            case ShapeType.ROUNDED_RECTANGLE: shape = this.renderRoundedRectangle(shapeGroup, colorAttributes); break;
            case ShapeType.BEVELLED_RECTANGLE: shape = this.renderBevelledRectangle(shapeGroup, colorAttributes); break;
            case ShapeType.WAVED_RECTANGLE: shape = this.renderWavedRectangle(shapeGroup, colorAttributes); break;
            case ShapeType.RIGHT_TOP_FOLDED_RECTANGLE: shape = this.renderRightTopFoldedRectangle(shapeGroup, colorAttributes); break;
            case ShapeType.BLOCK: shape = this.renderBlock(shapeGroup, colorAttributes); break;
            case ShapeType.ELIPSIS: shape = this.renderElipsis(shapeGroup, colorAttributes); break;
            case ShapeType.CLOUD: shape = this.renderCloud(shapeGroup, colorAttributes); break;
            case ShapeType.DEVICE: shape = this.renderDevice(shapeGroup, colorAttributes); break;
            case ShapeType.DASHED_FOLDER: shape = this.renderFolder(shapeGroup, colorAttributes, true); break;
            case ShapeType.SOLID_FOLDER: shape = this.renderFolder(shapeGroup, colorAttributes, false); break;
            case ShapeType.CIRCLE: shape = this.renderCircle(shapeGroup, colorAttributes); break;
            case ShapeType.RIGHT_BOTTOM_CLIPPED_RECTANGLE: shape = this.renderRightBottomClippedRectangle(shapeGroup, colorAttributes); break;
            default: shape = this.renderRectangle(shapeGroup, colorAttributes); break;
        }
        if (shape) {
            if (modelManager.getParentNode(node) == null) {
                shape.style("filter", "drop-shadow(2px 2px 2px rgb(120, 120, 120))");
            }
            if (node.highlighted) {
                shape.style("filter", "drop-shadow(0px 0px 5px rgb(255, 0, 0))");
            }
            if (node.forbidden) {
                shapeGroup.style("filter", "opacity(50%) grayscale(100%)");
                shapeGroup.style("cursor", "not-allowed");
            }
        }
    }

    protected renderDecorations(node: DiagramNode,
                                shapeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                modelManager: ModelManager) {
        const colorAttributes = ConfigurableNodeRenderer.createColorAttributes(node, modelManager);
        this.decorations.forEach(decoration => {
            switch (decoration) {
                case DecorationType.HORIZONTAL_LINE_TOP: this.renderHorizontalLineTop(shapeGroup, colorAttributes, this.shapeType); break;
                case DecorationType.HORIZONTAL_LINE_BOTTOM: this.renderHorizontalLineBottom(shapeGroup, colorAttributes, this.shapeType); break;
                case DecorationType.HALF_RECT_TOP_LEFT: this.renderHalfRectTopLeft(shapeGroup, colorAttributes, this.shapeType); break;
                case DecorationType.APP_BARS: this.renderAppBars(shapeGroup, colorAttributes, this.shapeType); break;
                default: break;
            }
        })
    }

    private static createColorAttributes(node: DiagramNode,
                                         modelManager: ModelManager): ColorAttributes {
        const elementType = modelManager.getElementType(node.elementIdentifier);

        let fillColor = node.style?.fillColor
            ? ColorRenderer.renderColor(node.style.fillColor)
            : NodeRendererUtils.getNodeFillColor(node, modelManager);
        let fillGradientId: string | undefined;
        if (!(node.style?.fillColor) && elementType) {
            fillGradientId = NodeRendererFactory.getElementShapeGradientId(elementType);
        }
        const fillDarkerColor = d3.color(fillColor)?.darker(0.5).formatRgb() || LAYER_DEFAULT_COLOR;

        let strokeColor = node.style?.lineColor
            ? ColorRenderer.renderColor(node.style.lineColor)
            : d3.color(fillColor)?.darker(1).formatRgb() || "black";
        if (elementType === ArchimateElementType.OR_JUNCTION || elementType === ArchimateElementType.AND_JUNCTION) {
            strokeColor = "black";
        }
        const strokeWidth = 0.8;

        return new ColorAttributes(fillColor, fillGradientId, fillDarkerColor, strokeColor, strokeWidth);
    }

    private appendPointerEventsRect(shapeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                    node: DiagramNode,
                                    eventManager: EventManager,
                                    mode: RenderMode,
                                    renderContext: RenderContext) {
        const overlay = shapeGroup.append("rect")
            .attr("fill", "none")
            .attr("stroke", "none")
            .attr("x", node.x)
            .attr("y", node.y)
            .attr("width", node.w)
            .attr("height", node.h)
            .attr("pointer-events", POINTER_EVENTS_RECT_ATTR_VALUE)
            .classed(POINTER_EVENTS_CLASS_NAME, true);

        overlay.on("dblclick", (event: any, node) => {
            eventManager.publishEvent({type: EventType.NODE_DBLCLICK, event: event, node: node});
        });

        if (mode === RenderMode.EDIT || mode === RenderMode.PRE_EDIT) {
            // append mouse events publisher - e.g. mouse enter, mouse leave
            this.addEditPointerEvents(overlay, node, eventManager, mode, renderContext);
        }
        return overlay;
    }

    private addEditPointerEvents(overlay: d3.Selection<SVGRectElement, DiagramNode, null, undefined>,
                                 node: DiagramNode,
                                 eventManager: EventManager,
                                 mode: RenderMode,
                                 renderContext: RenderContext) {
            overlay
                .on("mouseenter", (event: any, node) => {
                    eventManager.publishEvent({type: EventType.NODE_MOUSE_ENTER, event: event, node: node});
                })
                .on("mouseleave", (event: any, node) => {
                    eventManager.publishEvent({type: EventType.NODE_MOUSE_LEAVE, event: event, node: node});
                })
                .on("mouseup", (event: any, node) => {
                    eventManager.publishEvent({type: EventType.NODE_MOUSE_UP, event: event, node: node});
                })
                .on("mousedown", (event: any, node) => {
                    eventManager.publishEvent({type: EventType.NODE_MOUSE_DOWN, event: event, node: node});
                })
                .on("contextmenu", (event: any, node) => {
                    event.preventDefault();

                    const diagramGroupPoint = DiagramEditorUtils.convertOuterPointToGroup(new Point(event.clientX, event.clientY),
                        renderContext.svgElementManager.getDiagramGroupSelection().node() as SVGGElement,
                        renderContext.svgElementManager.getSvg() as SVGSVGElement);

                    eventManager.publishEvent({
                        type: EventType.NODE_SHOW_CONTEXT_MENU,
                        event: event,
                        node: node,
                        transformedClientCoordinates: [diagramGroupPoint.x, diagramGroupPoint.y]
                    });
                })



        const clickThreshold = ConfigurableNodeRenderer.NODE_CLICK_THRESHOLD;

        const publishClickEvent = (event: any) => eventManager.publishEvent({type: EventType.NODE_MOUSE_CLICKED, event: event, node: node});
        PositionHandle.addMoveEventPublisher(overlay, node, eventManager, mode, clickThreshold, publishClickEvent);

    }

    private renderStateIndicator(node: DiagramNode,
                                 shapeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                 modelManager: ModelManager) {
        this.stateIndicatorRenderer.render(node, shapeGroup, this.shapeType, modelManager);
    }

    private renderExternalLink(node: DiagramNode,
                               shapeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                               modelManager: ModelManager) {
        this.externalLinkRenderer.render(node, shapeGroup, this.shapeType, modelManager);
    }
}
