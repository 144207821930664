import React, {useEffect, useReducer, useRef} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import * as d3 from "d3";
import ElementTypeIcon from "../../fields/ElementTypeIcon";
import {IApplicationState} from "../../../store/Store";
import {useSelector} from "react-redux";
import {Grow, IconButton, MenuItem, MenuList, Paper, Popper, Tooltip} from "@mui/material";
import {DiagramEditor} from "../../../common/diagrameditor/DiagramEditor";
import {EventType, IModelUpdatedOnItemsCreatedEvent, INodeEvent,} from "../../../common/event/Event";
import StickyNoteOutlined from "../../svgicons/StickyNoteOutlined";
import Constants from "../../../common/Constants";
import Api from "../../../common/Api";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import clsx from "clsx";
import {ElementDefinition} from "../../../common/diagrameditor/manager/ElementCreateManager";
import {ArchimateLayerType} from "../../../common/archimate/ArchiMateLayer";
import {ArchimateElement, ArchimateElementType} from "../../../common/archimate/ArchimateElement";
import {_transl} from "../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../DiagramEditorTranslationKey";
import {NodeType} from "../../../common/apis/diagram/NodeType";
import {IEditMode} from "../../../common/diagrameditor/editor/IEditMode";
import viewpointService, {ViewpointDefinitionDto} from "../../../common/apis/ViewpointService";
import {ArchimateElementWrapper} from "../../../common/archimate/ArchimateElementWrapper";
import {NotFoundError} from "../../../common/Errors";
import NodeRendererUtils from "../../../common/diagrameditor/renderer/node/NodeRendererUtils";
import {DefaultColorsDto, getDefaultElementsBgColor} from "../../../common/apis/diagram/DefaultColorsDto";
import {StereotypeDto} from "../../../common/apis/stereotype/StereotypeDto";

const createNodeGroupName = "CreateNodeGroup";
const createNodeNoteName = "CreateNodeNote";

const DRAG_CONTAINER_CLASS_NAME = "__diagram-editor-right-menu-drag-container__";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rightMenu: {
            padding: "1em",
            width: "15em",
            border: "1px solid " + Constants.MENU_BACKGROUND_COLOR_DARKER,
            minHeight: "100%",
            overflow: "auto",
            maxHeight: "100%",
        },
        rightMenuDivider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        iconButton: {
            margin: "0.3em",
            padding: "0.2rem",
            fontSize: "1rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "lightgray",
        },
        iconButtonNotAllowed: {
            margin: "0.3em",
            padding: "0.2rem",
            fontSize: "1rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "lightgray",
            opacity: "0.3"
        },
        stereotypeIcon: {
            left: 0,
            bottom: "-8.5px;",
            position: "absolute",
            userSelect: "none",
            width: "100%",
            height: "20px",
        }
    })
);

interface ItemDragInfo {
    draggedItemTarget: HTMLElement,
    draggedItemTargetInitialCursor: string,
}

interface PalettePanelProps {
    //store props
    menuId?: string,
    mode: IEditMode,
    editor?: DiagramEditor,
    viewpointIdentifier?: string;
}


// STATE

const initialState: State = {
    selectedItem: undefined,
    openedStereotypesMenuId: undefined,
    stereotypes: [],
    viewpointDefinition: undefined,
}

type Item = {
    name: string,
    stereotype?: StereotypeDto,
    nodeType?: NodeType;
}

type State = {
    selectedItem: Item | undefined,
    stereotypes: Array<StereotypeDto>,
    openedStereotypesMenuId: string | undefined,
    viewpointDefinition: ViewpointDefinitionDto | undefined,
}

enum ActionType {
    SET_SELECTED_ITEM = "SET_SELECTED_ITEM",
    SET_STEREOTYPES = "SET_STEREOTYPES",
    SET_OPENED_STEREOTYPES_MENU_ID = "SET_OPENED_STEREOTYPES_MENU_ID",
    SET_VIEWPOINT_DEFINITON = "SET_VIEWPOINT_DEFINITON",
}

type Action =
    | {type: ActionType.SET_SELECTED_ITEM, payload: Item | undefined}
    | {type: ActionType.SET_STEREOTYPES, payload: Array<StereotypeDto>}
    | {type: ActionType.SET_OPENED_STEREOTYPES_MENU_ID, payload: string | undefined}
    | {type: ActionType.SET_VIEWPOINT_DEFINITON, payload: ViewpointDefinitionDto | undefined}


function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.SET_SELECTED_ITEM:
            return {
                ...state,
                selectedItem: action.payload,
            }
        case ActionType.SET_STEREOTYPES:
            return {
                ...state,
                stereotypes: action.payload,
            }
        case ActionType.SET_OPENED_STEREOTYPES_MENU_ID:
            return {
                ...state,
                openedStereotypesMenuId: action.payload,
            }
        case ActionType.SET_VIEWPOINT_DEFINITON:
            return {
                ...state,
                viewpointDefinition: action.payload,
            }
    }
    return state;
}


// GLOBAL FUNCTIONS

function deselectItem(actualSelectedItem: Item | undefined, dispatch: React.Dispatch<Action>, editor: DiagramEditor | undefined, event: any, itemDragInfo: React.MutableRefObject<ItemDragInfo | undefined>) {
    if (actualSelectedItem != null) {
        clearSelectedItem(dispatch, itemDragInfo, editor);
        editor?.getDiagramEditorApi().onNodeCreateMenuDeactivated(event);
    }
}

function selectItem(newSelectedItem: Item, actualSelectedItem: Item | undefined, dispatch: React.Dispatch<Action>, editor: DiagramEditor | undefined, event: any, itemDragInfo: React.MutableRefObject<ItemDragInfo | undefined>) {
    deselectItem(actualSelectedItem, dispatch, editor, event, itemDragInfo);
    // do not activate deactivated menu
    if ((actualSelectedItem?.name !== newSelectedItem.name) ||
        ((actualSelectedItem?.name === newSelectedItem.name) && (actualSelectedItem?.stereotype !== newSelectedItem.stereotype))) {
        dispatch({
            type: ActionType.SET_SELECTED_ITEM,
            payload: newSelectedItem
        });
        createItemDragInfoOnItemSelect(itemDragInfo, event);
        const elementDefinition: ElementDefinition = {
            newElement: {
                id: "",
                elementStandardName: newSelectedItem.name,
                stereotype: newSelectedItem.stereotype,
            }
        }
        editor?.getDiagramEditorApi().onNodeCreateMenuActivated(event, newSelectedItem.nodeType as NodeType, elementDefinition);
    }
}

function clearSelectedItem(dispatch: React.Dispatch<any>, itemDragInfo: React.MutableRefObject<ItemDragInfo | undefined>,  editor: DiagramEditor | undefined) {
    clearItemDragInfo(itemDragInfo);
    dispatch({type: ActionType.SET_SELECTED_ITEM, item: undefined});
    editor?.getDiagramEditorApi().onNodeCreateMenuDeactivated({});
}

function createItemDragInfoOnItemSelect(itemDragInfo: React.MutableRefObject<ItemDragInfo | undefined>, event: any) {
    const button = event.target?.closest("button");
    itemDragInfo.current = {
        draggedItemTarget: button,
        draggedItemTargetInitialCursor: button?.style.cursor,
    }
    if (button) {
        button.style.cursor = "move";
    }
    (document.getElementsByClassName(DRAG_CONTAINER_CLASS_NAME)[0] as HTMLElement).style.cursor = "move";
}

function clearItemDragInfo(itemDragInfo: React.MutableRefObject<ItemDragInfo | undefined>) {
    if (itemDragInfo.current?.draggedItemTarget) {
        itemDragInfo.current.draggedItemTarget.style.cursor = itemDragInfo.current.draggedItemTargetInitialCursor;
    }
    (document.getElementsByClassName(DRAG_CONTAINER_CLASS_NAME)[0] as HTMLElement).style.cursor = "";
    itemDragInfo.current = undefined;
}


// COMPONENT

export default function PalettePanel(props: PalettePanelProps) {
    const {menuId, editor, viewpointIdentifier} = props;

    const classes = useStyles();

    // REFS
    const enteredStereotypesMenuId = useRef<string>();
    const hideStereotypesMenuTimeoutId = useRef<number>();
    const itemDragInfo = useRef<ItemDragInfo>();

    // STATE
    const [{selectedItem, stereotypes, openedStereotypesMenuId, viewpointDefinition}, dispatch] = useReducer(reducer, initialState);

    // STORE
    const defaultColors: DefaultColorsDto = useSelector((state: IApplicationState) => state.diagramDefaults.defaultColors);

    // EFFECTS
    useEffect(() => {
        let isUnmounted = false;
        Api.stereotypes.findAll()
            .then((stereotypes) => {
                    if (!isUnmounted) {
                        dispatch({type: ActionType.SET_STEREOTYPES, payload: stereotypes})
                    }
            });
        return () => {
            isUnmounted = true;
        }
    }, []);

    useEffect(() => {
        let isUnmounted = false;
        const itemsCreatedUnsubscriber = editor?.getDiagramEditorApi().addModelUpdatedOnItemsCreatedListener(
            (event: IModelUpdatedOnItemsCreatedEvent) => {
                if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_CREATED) {
                    if (!isUnmounted) {
                        clearSelectedItem(dispatch, itemDragInfo, editor);
                    }
                }
            }
        );
        const itemCreateUnsubscriber = editor?.getDiagramEditorApi().addNodeConnectionCreateByHandleListener(
            (event: INodeEvent) => {
                if (!isUnmounted) {
                    if (event.type === EventType.NODE_CONNECTION_CREATE_BY_HANDLE_ACTIVATED) {
                        clearSelectedItem(dispatch, itemDragInfo, editor);
                    }
                }
            }
        );

        return () => {
            isUnmounted = true;
            itemsCreatedUnsubscriber?.();
            itemCreateUnsubscriber?.();
        }
    }, [selectedItem, editor]);

    useEffect(() => {
        let isUnmounted = false;
        let viewpointDefinition = {} as ViewpointDefinitionDto;
        if (viewpointIdentifier) {
            (async () => {
                try {
                    viewpointDefinition = await viewpointService.findById(viewpointIdentifier);
                    if (!isUnmounted) {
                        dispatch({type: ActionType.SET_VIEWPOINT_DEFINITON, payload: viewpointDefinition})
                    }
                } catch (error) {
                    if (error instanceof NotFoundError) {
                        viewpointDefinition = {} as ViewpointDefinitionDto;
                    } else {
                        throw error;
                    }
                }
            })();
        }
        return () => {
            isUnmounted = true;
        }
    }, [viewpointIdentifier]);


    // RENDER RELATED FUNCTIONS

    function GroupIconButton() {
        const name = createNodeGroupName;
        const tooltip = _transl(DiagramEditorTranslationKey.ELEMENT_PALETTE_GROUP);
        const activeBgColor = NodeRendererUtils.GROUP_BACKGROUND_COLOR;
        const inactiveBgColor = lighten(activeBgColor, 0.3);
        const icon = <ElementTypeIcon name={ArchimateElement[ArchimateElementType.GROUPING].standardName} isMenuIcon={true}/>
        const onMouseDown = (event: any, stereotype?: StereotypeDto) => onNodeButtonClicked(NodeType.CONTAINER, name, stereotype, event);
        return BaseIconButton(name, tooltip, activeBgColor, inactiveBgColor, icon, onMouseDown, []);
    }

    function NoteIconButton() {
        const name = createNodeNoteName;
        const tooltip = _transl(DiagramEditorTranslationKey.ELEMENT_PALETTE_NOTE);
        const activeBgColor = NodeRendererUtils.CONTAINER_FILL_COLOR;
        const inactiveBgColor = lighten(activeBgColor, 1);
        const icon = <StickyNoteOutlined />
        const onMouseDown = (event: any, stereotype?: StereotypeDto) => onNodeButtonClicked(NodeType.LABEL, name, stereotype, event);
        return BaseIconButton(name, tooltip, activeBgColor, inactiveBgColor, icon, onMouseDown, []);
    }

    const LAYER_TO_LIGHTEN_FACTOR_MAP = {
        [ArchimateLayerType.COMPOSITE_ELEMENTS]: 0.3,
        [ArchimateLayerType.STRATEGY]: 0.17,
        [ArchimateLayerType.BUSINESS]: 0.6,
        [ArchimateLayerType.APPLICATION]: 0.7,
        [ArchimateLayerType.TECHNOLOGY]: 0.5,
        [ArchimateLayerType.PHYSICAL]: 0.5,
        [ArchimateLayerType.MOTIVATION]: 0.3,
        [ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION]: 0.25,
        [ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS]: 0.2
    };

    function ElementIconButton(elementWrapper: ArchimateElementWrapper, stereotypes: Array<StereotypeDto>) {
        const name = elementWrapper.element.standardName;
        const tooltip = elementWrapper.element.visibleName;

        let activeBgColor = getDefaultElementsBgColor(name, defaultColors);

        const inactiveBgColor = lighten(activeBgColor, LAYER_TO_LIGHTEN_FACTOR_MAP[elementWrapper.element.layerType]);
        const icon = <ElementTypeIcon name={(name)} isMenuIcon={true}/>;
        const onMouseDown = (event: any, stereotype?: StereotypeDto) => onNodeButtonClicked(NodeType.ELEMENT, name, stereotype, event);
        return BaseIconButton(name, tooltip, activeBgColor, inactiveBgColor, icon, onMouseDown, stereotypes, elementWrapper.isAllowed);
    }

    function BaseIconButton(name: string,
                           tooltip: string,
                           activeBgColor: string,
                           inactiveBgColor: string,
                           icon: JSX.Element,
                           onMouseDown: (event: any, stereotype?: StereotypeDto) => void,
                           stereotypes: Array<StereotypeDto>,
                           isAllowed?: boolean) {
        const stereotypesMenuId = `stereotype-menu-${name}`;
        return (
            <span key={stereotypesMenuId} id={stereotypesMenuId} style={{position: "relative", display: "inline-block"}}>
                    <Tooltip title={tooltip} placement={"top"}>
                        <span>
                            {stereotypes.length > 0 && <ArrowDropDownIcon className={classes.stereotypeIcon} />}
                            <IconButton
                                onMouseEnter={(event) => {
                                    const button: HTMLButtonElement = (event.target as any).closest("button");
                                    button.style.backgroundColor = activeBgColor;
                                    window.clearTimeout(hideStereotypesMenuTimeoutId.current);
                                    showStereotypesMenu(stereotypesMenuId);
                                }}
                                onMouseLeave={(event) => {
                                    const button: HTMLButtonElement = (event.target as any).closest("button");
                                    button.style.backgroundColor = isSelected(name) ? activeBgColor : inactiveBgColor;
                                    hideStereotypesMenuTimeoutId.current = window.setTimeout(() => hideStereotypesMenuWhenNotEntered(stereotypesMenuId), 400);
                                }}
                                style={{
                                    backgroundColor: isSelected(name) ? activeBgColor : inactiveBgColor,
                                    border: isSelected(name) ? "1px solid black" : "1px solid lightgray",
                                    marginTop: "5px",
                                }}
                                className={isAllowed === undefined || isAllowed ? classes.iconButton : classes.iconButtonNotAllowed}
                                onMouseDown={(event) => {
                                    onMouseDown(event);
                                }}
                                size="large">
                                {icon}
                            </IconButton>
                        </span>
                    </Tooltip>
                    {stereotypes.length > 0 && <Popper open={openedStereotypesMenuId === stereotypesMenuId}
                                                       anchorEl={document.getElementById(stereotypesMenuId)}
                                                       role={undefined}
                                                       transition
                                                       style={{zIndex: 1303}}
                                                       placement={"bottom-end"}
                                                       modifiers={[
                                                           {
                                                               name: "offset",
                                                               options: {
                                                                   offset: [+15, 0],
                                                               },
                                                           },
                                                       ]}>
                        {({ TransitionProps}) => (
                            <Grow
                                {...TransitionProps}
                            >
                                <Paper onMouseEnter={() => enteredStereotypesMenuId.current = stereotypesMenuId}
                                       onMouseLeave={() => hideStereotypesMenus()}>
                                    <MenuList id="menu-list-grow" dense={true}>
                                        {stereotypes.map(stereotype =>
                                            <MenuItem key={stereotype.name}
                                                      onMouseDown={(event) => {
                                                          hideStereotypesMenus();
                                                          onMouseDown(event, stereotype);
                                                      }}
                                                      style={{display: "flex", justifyItems: "center"}}>
                                                <span style={{fontSize: ".85em", lineHeight: ".85em", marginLeft: "auto"}}>{`<<${stereotype.name}>>`}</span>
                                                <span className={classes.iconButton} style={{border: "1px solid lightgray", backgroundColor: inactiveBgColor, borderRadius: 50, display: "flex"}}>{icon}</span>
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>}
                </span>
        );
    }

    function showStereotypesMenu(menuId: string) {
        dispatch({
            type: ActionType.SET_OPENED_STEREOTYPES_MENU_ID,
            payload: menuId,
        });
    }

    function hideStereotypesMenuWhenNotEntered(menuId: string) {
        if (openedStereotypesMenuId === menuId && enteredStereotypesMenuId.current !== menuId) {
            dispatch({
                type: ActionType.SET_OPENED_STEREOTYPES_MENU_ID,
                payload: undefined,
            });
        }
    }

    function hideStereotypesMenus() {
        enteredStereotypesMenuId.current = undefined;
        dispatch({
            type: ActionType.SET_OPENED_STEREOTYPES_MENU_ID,
            payload: undefined,
        });
    }

    function isSelected(name: string) {
        return selectedItem?.name === name;
    }

    function getLayerType(elementStandardName: string) {
        return ArchimateElement.findByStandardName(elementStandardName)?.layerType ?? ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS;
    }

    function lighten(layerColor: string, k: number) {
        return d3.color(layerColor || "gray")?.brighter(k).formatRgb() as string;
    }

    function onNodeButtonClicked(nodeType: NodeType, name: string, stereotype: StereotypeDto | undefined, event: any) {
        const item = {
            name: name,
            stereotype: stereotype,
            nodeType: nodeType,
        };
        selectItem(item, selectedItem, dispatch, editor, event, itemDragInfo);
    }

    function createLayerToElementsMap(viewpointDefinition: ViewpointDefinitionDto | undefined) {
        const layerToElementsMap: { [layer: string]: ArchimateElementWrapper[] } = {};
        if (viewpointDefinition?.elementTypes) {
            const elementTypes = viewpointDefinition.elementTypes;
            ArchimateElement.values().forEach((element) => {
                let isAllowed = elementTypes.some(e => e === element.standardName);
                const layerName = getLayerName(element.standardName, layerToElementsMap);
                layerToElementsMap[layerName].push({element, isAllowed});
            });
        } else {
            ArchimateElement.values().forEach((element) => {
                const layerName = getLayerName(element.standardName, layerToElementsMap);
                layerToElementsMap[layerName].push({element});
            });
        }
        return layerToElementsMap;
    }

    function getLayerName(standardName: string, layerToElementsMap: { [layer: string]: ArchimateElementWrapper[] }) {
        const layerName = getLayerType(standardName);
        if (layerToElementsMap[layerName] == null) {
            layerToElementsMap[layerName] = [];
        }
        return layerName;
    }

    // RETURN
    const elementNameToStereotypes: {[name: string]: Array<StereotypeDto>} = {};
    stereotypes.forEach(stereotype => {
        if (elementNameToStereotypes[stereotype.elementType] == null) {
            elementNameToStereotypes[stereotype.elementType] = [];
        }
        elementNameToStereotypes[stereotype.elementType].push(stereotype);
    });

    const layerToElementsMap =  createLayerToElementsMap(viewpointDefinition);

    const layersOrder = [ArchimateLayerType.COMPOSITE_ELEMENTS, ArchimateLayerType.MOTIVATION, ArchimateLayerType.STRATEGY, ArchimateLayerType.BUSINESS, ArchimateLayerType.APPLICATION, ArchimateLayerType.TECHNOLOGY,
        ArchimateLayerType.PHYSICAL, ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION, ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS];

    return <div className={clsx(classes.rightMenu, DRAG_CONTAINER_CLASS_NAME)} id={menuId}>
        {layersOrder.map(layerName =>
            [
                <div key={`${layerName}-elements`}>
                    {layerToElementsMap[layerName].map(elementWrapper => {
                        return ElementIconButton(elementWrapper, elementNameToStereotypes[elementWrapper.element.standardName] || []);
                    })}
                </div>,
                <Divider key={`${layerName}-divider`} className={classes.rightMenuDivider}/>
            ]
        )}
        <div>
            <NoteIconButton />
            <GroupIconButton />
        </div>
    </div>
}
