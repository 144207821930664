import {ITranslation} from "../../store/localization/ITranslation";
import {Dashboard} from "../../store/dashboard/Dashboard";
import {map} from "rxjs/operators";
import {forkJoin} from "rxjs";
import translationService from "../../common/apis/TranslationsService";
import dashboardService from "../../common/apis/DashboardService";
import {UserDto} from "../../common/apis/user/UserDto";
import userService from "../../common/apis/UserService";
import userSettingsService from "../../common/apis/user/UserSettingsService"
import {Language, languageFromCode} from "../../common/Language";
import diagramsApi from "../../common/apis/DiagramsApi";
import {DiagramDefaultsDto} from "../../common/apis/diagram/DiagramDefaultsDto";
import {FeatureStatusesDto} from "../../configuration/FeatureStatusesDto";
import {TrackerConfigDto} from "../../configuration/TrackerConfigDto";
import configurationService from "../../configuration/ConfigurationService";

export interface InitialData {
    userData: UserDto;
    language: Language;
    translations: ITranslation;
    dashboard: Dashboard;
    featureStatuses: FeatureStatusesDto;
    trackerConfig: TrackerConfigDto;
    diagramDefaults: DiagramDefaultsDto;
}

export interface LoginPageService {
    fetchInitialData(userName: string): Promise<InitialData>,
}

class RxJsLoginPageService implements LoginPageService {

    async fetchInitialData(userName: string): Promise<InitialData> {
        const generalSettings = await userSettingsService.getGeneralSettings();
        const language = languageFromCode(generalSettings?.language) ?? Language.CS;

        return forkJoin([
            userService.getUserByToken(),
            translationService.getTranslations(language),
            dashboardService.getDashboard(),
            configurationService.getTrackerConfig(),
            configurationService.getFeatureStatuses(),
            diagramsApi.getDiagramDefaults()
        ]).pipe(
            map(responses => ({
                userData: responses[0],
                language: language,
                translations: responses[1],
                dashboard: responses[2],
                trackerConfig: responses[3],
                featureStatuses: responses[4],
                diagramDefaults: responses[5],
            }))
        ).toPromise();
    }

}

const loginPageService = new RxJsLoginPageService();
export default loginPageService;
