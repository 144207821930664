import {IconButton} from "@mui/material";
import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {OptionalTooltip} from "./OptionalTooltip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbarButton: {
            padding: "1px",
            margin: "2px",
            "& .MuiSvgIcon-root": {
                padding: "1px",
                fontSize: "1.4rem",
            }
        },
    })
);

export interface ToolbarIconButtonProps {
    tooltip?: string,
    icon: JSX.Element,
    disabled?: boolean,
    onClick: (event: any) => void,
}

export function ToolbarIconButton(props: ToolbarIconButtonProps) {
    const {disabled, icon, onClick} = props;
    const tooltip = props.tooltip || "";
    const classes = useStyles();

    return (
        <OptionalTooltip title={tooltip} disabled={disabled}>
            <IconButton
                onClick={onClick}
                disabled={disabled}
                className={classes.toolbarButton}
                size="large">
                {icon}
            </IconButton>
        </OptionalTooltip>
    );
}
