import {IDiagramNodeDto} from "../../../../../apis/diagram/IDiagramNodeDto";

export default function areAlignable(nodes: Array<IDiagramNodeDto>, nodeIdToParentIdMap: Map<string, string | undefined>) {
    if (nodes.length < 2) {
        return false;
    } else {
        let lastParentId: string | undefined = nodeIdToParentIdMap.get(nodes[0].identifier);
        for (const node of nodes) {
            if (lastParentId !== nodeIdToParentIdMap.get(node.identifier)) {
                return false;
            }
        }
        return true;
    }
}