import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../../components/grid/GridAction";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../ElementDetailTranslationKey";
import LinkIcon from "@mui/icons-material/Link";
import {LinkOff, Refresh} from "@mui/icons-material";
import {PersistentStateId} from "../../../../../store/common/Grid";
import React, {useState} from "react";
import {TaskDto} from "@dain/tracker-client";
import {GridColDef} from "@mui/x-data-grid";
import TrackerService from "./TrackerService";
import AssignTaskOptionsDialog, {AssignTaskOption} from "./AssignTaskOptionsDialog";
import AssignExistingTaskDialog from "./AssignExistingTaskDialog";
import ConfirmationDialog from "../../../../../components/dialogs/ConfirmationDialog";
import ExtGridWrapper from "../../../../../components/grid/ExtGridWrapper";

export interface TrackerTasksGridProps {
    trackerService: TrackerService,
    tasks: TaskDto[],
    onTaskAssigned: (task: TaskDto) => void,
    onTaskUnassigned: (task: TaskDto) => void,
    onRefresh: () => void
}

export default function TrackerTasksGrid({trackerService, tasks, onTaskAssigned, onTaskUnassigned, onRefresh}: TrackerTasksGridProps) {

    const [assignTaskOptionsDialogOpened, setAssignTaskOptionsDialogOpened] = useState<boolean>(false);
    const [assignExistingTaskDialogOpened, setAssignExistingTaskDialogOpened] = useState<boolean>(false);
    const [selectedTask, setSelectedTask] = useState<TaskDto | undefined>(undefined);
    const [showDeleteLinkConfirmationDialog, setShowDeleteLinkConfirmationDialog] = useState<boolean>(false);


    const COL_DEFS: GridColDef[] = [
        {field: 'key', headerName: _transl(ElementDetailTranslationKey.TASKS_KEY), width: 100},
        {field: 'caption', headerName: _transl(ElementDetailTranslationKey.TASKS_NAME), width: 250},
        {field: 'taskTypeName', headerName: _transl(ElementDetailTranslationKey.TASKS_TASK_TYPE), width: 150},
        {field: 'statusName', headerName: _transl(ElementDetailTranslationKey.TASKS_STATE), width: 150},
    ];

    function openTaskInTracker(task: TaskDto): void {
        if (task.key) {
            window.open(trackerService.getTaskUrl(task.key), "_blank");
        }
    }

    function handleOnAssignTaskActionButtonClick(): void {
        setAssignTaskOptionsDialogOpened(true)
    }

    function handleOnAssignTaskOptionsDialogClose(): void {
        setAssignTaskOptionsDialogOpened(false);
    }

    function handleOnAssignExistingTaskDialogClose(): void {
        setAssignExistingTaskDialogOpened(false);
    }

    function handleOnExistingTaskPicked(task: TaskDto): void {
        setAssignExistingTaskDialogOpened(false);
        onTaskAssigned(task);
    }

    function handleOnDeleteActionButtonClicked(selectedRowIds: any[], selectedRows: any[]): void {
        setSelectedTask(selectedRows[0]);
        setShowDeleteLinkConfirmationDialog(true);
    }

    function handleOnRefresh(): void {
        onRefresh();
    }

    function handleOnAssignTaskOptionSelected(option: AssignTaskOption): void {
        if (option === AssignTaskOption.ASSIGN_EXISTING_TASK) {
            setAssignExistingTaskDialogOpened(true);
        } else if (option === AssignTaskOption.ASSIGN_NEW_TASK) {
            //TODO otvori sa novy browser tab trackeru s parametrami adresy pre konkretny element/ukol
        }
    }

    return (
            <>
                <ConfirmationDialog open={showDeleteLinkConfirmationDialog}
                                    title={_transl(ElementDetailTranslationKey.TASKS_DELETE_LINK_DIALOG_TITLE)}
                                    confirmationText={_transl(ElementDetailTranslationKey.TASKS_DELETE_CONFIRMATION_TEXT)}
                                    onConfirm={() => {
                                        setShowDeleteLinkConfirmationDialog(false);
                                        onTaskUnassigned(selectedTask!);
                                    }}
                                    onReject={() => setShowDeleteLinkConfirmationDialog(false)}
                                    isModal={true}
                />
                {assignTaskOptionsDialogOpened &&
                    <AssignTaskOptionsDialog
                        onClose={handleOnAssignTaskOptionsDialogClose}
                        onOptionSelected={handleOnAssignTaskOptionSelected}
                    />
                }
                {assignExistingTaskDialogOpened &&
                    <AssignExistingTaskDialog
                        trackerService={trackerService}
                        onClose={handleOnAssignExistingTaskDialogClose}
                        onTaskPicked={handleOnExistingTaskPicked}
                    />
                }
                <ExtGridWrapper
                        columns={COL_DEFS}
                        rows={tasks}
                        rowCount={tasks.length}
                        getRowId={row => row.key}
                        actions={[
                            GridAction.detailButtonBuilder()
                                    .onClick((selectedRowIds, selectedRows) => {
                                        if (selectedRows.length === 1) {
                                            openTaskInTracker(selectedRows[0]);
                                        }
                                    }).build(),
                            GridAction.buttonBuilder("ADD_TASK_BUTTON", ActionButtonType.IMMEDIATE, _transl(ElementDetailTranslationKey.TASKS_ADD_TASK),
                                    <LinkIcon/>)
                                    .enabledPolicy(EnabledPolicy.ALWAYS)
                                    .isEnabled(() => true)
                                    .onClick(handleOnAssignTaskActionButtonClick)
                                    .build(),
                            GridAction.buttonBuilder("DELETE_TASK_BUTTON", ActionButtonType.IMMEDIATE, _transl(ElementDetailTranslationKey.TASKS_DELETE_LINK),
                                    <LinkOff/>)
                                    .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                                    .isEnabled(() => true)
                                    .onClick(handleOnDeleteActionButtonClicked)
                                    .build(),
                            GridAction.buttonBuilder("REFRESH_TASKS_BUTTON", ActionButtonType.IMMEDIATE, _transl(ElementDetailTranslationKey.TASKS_REFRESH),
                                    <Refresh/>)
                                    .enabledPolicy(EnabledPolicy.ALWAYS)
                                    .onClick(handleOnRefresh)
                                    .build(),
                        ]}

                        peristentStateId={PersistentStateId.ELEMENT_DETAIL_PAGE_TRACKER_TASKS_GRID}
                        resourceId={PersistentStateId.ELEMENT_DETAIL_PAGE_TRACKER_TASKS_GRID}
                />
            </>
    );
}
