import {CONNECTION_HANDLE_TEMPLATE_SIZE} from "./ConnectionHandle";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";

const CONNECTION_HANDLE_OFFSET = 10;

export enum Orientation {
    N = "N",
    S = "S",
    E = "E",
    W = "W"
}

export interface Positioner {
    computeTransform(node: IDiagramNodeDto): string,
}

export default class PositionerFactory {

    private static orientationToPositionerMap: Record<Orientation, Positioner> = {
        [Orientation.N]: {
            computeTransform(node: IDiagramNodeDto) {
                const translateX = node.x + (node.w / 2) - (CONNECTION_HANDLE_TEMPLATE_SIZE / 2);
                const translateY = node.y - CONNECTION_HANDLE_OFFSET - CONNECTION_HANDLE_TEMPLATE_SIZE;
                return `translate(${translateX} ${translateY})`;
            },
        },
        [Orientation.S]: {
            computeTransform(node: IDiagramNodeDto) {
                const translateX = node.x + (node.w / 2) - (CONNECTION_HANDLE_TEMPLATE_SIZE / 2);
                const translateY = node.y + node.h + CONNECTION_HANDLE_OFFSET;
                const rotateXY = CONNECTION_HANDLE_TEMPLATE_SIZE / 2;
                return `translate(${translateX} ${translateY}) rotate(180 ${rotateXY} ${rotateXY})`;
            },
        },
        [Orientation.E]: {
            computeTransform(node: IDiagramNodeDto) {
                const translateX = node.x - CONNECTION_HANDLE_OFFSET - CONNECTION_HANDLE_TEMPLATE_SIZE;
                const translateY = node.y + (node.h / 2) - (CONNECTION_HANDLE_TEMPLATE_SIZE / 2);
                const rotateXY = CONNECTION_HANDLE_TEMPLATE_SIZE / 2;
                return `translate(${translateX} ${translateY}) rotate(-90 ${rotateXY} ${rotateXY})`;
            },
        },
        [Orientation.W]: {
            computeTransform(node: IDiagramNodeDto) {
                const translateX = node.x + node.w + CONNECTION_HANDLE_OFFSET;
                const translateY = node.y + (node.h / 2) - (CONNECTION_HANDLE_TEMPLATE_SIZE / 2);
                const rotateXY = CONNECTION_HANDLE_TEMPLATE_SIZE / 2;
                return `translate(${translateX} ${translateY}) rotate(90 ${rotateXY} ${rotateXY})`;
            },
        }
    }

    static get(orientation: Orientation): Positioner {
        return this.orientationToPositionerMap[orientation];
    }
}