import Button from "@mui/material/Button";
import {_transl} from "../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import React from "react";
import {ButtonPropTypes} from "./ButtonPropTypes";

interface Props {
    onClick: (event?: any) => void,
    variant?: ButtonPropTypes.Variant,
    size?: ButtonPropTypes.Size;
}

export function CancelButton(props: Props) {

    const {onClick, variant, size} = props;

    return (
        <Button
            onClick={onClick}
            variant={variant ?? "outlined"}
            size={size}
        >
            {_transl(CommonTranslation.CANCEL)}
        </Button>
    )
}

