import {Slider} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {computeMarks} from "./FontSizeSlider";
import {MAX_LINE_WIDTH} from "../../../../common/diagrameditor/renderer/connection/AbstractConnectionRenderer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingLeft: "3px",
            paddingRight: "3px"
        },
    })
);

const MIN = 1;
const MAX = MAX_LINE_WIDTH;
const MARK_STEP = 2;

export interface LineWidthSliderProps {
    id?: string,
    value: number,
    disabled?: boolean,
    onChange: (size: number) => void,
}

export function LineWidthSlider(props: LineWidthSliderProps) {
    const classes = useStyles();

    function onValueChanged(event: any, value: number | number[]) {
        if (typeof value === "number") {
            props.onChange(value);
        }
    }

    return (
        <div className={classes.container}>
            <Slider id={props.id}
                    min={MIN} max={MAX} marks={computeMarks(MIN, MAX, MARK_STEP)}
                    value={props.value}
                    valueLabelDisplay={"auto"}
                    disabled={props.disabled ?? false}
                    onChange={onValueChanged}/>
        </div>
    );
}
