import {ListItemButton, ListItemIcon, ListItemText, styled} from "@mui/material";
import {NavLink, NavLinkProps} from "react-router-dom";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

export interface DrawerListItemButtonProps {
    icon: React.ReactNode;
    text: string;
    to?: NavLinkProps["to"];
    expanded?: boolean;
    indentationLevel?: number;
    onClick?: () => void;
}

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    ".active &": {
        color: "white",
    }
}));

const StyledListItemText = styled(ListItemText)(({theme}) => ({
    ".active &": {
        color: "white",
    }
}));

const StyledNavLink = styled(NavLink)(({theme}) => ({
    "&.active": {
        backgroundColor: theme.palette.primary.main,
    },
    "&:hover.active": {
        backgroundColor: theme.palette.primary.main,
    }
}));

export default function DrawerListItem(props: Readonly<DrawerListItemButtonProps>) {
    const {icon, text, to, expanded, indentationLevel, onClick} = props;
    const sx = indentationLevel ? {pl: indentationLevel * 7} : {};

    let expandIcon = null;
    if (expanded !== undefined) {
        expandIcon = expanded ? <ExpandLess /> : <ExpandMore />;
    }

    const navigationProps = to ? {to: to, component: StyledNavLink} : {};

    return (
        <ListItemButton {...navigationProps} onClick={onClick} sx={sx}>
            <StyledListItemIcon sx={{minWidth: 48}}>{icon}</StyledListItemIcon>
            <StyledListItemText primary={text}/>
            {expandIcon}
        </ListItemButton>
    );
}