import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "./ElementTranslationKey";
import DialogContent from "../../../../components/dialogs/DialogContent";
import Grid from "../../../../components/dialogs/Grid";
import React, {useEffect, useState} from "react";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import SingleselectElementTypesComboBox from "../../../../components/fields/SingleselectElementTypesComboBox";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import {CommonTranslation} from "../CommonTranslation";
import Snackbar from "../snackbar/Snackbar";
import elementsService, {BulkElementTypeUpdateDto} from "./service/ElementService";
import SingleselectComboBox from "../../../../components/fields/SingleselectComboBox";
import stereotypeService from "../../../../common/apis/StereotypeService";
import {IElementTypeDto} from "../../../../common/apis/Elements";
import {StereotypeDto} from "../../../../common/apis/stereotype/StereotypeDto";

interface UpdateElementTypeDialogProps {
    open: boolean;
    elementIdentifiers: string[];
    onClosed: () => void;
    refetchFilter: () => void;
}

export default function UpdateElementTypeDialog(props: UpdateElementTypeDialogProps) {
    const {open, onClosed, elementIdentifiers, refetchFilter} = props;

    const [isUnsuccessfullyValidated, setUnsuccessfullyValidated] = useState<boolean>(false);
    const [type, setType] = useState<IElementTypeDto>()
    const [stereotype, setStereotype] = useState<StereotypeDto>();
    const [typeValidationMsg, setTypeValidationMsg] = useState<string | undefined>(undefined);
    const typeOptions = useSelector((state: IApplicationState) => state.pages.common.options.types.resource);

    const [stereotypes, setStereotypes] = useState<StereotypeDto[]>();

    useEffect(() => {
        stereotypeService.findAll()
            .then((stereotypes) => {
                setStereotypes(stereotypes);
            });
    }, []);

    function changeType(type: IElementTypeDto) {
        isUnsuccessfullyValidated && validateType(type);
        setType(type);
        setStereotype(undefined);
    }

    function validateType(type: IElementTypeDto | undefined): boolean {
        const isEmpty = type === undefined;
        if (isEmpty) {
            setTypeValidationMsg(_transl(CommonTranslation.FILL_OUT_ITEM_FROM_LIST))
            return false;
        } else {
            setTypeValidationMsg(undefined)
            return true;
        }
    }

    const isFormValid = () => {
        return validateType(type);
    }

    const onDialogSave = () => {
        if (isFormValid()) {
            updateElementType(type!, stereotype);
        } else {
            setUnsuccessfullyValidated(true)
        }
    }

    const updateElementType = (elementType: IElementTypeDto, stereotype: StereotypeDto | undefined) => {
        var bulkTypeUpdate: BulkElementTypeUpdateDto = {
            type: elementType.name,
            stereotype: stereotype?.name,
            identifiers: elementIdentifiers
        };

        elementsService.bulkUpdateElementType(bulkTypeUpdate)
            .then(() => {
                refetchFilter();
                onClosed();
                Snackbar.success(_transl(ElementTranslationKey.DIALOG_CHANGE_ELEMENT_TYPE_SUCCEEDED));
            })
            .catch(() => {
                Snackbar.error(_transl(ElementTranslationKey.DIALOG_CHANGE_ELEMENT_TYPE_NOT_SUCCEEDED));
            });
    };

    const stereotypeOptions: StereotypeDto[] = [];
    if (type && stereotypes) {
        stereotypeOptions.push(...stereotypes.filter(stereotype => stereotype.elementType === type.name));
    }

    return (
        <Dialog open={open} onClose={onClosed}>
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(ElementTranslationKey.DIALOG_CHANGE_ELEMENT_TYPE_MESSAGE)}
                         onDialogClosed={onClosed}/>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <SingleselectElementTypesComboBox id={"update-element-type-dialog-type"}
                                                          options={typeOptions}
                                                          label={_transl(ElementTranslationKey.DIALOG_CHANGE_ELEMENT_TYPE_ELEMENT_TYPE)}
                                                          size={"small"}
                                                          handleOnChange={changeType}
                                                          errorMessage={typeValidationMsg}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleselectComboBox id={"update-element-type-dialog-stereotype"}
                                              options={stereotypeOptions}
                                              selectedValue={stereotype}
                                              getRenderLabel={(stereotype) => stereotype.name}
                                              label={_transl(ElementTranslationKey.GRID_HEADER_STEREOTYPE)}
                                              handleOnChange={setStereotype}
                                              disabled={stereotypeOptions.length === 0}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <SaveButton onClick={onDialogSave}/>
                <CancelButton onClick={onClosed}/>
            </DialogActions>
        </Dialog>
    )
}
