import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React, {useContext, useState} from "react";
import Grid from "@mui/material/Grid";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {CommonTranslation} from "../CommonTranslation";
import {ElementTranslationKey} from "./ElementTranslationKey";
import {IPropertyDefinition} from "../../../../common/apis/CommonTypes";
import SelectField from "../../../../components/fields/SelectField";
import ElementPropertyDefinitionContext from "../../../../common/ElementPropertyDefinitionContext";
import {GridColDef} from "@mui/x-data-grid";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContainer: {
            display: "flex",
            width: "100%",
            paddingTop: "1em",
            paddingBottom: "1em",
        },
        gridItem: {
            width: "100%",
            padding: theme.spacing(2, 0, 1, 0),
        },
    }));

interface CustomPropertyColumnAddDialogProps {
    open: boolean;
    existingCustomPropertyNames: string[];
    onAddCustomColumn: (attributeName: string, colDef: GridColDef) => void;
    colDef: GridColDef;
    onClosed: () => void;
}

export default function CustomPropertyColumnAddDialog(props: CustomPropertyColumnAddDialogProps) {

    const classes = useStyles();
    const {open, onAddCustomColumn, colDef, onClosed} = props;
    const existingCustomAttributeNames = new Set(props.existingCustomPropertyNames);

    const [name, setName] = useState<string>("");
    const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>();
    const propertyDefinitions = useContext(ElementPropertyDefinitionContext).propertyDefinitions;

    function save() {
        if (validate()) {
            onAddCustomColumn(name, colDef);
            close();
        }
    }

    function validate() {
        if (!name) {
            setNameErrorMessage(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            return false;
        }
        if (existingCustomAttributeNames.has(name)) {
            setNameErrorMessage(_transl(ElementTranslationKey.CUSTOM_PROPERTY_COLUMN_ADD_DIALOG_COLUMN_ALREADY_EXISTS));
            return false;
        }
        return true;
    }

    function close() {
        onClosed();
        resetState();
    }

    function resetState() {
        setName("");
        setNameErrorMessage(undefined);
    }

    function updateName(newValue: IPropertyDefinition | string) {
        setNameErrorMessage(undefined);
        setName(typeof newValue === "string" ? newValue : (newValue as IPropertyDefinition).name);
    }

    return (
        <React.Fragment>
            {propertyDefinitions &&
                <Dialog open={open}>
                    <DialogTitle title={_transl(ElementTranslationKey.CUSTOM_PROPERTY_COLUMN_ADD_DIALOG_TITLE)}
                                 onDialogClosed={close}/>
                    <DialogContent>
                        <Grid container className={classes.dialogContainer}>
                            <Grid item width={"100%"}>
                                <SelectField
                                    id="name-field"
                                    value={name}
                                    label={_transl(ElementTranslationKey.CUSTOM_PROPERTY_COLUMN_ADD_DIALOG_NAME)}
                                    freeSolo={false}
                                    required={true}
                                    errorMessage={nameErrorMessage}
                                    options={propertyDefinitions}
                                    onChange={(newValue: IPropertyDefinition | string) => updateName(newValue)}
                                    getOptionLabel={option => (option as IPropertyDefinition).name}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton onClick={(e) => save()}/>
                        <CancelButton onClick={() => close()}
                        />
                    </DialogActions>
                </Dialog>}
        </React.Fragment>
    );
}
