import {MenuItem, MenuItemProps} from "./MenuItem";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";


export interface CheckBoxMenuItemProps extends Omit<MenuItemProps, "icon"> {
    checked: boolean
}

export function CheckBoxMenuItem(props: CheckBoxMenuItemProps) {
    const icon = props.checked ? (<CheckBoxIcon />) : (<CheckBoxOutlineBlankIcon />);
    return (
        <MenuItem icon={icon} {...props} />
    );
}
