import {useState} from "react";
import chatService, {ChatDto} from "../../../../../common/apis/chat/ChatService";
import Snackbar from "../../snackbar/Snackbar";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import {IconButton} from "@mui/material";
import {Clear, Done, Edit} from "@mui/icons-material";
import {ActionButton} from "../../../../../components/button/ActionButton";
import {CommonTranslation} from "../../CommonTranslation";
import TextField from "../../../../../components/fields/textfield/TextField";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import ChatCommentBody from "../ChatCommentBody";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatAccordionBody: {
            display: 'flex',
            alignItems: 'center'
        },
        textPart: {
            paddingLeft: '40px',
            width: '100%'
        },
        editButtons: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        actionButton: {
            marginLeft: 'auto'
        },
    })
);

enum ChatAccordionBodyState {
    VIEW,
    EDIT,
    IN_PROGRESS,
}

interface ChatAccordionBodyProps {
    chat: ChatDto,
    expanded: boolean,
    onCommentEdited: (commentId: number, message: string) => void
}

export default function ChatAccordionBody(props: ChatAccordionBodyProps) {
    const {chat, expanded, onCommentEdited} = props;

    const classes = useStyles();

    const comment = chat.chatComments[0];

    const [state, setState] = useState(ChatAccordionBodyState.VIEW);
    const [message, setMessage] = useState<string>(comment.message);

    function onSaved() {
        setState(ChatAccordionBodyState.IN_PROGRESS);
        chatService.updateComment(chat.id, comment.id, {message: message})
            .then(() => {
                setState(ChatAccordionBodyState.VIEW);
                onCommentEdited(comment.id, message);
            })
            .catch((err) => {
                setState(ChatAccordionBodyState.EDIT);
                Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), err);
            });
    }

    function onCanceled() {
        setMessage(comment.message);
        setState(ChatAccordionBodyState.VIEW);
    }

    return (
        <div className={classes.chatAccordionBody}>
            <div className={classes.textPart}>
                {state === ChatAccordionBodyState.VIEW &&
                    <ChatCommentBody comment={comment} preview={!expanded} />
                }
                {state !== ChatAccordionBodyState.VIEW && (
                    <div>
                        <TextField
                            multiline
                            maxRows={5}
                            value={message}
                            fullWidth
                            onChange={setMessage}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <div className={classes.editButtons}>
                            <IconButton
                                disabled={state === ChatAccordionBodyState.IN_PROGRESS}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onSaved();
                                }}>
                                <Done/>
                            </IconButton>
                            <IconButton
                                disabled={state === ChatAccordionBodyState.IN_PROGRESS}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onCanceled();
                                }}>
                                <Clear/>
                            </IconButton>
                        </div>
                    </div>
                )}
            </div>
            {state === ChatAccordionBodyState.VIEW &&
                <div className={classes.actionButton}>
                    <ActionButton id={"edit-comment-button"}
                                  tooltip={_transl(CommonTranslation.EDIT)}
                                  icon={<Edit fontSize={"small"}/>}
                                  disabled={!comment.acl.canUpdate}
                                  color={"default"}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      setState(ChatAccordionBodyState.EDIT);
                                  }}/>
                </div>
            }
        </div>
    );
}