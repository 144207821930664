import {ArrayNullUndefined} from "./Types";

export default class ArrayUtils {

    public static join(arr: ArrayNullUndefined, delimiter: string): string {
        const arrTmp = arr || [];
        return arrTmp.join(delimiter);
    }

    public static joinOrElse(arr: ArrayNullUndefined, delimiter: string, emptyValue: string): string {
        return ArrayUtils.join(arr, delimiter) || emptyValue;
    }

    public static arraysEqualIgnoreOrder<T>(itemsArray: Array<T>, newItemsArray: Array<T>, getItemId: (item: T) => string) {
        return (itemsArray == null && newItemsArray == null) ||
            (ArrayUtils.createItemIdsString(itemsArray, getItemId) === ArrayUtils.createItemIdsString(newItemsArray, getItemId));
    }

    private static createItemIdsString<T>(itemsArray: Array<T>, getItemId: (item: T) => string) {
        let idsString;
        if (itemsArray == null) {
            idsString = "";
        } else {
            idsString = itemsArray.map(item => getItemId(item)).sort().join(",");
        }
        return idsString;
    }

    public static filterOutDuplicitIdentifiableItems<T>(items: Array<T>, getId: (item: T) => string) {
        const usedIds = new Map<String, String>();
        const uniqueItems: T[] = [];
        for(const item of items) {
            const itemId = getId(item);
            if (usedIds.get(itemId) == null) {
                usedIds.set(itemId, "");
                uniqueItems.push(item);
            }
        }
        return uniqueItems;
    }
}
