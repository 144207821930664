import Keycloak from 'keycloak-js';
import logoutActionProvider from "../common/logout/LogoutActionProvider";

export class KeycloakHolder {

    public static keycloak: Keycloak.KeycloakInstance | null = null;

    public static getToken(): string | null {
        return KeycloakHolder.keycloak?.token || null;
    }

    public static getParsedToken(): Keycloak.KeycloakTokenParsed | undefined {
        return KeycloakHolder.keycloak?.tokenParsed;
    }

    public static logout() {
        logoutActionProvider.setLogoutInProgress(true);
        KeycloakHolder.keycloak?.logout();
    }
}
