import React, {useRef, useState} from "react";
import {TopMenuId} from "./TopMenuId";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../../DiagramEditorTranslationKey";
import {MenuButton} from "../../../menu/MenuButton";
import {Menu} from "../../../menu/Menu";
import {MenuItem} from "../../../menu/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";
import {FileFormatType} from "../../../../common/apis/Exports";
import {DiagramTranslationKey} from "../../../../pages/main/content/diagrams/DiagramTranslationKey";
import ImageIcon from "@mui/icons-material/Image";
import {
    visualiseDiagramSendingModel
} from "../../../../pages/main/content/diagrams/DiagramVisualization";
import Snackbar from "../../../../pages/main/content/snackbar/Snackbar";
import {ErrorTranslationKey} from "../../../../pages/main/content/ErrorTranslationKey";
import {ModelProvider} from "../../../../common/diagrameditor/api/IDiagramEditorApi";

interface Props {
    open: boolean,
    onClick: (menuId: TopMenuId) => void,
    onClose: () => void,
    saveDisabled: boolean,
    onSaveClicked: (event: any) => void,
    mode: RenderMode,
    modelProvider?: ModelProvider,
}

export default function DiagramMenu(props: Props) {

    const {open, onClick, onClose, saveDisabled, onSaveClicked, modelProvider} = props;

    const anchorRef = useRef<HTMLButtonElement | null>(null);

    const [loading, setLoading] = useState(false);

    function onSaveClick(event: any) {
        onSaveClicked(event);
        onClose();
    }
    function onExportPngClick() {
        setLoading(true);
        const model = modelProvider!.getModel();
        Snackbar.info(_transl(DiagramTranslationKey.DETAIL_ACTION_EXPORT_PNG_IN_PROGRESS_MESSAGE));
        visualiseDiagramSendingModel(FileFormatType.PNG, model, onExportPngFinished, onExportPngFailed);
    }

    function onExportPngFinished() {
        setLoading(false);
        Snackbar.info(_transl(DiagramTranslationKey.DETAIL_ACTION_EXPORT_PNG_FINISHED_MESSAGE));
    }

    function onExportPngFailed() {
        setLoading(false);
        Snackbar.error(_transl(ErrorTranslationKey.UNEXPECTED_ERROR_OCCURRED));
    }

    return  <>
        <MenuButton
            ref={anchorRef}
            onClick={() => onClick(TopMenuId.DIAGRAM_MENU)}
            label={_transl(DiagramEditorTranslationKey.TOP_MENU_DIAGRAM)}
        />
        <Menu open={open}
              anchorEl={anchorRef.current}
              onClose={onClose}
        >
            {props.mode === RenderMode.EDIT ?
                <MenuItem icon={<SaveIcon />}
                      label={_transl(DiagramEditorTranslationKey.TOP_MENU_SAVE_CHANGES)}
                      disabled={saveDisabled}
                      onClick={onSaveClick} /> : <div />
            }
            <MenuItem icon={<ImageIcon />}
                      label={_transl(DiagramTranslationKey.DETAIL_ACTION_EXPORT_PNG_TITLE)}
                      disabled={loading}
                      onClick={onExportPngClick} />
        </Menu>
    </>
}
