import {ITranslation} from "./ITranslation";

const initialState = {} as ITranslation;

enum TranslationActionType {
    SET_TRANSLATION = "SET_TRANSLATION",
}

interface ITranslationAction {
    type: TranslationActionType,
    translation: ITranslation
}

export function getSaveTranslationsAction(translations: ITranslation): ITranslationAction {
    return {
        type: TranslationActionType.SET_TRANSLATION,
        translation: translations,
    }
}

export default function translationReducer(state = initialState, action: ITranslationAction ) {
    switch (action.type) {
        case TranslationActionType.SET_TRANSLATION:
            return action.translation;
        default:
            return state;
    }
}