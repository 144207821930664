import {DataSubformValidator} from "../DataSubformValidator";
import {LayersTreeDataDto} from "../../../../service/HierarchyTreeDataDto";
import {ValidationResult} from "../../../../../../../../common/validation/ValidationResult";

export class LayersSubformValidator implements DataSubformValidator {
    validate(data: LayersTreeDataDto): ValidationResult {
        return {
            errors: []
        };
    }
}
