export class DiagramBackupInfo {
    constructor(private _diagramId: string,
                private _diagramName: string,
                private _date: Date,
                private _login:  string) {
    }

    getDiagramId() {
        return this._diagramId;
    }

    getDiagramName() {
        return this._diagramName;
    }

    getDate() {
        return this._date;
    }

    getLogin() {
        return this._login;
    }
}
