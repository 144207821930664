import {INode} from "../manager/ModelManager";

export enum EventType {
    // NODE EVENTS
    NODE_DRAG_START = "NODE_DRAG_START",
    NODE_DRAG_IN_PROGRESS = "NODE_DRAG_IN_PROGRESS",
    NODE_DRAG_END = "NODE_DRAG_END",
    NODE_MOUSE_ENTER = "NODE_MOUSE_ENTER",
    NODE_MOUSE_LEAVE = "NODE_MOUSE_LEAVE",
    NODE_MOUSE_CLICK = "NODE_MOUSE_CLICK",
    NODE_MOUSE_DBLCLICK = "NODE_MOUSE_DBLCLICK",
    NODE_MENU_OPENED = "NODE_MENU_OPENED",
    NODE_MENU_CLOSED = "NODE_MENU_CLOSED",
    NODE_MENUITEM_CLICKED = "NODE_MENUITEM_CLICKED",
    NODE_ANCHOR_MENUITEM_CLICKED = "NODE_ANCHOR_MENUITEM_CLICKED",
    NODE_UNANCHOR_MENUITEM_CLICKED = "NODE_UNANCHOR_MENUITEM_CLICKED",
    NODE_ANCHORED_ON_DRAG_END = "NODE_ANCHORED_ON_DRAG_END",
    // CHART EVENTS
    CHART_ZOOM_IN = "CHART_ZOOM_IN",
    CHART_ZOOM_OUT = "CHART_ZOOM_OUT",
    CHART_ZOOM_RESET = "CHART_ZOOM_RESET",
    CHART_MOUSE_ENTER = "CHART_MOUSE_ENTER",
    CHART_MOUSE_LEAVE = "CHART_MOUSE_LEAVE",
    CHART_MOUSE_CLICKED = "CHART_MOUSE_CLICKED",
    CHART_MAXIMIZE = "CHART_MAXIMIZE",
    CHART_MINIMIZE = "CHART_MINIMIZE",
}

export type NodeEventType = EventType.NODE_DRAG_START | EventType.NODE_DRAG_IN_PROGRESS | EventType.NODE_DRAG_END
    | EventType.NODE_MOUSE_ENTER | EventType.NODE_MOUSE_LEAVE | EventType.NODE_MOUSE_CLICK | EventType.NODE_MOUSE_DBLCLICK
    | EventType.NODE_ANCHOR_MENUITEM_CLICKED | EventType.NODE_UNANCHOR_MENUITEM_CLICKED | EventType.NODE_MENU_OPENED | EventType.NODE_MENU_CLOSED
    | EventType.NODE_MENUITEM_CLICKED | EventType.NODE_ANCHORED_ON_DRAG_END;

export type ChartEventType = EventType.CHART_ZOOM_IN | EventType.CHART_ZOOM_OUT | EventType.CHART_ZOOM_RESET
    | EventType.CHART_MOUSE_ENTER | EventType.CHART_MOUSE_LEAVE | EventType.CHART_MOUSE_CLICKED | EventType.CHART_MAXIMIZE
    | EventType.CHART_MINIMIZE;

export interface IEvent {
    type: EventType,
    event: any,
}

export interface INodeEvent extends IEvent {
    type: NodeEventType,
    node: INode,
}

export interface IChartEvent extends  IEvent {
    type: ChartEventType,
}