import React, {Component} from 'react';
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {Grid, Paper, TextField} from '@mui/material';
import CommonCssStyles from "../../../css/CommonCssStyles";
import Api from "../../../common/Api";
import {FetchStatusType} from "../../../store/common/FetchableResource";
import {IAppInfoDto} from "../../../common/apis/Common";
import Constants from "../../../common/Constants";
import moment from "moment";
import BuildIcon from '@mui/icons-material/Build';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import {AppBarTranslationKey} from "../appbar/AppBarTranslationKey";
import {AppInfoTranslationKey} from "./AppInfoTranslationKey";
import {_transl} from "../../../store/localization/TranslMessasge";


const styles = (theme: Theme) => createStyles({
    paper: CommonCssStyles.getRootPageStyles(theme),
    headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
    wrapper: {
        marginBottom: theme.spacing(CommonCssStyles.PAGE_SEGMENT_PADDING),
    },
    basicInfoGrid: CommonCssStyles.getBasicInfoGridStyles(theme),
    item: {
        "& > *": {
            flexGrow: 1,
            marginLeft: theme.spacing(CommonCssStyles.PAGE_SEGMENT_PADDING),
            marginRight: theme.spacing(CommonCssStyles.PAGE_SEGMENT_PADDING),
        },
        display: "flex",
        flexGrow: 1,
    },
    sectionHeader: {
        marginTop: theme.spacing(CommonCssStyles.PAGE_SEGMENT_PADDING),
    },
    headerText: {
        paddingTop: theme.spacing(CommonCssStyles.PAGE_SEGMENT_PADDING),
        display: 'flex',
        alignItems: "center",
        "& > *": {
            marginRight: theme.spacing(2),
        }
    },
    remainingSpace: {
        flexGrow: 1
    }
});

interface IProps extends WithStyles<typeof styles> {
}

interface IState {
    fetchStatus: FetchStatusType;
    data?: IAppInfoDto;
}

class AppInfoPage extends Component<IProps, IState> {

    public webRef: React.RefObject<HTMLAnchorElement>;
    public mailRef: React.RefObject<HTMLAnchorElement>;
    public releaseNotesRef: React.RefObject<HTMLAnchorElement>;

    constructor(props: IProps) {
        super(props);
        this.mailRef = React.createRef();
        this.webRef = React.createRef();
        this.releaseNotesRef = React.createRef();
        this.state = {
            fetchStatus: FetchStatusType.NOT_STARTED,
        }
    }

    componentDidMount() {
        this.setState({fetchStatus: FetchStatusType.STARTED, data: undefined});
        Api.common.fetchAppInfoDto(
            (data) => this.setState({fetchStatus: FetchStatusType.SUCCESSFUL, data: data}),
            () => this.setState({fetchStatus: FetchStatusType.FAILED, data: undefined}));
    }

    render() {
        const { classes } = this.props;
        const {fetchStatus, data} = this.state;
        return (
            <Paper className={classes.paper}>
                <div className={classes.headerPageSegment}>
                    <Typography variant="h6">
                        {_transl(AppBarTranslationKey.APP_INFO)}
                    </Typography>
                </div>
                <Divider />
                {fetchStatus === FetchStatusType.STARTED && <div>{_transl(AppInfoTranslationKey.LOADING)}</div>}
                {fetchStatus === FetchStatusType.FAILED && <div>{_transl(AppInfoTranslationKey.DATA_LOAD_FAILED)}</div>}
                {fetchStatus === FetchStatusType.SUCCESSFUL && <div className={classes.wrapper}>
                    <Grid container spacing={3} className={classes.basicInfoGrid}>
                        <Grid item xs={12} md={6} className={classes.item}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className={classes.sectionHeader}>
                                        <Typography variant="h5" className={classes.headerText}>
                                            <ImportContactsIcon color={"primary"}/>{_transl(AppInfoTranslationKey.BASIC_INFO)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.createTextField(data?.name, _transl(AppInfoTranslationKey.PRODUCT), "product")}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.createTextField(data?.url, _transl(AppInfoTranslationKey.WEB), "web", () => this.webRef.current && this.webRef.current.click())}
                                    <a ref={this.webRef}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       href={data?.url}
                                       style={{display: "none"}}>{data?.url}</a>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.createTextField(data?.email, _transl(AppInfoTranslationKey.HELPDESK), "helpdesk", () => this.mailRef.current && this.mailRef.current.click())}
                                    <a ref={this.mailRef}
                                       target="_top"
                                       rel="noopener noreferrer"
                                       href={`mailto:${data?.email}`}
                                       style={{display: "none"}}>{data?.email}</a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.item}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className={classes.sectionHeader}>
                                        <Typography variant="h5" className={classes.headerText}>
                                            <BuildIcon color="primary"/> {_transl(AppInfoTranslationKey.COMPILATION)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.createTextField(`${data?.version}.${data?.buildNumber}`, _transl(AppInfoTranslationKey.VERSION_AND_COMPILATION), "version")}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.createTextField(moment(data?.buildDate).format(Constants.DATE_FORMAT), _transl(AppInfoTranslationKey.DATE_OF_COMPILATION), "date")}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.createTextField("Release notes", _transl(AppInfoTranslationKey.RELEASE_NOTES), "notes", () => this.releaseNotesRef.current && this.releaseNotesRef.current.click())}
                                    <a ref={this.releaseNotesRef}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       href={data?.releaseNotes}
                                       style={{display: "none"}}>{data?.releaseNotes}</a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>}
            </Paper>
        );
    }

    createTextField(defaultValue: string | undefined, label: string, id: string, onClick?: () => void): JSX.Element {
        return <TextField
            id={id}
            key={defaultValue}
            label={label}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                readOnly: true,
                style: {
                    cursor: onClick ? "pointer" : "text",
                    textDecoration: onClick ? "underline" : "none",
                    color: onClick ? "#0000EE" : "auto",
                }
            }}
            onClick={onClick}
            variant="outlined"
            defaultValue={defaultValue}
            fullWidth={true}
            size={"small"}
        />
    }

}

export default withStyles(styles, { withTheme: true })(AppInfoPage);

