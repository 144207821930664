export enum ButtonId {
    DELETE_ELEMENT = "DELETE_ELEMENT",
    BULK_DELETE_ELEMENT = "BULK_DELETE_ELEMENT",
    ADD_ELEMENT = "ADD_ELEMENT",
    ADD_DIAGRAM = "ADD_DIAGRAM",
    DELETE_DIAGRAM = "DELETE_DIAGRAM",
    DELETE_DIAGRAMS = "DELETE_DIAGRAMS",
    SHOW_DIAGRAM = "SHOW_DIAGRAM",
    EDIT_DIAGRAM = "EDIT_DIAGRAM",
    MERGE_ELEMENTS = "MERGE_ELEMENTS",
    SHOW_NEIGHBOURS_CHART = "SHOW_NEIGHBOURS_CHART",
    SHOW_INTERRELATIONSHIPS_CHART = "SHOW_INTERRELATIONSHIPS_CHART",
    ADD_BINDING_ELEMENTS = "ADD_BINDING_ELEMENTS",
    ADD_BULK_LABELS = "ADD_BULK_LABELS",
    ADD_BULK_COLLECTIONS = "ADD_BULK_COLLECTIONS",
    REMOVE_BULK_LABELS = "REMOVE_BULK_LABELS",
    REMOVE_BULK_COLLECTIONS = "REMOVE_BULK_COLLECTIONS",
    EXPORT_DATA = "EXPORT_DATA",
    CHANGE_ELEMENTS_TYPE = "CHANGE_ELEMENTS_TYPE",
    SHOW_ELEMENTS_LIST = "SHOW_ELEMENTS_LIST",
}
