import {DataSubformValidator} from "../DataSubformValidator";
import {CollectionsTreeDataDto} from "../../../../service/HierarchyTreeDataDto";
import {ValidationResult} from "../../../../../../../../common/validation/ValidationResult";
import {CommonTranslation} from "../../../../../CommonTranslation";

export class CollectionsSubformValidator implements DataSubformValidator {
    validate(data: CollectionsTreeDataDto): ValidationResult {
        const errors = [];

        if (data.collections == null || data.collections.length === 0) {
            errors.push({ errorMessage: CommonTranslation.FILL_OUT_AT_LEAST_ONE });
        }

        return {
            errors: errors
        };
    }
}
