import {ChartEventType, EventType, IChartEvent, INodeEvent, NodeEventType} from "../event/Event";
import {IChartEventListener, IEventListener, INodeEventListener} from "../event/Listener";


export default class EventManager {

    private listeners: {[key in keyof typeof EventType] : Array<IEventListener>};

    constructor() {
        this.listeners = {
            NODE_DRAG_START: [],
            NODE_DRAG_IN_PROGRESS: [],
            NODE_DRAG_END: [],
            NODE_MOUSE_ENTER: [],
            NODE_MOUSE_LEAVE: [],
            NODE_MOUSE_CLICK: [],
            NODE_MOUSE_DBLCLICK: [],
            NODE_ANCHOR_MENUITEM_CLICKED: [],
            NODE_UNANCHOR_MENUITEM_CLICKED: [],
            NODE_MENU_OPENED: [],
            NODE_MENU_CLOSED: [],
            CHART_ZOOM_IN: [],
            CHART_ZOOM_OUT: [],
            CHART_ZOOM_RESET: [],
            NODE_MENUITEM_CLICKED: [],
            NODE_ANCHORED_ON_DRAG_END: [],
            CHART_MOUSE_LEAVE: [],
            CHART_MOUSE_ENTER: [],
            CHART_MOUSE_CLICKED: [],
            CHART_MAXIMIZE: [],
            CHART_MINIMIZE: [],
        }
    }

    public subscribeChartListener(listener: IChartEventListener, eventType: ChartEventType): () => void {
        const array = this.listeners[eventType];
        array.push(listener);
        return () => array.splice(array.indexOf(listener));
    }

    public subscribeNodeListener(listener: INodeEventListener, eventType: NodeEventType): () => void {
        const array = this.listeners[eventType];
        array.push(listener);
        return () => array.splice(array.indexOf(listener));
    }

    public publishNodeEvent(event: INodeEvent) {
        if (event) {
            this.listeners[event.type].forEach(listener => {
                const typed = (listener as INodeEventListener);
                typed.handleNodeEvent && typed.handleNodeEvent(event);
            });
        }
    }

    public publishChartEvent(event: IChartEvent) {
        if (event) {
            this.listeners[event.type].forEach(listener => {
                const typed = (listener as IChartEventListener);
                typed.handleChartEvent && typed.handleChartEvent(event);
            });
        }
    }

}