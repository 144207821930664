import {Menu} from "@mui/material";
import React from "react";
import {PopoverPosition} from "@mui/material/Popover/Popover";

export interface ContextMenuProps {
    open: boolean,
    anchorPosition?: PopoverPosition,
    children?: React.ReactNode;
    onClose: () => void;
}

export function ContextMenu(props: ContextMenuProps) {
    return (
        <Menu open={props.open}
              onClose={props.onClose}
              anchorReference="anchorPosition"
              anchorPosition={props.anchorPosition}
              onContextMenu={event => event.preventDefault()}
              MenuListProps={{
                  dense: true
              }}
        >
            {props.children}
        </Menu>
    );
}
