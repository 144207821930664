export enum CollectionsTranslationKey {
    TITLE = "collections.title",

    CREATE_BUTTON = "collections.add.button",
    CREATE_FAILED = "collections.add.failed",
    CREATE_TITLE = "collections.add.title",
    CREATE_SUCCEEDED = "collections.add.succeeded",

    DELETE_BUTTON = "collections.delete.button",
    DELETE_FAILED = "collections.delete.failed",
    DELETE_TITLE = "collections.delete.title",
    DELETE_SUCCEEDED = "collections.delete.succeeded",
    DELETE_CONFIRMATION = "collections.delete.confirmation",

    UPDATE_BUTTON = "collections.update.button",
    UPDATE_FAILED = "collections.update.failed",
    UPDATE_TITLE = "collections.update.title",
    UPDATE_SUCCEEDED = "collections.update.succeeded",
    NOT_FETCHED = "collections.notFetched",
    COLLECTION_IS_REFERENCED = "collections.error.isReferenced",
    CODE_USED_BY_ANOTHER_COLLECTION = "collections.error.codeUsedByAnotherCollection",
}
