import React from "react";
import {Language, languageToLocale} from "./Language";

export interface Locale {
    locale: string;
    language: Language;
    setLanguage: (language: Language, store: boolean) => void;
}

const DEFAULT_LANG = Language.CS;

const LocaleContext = React.createContext<Locale>({
    locale: languageToLocale(DEFAULT_LANG) as string,
    language: DEFAULT_LANG,
    setLanguage: () => {}
});
LocaleContext.displayName = "LocaleContext";

export default LocaleContext;