import React from "react";
import DiagramEditorDialog from "./DiagramEditorDialog";
import {DiagramsGridAction, DiagramsGridActionType} from "./DiagramsGridAction";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";
import {useSearchParams} from "react-router-dom";

export default function DiagramEditorPage() {

    const [params] = useSearchParams();
    const showEditorDiagramId = params.get(DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR));
    const editorMode = params.get(DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDITOR_MODE));

    return (
        <DiagramEditorDialog id={showEditorDiagramId!}
                             onClosed={() => window.close()}
                             mode={editorMode! === RenderMode.EDIT ? RenderMode.EDIT : RenderMode.PRE_EDIT}
        />
    );
}
