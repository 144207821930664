import React from "react";
import {IPropertyDefinition} from "./apis/CommonTypes";

export interface DiagramPropertyDefinition {
    propertyDefinitions: IPropertyDefinition[];
    updatePropertyDefinitions: () => void;
}

const DiagramPropertyDefinitionContext = React.createContext<DiagramPropertyDefinition>({
    propertyDefinitions: [],
    updatePropertyDefinitions: () => {}
});

export default DiagramPropertyDefinitionContext;