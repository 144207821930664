import {Language} from "./Language";
import TimeAgo from "javascript-time-ago";
import cs from "javascript-time-ago/locale/cs";
import en from "javascript-time-ago/locale/en";

function initializeTimeAgoLocales() {
    const ALL_SUPPORTED_LOCALES = new Map([
        [Language.CS, cs],
        [Language.EN, en]
    ]);

    ALL_SUPPORTED_LOCALES.forEach((locale) => {
        TimeAgo.addLocale(locale);
    });
}

const TIME_AGO_STYLE = "twitter-minute-now";

export class TimeAgoFormatter {
    private readonly timeAgo: TimeAgo;

    constructor(private readonly language: Language) {
        this.timeAgo = new TimeAgo(language.toLowerCase());
    }

    formatAsTimeAgo(date: Date | number): string {
        return this.timeAgo.format(date, TIME_AGO_STYLE);
    }
}

initializeTimeAgoLocales();