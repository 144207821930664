import React, {useState} from "react";
import {PersistentStateId} from "../../../../../../store/common/Grid";
import {DiagramInfoDto} from "../../../../../../common/apis/diagram/DiagramInfoDto";
import {GridAction} from "../../../../../../components/grid/GridAction";
import {createDiagramPanelColDef} from "./DiagramsPanelColumnDefinition";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import constructDiagramController from "../../../diagrams/controller/DiagramController";
import DiagramDetailDialog from "../../../diagrams/detail/DiagramDetailDialog";
import ExtGridWrapper from "../../../../../../components/grid/ExtGridWrapper";


interface DiagramPanelProps {
    diagrams: DiagramInfoDto[],
    elementId: string,
}

export default function DiagramsPanel({diagrams, elementId}: DiagramPanelProps) {

    const controller = constructDiagramController();

    const [selectedDiagram, setSelectedDiagram] = useState<DiagramInfoDto>();
    const [diagramDetailDialogOpened, setDiagramDetailDialogOpened] = useState<boolean>(false);

    return (
        <React.Fragment>
            {selectedDiagram &&
                <DiagramDetailDialog opened={diagramDetailDialogOpened}
                                     diagramId={selectedDiagram.identifier}
                                     onClosed={() => setDiagramDetailDialogOpened(false)}
                />
            }
            <ExtGridWrapper
                columns={createDiagramPanelColDef(_transl)}
                rows={diagrams}
                rowCount={diagrams.length}
                getRowId={row => row.identifier}
                actions={[
                    GridAction.detailButtonBuilder()
                        .onClick((selectedRowIds) => {
                            setDiagramDetailDialogOpened(true);
                        }).build(),
                    GridAction.showDiagramEditorButtonBuilder(false).build(),
                    GridAction.showDiagramEditorButtonBuilder(true)
                        .isEnabled(selectedRows => controller.canUpdateAllDiagrams(selectedRows))
                        .build(),
                ]}
                onRowClick={(params) => setSelectedDiagram(params.row)}
                peristentStateId={PersistentStateId.DIAGRAMS_CONTAINING_ITEM_GRID}
                resourceId={elementId}
            />
        </React.Fragment>
    );
}
