import {Action, Reducer} from "redux";
import {GridPresets} from "../../components/grid/presets/GridPresets";

export enum GridPresetsActionType {
    UPDATE = "ACTION/ITEMS/GRID_PRESETS/UPDATE",
    RESET = "ACTION/ITEMS/GRID_PRESETS/RESET",
}

interface GridPresetsAction extends Action<GridPresetsActionType> {
    type: GridPresetsActionType;
    gridPresets?: GridPresets,
}

export function getGridPresetsUpdateAction(gridPresets: GridPresets): GridPresetsAction {
    return {
        type: GridPresetsActionType.UPDATE,
        gridPresets: gridPresets,
    }
}

export function getGridPresetsResetAction(): GridPresetsAction {
    return {
        type: GridPresetsActionType.RESET,
    }
}

export interface GridPresetsState {
    gridPresets: GridPresets,
}

const initialGridPresetsState: GridPresetsState = {
    gridPresets: {columns: []},
}

export const gridPresetsStateReducer: Reducer<GridPresetsState, GridPresetsAction> = (
    state = initialGridPresetsState,
    action
) => {
    switch (action.type) {
        case GridPresetsActionType.UPDATE:
            return {
                ...state,
                gridPresets: action.gridPresets as GridPresets,
            };
        case GridPresetsActionType.RESET:
            return initialGridPresetsState;
        default:
            return state;
    }
};

