import React, { useState } from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {CircularProgress, Step, StepLabel, Stepper} from '@mui/material';
import WizardIcon from "./WizardIcon";
import Typography from "@mui/material/Typography";
import WizardStepConnector from "./WizardStepConnector";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Grid from "@mui/material/Grid";
import WizardButtons from "./WizardButtons";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {WizardStep} from "./WizardStep";
import {AjaxError} from "rxjs/ajax";
import {_transl} from "../../store/localization/TranslMessasge";
import WizardImportStep from "./WizardImportStep";

export enum ImportStatus {
    NOT_STARTED,
    STARTED,
    SUCCESSFULL,
    FAILED,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainWizardSegment: {
            "& .MuiStepper-root": {
                padding: 0,
                marginLeft: "5em",
                marginRight: "5em",
            },
        },
        wizardGrid: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(6),
        },
        stepGridItem: {
            "& > *": {
                width: "100%",
                height: "100%",
            }
        },
        stepGridItemContentDiv: {
            display: "flex",
            flexDirection: "column",
        },
        stepGridItemLabelDiv: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            minHeight: "4em",
            fontWeight: "bolder",
            color: "gray",
            fontSize: "1.2em",
        },
        stepGridItemStepDiv: {
            flexGrow: 1,
        },
        importStep: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        importLabel: {
            fontSize: "1.4em",
            color: "gray",
            marginBottom: theme.spacing(4),
        },
        importErrorMessage: {
            color: "gray"
        },
        importStartedIcon: {
            color: theme.palette.primary.main,
        },
        importSuccessIcon: {
            color: theme.palette.success.main,
        },
        importFailedIcon: {
            color: theme.palette.error.main,
        },
    })
);

interface Props {
    steps: WizardStep[],
    lastStepLabel: string,
    lastStepButtonLabel: string,
    lastStepActionInProgressText: string,
    lastStepActionSuccessfulText: string,
    lastStepActionFailedText: string,
    lastStepAction: () => Promise<any>,
    lastStepActionSuccessProcessor?: (result: any) => void,
    cancelWizard: () => void,
    translateErrorToMessage?: (error: AjaxError) => string | undefined,
    onActiveStepChanged?: (id: number) => void,
    wizardGridMinHeight?: string,
    lastStepActionSuccessfulTextAdditionalInfo?: string,
}

export default function Wizard(props: Props) {

    const [activeStepId, setActiveStepId] = useState<number>(0);
    const [importStatus, setImportStatus] = useState<ImportStatus>(ImportStatus.NOT_STARTED);
    const [errorMessage, setErrorMessage] = useState<string>();

    const classes = useStyles();

    function onPrevStep(recognizedActiveStepId: number, newStepId: number, isLastStep: boolean) {
        if (isLastStep) {
            setImportStatus(ImportStatus.NOT_STARTED);
        } else {
            setActiveStepId(newStepId);
        }
    }

    function doImport() {
        const { lastStepAction, lastStepActionSuccessProcessor, translateErrorToMessage } = props;

        setErrorMessage(undefined);
        setImportStatus(ImportStatus.STARTED);
        (async () => {
            try {
                const result = await lastStepAction();
                setImportStatus(ImportStatus.SUCCESSFULL);
                lastStepActionSuccessProcessor && lastStepActionSuccessProcessor(result)
            } catch (error) {
                if (translateErrorToMessage) {
                    const errorKey = translateErrorToMessage(error as AjaxError);
                    setErrorMessage(errorKey);
                }
                setImportStatus(ImportStatus.FAILED);
            }
        })();
    }

    function renderImportStep() {
        const {lastStepActionInProgressText, lastStepActionSuccessfulText, lastStepActionFailedText, lastStepActionSuccessfulTextAdditionalInfo} = props;

        return <React.Fragment>
            {importStatus === ImportStatus.STARTED &&
                <WizardImportStep
                    firstRowText={lastStepActionInProgressText}
                    icon={<CircularProgress size="5em" color="primary"/>}
                    iconClass={classes.importStartedIcon}
                />
            }
            {importStatus === ImportStatus.SUCCESSFULL &&
                <WizardImportStep
                    firstRowText={lastStepActionSuccessfulText}
                    secondRowText={lastStepActionSuccessfulTextAdditionalInfo}
                    icon={<CheckCircleOutlineIcon/>}
                    iconClass={classes.importSuccessIcon}
                />
            }
            {importStatus === ImportStatus.FAILED &&
                <WizardImportStep
                    firstRowText={lastStepActionFailedText}
                    secondRowText={errorMessage && _transl(errorMessage)}
                    icon={<CancelOutlinedIcon/>}
                    iconClass={classes.importFailedIcon}
                />
            }
        </React.Fragment>
    }

    function isOptional(stepConfig: WizardStep) {
        let optional: boolean;
        if (stepConfig.optional === true || stepConfig.optional === false) {
            optional = stepConfig.optional;
        } else {
            optional = stepConfig.optional();
        }
        return optional;
    }

    const { wizardGridMinHeight } = props;
    const { steps, lastStepLabel, lastStepButtonLabel, onActiveStepChanged } = props;
    const { cancelWizard } = props;
    const importFinished = importStatus === ImportStatus.SUCCESSFULL || importStatus === ImportStatus.FAILED;

    const lastStepId = steps.length;
    const recognizedActiveStepId = importStatus === ImportStatus.NOT_STARTED ? activeStepId : lastStepId;
    const isLastStep = recognizedActiveStepId === lastStepId;
    const wizardGridStyles = wizardGridMinHeight != null ? {minHeight: wizardGridMinHeight} : {}

    return (
        <div className={classes.mainWizardSegment}>
            <Stepper alternativeLabel activeStep={recognizedActiveStepId} connector={<WizardStepConnector />}>
                {
                    steps.map((stepConfig, index) =>
                        <Step key={index}
                              completed={recognizedActiveStepId > index}
                        >
                            <StepLabel StepIconComponent={(props) => <WizardIcon {...props} icon={stepConfig.icon} />}
                                       optional={isOptional(stepConfig) ? <Typography variant={"caption"} display={"block"} style={{textAlign: "center"}}>(Volitelný)</Typography> : null}
                            >
                                {stepConfig.label}
                            </StepLabel>
                        </Step>
                    )
                }
                <Step key={lastStepId}
                      completed={importFinished}
                >
                    <StepLabel StepIconComponent={(props) => <WizardIcon {...props} icon={<InsertDriveFileIcon />} />}>
                        {lastStepLabel}
                    </StepLabel>
                </Step>
            </Stepper>
            <div>
                <Grid container className={classes.wizardGrid} style={wizardGridStyles}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className={classes.stepGridItem}>
                        {!isLastStep &&
                            <div className={classes.stepGridItemContentDiv}>
                                <div className={classes.stepGridItemLabelDiv}>
                                    {steps[activeStepId].description}
                                </div>
                                <div className={classes.stepGridItemStepDiv}>
                                    {steps[activeStepId].renderStep()}
                                </div>
                            </div>
                        }
                        {isLastStep && renderImportStep()}
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </div>
            <div>
                <WizardButtons steps={steps}
                               activeStepId={activeStepId}
                               onPrevStep={() => {
                                   const prevStep = activeStepId - 1;
                                   onPrevStep(recognizedActiveStepId, prevStep, isLastStep);
                                   onActiveStepChanged && onActiveStepChanged(prevStep);
                               }}
                               onNextStep={(stepId) => {
                                   setActiveStepId(stepId);
                                   onActiveStepChanged && onActiveStepChanged(stepId);
                               }}
                               onImport={() => doImport()}
                               importStatus={importStatus}
                               onResetImport={() => cancelWizard()}
                               lastStepButtonLabel={lastStepButtonLabel}
                />
            </div>
        </div>
    );

}
