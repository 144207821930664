export enum TrackerTranslationKey {
    OPTIONS_DIALOG_CHOOSE_ASSIGN_TASK_OPTION = "tracker.optionsDialog.chooseAssignTaskOption",
    OPTIONS_DIALOG_ASSIGN_NEW_TASK = "tracker.optionsDialog.assignNewTask",
    OPTIONS_DIALOG_ASSIGN_EXISTING_TASK = "tracker.optionsDialog.assignExistingTask",
    FILTER_FIELD_TASK_NAME = "tracker.assignExistingTaskFilter.nameField",
    FILTER_FIELD_THEME = "tracker.assignExistingTaskFilter.themeField",
    CHOOSE_TASK = "tracker.chooseTask",
    TASKS_DELETE_LINK = "elementsDetail.tasks.deleteLink",
    TASKS_DELETE_LINK_DIALOG_TITLE = "elementsDetail.tasks.deleteLinkDialogTitle",
    TASKS_DELETE_CONFIRMATION_TEXT = "elementsDetail.tasks.deleteLinkConfirmationText",
    TASKS_REFRESH = "elementsDetail.tasks.refresh",
}
