import {IconButton, Tooltip} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import React, {useState} from "react";
import Constants from "../common/Constants";
import {from} from "rxjs";
import CheckIcon from "@mui/icons-material/Check";

enum UrlCopyIconType {
    COPY_URL = "COPY_URL",
    URL_COPIED = "URL_COPIED",
}

interface Props {
    title: string,
    successTitle: string,
    url: string,
}

export default function CopyUrlIcon(props: Props) {

    const [shownUrlCopyIcon, setShownUrlCopyIcon] = useState<UrlCopyIconType>(UrlCopyIconType.COPY_URL);
    const {title, successTitle, url} = props;

    function copyUrl() {
        from(navigator.clipboard.writeText(url)).subscribe({
            next: () => {
                setShownUrlCopyIcon(UrlCopyIconType.URL_COPIED);
                setTimeout(() => setShownUrlCopyIcon(UrlCopyIconType.COPY_URL), Constants.FE_APP_ALERT_DELAY);
            }
        });
    }

    return <>
            {shownUrlCopyIcon === UrlCopyIconType.COPY_URL &&
                <Tooltip title={title} placement={"top"}>
                    <IconButton onClick={(e) => copyUrl()} size="large">
                        <LinkIcon/>
                    </IconButton>
                </Tooltip>
            }
            {shownUrlCopyIcon === UrlCopyIconType.URL_COPIED &&
                <Tooltip title={successTitle} placement="top">
                    <IconButton size="large">
                    <CheckIcon
                        style={{color: 'green'}}
                    />
                    </IconButton>
                </Tooltip>
            }
        </>;
}
