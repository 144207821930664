import {_transl} from "../../store/localization/TranslMessasge";
import {LibraryAddCheckOutlined, LibraryAddCheck} from "@mui/icons-material";
import {Fab, Tooltip} from "@mui/material";
import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {ExtGridTranslationKey} from "./ExtGridTranslationKey";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        unchecked: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0px 0px 0px 0px",
        }
    })
);

export interface BulkActionsSwitchButtonProps {
    checked: boolean,
    disabled?: boolean,
    onChange: (checked: boolean) => void,
}

export function BulkActionsSwitchButton(props: BulkActionsSwitchButtonProps) {
    const {checked, disabled, onChange} = props;
    const tooltip = _transl(ExtGridTranslationKey.BULK_ACTIONS);
    const icon = checked ? <LibraryAddCheck/> : <LibraryAddCheckOutlined/>;

    const classes = useStyles();

    return (
        <Tooltip title={tooltip} key={tooltip} placement={"top"}>
            <Fab className={checked ? undefined : classes.unchecked}
                 aria-label={tooltip}
                 color={checked ? "primary" : "default"}
                 size={"medium"}
                 disabled={disabled}
                 onClick={()=> onChange(!checked)}
            >
                {icon}
            </Fab>

        </Tooltip>
    );
}
