import React from "react";

interface IProps {
    id: string;
    svgRef: React.RefObject<SVGSVGElement>;
}

export default class SvgCanvas extends React.Component<IProps, {}>{

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return this.props.id !== nextProps.id;
    }

    render() {
        return <svg ref={this.props.svgRef} id={SvgCanvas.createDiagramEditorSvgId()} />
    }

    public static createDiagramEditorSvgId(isSelector?: boolean) {
        return (isSelector ? "#" : "") + `__diagram-editor-svg__`;
    }

}