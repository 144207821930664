import {JSXElementNullable} from "../Types";
import RouteDefinition from "../routedefinition/RouteDefinition";

export default class MenuDefinition {

    constructor(private _label: string,
                private _icon: JSXElementNullable,
                private _routeDefinition: () => RouteDefinition) {
    }

    public get label() {
        return this._label;
    }

    public get icon() {
        return this._icon;
    }

    public get route() {
        return this._routeDefinition();
    }

    public get path() {
        return this._routeDefinition().path;
    }

}