import {TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, useTheme} from "@mui/material";
import ClearButtonInputAdornment from "../adornment/ClearButtonInputAdornment";
import React, {useEffect, useState} from "react";
import {Theme} from "@mui/material/styles";

export type TextFieldProps = Omit<MuiTextFieldProps, "onChange"> & {
    onChange?: (text: string) => void;
    errorMessage?: string,
    maxLength?: number,
    onClearButtonClick?: () => void;
    clearable?: boolean;
}

export default function TextField(props: TextFieldProps) {
    const {
        id,
        errorMessage,
        inputProps,
        InputLabelProps,
        InputProps,
        value,
        onChange,
        onClearButtonClick,
        clearable,
        error,
        helperText,
        ...rest
    } = props;
    const [text, setText] = useState(value);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const maxLength = props.maxLength ?? inputProps?.maxLength;
    const errorFromErrorMessage = errorMessage !== undefined ? true : error;
    const helperTextFromErrorMessage = errorMessage ?? helperText;
    const fullWidth = props.fullWidth ?? true;

    function createReadOnlyStyle(theme: Theme) {
        const readOnlyBorderColor = theme.palette.grey["400"];
        const readOnlyBorderWidth = '1px';

        return {
            "& .MuiInputBase-readOnly": {
                "& > fieldset": {borderColor: readOnlyBorderColor},
            },
            "& .MuiInputBase-readOnly:hover": {
                "&:hover > fieldset": {borderColor: readOnlyBorderColor},
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: readOnlyBorderColor, borderWidth: readOnlyBorderWidth
                },
                '&:hover fieldset': {
                    borderColor: readOnlyBorderColor, borderWidth: readOnlyBorderWidth
                },
                '&.Mui-focused fieldset': {
                    borderColor: readOnlyBorderColor, borderWidth: readOnlyBorderWidth
                },
            }
        };
    }

    useEffect(() => {
        setText(value);
    }, [value])

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    const createClearButtonAdornment = () => {
        if (clearable && onClearButtonClick !== undefined) {
            return <ClearButtonInputAdornment
                visible={hasInputText() && (isFocused || isHovered)}
                onClick={(e) => {
                    onClearButtonClick();
                    e.stopPropagation();
                }}/>;
        } else if (clearable) {
            return <ClearButtonInputAdornment
                visible={hasInputText() && (isFocused || isHovered)}
                onClick={() => onTextChange("")}
            />;
        } else {
            return undefined;
        }
    }

    function hasInputText(): boolean {
        return !!text;
    }

    const onTextChange = (newText: string) => {
        setText(newText);
        if (onChange) {
            onChange(newText);
        }
    }
    const theme = useTheme();

    return (
        <MuiTextField
            sx={(props.InputProps?.readOnly) ? createReadOnlyStyle(theme) : {}}
            value={text}
            onChange={e => onTextChange(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            variant={"outlined"}
            size={"small"}
            error={errorFromErrorMessage}
            helperText={helperTextFromErrorMessage}
            fullWidth={fullWidth}
            inputProps={{
                ...inputProps,
                maxLength: maxLength
            }}
            InputLabelProps={{
                shrink: true,
                ...InputLabelProps,
            }}
            InputProps={{
                endAdornment: createClearButtonAdornment(),
                ...InputProps,
            }}
            {...rest}
        />
    );
}
