import React from "react";
import * as d3 from "d3";

export default class UIUtils {

    public static animateBackgroundColorTo(requiredBgColor: string, htmlDiv: React.RefObject<HTMLDivElement>) {
        d3.select(htmlDiv.current)
            .transition().duration(500)
            .style("background-color", requiredBgColor);
    }

    public static requestFocus(elemId: string) {
        const input = document.getElementById(elemId);
        if (input != null ){
            input.focus();
        }
    }

}