import {ArchiMateLayerTranslationKey} from "../../pages/main/content/ArchiMateLayerTranslationKey";

export const LAYER_DEFAULT_COLOR = "gray";

export enum ArchimateLayerType {
    APPLICATION = "APPLICATION",
    BUSINESS = "BUSINESS",
    IMPLEMENTATION_AND_MIGRATION = "IMPLEMENTATION_AND_MIGRATION",
    MOTIVATION = "MOTIVATION",
    PHYSICAL = "PHYSICAL",
    STRATEGY = "STRATEGY",
    TECHNOLOGY = "TECHNOLOGY",
    COMPOSITE_ELEMENTS = "COMPOSITE_ELEMENTS",
    VIRTUAL_LAYER_CONNECTORS = "VIRTUAL_LAYER_CONNECTORS",
}

export class ArchimateLayer {
    public static readonly [ArchimateLayerType.APPLICATION] = new ArchimateLayer(ArchimateLayerType.APPLICATION, ArchiMateLayerTranslationKey.LAYER_APPLICATION);
    public static readonly [ArchimateLayerType.BUSINESS] = new ArchimateLayer(ArchimateLayerType.BUSINESS, ArchiMateLayerTranslationKey.LAYER_BUSINESS);
    public static readonly [ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION] = new ArchimateLayer(ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION, ArchiMateLayerTranslationKey.LAYER_IMPLEMENTATION_AND_MIGRATION);
    public static readonly [ArchimateLayerType.MOTIVATION] = new ArchimateLayer(ArchimateLayerType.MOTIVATION, ArchiMateLayerTranslationKey.LAYER_MOTIVATION);
    public static readonly [ArchimateLayerType.PHYSICAL] = new ArchimateLayer(ArchimateLayerType.PHYSICAL, ArchiMateLayerTranslationKey.LAYER_PHYSICAL);
    public static readonly [ArchimateLayerType.STRATEGY] = new ArchimateLayer(ArchimateLayerType.STRATEGY, ArchiMateLayerTranslationKey.LAYER_STRATEGY);
    public static readonly [ArchimateLayerType.TECHNOLOGY] = new ArchimateLayer(ArchimateLayerType.TECHNOLOGY, ArchiMateLayerTranslationKey.LAYER_TECHNOLOGY);
    public static readonly [ArchimateLayerType.COMPOSITE_ELEMENTS] = new ArchimateLayer(ArchimateLayerType.COMPOSITE_ELEMENTS, ArchiMateLayerTranslationKey.LAYER_COMPOSITE_ELEMENTS);
    public static readonly [ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS] = new ArchimateLayer(ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS, ArchiMateLayerTranslationKey.LAYER_VIRTUAL_CONNECTORS);

    constructor(public readonly layerType: ArchimateLayerType,
                public readonly translationKey: string) {
    }
}