import {ChromePicker, ColorResult} from "react-color";
import {ColorDto, Colors} from "../../common/apis/Color";

export interface ColorPickerProps {
    color: ColorDto,
    onChange: (color: ColorDto) => void,
}

export function ColorPicker({color, onChange}: ColorPickerProps) {

    function onColorChange(colorResult: ColorResult) {
        onChange(toColor(colorResult));
    }

    function toColor(colorResult: ColorResult) {
        const rgb = colorResult.rgb;
        const alpha = rgb.a ? Math.floor(rgb.a * 100) : 100;
        return Colors.color(rgb.r, rgb.g, rgb.b, alpha);
    }

    return (
        <ChromePicker color={Colors.toCss(color)} onChange={onColorChange} />
    );
}