import RenderContext from "../../../../context/RenderContext";
import {CUSTOM_DRAG_HANDLER, ICustomClickOnlyHandler} from "../../../../manager/SvgElementDragManager";
import {LinePath} from "../../../connection/ConnectionRendererUtils";
import * as d3 from 'd3';
import ConnectionHandle from "./ConnectionHandle";
import {
    DISABLE_ON_NODE_MOVE_POINTER_EVENTS_AUTO_CLASS_NAME,
    HANDLE_ADD_FILL_COLOR_PRIMARY,
    HANDLE_COLOR_TRANSPARENT_OPACITY
} from "../../../../common/UIConstants";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import TranslationKey from "../../../../TranslationKey";
import {IDiagramConnectionDto} from "../../../../../apis/diagram/IDiagramConnectionDto";
import EventManager from "../../../../../event/EventManager";
import {EventType} from "../../../../../event/Event";

export default class HiddenConnectionVisualizeHandle implements ConnectionHandle {

    private static readonly HANDLE_RADIUS = 8;

    private eventManager: EventManager;
    private renderContext?: RenderContext;
    private handleGroup?: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    private scaleFactor: number;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.scaleFactor = 1;
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    render(connection: IDiagramConnectionDto,
           linePath: LinePath,
           handleGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
           isHidden: boolean,
           eventManager: EventManager) {
        if (this.renderContext?.isEdit()) {
            const handle = this.renderRemoveHandle(linePath, handleGroup);
            this.appendClickHandler(connection, handle);
            this.applyScaleFactor();
        }
    }

    private renderRemoveHandle(linePath: LinePath,
                               handleGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>) {
        const defaultOpacity = HANDLE_COLOR_TRANSPARENT_OPACITY;
        const circleRadius = HiddenConnectionVisualizeHandle.HANDLE_RADIUS;

        const handleFillColor = HANDLE_ADD_FILL_COLOR_PRIMARY;
        const startPoint = linePath.points[0];

        this.handleGroup = handleGroup.append("g")
            .attr("opacity", defaultOpacity);

        this.handleGroup.append("title")
            .text(_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_EDITOR_SHOW_HIDDEN_CONNECTION_HANDLE));

        const icon = this.handleGroup.append("circle")
            .classed(DISABLE_ON_NODE_MOVE_POINTER_EVENTS_AUTO_CLASS_NAME, true)
            .attr("cx", startPoint.x + 10)
            .attr("cy", startPoint.y - 10)
            .attr("r", circleRadius)
            .attr("stroke", d3.color(handleFillColor)?.darker(0.5).formatRgb() as string)
            .attr("fill", handleFillColor);
        this.handleGroup.append("text")
            .attr("x", startPoint.x + 7)
            .attr("y", startPoint.y - 7)
            .attr("stroke", "white")
            .attr("fill", "white")
            .attr("font-size", 10)
            .attr("pointer-events", "none")
            .text("+");
        icon
            .on("mouseenter", () => {
                this.handleGroup?.transition()
                    .duration(100)
                    .attr("opacity", 1);
            })
            .on("mouseleave", () => {
                this.handleGroup?.transition()
                    .duration(100)
                    .attr("opacity", defaultOpacity);
            });
        return icon;
    }

    private appendClickHandler(connection: IDiagramConnectionDto, icon: any) {
        const handler: ICustomClickOnlyHandler = {
            useSnappingFunction: false,
            clickThreshold: 2,
            publishClickEvent: (event: DragEvent) => this.publishVisualizeEvent(connection, event)
        };
        (icon.node() as any)[CUSTOM_DRAG_HANDLER] = handler;
    }

    updateScaleFactor(scaleFactor: number) {
        this.scaleFactor = scaleFactor;
        this.applyScaleFactor();
    }

    private applyScaleFactor() {
    }

    private publishVisualizeEvent(connection: IDiagramConnectionDto, event: DragEvent) {
        this.eventManager.publishEvent({
            type: EventType.HIDDEN_CONNECTION_VISUALIZE,
            connection: connection,
        })
    }
}
