import {UserRole} from "../../../../common/access/UserRole";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import {UserDto} from "../../../../common/apis/user/UserDto";

export interface StereotypesPageAccessResolver {
    hasPageAccess(user?: UserDto): boolean
}

class StereotypesPageAccessResolverImpl implements StereotypesPageAccessResolver {

    hasPageAccess(user?: UserDto): boolean {
        return user != null && UserRole.isAtLeast(user, UserRoleType.ROLE_SPRAVCE);
    }

}

const stereotypesPageAccessResolver = new StereotypesPageAccessResolverImpl();
export default stereotypesPageAccessResolver;
