export enum UsersTranslationKey {
    ACTIVATE_USER = "users.activateUser",
    ACTIVATE_USER_CONFIRMATION = "users.activateUser.confirmation",
    ACTIVATE_USER_FAILED = "users.activateUser.failed",
    ADDITIONAL_DATA="users.additionalData",
    ADD_PERMISSION = "users.addPermission",
    ADD_PERMISSION_CHANGE = "users.addPermission.change",
    ADD_PERMISSION_COLLECTION = "users.addPermission.collection",
    ADD_PERMISSION_DIALOG = "users.addPermission.dialog",
    CREATE_PERMISSION_SUCCEEDED = "users.createPermission.success",
    CREATE_PERMISSION_FAILED = "users.createPermission.error",
    UPDATE_PERMISSION_SUCCEEDED = "users.updatePermission.success",
    ADD_PERMISSION_PERMISSION_LEVEL = "users.addPermission.permissionLevel",
    CREATE_USER = "users.createUser",
    CREATE_USER_SUCCESS = "users.createUser.success",
    CREATE_USER_FAILED = "users.createUser.failed",
    CREATE_USER_IS_ACTIVE = "users.createUser.isActive",
    CREATE_USER_PASSWORD = "users.createUser.password",
    CREATE_USER_REPEATED_PASSWORD = "users.createUser.repeatedPassword",
    CREATE_USER_TITLE = "users.createUser.title",
    BASIC_DATA = "users.basicData",
    BASIC_INFO_FAILED = "users.basicInfo.failed",
    BASIC_INFO_LOAD = "users.basicInfo.load",
    CHANGE_PASSWORD = "users.changePassword",
    CHANGE_PASSWORD_FAILED = "users.changePassword.failed",
    CHANGE_PASSWORD_INVALID_PASSWORD = "users.changePassword.invalidPassword",
    CHANGE_PASSWORD_NEW_PASSWORD = "users.changePassword.newPassword",
    CHANGE_PASSWORD_OLD_PASSWORD = "users.changePassword.oldPassword",
    CHANGE_PASSWORD_REPEATED_PASSWORD = "users.changePassword.repeatedPassword",
    CHANGE_PASSWORD_REPEATED_PASSWORD_NOT_MATCH = "users.changePassword.repeatedPasswordNotMatch",
    CHANGE_PASSWORD_SUCCEEDED = "users.changePassword.succeeded",
    CHANGE_PASSWORD_TITLE = "users.changePassword.title",
    DEACTIVATE_USER = "users.deactivateUser",
    DEACTIVATE_USER_CONFIRMATION = "users.deactivateUser.confirmation",
    DEACTIVATE_USER_FAILED = "users.deactivateUser.failed",
    DELETE_PERMISSION = "users.deletePermission.button",
    DELETE_PERMISSION_ALERT_DIALOG_TEXT = "users.deletePermission.failed",
    DELETE_PERMISSION_CONFIRMATION_DIALOG_TEXT = "users.deletePermission.confirmation",
    DELETE_PERMISSION_TITLE = "users.deletePermission.title",
    USER_DETAIL = "users.detail",
    DETAIL_PERMISSIONS = "users.detail.permissions",
    EXPORT_PERMISSIONS = "users.exportPermissions",
    EXPORT_PERMISSIONS_FAILED = "users.exportPermissions.failed",
    IMPORT_PERMISSIONS = "users.importPermissions",
    NOTE = "users.note",
    PERMISSION_EDITOR = "users.permission.editor",
    PERMISSION_READER = "users.permission.reader",
    PROFILE = "users.profile",
    TITLE = "users.title",
    UPDATE_PERMISSION = "users.updatePermission",
    USER_CREATED = "users.userData.created",
    USER_EMAIL = "users.userData.email",
    USER_EMAIL_INVALID = "users.userData.email.invalid",
    USER_EMAIL_ALREADY_IN_USE = "users.userData.email.alreadyInUse",
    USER_FIRST_NAME = "users.userData.firstName",
    USER_FULL_NAME = "users.userData.fullName",
    USER_IS_ACTIVE = "users.userData.active",
    USER_IS_INACTIVE = "users.userData.inactive",
    USER_LAST_LOGIN = "users.userData.lastLogin",
    USER_LAST_NAME = "users.userData.lastName",
    USER_LOGIN = "users.userData.login",
    USER_LOGIN_NAME = "users.userData.loginName",
    USER_PHONE = "users.userData.phone",
    USER_ROLE = "users.userData.role",
    USER_STATE = "users.userData.state",
    USER_ROLE_ADMIN = "users.userRole.admin",
    USER_ROLE_MANAGER = "users.userRole.manager",
    USER_ROLE_OPERATOR = "users.userRole.operator",
    USERS_CREATEUSER_EMAIL_VALIDATION_ERROR = "users.createUser.email.validation.error",
    USERS_CREATEUSER_LOGIN_VALIDATION_ERROR = "users.createUser.login.validation.error",
    USERS_CREATEUSER_PASSWORD_VALIDATION_ERROR = "users.createUser.password.validation.error",
    USERS_CREATEUSER_REPEATED_PASSWORD_VALIDATION_ERROR = "users.createUser.repeated.password.validation.error",
    USERS_GRID_ACTIVE_STATUS_INACTIVE = "users.userGrid.activeStatus.inactive",
    USERS_GRID_ACTIVE_STATUS_UNDEFINED = "users.userGrid.activeStatus.undefined",
    USERS_GRID_USER_ACTIVE_STATUS_ACTIVE = "users.userGrid.activeStatus.active",
}
