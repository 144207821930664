import {Action, Reducer} from "redux";

// types

export enum PersistentStateId {
    // listing pages
    ELEMENTS_PAGE_GRID = "ELEMENTS_PAGE_GRID",
    ELEMENTS_LIST_GRID = "ELEMENTS_LIST_GRID",
    ELEMENTS_PICK_GRID = "ELEMENTS_PICK_GRID",
    DIAGRAMS_PAGE_GRID = "DIAGRAMS_PAGE_GRID",
    DIAGRAMS_PAGE_DIALOGGRID = "DIAGRAMS_PAGE_DIALOGGRID",
    DIAGRAMS_CONTAINING_ITEM_GRID = "DIAGRAMS_CONTAINING_ITEM_GRID",
    USERS_PAGE_GRID = "USERS_PAGE_GRID",
    LABELS_PAGE_GRID = "LABELS_PAGE_GRID",
    STEREOTYPES_PAGE_GRID = "STEREOTYPES_PAGE_GRID",
    COLLECTIONS_PAGE_GRID = "COLLECTIONS_PAGE_GRID",
    QUERIES_PAGE_GRID = "QUERIES_PAGE_GRID",
    VALIDATE_MODEL_PAGE_GRID = "VALIDATE_MODEL_PAGE_GRID",

    // detail pages
    DIAGRAM_DETAIL_PAGE_ATTRIBUTES_GRID = "DIAGRAM_DETAIL_PAGE_ATTRIBUTES_GRID",
    ELEMENT_DETAIL_PAGE_ATTRIBUTES_GRID = "ELEMENT_DETAIL_PAGE_ATTRIBUTES_GRID",
    ELEMENT_DETAIL_PAGE_RELATIONSHIPS_GRID = "ELEMENT_DETAIL_PAGE_RELATIONSHIPS_GRID",
    ELEMENT_DETAIL_PAGE_TRACKER_TASKS_GRID = "ELEMENT_DETAIL_PAGE_TRACKER_TASKS_GRID",
    ELEMENT_DETAIL_PAGE_TRACKER_TASKS_PICK_GRID = "ELEMENT_DETAIL_PAGE_TRACKER_TASKS_PICK_GRID",
    EXPORT_RELATIONSHIP_MATRIX_COLUMN_GRID = "EXPORT_RELATIONSHIP_MATRIX_COLUMN_GRID",
    EXPORT_RELATIONSHIP_MATRIX_ROW_GRID = "EXPORT_RELATIONSHIP_MATRIX_ROW_GRID",
    USER_DETAIL_PAGE_ROLES_GRID = "USER_DETAIL_PAGE_ROLES_GRID",
    EXPORT_MODEL_DIAGRAMS_GRID = "EXPORT_MODEL_DIAGRAMS_GRID",
    EXPORT_MODEL_DOCUMENTATION_GRID = "EXPORT_MODEL_DOCUMENTATION_GRID",
    EXPORT_MODEL_ELEMENTS_GRID = "EXPORT_MODEL_ELEMENTS_GRID",
    MERGE_ELEMENTS_GRID = "MERGE_ELEMENTS_GRID",
    ADD_DIALOG_GRID = "ADD_DIALOG_GRID",
    PRESETS_GRID = "PRESETS_GRID",

    HIERARCHY_TREE_REORDER_DIALOG_GRID = "HIERARCHY_TREE_REORDER_DIALOG_GRID",
}

// Redux actions

export enum GridActionType {
    UPDATE = "ACTION/COMMON/GRID_UPDATE",
    RESET = "ACTION/COMMON/GRID_RESET",
}

export interface IGridAction extends Action<GridActionType> {
    type: GridActionType;
    persistentStateId: PersistentStateId;
    state?: IGridState;
}

// Redux action creators

export function getGridPersistentStateUpdateAction(persistentStateId: PersistentStateId, state: IGridState): IGridAction {
    return {
        type: GridActionType.UPDATE,
        persistentStateId: persistentStateId,
        state: state,
    }
}

export function getGridPersistentStateResetAction(persistentStateId: PersistentStateId): IGridAction {
    return {
        type: GridActionType.UPDATE,
        persistentStateId: persistentStateId,
    }
}

// Redux state

export interface IGridState {
    resourceId: string,
    page: number;
    selectedRowIds: Array<any>;
    checkboxSelection: boolean;
}

export type IGridPersistentState = {
    [prop in keyof typeof PersistentStateId]?: IGridState;
}

const initialState: IGridPersistentState = {};

// Redux redurer

export const gridPersistentStateReducer: Reducer<IGridPersistentState, IGridAction> = (
    state = initialState,
    action
) => {
    if (action.type === GridActionType.UPDATE) {
        return {
            ...state,
            [action.persistentStateId]: action.state,
        };
    } else if (action.type === GridActionType.RESET) {
        return {
            ...state,
            [action.persistentStateId]: {
                resourceId: state[action.persistentStateId]?.resourceId,
                page: 0,
                selectedRowIds: [],
                checkboxSelection: false,
            },
        }
    }
    return state;
};
