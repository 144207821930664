import {UserRoleType} from "./UserRoleType";
import {UserDto} from "../apis/user/UserDto";

export class UserRoleHierarchy {
    private hierarchy: Array<UserRoleType> = [UserRoleType.ROLE_OPERATOR, UserRoleType.ROLE_SPRAVCE, UserRoleType.ROLE_ADMIN];

    public isAtLeast(user: UserDto, requiredRole: UserRoleType) {
        const userRole = user.role;
        const userRoleIndex = this.hierarchy.indexOf(userRole);
        const requiredRoleIndex = this.hierarchy.indexOf(requiredRole);

        return userRoleIndex >= requiredRoleIndex;
    }
}

export default new UserRoleHierarchy();
