import Constants from "../../../../../common/Constants";
import Api from "../../../../../common/Api";
import {HierarchyTreeItemDetailDto} from "./HierarchyTreeItemDetailDto";
import {map} from "rxjs/operators";
import {HierarchyTreeDto, HierarchyTreeInfoDto} from "./HierarchyTreeDto";
import {HierarchyTreeUpdateDto} from "./HierarchyTreeUpdateDto";
import {HierarchyTreeCreateDto} from "./HierarchyTreeCreateDto";


const TREE_ID_PARAM = ":treeId";
const ITEM_ID_PARAM = ":itemId";
const PATH_PARAM = ":path";
const ENDPOINT_URL: string = Constants.REST_API_URL + "/repository/hierarchy-trees/";
const CONCRETE_TREE_URL: string = `${ENDPOINT_URL}${TREE_ID_PARAM}/`;
const TREE_ITEM_URL: string = `${CONCRETE_TREE_URL}items/${ITEM_ID_PARAM}?path=${PATH_PARAM}`;

export interface HierarchyTreeService {
    findRootTrees(): Promise<HierarchyTreeInfoDto[]>;
    findTree(treeId: string): Promise<HierarchyTreeDto>;
    findItemChildren(treeId: string, itemId: string, path: string): Promise<HierarchyTreeItemDetailDto[]>;
    deleteTree(treeId: string): Promise<any>;
    updateTree(treeId: string, treeUpdate: HierarchyTreeUpdateDto<any>): Promise<void>;
    updateTreeOrder(treeId: string, order: number): Promise<void>;
    createTree(treeCreate: HierarchyTreeCreateDto<any>): Promise<string>;
}

class RxJsHierarchyTreeService implements HierarchyTreeService {

    async findRootTrees(): Promise<HierarchyTreeInfoDto[]> {
        const request = {
            url: ENDPOINT_URL,
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as HierarchyTreeInfoDto[])
            )
            .toPromise();
    }

    async findTree(treeId: string): Promise<HierarchyTreeDto> {
        const request = {
            url: Api.replaceUrlParams(CONCRETE_TREE_URL, {[TREE_ID_PARAM]: treeId}),
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as HierarchyTreeDto)
            )
            .toPromise();
    }

    async findItemChildren(treeId: string, itemId: string, path: string): Promise<HierarchyTreeItemDetailDto[]> {
        const request = {
            url: TREE_ITEM_URL
                .replace(TREE_ID_PARAM, treeId)
                .replace(ITEM_ID_PARAM, itemId)
                .replace(PATH_PARAM, path),
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response.children || []) as HierarchyTreeItemDetailDto[])
            )
            .toPromise();
    }

    async deleteTree(treeId: string): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(CONCRETE_TREE_URL, {[TREE_ID_PARAM]: treeId}),
            method: "DELETE",
        };
        return Api.createAjax(request).toPromise();
    }

    async updateTree(treeId: string, treeUpdate: HierarchyTreeUpdateDto<any>): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(CONCRETE_TREE_URL, {[TREE_ID_PARAM]: treeId}),
            method: "PATCH",
            body: treeUpdate
        };
        return Api.createAjax(request).toPromise();
    }

    async updateTreeOrder(treeId: string, order: number): Promise<any> {
        const request = {
            url: `${ENDPOINT_URL}${treeId}/order`,
            method: "PUT",
            body: order
        };
        return Api.createAjax(request).toPromise();
    }

    createTree(treeCreate: HierarchyTreeCreateDto<any>): Promise<string> {
        const request = {
            url: ENDPOINT_URL,
            method: "POST",
            body: treeCreate
        };
        return Api.createAjax(request)
            .pipe(
                map((response) => response.response as string)
            )
            .toPromise();
    }

}

const treeService = new RxJsHierarchyTreeService();
export default treeService;
