import React from "react";
import {HierarchyTreeDataDto, ReferenceTreeDataDto} from "./service/HierarchyTreeDataDto";

export interface HierarchyTreeCrudOps {
    requestToCreateTree: (parentTreeId?: string, parentTreePath?: string) => void;
    requestToUpdateTree: <TreeData extends HierarchyTreeDataDto>(treeId: string, treeData: TreeData, parentTreeId: string | null | undefined, parentTreePath?: string) => void;
    requestToUpdateReference: (treeId: string, treeData: ReferenceTreeDataDto, parentTreeId: string | null | undefined, parentTreePath?: string) => void;
    requestToUpdateOrder: (parentTreeId: string, parentTreePath?: string) => void;
    requestToDeleteTree: (treeId: string, parentTreeId: string | null | undefined, parentTreePath?: string) => void;
    requestToCreateUrlReference(parentTreeId: string, parentTreePath?: string): void;
    requestToCreateElementReportReference(parentTreeId: string, parentTreePath?: string): void;
}

const HierarchyTreeCrudOpsContext = React.createContext<HierarchyTreeCrudOps>({
    requestToCreateTree: () => {},
    requestToUpdateTree: () => {},
    requestToUpdateReference: () => {},
    requestToUpdateOrder: () => {},
    requestToDeleteTree: () => {},
    requestToCreateUrlReference: () => {},
    requestToCreateElementReportReference: () => {},
});
HierarchyTreeCrudOpsContext.displayName = "HierarchyTreeCrudOpsContext";

export default HierarchyTreeCrudOpsContext;