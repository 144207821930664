import HierarchyTreeItem from "./item/HierarchyTreeItem";
import {Box, CircularProgress} from "@mui/material";
import * as React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {HierarchyTreeItemDetailDto} from "./service/HierarchyTreeItemDetailDto";
import hierarchyTreePathParser from "./controller/HierarchyTreePathParser";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinner: {
            display: 'flex',
            justifyContent: "center",
            margin: "5px",
            width: "200px",
        },
    })
);

interface HierarchyTreeItemsProps {
    treeModelItems: HierarchyTreeItemDetailDto[];
    wholeGeneratedTreePath?: string;
    onContextMenu?: (treeItem: HierarchyTreeItemDetailDto, treeItemPath: string, event: React.MouseEvent) => void;
}

export default function HierarchyTreeItems(props: HierarchyTreeItemsProps) {

    const {treeModelItems, wholeGeneratedTreePath} = props;
    const classes = useStyles();

    return <>
        {treeModelItems?.map((treeModelItem) => {
            const treePathId = wholeGeneratedTreePath ? hierarchyTreePathParser.createPath([wholeGeneratedTreePath, treeModelItem.id]) : treeModelItem.id;
            if (treeModelItem.leaf) {
                return <HierarchyTreeItem id={treePathId} treeItemDetail={treeModelItem} key={treePathId} onContextMenu={props.onContextMenu}/>
            } else {
                return <HierarchyTreeItem id={treePathId} treeItemDetail={treeModelItem} key={treePathId} onContextMenu={props.onContextMenu}>
                    {treeModelItem.children && treeModelItem.children.length > 0 &&
                        <HierarchyTreeItems treeModelItems={treeModelItem.children}
                                            wholeGeneratedTreePath={treePathId}
                                            onContextMenu={props.onContextMenu}/>}
                    {(!treeModelItem.children || (treeModelItem.children && treeModelItem.children.length === 0)) && !treeModelItem.leaf &&
                        <Box className={classes.spinner}>
                            <CircularProgress size={20}/>
                        </Box>}
                </HierarchyTreeItem>
            }
        })
        }
    </>
}
