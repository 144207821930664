import {ILabelDto} from "../../../../../common/apis/label/ILabelDto";
import {LabelsService} from "../service/LabelsService";
import {LabelCreateDto} from "../../../../../common/apis/label/LabelCreateDto";
import UserAclDto from "../../../../../common/apis/user/UserAclDto";

export interface LabelsController {
    getAll(): Promise<ILabelDto[]>

    delete(code: string): Promise<any>

    create(labelCreate: LabelCreateDto): Promise<any>

    update(code: string, name: string, description: string): Promise<any>

    canRemoveLabel(acl: UserAclDto, selectedLabel: ILabelDto): boolean

    canUpdateLabel(acl: UserAclDto, selectedLabel: ILabelDto): boolean
}

class LabelsControllerImpl implements LabelsController {

    constructor(private service: LabelsService) {
    }

    canRemoveLabel(acl: UserAclDto, selectedLabel: ILabelDto): boolean {
        return selectedLabel.acl.canRemoveLabel;
    }

    canUpdateLabel(acl: UserAclDto, selectedLabel: ILabelDto): boolean {
        return selectedLabel.acl.canUpdateLabel;
    }

    async getAll(): Promise<ILabelDto[]> {
        return this.service.getAll()
    }

    async delete(code: string): Promise<any> {
        return this.service.delete(code);
    }

    async create(labelCreate: LabelCreateDto): Promise<any> {
        return this.service.create(labelCreate);
    }

    async update(code: string, name: string, description: string): Promise<any> {
        return this.service.updateLabel(code, name, description);
    }
}

export default function constructLabelsController(service: LabelsService): LabelsController {
    return new LabelsControllerImpl(service);
}

