import React, {SyntheticEvent} from "react";
import {
    GridColumnMenuContainer,
    GridColumnMenuProps,
    GridColumnsMenuItem,
    HideGridColMenuItem,
    SortGridMenuItems
} from "@mui/x-data-grid-pro";
import {Divider, MenuItem} from "@mui/material";
import {GridFilterItemProps} from "@mui/x-data-grid/components/menu/columnMenu/GridFilterItemProps";
import {_transl} from "../../store/localization/TranslMessasge";
import {GridColDef} from "@mui/x-data-grid";
import {ExtGridTranslationKey} from "./ExtGridTranslationKey";

function CustomColumnAddMenuItem({onClick}: GridFilterItemProps) {
    return (
        <MenuItem onClick={onClick}>
            {_transl(ExtGridTranslationKey.COLUMN_MENU_ADD_CUSTOM_COLUMN)}
        </MenuItem>
    );
}

function CustomColumnRemoveMenuItem({onClick}: GridFilterItemProps) {
    return (
        <MenuItem onClick={onClick}>
            {_transl(ExtGridTranslationKey.COLUMN_MENU_REMOVE_COLUMN)}
        </MenuItem>
    );
}

interface ExtGridColumnMenuProps extends GridColumnMenuProps {
    onCustomColumnAddClick?: (colDef: GridColDef, event: SyntheticEvent) => void;
    onCustomColumnRemoveClick?: (colDef: GridColDef, event: SyntheticEvent) => void;
}

export const ExtGridColumnMenu = React.forwardRef<
    HTMLUListElement,
    ExtGridColumnMenuProps
    >(function GridColumnMenu(props: ExtGridColumnMenuProps, ref) {
    const {hideMenu, currentColumn, onCustomColumnAddClick, onCustomColumnRemoveClick} = props;

    function onCustomColumnRemoveItemClicked(event: SyntheticEvent) {
        hideMenu(event);
        if (onCustomColumnRemoveClick) {
            onCustomColumnRemoveClick(currentColumn!, event);
        }
    }

    function onCustomColumnAddItemClicked(event: SyntheticEvent) {
        hideMenu(event);
        if (onCustomColumnAddClick) {
            onCustomColumnAddClick(currentColumn!, event);
        }
    }

    function isCustomColumn(colDef?: GridColDef) {
        return colDef && colDef.field.includes("#");
    }

    return (
        <GridColumnMenuContainer ref={ref} {...props}>
            <SortGridMenuItems onClick={hideMenu} column={currentColumn!} />
            <HideGridColMenuItem onClick={hideMenu} column={currentColumn!} />
            {(isCustomColumn(currentColumn) && onCustomColumnRemoveClick) && <CustomColumnRemoveMenuItem onClick={onCustomColumnRemoveItemClicked} column={currentColumn!} /> }
            <Divider/>
            <GridColumnsMenuItem onClick={hideMenu} column={currentColumn!} />
            {onCustomColumnAddClick && <CustomColumnAddMenuItem onClick={onCustomColumnAddItemClicked} column={currentColumn!} /> }
        </GridColumnMenuContainer>
    );
});
