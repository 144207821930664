import {UserRole} from "../../../../common/access/UserRole";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import {UserDto} from "../../../../common/apis/user/UserDto";

export interface CollectionsPageAccessResolver {
    hasPageAccess(user?: UserDto): boolean
}

class CollectionsPageAccessResolverImpl implements CollectionsPageAccessResolver {

    hasPageAccess(user?: UserDto): boolean {
        return user != null && UserRole.isAtLeast(user, UserRoleType.ROLE_SPRAVCE);
    }

}

const collectionsPageAccessResolver = new CollectionsPageAccessResolverImpl();
export default collectionsPageAccessResolver;
