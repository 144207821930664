import React, {useRef, useState} from "react";
import {UsersTranslationKey} from "./UsersTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {CancelButton} from "../../../../components/button/CancelButton";
import userService from "../../../../common/apis/UserService";
import Snackbar from "../snackbar/Snackbar";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {ChangePasswordButton} from "../../../../components/button/ChangePasswordButton";
import Grid from "../../../../components/dialogs/Grid";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import PasswordTextField from "../../../../components/fields/textfield/PasswordTextField";
import constructUserDetailController, {UserDetailController} from "../userdetail/controller/UserDetailController";
import {CommonTranslation} from "../CommonTranslation";

interface ChangeUserPasswordDialogProps {
    open: boolean,
    userLogin: string,
    onClose: () => void,
    onPasswordChangeSuccess?: () => void,
}

export function ChangeUserPasswordDialog(props: ChangeUserPasswordDialogProps) {
    const {userLogin, onPasswordChangeSuccess, onClose, open} = props;

    const userController = useRef<UserDetailController>(constructUserDetailController(userService));
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [repeatedPassword, setRepeatedPassword] = useState<string>("");
    const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState<string>();
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState<string>();
    const [repeatedPasswordErrorMessage, setRepeatedPasswordErrorMessage] = useState<string>();

    function setAllStatesToDefault() {
        setOldPassword("");
        setNewPassword("");
        setRepeatedPassword("");
        setNewPasswordErrorMessage(undefined);
        setRepeatedPasswordErrorMessage(undefined);
        setOldPasswordErrorMessage(undefined);
    }

    function validate(): boolean {
        let isValid = true;

        if (oldPassword.length === 0) {
            setOldPasswordErrorMessage(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isValid = false;
        } else {
            setOldPasswordErrorMessage(undefined);
        }
        if (newPassword.length < 4 || newPassword.length > 128) {
            setNewPasswordErrorMessage(_transl(UsersTranslationKey.CHANGE_PASSWORD_INVALID_PASSWORD));
            isValid = false;
        } else {
            setNewPasswordErrorMessage(undefined);
        }
        if (newPassword.normalize("NFC") !== repeatedPassword.normalize("NFC")) {
            setRepeatedPasswordErrorMessage(_transl(UsersTranslationKey.CHANGE_PASSWORD_REPEATED_PASSWORD_NOT_MATCH));
            isValid = false;
        } else {
            setRepeatedPasswordErrorMessage(undefined);
        }

        return isValid;
    }

    function saveChanges() {
        setOldPassword(oldPassword.trim());
        setNewPassword(newPassword.trim());
        setRepeatedPassword(repeatedPassword.trim());

        if (validate()) {
            (async () => {
                try {
                    await userController.current.changePassword(userLogin, oldPassword, newPassword);
                    Snackbar.success(_transl(UsersTranslationKey.CHANGE_PASSWORD_SUCCEEDED));
                    onDialogClosed();
                    onPasswordChangeSuccess && onPasswordChangeSuccess();
                } catch (error) {
                    Snackbar.error(_transl(UsersTranslationKey.CHANGE_PASSWORD_FAILED));
                }
            })();
        }
    }

    function onDialogClosed() {
        setAllStatesToDefault();
        onClose();
    }

    return (
        <Dialog open={open} onClose={onDialogClosed}>
            <DialogTitle title={_transl(UsersTranslationKey.CHANGE_PASSWORD_TITLE)}
                         onDialogClosed={onDialogClosed}
            />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <PasswordTextField
                            id="change-user-pwd-dialog-old-pwd-field"
                            label={_transl(UsersTranslationKey.CHANGE_PASSWORD_OLD_PASSWORD)}
                            errorMessage={oldPasswordErrorMessage}
                            onChange={(value) => setOldPassword(value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordTextField
                            id="change-user-pwd-dialog-new-pwd-field"
                            label={_transl(UsersTranslationKey.CHANGE_PASSWORD_NEW_PASSWORD)}
                            errorMessage={newPasswordErrorMessage}
                            onChange={(value) => setNewPassword(value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordTextField
                            id="change-user-pwd-dialog-repeated-pwd-field"
                            label={_transl(UsersTranslationKey.CHANGE_PASSWORD_REPEATED_PASSWORD)}
                            errorMessage={repeatedPasswordErrorMessage}
                            onChange={(value) => setRepeatedPassword(value)}
                            required
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ChangePasswordButton onClick={saveChanges}/>
                <CancelButton onClick={onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );
}
