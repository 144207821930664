import {DiagramInfoDto} from "../../../../../common/apis/diagram/DiagramInfoDto";
import Api from "../../../../../common/Api";
import {map} from "rxjs/operators";
import Constants from "../../../../../common/Constants";
import DiagramContentFilterDto from "./DiagramContentFilterDto";
import {IPropertyDefinition, IPropertyDto} from "../../../../../common/apis/CommonTypes";
import {DiagramDto} from "../../../../../common/apis/diagram/DiagramDto";

const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/repository/diagrams";
const SEARCH_BY_CONTENT_URL: string = `${ENDPOINT_URL}:searchByContent`;


export interface DiagramService {
    searchByContent(filter: DiagramContentFilterDto): Promise<DiagramInfoDto[]>;
    findDiagramById(diagramId: string): Promise<DiagramDto>;
    createDiagram(name: string, description: string | null, elementIds: string[], collectionCodes: string[] | null): Promise<string>;
    duplicateDiagram(identifier: string, name: string, collectionCodes: string[], duplicateElementsAndRelationships: boolean): Promise<string>;

    getAllDiagramProperties(diagramId: string): Promise<IPropertyDto[]>;
    createDiagramProperty(diagramId: string, name: string, type: string, value: string, propDefIdentifier?: string): Promise<string>;
    updateDiagramProperty(diagramId: string, propertyDefinitionId: string, value: string): Promise<any>;
    deleteDiagramProperty(diagramId: string, propertyDefinitionId: string): Promise<any>;
    findAllPropertyDefinitions(): Promise<Array<IPropertyDefinition>>,

}

class RxJsDiagramService implements DiagramService {

    async searchByContent(filter: DiagramContentFilterDto): Promise<DiagramInfoDto[]> {
        const request = {
            url: SEARCH_BY_CONTENT_URL,
            method: "POST",
            body: filter,
        };
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response || []) as DiagramInfoDto[])
            )
            .toPromise();
    }

    findDiagramById(diagramId: string): Promise<DiagramDto> {
        const request = {
            url: `${ENDPOINT_URL}/${diagramId}`,
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response || []) as DiagramDto)
            )
            .toPromise();
    }

    createDiagram(name: string, description: string | null, elementIds: string[], collectionCodes: string[] | null): Promise<string> {
        const request = {
            url: ENDPOINT_URL + "/",
            method: "POST",
            body: {
                name: name,
                description: description,
                identifiers: elementIds,
                collectionCodes: collectionCodes
            },
        };
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response) as string)
            )
            .toPromise();
    }

    duplicateDiagram(identifier: string, name: string, collectionCodes: string[], duplicateElementsAndRelationships: boolean): Promise<string> {
        const request = {
            url: ENDPOINT_URL + `/${identifier}:duplicate`,
            method: "POST",
            body: {
                name: name,
                duplicateElementsAndRelationships: duplicateElementsAndRelationships,
                collectionCodes: collectionCodes,
            },
        };
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response) as string)
            )
            .toPromise();
    }

    getAllDiagramProperties(diagramId: string): Promise<IPropertyDto[]> {
        const request = {
            url: `${ENDPOINT_URL}/${diagramId}/properties`,
            method: "GET",
        }
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response || []) as IPropertyDto[])
            )
            .toPromise();
    }

    createDiagramProperty(diagramId: string, name: string, type: string, value: string, propDefIdentifier?: string): Promise<string> {
        const request = {
            url: `${ENDPOINT_URL}/${diagramId}/properties`,
            method: "POST",
            body: {
                name: name,
                type: type,
                value: value,
                propDefIdentifier: propDefIdentifier,
            },
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as string)
            )
            .toPromise();
    }

    updateDiagramProperty(diagramId: string, propertyDefinitionId: string, value: string): Promise<any> {
        const request = {
            url: `${ENDPOINT_URL}/${diagramId}/properties/${propertyDefinitionId}`,
            method: "PUT",
            body: {
                value: value,
            },
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response)
            )
            .toPromise();
    }

    deleteDiagramProperty(diagramId: string, propertyDefinitionId: string): Promise<any> {
        const request = {
            url: `${ENDPOINT_URL}/${diagramId}/properties/${propertyDefinitionId}`,
            method: "DELETE",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response))
            )
            .toPromise();
    }

    async findAllPropertyDefinitions(): Promise<Array<IPropertyDefinition>> {
        return Api.createAjax({
            url: Constants.API_HOST + "/rest-api/diagrams/propertyDefinitions",
            method: "GET",
        })
            .pipe(map(response => response.response as IPropertyDefinition[]))
            .toPromise();
    }

}

export default new RxJsDiagramService() as DiagramService;
