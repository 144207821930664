import {MenuItem} from "../../../../components/menu/MenuItem";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {Add, Delete, Edit, Link, Reorder, ViewList} from "@mui/icons-material";
import React, {useContext} from "react";
import {PopoverPosition} from "@mui/material/Popover/Popover";
import {CommonTranslation} from "../CommonTranslation";
import {ContextMenu} from "../../../../diagram/editor/components/menu/ContextMenu";
import Divider from "@mui/material/Divider";
import HierarchyTreeCrudOpsContext from "./HierarchyTreeCrudOpsContext";
import {HierarchyTreeTranslationKey} from "./HierarchyTreeTranslationKey";
import {HierarchyTreeDataType} from "./service/HierarchyTreeDataType";
import {HierarchyTreeInfoDto} from "./service/HierarchyTreeDto";
import {ReferenceTreeDataDto} from "./service/HierarchyTreeDataDto";

export interface HierarchyTreeContextMenuProps {
    open: boolean;
    anchorPosition?: PopoverPosition;
    tree?: HierarchyTreeInfoDto;
    onClose: () => void;
}

export default function HierarchyTreeContextMenu(props: HierarchyTreeContextMenuProps) {
    const {open, anchorPosition, tree, onClose} = props;

    const crudOps = useContext(HierarchyTreeCrudOpsContext);

    const isFolder = tree?.data.type === HierarchyTreeDataType.FOLDER;
    const isReference = tree?.data.type === HierarchyTreeDataType.REFERENCE;
    const canUpdate = tree?.acl.canUpdate ?? false;
    const canDelete = tree?.acl.canDelete ?? false;

    function onEditClick() {
        if (isReference) {
            crudOps.requestToUpdateReference(tree!.id, tree!.data as ReferenceTreeDataDto, tree!.parentId);
        } else {
            crudOps.requestToUpdateTree(tree!.id, tree!.data, tree!.parentId);
        }
        onClose();
    }

    return (
        <ContextMenu open={open}
                     onClose={onClose}
                     anchorPosition={anchorPosition}>
            <MenuItem label={_transl(HierarchyTreeTranslationKey.TREE_ITEM_CONTEXT_MENU_ADD_STRUCTURE)}
                      icon={<Add/>}
                      disabled={!(canUpdate && isFolder)}
                      onClick={() => {
                          crudOps.requestToCreateTree(tree!.id, "");
                          onClose();
                      }} />
            <MenuItem label={_transl(HierarchyTreeTranslationKey.TREE_ITEM_CONTEXT_MENU_ADD_LINK)}
                      icon={<Link/>}
                      disabled={!(canUpdate && isFolder)}
                      onClick={() => {
                          crudOps.requestToCreateUrlReference(tree!.id, "");
                          onClose();
                      }} />
            <MenuItem label={_transl(HierarchyTreeTranslationKey.TREE_ITEM_CONTEXT_MENU_ADD_ELEMENT_REPORT_REFERENCE)}
                      icon={<ViewList/>}
                      disabled={!(canUpdate && isFolder)}
                      onClick={() => {
                          crudOps.requestToCreateElementReportReference(tree!.id, "");
                          onClose();
                      }} />
            <Divider/>
            <MenuItem label={_transl(CommonTranslation.EDIT)}
                      icon={<Edit/>}
                      disabled={!canUpdate}
                      onClick={onEditClick} />
            <MenuItem label={_transl(CommonTranslation.REMOVE)}
                      icon={<Delete/>}
                      disabled={!canDelete}
                      onClick={() => {
                          crudOps.requestToDeleteTree(tree!.id, tree!.parentId);
                          onClose();
                      }} />
            <Divider/>
            <MenuItem label={_transl(HierarchyTreeTranslationKey.TREE_ITEM_CONTEXT_MENU_REORDER_ITEMS)}
                      icon={<Reorder/>}
                      disabled={!(canUpdate && isFolder)}
                      onClick={() => {
                          crudOps.requestToUpdateOrder(tree!.id, "");
                          onClose();
                      }} />
        </ContextMenu>
    );
}