export enum CreateRelationshipTranslationKey {
    COMMON_ELEMENT = "relationship.create.commonElement",
    COMMON_ELEMENT_IS_SOURCE = "relationship.create.commonElementIsSource",
    COMMON_ELEMENT_IS_TARGET = "relationship.create.commonElementIsTarget",
    FIND_COMMON_ELEMENT = "relationship.create.findCommonElement",
    NOT_ALLOWED_WARNING_MESSAGE = "relationship.create.notAllowedWarning.message",
    NOT_ALLOWED_WARNING_TITLE = "relationship.create.notAllowedWarning.title",
    RELATIONSHIP_DIRECTION = "relationship.create.relationshipDirection",
    RELATIONSHIP_TYPE = "relationship.create.relationshipType",
    DIALOG_CREATE_RELATIONSHIP_DIRECTION_SUCCEEDED = "relationship.create.relationshipDirection.succeeded",
    DIALOG_CREATE_RELATIONSHIP_DIRECTION_NOTSUCCEEDED  = "relationship.create.relationshipDirection.notSucceeded",
}
