import {IDiagramConnectionDto} from "../../../apis/diagram/IDiagramConnectionDto";
import {ElementDto} from "../../../apis/element/ElementDto";
import {RelationshipDto} from "../../../apis/relationship/RelationshipDto";
import {DiagramNode} from "../../model/Model";

export default class CacheLocator {
    
    private nodeIdToNodeCache: Map<string, DiagramNode> = new Map();
    private connectionIdToConnectionCache: Map<string, IDiagramConnectionDto> = new Map();
    private nodeIdToChildNodesCache: Map<string, Array<DiagramNode>> = new Map();
    private nodeIdToParentNodeCache: Map<string, DiagramNode | undefined> = new Map();
    private elementIdToElementCache: Map<string, ElementDto> = new Map();
    private relationshipIdToRelationshipCache: Map<string, RelationshipDto> = new Map();

    getNodeIdToNodeCache(): Map<string, DiagramNode> {
        return this.nodeIdToNodeCache;
    }

    getConnectionIdToConnectionCache(): Map<string, IDiagramConnectionDto> {
        return this.connectionIdToConnectionCache;
    }

    getNodeIdToChildNodesCache(): Map<string, Array<DiagramNode>> {
        return this.nodeIdToChildNodesCache;
    }

    getNodeIdToParentNodeCache(): Map<string, DiagramNode | undefined> {
        return this.nodeIdToParentNodeCache;
    }

    getElementIdToElementCache(): Map<string, ElementDto> {
        return this.elementIdToElementCache;
    }

    getRelationshipIdToRelationshipCache(): Map<string, RelationshipDto> {
        return this.relationshipIdToRelationshipCache;
    }
    
}
