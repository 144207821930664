import {GridColDef} from "@mui/x-data-grid";
import {TranslationFunction} from "../../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../../../diagrams/DiagramTranslationKey";
import {Tooltip} from "@mui/material";
import React from "react";

export function createDiagramPanelColDef(_transl: TranslationFunction): GridColDef[] {
    return [
        {
            field: 'identifier', headerName: _transl(DiagramTranslationKey.RENDER_MODE_IDENTIFIER),
            headerClassName: 'datagrid-column', width: 350, renderCell: (params: any) => getTooltip(params)
        },
        {
            field: 'name', headerName: _transl(DiagramTranslationKey.RENDER_MODE_NAME),
            headerClassName: 'datagrid-column', width: 300, renderCell: (params: any) => getTooltip(params)
        },
        {
            field: 'description', headerName: _transl(DiagramTranslationKey.RENDER_MODE_DESCRIPTION),
            headerClassName: 'datagrid-column', width: 300, renderCell: (params: any) => getTooltip(params)
        }
    ]
}

function getTooltip(params: any) {
    return <Tooltip title={params.value || ""}>
        <span>{params.value}</span>
    </Tooltip>;
}
