import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React, {useState} from "react";
import {Button, CircularProgress} from "@mui/material";
import PickList from "../picklist/PickList";
import {CancelButton} from "../button/CancelButton";
import {_transl} from "../../store/localization/TranslMessasge";
import {PickListTranslationKey} from "../picklist/PickListTranslationKey";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import Dialog from "./Dialog";
import DialogTitle from "./DialogTitle";
import DialogContent from "./DialogContent";
import DialogActions from "./DialogActions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinner: {
            color: "white",
        },
    })
);

interface Props<T> {
    // state
    isOpened: boolean,
    showInProgress?: boolean,
    showError?: boolean,

    // options
    initialAvailableOptions: Array<T>,
    initialPickedOptions: Array<T>,

    // option rendering
    getOptionText: (item: T) => string,
    optionContentRenderer?: (item: T) => JSX.Element,
    isAvailableOptionDisabled?: (item: T) => boolean,
    isPickedOptionDisabled?: (item: T) => boolean,

    // titles
    dialogTitle?: string,
    availableOptionsTitle?: string,
    pickedOptionsTitle?: string,

    // callbacks
    onSaveChanges: (options: Array<T>) => void,
    onDialogClosed: () => void,

    // other
    role?: string,
    saveChangesButtonLabel?: string,
}

export default function PickListDialog<T>(props: Props<T>) {

    const [pickedOptions, setPickedOptions] = useState<Array<T>>(props.initialPickedOptions);
    const classes = useStyles();
    const resolvedDialogTitle = props.dialogTitle || _transl(PickListTranslationKey.ITEMS_ASSIGNMENT);
    const resolvedAvailableOptionsTitle = props.availableOptionsTitle || _transl(PickListTranslationKey.AVAILABLE_ITEMS);
    const resolvedPickedOptionsTitle = props.pickedOptionsTitle || _transl(PickListTranslationKey.ASSIGNED_ITEMS);
    const saveButtonLabel = props.saveChangesButtonLabel || _transl(CommonTranslation.SAVE);

    return (
        <Dialog
            open={props.isOpened}
            role={props.role}
            aria-labelledby="picklist-dialog-title"
            onClose={props.onDialogClosed}
            fullWidth={true}
            maxWidth={'md'}
        >

            <DialogTitle id="picklist-dialog-title"
                         title={resolvedDialogTitle}
                         onDialogClosed={props.onDialogClosed}/>

            <DialogContent>
                <PickList
                    initialAvailableOptions={props.initialAvailableOptions}
                    initialPickedOptions={props.initialPickedOptions}
                    getOptionText={props.getOptionText as (item: unknown) => string}
                    optionContentRenderer={props.optionContentRenderer as (item: unknown) => JSX.Element}
                    isAvailableOptionDisabled={props.isAvailableOptionDisabled}
                    isPickedOptionDisabled={props.isPickedOptionDisabled}
                    availableOptionsTitle={resolvedAvailableOptionsTitle}
                    pickedOptionsTitle={resolvedPickedOptionsTitle}
                    onPickedOptionsUpdated={(pickedOptions) => setPickedOptions(pickedOptions as Array<T>)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onSaveChanges(pickedOptions)}
                        variant={"contained"}
                        color="primary"
                        endIcon={props.showInProgress ?
                            <CircularProgress className={classes.spinner} size={14}/> : undefined}
                        data-testid={"pick-list-dialog-ok-action"}
                >
                    {saveButtonLabel}
                </Button>
                <CancelButton onClick={props.onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );

}
