import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogContentText from "../../../../components/dialogs/DialogContentText";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {ButtonLayout, NegativeButtons, PositiveButtons} from "../../../../components/button/ButtonLayout";
import {YesButton} from "../../../../components/button/YesButton";
import {NoButton} from "../../../../components/button/NoButton";
import React from "react";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";

export interface ConcurrentUpdateDialogProps {
    open: boolean,
    onConfirm: (e: any) => void,
    onReject: (e: any) => void,
    conflictingDiagramNames: string[],
    isModal?: boolean,
}

export default function ConcurrentUpdateDialog(props: ConcurrentUpdateDialogProps) {

    const {open, onConfirm, onReject, conflictingDiagramNames} = props;

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if (props.isModal && reason) {
                    return;
                }
                onReject(e);
            }}
            aria-labelledby="yes-no-dialog"
        >
            <DialogTitle title={_transl(ImportTranslationKey.IGNORE_CONCURRENT_UPDATE_DIALOG_TITLE)} onDialogClosed={onReject}/>
            <DialogContent>
                <DialogContentText>
                    {_transl(ImportTranslationKey.IGNORE_CONCURRENT_UPDATE_DIALOG_TEXT)}
                </DialogContentText>
                <DialogContentText>
                    {_transl(ImportTranslationKey.CHANGED_DIAGRAMS_TITLE)}
                </DialogContentText>
                <List>
                    {conflictingDiagramNames.map((name, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={name} style={{color: 'gray'}}/>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <ButtonLayout>
                    <PositiveButtons>
                        <YesButton onClick={onConfirm} variant="contained"/>
                    </PositiveButtons>
                    <NegativeButtons>
                        <NoButton onClick={onReject} variant="outlined"/>
                    </NegativeButtons>
                </ButtonLayout>
            </DialogActions>
        </Dialog>
    )
}