import {DataSubformValidator} from "../DataSubformValidator";
import {FolderTreeDataDto} from "../../../../service/HierarchyTreeDataDto";
import {ValidationError, ValidationResult} from "../../../../../../../../common/validation/ValidationResult";

export class FolderSubformValidator implements DataSubformValidator {
    validate(data: FolderTreeDataDto): ValidationResult {
        const errors: ValidationError[] = [];

        return {
            errors: errors
        };
    }
}
