export enum ExportTranslationKey {
    TITLE = "tools.export.title",
    TYPE_LABEL = "tools.export.typeLabel",
    TYPE_DESCRIPTION = "tools.export.typeDescription",
    EXPORT_CONFIGURATION_LABEL = "tools.export.configurationLabel",
    CHANGE_EXPORT_TYPE = "tools.export.type.changeExportType",
    TYPE_RELATIONSHIP_MATRIX_LABEL = "tools.export.type.relationshipMatrix.label",
    TYPE_RELATIONSHIP_MATRIX_DESCRIPTION = "tools.export.type.relationshipMatrix.description",
    TYPE_EXCHANGE_FORMAT_LABEL = "tools.export.type.exchangeFormat.label",
    TYPE_EXCHANGE_FORMAT_DESCRIPTION = "tools.export.type.exchangeFormat.description",
    TYPE_EXPORT_ELEMENTS_WIZARD_TYPE_LABEL = "tools.export.type.elements.label",
    TYPE_EXPORT_ELEMENTS_WIZARD_TYPE_DESCRIPTION = "tools.export.type.elements.description",
    ELEMENTS_LAST_STEP_BUTTON_LABEL = "tools.export.elements.lastStepButtonLabel",
    ELEMENTS_LAST_STEP_FAILED_TEXT = "tools.export.elements.lastStepActionFailedText",
    ELEMENTS_LAST_STEP_IN_PROGRESS_TEXT = "tools.export.elements.lastStepActionInProgressText",
    ELEMENTS_LAST_STEP_LABEL = "tools.export.elements.lastStepLabel",
    ELEMENTS_LAST_STEP_SUCCESSFUL_TEXT = "tools.export.elements.lastStepActionSuccessfulText",
    RELATIONSHIP_MATRIX_GRID_BUTTON_ADD = "tools.export.relationshipMatrix.grid.button.add",
    RELATIONSHIP_MATRIX_GRID_BUTTON_REMOVE = "tools.export.relationshipMatrix.grid.button.remove",
    RELATIONSHIP_MATRIX_LAST_STEP_LABEL = "tools.export.relationshipMatrix.lastStepLabel",
    RELATIONSHIP_MATRIX_LAST_STEP_BUTTON_LABEL = "tools.export.relationshipMatrix.lastStepButtonLabel",
    RELATIONSHIP_MATRIX_LAST_STEP_IN_PROGRESS_TEXT = "tools.export.relationshipMatrix.lastStepActionInProgressText",
    RELATIONSHIP_MATRIX_LAST_STEP_SUCCESSFUL_TEXT = "tools.export.relationshipMatrix.lastStepActionSuccessfulText",
    RELATIONSHIP_MATRIX_LAST_STEP_FAILED_TEXT = "tools.export.relationshipMatrix.lastStepActionFailedText",
    RELATIONSHIP_MATRIX_STEP_ELEMENTS_IN_COLUMNS_LABEL = "tools.export.relationshipMatrix.step.elementsInColumns.label",
    RELATIONSHIP_MATRIX_STEP_ELEMENTS_IN_COLUMNS_DESCRIPTION = "tools.export.relationshipMatrix.step.elementsInColumns.description",
    RELATIONSHIP_MATRIX_STEP_ELEMENTS_IN_ROWS_LABEL = "tools.export.relationshipMatrix.step.elementsInRows.label",
    RELATIONSHIP_MATRIX_STEP_ELEMENTS_IN_ROWS_DESCRIPTION = "tools.export.relationshipMatrix.step.elementsInRows.description",
    RELATIONSHIP_MATRIX_STEP_OTHER_OPTIONS_LABEL = "tools.export.relationshipMatrix.step.otherOptions.label",
    RELATIONSHIP_MATRIX_STEP_OTHER_OPTIONS_DESCRIPTION = "tools.export.relationshipMatrix.step.otherOptions.description",
    RELATIONSHIP_MATRIX_STEP_OTHER_OPTIONS_OUTPUT_FORMAT = "tools.export.relationshipMatrix.step.otherOptions.outputFormat",
    MODEL_LAST_STEP_LABEL = "tools.export.model.lastStepLabel",
    MODEL_LAST_STEP_BUTTON_LABEL = "tools.export.model.lastStepButtonLabel",
    MODEL_LAST_STEP_IN_PROGRESS_TEXT = "tools.export.model.lastStepActionInProgressText",
    MODEL_LAST_STEP_SUCCESSFUL_TEXT = "tools.export.model.lastStepActionSuccessfulText",
    MODEL_LAST_STEP_FAILED_TEXT = "tools.export.model.lastStepActionFailedText",
    MODEL_STEP_DIAGRAMSSELECTION_LABEL = "tools.export.model.step.diagramsSelection.label",
    MODEL_STEP_DIAGRAMSSELECTION_DESCRIPTION = "tools.export.model.step.diagramsSelection.description",
    MODEL_STEP_ELEMENTSSELECTION_LABEL = "tools.export.model.step.elementsSelection.label",
    MODEL_STEP_ELEMENTSSELECTION_MENU_LABEL = "tools.export.model.step.elementsSelection.menu.label",
    MODEL_STEP_ELEMENTSSELECTION_MENU_DIAGRAMS_RELATED_ONLY = "tools.export.model.step.elementsSelection.menu.diagramsRelatedOnly",
    MODEL_STEP_ELEMENTSSELECTION_MENU_MANUALLY_PICKED = "tools.export.model.step.elementsSelection.menu.manuallyPicked",
    MODEL_STEP_ELEMENTSSELECTION_MENU_MANUALLY_PICKED_DIAGRAMS = "tools.export.model.step.elementsSelection.menu.manuallyPickedDiagrams",
    MODEL_STEP_ELEMENTSSELECTION_MENU_ALL = "tools.export.model.step.elementsSelection.menu.all",
    MODEL_STEP_ELEMENTSSELECTION_MENU_ALL_DIAGRAMS = "tools.export.model.step.elementsSelection.menu.allDiagrams",
    MODEL_STEP_ELEMENTSSELECTION_ELEMENTS = "tools.export.model.step.elementsSelection.elements",
    MODEL_STEP_ELEMENTSSELECTION_ELEMENTS_DIAGRAMS = "tools.export.model.step.elementsSelection.elementsDiagrams",
    MODEL_ELEMENTSGRID_BUTTON_ADD = "tools.export.model.elementsgrid.button.add",
    MODEL_ELEMENTSGRID_BUTTON_REMOVE = "tools.export.model.elementsgrid.button.remove",
    MODEL_DIAGRAMSGRID_BUTTON_ADD = "tools.export.model.diagramsgrid.button.add",
    MODEL_DIAGRAMSGRID_BUTTON_REMOVE = "tools.export.model.diagramsgrid.button.remove",
}