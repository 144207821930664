import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Typography from '@mui/material/Typography';
import {MenuItem, Select, Step, StepContent, StepLabel, Stepper, Button} from '@mui/material';
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {Configuration} from "./Configuration";
import {ConfigurationId} from "./Configuration";
import {ImportConfigurationNullable} from "../../../../common/Types";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import {UserDto} from "../../../../common/apis/user/UserDto";

type ConfigurationNullable = Configuration | null;
const INITIAL_STEP = 0;
const CONFIGURATION_STEP = 1;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        importTypeSelect: {
            minWidth: "12em",
        },
        importTypeBlock: {
            "& .MuiButton-label": {
                fontSize: "0.7em",
            },
            display: "inline-block",
            fontSize: "0.9em",
            marginLeft: theme.spacing(2),
        },
        importTypeDiv: {
            paddingTop: theme.spacing(2),
        },
        stepperPage: {
            display: "flex",
        },
        stepperDiv: {
            "& .MuiStepper-root": {
                padding: 0,
                borderRight: "1px solid lightgray",
            },
            display: "inline-block",
            minWidth: "23em",
        },
        stepContentDiv: {
            display: "inline-block",
            paddingLeft: theme.spacing(CommonCssStyles.PAGE_SEGMENT_PADDING),
            flexGrow: 1,
        }
    })
);

interface Props {
    initialConfigurationId?: ConfigurationId,
    closeDialog?: () => void,
}

function getInitialConfiguration(props: Props): ImportConfigurationNullable {
    const initialConfigurationId = props.initialConfigurationId;
    let initialConfiguration = null;
    if (initialConfigurationId != null) {
        initialConfiguration = Configuration.findById(Configuration.getConfigurations(), initialConfigurationId);
    }
    return initialConfiguration;
}

export default function ImportPanel(props: Props) {

    const initialConfiguration = getInitialConfiguration(props);

    const [activeStep, setActiveStep] = useState<number>(initialConfiguration ? CONFIGURATION_STEP : INITIAL_STEP);
    const [selectedConfiguration, setSelectedConfiguration] = useState<ConfigurationNullable>(initialConfiguration);

    const user = useSelector((state: IApplicationState) => state.user.userData);

    const classes = useStyles();


    function updateSelectedConfiguration(configurationId: number) {
        const configuration: ConfigurationNullable = configurationId === INITIAL_STEP ? null : Configuration.getConfigurations()[configurationId - 1];
        const activeStep = configuration !== null ? CONFIGURATION_STEP : INITIAL_STEP;

        setActiveStep(activeStep);
        setSelectedConfiguration(configuration);
    }

    function createConfigurationId(index: number): number {
        return index + 1;
    }

    const pageTitle = selectedConfiguration != null ? selectedConfiguration.pageLabel : _transl(ImportTranslationKey.TITLE);

    function buildMenuItems(configurations: Configuration[]): JSX.Element[] {
        const configurationIndexesMap = new Map<ConfigurationId, number>();
        configurations.forEach((configuration, index) => {
            configurationIndexesMap.set(configuration.id, index);
        });

        return configurations
            .filter((config) => config.isVisible(user as UserDto))
            .map((config) =>
                <MenuItem value={createConfigurationId(configurationIndexesMap.get(config.id)!)} key={config.label}>
                    {config.label}
                </MenuItem>);
    }

    function resetImport() {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            setActiveStep(INITIAL_STEP);
        }
    }

    return (
        <div className={classes.stepperPage}>
            <div className={classes.stepperDiv}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={"import-type"}>
                        <StepLabel>
                            {_transl(ImportTranslationKey.TYPE_LABEL)}
                        </StepLabel>
                        <StepContent>
                            {activeStep === INITIAL_STEP && <div>
                                <Typography style={{fontSize: "0.9em"}}>{_transl(ImportTranslationKey.TYPE_TYPOGRAPHY)}</Typography>
                                <div className={classes.importTypeDiv}>
                                    <Select
                                        id="import-type-select"
                                        value={INITIAL_STEP}
                                        className={classes.importTypeSelect}
                                        onChange={(event) => updateSelectedConfiguration(event.target.value as number)}
                                    >
                                        <MenuItem value={INITIAL_STEP} key={"initial_step"}>
                                            <span>&nbsp;</span>
                                        </MenuItem>
                                        {
                                            buildMenuItems(Configuration.getConfigurations())
                                        }
                                    </Select>
                                </div>
                            </div>}
                        </StepContent>
                    </Step>
                    <Step key={"import-configuration"}>
                        <StepLabel>
                            {_transl(ImportTranslationKey.IMPORT_CONFIGURATION_LABEL)}
                        </StepLabel>
                        <StepContent>
                                    <span>
                                        <Typography style={{fontSize: "0.9em"}} display={'inline'}>{pageTitle}</Typography>
                                        <span className={classes.importTypeBlock}>
                                            <Button variant={"contained"}
                                                    size={"small"}
                                                    style={{fontSize: "inherit"}}
                                                    color={"primary"}
                                                    onClick={() => updateSelectedConfiguration(INITIAL_STEP)}>{_transl(ImportTranslationKey.CHANGE_IMPORT_TYPE)}</Button>
                                        </span>
                                    </span>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
            <div className={classes.stepContentDiv}>
                {activeStep !== INITIAL_STEP && <div>
                    {selectedConfiguration?.getWizard(() => resetImport())}
                </div>}
            </div>
        </div>
    );
}
