import {ActionButtonType} from "./GridAction";
import {PersistentStateId} from "../../store/common/Grid";

export default class ActionButtonUtils {

    public static createButtonId(id: string, type: ActionButtonType, gridPersistentStateId: PersistentStateId) {
        return `ACTION_BUTTON_${gridPersistentStateId}_${type}_${id}`;
    }

    public static clickOnImmediateButton(id: string, gridPersistentStateId: PersistentStateId) {
        const element = document.getElementById(ActionButtonUtils.createButtonId(id, ActionButtonType.IMMEDIATE, gridPersistentStateId));
        element && element.click();
    }
}
