import Constants from "../../Constants";
import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../../Api";

export default class Labels {

    public static LABEL_CODE_PARAM = ":code";
    public static ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/labels";
    public static PUT_UPDATE_LABEL_URL : string = `${Labels.ENDPOINT_URL}/${Labels.LABEL_CODE_PARAM}`;
    public static DELETE_LABEL_URL: string = `${Labels.ENDPOINT_URL}/${Labels.LABEL_CODE_PARAM}`;

    getAll(): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Labels.ENDPOINT_URL + "/",
            method: "GET",
        });
    }

    deleteLabel(id: string): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Labels.DELETE_LABEL_URL.replace(Labels.LABEL_CODE_PARAM, id),
            method: "DELETE",
        });
    }

    updateLabel(name: string, id: string, description: string, isLabelPrivate: boolean | undefined): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Labels.PUT_UPDATE_LABEL_URL.replace(Labels.LABEL_CODE_PARAM, id),
            method: "PUT",
            body: {
                name: name,
                description: description,
                publicLabel: !isLabelPrivate,
            }
        });
    }
}
