import {Event} from "../../../event/Event";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";

export enum NodeDistributionEventType {
    DISTRIBUTE_HORIZONTALLY = "NODE_DISTRIBUTE_HORIZONTALLY",
    DISTRIBUTE_VERTICALLY = "NODE_DISTRIBUTE_VERTICALLY",
}

interface NodeDistributionEvent extends Event {
    parentNode?: IDiagramNodeDto;
    nodes: IDiagramNodeDto[];
}

export interface NodeDistributeHorizontallyEvent extends NodeDistributionEvent {
    type: NodeDistributionEventType.DISTRIBUTE_HORIZONTALLY;
}

export interface NodeDistributeVerticallyEvent extends NodeDistributionEvent {
    type: NodeDistributionEventType.DISTRIBUTE_VERTICALLY;
}