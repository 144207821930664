import {LogoutAction} from "./LogoutAction";

export interface LogoutActionStorage {
    store(action: LogoutAction): void,
    load(): LogoutAction | null,
    clear(): void,
}

export class LocalStorageLogoutActionStorage implements LogoutActionStorage {

    private static readonly LOGOUT_ACTION_KEY = "ARCHIREPO_LOGOUT_ACTION";

    store(action: LogoutAction) {
        localStorage.setItem(LocalStorageLogoutActionStorage.LOGOUT_ACTION_KEY, JSON.stringify(action));
    }

    load(): LogoutAction | null {
        const action = localStorage.getItem(LocalStorageLogoutActionStorage.LOGOUT_ACTION_KEY);
        return action ? JSON.parse(action) : null;
    }

    clear() {
        localStorage.removeItem(LocalStorageLogoutActionStorage.LOGOUT_ACTION_KEY);
    }
}
