
export interface ColorDto {
    r: number,
    g: number,
    b: number,
    a?: number
}

export class Colors {
    public static readonly BLACK = Colors.color(0, 0, 0);
    public static readonly LIGHT_GRAY = Colors.color(196, 196, 196);
    public static readonly WHITE = Colors.color(255, 255, 255);

    static color(r: number, g: number, b: number, a?: number): ColorDto {
        return { r: r, g: g, b: b, a: a };
    }

    static toCss(color: ColorDto): string {
        const alpha = color.a ? color.a / 100.0 : 1.0;
        return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
    }
}