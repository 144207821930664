import RelationshipMatrixExportWizard from "./RelationshipMatrixExportWizard";
import ModelExportWizard from "./ModelExportWizard";
import {ExportConfigurationNullable} from "../../../../common/Types";
import {ExportTranslationKey} from "./ExportTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import ElementExportWizard from "./ElementExportWizard";

export enum ConfigurationId {
    RELATIONSHIP_MATRIX = "relationship_matrix",
    MODEL_TO_EXCHANGE_FORMAT = "model_to_exchange_format",
    EXPORT_PRVKY_CONFIGURATION_ID = "export_prvky_configuration_id",
}

export class Configuration {
    constructor(public id: ConfigurationId,
                public pageLabelKey: string,
                public label: string,
                public description: string,
                public getWizard: (resetWizard: () => void) => JSX.Element) {
    }

    public static findById(configurations: Array<Configuration>, id: ConfigurationId): ExportConfigurationNullable {
        const filtered = configurations.filter(configuration => configuration.id === id);
        return filtered.length === 1 ? filtered[0] : null;
    }

    public static getConfigurations(): Array<Configuration> {
        return [
            new Configuration(ConfigurationId.RELATIONSHIP_MATRIX, _transl(ExportTranslationKey.TYPE_RELATIONSHIP_MATRIX_LABEL), _transl(ExportTranslationKey.TYPE_RELATIONSHIP_MATRIX_LABEL), _transl(ExportTranslationKey.TYPE_RELATIONSHIP_MATRIX_DESCRIPTION), (resetWizard) => <RelationshipMatrixExportWizard resetExport={resetWizard}/>),
            new Configuration(ConfigurationId.MODEL_TO_EXCHANGE_FORMAT, _transl(ExportTranslationKey.TYPE_EXCHANGE_FORMAT_LABEL), _transl(ExportTranslationKey.TYPE_EXCHANGE_FORMAT_LABEL), _transl(ExportTranslationKey.TYPE_EXCHANGE_FORMAT_DESCRIPTION), (resetWizard) => <ModelExportWizard resetExport={resetWizard}/>),
            new Configuration(ConfigurationId.EXPORT_PRVKY_CONFIGURATION_ID, _transl(ExportTranslationKey.TYPE_EXPORT_ELEMENTS_WIZARD_TYPE_LABEL), _transl(ExportTranslationKey.TYPE_EXPORT_ELEMENTS_WIZARD_TYPE_LABEL), _transl(ExportTranslationKey.TYPE_EXPORT_ELEMENTS_WIZARD_TYPE_DESCRIPTION), (resetWizard) => <ElementExportWizard resetExport={resetWizard}/>),
        ];
    }
}