export class BlobUtils {

    public static saveBlob(blob: Blob, fileName: string) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
    }

    public static saveBlobData(blobData: BlobData) {
        const blob = new Blob(blobData.blobParts);
        const fileName = blobData.fileName;
        BlobUtils.saveBlob(blob, fileName);
    }

}

export type BlobData = {
    fileName: string,
    blobParts: any[],
}