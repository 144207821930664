import React from "react";
import {Collapse, ListItem, ListItemText, Paper} from "@mui/material";
import UIUtils from "../../../../common/UIUtils";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {SearchState, SearchStatus} from "./Search";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {SearchProvider} from "./SearchProvider";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {AppBarTranslationKey} from "../AppBarTranslationKey";

const searchResultAreaStyles = (theme: Theme) => createStyles({
    searchResultArea: {
        position: "absolute",
        top: theme.spacing(5),
        left: theme.spacing(1.5),
        width: theme.spacing(60),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        cursor: "default",
        "& #app-bar-search-area-result:focus": {
            outline: "none",
        }
    },
    searchResultAreaNoDataArea: {
        padding: theme.spacing(2),
    },
    searchResultAreaItemName: {
        display: "inline-block",
        marginBottom: theme.spacing(0.1),
        color: "rgba(0, 0, 0, 0.54)",
    },
    searchResultAreaItemType: {
        display: "inline-block",
        fontSize: "0.8em",
        marginBottom: theme.spacing(0.5),
    },
    searchResultAreaItemDescription: {
        display: "inline-block",
        fontSize: "0.8em",
        marginBottom: theme.spacing(0.5),
    },
    searchResultAreaDots: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(1)
    },
    searchResultAreaShowAllArea: {
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        padding: theme.spacing(2),
        fontSize: "0.8em",
    },
    searchResultAreaShowAllAreaText: {
        flex: 1,
    },
    bold: {
        fontWeight: "bold",
    },
});

interface IProps extends WithStyles<typeof searchResultAreaStyles> {
    searchProvider: SearchProvider,
    searchResultAreaOpened: boolean,
    searchState: SearchState,
    onItemSelected: (item: any) => void,
    onShowAllItemsSelected: () => void,
}

interface IState {}

export const SEARCH_RESULT_AREA_ID = "app-bar-search-result-area";
export const SEARCH_RESULT_AREA_ACTION_ROLE = "SEARCH_RESULT_AREA_ACTION";

export class SearchResultArea extends React.Component<IProps, IState> {

    render() {
        const {searchResultAreaOpened, searchState, classes} = this.props;

        return (
            <Collapse in={searchResultAreaOpened} className={classes.searchResultArea} timeout={100}
                      onEntered={e => UIUtils.requestFocus(SEARCH_RESULT_AREA_ID)}>

                <Paper elevation={1} id={SEARCH_RESULT_AREA_ID}>
                    {searchState.status === SearchStatus.FAILED &&
                    <div className={classes.searchResultAreaNoDataArea}>
                        { _transl(AppBarTranslationKey.SEARCH_BAR_ERROR_WHILE_FETCHING_DATA) }
                    </div>
                    }
                    {searchState.status === SearchStatus.SUCCEEDED && searchState.items.length === 0 &&
                    <div className={classes.searchResultAreaNoDataArea}>
                        { _transl(AppBarTranslationKey.SEARCH_BAR_NO_DATA_FOUND) }
                    </div>
                    }
                    {searchState.status === SearchStatus.SUCCEEDED && searchState.items.length > 0 &&
                    this.renderSearchResultAreaItems()
                    }
                </Paper>

            </Collapse>
        );
    }

    renderSearchResultAreaItems(): JSX.Element {
        const { searchProvider, searchState, onItemSelected, onShowAllItemsSelected, classes } = this.props;
        const items = searchState.items;

        const count = Math.min(5, items.length);
        const renderedItems = items
            .filter((value, index) => index < count)
            .map(item => searchProvider.resultAreaConfig.toResultItem(item));
        const renderLinkToItemsPage = items.length > count;

        return (
            <div>
                <List>
                    {
                        renderedItems.map((item, index) => {
                            const name = this.ellipsize(item.name || "", 40);
                            const type = this.ellipsize(item.type || "", 90);
                            const description = this.ellipsize(item.description || "", 150);

                            return (
                                <React.Fragment key={index + ""}>
                                    <ListItem button onClick={e => onItemSelected(item)} role={SEARCH_RESULT_AREA_ACTION_ROLE}>
                                        <ListItemText
                                            primary={
                                                <span className={`${classes.searchResultAreaItemName} ${classes.bold}`}>
                                                    {name}
                                                </span>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <span className={`${classes.searchResultAreaItemType} ${classes.bold}`}>
                                                        {type}
                                                    </span>
                                                    <span className={classes.searchResultAreaItemDescription}>
                                                        {description}
                                                    </span>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    {
                                        index < (count - 1) && <Divider />
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </List>
                {
                    renderLinkToItemsPage && <React.Fragment>
                        <Divider />
                        <span className={`${classes.searchResultAreaDots} ${classes.bold}`}>...</span>
                        <Divider />
                        <div className={classes.searchResultAreaShowAllArea}>
                            <Button variant="contained" size="small" color="primary" onClick={e => onShowAllItemsSelected()} role={SEARCH_RESULT_AREA_ACTION_ROLE}>
                                { _transl(AppBarTranslationKey.SEARCH_BAR_SHOW_ALL) }
                            </Button>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }

    ellipsize(text: string, ellipsisThreshold: number): string {
        return text.length < ellipsisThreshold ? text || "" : text.substring(0, ellipsisThreshold) + " ...";
    }

}

export default withStyles(searchResultAreaStyles, { withTheme: true })(SearchResultArea);
