import EventManager, {Unsubscriber} from "../../event/EventManager";
import RenderContext from "../context/RenderContext";
import {
    EventType, IConnectionEvent, IModelUpdatedOnConnectionLabelUpdateEvent,
} from "../../event/Event";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import {UPDATE_RESPONSE_STATUS_OK, UpdateResponse} from "../../../components/fields/EditableComponent";
import {Observable} from "rxjs";
import {fromPromise} from "rxjs/internal-compatibility";
import {Area} from "../util/GeometryUtils";
import {IEditMode} from "../editor/IEditMode";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";
import ConnectionRendererUtils from "../renderer/connection/ConnectionRendererUtils";

export const MINIMAL_TEXT_CLIENT_BOUND_WIDTH = 200;

export default class ConnectionLabelManager {

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private updatedConnection?: IDiagramConnectionDto;
    private isConnectionCreateResult?: boolean;
    private updatePromiseResolve?: (value: UpdateResponse) => void;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_DBLCLICK, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE, this.handleModelUpdatedOnConnectionLabelUpdateEvent.bind(this)));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    private handleConnectionEvent(event: IConnectionEvent) {
        if (event.type === EventType.CONNECTION_DBLCLICK) {
            if (this.renderContext && this.renderContext.isEdit()) {
                this.updateConnectionText(event.connection);
            }
        }
    }

    private handleModelUpdatedOnConnectionLabelUpdateEvent(event: IModelUpdatedOnConnectionLabelUpdateEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE && this.updatePromiseResolve) {
            this.updatePromiseResolve({
                status: UPDATE_RESPONSE_STATUS_OK,
            });
            this.onConnectionUpdateFinished();
        }
    }

    private updateConnectionText(connection: IDiagramConnectionDto) {
        this.updatedConnection = connection;
        this.showUpdateUI();
    }

    private showUpdateUI() {
        if (this.updatedConnection && this.renderContext) {
            const label = DiagramEditorUtils.getConnectionLabel(this.updatedConnection, this.renderContext.modelManager);
            let connectionTextClientBounds = ConnectionRendererUtils.getConnectionTextClientBounds(this.updatedConnection);
            connectionTextClientBounds = ConnectionLabelManager.ensureMinimalTextClientBounds(connectionTextClientBounds);
            ConnectionRendererUtils.hideConnectionText(this.updatedConnection);
            const doUpdate = (label: string) => this.updateConnectionLabel(label, this.updatedConnection as IDiagramConnectionDto, this.isConnectionCreateResult === true);
            const doCancel = () => this.cancelUpdate();
            (this.renderContext.renderMode as IEditMode).diagramApi.updateConnectionLabel(label, connectionTextClientBounds, doUpdate, doCancel);
        }
    }

    private updateConnectionLabel(label: string, updatedConnection: IDiagramConnectionDto, isConnectionCreateResult: boolean): Observable<UpdateResponse> {
        const promise = new Promise<UpdateResponse>((resolve, reject) => {
            this.updatePromiseResolve = resolve;
        });
        setTimeout(() => this.eventManager.publishEvent({
            type: EventType.CONNECTION_LABEL_UPDATE,
            connection: updatedConnection,
            label: label,
            isConnectionCreateResult: isConnectionCreateResult,
        }), 0);

        return fromPromise<UpdateResponse>(promise);
    }

    private cancelUpdate() {
        if (this.updatedConnection) {
            ConnectionRendererUtils.showConnectionText(this.updatedConnection);
        }
        this.onConnectionUpdateFinished();
    }

    private getElementType(connection: IDiagramConnectionDto) {
        let elementType = null;
        if (this.renderContext && connection.relationshipIdentifier) {
            elementType = this.renderContext.modelManager.getElementType(connection.relationshipIdentifier);
        }
        return elementType;
    }

    private static ensureMinimalTextClientBounds(connectionTextClientBounds: Area) {
        if (connectionTextClientBounds.w < MINIMAL_TEXT_CLIENT_BOUND_WIDTH) {
            connectionTextClientBounds.w = MINIMAL_TEXT_CLIENT_BOUND_WIDTH;
        }
        return connectionTextClientBounds;
    }

    private onConnectionUpdateFinished() {
        this.updatedConnection = undefined;
        this.updatePromiseResolve = undefined;
        this.isConnectionCreateResult = undefined;
    }
}
