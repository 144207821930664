import React, {useEffect, useState} from "react";
import {HierarchyTreeDataType} from "../service/HierarchyTreeDataType";
import {SaveButton} from "../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../components/button/CancelButton";
import {HierarchyTreeTranslationKey} from "../HierarchyTreeTranslationKey";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import DataTypeSelect from "./subforms/DataTypeSelect";
import {HierarchyTreeDataDto} from "../service/HierarchyTreeDataDto";
import {DialogValidationResult} from "./DialogValidationResult";
import {TitleSubformValidator} from "./subforms/title/TitleSubformValidator";
import {HierarchyTreeDataTypeEnum} from "./subforms/HierarchyTreeDataTypeEnum";
import TextField from "../../../../../components/fields/textfield/TextField";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import Grid from "../../../../../components/dialogs/Grid";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import {ValidationResult} from "../../../../../common/validation/ValidationResult";
import {HierarchyTreeCreateDto} from "../service/HierarchyTreeCreateDto";

const DEFAULT_DATA_TYPE: HierarchyTreeDataType = HierarchyTreeDataType.FOLDER;

function createDefaultCreateDto(dataType: HierarchyTreeDataType, parentTreeId?: string | null): HierarchyTreeCreateDto<any> {
    return {
        parentId: parentTreeId,
        title: "",
        data: HierarchyTreeDataTypeEnum[dataType].emptyData
    };
}

export interface HierarchyTreeCreateDialogProps {
    open: boolean;
    parentTreeId?: string | null;
    onConfirm: (tree: HierarchyTreeCreateDto<any>) => void;
    onCancel: () => void;
}

export function HierarchyTreeCreateDialog({open, parentTreeId, onConfirm, onCancel}: HierarchyTreeCreateDialogProps) {
    const [selectedDataType, setSelectedDataType] = useState<HierarchyTreeDataType>(DEFAULT_DATA_TYPE);
    const [treeCreate, setTreeCreate] = useState<HierarchyTreeCreateDto<any>>(createDefaultCreateDto(DEFAULT_DATA_TYPE, parentTreeId));
    const [dialogValidationResult, setDialogValidationResult] = useState<DialogValidationResult>();

    const Subform = HierarchyTreeDataTypeEnum[selectedDataType].subform;
    const dataValidator = HierarchyTreeDataTypeEnum[selectedDataType].validator;
    const titleValidator = new TitleSubformValidator();

    useEffect(() => {
        setTreeCreate(createDefaultCreateDto(DEFAULT_DATA_TYPE, parentTreeId));
        setSelectedDataType(DEFAULT_DATA_TYPE);
    }, [open, parentTreeId]);

    function onDialogClosed() {
        onCancel();
    }

    function onDialogSaved() {
        if (validate()) {
            onConfirm(treeCreate!);
        }
    }

    function validate(): boolean {
        const titleValidationResult = titleValidator.validate(treeCreate.title);
        const dataValidationResult = dataValidator.validate(treeCreate.data);
        if (titleValidationResult.errors.length === 0 && dataValidationResult.errors.length === 0) {
            setDialogValidationResult(undefined);
            return true;
        } else {
            setDialogValidationResult({
                titleValidationResult: titleValidationResult,
                dataValidationResult: dataValidationResult,
            })
            return false;
        }
    }

    function onDataTypeSubformUpdated(data: HierarchyTreeDataDto) {
        setTreeCreate({...treeCreate, data});
    }

    function onTitleChanged(title: string) {
        setTreeCreate({...treeCreate, title});
    }

    function onDataTypeSelected(treeDataType: HierarchyTreeDataType) {
        setSelectedDataType(treeDataType);
        setTreeCreate({...treeCreate, data: HierarchyTreeDataTypeEnum[treeDataType].emptyData});
        setDialogValidationResult({...dialogValidationResult, dataValidationResult: undefined});
    }

    function getTitleErrorMessage(validationResult?: ValidationResult) {
        if (validationResult && validationResult.errors.length > 0 && treeCreate.title.length === 0) {
            return validationResult.errors[0].errorMessage;
        } else {
            return undefined;
        }
    }

    const titleErrorMessage = getTitleErrorMessage(dialogValidationResult?.titleValidationResult);

    return (
        <Dialog
            open={open}
            onClose={() => onCancel}
            maxWidth={"lg"}
        >
            <DialogTitle
                id="tree-create-dialog-scroll-dialog-title"
                title={_transl(HierarchyTreeTranslationKey.STRUCTURE_CREATE_TITLE)}
                onDialogClosed={onDialogClosed}
            />

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <DataTypeSelect initialValue={selectedDataType}
                                        onChange={(treeDataType) => onDataTypeSelected(treeDataType)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField label={_transl(HierarchyTreeTranslationKey.COMMON_SUBFORM_TITLE)}
                                   value={treeCreate.title}
                                   onChange={(event) => onTitleChanged(event)}
                                   errorMessage={titleErrorMessage ? _transl(titleErrorMessage) : undefined}
                                   maxLength={TitleSubformValidator.TREE_CREATION_TITLE_MAX_LENGTH}
                                   required={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Subform data={treeCreate.data}
                             onChange={(data: HierarchyTreeDataDto) => onDataTypeSubformUpdated(data)}
                             validationResult={dialogValidationResult?.dataValidationResult}
                    />
                </Grid>

            </DialogContent>
            <DialogActions>
                <SaveButton onClick={onDialogSaved}/>
                <CancelButton onClick={onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );
}
