import React, {useEffect, useRef, useState} from "react";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {LabelsTranslationKey} from "./LabelsTranslationKey";
import {CommonTranslation} from "../CommonTranslation";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {UserDto} from "../../../../common/apis/user/UserDto";
import {IApplicationState} from "../../../../store/Store";
import Snackbar from "../snackbar/Snackbar";
import {useSelector} from "react-redux";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import Grid from "../../../../components/dialogs/Grid";
import TextField from "../../../../components/fields/textfield/TextField";
import {Checkbox, FormControlLabel} from "@mui/material";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {LabelCreateDto} from "../../../../common/apis/label/LabelCreateDto";
import constructLabelsController, {LabelsController} from "./controller/LabelsController";
import LabelsService from "./service/LabelsService";
import {LabelErrorCode} from "./service/LabelErrorCode";
import {LabelConstant} from "./service/LabelConstant";

interface CreateLabelDialogProps {
    onClosed: () => void,
    onLabelCreated: () => void,
}

export default function CreateLabelDialog({onClosed, onLabelCreated}: CreateLabelDialogProps) {
    const labelController = useRef<LabelsController>(constructLabelsController(LabelsService));

    const user = useSelector((state: IApplicationState) => state.user.userData as UserDto);

    const [name, setName] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [isPrivate, setIsPrivate] = useState<boolean>(true);

    const [nameError, setNameError] = useState<string>();
    const [codeError, setCodeError] = useState<string>();
    const [isNameToCodeCopyingModeOn, setIsNameToCodeCopyingModeOn] = useState<boolean>(true);

    const isAccessModifierDisabled = !user.userAcl.canCreatePublicLabels;

    useEffect(() => {
        if (!user.userAcl.canCreatePublicLabels) {
            setIsPrivate(true);
        }
    }, [user.userAcl.canCreatePublicLabels]);

    function convertToCode(name: string) {
        return name.toLowerCase().replace(/ /g, "_");
    }

    function validate(): boolean {
        const isNameValid = validateName();
        const isCodeValid = validateCode();
        return isNameValid && isCodeValid;
    }

    function validateName(): boolean {
        let isValid = false;
        if (name.trim().length === 0) {
            setNameError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        } else {
            setNameError(undefined);
            isValid = true;
        }
        return isValid;
    }

    function validateCode(): boolean {
        let isValid = false;
        if (code.trim().length === 0) {
            setCodeError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        } else {
            setCodeError(undefined);
            isValid = true;
        }

        return isValid;
    }

    function saveChanges() {
        if (validate()) {
            (async () => {
                try {
                    await labelController.current.create(createLabelDto(name, code, description, !isPrivate));
                    onClosed();
                    onLabelCreated();
                    Snackbar.success(_transl(LabelsTranslationKey.ADD_SUCCEEDED));
                } catch (error: any) {
                    if (error.response.code === LabelErrorCode.CODE_USED_BY_ANOTHER_LABEL) {
                        Snackbar.error(_transl(LabelsTranslationKey.CODE_IS_USED_BY_ANOTHER_LABEL));
                    } else {
                        Snackbar.error(_transl(LabelsTranslationKey.ADD_FAILED));
                    }
                }
            })();
        }
    }

    function createLabelDto(name: string, code: string, description: string, isPublic: boolean): LabelCreateDto {
        return {
            name: name,
            code: code,
            description: description,
            publicLabel: isPublic
        }
    }

    return (
        <Dialog open={true} onClose={onClosed}>
            <DialogTitle id="create-label-dialog-title"
                         title={_transl(LabelsTranslationKey.ADD_TITLE)}
                         onDialogClosed={onClosed}
            />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="create-label-name-field"
                                   label={_transl(CommonTranslation.TITLE)}
                                   errorMessage={nameError}
                                   value={name}
                                   onChange={(newName) => {
                                       if (isNameToCodeCopyingModeOn) {
                                           setCode(convertToCode(newName))
                                       }
                                       setName(newName)
                                   }}
                                   required
                                   maxLength={LabelConstant.NAME_MAX_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="create-label-code-field"
                                   label={_transl(CommonTranslation.CODE)}
                                   errorMessage={codeError}
                                   value={code}
                                   onChange={(newCode) => setCode(convertToCode(newCode))}
                                   onFocus={() => {
                                       setIsNameToCodeCopyingModeOn(false)
                                   }}
                                   required
                                   maxLength={LabelConstant.CODE_MAX_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="create-label-description-field"
                                   label={_transl(CommonTranslation.DESCRIPTION)}
                                   value={description}
                                   onChange={newDesc => setDescription(newDesc)}
                                   maxLength={LabelConstant.DESCRIPTION_MAX_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label={_transl(LabelsTranslationKey.IS_PRIVATE)}
                            control={
                                <Checkbox
                                    name="labelAccessModifier"
                                    checked={isPrivate}
                                    onChange={() => setIsPrivate(!isPrivate)}
                                    color="primary"
                                    disabled={isAccessModifierDisabled}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={saveChanges}/>
                <CancelButton onClick={onClosed}/>
            </DialogActions>
        </Dialog>
    );
}

