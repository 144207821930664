export enum PagePresetsTranslationKey {
    PRESETS_ARE_PRIVATE = "presets.pagePresets.presetsArePrivate",
    PRESETS_DELETE_CONFIRMATION = "presets.pagePresets.presetsDeleteConfirmation",
    PRESETS_DELETE_TITLE = "presets.pagePresets.presetsDeleteTitle",
    PRESETS_MANAGEMENT = "presets.pagePresets.presetsManagement",
    PRESETS_SELECTION = "presets.pagePresets.presetsSelection",
    PAGE_RESETS = "presets.pagePresets.pagePresets",
    RESET_GRID_PRESETS = "presets.pagePresets.resetGridPresets",
    RESTORE_TO_DEFAULT_GRID_PRESETS = "presets.pagePresets.restoreToDefaultGridPresets",
    SAVE_GRID_PRESETS_AS_DEFAULT = "presets.pagePresets.saveGridPresetsAsDefault",
    SAVE_PRESETS_AS = "presets.pagePresets.savePresetsAs",
    USE_PRESETS = "presets.pagePresets.usePresets",
}
