import * as d3 from "d3";
import {ILink, INode} from "./ModelManager";
import EventManager from "./EventManager";
import {INodeEventListener} from "../event/Listener";
import {EventType, INodeEvent} from "../event/Event";

export default class SimulationManager implements INodeEventListener {

    public static readonly FORCE_COLLIDE_RADIUS = 60;

    private eventManager: EventManager;
    private simulation?: d3.Simulation<any, any>;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.eventManager.subscribeNodeListener(this, EventType.NODE_DRAG_START);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_DRAG_END);
    }

    public stopSimulation() {
        if (this.simulation != null) {
            // stop previous simulation
            this.simulation && this.simulation.stop();
        }
    }

    public createSimulation(nodes: INode[],
                            links: ILink[],
                            linkElems: d3.Selection<any, ILink, any, unknown>,
                            nodeElems: d3.Selection<any, INode, any, unknown>,
                            graphDataChanged: boolean) {
        this.simulation = d3.forceSimulation(nodes)
            .alpha(graphDataChanged ? 1 : .5)
            .force("link", d3.forceLink(links).id(d => (d as ILink).id).distance(270))
            .force("charge", d3.forceManyBody())
            .force("x", d3.forceX())
            .force("y", d3.forceY())
            .force('collide', d3.forceCollide(d => SimulationManager.FORCE_COLLIDE_RADIUS));

        // define tick behavior
        this.defineTickBehavior(linkElems, nodeElems);
    }

    private defineTickBehavior(linkElems: d3.Selection<any, ILink, any, unknown>,
                               nodeElems: d3.Selection<any, INode, any, unknown>,
    ) {
        if (this.simulation) {
            this.simulation.on("tick", () => {
                linkElems.attr("d", d => `M${d.source.x},${d.source.y}A0,0 0 0,1 ${d.target.x},${d.target.y}`);
                nodeElems.attr("transform", d => `translate(${d.x},${d.y})`);
            });
        }
    }

    public getSimulation() {
        return this.simulation;
    }

    public handleNodeEvent(event: INodeEvent): void {
        if (event.type === EventType.NODE_DRAG_START) {
            if (!event.event.active){
                this.simulation?.alphaTarget(0.3).restart();
            }
        }
        if (event.type === EventType.NODE_DRAG_END) {
            if (!event.event.active) {
                this.simulation?.alphaTarget(0);
            }
        }
    }

}