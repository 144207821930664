import RenderContext from "../../context/RenderContext";
import ConnectionRendererUtils from "../../renderer/connection/ConnectionRendererUtils";
import * as d3 from "d3";
import {Point} from "../../util/GeometryUtils";
import ChartGroup, {ChartGroupType} from "../../common/ChartGroup";
import {PointIncrement} from "../../common/PointIncrement";
import {OVERLAY_STROKE_COLOR, OVERLAY_VISIBLE_OPACITY} from "../../common/UIConstants";
import {IDiagramConnectionDto} from "../../../apis/diagram/IDiagramConnectionDto";
import EventManager, {Unsubscriber} from "../../../event/EventManager";
import {EventType, DiagramZoomEvent} from "../../../event/Event";

export default class Overlay {

    private static readonly LARGE_RESIZE_HANDLE_RADIUS = 3;
    private static readonly OVERLAY_CIRCLE_CLASS_NAME = "__connection_selection_overlay_circle__"
    private static readonly OVERLAY_CIRCLE_INITIAL_RADIUS = "__connection_selection_overlay_circle_initial_radius__";

    private eventManager: EventManager;
    private renderContext?: RenderContext;
    private scale: number;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.DIAGRAM_ZOOM_UPDATED, this.handleChartZoomEvent.bind(this)));

        this.scale = 1;
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    initConnectionsOverlayGroup(renderContext: RenderContext) {
        this.renderContext = renderContext;
        Overlay.initPositionOverlayGroup();
    }

    initConnectionOverlayGroup(connection: IDiagramConnectionDto) {
        const id = Overlay.createPositionConnectionOverlayGroupId(connection);
        const group = Overlay.getPositionConnectionOverlayGroup(connection);
        if (group.empty()) {
            Overlay.getPositionOverlayGroup().append("g")
                .attr("id", id);
        }
    }

    removeConnectionOverlayGroup(connection: IDiagramConnectionDto) {
        d3.select(Overlay.createPositionConnectionOverlayGroupId(connection, true)).remove();
    }

    private static initPositionOverlayGroup() {
        const positionGroup = Overlay.getPositionGroup();
        positionGroup
            .append("g")
            .attr("id", Overlay.createPositionOverlayGroupId())
    }

    updateConnectionOverlayOnBendpointMove(connection: IDiagramConnectionDto,
                                           bendpointIndex: number,
                                           positionIncrement: PointIncrement,
                                           isNewBendpoint: boolean)
    {
        if (this.renderContext?.modelManager) {
            const updatedBendpoints: Array<Point> = [...(connection.bendpoints || [])].map(point => new Point(point.x, point.y));
            const updatedBendpoint = Overlay.createIncrementedBendpoint(updatedBendpoints[bendpointIndex], positionIncrement);
            updatedBendpoints[bendpointIndex] = updatedBendpoint;
            const updatedConnection = Object.assign({}, connection, {bendpoints: updatedBendpoints});

            const linePath = ConnectionRendererUtils.createLinePath(updatedConnection, this.renderContext.modelManager);
            const d = linePath?.path || "";

            const overlayGroup = Overlay.getPositionConnectionOverlayGroup(connection);

            if (overlayGroup.select("path").empty()) {
                // render path
                overlayGroup.append("path")
                    .attr("d", d)
                    .attr("fill", "none")
                    .attr("stroke", OVERLAY_STROKE_COLOR)
                    .attr("stroke-dasharray", "6 2")
                    .attr("fill-opacity", OVERLAY_VISIBLE_OPACITY)
                    .attr("stroke-opacity", OVERLAY_VISIBLE_OPACITY);
            }
            if (overlayGroup.select("circle").empty()) {
                const initialRadius = Overlay.LARGE_RESIZE_HANDLE_RADIUS;
                const radius = initialRadius * this.getHandleScaleFactor();

                const cursor = "move";
                overlayGroup.append("circle")
                    .attr("id", Overlay.createPositionBendpointId(connection.identifier, bendpointIndex))
                    .attr("r", radius)
                    .attr("fill", "#CCCCCC")
                    .attr("stroke", OVERLAY_STROKE_COLOR)
                    .attr("stroke-dasharray", "4 1")
                    .attr("cursor", cursor)
                    .attr("cx", updatedBendpoint.x)
                    .attr("cy", updatedBendpoint.y)
                    .attr("fill-opacity", OVERLAY_VISIBLE_OPACITY)
                    .attr("stroke-opacity", OVERLAY_VISIBLE_OPACITY);
            }
            overlayGroup.select("path")
                .attr("d", d);
            overlayGroup.select("circle")
                .attr("cx", updatedBendpoint.x)
                .attr("cy", updatedBendpoint.y);
        }
    }

    hidePositionOverlays(connections: IDiagramConnectionDto[]) {
        connections.forEach(connection => {
            const overlayGroup = Overlay.getPositionConnectionOverlayGroup(connection);
            overlayGroup.selectAll("*").remove();
        });
    }

    cancelPositionOverlays(connections: IDiagramConnectionDto[]) {
        connections.forEach(connection => {
            const overlayGroup = Overlay.getPositionConnectionOverlayGroup(connection);
            overlayGroup.selectAll("*").remove();
        });
    }

    private handleChartZoomEvent(event: DiagramZoomEvent) {
        if (event.type === EventType.DIAGRAM_ZOOM_UPDATED) {
            const scale = event.actualZoom;
            this.updateScale(scale);
        }
    }

    private updateScale(scale: number) {
        if (this.scale !== scale) {
            this.scale = scale;

            d3.selectAll(`circle.${Overlay.OVERLAY_CIRCLE_CLASS_NAME}`)
                .attr("r", (d, i, circles ) => {
                    const initialRadius: number = d3.select(circles[i]).property(Overlay.OVERLAY_CIRCLE_INITIAL_RADIUS);
                    return initialRadius * this.getHandleScaleFactor();
                })
                .attr("stroke-width", this.getHandleScaleFactor())
        }
    }

    private getHandleScaleFactor(): number {
        return this.scale === 1 ? this.scale : 1 / this.scale;
    }

    static createIncrementedBendpoint(bendpoint: Point, positionIncrement: PointIncrement) {
        return new Point(bendpoint.x + positionIncrement.incrementX, bendpoint.y + positionIncrement.incrementY);
    }

    static createPositionGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.CONNECTIONS_HANDLE_OVERLAY_GROUP, selector);
    }

    static getPositionGroup() {
        const id = Overlay.createPositionGroupId(true);
        return d3.select(id) as d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    }

    static createPositionOverlayGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.CONNECTIONS_OVERLAY_GROUP, selector);
    }

    static getPositionOverlayGroup() {
        const id = Overlay.createPositionOverlayGroupId(true);
        return d3.select(id) as d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    }

    static createPositionConnectionOverlayGroupId(connection: IDiagramConnectionDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-selection-connection-overlay-group-" + connection.identifier + "__";
    }

    static getPositionConnectionOverlayGroup(connection: IDiagramConnectionDto) {
        const id = Overlay.createPositionConnectionOverlayGroupId(connection, true);
        return d3.select(id) as d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    }

    static createPositionBendpointId(identifier: string, bendpointIndex: number, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-selection-bendpoint-id-" + identifier + "-" + bendpointIndex + "__";
    }

}
