import React, {useEffect, useState} from "react";
import {PersistentStateId} from "../../../../store/common/Grid";
import {
    GridAction,
    ActionButtonType,
    EnabledPolicy
} from "../../../../components/grid/GridAction";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {UsersTranslationKey} from "../users/UsersTranslationKey";
import userService from "../../../../common/apis/UserService";
import {IApplicationState} from "../../../../store/Store";
import {UserDto} from "../../../../common/apis/user/UserDto";
import {useSelector} from "react-redux";
import {CollectionPermissionDto} from "../../../../common/apis/user/CollectionPermissionDto";
import {GridColumns, GridValueFormatterParams} from "@mui/x-data-grid";
import {CollectionPermissionLevelDto} from "../../../../common/apis/user/CollectionPermissionLevelDto";
import {CollectionDto} from "../../../../common/apis/collection/CollectionDto";
import {UserDetailController} from "./controller/UserDetailController";
import {ButtonId} from "./ButtonId";
import CreatePermissionDialog from "./CreatePermissionDialog";
import UpdatePermissionDialog from "./UpdatePermissionDialog";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            minHeight: "18em",
        }
    })
);

type ChangeUserPermissionDialogDef = {
    selectedCollectionName: string,
    selectedCollectionCode: string,
    permissionLevelCode: string,
    permissionLevelName: string,
}

type ConfirmationDialogDef = {
    title: string,
    text: string,
    action: () => void,
}

type AlertDialogDef = {
    type: AlertDialogType,
    title: string,
    text: string,
}

interface UserPermissionPanelProps {
    login: string,
    controller: UserDetailController
}

export default function UserPermissionPanel({login, controller}: UserPermissionPanelProps) {

    const classes = useStyles();

    const [userCollectionPermissions, setUserCollectionPermissions] = useState<CollectionPermissionDto[]>([]);
    const [userCollectionPermissionsRefreshDate, setUserCollectionPermissionsRefreshDate] = useState<number>(new Date().getTime());
    const [createUserPermissionDialogIsOpen, setCreateUserPermissionDialogIsOpen] = useState<boolean>(false);
    const [changeUserPermissionDialogDef, setChangeUserPermissionDialogDef] = useState<ChangeUserPermissionDialogDef | undefined>();
    const [confirmationDialogDef, setConfirmationDialogDef] = useState<ConfirmationDialogDef | undefined>();
    const [alertDialogDef, setAlertDialogDef] = useState<AlertDialogDef | undefined>();

    const loggedUser = useSelector((application: IApplicationState) => application.user.userData as UserDto);

    useEffect(() => {
        (async () => {
            const permissions = await userService.getCollectionPermissionsByLogin(login);
            setUserCollectionPermissions(permissions);
        })();
    }, [login, userCollectionPermissionsRefreshDate]);

    function refreshCollectionPermissions() {
        setUserCollectionPermissionsRefreshDate(new Date().getTime());
    }

    function showChangeUserPermissionDialog(changeUserPermissionDialogIsOpen: boolean, selectedRow: CollectionPermissionDto) {
        setChangeUserPermissionDialogDef({
            selectedCollectionName: selectedRow.collection.name,
            selectedCollectionCode: selectedRow.collection.code,
            permissionLevelCode: selectedRow.permissionLevel.code,
            permissionLevelName: selectedRow.permissionLevel.translationKey,
        });
    }

    const collectionFormatter = (params: GridValueFormatterParams) => {
        return params.value != null ? (params.value as CollectionDto).name : "";
    };

    const permissionLevelFormatter = (params: GridValueFormatterParams) => {
        return params.value != null ? _transl((params.value as CollectionPermissionLevelDto).translationKey) : "";
    };

    function createColumns(): GridColumns {
        return [
            {
                field: 'collection',
                headerName: _transl(UsersTranslationKey.ADD_PERMISSION_COLLECTION),
                width: 300,
                valueFormatter: collectionFormatter
            },
            {
                field: 'permissionLevel',
                headerName: _transl(UsersTranslationKey.ADD_PERMISSION_PERMISSION_LEVEL),
                width: 200,
                valueFormatter: permissionLevelFormatter
            },
        ];
    }

    function showConfirmationDialog(title: string, text: string, action: () => void) {
        setConfirmationDialogDef({
            title: title,
            text: text,
            action: action,
        })
    }

    async function deletePermission(collectionPermission: CollectionPermissionDto) {
        try {
            await userService.removeCollectionPermissionByLogin(login, collectionPermission.collection.code as string);
            refreshCollectionPermissions()
        } catch (error) {
            showAlertDialog(AlertDialogType.ERROR, _transl(UsersTranslationKey.DELETE_PERMISSION_TITLE), _transl(UsersTranslationKey.DELETE_PERMISSION_ALERT_DIALOG_TEXT));
        }
    }

    function showAlertDialog(type: AlertDialogType, title: string, text: string) {
        setAlertDialogDef({
            type: type,
            title: title,
            text: text,
        });
    }

    return (
        <React.Fragment>
            {alertDialogDef && <AlertDialog open={true}
                                            type={alertDialogDef.type}
                                            title={alertDialogDef.title}
                                            text={alertDialogDef.text}
                                            onClose={() => setAlertDialogDef(undefined)}/>
            }
            {confirmationDialogDef && <ConfirmationDialog open={true}
                                                          title={confirmationDialogDef.title}
                                                          confirmationText={confirmationDialogDef.text}
                                                          onConfirm={() => {
                                                              const action = confirmationDialogDef.action;
                                                              setConfirmationDialogDef(undefined);
                                                              action();
                                                          }}
                                                          onReject={() => setConfirmationDialogDef(undefined)}/>

            }
            {changeUserPermissionDialogDef &&
                <UpdatePermissionDialog onClose={() => setChangeUserPermissionDialogDef(undefined)}
                                        login={login}
                                        reloadUserPermissionsGrid={() => refreshCollectionPermissions()}
                                        collectionName={changeUserPermissionDialogDef.selectedCollectionName}
                                        collectionCode={changeUserPermissionDialogDef.selectedCollectionCode}
                                        permissionCurrentCode={changeUserPermissionDialogDef.permissionLevelCode}
                />
            }
            {createUserPermissionDialogIsOpen &&
                <CreatePermissionDialog onClose={() => setCreateUserPermissionDialogIsOpen(false)}
                                        login={login}
                                        reloadUserPermissionsGrid={() => refreshCollectionPermissions()}
                />
            }
            <ExtGridWrapper
                columns={createColumns()}
                rows={userCollectionPermissions}
                rowCount={userCollectionPermissions.length}
                getRowId={row => row.collection.code}
                dataGridClass={classes.grid}
                actions={[
                    GridAction.buttonBuilder(ButtonId.USER_ADD_PERMISSION_BUTTON, ActionButtonType.IMMEDIATE, _transl(UsersTranslationKey.ADD_PERMISSION),
                        <AddIcon/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .onClick(() => setCreateUserPermissionDialogIsOpen(true))
                        .isEnabled(() => controller.canCreatePermissions(loggedUser.userAcl))
                        .build(),
                    GridAction.buttonBuilder(ButtonId.USER_UPDATE_PERMISSION_BUTTON, ActionButtonType.IMMEDIATE, _transl(UsersTranslationKey.UPDATE_PERMISSION),
                        <CreateIcon/>)
                        .onClick((selectedRowIds, selectedRows) => showChangeUserPermissionDialog(true, selectedRows[0] as CollectionPermissionDto))
                        .isEnabled(() => controller.canUpdatePermissions(loggedUser.userAcl))
                        .build(),
                    GridAction.buttonBuilder(ButtonId.USER_DELETE_PERMISSION_BUTTON, ActionButtonType.IMMEDIATE, _transl(UsersTranslationKey.DELETE_PERMISSION),
                        <DeleteIcon/>)
                        .onClick((selectedRowIds, selectedRows) => {
                            showConfirmationDialog(_transl(UsersTranslationKey.DELETE_PERMISSION_TITLE), _transl(UsersTranslationKey.DELETE_PERMISSION_CONFIRMATION_DIALOG_TEXT),
                                () => deletePermission(selectedRows[0] as CollectionPermissionDto))
                        })
                        .isEnabled(() => controller.canUpdatePermissions(loggedUser.userAcl))
                        .build(),
                ]}
                peristentStateId={PersistentStateId.USER_DETAIL_PAGE_ROLES_GRID}
                resourceId={"roles"}
            />
        </React.Fragment>
    )
}
