import {
    EventType, HiddenConnectionVisualizedEvent,
    IChartEvent,
    IConnectionEvent,
    IModelUpdatedOnConnectionRemovedEvent,
    IModelUpdatedOnItemsRemovedEvent,
    INodeEvent, ModelBackupEvent,
} from "../../event/Event";
import EventManager, {Unsubscriber} from "../../event/EventManager";
import RenderContext from "../context/RenderContext";
import DragSelection from "./selection/producer/DragSelection";
import KeyPressSelection from "./selection/producer/KeyPressSelection";
import SelectionManagerUtils from "./selection/SelectionManagerUtils";
import {ISelectionManagerApi, ISelectionProducer} from "./selection/ISelectionProducer";
import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";
import { SelectionEventType } from "./selection/SelectionEvents";

export enum SelectableObjectType {
    NODE = "NODE",
    CONNECTION = "CONNECTION",
}

export default class SelectionManager {

    public static readonly SELECTABLE_OBJECT_TYPE_PROPERTY_NAME = "__selection-selectable-object-type__";
    public static readonly SELECTABLE_OBJECT_PROPERTY_NAME = "__selection-selectable-object__";
    public static readonly SELECTABLE_OBJECT_CLASS_NAME = "__selection-selectable-object-class__";

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private selectedNodes: Array<IDiagramNodeDto>;
    private selectedConnections: Array<IDiagramConnectionDto>;
    private selectedHiddenConnections: Array<IDiagramConnectionDto>;

    private alternativeSelectionProducers: Array<ISelectionProducer>;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOUSE_CLICKED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RENDERED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_STARTED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_MOUSE_CLICKED, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.HIDDEN_CONNECTION_MOUSE_CLICKED, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_RENDERED, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_REMOVED, this.handleModelUpdatedOnConnectionRemovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_CANVAS_CLICKED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_REMOVED, this.handleModelUpdatedOnItemsRemovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.HIDDEN_CONNECTION_VISUALIZED, this.handleHiddenConnectionVisualizedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_MODEL_BACKUP_LOADED, this.handleModelUpdatedOnModelBackupLoadedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.HIDDEN_CONNECTION_VISUALIZED, this.handleHiddenConnectionVisualizedEvent.bind(this)));

        this.unsubscribers.push(this.eventManager.subscribeListener(SelectionEventType.SELECT_ALL, this.onSelectAll.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(SelectionEventType.DESELECT_ALL, this.onDeselectAll.bind(this)));

        this.selectedNodes = [];
        this.selectedConnections = [];
        this.selectedHiddenConnections = [];

        const api: ISelectionManagerApi = {
            onSelectionChanged: this.onSelectionChanged.bind(this),
            onDeselectAll: this.onDeselectAll.bind(this),
            onSelectAll: this.onSelectAll.bind(this)
        }

        this.alternativeSelectionProducers = [];
        this.alternativeSelectionProducers.push(new DragSelection(eventManager, api))
        this.alternativeSelectionProducers.push(new KeyPressSelection(eventManager, api));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
        for (const producer of this.alternativeSelectionProducers) {
            producer.onDestroy();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        this.alternativeSelectionProducers.forEach(producer => producer.init(renderContext));
    }

    private handleNodeEvent(event: INodeEvent): void {
        if (event.type === EventType.NODE_MOUSE_CLICKED) {
            if (this.isRightButtonClick(event.event)) {
                this.handleNodeRightButtonClick(event);
            } else {
                this.onSelectionChanged([event.node], [], event.event);
            }
        }
        if (event.type === EventType.NODE_MOVE_STARTED) {
            if (this.selectedNodes.indexOf(event.node) === -1) {
                this.onSelectionChanged([event.node], [], event.event);
            }
        }
        if (event.type === EventType.NODE_RENDERED) {
            SelectionManagerUtils.initNode(event.node);
        }
    }

    private handleNodeRightButtonClick(event: INodeEvent) {
        if (this.isNodeSelected(event.node)) {
            // mimic behavior of other tools -> do not update selection
            return;
        } else {
            // update selection
            this.onSelectionChanged([event.node], [], event.event);
        }
    }

    private handleConnectionEvent(event: IConnectionEvent) {
        if (event.type === EventType.CONNECTION_MOUSE_CLICKED) {
            if (this.isRightButtonClick(event.event)) {
                this.handleConnectionRightButtonClick(event);
            } else {
                this.onSelectionChanged([], [event.connection], event.event);
            }
        }
        if (event.type === EventType.HIDDEN_CONNECTION_MOUSE_CLICKED) {
            this.onHiddenConnectionSelectionChanged(event.connection, event.event);
        }
        if (event.type === EventType.CONNECTION_RENDERED) {
            SelectionManagerUtils.initConnection(event.connection);
        }
    }

    private handleConnectionRightButtonClick(event: IConnectionEvent) {
        if (this.isConnectionSelected(event.connection)) {
            // mimic behavior of other tools -> do not update selection
            return;
        } else {
            // update selection
            this.onSelectionChanged([], [event.connection], event.event);
        }
    }

    private handleHiddenConnectionVisualizedEvent(event: HiddenConnectionVisualizedEvent) {
        this.onHiddenConnectionSelectionChanged(null, event);
    }

    private handleModelUpdatedOnConnectionRemovedEvent(event: IModelUpdatedOnConnectionRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_REMOVED) {
            const newSelectedConnections = this.selectedConnections.filter(connection => connection.identifier !== event.connection.identifier);
            this.onSelectionChanged(this.selectedNodes, newSelectedConnections, {});
        }
    }

    private handleModelUpdatedOnItemsRemovedEvent(event: IModelUpdatedOnItemsRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_REMOVED) {
            const removedConnectionIds = event.removedConnections?.map(connection => connection.identifier) || [];
            const newSelectedConnections = this.selectedConnections.filter(connection => removedConnectionIds.indexOf(connection.identifier) === -1);
            const removedNodeIds = event.removedNodes.map(node => node.identifier);
            const newSelectedNodes = this.selectedNodes.filter(node => removedNodeIds.indexOf(node.identifier) === -1);
            this.onSelectionChanged(newSelectedNodes, newSelectedConnections, {});
        }
    }

    private handleChartEvent(event: IChartEvent) {
        if (event.type === EventType.CHART_CANVAS_CLICKED) {
            this.onHiddenConnectionSelectionChanged(null, event.event);
            this.onSelectionChanged([], [], event.event);
        }
    }

    private handleModelUpdatedOnModelBackupLoadedEvent(event: ModelBackupEvent) {
        this.onDeselectAll(event);
    }

    onSelectionChanged(activatedNodes: Array<IDiagramNodeDto>, activatedConnections: Array<IDiagramConnectionDto>, jsEvent: any) {
        const isCtrlOrMetaPressed = SelectionManager.resolveCtrlOrMetaKeyIsPressed(jsEvent);
        if (activatedConnections.length > 0) {
            this.onHiddenConnectionSelectionChangedCompletely(null);
        }
        if (isCtrlOrMetaPressed) {
            this.onSelectionChangedIncrementally(activatedNodes, activatedConnections, jsEvent);
        } else {
            this.onSelectionChangedCompletely(activatedNodes, activatedConnections, jsEvent);
        }
    }

    onHiddenConnectionSelectionChanged(activatedConnection: IDiagramConnectionDto | null, jsEvent: any) {
        if (activatedConnection) {
            const isCtrlOrMetaPressed = SelectionManager.resolveCtrlOrMetaKeyIsPressed(jsEvent);
            if (isCtrlOrMetaPressed) {
                if (this.selectedConnections.length === 0 && this.selectedHiddenConnections.length === 0) {
                    // nothing is selected -> select activated connection
                    this.onHiddenConnectionSelectionChangedCompletely(activatedConnection);
                }
            } else {
                // clear nodes & connection selection and handle hidden connection activation
                this.onSelectionChangedCompletely([], [], jsEvent);
                this.onHiddenConnectionSelectionChangedCompletely(activatedConnection);
            }
        } else {
            this.onHiddenConnectionSelectionChangedCompletely(null);
        }
    }

    private onHiddenConnectionSelectionChangedCompletely(activatedHiddenConnection: IDiagramConnectionDto | null) {
        let selectedConnection: IDiagramConnectionDto | null = null;
        if (!this.isHiddenConnectionSelected(activatedHiddenConnection)) {
            selectedConnection = activatedHiddenConnection;
        }
        let deselectedConnection: IDiagramConnectionDto | null = null;
        if (this.shouldBeCurrentlySelectedHiddenConnectionDeselected(activatedHiddenConnection)) {
            deselectedConnection = this.selectedHiddenConnections[0];
        }
        this.selectedHiddenConnections = [];
        if (selectedConnection != null) {
            this.selectedHiddenConnections.push(selectedConnection);
        }
        this.eventManager.publishEvent({
            type: EventType.HIDDEN_CONNECTION_SELECTION_CHANGED,
            selectedConnection: selectedConnection,
            deselectedConnection: deselectedConnection,
        });
    }

    private shouldBeCurrentlySelectedHiddenConnectionDeselected(activatedConnection: IDiagramConnectionDto | null) {
        const isAnyHiddenConnectionSelectedAndActivatedConnectionEmpty =  this.selectedHiddenConnections.length > 0 && !activatedConnection;
        return this.isHiddenConnectionSelected(activatedConnection) ||
            !this.isAnyHiddenConnectionSelectedAndEquals(activatedConnection) ||
            isAnyHiddenConnectionSelectedAndActivatedConnectionEmpty;
    }

    private isHiddenConnectionSelected(connection: IDiagramConnectionDto | null) {
        return connection && this.selectedHiddenConnections.indexOf(connection) !== -1;
    }

    private isAnyHiddenConnectionSelectedAndEquals(connection: IDiagramConnectionDto | null) {
        return this.selectedHiddenConnections.length > 0 && connection && this.selectedHiddenConnections.indexOf(connection) !== -1;
    }

    onSelectionChangedIncrementally(activatedNodes: Array<IDiagramNodeDto>, activatedConnections: Array<IDiagramConnectionDto>, jsEvent: any) {
        const newlySelectedNodes = new Array<IDiagramNodeDto>();
        const newlyDeselectedNodes = new Array<IDiagramNodeDto>();
        const newlySelectedConnections = new Array<IDiagramConnectionDto>();
        const newlyDeselectedConnections = new Array<IDiagramConnectionDto>();

        activatedNodes.forEach(activatedNode => {
            const isClickedNodeAlreadySelected = this.selectedNodes.indexOf(activatedNode) !== -1;
            if (isClickedNodeAlreadySelected) {
                newlyDeselectedNodes.push(activatedNode);
                this.selectedNodes = this.selectedNodes.filter(selectedNode => selectedNode.identifier !== activatedNode.identifier);
                //this.selectionHandle.hideSelectionHandles([node]);
            } else {
                newlySelectedNodes.push(activatedNode);
                this.selectedNodes.push(activatedNode);
                //this.selectionHandle.showSelectionHandles([node]);
            }
        });
        activatedConnections.forEach(activatedConnection => {
            const isClickedConnectionAlreadySelected = this.selectedConnections.indexOf(activatedConnection) !== -1;
            if (isClickedConnectionAlreadySelected) {
                newlyDeselectedConnections.push(activatedConnection);
                this.selectedConnections = this.selectedConnections.filter(selectedConnection => selectedConnection.identifier !== activatedConnection.identifier);
                //this.selectionHandle.hideSelectionHandles([node]);
            } else {
                newlySelectedConnections.push(activatedConnection);
                this.selectedConnections.push(activatedConnection);
                //this.selectionHandle.showSelectionHandles([node]);
            }
        });

        this.eventManager.publishEvent({
            type: EventType.SELECTION_CHANGED,
            event: jsEvent,
            selectedConnections: this.selectedConnections,
            selectedNodes: this.selectedNodes,
            newlySelectedConnections: newlySelectedConnections,
            newlySelectedNodes: newlySelectedNodes,
            newlyDeselectedConnections: newlyDeselectedConnections,
            newlyDeselectedNodes: newlyDeselectedNodes,
        });
    }

    onSelectionChangedCompletely(activatedNodes: Array<IDiagramNodeDto>, activatedConnections: Array<IDiagramConnectionDto>, jsEvent: any) {
        const newlySelectedNodes = new Array<IDiagramNodeDto>();
        const newlyDeselectedNodes = new Array<IDiagramNodeDto>();
        const newlySelectedConnections = new Array<IDiagramConnectionDto>();
        const newlyDeselectedConnections = new Array<IDiagramConnectionDto>();

        const nextSelectedNodes = new Array<IDiagramNodeDto>();
        activatedNodes.forEach(activatedNode => {
            nextSelectedNodes.push(activatedNode);
            const isClickedNodeAlreadySelected = this.selectedNodes.indexOf(activatedNode) !== -1;
            if (!isClickedNodeAlreadySelected) {
                newlySelectedNodes.push(activatedNode);
            }
        });
        this.selectedNodes.forEach(selectedNode => {
            if (nextSelectedNodes.indexOf(selectedNode) === -1) {
                newlyDeselectedNodes.push(selectedNode);
            }
        })
        this.selectedNodes = nextSelectedNodes;

        const nextSelectedConnections = new Array<IDiagramConnectionDto>();
        activatedConnections.forEach(activatedConnection => {
            nextSelectedConnections.push(activatedConnection);
            const isClickedConnectionAlreadySelected = this.selectedConnections.indexOf(activatedConnection) !== -1;
            if (!isClickedConnectionAlreadySelected) {
                newlySelectedConnections.push(activatedConnection);
            }
        });
        this.selectedConnections.forEach(selectedConnection => {
            if (nextSelectedConnections.indexOf(selectedConnection) === -1) {
                newlyDeselectedConnections.push(selectedConnection);
            }
        })
        this.selectedConnections = nextSelectedConnections;

        this.eventManager.publishEvent({
            type: EventType.SELECTION_CHANGED,
            event: jsEvent,
            selectedConnections: this.selectedConnections,
            selectedNodes: this.selectedNodes,
            newlySelectedConnections: newlySelectedConnections,
            newlySelectedNodes: newlySelectedNodes,
            newlyDeselectedConnections: newlyDeselectedConnections,
            newlyDeselectedNodes: newlyDeselectedNodes,
        });
    }

    onSelectAll(event: any) {
        if (this.renderContext) {
            const modelNodes = this.renderContext.modelManager.getDiagramNodes();
            const modelConnections = this.renderContext.modelManager.getDiagramConnections();
            const newlySelectedNodes = new Array<IDiagramNodeDto>();
            const newlySelectedConnections = new Array<IDiagramConnectionDto>();

            modelNodes.forEach(modelNode => {
                if (this.selectedNodes.indexOf(modelNode) === -1) {
                    newlySelectedNodes.push(modelNode);
                    this.selectedNodes.push(modelNode);
                }
            });
            modelConnections.forEach(modelConnection => {
                if (this.selectedConnections.indexOf(modelConnection) === -1) {
                    newlySelectedConnections.push(modelConnection);
                    this.selectedConnections.push(modelConnection);
                }
            });

            this.eventManager.publishEvent({
                type: EventType.SELECTION_CHANGED,
                event: event,
                selectedConnections: this.selectedConnections,
                selectedNodes: this.selectedNodes,
                newlySelectedConnections: newlySelectedConnections,
                newlySelectedNodes: newlySelectedNodes,
                newlyDeselectedConnections: [],
                newlyDeselectedNodes: [],
            });
        }
    }

    onDeselectAll(event: any) {
        this.eventManager.publishEvent({
            type: EventType.SELECTION_CHANGED,
            event: event,
            selectedConnections: [],
            selectedNodes: [],
            newlySelectedConnections: [],
            newlySelectedNodes: [],
            newlyDeselectedConnections: this.selectedConnections,
            newlyDeselectedNodes: this.selectedNodes,
        });
        this.selectedNodes = [];
        this.selectedConnections = [];
    }

    private static resolveCtrlOrMetaKeyIsPressed(jsEvent: any) {
        if (jsEvent.ctrlKey != null) {
            return jsEvent.ctrlKey || jsEvent.metaKey;
        } else if (jsEvent.sourceEvent != null && jsEvent.sourceEvent.ctrlKey != null) {
            return jsEvent.sourceEvent.ctrlKey || jsEvent.sourceEvent.metaKey;
        } else {
            return false;
        }
    }

    getSelectedNodes() {
        return this.selectedNodes;
    }

    getSelectedConnections() {
        return this.selectedConnections;
    }

    private isRightButtonClick(event: any) {
        let which: number | undefined;
        if (event.which != null) {
            which = event.which;
        } else if (event.sourceEvent?.which != null) {
            which = event.sourceEvent.which;
        }
        return which === 3;
    }

    private isNodeSelected(node: IDiagramNodeDto) {
        return this.selectedNodes
            .filter(selectedNode => selectedNode.identifier === node.identifier)
            .length > 0;
    }

    private isConnectionSelected(connection: IDiagramConnectionDto) {
        return this.selectedConnections
            .filter(selectedConnection => selectedConnection.identifier === connection.identifier)
            .length > 0;
    }
}
