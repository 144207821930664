import logoLarge from "../../../ArchiREPO_logo_with_text.svg";
import logoSmall from "../../../ArchiREPO_logo.svg";
import {IconButton, useTheme} from "@mui/material";
import React from "react";


export interface AppLogoProps {
    size: "small" | "large";
}

export default function AppLogo(props: AppLogoProps) {
    const {size} = props;
    const theme = useTheme();

    const logo = size === "small" ? logoSmall : logoLarge;

    return (
        <IconButton
            color="inherit"
            aria-label="ArchiREPO logo"
            edge="start"
            size="large">
            <img height={theme.spacing(5)}
                 src={logo}
                 alt={"ArchiREPO"} />
        </IconButton>
    );
}