import {_transl} from "../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import Button from "@mui/material/Button";
import React from "react";


interface ChangePasswordButtonProps {
    onClick: (event?: any) => void;
}

export function ChangePasswordButton({onClick}: ChangePasswordButtonProps) {
    return (
        <Button
            color={"primary"}
            variant={"contained"}
            autoFocus={true}
            onClick={onClick}
        >
            {_transl(CommonTranslation.CHANGE_PASSWORD)}
        </Button>
    )
}


