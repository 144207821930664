import {SaveButtonStatus} from "../../pages/main/content/diagrams/DiagramEditorDialog";
import clsx from "clsx";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import {_transl} from "../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../diagrameditor/DiagramEditorTranslationKey";
import React, {useState} from "react";
import LoopIcon from "@mui/icons-material/Loop";
import ErrorIcon from "@mui/icons-material/Error";
import SaveIcon from "@mui/icons-material/Save";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import * as d3 from "d3";
import ElementsPickDialog from "../../pages/main/content/elements/ElementsPickDialog";
import {ElementDto} from "../../common/apis/element/ElementDto";
import {ToolbarDivider} from "./ToolbarDivider";
import {ToolbarIconButton} from "./ToolbarIconButton";
import DescriptionIcon from "@mui/icons-material/Description";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import RenderMode from "../../common/diagrameditor/context/RenderMode";
import {Add, BookmarkAdd} from "@mui/icons-material";
import DiagramsPickDialog from "../../pages/main/content/diagrams/DiagramsPickDialog";
import {DiagramInfoDto} from "../../common/apis/diagram/DiagramInfoDto";
import EventManager from "../../common/event/EventManager";
import {
    AddDiagramRefsRequestEvent,
    DiagramRefsEventType
} from "../../diagram/editor/editing/diagram-refs/DiagramRefsEvents";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbarButtonSaveNotNeededIcon: {
        },
        toolbarButtonSaveNeededIcon: {
            color: theme.palette.success.main,
            "&:hover": {
                color: d3.color(theme.palette.success.main)?.darker(0.5).formatRgb(),
            }
        },
        toolbarButtonSaveInProgressIcon: {
            color: theme.palette.info.main,
            "&:hover": {
                color: d3.color(theme.palette.info.main)?.darker(0.5).formatRgb(),
            }
        },
        toolbarButtonSaveFailedIcon: {
            color: theme.palette.error.main,
            "&:hover": {
                color: d3.color(theme.palette.error.main)?.darker(0.5).formatRgb(),
            }
        },
        zoomLabel: {
            width: "2.2em",
            marginLeft: "2px",
            marginRight: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            userSelect: "none",
        }
    })
);

interface Props {
    saveButtonStatus: SaveButtonStatus,
    lastUndoEvent?: string,
    firstRedoEvent?: string,
    onUndoButtonClicked: (event: any) => void,
    onRedoButtonClicked: (event: any) => void,
    zoomInDisabled: boolean,
    zoomOutDisabled: boolean,
    zoomInClicked: (event: any) => void,
    zoomOutClicked: (event: any) => void,
    zoomScale: number,
    scaleToFit: (event: any) => void,
    onToolbarSaveButtonOnClick: (event: any) => void,
    addElementsToModel: (elements: ElementDto[]) => void,
    openDiagramDetailDialog: () => void,
    eventManager?: EventManager,
    mode: RenderMode,
}

export default function Toolbar(props: Props) {

    const {saveButtonStatus, lastUndoEvent, firstRedoEvent, onUndoButtonClicked, onRedoButtonClicked,
        zoomInDisabled, zoomOutDisabled, zoomInClicked, zoomOutClicked, zoomScale, scaleToFit,
        onToolbarSaveButtonOnClick, addElementsToModel, openDiagramDetailDialog, eventManager} = props;

    const classes = useStyles();

    const [showAddElementsToModelDialog, setShowAddElementsToModelDialog] = useState<boolean>(false);
    const [showDiagramSearchDialog, setShowDiagramSearchDialog] = useState<boolean>(false);
    const isEditMode = props.mode === RenderMode.EDIT;

    function resolveToolbarSaveButtonTitle(saveButtonStatus: SaveButtonStatus) {
        switch (saveButtonStatus) {
            case SaveButtonStatus.SAVE_IN_PROGRESS: return _transl(DiagramEditorTranslationKey.TOP_MENU_SAVE_IN_PROGRESS);
            case SaveButtonStatus.SAVE_FAILED: return _transl(DiagramEditorTranslationKey.TOP_MENU_SAVE_FAILED);
            case SaveButtonStatus.SAVE_NOT_NEEDED: return _transl(DiagramEditorTranslationKey.TOP_MENU_DIAGRAM_SAVED);
            default: return _transl(DiagramEditorTranslationKey.TOP_MENU_DEFAULT_SAVE);
        }
    }

    function resolveToolbarSaveButtonIcon(saveButtonStatus: SaveButtonStatus) {
        switch (saveButtonStatus) {
            case SaveButtonStatus.SAVE_IN_PROGRESS: return <LoopIcon className={classes.toolbarButtonSaveInProgressIcon}/>;
            case SaveButtonStatus.SAVE_FAILED: return <ErrorIcon className={classes.toolbarButtonSaveFailedIcon}/>;
            case SaveButtonStatus.SAVE_NOT_NEEDED: return <SaveIcon className={classes.toolbarButtonSaveNotNeededIcon}/>;
            default: return <SaveIcon className={classes.toolbarButtonSaveNeededIcon}/>;
        }
    }

    function addDiagramRefsToDiagram(diagrams: DiagramInfoDto[]) {
        const event: AddDiagramRefsRequestEvent = {
            type: DiagramRefsEventType.ADD_DIAGRAMS_REFS_REQUEST,
            diagrams: diagrams
        };
        eventManager?.publishEvent(event);
    }

    return <>
        <ElementsPickDialog isOpened={showAddElementsToModelDialog}
                            isMultiSelection={true}
                            onElementsPicked={(elements) => {
                                setShowAddElementsToModelDialog(false);
                                addElementsToModel(elements);
                            }}
                            onDialogClosed={() => setShowAddElementsToModelDialog(false)}
                            key={"elements-pick-dialog"} />
        <DiagramsPickDialog isOpened={showDiagramSearchDialog}
                            isMultiSelection={true}
                            onDiagramsPicked={(diagrams) => {
                                setShowDiagramSearchDialog(false);
                                addDiagramRefsToDiagram(diagrams);
                            }}
                            onDialogClosed={() => setShowDiagramSearchDialog(false)}/>

        {isEditMode && [
            <ToolbarIconButton tooltip={resolveToolbarSaveButtonTitle(saveButtonStatus)}
                               icon={resolveToolbarSaveButtonIcon(saveButtonStatus)}
                               disabled={saveButtonStatus === SaveButtonStatus.SAVE_NOT_NEEDED}
                               onClick={(event) => onToolbarSaveButtonOnClick(event)}
                               key={"save-button"} />,
            <ToolbarDivider key={"save-button-divider"}/>
        ]}
        {isEditMode && [
            <ToolbarIconButton tooltip={lastUndoEvent}
                               icon={<UndoIcon />}
                               disabled={lastUndoEvent == null}
                               onClick={(event) => onUndoButtonClicked(event)}
                               key={"undo"}/>,
            <ToolbarIconButton tooltip={firstRedoEvent}
                               icon={<RedoIcon />}
                               disabled={firstRedoEvent == null}
                               onClick={(event) => onRedoButtonClicked(event)}
                               key={"redo"} />,
            <ToolbarDivider key={"undo-divider"}/>
        ]}
        <ToolbarIconButton tooltip={_transl(DiagramEditorTranslationKey.TOP_MENU_INCREASE)}
                           icon={<ZoomInIcon />}
                           disabled={zoomInDisabled}
                           onClick={(event) => zoomInClicked(event)}
                           key={"zoom-in"} />
        <span className={clsx(classes.zoomLabel)} key={"zoom-label"}>{Math.trunc(100 * zoomScale)}%</span>
        <ToolbarIconButton tooltip={_transl(DiagramEditorTranslationKey.TOP_MENU_DECREASE)}
                           icon={<ZoomOutIcon />}
                           disabled={zoomOutDisabled}
                           onClick={(event) => zoomOutClicked(event)}
                           key={"zoom-out"} />
        <ToolbarIconButton tooltip={_transl(DiagramEditorTranslationKey.TOP_MENU_DEFAULT_SIZE)}
                           icon={<FindReplaceIcon />}
                           onClick={(event) => scaleToFit(event)}
                           key={"zoom-default-size"} />
        <ToolbarDivider key={"zoom-divider"} />
        {isEditMode &&
            <>
                <ToolbarIconButton tooltip={_transl(DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ADD_ELEMENTS_FROM_REPOSITORY)}
                                   icon={<Add />}
                                   onClick={() => setShowAddElementsToModelDialog(true)}
                                   key={"add-elements-from-repo"} />
                <ToolbarIconButton tooltip={_transl(DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ADD_DIAGRAM_REFERENCE)}
                                   icon={<BookmarkAdd />}
                                   onClick={() => setShowDiagramSearchDialog(true)}
                                   key={"add-diagram-references"} />
                <ToolbarDivider key={"add-objects-to-model-divider"} />
            </>
        }
        <ToolbarIconButton tooltip={_transl(CommonTranslation.SHOW_DETAIL)}
                           icon={<DescriptionIcon />}
                           onClick={() => openDiagramDetailDialog()} key={"show-detail"} />
    </>
}
