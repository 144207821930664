import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {_transl} from "../../../../store/localization/TranslMessasge";
import React from "react";
import {GraphQueryTranslationKey} from "./GraphQueryTranslationKey";
import Divider from "@mui/material/Divider";
import GraphQueryGridWithFilter from "./GraphQueryGridWithFilter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme)
    })
);

export default function GraphQueryPage() {

    const classes = useStyles();

    return (
        <Paper className={classes.page}>
            <div className={classes.headerPageSegment}>
                <Typography variant="h6">
                    {_transl(GraphQueryTranslationKey.QUERIES_TITLE)}
                </Typography>
            </div>
            <Divider/>
            <GraphQueryGridWithFilter resourceId={"queries"}/>
        </Paper>
    );
}
