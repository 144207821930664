import {TranslationKey} from "./TranslationKey";
import store, {IApplicationState} from "../Store";
import {useSelector} from "react-redux";

interface IProps {
    key: TranslationKey,
}

export default function TranslMessage(props: IProps) {
    const translation = useSelector((state: IApplicationState) => state.translation)
    return <span>{translation[props.key]}</span>;
}

export function _transl(key: TranslationKey | string, args?: any) {
    let value = store.getState().translation[key];
    if (value && args) {
        for (let argKey in args) {
            value = value.replace('{{' + argKey + '}}', args[argKey]);
        }
    }
    return value || key.toString();
}

export type TranslationFunction = typeof _transl;