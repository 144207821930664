import EventManager, {Unsubscriber} from "../../event/EventManager";
import {EventType, IModelUpdatedEvent} from "../../event/Event";
import RenderContext from "../context/RenderContext";
import IgnoreParentChildConnectionPredicate from "./model/predicates/IgnoreParentChildConnectionPredicate";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";

export class HiddenConnectionManager {

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private connections: Array<IDiagramConnectionDto>;
    private hiddenConnectionsShown: boolean;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED, this.handleModelUpdatedEvent.bind(this)));

        this.connections = [];
        this.hiddenConnectionsShown = false;
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    handleModelUpdatedEvent(event: IModelUpdatedEvent) {
        if (event.type === EventType.MODEL_UPDATED) {
            this.refreshHiddenConnections();
        }
    }

    private refreshHiddenConnections() {
        if (this.hiddenConnectionsShown) {
            this.hideConnections();
            this.showConnections();
        }
    }

    showConnections() {
        if (this.renderContext) {
            this.connections = this.renderContext.modelManager
                .getFilteredHiddenConnections(new IgnoreParentChildConnectionPredicate());
            this.publishHiddenConnectionsUpdated();
            this.hiddenConnectionsShown = true;
        }
    }

    hideConnections() {
        this.connections = [];
        this.publishHiddenConnectionsUpdated();
        this.hiddenConnectionsShown = false;
    }

    private publishHiddenConnectionsUpdated() {
        if (this.renderContext?.isEditOrPreEdit()) {
            this.eventManager.publishEvent({
                type: EventType.HIDDEN_CONNECTIONS_UPDATED,
                connections: this.connections,
            })
        }
    }

}
