import {GraphQueryDto} from "../../../../common/apis/query/GraphQueryDto";
import Api from "../../../../common/Api";
import Constants from "../../../../common/Constants";
import {GraphQueryFilterDto} from "../../../../common/apis/query/GraphQueryFilterDto";
import {map} from "rxjs/operators";
import {GraphQueryCreateDto} from "../../../../common/apis/query/GraphQueryCreateDto";
import {GraphQueryUpdateDto} from "../../../../common/apis/query/GraphQueryUpdateDto";
import {CustomGraphQueryDto} from "../../../../common/apis/query/CustomGraphQueryDto";
import {ElementDto} from "../../../../common/apis/element/ElementDto";

const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/queries";

export interface GraphQueryService {

    findById(queryId: string): Promise<GraphQueryDto>,

    findAll(): Promise<GraphQueryDto[]>,

    deleteQuery(identifier: string): Promise<any>,

    searchByFilter(queryFilter: GraphQueryFilterDto): Promise<GraphQueryDto[]>

    create(queryCreate: GraphQueryCreateDto): Promise<string>

    update(identifier: string, queryUpdateDto: GraphQueryUpdateDto): Promise<any>

    run(identifier: string): Promise<ElementDto[]>,

    runCustomQuery(queryFilter: CustomGraphQueryDto): Promise<ElementDto[]>
}

class RxJsGraphQueryService implements GraphQueryService {

    async findAll(): Promise<GraphQueryDto[]> {
        const request = {
            url: ENDPOINT_URL + "/",
            method: "GET",
        }

        return Api.createAjax(request)
            .pipe(
                map(response => (response.response || []) as GraphQueryDto[])
            ).toPromise();
    }

    async searchByFilter(queryFilter: GraphQueryFilterDto): Promise<GraphQueryDto[]> {
        const request = {
            url: ENDPOINT_URL + ":search",
            method: "POST",
            body: queryFilter,
        };

        return Api.createAjax(request)
            .pipe(
                map(response => response.response as GraphQueryDto[])
            ).toPromise();
    }

    async deleteQuery(identifier: string): Promise<any> {
        const request = {
            url: ENDPOINT_URL + "/" + identifier,
            method: "DELETE",
        };

        return Api.createAjax(request).toPromise()
    }

    create(queryCreate: GraphQueryCreateDto): Promise<string> {
        return Api.createAjax({
            url: ENDPOINT_URL + "/",
            method: "POST",
            body: {
                name: queryCreate.name,
                description: queryCreate.description,
                queryText: queryCreate.queryText,
                isPrivate: queryCreate.isPrivate,
            }
        })
            .pipe(map(response => response.response as string))
            .toPromise();
    }

    findById(queryId: string): Promise<GraphQueryDto> {
        const request = {
            url: ENDPOINT_URL + "/" + queryId,
            method: "GET"
        };

        return Api.createAjax(request)
            .pipe(
                map(response => (response.response) as GraphQueryDto)
            ).toPromise();
    }

    update(identifier: string, queryUpdateDto: GraphQueryUpdateDto): Promise<any> {
        return Api.createAjax({
            url: ENDPOINT_URL + "/" + identifier,
            method: "PATCH",
            body: {
                name: queryUpdateDto.name,
                description: queryUpdateDto.description,
                isPrivate: queryUpdateDto.isPrivate,
                queryText: queryUpdateDto.queryText
            }
        }).toPromise();
    }

    async run(identifier: string): Promise<ElementDto[]> {
        const request = {
            url: ENDPOINT_URL + "/" + identifier + ":run",
            method: "POST",
        };

        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ElementDto[])
            ).toPromise();
    }

    async runCustomQuery(customGraphQuery: CustomGraphQueryDto): Promise<ElementDto[]> {
        const request = {
            url: ENDPOINT_URL + ":run-custom-query",
            method: "POST",
            body: customGraphQuery,
        };

        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ElementDto[])
            )
            .toPromise();
    }
}

export default function graphQueryService(): GraphQueryService {
    return new RxJsGraphQueryService() as GraphQueryService;
}
