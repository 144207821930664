import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import DiagramViewer from "../diagrams/DiagramViewer";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DashboardTranslationKey} from "./DashboardTranslationKey";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {Paper} from "@mui/material";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme, {height: "90vh", display: "flex", flexDirection: "column"}),
        controlPageSegment:  {
            position: "relative",
            flexGrow: 1,
            marginBottom: "10px",
        },
        iFrame: {
            width: "100%",
            height: "100%",
        }
    })
);

export default function DashboardPage() {

    const mainWidget = useSelector((state: IApplicationState) => state.dashboard.mainWidget);
    const diagramId = mainWidget.diagramIdentifier;
    const classes = useStyles();
    const url = mainWidget.url;

    return (
        <>
            <Paper className={classes.page}>
                {diagramId &&
                    <div className={classes.controlPageSegment}>
                        <DiagramViewer
                            diagramId={diagramId}
                            renderMode={RenderMode.DASHBOARD}/>
                    </div>
                }
                {url && <iframe className={classes.iFrame} src={url} title={_transl(DashboardTranslationKey.IFRAME_TITLE)} />}
                {!url && !diagramId && <div/>}
            </Paper>
        </>
    )
}

