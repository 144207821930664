import React, {useEffect, useState} from "react";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton, ListItem
} from "@mui/material";
import {ButtonLayout, NegativeButtons, PositiveButtons} from "../../../../components/button/ButtonLayout";
import {YesButton} from "../../../../components/button/YesButton";
import {NoButton} from "../../../../components/button/NoButton";
import createElementDetailController from "../elementdetail/controller/ElementDetailController";
import elementService from "../elementdetail/service/ElementService";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import List from "@mui/material/List";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "./ElementTranslationKey";
import diagramService from "../diagrams/service/DiagramService";
import constructElementDetailController from "../elementdetail/controller/ElementDetailController";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinner: {
            minWidth: "1em",
            color: "red",
        },
        loadedText: {
            whiteSpace: "nowrap"
        }
    })
);

enum LoadStatus {
    LOAD_STATUS_NOT_STARTED = "LOAD_STATUS_NOT_STARTED",
    LOAD_STATUS_PENDING = "LOAD_STATUS_PENDING",
    LOAD_STATUS_SUCCEEDED = "LOAD_STATUS_SUCCEEDED",
    LOAD_STATUS_FAILED = "LOAD_STATUS_FAILED",
}


export interface ConfirmationInfoDialogProps {
    open: boolean,
    elementId: string,
    onConfirm: (e: any) => void,
    onCancel: (e: any) => void,
}

export default function DeleteConfirmationInfoDialog(props: ConfirmationInfoDialogProps) {

    const {open, onConfirm, onCancel, elementId} = props;
    const errorMessageText = _transl(ElementTranslationKey.ERROR_MESSAGE_TEXT);
    const relationshipCountText = _transl(ElementTranslationKey.RELATIONSHIP_COUNT_TEXT);
    const diagramsCountText = _transl(ElementTranslationKey.DIAGRAMS_COUNT_TEXT);
    const title = _transl(ElementTranslationKey.CONFIRMATION_DIALOG_DELETE_TITLE)
    const confirmationText = _transl(ElementTranslationKey.CONFIRMATION_DIALOG_DELETE_CONFIRMATION_TEXT)
    const classes = useStyles();
    const [relationshipCount, setRelationshipCount] = useState<number | string>();
    const [diagramsCount, setDiagramsCount] = useState<number | string>();
    const [diagramsCountLoadStatus, setDiagramsCountLoadStatus] = useState<LoadStatus>(LoadStatus.LOAD_STATUS_NOT_STARTED)
    const [relationshipCountLoadStatus, setRelationshipCountLoadStatus] = useState<LoadStatus>(LoadStatus.LOAD_STATUS_NOT_STARTED)


    useEffect(() => {
        (async () => {
            try {
                setRelationshipCountLoadStatus(LoadStatus.LOAD_STATUS_PENDING)
                const relationshipsCount = await createElementDetailController(elementService, diagramService).getRelationshipsCount(elementId);
                setRelationshipCountLoadStatus(LoadStatus.LOAD_STATUS_SUCCEEDED)
                setRelationshipCount(relationshipsCount);
            } catch (error) {
                setRelationshipCountLoadStatus(LoadStatus.LOAD_STATUS_FAILED)
                setRelationshipCount(errorMessageText);
            }
        })();
    }, [elementId, errorMessageText]);

    useEffect(() => {
        (async () => {
            try {
                setDiagramsCountLoadStatus(LoadStatus.LOAD_STATUS_PENDING)
                const diagramsCount = await constructElementDetailController(elementService, diagramService).getDiagramsContainingElementCount(elementId);
                setDiagramsCountLoadStatus(LoadStatus.LOAD_STATUS_SUCCEEDED)
                setDiagramsCount(diagramsCount);
            } catch (error) {
                setDiagramsCountLoadStatus(LoadStatus.LOAD_STATUS_FAILED)
                setDiagramsCount(errorMessageText);
            }
        })();
    }, [elementId, errorMessageText]);


    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="yes-no-dialog"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {confirmationText}
                    <List>
                        <ListItem>
                            {relationshipCountLoadStatus === LoadStatus.LOAD_STATUS_PENDING &&
                                <span className={classes.loadedText}>
                                    {relationshipCountText}
                                    <IconButton aria-label="pending"
                                                size={"small"}
                                    >
                                        <CircularProgress size={"small"} className={classes.spinner}/>
                                    </IconButton>
                                </span>
                            }
                            {relationshipCountLoadStatus === LoadStatus.LOAD_STATUS_SUCCEEDED &&
                                `${relationshipCountText}: ${relationshipCount}`
                            }
                            {relationshipCountLoadStatus === LoadStatus.LOAD_STATUS_FAILED &&
                                `${relationshipCountText}: ${errorMessageText}`
                            }
                        </ListItem>
                        <ListItem>
                            {diagramsCountLoadStatus === LoadStatus.LOAD_STATUS_PENDING &&
                                <span className={classes.loadedText}>
                                    {diagramsCountText}
                                    <IconButton aria-label="pending"
                                                size={"small"}
                                    >
                                        <CircularProgress size={"small"} className={classes.spinner}/>
                                    </IconButton>
                                </span>
                            }
                            {diagramsCountLoadStatus === LoadStatus.LOAD_STATUS_SUCCEEDED &&
                                `${diagramsCountText}: ${diagramsCount}`
                            }
                            {diagramsCountLoadStatus === LoadStatus.LOAD_STATUS_FAILED &&
                                `${diagramsCountText}: ${errorMessageText}`
                            }
                        </ListItem>
                    </List>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonLayout>
                    <PositiveButtons>
                        <YesButton onClick={onConfirm} variant="contained"/>
                    </PositiveButtons>
                    <NegativeButtons>
                        <NoButton onClick={onCancel} variant="contained"/>
                    </NegativeButtons>
                </ButtonLayout>
            </DialogActions>
        </Dialog>
    )
}
