export enum OrderDirection {
    ASCENDING = "ASCENDING",
    DESCENDING = "DESCENDING"
}

export interface ColumnPresets {
    field: string;
    hidden?: boolean;
    orderDirection?: OrderDirection;
    sortOrder?: number;
    width?: number;
}

export interface GridPresets {
    columns: ColumnPresets[];
}