import React, {ReactNode} from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import EditableComponent, {EDIT_ROOT_SUBCOMPONENT_ROLE} from "./EditableComponent";
import {MenuItem, TextField} from "@mui/material";

const styles = (theme: Theme) => createStyles({
    root: {
        "& > *": {
            flexGrow: 1,
        },
        display: "flex",
    },
});

interface IProps<T> extends WithStyles<typeof styles> {
    label: string,
    initialValues: Array<T>,
    initialSelectedValue?: T
    isOptional: boolean,
    getValueLabel: (value?: T) => string,
    renderValueLabel?: (value?: T) => JSX.Element,
    getValueId: (value?: T) => string,
    doUpdate: (value?: T) => Promise<any>,
    onSuccessfulUpdate?: (value?: T) => void,
    id?: string,
    readonly?: boolean,
}

interface IState {
    selectedIndex: number,
    selectOpened: boolean,
}

class EditableSelectComponent<T> extends React.Component<IProps<T>, IState> {

    editRootRole: string;

    constructor(props: IProps<T>) {
        super(props);
        this.editRootRole = `${EDIT_ROOT_SUBCOMPONENT_ROLE}_${Math.random()}`;
        const { initialSelectedValue } = this.props;
        const initialIndex = this.getValueIndex(initialSelectedValue);
        this.state = this.createInitialState(initialIndex);
    }

    createInitialState(initialIndex: number) {
        return {
            selectedIndex: initialIndex,
            selectOpened: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps<T>>, prevState: Readonly<IState>, snapshot?: any) {
        const {initialSelectedValue} = this.props;

        if (initialSelectedValue !== prevProps.initialSelectedValue) {
            this.updateState([{propertyName: "selectedIndex", value: this.getValueIndex(initialSelectedValue)}]);
        }
    }

    getValueIndex(value?: T) {
        const {initialValues} = this.props;
        return value == null ? -1 : initialValues.indexOf(value);
    }

    updateState<V extends keyof IState>(newPropertyValues: Array<{propertyName: V, value: IState[V]}>, callback?: (state: IState) => void) {
        const stateCopy: IState = {...this.state};
        newPropertyValues.forEach(propertyValue => stateCopy[propertyValue.propertyName] = propertyValue.value);
        this.setState(stateCopy, () => {if (callback) { callback(stateCopy)}});
    }

    render() {
        const { label, getValueLabel, doUpdate, onSuccessfulUpdate, id, initialValues, initialSelectedValue, getValueId, readonly } = this.props;
        const { selectedIndex } = this.state;

        const resolvedGetValueLabel = (index: number) => {
            return index === -1 ? " " : getValueLabel(initialValues[index] as T);
        }
        const resolvedDoUpdate = (index: number) => {
            const value = index === -1 ? undefined : initialValues[index];
            return doUpdate(value);
        }
        const resolvedSuccessfulUpdate = (index: number) => {
            const value = index === -1 ? undefined : initialValues[index];
            if (onSuccessfulUpdate) {
                onSuccessfulUpdate(value);
            }
        }

        return (
            <EditableComponent label={label}
                               initialValue={this.getValueIndex(initialSelectedValue)}
                               getEditedValue={() => selectedIndex}
                               valueToViewString={resolvedGetValueLabel as (value: unknown) => string}
                               valuesEqual={(value, newValue) => getValueId(value as T) === getValueId(newValue as T)}
                               getEditComponent={(cancelUpdate, saveChanges) => this.renderEditComponent(cancelUpdate, saveChanges)}
                               onEditModeEntered={() => this.updateState([{propertyName: "selectOpened", value: true}])}
                               focusEditComponent={() => {}}
                               doUpdate={resolvedDoUpdate as (value: unknown) => Promise<any>}
                               onSuccessfulUpdate={resolvedSuccessfulUpdate as (value: unknown) => void}
                               onFailedUpdate={() => {}}
                               onCancelChanges={() => {this.updateState([{propertyName: "selectedIndex", value: this.getValueIndex(initialSelectedValue)}])}}
                               id={id}
                               editRootRole={this.editRootRole}
                               readonly={readonly}
            />
        );
    }

    renderEditComponent(cancelUpdate: () => void, saveChanges: () => void) {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {this.renderSelect(cancelUpdate, saveChanges)}
            </div>
        );
    }

    private renderValueLabel(value: T): ReactNode {
        const {renderValueLabel, getValueLabel} = this.props;
        return renderValueLabel ? renderValueLabel(value) : getValueLabel(value);
    }

    private renderSelect(cancelUpdate: () => void, saveChanges: () => void): JSX.Element {
        const { id, label, initialValues, isOptional } = this.props;
        const { selectedIndex, selectOpened } = this.state;

        return <TextField id={id}
                          select
                          label={label}
                          value={`${selectedIndex}`}
                          onChange={(e) => {
                              e.stopPropagation();
                              this.updateState([
                                  {propertyName: "selectedIndex", value: parseInt(e.target.value)},
                                  {propertyName: "selectOpened", value: false},
                              ], () => saveChanges());
                          }}
                          variant="outlined"
                          size={"small"}
                          SelectProps={{
                              MenuProps: {
                                  role: this.editRootRole,
                              },
                              open: selectOpened,
                          }}
                          InputProps={{
                              onClick: (e) => {
                                  setTimeout(() => this.updateState([{propertyName: "selectOpened", value: true}]), 100);
                              },
                          }}
        >
            {
                isOptional &&
                <MenuItem key={"-1"}
                          value={"-1"}>
                    <em>&nbsp;&nbsp;</em>
                </MenuItem>
            }
            {initialValues.map((value, index) => <MenuItem key={`${index}`} value={`${index}`}>{this.renderValueLabel(value)}</MenuItem>)}
        </TextField>
    }

}

export default withStyles(styles, { withTheme: true })(EditableSelectComponent);
