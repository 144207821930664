import * as d3 from "d3";
import {RGBColor} from "d3";
import {ElementApi} from "../GraphDataChart";
import {INode} from "../manager/ModelManager";
import EventManager from "../manager/EventManager";
import {EventType} from "../event/Event";
import {ArchimateElement} from "../../../common/archimate/ArchimateElement";
import store from "../../../store/Store";
import {getDefaultElementsBgColor} from "../../../common/apis/diagram/DefaultColorsDto";

export default class NodesRenderer {

    public static readonly NODE_RADIUS = 25;
    public static readonly ON_MOUSE_OVER_BG_COLOR = "rgb(61, 73, 119, 0.2)";
    public static readonly NODE_NAME_MAX_LENGTH = 25;

    private eventManager: EventManager;
    private elementApi: ElementApi;

    constructor(elementApi: ElementApi, eventManager: EventManager) {
        this.elementApi = elementApi;
        this.eventManager = eventManager;
    }

    public render(chartGroup: d3.Selection<any, unknown, null, undefined>,
                  nodes: INode[],
                  rootElementIds: Array<string>,
                  explodedElementIds: Array<string>)
    {
        const nodeElems = chartGroup.append("g")
            .attr("fill", "currentColor")
            .attr("stroke-linecap", "round")
            .attr("stroke-linejoin", "round")
            .selectAll("g")
            .data(nodes)
            .join("g")
            .call(this.drag());

        // append title to node groups
        nodeElems.append("title")
            .text(d => (d.archimateElement?.visibleName || d.type) + "\n" + d.name + "\n" + (d.description || ""));

        // append onmouseover circles to node groups
        nodeElems.append("circle")
            .attr("id", d => NodesRenderer.createSelectionNodeId(d))
            .attr("data-type", d =>  rootElementIds.indexOf(d.id) !== -1 ? "root" : "neighbour")
            .attr("r", NodesRenderer.NODE_RADIUS + 12)
            .style("visibility", d => rootElementIds.indexOf(d.id) !== -1 ? "visible" : "hidden")
            .attr("fill", d => rootElementIds.indexOf(d.id) !== -1 ? "rgb(170, 170, 170, 0.3)" : NodesRenderer.ON_MOUSE_OVER_BG_COLOR);

        // append circles to node groups
        nodeElems.append("circle")
            .attr("id", d => NodesRenderer.createNodeId(d))
            .attr("stroke", d => (d3.color(NodesRenderer.getNodeBgColor(d)) as RGBColor).darker().formatRgb())
            .attr("stroke-width", 1.5)
            .attr("cursor", d => explodedElementIds.indexOf(d.id) === -1 ? "pointer" : "default")
            .attr("r", NodesRenderer.NODE_RADIUS)
            .attr('fill', d => NodesRenderer.getNodeBgColor(d))
            .on("mouseover", (event, node) => this.eventManager.publishNodeEvent({type: EventType.NODE_MOUSE_ENTER, event: event, node: node}))
            .on("mouseout", (event, node) => this.eventManager.publishNodeEvent({type: EventType.NODE_MOUSE_LEAVE, event: event, node: node}))
            .on('click', (event, node) => {
                event.stopPropagation();
                this.eventManager.publishNodeEvent({type: EventType.NODE_MOUSE_CLICK, event: event, node: node})
            })
            .on('dblclick', (event, node) => {
                event.preventDefault();
                event.stopPropagation();
                this.eventManager.publishNodeEvent({type: EventType.NODE_MOUSE_DBLCLICK, event: event, node: node});
            });

        // append texts to node groups
        nodeElems.append("text")
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .attr("y", NodesRenderer.NODE_RADIUS + 10)
            .text(d => NodesRenderer.trimNodeName(d.name))
            .clone(true).lower()
            .attr("fill", "none")
            .attr("stroke", "white")
            .attr("stroke-width", 3);

        // append icons to node groups
        nodeElems.append('text')
            .attr('class', "archimate-icon")
            .attr('fill', "#000000")
            .attr('font-size', NodesRenderer.NODE_RADIUS+"px")
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .attr('y', parseInt(Math.round(NodesRenderer.NODE_RADIUS * 0.4)+"")+"px")
            .text(d => {
                const codepoint = parseInt(ArchimateElement.getCodepoint(d.archimateElement?.elementType));
                return String.fromCharCode(codepoint);
            });

        return nodeElems;
    }

    private drag(): any {
        return d3.drag()
            .on("start", (event: any, node) => this.eventManager.publishNodeEvent({type: EventType.NODE_DRAG_START, event: event, node: node as INode}))
            .on("drag", (event: any, node) => this.eventManager.publishNodeEvent({type: EventType.NODE_DRAG_IN_PROGRESS, event: event, node: node as INode}))
            .on("end", (event: any, node) => this.eventManager.publishNodeEvent({type: EventType.NODE_DRAG_END, event: event, node: node as INode}));
    }

    public static trimNodeName(name: string) {
        if (!name) {
            return "";
        }
        return name.length > NodesRenderer.NODE_NAME_MAX_LENGTH ? name.substring(0, NodesRenderer.NODE_NAME_MAX_LENGTH) + " ..." : name;
    }

    public static createNodeId(node: INode) {
        return "nodeid-"+node.id;
    }

    public static createSelectionNodeId(node: INode) {
        return "selection-"+this.createNodeId(node);
    }

    public static getNodeBgColor(node: INode) {
        return getDefaultElementsBgColor(node.type, store.getState().diagramDefaults.defaultColors);
    }

}