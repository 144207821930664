import {ElementDto} from "./element/ElementDto";
import {RelationshipDto} from "./relationship/RelationshipDto";
import Constants from "../Constants";
import {AjaxRequest} from "rxjs/internal/observable/dom/AjaxObservable";
import Api from "../Api";

export interface MetamodelValidationDto {
    testedDiagramIdentifier: string,
    metamodelDiagramIdentifier: string,
    extractMetamodel: boolean,
}

export interface MetamodelValidationReportDto {
    reportedElements: ElementDto[]
    reportedRelationships: RelationshipDto[],
}

export class MetamodelValidationReportDto {
    static builder(): MetamodelValidationReportDtoBuilder {
        return new MetamodelValidationReportDtoBuilder();
    }

    static isValid(report: MetamodelValidationReportDto): boolean {
        return report.reportedElements.length === 0 && report.reportedRelationships.length === 0;
    }
}

export class MetamodelValidationReportDtoBuilder {
    constructor(private _reportedElements: ElementDto[] = [],
                private _reportedRelationships: RelationshipDto[] = []) {
    }

    reportedElements(reportedElements: ElementDto[]) {
        this._reportedElements = reportedElements;
        return this;
    }

    reportedRelationships(reportedRelationships: RelationshipDto[]) {
        this._reportedRelationships = reportedRelationships;
        return this;
    }

    build(): MetamodelValidationReportDto {
        return {
            reportedElements: this._reportedElements,
            reportedRelationships: this._reportedRelationships,
        };
    }

}

export interface ValidateModelService {
    validateModel(metamodelValidationDto: MetamodelValidationDto): Promise<any>,
}

const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/repository/metamodel/";
const VALIDATE_MODEL_URL: string = `${ENDPOINT_URL}validate/xlsx`;

class RxJsValidateModelService implements ValidateModelService {

    async validateModel(metamodelValidationDto: MetamodelValidationDto): Promise<any> {
        const request: AjaxRequest = {
            url: VALIDATE_MODEL_URL,
            method: "POST",
            responseType: "blob",
            body: metamodelValidationDto,
        };
        return Api.createAjax(request).toPromise();
    }
}

export default new RxJsValidateModelService() as ValidateModelService;