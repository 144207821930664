import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Constants from "../../../../../common/Constants";
import FolderViewTypeSelect from "./FolderViewTypeSelect";
import {FolderViewType} from "./FolderViewType";
import {useEffect, useState} from "react";
import {ISelectionChangedEvent} from "../../../../../common/event/Event";
import IDiagramEditorApi from "../../../../../common/diagrameditor/api/IDiagramEditorApi";
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import {IconButton, Tooltip} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {ItemType} from "../modeltree/factory/Item";
import {IDiagramNodeDto} from "../../../../../common/apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../../../../common/apis/diagram/IDiagramConnectionDto";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../../../DiagramEditorTranslationKey";

const useLeftMenuOptionsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            position: "fixed",
            backgroundColor: Constants.MENU_OPTIONS_BACKGROUND_COLOR,
            display: "flex",
            zIndex: 1,
            borderTop: `1px solid ${Constants.MENU_BACKGROUND_COLOR_DARKER}`,
            borderRight: `1px solid ${Constants.MENU_BACKGROUND_COLOR_DARKER}`,
            borderBottom: `1px solid ${Constants.MENU_BACKGROUND_COLOR_DARKER}`,
            padding: 3,
            paddingLeft: 4,
            alignItems: "center",
            "& > div": {
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                "&:first-child": {
                    justifyContent: "flex-start",
                },
                "&:last-child": {
                    justifyContent: "flex-end",
                },
            },
        },
        menuIconButton: {
            alignSelf: "right",
            "& .MuiSvgIcon-root": {
                fontSize: ".9em",
            }
        },
    })
);

interface SelectedDiagramItems {
    selectedNodes: Array<IDiagramNodeDto>,
    selectedConnections: Array<IDiagramConnectionDto>,
}

interface IProps {
    initialFolderViewType: FolderViewType,
    onFolderViewTypeChanged?: (folderViewType: FolderViewType) => void,
    onCenteredItemUpdated?: (itemId: string, itemType: ItemType) => void,
    diagramEditorApi: IDiagramEditorApi | undefined;
    onSearchButtonClicked: () => void,
}

export default function LeftMenuOptionsPanel(props: IProps) {
    const {diagramEditorApi, onCenteredItemUpdated, onSearchButtonClicked} = props;
    const classes = useLeftMenuOptionsStyles();

    // state
    const [selectedDiagramItems, setSelectedDiagramItems] = useState<SelectedDiagramItems>();

    // effects
    useEffect(() => {
        let ignoreEvents = false;
        const unsubscriber = diagramEditorApi?.addSelectionChangedListener(
            (event: ISelectionChangedEvent) => {
                if (!ignoreEvents) {
                    setSelectedDiagramItems({
                        selectedNodes: event.selectedNodes,
                        selectedConnections: event.selectedConnections,
                    });
                }
            }
        );
        return () => {
            ignoreEvents = true;
            unsubscriber && unsubscriber();
        }
    }, [diagramEditorApi]);

    // functions
    function isSelectItemButtonEnabled() {
        return (selectedDiagramItems &&
            ((selectedDiagramItems.selectedConnections.length === 1 &&
                selectedDiagramItems.selectedConnections[0].relationshipIdentifier != null &&
                    selectedDiagramItems.selectedNodes.length === 0) ||
                (selectedDiagramItems.selectedNodes.length === 1 &&
                    selectedDiagramItems.selectedNodes[0].elementIdentifier != null &&
                    selectedDiagramItems.selectedConnections.length === 0)));

    }

    function onSelectItemButtonClicked() {
        if (isSelectItemButtonEnabled() && onCenteredItemUpdated && selectedDiagramItems) {
            if (selectedDiagramItems.selectedConnections.length === 1) {
                onCenteredItemUpdated(selectedDiagramItems.selectedConnections[0].relationshipIdentifier as string, ItemType.RELATIONSHIP);
            } else {
                onCenteredItemUpdated(selectedDiagramItems.selectedNodes[0].elementIdentifier as string, ItemType.ELEMENT);
            }
        }
    }

    return <div className={classes.root}>
        <div>
            <FolderViewTypeSelect initialFolderViewType={props.initialFolderViewType} onFolderViewTypeChanged={props.onFolderViewTypeChanged}/>
        </div>
        <div>
            <Tooltip title={_transl(DiagramEditorTranslationKey.LEFT_MENU_OPTIONS_VIEW_HIDE_FILTER)}>
                <IconButton size={"small"}
                            className={classes.menuIconButton}
                            onClick={() => onSearchButtonClicked()}
                >
                    <SearchIcon fontSize={"small"}  />
                </IconButton>
            </Tooltip>
            <Tooltip title={_transl(DiagramEditorTranslationKey.MODEL_TREE_SELECT_IN_DIAGRAM_MODEL)}>
                <span>
                    <IconButton size={"small"}
                                className={classes.menuIconButton}
                                disabled={!isSelectItemButtonEnabled()}
                                onClick={() => onSelectItemButtonClicked()}
                    >
                        <FilterTiltShiftIcon fontSize={"small"}  />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    </div>
}
