import {NodeType} from "./NodeType";
import {StyleDto} from "./StyleDto";
import {Area} from "../../diagrameditor/util/GeometryUtils";
import {Styleable} from "./Styleable";

export function createDiagramNodeDto(identifier: string,
                                     elementIdentifier: string | undefined,
                                     type: NodeType,
                                     area: Area,
                                     childNodes?: Array<IDiagramNodeDto>,
                                     label?: string,
                                     description?: string,
                                     style?: StyleDto,
                                     diagramReferences?: Array<string>): IDiagramNodeDto {
    return {
        identifier: identifier,
        elementIdentifier: elementIdentifier,
        type: type,
        x: area.x,
        y: area.y,
        w: area.w,
        h: area.h,
        childNodes: childNodes,
        label: label,
        description: description,
        style: style,
        diagramReferences: diagramReferences,
    }
}

export interface IDiagramNodeDto extends Styleable {
    identifier: string,
    label?: string,
    description?: string,
    type: NodeType,
    x: number,
    y: number,
    w: number,
    h: number,
    elementIdentifier?: string,
    childNodes?: Array<IDiagramNodeDto>,
    diagramReferences?: Array<string>,
}