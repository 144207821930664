import React, {useState} from "react";
import Dialog from "../../../../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../../../../components/dialogs/DialogTitle";
import {_transl} from "../../../../../../../../store/localization/TranslMessasge";
import DialogContent from "../../../../../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../../../../../components/dialogs/DialogActions";
import {CancelButton} from "../../../../../../../../components/button/CancelButton";
import {HierarchyTreeTranslationKey} from "../../../../HierarchyTreeTranslationKey";
import GraphQueryGridWithFilter from "../../../../../graphquery/GraphQueryGridWithFilter";
import {GraphQueryDto} from "../../../../../../../../common/apis/query/GraphQueryDto";
import Button from "@mui/material/Button";
import {GraphQueryGridMode} from "../../../../../graphquery/GraphQueryGrid";

export interface QueryTextPrefillDialogProps {
    onClose: () => void,
    onPrefill: (queryText: string) => void
}

export function QueryTextPrefillDialog({onClose, onPrefill}: QueryTextPrefillDialogProps) {

    const [selectedQuery, setSelectedQuery] = useState<GraphQueryDto>();
    const [resourceId, setResourceId] = useState<string>(new Date().toISOString());

    function handleOnPrefill() {
        onPrefill(selectedQuery?.queryText || "");
        onClose();
        resetSelection();
    }

    function handleOnCancel() {
        onClose();
        resetSelection();
    }

    function resetSelection() {
        setSelectedQuery(undefined);
        setResourceId(new Date().toISOString());
    }

    return (
        <Dialog open={true} onClose={handleOnCancel} maxWidth={"xl"}>
            <DialogTitle id="query-text-prefill-dialog-title"
                         title={_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_QUERY_PREFILL_TITLE)}
                         onDialogClosed={handleOnCancel}
            />
            <DialogContent>
                <GraphQueryGridWithFilter onGridRowChange={setSelectedQuery}
                                          mode={GraphQueryGridMode.NO_GRID_ACTION_BUTTON}
                                          resourceId={resourceId}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color={"primary"}
                    variant={"contained"}
                    autoFocus={true}
                    disabled={selectedQuery === undefined}
                    onClick={() => handleOnPrefill()}
                >
                    {_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_QUERY_PREFILL)}
                </Button>
                <CancelButton onClick={handleOnCancel}/>
            </DialogActions>
        </Dialog>
    );


}
