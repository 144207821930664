import SelectionManager, {SelectableObjectType} from "../SelectionManager";
import * as d3 from "d3";
import NodeRendererUtils from "../../renderer/node/NodeRendererUtils";
import ConnectionRendererUtils from "../../renderer/connection/ConnectionRendererUtils";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../../apis/diagram/IDiagramConnectionDto";

export default class SelectionManagerUtils {

    static initNode(node: IDiagramNodeDto) {
        const group = d3.select(NodeRendererUtils.createNodeOrderGroupId(node, true));
        this.initSelectableObject(group, node, SelectableObjectType.NODE);
    }

    static initConnection(connection: IDiagramConnectionDto) {
        const group = d3.select(ConnectionRendererUtils.createConnectionOrderGroupId(connection, true));
        this.initSelectableObject(group, connection, SelectableObjectType.CONNECTION);
    }

    private static initSelectableObject(group: d3.Selection<any, unknown, any, any>,
                                        object: any,
                                        type: SelectableObjectType) {
        group.property(SelectionManager.SELECTABLE_OBJECT_TYPE_PROPERTY_NAME, type);
        group.property(SelectionManager.SELECTABLE_OBJECT_PROPERTY_NAME, object);
        group.classed(SelectionManager.SELECTABLE_OBJECT_CLASS_NAME, true);
    }

    static createDragSelectionOverlayId(selector?: boolean) {
        return (selector === true ? "#" : "") + "__drag-selection-overlay__";
    }

    static getAllSelectableObjectGroups() {
        return d3.selectAll("." + SelectionManager.SELECTABLE_OBJECT_CLASS_NAME) as d3.Selection<any, unknown, any, any>;
    }

    static getSelectableProperty(group: SVGGElement, propertyName: string): any | null {
        const property = d3.select(group).property(propertyName);
        return property || null;
    }

}