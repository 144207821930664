import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../../../../../store/Store";
import React from "react";
import MultiselectComboBox from "../../../../../../../../components/fields/MultiselectComboBox";
import {_transl} from "../../../../../../../../store/localization/TranslMessasge";
import {HierarchyTreeTranslationKey} from "../../../../HierarchyTreeTranslationKey";
import {DataTypeSubformProps} from "../DataTypeSubformProps";
import {CollectionsTreeDataDto} from "../../../../service/HierarchyTreeDataDto";
import CollectionViewModeSelect from "./CollectionViewModeSelect";
import {CollectionTreeViewMode} from "../../../../service/CollectionTreeViewMode";
import {CollectionDto} from "../../../../../../../../common/apis/collection/CollectionDto";
import Grid from "../../../../../../../../components/dialogs/Grid";

export default function CollectionsSubform({data, onChange, validationResult}: DataTypeSubformProps) {
    const collectionOptions = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);

    const collectionsTreeData = data as CollectionsTreeDataDto;

    const viewMode = collectionsTreeData.viewMode;
    const collections = collectionsTreeData.collections.map(code => getCollectionFromCode(code));
    const error = validationResult && validationResult.errors.length > 0 && collections.length === 0;

    function getCollectionFromCode(collectionCode: string): CollectionDto {
        return collectionOptions.filter((collection) => collection.code === collectionCode)[0];
    }

    function onCollectionsAssigned(collections: CollectionDto[]): void {
        const collectionCodes: string[] = collections.map((collection) => collection.code);
        const treeData: CollectionsTreeDataDto = {...collectionsTreeData, collections: collectionCodes};
        onChange(treeData);
    }

    function onViewModeChanged(viewMode: CollectionTreeViewMode): void {
        const treeData: CollectionsTreeDataDto = {...collectionsTreeData, viewMode: viewMode};
        onChange(treeData);
    }

    return (
        <>
            <Grid item xs={12} sm={6}>
                <MultiselectComboBox
                    label={_transl(HierarchyTreeTranslationKey.SUBFORM_COLLECTIONS)}
                    id="collections-subform-multiselect-combobox"
                    className={"Collections-subform-multiselect-combobox"}
                    options={collectionOptions}
                    selectedValues={collections}
                    getRenderLabel={(value) => value.name}
                    handleOnChange={(changedValues) => onCollectionsAssigned(changedValues)}
                    errorMessage={error ? _transl(validationResult.errors[0].errorMessage) : undefined}
                    required={true}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <CollectionViewModeSelect
                    value={viewMode}
                    onChange={onViewModeChanged}
                />
            </Grid>
        </>
    );
}
