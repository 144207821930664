import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {FormControl, FormControlLabel, IconButton, Paper, RadioGroup, TextField, Tooltip} from '@mui/material';
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DashboardTranslationKey} from "./DashboardTranslationKey";
import SearchIcon from '@mui/icons-material/Search';
import DiagramsPickDialog from "../diagrams/DiagramsPickDialog";
import {SaveButton} from "../../../../components/button/SaveButton";
import Radio from '@mui/material/Radio';
import * as d3 from "d3";
import diagramsApi from "../../../../common/apis/DiagramsApi";
import {useDispatch, useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import {DiagramDto} from "../../../../common/apis/diagram/DiagramDto";
import {getSaveDashboardAction} from "../../../../store/dashboard/DashboardReducer";
import {Dispatch} from "redux";
import dashboardService, {WidgetType, WidgetUpdateDto} from "../../../../common/apis/DashboardService";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import Snackbar from "../snackbar/Snackbar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: CommonCssStyles.getRootPageMargin(theme),
            padding: CommonCssStyles.getRootPagePadding(theme),
            width: "100%",
            height: "100%",
        },
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        pageContent: {
            paddingRight: 20,
        },
        radio: {
            padding: theme.spacing(3, 0, 0, 3),
        },
        textfield: {
            padding: theme.spacing(3, 0, 0, 3),
            display: "flex",
            "& > *:first-child": {
                flexGrow: 1,
            }
        },
        iconButton: {
            marginLeft: 6,
            color: "white",
            backgroundColor: theme.palette.primary.main,
            borderColor: d3.color(theme.palette.primary.main)?.darker(1).formatRgb(),
            "&:hover": {
                backgroundColor: d3.color(theme.palette.primary.main)?.darker(0.5).formatRgb(),
            }
        },
        saveButton: {
            display: 'flex',
            flexDirection: "row-reverse",
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
    })
);


export default function DashboardAdministrationPage() {

    const mainWidget = useSelector((state: IApplicationState) => state.dashboard.mainWidget);
    const classes = useStyles();
    const [value, setValue] = React.useState("emptyPage");
    const [diagramName, setDiagramName] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [isDiagramVisible, setIsDiagramVisible] = useState<boolean>(false);
    const [isUrlVisible, setIsUrlVisible] = useState<boolean>(false);
    const [diagramId, setDiagramId] = useState<string | null>(null);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [isDialogMultiselection] = useState(false);
    const [diagramError, setDiagramError] = useState(false);
    const [urlError, setUrlError] = useState(false);
    const dispatch = useDispatch();


    function setDiagramNameById(diagramId: string) {
        diagramsApi.getDiagramById(diagramId)
            .subscribe({
            next: (response) => {
                const diagram = response.response as DiagramDto;
                const diagramName = diagram.diagramInfo.name ? diagram.diagramInfo.name : "";
                setDiagramName(diagramName);
            },
            error: () => {},
        });
    }

    useEffect(() => {
        const diagramId = mainWidget?.diagramIdentifier ? mainWidget.diagramIdentifier : "";
        const url = mainWidget?.url ? mainWidget.url : "";
        if(diagramId) {
            setDiagramNameById(diagramId)
            setValue("diagram")
            setIsDiagramVisible(true);
        }
        else if (url) {
            setUrl(url);
            setValue("url");
            setIsUrlVisible(true);
        }
        else {
            setValue("emptyPage")
        }
        }, [mainWidget?.diagramIdentifier, mainWidget?.url]);

    function setInputVisibility(value: string) {
        switch (value) {
            case "diagram": {
                setIsDiagramVisible(true);
                setIsUrlVisible(false)
                setUrl("");
                setUrlError(false)
                break;
            }
            case "url": {
                setIsDiagramVisible(false);
                setIsUrlVisible(true);
                setDiagramName("");
                setDiagramError(false)
                break;
            }
            default: {
                setIsDiagramVisible(false);
                setIsUrlVisible(false);
                setDiagramName("");
                setUrl("");
                setDiagramError(false)
                setUrlError(false)
                break;
            }
        }
    }

    const setRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setValue(value);
        setInputVisibility(value)
    };

    function changeState(dto: Array<DiagramInfoDto>) {
        const [diagram] = dto;
        setDiagramName(diagram.name!);
        setDiagramId(diagram.identifier);
        setDialogIsOpen(false);
        setUrlError(false);
        setDiagramError(false);
    }

    function createType() {
        if (diagramId !== null) {
            return WidgetType.DIAGRAM;
        } else if (url !== null) {
            return WidgetType.URL;
        }
        return WidgetType.EMPTY;
    }

    function showSuccessfulSave() {
        Snackbar.success(_transl(DashboardTranslationKey.SUCCESSFUL_SAVE));
    }

    function showNotSuccessfulSave(error: any) {
        Snackbar.error(_transl(DashboardTranslationKey.NOT_SUCCESSFUL_SAVE), error);
    }

    function setInputsToEmptyString(widget: WidgetUpdateDto) {
        const diagramId = widget.diagramId;
        diagramId ? setUrl("") : setDiagramName("");
    }

    async function saveChanges() {
        if (isNotError()) {
            const widget = createNewWidget();
            try {
                await dashboardService.updateWidget(widget);
                setInputsToEmptyString(widget)
                showSuccessfulSave()
                refreshDashboard(dispatch);
            } catch(error) {
                showNotSuccessfulSave(error);
            }
        }
    }

    function createNewWidget() {
        return {
            type: createType(),
            diagramId: diagramId,
            url: url,
        } as WidgetUpdateDto;
    }

    function isNotError() {
        if (isDiagramVisible && diagramName.trim().length === 0) {
            setDiagramError(true);
            setUrlError(false);
            return false;
        } else if (isUrlVisible && url.trim().length === 0) {
            setUrlError(true);
            setDiagramError(false);
            return false;
        }
        return true;
    }

    async function refreshDashboard(dispatch: Dispatch<any>) {
        const dashboard = await dashboardService.getDashboard();
        dispatch(getSaveDashboardAction(dashboard));
    }

    function setInputChanges(value: string) {
        setUrl(value);
        setUrlError(false);
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.headerPageSegment}>
                <Typography variant="h6">
                    {_transl(DashboardTranslationKey.ADMINISTRATION)}
                </Typography>
            </div>
            <Divider/>
            {dialogIsOpen && <DiagramsPickDialog isOpened={dialogIsOpen}
                                                 isMultiSelection={isDialogMultiselection}
                                                 onDiagramsPicked={(dto) => changeState(dto)}
                                                 onDialogClosed={() => setDialogIsOpen(false)}
            />}
            <div className={classes.pageContent}>
                <FormControl className={classes.radio} component="fieldset">
                    <RadioGroup value={value} onChange={setRadioButton}>
                        <FormControlLabel value="diagram"
                                          control={<Radio/>}
                                          label={_transl(DashboardTranslationKey.DIAGRAM)}/>
                        <FormControlLabel value="url"
                                          control={<Radio/>}
                                          label={_transl(DashboardTranslationKey.URL)}/>
                        <FormControlLabel value="emptyPage"
                                          control={<Radio/>}
                                          label={_transl(DashboardTranslationKey.EMPTY_PAGE)}/>
                    </RadioGroup>
                </FormControl>
                <div className={classes.textfield}>
                    {isDiagramVisible &&
                        <>
                            <TextField id="diagram"
                                       label={_transl(DashboardTranslationKey.DIAGRAM)}
                                       variant="outlined"
                                       value={diagramName}
                                       required={true}
                                       error={diagramError}
                                       InputLabelProps={{shrink: true}}
                                       size={"small"}
                            />
                            <span>
                                      <Tooltip title={_transl(DashboardTranslationKey.SEARCH_DIAGRAM)}
                                               placement={"top"}>
                                        <IconButton aria-label="edit" className={classes.iconButton} size={"small"}
                                                    onClick={() => setDialogIsOpen(true)}>
                                             <SearchIcon aria-label="addItem"/>
                                        </IconButton>
                                    </Tooltip>
                            </span>
                        </>
                    }
                    {isUrlVisible && <TextField id="url"
                                                label={_transl(DashboardTranslationKey.URL)}
                                                variant="outlined"
                                                value={url}
                                                required={true}
                                                error={urlError}
                                                InputLabelProps={{shrink: true}}
                                                size={"small"}
                                                onChange={event => (setInputChanges(event.target.value))}
                    />
                    }
                </div>
                <div className={classes.saveButton}>
                    <SaveButton onClick={(e) => {
                        saveChanges()
                    }}/>
                </div>
            </div>
        </Paper>
    )
}
