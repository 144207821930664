import {IconButton, InputAdornment} from "@mui/material";
import React from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";

interface PasswordVisibilityInputAdornmentProps {
    onClick: (value: any) => void,
    onMouseDown: (value: any) => void,
    showPassword: boolean
}

export default function PasswordVisibilityInputAdornment(props: PasswordVisibilityInputAdornmentProps) {
    const {onClick, onMouseDown, showPassword} = props;

    return <InputAdornment position="end">
        <IconButton
            aria-label="toggle password visibility"
            onClick={onClick}
            onMouseDown={onMouseDown}
            size="large">
            {showPassword ? <Visibility/> : <VisibilityOff/>}
        </IconButton>
    </InputAdornment>
}
