import {Divider} from "@mui/material";
import IDiagramApi from "../../../../../common/diagrameditor/api/IDiagramApi";
import React from "react";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import IDiagramEditorApi from "../../../../../common/diagrameditor/api/IDiagramEditorApi";
import {AlignmentType} from "../../../../../common/diagrameditor/common/AlignmentType";
import {IDiagramConnectionDto} from "../../../../../common/apis/diagram/IDiagramConnectionDto";
import AlignNodesMenuItems from "./AlignNodesMenuItems";
import DeleteIcon from "@mui/icons-material/Delete";
import {CommonTranslation} from "../../CommonTranslation";
import {ContextMenu} from "../../../../../diagram/editor/components/menu/ContextMenu";
import {DiagramEditorTranslationKey} from "./DiagramEditorTranslationKey";
import {Brush} from "@mui/icons-material";
import {MenuItem} from "../../../../../components/menu/MenuItem";
import {IDiagramNodeDto} from "../../../../../common/apis/diagram/IDiagramNodeDto";
import {EventType, OnDeleteSelectedItemsMenuActivatedEvent} from "../../../../../common/event/Event";
import EventManager from "../../../../../common/event/EventManager";
import {ElementPermissionResolver} from "./ElementPermissionResolver";
import {RelationshipPermissionResolver} from "./RelationshipPermissionResolver";

interface Props {
    opened: boolean,
    selectedNodes: IDiagramNodeDto[],
    selectedConnections: IDiagramConnectionDto[],
    clientCoordinates: [number, number],
    onClose: () => void,
    onStylesClick: () => void,
    diagramApi: IDiagramApi,
    diagramEditorApi?: IDiagramEditorApi,
    eventManager?: EventManager,
}

export default function ConnectionContextMenu(props: Props) {

    const {opened, onClose, selectedNodes, selectedConnections,
        clientCoordinates, diagramEditorApi, eventManager} = props;
    const isAlignDisabled = !diagramEditorApi?.areSelectedNodesAlignable();
    const anchorPosition = {
        top: clientCoordinates[1],
        left: clientCoordinates[0]
    };

    const modelAccessor = props.diagramEditorApi?.getModelAccessor();
    const elementPermissionResolver = new ElementPermissionResolver();
    const relationshipPermissionResolver = new RelationshipPermissionResolver();

    function alignNodesClicked(alignmentType: AlignmentType) {
        diagramEditorApi?.onAlignSelectedNodesClicked(alignmentType);
    }

    function deleteSelectedItems() {
        if (eventManager) {
            eventManager.publishEvent<OnDeleteSelectedItemsMenuActivatedEvent>({
                type: EventType.ON_DELETE_SELECTED_ITEMS_MENU_ACTIVATED,
                disableRemoveFromModel: disableRemoveFromModel(),
            })
        }
        onClose();
    }

    function disableRemoveFromModel(): boolean {
        for (const node of selectedNodes) {
            const element = node.elementIdentifier ? modelAccessor?.getElementById(node.elementIdentifier) : null;
            if (element && !elementPermissionResolver?.checkCanDeleteElement(element)) {
                return true;
            }
        }
        for (const connection of selectedConnections) {
            const relationship = modelAccessor?.getRelationshipById(connection.relationshipIdentifier);
            if (relationship) {
                let sourceElement = modelAccessor?.getElementById(relationship.source.identifier);
                let targetElement = modelAccessor?.getElementById(relationship.target.identifier);
                if (sourceElement && targetElement && !relationshipPermissionResolver?.checkCanDeleteRelationship(sourceElement, targetElement)) {
                    return true;
                }
            }
        }
        return false;
    }

    function onStylesClick() {
        props.onStylesClick();
        onClose();
    }

    return (
        <ContextMenu open={opened}
                     onClose={onClose}
                     anchorPosition={anchorPosition}>
            <MenuItem label={_transl(CommonTranslation.REMOVE)}
                      icon={<DeleteIcon/>}
                      shortcut={"⌫"}
                      onClick={deleteSelectedItems} />
            <Divider />
            <AlignNodesMenuItems isAlignDisabled={isAlignDisabled}
                                 alignNodesClicked={(type) => alignNodesClicked(type)}
                                 onClose={() => onClose}/>
            <Divider />
            <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_STYLES)} icon={<Brush/>}
                      onClick={onStylesClick}/>
        </ContextMenu>
    );
}
