import React from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import clsx from "clsx";
import {ActionButtonType, EnabledPolicy, GridAction} from "./GridAction";
import {PersistentStateId} from "../../store/common/Grid";
import ActionButtonUtils from "./ActionButtonUtils";
import {_transl} from "../../store/localization/TranslMessasge";
import {GridActionButton} from "./GridActionButton";
import {GridActionsMenu} from "./GridActionsMenu";
import {BulkActionsSwitchButton} from "./BulkActionsSwitchButton";
import {ExtGridTranslationKey} from "./ExtGridTranslationKey";
import {MoreHoriz} from "@mui/icons-material";
import {OpenReason} from "@mui/material";

const styles = (theme: Theme) => createStyles({
    root: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    nonSpeedButtonsPanel: {
        flexGrow: 1,
    },
    bulkButtonsPanel: {
        display: "inline-flex",
        verticalAlign: "middle",
    },
    speedButtonsPanel: {
    },
    displayNone: {
        display: "none",
    },
    rowsCountSpan: {
        marginLeft: "1.9em",
    }
});

interface GridActionsPanelProps extends WithStyles<typeof styles> {
    // action button properties
    actionButtons: GridAction[],
    onActionButtonClicked: (button: GridAction) => void,
    rowsCount: number,
    selectedRows: any[],
    selectedRowsCount: number,
    bulkActionsOpened: boolean,
    enableBulkActionsPermanently?: boolean,
    onBulkActionsOpened: () => void,
    onBulkActionsClosed: () => void,
    gridPersistendStateId: PersistentStateId,
    // styling
    hidden?: boolean,
    rootClass?: string,
}

interface GridActionsPanelState {
    speedActionsOpened: boolean,
    selectedBulkActionButtonTarget?: HTMLButtonElement,
    selectedBulkActionButton?: GridAction
}

class GridActionsPanel extends React.Component<GridActionsPanelProps, GridActionsPanelState> {

    constructor(props: GridActionsPanelProps) {
        super(props);
        this.state = {
            speedActionsOpened: false,
        };
    }

    render() {
        const {classes, enableBulkActionsPermanently} = this.props;
        const rootClassName = this.computeRootClasses();

        const immediateButtons = this.filterButtonTypes([ActionButtonType.IMMEDIATE]);
        const bulkButtons = this.filterButtonTypes([ActionButtonType.BULK_MENU]);
        const speedButtons = this.filterButtonTypes([ActionButtonType.SPEED]);

        return (
            <div className={rootClassName}>
                <div className={classes.nonSpeedButtonsPanel} key={"nonSpeedButtonsPanel"}>
                    {immediateButtons.length > 0 && this.renderImmediateButtons(immediateButtons)}
                    {(bulkButtons.length > 0 || (enableBulkActionsPermanently === true)) && this.renderBulkButtons(bulkButtons)}
                    {this.renderInfoPanel()}
                </div>
                <div className={classes.speedButtonsPanel} key={"speedButtonsPanel"}>
                    {speedButtons.length > 0 && this.renderSpeedButtons(speedButtons)}
                </div>
            </div>
        );
    }

    renderInfoPanel() {
        const {classes, rowsCount, selectedRowsCount, bulkActionsOpened} = this.props;
        return <span key={"infoPanel"}>
        {!bulkActionsOpened && <span className={classes.rowsCountSpan}>{_transl(ExtGridTranslationKey.TOTAL)}: {rowsCount}</span>}
        {bulkActionsOpened && <span className={classes.rowsCountSpan}>{selectedRowsCount}/{rowsCount} {_transl(ExtGridTranslationKey.SELECTED_SLASH_TOTAL)}</span>}
        </span>
    }

    renderImmediateButtons(immediateButtons: GridAction[]) {
        const onActionButtonClicked = this.props.onActionButtonClicked || (() => {});

        return immediateButtons.map(button => {
            const id = ActionButtonUtils.createButtonId(button.id, button.type, this.props.gridPersistendStateId);
            return <GridActionButton id={id}
                                     key={button.tooltip}
                                     tooltip={button.tooltip}
                                     icon={button.icon}
                                     className={this.getButtonClassName(button)}
                                     disabled={this.isButtonDisabled(button)}
                                     onClick={() => {onActionButtonClicked(button)}} />
        });
    }

    renderBulkButtons(bulkButtons: GridAction[]) {
        const {classes} = this.props;
        const {bulkActionsOpened, enableBulkActionsPermanently} = this.props;
        const {selectedBulkActionButton} = this.state;
        const onActionButtonClicked = this.props.onActionButtonClicked || (() => {});

        return <span className={classes.bulkButtonsPanel} key={"bulkButtonsPanel"}>
            {!enableBulkActionsPermanently &&
                <BulkActionsSwitchButton key={"switchButton"}
                                         checked={bulkActionsOpened}
                                         onChange={(checked) => this.handleBulkActionsOpened(checked)}/>
            }

            {bulkActionsOpened && bulkButtons.map((action) => {
                return <GridActionsMenu key={"actionsMenu_"+action.tooltip}
                                        open={selectedBulkActionButton != null && selectedBulkActionButton.id === action.id}
                                        onOpen={(event, reason) => this.onBulkMenuOpen(action, reason)}
                                        onClose={() => this.onBulkMenuClose(action)}
                                        onActionClick={(a) => onActionButtonClicked(a)}
                                        visibleResolver={(a) => this.isButtonVisible(a)}
                                        disabledResolver={(a) => this.isButtonDisabled(a)}
                                        {...action} />;
            })}
        </span>
    }

    private onBulkMenuOpen(action: GridAction, reason: OpenReason) {
        if (reason === "focus") {
            return;
        }
        this.setState(state => {
            return {
                ...state,
                selectedBulkActionButton: action
            }
        });
    }

    private onBulkMenuClose(action: GridAction) {
        this.setState(state => {
            return {
                ...state,
                selectedBulkActionButton: undefined
            }
        });
    }

    private renderSpeedButtons(speedActions: GridAction[]) {
        const {speedActionsOpened} = this.state;
        const onActionButtonClicked = (gridAction: GridAction) => {
            if (this.props.onActionButtonClicked) {
                this.props.onActionButtonClicked(gridAction);
            }
            this.handleSpeedActionsOpened(false);
        };

        return <GridActionsMenu id={"GRID.SPEED_ACTIONS_MENU"}
                                key={"GRID.SPEED_ACTIONS_MENU"}
                                type={ActionButtonType.SPEED}
                                tooltip={""}
                                icon={<MoreHoriz/>}
                                enabledPolicy={EnabledPolicy.ALWAYS}
                                open={speedActionsOpened}
                                onOpen={() => this.handleSpeedActionsOpened(true)}
                                onClose={() => this.handleSpeedActionsOpened(false)}
                                visibleResolver={(a) => this.isButtonVisible(a)}
                                disabledResolver={(a) => this.isButtonDisabled(a)}
                                onActionClick={(a) => onActionButtonClicked(a)}
                                childActions={speedActions}
                                actionTooltipPlacement={"left"} />;
    }

    computeRootClasses() {
        const {classes, rootClass, hidden} = this.props;

        const rootClasses = {
            [classes.root] : true,
            [rootClass as string] : rootClass != null,
            [classes.displayNone]: hidden || false,
        }
        return clsx(rootClasses);
    }

    getButtonClassName(button: GridAction) {
        const {classes} = this.props;

        return clsx({[classes.displayNone]: !this.isButtonVisible(button)});
    }

    handleSpeedActionsOpened(opened: boolean) {
        this.setState({
            speedActionsOpened: opened,
        })
    }

    handleBulkActionsOpened(opened: boolean) {
        const {onBulkActionsOpened, onBulkActionsClosed} = this.props;
        this.setState(state => {
            return {
                ...state,
                selectedBulkActionButtonTarget: undefined,
                selectedBulkActionButton: undefined,
            }
        }, () => {
            opened ? onBulkActionsOpened() : onBulkActionsClosed();
        });
    }

    filterButtonTypes(types: ActionButtonType[]) {
        const {actionButtons} = this.props;
        return [...(actionButtons || [])].filter(button => types.indexOf(button.type) !== -1);
    }

    isButtonDisabled(button: GridAction): boolean {
        const {selectedRowsCount} = this.props;
        return (button.enabledPolicy === EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED && selectedRowsCount !== 1)
            || (button.enabledPolicy === EnabledPolicy.WHEN_AT_LEAST_ONE_SELECTED && selectedRowsCount < 1)
            || (button.enabledPolicy === EnabledPolicy.WHEN_MORE_SELECTED && selectedRowsCount < 2)
            || ((button.isEnabled && !button.isEnabled(this.props.selectedRows)) || false);
    }

    private isButtonVisible(button: GridAction) {
        if (button.isVisible) {
            return button.isVisible(this.props.selectedRows);
        } else {
            return true;
        }
    }
}

export default withStyles(styles, { withTheme: true })(GridActionsPanel);
