import Constants from "../Constants";
import {AjaxRequest} from "rxjs/internal/observable/dom/AjaxObservable";
import Api from "../Api";

export interface ModelDocumentationPropertiesDto {
    diagramIdentifiers: Array<string>,
    includeElementDescription: boolean,
    includeDiagramDescription: boolean,
}

export interface ModelDocumentationService {
    generateModelDocumentation(modelDocumentationPropertiesDto: ModelDocumentationPropertiesDto): Promise<any>,
}

class RxJsMetamodelDocumentationService implements ModelDocumentationService {

    async generateModelDocumentation(modelDocumentationPropertiesDto: ModelDocumentationPropertiesDto): Promise<any> {
        const request: AjaxRequest = {
            url: Constants.REST_API_URL + "/docs/model-documentation:generate",
            method: "POST",
            responseType: "blob",
            body: modelDocumentationPropertiesDto,
        };
        return Api.createAjax(request).toPromise();
    }
}

export default new RxJsMetamodelDocumentationService() as ModelDocumentationService;