import React, {useCallback, useEffect, useReducer} from "react";
import {useDispatch} from "react-redux";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {FetchStatusType, IFetchableResourceState} from "../../../../store/common/FetchableResource";
import Grid from "@mui/material/Grid";
import MainPanel from ".//MainPanel";
import SidePanel from ".//SidePanel";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {getFilterRefetchAction} from "../../../../store/elements/Elements";
import Api from "../../../../common/Api";
import {CircularProgress} from "@mui/material";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "./ElementDetailTranslationKey";
import createElementDetailController from "./controller/ElementDetailController";
import elementService from "./service/ElementService";
import diagramService from "../diagrams/service/DiagramService";
import ElementDetailHeader from "./ElementDetailHeader";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
        fullHeight: CommonCssStyles.getFullHeightStyle(),
        infoGridItem: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        infoGridLabel: {
            fontSize: 18,
            marginBottom: theme.spacing(4),
        }
    })
);

export enum EditableProperty {
    NAME = "NAME",
    RELATIONSHIPS = "RELATIONSHIPS",
    ATTRIBUTES = "ATTRIBUTES",
}

interface ElementDetailPanelProps {
    id: string;
    onShowElementDetail: (elementId: string) => void;
    nonEditableProperties?: Array<EditableProperty>;
    showHeader? : boolean;
    onUpdated: () => void;
    onElementUpdated?: (id: string) => void;
}

interface State {
    elementFetchState: IFetchableResourceState<ElementDto | null>,
    refetchRequest?: {
        silently: boolean,
    }
}

const initialState: State = {
    elementFetchState: createElementFetchState(FetchStatusType.STARTED, null, null),
}

function createElementFetchState(status: FetchStatusType, error: string | null, resource: ElementDto | null): IFetchableResourceState<ElementDto | null> {
    return {
        fetchStatus: {
            status: status,
            date: new Date(),
            error: error,
        },
        resource: resource,
    }
}

enum ActionType {
    REFETCH_ELEMENT = "REFETCH_ELEMENT",
    SET_ELEMENT_FETCH_STATE = "SET_ELEMENT_FETCH_STATE",
}

type Action =
    | { type: ActionType.REFETCH_ELEMENT, payload: { silently: boolean } }
    | { type: ActionType.SET_ELEMENT_FETCH_STATE, payload: IFetchableResourceState<ElementDto | null> }


function getRefetchElementAction(silently: boolean): Action {
    return {
        type: ActionType.REFETCH_ELEMENT,
        payload: {silently: silently},
    }
}

function getUpdateElementFetchStateAction(fetchStatus: FetchStatusType, error: string | null, resource: ElementDto | null): Action {
    return {
        type: ActionType.SET_ELEMENT_FETCH_STATE,
        payload: {
            fetchStatus: {
                status: fetchStatus,
                date: new Date(),
                error: error,
            },
            resource: resource,
        } as IFetchableResourceState<ElementDto | null>
    }
}


function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.REFETCH_ELEMENT:
            return {
                ...state,
                elementFetchState: createElementFetchState(FetchStatusType.STARTED, null, state.elementFetchState?.resource || null),
                refetchRequest: {silently: action.payload.silently},
            }
        case ActionType.SET_ELEMENT_FETCH_STATE:
            return {
                ...state,
                elementFetchState: action.payload,
            }
        default:
            return state;
    }
}

function fetchElement(id: string, dispatch: React.Dispatch<Action>) {
    Api.elements.getByIdentifier(id)
        .subscribe({
            next: (response) =>
                dispatch(getUpdateElementFetchStateAction(FetchStatusType.SUCCESSFUL, null, response.response as ElementDto)),
            error: () =>
                dispatch(getUpdateElementFetchStateAction(FetchStatusType.FAILED, "Fetch failed.", null)),
        })
}

function fetchElementOnPropertyUpdate(dispatch: React.Dispatch<Action>, reduxDispatch: any) {
    dispatch(getRefetchElementAction(true));
    reduxDispatch(getFilterRefetchAction());
}

export default function ElementDetailPanel({id, nonEditableProperties, onShowElementDetail, showHeader, onUpdated, onElementUpdated}: ElementDetailPanelProps) {

    const classes = useStyles();
    const reduxDispatch = useDispatch();
    const [{elementFetchState, refetchRequest}, dispatch] = useReducer(reducer, initialState);
    const onPropertyUpdateClbk = useCallback(() => {
        fetchElementOnPropertyUpdate(dispatch, reduxDispatch);
        onUpdated();
    }, [reduxDispatch, dispatch, onUpdated])

    useEffect(() => {
        fetchElement(id, dispatch);
    }, [id, refetchRequest]);


    const fetchStatus = elementFetchState.fetchStatus.status;
    const element = elementFetchState.resource;
    const controller = createElementDetailController(elementService, diagramService);

    return (
        <Grid container className={classes.fullHeight}>
            {(fetchStatus === FetchStatusType.SUCCESSFUL ||
                    (fetchStatus === FetchStatusType.STARTED && refetchRequest?.silently === true && element)) &&
                <React.Fragment>
                    {showHeader &&
                        <Grid item xs={12}>
                            <ElementDetailHeader element={element!}/>
                        </Grid>
                    }
                    <Grid item xs={6} className={classes.fullHeight}>
                        <div className={`${classes.controlPageSegment} ${classes.fullHeight}`}>
                            {element &&
                                <MainPanel element={element}
                                           onPropertyUpdate={onPropertyUpdateClbk}
                                           onElementUpdated={onElementUpdated}
                                />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={`${classes.controlPageSegment} ${classes.fullHeight}`}>
                            {element &&
                                <SidePanel element={element}
                                           onPropertyUpdate={onPropertyUpdateClbk}
                                           nonEditableProperties={nonEditableProperties}
                                           onShowElementDetail={onShowElementDetail}
                                           controller={controller}
                                />
                            }
                        </div>
                    </Grid>
                </React.Fragment>
            }

            {(fetchStatus === FetchStatusType.STARTED && (!refetchRequest || (refetchRequest.silently === false))) &&
                <Grid item xs={12} className={classes.infoGridItem}>
                    <div
                        className={classes.infoGridLabel}>{_transl(ElementDetailTranslationKey.FETCH_DATA_STARTED)}</div>
                    <div><CircularProgress size={40}/></div>
                </Grid>
            }

            {fetchStatus === FetchStatusType.FAILED &&
                <Grid item xs={12} className={classes.infoGridItem}>
                    <div>{_transl(ElementDetailTranslationKey.FETCH_DATA_FAILED)}</div>
                </Grid>
            }

        </Grid>
    );
}
