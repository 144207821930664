import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import clsx from "clsx";
import {useEffect, useRef} from "react";
import IDiagramEditorApi from "../../../../../common/diagrameditor/api/IDiagramEditorApi";
import {
    IChartScrolledEvent, DiagramZoomEvent,
} from "../../../../../common/event/Event";
import * as d3 from "d3";
import {RULER_SIZE} from "../../../DiagramEditorComponent";
import RulerUtils from "./RulerUtils";
import {Unsubscriber} from "../../../../../common/event/EventManager";

const RULER_COLOR = "rgb(180, 180, 180)";

export enum RulerType {
    HORIZONTAL = "HORIZONTAL",
    VERTICAL = "VERTICAL",
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ruler: {
            overflow: "hidden",
            color: RULER_COLOR,
        },
        svg: {
            position: "relative",
            inset: 0,
            display: "block",
            userSelect: "none",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
        }
    })
);

interface IProps {
    type: RulerType,
    className?: string,
    editorApi?: IDiagramEditorApi,
}

// FUNCTIONS

function createRuler(rulerType: RulerType,
                     rulerSvgNode: SVGSVGElement,
                     scrollEvent: IChartScrolledEvent,
                     zoom: number,
                     lastRulersDims: React.MutableRefObject<[number, number]>) {
    const diagramArea = scrollEvent.nodesConnectionsOverlaysArea;
    const paperArea = scrollEvent.paperArea;

    if (rulerType === RulerType.HORIZONTAL) {
        const start = diagramArea.x - paperArea.x;
        RulerUtils.createAxis(rulerType, start, zoom, rulerSvgNode, lastRulersDims);
    } else {
        const start = diagramArea.y - paperArea.y;
        RulerUtils.createAxis(rulerType, start, zoom, rulerSvgNode, lastRulersDims);
    }
}

function updateRulerPosition(rulerType: RulerType,
                             rulerSvgNode: SVGSVGElement,
                             scrollEvent: IChartScrolledEvent) {
    const paperArea = scrollEvent.paperArea;
    const clientArea = scrollEvent.clientArea;

    if (rulerType === RulerType.HORIZONTAL) {
        const left = paperArea.x - clientArea.x;
        const width = paperArea.w;

        d3.select(rulerSvgNode)
            .style("left", left+"px")
            .style("width", width+"px")
            .attr("width", width)
    } else {
        const top = paperArea.y - clientArea.y;
        const height = paperArea.h;

        d3.select(rulerSvgNode)
            .style("top", top+"px")
            .style("height", height+"px")
            .attr("height", height)
    }
}

function updateRuler(type: RulerType,
                     svgNode: SVGSVGElement,
                     scrollEvent: IChartScrolledEvent,
                     lastScrollEvent: React.MutableRefObject<IChartScrolledEvent | undefined>,
                     scale: number,
                     lastRulersDims: React.MutableRefObject<[number, number]>) {
    if (lastScrollEvent.current == null) {
        recreateRuler(type, svgNode, scrollEvent, scale, lastRulersDims);
    } else {
        const paperArea = scrollEvent.paperArea;
        if ((type === RulerType.HORIZONTAL && paperArea.w > (lastRulersDims.current[0] + 2)) ||
            (type === RulerType.VERTICAL && paperArea.h > (lastRulersDims.current[1] + 2))) {
            recreateRuler(type, svgNode, scrollEvent, scale, lastRulersDims);
        } else {
            if (!paperArea.equals(lastScrollEvent.current.paperArea)) {
                updateRulerPosition(type, svgNode, scrollEvent);
            }
        }
    }
    lastScrollEvent.current = scrollEvent;
}

function recreateRuler(type: RulerType,
                       svgNode: SVGSVGElement,
                       scrollEvent: IChartScrolledEvent,
                       scale: number,
                       lastRulersDims: React.MutableRefObject<[number, number]>) {
    createRuler(type, svgNode, scrollEvent, scale, lastRulersDims);
    updateRulerPosition(type, svgNode, scrollEvent);
}

// COMPONENT

export default function Ruler(props: IProps) {
    const {type, editorApi} = props;
    const classes = useStyles(props.type);

    // REFS
    const lastScrollEvent = useRef<IChartScrolledEvent>();
    const lastScale = useRef<number>(1);
    const lastRulersDims = useRef<[number, number]>([0, 0]);
    const svgRef = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        let chartScrolledUnsubscriber: Unsubscriber | undefined;
        let chartZoomUnsubscriber: Unsubscriber | undefined;
        if (editorApi) {
            chartScrolledUnsubscriber = editorApi?.addChartScrolledEventListener(
                (event: IChartScrolledEvent) => {
                    if (svgRef.current) {
                        updateRuler(type, svgRef.current, event, lastScrollEvent, lastScale.current, lastRulersDims);
                    }
                },
                true);
            chartZoomUnsubscriber = editorApi?.addChartZoomListener(
                (event: DiagramZoomEvent) => {
                    if (lastScale.current !== event.actualZoom) {
                        lastScale.current = event.actualZoom;
                        if (svgRef.current && lastScrollEvent.current) {
                            recreateRuler(type, svgRef.current, lastScrollEvent.current, lastScale.current, lastRulersDims);
                        }
                    }
                }
            );
        }
        return () => {
            chartScrolledUnsubscriber && chartScrolledUnsubscriber();
            chartZoomUnsubscriber && chartZoomUnsubscriber();
        }
    }, [type, editorApi])

    return <div className={clsx(classes.ruler, props.className)}>
        <svg ref={svgRef}
             className={classes.svg}
             width={type === RulerType.VERTICAL ? RULER_SIZE : "100%"}
             height={type === RulerType.HORIZONTAL ? RULER_SIZE : "100%"}
        />
    </div>
}
