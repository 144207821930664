import {
    composeFetchableResourceEpic,
    createFetchableResourceReducer,
    FetchableResourceType,
} from "../common/FetchableResource";
import {Action, Reducer} from "redux";
import Api from "../../common/Api";
import {IFilter} from "./Diagrams";
import {DiagramInfoDto} from "../../common/apis/diagram/DiagramInfoDto";

// ### DIAGRAMS DIALOG FILTER ###

// Actions

export enum FilterActionType {
    UPDATE = "ACTION/DIAGRAMS/DIALOGFILTER/UPDATE",
    RESET = "ACTION/DIAGRAMS/DIALOGFILTER/RESET",
}

interface IFilterAction extends Action<FilterActionType> {
    type: FilterActionType;
    filter?: IFilter,
}

export function getDialogFilterUpdateAction(filter: IFilter): IFilterAction {
    return {
        type: FilterActionType.UPDATE,
        filter: filter,
    }
}

export function getDialogFilterResetAction(): IFilterAction {
    return {
        type: FilterActionType.RESET,
    }
}

// State

export interface IFilterState {
    lastFilter: IFilter;
    lastFilterUpdate: Date,
}

const initialFilterState: IFilterState = {
    lastFilter: {},
    lastFilterUpdate: new Date(),
}

// Reducer

export const dialogFilterReducer: Reducer<IFilterState, IFilterAction> = (
    state = initialFilterState,
    action
) => {
    switch(action.type) {
        case FilterActionType.UPDATE :
            return {
                ...state,
                lastFilter: action.filter as IFilter,
                lastFilterUpdate: new Date(),
            };
        case FilterActionType.RESET :
            return {
                ...state,
                lastFilter: {},
                lastFilterUpdate: new Date(),
            }
    }
    return state;
};


// ### DIAGRAMS DIALOG GRID ###

// Reducer & epic

const type = FetchableResourceType.DIAGRAMS_PAGE_DIALOGGRID;

export const dialogGridReducer = createFetchableResourceReducer<DiagramInfoDto[]>(type, []);

export const dialogGridRefreshEpic = composeFetchableResourceEpic<IFilterAction>(
    FilterActionType.UPDATE,
    type,
    (action) =>  Api.diagrams.doSearch(action.filter as IFilter),
    (action) => type
);
