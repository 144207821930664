import {ChatState} from "../../../../common/apis/chat/ChatService";
import React from "react";
import {ChatTranslationKey} from "./ChatTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {CheckCircle, CheckCircleOutline} from "@mui/icons-material";
import {ActionButton} from "../../../../components/button/ActionButton";


interface ChatResolvingButtonProps {
    chatState: ChatState;
    onChatStateChange: (chatState: ChatState, event: Event) => void;
    disabled: boolean;
}

export default function ChatResolvingButton({onChatStateChange, chatState, disabled}: ChatResolvingButtonProps) {

    return <>
        {chatState === ChatState.RESOLVED &&
            <ActionButton id="unresolve-chat"
                          onClick={(e) => onChatStateChange(ChatState.UNRESOLVED, e)}
                          tooltip={_transl(ChatTranslationKey.UNRESOLVE)}
                          icon={<CheckCircle />}
                          color={"success"}
                          disabled={disabled} />
        }
        {chatState === ChatState.UNRESOLVED &&
            <ActionButton id="resolve-chat"
                          onClick={(e) => onChatStateChange(ChatState.RESOLVED, e)}
                          tooltip={_transl(ChatTranslationKey.RESOLVE)}
                          icon={<CheckCircleOutline />}
                          color={"default"}
                          disabled={disabled} />
        }
    </>;
}
