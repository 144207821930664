import React, {useContext} from "react";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import Grid from "../../../../../components/dialogs/Grid";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import {OkButton} from "../../../../../components/button/OkButton";
import {CancelButton} from "../../../../../components/button/CancelButton";
import {ClipboardEventType, CreateItemsFromClipboardEvent, ItemsInfo} from "./ClipboardEvents";
import EventManagerContext from "../../../../../common/event/EventManagerContext";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "./DiagramEditorTranslationKey";

interface PasteDuplicateItemsDialogProps {
    itemsInfo: ItemsInfo;
    onCancel: () => void,
}

export default function PasteDuplicateItemsDialog(props: PasteDuplicateItemsDialogProps) {

    const eventManager = useContext(EventManagerContext);

    const {itemsInfo, onCancel} = props;

    function createItems() {
        const createItemsFromClipboardEvent: CreateItemsFromClipboardEvent = {
            type: ClipboardEventType.CREATE_ITEMS_FROM_CLIPBOARD,
            itemsInfo: itemsInfo,
        };
        eventManager.publishEvent(createItemsFromClipboardEvent);

        onCancel();
    }

    return (
        <Dialog open={true} maxWidth={"md"}>
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(DiagramEditorTranslationKey.PASTE_DUPLICATE_ITEMS_DIALOG_TITLE)}
                         onDialogClosed={() => onCancel()}/>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <span>{_transl(DiagramEditorTranslationKey.PASTE_DUPLICATE_ITEMS_DIALOG_MSG)}</span>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <OkButton
                    onClick={() => createItems()}/>
                <CancelButton
                    onClick={() => onCancel()}
                />
            </DialogActions>
        </Dialog>
    );
}