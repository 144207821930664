export enum CommonTranslation {
    CANCEL = "common.cancel",
    CHANGE_PASSWORD = "common.changePassword",
    CLOSE = "common.close",
    CODE = "common.code",
    COPY_URL = "common.copyUrl",
    CREATE = "common.create",
    CSV_LABEL = "common.csvLabel",
    DATA_SAVED_SUCESSFULLY = "common.dataSavedSuccessfully",
    DESCRIPTION = "common.description",
    EDIT = "common.edit",
    EXCEL_LABEL = "common.excelLabel",
    FIELD_TOO_LONG = "common.fieldTooLong",
    FILL_OUT_AT_LEAST_ONE = "common.fillOutAtLeastOne",
    FILL_OUT_ITEM_FROM_LIST = "common.fillOutItemFromList",
    FILL_OUT_THIS_FIELD = "common.fillOutThisField",
    LIST_OF_DISPLAYED_ELEMENTS = "common.listOfDisplayedElements",
    NO = "common.no",
    OK = "common.ok",
    PAGE_NOT_FOUND = "common.pageNotFound",
    PUBLIC = "common.public",
    PRIVATE = "common.private",
    REMOVE = "common.remove",
    SAVE = "common.save",
    SELECT_ALL = "common.selectAll",
    SHOW_DETAIL = "common.showDetail",
    TITLE = "common.title",
    VALUE = "common.value",
    UPDATE = "common.update",
    USER = "common.user",
    WARNING = "common.warning",
    YES = "common.yes",
    CHOOSE = "common.choose"
}
