import {DiagramBackup} from "./DiagramBackup";
import {DiagramBackupPersister} from "./DiagramBackupPersister";
import {DiagramBackupInfo} from "./DiagramBackupInfo";
import {Diagram} from "../model/Model";

export interface DiagramBackupService {
    countBackups(): number;

    findBackup(diagramId: string): DiagramBackup | null;

    findUserBackups(login: string): Array<DiagramBackupInfo>;

    doBackup(backup: DiagramBackup): void,

    removeBackup(diagramId: string): void,

    updateBackupDate(diagramId: string, date: Date): void,
}

export class DefaultDiagramBackupService implements DiagramBackupService {

    private persister: DiagramBackupPersister;

    constructor(persister: DiagramBackupPersister) {
        this.persister = persister;
    }

    countBackups(): number {
        return this.persister.load().size;
    }

    findBackup(diagramId: string): DiagramBackup | null {
        return this.persister.load().get(diagramId) || null;
    }

    updateBackupDate(diagramId: string, date: Date) {
        let backup = this.persister.load().get(diagramId);
        if (backup) {
            backup.setDate(date);
            this.persister.persist(backup);
        }
    }

    findUserBackups(login: string): Array<DiagramBackupInfo> {
        const backups = this.persister.load();
        const userBackups = Array.from(backups.values())
            .filter(backup => backup.getLogin() === login);
        return Array.from(userBackups)
            .map(backup => this.constructDiagramBackupInfo(backup));
    }

    private constructDiagramBackupInfo(backup: DiagramBackup) {
        const diagramId = backup.getDiagramId();
        const diagram = this.findDiagramById(diagramId, backup.getDiagramModel().diagrams);
        if (!diagram) {
            throw new Error(`Corrupted data received. Cannot find diagram by id: ${diagramId}`);
        }

        return new DiagramBackupInfo(diagramId, diagram.diagramInfo.name || diagramId, this.getDate(backup.getDate()), backup.getLogin());
    }

    getDate(date: Date | string): Date {
        return typeof date === 'string' ? new Date(date) : date;
    }

    doBackup(backup: DiagramBackup): void {
        if (!this.findDiagramById(backup.getDiagramId(), backup.getDiagramModel().diagrams)) {
            throw new Error(`Cannot find diagram by id: ${backup.getDiagramId()}`);
        }
        this.persister.persist(backup);
    }

    removeBackup(diagramId: string): void {
        const backup = this.findBackup(diagramId);
        if (backup) {
            this.persister.remove(backup);
        }
    }

    private findDiagramById(diagramId: string, diagrams: Array<Diagram>): Diagram | null {
        return diagrams
            .find(diagram => diagram.diagramInfo.identifier === diagramId) || null
    }

}
