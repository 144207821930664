import {TreeItem} from "@mui/lab";
import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {HierarchyTreeItemDetailDto} from "../service/HierarchyTreeItemDetailDto";
import {HierarchyTreeDataType} from "../service/HierarchyTreeDataType";
import {HierarchyTreeItemType} from "../service/HierarchyTreeItemType";
import {FolderTreeItemLabel} from "./FolderTreeItemLabel";
import {ElementTreeItemLabel} from "./ElementTreeItemLabel";
import {DiagramTreeItemLabel} from "./DiagramTreeItemLabel";
import {ReferenceTreeItemLabel} from "./ReferenceTreeItemLabel";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0),
            whiteSpace: "nowrap",
        },
    })
);

interface HierarchyTreeItemProps {
    id: string;
    treeItemDetail: HierarchyTreeItemDetailDto;
    onContextMenu?: (treeItem: HierarchyTreeItemDetailDto, treeItemPath: string, event: React.MouseEvent) => void;
}

export default function HierarchyTreeItem (props: React.PropsWithChildren<HierarchyTreeItemProps>) {

    const {id, treeItemDetail} = props;
    const classes = useStyles();

    function handleContextMenu(event: React.MouseEvent) {
        props.onContextMenu && props.onContextMenu(treeItemDetail, id, event);
    }

    function renderLabel() {
        if (treeItemDetail.type === HierarchyTreeItemType.ELEMENT) {
            return <ElementTreeItemLabel treeItem={treeItemDetail} />
        } else if (treeItemDetail.type === HierarchyTreeItemType.DIAGRAM) {
            return <DiagramTreeItemLabel treeItem={treeItemDetail} />
        } else if (treeItemDetail.type === HierarchyTreeItemType.FOLDER) {
            return <FolderTreeItemLabel treeItem={treeItemDetail} />
        } else if (treeItemDetail.type === HierarchyTreeItemType.SUBTREE) {
            if (treeItemDetail.treeData?.type === HierarchyTreeDataType.REFERENCE) {
                return <ReferenceTreeItemLabel treeItem={treeItemDetail} />
            }
        }

        return <FolderTreeItemLabel treeItem={treeItemDetail} />
    }

    return (
        <TreeItem key={id}
                  nodeId={id}
                  label={<div className={classes.labelRoot}>
                      {renderLabel()}
                  </div>}
                  onContextMenu={handleContextMenu}>
            {props.children}
        </TreeItem>
    );
}
