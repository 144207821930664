import React from "react";
import {ButtonProps} from "@mui/material/Button/Button";

type ButtonContainerProps = {
    children: React.ReactElement<ButtonProps> | Array<React.ReactElement<ButtonProps>>,
}

export function PositiveButtons({children}: ButtonContainerProps) {
    return (
        <>
            {children}
        </>
    )
}

export function NegativeButtons({children}: ButtonContainerProps) {
    return (
        <>
            {children}
        </>
    )
}

type ButtonLayoutChildren = React.ReactElement<ButtonContainerProps>;

type ButtonLayoutProps = {
    children: ButtonLayoutChildren | Array<ButtonLayoutChildren>,
}

export function ButtonLayout({children}: ButtonLayoutProps) {

    function filterChildren(children: Array<ButtonLayoutChildren>): Array<ButtonLayoutChildren> {
        return children
            .filter((child) => (child.type === PositiveButtons || child.type === NegativeButtons));
    }

    function sortChildren(children: Array<React.ReactElement<ButtonContainerProps>>) {
        return children.sort(compareChildren);
    }

    function compareChildren(child1: ButtonLayoutChildren, child2: ButtonLayoutChildren) {
        return getWeight(child1) - getWeight(child2);
    }

    function getWeight(child: React.ReactElement<ButtonContainerProps>): number {
        switch (child.type) {
            case PositiveButtons:
                return 0;
            case NegativeButtons:
            default:
                return 1;
        }
    }

    let filteredChildren = filterChildren(React.Children.toArray(children) as Array<ButtonLayoutChildren>);
    let sortedChildren = sortChildren(filteredChildren);
    return (
        <>
            {sortedChildren}
        </>
    )
}

