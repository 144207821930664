
export enum StyleSettingsTranslationKey {
    CHANGE_COLOR = "diagrams.editor.style.changeColor",
    FILL_COLOR = "diagrams.editor.style.fillColor",
    FONT_SIZE = "diagrams.editor.style.fontSize",
    FONT_STYLE = "diagrams.editor.style.fontStyle",
    KEEP_UNCHANGED = "diagrams.editor.style.keepUnchanged",
    LINE_COLOR = "diagrams.editor.style.lineColor",
    LINE_WIDTH = "diagrams.editor.style.lineWidth",
    RESET_TO_DEFAULTS = "diagrams.editor.style.resetToDefaults",
    STYLE_SETTINGS = "diagrams.editor.style.styleSettings",
    TEXT_COLOR = "diagrams.editor.style.textColor",
    USE_CUSTOM = "diagrams.editor.style.useCustom",
}