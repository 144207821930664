import {DataSubformValidator} from "../DataSubformValidator";
import {LabelsTreeDataDto} from "../../../../service/HierarchyTreeDataDto";
import {CommonTranslation} from "../../../../../CommonTranslation";
import {ValidationResult} from "../../../../../../../../common/validation/ValidationResult";

export class LabelsSubformValidator implements DataSubformValidator {
    validate(data: LabelsTreeDataDto): ValidationResult {
        const errors = [];

        if (data.labels == null || data.labels.length === 0) {
            errors.push({ errorMessage: CommonTranslation.FILL_OUT_AT_LEAST_ONE });
        }

        return {
            errors: errors
        };
    }
}
