import React from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {Dialog, DialogContent} from '@mui/material';
import {Configuration} from "./Configuration";
import {ConfigurationId} from "./Configuration";
import {ImportConfigurationNullable} from "../../../../common/Types";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";
import ImportPanel from "./ImportPanel";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import {INITIAL_CONFIGURATION_ID_PARAM} from "../../../../common/routes/AppRoutes";

const margin = 3;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: theme.spacing(margin),
            marginTop: theme.spacing(margin + 1),
        },
    })
);
interface Props {
    initialConfigurationId?: ConfigurationId,
    isOpened: boolean,
    onDialogClosed: () => void,
}

function getInitialConfiguration(props: Props): ImportConfigurationNullable {
    const initialConfigurationId = props[INITIAL_CONFIGURATION_ID_PARAM];
    let initialConfiguration = null;
    if (initialConfigurationId != null) {
        initialConfiguration = Configuration.findById(Configuration.getConfigurations(), initialConfigurationId);
    }
    return initialConfiguration;
}

export default function ImportDialog(props: Props) {

    const classes = useStyles();

    const initialConfiguration = getInitialConfiguration(props);
    const dialogTitle = initialConfiguration != null ? initialConfiguration.pageLabel : _transl(ImportTranslationKey.TITLE);

    const {isOpened, onDialogClosed} = props;

    return (
        <Dialog
            open={isOpened}
            aria-labelledby="import-dialog"
            onClose={onDialogClosed}
            fullWidth={true}
            maxWidth={"xl"}
        >
            <DialogTitle title={dialogTitle}
                         onDialogClosed={onDialogClosed} />
            <DialogContent className={classes.dialogContent}>
                <ImportPanel initialConfigurationId={props[INITIAL_CONFIGURATION_ID_PARAM]}
                             closeDialog={onDialogClosed}/>
            </DialogContent>
        </Dialog>
    );
}
