
export enum Language {
    CS = "CS",
    EN = "EN"
}

const languageToLocaleMap = new Map([
    [Language.CS, "cs"],
    [Language.EN, "en"]
]);

export function languageToLocale(language: Language): string | undefined {
    return languageToLocaleMap.get(language);
}

export function languageFromCode(code: string | undefined): Language | undefined {
    if (code) {
        const langCode = Object.entries(Language)
            .map(([langCode]) => langCode)
            .find((langCode) => langCode === code);
        if (langCode) {
            return langCode as Language;
        }
    }
    return undefined;
}