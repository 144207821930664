import React, {useContext, useReducer} from "react";
import {GridColDef} from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../../../components/grid/GridAction";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from '@mui/icons-material/Create';
import {PersistentStateId} from "../../../../../../store/common/Grid";
import {IPropertyDto} from "../../../../../../common/apis/CommonTypes";
import CreatePropertyDialog from "./CreatePropertyDialog";
import ConfirmationDialog from "../../../../../../components/dialogs/ConfirmationDialog";
import Api from "../../../../../../common/Api";
import SucceededAlert from "../../../../../../components/SucceededAlert";
import NotSucceededAlert from "../../../../../../components/NotSucceededAlert";
import Constants from "../../../../../../common/Constants";
import {ElementDto} from "../../../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../../ElementDetailTranslationKey";
import {getLabelForPropertyType} from "../../../property/PropertyType";
import UpdatePropertyDialog from "./UpdatePropertyDialog";
import ExtGridWrapper from "../../../../../../components/grid/ExtGridWrapper";
import {CommonTranslation} from "../../../CommonTranslation";
import ElementPropertyDefinitionContext, {
    ElementPropertyDefinition
} from "../../../../../../common/ElementPropertyDefinitionContext";


interface IState {
    addPropertyDialogVisible: boolean,
    changePropertyDialog?: {
        selectedProperty: IPropertyDto,
    },
    deletePropertyDialog?: {
        selectedRowId: string,
    },
    succeededAlert?: {
        text: string,
    },
    notSucceededAlert?: {
        text: string,
    },
}
const initialState: IState = {
    addPropertyDialogVisible: false,
}

enum ActionType {
    UPDATE_ADD_PROPERTY_DIALOG_VISIBLE = "UPDATE_ADD_PROPERTY_DIALOG_VISIBLE",
    UPDATE_CHANGE_PROPERTY_DIALOG = "UPDATE_CHANGE_PROPERTY_DIALOG",
    UPDATE_DELETE_PROPERTY_DIALOG = "UPDATE_DELETE_PROPERTY_DIALOG",
    UPDATE_SUCCEEDED_ALERT = "UPDATE_SUCCEEDED_ALERT",
    UPDATE_NOT_SUCCEEDED_ALERT = "UPDATE_NOT_SUCCEEDED_ALERT",
}

type Action =
    {type: ActionType.UPDATE_ADD_PROPERTY_DIALOG_VISIBLE, payload: boolean} |
    {type: ActionType.UPDATE_CHANGE_PROPERTY_DIALOG, payload: {selectedProperty: IPropertyDto} | undefined} |
    {type: ActionType.UPDATE_DELETE_PROPERTY_DIALOG, payload: {selectedRowId: string} | undefined} |
    {type: ActionType.UPDATE_SUCCEEDED_ALERT, payload: {text: string} | undefined} |
    {type: ActionType.UPDATE_NOT_SUCCEEDED_ALERT, payload: {text: string} | undefined};

function getAddPropertyDialogVisibleAction(visible: boolean): Action {
    return {
        type: ActionType.UPDATE_ADD_PROPERTY_DIALOG_VISIBLE,
        payload: visible,
    }
}

function getShowChangePropertyDialogAction(selectedProperty: IPropertyDto): Action {
    return {
        type: ActionType.UPDATE_CHANGE_PROPERTY_DIALOG,
        payload: {selectedProperty: selectedProperty},
    }
}

function getShowDeletePropertyDialogAction(selectedRowId: string): Action {
    return {
        type: ActionType.UPDATE_DELETE_PROPERTY_DIALOG,
        payload: {selectedRowId: selectedRowId},
    }
}

type AlertActionType = ActionType.UPDATE_SUCCEEDED_ALERT | ActionType.UPDATE_NOT_SUCCEEDED_ALERT;

function getShowAlertAction(type: AlertActionType, text: string): Action {
    return {
        type: type,
        payload: {text: text},
    }
}

type NullableDialogActionType = ActionType.UPDATE_CHANGE_PROPERTY_DIALOG | ActionType.UPDATE_DELETE_PROPERTY_DIALOG |
    ActionType.UPDATE_NOT_SUCCEEDED_ALERT | ActionType.UPDATE_SUCCEEDED_ALERT;

function getHideDialogAction(type: NullableDialogActionType): Action {
    return {
        type: type,
        payload: undefined,
    }
}

function reducer(state: IState, action: Action): IState {
    switch (action.type) {
        case ActionType.UPDATE_ADD_PROPERTY_DIALOG_VISIBLE:
            return {
                ...state,
                addPropertyDialogVisible: action.payload,
            }
        case ActionType.UPDATE_CHANGE_PROPERTY_DIALOG:
            return {
                ...state,
                changePropertyDialog: action.payload,
            }
        case ActionType.UPDATE_DELETE_PROPERTY_DIALOG:
            return {
                ...state,
                deletePropertyDialog: action.payload,
            }
        case ActionType.UPDATE_SUCCEEDED_ALERT:
            return {
                ...state,
                succeededAlert: action.payload,
            }
        case ActionType.UPDATE_NOT_SUCCEEDED_ALERT:
            return {
                ...state,
                notSucceededAlert: action.payload,
            }
    }
    return state;
}

interface PropertyPanelProps  {
    element: ElementDto,
    onPropertyUpdate: () => void,
}

export default function PropertyPanel({element, onPropertyUpdate}: PropertyPanelProps) {

    const [
        {addPropertyDialogVisible, changePropertyDialog, deletePropertyDialog,
            notSucceededAlert, succeededAlert
        },
        dispatch
    ] = useReducer(reducer, initialState);

    const elementPropertyDefinitionContext = useContext<ElementPropertyDefinition>(ElementPropertyDefinitionContext);

    function deleteRow(selectedRowId: string) {
        Api.elements.deleteItemAttribute(selectedRowId, element.identifier)
            .subscribe({
                next: (response) => {
                    dispatch(getShowAlertAction(ActionType.UPDATE_SUCCEEDED_ALERT, _transl(ElementDetailTranslationKey.ATTRIBUTE_DELETE_SUCCEEDED)));
                    setTimeout(() => dispatch(getHideDialogAction(ActionType.UPDATE_SUCCEEDED_ALERT)), Constants.FE_APP_ALERT_DELAY);
                    elementPropertyDefinitionContext.updatePropertyDefinitions();
                    onPropertyUpdate();
                },
                error: (err) => {
                    dispatch(getShowAlertAction(ActionType.UPDATE_NOT_SUCCEEDED_ALERT, _transl(ElementDetailTranslationKey.ATTRIBUTE_DELETE_FAILED)));
                    setTimeout(() => dispatch(getHideDialogAction(ActionType.UPDATE_NOT_SUCCEEDED_ALERT)), Constants.FE_APP_ALERT_DELAY);
                }
            });
        dispatch(getHideDialogAction(ActionType.UPDATE_DELETE_PROPERTY_DIALOG));
    }
    function createColumns() {
        const COLUMN_DEF: GridColDef[] = [
            { field: 'name', headerName: _transl(CommonTranslation.TITLE), width: 300},
            { field: 'value', headerName: _transl(ElementDetailTranslationKey.ATTRIBUTES_VALUE), width: 300},
            { field: 'type', headerName: _transl(ElementDetailTranslationKey.TYPE), width: 300, valueFormatter: (params) => getLabelForPropertyType(params.value as string)},
        ];
        return COLUMN_DEF;
    }

    const properties = element && element.properties ? element.properties : [];
    properties.sort((a, b) => {
        const nameA = a.definition.name.toLowerCase();
        const nameB = b.definition.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    const rows = properties.map((prop, index) => ({
        identifier: prop.definition.identifier,
        name: prop.definition.name,
        type: prop.definition.type,
        value: prop.value,
        index: index,
    }));

    return (
        <React.Fragment>
            {succeededAlert && <SucceededAlert text={succeededAlert.text}
                                               opened={true}
                                               onClose={() => dispatch(getHideDialogAction(ActionType.UPDATE_SUCCEEDED_ALERT))}/>
            }
            {notSucceededAlert && <NotSucceededAlert text={notSucceededAlert.text}
                                                     opened={true}
                                                     onClose={() => dispatch(getHideDialogAction(ActionType.UPDATE_NOT_SUCCEEDED_ALERT))}/>
            }
            {deletePropertyDialog && <ConfirmationDialog open={true}
                                                         title={_transl(ElementDetailTranslationKey.ATTRIBUTES_DELETE_TTILE)}
                                                         confirmationText={_transl(ElementDetailTranslationKey.ATTRIBUTES_DELETE_CONFIRMATION)}
                                                         onConfirm={() => {
                                                             deleteRow(deletePropertyDialog.selectedRowId);
                                                         }}
                                                         onReject={() => dispatch(getHideDialogAction(ActionType.UPDATE_DELETE_PROPERTY_DIALOG))}/>
            }
            {addPropertyDialogVisible && <CreatePropertyDialog opened={true}
                                                               existingPropertyNames={new Set(properties.map(value => value.definition.name))}
                                                               onClosed={() => dispatch(getAddPropertyDialogVisibleAction(false))}
                                                               elementId={element.identifier}
                                                               onSave={() => onPropertyUpdate()}/>
            }
            {changePropertyDialog && <UpdatePropertyDialog opened={true}
                                                           onClosed={() => dispatch(getHideDialogAction(ActionType.UPDATE_CHANGE_PROPERTY_DIALOG))}
                                                           elementId={element.identifier}
                                                           onPropertyChanged={() => onPropertyUpdate()}
                                                           selectedProperty={changePropertyDialog.selectedProperty}/>
            }
            <ExtGridWrapper
                columns={createColumns()}
                rows={rows}
                rowCount={rows.length}
                getRowId={row => row.identifier + row.index}
                actions={[
                    GridAction.buttonBuilder("ITEMS_ADD_PROPERTY_BUTTON", ActionButtonType.IMMEDIATE, _transl(ElementDetailTranslationKey.ATTRIBUTES_ADD_BUTTON),
                        <AddIcon/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .isEnabled(() => element.acl.canCreateProperty)
                        .onClick(() => {
                            dispatch(getAddPropertyDialogVisibleAction(true));
                        }).build(),
                    GridAction.buttonBuilder("ITEMS_UPDATE_PROPERTY_BUTTON", ActionButtonType.IMMEDIATE, _transl(ElementDetailTranslationKey.ATTRIBUTES_EDIT_BUTTON),
                        <CreateIcon/>)
                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                        .isEnabled(() => element.acl.canUpdate)
                        .onClick((selectedRowIds, selectedRows) => {
                            const item = properties.filter(property => property.definition.identifier === selectedRows[0].identifier)[0];
                            dispatch(getShowChangePropertyDialogAction(item));
                        }).build(),
                    GridAction.buttonBuilder("ITEMS_DELETE_PROPERTY_BUTTON", ActionButtonType.IMMEDIATE, _transl(ElementDetailTranslationKey.ATTRIBUTES_DELETE_BUTTON),
                        <DeleteIcon/>)
                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                        .isEnabled(() => element.acl.canUpdate)
                        .onClick((selectedRowIds, selectedRows) => {
                            dispatch(getShowDeletePropertyDialogAction(selectedRows[0].identifier));
                        }).build(),
                ]}
                peristentStateId={PersistentStateId.ELEMENT_DETAIL_PAGE_ATTRIBUTES_GRID}
                resourceId={element.identifier}
            />
        </React.Fragment>
    );
}
