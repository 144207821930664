import {GridColDef} from "@mui/x-data-grid";
import {TranslationFunction} from "../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../DiagramTranslationKey";
import {getLabelForPropertyType} from "../../property/PropertyType";

export function createDiagramDetailPanelGridColDef(_transl: TranslationFunction): GridColDef[] {
    return [
        {
            field: 'name',
            headerName: _transl(DiagramTranslationKey.GRID_HEADER_NAME),
            width: 300
        },
        {
            field: 'value',
            headerName: _transl(DiagramTranslationKey.GRID_HEADER_VALUE),
            width: 300
        },
        {
            field: 'type',
            headerName: _transl(DiagramTranslationKey.GRID_HEADER_TYPE),
            width: 300,
            valueFormatter: (params) => getLabelForPropertyType(params.value as string)
        },
    ];
}
