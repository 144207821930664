import * as d3 from "d3";
import EventManager from "./EventManager";
import {IChartEventListener} from "../event/Listener";
import {EventType, IChartEvent} from "../event/Event";
import {GRAPH_DATA_CHART_SVG_DIV_ID} from "../GraphDataChartPanel";
import {hideMainPageOverlay, MAIN_PAGE_OVERLAY_ZINDEX, showMainPageOverlay} from "../../../pages/MainPage";

export default class ZoomManager implements IChartEventListener {

    public static readonly ZOOM_DURATION_MS = 500;
    public static readonly ZOOM_FACTOR = 1.3;
    public static readonly ZOOM_FACTOR_INCREMENT = 0.4;
    private svg?: SVGSVGElement;
    private eventManager: EventManager;
    private zoom?: d3.ZoomBehavior<Element, any>;
    private zoomFactorIncrementMultiplier: number = 1;
    private lastChartGroupTransform?: string;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.eventManager.subscribeChartListener(this, EventType.CHART_ZOOM_IN);
        this.eventManager.subscribeChartListener(this, EventType.CHART_ZOOM_OUT);
        this.eventManager.subscribeChartListener(this, EventType.CHART_ZOOM_RESET);
        this.eventManager.subscribeChartListener(this, EventType.CHART_MAXIMIZE);
        this.eventManager.subscribeChartListener(this, EventType.CHART_MINIMIZE);
    }

    public init(svg: SVGSVGElement,
                svgElem: d3.Selection<SVGSVGElement, unknown, null, undefined>,
                chartGroupElem: d3.Selection<SVGGElement, unknown, null, undefined>) {
        this.svg = svg;


        this.zoom = d3.zoom();
        this.zoom.on("zoom", (e) => {
            this.lastChartGroupTransform = e.transform;
            chartGroupElem.attr('transform', this.lastChartGroupTransform as string);
        });
        if (this.lastChartGroupTransform) {
            chartGroupElem.attr("transform", this.lastChartGroupTransform);
        }

        svgElem.call(this.zoom as any);

    }

    private zoomIn() {
        const scaleIncrement = this.createScaleIncrement();
        this.zoom?.scaleBy(
            d3.select(this.svg as Element)
                .transition()
                .duration(ZoomManager.ZOOM_DURATION_MS),
            scaleIncrement);

        this.zoomFactorIncrementMultiplier += 1;
        if (this.zoomFactorIncrementMultiplier > Number.MAX_SAFE_INTEGER) {
            this.zoomFactorIncrementMultiplier = Number.MAX_SAFE_INTEGER;
        }
    }

    private zoomOut() {
        this.zoomFactorIncrementMultiplier -= 1;
        if (this.zoomFactorIncrementMultiplier < 1) {
            this.zoomFactorIncrementMultiplier = 1;
        }

        const scaleDecrement = 1 / this.createScaleIncrement();
        this.zoom?.scaleBy(d3.select(this.svg as Element).transition().duration(ZoomManager.ZOOM_DURATION_MS), scaleDecrement);
    }

    private resetZoom() {
        if (this.zoom) {
            d3.select(this.svg as Element).transition().duration(ZoomManager.ZOOM_DURATION_MS).call(this.zoom.transform, d3.zoomIdentity);
        }
    }

    private createScaleIncrement() {
        return ZoomManager.ZOOM_FACTOR + (ZoomManager.ZOOM_FACTOR_INCREMENT * this.zoomFactorIncrementMultiplier);
    }

    private maximize() {
        showMainPageOverlay();
        d3.select("#"+GRAPH_DATA_CHART_SVG_DIV_ID)
            .style('height', 'auto')
            .style('width', 'auto')
            .style('position', 'absolute')
            .style('inset', 0)
            .style('background-color', 'white')
            .style('z-index', MAIN_PAGE_OVERLAY_ZINDEX + 1)
            .style('margin', "20px");
    }

    private minimize() {
        hideMainPageOverlay();
        d3.select("#"+GRAPH_DATA_CHART_SVG_DIV_ID)
            .style('position', 'relative')
            .style("inset", 0)
            .style("margin", 0)
            .style('z-index', "auto")
            .style("height", "100%");
    }

    handleChartEvent(event: IChartEvent): void {
        if (event.type === EventType.CHART_ZOOM_IN) {
            this.zoomIn();
        }
        if (event.type === EventType.CHART_ZOOM_OUT) {
            this.zoomOut();
        }
        if (event.type === EventType.CHART_ZOOM_RESET) {
            this.resetZoom();
        }
        if (event.type === EventType.CHART_MAXIMIZE) {
            this.maximize();
        }
        if (event.type === EventType.CHART_MINIMIZE) {
            this.minimize();
        }
    }

}