import elementService, {ElementService} from "../service/ElementService";
import {RelationshipDto} from "../../../../../common/apis/relationship/RelationshipDto";
import {DiagramService} from "../../diagrams/service/DiagramService";
import {DiagramContentFilterDtoBuilder} from "../../diagrams/service/DiagramContentFilterDto";
import {IElementStateDto} from "../../../../../common/apis/Elements";
import {ILabelDto} from "../../../../../common/apis/label/ILabelDto";
import {CollectionDto} from "../../../../../common/apis/collection/CollectionDto";
import {DateNullable} from "../../../../../common/Types";

export interface ElementDetailController {
    getRelationshipsCount(elementId: string): Promise<number>,

    getAllRelationships(elementId: string): Promise<RelationshipDto[]>,

    getDiagramsContainingElementCount(elementId: string): Promise<number>,

    updateValidFromById(elementId: any, date: DateNullable): Promise<any>,

    updateValidToById(elementId: any, date: DateNullable): Promise<any>,

    updateDescriptionById(elementId: any, text: string): Promise<any>,
    updateStateById(elementId: string, state: IElementStateDto | undefined): Promise<any>,

    updateNameById(elementId: any, text: string): Promise<any>,

    updateLabels(elementId: string, labels: Array<ILabelDto>): Promise<any>,

    updateCollections(elementId: string, collections: Array<CollectionDto>): Promise<any>,

    createProperty(elementId: string, name: string, type: string, value: string, propDefIdentifier?: string): Promise<any>,

    updateProperty(elementId: string, propertyId: string, value: string): Promise<any>,
}

class ElementDetailControllerImpl implements ElementDetailController {

    constructor(private service: ElementService, private diagramService: DiagramService) {}

    async getRelationshipsCount(elementId: string) {
        const relationships = await this.service.findAllRelationships(elementId) as RelationshipDto[];
        return relationships.length;
    }

    async getAllRelationships(elementId: string) : Promise<RelationshipDto[]>{
        return await this.service.findAllRelationships(elementId) as RelationshipDto[];
    }

    async getDiagramsContainingElementCount(elementId: string) {
        const filter = DiagramContentFilterDtoBuilder.builder()
            .elementIds([elementId])
            .build();

        const diagramsCount = await this.diagramService.searchByContent(filter);
        return diagramsCount.length;
    }

    async updateValidFromById(elementId: any, date: DateNullable): Promise<any> {
        return elementService.updateValidFromById(elementId, date);
    }

    async updateValidToById(elementId: any, date: DateNullable): Promise<any> {
        return elementService.updateValidToById(elementId, date);
    }

    async updateDescriptionById(elementId: any, text: string): Promise<any> {
        return elementService.updateDescriptionById(elementId, text);
    }

    async updateStateById(elementId: string, state: IElementStateDto | undefined): Promise<any> {
        return elementService.updateStateById(elementId, state);
    }

    async updateNameById(elementId: any, text: string): Promise<any> {
        return elementService.updateNameById(elementId, text);
    }

    async updateLabels(elementId: string, labels: Array<ILabelDto>): Promise<any> {
        return elementService.updateLabels(elementId, labels);
    }

    async updateCollections(elementId: string, collections: Array<CollectionDto>): Promise<any> {
        return elementService.updateCollections(elementId, collections);
    }

    async createProperty(elementId: string, name: string, type: string, value: string, propDefIdentifier?: string): Promise<any> {
        return elementService.createProperty(elementId, name, type, value, propDefIdentifier);
    }

    async updateProperty(elementId: string, propertyId: string, value: string): Promise<any> {
        return elementService.updateProperty(elementId, propertyId, value);
    }
}

export default function constructElementDetailController(service: ElementService, diagramService: DiagramService): ElementDetailController {
    return new ElementDetailControllerImpl(service, diagramService);
}
