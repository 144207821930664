import Constants from "../Constants";
import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../Api";

export interface IElementDataDto {
    id: string,
    type: string,
    name: string,
    description: string,
}

export interface IRelationshipDataDto {
    id: string,
    type: string,
    startNodeId: string,
    endNodeId: string,
}

export interface IGraphDataDto {
    elementsData: Array<IElementDataDto>,
    relationshipsData: Array<IRelationshipDataDto>,
    maxResultsLimit: number,
    maxResultsLimitExceeded: boolean,
}

export default class GraphData {

    public static ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/graph";
    public static GET_NEIGHBOURS_URL : string = `${GraphData.ENDPOINT_URL}/elements:neighbours`;
    public static GET_INTERRELATIONSHIPS_URL : string = `${GraphData.ENDPOINT_URL}/elements:interrelationships`;

    getNeighbours(elementIdentifiers: Array<string>): Observable<AjaxResponse> {
        return Api.createAjax({
            url: GraphData.GET_NEIGHBOURS_URL,
            method: "POST",
            body: elementIdentifiers,
        });
    }

    getInterrelationships(elementIdentifiers: Array<string>): Observable<AjaxResponse> {
        return Api.createAjax({
            url: GraphData.GET_INTERRELATIONSHIPS_URL,
            method: "POST",
            body: elementIdentifiers,
        });
    }

}