export enum GraphQueryTranslationKey {
    QUERIES = "query.menu.name",
    QUERIES_TITLE = "query.title",

    IDENTIFIER = "query.grid.id",
    NAME = "query.name",
    DESCRIPTION = "query.description",
    QUERY_TEXT = "query.query",
    ACCESS = "query.grid.permission",
    AUTHOR = "query.grid.author",
    CREATED = "query.grid.queryCreated",
    LAST_CHANGED = "query.grid.queryLastChanged",

    QUERY_RUN = "query.grid.icon.run",

    QUERIES_NOT_FETCHED = "query.notFetched",

    DELETE_FAILED = "query.delete.failed",
    DELETE_TITLE = "query.delete.title",
    DELETE_SUCCEEDED = "query.delete.succeeded",
    DELETE_CONFIRMATION = "query.delete.confirmation",

    CREATE_TITLE = "query.create.title",
    DETAIL_TITLE = "query.detail.title",
    IS_PRIVATE = "query.isPrivate",
    UPDATE_SUCCEEDED = "query.updateSucceeded",
    ADD_SUCCEEDED = "query.addSucceeded",
    ADD_FAILED = "query.addFailed",

    EDITOR_RUN_QUERY = "query.editor.runQuery",
    EDITOR_TITLE = "query.editor.title",
    ERROR_QUERY_EXECUTION_MESSAGE = "query.executionError.message",
    ERROR_QUERY_EXECUTION_TITLE = "query.executionError.title",

    UNSAVED_CHANGES = "query.unsavedChanges",
    CONFIRMATION_TEXT = "query.discardChangesConfirmation",

    GRAPH = "query.graph",
    ELEMENTS = "query.elements",

    CYPHER_REF = "query.cypherRef",
}
