import {Action, Reducer} from "redux";
import {IElementStateDto, IElementTypeDto} from "../../common/apis/Elements";
import {CollectionDto} from "../../common/apis/collection/CollectionDto";
import {ActionsObservable, StateObservable} from "redux-observable";
import {switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {IApplicationState} from "../Store";
import {UserDto} from "../../common/apis/user/UserDto";
import {ILabelDto} from "../../common/apis/label/ILabelDto";
import { PagingFilterDto } from "../../common/apis/PagingFilterDto";

// ### PAGE SPECIFIC DTOs ###

export interface PropertyFilterDto {
     name: string;
     value: string;
     contains: boolean;
}

export interface IFilter extends PagingFilterDto {
    searchText?: string;
    identifierLike?: string;
    identifiers?: Array<string>,
    nameLike?: string;
    selectedLabels?: ILabelDto[];
    selectedTypes?: IElementTypeDto[];
    selectedCollections?: CollectionDto[];
    selectedStates?: IElementStateDto[];
    validFrom?: Date;
    validThru?: Date;
    stereotype?: string;
    selectedAuthors?: UserDto[];
    propertyFilters?: PropertyFilterDto[];
}

// ### ITEMS FILTER ###

    // Actions

export enum FilterActionType {
    UPDATE = "ACTION/ITEMS/FILTER/UPDATE",
    REFETCH_FILTER = "ACTION/ITEMS/FILTER/REFETCH_FILTER",
}

interface IFilterAction extends Action<FilterActionType> {
    type: FilterActionType;
    filter?: IFilter,
    searchString?: string,
    refetchOnly?: boolean,
}

export function getFilterUpdateAction(filter: IFilter): IFilterAction {
    return {
        type: FilterActionType.UPDATE,
        filter: filter,
    }
}

// epic private refetch only action
function getFilterUpdateRefetchOnlyAction(filter: IFilter): IFilterAction {
    return {
        type: FilterActionType.UPDATE,
        filter: filter,
        refetchOnly: true,
    }
}

export function getFilterRefetchAction(): IFilterAction {
    return {
        type: FilterActionType.REFETCH_FILTER,
    }
}


    // State

export interface IFilterState {
    lastFilter: IFilter;
    lastFilterUpdate?: Date,
}

const initialFilterState: IFilterState = {
    lastFilter: {},
}

    // Reducer

export const filterReducer: Reducer<IFilterState, IFilterAction> = (
    state = initialFilterState,
    action
) => {
    switch(action.type) {
        case FilterActionType.UPDATE :
            return {
                ...state,
                lastFilter: action.filter as IFilter,
                lastFilterUpdate: action.refetchOnly === true ? state.lastFilterUpdate : new Date(),
            };
    }
    return state;
};

export const refetchFilterEpic = (action$: ActionsObservable<IFilterAction>, $state: StateObservable<IApplicationState>) =>
    action$.ofType(FilterActionType.REFETCH_FILTER)
        .pipe(
            switchMap(() => {
                return of(getFilterUpdateRefetchOnlyAction($state.value.pages.elements.filter.submittedFilter.lastFilter));
            }),
        );
