import clsx from "clsx";
import {default as MaterialAppBar} from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {IconButton, ListItemButton, Stack, SxProps, useMediaQuery, useTheme} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ListItemText from "@mui/material/ListItemText";
import React, {useRef, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import UserAccountMenu from "./UserAccountMenu";
import Search from "./search/Search";
import {IApplicationState} from "../../../store/Store";
import {useSelector} from "react-redux";
import UserFormatter from "../content/users/UserFormatter";
import elementSearchProvider from "./search/ElementSearchProvider";
import diagramSearchProvider from "./search/DiagramSearchProvider";
import {AccountTree, AppsRounded, Build, Home, Public, Settings} from "@mui/icons-material";
import AppBarIconButton from "./AppBarIconButton";
import AdministrationMenu from "./AdministrationMenu";
import ToolsMenu from "./ToolsMenu";
import {
    administrationMenu,
    dashboardMenu,
    diagramsMenu,
    elementsMenu,
    structuresMenu,
    toolsMenu
} from "../../../common/menudefinition/MenuDefinitionUtils";
import AppBarIconLink from "./AppBarIconLink";
import {Dashboard} from "../../../store/dashboard/Dashboard";
import AppLogo from "./AppLogo";
import {UserDto} from "../../../common/apis/user/UserDto";
import {_transl} from "../../../store/localization/TranslMessasge";
import LanguageSwitcher from "./language/LanguageSwitcher";
import {Location, useLocation} from "react-router-dom";
import RouteDefinition from "../../../common/routedefinition/RouteDefinition";
import {ADMINISTRATION_PAGES, TOOL_PAGES} from "../../../common/routes/AppRoutes";
import Constants from "../../../common/Constants";

const useStyles = makeStyles((theme: Theme) => createStyles({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    grow: {
        flexGrow: 1,
    },
    accountIcon: {
        marginLeft: theme.spacing(1),
        color: 'white',
        minWidth: theme.spacing(0)
    },
}));

interface AppBarProps {
    sx?: SxProps;
}

export default function AppBar(props: AppBarProps) {
    const {sx} = props;

    const administrationIconRef = useRef<HTMLDivElement>();
    const [administrationMenuOpened, setAdministrationMenuOpened] = useState(false);

    const toolsIconRef = useRef<HTMLDivElement>();
    const [toolsMenuOpened, setToolsMenuOpened] = useState(false);

    const accountIconRef = useRef<HTMLDivElement>(null);
    const [profileMenuOpened, setProfileMenuOpened] = useState(false);

    const user = useSelector<IApplicationState, UserDto | undefined>((state) => state.user.userData);

    const dashboard = useSelector<IApplicationState, Dashboard>((state) => state.dashboard);
    const dashboardNotEmpty = isNotEmpty(dashboard.mainWidget?.url) || isNotEmpty(dashboard.mainWidget?.diagramIdentifier);

    const theme = useTheme();
    const classes = useStyles();

    const useSmallLogo = useMediaQuery(theme.breakpoints.down('md'));
    const showUserName = useMediaQuery(theme.breakpoints.up('sm'));

    const location = useLocation();
    const isAnyToolPageActive = containsPages(location, TOOL_PAGES);
    const isAnyAdministrationPageActive = containsPages(location, ADMINISTRATION_PAGES);

    function containsPages(location: Location, routes: RouteDefinition[]): boolean {
        return routes.some(route => `${Constants.FE_APP_MAIN_ROUTE}/${route.path}` === location.pathname);
    }

    function isNotEmpty(str: string | null | undefined): boolean {
        return str != null && str.length > 0;
    }

    return (
        <>
            <MaterialAppBar
                position="fixed"
                sx={sx}
                className={clsx(classes.appBar)}
            >
                <Toolbar sx={{"@media (min-width: 600px)": {pr: 2}}}>
                    <AppLogo size={useSmallLogo ? "small" : "large"}/>

                    <Stack direction={"row"} spacing={1} ml={2} mr={2}>
                        {dashboardNotEmpty &&
                            <AppBarIconLink tooltip={_transl(dashboardMenu.label)}
                                            icon={Home}
                                            to={dashboardMenu.path} />
                        }
                        <AppBarIconLink tooltip={_transl(structuresMenu.label)}
                                        icon={Public}
                                        to={structuresMenu.path} />

                        <AppBarIconLink tooltip={_transl(elementsMenu.label)}
                                        icon={AppsRounded}
                                        to={elementsMenu.path} />
                        <AppBarIconLink tooltip={_transl(diagramsMenu.label)}
                                        icon={AccountTree}
                                        to={diagramsMenu.path} />
                        <AppBarIconButton ref={toolsIconRef}
                                          isActive={isAnyToolPageActive}
                                          icon={Build}
                                          tooltip={_transl(toolsMenu.label)}
                                          onClick={() => setToolsMenuOpened(!toolsMenuOpened)} />
                        <AppBarIconButton ref={administrationIconRef}
                                          icon={Settings}
                                          tooltip={_transl(administrationMenu.label)}
                                          isActive={isAnyAdministrationPageActive}
                                          onClick={() => setAdministrationMenuOpened(!administrationMenuOpened)} />
                    </Stack>

                    <div className={classes.grow} />

                    <Search searchProviders={[elementSearchProvider, diagramSearchProvider]}
                            sx={{display: { sm: 'none', md: 'flex'}}} />

                    <LanguageSwitcher />

                    <IconButton
                        edge="end"
                        aria-label="account"
                        aria-haspopup="true"
                        onClick={() => setProfileMenuOpened(!profileMenuOpened)}
                        color="inherit"
                        size="large">
                        <ListItemButton aria-controls="simple-menu" aria-haspopup="true" key={"Account"} disableGutters={true} ref={accountIconRef}>
                            {showUserName &&
                                <ListItemText primary={UserFormatter.formatFullName(user?.firstName, user?.lastName)}/>
                            }
                            <ListItemIcon className={classes.accountIcon}>{<AccountCircle/>}</ListItemIcon>
                        </ListItemButton>
                    </IconButton>
                </Toolbar>
            </MaterialAppBar>

            <ToolsMenu opened={toolsMenuOpened}
                       anchorRef={toolsIconRef.current}
                       onMenuClose={() => setToolsMenuOpened(false)}/>
            <AdministrationMenu opened={administrationMenuOpened}
                                anchorRef={administrationIconRef.current}
                                onMenuClose={() => setAdministrationMenuOpened(false)}/>
            <UserAccountMenu opened={profileMenuOpened}
                             anchorRef={accountIconRef.current}
                             onMenuClose={() => setProfileMenuOpened(false)}/>
        </>
    );
}
