import React from "react";
import {HierarchyTreeItemDetailDto} from "../service/HierarchyTreeItemDetailDto";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {ReferenceTreeDataDto, ReferenceType} from "../service/HierarchyTreeDataDto";
import LinkIcon from "@mui/icons-material/Link";
import Constants from "../../../../../common/Constants";
import {ViewList} from "@mui/icons-material";
import {Colors} from "../../../../../common/apis/Color";
import {useTheme} from "@mui/material";

export interface ReferenceTreeItemLabelProps {
    treeItem: HierarchyTreeItemDetailDto;
}

export function ReferenceTreeItemLabel(props: ReferenceTreeItemLabelProps) {
    const {treeItem} = props;
    const treeData = treeItem.treeData as ReferenceTreeDataDto;

    const theme = useTheme();

    const color = treeItem.color ? Colors.toCss(treeItem.color) : theme.palette.grey[400];
    let icon: JSX.Element;
    let href: string;

    if (treeData.referenceType === ReferenceType.ELEMENT_PAGE_REPORT) {
        icon = <ViewList/>;
        href = `${Constants.FE_APP_MAIN_ROUTE}/elements?presets=${treeData.reference}`;
    } else {
        icon = <LinkIcon/>;
        href = treeData.reference;
    }

    return (
        <>
            <Typography component={"span"} sx={{color: color, display: "flex", alignItems: "center", mr: 1}}>
                {icon}
            </Typography>
            <Typography component={"span"} sx={{display: "flex", alignItems: "center"}}>
                <Link href={href} target={"_blank"}>{treeItem.title}</Link>
            </Typography>
        </>
    );
}