import React, {useEffect, useState} from "react";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../components/grid/GridAction";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import {PersistentStateId} from "../../../../store/common/Grid";
import {PagePresetsTranslationKey} from "./PagePresetsTranslationKey";
import {PagePresetsDto} from "./client/PagePresetsDto";
import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import {CommonTranslation} from "../CommonTranslation";
import Snackbar from "../../../main/content/snackbar/Snackbar";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import pagePresetsClient from "./client/PagePresetsClient";
import {FormatPaint} from "@mui/icons-material";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import PagePresetsUpdateDialog from "./PagePresetsUpdateDialog";
import {UserInfoDto} from "../../../../common/apis/user/UserInfoDto";
import UserFormatter from "../users/UserFormatter";
import LinkIcon from "@mui/icons-material/Link";
import {ContextMenuItem} from "../../../../components/ContextMenu";
import RouteDefinitionUtils from "../../../../common/routedefinition/RouteDefinitionUtils";
import Constants from "../../../../common/Constants";
import {from} from "rxjs";
import {ElementTranslationKey} from "../elements/ElementTranslationKey";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

enum ButtonId {
    USE_PRESETS = "USE_PRESETS",
    UPDATE_GRID_PRESETS = "UPDATE_GRID_PRESETS",
    DELETE_GRID_PRESETS = "DELETE_GRID_PRESETS",
}

interface PagePresetsManagementDialogProps {
    gridId: string;
    open: boolean;
    onApplyPresets: (presets: PagePresetsDto) => void;
    onDialogClosed: () => void;
}

export default function PagePresetsManagementDialog(props: PagePresetsManagementDialogProps) {

    const {gridId, open, onApplyPresets, onDialogClosed} = props;
    const [availablePresets, setAvailablePresets] = useState<PagePresetsDto[]>([]);
    const [refreshPresets, setRefreshPresets] = useState(new Date());
    const [selectedPresets, setSelectedPresets] = useState<PagePresetsDto>();

    const [updateDialogOpened, setUpdateDialogOpened] = useState<boolean>(false);
    const [deleteConfirmDialogOpened, setDeleteConfirmDialogOpened] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            pagePresetsClient.findAllPresets(gridId)
                    .then(availablePresets => setAvailablePresets(availablePresets))
                    .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA, err)));
        }
    }, [gridId, open, refreshPresets]);

    function applyPresets(pagePresets: PagePresetsDto) {
        if (onApplyPresets) {
            onApplyPresets(pagePresets);
            onDialogClosed();
        }
    }

    function showUpdateDialog(pagePresets: PagePresetsDto) {
        setSelectedPresets(pagePresets);
        setUpdateDialogOpened(true);
    }

    function closeUpdateDialog(saved: boolean) {
        if (saved) {
            setRefreshPresets(new Date());
        }
        setUpdateDialogOpened(false);
    }

    function showDeleteConfirmationDialog(pagePresets: PagePresetsDto) {
        setSelectedPresets(pagePresets);
        setDeleteConfirmDialogOpened(true);
    }

    function closeDeleteConfirmationDialog() {
        setDeleteConfirmDialogOpened(false);
    }

    function deletePresets() {
        if (selectedPresets) {
            pagePresetsClient.delete(gridId, selectedPresets.id)
                    .then(() => {
                        closeDeleteConfirmationDialog();
                        setRefreshPresets(new Date());
                    })
                    .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_REMOVE_DATA), err));
            setSelectedPresets(undefined);
        }
    }

    function copyUrlToClipboard(id: string | undefined) {
        if (id !== undefined) {
            const path = RouteDefinitionUtils.resolveElementPagePath(id);
            const url = Constants.attachAppHost(path);
            from(navigator.clipboard.writeText(url)).subscribe(
                () => {
                    Snackbar.success(_transl(ElementTranslationKey.URL_COPIED));
                },
                () => {
                }
            );
        }
    }

    const menuItems: ContextMenuItem<string>[] = [
        {
            icon: <LinkIcon/>,
            label: _transl(CommonTranslation.COPY_URL),
            onClick: (id) => copyUrlToClipboard(id),
        },
    ];

    const columns: GridColDef<PagePresetsDto>[] = [
        {
            field: 'name', headerName: _transl(CommonTranslation.TITLE), width: 250
        },
        {
            field: 'description', headerName: _transl(CommonTranslation.DESCRIPTION), width: 300
        },
        {
            field: 'isPrivate',
            headerName: _transl(CommonTranslation.PRIVATE),
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                switch (params.value) {
                    case true:
                        return _transl(CommonTranslation.YES);
                    case false:
                        return _transl(CommonTranslation.NO);
                    default:
                        return "";
                }
            }
        },
        {
            field: 'user',
            headerName: _transl(CommonTranslation.USER),
            width: 200,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (!params.value) {
                    return "";
                } else {
                    const user = params.value as UserInfoDto;
                    return UserFormatter.formatFullName(user.firstName, user.lastName);
                }
            }
        },
    ];

    return (
        <>
            <ConfirmationDialog open={deleteConfirmDialogOpened}
                                title={_transl(PagePresetsTranslationKey.PRESETS_DELETE_TITLE)}
                                confirmationText={_transl(PagePresetsTranslationKey.PRESETS_DELETE_CONFIRMATION)}
                                onConfirm={() => deletePresets()}
                                onReject={() => closeDeleteConfirmationDialog()} />

            <PagePresetsUpdateDialog open={updateDialogOpened}
                                     pageId={gridId}
                                     pagePresets={selectedPresets}
                                     onDialogClosed={(saved) => closeUpdateDialog(saved)} />

            <Dialog
                    open={open}
                    aria-labelledby="grid-presets-administration-dialog"
                    onClose={onDialogClosed}
                    fullWidth={true}
                    maxWidth={"md"}
            >
                <DialogTitle
                        id="grid-presets-administration-dialog"
                        title={_transl(PagePresetsTranslationKey.PRESETS_MANAGEMENT)}
                        onDialogClosed={onDialogClosed}
                />
                <DialogContent>
                    <ExtGridWrapper
                            rows={availablePresets}
                            rowCount={availablePresets.length}
                            columns={columns}
                            menuItems={menuItems}
                            getRowId={row => row.id}
                            actions={[
                                GridAction.buttonBuilder(ButtonId.USE_PRESETS, ActionButtonType.IMMEDIATE, _transl(PagePresetsTranslationKey.USE_PRESETS),
                                        <FormatPaint/>)
                                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                                        .onClick((_, selectedPresets) => {
                                            applyPresets(selectedPresets[0] as PagePresetsDto);
                                        })
                                        .build(),
                                GridAction.buttonBuilder(ButtonId.UPDATE_GRID_PRESETS, ActionButtonType.IMMEDIATE, _transl(CommonTranslation.UPDATE),
                                        <CreateIcon/>)
                                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                                        .onClick((_, selectedPresets) => {
                                            showUpdateDialog(selectedPresets[0] as PagePresetsDto)
                                        })
                                        .isEnabled(selectedPresets => selectedPresets.length > 0 && selectedPresets[0].acl.canUpdate)
                                        .build(),
                                GridAction.buttonBuilder(ButtonId.DELETE_GRID_PRESETS, ActionButtonType.IMMEDIATE, _transl(CommonTranslation.REMOVE),
                                        <DeleteIcon/>)
                                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                                        .onClick((_, selectedPresets) => {
                                            showDeleteConfirmationDialog(selectedPresets[0] as PagePresetsDto);
                                        })
                                        .isEnabled(selectedPresets => selectedPresets.length > 0 && selectedPresets[0].acl.canDelete)
                                        .build(),
                            ]}
                            peristentStateId={PersistentStateId.PRESETS_GRID}
                            resourceId={"page-presets-management"}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
