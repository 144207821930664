import {FolderViewType} from "../../options/FolderViewType";
import {ItemFactory} from "./ItemFactory";
import Item, {ItemType} from "./Item";
import LayerItemFactory from "./LayerItemFactory";
import TypeStereotypeItemFactory from "./TypeStereotypeItemFactory";
import {ModelTreeUtils} from "../ModelTreeView";
import FolderIcon from "@mui/icons-material/Folder";
import Constants from "../../../../../../common/Constants";
import StickIcon from "../../../../../svgicons/StickIcon";
import React from "react";
import {RelationshipDto} from "../../../../../../common/apis/relationship/RelationshipDto";
import {DiagramDto} from "../../../../../../common/apis/diagram/DiagramDto";
import {ElementDto} from "../../../../../../common/apis/element/ElementDto";
import {DiagramEditorTranslationKey} from "../../../../DiagramEditorTranslationKey";
import {_transl} from "../../../../../../store/localization/TranslMessasge";

export default class TreeViewItemFactory {

    private static folderViewTypeToFactory: {[id: string]: ItemFactory} = {
        [FolderViewType.LAYERS]: new LayerItemFactory(),
        [FolderViewType.TYPES]: new TypeStereotypeItemFactory(false),
        [FolderViewType.STEREOTYPES]: new TypeStereotypeItemFactory(true),
    }

    public static getItems(folderViewType: FolderViewType,
                           elements: Array<ElementDto>,
                           relationships: Array<RelationshipDto>,
                           diagrams: Array<DiagramDto>,
                           utils: ModelTreeUtils): Array<Item> {
        return TreeViewItemFactory.folderViewTypeToFactory[folderViewType].getItems(elements, relationships, diagrams, utils);
    }

    public static createRelationshipsItem(elements: Array<ElementDto>,
                                          relationships: Array<RelationshipDto>,
                                          diagrams: Array<DiagramDto>,
                                          utils: ModelTreeUtils): Item {
        const item: Item = {
            nodeId: "relationships",
            itemType: ItemType.FOLDER,
            labelText: _transl(DiagramEditorTranslationKey.MODEL_TREE_RELATIONSHIPS),
            labelIcon: <FolderIcon />,
            labelIconColor: Constants.MODEL_FOLDER_ICON_COLOR,
            children: [],
        }
        const children = relationships.map(relationship => {
            return {
                nodeId:relationship.identifier,
                itemType: ItemType.RELATIONSHIP,
                labelText: utils.getRelationshipNameComponent(relationship),
                labelTitle: utils.getRelationshipNameTitle(relationship),
                labelIcon: <StickIcon/>,
                children: [],
            }
        });
        item.children.push(...children);
        return item;
    }

}
