import React from "react";
import {Folder} from "@mui/icons-material";
import Constants from "../../../../../common/Constants";
import {HierarchyTreeItemDetailDto} from "../service/HierarchyTreeItemDetailDto";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import Typography from "@mui/material/Typography";
import {Colors} from "../../../../../common/apis/Color";

export interface FolderTreeItemLabelProps {
    treeItem: HierarchyTreeItemDetailDto;
}

export function FolderTreeItemLabel(props: FolderTreeItemLabelProps) {
    const {treeItem} = props;

    const color = treeItem.color ? Colors.toCss(treeItem.color) : Constants.MODEL_FOLDER_ICON_COLOR;

    function formatTitle(treeItem: HierarchyTreeItemDetailDto): string {
        if (treeItem.title === undefined) {
            return "";
        } else {
            return treeItem.translateTitle ? _transl(treeItem.title) : treeItem.title
        }
    }

    return (
        <>
            <Typography component={"span"} sx={{color: color, marginRight: 1, display: "flex", alignItems: "center"}}>
                <Folder />
            </Typography>
            <Typography component={"span"} sx={{display: "flex", alignItems: "center"}}>
                {formatTitle(treeItem)}
            </Typography>
        </>
    );
}