import {IconButton, IconButtonProps, Tooltip} from "@mui/material";
import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            activated: {
                color: "white",
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.dark,
                "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                }
            }
        }
    )
);

enum TooltippedIconButtonVariant {
    STANDARD = "standard",
    ACTIVATED = "activated",
}

export interface TooltippedIconButtonProps {
    tooltip?: string;
    onClick: () => void;
    icon: JSX.Element;
    disabled?: boolean;
    color?: IconButtonProps["color"];
    size?: IconButtonProps["size"];
    variant?: `${TooltippedIconButtonVariant}`;
}

export function TooltippedIconButton({tooltip, onClick, icon, disabled, color, size, variant}: TooltippedIconButtonProps) {
    const classes = useStyles();

    let classNames: string;
    if (variant === TooltippedIconButtonVariant.ACTIVATED) {
        classNames = classes.activated;
    }

    const renderIconButton = () => (
        <IconButton
            className={classNames}
            color={color}
            size={size ?? "small"}
            onClick={onClick}
            disabled={disabled}
        >
            {icon}
        </IconButton>
    );

    return tooltip ? (
        <Tooltip title={tooltip} placement={"top"}>
            {renderIconButton()}
        </Tooltip>
    ) : (
        renderIconButton()
    );
}

