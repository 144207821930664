import {HierarchyTreeTranslationKey} from "../../HierarchyTreeTranslationKey";
import {
    createCollectionsTreeDataDto, createFolderTreeDataDto,
    createLabelsTreeDataDto,
    createLayersTreeDataDto, createQueriesTreeDataDto,
    HierarchyTreeDataDto
} from "../../service/HierarchyTreeDataDto";
import LayersSubform from "./data/layers/LayersSubform";
import {LayersSubformValidator} from "./data/layers/LayersSubformValidator";
import LabelsSubform from "./data/labels/LabelsSubform";
import {LabelsSubformValidator} from "./data/labels/LabelsSubformValidator";
import {ElementType} from "react";
import {DataSubformValidator} from "./data/DataSubformValidator";
import {HierarchyTreeDataType} from "../../service/HierarchyTreeDataType";
import CollectionsSubform from "./data/collections/CollectionsSubform";
import {CollectionsSubformValidator} from "./data/collections/CollectionsSubformValidator";
import {LabelTreeViewMode} from "../../service/LabelTreeViewMode";
import {CollectionTreeViewMode} from "../../service/CollectionTreeViewMode";
import QueriesSubform from "./data/queries/QueriesSubform";
import {QueriesSubformValidator} from "./data/queries/QueriesSubformValidator";
import {FolderSubformValidator} from "./data/folder/FolderSubformValidator";
import FolderSubform from "./data/folder/FolderSubform";
import EmptySubform from "./data/empty/EmptySubform";
import {EmptySubformValidator} from "./data/empty/EmptySubformValidator";

export class HierarchyTreeDataTypeEnum {
    public static readonly [HierarchyTreeDataType.COLLECTIONS] = new HierarchyTreeDataTypeEnum(HierarchyTreeTranslationKey.DATA_TYPE_COLLECTIONS,
        HierarchyTreeDataType.COLLECTIONS, createCollectionsTreeDataDto(CollectionTreeViewMode.FOLDERS, []), CollectionsSubform, new CollectionsSubformValidator());

    public static readonly [HierarchyTreeDataType.FOLDER] = new HierarchyTreeDataTypeEnum(HierarchyTreeTranslationKey.DATA_TYPE_FOLDER,
        HierarchyTreeDataType.FOLDER, createFolderTreeDataDto(), FolderSubform, new FolderSubformValidator());

    public static readonly [HierarchyTreeDataType.LABELS] = new HierarchyTreeDataTypeEnum(HierarchyTreeTranslationKey.DATA_TYPE_LABELS,
        HierarchyTreeDataType.LABELS, createLabelsTreeDataDto(LabelTreeViewMode.FOLDERS, []), LabelsSubform, new LabelsSubformValidator());

    public static readonly [HierarchyTreeDataType.LAYERS] = new HierarchyTreeDataTypeEnum(HierarchyTreeTranslationKey.DATA_TYPE_LAYERS,
        HierarchyTreeDataType.LAYERS, createLayersTreeDataDto([]), LayersSubform, new LayersSubformValidator());

    public static readonly [HierarchyTreeDataType.QUERIES] = new HierarchyTreeDataTypeEnum(HierarchyTreeTranslationKey.DATA_TYPE_QUERIES,
        HierarchyTreeDataType.QUERIES, createQueriesTreeDataDto([]), QueriesSubform, new QueriesSubformValidator());

    public static readonly [HierarchyTreeDataType.REFERENCE] = new HierarchyTreeDataTypeEnum(HierarchyTreeTranslationKey.DATA_TYPE_REFERENCE,
        HierarchyTreeDataType.REFERENCE, { type: "Reference" }, EmptySubform, new EmptySubformValidator());

    translationKey: string;
    dataType: HierarchyTreeDataType;
    emptyData: HierarchyTreeDataDto;
    subform: ElementType;
    validator: DataSubformValidator;

    constructor(translationKey: string, dataType: HierarchyTreeDataType, emptyData: HierarchyTreeDataDto,
                subform: ElementType, validator: DataSubformValidator) {
        this.translationKey = translationKey;
        this.dataType = dataType
        this.emptyData = emptyData;
        this.subform = subform;
        this.validator = validator;
    }

    public static values(): Array<HierarchyTreeDataTypeEnum> {
        return Object.values(HierarchyTreeDataType)
            .map(key => HierarchyTreeDataTypeEnum[key as HierarchyTreeDataType]);
    }

    public static getFromType(type: string): HierarchyTreeDataTypeEnum | undefined {
        const dataTypes = this.values().filter(dataTypeEnum => dataTypeEnum.dataType === type);
        return dataTypes.length > 0 ? dataTypes[0] : undefined;
    }

}
