import Api from "../../../../../common/Api";
import {map} from "rxjs/operators";
import Constants from "../../../../../common/Constants";
import {RelationshipDto} from "../../../../../common/apis/relationship/RelationshipDto";
import {forkJoin} from "rxjs";
import {RelationshipSearchType} from "../../../../../common/apis/relationship/RelationshipSearchType";
import moment from "moment";
import {IElementStateDto} from "../../../../../common/apis/Elements";
import {ILabelDto} from "../../../../../common/apis/label/ILabelDto";
import {CollectionDto} from "../../../../../common/apis/collection/CollectionDto";
import {DateNullable} from "../../../../../common/Types";
import {IPropertyDefinition} from "../../../../../common/apis/CommonTypes";

const ELEMENT_ID_PARAM = ":elementId";
const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/repository/elements";
const CONCRETE_ELEMENT_URL: string = `${ENDPOINT_URL}/${ELEMENT_ID_PARAM}`;
const UPDATE_STATE_BY_ID: string = `${ENDPOINT_URL}/${ELEMENT_ID_PARAM}:change-state`
const UPDATE_LABELS_URL: string = `${ENDPOINT_URL}/${ELEMENT_ID_PARAM}/labels`;
const UPDATE_COLLECTIONS_URL: string = `${ENDPOINT_URL}/${ELEMENT_ID_PARAM}/collections`;
const PROPERTY_ID_PARAM = ":propertyDefinitionId";
const CREATE_PROPERTY_URL: string = `${ENDPOINT_URL}/${ELEMENT_ID_PARAM}/properties/`
const UPDATE_PROPERTY_URL: string = `${ENDPOINT_URL}/${ELEMENT_ID_PARAM}/properties/${PROPERTY_ID_PARAM}`;
const RELATIONSHIPS_SEARCH_URL: string = Constants.API_HOST + "/rest-api/repository/relationships/search";

export interface ElementService {
    fetchRelationships(itemId: string): Promise<Array<Array<RelationshipDto>>>,

    findAllRelationships(itemId: string): Promise<any>

    updateValidFromById(elementId: any, date: DateNullable): Promise<any>,

    updateValidToById(elementId: any, date: DateNullable): Promise<any>,

    updateDescriptionById(elementId: any, text: string): Promise<any>,

    updateStateById(elementId: string, state: IElementStateDto | undefined): Promise<any>,

    updateNameById(elementId: any, text: string): Promise<any>,

    updateLabels(elementId: string, labels: Array<ILabelDto>): Promise<any>,

    updateCollections(elementId: string, collections: Array<CollectionDto>): Promise<any>,

    createProperty(elementId: string, name: string, type: string, value: string, propDefIdentifier?: string): Promise<any>,

    updateProperty(elementId: string, propertyId: string, value: string): Promise<any>,

    findAllPropertyDefinitions(): Promise<Array<IPropertyDefinition>>,
}

class RxJsElementService implements ElementService {

    async fetchRelationships(itemId: string): Promise<Array<Array<RelationshipDto>>> {
        return forkJoin([
            Api.relationships.findRelationships([itemId], RelationshipSearchType.SOURCE),
            Api.relationships.findRelationships([itemId], RelationshipSearchType.TARGET),
        ]).pipe(map(responses => [
            responses[0].response as Array<RelationshipDto>,
            responses[1].response as Array<RelationshipDto>
        ])).toPromise();
    }

    async findAllRelationships(elementId: string): Promise<RelationshipDto[]> {
        const request = {
            url: RELATIONSHIPS_SEARCH_URL,
            method: "POST",
            body: {
                "sourceIdentifiers": [elementId],
                "targetIdentifiers": [elementId],
            }
        };

        return Api.createAjax(request)
            .pipe(map(response => response.response as RelationshipDto[]))
            .toPromise();
    }

    async updateNameById(elementId: any, text: string): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(CONCRETE_ELEMENT_URL, {[ELEMENT_ID_PARAM]: elementId}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["name", text]
            ])),
        };

        return Api.createAjax(request).toPromise();
    }

    async updateValidFromById(elementId: any, date: DateNullable): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(CONCRETE_ELEMENT_URL, {[ELEMENT_ID_PARAM]: elementId}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["validFrom", date != null ? moment(date).toISOString() : null]
            ])),
        };

        return Api.createAjax(request).toPromise();
    }

    async updateDescriptionById(elementId: any, text: string): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(CONCRETE_ELEMENT_URL, {[ELEMENT_ID_PARAM]: elementId}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["description", text]
            ])),
        };

        return Api.createAjax(request).toPromise();
    }

    async updateStateById(elementId: string, state: IElementStateDto | undefined): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(UPDATE_STATE_BY_ID, {[ELEMENT_ID_PARAM]: elementId}),
            method: "PUT",
            body: state != null ? state.code : "",
        };

        return Api.createAjax(request).toPromise();
    }

    async updateValidToById(elementId: any, date: DateNullable): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(CONCRETE_ELEMENT_URL, {[ELEMENT_ID_PARAM]: elementId}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["validThru", date != null ? moment(date).toISOString() : null]
            ])),
        };

        return Api.createAjax(request).toPromise();
    }

    async updateLabels(elementId: string, labels: Array<ILabelDto>): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(UPDATE_LABELS_URL, {[ELEMENT_ID_PARAM]: elementId}),
            method: "PUT",
            body: {
                "labelsCodes": labels.map(item => item.code)
            }
        };

        return Api.createAjax(request).toPromise();
    }

    async updateCollections(elementId: string, collections: Array<CollectionDto>): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(UPDATE_COLLECTIONS_URL, {[ELEMENT_ID_PARAM]: elementId}),
            method: "PUT",
            body: {
                "collectionsCodes": collections.map(item => item.code)
            }
        };

        return Api.createAjax(request).toPromise();
    }

    async createProperty(elementId: string,name: string, type: string, value: string, propDefIdentifier?: string): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(CREATE_PROPERTY_URL, {[ELEMENT_ID_PARAM]: elementId}),
            method: "POST",
            body: {
                name: name,
                type: type,
                value: value,
                propDefIdentifier: propDefIdentifier,
            },
        };

        return Api.createAjax(request).toPromise();
    }

    async updateProperty(elementId: string, propertyId: string, value: string): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(UPDATE_PROPERTY_URL, {
                [ELEMENT_ID_PARAM]: elementId,
                [PROPERTY_ID_PARAM]: propertyId
            }),
            method: "PUT",
            body: {
                value: value,
            },
        };

        return Api.createAjax(request).toPromise();
    }

    async findAllPropertyDefinitions(): Promise<Array<IPropertyDefinition>> {
        return Api.createAjax({
            url: Constants.API_HOST + "/rest-api/elements/propertyDefinitions",
            method: "GET",
        })
            .pipe(map(response => response.response as IPropertyDefinition[]))
            .toPromise();
    }

}

const elementService = new RxJsElementService() as ElementService;
export default elementService;
