import EventManager, {Unsubscriber} from "../../../../event/EventManager";
import {EventType, IChartEvent} from "../../../../event/Event";
import SelectionOverlay from "./drag/SelectionOverlay";
import EventUtils from "../../../../event/EventUtils";
import {ISelectionManagerApi, ISelectionProducer} from "../ISelectionProducer";
import RenderContext from "../../../context/RenderContext";
import ConnectionRendererUtils, {LinePathUtils} from "../../../renderer/connection/ConnectionRendererUtils";
import SelectionManagerUtils from "../SelectionManagerUtils";
import SelectionManager, {SelectableObjectType} from "../../SelectionManager";
import NodeRendererUtils from "../../../renderer/node/NodeRendererUtils";
import {Area, Point} from "../../../util/GeometryUtils";
import {IDiagramNodeDto} from "../../../../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../../../apis/diagram/IDiagramConnectionDto";

enum SelectionType {
    INTERSECTION = "INTERSECTION",
    INCLUSION = "INCLUSION",
}

export default class DragSelection implements ISelectionProducer {

    private selectionOverlay: SelectionOverlay;
    private eventManager: EventManager;
    private api: ISelectionManagerApi;

    private startPoint: Point;
    private endPoint: Point;

    private renderContext?: RenderContext;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager,
                api: ISelectionManagerApi)
    {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_CANVAS_DRAG_STARTED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_CANVAS_DRAG_IN_PROGRESS, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_CANVAS_DRAG_FINISHED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_CANVAS_DRAG_CANCELLED, this.handleChartEvent.bind(this)));

        this.api = api;
        this.selectionOverlay = new SelectionOverlay();

        this.startPoint = new Point(0, 0);
        this.endPoint = new Point(0, 0);
    }

    onDestroy() {
        this.destroy();
    }
    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    private handleChartEvent(event: IChartEvent) {
        if (event.type === EventType.CHART_CANVAS_DRAG_STARTED) {
            this.setStartPoint(event);
            this.selectionOverlay.showOverlay(this.computeArea(), this.renderContext);
        }
        if (event.type === EventType.CHART_CANVAS_DRAG_IN_PROGRESS) {
            this.updateEndPoint(event);
            this.selectionOverlay.updateOverlay(this.computeArea(), this.renderContext);
        }
        if (event.type === EventType.CHART_CANVAS_DRAG_FINISHED) {
            this.updateEndPoint(event);
            this.selectionOverlay.updateOverlay(this.computeArea(), this.renderContext);
            this.selectionOverlay.hideOverlay();

            this.updateSelection(event);
        }
        if (event.type === EventType.CHART_CANVAS_DRAG_CANCELLED) {
            this.selectionOverlay.hideOverlay();
        }
    }

    private setStartPoint(event: IChartEvent) {
        const xy = EventUtils.getTransformedCoordinates(event.event);
        this.startPoint = new Point(xy[0], xy[1]);
        this.endPoint = new Point(this.startPoint.x, this.startPoint.y);
    }

    private updateEndPoint(event: IChartEvent) {
        const xy = EventUtils.getTransformedCoordinates(event.event);
        this.endPoint = new Point(xy[0], xy[1]);
    }

    private updateSelection(event: IChartEvent) {
        const selectedNodes: Array<IDiagramNodeDto> = [];
        const selectedConnections: Array<IDiagramConnectionDto> = [];

        const dragArea = this.computeArea();
        const selectionType = this.resolveSelectionType();
        const candidates = SelectionManagerUtils.getAllSelectableObjectGroups();
        if (!candidates.empty()) {
            candidates.nodes().forEach(node => {
                const type = SelectionManagerUtils.getSelectableProperty(node as SVGGElement, SelectionManager.SELECTABLE_OBJECT_TYPE_PROPERTY_NAME);
                const object = SelectionManagerUtils.getSelectableProperty(node as SVGGElement, SelectionManager.SELECTABLE_OBJECT_PROPERTY_NAME);

                if (type === SelectableObjectType.NODE) {
                    const diagramNode = object as IDiagramNodeDto;
                    const isSelected: boolean = DragSelection.isNodeSelected(diagramNode, dragArea, selectionType);
                    if (isSelected) {
                        selectedNodes.push(diagramNode);
                    }
                } else if (type === SelectableObjectType.CONNECTION) {
                    const diagramConnection = object as IDiagramConnectionDto;
                    const isSelected: boolean = DragSelection.isConnectionSelected(diagramConnection, dragArea, selectionType);
                    if (isSelected) {
                        selectedConnections.push(diagramConnection);
                    }
                }
            });

            this.api.onSelectionChanged(selectedNodes, selectedConnections, event.event);
        }
    }

    private computeArea() {
        const startPoint = this.startPoint;
        const endPoint = this.endPoint;
        const minX = Math.min(startPoint.x, endPoint.x);
        const minY = Math.min(startPoint.y, endPoint.y);
        const w = Math.abs(endPoint.x - startPoint.x);
        const h = Math.abs(endPoint.y - startPoint.y);

        return new Area(minX, minY, w, h);
    }

    private resolveSelectionType() {
        return this.startPoint.x > this.endPoint.x ? SelectionType.INTERSECTION : SelectionType.INCLUSION;
    }

    private static isNodeSelected(diagramNode: IDiagramNodeDto, dragArea: Area, selectionType: SelectionType) {
        const nodeArea = NodeRendererUtils.extractAreaProperty(diagramNode);
        if (nodeArea) {
            if (selectionType === SelectionType.INCLUSION) {
                return dragArea.contains(nodeArea);
            }
            if (selectionType === SelectionType.INTERSECTION) {
                return dragArea.intersects(nodeArea);
            }
        }
        return false;
    }

    private static isConnectionSelected(connection: IDiagramConnectionDto, dragArea: Area, selectionType: SelectionType) {
        const linePath = ConnectionRendererUtils.extractLinePathProperty(connection);
        if (linePath && linePath.points.length > 1) {
            if (selectionType === SelectionType.INCLUSION) {
                return dragArea.containsAllPoints(linePath.points);
            }
            if (selectionType === SelectionType.INTERSECTION) {
                return dragArea.intersectsAnyLine(LinePathUtils.createLines(linePath));
            }
        }
        return false;
    }
}