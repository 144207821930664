import EventManager from "./EventManager";
import {IChartEventListener, INodeEventListener} from "../event/Listener";
import {EventType, IChartEvent, INodeEvent} from "../event/Event";
import {ChartRenderContext, ElementApi, GraphDataChartApi} from "../GraphDataChart";
import * as d3 from "d3";
import NodeMenu from "./nodemenu/NodeMenu";
import {INode} from "./ModelManager";


export default class NodeMenuManager implements INodeEventListener, IChartEventListener {

    private eventManager: EventManager;
    private nodeMenu: NodeMenu;


    constructor(eventManager: EventManager, chartApi: GraphDataChartApi, elementApi: ElementApi) {
        this.eventManager = eventManager;
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MOUSE_CLICK);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_DRAG_IN_PROGRESS);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MOUSE_DBLCLICK);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MENUITEM_CLICKED);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_ANCHORED_ON_DRAG_END);
        this.eventManager.subscribeChartListener(this, EventType.CHART_MOUSE_LEAVE);
        this.eventManager.subscribeChartListener(this, EventType.CHART_MOUSE_CLICKED);

        this.nodeMenu = new NodeMenu(this.eventManager, chartApi, elementApi);
    }

    init(defsElement: d3.Selection<SVGDefsElement, unknown, any, any>,
         chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
         renderingContext: ChartRenderContext) {
        this.nodeMenu.init(defsElement, chartGroup, renderingContext);
    }

    handleNodeEvent(event: INodeEvent): void {
        const selectedNode = this.getSelectedNode()

        if (event.type === EventType.NODE_MOUSE_CLICK) {
            if (selectedNode?.id === event.node.id) {
                this.hideNodeMenu(event, event.node);
            } else {
                if (selectedNode?.id != null) {
                    this.hideNodeMenu(event, selectedNode);
                }
                this.showNodeMenu(event, true);
            }
        }
        if (event.type === EventType.NODE_DRAG_IN_PROGRESS || event.type === EventType.NODE_MOUSE_DBLCLICK || event.type === EventType.NODE_MENUITEM_CLICKED) {
            if (selectedNode?.id) {
                this.hideNodeMenu(event, selectedNode);
            }
        }
        if (event.type === EventType.NODE_ANCHORED_ON_DRAG_END) {
            this.showAnchorIndicator(event.node);
        }
    }

    handleChartEvent(event: IChartEvent) {
        if (event.type === EventType.CHART_MOUSE_LEAVE || event.type === EventType.CHART_MOUSE_CLICKED) {
            this.hideNodeMenuWhenOpened(event);
        }
    }

    private getSelectedNode() {
        return this.nodeMenu.getSelectedNode();
    }

    private hideNodeMenu(event: INodeEvent, node: INode) {
        this.nodeMenu.hide();
        this.eventManager.publishNodeEvent({type: EventType.NODE_MENU_CLOSED, event: event.event, node: node})
    }

    private hideNodeMenuWhenOpened(event: IChartEvent) {
        const selectedNode = this.nodeMenu.getSelectedNode();
        if (selectedNode) {
            const nodeEvent: INodeEvent = {type: EventType.NODE_MENUITEM_CLICKED, event: event.event, node: selectedNode};
            this.hideNodeMenu(nodeEvent, selectedNode);
        }
    }

    public showNodeMenu(event: INodeEvent, runAnimation: boolean) {
        this.nodeMenu.show(event, runAnimation);
        this.eventManager.publishNodeEvent({type: EventType.NODE_MENU_OPENED, event: event.event, node: event.node})
    }

    public showAnchorIndicator(node: INode) {
        this.nodeMenu.showAnchorIndicator(node);
    }
}