import Constants from "../Constants";
import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../Api";
import {RelationshipSearchType} from "./relationship/RelationshipSearchType";

export default class Relationships {

    public static ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/repository/relationships";
    public static SEARCH_URL: string = `${Relationships.ENDPOINT_URL}/search`;
    public static DELETE_URL: string = `${Relationships.ENDPOINT_URL}/:identifier`;

    findRelationships(identifiers: string[], searchType: RelationshipSearchType): Observable<AjaxResponse> {
        const property = searchType === RelationshipSearchType.SOURCE ? "sourceIdentifiers" : "targetIdentifiers";
        return Api.createAjax({
            url: Relationships.SEARCH_URL,
            method: "POST",
            body: {
                [property]: identifiers,
            },
        });
    }

    deleteRelationship(id: string): Observable<AjaxResponse> {
        return Api.createAjax({
                url: Relationships.DELETE_URL.replace(":identifier", id),
                method: "DELETE",
            });
    }

}
