export enum LabelsTranslationKey {
    IS_PRIVATE = "labels.isPrivate",
    TITLE = "labels.title",
    ADMINISTRATION_TOOLTIP = "labels.administration.tooltip",

    ADD_TITLE = "labels.add.title",
    ADD_BUTTON = "labels.add.button",
    ADD_FAILED = "labels.add.failed",
    ADD_SUCCEEDED = "labels.add.succeeded",

    DELETE_TITLE = "labels.delete.title",
    DELETE_BUTTON = "labels.delete.button",
    DELETE_CONFIRMATION = "labels.delete.confirmation",
    DELETE_FAILED = "labels.delete.failed",
    DELETE_SUCCEEDED = "labels.delete.succeeded",

    UPDATE_TITLE = "labels.update.title",
    UPDATE_BUTTON = "labels.update.button",
    UPDATE_FAILED = "labels.update.failed",
    UPDATE_SUCCEEDED = "labels.update.succeeded",

    NOT_FETCHED = "labels.notFetched",
    DIAGRAM_IS_USING_LABEL = "labels.error.diagramIsUsingLabel",
    ELEMENT_IS_USING_LABEL = "labels.error.elementIsUsingLabel",
    CODE_IS_USED_BY_ANOTHER_LABEL = "labels.error.codeIsUsedByAnotherLabel",
    CREATE_PROPERTY_SUCCEEDED = "labels.success.createProperty",
    UPDATE_PROPERTY_SUCCEEDED = "labels.success.updateProperty",
    CREATE_PROPERTY_FAILED = "labels.error.createProperty",
    UPDATE_PROPERTY_FAILED = "labels.error.updateProperty",
}
