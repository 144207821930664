import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../../store/Store";
import {UserDto} from "../../../../../common/apis/user/UserDto";
import ChatCommentCreateFooter, {NEW_CHAT_ID} from "../ChatCommentCreateFooter";
import {ChatDto, ChatState} from "../../../../../common/apis/chat/ChatService";
import ChatAccordion from './ChatAccordion';
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {ChatTranslationKey} from "../ChatTranslationKey";
import {Theme} from "@mui/material/styles";


interface ChatBoxProps {
    chats: ChatDto[];
    canCreateChats: boolean;
    onChatCommentEdited: (chatId: number, commentId: number, message: string) => void;
    onChatsUpdated: () => void;
    openElementDetail?: (id: string) => void;
    elementId?: string;
    diagramId?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatBox: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%',
        },
        chatAccordionScrollWrapper: {
            flex: 1,
            overflow: 'auto',
        },
        chatAccordion: {
            overflow: 'unset',
            margin: theme.spacing(1)
        },
        chatAccordionMinHeight: {
            minHeight: '10em',
        },
        chatCommentCreateFooter: {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(3),
        },
    })
);

export default function ChatBox(props: ChatBoxProps) {

    const {chats, canCreateChats, onChatsUpdated, onChatCommentEdited, elementId, diagramId, openElementDetail} = props;
    const classes = useStyles();

    const user = useSelector<IApplicationState>(state => state.user.userData) as UserDto;
    const chatBoxRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    const [newChat, setNewChat] = useState(false);
    const newChatRef = useRef(false);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, []);

    useEffect(() => {
        newChatRef.current = newChat;
    }, [newChat]);

    useEffect(() => {
        if (newChatRef.current && scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            setNewChat(false);
        }
    }, [chats]);

    return (
        <div className={classes.chatBox} ref={chatBoxRef}>
            <div className={`${classes.chatAccordionScrollWrapper} ${chats.length > 0 ? classes.chatAccordionMinHeight : ''}`} ref={scrollRef}>
                <div className={classes.chatAccordion}>
                    {chats.map((chat) => (
                        <ChatAccordion
                            key={chat.id}
                            chat={chat}
                            elementId={elementId}
                            diagramId={diagramId}
                            onCommentEdited={onChatCommentEdited}
                            onChatsUpdated={onChatsUpdated}
                            openElementDetail={openElementDetail}
                        />
                    ))}
                </div>
            </div>
            {canCreateChats &&
                <div className={classes.chatCommentCreateFooter}>
                    <ChatCommentCreateFooter chatId={NEW_CHAT_ID}
                                             user={user}
                                             placeholder={_transl(ChatTranslationKey.NEW_CHAT_PLACEHOLDER)}
                                             chat={{
                                                 id: NEW_CHAT_ID,
                                                 chatComments: [],
                                                 elementId: elementId,
                                                 diagramId: diagramId,
                                                 state: ChatState.UNRESOLVED,
                                                 acl: {canChangeState: false, canDelete: false, canCreateComments: true}
                                             }}
                                             onCommentCreated={onChatsUpdated}
                                             handleNewChat={() => setNewChat(true)}
                    />
                </div>
            }
        </div>
    );
}