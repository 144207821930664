import {EventTypeKey, Event,} from "./Event";

export interface EventListener<E extends Event> {
    (event: E): void;
}

export type Unsubscriber = () => void;

const DEBUG_TRACING = true;
const MIN_LENGTH_FOR_STATISTICS : number = 9;

export default class EventManager {

    private listeners: Map<string, Array<EventListener<any>>>;
    private listenerMaxCountMap: Map<string, number>;

    constructor() {
        this.listeners = new Map();
        this.listenerMaxCountMap = new Map();
    }

    public publishEvent<E extends Event>(event: E) {
        this.getListenersForEvent(event.type)
            .forEach(listener => {
                listener(event)
            });
    }

    public subscribeListener<E extends Event>(eventType: EventTypeKey<E>, listener: EventListener<E>): Unsubscriber {
        const eventListeners = this.getListenersForEvent(eventType);
        eventListeners.push(listener);
        if (DEBUG_TRACING) {
            this.logListenersCountIncreasedOverThresholdGroupByEventType(eventType, eventListeners);
        }
        return () => this.removeListener(eventType, listener);
    }

    private removeListener(eventType: EventTypeKey<any>, listener: EventListener<any>) {
        const eventListeners = this.getListenersForEvent(eventType);
        const index = eventListeners.indexOf(listener);
        if (index !== -1) {
            eventListeners.splice(index, 1);
        } else {
            console.warn("Unsubscribing an unknown listener: ");
            console.warn(listener);
        }
    }

    private logListenersCountIncreasedOverThresholdGroupByEventType<E extends Event>(eventType: EventTypeKey<E>, eventListeners: Array<EventListener<E>>) {
        if (eventListeners.length >= MIN_LENGTH_FOR_STATISTICS) {
            let previousCount = this.listenerMaxCountMap.get(eventType);
            if (previousCount && previousCount > 0 && previousCount < eventListeners.length) {
                console.warn(eventType + " count of listeners increased to: " + eventListeners.length +
                    ". Inspect array logged below or increase threshold constant MIN_LENGTH_FOR_STATISTICS(" + MIN_LENGTH_FOR_STATISTICS + ")");
                console.warn(this.listeners.get(eventType));
            }
            if (!previousCount || previousCount < eventListeners.length) {
                this.listenerMaxCountMap.set(eventType, eventListeners.length);
            }
        }
    }

    private getListenersForEvent(eventType: string) : Array<EventListener<any>> {
        if (this.listeners.has(eventType)) {
            return this.listeners.get(eventType) as Array<EventListener<any>>;
        } else {
            const eventListeners = new Array<EventListener<any>>();
            this.listeners.set(eventType, eventListeners);
            return eventListeners;
        }
    }

}
