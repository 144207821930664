import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React, {useCallback, useEffect, useState} from "react";
import {DiagramEditor} from "../../../common/diagrameditor/DiagramEditor";
import DiagramNavigator from "./left/navigator/DiagramNavigator";
import {IModelUpdatedEvent} from "../../../common/event/Event";
import Constants from "../../../common/Constants";
import LeftMenuOptionsPanel from "./left/options/LeftMenuOptionsPanel";
import {FolderViewType} from "./left/options/FolderViewType";
import ModelTreeView from "./left/modeltree/ModelTreeView";
import {ItemType} from "./left/modeltree/factory/Item";
import {Unsubscriber} from "../../../common/event/EventManager";
import {IEditMode} from "../../../common/diagrameditor/editor/IEditMode";

export const menuPartsPadding = 1;
export const navigatorHeight = 150;
export const navigatorBorderSize = 1;
const LEFT_MENU_BOX_INITIAL_WIDTH = "22em";

const useLeftMenuStyles = makeStyles((theme: Theme) =>
    createStyles({
        leftMenuBox: {
            width: LEFT_MENU_BOX_INITIAL_WIDTH,
            height: "100%",
            display: "grid",
            gridTemplateRows: "auto " + (navigatorHeight + (navigatorBorderSize * 2)) + "px",
            gridTemplateAreas: '"top" "bottom"',
            backgroundColor: Constants.MENU_BACKGROUND_COLOR,
            gridGap: menuPartsPadding,
            transform: "translateX(0%) translateY(0%)",
            overflow: "auto",
        },
        project: {
            gridArea: "top",
            border: navigatorBorderSize + "px solid " + Constants.MENU_BACKGROUND_COLOR_DARKER,
            backgroundColor: "rgb(252, 252, 252)",
            position: "relative",
            marginTop: 25,
        },
        treeView: {
            position: "absolute",
            inset: 0,
            overflow: "auto",
            paddingTop: 2,
        },
        navigator: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gridArea: "bottom",
            border: navigatorBorderSize + "px solid " + Constants.MENU_BACKGROUND_COLOR_DARKER,
            backgroundColor: Constants.MENU_BACKGROUND_COLOR,
        },
    })
);

export interface OptionsPanelSelectedItem {
    id: string,
    type: ItemType,
}

interface IProps {
    menuId?: string,
    diagramId: string,
    mode: IEditMode,
    editor?: DiagramEditor;
}

export default function LeftMenu(props: IProps) {

    const {diagramId, mode, editor, menuId} = props;
    const [, setModelUpdatedDate] = useState<number>(0);
    const [folderViewType, setFolderViewType] = useState<FolderViewType>(FolderViewType.LAYERS);
    const [optionsPanelSelectedItem, setOptionsPanelSelectedItem] = useState<OptionsPanelSelectedItem>();
    const [showFilterField, setShowFilterField] = useState<boolean>(false);
    const classes = useLeftMenuStyles();

    useEffect(() => {
        let nodeCreatedUnsubscriber: Unsubscriber | undefined;
        if (editor) {
            nodeCreatedUnsubscriber = editor.getDiagramEditorApi().addModelUpdatedListener(
                (event: IModelUpdatedEvent) => setModelUpdatedDate(Date.now));
        }
        return () => {nodeCreatedUnsubscriber && nodeCreatedUnsubscriber()};
    }, [editor]);

    const diagramEditorApi = editor?.getDiagramEditorApi();
    const modelAccessor = diagramEditorApi?.getModelAccessor();

    const elements = modelAccessor?.getElements() || [];
    const relationships = modelAccessor?.getRelationships() || [];
    const diagrams = modelAccessor?.getDiagrams() || [];

    const getElementById = useCallback((id: string) => modelAccessor?.getElementById(id), [modelAccessor]);
    const selectItems = useCallback((elementIds: Array<string>, relationshipIds: Array<string>) => editor?.getDiagramEditorApi().selectItems(elementIds, relationshipIds), [editor]);

    return <div className={classes.leftMenuBox} id={menuId}>
        <LeftMenuOptionsPanel initialFolderViewType={folderViewType}
                              onFolderViewTypeChanged={setFolderViewType}
                              onCenteredItemUpdated={(id, itemType) => setOptionsPanelSelectedItem({id: id, type: itemType})}
                              diagramEditorApi={editor?.getDiagramEditorApi()}
                              onSearchButtonClicked={() => setShowFilterField(!showFilterField)}
        />
        <div className={classes.project}>
            <div className={classes.treeView}>
                <ModelTreeView folderViewType={folderViewType} elements={elements} diagrams={diagrams} relationships={relationships} diagramEditorApi={diagramEditorApi} getElementById={getElementById} selectItems={selectItems} optionsPanelSelectedItem={optionsPanelSelectedItem}
                               showFilterField={showFilterField} onFilterFieldClosed={() => setShowFilterField(false)} mode={props.mode.mode}
                />
            </div>
        </div>
        <div className={classes.navigator}>
            <DiagramNavigator diagramId={diagramId} mode={mode} editor={editor} />
        </div>
    </div>
}
