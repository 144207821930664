import {
    ConnectionOverlayBendpointUpdateEvent, ConnectionOverlayInitEvent,
    ConnectionOverlayRemoveEvent, EventType,
} from "../../event/Event";
import RenderContext from "../context/RenderContext";
import Overlay from "./connectionoverlay/Overlay";
import EventManager, {Unsubscriber} from "../../event/EventManager";

export default class ConnectionOverlaysRenderer
{

    private connectionOverlay: Overlay;

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_OVERLAY_INIT, this.onConnectionOverlayInitEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_OVERLAY_BENDPOINT_UPDATE, this.onConnectionOverlayBendpointUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_OVERLAY_REMOVE, this.onConnectionOverlayRemoveEvent.bind(this)));

        this.connectionOverlay = new Overlay(eventManager);
    }

    destroy() {
        this.connectionOverlay.destroy();
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        this.connectionOverlay.initConnectionsOverlayGroup(renderContext);
    }

    private onConnectionOverlayInitEvent(event: ConnectionOverlayInitEvent) {
        for (const connection of event.initialisedConnections) {
            this.connectionOverlay.initConnectionOverlayGroup(connection);
        }
    }

    private onConnectionOverlayBendpointUpdateEvent(event: ConnectionOverlayBendpointUpdateEvent) {
        this.connectionOverlay.updateConnectionOverlayOnBendpointMove(
            event.connection,
            event.bendpointIndex,
            event.positionIncrement,
            event.isNewBendpoint
        );
    }

    private onConnectionOverlayRemoveEvent(event: ConnectionOverlayRemoveEvent) {
        for (const connection of event.removedConnections) {
            this.connectionOverlay.removeConnectionOverlayGroup(connection);
        }
    }
}