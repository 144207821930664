import EventManager from "../../../common/event/EventManager";
import {IDiagramNodeDto} from "../../../common/apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../../common/apis/diagram/IDiagramConnectionDto";
import {ModelManager} from "../../../common/diagrameditor/manager/ModelManager";
import {StyleSettings} from "./StyleSettings";
import {StyleSettingsApplicator} from "./StyleSettingsApplicator";
import {Styleable} from "../../../common/apis/diagram/Styleable";
import {StyleSettingsDialogMode, StyleSettingsDialogVariant} from "./dialog/StyleSettingsDialog";
import SelectionManager from "../../../common/diagrameditor/manager/SelectionManager";
import {StyleSettingsExtractor} from "./StyleSettingsExtractor";
import {StyleUpdate} from "./StyleUpdate";

export interface StyleSettingsDialogConfig {
    settings: StyleSettings,
    variant: StyleSettingsDialogVariant,
    mode: StyleSettingsDialogMode
}

export class StyleSettingsManager {
    private readonly eventManager: EventManager;
    private readonly modelManager: ModelManager;
    private readonly selectionManager: SelectionManager;

    private readonly settingsApplicator: StyleSettingsApplicator;
    private readonly settingsExtractor: StyleSettingsExtractor;

    constructor(eventManager: EventManager, modelManager: ModelManager, selectionManager: SelectionManager) {
        this.eventManager = eventManager;
        this.modelManager = modelManager;
        this.selectionManager = selectionManager;
        this.settingsApplicator = new StyleSettingsApplicator();
        this.settingsExtractor = new StyleSettingsExtractor();
    }

    destroy() {
    }
    public extractStyleSettingsDialogConfig(focusedStylable: Styleable): StyleSettingsDialogConfig {
        const nodes = this.selectionManager.getSelectedNodes();
        const connections = this.selectionManager.getSelectedConnections();

        const bulkChange = (nodes.length + connections.length) > 1;
        const initialSettings = this.settingsExtractor.extractStyleSettings(focusedStylable, bulkChange);

        const variant: StyleSettingsDialogVariant = nodes.length > 0 ? "nodes" : "connections";
        const mode: StyleSettingsDialogMode = bulkChange ? "bulk" : "single";

        return {
            settings: initialSettings,
            variant: variant,
            mode: mode
        };
    }

    public applyStyleSettingsToSelection(settings: StyleSettings) {
        const nodes = this.selectionManager.getSelectedNodes();
        const nodeUpdates = this.buildNodeUpdates(nodes, settings);

        const connections = this.selectionManager.getSelectedConnections();
        const connectionUpdates = this.buildConnectionUpdates(connections, settings);

        this.modelManager.updateStyles(nodeUpdates, connectionUpdates);
    }

    private buildNodeUpdates(nodes: IDiagramNodeDto[], settings: StyleSettings) {
        const nodeUpdates: StyleUpdate[] = [];
        for (const node of nodes) {
            nodeUpdates.push({
                id: node.identifier,
                oldStyle: node.style,
                newStyle: this.settingsApplicator.applyStyleSettings(settings, node.style)
            });
        }
        return nodeUpdates;
    }

    private buildConnectionUpdates(connections: IDiagramConnectionDto[], settings: StyleSettings) {
        const connectionUpdates: StyleUpdate[] = [];
        for (const connection of connections) {
            connectionUpdates.push({
                id: connection.identifier,
                oldStyle: connection.style,
                newStyle: this.settingsApplicator.applyStyleSettings(settings, connection.style)
            });
        }
        return connectionUpdates;
    }
}