import RenderContext from "../../../../context/RenderContext";
import * as d3 from "d3";
import BendpointHandle from "./BendpointHandle";
import {
    DISABLE_ON_NODE_MOVE_POINTER_EVENTS_ALL_CLASS_NAME,
    DISABLE_ON_NODE_MOVE_POINTER_EVENTS_AUTO_CLASS_NAME,
    HANDLE_COLOR_TRANSPARENT_OPACITY,
    HANDLE_FILL_COLOR,
    HANDLE_REMOVE_FILL_COLOR_SECONDARY,
    OVERLAY_STROKE_COLOR
} from "../../../../common/UIConstants";
import {
    CUSTOM_DRAG_HANDLER,
    ICustomClickOnlyHandler,
    ICustomDragHandler
} from "../../../../manager/SvgElementDragManager";
import {IDiagramConnectionDto} from "../../../../../apis/diagram/IDiagramConnectionDto";
import EventManager from "../../../../../event/EventManager";
import {BendpointEvent, EventProperty, EventType} from "../../../../../event/Event";
import {IDiagramPoint} from "../../../../../apis/diagram/IDiagramPoint";

export default class BendpointMoveHandle implements BendpointHandle {

    private static readonly LARGE_RESIZE_HANDLE_RADIUS = 5;
    private static readonly DRAG_RESIZE_HANDLE_RADIUS = 7;
    private static readonly BENDPOINT_MOVE_START_THRESHOLD = 2;
    private static readonly MOVE_CIRCLE_CLASS_NAME = "__connection_bendpoint_handle_circle__"
    private static readonly MOVE_CIRCLE_INITIAL_RADIUS = "__connection_bendpoint_handle_circle_initial_radius__";

    private eventManager: EventManager;
    private scaleFactor: number;
    private renderContext?: RenderContext;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.scaleFactor = 1;
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    render(connection: IDiagramConnectionDto,
           bendpoint: IDiagramPoint,
           bendpointIndex: number,
           bendpointGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>)
    {
        this.renderBendpoint(bendpoint, bendpointGroup);
        this.renderBendpointMoveHandle(connection, bendpoint, bendpointIndex, bendpointGroup);
        if (this.renderContext?.isEdit()) {
            this.renderBendpointRemoveHandle(connection, bendpoint, bendpointIndex, bendpointGroup);
        }
    }

    private renderBendpoint(bendpoint: IDiagramPoint,
                            bendpointGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>) {
        bendpointGroup.append("circle")
            .classed(BendpointMoveHandle.MOVE_CIRCLE_CLASS_NAME, true)
            .property(BendpointMoveHandle.MOVE_CIRCLE_INITIAL_RADIUS, BendpointMoveHandle.getDecorativeCircleInitialRadius())
            .attr("cx", bendpoint.x)
            .attr("cy", bendpoint.y)
            .attr("r", this.getDecorativeCircleRadius())
            .attr("stroke", OVERLAY_STROKE_COLOR)
            .attr("fill", HANDLE_FILL_COLOR)
            .attr("pointer-events", "none");
    }

    private static createBendpointRemoveHandleGroupId(connection: IDiagramConnectionDto, bendpointIndex: number, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-bendpoint-remove-handle-" + connection.identifier + "-" + bendpointIndex + "__";
    }

    private renderBendpointMoveHandle(connection: IDiagramConnectionDto,
                                      bendpoint: IDiagramPoint,
                                      bendpointIndex: number,
                                      bendpointGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>) {
        const bendpointMoveHandle = bendpointGroup.append("circle")
            .classed(BendpointMoveHandle.MOVE_CIRCLE_CLASS_NAME, true)
            .property(BendpointMoveHandle.MOVE_CIRCLE_INITIAL_RADIUS, BendpointMoveHandle.getMoveCircleInitialRadius())
            .attr("cx", bendpoint.x)
            .attr("cy", bendpoint.y)
            .attr("r", this.getMoveCircleRadius())
            .attr("stroke", "none")
            .attr("fill", "none")
            .classed(DISABLE_ON_NODE_MOVE_POINTER_EVENTS_ALL_CLASS_NAME, true)
            .attr("pointer-events", "all")
            .style("cursor", "move")
            .on("mouseenter", (event) => this.eventManager.publishEvent<BendpointEvent>({
                type: EventType.CONNECTION_BENDPOINT_MOUSE_ENTER,
                connection: connection,
                bendpointIndex: bendpointIndex,
                event: event}))
            .on("mouseleave", (event) => this.eventManager.publishEvent<BendpointEvent>({
                type: EventType.CONNECTION_BENDPOINT_MOUSE_LEAVE,
                connection: connection,
                bendpointIndex: bendpointIndex,
                event: event}));

        if (this.renderContext?.isEdit()) {
            this.addBendpointMoveDragHandler(bendpointMoveHandle, connection, bendpointIndex, this.eventManager);
        }
    }

    private addBendpointMoveDragHandler(selection: d3.Selection<any, unknown, any, undefined>,
                                           connection: IDiagramConnectionDto,
                                           bendpointIndex: number,
                                           eventManager: EventManager) {
        const dragHandler: ICustomDragHandler = {
            useSnappingFunction: true,
            publishStartEvent: (event: DragEvent) => {
                eventManager.publishEvent(BendpointMoveHandle.createBendpointMoveEvent(
                    EventType.CONNECTION_BENDPOINT_MOVE_STARTED, event, connection, bendpointIndex));
            },
            publishInProgressEvent: (event: DragEvent) => {
                eventManager.publishEvent(BendpointMoveHandle.createBendpointMoveEvent(
                    EventType.CONNECTION_BENDPOINT_MOVE_IN_PROGRESS, event, connection, bendpointIndex));
            },
            publishEndEvent: (event: DragEvent) => {
                eventManager.publishEvent(BendpointMoveHandle.createBendpointMoveEvent(
                    EventType.CONNECTION_BENDPOINT_MOVE_FINISHED, event, connection, bendpointIndex));
            },
            publishCancelEvent: (event: DragEvent) => {
                eventManager.publishEvent(BendpointMoveHandle.createBendpointMoveEvent(
                    EventType.CONNECTION_BENDPOINT_MOVE_CANCELLED, event, connection, bendpointIndex));
            },
            dragStartThreshold: BendpointMoveHandle.BENDPOINT_MOVE_START_THRESHOLD,
            setTransformedEventCoordinates: (event: any, transformedX: number, transformedY: number) => {
                event[EventProperty.TRANSFORMED_X_COORDINATE] = transformedX;
                event[EventProperty.TRANSFORMED_Y_COORDINATE] = transformedY;
            }
        };
        selection.node()[CUSTOM_DRAG_HANDLER] = dragHandler;
    }

    private static createBendpointMoveEvent(eventType: EventType, event: DragEvent, connection: IDiagramConnectionDto, bendpointIndex: number) {
        return {
            type: eventType,
            event: event,
            connection: connection,
            bendpointIndex: bendpointIndex,
        }
    }


    private renderBendpointRemoveHandle(connection: IDiagramConnectionDto,
                                        bendpoint: IDiagramPoint,
                                        bendpointIndex: number,
                                        bendpointGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>) {
        const defaultOpacity = HANDLE_COLOR_TRANSPARENT_OPACITY;
        const id = BendpointMoveHandle.createBendpointRemoveHandleGroupId(connection, bendpointIndex);

        const iconGroup = bendpointGroup.append("g")
            .attr("opacity", defaultOpacity)
            .attr("id", id);

        const removeHandleFill = HANDLE_REMOVE_FILL_COLOR_SECONDARY;

        iconGroup.append("title")
            .text("Odstranit bod ohybu");

        const bendpointRemoveHandle = iconGroup.append("circle")
            .classed(DISABLE_ON_NODE_MOVE_POINTER_EVENTS_AUTO_CLASS_NAME, true)
            .attr("cx", bendpoint.x + 10)
            .attr("cy", bendpoint.y - 10)
            .attr("r", this.getMoveCircleRadius())
            .attr("stroke", d3.color(removeHandleFill)?.darker(0.2).formatRgb() as string)
            .attr("fill", removeHandleFill);
        iconGroup.append("text")
            .attr("x", bendpoint.x + 8)
            .attr("y", bendpoint.y - 8)
            .attr("stroke", "white")
            .attr("fill", "white")
            .attr("font-size", 6)
            .attr("pointer-events", "none")
            .text("X");
        bendpointRemoveHandle
            .on("mouseenter", (event) => {
                this.eventManager.publishEvent<BendpointEvent>({
                    type: EventType.CONNECTION_BENDPOINT_REMOVE_MOUSE_ENTER,
                    connection: connection,
                    bendpointIndex: bendpointIndex,
                    event: event
                });
                iconGroup.transition()
                    .duration(100)
                    .attr("opacity", 1);
            })
            .on("mouseleave", (event) => {
                this.eventManager.publishEvent<BendpointEvent>({
                    type: EventType.CONNECTION_BENDPOINT_REMOVE_MOUSE_LEAVE,
                    connection: connection,
                    bendpointIndex: bendpointIndex,
                    event: event
                });
                iconGroup.transition()
                    .duration(100)
                    .attr("opacity", defaultOpacity);
            });

        this.addBendpointRemoveHandleClickHandler(bendpointRemoveHandle, connection, bendpointIndex);
    }

    private addBendpointRemoveHandleClickHandler(bendpointRemoveHandle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>,
                                                 connection: IDiagramConnectionDto,
                                                 bendpointIndex: number) {
        const handler: ICustomClickOnlyHandler = {
            useSnappingFunction: false,
            clickThreshold: 2,
            publishClickEvent: (event: DragEvent) => {
                this.eventManager.publishEvent({
                    type: EventType.CONNECTION_BENDPOINT_REMOVE_REQUESTED,
                    event: event,
                    connection: connection,
                    bendpointIndex: bendpointIndex,
                })
            }
        };
        (bendpointRemoveHandle.node() as any)[CUSTOM_DRAG_HANDLER] = handler;
    }

    updateScaleFactor(scaleFactor: number) {
        this.scaleFactor = scaleFactor;
        this.applyScaleFactor();
    }

    private applyScaleFactor() {
        d3.selectAll(`circle.${BendpointMoveHandle.MOVE_CIRCLE_CLASS_NAME}`)
            .attr("r", (d, i, circles ) => {
                let initialRadius: number = d3.select(circles[i]).property(BendpointMoveHandle.MOVE_CIRCLE_INITIAL_RADIUS);
                initialRadius = initialRadius != null ? initialRadius : BendpointMoveHandle.DRAG_RESIZE_HANDLE_RADIUS;
                return initialRadius * this.scaleFactor;
            })
            .attr("stroke-width", this.scaleFactor);
    }

    private static getDecorativeCircleInitialRadius() {
        return BendpointMoveHandle.LARGE_RESIZE_HANDLE_RADIUS;
    }

    private getDecorativeCircleRadius() {
        return BendpointMoveHandle.getDecorativeCircleInitialRadius() * this.scaleFactor;
    }

    private static getMoveCircleInitialRadius() {
        return BendpointMoveHandle.DRAG_RESIZE_HANDLE_RADIUS;
    }

    private getMoveCircleRadius() {
        return BendpointMoveHandle.getMoveCircleInitialRadius() * this.scaleFactor;
    }
}
