import React, {useRef, useState} from "react";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {CollectionsTranslationKey} from "../CollectionsTranslationKey";
import {CommonTranslation} from "../../CommonTranslation";
import {SaveButton} from "../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../components/button/CancelButton";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import TextField from "../../../../../components/fields/textfield/TextField";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import {CollectionCreateDto} from "../../../../../common/apis/collection/CollectionCreateDto";
import Dialog from "../../../../../components/dialogs/Dialog";
import Snackbar from "../../snackbar/Snackbar";
import constructCollectionsController, {CollectionsController} from "../controller/CollectionsController";
import collectionsService from "../service/CollectionsService";
import Grid from "../../../../../components/dialogs/Grid";
import {CollectionErrorCode} from "../service/CollectionErrorCode";
import {CollectionConstant} from "../service/CollectionConstant";

interface CreateCollectionDialogProps {
    onClosed: () => void,
    onCollectionCreated: () => void,
}

export function CreateCollectionDialog(props: CreateCollectionDialogProps) {
    const {onClosed, onCollectionCreated} = props;

    const collectionController = useRef<CollectionsController>(constructCollectionsController(collectionsService));

    const [name, setName] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const [nameError, setNameError] = useState<string>();
    const [codeError, setCodeError] = useState<string>();
    const [isNameToCodeCopyingModeOn, setIsNameToCodeCopyingModeOn] = useState<boolean>(true);

    function validate(): boolean {
        const isNameValid = validateName();
        const isCodeValid = validateCode();
        return isNameValid && isCodeValid;
    }

    function validateName(): boolean {
        let isValid = false;
        if (name.trim().length === 0) {
            setNameError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        } else {
            setNameError(undefined);
            isValid = true;
        }
        return isValid;
    }

    function validateCode(): boolean {
        let isValid = false;
        if (code.trim().length === 0) {
            setCodeError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        } else {
            setCodeError(undefined);
            isValid = true;
        }

        return isValid;
    }

    function convertToCode(name: string) {
        return name.toLowerCase().replace(/ /g, "_");
    }

    function createCollectionDto(name: string, code: string, description: string): CollectionCreateDto {
        return {
            name: name,
            code: code,
            description: description
        }
    }

    function onDialogSave() {
        if (validate()) {
            (async () => {
                const collectionDto = createCollectionDto(name, code, description)
                try {
                    await collectionController.current.create(collectionDto);
                    onCollectionCreated();
                    Snackbar.success(_transl(CollectionsTranslationKey.CREATE_SUCCEEDED));
                } catch (error: any) {
                    if (error.response.code === CollectionErrorCode.CODE_USED_BY_ANOTHER_COLLECTION) {
                        Snackbar.error(_transl(CollectionsTranslationKey.CODE_USED_BY_ANOTHER_COLLECTION));
                    } else {
                        Snackbar.error(_transl(CollectionsTranslationKey.CREATE_FAILED));
                    }
                }
            })();
        }
    }

    return (
        <Dialog open={true} onClose={onClosed}>
            <DialogTitle title={_transl(CollectionsTranslationKey.CREATE_TITLE)}
                         onDialogClosed={onClosed}
            />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="collection-create-name-field"
                                   label={_transl(CommonTranslation.TITLE)}
                                   value={name}
                                   errorMessage={nameError}
                                   onChange={(newName) => {
                                       if (isNameToCodeCopyingModeOn) {
                                           setCode(convertToCode(newName))
                                       }
                                       setName(newName)
                                   }}
                                   required={true}
                                   maxLength={CollectionConstant.NAME_MAX_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="collection-create-code-field"
                                   label={_transl(CommonTranslation.CODE)}
                                   errorMessage={codeError}
                                   value={code}
                                   onChange={(newCode) => setCode(convertToCode(newCode))}
                                   required={true}
                                   maxLength={CollectionConstant.CODE_MAX_LENGTH}
                                   onFocus={() => {
                                       setIsNameToCodeCopyingModeOn(false)
                                   }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="collection-create-description-field"
                                   label={_transl(CommonTranslation.DESCRIPTION)}
                                   value={description}
                                   onChange={(newDesc) => setDescription(newDesc)}
                                   maxLength={CollectionConstant.DESCRIPTION_MAX_LENGTH}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={onDialogSave}/>
                <CancelButton onClick={onClosed}/>
            </DialogActions>
        </Dialog>
    );
}
