import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React, {ReactElement} from "react";
import Grid from "@mui/material/Grid";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        headerButton: {
            marginLeft: "1.5em",
        },
        closeButton: CommonCssStyles.getHeaderCloseButtonStyle(theme),
    })
);

interface IProps {
    icon?: JSX.Element,
    name: string,
    children: ReactElement | ReactElement[],
    onClosed?: () => void,
}

export default function DetailHeader({name, icon, children, onClosed}: IProps) {

    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12}>
                <div className={classes.headerPageSegment}>
                    <Typography variant="h6"
                                style={{display: "flex", alignItems: "center", gap: "12px", marginLeft: "0.2em"}}>
                        {icon}
                        {name}
                        <div className={classes.headerButton}>
                            {onClosed && (
                                <IconButton
                                    aria-label="close"
                                    className={classes.closeButton}
                                    onClick={onClosed}
                                    size="large"
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                            {children}
                        </div>
                    </Typography>
                </div>
                <Divider/>
            </Grid>
        </Grid>
    )
}
