import {DialogActions as MuiDialogActions, DialogActionsProps as MuiDialogActionsProps} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialogActions: {
        padding: theme.spacing(0, 3, 3, 3 )
    },
}));

export interface DialogActionsProps extends MuiDialogActionsProps {
}

export default function DialogActions({className, ...props}: DialogActionsProps) {
    const classes = useStyles();

    return (
        <MuiDialogActions className={clsx(classes.dialogActions, className)} {...props}>
            {props.children}
        </MuiDialogActions>
    );
}
