export enum ArchimateRelationshipType {
    ASSOCIATION = "ASSOCIATION",
    ACCESS = "ACCESS",
    SERVING = "SERVING",
    REALIZATION = "REALIZATION",
    ASSIGNMENT = "ASSIGNMENT",
    AGGREGATION = "AGGREGATION",
    COMPOSITION = "COMPOSITION",
    SPECIALIZATION = "SPECIALIZATION",
    FLOW = "FLOW",
    TRIGGERING = "TRIGGERING",
    INFLUENCE = "INFLUENCE",
}

export class ArchimateRelationship {
    public static readonly [ArchimateRelationshipType.ASSOCIATION] = new ArchimateRelationship(ArchimateRelationshipType.ASSOCIATION, 11, ["ArchiMateAssociation"], "Association");
    public static readonly [ArchimateRelationshipType.ACCESS] = new ArchimateRelationship(ArchimateRelationshipType.ACCESS, 6, ["ArchiMateAccess"], "Access");
    public static readonly [ArchimateRelationshipType.SERVING] = new ArchimateRelationship(ArchimateRelationshipType.SERVING, 5, ["ArchiMateServing", "ArchiMateUsedBy"], "Serving");
    public static readonly [ArchimateRelationshipType.REALIZATION] = new ArchimateRelationship(ArchimateRelationshipType.REALIZATION, 4, ["ArchiMateRealization"], "Realization");
    public static readonly [ArchimateRelationshipType.ASSIGNMENT] = new ArchimateRelationship(ArchimateRelationshipType.ASSIGNMENT, 3, ["ArchiMateAssignment"], "Assignment");
    public static readonly [ArchimateRelationshipType.AGGREGATION] = new ArchimateRelationship(ArchimateRelationshipType.AGGREGATION, 2, ["ArchiMateAggregation"], "Aggregation");
    public static readonly [ArchimateRelationshipType.COMPOSITION] = new ArchimateRelationship(ArchimateRelationshipType.COMPOSITION, 1, ["ArchiMateComposition"], "Composition");
    public static readonly [ArchimateRelationshipType.SPECIALIZATION] = new ArchimateRelationship(ArchimateRelationshipType.SPECIALIZATION, 10, ["ArchiMateSpecialization"], "Specialization");
    public static readonly [ArchimateRelationshipType.FLOW] = new ArchimateRelationship(ArchimateRelationshipType.FLOW, 9, ["ArchiMateFlow"], "Flow");
    public static readonly [ArchimateRelationshipType.TRIGGERING] = new ArchimateRelationship(ArchimateRelationshipType.TRIGGERING, 8, ["ArchiMateTriggering"], "Triggering");
    public static readonly [ArchimateRelationshipType.INFLUENCE] = new ArchimateRelationship(ArchimateRelationshipType.INFLUENCE, 7, ["ArchiMateInfluence"], "Influence");

    public static values(): Array<ArchimateRelationship> {
        return Object.keys(ArchimateRelationshipType)
            .map(key => ArchimateRelationship[key as ArchimateRelationshipType])
            .sort((a, b) => a.relationshipType.toString().localeCompare(b.relationshipType.toString()));
    }

    public static valuesSortedByVisibleName(): Array<ArchimateRelationship> {
        return Object.keys(ArchimateRelationshipType)
            .map(key => ArchimateRelationship[key as ArchimateRelationshipType])
            .sort((a, b) => a.visibleName.toString().localeCompare(b.visibleName.toString()));
    }

    public static valueOf(relationshipTypeName: string) {
        const existingValues = Object.keys(ArchimateRelationshipType)
            .filter((name) => name === relationshipTypeName);
        return existingValues.length === 1 ? ArchimateRelationship[existingValues[0] as ArchimateRelationshipType] : null;
    }

    public static findByStandardName(standardName: string) {
        const vals = Object.keys(ArchimateRelationshipType)
            .map(key => ArchimateRelationship[key as ArchimateRelationshipType])
            .filter(relationship => relationship.standardNames.indexOf(standardName) !== -1);
        return vals.length > 0 ? vals[0] : null;
    }

    public static getVisibleName(relationshipType: ArchimateRelationshipType) {
        const element = ArchimateRelationship[relationshipType];
        return element ? element.visibleName : relationshipType;
    }

    constructor(public readonly relationshipType: ArchimateRelationshipType,
                public readonly id: number,
                public readonly standardNames: Array<string>,
                public readonly visibleName: string) {
    }
}

export enum ArchiMateAccessType {
    WRITE = "WRITE",
    READ = "READ",
    READ_WRITE = "READ_WRITE",
    ACCESS = "ACCESS",
}

export class ArchiMateAccess {
    public static readonly [ArchiMateAccessType.WRITE] = new ArchiMateAccess(ArchiMateAccessType.WRITE, "Zápis");
    public static readonly [ArchiMateAccessType.READ] = new ArchiMateAccess(ArchiMateAccessType.READ, "Čtení");
    public static readonly [ArchiMateAccessType.READ_WRITE] = new ArchiMateAccess(ArchiMateAccessType.READ_WRITE, "Čtení a zápis");
    public static readonly [ArchiMateAccessType.ACCESS] = new ArchiMateAccess(ArchiMateAccessType.ACCESS, "Přístup");

    public static values(): Array<ArchiMateAccess> {
        return Object.keys(ArchiMateAccessType)
            .map(key => ArchiMateAccess[key as ArchiMateAccessType])
            .sort((a, b) => a.accessType.toString().localeCompare(b.accessType.toString()));
    }

    public static valueOf(accessTypeName: string) {
        const existingValues = Object.keys(ArchiMateAccessType)
            .filter((name) => name === accessTypeName);
        return existingValues.length === 1 ? ArchiMateAccess[existingValues[0] as ArchiMateAccessType] : null;
    }

    public static getVisibleName(accessType: ArchiMateAccessType) {
        const access = ArchiMateAccess[accessType];
        return access ? access.visibleName : accessType;
    }

    constructor(public readonly accessType: ArchiMateAccessType,
                public readonly visibleName: string) {
    }
}