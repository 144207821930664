import React from "react";
import {Menu} from "@mui/material";
import {
    collectionsMenu,
    dashboardAdministrationMenu,
    labelsMenu,
    stereotypesMenu,
    usersMenu
} from "../../../common/menudefinition/MenuDefinitionUtils";
import Divider from "@mui/material/Divider";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../store/Store";
import collectionsPageAccessResolver from "../content/collections/CollectionsPageAccessResolver";
import stereotypesPageAccessResolver from "../content/stereotypes/StereotypesPageAccessResolver";
import AppBarMenuItem from "./AppBarMenuItem";
import {UserRoleType} from "../../../common/access/UserRoleType";

export interface AdministrationMenuProps {
    opened: boolean;
    anchorRef: any;
    onMenuClose: () => void;
}

export default function AdministrationMenu({opened, anchorRef, onMenuClose}: AdministrationMenuProps) {

    const user = useSelector((state: IApplicationState) => state.user.userData);

    const collectionPageVisible = collectionsPageAccessResolver.hasPageAccess(user);
    const stereotypePageVisible = stereotypesPageAccessResolver.hasPageAccess(user);
    const dashboardAdministrationVisible = user?.role === UserRoleType.ROLE_ADMIN || user?.role === UserRoleType.ROLE_SPRAVCE;

    return (
        <Menu open={opened}
              id="administration-menu"
              anchorEl={anchorRef}
              keepMounted
              onClose={onMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{vertical: -10, horizontal: 0}} >
            <AppBarMenuItem menuDefinition={usersMenu} onClick={onMenuClose} />
            <Divider />
            <AppBarMenuItem menuDefinition={labelsMenu} onClick={onMenuClose} />
            {collectionPageVisible &&
                <AppBarMenuItem menuDefinition={collectionsMenu} onClick={onMenuClose} />
            }
            <Divider />
            {stereotypePageVisible &&
                <AppBarMenuItem menuDefinition={stereotypesMenu} onClick={onMenuClose} />
            }
            {stereotypePageVisible &&
                <Divider />
            }
            {dashboardAdministrationVisible &&
                <AppBarMenuItem menuDefinition={dashboardAdministrationMenu} onClick={onMenuClose}/>
            }
        </Menu>
    )

}
