import {ArchimateLayerType} from "./ArchiMateLayer";

export enum ArchimateElementType {

    // Application layer types
    APPLICATION_COMPONENT = "APPLICATION_COMPONENT",
    APPLICATION_COLLABORATION = "APPLICATION_COLLABORATION",
    APPLICATION_EVENT = "APPLICATION_EVENT",
    APPLICATION_INTERACTION = "APPLICATION_INTERACTION",
    APPLICATION_INTERFACE = "APPLICATION_INTERFACE",
    APPLICATION_FUNCTION = "APPLICATION_FUNCTION",
    APPLICATION_PROCESS = "APPLICATION_PROCESS",
    APPLICATION_SERVICE = "APPLICATION_SERVICE",
    DATA_OBJECT = "DATA_OBJECT",

    // Business layer types
    BUSINESS_ACTOR = "BUSINESS_ACTOR",
    BUSINESS_COLLABORATION = "BUSINESS_COLLABORATION",
    BUSINESS_EVENT = "BUSINESS_EVENT",
    BUSINESS_FUNCTION = "BUSINESS_FUNCTION",
    BUSINESS_INTERACTION = "BUSINESS_INTERACTION",
    BUSINESS_INTERFACE = "BUSINESS_INTERFACE",
    BUSINESS_OBJECT = "BUSINESS_OBJECT",
    BUSINESS_PROCESS = "BUSINESS_PROCESS",
    BUSINESS_ROLE = "BUSINESS_ROLE",
    BUSINESS_SERVICE = "BUSINESS_SERVICE",
    CONTRACT = "CONTRACT",
    PRODUCT = "PRODUCT",
    REPRESENTATION = "REPRESENTATION",

    // Implementation and migration layer types
    DELIVERABLE = "DELIVERABLE",
    GAP = "GAP",
    IMPLEMENTATION_EVENT = "IMPLEMENTATION_EVENT",
    PLATEAU = "PLATEAU",
    WORK_PACKAGE = "WORK_PACKAGE",

    // Motivation layer types
    ASSESSMENT = "ASSESSMENT",
    CONSTRAINT = "CONSTRAINT",
    DRIVER = "DRIVER",
    GOAL = "GOAL",
    MEANING = "MEANING",
    OUTCOME = "OUTCOME",
    PRINCIPLE = "PRINCIPLE",
    REQUIREMENT = "REQUIREMENT",
    STAKEHOLDER = "STAKEHOLDER",
    VALUE = "VALUE",

    // Physical layer types
    DISTRIBUTION_NETWORK = "DISTRIBUTION_NETWORK",
    EQUIPMENT = "EQUIPMENT",
    FACILITY = "FACILITY",
    MATERIAL = "MATERIAL",

    // Strategy layer types
    RESOURCE = "RESOURCE",
    CAPABILITY = "CAPABILITY",
    COURSE_OF_ACTION = "COURSE_OF_ACTION",
    VALUE_STREAM = "VALUE_STREAM",

    // Technology layer types
    ARTIFACT = "ARTIFACT",
    COMMUNICATION_NETWORK = "COMMUNICATION_NETWORK",
    DEVICE = "DEVICE",
    NODE = "NODE",
    PATH = "PATH",
    SYSTEM_SOFTWARE = "SYSTEM_SOFTWARE",
    TECHNOLOGY_COLLABORATION = "TECHNOLOGY_COLLABORATION",
    TECHNOLOGY_EVENT = "TECHNOLOGY_EVENT",
    TECHNOLOGY_FUNCTION = "TECHNOLOGY_FUNCTION",
    TECHNOLOGY_INTERACTION = "TECHNOLOGY_INTERACTION",
    TECHNOLOGY_INTERFACE = "TECHNOLOGY_INTERFACE",
    TECHNOLOGY_PROCESS = "TECHNOLOGY_PROCESS",
    TECHNOLOGY_SERVICE = "TECHNOLOGY_SERVICE",

    // Composite types - no specific layer (possibly multiple layers)
    GROUPING = "GROUPING",
    LOCATION = "LOCATION",

    // Relationship connectors - no specific layer
    AND_JUNCTION = "AND_JUNCTION",
    OR_JUNCTION = "OR_JUNCTION",
}

const collaborationCodepoint = "0xe904";
const deliverableCodepoint = "0xe92c";
const eventCodepoint = "0xe905";
const functionCodepoint = "0xe906";
const interactionCodepoint = "0xe907";
const interfaceCodepoint = "0xe908";
const locationCodepoint = "0xe91a";
const objectCodepoint = "0xe909";
const processCodepoint = "0xe90a";
const representationCodepoint = "0xe923";
const roleCodepoint = "0xe90b";
const serviceCodepoint = "0xe90c";

export class ArchimateElement {
    // Application layer types
    public static readonly [ArchimateElementType.APPLICATION_COMPONENT] = new ArchimateElement(ArchimateElementType.APPLICATION_COMPONENT, ArchimateLayerType.APPLICATION, "ArchiMateApplicationComponent", "0xe900", "Application Component");
    public static readonly [ArchimateElementType.APPLICATION_COLLABORATION] = new ArchimateElement(ArchimateElementType.APPLICATION_COLLABORATION, ArchimateLayerType.APPLICATION, "ArchiMateApplicationCollaboration", collaborationCodepoint, "Application Collaboration");
    public static readonly [ArchimateElementType.APPLICATION_EVENT] = new ArchimateElement(ArchimateElementType.APPLICATION_EVENT, ArchimateLayerType.APPLICATION, "ArchiMateApplicationEvent", eventCodepoint, "Application Event");
    public static readonly [ArchimateElementType.APPLICATION_INTERACTION] = new ArchimateElement(ArchimateElementType.APPLICATION_INTERACTION, ArchimateLayerType.APPLICATION, "ArchiMateApplicationInteraction", interactionCodepoint, "Application Interaction");
    public static readonly [ArchimateElementType.APPLICATION_INTERFACE] = new ArchimateElement(ArchimateElementType.APPLICATION_INTERFACE, ArchimateLayerType.APPLICATION, "ArchiMateApplicationInterface", interfaceCodepoint, "Application Interface");
    public static readonly [ArchimateElementType.APPLICATION_FUNCTION] = new ArchimateElement(ArchimateElementType.APPLICATION_FUNCTION, ArchimateLayerType.APPLICATION, "ArchiMateApplicationFunction", functionCodepoint, "Application Function");
    public static readonly [ArchimateElementType.APPLICATION_PROCESS] = new ArchimateElement(ArchimateElementType.APPLICATION_PROCESS, ArchimateLayerType.APPLICATION, "ArchiMateApplicationProcess", processCodepoint, "Application Process");
    public static readonly [ArchimateElementType.APPLICATION_SERVICE] = new ArchimateElement(ArchimateElementType.APPLICATION_SERVICE, ArchimateLayerType.APPLICATION, "ArchiMateApplicationService", serviceCodepoint, "Application Service");
    public static readonly [ArchimateElementType.DATA_OBJECT] = new ArchimateElement(ArchimateElementType.DATA_OBJECT, ArchimateLayerType.APPLICATION, "ArchiMateDataObject", objectCodepoint, "Data Object");

    // Business layer types
    public static readonly [ArchimateElementType.BUSINESS_ACTOR] = new ArchimateElement(ArchimateElementType.BUSINESS_ACTOR, ArchimateLayerType.BUSINESS, "ArchiMateBusinessActor", "0xe903", "Business Actor");
    public static readonly [ArchimateElementType.BUSINESS_COLLABORATION] = new ArchimateElement(ArchimateElementType.BUSINESS_COLLABORATION, ArchimateLayerType.BUSINESS, "ArchiMateBusinessCollaboration", collaborationCodepoint, "Business Collaboration");
    public static readonly [ArchimateElementType.BUSINESS_EVENT] = new ArchimateElement(ArchimateElementType.BUSINESS_EVENT, ArchimateLayerType.BUSINESS, "ArchiMateBusinessEvent", eventCodepoint, "Business Event");
    public static readonly [ArchimateElementType.BUSINESS_FUNCTION] = new ArchimateElement(ArchimateElementType.BUSINESS_FUNCTION, ArchimateLayerType.BUSINESS, "ArchiMateBusinessFunction", functionCodepoint, "Business Function");
    public static readonly [ArchimateElementType.BUSINESS_INTERACTION] = new ArchimateElement(ArchimateElementType.BUSINESS_INTERACTION, ArchimateLayerType.BUSINESS, "ArchiMateBusinessInteraction", interactionCodepoint, "Business Interaction");
    public static readonly [ArchimateElementType.BUSINESS_INTERFACE] = new ArchimateElement(ArchimateElementType.BUSINESS_INTERFACE, ArchimateLayerType.BUSINESS, "ArchiMateBusinessInterface", interfaceCodepoint, "Business Interface");
    public static readonly [ArchimateElementType.BUSINESS_OBJECT] = new ArchimateElement(ArchimateElementType.BUSINESS_OBJECT, ArchimateLayerType.BUSINESS, "ArchiMateBusinessObject", objectCodepoint, "Business Object");
    public static readonly [ArchimateElementType.BUSINESS_PROCESS] = new ArchimateElement(ArchimateElementType.BUSINESS_PROCESS, ArchimateLayerType.BUSINESS, "ArchiMateBusinessProcess", processCodepoint, "Business Process");
    public static readonly [ArchimateElementType.BUSINESS_ROLE] = new ArchimateElement(ArchimateElementType.BUSINESS_ROLE, ArchimateLayerType.BUSINESS, "ArchiMateBusinessRole", roleCodepoint, "Business Role");
    public static readonly [ArchimateElementType.BUSINESS_SERVICE] = new ArchimateElement(ArchimateElementType.BUSINESS_SERVICE, ArchimateLayerType.BUSINESS, "ArchiMateBusinessService", serviceCodepoint, "Business Service");
    public static readonly [ArchimateElementType.CONTRACT] = new ArchimateElement(ArchimateElementType.CONTRACT, ArchimateLayerType.BUSINESS, "ArchiMateContract", "0xe910", "Contract");
    public static readonly [ArchimateElementType.PRODUCT] = new ArchimateElement(ArchimateElementType.PRODUCT, ArchimateLayerType.BUSINESS, "ArchiMateProduct", "0xe922", "Product");
    public static readonly [ArchimateElementType.REPRESENTATION] = new ArchimateElement(ArchimateElementType.REPRESENTATION, ArchimateLayerType.BUSINESS, "ArchiMateRepresentation", representationCodepoint, "Representation");

    // Implementation and migration layer types
    public static readonly [ArchimateElementType.DELIVERABLE] = new ArchimateElement(ArchimateElementType.DELIVERABLE, ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION, "ArchiMateDeliverable", deliverableCodepoint, "Deliverable");
    public static readonly [ArchimateElementType.GAP] = new ArchimateElement(ArchimateElementType.GAP, ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION, "ArchiMateGap", "0xe917", "Gap");
    public static readonly [ArchimateElementType.IMPLEMENTATION_EVENT] = new ArchimateElement(ArchimateElementType.IMPLEMENTATION_EVENT, ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION, "ArchiMateImplementationEvent", eventCodepoint, "Implementation Event");
    public static readonly [ArchimateElementType.PLATEAU] = new ArchimateElement(ArchimateElementType.PLATEAU, ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION, "ArchiMatePlateau", "0xe920", "Plateau");
    public static readonly [ArchimateElementType.WORK_PACKAGE] = new ArchimateElement(ArchimateElementType.WORK_PACKAGE, ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION, "ArchiMateWorkPackage", "0xe928", "Work Package");

    // Motivation layer types
    public static readonly [ArchimateElementType.ASSESSMENT] = new ArchimateElement(ArchimateElementType.ASSESSMENT, ArchimateLayerType.MOTIVATION, "ArchiMateAssessment", "0xe902", "Assessment");
    public static readonly [ArchimateElementType.CONSTRAINT] = new ArchimateElement(ArchimateElementType.CONSTRAINT, ArchimateLayerType.MOTIVATION, "ArchiMateConstraint", "0xe90f", "Constraint");
    public static readonly [ArchimateElementType.DRIVER] = new ArchimateElement(ArchimateElementType.DRIVER, ArchimateLayerType.MOTIVATION, "ArchiMateDriver", "0xe914", "Driver");
    public static readonly [ArchimateElementType.GOAL] = new ArchimateElement(ArchimateElementType.GOAL, ArchimateLayerType.MOTIVATION, "ArchiMateGoal", "0xe918", "Goal");
    public static readonly [ArchimateElementType.MEANING] = new ArchimateElement(ArchimateElementType.MEANING, ArchimateLayerType.MOTIVATION, "ArchiMateMeaning", "0xe91c", "Meaning");
    public static readonly [ArchimateElementType.OUTCOME] = new ArchimateElement(ArchimateElementType.OUTCOME, ArchimateLayerType.MOTIVATION, "ArchiMateOutcome", "0xe91f", "Outcome");
    public static readonly [ArchimateElementType.PRINCIPLE] = new ArchimateElement(ArchimateElementType.PRINCIPLE, ArchimateLayerType.MOTIVATION, "ArchiMatePrinciple", "0xe921", "Principle");
    public static readonly [ArchimateElementType.REQUIREMENT] = new ArchimateElement(ArchimateElementType.REQUIREMENT, ArchimateLayerType.MOTIVATION, "ArchiMateRequirement", "0xe924", "Requirement");
    public static readonly [ArchimateElementType.STAKEHOLDER] = new ArchimateElement(ArchimateElementType.STAKEHOLDER, ArchimateLayerType.MOTIVATION, "ArchiMateStakeholder", roleCodepoint, "Stakeholder");
    public static readonly [ArchimateElementType.VALUE] = new ArchimateElement(ArchimateElementType.VALUE, ArchimateLayerType.MOTIVATION, "ArchiMateValue", "0xe927", "Value");

    // Physical layer types
    public static readonly [ArchimateElementType.DISTRIBUTION_NETWORK] = new ArchimateElement(ArchimateElementType.DISTRIBUTION_NETWORK, ArchimateLayerType.PHYSICAL, "ArchiMateDistributionNetwork", "0xe913", "Distribution Network");
    public static readonly [ArchimateElementType.EQUIPMENT] = new ArchimateElement(ArchimateElementType.EQUIPMENT, ArchimateLayerType.PHYSICAL, "ArchiMateEquipment", "0xe915", "Equipment");
    public static readonly [ArchimateElementType.FACILITY] = new ArchimateElement(ArchimateElementType.FACILITY, ArchimateLayerType.PHYSICAL, "ArchiMateFacility", "0xe916", "Facility");
    public static readonly [ArchimateElementType.MATERIAL] = new ArchimateElement(ArchimateElementType.MATERIAL, ArchimateLayerType.PHYSICAL, "ArchiMateMaterial", "0xe91b", "Material");

    // Strategy layer types
    public static readonly [ArchimateElementType.RESOURCE] = new ArchimateElement(ArchimateElementType.RESOURCE, ArchimateLayerType.STRATEGY, "ArchiMateResource", "0xe925", "Resource");
    public static readonly [ArchimateElementType.CAPABILITY] = new ArchimateElement(ArchimateElementType.CAPABILITY, ArchimateLayerType.STRATEGY, "ArchiMateCapability", "0xe90d", "Capability");
    public static readonly [ArchimateElementType.COURSE_OF_ACTION] = new ArchimateElement(ArchimateElementType.COURSE_OF_ACTION, ArchimateLayerType.STRATEGY, "ArchiMateCourseOfAction", "0xe911", "Course Of Action");
    public static readonly [ArchimateElementType.VALUE_STREAM] = new ArchimateElement(ArchimateElementType.VALUE_STREAM, ArchimateLayerType.STRATEGY, "ArchiMateValueStream", "0xe92b", "Value Stream");

    // Technology layer types
    public static readonly [ArchimateElementType.ARTIFACT] = new ArchimateElement(ArchimateElementType.ARTIFACT, ArchimateLayerType.TECHNOLOGY, "ArchiMateArtifact", "0xe901", "Artifact");
    public static readonly [ArchimateElementType.COMMUNICATION_NETWORK] = new ArchimateElement(ArchimateElementType.COMMUNICATION_NETWORK, ArchimateLayerType.TECHNOLOGY, "ArchiMateCommunicationNetwork", "0xe91d", "Communication Network");
    public static readonly [ArchimateElementType.DEVICE] = new ArchimateElement(ArchimateElementType.DEVICE, ArchimateLayerType.TECHNOLOGY, "ArchiMateDevice", "0xe912", "Device");
    public static readonly [ArchimateElementType.NODE] = new ArchimateElement(ArchimateElementType.NODE, ArchimateLayerType.TECHNOLOGY, "ArchiMateNode", "0xe91e", "Node");
    public static readonly [ArchimateElementType.PATH] = new ArchimateElement(ArchimateElementType.PATH, ArchimateLayerType.TECHNOLOGY, "ArchiMatePath", "0xe90e", "Path");
    public static readonly [ArchimateElementType.SYSTEM_SOFTWARE] = new ArchimateElement(ArchimateElementType.SYSTEM_SOFTWARE, ArchimateLayerType.TECHNOLOGY, "ArchiMateSystemSoftware", "0xe926", "System Software");
    public static readonly [ArchimateElementType.TECHNOLOGY_COLLABORATION] = new ArchimateElement(ArchimateElementType.TECHNOLOGY_COLLABORATION, ArchimateLayerType.TECHNOLOGY, "ArchiMateTechnologyCollaboration", collaborationCodepoint, "Technology Collaboration");
    public static readonly [ArchimateElementType.TECHNOLOGY_EVENT] = new ArchimateElement(ArchimateElementType.TECHNOLOGY_EVENT, ArchimateLayerType.TECHNOLOGY, "ArchiMateTechnologyEvent", eventCodepoint, "Technology Event");
    public static readonly [ArchimateElementType.TECHNOLOGY_FUNCTION] = new ArchimateElement(ArchimateElementType.TECHNOLOGY_FUNCTION, ArchimateLayerType.TECHNOLOGY, "ArchiMateTechnologyFunction", functionCodepoint, "Technology Function");
    public static readonly [ArchimateElementType.TECHNOLOGY_INTERACTION] = new ArchimateElement(ArchimateElementType.TECHNOLOGY_INTERACTION, ArchimateLayerType.TECHNOLOGY, "ArchiMateTechnologyInteraction", interactionCodepoint, "Technology Interaction");
    public static readonly [ArchimateElementType.TECHNOLOGY_INTERFACE] = new ArchimateElement(ArchimateElementType.TECHNOLOGY_INTERFACE, ArchimateLayerType.TECHNOLOGY, "ArchiMateTechnologyInterface", interfaceCodepoint, "Technology Interface");
    public static readonly [ArchimateElementType.TECHNOLOGY_PROCESS] = new ArchimateElement(ArchimateElementType.TECHNOLOGY_PROCESS, ArchimateLayerType.TECHNOLOGY, "ArchiMateTechnologyProcess", processCodepoint, "Technology Process");
    public static readonly [ArchimateElementType.TECHNOLOGY_SERVICE] = new ArchimateElement(ArchimateElementType.TECHNOLOGY_SERVICE, ArchimateLayerType.TECHNOLOGY, "ArchiMateTechnologyService", serviceCodepoint, "Technology Service");

    // Composite types - no specific layer (possibly multiple layers)
    public static readonly [ArchimateElementType.GROUPING] = new ArchimateElement(ArchimateElementType.GROUPING, ArchimateLayerType.COMPOSITE_ELEMENTS, "ArchiMateGrouping", "0xe919", "Grouping");
    public static readonly [ArchimateElementType.LOCATION] = new ArchimateElement(ArchimateElementType.LOCATION, ArchimateLayerType.COMPOSITE_ELEMENTS, "ArchiMateLocation", locationCodepoint, "Location");

    // Relationship connectors - no specific layer
    public static readonly [ArchimateElementType.AND_JUNCTION] = new ArchimateElement(ArchimateElementType.AND_JUNCTION, ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS, "ArchiMateAndJunction", "0xe929", "And Junction");
    public static readonly [ArchimateElementType.OR_JUNCTION] = new ArchimateElement(ArchimateElementType.OR_JUNCTION, ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS, "ArchiMateOrJunction", "0xe92a", "Or Junction");


    public static values(): Array<ArchimateElement> {
        return Object.keys(ArchimateElementType)
            .map(key => ArchimateElement[key as ArchimateElementType])
            .sort((a, b) => a.elementType.toString().localeCompare(b.elementType.toString()));
    }

    public static valueOf(formatTypeName: string) {
        const existingValues = Object.keys(ArchimateElementType)
            .filter((name) => name === formatTypeName);
        return existingValues.length === 1 ? ArchimateElement[existingValues[0] as ArchimateElementType] : null;
    }

    public static findByStandardName(standardName: string) {
        const vals = Object.keys(ArchimateElementType)
            .map(key => ArchimateElement[key as ArchimateElementType])
            .filter(element => {
                return element.standardName === standardName;
            });
        return vals.length > 0 ? vals[0] : null;
    }

    public static getCodepoint(elementType: ArchimateElementType) {
        const element = ArchimateElement[elementType];
        return element ? element.iconCodepoint : locationCodepoint;
    }

    public static getVisibleName(elementType: ArchimateElementType) {
        const element = ArchimateElement[elementType];
        return element ? element.visibleName : elementType;
    }

    public static isJunction(elementType: ArchimateElementType): boolean {
        const element = ArchimateElement[elementType];
        return element ? element.isJunction() : false;
    }

    constructor(public readonly elementType: ArchimateElementType,
                public readonly layerType: ArchimateLayerType,
                public readonly standardName: string,
                public readonly iconCodepoint: string,
                public readonly visibleName: string) {
    }

    public isJunction(): boolean {
        return this.elementType === ArchimateElementType.OR_JUNCTION || this.elementType === ArchimateElementType.AND_JUNCTION;
    }
}
