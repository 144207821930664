import React from "react";
import {Paper} from "@mui/material";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import CommonCssStyles from "../../../../css/CommonCssStyles";
import UsersGrid from "./UsersGrid";
import {UsersTranslationKey} from "./UsersTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import TitleHeader from "../../../../components/TitleHeader";

const styles = (theme: Theme) => createStyles({
    page: CommonCssStyles.getRootPageStyles(theme),
    headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
    controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
});

interface IProps extends WithStyles<typeof styles> {
}

class UsersPage extends React.Component<IProps, {}> {

    render() {
        const {classes} = this.props;
        return (
            <Paper className={classes.page}>
                <TitleHeader title={_transl(UsersTranslationKey.TITLE)} />
                <div className={classes.controlPageSegment}>
                    <UsersGrid/>
                </div>
            </Paper>
        )
    }

}

export default withStyles(styles, {withTheme: true})(UsersPage);
