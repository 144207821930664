import {Button} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {ForwardedRef, forwardRef} from "react";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            fontWeight: "normal",
            textTransform: "none",
            borderRadius: 0,
        },
    })
);

export interface MenuButtonProps {
    label: string,
    onClick: () => void,
    className?: string
}

export const MenuButton = forwardRef(({label, onClick, className}: MenuButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const classes = useStyles();

    return (
        <Button ref={ref}
                size={"small"}
                aria-haspopup={"true"}
                onClick={onClick}
                variant={"text"}
                className={className ?? classes.menuButton}
        >
            {label}
        </Button>
    );
});
