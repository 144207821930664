export interface RouteStorage {
    store(route: string): void,
    load(): string | null,
    clear(): void,
}

export class LocalStorageRouteStorage implements RouteStorage {

    private static readonly ROUTE_KEY = "ARCHIREPO_ROUTE";

    store(route: string) {
        localStorage.setItem(LocalStorageRouteStorage.ROUTE_KEY, route);
    }

    load(): string | null {
        return localStorage.getItem(LocalStorageRouteStorage.ROUTE_KEY);
    }

    clear() {
        localStorage.removeItem(LocalStorageRouteStorage.ROUTE_KEY);
    }
}
