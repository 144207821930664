import {MenuItem as MuiMenuItem, useTheme} from "@mui/material";
import {ReactElement} from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export interface MenuItemProps {
    label?: string;
    icon?: JSX.Element;
    shortcut?: string;
    onClick?: (event: any) => void;
    disabled?: boolean;
    selected?: boolean;
    className?: string;
    children?: ReactElement | ReactElement[];
}

export function MenuItem(props: MenuItemProps) {
    const {label, icon, shortcut, onClick, disabled, selected, className, children} = props;

    const theme = useTheme();

    return (
        <MuiMenuItem onClick={onClick} disabled={disabled} selected={selected} className={className}>
            <ListItemIcon>{icon ?? null}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
            <Typography variant={"body2"} color={"text.secondary"} sx={{paddingLeft: theme.spacing(2)}}>{shortcut}</Typography>
            {children}
        </MuiMenuItem>
    );
}
