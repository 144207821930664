import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import ArrayUtils from "../../../../../common/ArrayUtils";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../DiagramTranslationKey";
import {CollectionDto} from "../../../../../common/apis/collection/CollectionDto";
import {stereotypeGetter} from "../../stereotypes/StereotypeGetter";

export class GridColumnDefinition {
    static getDefinition = (): GridColDef[] => {

        function optionsFormatter<T>(getOptionLabel: (option: T) => string) {
            return ((params: GridValueFormatterParams) => ArrayUtils.joinOrElse((params.value as Array<T> || []).map(param => getOptionLabel(param)), ", ", ""));
        }

        return (
            [
                {
                    field: 'identifier',
                    headerName: _transl(DiagramTranslationKey.GRID_HEADER_IDENTIFIER),
                    headerClassName: 'datagrid-column',
                    width: 150
                },
                {
                    field: 'type',
                    headerName: _transl(DiagramTranslationKey.GRID_HEADER_TYPE),
                    headerClassName: 'datagrid-column',
                    width: 180
                },
                {
                    field: 'stereotype',
                    valueGetter: stereotypeGetter,
                    headerName: _transl(DiagramTranslationKey.GRID_HEADER_STEREOTYPE),
                    headerClassName: 'datagrid-column',
                    width: 180
                },
                {
                    field: 'collections',
                    valueFormatter: optionsFormatter<CollectionDto>((collection) => collection.name),
                    headerName: _transl(DiagramTranslationKey.GRID_HEADER_COLLECTIONS),
                    headerClassName: 'datagrid-column',
                    width: 180
                },
                {
                    field: 'name',
                    headerName: _transl(DiagramTranslationKey.GRID_HEADER_NAME),
                    headerClassName: 'datagrid-column',
                    minWidth: 280,
                    flex: 1
                },
            ]
        );
    }
}
