import {Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {ViewpointValidationController} from "./ViewpointValidationController";
import {MetamodelValidationReportDto} from "../../../../common/apis/MetamodelService";
import {ValidationReportSummary} from "./ValidationReportSummary";
import {ButtonLayout, PositiveButtons} from "../../../../components/button/ButtonLayout";
import {OkButton} from "../../../../components/button/OkButton";
import CircularProgress from "../../../../components/progress/CircularProgress";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ViewpointValidationTranslationKey} from "./ViewpointValidationTranslationKey";
import {NotFoundError} from "../../../../common/Errors";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";

enum DialogState {
    FETCHING_DATA,
    VALIDATION_REPORT,
    ERROR
}

export interface ViewpointValidationDialogProps {
    open: boolean,
    onClose?: () => void,
    viewpointValidationController: ViewpointValidationController,
}

export function ViewpointValidationDialog(props: ViewpointValidationDialogProps) {
    const onClose = props.onClose || (() => {});
    const {open, viewpointValidationController} = props;

    const [dialogState, setDialogState] = useState(DialogState.FETCHING_DATA);
    const [errorMessage, setErrorMessage] = useState("");
    const [validationReport, setValidationReport] = useState<MetamodelValidationReportDto | undefined>();

    useEffect(() => {
        function showReport(report: MetamodelValidationReportDto) {
            setValidationReport(report);
            setDialogState(DialogState.VALIDATION_REPORT);
            if (!MetamodelValidationReportDto.isValid(report)) {
                viewpointValidationController.publishHighlightRequestEvent(report);
            }
        }

        function showViewpointNotDefinedError() {
            setErrorMessage(_transl(ViewpointValidationTranslationKey.VIEWPOINT_NOT_DEFINED_ERROR));
            setDialogState(DialogState.ERROR);
        }

        function showUnexpectedError() {
            setErrorMessage(_transl(ViewpointValidationTranslationKey.UNEXPECTED_ERROR));
            setDialogState(DialogState.ERROR);
        }

        if (open) {
            setDialogState(DialogState.FETCHING_DATA);
            viewpointValidationController.validateDiagram()
                .then(report => {
                    showReport(report);
                })
                .catch((error) => {
                    if (error instanceof NotFoundError) {
                        showViewpointNotDefinedError();
                    } else {
                        showUnexpectedError();
                    }
                });
        }
    }, [open, viewpointValidationController]);

    function onCloseClicked() {
        onClose();
    }

    return (
        <Dialog open={open} onClose={onCloseClicked}>
            <DialogTitle title={_transl(ViewpointValidationTranslationKey.REPORT_TITLE)} onDialogClosed={onCloseClicked} />
            <DialogContent>
                {dialogState === DialogState.FETCHING_DATA &&
                    <CircularProgress variant={"indeterminate"} text={_transl(ViewpointValidationTranslationKey.VALIDATE_IN_PROGRESS)} />
                }
                {dialogState === DialogState.VALIDATION_REPORT &&
                    <ValidationReportSummary report={validationReport!} />
                }
                {dialogState === DialogState.ERROR &&
                    <Typography variant={"body2"}>
                        {errorMessage}
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                <ButtonLayout>
                    <PositiveButtons>
                        <OkButton onClick={onCloseClicked} />
                    </PositiveButtons>
                </ButtonLayout>
            </DialogActions>
        </Dialog>
    );
}
