import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {ColorDto, Colors} from "../../common/apis/Color";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        swatch: {
            width: '46px',
            height: '28px',
            borderRadius: '2px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
    })
);

export interface ColorSwatchProps {
    color: ColorDto,
    brightness?: number
}

export function ColorSwatch(props: ColorSwatchProps) {
    const classes = useStyles();
    const brightness = props.brightness ?? 1.0;
    const colorStyle = {
        backgroundColor: Colors.toCss(props.color),
        filter: `brightness(${brightness})`
    };

    return (
        <div className={classes.swatch} style={colorStyle} />
    );
}
