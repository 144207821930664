import {UsersTranslationKey} from "./UsersTranslationKey";
import {_transl, TranslationFunction} from "../../../../store/localization/TranslMessasge";
import {UserRoleType} from "../../../../common/access/UserRoleType";

export class UserRoleTranslator {

    private static TRANSLATION_KEY_MAP = {
        [UserRoleType.ROLE_ADMIN]: UsersTranslationKey.USER_ROLE_ADMIN,
        [UserRoleType.ROLE_SPRAVCE]: UsersTranslationKey.USER_ROLE_MANAGER,
        [UserRoleType.ROLE_OPERATOR]: UsersTranslationKey.USER_ROLE_OPERATOR,
    }

    private static readonly DEFAULT_INSTANCE = new UserRoleTranslator(_transl);

    public static get() {
        return UserRoleTranslator.DEFAULT_INSTANCE;
    }

    constructor(private readonly translate: TranslationFunction) {}

    translateUserRole(userRole: string | null): string | null {
        if (userRole) {
            return this.translate(UserRoleTranslator.TRANSLATION_KEY_MAP[userRole as UserRoleType]) ?? null;
        } else {
            return null;
        }
    }
}