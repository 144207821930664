import * as d3 from 'd3';
import {LinePath} from "../../../connection/ConnectionRendererUtils";
import {OVERLAY_STROKE_COLOR} from "../../../../common/UIConstants";
import RenderContext from "../../../../context/RenderContext";
import ConnectionHandle from "./ConnectionHandle";
import {IDiagramConnectionDto} from "../../../../../apis/diagram/IDiagramConnectionDto";
import EventManager from "../../../../../event/EventManager";

export default class ConnectionDashedLineHandle implements ConnectionHandle {

    private static readonly CIRCLE_START_MARKER_ID = "__connection_handle_dashed_line_start_marker__";
    private static readonly CIRCLE_END_MARKER_ID = "__connection_handle_dashed_line_end_marker__";

    private eventManager: EventManager;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
    }

    init(renderContext: RenderContext) {
        ConnectionDashedLineHandle.initConnectionStartEndMarkersDef(renderContext);
    }

    private static initConnectionStartEndMarkersDef(renderContext: RenderContext) {
        ConnectionDashedLineHandle.createConnectionStartEndMarker(true, renderContext);
        ConnectionDashedLineHandle.createConnectionStartEndMarker(false, renderContext);
    }

    private static createConnectionStartEndMarker(isStart: boolean, renderContext: RenderContext) {
        const size = 5;
        const id = isStart ? ConnectionDashedLineHandle.CIRCLE_START_MARKER_ID : ConnectionDashedLineHandle.CIRCLE_END_MARKER_ID;
        const refX = isStart ? 0 : size + 3;

        const defsGroup = renderContext.svgElementManager.getDefsSelection();
        const marker = defsGroup.append("marker")
            .attr("id", id)
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", refX)
            .attr("refY", 0)
            .attr("markerWidth", size)
            .attr("markerHeight", size)
            .attr("orient", "auto")
            .attr("markerUnits", "userSpaceOnUse");

        const path = marker.append("path");
        path.attr("d", "M5,0m-4,0a4,4 0 1,0 8,0a4,4 0 1,0 -8,0");
        path.attr("fill", "#DADADA");
        path.attr("stroke", OVERLAY_STROKE_COLOR);
        path.attr("stroke-width", 2)
    }

    render(connection: IDiagramConnectionDto,
           linePath: LinePath,
           handleGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
           isHidden: boolean,
           eventManager: EventManager) {

        const d = linePath.path;

        handleGroup.append("path")
            .attr("d", d)
            .attr("fill", "none")
            .attr("stroke", "white")
            .attr("stroke-width", 2)
            .attr("pointer-events", "none");

        handleGroup.append("path")
            .attr("d", d)
            .attr("fill", "none")
            .attr("stroke", OVERLAY_STROKE_COLOR)
            .attr("stroke-width", 2)
            .attr("stroke-dasharray", "7 3")
            .attr("marker-start", `url(${new URL(`#${ConnectionDashedLineHandle.CIRCLE_START_MARKER_ID}`, window.location.href)})`)
            .attr("marker-end", `url(${new URL(`#${ConnectionDashedLineHandle.CIRCLE_END_MARKER_ID}`, window.location.href)})`)
            .attr("pointer-events", "none");
    }

    updateScaleFactor(scaleFactor: number) {}
}