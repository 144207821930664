import React from "react";
import {Menu, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Language} from "../../../../common/Language";
import {buildTranslationKeyForLanguage} from "../../../../store/localization/LanguageTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Check} from "@mui/icons-material";


interface LanguageMenuProps {
    open: boolean;
    anchorRef: any;
    language: Language;
    changeLanguage: (value: Language) => void;
    onMenuClose: () => void;
}

export default function LanguageMenu(props: LanguageMenuProps) {
    const { open, onMenuClose, language, changeLanguage, anchorRef } = props;

    return (
        <Menu
            open={open}
            id="app-bar-language-menu"
            anchorEl={anchorRef}
            keepMounted
            onClose={onMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{vertical: -10, horizontal: 0}}
        >
            {(Object.entries(Language))
                .sort()
                .map(([key, value]) => (
                    <MenuItem key={key} onClick={() => changeLanguage(value)}>
                        <ListItemIcon>{value === language ? <Check/> : null}</ListItemIcon>
                        <Typography variant="inherit">{_transl(buildTranslationKeyForLanguage(value))}</Typography>
                    </MenuItem>
                ))}
        </Menu>
    );
}
