import {FontDto} from "../../../apis/DiagramsApi";
import {FontStyle} from "../../../../diagram/editor/style/StyleSettings";

export class FontStyleBuilder {

    static buildFontStyle(font: FontDto | null) {
        const lowerCaseStyles = FontStyleBuilder.toLowerCase(font?.styles);
        if (lowerCaseStyles.includes(FontStyle.ITALIC.toLowerCase())) {
            return "italic";
        } else {
            return "normal";
        }
    }

    static buildFontWeight(font: FontDto | null): string {
        const lowerCaseStyles = FontStyleBuilder.toLowerCase(font?.styles);
        if (lowerCaseStyles.includes(FontStyle.BOLD.toLowerCase())) {
            return "bold";
        } else {
            return "normal";
        }
    }

    static buildTextDecoration(font: FontDto | null): string {
        const lowerCaseStyles = FontStyleBuilder.toLowerCase(font?.styles);
        if (lowerCaseStyles.includes(FontStyle.UNDERLINE.toLowerCase())) {
            return "underline";
        } else {
            return "none";
        }
    }

    private static toLowerCase(styles: string[] | undefined | null) {
        if (styles) {
            return styles.map(style => style.toLowerCase());
        } else {
            return [];
        }
    }
}