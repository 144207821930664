import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Radio,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import {OkButton} from "../../../../../components/button/OkButton";
import {CancelButton} from "../../../../../components/button/CancelButton";
import {RelationshipDto} from "../../../../../common/apis/relationship/RelationshipDto";
import { _transl } from "../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../DiagramTranslationKey";


const margin = 3;

const styles = (theme: Theme) => createStyles({
    dialog: {},
    dialogTitle: {
        color: "gray",
        padding: 0,
        textAlign: "center",
        "& div": {
            padding: theme.spacing(margin)
        },
        "& hr": {
            marginLeft: theme.spacing(margin),
            marginRight: theme.spacing(margin),
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    gridItem: {
        width: "100%",
        padding: theme.spacing(2,0,1, 0),
    },
    dialogContainer: {
        display: "flex",
        width: "100%",
        paddingTop: "1em",
        paddingBottom: "1em",
    },
    textField: {
        width: "100%",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogActions: {
        padding: theme.spacing(0, 3, 3, 0 )
    },
    alertWrapper: {
        paddingBottom: "1em",
    }
});

interface IProps extends WithStyles<typeof styles> {
    hiddenRelationship: RelationshipDto,
    onCancel: (event: any) => void,
    onConfirm: (reuse: boolean) => void,
}

interface IState {
    selectedValue: ReuseValue,
}

enum ReuseValue {
    REUSE = "REUSE",
    DO_NOT_REUSE = "DO_NOT_REUSE",
}

class HiddenRelationshipReuseConfirmDialog extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedValue: ReuseValue.REUSE,
        }
    }

    render() {

        const {classes, onCancel, onConfirm, hiddenRelationship} = this.props;
        const {selectedValue} = this.state;

        return (
            <Dialog
                open={true}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}>
                    <div>{_transl(DiagramTranslationKey.HIDDEN_RELATIONSHIP_EXISTING)}</div>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={event => onCancel(event)}
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Divider/>
                </DialogTitle>
                <DialogContent>
                    <Grid container className={classes.dialogContainer}>
                        <Grid item className={classes.gridItem}>
                            <span>{_transl(DiagramTranslationKey.HIDDEN_RELATIONSHIP_RELATIONSHIP_EXISTS)}
                                {hiddenRelationship.name && +" " + _transl(DiagramTranslationKey.HIDDEN_RELATIONSHIP_WITH_NAME) + "\"" + hiddenRelationship.name + "\""}.
                                {_transl(DiagramTranslationKey.HIDDEN_RELATIONSHIP_SELECT_ACTION)}</span>
                        </Grid>
                        <Grid item className={classes.gridItem}>
                            <Radio
                                checked={selectedValue === ReuseValue.REUSE}
                                onChange={() => this.setState({selectedValue: ReuseValue.REUSE})}
                                value={ReuseValue.REUSE}
                                name="reuse-group"
                            />
                            <span>{_transl(DiagramTranslationKey.HIDDEN_RELATIONSHIP_REUSE)}</span>
                        </Grid>
                        <Grid item className={classes.gridItem}>
                            <Radio
                                checked={selectedValue ===  ReuseValue.DO_NOT_REUSE}
                                onChange={() => this.setState({selectedValue: ReuseValue.DO_NOT_REUSE})}
                                value={ReuseValue.DO_NOT_REUSE}
                                name="reuse-group"
                            />
                            <span>{_transl(DiagramTranslationKey.HIDDEN_RELATIONSHIP_DO_NOT_REUSE)}</span>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <OkButton onClick={() => onConfirm(this.state.selectedValue === ReuseValue.REUSE)}/>
                    <CancelButton onClick={(event) => onCancel(event)} variant={"contained"}/>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles, {withTheme: true})(HiddenRelationshipReuseConfirmDialog);
