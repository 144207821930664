import React, {useEffect, useRef, useState} from "react";

import {TaskDto, TaskFilterDto} from "@dain/tracker-client";
import {ElementDto} from "../../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import Snackbar from "../../snackbar/Snackbar";
import TrackerService from "./TrackerService";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import {TrackerConfigDto} from "../../../../../configuration/TrackerConfigDto";
import TrackerTasksGrid from "./TrackerTasksGrid";

interface TrackerTasksPanelProps {
    element: ElementDto,
    trackerConfig: TrackerConfigDto,
}

const ARCHIREPO_IDENT: string = "archirepo";

export default function TrackerTasksPanel({element, trackerConfig}: TrackerTasksPanelProps) {

    const trackerService = useRef(new TrackerService(trackerConfig));

    const [tasks, setTasks] = useState<TaskDto[]>([]);
    const [reload, setReload] = useState<number>(0);

    useEffect(() => {
        const filter: TaskFilterDto = {
            extIdent: element.identifier,
            extSystemIdents: [ARCHIREPO_IDENT]
        };

        trackerService.current.api.taskFindByFilterUsingPOST({taskFilterDto: filter})
                .then((response => {
                    setTasks(response.resultList as TaskDto[]);
                }))
                .catch(error => {
                    setTasks([]);
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA));
                });
    }, [element, reload]);


    function reloadTasks() {
        setReload(reload + 1);
    }


    function handleOnTaskAssigned(task: TaskDto) {
        const assignElementToTaskRequest = {
            entityIdents: [element.identifier],
            extSystemCode: ARCHIREPO_IDENT,
            taskKey: task.key
        }

        trackerService.current.externalSystemsApi.attachExtEntitiesToTaskUsingPUT({request: assignElementToTaskRequest})
                .then(() => {
                    reloadTasks();
                })
                .catch(error => {
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA));
                });
    }

    function handleOnTaskUnassigned(task: TaskDto) {
        const assignElementToTaskRequest = {
            entityIdents: [element.identifier],
            extSystemCode: ARCHIREPO_IDENT,
            taskKey: task.key
        }

        trackerService.current.externalSystemsApi.detachExtEntitiesFromTaskUsingPUT({request: assignElementToTaskRequest})
                .then(() => {
                    reloadTasks();
                })
                .catch(error => {
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_REMOVE_DATA));
                });
    }

    return (
            <>
                <TrackerTasksGrid
                        trackerService={trackerService.current}
                        tasks={tasks}
                        onTaskAssigned={handleOnTaskAssigned}
                        onTaskUnassigned={handleOnTaskUnassigned}
                        onRefresh={reloadTasks}
                />
            </>
    );
}
