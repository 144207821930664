import {MenuItem} from "../../../../components/menu/MenuItem";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {Add, Delete, Edit, Link, Reorder, ViewList} from "@mui/icons-material";
import React, {useContext} from "react";
import {PopoverPosition} from "@mui/material/Popover/Popover";
import {CommonTranslation} from "../CommonTranslation";
import {ContextMenu} from "../../../../diagram/editor/components/menu/ContextMenu";
import Divider from "@mui/material/Divider";
import HierarchyTreeCrudOpsContext from "./HierarchyTreeCrudOpsContext";
import {HierarchyTreeItemDetailDto} from "./service/HierarchyTreeItemDetailDto";
import {HierarchyTreeItemType} from "./service/HierarchyTreeItemType";
import {HierarchyTreeTranslationKey} from "./HierarchyTreeTranslationKey";
import {HierarchyTreeDataType} from "./service/HierarchyTreeDataType";
import {HierarchyTreeInfoDto} from "./service/HierarchyTreeDto";
import HierarchyTreePathParser from "./controller/HierarchyTreePathParser";
import {ReferenceTreeDataDto} from "./service/HierarchyTreeDataDto";

export interface HierarchyTreeItemContextMenuProps {
    open: boolean;
    anchorPosition: PopoverPosition;
    treeItem?: HierarchyTreeItemDetailDto;
    treeItemPath?: string;
    tree?: HierarchyTreeInfoDto;
    onClose: () => void;
}

export default function HierarchyTreeItemContextMenu(props: HierarchyTreeItemContextMenuProps) {
    const {open, anchorPosition, treeItem, treeItemPath, tree, onClose} = props;

    const crudOps = useContext(HierarchyTreeCrudOpsContext);

    const isSubtree = treeItem?.type === HierarchyTreeItemType.SUBTREE;
    const isFolder = isSubtree && treeItem.treeData?.type === HierarchyTreeDataType.FOLDER;
    const isReference = isSubtree && treeItem.treeData?.type === HierarchyTreeDataType.REFERENCE;
    const canUpdate = tree?.acl.canUpdate ?? false;
    const canDelete = tree?.acl.canDelete ?? false;

    function onEditClick() {
        if (isReference) {
            crudOps.requestToUpdateReference(treeItem!.id, treeItem!.treeData! as ReferenceTreeDataDto, treeItem!.parentTreeId, parentPath);
        } else {
            crudOps.requestToUpdateTree(treeItem!.id, treeItem!.treeData!, treeItem!.parentTreeId, parentPath);
        }
        onClose();
    }

    const parentPath = treeItemPath ? HierarchyTreePathParser.getParentPath(treeItemPath) : undefined;

    return (
        <ContextMenu open={open}
                 onClose={onClose}
                 anchorPosition={anchorPosition}>
            <MenuItem label={_transl(HierarchyTreeTranslationKey.TREE_ITEM_CONTEXT_MENU_ADD_STRUCTURE)}
                      icon={<Add/>}
                      disabled={!(canUpdate && isFolder)}
                      onClick={() => {
                          crudOps.requestToCreateTree(treeItem!.id, treeItemPath);
                          onClose();
                      }} />
            <MenuItem label={_transl(HierarchyTreeTranslationKey.TREE_ITEM_CONTEXT_MENU_ADD_LINK)}
                      icon={<Link/>}
                      disabled={!(canUpdate && isFolder)}
                      onClick={() => {
                          crudOps.requestToCreateUrlReference(treeItem!.id, treeItemPath);
                          onClose();
                      }} />
            <MenuItem label={_transl(HierarchyTreeTranslationKey.TREE_ITEM_CONTEXT_MENU_ADD_ELEMENT_REPORT_REFERENCE)}
                      icon={<ViewList/>}
                      disabled={!(canUpdate && isFolder)}
                      onClick={() => {
                          crudOps.requestToCreateElementReportReference(treeItem!.id, treeItemPath);
                          onClose();
                      }} />
            <Divider/>
            <MenuItem label={_transl(CommonTranslation.EDIT)}
                      icon={<Edit/>}
                      disabled={!(canUpdate && isSubtree)}
                      onClick={onEditClick} />
            <MenuItem label={_transl(CommonTranslation.REMOVE)}
                      icon={<Delete/>}
                      disabled={!(canDelete && isSubtree)}
                      onClick={() => {
                          crudOps.requestToDeleteTree(treeItem!.id, treeItem!.parentTreeId, parentPath);
                          onClose();
                      }} />
            <Divider/>
            <MenuItem label={_transl(HierarchyTreeTranslationKey.TREE_ITEM_CONTEXT_MENU_REORDER_ITEMS)}
                      icon={<Reorder/>}
                      disabled={!(canUpdate && isFolder)}
                      onClick={() => {
                          crudOps.requestToUpdateOrder(treeItem!.id, treeItemPath);
                          onClose();
                      }} />
        </ContextMenu>
    );
}