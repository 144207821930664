import React, {useState} from "react";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {IFilter} from "../../../../store/elements/Elements";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import ArrayUtils from "../../../../common/ArrayUtils";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {CloseButton} from "../../../../components/button/CloseButton";
import Constants from "../../../../common/Constants";
import ElementsGrid, {ElementsGridType} from "./ElementsGrid";
import {Box} from "@mui/material";
import {PersistentStateId} from "../../../../store/common/Grid";

interface Props {
    dialogTitle: string,
    isOpened: boolean,
    elements: Array<ElementDto>,
    onDialogClosed: () => void,
}

export default function ElementListDialog({isOpened, elements, onDialogClosed, dialogTitle}: Props) {
    const uniqueElements = ArrayUtils.filterOutDuplicitIdentifiableItems(elements, element => element.identifier);
    const [filter,] = useState<IFilter>({identifiers: uniqueElements.map(element => element.identifier)});

    return (
        <Dialog
            open={isOpened}
            aria-labelledby="element-list-dialog-title"
            onClose={onDialogClosed}
            fullWidth={true}
            maxWidth={"xl"}
        >
            <DialogTitle
                id="element-list-dialog-title"
                title={dialogTitle}
                onDialogClosed={onDialogClosed}
            />
            <DialogContent>
                <Box padding={theme => theme.spacing(CommonCssStyles.STANDARD_PADDING, 0, 0, 0)}>
                    <ElementsGrid
                        filter={{...filter, maxPageSize: Constants.LARGE_GRID_PAGE_SIZE}}
                        elementsGridType={ElementsGridType.LISTING}
                        persistentStateId={PersistentStateId.ELEMENTS_LIST_GRID}
                        refetchData={() => {}}
                        isAtLeastOneIdentifierMandatory={true}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );

}
