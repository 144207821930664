import {GridColDef} from "@mui/x-data-grid";
import {TranslationFunction} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "../elements/ElementTranslationKey";
import {stereotypeGetter} from "../stereotypes/StereotypeGetter";
import {DiagramTranslationKey} from "../diagrams/DiagramTranslationKey";

export function createElementsExportWizardGridColDef(_transl: TranslationFunction): GridColDef[] {
    return [
        {
            field: 'identifier',
            headerName: _transl(ElementTranslationKey.GRID_HEADER_IDENTIFIER),
            headerClassName: 'datagrid-column',
            width: 200
        },
        {
            field: 'stereotype',
            valueGetter: stereotypeGetter,
            headerName: _transl(DiagramTranslationKey.GRID_HEADER_STEREOTYPE),
            headerClassName: 'datagrid-column',
            width: 180
        },
        {
            field: 'name',
            headerName: _transl(ElementTranslationKey.GRID_HEADER_NAME),
            headerClassName: 'datagrid-column',
            width: 600
        },
        {
            field: 'description',
            headerName: _transl(ElementTranslationKey.GRID_HEADER_DESCRIPTION),
            headerClassName: 'datagrid-column',
            width: 170
        },
    ];
}