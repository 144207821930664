import {TopMenuId} from "./TopMenuId";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../../DiagramEditorTranslationKey";
import React, {useRef} from "react";
import AlignIcon, {Orientation as AlignOrientation} from "../../../svgicons/AlignIcon";
import AlignCenterIcon, {Orientation as AlignCenterOrientation} from "../../../svgicons/AlignCenterIcon";
import {AlignmentType} from "../../../../common/diagrameditor/common/AlignmentType";
import {MenuButton} from "../../../menu/MenuButton";
import {Menu} from "../../../menu/Menu";
import {MenuItem} from "../../../menu/MenuItem";
import {Divider} from "@mui/material";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";


export interface AlignMenuProps {
    open: boolean,
    itemsDisabled: boolean,
    onClick: (menuId: TopMenuId) => void,
    onClose: () => void,
    onAlignNodes: (alignmentType: AlignmentType) => void,
    mode: RenderMode,
}

export default function AlignMenu(props: AlignMenuProps) {

    const {open, itemsDisabled, onClick, onClose, onAlignNodes} = props;

    const anchorRef = useRef<HTMLButtonElement | null>(null);

    function onAlignClick(alignmentType: AlignmentType) {
        onAlignNodes(alignmentType);
        onClose();
    }

    return props.mode === RenderMode.EDIT ? <>
        <MenuButton label={_transl(DiagramEditorTranslationKey.TOP_MENU_ALIGNMENT)}
                    ref={anchorRef}
                    onClick={() => onClick(TopMenuId.ALIGN_MENU)} />
        <Menu open={open}
              anchorEl={anchorRef.current}
              onClose={onClose}
        >
            <MenuItem label={_transl(DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_LEFT)}
                      icon={<AlignIcon orientation={AlignOrientation.TO_LEFT} />}
                      disabled={itemsDisabled}
                      onClick={() => onAlignClick(AlignmentType.LEFT)} />

            <MenuItem label={_transl(DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_CENTER)}
                      icon={<AlignCenterIcon orientation={AlignCenterOrientation.HORIZONTAL} />}
                      disabled={itemsDisabled}
                      onClick={() => onAlignClick(AlignmentType.CENTER)} />

            <MenuItem label={_transl(DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_RIGHT)}
                      icon={<AlignIcon orientation={AlignOrientation.TO_RIGHT} />}
                      disabled={itemsDisabled}
                      onClick={() => onAlignClick(AlignmentType.RIGHT)} />

            <Divider/>

            <MenuItem label={_transl(DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_TOP)}
                      icon={<AlignIcon orientation={AlignOrientation.TO_TOP} />}
                      disabled={itemsDisabled}
                      onClick={() => onAlignClick(AlignmentType.TOP)} />

            <MenuItem label={_transl(DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_MIDDLE)}
                      icon={<AlignCenterIcon orientation={AlignCenterOrientation.VERTICAL} />}
                      disabled={itemsDisabled}
                      onClick={() => onAlignClick(AlignmentType.MIDDLE)} />

            <MenuItem label={_transl(DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_BOTTOM)}
                      icon={<AlignIcon orientation={AlignOrientation.TO_BOTTOM} />}
                      disabled={itemsDisabled}
                      onClick={() => onAlignClick(AlignmentType.BOTTOM)} />
        </Menu>
    </> : null

}
