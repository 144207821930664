import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React, {useEffect, useReducer, useRef, useState} from "react";
import clsx from "clsx";
import {GRID_DEFAULT_UNITS} from "../../../common/diagrameditor/manager/GridManager";
import {DiagramEditor} from "../../../common/diagrameditor/DiagramEditor";
import {SaveButtonStatus} from "../../../pages/main/content/diagrams/DiagramEditorDialog";
import {DiagramZoomEvent, EventType, ISelectionChangedEvent, IUndoRedoEvent} from "../../../common/event/Event";
import Constants from "../../../common/Constants";
import EventManager, {Unsubscriber} from "../../../common/event/EventManager";
import {ElementDto} from "../../../common/apis/element/ElementDto";
import {IEditMode} from "../../../common/diagrameditor/editor/IEditMode";
import DiagramMenu from "./top/DiagramMenu";
import {TopMenuId} from "./top/TopMenuId";
import ViewMenu from "./top/ViewMenu";
import Toolbar from "../../toolbar/Toolbar";
import AlignMenu from "./top/AlignMenu";
import {AlignmentType} from "../../../common/diagrameditor/common/AlignmentType";
import PositionDirection
    from "../../../common/diagrameditor/manager/model/eventhandlers/positionupdater/PositionDirection";
import {_transl} from "../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../DiagramEditorTranslationKey";
import ToolsMenu from "./top/ToolsMenu";
import userSettingsService from "../../../common/apis/user/UserSettingsService";

export const topMenuListClassName = "__diagram-editor-top-menu__";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topMenu: {
            backgroundColor: Constants.MENU_BACKGROUND_COLOR,
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            color: "gray",
            fontSize: "90%",
            letterSpacing: "0.00938em",
            flexGrow: 1,
            "& .menu-text": {
                display: "flex",
                userSelect: "none",
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                color: "gray",
                fontSize: "0.8rem",
                fontWeight: "normal",
                letterSpacing: "0.00938em",
                textTransform: "none",
            },
        },
        topMenuItems: {
            borderBottom: "1px solid " + Constants.MENU_BACKGROUND_COLOR_DARKER,
        },
        topMenuToolbar: {
            display: "flex",
        },
    })
);

interface IProps {
    mode: IEditMode,
    saveButtonStatus: SaveButtonStatus,
    eventManager?: EventManager;
    editor?: DiagramEditor;
    openDiagramDetailDialog: () => void,
    diagramId: string
}

// STATE

type SelectionRelatedMenusState = {
    alignNodeMenusDisabled: boolean,
    moveNodeToFrontMenuDisabled: boolean,
    moveNodeToBackMenuDisabled: boolean,
}

type State = {
    openedMenuId?: TopMenuId,
    zoomScale: number,
    zoomInDisabled: boolean,
    zoomOutDisabled: boolean,
    undoEvents: Array<string>,
    redoEvents: Array<string>,
    showAddElementsToModelDialog: boolean,
    selectionRelatedMenus: SelectionRelatedMenusState,
}

enum ActionType {
    SET_OPENED_MENUID = "SET_OPENED_MENUID",
    SET_ZOOM = "SET_ZOOM",
    SET_UNDO_REDO_EVENTS = "SET_UNDO_REDO_EVENTS",
    UPDATE_SELECTION_RELATED_MENUS = "UPDATE_SELECTION_RELATED_MENUS",
}

type Action =
    | { type: ActionType.SET_OPENED_MENUID, payload: TopMenuId | undefined }
    | { type: ActionType.SET_ZOOM, payload: { zoomScale: number, zoomInDisabled: boolean, zoomOutDisabled: boolean } }
    | { type: ActionType.SET_UNDO_REDO_EVENTS, payload: { undoEvents: Array<string>, redoEvents: Array<string> } }
    | { type: ActionType.UPDATE_SELECTION_RELATED_MENUS, payload: SelectionRelatedMenusState }

function globalReducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.SET_OPENED_MENUID:
            return {
                ...state,
                openedMenuId: action.payload,
            };
        case ActionType.SET_ZOOM:
            return {
                ...state,
                zoomScale: action.payload.zoomScale,
                zoomInDisabled: action.payload.zoomInDisabled,
                zoomOutDisabled: action.payload.zoomOutDisabled,
            };
        case ActionType.SET_UNDO_REDO_EVENTS:
            return {
                ...state,
                undoEvents: action.payload.undoEvents,
                redoEvents: action.payload.redoEvents,
            };
        case ActionType.UPDATE_SELECTION_RELATED_MENUS :
            return {
                ...state,
                selectionRelatedMenus: action.payload,
            }
        default:
            return state;
    }
}

const initialState: State = {
    openedMenuId: undefined,
    zoomScale: 1,
    zoomInDisabled: false,
    zoomOutDisabled: false,
    undoEvents: [],
    redoEvents: [],
    showAddElementsToModelDialog: false,
    selectionRelatedMenus: {
        alignNodeMenusDisabled: true,
        moveNodeToFrontMenuDisabled: true,
        moveNodeToBackMenuDisabled: true,
    },
}

type IgnoreEventsType = { ignoreEvents: boolean };


// GLOBAL FUNCTIONS

function addChartZoomListener(editor: DiagramEditor, dispatch: React.Dispatch<Action>, ignoreEvents: IgnoreEventsType) {
    return editor.getDiagramEditorApi().addChartZoomListener((event: DiagramZoomEvent) => {
        if (!ignoreEvents.ignoreEvents) {
            dispatch({
                type: ActionType.SET_ZOOM,
                payload: {
                    zoomScale: event.actualZoom,
                    zoomInDisabled: event.actualZoom >= event.maxZoom,
                    zoomOutDisabled: event.actualZoom <= event.minZoom,
                }
            });
        }
    });
}

function addUndoRedoListener(editor: DiagramEditor, dispatch: React.Dispatch<Action>, ignoreEvents: IgnoreEventsType) {
    return editor?.getDiagramEditorApi().addUndoRedoListener((event: IUndoRedoEvent) => {
        if (!ignoreEvents.ignoreEvents) {
            dispatch({
                type: ActionType.SET_UNDO_REDO_EVENTS,
                payload: {
                    undoEvents: event.undoEvents,
                    redoEvents: event.redoEvents,
                }
            });
        }
    });
}

function addSelectionListener(editor: DiagramEditor, dispatch: React.Dispatch<Action>, ignoreEvents: IgnoreEventsType) {
    return editor?.getDiagramEditorApi().addSelectionChangedListener((event: ISelectionChangedEvent) => {
        if (!ignoreEvents.ignoreEvents) {
            dispatch({
                type: ActionType.UPDATE_SELECTION_RELATED_MENUS,
                payload: {
                    alignNodeMenusDisabled: !editor.getDiagramEditorApi().areSelectedNodesAlignable(),
                    moveNodeToBackMenuDisabled: !editor.getDiagramEditorApi().isSelectedNodesPositionUpdatable(PositionDirection.TO_BACK),
                    moveNodeToFrontMenuDisabled: !editor.getDiagramEditorApi().isSelectedNodesPositionUpdatable(PositionDirection.TO_FRONT),
                }
            });
        }
    })
}

export default function TopMenu({mode, saveButtonStatus, eventManager, editor, openDiagramDetailDialog, diagramId}: IProps) {

    const classes = useStyles();

    // REDUCER
    const [{
        openedMenuId, zoomScale, zoomInDisabled, zoomOutDisabled, undoEvents, redoEvents,
        selectionRelatedMenus
    }, dispatch] = useReducer(globalReducer, initialState);

    const [snapToGrid, setSnapToGrid] = useState<boolean>(true);
    const [showGrid, setShowGrid] = useState<boolean>(false);
    const [gridSize, setGridSize] = useState<number>(GRID_DEFAULT_UNITS);
    const [showHiddenConnections, setShowHiddenConnections] = useState<boolean>(false);

    // EFFECTS
    useEffect(() => {
        const ignoreEvents: IgnoreEventsType = {ignoreEvents: false};
        let unsubscribers: Array<Unsubscriber> = [];
        if (editor) {
            unsubscribers.push(addChartZoomListener(editor, dispatch, ignoreEvents));
            unsubscribers.push(addUndoRedoListener(editor, dispatch, ignoreEvents));
            unsubscribers.push(addSelectionListener(editor, dispatch, ignoreEvents))
        }

        return () => {
            ignoreEvents.ignoreEvents = true;
            for (const unsubscriber of unsubscribers) {
                unsubscriber();
            }
        }
    }, [editor]);

    useEffect(() => {
        userSettingsService.getEditorSettings().then((settings) => {
            if (settings) {
                if (settings.snapToGrid != null) {
                    editor?.getDiagramEditorApi().snapToGrid(settings.snapToGrid, null);
                    setSnapToGrid(settings.snapToGrid);
                }
                if (settings.showGrid != null) {
                    editor?.getDiagramEditorApi().showGrid(settings.showGrid, null);
                    setShowGrid(settings.showGrid);
                }
                if (settings.gridSize != null) {
                    editor?.getDiagramEditorApi().updateGridUnits(settings.gridSize, null);
                    setGridSize(settings.gridSize);
                }
                if (settings.showHiddenConnections != null) {
                    editor?.getDiagramEditorApi().updateHiddenConnections(settings.showHiddenConnections);
                    setShowHiddenConnections(settings.showHiddenConnections);
                }
            }
        });
    }, [editor]);

    const didMount = useRef<boolean>(false)

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }
        userSettingsService.getEditorSettings().then((settings) => {
            if (!settings) {
                settings = {};
            }
            settings.snapToGrid = snapToGrid;
            settings.showGrid = showGrid;
            settings.gridSize = gridSize;
            settings.showHiddenConnections = showHiddenConnections;
            userSettingsService.updateEditorSettings(settings);
        });
    }, [snapToGrid, showGrid, gridSize, showHiddenConnections]);

    function onMainMenuClicked(menuId: TopMenuId) {
        if (menuId === openedMenuId) {
            dispatch({type: ActionType.SET_OPENED_MENUID, payload: undefined});
        } else {
            dispatch({type: ActionType.SET_OPENED_MENUID, payload: menuId});
        }
    }

    function closeMainMenu(callback?: () => void) {
        dispatch({type: ActionType.SET_OPENED_MENUID, payload: undefined});
        callback && callback();
    }

    function isMainMenuOpened(menuId: TopMenuId) {
        return menuId === openedMenuId;
    }

    function onShowGridMenuClicked(event: any) {
        const newShowGrid = !showGrid;
        editor?.getDiagramEditorApi().showGrid(newShowGrid, event);
        setShowGrid(newShowGrid);
    }

    function onSnapToGridMenuClicked(event: any) {
        const newSnapToGrid = !snapToGrid;
        editor?.getDiagramEditorApi().snapToGrid(newSnapToGrid, event);
        setSnapToGrid(newSnapToGrid);
    }

    function onGridSizeChange(event: any, newGridSize: number) {
        editor?.getDiagramEditorApi().updateGridUnits(newGridSize, event);
        setGridSize(newGridSize);
    }

    function onShowHiddenConnectionsMenuClicked() {
        const show = !showHiddenConnections;
        editor?.getDiagramEditorApi().updateHiddenConnections(show);
        setShowHiddenConnections(show);
    }

    function onSaveChangesClicked(event: any) {
        mode.diagramApi.saveChanges(event);
    }

    function onToolbarSaveButtonOnClick(event: any, saveButtonStatus: SaveButtonStatus) {
        if (saveButtonStatus !== SaveButtonStatus.SAVE_NOT_NEEDED) {
            mode.diagramApi.saveChanges(event);
        }
    }

    function zoomInClicked(event: any) {
        eventManager?.publishEvent({type: EventType.CHART_ZOOM_IN_CLICKED, event: event});
    }

    function zoomOutClicked(event: any) {
        eventManager?.publishEvent({type: EventType.CHART_ZOOM_OUT_CLICKED, event: event});
    }

    function scaleToFit(event: any) {
        eventManager?.publishEvent({type: EventType.CHART_ZOOM_SCALE_TO_FIT_CLICKED, event: event});
    }

    function onUndoButtonClicked(event: any) {
        editor?.getDiagramEditorApi().undo(event);
    }

    function onRedoButtonClicked(event: any) {
        editor?.getDiagramEditorApi().redo(event);
    }

    function addElementsToModel(elements: Array<ElementDto>) {
        editor?.getDiagramEditorApi().addElementsAndRelatedConnectionsToModel(elements);
    }

    function onAlignSelectedNodesClicked(alignmentType: AlignmentType) {
        editor?.getDiagramEditorApi().onAlignSelectedNodesClicked(alignmentType);
    }

    function updateSelectedNodesPosition(direction: PositionDirection) {
        editor?.getDiagramEditorApi().updateSelectedNodesPosition(direction);
    }

    const isDiagramMenuOpened = isMainMenuOpened(TopMenuId.DIAGRAM_MENU);
    const isViewMenuOpened = isMainMenuOpened(TopMenuId.VIEW_MENU);
    const isAlignMenuOpened = isMainMenuOpened(TopMenuId.ALIGN_MENU);
    const isToolsMenuOpened = isMainMenuOpened(TopMenuId.TOOLS_MENU);
    const saveMenuItemDisabled = saveButtonStatus === SaveButtonStatus.SAVE_NOT_NEEDED || saveButtonStatus === SaveButtonStatus.SAVE_IN_PROGRESS;

    const lastUndoEvent = undoEvents.length > 0 ?
        _transl(DiagramEditorTranslationKey.TOP_MENU_BACK) + " " + undoEvents[undoEvents.length - 1] : undefined;
    const firstRedoEvent = redoEvents.length > 0 ?
        _transl(DiagramEditorTranslationKey.TOP_MENU_REPEAT) + " " + redoEvents[0] : undefined;

    return (
        <div className={clsx(classes.topMenu, topMenuListClassName)}>
            <div className={classes.topMenuItems}>
                <DiagramMenu open={isDiagramMenuOpened}
                             onClick={menuId => onMainMenuClicked(menuId)}
                             onClose={() => closeMainMenu()}
                             saveDisabled={saveMenuItemDisabled}
                             onSaveClicked={(event: any) => onSaveChangesClicked(event)}
                             mode={mode.mode}
                             modelProvider={editor?.getDiagramEditorApi()}
                />
                <ViewMenu open={isViewMenuOpened}
                          onClick={menuId => onMainMenuClicked(menuId)}
                          onClose={() => closeMainMenu()}
                          showHiddenConnections={showHiddenConnections}
                          onShowHiddenConnectionsMenuClicked={() => onShowHiddenConnectionsMenuClicked()}
                          gridUnits={gridSize}
                          onGridUnitsChange={(event, gridSize) => onGridSizeChange(event, gridSize)}
                          snapToGrid={snapToGrid}
                          onSnapToGridMenuClicked={(event) => onSnapToGridMenuClicked(event)}
                          showGrid={showGrid}
                          onShowGridMenuClicked={(event) => onShowGridMenuClicked(event)}
                          moveToFrontMenuDisabled={selectionRelatedMenus.moveNodeToFrontMenuDisabled}
                          onMoveToFrontClicked={() => updateSelectedNodesPosition(PositionDirection.TO_FRONT)}
                          moveToBackMenuDisabled={selectionRelatedMenus.moveNodeToBackMenuDisabled}
                          onMoveToBackClicked={() => updateSelectedNodesPosition(PositionDirection.TO_BACK)}
                          mode={mode.mode}
                />
                <AlignMenu open={isAlignMenuOpened}
                           itemsDisabled={selectionRelatedMenus.alignNodeMenusDisabled}
                           onClick={menuId => onMainMenuClicked(menuId)}
                           onClose={() => closeMainMenu()}
                           onAlignNodes={(alignmentType: AlignmentType) => onAlignSelectedNodesClicked(alignmentType)}
                           mode={mode.mode}
                />
                <ToolsMenu open={isToolsMenuOpened}
                           onClick={menuId => onMainMenuClicked(menuId)}
                           onClose={() => closeMainMenu()}
                           eventManager={editor?.getEventManager()}
                           modelProvider={editor?.getDiagramEditorApi()}
                           mode={mode.mode}
                />
            </div>
            <div className={classes.topMenuToolbar}>
                <Toolbar saveButtonStatus={saveButtonStatus}
                         firstRedoEvent={firstRedoEvent}
                         lastUndoEvent={lastUndoEvent}
                         onUndoButtonClicked={(event) => onUndoButtonClicked(event)}
                         onRedoButtonClicked={event => onRedoButtonClicked(event)}
                         zoomInDisabled={zoomInDisabled}
                         zoomOutDisabled={zoomOutDisabled}
                         zoomInClicked={(event) => zoomInClicked(event)}
                         zoomOutClicked={(event) => zoomOutClicked(event)}
                         zoomScale={zoomScale}
                         scaleToFit={(event) => scaleToFit(event)}
                         onToolbarSaveButtonOnClick={event => onToolbarSaveButtonOnClick(event, saveButtonStatus)}
                         addElementsToModel={elements => addElementsToModel(elements)}
                         openDiagramDetailDialog={() => openDiagramDetailDialog()}
                         eventManager={editor?.getEventManager()}
                         mode={mode.mode}
                />
            </div>
        </div>
    )
}
