import UserAclDto from "../../../../../common/apis/user/UserAclDto";
import {UserDto} from "../../../../../common/apis/user/UserDto";
import {BlobData, BlobUtils} from "../../../../../common/BlobUtils";
import Api from "../../../../../common/Api";
import {map} from "rxjs/operators";
import {UserService} from "../../../../../common/apis/UserService";
import {UserRole} from "../../../../../common/access/UserRole";
import {UserRoleType} from "../../../../../common/access/UserRoleType";

export type PermissionsData = {
    data: any,
    fileName: string,
}

export interface UsersPageController {
    getAllUsers(): Promise<UserDto[]>,

    activateUserByLogin(login: string, isActive: boolean): Promise<void>,

    exportPermissions(): Promise<PermissionsData>,

    saveBlobData(blobData: BlobData): void,

    visibleRoles(loggedUser: UserDto): UserRole[],

    canActivateUser(acl: UserAclDto): boolean,

    canDeactivateUser(acl: UserAclDto): boolean,

    canCreateUsers(acl: UserAclDto): boolean,

    canChangePassword(acl: UserAclDto): boolean,

    canImportPermissions(acl: UserAclDto): boolean,

    canExportPermissions(acl: UserAclDto): boolean,
}

class UsersPageControllerImpl implements UsersPageController {
    constructor(private userService: UserService) {
    }

    async getAllUsers(): Promise<UserDto[]> {
        return this.userService.getAllUsers();
    }

    async activateUserByLogin(login: string, isActive: boolean): Promise<void> {
        return this.userService.activateUserByLogin(login, isActive);
    }

    async exportPermissions(): Promise<PermissionsData> {
        return Api.exports.exportPermissions()
            .pipe(
                map(response => {
                    const disposition = response.xhr.getResponseHeader('Content-Disposition') as string;
                    const fileName = disposition.substring(disposition?.indexOf("filename=") + 9);
                    const data = response.response;
                    const permissionsData: PermissionsData = {
                        fileName: fileName,
                        data: data,
                    }
                    return permissionsData;
                })
            )
            .toPromise();
    }

    saveBlobData(blobData: BlobData) {
        BlobUtils.saveBlobData(blobData);
    }

    canActivateUser(acl: UserAclDto): boolean {
        return acl.canActivateUser;
    }

    canDeactivateUser(acl: UserAclDto): boolean {
        return acl.canDeactivateUser;
    }

    canCreateUsers(acl: UserAclDto): boolean {
        return acl.canCreateUsers;
    }

    canChangePassword(acl: UserAclDto): boolean {
        return acl.canChangePassword;
    }

    canImportPermissions(acl: UserAclDto): boolean {
        return acl.canImportPermissions;
    }

    canExportPermissions(acl: UserAclDto): boolean {
        return acl.canExportPermissions;
    }

    visibleRoles(loggedUser: UserDto): UserRole[] {
        const loggedUserRole = loggedUser.role;
        switch (loggedUserRole) {
            case UserRoleType.ROLE_ADMIN:
                return [UserRole[UserRoleType.ROLE_ADMIN], UserRole[UserRoleType.ROLE_SPRAVCE], UserRole[UserRoleType.ROLE_OPERATOR]];
            case UserRoleType.ROLE_SPRAVCE:
                return [UserRole[UserRoleType.ROLE_SPRAVCE], UserRole[UserRoleType.ROLE_OPERATOR]];
            default:
                return [];
        }
    }

}

export default function constructUsersController(userService: UserService): UsersPageController {
    return new UsersPageControllerImpl(userService);
}
