import React, {useContext, useState} from "react";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import Snackbar from "../../snackbar/Snackbar";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import Grid from "../../../../../components/dialogs/Grid";
import TextField from "../../../../../components/fields/textfield/TextField";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import {CancelButton} from "../../../../../components/button/CancelButton";
import elementService, {DescriptionGenerationPropertiesDto} from "../../elements/service/ElementService";
import {ElementDetailTranslationKey} from "../ElementDetailTranslationKey";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import LocaleContext from "../../../../../common/LocaleContext";
import {Button} from "../../../../../components/button/Button";
import {AutoFixHigh} from "@mui/icons-material";
import {Box, CircularProgress} from "@mui/material";

interface ElementDescriptionGenerationDialogProps {
    open: boolean;
    elementIdentifier: string;
    onClosed: () => void;
    onDescriptionGenerated: (description: string) => void;
}

export default function ElementDescriptionGenerationDialog({open, elementIdentifier, onClosed, onDescriptionGenerated}: ElementDescriptionGenerationDialogProps) {

    const { language } = useContext(LocaleContext);

    const [assistantRoleDesc, setAssistantRoleDesc] = useState<string>("You are a solution architect and an enterprise architect with expertise in ArchiMate and TOGAF.");
    const [elementSummary, setElementSummary] = useState<string>("An ArchiMate model contains an element of type %1$s named %2$s.");
    const [relationshipsProlog, setRelationshipsProlog] = useState<string>("The element has the following relationships:");
    const [relationshipSummary, setRelationshipSummary] = useState<string>("%1$d. %2$s %3$s %4$s named %5$s");
    const [directionTo, setDirectionTo] = useState<string>("to");
    const [directionFrom, setDirectionFrom] = useState<string>("from");
    const [finalInstructions, setFinalInstructions] = useState<string>("Briefly summarize the element. Do not repeat facts about relationships, only summarize what they imply if they are provided.");

    const [descriptionGenInProgress, setDescriptionGenInProgress] = useState(false);

    function generateDescription() {
        setDescriptionGenInProgress(true);

        const properties: DescriptionGenerationPropertiesDto = {
            languageCode: language,
            messagesOverrides: {
                assistantRoleDesc: assistantRoleDesc.trim().length > 0 ? assistantRoleDesc : undefined,
                elementSummary: elementSummary.trim().length > 0 ? elementSummary : undefined,
                relationshipsProlog: relationshipsProlog.trim().length > 0 ? relationshipsProlog : undefined,
                relationshipSummary: relationshipSummary.trim().length > 0 ? relationshipSummary : undefined,
                directionTo: directionTo.trim().length > 0 ? directionTo : undefined,
                directionFrom: directionFrom.trim().length > 0 ? directionFrom : undefined,
                finalInstructions: finalInstructions.trim().length > 0 ? finalInstructions : undefined,
            }
        };

        elementService.generateDescriptionUsingAi(elementIdentifier, properties)
            .then((result) => {
                onDescriptionGenerated(result.description);
                onClosed();
                Snackbar.success(_transl(ElementDetailTranslationKey.DESCRIPTION_GENERATION_COMPLETED));
            })
            .catch((err) => {
                Snackbar.error(_transl(ErrorTranslationKey.UNEXPECTED_ERROR_OCCURRED), err);
            })
            .finally(() => setDescriptionGenInProgress(false));
    }

    return (
        <Dialog open={open} onClose={onClosed} maxWidth={"md"}>
            <DialogTitle title={"Description generation using AI"}
                         onDialogClosed={onClosed} />
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField id="assistant-role-desc-field"
                                   label={"Description of assistant role"}
                                   value={assistantRoleDesc}
                                   onChange={(value) => {
                                       setAssistantRoleDesc(value);
                                   }}
                                   multiline
                                   rows={3}
                                   maxLength={500}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="element-summary-field"
                                   label={"Element summary"}
                                   title={"%1$s: element type\n%2$s: element name"}
                                   value={elementSummary}
                                   onChange={(value) => {
                                       setElementSummary(value);
                                   }}
                                   multiline
                                   rows={2}
                                   maxLength={250}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="relationships-prolog-field"
                                   label={"Relationships prolog"}
                                   value={relationshipsProlog}
                                   onChange={(value) => {
                                       setRelationshipsProlog(value);
                                   }}
                                   multiline
                                   rows={2}
                                   maxLength={500}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="relationship-summary-field"
                                   label={"Relationship summary"}
                                   title={"%1$s: index (1, 2, 3, ...)\n%2$s: relationship type\n%3$s: direction (from/to)\n%4$s: element type\n%5$s: element name"}
                                   value={relationshipSummary}
                                   onChange={(value) => {
                                       setRelationshipSummary(value);
                                   }}
                                   multiline
                                   rows={2}
                                   maxLength={250}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="direction-from-field"
                                   label={"From"}
                                   value={directionFrom}
                                   onChange={(value) => {
                                       setDirectionFrom(value);
                                   }}
                                   maxLength={24}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="direction-to-field"
                                   label={"To"}
                                   value={directionTo}
                                   onChange={(value) => {
                                       setDirectionTo(value);
                                   }}
                                   maxLength={24}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="final-instructions-field"
                                   label={"Final instructions"}
                                   value={finalInstructions}
                                   onChange={(value) => {
                                       setFinalInstructions(value);
                                   }}
                                   multiline
                                   rows={3}
                                   maxLength={500}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box mr={1} sx={{position: "relative"}}>
                    <Button label="Generate"
                            icon={<AutoFixHigh/>}
                            variant={"contained"}
                            onClick={generateDescription}
                            disabled={descriptionGenInProgress}/>
                    {descriptionGenInProgress &&
                        <CircularProgress size={24} sx={{position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginBottom: "-12px"}} />
                    }
                </Box>
                <CancelButton onClick={onClosed}/>
            </DialogActions>
        </Dialog>
    );
}

