import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Wizard from "../../../../components/wizard/Wizard";
import UploadFilePanel from "../../../../components/UploadFilePanel";
import {MenuItem, Select} from "@mui/material";
import Api from "../../../../common/Api";
import {IRelationshipsMatrixMetadataDto} from "../../../../common/apis/Imports";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {WizardStep} from "../../../../components/wizard/WizardStep";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";
import {FileFormat, FileType} from "./FileType";
import {AjaxError} from "rxjs/ajax";
import {ValidationError} from "../../../../common/ValidationError";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uploadContentFileName: {
            fontSize: "1.4em",
            marginBottom: theme.spacing(5),
        },
        uploadContentFileTypeLabel: {
            marginRight: theme.spacing(2),
            fontWeight: "bolder",
        },
    })
);

interface Props {
    // router props
    resetImport: () => void,
}

const IMPORT_ERROR_CODE_TO_MESSAGE_MAP = new Map([
    ["ELEMENT_IDENTIFIER_MISSING", ImportTranslationKey.IMPORT_RELATIONSHIP_MATRIX_ELEMENT_IDENTIFIER_MISSING],
    ["EMPTY_COLUMN_NOT_ALLOWED", ImportTranslationKey.IMPORT_RELATIONSHIP_MATRIX_EMPTY_COLUMN_NOT_ALLOWED],
    ["EMPTY_ROW_NOT_ALLOWED", ImportTranslationKey.IMPORT_RELATIONSHIP_MATRIX_EMPTY_ROW_NOT_ALLOWED],
    ["UNKNOWN_IMPORT_FORMAT", ImportTranslationKey.IMPORT_RELATIONSHIP_MATRIX_UNKNOWN_FORMAT],
    ["IMPORT_RELATIONSHIP_ERROR", ImportTranslationKey.IMPORT_RELATIONSHIP_ERROR],
    ["IMPORT_RELATIONSHIP_MATRIX_INSUFFICIENT_RIGHTS_SOURCE", ImportTranslationKey.IMPORT_RELATIONSHIP_MATRIX_INSUFFICIENT_RIGHTS_SOURCE],
    ["IMPORT_RELATIONSHIP_MATRIX_INSUFFICIENT_RIGHTS_SOURCE_OR_TARGET", ImportTranslationKey.IMPORT_RELATIONSHIP_MATRIX_INSUFFICIENT_RIGHTS_SOURCE_OR_TARGET],
]);

export default function RelationshipImportWizard(props: Props) {

    const classes = useStyles();

    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedFileType, setSelectedFileType] = useState<FileType>();
    const [selectedFileTypeRecognitionDone, setSelectedFileTypeRecognitionDone] = useState<boolean>(false);

    useEffect(() => {
        if (selectedFile && !selectedFileType && !selectedFileTypeRecognitionDone) {
            const fileTypeByFileName = FileType.findFileTypeByFileName(selectedFile?.name);
            setSelectedFileTypeRecognitionDone(true);
            setSelectedFileType(fileTypeByFileName || undefined);
        }
    }, [props, selectedFile, selectedFileType, selectedFileTypeRecognitionDone]);

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(_transl(ImportTranslationKey.CHOOSE_FILE_TITLE), <CloudUploadIcon/>, _transl(ImportTranslationKey.CHOOSE_FILE_AND_TYPE_ICON), false,
                () => renderSelectFileStep(), () => canProceedFromSelectFileStep())
        ];
    }

    function renderSelectFileStep(): JSX.Element {
        const uploadPanelContentGetter = selectedFile != null ? () => createFileTypeSelect() : undefined;

        return <UploadFilePanel uploadAttachment={file => setSelectedFile(file)}
                                uploadPanelContent={uploadPanelContentGetter}/>
    }

    function createFileTypeSelect(): JSX.Element {
        const nonSelectedFileType = " ";

        return <React.Fragment>
            <div className={classes.uploadContentFileName}>{selectedFile?.name}</div>
            <div>
                <span className={classes.uploadContentFileTypeLabel}>{_transl(ImportTranslationKey.CHOOSE_FILE_TYPE)}</span>
                <span>
                    <Select
                        label={_transl(ImportTranslationKey.TYPE)}
                        id="file-type"
                        value={selectedFileType?.format || nonSelectedFileType}
                        style={{minWidth: "12em"}}
                        onChange={event => setSelectedFileType(FileType.findFileTypeByFormat(event.target.value as FileFormat))}
                    >
                        <MenuItem value={nonSelectedFileType}></MenuItem>
                        {FileType.values().map((fileType) =>
                            <MenuItem value={fileType.format}>{_transl(fileType.label)}</MenuItem>)}
                    </Select>
                </span>
            </div>
        </React.Fragment>
    }

    function canProceedFromSelectFileStep(): boolean {
        return selectedFileType != null && selectedFile != null;
    }

    function doImport(): Promise<any> {
        const metadata: IRelationshipsMatrixMetadataDto = {
            format: selectedFileType?.format as FileFormat,
        }
        return Api.imports.importRelationshipsMatrix(selectedFile as File, metadata).toPromise();
    }

    function translateErrorToMessage(error: AjaxError): string | undefined {
        if (error instanceof ValidationError) {
            const validationError = error as ValidationError;
            const errorMessageKey = IMPORT_ERROR_CODE_TO_MESSAGE_MAP.get(validationError.error.code);
            var translatedMessage;
            if (validationError.error.arguments) {
                translatedMessage = errorMessageKey ? _transl(errorMessageKey, validationError.error.arguments) : undefined;
            } else {
                translatedMessage = errorMessageKey ? _transl(errorMessageKey) : undefined;
            }
            return translatedMessage;
        }
        return undefined;
    }

    return (
        <Wizard steps={getSteps()}
                lastStepLabel={_transl(ImportTranslationKey.LAST_STEP_LABEL)}
                lastStepButtonLabel={_transl(ImportTranslationKey.LAST_STEP_BUTTON_LABEL)}
                lastStepAction={() => doImport()}
                lastStepActionInProgressText={_transl(ImportTranslationKey.LAST_STEP_ACTION_IN_PROGRESS)}
                lastStepActionSuccessfulText={_transl(ImportTranslationKey.LAST_STEP_ACTION_SUCCESSFUL)}
                lastStepActionFailedText={_transl(ImportTranslationKey.LAST_STEP_FAILED)}
                cancelWizard={props.resetImport}
                wizardGridMinHeight={"25em"}
                translateErrorToMessage={translateErrorToMessage}
        />
    );

}
