import {_transl} from "../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import Button from "@mui/material/Button";
import React from "react";


interface Props {
    onClick: (event?: any) => void;
}

export function OkButton(props: Props) {

    const {onClick} = props;

    return (
        <Button
            color={"primary"}
            variant={"contained"}
            autoFocus={true}
            onClick={onClick}
        >
            {_transl(CommonTranslation.OK)}
        </Button>
    )
}


