import React, {useState} from "react";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import {Divider} from "@mui/material";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import {CancelButton} from "../../../../../components/button/CancelButton";
import {Button} from "../../../../../components/button/Button";
import {ProjectDto, TaskDto, TaskFilterDto} from "@dain/tracker-client";
import TrackerTasksPickGrid from "./TrackerTasksPickGrid";
import TrackerService from "./TrackerService";
import AssignExistingTaskFilter from "./AssignExistingTaskFilter";
import Grid from "../../../../../components/dialogs/Grid";
import {CommonTranslation} from "../../CommonTranslation";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {TrackerTranslationKey} from "./TrackerTranslationKey";
import Snackbar from "../../snackbar/Snackbar";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";

export interface AssignExistingTaskDialogProps {
    onClose: () => void
    onTaskPicked: (task: TaskDto) => void,
    trackerService: TrackerService
}

export default function AssignExistingTaskDialog({
    onClose,
    onTaskPicked,
    trackerService
}: AssignExistingTaskDialogProps) {

    const [selectedTask, setSelectedTask] = useState<TaskDto>();
    const [filteredTasks, setFilteredTasks] = useState<TaskDto[]>([]);

    function handleOnSelectButtonClicked(): void {
        onTaskPicked(selectedTask!);
    }

    function handleOnSelectedTaskChanged(task: TaskDto) {
        setSelectedTask(task);
    }

    function handleOnSearchClicked(name: string, projects: ProjectDto[]) {
        const filter: TaskFilterDto = {
            captionLike: name,
            projectIdents: projects.filter(project => project.code !== undefined)
                    .map(project => project.code) as string[]
        };

        trackerService.api.taskFindByFilterUsingPOST({taskFilterDto: filter})
                .then((response => {
                    setFilteredTasks(response.resultList as TaskDto[]);
                }))
                .catch(error => {
                    setFilteredTasks([]);
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA));
                });
    }

    return (
            <Dialog
                    open={true}
                    aria-labelledby="assign-existing-task-dialog-title"
                    onClose={onClose}
                    maxWidth={"xl"}
            >
                <DialogTitle id="assign-existing-task-dialog-title"
                             title={_transl(TrackerTranslationKey.CHOOSE_TASK)}
                             onDialogClosed={onClose}
                />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <AssignExistingTaskFilter
                                    trackerService={trackerService}
                                    onSearchClicked={handleOnSearchClicked}
                            />
                        </Grid>
                        <Divider/>
                        <Grid item xs={12} sm={12}>
                            <TrackerTasksPickGrid
                                    tasks={filteredTasks}
                                    onSelectionChange={handleOnSelectedTaskChanged}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                            onClick={handleOnSelectButtonClicked}
                            label={_transl(CommonTranslation.CHOOSE)}
                            disabled={!selectedTask}
                            color={"primary"}
                            variant={"contained"}
                            autoFocus={true}
                    />
                    <CancelButton onClick={onClose}/>
                </DialogActions>
            </Dialog>
    );

}
