import React, {useEffect} from "react";
import {IApplicationState} from "../../../../store/Store";
import {useDispatch, useSelector} from "react-redux";
import {
    FetchableResourceType,
    FetchStatusType,
    getResourceFetchResetResourceAction
} from "../../../../store/common/FetchableResource";
import {getGridPersistentStateResetAction, PersistentStateId} from "../../../../store/common/Grid";
import {IFilter} from "../../../../store/diagrams/Diagrams";
import {GridDef} from "./RenderMode";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

interface DiagramsPickGridProps {
    onSelectionChanged: (rowIds: Array<string>, rows: Array<DiagramInfoDto>) => void,
    isMultiSelection: boolean,
}

export function createResourceId(filter: IFilter) {
    return JSON.stringify(filter || {});
}


export default function DiagramsPickGrid(props: DiagramsPickGridProps) {

    const fetchStatus = useSelector((state: IApplicationState) => state.pages.diagrams.dialogGrid.fetchStatus.status);
    const rows = useSelector((state: IApplicationState) => state.pages.diagrams.dialogGrid.resource);
    const filter = useSelector((state: IApplicationState) => state.pages.diagrams.dialogFilter.submittedFilter.lastFilter);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(getGridPersistentStateResetAction(PersistentStateId.DIAGRAMS_PAGE_DIALOGGRID));
            dispatch(getResourceFetchResetResourceAction(FetchableResourceType.DIAGRAMS_PAGE_DIALOGGRID));
        }
    }, [dispatch]);

    const { onSelectionChanged, isMultiSelection } = props;

    return (
        <React.Fragment>
            <div>
                <ExtGridWrapper
                    columns={GridDef.getGridColDef()}
                    rows={rows}
                    rowCount={rows.length}
                    getRowId={row => row.identifier}
                    bulkActionsOpenedPermanently={isMultiSelection}
                    onSelectionChanged={(rowIds, rows) => onSelectionChanged(rowIds as Array<string>, rows as Array<DiagramInfoDto>)}
                    loading={fetchStatus === FetchStatusType.STARTED}
                    peristentStateId={PersistentStateId.DIAGRAMS_PAGE_DIALOGGRID}
                    resourceId={createResourceId(filter)}
                />
            </div>
        </React.Fragment>
    )

}
