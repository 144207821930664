import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React from "react";
import {IUploadableResourceState} from "../../../store/common/UploadableResource";
import Divider from "@mui/material/Divider";
import FileUtils from "../../../common/FileUtils";
import IconResolver from "../../../common/IconResolver";
import LinearProgressWithLabel from "../../progress/LinearProgressWithLabel";

const itemPadding = 3;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        item: {
            display: "flex",
            flexDirection: "column",
            paddingLeft: 0,
            padding: theme.spacing(itemPadding),
        },
        itemInfo: {
            display: "flex",
            alignItems: "center",
        },
        itemProgress: {

        },
        icon: {
            fontSize: "2.5em",
            marginRight: theme.spacing(1),
        },
        fileName: {
            fontSize: "1.2em",
            marginRight: theme.spacing(2),
        }
    })
);

interface Props {
    item: IUploadableResourceState,
}

export default function ProgressingUpload({item}: Props) {

    const classes = useStyles();

    const file = item.resource as File;

    return <div className={classes.root}>
        <div className={classes.item}>
            <div className={classes.itemInfo}>
                    <span>
                        {IconResolver.resolveIcon(file.name, file.type, {className: classes.icon})}
                    </span>
                <span className={classes.fileName}>
                        {item.resource?.name}
                    </span>
                <span>
                        {FileUtils.createFileSizeLabel(file.size)}
                    </span>
            </div>
            <div className={classes.itemProgress}>
                <LinearProgressWithLabel value={item.uploadStatus.progress} />
            </div>
        </div>
        <Divider />
    </div>
}
