import React, {useEffect, useState} from "react";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogActions from "../../../../components/dialogs/DialogActions";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import TextField from "../../../../components/fields/textfield/TextField";
import Grid from "../../../../components/dialogs/Grid";
import Api from "../../../../common/Api";
import {IApplicationState} from "../../../../store/Store";
import {useSelector} from "react-redux";
import {IElementTypeDto} from "../../../../common/apis/Elements";
import SingleselectElementTypesComboBox from "../../../../components/fields/SingleselectElementTypesComboBox";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "./ElementTranslationKey";
import MultiselectComboBox from "../../../../components/fields/MultiselectComboBox";
import {DiagramTranslationKey} from "../diagrams/DiagramTranslationKey";
import {CollectionDto} from "../../../../common/apis/collection/CollectionDto";
import {CommonTranslation} from "../CommonTranslation";
import Snackbar from "../snackbar/Snackbar";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import stereotypeService from "../../../../common/apis/StereotypeService";
import SingleselectComboBox from "../../../../components/fields/SingleselectComboBox";
import {ElementCreateDto} from "../../../../common/apis/element/ElementCreateDto";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {StereotypeDto} from "../../../../common/apis/stereotype/StereotypeDto";


interface CreateElementDialogProps {
    open: boolean;
    message: string;
    onCreated: (element: ElementDto) => void;
    onClosed: () => void;
}

export default function CreateElementDialog(props: CreateElementDialogProps) {

    const {open, message, onCreated, onClosed} = props;

    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [type, setType] = useState<IElementTypeDto>();
    const [stereotype, setStereotype] = useState<StereotypeDto>();
    const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>();
    const [typeErrorMessage, setTypeErrorMessage] = useState<string | undefined>();
    const [collectionErrorMessage, setCollectionErrorMessage] = useState<string | undefined>();
    const [selectedCollections, setSelectedCollections] = useState<CollectionDto[]>([]);

    const collections = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);
    const typeOptions = useSelector((state: IApplicationState) => state.pages.common.options.types.resource);
    const user = useSelector((state: IApplicationState) => state.user.userData);
    const isUserOperator = user?.role === UserRoleType.ROLE_OPERATOR;

    const [stereotypes, setStereotypes] = useState<StereotypeDto[]>();

    useEffect(() => {
        stereotypeService.findAll()
            .then((stereotypes) => {
                setStereotypes(stereotypes);
            });
    }, []);

    function changeType(type: IElementTypeDto) {
        setType(type);
        setStereotype(undefined);
    }

    function saveChanges() {
        const isValid = validate();

        if (isValid) {
            const collectionCodes = selectedCollections.map((collection) => collection.code);
            const elementCreate: ElementCreateDto = {
                name: name,
                type: type!.name,
                stereotype: stereotype?.name,
                collectionCodes: collectionCodes,
                description: description,

            };
            Api.elements.createElement(elementCreate)
                .then((element) => {
                    Snackbar.success(_transl(ElementTranslationKey.DIALOG_CREATE_ELEMENT_SUCCEEDED));
                    onCreated(element);
                    onDialogClosed();
                })
                .catch((err) => Snackbar.error(_transl(ElementTranslationKey.DIALOG_CREATE_ELEMENT_NOT_SUCCEEDED), err));
        }
    }

    function validate() {
        const isNameError = checkIfIsNameError();
        const isTypeError = checkIfIsTypeError();
        const isCollectionError = checkIfIsCollectionError();

        setNameErrorMessage(isNameError ? _transl(CommonTranslation.FILL_OUT_THIS_FIELD) : undefined);
        setTypeErrorMessage(isTypeError ? _transl(CommonTranslation.FILL_OUT_ITEM_FROM_LIST) : undefined);
        setCollectionErrorMessage(isCollectionError ? _transl(CommonTranslation.FILL_OUT_AT_LEAST_ONE) : undefined);

        return !(isNameError || isTypeError || isCollectionError);
    }

    function checkIfIsNameError() {
        return !name;
    }

    function checkIfIsTypeError() {
        return !type;
    }

    function checkIfIsCollectionError() {
        if (!isUserOperator) {
            return false;
        }
        return selectedCollections.length === 0;
    }

    function resetToDefaultValues() {
        setName("");
        setType(undefined);
        setStereotype(undefined);
        setSelectedCollections([]);
        setDescription("")
    }

    function resetErrorState() {
        setNameErrorMessage(undefined);
        setTypeErrorMessage(undefined);
        setCollectionErrorMessage(undefined);
    }

    function onDialogClosed() {
        onClosed();
        resetToDefaultValues();
        resetErrorState();
    }

    const stereotypeOptions: StereotypeDto[] = [];
    if (type && stereotypes) {
        stereotypeOptions.push(...stereotypes.filter(stereotype => stereotype.elementType === type.name));
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle title={message}
                         id="scroll-dialog-title"
                         onDialogClosed={onDialogClosed}
            />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="create-element-dialog-name"
                                   label={_transl(ElementTranslationKey.DIALOG_CREATE_ELEMENT_FIELD_NAME)}
                                   required={true}
                                   errorMessage={nameErrorMessage}
                                   onChange={setName}
                                   value={name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleselectElementTypesComboBox options={typeOptions}
                                                          selectedValue={type}
                                                          id={"create-element-dialog-type"}
                                                          label={_transl(ElementTranslationKey.DIALOG_CREATE_ELEMENT_FIELD_TYPE)}
                                                          handleOnChange={changeType}
                                                          errorMessage={typeErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleselectComboBox options={stereotypeOptions}
                                              selectedValue={stereotype}
                                              getRenderLabel={(stereotype) => stereotype.name}
                                              id={"create-element-dialog-stereotype"}
                                              label={_transl(ElementTranslationKey.GRID_HEADER_STEREOTYPE)}
                                              handleOnChange={setStereotype}
                                              disabled={stereotypeOptions.length === 0}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultiselectComboBox
                            label={_transl(DiagramTranslationKey.FILTER_COLLECTIONS)}
                            id="create-element-dialog-collections"
                            options={collections.filter(collection => collection.acl.canAssignObjectsToCollection)}
                            selectedValues={selectedCollections}
                            getRenderLabel={(value) => value.name}
                            required={isUserOperator}
                            errorMessage={collectionErrorMessage}
                            handleOnChange={setSelectedCollections}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="create-element-dialog-description"
                            rows={6}
                            maxRows={15}
                            label={_transl(ElementTranslationKey.DIALOG_CREATE_ELEMENT_FIELD_DESCRIPTION)}
                            multiline
                            onChange={setDescription}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={saveChanges}/>
                <CancelButton onClick={onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );
}
