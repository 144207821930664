import {IconButton, IconButtonProps, Tooltip} from "@mui/material";
import React from "react";

export interface ActionButtonProps {
    id: string;
    tooltip?: string;
    icon?: JSX.Element;
    label?: string;
    disabled?: boolean;
    className?: string;
    color?: IconButtonProps["color"];
    size?: IconButtonProps["size"];
    onClick?: (event: any) => void;
    style?: React.CSSProperties;
}

export function ActionButton(props: ActionButtonProps) {
    const onClick = props.onClick ?? (() => {});

    return (
        <Tooltip title={props.tooltip ?? ""} key={props.tooltip} placement={"top"}>
            <IconButton id={props.id}
                        className={props.className}
                        disabled={props.disabled}
                        color={props.color ?? "primary"}
                        component="span"
                        onClick={onClick}
                        size={props.size ?? "medium"}
                        style={props.style}
            >
                {props.icon}
            </IconButton>
        </Tooltip>
    );
}
