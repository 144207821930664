import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React from "react";
import {Point} from "../../../../../common/diagrameditor/util/GeometryUtils";
import * as d3 from 'd3';
import {ClickAwayListener, ListItemText, MenuItem, MenuList, Paper} from "@mui/material";
import {MAIN_PAGE_OVERLAY_ZINDEX} from "../../../../MainPage";
import clsx from "clsx";
import {NewConnectionDefinition} from "../../../../../common/diagrameditor/manager/ConnectionCreateManager";
import {forkJoin} from "rxjs";
import Api from "../../../../../common/Api";
import HiddenRelationshipReuseConfirmDialog from "./HiddenRelationshipReuseConfirmDialog";
import {DIAGRAM_EDITOR_PAGE_ID} from "../DiagramEditorDialog";
import {
    ArchiMateAccess,
    ArchiMateAccessType,
    ArchimateRelationship,
    ArchimateRelationshipType
} from "../../../../../common/archimate/ArchimateRelationship";
import {ObjectType} from "../../../../../common/apis/editor/ObjectType";
import {RelationshipDto} from "../../../../../common/apis/relationship/RelationshipDto";
import {DiagramTranslationKey} from "../DiagramTranslationKey";
import {_transl} from "../../../../../store/localization/TranslMessasge";

const DIALOG_ID = "_diagram-editor-page-connection-type-dialog__";

const styles = (theme: Theme) => createStyles({
    dialog: {
        position: "absolute",
        zIndex: MAIN_PAGE_OVERLAY_ZINDEX + 1000,
    },
    menuItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    menuItemText: {
        "& .MuiTypography-root": {
            fontSize: ".82em",
        }
    },
    subMenuItemText: {
        paddingLeft: 20,
    },
});

interface IProps extends WithStyles<typeof styles> {
    allowedRelationshipTypes: ArchimateRelationship[];
    hiddenRelationships: RelationshipDto[];
    eventPoint: Point;
    onSelect: (definition: NewConnectionDefinition, event: any) => void,
    onCancel: (event: any) => void,
}

enum DialogType {
    CONNECTION_TYPE_SELECTION,
    HIDDEN_RELATIONSHIP_REUSE_CONFIRMATION
}

interface IState {
    dialogType: DialogType,
    reusedRelationship?: RelationshipDto,
    selectedRelationshipType?: ArchimateRelationshipType,
    selectedDirected?: boolean,
    selectedAccessType?: ArchiMateAccessType,
    event?: any,
}

class ConnectionTypeSelectionDialog extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dialogType: DialogType.CONNECTION_TYPE_SELECTION,
        }
    }

    componentDidMount() {
        const {eventPoint} = this.props;
        this.positionDialog(eventPoint);
    }

    render() {
        const {classes, onCancel, allowedRelationshipTypes} = this.props;
        const {dialogType} = this.state;

        return <React.Fragment>
            {(dialogType === DialogType.CONNECTION_TYPE_SELECTION) &&
                <div id={DIALOG_ID} className={classes.dialog}>
                    <ClickAwayListener onClickAway={(event) => onCancel(event)}>
                        <Paper>
                            <MenuList dense={true}>
                                {allowedRelationshipTypes.map(relationShipType => {
                                    let element;

                                    if (relationShipType.relationshipType === ArchimateRelationshipType.ACCESS) {
                                        const elements = [];
                                        elements.push(
                                            <MenuItem className={classes.menuItem} dense={true} disabled={true} key={relationShipType.relationshipType + "-disabled"}>
                                                <ListItemText
                                                    className={classes.menuItemText}>{relationShipType.visibleName}</ListItemText>
                                            </MenuItem>
                                        )
                                        ArchiMateAccess.values().forEach(accessType => {
                                            elements.push(
                                                <MenuItem className={classes.menuItem} dense={true} onClick={event => this.onAccessTypeSelected(accessType.accessType, event)} key={relationShipType.relationshipType + accessType.accessType}>
                                                    <ListItemText
                                                        className={clsx(classes.menuItemText, classes.subMenuItemText)}>{relationShipType.visibleName} typu {accessType.accessType}</ListItemText>
                                                </MenuItem>
                                            )
                                        });

                                        element = elements
                                    } else if (relationShipType.relationshipType === ArchimateRelationshipType.ASSOCIATION) {
                                        const elements = [];
                                        elements.push(
                                            <MenuItem className={classes.menuItem} dense={true} disabled={true} key={relationShipType.relationshipType + "-disabled"}>
                                                <ListItemText
                                                    className={classes.menuItemText}>{relationShipType.visibleName}</ListItemText>
                                            </MenuItem>
                                        );
                                        elements.push(
                                            <MenuItem className={classes.menuItem} dense={true} onClick={event => this.onAssociationSelected(true, event)} key={relationShipType.relationshipType + "-directed"}>
                                                <ListItemText
                                                    className={clsx(classes.menuItemText, classes.subMenuItemText)}>
                                                    {relationShipType.visibleName + " "
                                                        + _transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_RELATION_TYPE_DIRECTED)}</ListItemText>
                                            </MenuItem>
                                        );
                                        elements.push(
                                            <MenuItem className={classes.menuItem} dense={true} onClick={event => this.onAssociationSelected(false, event)} key={relationShipType.relationshipType + "-undirected"}>
                                                <ListItemText
                                                    className={clsx(classes.menuItemText, classes.subMenuItemText)}>
                                                    {relationShipType.visibleName + " " +
                                                        _transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_RELATION_TYPE_NON_UNDIRECTED)}</ListItemText>
                                            </MenuItem>
                                        );
                                        element = elements
                                    } else {
                                        element = <MenuItem className={classes.menuItem} dense={true} onClick={event => this.onOtherSelected(relationShipType.relationshipType, event)} key={relationShipType.relationshipType}>
                                            <ListItemText
                                                className={classes.menuItemText}>{relationShipType.visibleName}</ListItemText>
                                        </MenuItem>
                                    }

                                    return element;
                                })}
                            </MenuList>
                        </Paper>
                    </ClickAwayListener>
                </div>
            }
            {(dialogType === DialogType.HIDDEN_RELATIONSHIP_REUSE_CONFIRMATION) &&
                <HiddenRelationshipReuseConfirmDialog hiddenRelationship={this.state.reusedRelationship as RelationshipDto}
                                                      onConfirm={reuse => this.fetchIdsAndCreate(reuse)}
                                                      onCancel={(event) => onCancel(event)} />
            }
        </React.Fragment>
    }

    private onAccessTypeSelected(accessType: ArchiMateAccessType, event: any) {
        const relationshipType = ArchimateRelationship[ArchimateRelationshipType.ACCESS];
        const hidden = this.props.hiddenRelationships
            .filter(relationship => (relationshipType.standardNames.indexOf(relationship.type) !== -1) && (relationship.accessType === accessType));
        this.onSelected(relationshipType.relationshipType, accessType, undefined, hidden, event);
    }

    private onAssociationSelected(directed: boolean, event: any) {
        const relationshipType = ArchimateRelationship[ArchimateRelationshipType.ASSOCIATION];
        const hidden = this.props.hiddenRelationships
            .filter(relationship => (relationshipType.standardNames.indexOf(relationship.type) !== -1) && (relationship.associationDirected === directed));
        this.onSelected(relationshipType.relationshipType, undefined, directed, hidden, event);
    }

    private onOtherSelected(type: ArchimateRelationshipType, event: any) {
        const relationshipType = ArchimateRelationship[type];
        const hidden = this.props.hiddenRelationships
            .filter(relationship => relationshipType.standardNames.indexOf(relationship.type) !== -1);
        this.onSelected(type, undefined, undefined, hidden, event);
    }

    private onSelected(type: ArchimateRelationshipType, accessType: ArchiMateAccessType | undefined, directed: boolean | undefined, hidden: RelationshipDto[], event: any) {
        const newState: IState = {
            dialogType: this.state.dialogType,
            selectedRelationshipType: type,
            selectedAccessType: accessType,
            selectedDirected: directed,
            event: event,
        }
        let callback = () => {};
        if (hidden.length > 0) {
            newState["dialogType"] = DialogType.HIDDEN_RELATIONSHIP_REUSE_CONFIRMATION;
            newState["reusedRelationship"] = hidden[0];
        } else {
            callback = () => this.fetchIdsAndCreate(false);
        }
        this.setState(newState, callback);
    }

    private fetchIdsAndCreate(reuseHiddenRelationship: boolean) {
        forkJoin([Api.editor.generateIdentifier(ObjectType.CONNECTION), Api.editor.generateIdentifier(ObjectType.RELATIONSHIP) ]).subscribe(
            (result) => {
                if (reuseHiddenRelationship) {
                    this.createConnectionWithReusedRelationship(result[0].response);
                } else {
                    this.createConnection(result[0].response, result[1].response);
                }

            },
            (error) => {this.onError(error)},
        )
    }

    private createConnectionWithReusedRelationship(connectionId: any) {
        const {reusedRelationship, event} = this.state;
        const definition: NewConnectionDefinition = {
            existingRelationship: {
                identifier: reusedRelationship?.identifier as string
            },
            connectionIdentifier: connectionId,
        }
        this.props.onSelect(definition, event);
    }

    private createConnection(connectionId: string, relationshipId?: string) {
        const {selectedRelationshipType, selectedAccessType, selectedDirected, event} = this.state;
        const definition: NewConnectionDefinition = {
            newRelationship: {
                identifier: relationshipId as string,
                type: selectedRelationshipType as ArchimateRelationshipType,
                accessType: selectedAccessType,
                directed: selectedDirected,
            },
            connectionIdentifier: connectionId,
        }
        this.props.onSelect(definition, event);
    }

    private onError(error: any) {
        const {onCancel} = this.props;
        const {event} = this.state;
        onCancel(event);
    }

    private positionDialog(eventPoint: Point) {
        let y = eventPoint.y;
        let x = eventPoint.x;
        const diagramEditorRect = (d3.select("#"+DIAGRAM_EDITOR_PAGE_ID).node() as HTMLDivElement)?.getBoundingClientRect();
        const connectionTypesDialogRect = (d3.select("#"+DIALOG_ID).node() as HTMLDivElement)?.getBoundingClientRect();
        if (diagramEditorRect && connectionTypesDialogRect) {
            if((y + connectionTypesDialogRect.height) > (diagramEditorRect.y + diagramEditorRect.height)) {
                y = y - ((y + connectionTypesDialogRect.height) - (diagramEditorRect.y + diagramEditorRect.height));
            }
        }
        d3.select("#"+DIALOG_ID)
            .style("top", y+"px")
            .style("left", x+"px");
    }
}

export default withStyles(styles, { withTheme: true })(ConnectionTypeSelectionDialog);
