import Button from "@mui/material/Button";
import {_transl} from "../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import React from "react";
import {ButtonPropTypes} from "./ButtonPropTypes";

interface Props {
    onClick: (event?: any) => void,
    size?: ButtonPropTypes.Size,
    startIcon?: React.ReactNode;
    disabled?: boolean;
}

export function SaveButton(props: Props) {

    const {onClick, size, startIcon, disabled} = props;

    return (
        <Button
            color={"primary"}
            variant={"contained"}
            autoFocus={true}
            onClick={onClick}
            size={size}
            startIcon={startIcon}
            disabled={disabled}
        >
            {_transl(CommonTranslation.SAVE)}
        </Button>
    )
}
