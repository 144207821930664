import {IDiagramNodeDto} from "../../../../apis/diagram/IDiagramNodeDto";
import PositionDirection from "./positionupdater/PositionDirection";
import isPositionUpdatable from "./positionupdater/IsPositionUpdatable";
import {ParentNodePosition} from "../ParentNodePosition";
import {PositionUpdateType} from "./positionupdater/PositionUpdate";
import NodeChildNodes from "../NodeChildNodes";

export interface Manager {
    getNodeIdToParentNodeChildNodesMap: (nodes: Array<IDiagramNodeDto>) => Map<string, NodeChildNodes>,
    createExistingNodeIdToParentNodePositionMap: (nodes: Array<IDiagramNodeDto>) => Map<string, ParentNodePosition>,
    updateNodePositions: (nodes: Array<IDiagramNodeDto>,
                          oldNodePositions: Array<PositionUpdateType>,
                          newNodePositions: Array<PositionUpdateType>,
                          direction: PositionDirection) => void;
}

export default class NodesPositionUpdater {

    private modelManager: Manager;

    constructor(modelManager: Manager) {
        this.modelManager = modelManager;
    }

    updatePosition(nodes: Array<IDiagramNodeDto>,
                   direction: PositionDirection) {
        const nodeIdToParentChildrenMap = this.modelManager.getNodeIdToParentNodeChildNodesMap(nodes);
        if (isPositionUpdatable(nodes, nodeIdToParentChildrenMap, direction)) {
            const oldNodePositions = this.createOldNodePositions(nodes);
            const newNodePositions = this.createNewNodePositions(nodes, direction, nodeIdToParentChildrenMap);
            this.modelManager.updateNodePositions(nodes, oldNodePositions, newNodePositions, direction);
        }
    }

    private createNewNodePositions(nodes: Array<IDiagramNodeDto>,
                                   direction: PositionDirection,
                                   nodeIdToParentChildrenMap: Map<string, NodeChildNodes>): Array<PositionUpdateType> {
        const newNodePositions: Array<PositionUpdateType> = [];
        for(const node of nodes) {
            const newNodePosition = this.createNewNodePosition(node, direction, nodeIdToParentChildrenMap);
            newNodePositions.push(newNodePosition);
        }
        return newNodePositions;
    }

    private createOldNodePositions(nodes: Array<IDiagramNodeDto>): Array<PositionUpdateType> {
        const nodeToParentNodePositionMap = this.modelManager.createExistingNodeIdToParentNodePositionMap(nodes);
        const oldNodePositions: Array<PositionUpdateType> = [];
        for (const node of nodes) {
            oldNodePositions.push({
                nodeId: node.identifier,
                parentNodePosition: nodeToParentNodePositionMap.get(node.identifier) as ParentNodePosition,
            });
        }
        return oldNodePositions;
    }

    private createNewNodePosition(node: IDiagramNodeDto,
                                  direction: PositionDirection,
                                  nodeIdToParentChildNodesMap: Map<string, NodeChildNodes>): PositionUpdateType {
        const parentNodeChildNodes = nodeIdToParentChildNodesMap.get(node.identifier) as NodeChildNodes;
        let parentNodeChildIndex = this.createParentNodeChildIndex(direction, parentNodeChildNodes);
        return {
            nodeId: node.identifier,
            parentNodePosition: {
                parentNodeId: parentNodeChildNodes.nodeId,
                parentNodeChildrenIndex: parentNodeChildIndex,
            }
        }
    }

    private createParentNodeChildIndex(direction: PositionDirection, parentNodeChildNodes: NodeChildNodes) {
        if (direction === PositionDirection.TO_FRONT) {
            return parentNodeChildNodes.childNodes.length - 1;
        } else {
            return 0;
        }
    }
}