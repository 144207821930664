import Utils from "./Utils";
import {ajax, AjaxError, AjaxResponse} from "rxjs/ajax";
import {from, Observable, of, throwError} from "rxjs";
import {AjaxRequest} from "rxjs/internal/observable/dom/AjaxObservable";
import Elements from "./apis/Elements";
import Labels from "./apis/label/Labels";
import diagramsApi from "./apis/DiagramsApi";
import Relationships from "./apis/Relationships";
import Imports from "./apis/Imports";
import Common from "./apis/Common";
import Exports from "./apis/Exports";
import GraphData from "./apis/GraphData";
import {Editor} from "./apis/Editor";
import {catchError, concatMap, delay, retryWhen, switchMap} from "rxjs/operators";
import {KeycloakHolder} from "../keycloak/KeycloakHolder";
import StereotypeService from "./apis/StereotypeService";
import {HTTPStatusCodeType, is} from "./util/HTTPStatusCode";
import {ValidationError} from "./ValidationError";
import ErrorDto from "./apis/ErrorDto";

const MAX_RETRY_COUNT = 10;
const RETRY_DELAY_MS = 1000;

export default class Api {

    public static elements = new Elements();
    public static labels = new Labels();
    public static diagrams = diagramsApi;
    public static relationships = new Relationships();
    public static imports = new Imports();
    public static exports = new Exports();
    public static common = new Common();
    public static graphData = new GraphData();
    public static editor = new Editor();
    public static stereotypes = StereotypeService;

    public static createAjax(request: AjaxRequest, ignoreContentType: boolean = false): Observable<AjaxResponse> {
        if (Utils.isDevelopment()) {
            request.crossDomain = true;
            request.withCredentials = true;
        }

        const keycloak = KeycloakHolder.keycloak as Keycloak.KeycloakInstance;
        return from(keycloak.updateToken(10))
            .pipe(
                switchMap((refreshed: boolean) => {
                    return of(keycloak.token);
                }),
                switchMap(token => {
                    if (token) {
                        RequestHeaderAppender.appendBearerTokenHeader(request, token);
                    }
                    if (!ignoreContentType) {
                        RequestHeaderAppender.appendDefaultContentTypeIfNotSet(request);
                    }
                    return ajax(request)
                }),
                retryWhen(notifier =>
                    notifier.pipe(
                        concatMap((e, i) => {
                            if (this.shouldThrowError(i, e)) {
                                return throwError(e);
                            }
                            return of(e).pipe(delay(RETRY_DELAY_MS));
                        })
                    )
                ),
                catchError(err => {
                    if (err instanceof AjaxError && [400, 409].includes(err.status) && err.response && err.response.code) {
                        throw new ValidationError(err.response as ErrorDto);
                    } else {
                        throw err;
                    }
                })
            );
    }

    private static shouldThrowError(i: number, e: any) {
        return i >= MAX_RETRY_COUNT || this.isNotTryAgainTypeOfError(e);
    }

    private static isNotTryAgainTypeOfError(e: any) {
        return !(this.isConnectionFailedError(e)
            || is(e.status, HTTPStatusCodeType.BAD_GATEWAY)
            || is(e.status, HTTPStatusCodeType.SERVICE_UNAVAILABLE)
            || is(e.status, HTTPStatusCodeType.GATEWAY_TIMEOUT));
    }

    private static isConnectionFailedError(e: any) {
        return e.status === 0;
    }

    public static createAjaxWithoutToken(request: AjaxRequest, ignoreContentType?: boolean): Observable<AjaxResponse> {
        if (Utils.isDevelopment()) {
            request.crossDomain = true;
            request.withCredentials = true;
        }

        if (!ignoreContentType) {
            RequestHeaderAppender.appendDefaultContentTypeIfNotSet(request);
        }

        return ajax(request);
    }

    public static parseUsername(token: string): string {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload).preferred_username;
    };

    public static replaceUrlParams(url: string, replacements: { [p: string]: string }) {
        let replacedUrl = url;
        Object.keys(replacements).forEach(key => replacedUrl = replacedUrl.replace(key, replacements[key]));
        return replacedUrl;
    }
}

export class RequestHeaderAppender {
    public static readonly DEFAULT_CONTENT_TYPE = "application/json";

    public static appendBearerTokenHeader(request: AjaxRequest, token: string) {
        const headers: any = request.headers || {};
        headers["Authorization"] = "Bearer " + token;
        request.headers = headers;
    }

    public static appendDefaultContentTypeIfNotSet(request: AjaxRequest) {
        const headers: any = request.headers || {};
        if (!headers["Content-Type"]) {
            headers["Content-Type"] = RequestHeaderAppender.DEFAULT_CONTENT_TYPE;
        }
        request.headers = headers;
    }

}
