import * as d3 from "d3";
import EventManager from "./EventManager";
import {EventType} from "../event/Event";

export default class SvgElementManager {

    private eventManager: EventManager;
    private svg?: SVGSVGElement;
    private svgElement?: d3.Selection<SVGSVGElement, unknown, null, undefined>;
    private defsElement?: d3.Selection<SVGDefsElement, unknown, any, any>;
    private chartGroup?: d3.Selection<SVGGElement, unknown, null, undefined>;
    private lastViewBox?: string;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
    }

    public init(svgRef: SVGSVGElement, parentRect: DOMRect) {
        this.svg = svgRef;

        this.clearSvgElement();
        // create svg element
        this.createSvgElementSelection(parentRect);
        // init listeners
        this.initSvgElementListeners();
        // create chart group that will be used as main container (that is recreated on updates)
        this.createChartGroup();
        // create defs element within chart group
        this.createDefsElement();
    }

    getSvg() {
        return this.svg;
    }

    public getSvgElement() {
        return this.svgElement;
    }

    public getChartGroup() {
        return this.chartGroup;
    }

    public getDefsElement() {
        return this.defsElement;
    }

    private clearSvgElement() {
        if (this.svg) {
            d3.select(this.svg).select("g").remove();
        }
    }

    private createSvgElementSelection(parentRect: DOMRect) {
        if (this.svg) {
            this.svgElement = d3.select(this.svg)
                .attr("preserveAspectRatio", "xMidYMid meet")
                .attr("pointer-events", "all")
                .attr("cursor", "move");

            if (!this.lastViewBox) {
                this.lastViewBox = (-parentRect.width / 2) + "," + (-parentRect.height / 2) + "," + parentRect.width + "," + parentRect.height;
            }

            this.svgElement.attr("viewBox", this.lastViewBox);
        }
    }

    private createChartGroup() {
        if (this.svgElement) {
            this.chartGroup = this.svgElement.append("g")
        }
    }

    private createDefsElement() {
        if (this.chartGroup) {
            this.defsElement = this.chartGroup.append("defs");
        }
    }

    private initSvgElementListeners() {
        if (this.svgElement) {
            this.svgElement
                .on("mouseenter", e => this.eventManager.publishChartEvent({type: EventType.CHART_MOUSE_ENTER, event: e}))
                .on("mouseleave", e => this.eventManager.publishChartEvent({type: EventType.CHART_MOUSE_LEAVE, event: e}))
                .on("click", e => this.eventManager.publishChartEvent({type: EventType.CHART_MOUSE_CLICKED, event: e}))
                .on("contextmenu", e => e.preventDefault());
        }
    }
}