import Constants from "../../../../../common/Constants";
import Api from "../../../../../common/Api";
import {CollectionDto} from "../../../../../common/apis/collection/CollectionDto";
import {map} from "rxjs/operators";
import {CollectionCreateDto} from "../../../../../common/apis/collection/CollectionCreateDto";


const COLLECTION_CODE_PARAM = ":code";
const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/collections";
const PUT_UPDATE_COLLECTION_URL: string = `${ENDPOINT_URL}/${COLLECTION_CODE_PARAM}`;

export interface CollectionsService {
    getAll(): Promise<CollectionDto[]>

    deleteCollection(code: string): Promise<any>

    updateCollection(code: string, name: string, description: string): Promise<any>

    createCollection(collectionCreate: CollectionCreateDto): Promise<any>;
}

class RxJsCollectionsService implements CollectionsService {

    async getAll(): Promise<CollectionDto[]> {
        return Api.createAjax({
            url: ENDPOINT_URL + "/",
            method: "GET",
        }).pipe(
            map(response => response.response as CollectionDto[])
        ).toPromise();
    }

    deleteCollection(code: string): Promise<any> {
        return Api.createAjax({
            url: PUT_UPDATE_COLLECTION_URL.replace(COLLECTION_CODE_PARAM, code),
            method: "DELETE",
        }).toPromise()
    }

    updateCollection(code: string, name: string, description: string): Promise<any> {
        return Api.createAjax({
            url: PUT_UPDATE_COLLECTION_URL.replace(COLLECTION_CODE_PARAM, code),
            method: "PUT",
            body: {
                name: name,
                description: description,
            }
        }).toPromise();
    }

    createCollection(collectionCreate: CollectionCreateDto): Promise<any> {
        return Api.createAjax({
            url: ENDPOINT_URL + "/",
            method: "POST",
            body: {
                code: collectionCreate.code,
                name: collectionCreate.name,
                description: collectionCreate.description,
            }
        }).toPromise();
    }
}

export default new RxJsCollectionsService() as CollectionsService;
