import React, {useState} from "react";
import Grid from "../../../../../../../../components/dialogs/Grid";
import TextField from "../../../../../../../../components/fields/textfield/TextField";
import {Pending, RemoveCircle} from "@mui/icons-material";
import {_transl} from "../../../../../../../../store/localization/TranslMessasge";
import {HierarchyTreeTranslationKey} from "../../../../HierarchyTreeTranslationKey";
import {ActionButton} from "../../../../../../../../components/button/ActionButton";
import {ValidationResult} from "../../../../../../../../common/validation/ValidationResult";
import {QueriesSubformValidator} from "./QueriesSubformValidator";
import {QueryTextPrefillDialog} from "./QueryTextPrefillDialog";

export interface GraphQueryTreeLevelDefinition {
    name: string;
    queryText: string;
}

export interface QueryLevelProps {
    levelIndex: number;
    levelDefinition: GraphQueryTreeLevelDefinition;
    onLevelRemoved: (levelIndex: number) => void;
    onLevelUpdated: (levelIndex: number, queryLevelData: GraphQueryTreeLevelDefinition) => void;
    validationResult?: ValidationResult;
}

export function QueryLevel({levelIndex, onLevelUpdated, onLevelRemoved, levelDefinition, validationResult}: QueryLevelProps) {

    function findErrorForField(fieldId: string, validationResult?: ValidationResult) {
        if (validationResult) {
            const errors = validationResult.errors
                .filter(error => error.fieldId === `${levelIndex}.${fieldId}`);
            return errors.length > 0 ? errors[0] : undefined;
        }
        return undefined;
    }

    const nameError = findErrorForField(QueriesSubformValidator.NAME_FIELD_ID, validationResult);
    const queryTextError = findErrorForField(QueriesSubformValidator.QUERY_TEXT_FIELD_ID, validationResult);


    const [prefillDialogOpened, setPrefillDialogOpened] = useState<boolean>(false);

    return (
        <>
            {prefillDialogOpened &&
                <QueryTextPrefillDialog
                    onClose={() => setPrefillDialogOpened(false)}
                    onPrefill={(queryText: string) => onLevelUpdated(levelIndex, {...levelDefinition, queryText})}
                />

            }

            <Grid container spacing={2}>
                <Grid item xs sm={4}>
                    <Grid container spacing={2} noStyle>
                        <Grid item xs={"auto"}>
                            <ActionButton id={"query-level-delete-action-button"}
                                          color={"error"}
                                          onClick={() => onLevelRemoved(levelIndex)}
                                          style={{paddingLeft: 0, paddingRight: 0}}
                                          disabled={levelIndex === 0}
                                          tooltip={_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_DELETE)}
                                          icon={<RemoveCircle/>}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                label={(levelIndex + 1) + ". " + _transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL)}
                                value={levelDefinition.name}
                                required={true}
                                onChange={(name) => onLevelUpdated(levelIndex, {...levelDefinition, name})}
                                errorMessage={nameError ? _transl(nameError.errorMessage) : undefined}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm>
                    <TextField
                        label={_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_QUERY)}
                        multiline
                        rows={4}
                        value={levelDefinition.queryText}
                        required={true}
                        onChange={(queryText) => onLevelUpdated(levelIndex, {...levelDefinition, queryText})}
                        errorMessage={queryTextError ? _transl(queryTextError.errorMessage) : undefined}
                    />
                </Grid>
                <Grid item xs={12} sm={"auto"} textAlign={"right"}>
                    <ActionButton id={"query-level-prefill-action-button"}
                                  color={"default"}
                                  onClick={() => {
                                      setPrefillDialogOpened(true)
                                  }}
                                  style={{paddingLeft: 0, paddingRight: 0}}
                                  icon={<Pending/>}
                                  tooltip={_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_QUERY_PREFILL)}
                    />
                </Grid>
            </Grid>
        </>
    );
}
