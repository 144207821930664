import {
    createFetchableResourceEpic,
    createFetchableResourceReducer,
    FetchableResourceType,
} from "./FetchableResource";
import Api from "../../common/Api";
import {CollectionDto} from "../../common/apis/collection/CollectionDto";
import {IElementStateDto, IElementTypeDto} from "../../common/apis/Elements";
import {UserDto} from "../../common/apis/user/UserDto";
import userService from "../../common/apis/UserService";
import viewpointService, {ViewpointDto} from "../../common/apis/ViewpointService";
import {ILabelDto} from "../../common/apis/label/ILabelDto";
import collectionsService from "../../pages/main/content/collections/service/CollectionsService";
import {from} from "rxjs";


// Collection options

const collectionType = FetchableResourceType.COMMON_COLLECTION_OPTIONS;

export const collectionOptionsReducer = createFetchableResourceReducer<CollectionDto[]>(collectionType, []);
export const collectionOptionsEpic = createFetchableResourceEpic(collectionType, (action) => from(collectionsService.getAll()));


// Label options

const labelType = FetchableResourceType.COMMON_LABEL_OPTIONS;

export const labelOptionsReducer = createFetchableResourceReducer<ILabelDto[]>(labelType, []);
export const labelOptionsEpic = createFetchableResourceEpic(labelType, (action) => Api.labels.getAll());

// Viewpoint options

const viewpointType = FetchableResourceType.COMMON_VIEWPOINT_OPTIONS;

export const viewpointOptionsReducer = createFetchableResourceReducer<ViewpointDto[]>(viewpointType, []);
export const viewpointOptionsEpic = createFetchableResourceEpic(viewpointType, (action) => viewpointService.getAll());


// State options

const stateType = FetchableResourceType.COMMON_STATE_OPTIONS;

export const stateOptionsReducer = createFetchableResourceReducer<IElementStateDto[]>(stateType, []);
export const stateOptionsEpic = createFetchableResourceEpic(stateType, (action) => Api.elements.getStates());


// Type options

const typeType = FetchableResourceType.COMMON_TYPE_OPTIONS;

export const typeOptionsReducer = createFetchableResourceReducer<IElementTypeDto[]>(typeType, []);
export const typeOptionsEpic = createFetchableResourceEpic(typeType, (action) => Api.elements.getTypes());


// Type authors

const authorType = FetchableResourceType.COMMON_AUTHOR_OPTIONS;

export const authorOptionsReducer = createFetchableResourceReducer<UserDto[]>(authorType, []);
export const authorOptionsEpic = createFetchableResourceEpic(authorType, (action) => userService.getAllUsersObservable());
