import React, {useContext, useEffect, useState} from "react";
import {IPropertyDefinition} from "../../../common/apis/CommonTypes";
import {_transl} from "../../../store/localization/TranslMessasge";
import Dialog from "../../dialogs/Dialog";
import DialogTitle from "../../dialogs/DialogTitle";
import DialogContent from "../../dialogs/DialogContent";

import SelectField from "../SelectField";
import DialogActions from "../../dialogs/DialogActions";
import {SaveButton} from "../../button/SaveButton";
import {CancelButton} from "../../button/CancelButton";
import TextField from "../textfield/TextField";
import Grid from "../../dialogs/Grid";
import {CommonTranslation} from "../../../pages/main/content/CommonTranslation";
import {PropertyFilterDto} from "../../../store/elements/Elements";
import {PropertyTranslation} from "./PropertyTranslation";
import ElementPropertyDefinitionContext from "../../../common/ElementPropertyDefinitionContext";
import DiagramPropertyDefinitionContext from "../../../common/DiagramPropertyDefinitionContext";

interface PropertyFilterDialogProps {
    open: boolean;
    type: "elements" | "diagrams";
    propertyFilterDto: PropertyFilterDto | undefined;
    onSave: (name: string, value: string) => void;
    onClosed: () => void;
}

export default function PropertyFilterDialog(props: PropertyFilterDialogProps) {
    const {open, type, propertyFilterDto, onSave, onClosed} = props;

    const elementPropertyDefinitionContext = useContext(ElementPropertyDefinitionContext);
    const diagramPropertyDefinitionContext = useContext(DiagramPropertyDefinitionContext);

    const [name, setName] = useState<string>("");
    const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>();
    const [value, setValue] = useState<string>("");

    const propertyDefinitions = type === "elements" ? elementPropertyDefinitionContext.propertyDefinitions : diagramPropertyDefinitionContext.propertyDefinitions;

    useEffect(() => {
        if (open) {
            if (propertyFilterDto) {
                setName(propertyFilterDto.name);
                setValue(propertyFilterDto.value);
            } else {
                setName("");
                setValue("");
            }
        }
    }, [open, propertyFilterDto]);

    function save() {
        if (isInputValid()) {
            onSave(name, value);
            close();
        }
    }

    function isInputValid() {
        if (!name) {
            setNameErrorMessage(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            return false;
        }
        return true;
    }

    function close() {
        setNameErrorMessage(undefined);
        onClosed();
    }

    function updateName(newValue: IPropertyDefinition | string) {
        setNameErrorMessage(undefined);
        setName(typeof newValue === "string" ? newValue : (newValue as IPropertyDefinition).name);
    }

    return (
        <React.Fragment>
            {propertyDefinitions &&
                <Dialog open={open}>
                    <DialogTitle title={_transl(PropertyTranslation.TITLE)} onDialogClosed={close}/>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <SelectField
                                    id="name-field"
                                    label={_transl(CommonTranslation.TITLE)}
                                    value={name}
                                    required={true}
                                    freeSolo={false}
                                    errorMessage={nameErrorMessage}
                                    options={propertyDefinitions}
                                    onChange={(newValue: IPropertyDefinition | string) => updateName(newValue)}
                                    getOptionLabel={option => (option as IPropertyDefinition).name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="value-field"
                                           label={_transl(CommonTranslation.VALUE)}
                                           value={value}
                                           onChange={(value) => setValue(value)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton onClick={(e) => save()}/>
                        <CancelButton onClick={() => close()}/>
                    </DialogActions>
                </Dialog>}
        </React.Fragment>
    );
}
