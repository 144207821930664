import TextField from "../../../../components/fields/textfield/TextField";

export interface CommentCommentEditorProps {
    value?: string;
    onValueChanged: (value: string) => void;
    placeholder? : string;
}

export default function ChatCommentEditor({value, onValueChanged, placeholder}: CommentCommentEditorProps) {
    const text = value ?? "";
    return (
        <TextField placeholder={placeholder} multiline maxRows={5} value={text} fullWidth onChange={onValueChanged} />
    );
}