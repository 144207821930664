import {MetamodelValidationReportDto} from "../../../../common/apis/MetamodelService";
import {ViewpointService} from "../../../../common/apis/ViewpointService";
import {ModelProvider} from "../../../../common/diagrameditor/api/IDiagramEditorApi";
import EventManager from "../../../../common/event/EventManager";
import {ValidationEventType} from "../ValidationEvents";

export class ViewpointValidationController {

    constructor(private readonly viewpointService: ViewpointService,
                private readonly eventManager?: EventManager,
                private readonly modelProvider?: ModelProvider) {
    }

    validateDiagram() : Promise<MetamodelValidationReportDto> {
        if (!this.modelProvider) {
            throw new Error("Diagram editor not provided.");
        }
        const model = this.modelProvider!.getModel();
        const viewpointId = model.diagrams[0].diagramInfo.viewpoint;
        if (!viewpointId) {
            throw new Error("No viewpoint is defined for a diagram.");
        }
        return this.viewpointService.validate(viewpointId, model);
    }

    publishHighlightRequestEvent(report: MetamodelValidationReportDto) {
        if (!this.eventManager) {
            throw new Error("Event manager not provided.");
        }
        this.eventManager.publishEvent({
            type: ValidationEventType.HIGHLIGHT_REPORTED_OBJECTS_REQUEST,
            report: report
        });
    }

    publishUnhighlightRequestEvent() {
        if (!this.eventManager) {
            throw new Error("Event manager not provided.");
        }
        this.eventManager.publishEvent({
            type: ValidationEventType.UNHIGHLIGHT_REPORTED_OBJECTS_REQUEST
        });
    }
}