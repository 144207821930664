import {Grid, Tooltip} from "@mui/material";
import {ChatCommentDto} from "../../../../common/apis/chat/ChatService";
import UserFormatter from "../users/UserFormatter";
import {CommonTranslation} from "../CommonTranslation";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DeleteOutlined, Edit} from "@mui/icons-material";
import {ActionButton} from "../../../../components/button/ActionButton";
import {TimeAgoFormatter} from "../../../../common/TimeAgoFormatter";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import LocaleContext, {Locale} from "../../../../common/LocaleContext";
import {useContext} from "react";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        author: {
            fontWeight: "bold"
        },
        timeAgo: {
            color: "gray"
        }
    })
);

export interface CommentHeaderProps {
    comment: ChatCommentDto;
    onDelete: (comment: ChatCommentDto) => void;
    onEdit: (comment: ChatCommentDto) => void;
    hideDeleteButton?: boolean;
}

export default function ChatCommentHeader({comment, onDelete, onEdit, hideDeleteButton}: CommentHeaderProps) {
    const {language, locale} = useContext<Locale>(LocaleContext);
    const timeAgoFormatter = new TimeAgoFormatter(language);

    const authorName = UserFormatter.formatFullName(comment.user.firstName, comment.user.lastName);
    const timeAgo = timeAgoFormatter.formatAsTimeAgo(new Date(comment.created));

    const classes = useStyles();

    return (
        <>
            <Grid container spacing={1} alignItems={"center"}>
                <Grid item>
                    <span className={classes.author}>{authorName}</span>
                </Grid>
                <Grid item>
                    <Tooltip title={new Date(comment.created).toLocaleString([locale])} placement={"top"}>
                        <span className={classes.timeAgo}>{timeAgo}</span>
                    </Tooltip>
                </Grid>
                <Grid item xs>
                    <Grid container spacing={1} justifyContent={"flex-end"}>
                        <Grid item>
                            {hideDeleteButton !== true &&
                                <ActionButton id={"delete-comment-button"}
                                              tooltip={_transl(CommonTranslation.REMOVE)}
                                              icon={<DeleteOutlined fontSize={"small"}/>}
                                              disabled={!comment.acl.canDelete}
                                              color={"default"}
                                              onClick={() => onDelete(comment)} />
                            }
                        </Grid>
                        <Grid item>
                            <ActionButton id={"edit-comment-button"}
                                          tooltip={_transl(CommonTranslation.EDIT)}
                                          icon={<Edit fontSize={"small"}/>}
                                          disabled={!comment.acl.canUpdate}
                                          color={"default"}
                                          onClick={() => onEdit(comment)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}