import {Observable} from "rxjs";
import {createDiagramNodeDto, IDiagramNodeDto} from "../../../../apis/diagram/IDiagramNodeDto";
import {NodeType} from "../../../../apis/diagram/NodeType";
import GeometryUtils, {Area, Point} from "../../../util/GeometryUtils";
import {ObjectType} from "../../../../apis/editor/ObjectType";
import store from "../../../../../store/Store";
import {NodeDimensionsExtractor} from "../../nodeposition/NodeDimensionsExtractor";
import {DiagramInfoDto} from "../../../../apis/diagram/DiagramInfoDto";
import {DiagramDefaultsDto} from "../../../../apis/diagram/DiagramDefaultsDto";

export interface Service {
    generateIdentifiers: (objectTypes: ObjectType[]) => Observable<string[]>;
}

const NODES_PER_ROW = 5;

export default class AddDiagramRefsRequestHandler {

    private service: Service;
    private diagramDefaults: DiagramDefaultsDto;
    private dimensionsExtractor: NodeDimensionsExtractor;

    constructor(service: Service) {
        this.service = service;
        this.diagramDefaults = store.getState().diagramDefaults;
        this.dimensionsExtractor = new NodeDimensionsExtractor(this.diagramDefaults);
    }

    async createDiagramRefNodes(diagrams: DiagramInfoDto[], diagramNodes: IDiagramNodeDto[]) {
        const nodeObjectTypes = diagrams.map(() => ObjectType.NODE);
        const nodeIdentifiers = await this.generateIdentifiers(nodeObjectTypes);
        const startingPoint = this.getStartingPoint(diagramNodes);
        return diagrams.map((diagram, index) => {
            const identifier = nodeIdentifiers[index];
            const dimensions = this.computeNodeDimensions(index, startingPoint);
            return this.createDiagramRefNode(identifier, diagram, dimensions);
        });
    }

    private async generateIdentifiers(objectTypes: Array<ObjectType>) {
        return this.service.generateIdentifiers(objectTypes).toPromise();
    }

    private getStartingPoint(nodes: IDiagramNodeDto[]): Point {
        if (nodes.length === 0) {
            return new Point(0, 0);
        } else {
            const area = GeometryUtils.getNodesBoundingArea(nodes);
            const minX = area.x;
            const maxY = area.y + area.h + this.diagramDefaults.spacing;
            return new Point(minX, maxY);
        }
    }

    private computeNodeDimensions(position: number, startingPoint: Point): Area {
        const nodeDimensions = this.dimensionsExtractor.extractDimensions(undefined);
        const row = position % NODES_PER_ROW;
        const x = startingPoint.x + (row * (nodeDimensions.width + this.diagramDefaults.spacing));
        const column = Math.floor(position / NODES_PER_ROW);
        const y = startingPoint.y + (column * (nodeDimensions.height + this.diagramDefaults.spacing));

        return Area.from(x, y, nodeDimensions.width, nodeDimensions.height);
    }

    private createDiagramRefNode(identifier: string, diagram: DiagramInfoDto, dimensions: Area): IDiagramNodeDto {
        const node = createDiagramNodeDto(identifier, undefined, NodeType.LABEL, dimensions);
        node.label = diagram.name;
        node.diagramReferences = [diagram.identifier];
        return node;
    }
}