import AbstractConnectionRenderer, {ContextDef, LineDef, LineType, MarkerDef} from "./AbstractConnectionRenderer";
import {ModelManager} from "../../manager/ModelManager";
import * as d3 from "d3";
import EventManager from "../../../event/EventManager";
import {EventType} from "../../../event/Event";
import ConnectionRendererUtils from "./ConnectionRendererUtils";
import RenderContext from "../../context/RenderContext";
import {MarkerType} from "../marker/MarkerDefinitionFactory";
import {IDiagramConnectionDto} from "../../../apis/diagram/IDiagramConnectionDto";

export default class ConfigurableConnectionRenderer extends AbstractConnectionRenderer {

    private startMarker?: MarkerType;
    private endMarker?: MarkerType;
    private lineType: LineType;

    constructor(lineType: LineType, startMarker?: MarkerType, endMarker?: MarkerType) {
        super();
        this.lineType = lineType;
        this.startMarker = startMarker;
        this.endMarker = endMarker;
    }

    init(diagramGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
         defsGroup: d3.Selection<SVGDefsElement, unknown, null, undefined>,
         modelAccessor: ModelManager): void {
    }

    render(connection: IDiagramConnectionDto,
           connectionGroup: d3.Selection<SVGGElement, IDiagramConnectionDto, null, undefined>,
           isHidden: boolean,
           renderContext: RenderContext,
           eventManager: EventManager): void
    {
        const lineGroup = connectionGroup.append("g")
            .attr("id", ConnectionRendererUtils.createLineGroupId(connection))
            .attr("fill", "none")
            .attr("stroke-width", 1.5)
            .datum(connection);

        const lineDef: LineDef = {
            lineType: this.lineType,
            lineGroup: lineGroup,
        }

        const markerDef: MarkerDef = {
            startMarker: this.startMarker,
            endMarker: this.endMarker,
        }

        const contextDef: ContextDef = {
            connection: connection,
            renderContext: renderContext,
            isHidden: isHidden,
            eventManager: eventManager,
        }

        this.renderLine(lineDef, markerDef, contextDef);

        const type = isHidden ? EventType.HIDDEN_CONNECTION_RENDERED : EventType.CONNECTION_RENDERED;
        eventManager.publishEvent({type: type, connection: connection, event: {}});
    }

}