export enum StereotypesTranslationKey {
    GRID_HEADER_ID = "stereotypes.grid.header.id",
    GRID_HEADER_NAME = "stereotypes.grid.header.name",
    GRID_HEADER_DERIVED_FROM = "stereotypes.grid.header.derivedFrom",
    GRID_BUTTONS_ADD_STEREOTYPE_TOOLTIP = "stereotypes.grid.buttons.addStereotype.tooltip",
    GRID_BUTTONS_DELETE_STEREOTYPE_TOOLTIP = "stereotypes.grid.buttons.deleteStereotype.tooltip",
    PAGE_STEREOTYPE_OVERVIEW = "stereotypes.page.overview",
    CONFIRMATION_DIALOG_DELETE_STEREOTYPE_TITLE = "stereotypes.confirmationDialog.delete.title",
    CONFIRMATION_DIALOG_DELETE_STEREOTYPE_TEXT = "stereotypes.confirmationDialog.delete.text",
    SUCEEDED_ALERT_DELETE_STEREOTYPE_TEXT = "stereotypes.succeededAlert.delete.text",
    NOT_SUCEEDED_ALERT_DELETE_STEREOTYPE_TEXT = "stereotypes.notSucceededAlert.delete.text",
    ADD_STEREOTYPE_TITLE = "stereotypes.addStereotype.title",
    ADD_STEREOTYPE_NAME = "stereotypes.addStereotype.name",
    CREATE_STEREOTYPE_CONFLICTING_NAME = "stereotypes.create.conflictingName",
    CREATE_STEREOTYPE_SUCCEEDED = "stereotypes.create.succeeded",
    CREATE_STEREOTYPE_NOT_SUCCEEDED = "stereotypes.create.failed",
    ADD_STEREOTYPE_DERIVED_FROM = "stereotypes.addStereotype.derivedFrom",
    TITLE="stereotypes.title",
}
