import {RulerType} from "./Ruler";
import * as d3 from 'd3';
import {Point} from "../../../../../common/diagrameditor/util/GeometryUtils";
import React from "react";

const RULER_AXIS_ID_TEMPLATE = "__diagram-editor-ruler-axis-{type}-group__"
const TICK_SIZE_100 = 2;
const TICK_SIZE_50 = 11;
const TICK_SIZE_10 = 4;
const AXIS_SIZE_MULTIPLIER = 4;

export default class RulerUtils {

    static createAxis(rulerType: RulerType,
                      zeroPosition: number,
                      zoom: number,
                      rulerSvgNode: SVGSVGElement,
                      lastRulersDims: React.MutableRefObject<[number, number]>) {
        d3.select(rulerSvgNode)
            .select("g")
            .remove();

        const rulerClientRect = rulerSvgNode.getBoundingClientRect();
        const start = 0 - zeroPosition;
        const rulerAxisGroupId = RulerUtils.getRulerAxisGroupId(rulerType);

        let width = rulerClientRect.width;
        let height = rulerClientRect.height;
        let transform = "";

        if (rulerType === RulerType.HORIZONTAL) {
            width *= AXIS_SIZE_MULTIPLIER;
            lastRulersDims.current = [width, lastRulersDims.current[1]];
            transform = `translate(${zeroPosition}, 0)`;
        } else {
            height *= AXIS_SIZE_MULTIPLIER;
            lastRulersDims.current = [lastRulersDims.current[0], height];
            transform = `translate(0, ${zeroPosition})`;
        }

        const axisGroup = d3.select(rulerSvgNode)
            .append("g")
            .classed(rulerAxisGroupId, true)
            .attr("transform", transform);

        RulerUtils.createBgRect(rulerType, start, width, height, axisGroup);
        RulerUtils.createTicks(rulerType, start, width, height, zoom, axisGroup);
    }

    private static getRulerAxisGroupId(type: RulerType) {
        return RULER_AXIS_ID_TEMPLATE.replace("{type}", type.toLowerCase());
    }

    private static createBgRect(rulerType: RulerType,
                                start: number,
                                width: number,
                                height: number,
                                axisGroup: d3.Selection<SVGGElement, unknown, null, any>) {
        const startPoint: Point = rulerType === RulerType.HORIZONTAL ?
            new Point(start, 0) :
            new Point(0, start);

        axisGroup.append("rect")
            .attr("x", startPoint.x)
            .attr("y", startPoint.y)
            .attr("width", width)
            .attr("height", height)
            .attr("fill", "white")
            .attr("stroke", "currentColor");
    }

    private static createTicks(rulerType: RulerType,
                               start: number,
                               width: number,
                               height: number,
                               zoom: number,
                               axisGroup: d3.Selection<SVGGElement, unknown, null, undefined>) {
        const ticksGroup = axisGroup.append("g");
        const labelsGroup = axisGroup.append("g");

        let tick10Modulo = 10 * zoom;
        if (zoom < 0.4) {
            // switch to 200 interval
            tick10Modulo *= 2;
        }
        const tick50Modulo = tick10Modulo * 5;
        const tick100Modulo = tick10Modulo * 10;

        const rulerSize = rulerType === RulerType.HORIZONTAL ? height : width;
        const end = rulerType === RulerType.HORIZONTAL ? width : height;

        const roundedStart = Math.round(start);
        // generate positive side
        for (let i = roundedStart; i < end; i += 1) {
            if (i % tick100Modulo === 0) {
                RulerUtils.appendLine(rulerType, i, TICK_SIZE_100, rulerSize, ticksGroup);
                const label = Math.trunc(i / zoom);
                RulerUtils.appendLabel(rulerType, i, TICK_SIZE_100, rulerSize, label, labelsGroup);
            } else if (i % tick50Modulo === 0) {
                RulerUtils.appendLine(rulerType, i, TICK_SIZE_50, rulerSize, ticksGroup);
            } else if (i % tick10Modulo === 0) {
                RulerUtils.appendLine(rulerType, i, TICK_SIZE_10, rulerSize, ticksGroup);
            }
        }
    }

    private static appendLine(rulerType: RulerType,
                              position: number,
                              size: number,
                              rulerSize: number,
                              ticksGroup: d3.Selection<SVGGElement, unknown, null, undefined>) {
        const linePoints: [Point, Point] = rulerType === RulerType.HORIZONTAL ?
            [new Point(position, rulerSize), new Point(position, rulerSize - size)] :
            [new Point(rulerSize, position), new Point(rulerSize - size, position)];

        ticksGroup.append("line")
            .style("stroke", "currentColor")
            .style("shape-rendering", "crispedges")
            .style("stroke-width", .5)
            .attr("x1", linePoints[0].x)
            .attr("y1", linePoints[0].y)
            .attr("x2", linePoints[1].x)
            .attr("y2", linePoints[1].y);
    }

    private static appendLabel(rulerType: RulerType,
                               tickPosition: number,
                               tickSize: number,
                               rulerSize: number,
                               label: number,
                               labelsGroup: d3.Selection<SVGGElement, unknown, null, undefined>) {
        const text = labelsGroup.append("text")
            .attr("fill", "currentColor")
            .attr("font-size", 10)
            .style("font-size", 10)
            .text(`${label}`);
        const textBoundingRect = text.node()?.getBoundingClientRect() as DOMRect;
        const textWidth = textBoundingRect.width;

        if (rulerType === RulerType.HORIZONTAL) {
            const textOffsetX = textWidth / 2;
            text
                .attr("x", tickPosition - textOffsetX)
                .attr("y", rulerSize - tickSize - 2)

        } else {
            const textOffsetY = textWidth / 2;
            text
                .attr("transform", "rotate(-90)");
            text
                .attr("x", -(tickPosition + textOffsetY))
                .attr("y", rulerSize - tickSize - 2)
        }
    }
}