import EventManager, {Unsubscriber} from "../../event/EventManager";
import {
    EventType,
    IBendpointMoveEvent,
    IChartResizedEvent,
    INavigatorScrolledEvent,
    INodeEvent,
    INodeResizeEvent,
    IScrollRequestEvent,
} from "../../event/Event";
import RenderContext from "../context/RenderContext";
import {Area, EMPTY_AREA} from "../util/GeometryUtils";
import * as d3 from "d3";
import {CONTENT_AREA_ID} from "../../../components/diagrameditor/DiagramEditorComponent";
import EventUtils from "../../event/EventUtils";
import {PAPER_RECT_ID} from "./SvgPaperManager";
import ChartGroup from "../common/ChartGroup";

export const canvasPadding = 10;

const SCROLL_TIMER_INTERVAL_IN_MS = 50;
const SCROLL_TIMER_SCROLLBAR_MOVE_DISTANCE = 20;

export default class SvgElementScrollbarsManager {

    private eventManager: EventManager;

    private renderContext?: RenderContext;
    private onScrollEventListener = (event: any) => this.onScroll(event);

    // scroll timer
    private scrollTimerHandle?: number;
    private scrollLeft?: boolean;
    private scrollRight?: boolean;
    private scrollTop?: boolean;
    private scrollBottom?: boolean;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;

        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NAVIGATOR_SCROLLED, this.handleNavigatorScrolledEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.SCROLL_BY_REQUEST, this.handleScrollRequestEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.SCROLL_TO_REQUEST, this.handleScrollRequestEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_RESIZED, this.handleChartResizedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_STARTED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_IN_PROGRESS, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_FINISHED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_CANCELLED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_STARTED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_IN_PROGRESS, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_FINISHED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_CANCELLED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_STARTED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_IN_PROGRESS, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_FINISHED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_CANCELLED, this.handleNodeResizeEvent.bind(this)));
    }

    destroy() {
        const node = SvgElementScrollbarsManager.getContentAreaNode();
        if (node) {
            node.removeEventListener("scroll", this.onScrollEventListener);
        }

        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    public init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        const node = SvgElementScrollbarsManager.getContentAreaNode();
        node.addEventListener("scroll", this.onScrollEventListener);
    }

    handleScrollRequestEvent(event: IScrollRequestEvent) {
        if (event.type === EventType.SCROLL_BY_REQUEST) {
            this.scrollContentAreaNode(event.top, event.left, true);
        }
        if (event.type === EventType.SCROLL_TO_REQUEST) {
            this.scrollContentAreaNode(event.top, event.left, false);
        }
    }

    handleNavigatorScrolledEvent(event: INavigatorScrolledEvent) {
        if (event.type === EventType.NAVIGATOR_SCROLLED) {
            this.scrollContentAreaNode(event.increment.y, event.increment.x, true);
        }
    }

    handleChartResizedEvent(event: IChartResizedEvent) {
        if (event.type === EventType.CHART_RESIZED) {
            this.scrollContentAreaNode(0, 0, true);
        }
    }

    handleBendpointMoveEvent(event: IBendpointMoveEvent) {
        if (event.type === EventType.CONNECTION_BENDPOINT_MOVE_FINISHED || event.type === EventType.CONNECTION_BENDPOINT_MOVE_CANCELLED) {
            this.stopScrollTimer();
        } else {
            this.updateScrollTimer(event);
        }
    }

    handleNodeEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_MOVE_FINISHED || event.type === EventType.NODE_MOVE_CANCELLED) {
            this.stopScrollTimer();
        } else {
            this.updateScrollTimer(event);
        }
    }

    handleNodeResizeEvent(event: INodeResizeEvent) {
        if (event.type === EventType.NODE_RESIZE_FINISHED || event.type === EventType.NODE_RESIZE_CANCELLED) {
            this.stopScrollTimer();
        } else {
            this.updateScrollTimer(event);
        }
    }

    private onScroll(event: any) {
        this.scrollContentAreaNode(0, 0, event);
    }

    static getContentAreaNode() {
        return d3.select("#"+CONTENT_AREA_ID).node() as HTMLDivElement;
    }

    private scrollContentAreaNode(scrollTop: number, scrollLeft: number, isScrollBy: boolean, event?: any) {
        const node = SvgElementScrollbarsManager.getContentAreaNode();
        if (isScrollBy) {
            node.scrollTop += scrollTop;
            node.scrollLeft += scrollLeft;
        } else {
            node.scrollTop = scrollTop;
            node.scrollLeft = scrollLeft;
        }

        const clientRect = SvgElementScrollbarsManager.getContentAreaNode()
            .getBoundingClientRect() as DOMRect;

        const svgRect = this.renderContext?.svgElementManager.getSvg()
            .getBoundingClientRect() as DOMRect;

        const paperRect = (d3.select("#"+PAPER_RECT_ID).node() as SVGRectElement)
            .getBoundingClientRect() as DOMRect;
        const paperToSvgRelativeArea = new Area(
            paperRect.x - svgRect.x,
            paperRect.y - svgRect.y,
            paperRect.width,
            paperRect.height,
        );

        const diagramGroupRectArea = ChartGroup.computeNodesConnectionsBoundingClientArea(true) || EMPTY_AREA;

        const diagramGroupToSvgRelativeArea = new Area(
            diagramGroupRectArea.x - svgRect.x,
            diagramGroupRectArea.y - svgRect.y,
            diagramGroupRectArea.w,
            diagramGroupRectArea.h
        );

        this.eventManager.publishEvent({
            type: EventType.CHART_SCROLLED,
            event: event,
            scrollHeight: node.scrollHeight,
            scrollWidth: node.scrollWidth,
            clientHeight: node.clientHeight,
            clientWidth: node.clientWidth,
            scrollLeft: node.scrollLeft,
            scrollTop: node.scrollTop,
            clientArea: Area.fromDOMRect(clientRect),
            svgArea: Area.fromDOMRect(svgRect),
            paperArea: Area.fromDOMRect(paperRect),
            nodesConnectionsOverlaysArea: diagramGroupRectArea,
            paperToSvgRelativeArea: paperToSvgRelativeArea,
            diagramGroupToSvgRelativeArea: diagramGroupToSvgRelativeArea,
        });
    }

    private static getClientBoundingArea() {
        const contentAreaDiv = d3.select("#"+CONTENT_AREA_ID).node() as HTMLDivElement;
        const boundingRect = contentAreaDiv.getBoundingClientRect();
        return new Area(boundingRect.x, boundingRect.y, contentAreaDiv.clientWidth, contentAreaDiv.clientHeight);
    }

    // scroll timer methods

    private updateScrollTimer(causeEvent: IBendpointMoveEvent | INodeEvent | INodeResizeEvent) {
        const eventCoordinates = EventUtils.getClientCoordinates(causeEvent.event?.sourceEvent);
        if (eventCoordinates?.length === 2) {
            const eventClientX = eventCoordinates[0];
            const eventClientY = eventCoordinates[1];
            const clientArea = SvgElementScrollbarsManager.getClientBoundingArea();
            this.scrollLeft = eventClientX <= clientArea.x;
            this.scrollRight = eventClientX >= clientArea.x + clientArea.w;
            this.scrollTop = eventClientY <= clientArea.y;
            this.scrollBottom = eventClientY >= clientArea.y + clientArea.h;

            if (this.scrollLeft || this.scrollRight || this.scrollTop || this.scrollBottom) {
                if (this.scrollTimerHandle == null) {
                    this.scrollTimerHandle = window.setInterval(() => this.scrollTimerAutoScroll(causeEvent), SCROLL_TIMER_INTERVAL_IN_MS);
                }
            } else {
                this.stopScrollTimer();
            }
        }
    }

    private stopScrollTimer() {
        this.scrollTimerHandle && clearInterval(this.scrollTimerHandle);
        this.scrollTimerHandle = undefined;
        this.scrollBottom = undefined;
        this.scrollTop = undefined;
        this.scrollLeft = undefined;
        this.scrollRight = undefined;
    }

    private scrollTimerAutoScroll(causeEvent: IBendpointMoveEvent | INodeEvent | INodeResizeEvent) {
        let scrollTopBy = 0;
        let scrollLeftBy = 0;

        if (this.scrollLeft) {
            scrollLeftBy = -SCROLL_TIMER_SCROLLBAR_MOVE_DISTANCE;
        }
        if (this.scrollRight) {
            scrollLeftBy = SCROLL_TIMER_SCROLLBAR_MOVE_DISTANCE;
        }
        if (this.scrollTop) {
            scrollTopBy = - SCROLL_TIMER_SCROLLBAR_MOVE_DISTANCE;
        }
        if (this.scrollBottom) {
            scrollTopBy = SCROLL_TIMER_SCROLLBAR_MOVE_DISTANCE;
        }

        this.eventManager.publishEvent({
            type: EventType.SCROLL_BY_REQUEST,
            causeEvent: causeEvent.event,
            isAutoScroll: true,
            top: scrollTopBy,
            left: scrollLeftBy,
        });

    }
}
