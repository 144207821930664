import {StyleSettings, UpdateMode} from "./StyleSettings";
import {Styleable} from "../../../common/apis/diagram/Styleable";
import {FontStylesExtractor} from "./FontStylesExtractor";
import {Colors} from "../../../common/apis/Color";
import {DEFAULT_FONT_SIZE, DEFAULT_LINE_WIDTH} from "../../../common/diagrameditor/common/UIConstants";

export class StyleSettingsExtractor {

    extractStyleSettings(styleable: Styleable, forceModeToKeepUnchanged: boolean = false): StyleSettings {
        const settings = this.getDefaults(forceModeToKeepUnchanged);

        if (styleable.style) {
            const style = styleable.style;
            if (style.fillColor) {
                if (!forceModeToKeepUnchanged) {
                    settings.fillColorUpdateMode = UpdateMode.USE_CUSTOM;
                }
                settings.fillColor = style.fillColor;
            }
            if (style.lineColor) {
                if (!forceModeToKeepUnchanged) {
                    settings.lineColorUpdateMode = UpdateMode.USE_CUSTOM;
                }
                settings.lineColor = style.lineColor;
            }
            if (style.lineWidth) {
                if (!forceModeToKeepUnchanged) {
                    settings.lineWidthUpdateMode = UpdateMode.USE_CUSTOM;
                }
                settings.lineWidth = style.lineWidth;
            }
            if (style.font) {
                if (style.font.color) {
                    if (!forceModeToKeepUnchanged) {
                        settings.textColorUpdateMode = UpdateMode.USE_CUSTOM;
                    }
                    settings.textColor = style.font.color;
                }
                if (style.font.size) {
                    if (!forceModeToKeepUnchanged) {
                        settings.fontSizeUpdateMode = UpdateMode.USE_CUSTOM;
                    }
                    settings.fontSize = style.font.size;
                }
                if (style.font.styles) {
                    if (!forceModeToKeepUnchanged) {
                        settings.fontStylesUpdateMode = UpdateMode.USE_CUSTOM;
                    }
                    settings.fontStyles = FontStylesExtractor.extractStyles(style.font.styles);
                }
            }
        }

        return settings;
    }

    private getDefaults(forceModeToKeepUnchanged: boolean): StyleSettings {
        const updateMode = forceModeToKeepUnchanged ? UpdateMode.KEEP_UNCHANGED : UpdateMode.RESET_TO_DEFAULTS;
        return {
            fillColorUpdateMode: updateMode,
            fillColor: Colors.WHITE,
            lineColorUpdateMode: updateMode,
            lineColor: Colors.WHITE,
            textColorUpdateMode: updateMode,
            textColor: Colors.WHITE,
            fontStylesUpdateMode: updateMode,
            fontStyles: [],
            fontSizeUpdateMode: updateMode,
            fontSize: DEFAULT_FONT_SIZE,
            lineWidth: DEFAULT_LINE_WIDTH,
            lineWidthUpdateMode: updateMode
        };
    }
}