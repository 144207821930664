import {LinePath} from "../../../connection/ConnectionRendererUtils";
import * as d3 from 'd3';
import AbstractConnectionRenderer, {publisherStrokeWidth} from "../../../connection/AbstractConnectionRenderer";
import {CUSTOM_DRAG_HANDLER, ICustomDragHandler} from "../../../../manager/SvgElementDragManager";
import RenderContext from "../../../../context/RenderContext";
import ConnectionHandle from "./ConnectionHandle";
import {DISABLE_ON_NODE_MOVE_POINTER_EVENTS_STROKE_CLASS_NAME} from "../../../../common/UIConstants";
import {IDiagramConnectionDto} from "../../../../../apis/diagram/IDiagramConnectionDto";
import EventManager from "../../../../../event/EventManager";
import {EventProperty, EventType, IConnectionEvent} from "../../../../../event/Event";
import EventUtils from "../../../../../event/EventUtils";
import {ClickHandlerBuilder} from "../../../../util/ClickHandler";

export default class ConnectionBendpointCreateHandle implements ConnectionHandle {

    private static readonly NEW_BENDPOINT_INDEX_MARKER_ID = "__connection_create_bendpoint_handle_bendpoint_index__";

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    render(connection: IDiagramConnectionDto,
           linePath: LinePath,
           handleGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
           isHidden: boolean,
           eventManager: EventManager) {
        const dragPathWidth = publisherStrokeWidth - 1;

        // create drag lines that allow to create new bendpoint when dragged
        // path contains new bendpoint index as its parameter
        linePath.points.forEach((point, index) => {
            if (index < (linePath.points.length - 1)) {
                const d = d3.line()([linePath.points[index].toArray(), linePath.points[index + 1].toArray()]);
                const dragPath = handleGroup.append("path")
                    .attr("d", d)
                    .property(ConnectionBendpointCreateHandle.NEW_BENDPOINT_INDEX_MARKER_ID, index)
                    .attr("stroke", "none")
                    .attr("fill", "none")
                    .attr("stroke-width", dragPathWidth)
                    .classed(DISABLE_ON_NODE_MOVE_POINTER_EVENTS_STROKE_CLASS_NAME, true)
                    .attr("pointer-events", "stroke")
                    .attr("cursor", "move");
                dragPath.on("mousedown", () => {
                    dragPath.attr("cursor", "move")
                });
                dragPath.on("mouseup", () => {
                    dragPath.attr("cursor", "move")
                });
                dragPath.on("mouseleave", (event) => {
                    this.eventManager.publishEvent<IConnectionEvent>({type: EventType.CONNECTION_CREATE_HANDLE_MOUSE_LEAVE, connection: connection, event: event});
                });
                dragPath.on("contextmenu", (event) => {
                    event.preventDefault();
                    const eventType = isHidden ? EventType.HIDDEN_CONNECTION_SHOW_CONTEXT_MENU : EventType.CONNECTION_SHOW_CONTEXT_MENU;
                    eventManager.publishEvent<IConnectionEvent>({type: eventType, connection: connection, event: event});
                });

                if (this.renderContext?.isEdit()) {
                    this.addBendpointCreateEventPublisher(dragPath, connection, index);
                }
            }
        });
    }

    private addBendpointCreateEventPublisher(dragPath: d3.Selection<SVGPathElement, unknown, HTMLElement, any>,
                                             connection: IDiagramConnectionDto,
                                             newBendpointIndex: number) {
        const clickHandler = ClickHandlerBuilder.builder()
            .setOnClick((event: any) => this.eventManager.publishEvent({type: EventType.CONNECTION_MOUSE_CLICKED, connection: connection, event: event}))
            .setOnDblClick((event: any) => this.eventManager.publishEvent({type: EventType.CONNECTION_DBLCLICK, connection: connection, event: event}))
            .build();

        const dragHandler: ICustomDragHandler = {
            useSnappingFunction: true,
            publishStartEvent: (event: any) => {
                this.eventManager.publishEvent(ConnectionBendpointCreateHandle.createBendpointCreateEvent(
                    EventType.CONNECTION_BENDPOINT_CREATE_STARTED, connection, newBendpointIndex, event));
            },
            publishInProgressEvent: (event: any) => {
                this.eventManager.publishEvent(ConnectionBendpointCreateHandle.createBendpointCreateEvent(
                    EventType.CONNECTION_BENDPOINT_CREATE_IN_PROGRESS, connection, newBendpointIndex, event));
            },
            publishEndEvent: (event: any) => {
                this.eventManager.publishEvent(ConnectionBendpointCreateHandle.createBendpointCreateEvent(
                    EventType.CONNECTION_BENDPOINT_CREATE_FINISHED, connection, newBendpointIndex, event));
            },
            publishCancelEvent: (event: any) => {
                this.eventManager.publishEvent(ConnectionBendpointCreateHandle.createBendpointCreateEvent(
                    EventType.CONNECTION_BENDPOINT_CREATE_CANCELLED, connection, newBendpointIndex, event));
            },
            clickThreshold: AbstractConnectionRenderer.CLICK_THRESHOLD,
            publishClickEvent: (event: any) => clickHandler.onClick(event),
            setTransformedEventCoordinates: (event: any, transformedX: number, transformedY: number) => {
                event[EventProperty.TRANSFORMED_X_COORDINATE] = transformedX;
                event[EventProperty.TRANSFORMED_Y_COORDINATE] = transformedY;
            }
        };

        (dragPath.node() as any)[CUSTOM_DRAG_HANDLER] = dragHandler;
    }

    private static createBendpointCreateEvent(type: EventType, connection: IDiagramConnectionDto, bendpointIndex: number, event: any) {
        return {
            type: type,
            connection: connection,
            event: event,
            bendpoint: EventUtils.getTransformedCoordinates(event),
            bendpointIndex: bendpointIndex,
        }
    }

    updateScaleFactor(scaleFactor: number) {}
}
