import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import {Event} from "../../../../common/event/Event"

export enum DiagramRefsEventType {
    ADD_DIAGRAMS_REFS_REQUEST = "diagramRefs.ADD_DIAGRAMS_REFS_REQUEST",
}

export interface AddDiagramRefsRequestEvent extends Event {
    readonly type: DiagramRefsEventType.ADD_DIAGRAMS_REFS_REQUEST;
    readonly diagrams: DiagramInfoDto[];
}