import React from "react";
import {IPropertyDefinition} from "./apis/CommonTypes";

export interface ElementPropertyDefinition {
    propertyDefinitions: IPropertyDefinition[];
    updatePropertyDefinitions: () => void;
}

const ElementPropertyDefinitionContext = React.createContext<ElementPropertyDefinition>({
    propertyDefinitions: [],
    updatePropertyDefinitions: () => {}
});

export default ElementPropertyDefinitionContext;