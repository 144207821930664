import {Box} from "@mui/material";
import DialogTitle from "../../components/dialogs/DialogTitle";
import DialogContent from "../../components/dialogs/DialogContent";
import DialogActions from "../../components/dialogs/DialogActions";
import Dialog from "../../components/dialogs/Dialog";
import {ButtonLayout, NegativeButtons, PositiveButtons} from "../../components/button/ButtonLayout";
import {_transl} from "../../store/localization/TranslMessasge";
import TranslationKey from "./TranslationKey";
import {YesButton} from "../../components/button/YesButton";
import React from "react";
import {NoButton} from "../../components/button/NoButton";

interface ConcurrentUpdateDialogProps {
    onSuccess: () => void;
    onClose: () => void;
}

export default function ConcurrentUpdateDialog(props: ConcurrentUpdateDialogProps) {
    return (
        <Dialog open={true} maxWidth={"sm"}>
            <DialogTitle title={_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_CONCURRENT_MODIFICATION_TITLE)} onDialogClosed={props.onClose}/>
            <DialogContent>
                <Box marginTop={2}>
                    <div>{_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_CONCURRENT_MODIFICATION_MSG)}</div>
                </Box>
            </DialogContent>
            <DialogActions>
                <ButtonLayout>
                    <PositiveButtons>
                        <YesButton onClick={props.onSuccess} variant="contained"/>
                    </PositiveButtons>
                    <NegativeButtons>
                        <NoButton onClick={props.onClose} variant="outlined"/>
                    </NegativeButtons>
                </ButtonLayout>
            </DialogActions>
        </Dialog>
    );
}
