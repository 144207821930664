import {IconButton, Tooltip} from "@mui/material";
import React from "react";


export interface GridActionButtonProps {
    id: string,
    tooltip: string,
    icon?: JSX.Element,
    label?: string,
    disabled?: boolean,
    className?: string,
    onClick?: (event: any) => void,
}

export function GridActionButton(props: GridActionButtonProps) {
    const onClick = props.onClick ?? (() => {});

    return (
        <Tooltip title={props.tooltip} key={props.tooltip} placement={"top"}>
            <IconButton
                id={props.id}
                className={props.className}
                disabled={props.disabled}
                color="primary"
                component="span"
                onClick={onClick}
                size="large">
                {props.icon}
            </IconButton>
        </Tooltip>
    );
}
