import {
    NodeResizeEventType,
    NodeResizeToDefaultSizeEvent,
    NodeResizeToMatchHeightEvent,
    NodeResizeToMatchSizeEvent,
    NodeResizeToMatchWidthEvent
} from "./NodeResizeEvents";
import EventManager, {Unsubscriber} from "../../../event/EventManager";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";
import {EventType} from "../../../event/Event";
import {Area} from "../../util/GeometryUtils";
import {DiagramDefaultsDto} from "../../../apis/diagram/DiagramDefaultsDto";

export class NodeResizer {

    private eventManager: EventManager;
    private diagramDefaults: DiagramDefaultsDto;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager, diagramDefaults: DiagramDefaultsDto) {
        this.eventManager = eventManager;
        this.diagramDefaults = diagramDefaults;

        this.unsubscribers.push(this.eventManager.subscribeListener(NodeResizeEventType.RESIZE_TO_MATCH_WIDTH, this.handleMatchWidth.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(NodeResizeEventType.RESIZE_TO_MATCH_HEIGHT, this.handleMatchHeight.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(NodeResizeEventType.RESIZE_TO_MATCH_SIZE, this.handleMatchSize.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(NodeResizeEventType.RESIZE_TO_DEFAULT_SIZE, this.handleDefaultSize.bind(this)));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    private handleMatchWidth(event: NodeResizeToMatchWidthEvent) {
        if (event.nodes.length > 1) {
            const newDimensions = this.resizeToMatchWidth(event.nodes[0], event.nodes.slice(1));
            this.eventManager.publishEvent({
                type: EventType.NODES_RESIZED,
                nodes: event.nodes.slice(1),
                dimensions: newDimensions});
        }
    }

    private handleMatchHeight(event: NodeResizeToMatchHeightEvent) {
        if (event.nodes.length > 1) {
            const newDimensions = this.resizeToMatchHeight(event.nodes[0], event.nodes.slice(1));
            this.eventManager.publishEvent({
                type: EventType.NODES_RESIZED,
                nodes: event.nodes.slice(1),
                dimensions: newDimensions});
        }
    }

    private handleMatchSize(event: NodeResizeToMatchSizeEvent) {
        if (event.nodes.length > 1) {
            const newDimensions = this.resizeToMatchSize(event.nodes[0], event.nodes.slice(1));
            this.eventManager.publishEvent({
                type: EventType.NODES_RESIZED,
                nodes: event.nodes.slice(1),
                dimensions: newDimensions});
        }
    }

    private handleDefaultSize(event: NodeResizeToDefaultSizeEvent) {
        if (event.nodes.length > 0) {
            const w = this.diagramDefaults.nodeDimensions.width;
            const h = this.diagramDefaults.nodeDimensions.height;
            const newDimensions = this.resizeTo(event.nodes, w, h);
            this.eventManager.publishEvent({
                type: EventType.NODES_RESIZED,
                nodes: event.nodes,
                dimensions: newDimensions});
        }
    }

    resizeToMatchWidth(referenceNode: IDiagramNodeDto, nodesToResize: IDiagramNodeDto[]) {
        const newDimensions: {[id: string]: Area} = {};
        for (const node of nodesToResize) {
            newDimensions[node.identifier] = new Area(node.x, node.y, referenceNode.w, node.h);
        }
        return newDimensions;
    }

    resizeToMatchHeight(referenceNode: IDiagramNodeDto, nodesToResize: IDiagramNodeDto[]) {
        const newDimensions: {[id: string]: Area} = {};
        for (const node of nodesToResize) {
            newDimensions[node.identifier] = new Area(node.x, node.y, node.w, referenceNode.h);
        }
        return newDimensions;
    }

    resizeToMatchSize(referenceNode: IDiagramNodeDto, nodesToResize: IDiagramNodeDto[]) {
        const newDimensions: {[id: string]: Area} = {};
        for (const node of nodesToResize) {
            newDimensions[node.identifier] = new Area(node.x, node.y, referenceNode.w, referenceNode.h);
        }
        return newDimensions;
    }

    resizeTo(nodesToResize: IDiagramNodeDto[], w: number, h: number) {
        const newDimensions: {[id: string]: Area} = {};
        for (const node of nodesToResize) {
            newDimensions[node.identifier] = new Area(node.x, node.y, w, h);
        }
        return newDimensions;
    }
}