import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

interface Props {
    firstRowText: string;
    secondRowText?: string;
    icon: React.ReactNode;
    iconClass: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        importIcon: {
            "& .MuiSvgIcon-root": {
                width: "5em",
                height: "5em",
            },
            textAlign: 'center'
        },
        importStep: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        typography: {
            textAlign: "center",
            color: "gray"
        }
    })
);

export default function WizardImportStep(props: Props) {

    const classes = useStyles();

    const {firstRowText, secondRowText, icon, iconClass} = props;

    return (
        <div className={classes.importStep}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} className={classes.typography}>
                        {firstRowText}
                    </Typography>
                </Grid>
                {secondRowText &&
                    <Grid item xs={12}>
                        <Typography variant={"body1"} className={classes.typography}>
                            {secondRowText}
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12} className={clsx(classes.importIcon, iconClass)}>
                    {icon}
                </Grid>
            </Grid>
        </div>
    );
}


