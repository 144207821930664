import INodeRenderer from "./INodeRenderer";
import {ModelManager} from "../../manager/ModelManager";
import * as d3 from "d3";
import RenderContext from "../../context/RenderContext";
import EventManager from "../../../event/EventManager";
import NodeRendererUtils from "./NodeRendererUtils";
import {Area, Point} from "../../util/GeometryUtils";
import {DiagramEditorUtils} from "../../util/DiagramEditorUtils";
import {ArchimateElement, ArchimateElementType} from "../../../archimate/ArchimateElement";
import {NodeType} from "../../../apis/diagram/NodeType";
import {FontDto} from "../../../apis/DiagramsApi";
import {FontStyleBuilder} from "../text/FontStyleBuilder";
import {DiagramNode} from "../../model/Model";

export const RECTANGLE_RADIUS: number = 1;
export const ROUNDED_RECTANGLE_RADIUS: number = 8;
export const BEVELLED_CORNER_SIZE: number = ROUNDED_RECTANGLE_RADIUS;
export const HORIZONTAL_LINE_HEIGHT: number = ROUNDED_RECTANGLE_RADIUS + 7;
export const NODE_GROUP_DEFAULT_PADDING: number = 3;
export const ICON_FONT_SIZE = 16;
export const APP_BAR_WIDTH = 25;
export const DASHBOARD_TEXT_AREA_HEIGHT = 20;
export const TEXTAREA_CLASS_NAME = "textarea";

export class ColorAttributes {
    constructor(public fill: string,
                public fillGradientId: string | undefined,
                public fillDarker: string,
                public stroke: string,
                public strokeWidth: number) {
        this.fill = fill;
        this.fillGradientId = fillGradientId;
        this.fillDarker = fillDarker;
        this.stroke = stroke;
        this.strokeWidth = strokeWidth;
    }
}

export enum ShapeType {
    RECTANGLE,
    ROUNDED_RECTANGLE,
    BEVELLED_RECTANGLE,
    WAVED_RECTANGLE,
    RIGHT_TOP_FOLDED_RECTANGLE,
    BLOCK,
    ELIPSIS,
    CLOUD,
    DEVICE,
    DASHED_FOLDER,
    SOLID_FOLDER,
    CIRCLE,
    RIGHT_BOTTOM_CLIPPED_RECTANGLE,
}

export enum DecorationType {
    HORIZONTAL_LINE_TOP,
    HORIZONTAL_LINE_BOTTOM,
    HALF_RECT_TOP_LEFT,
    APP_BARS,
}

export default abstract class AbstractNodeRenderer implements INodeRenderer {

    abstract init(diagramGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
                  defsGroup: d3.Selection<SVGDefsElement, unknown, null, undefined>,
                  modelAccessor: ModelManager): void;

    abstract render(node: DiagramNode,
                    nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                    renderContext: RenderContext,
                    eventManager: EventManager): void;

    protected renderRoundedRectangle(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                     colorAttributes: ColorAttributes) {
        const rect = nodeGroup.append("rect")
            .attr("x", node => node.x)
            .attr("y", node => node.y)
            .attr("width", node => node.w)
            .attr("height", node => node.h)
            .attr("rx", ROUNDED_RECTANGLE_RADIUS)
            .attr("ry", ROUNDED_RECTANGLE_RADIUS);
        this.applyRectColorAttributes(rect, colorAttributes, false);
        return rect;
    }

    protected renderBevelledRectangle(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                      colorAttributes: ColorAttributes) {
        const path = nodeGroup.append("path")
            .attr("d", node => {
                const path = d3.path();
                path.moveTo(node.x, node.y + BEVELLED_CORNER_SIZE);
                path.lineTo(node.x, node.y + node.h - BEVELLED_CORNER_SIZE);
                path.lineTo(node.x + BEVELLED_CORNER_SIZE, node.y + node.h);
                path.lineTo(node.x + node.w - BEVELLED_CORNER_SIZE, node.y + node.h);
                path.lineTo(node.x + node.w - BEVELLED_CORNER_SIZE, node.y + node.h);
                path.lineTo(node.x + node.w, node.y + node.h - BEVELLED_CORNER_SIZE);
                path.lineTo(node.x + node.w, node.y + BEVELLED_CORNER_SIZE);
                path.lineTo(node.x + node.w - BEVELLED_CORNER_SIZE, node.y);
                path.lineTo(node.x + BEVELLED_CORNER_SIZE, node.y);
                path.closePath();
                return path.toString();
            });
        this.applyPathColorAttributes(path, colorAttributes, false);
        return path;
    }

    protected renderWavedRectangle(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                   colorAttributes: ColorAttributes) {
        const path = nodeGroup.append("path")
            .attr("d", node => {
                let cpYLength = Math.sqrt(Math.pow((node.w / Math.sqrt(2)), 2) - (Math.pow((node.w / 2), 2)));
                cpYLength = Math.min(cpYLength, node.h / 2);

                const startX = node.x;
                const startY = node.y + node.h - HORIZONTAL_LINE_HEIGHT;
                const endX = node.x + node.w;
                const endY = startY;

                let controlPoint1 = new Point(node.x + (node.w / 2), startY + cpYLength);
                let controlPoint2 = new Point(node.x + (node.w / 2), startY - cpYLength);

                const path = d3.path();
                path.moveTo(node.x, node.y);
                path.lineTo(startX, startY);
                path.bezierCurveTo(controlPoint1.x, controlPoint1.y, controlPoint2.x, controlPoint2.y, endX, endY);
                path.lineTo(endX, node.y);
                path.closePath();
                return path.toString();
            });
        this.applyPathColorAttributes(path, colorAttributes, false);
        return path;
    }

    protected renderRightTopFoldedRectangle(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                            colorAttributes: ColorAttributes) {
        // render rect
        const path = nodeGroup.append("path")
            .attr("d", node => {
                const path = d3.path();
                path.moveTo(node.x, node.y);
                path.lineTo(node.x, node.y + node.h);
                path.lineTo(node.x + node.w, node.y + node.h);
                path.lineTo(node.x + node.w, node.y + HORIZONTAL_LINE_HEIGHT);
                path.lineTo(node.x + node.w - HORIZONTAL_LINE_HEIGHT, node.y);
                path.closePath();
                return path.toString();
            });
        this.applyPathColorAttributes(path, colorAttributes, false);

        // render folded corner
        const foldedCornerPath = nodeGroup.append("path")
            .attr("d", node => {
                const path = d3.path();
                path.moveTo(node.x + node.w - HORIZONTAL_LINE_HEIGHT, node.y);
                path.lineTo(node.x + node.w, node.y + HORIZONTAL_LINE_HEIGHT);
                path.lineTo(node.x + node.w - HORIZONTAL_LINE_HEIGHT, node.y + HORIZONTAL_LINE_HEIGHT);
                path.closePath();
                return path.toString();
            });
        this.applyPathColorAttributes(foldedCornerPath, colorAttributes, true);
        return path;
    }

    protected renderBlock(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                          colorAttributes: ColorAttributes) {
        // render darker sides
        const path = nodeGroup.append("path")
            .attr("d", node => {
                const path = d3.path();
                path.moveTo(node.x + node.w, node.y);
                path.lineTo(node.x + (node.w - HORIZONTAL_LINE_HEIGHT), node.y + HORIZONTAL_LINE_HEIGHT);
                path.lineTo(node.x, node.y + HORIZONTAL_LINE_HEIGHT);
                path.lineTo(node.x + HORIZONTAL_LINE_HEIGHT, node.y);
                path.lineTo(node.x + node.w, node.y);
                path.lineTo(node.x + node.w, node.y + node.h - HORIZONTAL_LINE_HEIGHT);
                path.lineTo(node.x + node.w - HORIZONTAL_LINE_HEIGHT, node.y + node.h);
                path.lineTo(node.x + node.w - HORIZONTAL_LINE_HEIGHT, node.y + HORIZONTAL_LINE_HEIGHT);
                path.closePath();
                return path.toString();
            })
        this.applyPathColorAttributes(path, colorAttributes, true);

        // render center rectangle
        const rect = nodeGroup.append("rect")
            .attr("x", node => node.x)
            .attr("y", node => node.y + HORIZONTAL_LINE_HEIGHT)
            .attr("width", node => node.w - HORIZONTAL_LINE_HEIGHT)
            .attr("height", node => node.h - HORIZONTAL_LINE_HEIGHT)
        this.applyRectColorAttributes(rect, colorAttributes, false);
        return path;
    }

    protected renderDevice(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                           colorAttributes: ColorAttributes) {
        const path = nodeGroup.append("path")
            .attr("d", node => {
                const path = d3.path();
                path.moveTo(node.x, node.y + node.h);
                path.lineTo(node.x + HORIZONTAL_LINE_HEIGHT, node.y + node.h - HORIZONTAL_LINE_HEIGHT);
                path.lineTo(node.x + node.w - HORIZONTAL_LINE_HEIGHT, node.y + node.h - HORIZONTAL_LINE_HEIGHT);
                path.lineTo(node.x + node.w, node.y + node.h);
                path.closePath();
                return path.toString();
            })
        this.applyPathColorAttributes(path, colorAttributes, true);

        // render center rect
        const rect = nodeGroup.append("rect")
            .attr("x", node => node.x)
            .attr("y", node => node.y)
            .attr("width", node => node.w)
            .attr("height", node => node.h - HORIZONTAL_LINE_HEIGHT)
            .attr("rx", ROUNDED_RECTANGLE_RADIUS)
            .attr("ry", ROUNDED_RECTANGLE_RADIUS);
        this.applyRectColorAttributes(rect, colorAttributes, false);
        return path;
    }

    protected renderFolder(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                           colorAttributes: ColorAttributes,
                           dashed: boolean) {
        const path = nodeGroup.append("path");
        if (dashed) {
            path.attr("stroke-dasharray", "5,2");
        }
        path.attr("d", node => {
            const xMid = node.x + (node.w / 2);

            const path = d3.path();
            path.moveTo(node.x, node.y + DASHBOARD_TEXT_AREA_HEIGHT);
            path.lineTo(node.x, node.y)
            path.lineTo(xMid, node.y);
            path.lineTo(xMid, node.y + DASHBOARD_TEXT_AREA_HEIGHT);
            path.lineTo(node.x, node.y + DASHBOARD_TEXT_AREA_HEIGHT);
            path.lineTo(node.x, node.y + node.h);
            path.lineTo(node.x + node.w, node.y + node.h);
            path.lineTo(node.x + node.w, node.y + DASHBOARD_TEXT_AREA_HEIGHT);
            path.lineTo(xMid, node.y + DASHBOARD_TEXT_AREA_HEIGHT);
            return path.toString();
        })
        this.applyPathColorAttributes(path, colorAttributes, false);
        return path;
    }

    protected renderCircle(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                           colorAttributes: ColorAttributes) {
        const circle = nodeGroup.append("circle")
            .attr("cx", node => node.x + (node.w / 2))
            .attr("cy", node => node.y + (node.h / 2))
            .attr("r", node => node.w / 2);
        this.applyCircleColorAttributes(circle, colorAttributes, false);
        return circle;
    }

    protected renderElipsis(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                            colorAttributes: ColorAttributes) {
        const ellipse = nodeGroup.append("ellipse")
            .attr("cx", node => node.x + (node.w / 2))
            .attr("cy", node => node.y + (node.h / 2))
            .attr("rx", node => node.w / 2)
            .attr("ry", node => node.h / 2);
        this.applyEllipseColorAttributes(ellipse, colorAttributes, false);
        return ellipse;
    }

    protected renderCloud(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                          colorAttributes: ColorAttributes) {
        const path = nodeGroup.append("path")
            .attr("d", node => {
                const xMid = node.x + (node.w / 2);
                const yMid = node.y + (node.h / 2);

                const path = d3.path();
                path.moveTo(xMid, node.y);
                path.bezierCurveTo(xMid - HORIZONTAL_LINE_HEIGHT, node.y - HORIZONTAL_LINE_HEIGHT, node.x - (3 * HORIZONTAL_LINE_HEIGHT), node.y, node.x, yMid);
                path.bezierCurveTo(node.x - (2.7 * HORIZONTAL_LINE_HEIGHT), yMid + (1.8 * HORIZONTAL_LINE_HEIGHT), xMid - HORIZONTAL_LINE_HEIGHT, node.y + node.h + (1.3 * HORIZONTAL_LINE_HEIGHT), xMid, node.y + node.h);
                path.bezierCurveTo(xMid + (1.3 * HORIZONTAL_LINE_HEIGHT), node.y + node.h + HORIZONTAL_LINE_HEIGHT, node.x + node.w + (1.8 * HORIZONTAL_LINE_HEIGHT), node.y + node.h - (1.2 * HORIZONTAL_LINE_HEIGHT), node.x + node.w, yMid);
                path.bezierCurveTo(node.x + node.w + (4 * HORIZONTAL_LINE_HEIGHT), yMid - (3 * HORIZONTAL_LINE_HEIGHT), xMid, node.y - HORIZONTAL_LINE_HEIGHT, xMid, node.y)
                return path.toString();
            })
        this.applyPathColorAttributes(path, colorAttributes, false);
        return path;
    }

    protected renderRectangle(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                              colorAttributes: ColorAttributes) {
        const rect = nodeGroup.append("rect")
            .attr("x", node => node.x)
            .attr("y", node => node.y)
            .attr("width", node => node.w)
            .attr("height", node => node.h)
            .attr("rx", RECTANGLE_RADIUS)
            .attr("ry", RECTANGLE_RADIUS);
        this.applyRectColorAttributes(rect, colorAttributes, false);
        return rect;
    }

    protected renderRightBottomClippedRectangle(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                                colorAttributes: ColorAttributes) {
        const clipLength = 2 * ROUNDED_RECTANGLE_RADIUS;
        // render rect
        const path = nodeGroup.append("path")
            .attr("d", node => {
                const path = d3.path();
                path.moveTo(node.x, node.y);
                path.lineTo(node.x, node.y + node.h);
                path.lineTo(node.x + node.w - clipLength, node.y + node.h);
                path.lineTo(node.x + node.w, node.y + node.h - clipLength);
                path.lineTo(node.x + node.w, node.y);
                path.closePath();
                return path.toString();
            });
        this.applyPathColorAttributes(path, colorAttributes, false);
        return path;
    }

    protected renderHorizontalLineTop(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                      colorAttributes: ColorAttributes,
                                      shapeType: ShapeType) {
        nodeGroup.append("line")
            .attr("stroke", colorAttributes.stroke)
            .attr("stroke-width", colorAttributes.strokeWidth)
            .attr("fill", "none")
            .attr("x1", node => node.x)
            .attr("y1", node => node.y + HORIZONTAL_LINE_HEIGHT)
            .attr("x2", node => node.x + node.w)
            .attr("y2", node => node.y + HORIZONTAL_LINE_HEIGHT);
    }

    protected renderHorizontalLineBottom(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                         colorAttributes: ColorAttributes,
                                         shapeType: ShapeType) {
        nodeGroup.append("line")
            .attr("stroke", colorAttributes.stroke)
            .attr("stroke-width", colorAttributes.strokeWidth)
            .attr("fill", "none")
            .attr("x1", node => node.x)
            .attr("y1", node => node.y + node.h - HORIZONTAL_LINE_HEIGHT)
            .attr("x2", node => node.x + node.w)
            .attr("y2", node => node.y + node.h - HORIZONTAL_LINE_HEIGHT)
    }

    protected renderHalfRectTopLeft(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                    colorAttributes: ColorAttributes,
                                    shapeType: ShapeType) {
        nodeGroup.append("path")
            .attr("stroke", colorAttributes.stroke)
            .attr("stroke-width", colorAttributes.strokeWidth)
            .attr("fill", "none")
            .attr("d", node => {
                const path = d3.path();
                path.moveTo(node.x, node.y + HORIZONTAL_LINE_HEIGHT);
                path.lineTo(node.x + (node.w / 2), node.y + HORIZONTAL_LINE_HEIGHT);
                path.lineTo(node.x + (node.w / 2), node.y);
                return path.toString();
            })
    }

    protected renderAppBars(nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                            colorAttributes: ColorAttributes,
                            shapeType: ShapeType) {
        const appBarHeight = 10;
        const space = 5;
        let rect = nodeGroup.append("rect")
            .attr("x", node => node.x - (APP_BAR_WIDTH / 2))
            .attr("y", node => node.y + (2 * space))
            .attr("width", APP_BAR_WIDTH)
            .attr("height", appBarHeight);
        this.applyRectColorAttributes(rect, colorAttributes, true);

        rect = rect.clone()
            .attr("y", node => node.y + (2 * space) + appBarHeight + space)

    }

    protected renderText(text: string,
                         nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                         node: DiagramNode,
                         elementType: ArchimateElement | null,
                         elementStereotype: string | undefined,
                         shapeType: ShapeType,
                         decorations: Array<DecorationType>) {
        const availableTextArea = this.getAvailableTextArea(node, elementType, shapeType, decorations);
        const style = this.generateCssStyle(node)
        let html = `<p style="${style}">`;
        if (elementStereotype) {
            html += `<span style="font-weight: bolder; font-size: 1em">«${elementStereotype}»</span><br />`
        }
        html += text;
        html += '</p>';

        nodeGroup.append("foreignObject")
            .classed(TEXTAREA_CLASS_NAME, true)
            .attr("x", node => availableTextArea.x)
            .attr("y", node => availableTextArea.y)
            .attr("width", node => availableTextArea.w)
            .attr("height", node => availableTextArea.h)
            .html(d => html);
    }

    private generateCssStyle(node: DiagramNode) {
        let css = "";
        css += "color: " + NodeRendererUtils.createFontColor(node) + ";";
        css += "font-family: " + NodeRendererUtils.createFontFamily(node) + ";";
        css += "font-size: " + NodeRendererUtils.createFontSize(node) + "px;";
        css += "font-style: " + FontStyleBuilder.buildFontStyle(this.getFont(node)) + ";";
        css += "font-weight: " + FontStyleBuilder.buildFontWeight(this.getFont(node)) + ";";
        css += "text-decoration: " + FontStyleBuilder.buildTextDecoration(this.getFont(node)) + ";";
        css += "text-align: center; margin: 0; padding: 0; border: 0; white-space: pre-line; user-select: none;";
        css += "line-height:" + NodeRendererUtils.createLineHeight() + ";";
        css += "overflow-wrap: break-word;";
        return css;
    }

    private getFont(node: DiagramNode): FontDto | null {
        return node.style?.font || null;
    }

    private getAvailableTextArea(node: DiagramNode,
                                 elementType: ArchimateElement | null,
                                 shapeType: ShapeType,
                                 decorations: Array<DecorationType>): Area {
        let x: number;
        let y: number;
        let width: number;
        let height: number;

        const elementTypeKey = elementType?.elementType;
        if (elementTypeKey === ArchimateElementType.GROUPING || node.type === NodeType.CONTAINER) {
            x = node.x + 2;
            y = node.y + 2;
            width = (node.w / 2) - 4;
            height = DASHBOARD_TEXT_AREA_HEIGHT - 4;
        } else if (DiagramEditorUtils.isNoteNode(node)) {
            x = node.x + NODE_GROUP_DEFAULT_PADDING;
            y = node.y + NODE_GROUP_DEFAULT_PADDING;
            width = node.w - (2 * (NODE_GROUP_DEFAULT_PADDING));
            height = node.h - (2 * NODE_GROUP_DEFAULT_PADDING);
        } else {
            x = node.x + NODE_GROUP_DEFAULT_PADDING + ICON_FONT_SIZE;
            y = node.y + NODE_GROUP_DEFAULT_PADDING;
            width = node.w - (2 * (NODE_GROUP_DEFAULT_PADDING + ICON_FONT_SIZE));
            height = node.h - (2 * NODE_GROUP_DEFAULT_PADDING);

            // app bar decoration
            if (decorations.indexOf(DecorationType.APP_BARS) !== -1) {
                x = x + (APP_BAR_WIDTH / 2);
                width = width - (APP_BAR_WIDTH / 2);
            }
            // top decorations
            if (decorations.indexOf(DecorationType.HALF_RECT_TOP_LEFT) !== -1 || decorations.indexOf(DecorationType.HORIZONTAL_LINE_TOP) !== -1) {
                y = y + HORIZONTAL_LINE_HEIGHT;
                height = height - HORIZONTAL_LINE_HEIGHT;
            }
            // bottom decorations
            if (decorations.indexOf(DecorationType.HORIZONTAL_LINE_BOTTOM) !== -1) {
                height = height - HORIZONTAL_LINE_HEIGHT;
            }
            // shapes restrictions
            if (shapeType === ShapeType.WAVED_RECTANGLE) {
                height = height - HORIZONTAL_LINE_HEIGHT - NODE_GROUP_DEFAULT_PADDING;
            }
            if (shapeType === ShapeType.BLOCK) {
                y = y + HORIZONTAL_LINE_HEIGHT;
                height = height - HORIZONTAL_LINE_HEIGHT;
                width = width - HORIZONTAL_LINE_HEIGHT;
            }
            if (shapeType === ShapeType.DEVICE) {
                height = height - HORIZONTAL_LINE_HEIGHT;
            }
            if (shapeType === ShapeType.RIGHT_TOP_FOLDED_RECTANGLE) {
                width = width - HORIZONTAL_LINE_HEIGHT;
            }
            if (shapeType === ShapeType.ELIPSIS) {
                x = x + HORIZONTAL_LINE_HEIGHT;
                width = width - (2 * HORIZONTAL_LINE_HEIGHT);
            }
        }

        return new Area(
            x,
            y,
            width,
            height);
    }

    protected renderIcon(node: DiagramNode,
                         elementType: ArchimateElement | null,
                         nodeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                         shapeType: ShapeType,
                         decorations: Array<DecorationType>) {
        if (elementType) {
            nodeGroup.append('text')
                .attr('class', "archimate-icon")
                .attr('fill', 'black')
                .attr('font-size', ICON_FONT_SIZE)
                .attr('pointer-events', 'none')
                .style("user-select", "none")
                .attr("x", node => this.getIconX(node, elementType?.elementType, shapeType, decorations))
                .attr("y", node => this.getIconY(node, elementType?.elementType, shapeType, decorations))
                .text(d => {
                    if (elementType) {
                        const codepoint = parseInt(elementType.iconCodepoint);
                        return String.fromCharCode(codepoint);
                    } else {
                        return "";
                    }
                });
        } else if (DiagramEditorUtils.isDiagramReferenceNode(node)) {
            nodeGroup.append("use")
                .attr("href", "#" + NodeRendererUtils.DIAGRAM_SYMBOL_ID)
                .attr("x", this.getIconX(node, elementType, shapeType, decorations))
                .attr("y", node => this.getIconY(node, elementType, shapeType, decorations))
                .attr("width", 15)
                .attr("height", 15);
        }
    }

    private getIconX(node: DiagramNode,
                     elementType: ArchimateElementType | null,
                     shapeType: ShapeType,
                     decorations: Array<DecorationType>) {
        let x = node.x + node.w - NODE_GROUP_DEFAULT_PADDING - ICON_FONT_SIZE;
        if (shapeType === ShapeType.BLOCK) {
            x = x - HORIZONTAL_LINE_HEIGHT;
        }
        return x;
    }

    private getIconY(node: DiagramNode,
                     elementType: ArchimateElementType | null,
                     shapeType: ShapeType,
                     decorations: Array<DecorationType>) {
        let y;
        if (DiagramEditorUtils.isDiagramReferenceNode(node)) {
            y = node.y + NODE_GROUP_DEFAULT_PADDING;
        } else {
            y = node.y + NODE_GROUP_DEFAULT_PADDING + ICON_FONT_SIZE - (ICON_FONT_SIZE / 5);
            if (decorations.indexOf(DecorationType.HALF_RECT_TOP_LEFT) !== -1 || decorations.indexOf(DecorationType.HORIZONTAL_LINE_TOP) !== -1) {
                y = y + HORIZONTAL_LINE_HEIGHT;
            }
            if (shapeType === ShapeType.BLOCK) {
                y = y + HORIZONTAL_LINE_HEIGHT;
            }
        }
        return y;
    }

    private applyRectColorAttributes(rect: d3.Selection<SVGRectElement, DiagramNode, null, undefined>,
                                     colorAttributes: ColorAttributes,
                                     isDecoration: boolean) {
        let fillColor: string
        if (colorAttributes.fillGradientId && !isDecoration) {
            fillColor = `url(#${colorAttributes.fillGradientId})`;
        } else {
            fillColor = isDecoration ? colorAttributes.fillDarker : colorAttributes.fill;
        }
        rect
            .attr("fill", fillColor)
            .attr("stroke", colorAttributes.stroke)
            .attr("stroke-width", colorAttributes.strokeWidth)
    }

    private applyPathColorAttributes(rect: d3.Selection<SVGPathElement, DiagramNode, null, undefined>,
                                     colorAttributes: ColorAttributes,
                                     isDecoration: boolean) {
        let fillColor: string
        if (colorAttributes.fillGradientId && !isDecoration) {
            fillColor = `url(#${colorAttributes.fillGradientId})`;
        } else {
            fillColor = isDecoration ? colorAttributes.fillDarker : colorAttributes.fill;
        }
        rect
            .attr("fill", fillColor)
            .attr("stroke", colorAttributes.stroke)
            .attr("stroke-width", colorAttributes.strokeWidth)
    }

    private applyEllipseColorAttributes(rect: d3.Selection<SVGEllipseElement, DiagramNode, null, undefined>,
                                        colorAttributes: ColorAttributes,
                                        isDecoration: boolean) {
        let fillColor: string
        if (colorAttributes.fillGradientId && !isDecoration) {
            fillColor = `url(#${colorAttributes.fillGradientId})`;
        } else {
            fillColor = isDecoration ? colorAttributes.fillDarker : colorAttributes.fill;
        }
        rect
            .attr("fill", fillColor)
            .attr("stroke", colorAttributes.stroke)
            .attr("stroke-width", colorAttributes.strokeWidth)
    }

    private applyCircleColorAttributes(rect: d3.Selection<SVGCircleElement, DiagramNode, null, undefined>,
                                       colorAttributes: ColorAttributes,
                                       isDecoration: boolean) {
        let fillColor: string
        if (colorAttributes.fillGradientId && !isDecoration) {
            fillColor = `url(#${colorAttributes.fillGradientId})`;
        } else {
            fillColor = isDecoration ? colorAttributes.fillDarker : colorAttributes.fill;
        }
        rect
            .attr("fill", fillColor)
            .attr("stroke", colorAttributes.stroke)
            .attr("stroke-width", colorAttributes.strokeWidth)
    }
}
