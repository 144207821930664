import EventManager, {Unsubscriber} from "../../../../event/EventManager";
import {ISelectionManagerApi, ISelectionProducer} from "../ISelectionProducer";
import RenderContext from "../../../context/RenderContext";
import {EventType, IChartEvent} from "../../../../event/Event";

export default class KeyPressSelection implements ISelectionProducer {

    private eventManager: EventManager;
    private api: ISelectionManagerApi;

    private renderContext?: RenderContext;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager,
                api: ISelectionManagerApi) {
        this.eventManager = eventManager;
        this.api = api;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_KEYDOWN, this.handleChartEvent.bind(this)));
    }

    onDestroy() {
        this.destroy();
    }
    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    private handleChartEvent(event: IChartEvent): void {
        const jsEvent = event.event;
        if (event.type === EventType.CHART_KEYDOWN) {
            if (jsEvent.key.toLowerCase() === "a" && (jsEvent.ctrlKey === true || jsEvent.metaKey === true)) {
                jsEvent.preventDefault();
                this.api.onSelectAll(jsEvent);
            }
            if (jsEvent.key.toLowerCase() === "d" && (jsEvent.ctrlKey === true || jsEvent.metaKey === true)) {
                jsEvent.preventDefault();
                this.api.onDeselectAll(jsEvent);
            }
        }
    }

}