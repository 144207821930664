import {Event} from "../../common/event/Event";

export enum GridEventType {
    BULK_COLLECTIONS_ELEMENTS_REMOVED = "BULK_COLLECTIONS_ELEMENTS_REMOVED",
    BULK_LABELS_ELEMENTS_REMOVED = "BULK_LABELS_ELEMENTS_REMOVED",
    BULK_COLLECTIONS_DIAGRAMS_REMOVED = "BULK_COLLECTIONS_DIAGRAMS_REMOVED",
    BULK_LABELS_DIAGRAMS_REMOVED = "BULK_LABELS_DIAGRAMS_REMOVED",
    UPDATE_SELECTED_ROWS_IDS = "UPDATE_SELECTED_ROWS_IDS",
}

export interface BulkCollectionsElementsRemovedEvent extends Event {
    type: GridEventType.BULK_COLLECTIONS_ELEMENTS_REMOVED,
}

export interface BulkLabelsElementsRemovedEvent extends Event {
    type: GridEventType.BULK_LABELS_ELEMENTS_REMOVED,
}

export interface BulkCollectionsDiagramsRemovedEvent extends Event {
    type: GridEventType.BULK_COLLECTIONS_DIAGRAMS_REMOVED,
}

export interface BulkLabelsDiagramsRemovedEvent extends Event {
    type: GridEventType.BULK_LABELS_DIAGRAMS_REMOVED,
}

export interface UpdateSelectedRowsIdsEvent extends Event {
    type: GridEventType.UPDATE_SELECTED_ROWS_IDS,
    selectedRowIds: Array<string>,
}