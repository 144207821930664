import React, {useState} from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
    AutocompleteRenderGroupParams,
    Checkbox,
    Autocomplete,
    ClickAwayListener,
} from "@mui/material";
import TextField from "./textfield/TextField";
import {FilterOptionsState} from "@mui/material/useAutocomplete";

export type LabeledComponent = {
    label: string,
    component: JSX.Element | string,
}

// state & props

interface IProps<T> {
    options: T[],
    selectedValue?: T | null,
    className?: string,
    id: string,
    label: string,
    getRenderLabel: (value: T) => LabeledComponent | string,
    handleOnChange: (changedValues: T) => void,
    required?: boolean,
    error?: boolean,
    size?: "small" | "medium" | undefined,
    groupBy?: (option: T) => string,
    renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
    freesolo?: boolean;
    filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[],
    defaultValue?: T;
    readonly?: boolean,
    disabled?: boolean,
    errorMessage?: string,
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small" color={"primary"}/>;

export default function SingleselectComboBox<T>(props: IProps<T>) {
    const [opened, setOpened] = useState<boolean>(false);

    const {
        options, selectedValue, className, id, label, handleOnChange, error,
        groupBy, renderGroup, freesolo, filterOptions, defaultValue, required, disabled, readonly, errorMessage
    } = props;

    function normalizeRenderLabel(option: T): LabeledComponent {
        if(props.getRenderLabel) {
            const renderedLabel = props.getRenderLabel(option);
            if (typeof renderedLabel === 'string') {
                return {
                    label: renderedLabel,
                    component: renderedLabel,
                }
            } else {
                return renderedLabel;
            }
        } else {
            const label = JSON.stringify(option);
            return {
                label: label,
                component: label,
            }
        }
    }

    function renderLabel(option: T): string {
        return normalizeRenderLabel(option).label;
    }

    function renderComponent(option: T): JSX.Element | string {
        return normalizeRenderLabel(option).component;
    }

    function getOptionKey(option: T) {
        return renderLabel(option);
    }

    if (readonly) {
        return (
            <TextField {...props}
                       variant="outlined"
                       label={label}
                       defaultValue={defaultValue ? renderLabel(defaultValue) : null}
                       placeholder=""
                       error={error}
                       required={required}
                       disabled={disabled}
                       InputLabelProps={{shrink: true}}
                       InputProps={{readOnly: true}}/>
        );
    }
    return (
        <div>
            <ClickAwayListener onClickAway={() => setOpened(false)}>
                <Autocomplete
                    open={opened}
                    onOpen={() => setOpened(true)}
                    onClose={() => setOpened(false)}
                    groupBy={groupBy}
                    renderGroup={renderGroup}
                    disableCloseOnSelect={false}
                    defaultValue={defaultValue}
                    value={selectedValue}
                    size={"small"}
                    className={className}
                    multiple={false}
                    limitTags={1}
                    id={id}
                    freeSolo={freesolo}
                    filterOptions={filterOptions}
                    forcePopupIcon={true}
                    options={options}
                    disabled={disabled}
                    renderOption={(autProps, option, {selected}) => (
                        <li {...autProps} key={getOptionKey(option)}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                                color={"primary"}
                            />
                            {renderComponent(option)}
                        </li>
                    )}
                    getOptionLabel={option => renderLabel(option as T)}
                    onChange={(e, values) => {
                        handleOnChange(values as T);
                        e.stopPropagation();
                    }}
                    renderInput={(params) => (
                        <TextField {...params}
                                   variant="outlined"
                                   label={label}
                                   placeholder=""
                                   error={error}
                                   required={required}
                                   errorMessage={errorMessage}
                                   InputLabelProps={{shrink: true}}
                        />
                    )}
                />
            </ClickAwayListener>
        </div>
    )
}
