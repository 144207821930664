import {MetamodelValidationReportDto} from "../../../common/apis/MetamodelService";
import {DiagramNode} from "../../../common/diagrameditor/model/Model";
import {Event} from "../../../common/event/Event"


export enum ValidationEventType {
    HIGHLIGHT_REPORTED_OBJECTS_REQUEST = "validation.HIGHLIGHT_REPORTED_OBJECTS_REQUEST",
    REPORTED_OBJECTS_HIGHLIGHTED = "validation.REPORTED_OBJECTS_HIGHLIGHTED",
    UNHIGHLIGHT_REPORTED_OBJECTS_REQUEST = "validation.UNHIGHLIGHT_REPORTED_OBJECTS_REQUEST",
    REPORTED_OBJECTS_UNHIGHLIGHTED = "validation.REPORTED_OBJECTS_UNHIGHLIGHTED",
}

export interface HighlightReportedObjectsRequestEvent extends Event {
    type: ValidationEventType.HIGHLIGHT_REPORTED_OBJECTS_REQUEST,
    report: MetamodelValidationReportDto,
}

export interface ReportedObjectsHighlightedEvent extends Event {
    type: ValidationEventType.REPORTED_OBJECTS_HIGHLIGHTED,
    nodes: DiagramNode[],
}

export interface UnhighlightReportedObjectsRequestEvent extends Event {
    type: ValidationEventType.UNHIGHLIGHT_REPORTED_OBJECTS_REQUEST
}

export interface ReportedObjectsUnhighlightedEvent extends Event {
    type: ValidationEventType.REPORTED_OBJECTS_UNHIGHLIGHTED,
    nodes: DiagramNode[],
}