export enum GenerateMetamodelTranslationKey {
    CHOOSE_MODEL_TITLE = "tools.generateMetamodel.chooseModel",
    GENERATED_METAMODEL = "tools.generateMetamodel.generatedMetamodel",
    GENERATE_METAMODEL = "tools.generateMetamodel.generateMetamodelButton",
    METAMODEL_GENERATING_IN_PROGRESS = "tools.generateMetamodel.lastStepActionInProgressText",
    METAMODEL_WAS_EXPORTED = "tools.generateMetamodel.lastStepActionSuccessfulText",
    METAMODEL_EXPORT_FAILED = "tools.generateMetamodel.lastStepActionFailedText",
    CHOOSE_FILE_TYPE = "tools.generateMetamodel.chooseFileType",
    TYPE = "tools.generateMetamodel.type",
    CHOOSE_ARCHIMATE_MODEL = "tools.generateMetamodel.chooseArchimateModel",
}