import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React from "react";
import {DialogContentText as MuiDialogContentText, useTheme} from "@mui/material";
import {DialogContentTextProps as MuiDialogContentTextProps} from "@mui/material/DialogContentText/DialogContentText";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialogContentText: {
        paddingTop: "1em",
    },
}));

export interface DialogContentTextProps extends MuiDialogContentTextProps {

}

export default function DialogContentText(props: DialogContentTextProps) {
    const classes = useStyles(useTheme());

    return (
        <MuiDialogContentText className={classes.dialogContentText}>
            {props.children}
        </MuiDialogContentText>
    );
}
