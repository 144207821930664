import {TranslationFunction} from "../../../../store/localization/TranslMessasge";
import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import moment from "moment";
import {GraphQueryTranslationKey} from "./GraphQueryTranslationKey";
import {GraphQueryFormatter} from "./GraphQueryFormatter";

const dateFormatter = (params: GridValueFormatterParams) => params.value != null ? moment(params.value as Date).format("DD.MM.YYYY") : "";

export function createGraphQueryGridColDef(_transl: TranslationFunction): GridColDef[] {
    return [
        {
            field: 'id',
            headerName: _transl(GraphQueryTranslationKey.IDENTIFIER),
            width: 300
        },
        {
            field: 'name',
            headerName: _transl(GraphQueryTranslationKey.NAME),
            width: 300
        },
        {
            field: 'description',
            headerName: _transl(GraphQueryTranslationKey.DESCRIPTION),
            width: 150,
        },
        {
            field: 'queryText',
            headerName: _transl(GraphQueryTranslationKey.QUERY_TEXT),
            width: 150,
        },
        {
            field: 'isPrivate',
            headerName: _transl(GraphQueryTranslationKey.ACCESS),
            width: 150,
            valueFormatter: params => GraphQueryFormatter.formatIsPrivate(params.value, _transl)
        },
        {
            field: 'author',
            headerName: _transl(GraphQueryTranslationKey.AUTHOR),
            width: 150,
            valueGetter: params=> params.value.login
        },
        {
            field: 'created',
            headerName: _transl(GraphQueryTranslationKey.CREATED),
            width: 200,
            valueFormatter: dateFormatter
        },
        {
            field: 'lastUpdated',
            headerName: _transl(GraphQueryTranslationKey.LAST_CHANGED),
            width: 200,
            valueFormatter: dateFormatter
        },
    ]
}
