import {Event} from '../../../../common/event/Event'

export enum HierarchyTreeEventType {
    SUBTREE_RELOAD_REQUEST = "TREES_SUBTREE_RELOAD_REQUEST",
}

export interface SubtreeReloadRequestEvent extends Event {
    type: HierarchyTreeEventType.SUBTREE_RELOAD_REQUEST;
    subtreeId: string;
    subtreePath: string;
}