import {StringNullable} from "../../common/Types";
import {Action, Reducer} from "redux";
import {ActionsObservable, StateObservable} from "redux-observable";
import {IApplicationState} from "../Store";
import {catchError, filter, startWith, switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";

// Resource type

export enum FetchableResourceType {
    // Common
    COMMON_COLLECTION_OPTIONS = "COMMON_COLLECTION_OPTIONS",
    COMMON_LABEL_OPTIONS = "COMMON_LABEL_OPTIONS",
    COMMON_VIEWPOINT_OPTIONS = "COMMON_VIEWPOINT_OPTIONS",
    COMMON_STATE_OPTIONS = "COMMON_STATE_OPTIONS",
    COMMON_TYPE_OPTIONS = "COMMON_TYPE_OPTIONS",
    COMMON_AUTHOR_OPTIONS = "COMMON_AUTHOR_OPTIONS",

    // Page specific
    ELEMENTS_PAGE_DIALOGGRID = "ELEMENTS_PAGE_DIALOGGRID",
    ELEMENTS_DETAIL_PAGE_ATTACHMENTS = "ELEMENTS_DETAIL_PAGE_ATTACHMENTS",
    DIAGRAM_DETAIL_PAGE_ATTACHMENTS = "DIAGRAM_DETAIL_PAGE_ATTACHMENTS",

    DIAGRAMS_PAGE_GRID = "DIAGRAMS_PAGE_GRID",
    DIAGRAMS_PAGE_DIALOGGRID = "DIAGRAMS_PAGE_DIALOGGRID",
}

// Action

export enum FetchActionType {
    FETCH = "ACTION/COMMON/FETCH",
    FETCH_SUCCESSFUL = "ACTION/COMMON/FETCH_SUCCESSFUL",
    FETCH_FAILED = "ACTION/COMMON/FETCH_FAILED",
    FETCH_ALREADY_STARTED = "ACTION/COMMON/FETCH_ALREADY_STARTED",
    FETCH_RESET_RESOURCE = "ACTION/COMMON/FETCH_RESET_RESOURCE",
}

export enum FetchStatusType {
    NOT_STARTED = "NOT_STARTED",
    STARTED = "STARTED",
    SUCCESSFUL = "SUCCESSFUL",
    FAILED = "FAILED",
}

export interface IFetchableResourceAction<T> {
    type: FetchActionType;
    resourceType: FetchableResourceType;
    status: FetchStatusType;
    silently?: boolean;
    error?: string;
    resource?: T;
}

// Action creators

export function getResourceFetchAlreadyStartedAction(resourceType: FetchableResourceType): IFetchableResourceAction<unknown> {
    return {
        type: FetchActionType.FETCH_ALREADY_STARTED,
        resourceType: resourceType,
        status: FetchStatusType.STARTED,
    }
}

export function getResourceFetchAction(resourceType: FetchableResourceType, fetchSilently: boolean = false): IFetchableResourceAction<unknown> {
    return {
        type: FetchActionType.FETCH,
        resourceType: resourceType,
        status: FetchStatusType.STARTED,
        silently: fetchSilently,
    }
}

export function getResourceFetchCustomAction<I, T extends IFetchableResourceAction<I>>(resourceType: FetchableResourceType, params: any, fetchSilently: boolean = false): T {
    return {
        ...getResourceFetchAction(resourceType, fetchSilently),
        ...params,
    } as T;
}

export function getResourceFetchSuccessfulAction(resourceType: FetchableResourceType, resource: unknown): IFetchableResourceAction<unknown> {
    return {
        type: FetchActionType.FETCH_SUCCESSFUL,
        resourceType: resourceType,
        status: FetchStatusType.SUCCESSFUL,
        resource: resource,
    }
}

export function getResourceFetchFailedAction(resourceType: FetchableResourceType, error: string): IFetchableResourceAction<unknown> {
    return {
        type: FetchActionType.FETCH_FAILED,
        resourceType: resourceType,
        status: FetchStatusType.FAILED,
        error: error,
    }
}

export function getResourceFetchResetResourceAction(resourceType: FetchableResourceType): IFetchableResourceAction<unknown> {
    return {
        type: FetchActionType.FETCH_RESET_RESOURCE,
        resourceType: resourceType,
        status: FetchStatusType.NOT_STARTED,
    }
}

// State

export interface IFetchStatusState {
    status: FetchStatusType;
    date: Date,
    error: StringNullable;
}

export interface IFetchableResourceState<T> {
    fetchStatus: IFetchStatusState;
    resource: T;
}

export function createInitialFetchableResourceState<T>(initialValue: T): IFetchableResourceState<T> {
    return {
        fetchStatus: {
            status: FetchStatusType.NOT_STARTED,
            date: new Date(),
            error: null,
        },
        resource: initialValue,
    }
}

// Reducer

export function createFetchableResourceReducer<I>(resourceType: FetchableResourceType, resourceInitialValue: I): Reducer<IFetchableResourceState<I>, IFetchableResourceAction<I>> {
    return composeFetchableResourceReducer<I, IFetchableResourceState<I>, IFetchableResourceAction<I>>(
        resourceType, createInitialFetchableResourceState(resourceInitialValue), resourceInitialValue
    );
}

export function composeFetchableResourceReducer<I, T extends IFetchableResourceState<I>, U extends IFetchableResourceAction<I>>(
    resourceType: FetchableResourceType, initialState: T, initialValue: I
): Reducer<T, U> {

    return (
        state = initialState,
        action
    ) => {
        if (action.resourceType === resourceType) {
            switch (action.type) {
                case FetchActionType.FETCH:
                    if (action.silently === true) {
                        return {
                            ...state,
                        }
                    } else {
                        return {
                            ...state,
                            fetchStatus: {
                                status: action.status,
                                date: new Date(),
                                error: null
                            },
                            resource: initialValue,
                        }
                    }
                case FetchActionType.FETCH_SUCCESSFUL :
                    return {
                        ...state,
                        fetchStatus: {
                            status: action.status,
                            date: new Date(),
                            error: null
                        },
                        resource: action.resource
                    }
                case FetchActionType.FETCH_FAILED :
                    return {
                        ...state,
                        fetchStatus: {
                            status: action.status,
                            date: new Date(),
                            error: action.error
                        },
                        resource: initialValue
                    }
                case FetchActionType.FETCH_RESET_RESOURCE :
                    return {
                        ...state,
                        resource: initialValue
                    }

            }
        }
        return state;
    };
}

// Epic

export function createFetchableResourceEpic(
    resourceType: FetchableResourceType,
    apiCall: (action: IFetchableResourceAction<any>) => Observable<any>,
) {
    return composeFetchableResourceEpic<IFetchableResourceAction<any>>(
        FetchActionType.FETCH,
        resourceType,
        apiCall,
        (action) => action.resourceType);
}

export function createCustomActionFetchableResourceEpic<A extends IFetchableResourceAction<any>>(
    resourceType: FetchableResourceType,
    apiCall: (action: A) => Observable<AjaxResponse>,
) {
    return composeFetchableResourceEpic<A>(
        FetchActionType.FETCH,
        resourceType,
        apiCall,
        (action) => action.resourceType);
}

export function composeFetchableResourceEpic<T extends Action>(
    actionType: T["type"],
    requiredResourceType: FetchableResourceType,
    apiCall: (action: T) => Observable<AjaxResponse>,
    resourceType: (action: T) => FetchableResourceType,
) {
    return (action$: ActionsObservable<T>, $state: StateObservable<IApplicationState>) =>
        action$.ofType(actionType)
            .pipe(
                filter((action) => resourceType(action) === requiredResourceType),
                switchMap((action) => {
                    const startAction = actionType === FetchActionType.FETCH ?
                        getResourceFetchAlreadyStartedAction(resourceType(action)) : getResourceFetchAction(resourceType(action));
                    return apiCall(action).pipe(
                        switchMap((xhr: AjaxResponse) => {
                            return of(getResourceFetchSuccessfulAction(resourceType(action), xhr.response ? xhr.response : xhr))
                        }),
                        catchError((error) => of(getResourceFetchFailedAction(resourceType(action), "Data se nezdařilo načíst."))),
                        startWith(startAction),
                    )
                }),
            );
}
