import React, {useEffect, useRef, useState} from "react";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {PersistentStateId} from "../../../../store/common/Grid";
import {createGraphQueryGridColDef} from "./createGraphQueryGridColDef";
import {GraphQueryDto} from "../../../../common/apis/query/GraphQueryDto";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../components/grid/GridAction";
import DeleteIcon from "@mui/icons-material/Delete";
import {GraphQueryTranslationKey} from "./GraphQueryTranslationKey";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import Snackbar from "../snackbar/Snackbar";
import {CommonTranslation} from "../CommonTranslation";
import QueryEditorDialog from "./GraphQueryEditorDialog";
import AddIcon from "@mui/icons-material/Add";
import graphQueryService, {GraphQueryService} from "./GraphQueryService";
import {GraphQueryFilterDto} from "../../../../common/apis/query/GraphQueryFilterDto";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

export interface GraphQueryGridProps {
    queryFilter?: GraphQueryFilterDto;
    mode?: GraphQueryGridMode;
    onGridRowChange?: (query: GraphQueryDto) => void;
    resourceId: string;
}

export enum GraphQueryGridMode {
    ALL_GRID_ACTION_BUTTONS,
    NO_GRID_ACTION_BUTTON
}

export default function GraphQueryGrid({queryFilter, onGridRowChange, resourceId, ...props}: GraphQueryGridProps) {

    const mode = props.mode ? props.mode : GraphQueryGridMode.ALL_GRID_ACTION_BUTTONS;

    const service = useRef<GraphQueryService>(graphQueryService());

    const [queries, setQueries] = useState<GraphQueryDto[]>([]);
    const [selectedQuery, setSelectedQuery] = useState<GraphQueryDto | null>(null);
    const [createNewQuery, setCreateNewQuery] = useState<boolean>(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [reload, setReload] = useState<number>(0);
    const [showQueryEditorDialog, setShowQueryEditorDialog] = useState<boolean>(false);

    useEffect(() => {
        let isUnmounted = false;

        (async () => {
            try {
                let fetchedQueries: GraphQueryDto[];

                if (!queryFilter) {
                    fetchedQueries = await service.current.findAll();
                } else {
                    fetchedQueries = await service.current.searchByFilter(queryFilter);
                }

                if (!isUnmounted) {
                    setQueries(fetchedQueries);
                }
            } catch (error) {
                Snackbar.error(_transl(GraphQueryTranslationKey.QUERIES_NOT_FETCHED));
            }
        })();
        return () => {
            isUnmounted = true;
        }
    }, [reload, service, queryFilter]);

    function reloadQueries() {
        setReload(reload + 1);
    }

    function handleRowClick(newSelectedQuery: GraphQueryDto) {
        if (onGridRowChange) {
            onGridRowChange(newSelectedQuery);
        }
        setSelectedQuery(newSelectedQuery)
    }

    async function deleteRow() {
        if (selectedQuery) {
            try {
                await service.current.deleteQuery(selectedQuery.id);
                setSelectedQuery(null);
                reloadQueries();
                Snackbar.success(_transl(GraphQueryTranslationKey.DELETE_SUCCEEDED));
            } catch (e: any) {
                Snackbar.error(_transl(GraphQueryTranslationKey.DELETE_FAILED));
            }
        }
        setIsConfirmationDialogOpen(false);
    }

    function handleOnSelectionChanged(query: GraphQueryDto) {
        if (onGridRowChange) {
            onGridRowChange(query);
        }
    }

    return (
        <React.Fragment>
            <ConfirmationDialog open={isConfirmationDialogOpen}
                                title={_transl(GraphQueryTranslationKey.DELETE_TITLE)}
                                confirmationText={_transl(GraphQueryTranslationKey.DELETE_CONFIRMATION)}
                                onConfirm={() => deleteRow()}
                                onReject={() => setIsConfirmationDialogOpen(false)}
            />
            {createNewQuery &&
                <QueryEditorDialog onClosed={() => setCreateNewQuery(false)}
                                   reloadQueries={() => reloadQueries()}
                                   isReadonly={false}/>}
            {showQueryEditorDialog && selectedQuery &&
                <QueryEditorDialog onClosed={() => setShowQueryEditorDialog(false)}
                                   reloadQueries={() => reloadQueries()}
                                   queryId={selectedQuery.id}
                                   isReadonly={!selectedQuery.acl.canUpdate}/>}
            <ExtGridWrapper columns={createGraphQueryGridColDef(_transl)}
                     rows={queries}
                     rowCount={queries.length}
                     getRowId={query => query.id}
                     hideActionButtonsPanel={false}
                     onRowClick={(param) => handleRowClick(param.row as GraphQueryDto)}
                     actions={[
                         GridAction.buttonBuilder("QUERY_ADD", ActionButtonType.IMMEDIATE, _transl(GraphQueryTranslationKey.CREATE_TITLE))
                             .icon(<AddIcon/>)
                             .enabledPolicy(EnabledPolicy.ALWAYS)
                             .onClick(() => {
                                 setCreateNewQuery(true)
                             })
                             .isVisible(() => mode === GraphQueryGridMode.ALL_GRID_ACTION_BUTTONS)
                             .build(),
                         GridAction.buttonBuilder("QUERY_RUN", ActionButtonType.IMMEDIATE, _transl(GraphQueryTranslationKey.QUERY_RUN))
                             .icon(<PlayArrowIcon/>)
                             .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                             .onClick(() => setShowQueryEditorDialog(true))
                             .isVisible(() => mode === GraphQueryGridMode.ALL_GRID_ACTION_BUTTONS)
                             .build(),
                         GridAction.buttonBuilder("REMOVE", ActionButtonType.IMMEDIATE, _transl(CommonTranslation.REMOVE))
                             .icon(<DeleteIcon/>)
                             .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                             .onClick(() => setIsConfirmationDialogOpen(true))
                             .isEnabled(queries => queries.every(query => query.acl.canDelete))
                             .isVisible(() => mode === GraphQueryGridMode.ALL_GRID_ACTION_BUTTONS)
                             .build()
                     ]}
                     onSelectionChanged={(queryIds, queries) => handleOnSelectionChanged(queries[0] as GraphQueryDto)}
                     disableMultipleSelection={true}
                     peristentStateId={PersistentStateId.QUERIES_PAGE_GRID}
                     resourceId={resourceId}
            />

        </React.Fragment>
    );
}
