import {ModelManager} from "./manager/ModelManager";
import RenderMode from "./context/RenderMode";
import RenderContext from "./context/RenderContext";
import IDiagramEditorApi from "./api/IDiagramEditorApi";
import EventManager, {EventListener, Unsubscriber} from "../event/EventManager";
import SvgElementManager from "./manager/SvgElementManager";
import SelectionManager from "./manager/SelectionManager";
import SvgElementZoomManager from "./manager/SvgElementZoomManager";
import NodesRenderer from "./renderer/NodesRenderer";
import ConnectionsManager from "./renderer/ConnectionsRenderer";
import SvgElementDragManager from "./manager/SvgElementDragManager";
import ConnectionHandleOverlayManager from "./manager/ConnectionHandleOverlayManager";
import NodePositionManager from "./manager/NodePositionManager";
import ConsoleManager from "./manager/ConsoleManager";
import GridManager from "./manager/GridManager";
import {
    AlignNodesEvent,
    EventType,
    IChartEvent,
    IChartScrolledEvent,
    DiagramZoomEvent,
    IConnectionEvent,
    IModelUpdatedEvent,
    IModelUpdatedOnItemsCreatedEvent,
    INodeEvent, IPaperShowContextMenuEvent,
    ISelectionChangedEvent,
    ISvgPaperAreaUpdatedEvent,
    IUndoRedoEvent,
} from "../event/Event";
import UndoRedoManager from "./manager/UndoRedoManager";
import ElementCreateManager, {ElementDefinition} from "./manager/ElementCreateManager";
import SvgElementScrollbarsManager from "./manager/SvgElementScrollbarsManager";
import * as d3 from 'd3';
import {Point} from "./util/GeometryUtils";
import NodeLabelManager from "./manager/NodeLabelManager";
import ConnectionCreateManager from "./manager/ConnectionCreateManager";
import {DiagramEditorUtils} from "./util/DiagramEditorUtils";
import SvgPaperManager from "./manager/SvgPaperManager";
import {ArchimateElement} from "../archimate/ArchimateElement";
import {HiddenConnectionManager} from "./manager/HiddenConnectionManager";
import store from "../../store/Store";
import Constants from "../Constants";
import ConnectionHandlesRenderer from "./renderer/ConnectionHandlesRenderer";
import ConnectionOverlaysRenderer from "./renderer/ConnectionOverlaysRenderer";
import {IDiagramNodeDto} from "../apis/diagram/IDiagramNodeDto";
import {NodeType} from "../apis/diagram/NodeType";
import {ElementDto} from "../apis/element/ElementDto";
import {IModelDto} from "../apis/model/IModelDto";
import {IEditMode} from "./editor/IEditMode";
import {IMode} from "./model/IMode";
import SelectedItemsRemoveManager from "./manager/SelectedItemsRemoveManager";
import {AlignmentType} from "./common/AlignmentType";
import areAlignable from "./manager/model/eventhandlers/alignnodes/AreAlignable";
import isPositionUpdatable from "./manager/model/eventhandlers/positionupdater/IsPositionUpdatable";
import PositionDirection from "./manager/model/eventhandlers/positionupdater/PositionDirection";
import {UpdateNodesPositionEvent} from "../event/UpdateNodesPositionEvent";
import {StyleSettingsManager} from "../../diagram/editor/style/StyleSettingsManager";
import {ValidationReportHighlighter} from "../../diagram/editor/validation/ValidationReportHighlighter";
import ConnectionLabelManager from "./manager/ConnectionLabelManager";
import {Model} from "./model/Model";
import ChartGroup, {ChartGroupType} from "./common/ChartGroup";
import {MetamodelDto} from "../../pages/main/content/metamodel/extraction/MetamodelService";
import {getDefaultElementsColor} from "../apis/diagram/DefaultColorsDto";
import {NodeResizer} from "./manager/node/NodeResizer";
import {NodeSpaceDistributor} from "./manager/node/NodeSpaceDistributor";
import CopyItemsClipboardManager from "./manager/CopyItemsClipboardManager";
import PasteItemsClipboardManager from "./manager/PasteItemsClipboardManager";

export interface IPreviewMode extends IMode {
    mode: RenderMode.PREVIEW,
}

export interface IViewMode extends IMode {
    mode: RenderMode.VIEW,
    viewDiagramById: (diagramId: string) => void,
}

export interface IExportMode extends IMode {
    mode: RenderMode.EXPORT,
    viewDiagramById: (diagramId: string) => void,
}

export interface IDashboardMode extends IMode {
    mode: RenderMode.DASHBOARD,
}


export class DiagramEditor {

    private diagramEditorApi: IDiagramEditorApi;
    private diagramId: string;
    private renderMode: IMode;
    private isFirstRender: boolean;
    private renderContext?: RenderContext;

    private eventManager: EventManager;
    private svgElementManager: SvgElementManager;
    private svgElementDragManager: SvgElementDragManager;
    private svgElementZoomManager: SvgElementZoomManager;
    private modelManager: ModelManager;
    private selectionManager: SelectionManager;
    private nodePositionManager: NodePositionManager;
    private nodeResizer: NodeResizer;
    private nodeSpaceDistributor: NodeSpaceDistributor;
    private connectionPositionManager: ConnectionHandleOverlayManager;
    private consoleManager: ConsoleManager;
    private gridManager: GridManager;
    private undoManager: UndoRedoManager;
    private svgElementScrollbarsManager: SvgElementScrollbarsManager;
    private nodeLabelManager: NodeLabelManager;
    private connectionLabelManager: ConnectionLabelManager;
    private elementCreateManager: ElementCreateManager;
    private connectionCreateManager: ConnectionCreateManager;
    private svgPaperManager: SvgPaperManager;
    private hiddenConnectionsManager: HiddenConnectionManager;
    private selectedItemsRemoveManager: SelectedItemsRemoveManager;
    private copyItemsClipboardManager: CopyItemsClipboardManager;
    private pasteItemsManager: PasteItemsClipboardManager;
    private styleManager: StyleSettingsManager;
    private validationReportHighlighter: ValidationReportHighlighter;

    private nodesRenderer: NodesRenderer;
    private connectionsRenderer: ConnectionsManager;
    private connectionHandlesRenderer: ConnectionHandlesRenderer;
    private connectionOverlaysRenderer: ConnectionOverlaysRenderer;

    private unsubscribers: Array<Unsubscriber> = [];

    private constructor(diagramId: string, renderMode: IMode, eventManager: EventManager) {
        this.diagramEditorApi = {
            updateGridUnits: (units: number, event) => this.updateGridUnits(units, event),
            showGrid: (show, event) => this.showGrid(show, event),
            snapToGrid: (snap, event) => this.snapToGrid(snap, event),
            getModel: () => this.getModel() as IModelDto,
            diagramSaved: (event: any) => this.diagramSaved(event),
            getDiagramCloneForPreview: () => this.getDiagramCloneForPreview(),
            addChartScrolledEventListener: (listener: EventListener<IChartScrolledEvent>, synchronous?: boolean) => {
                if (synchronous === true) {
                    return this.eventManager.subscribeListener(EventType.CHART_SCROLLED,
                        (event: IChartScrolledEvent) => listener(event));
                } else {
                    return this.eventManager.subscribeListener(EventType.CHART_SCROLLED,
                        (event: IChartScrolledEvent) => this.callAsync(() => listener(event)));
                }
            },
            addChartRenderedListener: (listener: EventListener<IChartEvent>) => {
                return this.eventManager.subscribeListener(EventType.CHART_RENDERED,
                    (event: IChartEvent) => this.callAsync(() => listener(event)));

            },
            addModelUpdatedListener: (listener: EventListener<IModelUpdatedEvent>) => {
                return this.eventManager.subscribeListener(EventType.MODEL_UPDATED,
                    (event: IModelUpdatedEvent) => this.callAsync(() => listener(event)));
            },
            addModelUpdatedOnItemsCreatedListener: (listener: EventListener<IModelUpdatedOnItemsCreatedEvent>) => {
                return this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_CREATED,
                    (event: IModelUpdatedOnItemsCreatedEvent) => this.callAsync(() => listener(event)));
            },
            addNodeConnectionCreateByHandleListener: (listener: EventListener<INodeEvent>) => {
                return this.eventManager.subscribeListener(EventType.NODE_CONNECTION_CREATE_BY_HANDLE_ACTIVATED,
                    (event: INodeEvent) => this.callAsync(() => listener(event)));
            }
            ,
            addChartZoomListener: (listener: EventListener<DiagramZoomEvent>) => {
                return this.eventManager.subscribeListener(EventType.DIAGRAM_ZOOM_UPDATED,
                    (event: DiagramZoomEvent) => this.callAsync(() => listener(event)));
            },
            addUndoRedoListener: (listener: EventListener<IUndoRedoEvent>) => {
                return this.eventManager.subscribeListener(EventType.UNDO_REDO_UPDATED,
                    (event: IUndoRedoEvent) => this.callAsync(() => listener(event)));
            },
            addSvgPaperAreaUpdatedListener: (listener: EventListener<ISvgPaperAreaUpdatedEvent>) => {
                return this.eventManager.subscribeListener(EventType.SVG_PAPER_AREA_UPDATED,
                    (event: ISvgPaperAreaUpdatedEvent) => this.callAsync(() => listener(event)));
            },
            addSelectionChangedListener: (listener: EventListener<ISelectionChangedEvent>) => {
                return this.eventManager.subscribeListener(EventType.SELECTION_CHANGED,
                    (event: ISelectionChangedEvent) => this.callAsync(() => listener(event)));
            },
            scrollDiagram: (incrementX: number, incrementY: number, event: any) => this.scrollDiagram(incrementX, incrementY, event),
            onNodeCreateMenuActivated: (event: any, nodeType: NodeType, elementDefinition: ElementDefinition) => this.onNodeCreateMenuActivated(event, nodeType, elementDefinition),
            onNodeCreateMenuDeactivated: (event: any) => this.onNodeCreateMenuDeactivated(event),
            undo: (event) => this.undo(event),
            redo: (event) => this.redo(event),
            getNodeTypeName: (node: IDiagramNodeDto) => this.getNodeTypeName(node),
            getNodeLabel: (node: IDiagramNodeDto) => this.getNodeLabel(node),
            getNodeElementTypeStandardName: (node: IDiagramNodeDto) => this.getNodeElementTypeStandardName(node),
            getModelAccessor: () => this.modelManager.getModelAccessor(),
            selectItems: (elementIds: Array<string>, relationshipIds: Array<string>) => this.selectItems(elementIds, relationshipIds),
            getEventManager: () => this.getEventManager(),
            getStyleManager: () => this.styleManager,
            updateHiddenConnections: (show: boolean) => this.updateHiddenConnections(show),
            addElementsAndRelatedConnectionsToModel: (elements: Array<ElementDto>) => this.addElementsAndRelatedConnectionsToModel(elements),
            onAlignSelectedNodesClicked: (alignmentType: AlignmentType, alignmentNode?: IDiagramNodeDto) => this.onAlignSelectedNodesClicked(alignmentType, alignmentNode),
            areSelectedNodesAlignable: () => this.areSelectedNodesAlignable(),
            isSelectedNodesPositionUpdatable: (direction) => this.isSelectedNodesPositionUpdatable(direction),
            updateSelectedNodesPosition: (direction: PositionDirection) => this.updateSelectedNodesPosition(direction),
            getSelectedNodes: () => this.selectionManager.getSelectedNodes(),
        };

        this.diagramId = diagramId;
        this.renderMode = renderMode;

        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.UNDO_REDO_UPDATED, this.handleUndoRedoEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_DBLCLICK, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_SHOW_CONTEXT_MENU, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<IConnectionEvent>(EventType.CONNECTION_SHOW_CONTEXT_MENU, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.PAPER_SHOW_CONTEXT_MENU, this.handlePaperShowtContextMenuEvent.bind(this)));

        const diagramDefaults = store.getState().diagramDefaults;

        this.svgElementManager = new SvgElementManager(this.eventManager);
        this.svgElementZoomManager = new SvgElementZoomManager(this.eventManager);
        this.svgElementDragManager = new SvgElementDragManager(this.eventManager);
        this.modelManager = new ModelManager(this.eventManager);
        this.selectionManager = new SelectionManager(this.eventManager);
        this.nodePositionManager = new NodePositionManager(this.eventManager);
        this.nodeResizer = new NodeResizer(this.eventManager, diagramDefaults);
        this.nodeSpaceDistributor = new NodeSpaceDistributor(this.eventManager, diagramDefaults);
        this.connectionPositionManager = new ConnectionHandleOverlayManager(this.eventManager);
        this.consoleManager = new ConsoleManager();
        this.gridManager = new GridManager(this.eventManager);
        this.undoManager = new UndoRedoManager(this.eventManager);
        this.elementCreateManager = new ElementCreateManager(this.eventManager);
        this.svgElementScrollbarsManager = new SvgElementScrollbarsManager(this.eventManager);
        this.nodeLabelManager = new NodeLabelManager(this.eventManager);
        this.connectionLabelManager = new ConnectionLabelManager(this.eventManager);
        this.connectionCreateManager = new ConnectionCreateManager(this.eventManager);
        this.svgPaperManager = new SvgPaperManager(this.eventManager);
        this.hiddenConnectionsManager = new HiddenConnectionManager(this.eventManager);
        this.selectedItemsRemoveManager = new SelectedItemsRemoveManager(this.eventManager, this.renderMode);
        this.styleManager = new StyleSettingsManager(this.eventManager, this.modelManager, this.selectionManager);
        this.validationReportHighlighter = new ValidationReportHighlighter(this.eventManager, this.modelManager);
        this.copyItemsClipboardManager = new CopyItemsClipboardManager(this.eventManager, this.modelManager, this.selectionManager, this.diagramId, this.renderMode);
        this.pasteItemsManager = new PasteItemsClipboardManager(this.eventManager, this.modelManager);

        this.nodesRenderer = new NodesRenderer(this.eventManager);
        this.connectionsRenderer = new ConnectionsManager(this.eventManager);
        this.connectionHandlesRenderer = new ConnectionHandlesRenderer(this.eventManager);
        this.connectionOverlaysRenderer = new ConnectionOverlaysRenderer(this.eventManager);

        this.isFirstRender = true;
    }

    private callAsync(callback: () => void) {
        setTimeout(callback, 1);
    }

    static create(diagramId: string, renderMode: IMode, eventManager: EventManager) {
        return new DiagramEditor(diagramId, renderMode, eventManager);
    }

    public getDiagramEditorApi() {
        return this.diagramEditorApi;
    }

    public getEventManager() {
        return this.eventManager;
    }

    public getElementZoomManager() {
        return this.svgElementZoomManager;
    }

    public setTransparentPaper(transparent: boolean) {
        this.svgPaperManager.setTransparent(transparent);
    }

    init(model: IModelDto, backupModelToEdit?: Model, metamodel?: MetamodelDto) {
        this.modelManager.init(model, backupModelToEdit, metamodel, this.renderMode);
    }

    isInitialised() {
        return this.modelManager.isInitialised();
    }

    render(svgElement: SVGSVGElement, parentRect: DOMRect) {
        //this.consoleManager.init();

        ChartGroup.renderGroups(svgElement);

        this.svgElementManager.init(svgElement, parentRect, this.renderMode);

        this.renderContext = new RenderContext(this.renderMode, this.svgElementManager, this.modelManager);

        // initialise managers and renderers (order matters)
        this.gridManager.init(this.renderContext);
        this.svgElementZoomManager.init(this.renderContext);
        this.svgElementDragManager.init(this.renderContext);
        this.nodesRenderer.init(this.renderContext);
        this.connectionsRenderer.init(this.renderContext);
        this.selectionManager.init(this.renderContext);
        this.nodePositionManager.init(this.renderContext);
        this.connectionPositionManager.init(this.renderContext);
        this.elementCreateManager.init(this.renderContext);
        this.svgElementScrollbarsManager.init(this.renderContext);
        this.nodeLabelManager.init(this.renderContext);
        this.connectionLabelManager.init(this.renderContext);
        this.connectionCreateManager.init(this.renderContext);
        this.svgPaperManager.init(this.renderContext);
        this.hiddenConnectionsManager.init(this.renderContext);

        this.connectionHandlesRenderer.init(this.renderContext);
        this.connectionOverlaysRenderer.init(this.renderContext);

        // render nodes and connections
        this.nodesRenderer.render();
        this.connectionsRenderer.render();

        this.selectedItemsRemoveManager.init(this.modelManager);

        if (this.isFirstRender) {
            this.scaleOnFirstRender();
        }
        this.isFirstRender = false;

        this.eventManager.publishEvent<IChartEvent>({type: EventType.CHART_RENDERED, event: null});
    }

    private handleNodeEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_DBLCLICK) {
            if (this.renderContext?.renderMode.mode === RenderMode.VIEW) {
                if (DiagramEditorUtils.isDiagramReferenceNode(event.node) && event.node.diagramReferences && event.node.diagramReferences.length > 0) {
                    (this.renderContext?.renderMode as IViewMode).viewDiagramById(event.node.diagramReferences[0]);
                }
            } else if (this.renderContext?.renderMode.mode === RenderMode.EXPORT) {
                if (DiagramEditorUtils.isDiagramReferenceNode(event.node) && event.node.diagramReferences && event.node.diagramReferences.length > 0) {
                    (this.renderContext?.renderMode as IExportMode).viewDiagramById(event.node.diagramReferences[0]);
                }
            }
        }
        if (event.type === EventType.NODE_SHOW_CONTEXT_MENU) {
            if (this.renderContext?.isEditOrPreEdit()) {
                const clientCoords = this.getClientCoordinates(event);
                (this.renderContext?.renderMode as IEditMode).diagramApi.showNodeContextMenu(event.node,
                    this.selectionManager.getSelectedNodes(),this.selectionManager.getSelectedConnections(), clientCoords, event.transformedClientCoordinates);
            }
        }
    }

    private getClientCoordinates(event: INodeEvent | IConnectionEvent | IPaperShowContextMenuEvent): [number, number] {
        return [event.event.clientX, event.event.clientY];
    }

    private handleConnectionEvent(event: IConnectionEvent) {
        if (event.type === EventType.CONNECTION_SHOW_CONTEXT_MENU) {
            if (this.renderContext?.isEdit()) {
                const clientCoords = this.getClientCoordinates(event);
                (this.renderContext?.renderMode as IEditMode).diagramApi.showConnectionContextMenu(event.connection, this.selectionManager.getSelectedNodes(), this.selectionManager.getSelectedConnections(), clientCoords);
            }
        }
    }

    private handlePaperShowtContextMenuEvent(event: IPaperShowContextMenuEvent) {
        if (event.type === EventType.PAPER_SHOW_CONTEXT_MENU) {
            if (this.renderContext?.isEditOrPreEdit()) {
                const clientCoords = this.getClientCoordinates(event);
                (this.renderContext?.renderMode as IEditMode).diagramApi.showPaperContextMenu(clientCoords, event.transformedClientCoordinates);
            }
        }
    }

    private handleUndoRedoEvent(event: IUndoRedoEvent) {
        if (this.renderContext?.renderMode.mode === RenderMode.EDIT) {
            (this.renderContext.renderMode as IEditMode).diagramApi.notifySaveStatusUpdated(event.saveNeeded);
        }
    }

    destroy() {
        this.svgElementScrollbarsManager.destroy();
        this.svgElementManager.destroy();
        this.svgElementZoomManager.destroy();
        this.svgElementDragManager.destroy();
        this.modelManager.destroy();
        this.selectionManager.destroy();
        this.nodePositionManager.destroy();
        this.nodeResizer.destroy();
        this.nodeSpaceDistributor.destroy();
        this.connectionPositionManager.destroy();
        this.consoleManager.destroy();
        this.gridManager.destroy();
        this.undoManager.destroy();
        this.elementCreateManager.destroy();
        this.nodeLabelManager.destroy();
        this.connectionLabelManager.destroy();
        this.connectionCreateManager.destroy();
        this.svgPaperManager.destroy();
        this.hiddenConnectionsManager.destroy();
        this.selectedItemsRemoveManager.destroy();
        this.copyItemsClipboardManager.destroy();
        this.pasteItemsManager.destroy();
        this.styleManager.destroy();
        this.validationReportHighlighter.destroy();

        this.nodesRenderer.destroy();
        this.connectionsRenderer.destroy();
        this.connectionHandlesRenderer.destroy();
        this.connectionOverlaysRenderer.destroy();

        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    private scaleOnFirstRender() {
        if (this.renderMode.mode === RenderMode.EDIT || this.renderMode.mode === RenderMode.PRE_EDIT) {
            this.svgElementZoomManager.initialScaleOnEdit();
        } else if (this.renderMode.mode === RenderMode.VIEW) {
            this.svgElementZoomManager.initialScaleOnView();
        } else if (this.renderMode.mode === RenderMode.DASHBOARD) {
            this.svgElementZoomManager.initialScaleOnDashboard();
        } else if (this.renderMode.mode === RenderMode.EXPORT) {
            this.svgElementZoomManager.initialScaleOnExport();
        } else {
            // preview -> scale to fit
            this.svgElementZoomManager.initialScaleOnPreview();
        }
    }

    // PUBLIC API

    private snapToGrid(snap: boolean, event: any) {
        if (snap) {
            this.eventManager.publishEvent({type: EventType.CHART_GRID_SNAP, event: event});
        } else {
            this.eventManager.publishEvent({type: EventType.CHART_GRID_DO_NOT_SNAP, event: event});
        }
    }

    private showGrid(show: boolean, event: any) {
        if (show) {
            this.eventManager.publishEvent({type: EventType.CHART_GRID_SHOW, event: event});
        } else {
            this.eventManager.publishEvent({type: EventType.CHART_GRID_HIDE, event: event});
        }
    }

    private updateGridUnits(units: number, event: any) {
        this.eventManager.publishEvent({type: EventType.CHART_GRID_UPDATE_UNITS, units: units, event: event})
    }

    private getModel() {
        return this.modelManager.getModel();
    }

    private diagramSaved(event: any) {
        this.eventManager.publishEvent({type: EventType.CHART_SAVED, event: event});
    }

    private scaleToFit(event: any) {
        this.eventManager.publishEvent({type: EventType.CHART_ZOOM_SCALE_TO_FIT_CLICKED, event: event});
    }

    private getDiagramCloneForPreview() {
        if (this.renderContext) {
            const selection = this.renderContext.svgElementManager.getDiagramGroupSelection();
            if (!selection.empty() && selection.node() != null) {
                const node = selection.node() as SVGGElement;
                const clone: Node = node.cloneNode(true);
                const clonedSelection = d3.select(clone as SVGGElement);

                const groupTypesToKeepForPreview = new Set([ChartGroupType.CHART_GROUP, ChartGroupType.NODES_GROUP, ChartGroupType.CONNECTIONS_GROUP]);
                Object.keys(ChartGroupType)
                        .map(chartGroupTypeKey => ChartGroupType[chartGroupTypeKey as keyof typeof ChartGroupType])
                        .filter(groupType => !groupTypesToKeepForPreview.has(groupType))
                        .forEach(groupType => clonedSelection.selectAll(ChartGroup.getId(groupType, true)).remove());

                clonedSelection
                    .attr("id", "")
                    .attr("transform", "");
                clonedSelection.selectAll("[id]")
                     .attr("id", "");
                clonedSelection.selectAll("foreignObject")
                    .remove();
                clonedSelection.selectAll("text")
                    .remove();
                return clonedSelection.node() as SVGGElement;
            }
        }
        return undefined;
    }

    private scrollDiagram(incrementX: number, incrementY: number, event: any) {
        this.eventManager.publishEvent({type: EventType.NAVIGATOR_SCROLLED, increment: new Point(incrementX, incrementY), event: event})
    }

    private onNodeCreateMenuActivated(event: any, nodeType: NodeType, elementDefinition: ElementDefinition) {
        this.eventManager.publishEvent({type: EventType.NODE_CREATE_MENU_ACTIVATED, nodeType: nodeType, elementDefinition: elementDefinition, event: event});
    }

    private onNodeCreateMenuDeactivated(event: any) {
        this.eventManager.publishEvent({type: EventType.ELEMENT_CREATE_MENU_DEACTIVATED, event: event});
    }

    private undo(event: any) {
        this.undoManager.undo(event);
    }

    private redo(event: any) {
        this.undoManager.redo(event);
    }

    private getNodeTypeName(node: IDiagramNodeDto) {
        let type: string | null;
        if (node.type === NodeType.LABEL) {
            if (DiagramEditorUtils.isDiagramReferenceNode(node)) {
                type = "Odkaz na diagram";
            } else {
                type = "Poznámka";
            }
        } else if (node.type === NodeType.CONTAINER) {
            type = "Kontejner";
        } else {
            // ELEMENT
            const elementType = this.modelManager.getElementType(node.elementIdentifier);
            type = elementType && ArchimateElement.getVisibleName(elementType);
        }
        return type || "Nepojmenovaný prvek";
    }

    private getNodeLabel(node: IDiagramNodeDto) {
        return DiagramEditorUtils.getNodeLabel(node, this.modelManager);
    }

    private getNodeElementTypeStandardName(node: IDiagramNodeDto) {
        const type = this.modelManager.getElementType(node.elementIdentifier);
        return type && ArchimateElement[type].standardName;
    }

    private getNodeElementBgColor(node: IDiagramNodeDto) {
        const type = this.modelManager.getElementType(node.elementIdentifier);
        return type ? getDefaultElementsColor(ArchimateElement[type].standardName, store.getState().diagramDefaults.defaultColors) : Constants.ELEMENT_NOT_FOUND_COLOR;
    }

    private selectItems(elementIds: Array<string>, relationshipIds: Array<string>) {
        const nodes = this.modelManager.getDiagramNodesByElementIds(elementIds);
        const connections = this.modelManager.getDiagramConnectionsByRelationshipIds(relationshipIds);
        this.selectionManager.onSelectionChanged(nodes, connections, {});
    }

    private updateHiddenConnections(show: boolean) {
        if (show) {
            this.hiddenConnectionsManager.showConnections();
        } else {
            this.hiddenConnectionsManager.hideConnections();
        }
    }

    private addElementsAndRelatedConnectionsToModel(elements: Array<ElementDto>) {
        this.eventManager.publishEvent({
            type: EventType.ADD_ELEMENTS_TO_MODEL,
            elements: elements,
        });
    }

    private onAlignSelectedNodesClicked(alignmentType: AlignmentType, alignmentTarget?: IDiagramNodeDto) {
        this.eventManager.publishEvent<AlignNodesEvent>({
            type: EventType.ALIGN_NODES,
            alignmentType: alignmentType,
            alignmentTarget: alignmentTarget,
            nodes: this.selectionManager.getSelectedNodes(),
        })
    }

    private areSelectedNodesAlignable() {
        const selectedNodes = this.selectionManager.getSelectedNodes();
        const nodeIdToParentNodeIdMap = this.modelManager.getNodeIdToParentNodeIdMap(selectedNodes);
        return areAlignable(selectedNodes, nodeIdToParentNodeIdMap);
    }

    private isSelectedNodesPositionUpdatable(direction: PositionDirection) {
        const selectedNodes = this.selectionManager.getSelectedNodes();
        const nodeIdToParentChildrenMap = this.modelManager.getNodeIdToParentNodeDirectChildNodesMap(selectedNodes);
        return isPositionUpdatable(selectedNodes, nodeIdToParentChildrenMap, direction);
    }

    private updateSelectedNodesPosition(direction: PositionDirection) {
        this.eventManager.publishEvent<UpdateNodesPositionEvent>({
            type: EventType.UPDATE_NODES_POSITION,
            nodes: this.selectionManager.getSelectedNodes(),
            direction: direction,
        })
    }
}
