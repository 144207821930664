import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";

export class Interval {
    constructor(public start: number, public end: number) {
        this.start = start;
        this.end = end;
    }

    static of(start: number, end: number): Interval {
        return new Interval(start, end);
    }

    getMidNumber() {
        if (this.start === this.end) {
            return this.start;
        } else {
            return this.start + ((this.end - this.start) / 2)
        }
    }
}

export class Area {
    constructor(public x: number, public y: number, public w: number, public h: number) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }

    isComplete(): boolean {
        return this.x != null && this.y != null && this.w != null && this.h != null;
    }

    isPoint(): boolean {
        return this.isComplete() && this.w === 0 && this.h === 0;
    }

    static from(x: number, y: number, w: number, h: number): Area {
        return new Area(x, y, w, h);
    }

    static fromNode(node: IDiagramNodeDto): Area {
        return new Area(node?.x, node?.y, node?.w, node?.h);
    }

    static fromDOMRect(domRect: DOMRect) {
        return new Area(domRect.x, domRect.y, domRect.width, domRect.height);
    }

    contains(targetArea: Area) {
        return GeometryUtils.areaContainsArea(this, targetArea);
    }

    intersects(targetArea: Area) {
        return GeometryUtils.areaIntersectsArea(this, targetArea);
    }

    containsAllPoints(points: Array<Point>) {
        return GeometryUtils.areaContainsAllPoints(this, points);
    }

    containsPoint(point: Point) {
        return GeometryUtils.areaContainsPoint(this, point);
    }

    intersectsAnyLine(lines: Array<[Point, Point]>) {
        return GeometryUtils.areaIntersectsAnyLine(this, lines);
    }

    toLines(): Array<[Point, Point]> {
        return GeometryUtils.areaToLines(this);
    }

    copy() {
        return new Area(this.x, this.y, this.w, this.h);
    }

    equals(area: Area) {
        return this.x === area.x &&
            this.y === area.y &&
            this.w === area.w &&
            this.h === area.h;
    }
}

export const EMPTY_AREA = Area.from(0, 0, 0, 0);

export class Point {
    constructor(public x: number, public y: number) {
        this.x = x;
        this.y = y;
    }

    isComplete(): boolean {
        return this.x != null && this.y != null;
    }

    toArray(): [number, number] {
        return [this.x, this.y];
    }

    static of(x: number, y: number): Point {
        return new Point(x, y);
    }
}

export default class GeometryUtils {

    static areaToLines(area: Area): Array<[Point, Point]> {
        const lines: Array<[Point, Point]> = [];
        if (area) {
            if (area.isComplete()) {
                const a = new Point(area.x, area.y);
                const b = new Point(area.x + area.w, area.y);
                const c = new Point(area.x + area.w, area.y + area.h);
                const d = new Point(area.x, area.y + area.h);
                lines.push([a, b]);
                lines.push([b, c]);
                lines.push([c, d])
                lines.push([d, a]);
            }
        }
        return lines;
    }

    static areaContainsArea(container: Area, containee: Area): boolean {
        if (container && containee) {
            return container.isComplete() && containee.isComplete()
                && (containee.x + containee.w) <= (container.x + container.w)
                && (containee.x) >= (container.x)
                && (containee.y) >= (container.y)
                && (containee.y + containee.h) <= (container.y + container.h);
        }
        return false;
    }

    static areaIntersectsArea(container: Area, containee: Area): boolean {
        if (container && containee) {
            if (containee.x < container.x + container.w && container.x < containee.x + containee.w && containee.y < container.y + container.h) {
                return container.y < containee.y + containee.h;
            }
        }
        return false;
    }

    static areaContainsAllPoints(area: Area, points: Array<Point>): boolean {
        if (points && points.length > 0) {
            for (let i = 0; i < points.length; i++) {
                if (!area.containsPoint(points[i])) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    static areaContainsPoint(area: Area, point: Point): boolean {
        if (area && point) {
            return area.x <= point.x && point.x <= area.x + area.w &&
                area.y <= point.y && point.y <= area.y + area.h;
        }
        return false;
    }

    static areaIntersectsAnyLine(area: Area, lines: Array<[Point, Point]>): boolean {
        const areaLines = GeometryUtils.areaToLines(area);
        if (areaLines.length > 0) {
            if (lines && lines.length > 0) {
                for (let i = 0; i < lines.length; i++) {
                    const line = lines[i];
                    // when this area contains all line points then this area includes the line -> there's an intersection
                    if (area.containsAllPoints(line)) {
                        return true;
                    }
                    // when any of this area lines intersect the line then there's an intersection
                    for (let j = 0; j < areaLines.length; j++) {
                        const areaLine = areaLines[j];
                        if (GeometryUtils.linesIntersect(areaLine, line)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    static linesIntersect(lineA: [Point, Point], lineB: [Point, Point]): boolean {
        const a = lineA[0].x, b = lineA[0].y, c = lineA[1].x, d = lineA[1].y;
        const p = lineB[0].x, q = lineB[0].y, r = lineB[1].x, s = lineB[1].y;
        var det, gamma, lambda;
        det = (c - a) * (s - q) - (r - p) * (d - b);
        if (det === 0) {
            return false;
        } else {
            lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
            gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
            return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
        }
    }

    static getPointToPointDistance(pointA: Point, pointB: Point): number {
        return Math.sqrt(Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2));
    }

    static getPointToLineDistance(point: Point, line: [point1: Point, point2: Point]): number {
        const linePoint1 = line[0];
        const linePoint2 = line[1];
        return Math.abs(
                ((linePoint2.x - linePoint1.x) * (linePoint1.y - point.y)) -
                ((linePoint2.y - linePoint1.y) * (linePoint1.x - point.x))
            ) /
            Math.sqrt(
                Math.pow(linePoint2.x - linePoint1.x, 2) +
                Math.pow(linePoint2.y - linePoint1.y, 2)
            )
    }

    static getNodeArea(node: IDiagramNodeDto): Area {
        return new Area(node.x, node.y, node.w, node.h);
    }

    static getNodesBoundingArea(nodes: IDiagramNodeDto[]): Area {
        if (nodes.length === 0) {
            return new Area(0, 0, 0, 0);
        }

        const area = GeometryUtils.getNodeArea(nodes[0]);
        for (const node of nodes) {
            GeometryUtils.mergeNodeIntoArea(node, area);
        }
        return area;
    }

    static mergeNodeIntoArea(node: IDiagramNodeDto, area: Area) {
        return GeometryUtils.mergeRectIntoArea(node, area);
    }

    static mergeAreaIntoArea(areaToMerge: Area, area: Area) {
        return GeometryUtils.mergeRectIntoArea(areaToMerge, area);
    }

    static mergeRectIntoArea(rect: {x: number, y: number, w: number, h: number}, area: Area) {
        const minX = Math.min(rect.x, area.x);
        const minY = Math.min(rect.y, area.y);
        const maxWPoint = Math.max(rect.x + rect.w, area.x + area.w);
        const maxHPoint = Math.max(rect.y + rect.h, area.y + area.h);
        const width = maxWPoint - minX;
        const height = maxHPoint - minY;

        const mergedArea = new Area(minX, minY, width, height);
        const updated = mergedArea.x !== area.x || mergedArea.y !== area.y || mergedArea.h !== area.h || mergedArea.w !== area.w;
        area.x = mergedArea.x;
        area.y = mergedArea.y;
        area.w = mergedArea.w;
        area.h = mergedArea.h;

        return updated;
    }

    static mergeConnectionIntoArea(connection: IDiagramConnectionDto, area: Area) {
        let updated = false;
        connection.bendpoints.forEach(bendpoint => {
            const bendpointUpdated = GeometryUtils.mergePointIntoArea(bendpoint, area);
            updated = updated || bendpointUpdated;
        });
        return updated;
    }

    static mergePointIntoArea(point: {x: number, y: number}, area: Area) {
        let updated = false;
        if (point.x < area.x) {
            area.w = area.w + (area.x - point.x)
            area.x = point.x;
            updated = true;
        } else if (point.x > area.x + area.w) {
            area.w = area.w + (point.x - (area.x + area.w));
            updated = true;
        }
        if (point.y < area.y) {
            area.h = area.h + (area.y - point.y);
            area.y = point.y;
            updated = true;
        } else if (point.y > area.y + area.h) {
            area.h = area.h + (point.y - (area.y + area.h));
            updated = true;
        }
        return updated;
    }

    static getMinimalBoundingArea(areas: Array<Area>) {
        if (areas.length === 0) {
            return Area.from(0,0,0,0);
        } else if (areas.length === 1) {
            return areas[0];
        } else {
            const merge = areas[0].copy();
            areas.forEach((area, index) => {
                if (index > 0) {
                    GeometryUtils.mergeAreaIntoArea(area, merge);
                }
            })
            return merge;
        }
    }

    static getPointIncrementSoThatAreaContainsPoint(area: Area,
                                                    point: Point) {
        let increment = new Point(0, 0);
        if (!GeometryUtils.areaContainsPoint(area, point)) {
            const incrementX = GeometryUtils.getPointXIncrementSoThatAreaContainsPoint(area, point);
            const incrementY = GeometryUtils.getPointYIncrementSoThatAreaContainsPoint(area, point);
            increment = new Point(incrementX, incrementY);
        }
        return increment;
    }

    static calculateCoordinatesShift(points: Point[], startPoint: Point): Point {
        let leftmostPoint = points[0];
        for (const point of points) {
            if (point.x < leftmostPoint.x || (point.x === leftmostPoint.x && point.y < leftmostPoint.y)) {
                leftmostPoint = point;
            }
        }
        return Point.of(startPoint.x - leftmostPoint.x, startPoint.y - leftmostPoint.y);
    }

    private static getPointXIncrementSoThatAreaContainsPoint(area: Area,
                                                             point: Point) {
        let incrementX = 0;
        const areaMaxX = area.x + area.w;
        if (point.x < area.x) {
            incrementX = point.x - area.x;
        } else if (point.x > areaMaxX) {
            incrementX = point.x - areaMaxX;
        }
        return incrementX;
    }

    private static getPointYIncrementSoThatAreaContainsPoint(area: Area,
                                                             point: Point) {
        let incrementY = 0;
        const areaMaxY = area.y + area.h;
        if (point.y < area.y) {
            incrementY = point.y - area.y;
        } else if (point.y > areaMaxY) {
            incrementY = point.y - areaMaxY;
        }
        return incrementY;
    }
}