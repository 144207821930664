import {SearchProvider} from "./SearchProvider";
import {diagramsMenu} from "../../../../common/menudefinition/MenuDefinitionUtils";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import Api from "../../../../common/Api";
import {
    getFilterUpdateAction as getDiagramsFilterUpdateAction,
    IFilter as IDiagramsFilter
} from "../../../../store/diagrams/Diagrams";
import RouteDefinitionUtils from "../../../../common/routedefinition/RouteDefinitionUtils";
import {AppBarTranslationKey} from "../AppBarTranslationKey";
import {diagramsPage} from "../../../../common/routes/AppRoutes";

const diagramSearchProvider : SearchProvider = {
    id: "DIAGRAM_SEARCH",
    contextAreaConfig: {
        icon: diagramsMenu.icon,
        primaryTextKey: AppBarTranslationKey.SEARCH_BAR_DIAGRAMS,
        secondaryTextKey: AppBarTranslationKey.SEARCH_BAR_SEARCH_IN_DIAGRAMS,
        secondaryDescriptionKey: AppBarTranslationKey.SEARCH_BAR_SEARCH_BY_NAME,
    },
    resultAreaConfig: {
        toResultItem: (item) => {
            const diagram = item as DiagramInfoDto;
            return {
                id: diagram.identifier,
                name: diagram.name || "",
                type: `${diagram.identifier}`,
                description: diagram.description || "",
            }
        },
        search: (term: string) => Api.diagrams.doSearch({nameLike: term}),
        extractItemsFromResponse: (response: any) => response,
        getSearchAction: (term: string) => {
            const newFilter: IDiagramsFilter = {};
            newFilter.nameLike = term;
            return getDiagramsFilterUpdateAction(newFilter);
        },
        getRedirectUrl: () => RouteDefinitionUtils.resolvePath(diagramsPage),
        getDetailRedirectUrl: (selectedItem => RouteDefinitionUtils.resolveDiagramDetailPath(selectedItem.id))
    }
};

export default diagramSearchProvider;