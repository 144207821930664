import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Wizard from "../../../../components/wizard/Wizard";
import {IconButton, MenuItem, TextField} from "@mui/material";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../../../../common/Api";
import {WizardStep} from "../../../../components/wizard/WizardStep";
import {PersistentStateId} from "../../../../store/common/Grid";
import {GridColDef} from "@mui/x-data-grid";
import {
    GridAction,
    ActionButtonType,
    EnabledPolicy
} from "../../../../components/grid/GridAction";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ElementsPickDialog from "../elements/ElementsPickDialog";
import {BlobUtils} from "../../../../common/BlobUtils";
import ActionButtonUtils from "../../../../components/grid/ActionButtonUtils";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DiagramsPickDialog from "../diagrams/DiagramsPickDialog";
import {IModelExportDto, ModelExportFormatType} from "../../../../common/apis/Exports";
import clsx from "clsx";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {DiagramTranslationKey} from "../diagrams/DiagramTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "../elements/ElementTranslationKey";
import {ExportTranslationKey} from "./ExportTranslationKey";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import {stereotypeGetter} from "../stereotypes/StereotypeGetter";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

class GridDef {

    public static getDiagramsGridColDef(): GridColDef[] {
        return [
            {field: 'identifier', headerName: _transl(DiagramTranslationKey.GRID_HEADER_IDENTIFIER), headerClassName: 'datagrid-column', width: 150},
            {field: 'name', headerName: _transl(DiagramTranslationKey.GRID_HEADER_NAME), headerClassName: 'datagrid-column', minWidth: 280, flex: 1},
        ];
    }

    public static getElementsGridColDef(): GridColDef[] {
        return [
            {field: 'identifier', headerName: _transl(ElementTranslationKey.GRID_HEADER_IDENTIFIER), headerClassName: 'datagrid-column', width: 200},
            {field: 'stereotype', valueGetter: stereotypeGetter, headerName: _transl(DiagramTranslationKey.GRID_HEADER_STEREOTYPE), headerClassName: 'datagrid-column', width: 180},
            {field: 'name', headerName: _transl(ElementTranslationKey.GRID_HEADER_NAME), headerClassName: 'datagrid-column', width: 600},
            {field: 'description', headerName: _transl(ElementTranslationKey.GRID_HEADER_DESCRIPTION), headerClassName: 'datagrid-column', width: 170},
        ];
    }

}

const DIAGRAMS_GRID_ID = PersistentStateId.EXPORT_MODEL_DIAGRAMS_GRID;
const ELEMENTS_GRID_ID = PersistentStateId.EXPORT_MODEL_ELEMENTS_GRID;

enum ButtonId {
    ADD_ITEMS = "ADD_ITEMS",
    REMOVE_ITEM = "REMOVE_ITEM",
}

enum ElementsExportMode {
    DIAGRAMS_RELATED_ONLY = "DIAGRAMS_RELATED_ONLY",
    DIAGRAMS_RELATED_PLUS_MANUALLY_PICKED = "DIAGRAMS_RELATED_PLUS_MANUALLY_PICKED",
    ALL = "ALL",
}

const elementsGridDivBorderColor = "lightgray";
const elementsGridDivBackgroundColor = "white";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemsGridDiv: {
            color: "gray",
            backgroundColor: elementsGridDivBackgroundColor,
            padding: theme.spacing(1),
        },
        settingsDiv: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
            border: "1px solid " + elementsGridDivBorderColor,
            backgroundColor: elementsGridDivBackgroundColor,
            width: "100%",
            height: "100%",
        },
        wizardElementSelectionDescription: {
            display: "flex",
            alignItems: "center",
            gap: "1em",
        },
        explicitElementSelectionDiv: {
            marginTop: "1em",
        }
    })
);

interface ModelExportWizardProps {
    resetExport: () => void,
}

export default function ModelExportWizard(props: ModelExportWizardProps) {

    const classes = useStyles();

    const [diagrams, setDiagrams] = useState<Array<DiagramInfoDto>>([]);
    const [elementsExportMode, setElementsExportMode] = useState<ElementsExportMode>(ElementsExportMode.DIAGRAMS_RELATED_ONLY);
    const [, setElementsExportModeChangedExplicitly] = useState<boolean>(false);
    const [elements, setElements] = useState<Array<ElementDto>>([]);
    const [selectDiagramsDialogOpened, setSelectDiagramsDialogOpened] = useState<boolean>(false);
    const [selectElementsDialogOpened, setSelectElementsDialogOpened] = useState<boolean>(false);

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(
                _transl(ExportTranslationKey.MODEL_STEP_DIAGRAMSSELECTION_LABEL),
                <AccountTreeIcon />,
                <div className={classes.wizardElementSelectionDescription}>
                    <div>{_transl(ExportTranslationKey.MODEL_STEP_DIAGRAMSSELECTION_DESCRIPTION)}</div>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={() => ActionButtonUtils.clickOnImmediateButton(ButtonId.ADD_ITEMS, DIAGRAMS_GRID_ID)}
                            size="large">
                            <AddIcon/>
                        </IconButton>
                    </div>
                </div>,
                true,
                () => renderSelectDiagramsStep(),
                () => canProceedFromSelectDiagramsStep()),
            new WizardStep(
                _transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_LABEL),
                <ListAltIcon />,
                createElementsStepDescription(),
                () => isSelectElementsStepOptional(),
                () => renderSelectElementsStep(),
                () => canProceedFromSelectElementsStep()),
        ];
    }

    function createElementsStepDescription(): JSX.Element {
        const showSelectElementsGrid = elementsExportMode === ElementsExportMode.DIAGRAMS_RELATED_PLUS_MANUALLY_PICKED;

        return (
            <div>
                <div className={classes.wizardElementSelectionDescription}>
                    <div>{_transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_MENU_LABEL)}</div>
                    <div>
                        <TextField id="elements-export-type-select"
                                   select
                                   label=""
                                   value={elementsExportMode}
                                   onChange={(e) => {
                                       const exportMode = e.target.value as ElementsExportMode;
                                       onElementsExportModeChanged(exportMode);
                                   }}
                                   style={{minWidth: "10em"}}
                        >
                            {diagrams.length > 0 &&
                                <MenuItem key={ElementsExportMode.DIAGRAMS_RELATED_ONLY} value={ElementsExportMode.DIAGRAMS_RELATED_ONLY}>
                                    {_transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_MENU_DIAGRAMS_RELATED_ONLY)}
                                </MenuItem>
                            }
                            <MenuItem key={ElementsExportMode.DIAGRAMS_RELATED_PLUS_MANUALLY_PICKED} value={ElementsExportMode.DIAGRAMS_RELATED_PLUS_MANUALLY_PICKED}>
                                {diagrams.length > 0 && _transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_MENU_MANUALLY_PICKED_DIAGRAMS) }
                                {diagrams.length === 0 && _transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_MENU_MANUALLY_PICKED) }
                            </MenuItem>
                            <MenuItem key={ElementsExportMode.ALL} value={ElementsExportMode.ALL}>
                                {diagrams.length > 0 && _transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_MENU_ALL_DIAGRAMS)}
                                {diagrams.length === 0 && _transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_MENU_ALL)}
                            </MenuItem>
                        </TextField>
                    </div>
                </div>
                <div className={clsx(classes.wizardElementSelectionDescription, classes.explicitElementSelectionDiv)} style={{visibility: showSelectElementsGrid ? "visible" : "hidden"}}>
                    <div>
                        <span style={{fontWeight: "normal"}}>
                            {diagrams.length > 0 && _transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_ELEMENTS_DIAGRAMS)}
                            {diagrams.length === 0 && _transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_ELEMENTS)}
                        </span>
                    </div>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={() => ActionButtonUtils.clickOnImmediateButton(ButtonId.ADD_ITEMS, ELEMENTS_GRID_ID)}
                            size="large">
                            <AddIcon/>
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }

    function onElementsExportModeChanged(elementsExportMode: ElementsExportMode) {
        setElementsExportMode(elementsExportMode);
        setElementsExportModeChangedExplicitly(true);
        setElements([]);
    }

    function removeItems<T extends {identifier: string}>(items: Array<T>, identifiersToRemove: Array<string>) {
        return items.filter(item => identifiersToRemove.indexOf(item.identifier) === -1);
    }

    function addItems<T extends {identifier: string}>(items: Array<T>, itemsToBeAdded: Array<T>): Array<T> {
        const newItems = removeItems(itemsToBeAdded, items.map(item => item.identifier));
        return [...items, ...newItems];
    }

    function renderSelectDiagramsStep(): JSX.Element {
        return <div className={classes.itemsGridDiv}>
            {selectDiagramsDialogOpened && <DiagramsPickDialog isOpened={selectDiagramsDialogOpened}
                                                               isMultiSelection={true}
                                                               onDiagramsPicked={(pickedDiagrams) => {
                                                                   setDiagrams(addItems(diagrams, pickedDiagrams));
                                                                   setSelectDiagramsDialogOpened(false)
                                                               }}
                                                               onDialogClosed={() => setSelectDiagramsDialogOpened(false)}/>
            }
            <ExtGridWrapper
                columns={GridDef.getDiagramsGridColDef()}
                rows={diagrams}
                rowCount={diagrams.length}
                getRowId={row => row.identifier}
                actions={[
                    GridAction.buttonBuilder(ButtonId.ADD_ITEMS, ActionButtonType.IMMEDIATE, _transl(ExportTranslationKey.MODEL_DIAGRAMSGRID_BUTTON_ADD),
                        <AddIcon/>)
                        .onClick(() => setSelectDiagramsDialogOpened(true))
                        .enabledPolicy(EnabledPolicy.ALWAYS).build(),
                    GridAction.buttonBuilder(ButtonId.REMOVE_ITEM, ActionButtonType.IMMEDIATE, _transl(ExportTranslationKey.MODEL_DIAGRAMSGRID_BUTTON_REMOVE),
                        <DeleteIcon/>)
                        .onClick(selectedRowIds => setDiagrams(removeItems(diagrams, selectedRowIds as Array<string>)))
                        .build(),
                ]}
                peristentStateId={DIAGRAMS_GRID_ID}
                resourceId={"diagrams"}
            />
        </div>
    }

    function canProceedFromSelectDiagramsStep(): boolean {
        return true;
    }

    function renderSelectElementsStep(): JSX.Element {
        const showSelectElementsGrid = elementsExportMode === ElementsExportMode.DIAGRAMS_RELATED_PLUS_MANUALLY_PICKED;

        return <div className={classes.itemsGridDiv}>
            {showSelectElementsGrid && selectElementsDialogOpened &&
                <ElementsPickDialog
                    isOpened={selectElementsDialogOpened}
                    isMultiSelection={true}
                    onElementsPicked={(pickedItems) => {
                        setElements(addItems(elements, pickedItems));
                        setSelectElementsDialogOpened(false);
                    }}
                    onDialogClosed={() => setSelectElementsDialogOpened(false)}
                />}
            {showSelectElementsGrid &&
                <ExtGridWrapper
                    columns={GridDef.getElementsGridColDef()}
                    rows={elements}
                    rowCount={elements.length}
                    getRowId={row => row.identifier}
                    actions={[
                        GridAction.buttonBuilder(ButtonId.ADD_ITEMS, ActionButtonType.IMMEDIATE, _transl(ExportTranslationKey.MODEL_ELEMENTSGRID_BUTTON_ADD),
                            <AddIcon/>)
                            .enabledPolicy(EnabledPolicy.ALWAYS)
                            .onClick(() => setSelectElementsDialogOpened(true)).build(),
                        GridAction.buttonBuilder(ButtonId.REMOVE_ITEM, ActionButtonType.IMMEDIATE, _transl(ExportTranslationKey.MODEL_ELEMENTSGRID_BUTTON_REMOVE),
                            <DeleteIcon/>)
                            .onClick(selectedRowIds => setElements(removeItems(elements, selectedRowIds as Array<string>)))
                            .build(),
                    ]}
                    peristentStateId={ELEMENTS_GRID_ID}
                    resourceId={"elements"}
                />
            }
        </div>
    }

    function isSelectElementsStepOptional(): boolean {
        return diagrams.length > 0;
    }

    function canProceedFromSelectElementsStep(): boolean {
        let canProceed: boolean;
        if (isSelectElementsStepOptional()) {
            canProceed = true;
        } else {
            // only return true when export all is selected OR explicit select is choosen and at least one element has been selected
            canProceed = elementsExportMode === ElementsExportMode.ALL ||
                (elementsExportMode === ElementsExportMode.DIAGRAMS_RELATED_PLUS_MANUALLY_PICKED && elements.length > 0);
        }
        return canProceed;
    }

    function doExport(): Promise<any> {
        const dto: IModelExportDto = {
            format: ModelExportFormatType.ARCHIMATE_VERSION_3_1,
            diagramIdentifiers: diagrams.map(dto => dto.identifier),
            elementIdentifiers: elements.map(dto => dto.identifier),
            exportUnreferencedElementsAndRelationships: elementsExportMode === ElementsExportMode.ALL,
        }
        return Api.exports.exportModel(dto).toPromise();
    }


    return (
        <Wizard steps={getSteps()}
                lastStepLabel={_transl(ExportTranslationKey.MODEL_LAST_STEP_LABEL)}
                lastStepButtonLabel={_transl(ExportTranslationKey.MODEL_LAST_STEP_BUTTON_LABEL)}
                lastStepAction={() => doExport()}
                lastStepActionSuccessProcessor={(result) => {
                    const response = result as AjaxResponse;
                    const disposition = response.xhr.getResponseHeader('Content-Disposition') as string;
                    const fileName = disposition.substring(disposition?.indexOf("filename=") + 9);
                    const blob = new Blob([response.response]);

                    BlobUtils.saveBlob(blob, fileName);
                }}
                lastStepActionInProgressText={_transl(ExportTranslationKey.MODEL_LAST_STEP_IN_PROGRESS_TEXT)}
                lastStepActionSuccessfulText={_transl(ExportTranslationKey.MODEL_LAST_STEP_SUCCESSFUL_TEXT)}
                lastStepActionFailedText={_transl(ExportTranslationKey.MODEL_LAST_STEP_FAILED_TEXT)}
                cancelWizard={props.resetExport}
                wizardGridMinHeight={"25em"}
        />
    );

}
