import {Observable} from "rxjs";
import {IDiagramNodeDto} from "../../../../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../../../apis/diagram/IDiagramConnectionDto";
import {Point} from "../../../util/GeometryUtils";
import {DiagramConnectionType} from "../../../../apis/diagram/DiagramConnectionType";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import TranslationKey from "../../../TranslationKey";
import {ObjectType} from "../../../../apis/editor/ObjectType";
import {ParentNodePosition} from "../ParentNodePosition";
import {RelationshipDto} from "../../../../apis/relationship/RelationshipDto";
import {ElementDto} from "../../../../apis/element/ElementDto";
import {DiagramDefaultsDto} from "../../../../apis/diagram/DiagramDefaultsDto";
import {DiagramLayoutGenerator} from "../../../../../diagram/layout/DiagramLayoutGenerator";

export interface Manager {
    getElementById: (id: string) => ElementDto | null,
    createModelItems: (nodesToAdd: Array<IDiagramNodeDto>,
                       nodeToParentNodePositionMap: Map<string, ParentNodePosition>,
                       elementsToAdd: Array<ElementDto>,
                       connectionsToAdd: Array<IDiagramConnectionDto>,
                       relationshipsToAdd: Array<RelationshipDto>,
                       isNewElement: boolean) => void,
    getDiagramNodes: () => Array<IDiagramNodeDto>,
    getDiagramElements: () => Array<ElementDto>,
    getDiagramConnections(): Array<IDiagramConnectionDto>,
    getRelationshipById: (id: string) => RelationshipDto | null;
    createNewNodeToParentNodePositionMap: (newNodes: Array<IDiagramNodeDto>) => Map<string, ParentNodePosition>,
    showError: (text: string) => void,
}

export interface Service {
    findAllRelationshipsForElements: (elementsIds: Array<string>) => Observable<Array<RelationshipDto>>,
    generateIdentifiers: (objectTypes: Array<ObjectType>) => Observable<Array<string>>,
}

export default class AddElementsToModelHandler {

    private modelManager: Manager;
    private service: Service;
    private diagramDefaults: DiagramDefaultsDto;
    private layoutGenerator: DiagramLayoutGenerator;

    constructor(modelManager: Manager,
                service: Service,
                diagramDefaults: DiagramDefaultsDto) {
        this.modelManager = modelManager;
        this.service = service;
        this.diagramDefaults = diagramDefaults;
        this.layoutGenerator  = new DiagramLayoutGenerator(this.diagramDefaults);
    }

    async addElementsToModel(elements: Array<ElementDto>) {
        try {
            const elementsToBeAdded = this.filterOutExistingElements(elements);
            const existingElements = this.filterOutNewElements(elements);
            const relationshipsToBeAdded = await this.findRelationshipsToBeAdded(elementsToBeAdded);
            const nodesToBeAdded = await this.createNodesToBeAdded(elementsToBeAdded.concat(existingElements));
            const existingRelationships = await this.findRelationshipsToBeAdded(existingElements);
            const connectionsToBeAdded = await this.createConnectionsToBeAdded(
                this.getRelationshipsWithoutDuplicates(relationshipsToBeAdded.concat(existingRelationships)), nodesToBeAdded);
            if (this.shouldModelChange(elementsToBeAdded, relationshipsToBeAdded, nodesToBeAdded, connectionsToBeAdded)) {
                const nodeToParentNodePositionMap = this.modelManager.createNewNodeToParentNodePositionMap(nodesToBeAdded);
                this.modelManager.createModelItems(
                    nodesToBeAdded, nodeToParentNodePositionMap, elementsToBeAdded, connectionsToBeAdded, relationshipsToBeAdded, false);
            }
        } catch (error) {
            this.modelManager.showError(_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_EDITOR_ADD_ELEMENTS_TO_MODEL_FAILED));
        }
    }

    private filterOutExistingElements(elements: Array<ElementDto>) {
        return elements
            .filter(element => this.modelManager.getElementById(element.identifier) == null);
    }

    private filterOutNewElements(elements: Array<ElementDto>) {
        return elements
            .filter(element => this.modelManager.getElementById(element.identifier) != null);
    }

    public async findRelationshipsToBeAdded(elementsToBeAdded: Array<ElementDto>) {
        if (elementsToBeAdded.length === 0) {
            return [];
        }

        const relationships = await this.findRelationshipsOfNewElements(elementsToBeAdded);
        const elementIdsSet = this.getModelElementIds(elementsToBeAdded);

        let relationshipsToBeAdded = new Array<RelationshipDto>();

        for (let i = 0; i < relationships.length; i++) {
            const relationship = relationships[i];
            if (elementIdsSet.has(relationship.source.identifier) && elementIdsSet.has(relationship.target.identifier)) {
                relationshipsToBeAdded.push(relationship);
            }
        }
        return this.getRelationshipsWithoutDuplicates(relationshipsToBeAdded);
    }

    private getRelationshipsWithoutDuplicates(relationshipsToBeAdded: RelationshipDto[]) {
        const seen = new Set<string>();
        return relationshipsToBeAdded.filter(relationship => {
            const duplicate = seen.has(relationship.identifier);
            seen.add(relationship.identifier);
            return !duplicate;
        });
    }

    private async findRelationshipsOfNewElements(elementsToBeAdded: Array<ElementDto>): Promise<RelationshipDto[]> {
        const elementIds = elementsToBeAdded.map(element => element.identifier);
        return this.service
            .findAllRelationshipsForElements(elementIds)
            .toPromise();
    }

    private getModelElementIds(newElements: Array<ElementDto>): Set<string> {
        const elements = [...newElements, ...this.modelManager.getDiagramElements()];
        const elementIds = elements.map(element => element.identifier);

        var elementIdsSet = new Set<string>();
        elementIds.map(element => elementIdsSet.add(element));
        return elementIdsSet;
    }

    private async generateIdentifiers(objectTypes: Array<ObjectType>) {
        return this.service.generateIdentifiers(objectTypes)
            .toPromise();
    }

    private async createNodesToBeAdded(elements: Array<ElementDto>) {
        const layoutGenerator = new DiagramLayoutGenerator(this.diagramDefaults);
        const nodes = layoutGenerator.layoutNodes(elements, this.getFirstNodeStartPoint());
        const nodeIdentifiers = await this.generateIdentifiers(Array(nodes.length).fill(ObjectType.NODE));
        nodes.forEach((node, index) => node.identifier = nodeIdentifiers[index]);
        return nodes;
    }

    public async createConnectionsToBeAdded(relationshipsToBeAdded: Array<RelationshipDto>, nodesToBeAdded: Array<IDiagramNodeDto>) {
        const elementIdToNodesMap = this.createElementIdToNodesMap(nodesToBeAdded);
        const newConnectionIdentifiers = await this.generateConnectionIdentifiers(relationshipsToBeAdded, elementIdToNodesMap);
        const connectionsToBeAdded = relationshipsToBeAdded.flatMap(relationship => {
            const sourceNodes = elementIdToNodesMap.get(relationship.source.identifier) as Array<IDiagramNodeDto>;
            const targetNodes = elementIdToNodesMap.get(relationship.target.identifier) as Array<IDiagramNodeDto>;
            return AddElementsToModelHandler.createConnectionsForRelationship(relationship, sourceNodes, targetNodes, newConnectionIdentifiers);
        });
        const newConnections = this.filterOutExistingConnections(connectionsToBeAdded);
        return this.filterConnectionsByNodes(newConnections, nodesToBeAdded);
    }

    private filterConnectionsByNodes(connections: IDiagramConnectionDto[], nodes: IDiagramNodeDto[]): IDiagramConnectionDto[] {
        const nodeIdentifiers = new Set(nodes.map(node => node.identifier));
        const filteredConnections: IDiagramConnectionDto[] = [];
        for (const connection of connections) {
            if (nodeIdentifiers.has(connection.sourceIdentifier) || nodeIdentifiers.has(connection.targetIdentifier)) {
                filteredConnections.push(connection);
            }
        }
        return filteredConnections;
    }

    private filterOutExistingConnections(connectionsToBeAdded: IDiagramConnectionDto[]) {
        const existingConnections = this.modelManager.getDiagramConnections();
        const newConnections = new Set<IDiagramConnectionDto>();
        for (let i = 0; i < connectionsToBeAdded.length; i++) {
            const candidate = connectionsToBeAdded[i];
            let exists = false;
            for (let j = 0; j < existingConnections.length; j++) {
                const connection = existingConnections[j];
                if (candidate.relationshipIdentifier === connection.relationshipIdentifier &&
                    candidate.sourceIdentifier === connection.sourceIdentifier &&
                    candidate.targetIdentifier === connection.targetIdentifier) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                newConnections.add(candidate);
            }
        }
        return Array.from(newConnections.values());
    }

    private createElementIdToNodesMap(nodesToBeAdded: Array<IDiagramNodeDto>): Map<string, Array<IDiagramNodeDto>> {
        const allNodes = [...nodesToBeAdded, ...this.modelManager.getDiagramNodes()]
            .filter(node => node.elementIdentifier != null);
        const elementToNodesMap = new Map<string, Array<IDiagramNodeDto>>();
        for (const node of allNodes) {
            const elementId = node.elementIdentifier as string;
            let elementNodes = elementToNodesMap.get(elementId);
            if (!elementNodes) {
                elementNodes = [];
                elementToNodesMap.set(elementId, elementNodes);
            }
            elementNodes.push(node);
        }
        return elementToNodesMap;
    }

    private async generateConnectionIdentifiers(relationshipsToBeAdded: Array<RelationshipDto>,
                                                elementIdToNodesMap: Map<string, Array<IDiagramNodeDto>>) {
        let connectionsCount = 0;
        for (const relationship of relationshipsToBeAdded) {
            const sourceNodesCount = AddElementsToModelHandler.getNodesCount(relationship.source.identifier, elementIdToNodesMap);
            const targetNodesCount = AddElementsToModelHandler.getNodesCount(relationship.target.identifier, elementIdToNodesMap);
            connectionsCount += (sourceNodesCount * targetNodesCount);
        }
        const connectionObjectTypes = new Array(connectionsCount).fill("").map(noarg => ObjectType.CONNECTION);
        return await this.generateIdentifiers(connectionObjectTypes);
    }

    private static getNodesCount(elementId: string,
                                 elementIdToNodesMap: Map<string, Array<IDiagramNodeDto>>) {
        const nodes = elementIdToNodesMap.get(elementId);
        return nodes ? nodes.length : 0;
    }

    private static createConnectionsForRelationship(relationship: RelationshipDto,
                                                    sourceNodes: Array<IDiagramNodeDto>,
                                                    targetNodes: Array<IDiagramNodeDto>,
                                                    newConnectionIdentifiers: Array<string>) {
        const connections: Array<IDiagramConnectionDto> = [];
        for (const sourceNode of sourceNodes) {
            for (const targetNode of targetNodes) {
                const connectionId = newConnectionIdentifiers.pop() as string;
                connections.push(AddElementsToModelHandler.createDiagramConnection(
                    connectionId, relationship.identifier, sourceNode.identifier, targetNode.identifier));
            }
        }
        return connections;
    }

    private getFirstNodeStartPoint(): Point {
        const diagramNodes = this.modelManager.getDiagramNodes();
        if (diagramNodes.length === 0) {
            return new Point(0, 0);
        } else {
            let minX: number | null = null;
            let maxY: number | null = null;
            for (const node of diagramNodes) {
                minX = minX === null ? node.x : Math.min(minX, node.x);
                const nodeMaxY = node.y + node.h;
                maxY = maxY === null ? nodeMaxY : Math.max(maxY, nodeMaxY);
            }
            maxY = (maxY as number) + this.diagramDefaults.spacing;
            return new Point(minX as number, maxY as number);
        }
    }

    private static createDiagramConnection(connectionId: string,
                                           relationshipId: string,
                                           sourceNodeId: string,
                                           targetNodeId: string): IDiagramConnectionDto {
        return {
            identifier: connectionId,
            relationshipIdentifier: relationshipId,
            type: DiagramConnectionType.RELATIONSHIP,
            bendpoints: [],
            sourceIdentifier: sourceNodeId,
            targetIdentifier: targetNodeId,
        }
    }


    private shouldModelChange(elementsToBeAdded: Array<ElementDto>,
                              relationshipsToBeAdded: Array<RelationshipDto>,
                              nodesToBeAdded: Array<IDiagramNodeDto>,
                              connectionsToBeAdded: Array<IDiagramConnectionDto>) {
        return elementsToBeAdded.length > 0 || relationshipsToBeAdded.length > 0 || nodesToBeAdded.length > 0 || connectionsToBeAdded.length > 0;
    }
}