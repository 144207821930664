import GeometryUtils, {Area, Point} from "../util/GeometryUtils";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import SvgCanvas from "../../../components/diagrameditor/SvgCanvas";
import * as d3 from 'd3';

export const CHART_GROUP_ID = "__diagram-editor-chart-group__";
export const NODES_UNDERLAY_GROUP_ID = "__diagram-editor-nodes-underlay-group__";
export const NODES_GROUP_ID = "__diagram-editor-nodes-group__";
export const CONNECTIONS_GROUP_ID = "__diagram-editor-connections-group__";
export const HIDDEN_CONNECTIONS_GROUP_ID = "__diagram-editor-hidden-connections-group__";
export const NODES_HANDLE_OVERLAY_GROUP_ID = "__diagram-editor-nodes-handle-overlay-group__";
export const CONNECTIONS_HANDLE_OVERLAY_GROUP_ID = "__diagram-editor-connections-handle-overlay-group__";
export const NODES_HANDLE_GROUP_ID = "__diagram-editor-nodes-handle-group__";
export const NODES_OVERLAY_GROUP_ID = "__diagram-editor-nodes-overlay-group__";
export const CONNECTIONS_HANDLE_GROUP_ID = "__diagram-editor-connections-handle-group__";
export const CONNECTIONS_OVERLAY_GROUP_ID = "__diagram-editor-connections-overlay-group__";
export const NODES_CREATE_CONNECTION_HANDLE_GROUP_ID = "__diagram-editor-nodes-create-connection-handle-group__";

export enum ChartGroupType {
    CHART_GROUP = "CHART_GROUP",
    NODES_UNDERLAY_GROUP = "NODES_UNDERLAY_GROUP",
    NODES_GROUP = "NODES_GROUP",
    CONNECTIONS_GROUP = "CONNECTIONS_GROUP",
    HIDDEN_CONNECTIONS_GROUP = "HIDDEN_CONNECTIONS_GROUP",
    NODES_HANDLE_OVERLAY_GROUP = "NODES_HANDLE_OVERLAY_GROUP",
    CONNECTIONS_HANDLE_OVERLAY_GROUP = "CONNECTIONS_HANDLE_OVERLAY_GROUP",
    NODES_HANDLE_GROUP = "NODES_HANDLE_GROUP",
    NODES_OVERLAY_GROUP = "NODES_OVERLAY_GROUP",
    CONNECTIONS_HANDLE_GROUP = "CONNECTIONS_HANDLE_GROUP",
    CONNECTIONS_OVERLAY_GROUP = "CONNECTIONS_OVERLAY_GROUP",
    NODES_CREATE_CONNECTION_HANDLE_GROUP = "NODES_CREATE_CONNECTION_HANDLE_GROUP",
}

export default class ChartGroup {
    public static readonly [ChartGroupType.CHART_GROUP] = new ChartGroup(ChartGroupType.CHART_GROUP, CHART_GROUP_ID);
    public static readonly [ChartGroupType.NODES_UNDERLAY_GROUP] = new ChartGroup(ChartGroupType.NODES_UNDERLAY_GROUP, NODES_UNDERLAY_GROUP_ID);
    public static readonly [ChartGroupType.NODES_GROUP] = new ChartGroup(ChartGroupType.NODES_GROUP, NODES_GROUP_ID);
    public static readonly [ChartGroupType.CONNECTIONS_GROUP] = new ChartGroup(ChartGroupType.CONNECTIONS_GROUP, CONNECTIONS_GROUP_ID);
    public static readonly [ChartGroupType.HIDDEN_CONNECTIONS_GROUP] = new ChartGroup(ChartGroupType.HIDDEN_CONNECTIONS_GROUP, HIDDEN_CONNECTIONS_GROUP_ID);
    public static readonly [ChartGroupType.NODES_HANDLE_OVERLAY_GROUP] = new ChartGroup(ChartGroupType.NODES_HANDLE_OVERLAY_GROUP, NODES_HANDLE_OVERLAY_GROUP_ID);
    public static readonly [ChartGroupType.CONNECTIONS_HANDLE_OVERLAY_GROUP] = new ChartGroup(ChartGroupType.CONNECTIONS_HANDLE_OVERLAY_GROUP, CONNECTIONS_HANDLE_OVERLAY_GROUP_ID);
    public static readonly [ChartGroupType.NODES_HANDLE_GROUP] = new ChartGroup(ChartGroupType.NODES_HANDLE_GROUP, NODES_HANDLE_GROUP_ID);
    public static readonly [ChartGroupType.NODES_OVERLAY_GROUP] = new ChartGroup(ChartGroupType.NODES_OVERLAY_GROUP, NODES_OVERLAY_GROUP_ID);
    public static readonly [ChartGroupType.CONNECTIONS_HANDLE_GROUP] = new ChartGroup(ChartGroupType.CONNECTIONS_HANDLE_GROUP, CONNECTIONS_HANDLE_GROUP_ID);
    public static readonly [ChartGroupType.CONNECTIONS_OVERLAY_GROUP] = new ChartGroup(ChartGroupType.CONNECTIONS_OVERLAY_GROUP, CONNECTIONS_OVERLAY_GROUP_ID);
    public static readonly [ChartGroupType.NODES_CREATE_CONNECTION_HANDLE_GROUP] = new ChartGroup(ChartGroupType.NODES_CREATE_CONNECTION_HANDLE_GROUP, NODES_CREATE_CONNECTION_HANDLE_GROUP_ID);

    private type: ChartGroupType;
    private id: string;

    constructor(type: ChartGroupType, id: string) {
        this.type = type;
        this.id = id;
    }

    /* init groups hierarchy:

       CHART_GROUP
            NODES_UNDERLAY_GROUP
            NODES_GROUP
            CONNECTIONS_GROUP
            HIDDEN_CONNECTIONS_GROUP
            NODES_HANDLE_OVERLAY_GROUP
                NODES_HANDLE_GROUP
                NODES_OVERLAY_GROUP
            CONNECTIONS_HANDLE_OVERLAY_GROUP
                CONNECTIONS_HANDLE_GROUP
                CONNECTIONS_OVERLAY_GROUP
            NODES_CREATE_CONNECTION_HANDLE_GROUP
    */
    static renderGroups(svgElement: SVGSVGElement) {
        const svg = d3.select(svgElement);

        // append chart group to svg
        const chartGroup = svg.append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.CHART_GROUP));

        // append other groups to chart group
        chartGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.NODES_UNDERLAY_GROUP));
        chartGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.NODES_GROUP));
        chartGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.CONNECTIONS_GROUP));
        chartGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.HIDDEN_CONNECTIONS_GROUP));
        const nodesHandleOverlayGroup = chartGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.NODES_HANDLE_OVERLAY_GROUP));
        const connectionsHandleOverlayGroup = chartGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.CONNECTIONS_HANDLE_OVERLAY_GROUP));
        chartGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.NODES_CREATE_CONNECTION_HANDLE_GROUP));

        // append groups to nodes handle overlay group
        nodesHandleOverlayGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.NODES_HANDLE_GROUP));
        nodesHandleOverlayGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.NODES_OVERLAY_GROUP));

        // append groups to connections handle overlay group
        connectionsHandleOverlayGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.CONNECTIONS_HANDLE_GROUP));
        connectionsHandleOverlayGroup
            .append("g")
            .attr("id", ChartGroup.getId(ChartGroupType.CONNECTIONS_OVERLAY_GROUP));
    }

    static getId(type: ChartGroupType, isSelector?: boolean) {
        return (isSelector ? "#" : "") + ChartGroup[type].id;
    }

    static getNode(type: ChartGroupType): SVGGElement {
        return ChartGroup.getSelection(type).node() as SVGGElement;
    }

    static getSelection(type: ChartGroupType): d3.Selection<SVGGElement, unknown, any, undefined> {
        return d3.select(ChartGroup.getId(type, true));
    }

    static getBoundingClientRect(type: ChartGroupType): Area {
        return Area.fromDOMRect(ChartGroup.getNode(type).getBoundingClientRect());
    }

    public static getSvgNode(): SVGSVGElement {
        return d3.select(SvgCanvas.createDiagramEditorSvgId(true)).node() as SVGSVGElement;
    }

    public static getChartGroupNode(type: ChartGroupType): SVGGElement {
        return ChartGroup.getNode(type);
    }

    public static computeChartGroupsAreaRelativeTo(groupTypes: Array<ChartGroupType>, relativeToGroupNode: SVGGElement) {
        let firstArea: Area | undefined;
        groupTypes.forEach(type => {
            const area = ChartGroup.getGroupNodeArea(
                ChartGroup.getChartGroupNode(type),
                relativeToGroupNode,
                ChartGroup.getSvgNode()
            );
            if (ChartGroup.hasDimensions(area)) {
                if (firstArea == null) {
                    firstArea = area;
                } else {
                    GeometryUtils.mergeAreaIntoArea(area, firstArea);
                }
            }
        });
        return firstArea;
    }

    private static getGroupNodeArea(groupNode: SVGGElement, relativeToGroupNode: SVGGElement, svgNode: SVGSVGElement) {
        const diagramGroupPoints = DiagramEditorUtils.getGroupBBoxEdgePoints(groupNode)
        const paperGroupPoints = DiagramEditorUtils.convertPointsFromGroupToGroup(
            diagramGroupPoints,
            groupNode,
            relativeToGroupNode,
            svgNode
        );
        return DiagramEditorUtils.convertEdgePointsToArea(paperGroupPoints as [Point, Point, Point, Point]);
    }

    private static hasDimensions(area: Area) {
        return area.w > 0 || area.h > 0;
    }

    public static computeChartGroupsBoundingClientArea(groupTypes: Array<ChartGroupType>) {
        let firstArea: Area | undefined;
        groupTypes.forEach(type => {
            const area = ChartGroup.getBoundingClientRect(type);
            if (ChartGroup.hasDimensions(area)) {
                if (firstArea == null) {
                    firstArea = area;
                } else {
                    GeometryUtils.mergeAreaIntoArea(area, firstArea);
                }
            }
        });
        return firstArea;
    }

    public static computeNodesConnectionsAreaRelativeTo(relativeToNode: SVGGElement, includeOverlays: boolean) {
        const nodeTypes = [
            ChartGroupType.NODES_GROUP,
            ChartGroupType.CONNECTIONS_GROUP,
        ];
        if (includeOverlays) {
            nodeTypes.push(ChartGroupType.NODES_OVERLAY_GROUP, ChartGroupType.CONNECTIONS_OVERLAY_GROUP);
        }
        return ChartGroup.computeChartGroupsAreaRelativeTo(nodeTypes, relativeToNode) as Area;
    }

    public static computeNodesConnectionsBoundingClientArea(includeOverlays: boolean) {
        const nodeTypes = [
            ChartGroupType.NODES_GROUP,
            ChartGroupType.CONNECTIONS_GROUP,
        ];
        if (includeOverlays) {
            nodeTypes.push(ChartGroupType.NODES_OVERLAY_GROUP, ChartGroupType.CONNECTIONS_OVERLAY_GROUP);
        }
        return ChartGroup.computeChartGroupsBoundingClientArea(nodeTypes) as Area;
    }
}
