import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../../../../../store/Store";
import React, {useRef} from "react";
import {CollectionDto} from "../../../../../../../../common/apis/collection/CollectionDto";
import MultiselectComboBox from "../../../../../../../../components/fields/MultiselectComboBox";
import {_transl} from "../../../../../../../../store/localization/TranslMessasge";
import {HierarchyTreeTranslationKey} from "../../../../HierarchyTreeTranslationKey";
import {DataTypeSubformProps} from "../DataTypeSubformProps";
import {HierarchyTreeDataType} from "../../../../service/HierarchyTreeDataType";
import {LayersTreeDataDto} from "../../../../service/HierarchyTreeDataDto";
import Grid from "../../../../../../../../components/dialogs/Grid";

export default function LayersSubform({data, onChange}: DataTypeSubformProps) {
    const collectionOptions = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);
    const selectedCollections = useRef<CollectionDto[]>(getInitialCollections());

    function getInitialCollections(): CollectionDto[] {
        return data ? getCollectionsFromCodes((data as LayersTreeDataDto).collections) : [];
    }

    function getCollectionsFromCodes(codes: string[]): CollectionDto[] {
        return codes.map(code => getCollectionFromCode(code));
    }

    function getCollectionFromCode(collectionCode: string): CollectionDto {
        return collectionOptions.filter((collection) => collection.code === collectionCode)[0];
    }

    function onCollectionsAssigned(collections: CollectionDto[]): void {
        selectedCollections.current = collections;
        const collectionCodes: string[] = collections.map((collection) => collection.code);
        const newData = {type: HierarchyTreeDataType.LAYERS, collections: collectionCodes};
        onChange(newData);
    }

    return (
        <>
            <Grid item xs={12} sm={6}>
                <MultiselectComboBox
                    label={_transl(HierarchyTreeTranslationKey.SUBFORM_COLLECTIONS)}
                    id="layers-subform-multiselect-combobox"
                    className={"layers-subform-multiselect-combobox"}
                    options={collectionOptions}
                    selectedValues={selectedCollections.current}
                    getRenderLabel={(value) => value.name}
                    handleOnChange={(changedValues) => onCollectionsAssigned(changedValues)}
                    required={false}
                />
            </Grid>
        </>
    );
}
