import {IConceptDto, IPropertyDto} from "../CommonTypes";
import {CollectionDto} from "../collection/CollectionDto";
import {ILabelDto} from "../label/ILabelDto";
import {ElementAclDto} from "./ElementAclDto";
import {StringNullable} from "../../Types";
import {ChatStatsDto} from "../chat/ChatService";
import {StereotypeDto} from "../stereotype/StereotypeDto";

export interface ElementDto extends IConceptDto {
    type: string;
    validFrom?: string;
    validThru?: string;
    created?: string;
    updated?: string;
    userCreated?: string,
    userUpdated?: string,
    chatStats?: ChatStatsDto;
    collections: CollectionDto[],
    labels: ILabelDto[],
    stereotype?: StereotypeDto,
    state?: string,
    attachmentsCount?: number,
    acl: ElementAclDto,
}

export class ElementDto {
    static builder(identifier: string, type: string): ElementDtoBuilder {
        return new ElementDtoBuilder(identifier, type);
    }
}

export class ElementDtoBuilder {
    _identifier: string;
    _name?: string;
    _description?: StringNullable;
    _properties?: IPropertyDto[];
    _type: string;
    _validFrom?: string;
    _validThru?: string;
    _created?: string;
    _updated?: string;
    _userCreated?: string;
    _userUpdated?: string;
    _chatStats?: ChatStatsDto;
    _collections: CollectionDto[];
    _labels: ILabelDto[];
    _stereotype?: StereotypeDto;
    _state?: string;
    _attachmentsCount?: number;
    _acl: ElementAclDto;

    constructor(identifier: string, type: string, acl?: ElementAclDto) {
        this._identifier = identifier;
        this._type = type;
        this._acl = acl ? acl : ElementAclDto.builder().build();
        this._collections = [];
        this._labels = [];
    }

    identifier(identifier: string) {
        this._identifier = identifier;
        return this;
    }

    name(name: string) {
        this._name = name;
        return this;
    }

    description(description: string) {
        this._description = description;
        return this;
    }

    properties(properties: IPropertyDto[]) {
        this._properties = properties;
        return this;
    }

    type(type: string) {
        this._type = type;
        return this;
    }

    validFrom(validFrom: string) {
        this._validFrom = validFrom;
        return this;
    }

    validThru(validThru: string) {
        this._validThru = validThru;
        return this;
    }

    created(created: string) {
        this._created = created;
        return this;
    }

    updated(updated: string) {
        this._updated = updated;
        return this;
    }

    userCreated(userCreated: string) {
        this._userCreated = userCreated;
        return this;
    }

    userUpdated(userUpdated: string) {
        this._userUpdated = userUpdated;
        return this;
    }

    collections(collections: CollectionDto[]) {
        this._collections = collections;
        return this;
    }

    labels(labels: ILabelDto[]) {
        this._labels = labels;
        return this;
    }

    stereotype(stereotype: StereotypeDto | undefined) {
        this._stereotype = stereotype;
        return this;
    }

    state(state: string) {
        this._state = state;
        return this;
    }

    attachmentsCount(attachmentsCount: number) {
        this._attachmentsCount = attachmentsCount;
        return this;
    }

    chatStats(chatStats: ChatStatsDto) {
        this._chatStats = chatStats;
        return this;
    }

    acl(acl: ElementAclDto) {
        this._acl = acl;
        return this;
    }

    build(): ElementDto {
        return {
            identifier: this._identifier,
            name: this._name,
            description: this._description,
            properties: this._properties,
            type: this._type,
            validFrom: this._validFrom,
            validThru: this._validThru,
            created: this._created,
            updated: this._updated,
            userCreated: this._userCreated,
            userUpdated: this._userUpdated,
            chatStats: this._chatStats,
            collections: this._collections,
            labels: this._labels,
            stereotype: this._stereotype,
            state: this._state,
            attachmentsCount: this._attachmentsCount,
            acl: this._acl,
        }
    }
}
