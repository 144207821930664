import React from "react";
import {Paper} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import StereotypesGrid from "./StereotypesGrid";
import { _transl } from "../../../../store/localization/TranslMessasge";
import { StereotypesTranslationKey } from "./StereotypesTranslationKey";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
    })
);

export default function StereotypesPage() {

    // local vars
    const classes = useStyles();

    return(
        <Paper className={classes.page}>
            <div className={classes.headerPageSegment}>
                <Typography variant="h6">
                    {_transl(StereotypesTranslationKey.PAGE_STEREOTYPE_OVERVIEW)}
                </Typography>
            </div>
            <Divider />
            <Divider />
            <div className={classes.controlPageSegment}>
                <StereotypesGrid />
            </div>
        </Paper>
    )
}
