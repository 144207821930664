import {Dialog, DialogContent} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import ElementDetailPanel, {EditableProperty} from "./ElementDetailPanel";
import Api from "../../../../common/Api";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import ElementDetailHeader from "./ElementDetailHeader";
import {hideMainPageOverlay} from "../../../MainPage";

const margin = 3;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {
            minWidth: "98%",
            height: "100%",
        },
        dialogTitle: {
            color: "gray",
            padding: 0,
            textAlign: "center",
            "& hr": {
                marginLeft: theme.spacing(margin),
                marginRight: theme.spacing(margin),
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        dialogContent: {
            padding: 0,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    })
)

interface IProps {
    initialElementId: string,
    opened: boolean,
    onClosed: (reload: boolean) => void,
}

export default function ElementDetailDialog({opened, onClosed, initialElementId}: IProps) {

    const classes = useStyles();

    const [elementDto, setElementDto] = useState<ElementDto>()
    const [elementId, setElementId] = useState<string>(initialElementId);
    const [updated, setUpdated] = useState<boolean>(false);

    const updateElementId = useCallback((newElementId: string) => setElementId(newElementId), []);

    useEffect(() => {
        if (initialElementId) {
            updateElementId(initialElementId);
            fetchElement(initialElementId);
        }
    }, [initialElementId, updateElementId]);

    function fetchElement(id: string) {
        Api.elements.getByIdentifier(id)
            .subscribe({
                next: (response) =>
                    setElementDto(response.response as ElementDto),
                error: () => {
                }
            })
    }

    function onClose() {
        hideMainPageOverlay();
        onClosed(updated);
        setElementDto(undefined);
        setUpdated(false);
    }

    return (
        <React.Fragment>
            {elementDto && <Dialog open={opened}
                                   aria-labelledby="item-detail-dialog"
                                   onClose={onClose}
                                   PaperProps={{
                                       className: classes.dialogPaper,
                                   }}
            >
                <ElementDetailHeader
                    element={elementDto}
                    onClosed={onClose}
                />

                <DialogContent className={classes.dialogContent}>
                    <ElementDetailPanel id={elementId}
                                        key={elementId}
                                        onShowElementDetail={updateElementId}
                                        nonEditableProperties={[EditableProperty.NAME, EditableProperty.RELATIONSHIPS]}
                                        onUpdated={() => setUpdated(true)}
                                        onElementUpdated={fetchElement}
                    />
                </DialogContent>
            </Dialog>}
        </React.Fragment>
    );

}
