import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React from "react";
import {IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {_transl} from "../../store/localization/TranslMessasge";
import {AttachmentTranslationKey} from "./AttachmentTranslationKey";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            fontSize: "0.8em",
            fontWeight: "bolder",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginLeft: theme.spacing(2),
            "& > *": {
                marginRight: theme.spacing(1),
            }
        },
    })
);

interface Props {
    onUploadNewAttachment: () => void,
    disabled: boolean,
}

export default function IndicatorPanel(props: Props) {

    const { onUploadNewAttachment, disabled } = props;
    const classes = useStyles();

    return <div className={classes.root}>
        <div key={"showUploadDialog"}>
            <IconButton color="primary" aria-label={_transl(AttachmentTranslationKey.INDICATOR_PANEL_BUTTON)}
                        component="span" size={"small"} onClick={onUploadNewAttachment}
                        disabled={disabled}>
                <AddIcon />
            </IconButton>
        </div>
    </div>

}
