import React from "react";
import {Menu} from "@mui/material";
import {appInfoMenu, logoutMenu, profileMenu} from "../../../common/menudefinition/MenuDefinitionUtils";
import {KeycloakHolder} from "../../../keycloak/KeycloakHolder";
import AppBarMenuItem from "./AppBarMenuItem";

type Props = {
    opened: boolean;
    anchorRef: any,
    onMenuClose?: () => void;
}

export default function UserAccountMenu({opened, anchorRef, onMenuClose}: Props) {

    const onMenuCloseHandler = onMenuClose != null ? onMenuClose : () => {};

    return (
        <Menu open={opened}
              id="user-account-menu"
              anchorEl={anchorRef}
              keepMounted
              onClose={onMenuCloseHandler}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{vertical: -10, horizontal: 0}} >
            <AppBarMenuItem menuDefinition={profileMenu} onClick={onMenuCloseHandler} />
            <AppBarMenuItem menuDefinition={appInfoMenu} onClick={onMenuCloseHandler} />
            <AppBarMenuItem menuDefinition={logoutMenu} onClick={() => {
                onMenuCloseHandler();
                KeycloakHolder.logout();
            }} />
        </Menu>
    )

}
