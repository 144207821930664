import React from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import clsx from "clsx";

const styles = makeStyles((theme: Theme) =>
    createStyles({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        margin: theme.spacing(3, 0, 2, 0),
    },
    displayNone: {
        display: "none",
    }
    })
);

interface Props {
    text: string,
    opened:  boolean | undefined,
    onClose: () => void,
    alertClassName? : string,
}

export default function NotSucceededAlert(props: Props) {

        const{text, onClose, opened, alertClassName} = props;
        const classes = styles();
        const rootClassName = clsx(classes.root, {[classes.displayNone]: !opened}, alertClassName);

        return(
            <div className={rootClassName}>
                <Collapse in={opened}>
                    <Alert
                        severity={"error"}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {text}
                    </Alert>
                </Collapse>
            </div>
        );
}


