import React, {useEffect, useRef, useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Wizard from "../../../../components/wizard/Wizard";
import UploadFilePanel from "../../../../components/UploadFilePanel";
import PickList from "../../../../components/picklist/PickList";
import {CollectionDto} from "../../../../common/apis/collection/CollectionDto";
import {IApplicationState} from "../../../../store/Store";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, FormControlLabel, FormGroup, MenuItem, Select} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {WizardStep} from "../../../../components/wizard/WizardStep";
import LabelIcon from '@mui/icons-material/Label';
import SettingsIcon from '@mui/icons-material/Settings';
import {FetchableResourceType, getResourceFetchAction} from "../../../../store/common/FetchableResource";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";
import {ILabelDto} from "../../../../common/apis/label/ILabelDto";
import {OpenGroupExchangeFormat, OpenGroupExchangeFormatType} from "../../../../common/apis/OpenGroupExchangeFormat";
import {UserRole} from "../../../../common/access/UserRole";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import {IModelImportMetadataDto} from "../../../../common/apis/Imports";
import Api from "../../../../common/Api";
import {ValidationError} from "../../../../common/ValidationError";
import ConcurrentUpdateDialog from "./ConcurrentUpdateDialog";
import {ImportErrorCode} from "./ImportErrorCode";

const pickListDivBorderColor = "lightgray";
const pickListBackgroundColor = "whitesmoke";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pickListDiv: {
            color: "gray",
            border: "1px solid " + pickListDivBorderColor,
            backgroundColor: pickListBackgroundColor,
            padding: theme.spacing(1),
        },
        uploadContentFileName: {
            fontSize: "1.4em",
            marginBottom: theme.spacing(5),
        },
        uploadContentFileTypeLabel: {
            marginRight: theme.spacing(2),
            fontWeight: "bolder",
        },
        settingsDiv: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
            backgroundColor: pickListBackgroundColor,
            border: "1px solid " + pickListDivBorderColor,
            width: "100%",
            height: "100%",
        },
    })
);

interface Props {
    // router props
    resetImport: () => void,
}

type ConcurrentUpdateConfirmDialogInfo = {
    onImportSuccess: () => void,
    onImportFail: () => void,
    conflictingDiagramNames: string[],
}

export default function ModelImportWizard(props: Props) {

    const classes = useStyles();

    const [selectedFile, setSelectedFile] = useState<File>();
    const [modelImportFormatType, setModelImportFormatType] = useState<OpenGroupExchangeFormatType | null>(OpenGroupExchangeFormatType.ARCHIMATE_VERSION_3_1);
    const [selectedCollections, setSelectedCollections] = useState<Array<CollectionDto>>();
    const [selectedLabels, setSelectedLabels] = useState<Array<ILabelDto>>();
    const [importProperties, setImportProperties] = useState<boolean>(false);
    const [skipSchemaValidation, setSkipSchemaValidation] = useState<boolean>(false);
    const [removeDeletedElementsAndRelationships, setRemoveDeletedElementsAndRelationships] = useState<boolean>(false);
    const [ignoreFontSettings, setIgnoreFontSettings] = useState<boolean>(false);
    const [ignoreConcurrentUpdateConfirmDialog, setIgnoreConcurrentUpdateConfirmDialog] = useState<ConcurrentUpdateConfirmDialogInfo>();

    const dispatch = useDispatch();
    const collections = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);
    const labels = useSelector((state: IApplicationState) => state.pages.common.options.labels.resource);
    const user = useSelector((state: IApplicationState) => state.user.userData);
    const isAdmin = user && UserRole.isAtLeast(user, UserRoleType.ROLE_ADMIN);
    const resourcesRefetched = useRef<boolean>(false);

    useEffect(() => {
        if (!resourcesRefetched.current) {
            dispatch(getResourceFetchAction(FetchableResourceType.COMMON_COLLECTION_OPTIONS));
            dispatch(getResourceFetchAction(FetchableResourceType.COMMON_LABEL_OPTIONS));
            resourcesRefetched.current = true;
        }
    }, [resourcesRefetched, dispatch]);

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(_transl(ImportTranslationKey.CHOOSE_FILE_TITLE), <CloudUploadIcon/>,_transl(ImportTranslationKey.CHOOSE_FILE_AND_TYPE_ICON), false,
                () => renderSelectFileStep(), () => canProceedFromSelectFileStep()),
            new WizardStep(_transl(ImportTranslationKey.ADD_COLLECTIONS), <GroupAddIcon />,_transl(ImportTranslationKey.ADD_COLLECTIONS_ICON) , true,
                () => renderAssignGroupsStep(), () => canProceedFromAssignGroupsStep()),
            new WizardStep(_transl(ImportTranslationKey.ADD_LABELS), <LabelIcon />,_transl(ImportTranslationKey.ADD_LABELS_ICON), true,
                () => renderAssignLabelsStep(), () => canProceedFromAssignLabelsStep()),
            new WizardStep(_transl(ImportTranslationKey.OTHER_CHOICES), <SettingsIcon />,_transl(ImportTranslationKey.OTHER_CHOICES_ICON) , true,
                () => renderSettingsStep(), () => canProceedFromSettingsStep())
        ];
    }

    function renderSelectFileStep(): JSX.Element {
        const uploadPanelContentGetter = selectedFile != null ? () => createModelImportFormatTypeSelect() : undefined;

        return <UploadFilePanel uploadAttachment={file => setSelectedFile(file)}
                                uploadPanelContent={uploadPanelContentGetter}/>
    }

    function createModelImportFormatTypeSelect(): JSX.Element {
        return <React.Fragment>
            <div className={classes.uploadContentFileName}>{selectedFile?.name}</div>
            <div>
                <span className={classes.uploadContentFileTypeLabel}>{_transl(ImportTranslationKey.CHOOSE_FILE_TYPE)}</span>
                <span>
                    <Select
                        label={"Typ"}
                        id="file-type"
                        value={`${modelImportFormatType}`}
                        style={{minWidth: "12em"}}
                        onChange={event => {
                            const formatName = event.target.value as string;
                            const modelImportFormatType = OpenGroupExchangeFormat.valueOf(formatName)?.archimateFileFormat || null;
                            setModelImportFormatType(modelImportFormatType);
                        }}
                    >
                        {OpenGroupExchangeFormat.values()
                            .map(format =>
                                <MenuItem value={`${format.archimateFileFormat}`}>
                                    {`${format.caption} (${format.fileExtension})`}
                                </MenuItem>
                            )
                        }
                    </Select>
                </span>
            </div>
        </React.Fragment>
    }

    function canProceedFromSelectFileStep(): boolean {
        return modelImportFormatType != null && selectedFile != null;
    }

    function renderAssignGroupsStep(): JSX.Element {
        return <div className={classes.pickListDiv}>
            <PickList initialPickedOptions={selectedCollections || []}
                      initialAvailableOptions={collections}
                      availableOptionsTitle={_transl(ImportTranslationKey.PICK_LIST_AVAILABLE_OPTIONS_COLLECTIONS)}
                      pickedOptionsTitle={_transl(ImportTranslationKey.PICK_LIST_SELECTED_OPTIONS_COLLECTIONS)}
                      getOptionText={(option) => (option as CollectionDto).name}
                      showError={false}
                      onPickedOptionsUpdated={(options) => setSelectedCollections(options as CollectionDto[])}
                      backgroundColor={pickListBackgroundColor}
            />
        </div>
    }

    function canProceedFromAssignGroupsStep(): boolean {
        return true;
    }

    function renderAssignLabelsStep(): JSX.Element {
        return <div className={classes.pickListDiv}>
            <PickList initialPickedOptions={selectedLabels || []}
                      initialAvailableOptions={labels}
                      availableOptionsTitle={_transl(ImportTranslationKey.PICK_LIST_AVAILABLE_OPTIONS_LABELS)}
                      pickedOptionsTitle={_transl(ImportTranslationKey.PICK_LIST_SELECTED_OPTIONS_LABELS)}
                      getOptionText={(option) => (option as ILabelDto).name}
                      showError={false}
                      onPickedOptionsUpdated={(options) => setSelectedLabels(options as ILabelDto[])}
                      backgroundColor={pickListBackgroundColor}
            />
        </div>
    }

    function canProceedFromAssignLabelsStep(): boolean {
        return true;
    }

    function renderSettingsStep(): JSX.Element {
        return <div className={classes.settingsDiv}>
            <div>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={importProperties}
                                onChange={event => setImportProperties(event.target.checked)}
                                name="importProperties"
                                color="primary"
                            />
                        }
                        label={_transl(ImportTranslationKey.IMPORT_PROPERTIES)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={removeDeletedElementsAndRelationships}
                                onChange={event => setRemoveDeletedElementsAndRelationships(event.target.checked)}
                                name="removeDeletedElementsAndRelationships"
                                color="primary"
                            />
                        }
                        label={_transl(ImportTranslationKey.REMOVE_DELETED_ELEMENTS_AND_RELATIONSHIPS)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={ignoreFontSettings}
                                onChange={event => setIgnoreFontSettings(event.target.checked)}
                                name="ignoreFontSettings"
                                color="primary"
                            />
                        }
                        label={_transl(ImportTranslationKey.IGNORE_FONT_SETTINGS)}
                    />
                    {isAdmin && <FormControlLabel
                        control={
                            <Checkbox
                                checked={skipSchemaValidation}
                                onChange={event => setSkipSchemaValidation(event.target.checked)}
                                name="skipValidation"
                                color="primary"
                            />
                        }
                        label={_transl(ImportTranslationKey.SKIP_SCHEMA_VALIDATION)}
                    />}
                </FormGroup>
            </div>
        </div>
    }

    function canProceedFromSettingsStep(): boolean {
        return true;
    }

    async function doImportAndShowConfirmationOnConcurrentUpdate(): Promise<void> {
        try {
            await doImport(false);
        } catch (error: any) {
            if (error instanceof ValidationError) {
                const validationError = error as ValidationError;
                if (validationError.error.code === ImportErrorCode.CONFLICTING_VERSION && validationError.error.arguments?.conflictingDiagrams) {
                    const conflictingDiagrams: object[] = validationError.error.arguments.conflictingDiagrams;
                    const conflictingDiagramNames: string[] = conflictingDiagrams.map(obj => Object.values(obj)[0]);
                    return new Promise<void>((resolve, reject) => {
                        setIgnoreConcurrentUpdateConfirmDialog({
                            onImportSuccess: resolve,
                            onImportFail: reject,
                            conflictingDiagramNames: conflictingDiagramNames,
                        })
                    });
                } else {
                    return Promise.reject(error);
                }
            } else {
                return Promise.reject(error);
            }
        }
    }

    async function doImportAndIgnoreConcurrentUpdate(onImportSuccess: (value: any) => void, onImportFail: (value: any) => void) {
        try {
            await doImport(true);
            onImportSuccess(true);
        } catch (error) {
            onImportFail(false);
        }
    }

    async function doImport(ignoreConcurrentUpdates: boolean): Promise<void> {
        const metadata: IModelImportMetadataDto = {
            format: modelImportFormatType as OpenGroupExchangeFormatType,
            collectionCodes: selectedCollections != null ? selectedCollections.map(option => option.code) : [],
            labelCodes: selectedLabels != null ? selectedLabels.map(option => option.code) : [],
            importProperties: importProperties,
            removeDeletedElementsAndRelationships: removeDeletedElementsAndRelationships,
            ignoreFontSettings: ignoreFontSettings,
            ignoreConcurrentUpdates: ignoreConcurrentUpdates,
            skipSchemaValidation: skipSchemaValidation,
        }

        await Api.imports.importModel(selectedFile as File, metadata);
    }

    return <>
        {ignoreConcurrentUpdateConfirmDialog && <ConcurrentUpdateDialog open={true}
                                                                        conflictingDiagramNames={ignoreConcurrentUpdateConfirmDialog.conflictingDiagramNames}
                                                                        isModal={true}
                                                                        onConfirm={() => {
                                                                                 const {
                                                                                     onImportSuccess,
                                                                                     onImportFail
                                                                                 } = ignoreConcurrentUpdateConfirmDialog;
                                                                                 doImportAndIgnoreConcurrentUpdate(onImportSuccess, onImportFail);
                                                                                 setIgnoreConcurrentUpdateConfirmDialog(undefined);
                                                                             }}
                                                                        onReject={() => {
                                                                                 ignoreConcurrentUpdateConfirmDialog.onImportFail();
                                                                                 setIgnoreConcurrentUpdateConfirmDialog(undefined);
                                                                             }}/>
        }
        <Wizard steps={getSteps()}
                lastStepLabel={_transl(ImportTranslationKey.LAST_STEP_LABEL)}
                lastStepButtonLabel={_transl(ImportTranslationKey.LAST_STEP_BUTTON_LABEL)}
                lastStepAction={() => doImportAndShowConfirmationOnConcurrentUpdate()}
                lastStepActionInProgressText={_transl(ImportTranslationKey.LAST_STEP_ACTION_IN_PROGRESS)}
                lastStepActionSuccessfulText={_transl(ImportTranslationKey.LAST_STEP_ACTION_SUCCESSFUL)}
                lastStepActionFailedText={_transl(ImportTranslationKey.LAST_STEP_FAILED)}
                cancelWizard={props.resetImport}
                wizardGridMinHeight={"25em"}
        />
    </>;

}
