import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React from "react";
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {
    AttachmentDeleteResourceIdType,
    AttachmentDownloadResourceIdType,
    AttachmentResourceType
} from "../../store/common/Attachments";
import {FetchStatusType, IFetchableResourceState} from "../../store/common/FetchableResource";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TableCellActionPanel from "./listpanel/TableCellActionPanel";
import {DownloadStatusType, IDownloadableResourceState} from "../../store/common/DownloadableResource";
import {IDeletableResourceState} from "../../store/common/DeletableResource";
import DeleteActionPanel from "./listpanel/DeleteActionPanel";
import IconResolver from "../../common/IconResolver";
import {AttachmentTranslationKey} from "./AttachmentTranslationKey";
import {_transl} from "../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import {BlobUtils} from "../../common/BlobUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        table: {},
        actionsHeadCell: {
            minWidth: "5em",
        },
        tableRow: {
            "&:hover": {
                backgroundColor: theme.palette.background.default,
            },
            "&:hover *": {
            }
        },
        fileNameArea: {
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            "& > *": {
                marginRight: theme.spacing(1),
            }
        },
        buttonsArea: {
            "& > *": {
                marginRight: theme.spacing(1),
            }
        }
    })
);

interface Props {
    // component props
    attachments: IFetchableResourceState<AttachmentResourceType>,
    downloadedAttachments: IDownloadableResourceState<AttachmentDownloadResourceIdType>[],
    deletedAttachments: IDeletableResourceState<AttachmentDeleteResourceIdType>[],

    downloadAttachment: (attachmentId: string, fileName: string) => void,
    deleteAttachment: (attachmentId: string) => void,
    onDownloadedAttachmentSaved: (attachmentId: string) => void,
    onDeletedAttachmentViewed: (attachmentId: string) => void,
    onDownloadedAttachmentFailViewed: (attachmentId: string) => void,
}

export default function AttachmentListPanel(props: Props) {

    const classes = useStyles();

    const {attachments, onDownloadedAttachmentSaved} = props;
    const fetchStatus = attachments.fetchStatus;
    const rows = attachments.resource || [];

    function createActionButtons(attachmentId: string, attachmentFileName: string, canDelete: boolean): JSX.Element[] {
        const {downloadAttachment, deleteAttachment} = props;

        return [
            <IconButton key={"delete"} aria-label="delete attachment" component="span" size={"small"} onClick={() => deleteAttachment(attachmentId)} disabled={!canDelete}>
                <DeleteIcon />
            </IconButton>,
            <IconButton key={"download"} aria-label="download attachment" component="span" size={"small"} onClick={() => downloadAttachment(attachmentId, attachmentFileName)}>
                <CloudDownloadIcon />
            </IconButton>
        ];
    }

    function findDownloadedAttachmentById(attachmentId: string) {
        const downloadedAttachments = props.downloadedAttachments || [];
        const filtered = downloadedAttachments.filter(attachment => attachment.resourceId.attachmentId === attachmentId);
        return filtered.length > 0 ? filtered[0] : null;
    }

    function findDeletedAttachmentById(attachmentId: string) {
        const deletedAttachments = props.deletedAttachments || [];
        const filtered = deletedAttachments.filter(attachment => attachment.resourceId.attachmentId === attachmentId);
        return filtered.length > 0 ? filtered[0] : null;
    }

    function planDownloadedRemoval(downloadedAttachment: IDownloadableResourceState<AttachmentDownloadResourceIdType> | null) {
        const {onDownloadedAttachmentFailViewed} = props;

        if (downloadedAttachment != null && downloadedAttachment.downloadStatus.status === DownloadStatusType.FAILED) {
            setTimeout(() => onDownloadedAttachmentFailViewed(downloadedAttachment.resourceId.attachmentId), 3000);
        }
    }

    function planDeletedRemoval(deletedAttachment: IDeletableResourceState<AttachmentDeleteResourceIdType> | null) {
        const {onDeletedAttachmentViewed} = props;

        if (deletedAttachment != null) {
            setTimeout(() => onDeletedAttachmentViewed(deletedAttachment.resourceId.attachmentId), 3000);
        }
    }

    function saveAttachment(downloadedAttachment: IDownloadableResourceState<AttachmentDownloadResourceIdType>) {
        const data = new Blob([downloadedAttachment.downloadStatus.resource]);
        const fileName = downloadedAttachment.downloadStatus.resourceFileName as string;
        BlobUtils.saveBlob(data, fileName);
    }

    return (
        <div className={classes.root} key={"existingListPanel"}>
            <Table className={classes.table} aria-label={_transl(AttachmentTranslationKey.LIST_ATTACHMENTS_TABLE)} size={"medium"}>
                <TableHead>
                    <TableRow>
                        <TableCell>{_transl(CommonTranslation.TITLE)}</TableCell>
                        <TableCell align="right">{_transl(AttachmentTranslationKey.LIST_SIZE)}&nbsp;(KB)</TableCell>
                        <TableCell align="right" className={classes.actionsHeadCell}>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {fetchStatus.status === FetchStatusType.SUCCESSFUL && rows.length > 0 && rows.map((attachment) => {
                        const downloadedAttachment = findDownloadedAttachmentById(attachment.id);
                        const deletedAttachment = findDeletedAttachmentById(attachment.id);

                        planDownloadedRemoval(downloadedAttachment);
                        planDeletedRemoval(deletedAttachment);

                        const fileSizeInKB = attachment.sizeInBytes > 1000 ? attachment.sizeInBytes / 1000 : (attachment.sizeInBytes + Number.EPSILON) / 1000;
                        const fileSize = fileSizeInKB > 1 ? Math.round(fileSizeInKB) : Math.round((fileSizeInKB) * 100) / 100;

                        if (downloadedAttachment != null && downloadedAttachment.downloadStatus.status === DownloadStatusType.SUCCESSFUL) {
                            setTimeout(() => {
                                saveAttachment(downloadedAttachment);
                                onDownloadedAttachmentSaved(downloadedAttachment.resourceId.attachmentId);
                            }, 1);
                        }

                        return (
                            <TableRow key={attachment.fileName + attachment.id} className={classes.tableRow}>
                                <TableCell component="th" scope="row" align={"left"} valign={"middle"}>
                                    <div className={classes.fileNameArea}>
                                        <span>{IconResolver.resolveIcon(attachment.fileName, attachment.mimeType)}</span>
                                        <span>{attachment.fileName}</span>
                                    </div>
                                </TableCell>
                                <TableCell align="right">{fileSize}</TableCell>
                                <TableCell align="right" style={{position: "relative"}}>
                                    <div className={classes.buttonsArea}>
                                        {createActionButtons(attachment.id, attachment.fileName, attachment.acl.canDelete)}
                                    </div>
                                    <TableCellActionPanel opened={downloadedAttachment != null || deletedAttachment != null}>
                                        {deletedAttachment && <DeleteActionPanel attachment={deletedAttachment} />}
                                    </TableCellActionPanel>
                                </TableCell>
                            </TableRow>
                        )
                    })}

                    {fetchStatus.status === FetchStatusType.SUCCESSFUL && rows.length === 0 &&
                        <TableRow key={"empty"}>
                            <TableCell align={"center"} colSpan={5}>
                                {_transl(AttachmentTranslationKey.LIST_EMPTY)}
                            </TableCell>
                        </TableRow>
                    }

                    {fetchStatus.status === FetchStatusType.FAILED &&
                        <TableRow key={"error"}>
                            <TableCell align={"center"} colSpan={5}>
                                {_transl(AttachmentTranslationKey.LIST_ERROR)}
                            </TableCell>
                        </TableRow>
                    }

                    {fetchStatus.status === FetchStatusType.STARTED &&
                        <TableRow key={"downloading"}>
                            <TableCell align={"center"} colSpan={5}>
                                {_transl(AttachmentTranslationKey.LIST_DOWNLOADING)}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </div>
    )

}
