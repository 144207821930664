import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import clsx from "clsx";
import {ArchimateElement} from "../../common/archimate/ArchimateElement";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../store/Store";
import {DefaultColorsDto, getDefaultElementsColor} from "../../common/apis/diagram/DefaultColorsDto";

const useStyles =  makeStyles((theme: Theme) => createStyles({
    typeCodePoint: {
        width: "1em",
        height: "1em",
        display: "inline-block",
        marginRight: "0.5em",
    },
}));

interface Props {
    name: string,
    isMenuIcon?: boolean,
    clazz?: string,
    backgroundColor?: string,
}


export default function ElementTypeIcon(props: Props) {

    const classes = useStyles();

    // STORE
    const defaultColors: DefaultColorsDto = useSelector((state: IApplicationState) => state.diagramDefaults.defaultColors);

    function getTypeIcon() {
        const {name, isMenuIcon, clazz, backgroundColor} = props;
        const element = ArchimateElement.findByStandardName(name);
        if (element) {
            const elementType = element.elementType;
            const layerColor = getDefaultElementsColor(name, defaultColors);
            const codepoint = parseInt(ArchimateElement.getCodepoint(elementType));
            const code = String.fromCharCode(codepoint);
            if (isMenuIcon) {
                return <span style={{color: "black", backgroundColor: backgroundColor}} className={clsx("archimate-icon", clazz)}>{code}</span>
            }
            return <span style={{color: layerColor, backgroundColor: backgroundColor}} className={clsx("archimate-icon", classes.typeCodePoint, clazz)}>{code}</span>
        } else {
            return <span />;
        }
    }

    return getTypeIcon();
}


