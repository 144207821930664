import EventManager, {Unsubscriber} from "../../../event/EventManager";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";
import {EventType} from "../../../event/Event";
import GeometryUtils, {Area, Point} from "../../util/GeometryUtils";
import {
    NodeDistributeHorizontallyEvent,
    NodeDistributeVerticallyEvent,
    NodeDistributionEventType
} from "./NodeDistributionEvents";
import {DiagramDefaultsDto} from "../../../apis/diagram/DiagramDefaultsDto";

export class NodeSpaceDistributor {

    private eventManager: EventManager;
    private diagramDefaults: DiagramDefaultsDto;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager, diagramDefaults: DiagramDefaultsDto) {
        this.eventManager = eventManager;
        this.diagramDefaults = diagramDefaults;

        this.unsubscribers.push(this.eventManager.subscribeListener(NodeDistributionEventType.DISTRIBUTE_HORIZONTALLY, this.handleDistributeHorizontally.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(NodeDistributionEventType.DISTRIBUTE_VERTICALLY, this.handleDistributeVertically.bind(this)));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    private handleDistributeHorizontally(event: NodeDistributeHorizontallyEvent) {
        if (event.nodes.length > 2) {
            const newGeometries = this.distributeHorizontally(event.nodes);
            const [childNodes, newChildNodesGeometries] = this.updateChildNodesPosition(event.nodes, newGeometries);

            const nodes = [...event.nodes, ...childNodes];
            const geometries = { ...newGeometries, ...newChildNodesGeometries };
            if (event.parentNode) {
                nodes.push(event.parentNode);
                geometries[event.parentNode.identifier] = this.expandParentNodeDimensions(event.parentNode, newGeometries);
            }

            this.eventManager.publishEvent({
                type: EventType.NODES_RESIZED,
                nodes: nodes,
                dimensions: geometries
            });
        }
    }

    private handleDistributeVertically(event: NodeDistributeVerticallyEvent) {
        if (event.nodes.length > 2) {
            const newGeometries = this.distributeVertically(event.nodes);
            const [childNodes, newChildNodesGeometries] = this.updateChildNodesPosition(event.nodes, newGeometries);

            const nodes = [...event.nodes, ...childNodes];
            const geometries = { ...newGeometries, ...newChildNodesGeometries };
            if (event.parentNode) {
                nodes.push(event.parentNode);
                geometries[event.parentNode.identifier] = this.expandParentNodeDimensions(event.parentNode, newGeometries);
            }

            this.eventManager.publishEvent({
                type: EventType.NODES_RESIZED,
                nodes: nodes,
                dimensions: geometries
            });
        }
    }

    distributeHorizontally(nodes: IDiagramNodeDto[]) {
        const boundingBox = GeometryUtils.getNodesBoundingArea(nodes);
        const totalWidth = nodes.map(node => node.w).reduce((prev, current) => prev + current, 0);
        const totalSpace = boundingBox.w - totalWidth;

        let space;
        if (totalSpace > nodes.length - 1) {
            space = Math.floor(totalSpace / (nodes.length - 1));
        } else {
            space = this.diagramDefaults.spacing;
        }

        const sortedNodes = nodes.sort((a, b) => a.x - b.x);

        const newGeometries: {[id: string]: Area} = {};
        newGeometries[sortedNodes[0].identifier] = new Area(sortedNodes[0].x, sortedNodes[0].y, sortedNodes[0].w, sortedNodes[0].h);

        let previousNodeArea = newGeometries[sortedNodes[0].identifier];
        for (const node of sortedNodes.slice(1)) {
            const nodeArea = new Area( previousNodeArea.x + previousNodeArea.w + space, node.y, node.w, node.h);
            newGeometries[node.identifier] = nodeArea;
            previousNodeArea = nodeArea;
        }
        return newGeometries;
    }

    distributeVertically(nodes: IDiagramNodeDto[]) {
        const boundingBox = GeometryUtils.getNodesBoundingArea(nodes);
        const totalHeight = nodes.map(node => node.h).reduce((prev, current) => prev + current, 0);
        const totalSpace = boundingBox.h - totalHeight;

        let space;
        if (totalSpace > nodes.length - 1) {
            space = Math.floor(totalSpace / (nodes.length - 1));
        } else {
            space = this.diagramDefaults.spacing;
        }

        const sortedNodes = nodes.sort((a, b) => a.y - b.y);

        const newGeometries: {[id: string]: Area} = {};
        newGeometries[sortedNodes[0].identifier] = new Area(sortedNodes[0].x, sortedNodes[0].y, sortedNodes[0].w, sortedNodes[0].h);

        let previousNodeArea = newGeometries[sortedNodes[0].identifier];
        for (const node of sortedNodes.slice(1)) {
            const nodeArea = new Area(node.x, previousNodeArea.y + previousNodeArea.h + space, node.w, node.h);
            newGeometries[node.identifier] = nodeArea;
            previousNodeArea = nodeArea;
        }
        return newGeometries;
    }

    private updateChildNodesPosition(nodes: IDiagramNodeDto[], newGeometries: {[p: string]: Area}): [IDiagramNodeDto[], {[p: string]: Area}] {
        const translatedNodes: IDiagramNodeDto[] = [];
        let translatedNodesNewGeometries: {[p: string]: Area} = {};

        for (const node of nodes) {
            if (node.childNodes && node.childNodes.length > 0) {
                const newArea = newGeometries[node.identifier];
                const translation = new Point(newArea.x - node.x, newArea.y - node.y);

                const [translatedChildNodes, translatedChildNodesNewGeometries] = this.translateNodes(node.childNodes, translation);
                translatedNodes.push(...translatedChildNodes);
                translatedNodesNewGeometries = { ...translatedNodesNewGeometries, ...translatedChildNodesNewGeometries };
            }
        }
        return [translatedNodes, translatedNodesNewGeometries];
    }

    private translateNodes(nodes: IDiagramNodeDto[], translation: Point): [IDiagramNodeDto[], {[p: string]: Area}] {
        const translatedNodes: IDiagramNodeDto[] = [];
        let translatedNodesNewGeometries: {[p: string]: Area} = {};

        for (const node of nodes) {
            translatedNodes.push(node);
            translatedNodesNewGeometries[node.identifier] = new Area(node.x + translation.x, node.y + translation.y, node.w, node.h);

            if (node.childNodes) {
                const [childNodes, childNodesNewGeometries] = this.translateNodes(node.childNodes, translation);
                translatedNodes.push(...childNodes);
                translatedNodesNewGeometries = { ...translatedNodesNewGeometries, ...childNodesNewGeometries };
            }
        }

        return [translatedNodes, translatedNodesNewGeometries];
    }

    private expandParentNodeDimensions(parentNode: IDiagramNodeDto, childNodeGeometries: {[p: string]: Area}): Area {
        const areas: Area[] = [GeometryUtils.getNodeArea(parentNode)];
        for (const property in childNodeGeometries) {
            if (childNodeGeometries[property] instanceof Area) {
                areas.push(childNodeGeometries[property]);
            }
        }

        return GeometryUtils.getMinimalBoundingArea(areas);
    }
}