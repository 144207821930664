import {
    ConnectionHandlesHideEvent,
    ConnectionHandlesInitEvent,
    ConnectionHandlesRemoveEvent,
    ConnectionHandlesShowEvent,
    ConnectionHandlesUpdateEvent, EventType, HiddenConnectionHandlesHideEvent,
    HiddenConnectionHandlesShowEvent,
} from "../../event/Event";
import Handles from "./connectionhandle/Handles";
import RenderContext from "../context/RenderContext";
import EventManager, {Unsubscriber} from "../../event/EventManager";

export default class ConnectionHandlesRenderer {

    private connectionHandle: Handles;

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_HANDLES_INIT, this.onConnectionHandlesInitEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_HANDLES_SHOW, this.onConnectionHandlesShowEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_HANDLES_UPDATE, this.onConnectionHandlesUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_HANDLES_HIDE, this.onConnectionHandlesHideEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_HANDLES_REMOVE, this.onConnectionHandlesRemoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.HIDDEN_CONNECTION_HANDLES_SHOW, this.onHiddenConnectionHandlesShowEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.HIDDEN_CONNECTION_HANDLES_HIDE, this.onHiddenConnectionHandlesHideEvent.bind(this)));

        this.connectionHandle = new Handles(eventManager);
    }

    destroy() {
        this.connectionHandle.destroy();
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        this.connectionHandle.initConnectionsHandleGroup(renderContext);
    }

    private onConnectionHandlesInitEvent(event: ConnectionHandlesInitEvent) {
        for (const connection of event.initialisedConnections) {
            this.connectionHandle.initConnectionHandleGroup(connection);
        }
        this.connectionHandle.synchronizeConnectionHandlesVisibility(event.initialisedConnections, event.selectedConnections);
    }

    private onConnectionHandlesUpdateEvent(event: ConnectionHandlesUpdateEvent) {
        this.connectionHandle.synchronizeConnectionHandlesVisibility(event.updatedConnections, event.selectedConnections);
    }

    private onConnectionHandlesRemoveEvent(event: ConnectionHandlesRemoveEvent) {
        for (const connection of event.removedConnections) {
            this.connectionHandle.removeConnectionHandleGroup(connection);
        }
    }

    private onConnectionHandlesShowEvent(event: ConnectionHandlesShowEvent) {
        this.connectionHandle.showHandles(event.connections, event.bendpointsOnly);
    }

    private onConnectionHandlesHideEvent(event: ConnectionHandlesHideEvent) {
        this.connectionHandle.hideHandles(event.connections);
    }

    private onHiddenConnectionHandlesShowEvent(event: HiddenConnectionHandlesShowEvent) {
        this.connectionHandle.initConnectionHandleGroup(event.connection);
        this.connectionHandle.showHiddenConnectionHandles(event.connection);
    }

    private onHiddenConnectionHandlesHideEvent(event: HiddenConnectionHandlesHideEvent) {
        this.connectionHandle.hideHiddenConnectionHandles(event.connection);
        this.connectionHandle.removeConnectionHandleGroup(event.connection);
    }
}
