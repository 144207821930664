import React, {useContext} from 'react';
import {Box, Tooltip} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {TimeAgoFormatter} from "../../common/TimeAgoFormatter";
import {Language} from "../../common/Language";
import LocaleContext, { Locale } from "../../common/LocaleContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            textAlign: 'left',
            width: '212px',
            height: '90px',
            padding: theme.spacing(0.5),
            paddingRight: theme.spacing(1),
            textTransform: 'none'
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(1)
        },
        title: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 0,
        },
        timeAgo: {
            color: 'gray',
            paddingLeft: theme.spacing(1),
            whiteSpace: 'nowrap'
        },
        comment: {
            fontSize: 13,
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            wordWrap: 'break-word',
            fontWeight: 'normal'
        },
    })
);

interface ChatPinPreviewProps {
    authorName: string,
    comment: string,
    created: Date,
}

export default function ChatPinPreview(props: ChatPinPreviewProps) {
    const {authorName, comment, created} = props;
    const style = useStyles();
    const locale = useContext<Locale>(LocaleContext);
    const language = locale.language;
    const timeAgoFormatter = new TimeAgoFormatter(language as Language ?? Language.EN);
    const timeAgo = timeAgoFormatter.formatAsTimeAgo(created);
    const tooltipCommentCreated = created.toLocaleString(language);

    return (
        <Box className={style.box}>
            <div className={style.header}>
                <div className={style.title}>
                    {authorName}
                </div>
                <Tooltip title={tooltipCommentCreated} placement={"top"}>
                    <div className={style.timeAgo}>{timeAgo}</div>
                </Tooltip>
            </div>
            <div className={style.comment}>
                {comment}
            </div>
        </Box>
    );
}
