import DialogTitle from "../../../../components/dialogs/DialogTitle";
import React from "react";
import LabelsGrid from "./grid/LabelsGrid";
import DialogContent from "../../../../components/dialogs/DialogContent";
import Dialog from "../../../../components/dialogs/Dialog";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {LabelsTranslationKey} from "./LabelsTranslationKey";

interface LabelsDialogProps {
    open: boolean,
    onClosed: () => void,
}

export default function LabelsDialog(props: LabelsDialogProps) {
    const {open, onClosed} = props;

    return (
        <Dialog
            open={open}
            onClose={onClosed}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(LabelsTranslationKey.TITLE)}
                         onDialogClosed={onClosed}/>
            <DialogContent>
                <LabelsGrid/>
            </DialogContent>
        </Dialog>
    );

}