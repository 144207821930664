import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {CommonTranslation} from "../CommonTranslation";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogActions from "../../../../components/dialogs/DialogActions";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import TextField from "../../../../components/fields/textfield/TextField";
import {Checkbox, FormControlLabel} from "@mui/material";
import diagramService from "./service/DiagramService";
import Snackbar from "../snackbar/Snackbar";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import MultiselectComboBox from "../../../../components/fields/MultiselectComboBox";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import {CollectionDto} from "../../../../common/apis/collection/CollectionDto";
import {Button} from "../../../../components/button/Button";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import {UserRole} from "../../../../common/access/UserRole";

export interface DiagramDuplicateDialogProps {
    open: boolean,
    originalDiagramId: string,
    originalDiagramName?: string,
    onDialogDuplicated: (identifier: string) => void,
    onClosed: () => void,
}

export function DiagramDuplicateDialog(props: DiagramDuplicateDialogProps) {
    const {open, originalDiagramId, originalDiagramName, onClosed, onDialogDuplicated} = props;

    const [name, setName] = useState(generateInitialName(originalDiagramName));
    const [nameError, setNameError] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState<CollectionDto[]>([]);
    const [collectionsError, setCollectionsError] = useState(false);
    const [duplicateAlsoElements, setDuplicateAlsoElements] = useState(false);

    const collections = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);

    const user = useSelector((state: IApplicationState) => state.user.userData);
    const isCollectionsRequired = user && !UserRole.isAtLeast(user, UserRoleType.ROLE_SPRAVCE);

    function generateInitialName(originalDiagramName?: string) {
        return originalDiagramName
            ? "(Copy)" + originalDiagramName
            : "";
    }

    function onDialogClosed() {
        setNameError(false);
        onClosed();
    }

    function saveChanges() {
        const trimmedName = name.trim();
        if (trimmedName.length === 0) {
            setNameError(true);
            return;
        }

        const collectionCodes = selectedCollections.map(collection => collection.code);
        if (isCollectionsRequired && collectionCodes.length === 0) {
            setCollectionsError(true);
            return;
        }

        diagramService.duplicateDiagram(originalDiagramId, name, collectionCodes, duplicateAlsoElements)
            .then((identifier) => {
                onClosed();
                onDialogDuplicated(identifier);
                Snackbar.success(_transl(DiagramTranslationKey.DUPLICATE_DIALOG_DIAGRAM_SUCCESSFULLY_DUPLICATED));
            })
            .catch(() => {
                Snackbar.error(_transl(ErrorTranslationKey.UNEXPECTED_ERROR_OCCURRED));
            });
    }

    return (
        <Dialog open={open}
                onClose={onDialogClosed}
        >
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(DiagramTranslationKey.DUPLICATE_DIALOG_TITLE)}
                         onDialogClosed={onClosed}/>
            <DialogContent>
                <Grid container spacing={3} paddingTop={3}>
                    <Grid item xs={12}>
                        <TextField id="name-field"
                                   fullWidth={true}
                                   label={_transl(DiagramTranslationKey.DUPLICATE_DIALOG_FIELD_NAME)}
                                   required={true}
                                   error={nameError}
                                   helperText={nameError ? _transl(CommonTranslation.FILL_OUT_THIS_FIELD) : null}
                                   value={name}
                                   onChange={(e) => setName(e)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <MultiselectComboBox
                            label={_transl(DiagramTranslationKey.DUPLICATE_DIALOG_FIELD_COLLECTIONS)}
                            id="collections-multiselect"
                            options={collections.filter(collection => collection.acl.canAssignObjectsToCollection)}
                            selectedValues={selectedCollections}
                            getRenderLabel={(value) => value.name}
                            handleOnChange={(changedValues) => setSelectedCollections(changedValues)}
                            required={isCollectionsRequired}
                            errorMessage={collectionsError ? _transl(CommonTranslation.FILL_OUT_THIS_FIELD) : undefined}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label={_transl(DiagramTranslationKey.DUPLICATE_DIALOG_FIELD_DUPLICATE_ELEMENTS_AND_RELATIONSHIPS)}
                            control={
                                <Checkbox id="duplicate-element-field"
                                          checked={duplicateAlsoElements}
                                          onChange={() => setDuplicateAlsoElements(!duplicateAlsoElements)}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button label={_transl(DiagramTranslationKey.DUPLICATE_DIALOG_BUTTON_DUPLICATE)}
                        variant={"contained"}
                        onClick={() => saveChanges()}/>
                <CancelButton variant={"outlined"}
                              onClick={() => onDialogClosed()}/>
            </DialogActions>
        </Dialog>
    );
}
