import React from "react";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../../../../../../components/diagrameditor/DiagramEditorTranslationKey";
import {ChatBubble} from "@mui/icons-material";
import {BadgedIconButton} from "../../../../../../components/button/BadgetChatIconButton";

export interface ChatIconButtonProps {
    onChange: (visible: boolean) => void;
    numberOfUnresolvedChats: number;
    chatLayerVisible: boolean;
}

export function ChatIconButton(props: ChatIconButtonProps) {

    const {onChange, numberOfUnresolvedChats, chatLayerVisible} = props;

    const tooltip = chatLayerVisible ? DiagramEditorTranslationKey.TOP_MENU_COMMENTS_HIDE : DiagramEditorTranslationKey.TOP_MENU_COMMENTS_SHOW;
    return (
        <BadgedIconButton
            badgeContent={numberOfUnresolvedChats}
            onClick={() => onChange(!chatLayerVisible)}
            icon={<ChatBubble/>}
            color={"secondary"}
            variant={chatLayerVisible ? "activated" : "standard"}
            tooltip={_transl(tooltip)}
        />
    );
}
