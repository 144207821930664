import Constants from "../Constants";
import {Language} from "../Language";
import Api from "../Api";
import {map} from "rxjs/operators";
import {ITranslation} from "../../store/localization/ITranslation";

const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/translations/{language}";

export interface TranslationsService {
    getTranslations(language: Language): Promise<ITranslation>,
}

class RxJsTranslationsService implements TranslationsService {

    async getTranslations(language: Language): Promise<ITranslation> {
        const request = {
            url: ENDPOINT_URL.replace("{language}", language.toString()),
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as ITranslation)
            )
            .toPromise();
    }
}

const translationService = new RxJsTranslationsService();
export default translationService;