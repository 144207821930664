import {Badge, BadgeProps} from "@mui/material";
import {TooltippedIconButton, TooltippedIconButtonProps} from "./TooltippedIconButton";
import {ReactNode} from "react";

export interface BadgedIconButtonProps extends TooltippedIconButtonProps {
    badgeContent: ReactNode;
    color: BadgeProps["color"];
}

export function BadgedIconButton(props: BadgedIconButtonProps) {

    const {badgeContent} = props;

    return (
        <Badge badgeContent={badgeContent} color={props.color} overlap="circular">
            <TooltippedIconButton
                {...props}
            />
        </Badge>
    );
}
