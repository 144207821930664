export enum ArchiMateLayerTranslationKey {
    LAYER_STRATEGY = "archimate.layer.STRATEGY",
    LAYER_BUSINESS = "archimate.layer.BUSINESS",
    LAYER_APPLICATION = "archimate.layer.APPLICATION",
    LAYER_TECHNOLOGY = "archimate.layer.TECHNOLOGY",
    LAYER_PHYSICAL = "archimate.layer.PHYSICAL",
    LAYER_MOTIVATION = "archimate.layer.MOTIVATION",
    LAYER_IMPLEMENTATION_AND_MIGRATION = "archimate.layer.IMPLEMENTATION_AND_MIGRATION",
    LAYER_COMPOSITE_ELEMENTS = "archimate.layer.COMPOSITE_ELEMENTS",
    LAYER_VIRTUAL_CONNECTORS = "archimate.layer.VIRTUAL_CONNECTORS",
}