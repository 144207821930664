import {Divider} from "@mui/material";
import IDiagramApi from "../../../../../common/diagrameditor/api/IDiagramApi";
import React, {useEffect, useState} from "react";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {TranslationKey} from "../../../../../store/localization/TranslationKey";
import {IDiagramNodeDto} from "../../../../../common/apis/diagram/IDiagramNodeDto";
import IDiagramEditorApi from "../../../../../common/diagrameditor/api/IDiagramEditorApi";
import {AlignmentType} from "../../../../../common/diagrameditor/common/AlignmentType";
import AlignNodesMenuItems from "./AlignNodesMenuItems";
import {
    Brush,
    ChatBubble, ContentCopy,
    Delete,
    Description,
    Expand,
    FlipToBack,
    FlipToFront,
    Height,
    SettingsBackupRestore,
    SettingsOverscan,
    ViewList
} from '@mui/icons-material'
import {CommonTranslation} from "../../CommonTranslation";
import {ContextMenu} from "../../../../../diagram/editor/components/menu/ContextMenu";
import {DiagramEditorTranslationKey} from "./DiagramEditorTranslationKey";
import PositionDirection
    from "../../../../../common/diagrameditor/manager/model/eventhandlers/positionupdater/PositionDirection";
import RenderMode from "../../../../../common/diagrameditor/context/RenderMode";
import elementService from "../../elements/service/ElementService";
import {ChatEventType, CreateNewChatRequestEvent} from "../../chat/ChatEvents";
import EventManager from "../../../../../common/event/EventManager";
import {SelectionMode} from "../DiagramEditorDialog";
import {MenuItem} from "../../../../../components/menu/MenuItem";
import {
    NodeResizeEventType,
    NodeResizeToDefaultSizeEvent,
    NodeResizeToMatchHeightEvent,
    NodeResizeToMatchSizeEvent,
    NodeResizeToMatchWidthEvent
} from "../../../../../common/diagrameditor/manager/node/NodeResizeEvents";
import {
    NodeDistributeHorizontallyEvent,
    NodeDistributeVerticallyEvent,
    NodeDistributionEventType
} from "../../../../../common/diagrameditor/manager/node/NodeDistributionEvents";
import {ClipboardEventType, CopyItemsToClipboardEvent} from "./ClipboardEvents";
import ModelAccessor from "../../../../../common/diagrameditor/manager/model/ModelAccessor";
import {EventType, OnDeleteSelectedItemsMenuActivatedEvent} from "../../../../../common/event/Event";
import {ElementPermissionResolver} from "./ElementPermissionResolver";
import {IDiagramConnectionDto} from "../../../../../common/apis/diagram/IDiagramConnectionDto";
import {RelationshipPermissionResolver} from "./RelationshipPermissionResolver";

interface NodeContextMenuProps {
    opened: boolean,
    node: IDiagramNodeDto,
    selectedNodes: IDiagramNodeDto[],
    selectedConnections: IDiagramConnectionDto[],
    selectionMode: SelectionMode,
    clientCoordinates: [number, number],
    transformedClientCoordinates: [number, number],
    onClose: () => void,
    onStylesClick: () => void,
    diagramApi: IDiagramApi,
    diagramEditorApi?: IDiagramEditorApi,
    eventManager?: EventManager,
    mode: RenderMode,
    diagramId: string,
}

export default function NodeContextMenu(props: NodeContextMenuProps) {

    const {opened, onClose, node, selectionMode, selectedNodes,
        selectedConnections, clientCoordinates, transformedClientCoordinates,
        diagramApi, diagramEditorApi, eventManager} = props;
    const [elementExists, setElementExists] = useState<boolean>(false);

    const modelAccessor = props.diagramEditorApi?.getModelAccessor();
    const elementPermissionResolver = new ElementPermissionResolver();
    const relationshipPermissionResolver = new RelationshipPermissionResolver();

    const isAlignDisabled = !diagramEditorApi?.areSelectedNodesAlignable();
    const isMoveToFrontDisabled = !diagramEditorApi?.isSelectedNodesPositionUpdatable(PositionDirection.TO_FRONT) || (props.mode !== RenderMode.EDIT);
    const isMoveToBackDisabled = !diagramEditorApi?.isSelectedNodesPositionUpdatable(PositionDirection.TO_BACK) || (props.mode !== RenderMode.EDIT);
    const isResizeDisabled = containsAnyNodeWithChildren(selectedNodes);
    const isDistributionDisabled = !isDistributionAplicable(selectedNodes, modelAccessor);
    const anchorPosition = {
        top: clientCoordinates[1],
        left: clientCoordinates[0]
    };

    const element = node.elementIdentifier ? modelAccessor?.getElementById(node.elementIdentifier) : null;
    const canReadElement = element?.acl?.canRead;

    useEffect(() => {
        updateElementExistenceState(node.elementIdentifier as string);
    }, [node.elementIdentifier]);

    function updateElementExistenceState(identifier: string) {
        const elements = elementService.doSearch([identifier]);
        elements.then(result => {
            const isSavedElement = result.items.length === 1;
            setElementExists(isSavedElement);
        }).catch(() => setElementExists(false));
    }

    function containsAnyNodeWithChildren(nodes: IDiagramNodeDto[]) {
        const nodesWithChildren = nodes.filter(node => node.childNodes && node.childNodes.length > 0);
        return nodesWithChildren.length > 0;
    }

    function isDistributionAplicable(nodes: IDiagramNodeDto[], modelAccessor?: ModelAccessor): boolean {
        if (nodes.length < 3) {
            return false;
        }
        if (modelAccessor === undefined) {
            return false;
        }

        const parentNodeIdentifier = modelAccessor.getParentNode(nodes[0])?.identifier;
        for (const node of nodes.slice(1)) {
            if (parentNodeIdentifier !== modelAccessor.getParentNode(node)?.identifier) {
                return false;
            }
        }
        return true;
    }

    function showDetail() {
        diagramApi.showElementDetailDialog(node.elementIdentifier as string);
        onClose();
    }

    function copyToClipboard() {
        if (eventManager) {
            const event: CopyItemsToClipboardEvent = {
                type: ClipboardEventType.COPY_ITEMS_TO_CLIPBOARD,
                nodes: selectedNodes
            }
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function requestToCreateNewChat() {
        if (eventManager) {
            const event: CreateNewChatRequestEvent = {
                type: ChatEventType.CREATE_NEW_CHAT_REQUEST,
                node: node,
                transformedClientX: transformedClientCoordinates[0],
                transformedClientY: transformedClientCoordinates[1]
            };
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function showElementsGrid() {
        const identifiers = selectedNodes
            .filter(node => node.elementIdentifier)
            .map(node => node.elementIdentifier as string);
        diagramApi.showElementsGridDialog(identifiers);
        onClose();
    }

    function alignNodesClicked(alignmentType: AlignmentType) {
        diagramEditorApi?.onAlignSelectedNodesClicked(alignmentType, node);
    }

    function deleteSelectedItems() {
        if (eventManager) {
            eventManager.publishEvent<OnDeleteSelectedItemsMenuActivatedEvent>({
                type: EventType.ON_DELETE_SELECTED_ITEMS_MENU_ACTIVATED,
                disableRemoveFromModel: disableRemoveFromModel(),
            })
        }

        onClose();
    }

    function disableRemoveFromModel(): boolean {
        for (const node of selectedNodes) {
            const element = node.elementIdentifier ? modelAccessor?.getElementById(node.elementIdentifier) : null;
            if (element && !elementPermissionResolver?.checkCanDeleteElement(element)) {
                return true;
            }
        }
        for (const connection of selectedConnections) {
            const relationship = modelAccessor?.getRelationshipById(connection.relationshipIdentifier);
            if (relationship) {
                let sourceElement = modelAccessor?.getElementById(relationship.source.identifier);
                let targetElement = modelAccessor?.getElementById(relationship.target.identifier);
                if (sourceElement && targetElement && !relationshipPermissionResolver?.checkCanDeleteRelationship(sourceElement, targetElement)) {
                    return true;
                }
            }
        }
        return false;
    }

    function onMoveClicked(direction: PositionDirection) {
        diagramEditorApi?.updateSelectedNodesPosition(direction);
        onClose();
    }

    function onMatchWidthClicked() {
        if (eventManager) {
            const event: NodeResizeToMatchWidthEvent = {
                type: NodeResizeEventType.RESIZE_TO_MATCH_WIDTH,
                nodes: selectedNodes
            };
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function onMatchHeightClicked() {
        if (eventManager) {
            const event: NodeResizeToMatchHeightEvent = {
                type: NodeResizeEventType.RESIZE_TO_MATCH_HEIGHT,
                nodes: selectedNodes
            };
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function onMatchSizeClicked() {
        if (eventManager) {
            const event: NodeResizeToMatchSizeEvent = {
                type: NodeResizeEventType.RESIZE_TO_MATCH_SIZE,
                nodes: selectedNodes
            };
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function onResizeToDefaultSizeClicked() {
        if (eventManager) {
            const event: NodeResizeToDefaultSizeEvent = {
                type: NodeResizeEventType.RESIZE_TO_DEFAULT_SIZE,
                nodes: selectedNodes
            };
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function onDistributeVerticallyClicked() {
        if (eventManager && modelAccessor) {
            const event: NodeDistributeVerticallyEvent = {
                type: NodeDistributionEventType.DISTRIBUTE_VERTICALLY,
                parentNode: modelAccessor.getParentNode(selectedNodes[0]),
                nodes: selectedNodes
            };
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function onDistributeHorizontallyClicked() {
        if (eventManager && modelAccessor) {

            const event: NodeDistributeHorizontallyEvent = {
                type: NodeDistributionEventType.DISTRIBUTE_HORIZONTALLY,
                parentNode: modelAccessor.getParentNode(selectedNodes[0]),
                nodes: selectedNodes
            };
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function onStylesClick() {
        props.onStylesClick();
        onClose();
    }

    return (
        <ContextMenu open={opened}
                     onClose={onClose}
                     anchorPosition={anchorPosition}>
            <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_SHOW_DETAIL)}
                             icon={<Description/>}
                             disabled={selectionMode === SelectionMode.MULTIPLE || !elementExists}
                             onClick={showDetail} />
            <MenuItem label={_transl(DiagramEditorTranslationKey.COPY_TO_CLIPBOARD)}
                      shortcut={"Ctrl+C"}
                      icon={<ContentCopy/>}
                      disabled={!canReadElement}
                      onClick={copyToClipboard}/>
            <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_SHOW_IN_GRID)}
                             icon={<ViewList/>}
                             disabled={!canReadElement}
                             onClick={showElementsGrid} />

            {props.mode === RenderMode.EDIT ? <MenuItem label={_transl(CommonTranslation.REMOVE)}
                                                        icon={<Delete/>}
                                                        shortcut={"⌫"}
                                                        onClick={deleteSelectedItems}/> : <div/>
            }
            {props.mode === RenderMode.EDIT ? <Divider/> : <div />}
            <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_ADD_COMMENT)}
                             icon={<ChatBubble/>}
                             disabled={selectionMode === SelectionMode.MULTIPLE || !elementExists}
                             onClick={requestToCreateNewChat} />
            {props.mode === RenderMode.EDIT &&
                <div>
                    <Divider/>
                    <AlignNodesMenuItems isAlignDisabled={isAlignDisabled}
                                         alignNodesClicked={(type) => alignNodesClicked(type)}
                                         onClose={() => onClose()}/>
                    <Divider/>
                    <MenuItem label={_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_MENUITEM_MOVE_TO_FRONT)}
                              icon={<FlipToFront/>}
                              disabled={isMoveToFrontDisabled}
                              onClick={() => {
                                  onMoveClicked(PositionDirection.TO_FRONT)
                              }}/>
                    <MenuItem label={_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_MENUITEM_MOVE_TO_BACK)}
                              icon={<FlipToBack/>}
                              disabled={isMoveToBackDisabled}
                              onClick={() => {
                                  onMoveClicked(PositionDirection.TO_BACK)
                              }}/>
                    <Divider/>
                    <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_MATCH_WIDTH)}
                              icon={<Height sx={{rotate: "90deg"}}/>}
                              disabled={isResizeDisabled || selectionMode !== SelectionMode.MULTIPLE}
                              onClick={onMatchWidthClicked}/>
                    <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_MATCH_HEIGHT)}
                              icon={<Height/>}
                              disabled={isResizeDisabled || selectionMode !== SelectionMode.MULTIPLE}
                              onClick={onMatchHeightClicked}/>
                    <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_MATCH_SIZE)}
                              icon={<SettingsOverscan/>}
                              disabled={isResizeDisabled || selectionMode !== SelectionMode.MULTIPLE}
                              onClick={onMatchSizeClicked}/>
                    <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_DEFAULT_SIZE)}
                              icon={<SettingsBackupRestore/>}
                              disabled={isResizeDisabled}
                              onClick={onResizeToDefaultSizeClicked}/>
                    <Divider/>
                    <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_DISTRIBUTE_HORIZONTALLY)}
                              icon={<Expand sx={{rotate: "90deg"}}/>}
                              disabled={isDistributionDisabled}
                              onClick={onDistributeHorizontallyClicked}/>
                    <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_DISTRIBUTE_VERTICALLY)}
                              icon={<Expand/>}
                              disabled={isDistributionDisabled}
                              onClick={onDistributeVerticallyClicked}/>
                    <Divider/>
                    <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_STYLES)}
                              icon={<Brush/>}
                              onClick={onStylesClick}/>
                </div>
            }
        </ContextMenu>
    );
}
