import {Divider} from "@mui/material";
import {AlignmentType} from "../../../../../common/diagrameditor/common/AlignmentType";
import AlignIcon, {Orientation} from "../../../../../components/svgicons/AlignIcon";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../../../../../components/diagrameditor/DiagramEditorTranslationKey";
import AlignCenterIcon, {
    Orientation as AlignCenterOrientation
} from "../../../../../components/svgicons/AlignCenterIcon";
import React from "react";
import {MenuItem} from "../../../../../components/menu/MenuItem";

interface Props {
    isAlignDisabled: boolean,
    alignNodesClicked: (alignmentType: AlignmentType) => void,
    onClose: () => void
}

export default function AlignNodesMenuItems(props: Props) {

    const {isAlignDisabled, alignNodesClicked, onClose} = props;

    function renderMenuItem(alignmentType: AlignmentType,
                            alignOrientation: Orientation | null,
                            alignCenterOrientation: AlignCenterOrientation | null,
                            translationKey: DiagramEditorTranslationKey) {

        let icon: JSX.Element;
        if (alignOrientation !== null) {
            icon = <AlignIcon orientation={alignOrientation} />;
        } else {
            icon = <AlignCenterIcon orientation={alignCenterOrientation!} />;
        }

        return (
            <MenuItem label={_transl(translationKey)}
                      icon={icon}
                      disabled={isAlignDisabled}
                      onClick={() => {
                          alignNodesClicked(alignmentType);
                          onClose();
                      }}
                      key={`${alignmentType}`} />
        );
    }

    return (
        <>
            {renderMenuItem(AlignmentType.LEFT, Orientation.TO_LEFT, null, DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_LEFT)}
            {renderMenuItem(AlignmentType.CENTER, null, AlignCenterOrientation.HORIZONTAL, DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_CENTER)}
            {renderMenuItem(AlignmentType.RIGHT, Orientation.TO_RIGHT, null, DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_RIGHT)}
            <Divider />
            {renderMenuItem(AlignmentType.TOP, Orientation.TO_TOP, null, DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_TOP)}
            {renderMenuItem(AlignmentType.MIDDLE, null, AlignCenterOrientation.VERTICAL, DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_MIDDLE)}
            {renderMenuItem(AlignmentType.BOTTOM, Orientation.TO_BOTTOM, null, DiagramEditorTranslationKey.DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_BOTTOM)}
        </>
    );

}
