import IConnectionRenderer from "./IConnectionRenderer";
import ConfigurableConnectionRenderer from "./ConfigurableConnectionRenderer";
import {LineType} from "./AbstractConnectionRenderer";
import {ArchiMateAccessType, ArchimateRelationshipType} from "../../../archimate/ArchimateRelationship";
import {MarkerType} from "../marker/MarkerDefinitionFactory";

type RendererMap = {
    [prop in keyof typeof ArchimateRelationshipType]: (modifiers: ConnectionModifiers) => IConnectionRenderer;
}

export class ConnectionModifiers {
    public constructor(public readonly accessType?: ArchiMateAccessType,
                       public readonly influenceModifier?: string,
                       public readonly associationDirected?: boolean) {}
}

export default class ConnectionRendererFactory {

    private static factory: ConnectionRendererFactory;

    private relationTypeToRendererMap: RendererMap;
    private defaultConnectionRenderer: IConnectionRenderer;

    constructor() {
        const solidLine = new ConfigurableConnectionRenderer(LineType.SOLID);
        const solidLineWithDiamondBlackStart = new ConfigurableConnectionRenderer(LineType.SOLID, MarkerType.DIAMOND_LARGE_BLACK_START);
        const solidLineWithDiamondWhiteStart = new ConfigurableConnectionRenderer(LineType.SOLID, MarkerType.DIAMOND_LARGE_WHITE_START);
        const solidLineWithCircleStartAndArrowFilledBlackEnd = new ConfigurableConnectionRenderer(LineType.SOLID, MarkerType.CIRCLE_SMALL_BLACK_START, MarkerType.ARROW_FILLED_SMALL_BLACK_END);
        const solidLineWithArrowStrokedEnd = new ConfigurableConnectionRenderer(LineType.SOLID, undefined, MarkerType.ARROW_STROKED_END);
        const solidLineWithArrowFilledBlackEnd = new ConfigurableConnectionRenderer(LineType.SOLID, undefined, MarkerType.ARROW_FILLED_SMALL_BLACK_END)
        const solidLineWithArrowFilledWhiteEnd = new ConfigurableConnectionRenderer(LineType.SOLID, undefined, MarkerType.ARROW_FILLED_LARGE_WHITE_END)
        const solidLineWithArrowTopStrokedEnd = new ConfigurableConnectionRenderer(LineType.SOLID, undefined, MarkerType.ARROW_TOP_STROKED_END);
        const dashedLine12 = new ConfigurableConnectionRenderer(LineType.DASHED_1_2, undefined, undefined);
        const dashedLine12WithArrowStrokedStart = new ConfigurableConnectionRenderer(LineType.DASHED_1_2, MarkerType.ARROW_STROKED_START, undefined);
        const dashedLine12WithArrowStrokedEnd = new ConfigurableConnectionRenderer(LineType.DASHED_1_2, undefined, MarkerType.ARROW_STROKED_END);
        const dashedLine12WithArrowStrokedStartEnd = new ConfigurableConnectionRenderer(LineType.DASHED_1_2, MarkerType.ARROW_STROKED_START, MarkerType.ARROW_STROKED_END);
        const dashedLine12WithArrowFilledWhiteEnd = new ConfigurableConnectionRenderer(LineType.DASHED_1_2, undefined, MarkerType.ARROW_FILLED_LARGE_WHITE_END);
        const dashedLine43WithArrowStrokedEnd = new ConfigurableConnectionRenderer(LineType.DASHED_4_3, undefined, MarkerType.ARROW_STROKED_END);
        const dashedLine43WithArrowFilledBlackEnd = new ConfigurableConnectionRenderer(LineType.DASHED_4_3, undefined, MarkerType.ARROW_FILLED_SMALL_BLACK_END);

        this.defaultConnectionRenderer = solidLine;

        this.relationTypeToRendererMap = {
            ACCESS: (modifiers) => {
                let renderer: IConnectionRenderer;
                switch (modifiers.accessType) {
                    case ArchiMateAccessType.READ: renderer = dashedLine12WithArrowStrokedStart; break;
                    case ArchiMateAccessType.WRITE: renderer = dashedLine12WithArrowStrokedEnd; break;
                    case ArchiMateAccessType.READ_WRITE: renderer = dashedLine12WithArrowStrokedStartEnd; break;
                    default: renderer = dashedLine12;
                }
                return renderer;
            },
            AGGREGATION: (modifiers) => solidLineWithDiamondWhiteStart,
            ASSIGNMENT: (modifiers) => solidLineWithCircleStartAndArrowFilledBlackEnd,
            ASSOCIATION: (modifiers) => modifiers.associationDirected === true ? solidLineWithArrowTopStrokedEnd : solidLine,
            COMPOSITION: (modifiers) => solidLineWithDiamondBlackStart,
            FLOW: (modifiers) => dashedLine43WithArrowFilledBlackEnd,
            INFLUENCE: (modifiers) => dashedLine43WithArrowStrokedEnd,
            REALIZATION: (modifiers) => dashedLine12WithArrowFilledWhiteEnd,
            SERVING: (modifiers) => solidLineWithArrowStrokedEnd,
            SPECIALIZATION: (modifiers) => solidLineWithArrowFilledWhiteEnd,
            TRIGGERING: (modifiers) => solidLineWithArrowFilledBlackEnd,
        }
    }

    static getInstance() {
        if (!ConnectionRendererFactory.factory) {
            ConnectionRendererFactory.factory = new ConnectionRendererFactory();
        }
        return ConnectionRendererFactory.factory;
    }

    get(relationshipType: ArchimateRelationshipType | null, modifiers: ConnectionModifiers | null): IConnectionRenderer {
        let renderer = null;
        if (relationshipType && modifiers) {
            renderer = this.relationTypeToRendererMap[relationshipType] && this.relationTypeToRendererMap[relationshipType](modifiers);
        }
        return renderer || this.defaultConnectionRenderer;
    }

}