import React from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {ImportStatus} from "./Wizard";
import {WizardStep} from "./WizardStep";
import {_transl} from "../../store/localization/TranslMessasge";
import {WizardTranslationKey} from "./WizardTranslationKey";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonsSegment: {
            "& .MuiGrid-item": {
                display: "flex",
            },
        },
        buttonsGrid: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(6),
        },
        prevButtonDiv: {
            display: "inline-block",
        },
        nextButtonDiv: {
            display: "inline-block",
            marginLeft: "auto",
        },
        button: {
            "& + button": {
                marginLeft: theme.spacing(1),
            },
            minWidth: "12em"
        },
    })
);

interface Props {
    steps: WizardStep[],
    activeStepId: number,
    onPrevStep: (stepId: number) => void,
    onNextStep: (stepId: number) => void,
    onImport: () => void,
    importStatus: ImportStatus,
    onResetImport: () => void,
    lastStepButtonLabel: string,
}

export default function WizardButtons(props: Props) {

    const classes = useStyles();

    const { steps, activeStepId } = props;
    const { onNextStep, onPrevStep, onImport, importStatus, onResetImport, lastStepButtonLabel } = props;

    const importCompleted = importStatus === ImportStatus.SUCCESSFULL || importStatus === ImportStatus.FAILED;
    const importInProgress = importStatus === ImportStatus.STARTED;
    const doImportStepId = steps.length;
    const nextStepEnabled = steps[activeStepId].canProceedToNextStep();
    const prevStepId = activeStepId - 1;
    const nextStepId = activeStepId + 1;
    const nextStepCallback = nextStepId === doImportStepId ? () => onImport() : () => onNextStep(nextStepId)

    return (
        <div className={classes.buttonsSegment}>
            <Grid container className={classes.buttonsGrid}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <div className={classes.prevButtonDiv}>
                        <Button onClick={() => onPrevStep(prevStepId)}
                                variant={"outlined"}
                                color={"primary"}
                                disabled={activeStepId === 0 && !importInProgress}
                                className={classes.button}
                        >
                            {"< " + _transl(WizardTranslationKey.BACK)}
                        </Button>
                    </div>
                    <div className={classes.nextButtonDiv}>
                        {!importCompleted && <Button onClick={nextStepCallback}
                                                     variant={"contained"}
                                                     color={"primary"}
                                                     className={classes.button}
                                                     disabled={!nextStepEnabled && !importInProgress}
                        >
                            {activeStepId < (doImportStepId - 1) ? _transl(WizardTranslationKey.NEXT) + " >" : lastStepButtonLabel}
                        </Button>
                        }
                        {importCompleted && <Button variant={"contained"}
                                                    color={"primary"}
                                                    className={classes.button}
                                                    onClick={onResetImport}
                        >
                            {_transl(WizardTranslationKey.EXIT)}
                        </Button>
                        }
                    </div>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    );
}
