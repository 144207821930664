import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import * as d3 from "d3";

export default class ConsoleManager {

    private static readonly CONSOLE_ID = "__DIAGRAM_CONSOLE__";

    destroy() {
    }

    init() {
        if (DiagramEditorUtils.isTouchDevice()) {
            const consoleElem = d3.select("body").append("div")
                .attr("id", ConsoleManager.CONSOLE_ID)
                .style("position", "absolute")
                .style("left", "2em")
                .style("top", "2em")
                .style("width", "40em")
                .style("height", "20em")
                .style("overflow", "auto")
                .style("z-index", 3000)
                .style("background-color", "white")
                .style("padding", "10px")
                .style("border", "1px solid black");

            const consoleNode = (consoleElem.node() as HTMLDivElement);

            const log = window.console.log;
            const info = window.console.info;
            const warn = window.console.warn;
            const error = window.console.error;

            window.console.log = (...data: any[]) => {
                ConsoleManager.append(JSON.stringify(data), "black", consoleNode);
                log(data);
            }
            window.console.info = (...data: any[]) => {
                ConsoleManager.append(JSON.stringify(data), "black", consoleNode);
                info(data);
            }
            window.console.warn = (...data: any[]) => {
                ConsoleManager.append(JSON.stringify(data), "yellow", consoleNode);
                warn(data);
            }
            window.console.error = (...data: any[]) => {
                ConsoleManager.append(JSON.stringify(data), "red", consoleNode);
                error(data);
            }
        }
    }

    private static append(str: string, color: string, consoleNode: HTMLDivElement) {
        d3.select("#"+ConsoleManager.CONSOLE_ID)
            .append("div")
            .style("color", color)
            .text(str);
        consoleNode.scrollTop = consoleNode.scrollHeight;
    }
}