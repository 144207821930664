import {ItemFactory} from "./ItemFactory";
import Item, {ItemType} from "./Item";
import {ModelTreeUtils} from "../ModelTreeView";
import TreeViewItemFactory from "./TreeViewItemFactory";
import Constants from "../../../../../../common/Constants";
import React from "react";
import ElementTypeIcon from "../../../../../fields/ElementTypeIcon";
import * as d3 from "d3";
import {ArchimateLayerType} from "../../../../../../common/archimate/ArchiMateLayer";
import {ArchimateElement} from "../../../../../../common/archimate/ArchimateElement";
import store from "../../../../../../store/Store";
import {RelationshipDto} from "../../../../../../common/apis/relationship/RelationshipDto";
import {DiagramDto} from "../../../../../../common/apis/diagram/DiagramDto";
import {ElementDto} from "../../../../../../common/apis/element/ElementDto";
import {getDefaultElementsBgColor} from "../../../../../../common/apis/diagram/DefaultColorsDto";

interface TypeStereotypeItem extends Item {
    layer: ArchimateLayerType,
    standardName: string,
    isStereotype: boolean,
}

export default class TypeStereotypeItemFactory implements ItemFactory {

    constructor(private isStereotypeAware: boolean) {
    }

    getItems(elements: Array<ElementDto>,
             relationships: Array<RelationshipDto>,
             diagrams: Array<DiagramDto>,
             utils: ModelTreeUtils): Array<Item> {

        const items = new Array<Item>();
        items.push(...this.getItemsByElementType(elements, utils));
        items.push(TreeViewItemFactory.createRelationshipsItem(elements, relationships, diagrams, utils));
        return items;

    }

    private getItemsByElementType(elements: Array<ElementDto>, utils: ModelTreeUtils) {
        const itemByElementType: {[type: string]: TypeStereotypeItem} = {};
        elements.forEach(element => {
            let parentItem;
            if (this.isStereotypeAware && element.stereotype) {
                parentItem = TypeStereotypeItemFactory.getStereotypeItem(itemByElementType, element.stereotype.name, element.type, utils);
            } else {
                parentItem = TypeStereotypeItemFactory.getElementTypeItem(itemByElementType, element.type, utils);
            }
            const item: Item = {
                nodeId: element.identifier,
                itemType: ItemType.ELEMENT,
                elementId: element.identifier,
                labelText: utils.getElementName(element),
                labelTitle: utils.getElementName(element),
                labelIcon: <ElementTypeIcon name={element.type} isMenuIcon={true}/>,
                labelIconColor: "black",
                labelIconBgColor: getDefaultElementsBgColor(element.type, store.getState().diagramDefaults.defaultColors),
                children: [],
            }
            parentItem.children.push(item);
        });
        return TypeStereotypeItemFactory.sortItems(Object.values(itemByElementType));
    }

    private static getElementTypeItem(itemByElementType: {[type: string]: TypeStereotypeItem}, standardName: string, utils: ModelTreeUtils): TypeStereotypeItem {
        let item = itemByElementType[standardName];
        if (!item) {
            const element = ArchimateElement.findByStandardName(standardName) as ArchimateElement;
            item = {
                nodeId: standardName,
                itemType: ItemType.FOLDER,
                isStereotype: false,
                layer: element.layerType,
                standardName: standardName,
                labelText: element.visibleName,
                labelTitle: element.visibleName,
                labelIcon: null,
                labelIconColor: Constants.MODEL_FOLDER_ICON_COLOR,
                children: [],
            };
            TypeStereotypeItemFactory.setElementTypeIcon(item, element, standardName);
            itemByElementType[standardName] = item;
        }
        return item;
    }

    private static getStereotypeItem(itemByElementType: {[type: string]: TypeStereotypeItem}, stereotype: string, typeStandardName: string, utils: ModelTreeUtils): Item {
        const element = ArchimateElement.findByStandardName(typeStandardName) as ArchimateElement;
        const id = `stereotype-${stereotype}`;
        let item = itemByElementType[id];
        if (!item) {
            item = {
                nodeId: id,
                itemType: ItemType.FOLDER,
                isStereotype: true,
                layer: element.layerType,
                standardName: typeStandardName,
                labelText: `${element.visibleName} <<${stereotype}>>`,
                labelIcon: null,
                labelIconColor: Constants.MODEL_FOLDER_ICON_COLOR,
                children: [],
            };
            TypeStereotypeItemFactory.setElementTypeIcon(item, element, typeStandardName);
            itemByElementType[id] = item;
        }
        return item;
    }

    private static setElementTypeIcon(item: Item, element: ArchimateElement, standardName: string) {
        const layerColor = getDefaultElementsBgColor(element.standardName, store.getState().diagramDefaults.defaultColors);
        const layerColorDarker = d3.color(layerColor)?.darker(0.3).formatRgb();
        const labelIconStyle = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            backgroundColor: layerColor,
            borderRadius: 50,
            border: `1px solid ${layerColorDarker}`,
        };
        item.labelIcon = <span style={labelIconStyle}>
            <ElementTypeIcon name={standardName} isMenuIcon={true} />
        </span>;
    }

    private static sortItems(items: TypeStereotypeItem[]) {
        return items.sort((item1, item2) => {
            if (item1.standardName !== item2.standardName) {
                return item1.standardName.localeCompare(item2.standardName);
            } else {
                if (item1.isStereotype !== item2.isStereotype) {
                    return item1.isStereotype ? 1 : -1;
                } else {
                    return item1.labelText.localeCompare(item2.labelText);
                }
            }
        });
    }
}
