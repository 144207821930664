import {IconButton, Divider, DialogTitle as MuiDialogTitle} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {ReactNode} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            "padding": theme.spacing(1),
            "padding-bottom": 0,
            "& hr": {
                "margin-top": theme.spacing(0.5),
            }
        },
        closeButton: {
            "padding-left": theme.spacing(1)
        },
    })
);

interface ActionDialogTitleProps {
    leftButtons?: ReactNode,
    rightButtons?: ReactNode,
    id?: string,
    onDialogClosed: (e: any) => void,
}

export default function ActionDialogTitle(props: ActionDialogTitleProps) {
    const {id, onDialogClosed, leftButtons, rightButtons} = props;
    const classes = useStyles();

    return (
        <MuiDialogTitle id={id} className={classes.dialogTitle}>
            <Grid container alignItems={"center"}>
                <Grid item xs textAlign={"left"}>
                    {leftButtons}
                </Grid>
                <Grid item xs={5} textAlign={"right"}>
                    {rightButtons}
                </Grid>
                <Grid item xs={"auto"} textAlign={"right"} alignContent={"center"}>
                    <div className={classes.closeButton}>
                        <IconButton aria-label="close"
                                    onClick={onDialogClosed}
                                    size="large">
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <Divider/>
        </MuiDialogTitle>
    );
}
