import {Collapse, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import List from "@mui/material/List";
import React from "react";
import Divider from "@mui/material/Divider";
import UIUtils from "../../../../common/UIUtils";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {SearchProvider} from "./SearchProvider";
import {_transl} from "../../../../store/localization/TranslMessasge";

const contextAreaSelectStyles = (theme: Theme) => createStyles({
    contextAreaSelect: {
        position: "absolute",
        top: theme.spacing(5),
        left: theme.spacing(1.5),
        width: theme.spacing(33),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
    },
    contextAreaSelectList: {
        padding: 0,
    },
    contextAreaSelectIcon: {
        width: theme.spacing(1),
    },
    contextAreaSelectText: {
        display: "inline-block",
        marginBottom: theme.spacing(0.7),
        color: "rgba(0, 0, 0, 0.54)",
        fontWeight: "bold",
    },
    contextAreaSelectSubtext: {
        fontSize: "0.8em",
    },
    bold: {
        fontWeight: "bold",
    },
});

interface IProps extends WithStyles<typeof contextAreaSelectStyles> {
    searchProviders: SearchProvider[],
    contextAreaSelectOpened: boolean,
    onContextAreaUpdated: (id: string) => void,
}

interface IState {}

class ContextAreaSelect extends React.Component<IProps, IState> {

    public static readonly CONTEXT_AREA_SELECT_ID = "app-bar-context-area-select";

    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { searchProviders, contextAreaSelectOpened, classes } = this.props;

        return (
            <Collapse in={contextAreaSelectOpened} className={classes.contextAreaSelect} timeout={100} onEntered={e => UIUtils.requestFocus(ContextAreaSelect.CONTEXT_AREA_SELECT_ID)}>
                <Paper id={ContextAreaSelect.CONTEXT_AREA_SELECT_ID} elevation={1} tabIndex={2}>
                    <List className={classes.contextAreaSelectList} component="nav" aria-label="context areas">

                        {
                            searchProviders.map((provider, index) => {
                                return (
                                    <React.Fragment key={provider.id + "_FRAGMENT"}>
                                        <ListItem button onClick={e => this.props.onContextAreaUpdated(provider.id)}>
                                            <ListItemIcon>
                                                { provider.contextAreaConfig.icon }
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <span className={classes.contextAreaSelectText}>
                                                        { _transl(provider.contextAreaConfig.primaryTextKey) }
                                                    </span>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <span className={`${classes.contextAreaSelectSubtext} ${classes.bold}`}>
                                                            { _transl(provider.contextAreaConfig.secondaryTextKey) }
                                                        </span>
                                                        <span className={classes.contextAreaSelectSubtext}>
                                                            { " " + _transl(provider.contextAreaConfig.secondaryDescriptionKey) }
                                                        </span>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        {
                                            index < (searchProviders.length - 1) && <Divider />
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </List>
                </Paper>
            </Collapse>
        );
    }
}

export default withStyles(contextAreaSelectStyles, { withTheme: true })(ContextAreaSelect)
