export class AvatarUtils {
    public static stringToColor(string: string): string {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    }

    private static getInitials(name: string) {
        if (!name || name.trim() === '') {
            return "";
        }

        const nameParts = name.split(' ');
        if (nameParts.length >= 2) {
            return nameParts[0][0] + nameParts[1][0];
        } else if (nameParts.length === 1) {
            return nameParts[0][0];
        }
    }

    public static stringAvatar(name: string, width: number, height: number) {

        return {
            sx: {
                bgcolor: AvatarUtils.stringToColor(name),
                width: width,
                height: height
            },
            children: AvatarUtils.getInitials(name),
        };
    }
}
