import {RouteProvider, ProviderRouteProvider} from "./RouteProvider";
import {LocalStorageRouteStorage, RouteStorage} from "./RouteStorage";
import {WindowLocationProvider} from "./LocationProvider";

export interface InaccessibleRouteService {
    saveCurrent(): string,
    load(): string | null,
    loadAndClear(): string | null,
    clear(): void,
}

class ProviderInaccessibleRouteService implements InaccessibleRouteService {

    constructor(private routeProvider: RouteProvider,
                private routeStorage: RouteStorage) {}

    saveCurrent(): string {
        const route = this.routeProvider.getCurrentRoute();
        this.routeStorage.store(route);
        return route;
    }

    load(): string | null {
        return this.routeStorage.load();
    }

    loadAndClear(): string | null {
        const route = this.routeStorage.load();
        this.clear();
        return route;
    }

    clear(): void {
        this.routeStorage.clear();
    }
}

const locationProvider = new WindowLocationProvider();
const routeProvider = new ProviderRouteProvider(locationProvider);
const routeStorage = new LocalStorageRouteStorage();
export default new ProviderInaccessibleRouteService(routeProvider, routeStorage) as InaccessibleRouteService;
