import {IGraphDataDto} from "../../../common/apis/GraphData";
import {SimulationNodeDatum} from "d3-force";
import {SimulationLinkDatum} from "d3";
import EventManager from "./EventManager";
import {ArchimateElement} from "../../../common/archimate/ArchimateElement";
import {ArchimateRelationship} from "../../../common/archimate/ArchimateRelationship";

export interface INode extends SimulationNodeDatum {
    id: string,
    name: string,
    description: string,
    type: string,
    archimateElement: ArchimateElement,
    anchored?: boolean,
    anchorOnMouseOver?: boolean,
    anchoredOnMenuOpened?: boolean,
    anchoringStartPoint?: {
        x: number,
        y: number,
    };
}

export interface ILink extends SimulationLinkDatum<INode> {
    id: string,
    source: INode,
    target: INode,
    type: string,
    archimateRelationship: ArchimateRelationship,
}

export default class ModelManager {

    private eventManager: EventManager;
    private entityId?: string;
    private data?: IGraphDataDto;
    private rootElementIds: Array<string>;
    private explodedElementIds?: Array<string>
    private nodes?: Array<INode>;
    private links?: Array<ILink>;
    private idToNodeMap: { [index: string]: INode };

    constructor(rootElementIds: Array<string>, eventManager: EventManager) {
        this.rootElementIds = rootElementIds;
        this.idToNodeMap = {};
        this.eventManager = eventManager;
    }

    public updateModel(data: IGraphDataDto, entityId: string) {
        const entityChanged = this.entityId !== entityId;
        this.entityId = entityId;
        this.data = data;

        // save old nodes which props will be used for new nodes initialization
        const oldNodes = this.idToNodeMap;
        this.idToNodeMap = {};

        // create nodes (for each new one copy corresponding old node values set by previous simulations when entity id did NOT change)
        const copyOldData = !entityChanged;

        this.nodes = data.elementsData
            .map((element, index) => {
                const node = Object.assign(
                    copyOldData ? (oldNodes[element.id] || {}) : {},
                    {
                        id: element.id,
                        name: element.name,
                        description: element.description,
                        type: element.type,
                        archimateElement: ArchimateElement.findByStandardName(element.type) as ArchimateElement,
                    }
                );
                this.idToNodeMap[node.id] = node;
                return node;
            });

        // create links - remove self references
        this.links = data.relationshipsData
            .filter(relationship => relationship.startNodeId !== relationship.endNodeId)
            .map(relationship => ({
                    id: relationship.id,
                    source: this.idToNodeMap[relationship.startNodeId],
                    target: this.idToNodeMap[relationship.endNodeId],
                    type: relationship.type,
                    archimateRelationship: ArchimateRelationship.findByStandardName(relationship.type) as ArchimateRelationship,
                } as ILink)
            );
    }

    public getRootElementIds() {
        return this.rootElementIds;
    }

    public getNodes() {
        return this.nodes || [];
    }

    public getLinks() {
        return this.links || [];
    }

    public getData() {
        return this.data;
    }

    public getExplodedElementIds() {
        return this.explodedElementIds || [];
    }

    public setExplodedElementIds(explodedElementIds: Array<string>) {
        this.explodedElementIds = explodedElementIds;
    }
}