import {DateNullable, StringNullable} from "../../common/Types";
import {Reducer} from "redux";
import {ActionsObservable, StateObservable} from "redux-observable";
import {IApplicationState} from "../Store";
import {catchError, filter, mergeMap, startWith, switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";

// Resource type

export enum DeletableResourceType {
    // Page specific
    ELEMENTS_DETAIL_PAGE_ATTACHMENT = "ELEMENTS_DETAIL_PAGE_ATTACHMENT",
    DIAGRAM_DETAIL_PAGE_ATTACHMENT = "DIAGRAM_DETAIL_PAGE_ATTACHMENT",
}

// Action

export enum DeleteActionType {
    DELETE = "ACTION/COMMON/DELETE",
    DELETE_SUCCESSFUL = "ACTION/COMMON/DELETE_SUCCESSFUL",
    DELETE_FAILED = "ACTION/COMMON/DELETE_FAILED",
    DELETE_ALREADY_STARTED = "ACTION/COMMON/DELETE_ALREADY_STARTED",
    DELETE_REMOVE_ITEM = "ACTION/COMMON/DELETE_REMOVE_ITEM",
}

export enum DeleteStatusType {
    NOT_STARTED = "NOT_STARTED",
    STARTED = "STARTED",
    SUCCESSFUL = "SUCCESSFUL",
    FAILED = "FAILED",
}

export interface IDeletableResourceAction<T> {
    type: DeleteActionType;
    resourceType: DeletableResourceType;
    resourceId: T;
    status: DeleteStatusType;
    error?: string;
}

// Action creators

export function getResourceDeleteAlreadyStartedAction<T>(resourceType: DeletableResourceType, resourceId: T): IDeletableResourceAction<T> {
    const action = {
        ...getResourceDeleteAction(resourceType, resourceId),
        type: DeleteActionType.DELETE_ALREADY_STARTED,
    }

    return action;
}

export function getResourceDeleteAction<T>(resourceType: DeletableResourceType, resourceId: T): IDeletableResourceAction<T> {
    return {
        type: DeleteActionType.DELETE,
        resourceType: resourceType,
        resourceId: resourceId,
        status: DeleteStatusType.STARTED,
    }
}

export function getResourceDeleteCustomAction<I, T extends IDeletableResourceAction<I>>(resourceType: DeletableResourceType, resourceId: T, params: any): T {
    return {
        ...getResourceDeleteAction(resourceType, resourceId),
        ...params,
    } as T;
}

export function getResourceDeleteRemoveItemAction<T>(resourceType: DeletableResourceType, resourceId: T): IDeletableResourceAction<T> {
    return {
        type: DeleteActionType.DELETE_REMOVE_ITEM,
        resourceType: resourceType,
        resourceId: resourceId,
        status: DeleteStatusType.SUCCESSFUL,
    }
}

export function getResourceDeleteSuccessfulAction<T>(resourceType: DeletableResourceType, resourceId: T): IDeletableResourceAction<T> {
    return {
        type: DeleteActionType.DELETE_SUCCESSFUL,
        resourceType: resourceType,
        resourceId: resourceId,
        status: DeleteStatusType.SUCCESSFUL,
    }
}

export function getResourceDeleteFailedAction<T>(resourceType: DeletableResourceType, resourceId: T, error: string): IDeletableResourceAction<T> {
    return {
        type: DeleteActionType.DELETE_FAILED,
        resourceType: resourceType,
        resourceId: resourceId,
        status: DeleteStatusType.FAILED,
        error: error,
    }
}

// State

export interface IDeleteStatusState {
    status: DeleteStatusType;
    progress: number,
    start: Date,
    end: DateNullable,
    error: StringNullable;
}

export interface IDeletableResourceState<T> {
    deleteStatus: IDeleteStatusState;
    resourceId: T;
}

// Reducer

class ReducerUtils {

    public static replaceItem<T>(array: Array<IDeletableResourceState<T>>, oldItem: IDeletableResourceState<T>, newItem: IDeletableResourceState<T>) {
        return array.map(item => {
            return (item === oldItem) ? newItem : item
        })
    }

    public static findItemByResourceId<T>(array: Array<IDeletableResourceState<T>>, resourceId: T | undefined) {
        return array.filter(item => JSON.stringify(item.resourceId) === JSON.stringify(resourceId))[0];
    }

    static removeItemByResourceId<T>(array: Array<IDeletableResourceState<T>>, resourceId: T) {
        return array.filter(item => JSON.stringify(item.resourceId) !== JSON.stringify(resourceId));
    }
}

export function createDeletableResourceArrayReducer<T>(
    resourceType: IDeletableResourceAction<T>["resourceType"],
    initialState: Array<IDeletableResourceState<T>>
): Reducer<Array<IDeletableResourceState<T>>, IDeletableResourceAction<T>>
{
    return (
        state= initialState,
        action
    ) => {
        if (action.resourceType === resourceType) {
            let item, newItem;
            switch (action.type) {
                case DeleteActionType.DELETE:
                    return [
                        ...state,
                        {
                            deleteStatus: {
                                status: action.status,
                                progress: 0,
                                start: new Date(),
                                end: null,
                                error: null,
                            },
                            resourceId: action.resourceId,
                        },
                    ]

                case DeleteActionType.DELETE_SUCCESSFUL :
                    item = ReducerUtils.findItemByResourceId(state, action.resourceId);
                    newItem = {
                        ...item,
                        deleteStatus: {
                            ...item.deleteStatus,
                            status: action.status,
                            progress: 100,
                            end: new Date(),
                            error: null,
                        },
                    }
                    return ReducerUtils.replaceItem(state, item, newItem);

                case DeleteActionType.DELETE_FAILED :
                    item = ReducerUtils.findItemByResourceId(state, action.resourceId);
                    newItem = {
                        ...item,
                        deleteStatus: {
                            ...item.deleteStatus,
                            status: action.status,
                            progress: 0,
                            end: new Date(),
                            error: action.error,
                        },
                    } as IDeletableResourceState<T>;
                    return ReducerUtils.replaceItem(state, item, newItem);

                case DeleteActionType.DELETE_REMOVE_ITEM :
                    return ReducerUtils.removeItemByResourceId(state, action.resourceId);

            }
        }
        return state;
    };
}

// Epic

export function createDeletableResourceEpic<T>(
    resourceType: DeletableResourceType,
    apiCall: (action: IDeletableResourceAction<T>) => Observable<AjaxResponse>,
) {
    return composeDeletableResourceEpic<IDeletableResourceAction<any>>(
        DeleteActionType.DELETE,
        resourceType,
        apiCall,
        (action) => action.resourceType,
    );
}

export function createCustomActionDeletableResourceEpic<A extends IDeletableResourceAction<any>>(
    resourceType: DeletableResourceType,
    apiCall: (action: A) => Observable<AjaxResponse>,
) {
    return composeDeletableResourceEpic<A>(
        DeleteActionType.DELETE,
        resourceType,
        apiCall,
        (action) => action.resourceType,
    );
}

export function composeDeletableResourceEpic<T extends IDeletableResourceAction<any>>(
    actionType: T["type"],
    requiredResourceType: DeletableResourceType,
    apiCall: (action: T) => Observable<AjaxResponse>,
    resourceType: (action: T) => DeletableResourceType,
) {
    return (action$: ActionsObservable<T>, $state: StateObservable<IApplicationState>) =>
        action$.ofType(actionType)
            .pipe(
                filter((action) => resourceType(action) === requiredResourceType),
                mergeMap((action) => {
                    const startAction = actionType === DeleteActionType.DELETE ?
                        getResourceDeleteAlreadyStartedAction(resourceType(action), action.resourceId) :
                        getResourceDeleteAction(resourceType(action), action.resourceId);

                    return apiCall(action).pipe(
                        switchMap((xhr: AjaxResponse) => {
                            return of(getResourceDeleteSuccessfulAction(resourceType(action), action.resourceId))
                        }),
                        catchError((error) => of(getResourceDeleteFailedAction(resourceType(action), action.resourceId, "Data se nezdařilo načíst."))),
                        startWith(startAction),
                    )
                }),
            );
}