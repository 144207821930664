import {DiagramDefaultsDto} from "../../../common/apis/diagram/DiagramDefaultsDto";


const initialState = {} as DiagramDefaultsDto;

enum DiagramDefaultsActionType {
    DIAGRAM_DEFAULTS_UPDATED = "DIAGRAM_DEFAULTS_UPDATED",
}

interface DiagramDefaultsAction {
    type: DiagramDefaultsActionType,
    diagramDefaults: DiagramDefaultsDto,
}

export function createDiagramDefaultsUpdatedAction(diagramDefaults: DiagramDefaultsDto): DiagramDefaultsAction {
    return {
        type: DiagramDefaultsActionType.DIAGRAM_DEFAULTS_UPDATED,
        diagramDefaults: diagramDefaults,
    }
}

export default function diagramDefaultsReducer(state = initialState, action: DiagramDefaultsAction) {
    switch (action.type) {
        case DiagramDefaultsActionType.DIAGRAM_DEFAULTS_UPDATED:
            return action.diagramDefaults;
        default:
            return state;
    }
}