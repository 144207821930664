import EventManager, {Unsubscriber} from "../../event/EventManager";
import RenderContext from "../context/RenderContext";
import {
    EventType,
    IBendpointCreateEvent,
    IBendpointMoveEvent,
    IChartEvent,
    IChartGridUnitsUpdateEvent, DiagramZoomEvent,
    INodeEvent,
    INodeResizeEvent,
    ISvgPaperAreaUpdatedEvent
} from "../../event/Event";
import * as d3 from "d3";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import {Point} from "../util/GeometryUtils";
import {DEFAULT_PAPER_FILL_COLOR, PAPER_GROUP_ID, PAPER_RECT_ID} from "./SvgPaperManager";
import {DEFAULT_LINE_COLOR} from "../common/UIConstants";

export interface ISnappingFunction {
    snap(coord: number): number,
    snapPoint(point: Point): void,
}

class MultipliesSnappingFunction implements ISnappingFunction {

    constructor(private multiplier: number) {
        this["multiplier"] = multiplier;
    }

    snapPoint(point: Point) {
        if (point && point.x != null) {
            point.x = this.snapToMultiplier(point.x);
        }
        if (point && point.y != null) {
            point.y = this.snapToMultiplier(point.y);
        }
    }

    snap(x: number): number {
        return this.snapToMultiplier(x);
    }

    private snapToMultiplier(coordinate: number): number {
        return this.multiplier * Math.round(coordinate / this.multiplier);
    }
}

enum PathId {
    NORMAL = "NORMAL",
    DARKER = "DARKER",
}

export const GRID_MIN_UNITS = 1;
export const GRID_DEFAULT_UNITS = 10;
export const GRID_MIN_UNITS_SNAPPING_FUNCTION = new MultipliesSnappingFunction(GRID_MIN_UNITS);

const PATH_COLOR = DEFAULT_LINE_COLOR;
const PATH_COLOR_DARKER = d3.color(DEFAULT_LINE_COLOR)?.darker(0.4).formatRgb() as string;
const GRID_PATTERN_ID = "__diagram-editor-grid-pattern__";

export default class GridManager {

    private static readonly DEFAULT_OPACITY = 0.1;
    private static readonly DEFAULT_OPACITY_DARKER = 0.35;
    private static readonly ACTIVE_OPACITY = 0.23;
    private static readonly ACTIVE_OPACITY_DARKER = 0.9;
    private static readonly OPACITY_TRANSITION_START_DURATION = 650;
    private static readonly OPACITY_TRANSITION_END_DURATION = 1000;

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private gridUnits: number;
    private gridVisible: boolean;
    private gridSnap: boolean;
    private gridScale: number;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        // NODE MOVE
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_STARTED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_FINISHED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_CANCELLED, this.handleNodeEvent.bind(this)));
        // NODE RESIZE
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_STARTED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_FINISHED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_CANCELLED, this.handleNodeResizeEvent.bind(this)));
        // CONNECTION BENDPOINT MOVE
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_STARTED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_FINISHED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_CANCELLED, this.handleBendpointMoveEvent.bind(this)));
        // CONNECTION BENDPOINT CREATE
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_STARTED, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_FINISHED, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_CANCELLED, this.handleBendpointCreateEvent.bind(this)));
        // CHART GRID SHOW
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_GRID_UPDATE_UNITS, this.handleChartGridUnitsUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_GRID_SNAP, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_GRID_DO_NOT_SNAP, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_GRID_SHOW, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_GRID_HIDE, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.SVG_PAPER_AREA_UPDATED, this.handleSvgPaperAreaUpdatedEvent.bind(this)));

        this.gridUnits = GRID_DEFAULT_UNITS;
        this.gridVisible = false;
        this.gridSnap = true;
        this.gridScale = 1;
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        if (renderContext.isEditOrPreEdit()) {
            this.appendGridFilter(renderContext);
        }
        this.publishSnappingFunction();
    }

    private appendGridFilter(renderContext: RenderContext) {
        const defsSelection = renderContext.svgElementManager.getDefsSelection();
        const size = this.gridUnits * 5;
        const pattern = defsSelection.append("pattern")
            .attr("id", GRID_PATTERN_ID)
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", size)
            .attr("height", size)
            .attr("viewBox", "0,0,100,100")
            .attr("patternUnits", "userSpaceOnUse");
        pattern.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 100)
            .attr("height", 100)
            .attr("fill", DEFAULT_PAPER_FILL_COLOR);
        pattern.append("path")
            .datum(PathId.DARKER)
            .attr("d", "M 0,100 L 0,0 L 100,0")
            .attr("fill", "none")
            .attr("stroke", PATH_COLOR_DARKER)
            .attr("stroke-width", 1)
            .attr("opacity", GridManager.DEFAULT_OPACITY_DARKER);
        const horizontalLines = "M 0,20 L 100,20 M 0,40 L 100,40 M 0,60 L 100,60 M 0,80 L 100,80 ";
        const verticalLines = "M 20,0 L 20,100 M 40,0 L 40,100 M 60,0 L 60,100 M 80,0 L 80,100 ";
        pattern.append("path")
            .datum(PathId.NORMAL)
            .attr("d", horizontalLines + verticalLines)
            .attr("fill", "none")
            .attr("stroke", PATH_COLOR)
            .attr("stroke-width", 1)
            .attr("opacity", GridManager.DEFAULT_OPACITY);
    }

    handleChartEvent(event: IChartEvent) {
        if (event.type === EventType.CHART_GRID_SNAP) {
            // snap to grid units
            this.gridSnap = true;
            this.publishSnappingFunction();
        }
        if (event.type === EventType.CHART_GRID_DO_NOT_SNAP) {
            this.gridSnap = false;
            // snap to GRID_MIN_UNITS, i.e. 1
            this.publishSnappingFunction();
        }
        if (event.type === EventType.CHART_GRID_SHOW) {
            this.gridVisible = true;
            GridManager.showGrid(true);
        }
        if (event.type === EventType.CHART_GRID_HIDE) {
            this.gridVisible = false;
            GridManager.showGrid(false);
        }
    }

    // GRID HIGHLIGHT EVENTS

    handleNodeEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_MOVE_STARTED) {
            GridManager.highlightGrid(true);
        }
        if (event.type === EventType.NODE_MOVE_FINISHED || event.type === EventType.NODE_MOVE_CANCELLED) {
            GridManager.highlightGrid(false);
        }
    }

    handleNodeResizeEvent(event: INodeResizeEvent) {
        if (event.type === EventType.NODE_RESIZE_STARTED) {
            GridManager.highlightGrid(true);
        }
        if (event.type === EventType.NODE_RESIZE_FINISHED || event.type === EventType.NODE_RESIZE_CANCELLED) {
            GridManager.highlightGrid(false);
        }
    }

    handleBendpointMoveEvent(event: IBendpointMoveEvent) {
        if (event.type === EventType.CONNECTION_BENDPOINT_MOVE_STARTED) {
            GridManager.highlightGrid(true);
        }
        if (event.type === EventType.CONNECTION_BENDPOINT_MOVE_FINISHED || event.type === EventType.CONNECTION_BENDPOINT_MOVE_CANCELLED) {
            GridManager.highlightGrid(false);
        }
    }

    handleBendpointCreateEvent(event: IBendpointCreateEvent) {
        if (event.type === EventType.CONNECTION_BENDPOINT_CREATE_STARTED) {
            GridManager.highlightGrid(true);
        }
        if (event.type === EventType.CONNECTION_BENDPOINT_CREATE_FINISHED || event.type === EventType.CONNECTION_BENDPOINT_CREATE_CANCELLED) {
            GridManager.highlightGrid(false);
        }
    }

    // GRID REFRESH EVENTS

    handleChartGridUnitsUpdateEvent(event: IChartGridUnitsUpdateEvent) {
        if (event.type === EventType.CHART_GRID_UPDATE_UNITS) {
            this.gridUnits = event.units;
            this.refreshGrid(this.gridUnits, this.gridScale);
            this.publishSnappingFunction();
        }
    }

    handleSvgPaperAreaUpdatedEvent(event: ISvgPaperAreaUpdatedEvent) {
        if (event.type === EventType.SVG_PAPER_AREA_UPDATED) {
            if (event.causeEvent.type === EventType.DIAGRAM_ZOOM_UPDATED &&
                (event.causeEvent as DiagramZoomEvent).actualZoom !== this.gridScale) {
                this.gridScale = (event.causeEvent as DiagramZoomEvent).actualZoom;
            }
            this.refreshGrid(this.gridUnits, this.gridScale);
        }
    }

    // PRIVATE FNCS

    private static showGrid(show: boolean) {
        const fill = show ? `url(#${GRID_PATTERN_ID})` : DEFAULT_PAPER_FILL_COLOR;
        GridManager.getPaperRectSelection()
            .attr("fill", fill);
    }

    private refreshGrid(gridUnits: number, gridScale: number) {
        if (this.renderContext) {
            if (this.renderContext.isEditOrPreEdit()) {

                const svg = this.renderContext.svgElementManager.getSvg();
                const diagramGroupNode = this.renderContext.svgElementManager.getDiagramGroupNode();
                const paperGroupNode = GridManager.getPaperGroupNode();

                const x0y0 = DiagramEditorUtils.convertPointFromGroupToGroup(new Point(0, 0), diagramGroupNode, paperGroupNode, svg);
                const patternSize = 5 * gridUnits * gridScale;

                GridManager.getGridPatternSelection()
                    .attr("x", x0y0.x)
                    .attr("y", x0y0.y)
                    .attr("width", patternSize)
                    .attr("height", patternSize);
            }
        }
    }

    private publishSnappingFunction() {
        let snappingFunction: ISnappingFunction;
        if (this.gridSnap) {
            snappingFunction = new MultipliesSnappingFunction(this.gridUnits);
        } else {
            snappingFunction = GRID_MIN_UNITS_SNAPPING_FUNCTION;
        }
        this.eventManager.publishEvent(
            {
                type: EventType.CHART_GRID_SNAPPING_FUNCTION_UPDATED,
                event: {},
                snappingFunction: snappingFunction,
            });
    }

    private static highlightGrid(highlight: boolean) {
        const opacity = highlight ? GridManager.ACTIVE_OPACITY : GridManager.DEFAULT_OPACITY;
        const opacityDarker = highlight ? GridManager.ACTIVE_OPACITY_DARKER : GridManager.DEFAULT_OPACITY_DARKER;
        const duration = highlight ? GridManager.OPACITY_TRANSITION_START_DURATION : GridManager.OPACITY_TRANSITION_END_DURATION;

        const patternPaths = GridManager.getGridPatternPathsSelection();
        patternPaths.transition()
            .duration(duration)
            .attr("opacity", (pathId) => pathId === PathId.NORMAL ? opacity : opacityDarker);
    }

    private static getPaperRectSelection() {
        return d3.select("#"+PAPER_RECT_ID) as d3.Selection<SVGRectElement, unknown, any, any>;
    }

    private static getPaperGroupNode() {
        return d3.select("#"+PAPER_GROUP_ID).node() as SVGGElement;
    }

    private static getGridPatternSelection() {
        return d3.select(`#${GRID_PATTERN_ID}`) as d3.Selection<SVGPatternElement, unknown, any, any>;
    }

    private static getGridPatternPathsSelection() {
        return d3.selectAll(`#${GRID_PATTERN_ID} path`) as d3.Selection<SVGPathElement, PathId , any, any>;
    }

}
