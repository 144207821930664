import {IDiagramNodeDto} from "../../../../common/apis/diagram/IDiagramNodeDto";
import {ChatDto} from "../../../../common/apis/chat/ChatService";

export interface ChatCoordinates {
    chatId: number;
    x: number;
    y: number;
}

export class ChatCoordinatesCorrector {

    public correctChatCoordinates(nodes: IDiagramNodeDto[], chats: ChatDto[]): ChatCoordinates[] {
        const filteredChats = this.filterChatsForNodes(chats, nodes);
        const correctedChatCoordinates: ChatCoordinates[] = [];

        filteredChats.forEach((chat) => {
            const diagramNode = nodes.find((node) => node.identifier === chat.chatNode!.nodeId);
            if (diagramNode) {
                const newChatCoordinates = this.buildNewChatCoordinates(chat, diagramNode);
                if (newChatCoordinates) {
                    correctedChatCoordinates.push(newChatCoordinates);
                }
            }
        });
        return correctedChatCoordinates;
    }

    private filterChatsForNodes(chats: ChatDto[], nodes: IDiagramNodeDto[]): ChatDto[] {
        return chats.filter(chat => {
            if (chat.chatNode && chat.chatNode.nodeId) {
                const node = nodes.find(node => node.identifier === chat.chatNode!.nodeId);
                return node !== undefined;
            }
            return false;
        });
    }

    private buildNewChatCoordinates(chat: ChatDto, diagramNode: IDiagramNodeDto): ChatCoordinates | undefined {
        const chatNode = chat.chatNode!;
        const newWidth = chatNode.x > diagramNode.w ? diagramNode.w : undefined;
        const newHeight = chatNode.y > diagramNode.h ? diagramNode.h : undefined;
        if (newWidth || newHeight) {
            return {
                chatId: chat.id,
                x: newWidth ? newWidth : chatNode.x,
                y: newHeight ? newHeight : chatNode.y
            };
        } else {
            return undefined;
        }
    }
}