import React, {useRef, useState} from "react";
import {CollectionDto} from "../../../../../common/apis/collection/CollectionDto";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {CommonTranslation} from "../../CommonTranslation";
import {CollectionsTranslationKey} from "../CollectionsTranslationKey";
import {SaveButton} from "../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../components/button/CancelButton";
import TextField from "../../../../../components/fields/textfield/TextField";
import constructCollectionsController, {CollectionsController} from "../controller/CollectionsController";
import collectionsService from "../service/CollectionsService";
import Snackbar from "../../snackbar/Snackbar";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import Grid from "../../../../../components/dialogs/Grid";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import {CollectionConstant} from "../service/CollectionConstant";

interface UpdateCollectionDialogProps {
    onClosed: () => void,
    collection: CollectionDto,
    onCollectionUpdate: (updatedCollection: CollectionDto) => void,
}

export function UpdateCollectionDialog(props: UpdateCollectionDialogProps) {

    const collectionController = useRef<CollectionsController>(constructCollectionsController(collectionsService));

    const {onClosed, onCollectionUpdate} = props;

    const [collectionUpdate, setCollectionUpdate] = useState<CollectionDto>(props.collection);
    const [nameError, setNameError] = useState<string>();


    function saveChanges() {
        if (collectionUpdate.name.trim().length === 0) {
            setNameError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        } else {
            setNameError(undefined);
            (async () => {
                try {
                    await collectionController.current.update(collectionUpdate.code, collectionUpdate.name, collectionUpdate.description);
                    onCollectionUpdate(collectionUpdate)
                    Snackbar.success(_transl(CollectionsTranslationKey.UPDATE_SUCCEEDED));
                } catch (error) {
                    Snackbar.error(_transl(CollectionsTranslationKey.UPDATE_FAILED));
                }
            })();
        }
    }

    function onDialogClosed() {
        onClosed();
    }

    return (
        <Dialog open onClose={onDialogClosed}>
            <DialogTitle title={_transl(CollectionsTranslationKey.UPDATE_TITLE)}
                         onDialogClosed={onDialogClosed}
            />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="name-field"
                                   label={_transl(CommonTranslation.TITLE)}
                                   errorMessage={nameError}
                                   value={collectionUpdate.name}
                                   onChange={e => setCollectionUpdate({...collectionUpdate, name: e})}
                                   maxLength={CollectionConstant.NAME_MAX_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="type-field"
                                   label={_transl(CommonTranslation.CODE)}
                                   value={collectionUpdate.code}
                                   disabled={true}
                                   maxLength={CollectionConstant.CODE_MAX_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="value-field"
                                   label={_transl(CommonTranslation.DESCRIPTION)}
                                   value={collectionUpdate.description || ""}
                                   onChange={e => setCollectionUpdate({
                                       ...collectionUpdate,
                                       description: e
                                   })}
                                   maxLength={CollectionConstant.DESCRIPTION_MAX_LENGTH}

                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={saveChanges}/>
                <CancelButton onClick={onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );
}
