import * as d3 from "d3";
import EventManager, {Unsubscriber} from "../../event/EventManager";
import {Event, EventType, IChartResizedEvent, DiagramZoomEvent, ISvgPaperAreaUpdatedEvent} from "../../event/Event";
import RenderMode from "../context/RenderMode";
import {Area, Point} from "../util/GeometryUtils";
import {CONTENT_AREA_ID} from "../../../components/diagrameditor/DiagramEditorComponent";
import Constants from "../../Constants";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import {PAPER_PADDING_SIZE} from "./SvgPaperManager";
import ChartGroup, {ChartGroupType} from "../common/ChartGroup";
import {IMode} from "../model/IMode";

const INITIAL_PAPER_TOP_LEFT_PADDING = 30;

export default class SvgElementManager {

    private eventManager: EventManager;
    private svg?: SVGSVGElement;
    private parentRect?: DOMRect;
    private renderMode?: IMode;

    private keyDownListener: (event: any) => void;
    private keyUpListener: (event: any) => void;

    // resize
    private resizeObserver: ResizeObserver;
    private isInitialResizeObserverCall: boolean;
    private lastResizeArea?: Area;
    private lastZoom: number;

    // paper update
    private ignoreNextPaperAreaUpdate: boolean;

    private chartRendered: boolean;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.DIAGRAM_ZOOM_UPDATED, this.handleChartZoomEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.SVG_PAPER_AREA_UPDATED, this.handleSvgPaperAreaUpdatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_RESIZED, this.handleChartResizedEvent.bind(this)));

        this.keyDownListener = (event) => {
            this.eventManager.publishEvent({type: EventType.CHART_KEYDOWN, event: event});
        }
        this.keyUpListener = (event) => {
            this.eventManager.publishEvent({type: EventType.CHART_KEYUP, event: event});
        }

        // resize
        this.resizeObserver = new ResizeObserver((selection) => {
            if (this.isInitialResizeObserverCall) {
                this.isInitialResizeObserverCall = false;
            } else if (selection.length === 1) {
                this.isInitialResizeObserverCall = false;
                const rect = selection[0].contentRect;
                this.eventManager.publishEvent({
                    type: EventType.CHART_RESIZED,
                    area: new Area(rect.x, rect.y, rect.width, rect.height)
                });
            }
        });

        this.isInitialResizeObserverCall = true;
        this.ignoreNextPaperAreaUpdate = false;
        this.chartRendered = false;
        this.lastZoom = 1;
    }

    destroy() {
        this.clearSvgElement();
        if (this.isEditOrPreEditMode()) {
            window.document.removeEventListener("keydown", this.keyDownListener);
            window.document.removeEventListener("keyup", this.keyUpListener);
            this.resizeObserver.disconnect();
        }

        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    public init(svgRef: SVGSVGElement, parentRect: DOMRect, renderMode: IMode) {
        this.svg = svgRef;
        this.parentRect = parentRect;
        this.renderMode = renderMode;

        // create svg element
        this.initSvgElement();
        // init listeners
        if (this.isEditOrPreEditMode()) {
            this.initSvgElementListeners();
        }
        // create defs element within chart group
        this.createDefsElement();
    }

    handleChartZoomEvent(event: DiagramZoomEvent) {
        this.lastZoom = event.actualZoom;
    }

    handleSvgPaperAreaUpdatedEvent(event: ISvgPaperAreaUpdatedEvent) {
        this.setSvgWidthHeight(event);
    }

    handleChartResizedEvent(event: IChartResizedEvent) {
        this.setSvgWidthHeight(event);
    }

    public getSvg() {
        return this.svg as SVGSVGElement;
    }

    public getParentRect() {
        return this.parentRect as DOMRect;
    }

    public getSvgSelection() {
        return d3.select(this.getSvg()) as d3.Selection<SVGSVGElement, unknown, any, undefined>;
    }

    public getDiagramGroupSelection() {
        return d3.select(SvgElementManager.createChartGroupId(true)) as d3.Selection<SVGGElement, unknown, any, undefined>;
    }

    public getDiagramGroupNode() {
        return d3.select(SvgElementManager.createChartGroupId(true)).node() as SVGGElement;
    }

    public getDefsSelection() {
        return d3.select(SvgElementManager.createDefsId(true)) as d3.Selection<SVGDefsElement, unknown, any, any>;
    }

    private clearSvgElement() {
        if (this.svg) {
            d3.select(this.svg).selectAll("*").remove();
        }
    }

    private initSvgElement() {
        const svgElement = this.getSvgSelection();
        if (!svgElement.empty()) {
            svgElement
                .attr("pointer-events", "all")
                .attr("cursor", "default")
                .style("display", "block");

            if (this.renderMode?.mode !== RenderMode.PREVIEW && this.renderMode?.mode !== RenderMode.EXPORT) {
                svgElement.style("background-color", Constants.EDITOR_BACKGROUND_COLOR);
            }

            if (this.isEditOrPreEditMode()) {
                SvgElementManager.getContentAreaSelection()
                    .style("overflow", "scroll");
            }

            this.setSvgDimensions(svgElement);
        }
    }

    private setSvgDimensions(svgElement: d3.Selection<SVGSVGElement, unknown, any, undefined>) {
        // fit the svg to parent container and let the chart render (eventual clipping will not be visible)
        // once the chart renders then resize the svg element
        const parentDimensions = SvgElementManager.getContentAreaDimensions();
        if (this.isEditOrPreEditMode()) {
            svgElement.style("width", parentDimensions.width);
            svgElement.style("height", parentDimensions.height);
        } else {
            svgElement.style("width", `${parentDimensions.width}px`);
            svgElement.style("height", `${parentDimensions.height}px`);
        }
    }

    private isEditOrPreEditMode() {
        return this.renderMode?.mode === RenderMode.EDIT ||
            this.renderMode?.mode === RenderMode.PRE_EDIT;
    }

    private createDefsElement() {
        this.getSvgSelection()
            .append("defs")
            .attr("id", SvgElementManager.createDefsId());
    }

    private initSvgElementListeners() {
        window.document.addEventListener("keydown", this.keyDownListener);
        window.document.addEventListener("keyup", this.keyUpListener);
        const contentArea = d3.select("#"+CONTENT_AREA_ID).node() as HTMLElement;
        this.resizeObserver.observe(contentArea);

        const svgElement = this.getSvgSelection();
        if (!svgElement.empty()) {
            svgElement
                .on("mousemove", e => {
                    this.eventManager.publishEvent({type: EventType.CHART_MOUSE_MOVE, event: e})
                })
                .on("mouseenter", e => {
                    this.eventManager.publishEvent({type: EventType.CHART_MOUSE_ENTER, event: e})
                })
                .on("mousedown", e => {
                    this.eventManager.publishEvent({type: EventType.CHART_MOUSE_DOWN, event: e})
                })
                .on("mouseup", e => {
                    this.eventManager.publishEvent({type: EventType.CHART_MOUSE_UP, event: e})
                })
                .on("mouseleave", e => {
                    this.eventManager.publishEvent({type: EventType.CHART_MOUSE_LEAVE, event: e})
                })
                .on("click", e => {
                    this.eventManager.publishEvent({type: EventType.CHART_MOUSE_CLICKED, event: e})
                })
                .on("contextmenu", e => {
                    //e.preventDefault()
                });
        }
    }

    public static createChartGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.CHART_GROUP, selector);
    }

    public static createDefsId(selector?: boolean) {
        return (selector === true ? "#" : "") + `__diagram-editor-defs-__`;
    }

    static getContentAreaSelection() {
        return d3.select("#"+CONTENT_AREA_ID) as d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
    }

    static getContentAreaNode() {
        return d3.select("#"+CONTENT_AREA_ID).node() as HTMLDivElement;
    }

    static getContentAreaDimensions() {
        return (d3.select("#"+CONTENT_AREA_ID).node() as HTMLDivElement).getBoundingClientRect();
    }

    private getLastZoom(event: Event) {
        if (event.type === EventType.DIAGRAM_ZOOM_UPDATED) {
            this.lastZoom = (event as DiagramZoomEvent).actualZoom;
        }
        return this.lastZoom;
    }

    private setSvgWidthHeight(causeEvent: Event) {
        if (this.ignoreNextPaperAreaUpdate) {
            this.ignoreNextPaperAreaUpdate = false;
        } else {
            const svgElemSelection = this.getSvgSelection();
            const svgElemNode = this.getSvg();
            const diagramGroupNode = this.getDiagramGroupNode();
            const contentAreaNode = d3.select("#"+CONTENT_AREA_ID).node() as HTMLElement;

            const diagramGroupClientRect = diagramGroupNode.getBoundingClientRect();
            const paperBBox = diagramGroupNode.getBBox();

            if (this.isEditOrPreEditMode()) {
                const clientWidth = contentAreaNode.clientWidth - PAPER_PADDING_SIZE;
                const clientHeight = contentAreaNode.clientHeight - PAPER_PADDING_SIZE;

                const newSvgWidth = diagramGroupClientRect.width + (2 * clientWidth);
                const newSvgHeight = diagramGroupClientRect.height + (2 * clientHeight);

                svgElemSelection.attr("width", newSvgWidth).style("width", newSvgWidth).attr("height", newSvgHeight).style("height", newSvgHeight);

                // get actual scrollLeft/Top
                const paperGroupLeftTop = DiagramEditorUtils.convertPointFromGroupToGroup(new Point(contentAreaNode.scrollLeft, contentAreaNode.scrollTop), svgElemNode, diagramGroupNode, svgElemNode);

                this.ignoreNextPaperAreaUpdate = true;

                // move paper to 0, 0 (take scaling into cosinderation) and then to clientLeft/Top
                const newPaperXTranslate = (0 - (paperBBox.x * this.getLastZoom(causeEvent))) + clientWidth;
                const newPaperYTranslate = (0 - (paperBBox.y * this.getLastZoom(causeEvent))) + clientHeight;

                this.eventManager.publishEvent({
                    type: EventType.DIAGRAM_GROUP_MOVE_TO_REQUEST,
                    top: newPaperYTranslate,
                    left: newPaperXTranslate,
                });

                // restore scrollLeft/Top relative to new paper position
                let svgLeftTop = DiagramEditorUtils.convertPointFromGroupToGroup(paperGroupLeftTop, diagramGroupNode, svgElemNode, svgElemNode);

                if (causeEvent.type === EventType.CHART_RENDERED ||
                    (causeEvent.type === EventType.SVG_PAPER_AREA_UPDATED && (causeEvent as ISvgPaperAreaUpdatedEvent).causeEvent.type === EventType.CHART_RENDERED)) {
                    svgLeftTop = new Point(svgLeftTop.x - PAPER_PADDING_SIZE, svgLeftTop.y - PAPER_PADDING_SIZE);

                    // paper shrinks to min coordinates (even when it is a positive point, e.g. 60,60)
                    // -> scroll to the min coordinate
                    const bbox = diagramGroupNode.getBBox();
                    svgLeftTop.x += (bbox.x - PAPER_PADDING_SIZE);
                    svgLeftTop.y += (bbox.y - PAPER_PADDING_SIZE);
                }

                this.eventManager.publishEvent({
                    type: EventType.SCROLL_TO_REQUEST,
                    top: svgLeftTop.y,
                    left: svgLeftTop.x,
                });
            } else if (this.renderMode?.mode === RenderMode.VIEW || this.renderMode?.mode === RenderMode.EXPORT) {
                const newSvgWidth = diagramGroupClientRect.width + (2 * PAPER_PADDING_SIZE) + INITIAL_PAPER_TOP_LEFT_PADDING;
                const newSvgHeight = diagramGroupClientRect.height + (2 * PAPER_PADDING_SIZE) + INITIAL_PAPER_TOP_LEFT_PADDING;

                svgElemSelection.attr("width", newSvgWidth).style("width", newSvgWidth).attr("height", newSvgHeight).style("height", newSvgHeight);

                this.ignoreNextPaperAreaUpdate = true;

                // move paper to 0, 0 (take scaling into cosinderation) and then to clientLeft/Top
                const newPaperXTranslate = (0 - (paperBBox.x * this.getLastZoom(causeEvent))) + INITIAL_PAPER_TOP_LEFT_PADDING;
                const newPaperYTranslate = (0 - (paperBBox.y * this.getLastZoom(causeEvent))) + INITIAL_PAPER_TOP_LEFT_PADDING;

                this.eventManager.publishEvent({
                    type: EventType.DIAGRAM_GROUP_MOVE_TO_REQUEST,
                    top: newPaperYTranslate,
                    left: newPaperXTranslate,
                });
            }
        }
    }

}
