import React, {useContext, useState} from "react";
import {Stack, Tab, Tabs} from "@mui/material";
import {HierarchyTreeDto, HierarchyTreeInfoDto} from "./service/HierarchyTreeDto";
import {Ellipsis} from "react-simple-ellipsis"
import HierarchyTreeCrudOpsContext from "./HierarchyTreeCrudOpsContext";
import {TooltippedIconButton} from "../../../../components/button/TooltippedIconButton";
import {Add} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import HierarchyTreeContextMenu from "./HierarchyTreeContextMenu";

type TreeContextMenuDef = {
    x: number,
    y: number,
    treeInfo: HierarchyTreeInfoDto,
}

export interface HierarchyTreeChooserProps {
    treeInfos: HierarchyTreeInfoDto[];
    selectedTree?: HierarchyTreeDto;
    onTreeSelect: (treeId: string) => void;
    canCreateHierarchyTrees: boolean;
}

const MAX_CHARS_TREE_TITLE_LIMIT = 25;

export default function HierarchyTreeChooser({treeInfos, selectedTree, onTreeSelect, canCreateHierarchyTrees}: HierarchyTreeChooserProps) {
    const crudOps = useContext(HierarchyTreeCrudOpsContext);
    const [contextMenuDef, setContextMenuDef] = useState<TreeContextMenuDef | undefined>();

    function onTreeSelected(treeId: string) {
        onTreeSelect(treeId);
    }

    function openContextMenu(event: React.MouseEvent<HTMLDivElement, MouseEvent>, treeInfo: HierarchyTreeInfoDto) {
        event.preventDefault();
        setContextMenuDef({
            x: event.clientX,
            y: event.clientY,
            treeInfo: treeInfo,
        });
    }

    function closeContextMenu() {
        setContextMenuDef(undefined);
    }

    function onCreateTabClicked() {
        crudOps.requestToCreateTree();
    }

    return <>
        <HierarchyTreeContextMenu open={contextMenuDef !== undefined}
                                  anchorPosition={(contextMenuDef ? {left: contextMenuDef?.x, top: contextMenuDef?.y} : undefined)}
                                  tree={contextMenuDef?.treeInfo}
                                  onClose={closeContextMenu} />

        <Stack direction={"row"}>
            <Tabs value={selectedTree?.id ?? false}
                  indicatorColor="primary"
                  textColor="primary"
                  variant={"scrollable"}
                  scrollButtons={"auto"}
                  onChange={(event: any, treeId: string) => onTreeSelected(treeId)}>
                {treeInfos.map(treeInfo =>
                    <Tab key={treeInfo.id}
                         value={treeInfo.id}
                         label={
                             <Ellipsis
                                 label=""
                                 id={treeInfo.id}
                                 text={treeInfo.title}
                                 limit={MAX_CHARS_TREE_TITLE_LIMIT}
                             />}
                         onContextMenu={(event) => openContextMenu(event, treeInfo)}/>)
                }
            </Tabs>
            {canCreateHierarchyTrees &&
                <>
                    <Divider orientation={"vertical"}
                             variant={"middle"}
                             flexItem
                             sx={{marginRight: 1}} />
                    <TooltippedIconButton onClick={onCreateTabClicked}
                                          icon={<Add/>}
                                          size={"large"} />
                </>
            }
        </Stack>
    </>

}
