import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React from "react";
import {
    AttachmentDeleteResourceIdType,
} from "../../../store/common/Attachments";
import {DeleteStatusType, IDeletableResourceState} from "../../../store/common/DeletableResource";
import Box from "@mui/material/Box";
import {CircularProgress, Paper} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            padding: theme.spacing(1),
        },
        paper: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        },
        statusPanel: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        actionIcon: {
            marginRight: "0.5em",
        },
        downloadSuccess: {
            backgroundColor: theme.palette.success.main,
            color: "white",
        },
        downloadError: {
            backgroundColor: theme.palette.error.main,
            color: "white",
        }
    })
);

interface Props {
    attachment: IDeletableResourceState<AttachmentDeleteResourceIdType>
}

export default function DeleteActionPanel(props: Props) {

    const classes = useStyles();

    function getDeleteStatusClasses(status: DeleteStatusType) {
        if (status === DeleteStatusType.FAILED) {
            return classes.downloadError;
        } else if (status === DeleteStatusType.SUCCESSFUL) {
            return classes.downloadSuccess;
        } else {
            return "";
        }
    }

    const { attachment } = props;
    const status = attachment.deleteStatus.status;
    const deleteStatusClasses = getDeleteStatusClasses(status);
    const paperClasses = `${classes.paper} ${deleteStatusClasses}`;

    return (
        <Box className={classes.root}>
            <Paper elevation={1} className={paperClasses}>
                {status === DeleteStatusType.STARTED && <div className={classes.statusPanel}>
                    <DeleteIcon className={classes.actionIcon} color="primary" /> <CircularProgress color="primary" size={20}/>
                </div>
                }

                {status === DeleteStatusType.SUCCESSFUL && <div className={classes.statusPanel}>
                    <DeleteIcon className={classes.actionIcon}/> <CheckIcon />
                </div>
                }

                {status === DeleteStatusType.FAILED && <div className={classes.statusPanel}>
                    <DeleteIcon className={classes.actionIcon}/> {"Smazání selhalo."}
                </div>
                }
            </Paper>
        </Box>
    )

}
