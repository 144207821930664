import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";

export const findClosestParentNode = (diagramNodes: IDiagramNodeDto[], newNode: IDiagramNodeDto): IDiagramNodeDto | null => {
    const parentNodes = diagramNodes.filter((node) => {
        if (newNode.identifier === node.identifier) {
            return false;
        }
        return newNode.x >= node.x &&
            newNode.x <= node.x + node.w &&
            newNode.y >= node.y &&
            newNode.y <= node.y + node.h;
    });

    let closestParentNode: IDiagramNodeDto | null = null;
    let minArea = Infinity;

    parentNodes.forEach((node) => {
        const area = node.w * node.h;
        if (area < minArea) {
            minArea = area;
            closestParentNode = node;
        }
    });

    return closestParentNode;
};