import EventManager, {Unsubscriber} from "../../event/EventManager";
import {ModelManager} from "./ModelManager";
import {
    ClipboardEventType,
    CreateItemsFromClipboardEvent, PasteItemsFromClipboardDuplicatesEvent, PasteItemsFromClipboardEvent
} from "../../../pages/main/content/diagrams/diagrameditor/ClipboardEvents";


export default class PasteItemsClipboardManager {
    private eventManager: EventManager;
    private modelManager: ModelManager;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager, modelManager: ModelManager) {
        this.eventManager = eventManager;
        this.modelManager = modelManager;

        this.unsubscribers.push(this.eventManager.subscribeListener(ClipboardEventType.PASTE_ITEMS_FROM_CLIPBOARD,
            this.handlePasteItemsFromClipboardEvent.bind(this)));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    handlePasteItemsFromClipboardEvent(event: PasteItemsFromClipboardEvent) {
        if (event.type === ClipboardEventType.PASTE_ITEMS_FROM_CLIPBOARD) {
            let containsDuplicateNode = this.containsDuplicateNode(event.itemsInfo.nodes.map(value => value.elementIdentifier));
            if (containsDuplicateNode) {
                const pasteItemsFromClipboardDuplicatesEvent: PasteItemsFromClipboardDuplicatesEvent = {
                    type: ClipboardEventType.PASTE_ITEMS_FROM_CLIPBOARD_DUPLICATES_WARNING,
                    itemsInfo: event.itemsInfo,
                };
                this.eventManager.publishEvent(pasteItemsFromClipboardDuplicatesEvent);
            } else {
                const createItemsFromClipboardEvent: CreateItemsFromClipboardEvent = {
                    type: ClipboardEventType.CREATE_ITEMS_FROM_CLIPBOARD,
                    itemsInfo: event.itemsInfo,
                };
                this.eventManager.publishEvent(createItemsFromClipboardEvent);
            }
        }
    }

    private containsDuplicateNode(elementsIdentifiers: string[]) {
        let containsDuplicate: boolean = false;
        for (const elementIdentifier of elementsIdentifiers) {
            if (this.elementIsInModel(elementIdentifier)) {
                containsDuplicate = true;
                break;
            }
        }
        return containsDuplicate;
    }

    elementIsInModel(elementIdentifier: string): boolean {
        return this.modelManager.getElements().find(value => value.identifier === elementIdentifier) !== undefined;
    }

}