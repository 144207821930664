export interface ElementAclDto {
    canUpdate: boolean,
    canDelete: boolean,
    canUpdateCollections: boolean,
    canUpdatePublicLabels: boolean,
    canMerge: boolean,
    canAlterType: boolean,
    canCreateChats: boolean,
    canCreateAttachment: boolean,
    canCreateProperty: boolean,
    canRead: boolean,
}

export class ElementAclDto {
    static builder(): ElementAclDtoBuilder {
        return new ElementAclDtoBuilder();
    }
}

export class ElementAclDtoBuilder {
    _canUpdate: boolean;
    _canDelete: boolean;
    _canUpdateCollections: boolean;
    _canUpdatePublicLabels: boolean;
    _canMerge: boolean;
    _canAlterType: boolean;
    _canCreateChats: boolean;
    _canCreateAttachment: boolean;
    _canCreateProperty: boolean;
    _canRead: boolean;

    constructor() {
        this._canUpdate = false;
        this._canDelete = false;
        this._canUpdateCollections = false;
        this._canUpdatePublicLabels = false;
        this._canMerge = false;
        this._canAlterType = false;
        this._canCreateChats = false;
        this._canCreateAttachment = false;
        this._canCreateProperty = false;
        this._canRead = false;
    }

    canUpdate(canUpdate: boolean) {
        this._canUpdate = canUpdate;
        return this;
    }

    canDelete(canDelete: boolean) {
        this._canDelete = canDelete;
        return this;
    }

    canUpdateCollections(canUpdateCollections: boolean) {
        this._canUpdateCollections = canUpdateCollections;
        return this;
    }

    canUpdatePublicLabels(canUpdatePublicLabels: boolean) {
        this._canUpdatePublicLabels = canUpdatePublicLabels;
        return this;
    }

    canMerge(canMerge: boolean) {
        this._canMerge = canMerge;
        return this;
    }

    canAlterType(canAlterType: boolean) {
        this._canAlterType = canAlterType;
        return this;
    }

    canCreateChats(canCreateComment: boolean) {
        this._canCreateChats = canCreateComment;
        return this;
    }

    canCreateAttachment(canCreateAttachment: boolean) {
        this._canCreateAttachment = canCreateAttachment;
        return this;
    }

    canCreateProperty(canCreateProperty: boolean) {
        this._canCreateProperty = canCreateProperty;
        return this;
    }

    canRead(canRead: boolean) {
        this._canRead = canRead;
        return this;
    }

    build(): ElementAclDto {
        return {
            canUpdate: this._canUpdate,
            canDelete: this._canDelete,
            canUpdateCollections: this._canUpdateCollections,
            canUpdatePublicLabels: this._canUpdatePublicLabels,
            canMerge: this._canMerge,
            canAlterType: this._canAlterType,
            canCreateChats: this._canCreateChats,
            canCreateAttachment: this._canCreateAttachment,
            canCreateProperty: this._canCreateProperty,
            canRead: this._canRead,
        }
    }
}
