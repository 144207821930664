import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {UsersTranslationKey} from "../users/UsersTranslationKey";
import DialogContent from "../../../../components/dialogs/DialogContent";
import React, {useEffect, useState} from "react";
import Grid from "../../../../components/dialogs/Grid";
import TextField from "../../../../components/fields/textfield/TextField";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {CollectionPermissionLevelDto} from "../../../../common/apis/user/CollectionPermissionLevelDto";
import userService from "../../../../common/apis/UserService";
import Snackbar from "../snackbar/Snackbar";
import SingleSelectField from "../../../../components/fields/textfield/SingleSelectField";

interface UpdatePermissionDialogProps {
    onClose: () => void,
    login: string,
    reloadUserPermissionsGrid: () => void,
    collectionName: string,
    collectionCode: string,
    permissionCurrentCode: string,
}

export default function UpdatePermissionDialog(props: UpdatePermissionDialogProps) {
    const {onClose, login, collectionCode, collectionName, permissionCurrentCode, reloadUserPermissionsGrid} = props;

    const [permissions, setPermissions] = useState<CollectionPermissionLevelDto[]>([]);
    const [permissionCode, setPermissionCode] = useState<string>(permissionCurrentCode);

    useEffect(() => {
        const fetchPermissions = async () => {
            const permissions = await getPermissions();
            setPermissions(permissions);
        }
        fetchPermissions();
    }, []);

    const getPermissions = async (): Promise<CollectionPermissionLevelDto[]> => {
        try {
            const permissions = await userService.getAllPermissionLevels();
            return permissions;
        } catch (error) {
            return [];
        }
    }

    function onDialogSave() {
        (async () => {
            try {
                await userService.createUserCollectionPermission(login, collectionCode, permissionCode);
                onClose();
                reloadUserPermissionsGrid();
                Snackbar.success(_transl(UsersTranslationKey.UPDATE_PERMISSION_SUCCEEDED));
            } catch (e) {
                Snackbar.error(_transl(UsersTranslationKey.UPDATE_PERMISSION_SUCCEEDED));
            }
        })();
    }

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(UsersTranslationKey.ADD_PERMISSION_CHANGE)}
                         onDialogClosed={onClose}/>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="collection-field"
                                   label={_transl(UsersTranslationKey.ADD_PERMISSION_COLLECTION)}
                                   value={collectionName}
                                   disabled/>
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelectField<CollectionPermissionLevelDto>
                            id="userPermission-field"
                            label={_transl(UsersTranslationKey.ADD_PERMISSION_PERMISSION_LEVEL)}
                            value={permissionCode}
                            onChange={(permissionCode) => setPermissionCode(permissionCode)}
                            selectValue={(permission: CollectionPermissionLevelDto) => permission.code}
                            renderName={(permission: CollectionPermissionLevelDto) => permission.name}
                            items={permissions}
                            required={true}/>

                    </Grid>
                </Grid>


            </DialogContent>

            <DialogActions>
                <SaveButton onClick={onDialogSave}/>
                <CancelButton onClick={onClose}/>
            </DialogActions>

        </Dialog>
    );
}
