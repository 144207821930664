export enum LeftPanelTabId {
    DETAIL = "DETAIL",
    CHATS = "CHATS",
    ATTACHMENTS = "ATTACHMENTS",
}

export enum RightPanelTabId {
    PREVIEW = "PREVIEW",
    ATTRIBUTES = "ATTRIBUTES",
}

export enum FetchStatus {
    IN_PROGRESS = "IN_PROGRESS",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
}
