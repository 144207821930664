import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import {CancelButton} from "../../../../../components/button/CancelButton";
import React, {useState} from "react";
import {TrackerTranslationKey} from "./TrackerTranslationKey";
import FormControl from "@mui/material/FormControl";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import Grid from "../../../../../components/dialogs/Grid";
import {OkButton} from "../../../../../components/button/OkButton";

export enum AssignTaskOption {
    ASSIGN_EXISTING_TASK = "ASSIGN_EXISTING_TASK",
    ASSIGN_NEW_TASK = "ASSIGN_NEW_TASK"
}

export interface AddTaskOptionsDialogProps {
    onClose: () => void,
    onOptionSelected: (option: AssignTaskOption) => void
}

export default function AssignTaskOptionsDialog(props: AddTaskOptionsDialogProps) {
    const {onClose, onOptionSelected} = props;

    const [addTaskOption, setAddTaskOption] = useState<AssignTaskOption>(AssignTaskOption.ASSIGN_EXISTING_TASK);

    function handleOnOptionChanged(event: React.ChangeEvent<HTMLInputElement>, value: string) {
        setAddTaskOption(value as AssignTaskOption);
    }

    function handleOnOkButtonClicked(): void {
        onOptionSelected(addTaskOption);
        onClose();
    }

    return (
            <Dialog open={true} onClose={onClose}>
                <DialogTitle id="add-task-option-dialog-title"
                             title={_transl(TrackerTranslationKey.OPTIONS_DIALOG_CHOOSE_ASSIGN_TASK_OPTION)}
                             onDialogClosed={onClose}
                />
                <DialogContent>
                    <Grid container>
                        <FormControl>
                            <RadioGroup
                                    aria-labelledby="add-task-option-dialog-radio-buttons-group-label"
                                    value={addTaskOption}
                                    name="add-task-option-dialog-radio-buttons-group"
                                    onChange={handleOnOptionChanged}
                            >
                                <FormControlLabel value={AssignTaskOption.ASSIGN_EXISTING_TASK}
                                                  control={<Radio/>}
                                                  label={_transl(TrackerTranslationKey.OPTIONS_DIALOG_ASSIGN_EXISTING_TASK)}
                                />
                                <FormControlLabel value={AssignTaskOption.ASSIGN_NEW_TASK}
                                                  control={<Radio/>}
                                                  label={_transl(TrackerTranslationKey.OPTIONS_DIALOG_ASSIGN_NEW_TASK)}
                                                  disabled={true}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <OkButton onClick={handleOnOkButtonClicked}/>
                    <CancelButton onClick={onClose}/>
                </DialogActions>
            </Dialog>
    )
            ;

}
