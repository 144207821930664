import {DialogContent as MuiDialogContent, DialogContentProps as MuiDialogContentProps} from "@mui/material";

export interface DialogContentProps extends MuiDialogContentProps {
}

export default function DialogContent(props: DialogContentProps) {
    return (
        <MuiDialogContent {...props}>
            {props.children}
        </MuiDialogContent>
    );
}
