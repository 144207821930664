import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import ChatPin from "../../../../components/chat/ChatPin";
import chatService, {
    ChatComparator,
    ChatDto,
    ChatNodeDto,
    ChatReadDto,
    ChatState
} from "../../../../common/apis/chat/ChatService";
import {DiagramEditor} from "../../../../common/diagrameditor/DiagramEditor";
import {DiagramNode} from "../../../../common/diagrameditor/model/Model";
import {
    DiagramZoomEvent,
    EventType,
    IModelUpdatedOnNodesResizedEvent
} from "../../../../common/event/Event";
import {Point} from "../../../../common/diagrameditor/util/GeometryUtils";
import Snackbar from "../../../../pages/main/content/snackbar/Snackbar";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ChatTranslationKey} from "./ChatTranslationKey";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import ChatDialog from "./ChatDialog";
import {NEW_CHAT_ID} from "./ChatCommentCreateFooter";
import {ChangeChatLayerVisibilityEvent, ChatEventType, CreateNewChatRequestEvent} from "./ChatEvents";
import EventManagerContext from "../../../../common/event/EventManagerContext";
import {ChatCoordinatesManager} from "./ChatCoordinatesManager";
import {
    DiagramEditorEventType,
    ModalWindowVisibilityChangedEvent
} from "../../../../common/event/diagrameditor/DiagramEditorEvents";

export interface NodeCoordinate {
    x: number;
    y: number;
}

interface ChatLayerProps {
    open: boolean;
    editor: DiagramEditor | null;
    diagramId: string;
    onChatsUpdated?: () => void;
}

interface ChatDtoWrapper {
    chat: ChatDto;
    scrollDown: boolean;
}

export default function ChatLayer(props: ChatLayerProps) {
    const {open, editor, diagramId, onChatsUpdated} = props

    const [chats, setChats] = useState<ChatDto []>();
    const [chatLastReadMap, setChatLastReadMap] = useState<Map<number, ChatReadDto | undefined>>(new Map());
    const [openedChat, setOpenedChat] = useState<ChatDtoWrapper>();
    const [refreshChats, setRefreshChats] = useState<number>(0);
    const [refreshChatReads, setRefreshChatReads] = useState<number>(0);

    const [translation, setTranslation] = useState<Point>(new Point(0, 0));
    const [scaleFactor, setScaleFactor] = useState<number>(1);

    const relevantChats = filterOnlyRelevantChats(chats ?? [], diagramId);

    const eventManager = useContext(EventManagerContext);
    const chatCoordinatesManager = useRef(new ChatCoordinatesManager());

    useEffect(() => {
        if (open) {
            chatService.searchChatsByFilter({diagramId: diagramId, state: ChatState.UNRESOLVED, containsChatNode: true})
                .then(chats => {
                    chats = chats.sort((a, b) => ChatComparator.compareByCreationDate(a, b));
                    setChats(chats);
                })
                .catch(() => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA)));
        } else {
            setChats(undefined);
        }
    }, [open, diagramId, refreshChats]);

    useEffect(() => {
        const unsubscribe = eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_RESIZED,
            async (event: IModelUpdatedOnNodesResizedEvent) => {
                if (event.nodes) {
                    await chatCoordinatesManager.current.fixChatCoordinatesForDiagram(diagramId, event.nodes);
                    setRefreshChats(new Date().getTime());
                }
            });
        return () => {
            unsubscribe();
        };
    }, [eventManager, diagramId]);

    useEffect(() => {
        if (chats) {
            const lastChatReadPromises = chats.map(chat => chatService.findLastRead(chat.id));
            Promise.all(lastChatReadPromises)
                .then(chatReads => {
                    const chatReadMap = new Map(
                        chatReads.map((chatRead, index) => [chats[index].id, chatRead]));
                    setChatLastReadMap(chatReadMap);
                });
        }
    }, [chats, refreshChatReads]);

    useEffect(() => {
        const unsubscribe = eventManager.subscribeListener(ChatEventType.CREATE_NEW_CHAT_REQUEST, (event: CreateNewChatRequestEvent) => {
            onCreateNewChatRequested(event, diagramId);

            if (!open) {
                const changeVisibilityEvent: ChangeChatLayerVisibilityEvent = {
                    type: ChatEventType.CHANGE_CHAT_LAYER_VISIBILITY,
                    chatLayerVisible: true
                };
                eventManager.publishEvent(changeVisibilityEvent);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [eventManager, open, diagramId]);

    useEffect(() => {
        if (open && chats && chats.length === 0) {
            Snackbar.info(_transl(ChatTranslationKey.NO_CHATS_FOUND));
        }
    }, [open, chats]);

    const onDiagramZoomUpdated = useCallback((event: DiagramZoomEvent, editor: DiagramEditor) => {
        updateTranslation(editor);
        setScaleFactor(event.actualZoom);
    }, []);

    useEffect(() => {
        if (editor) {
            updateTranslation(editor);
            updateScaleFactor(editor);

            const unsubscribeZoomUpdated = editor.getEventManager().subscribeListener(EventType.DIAGRAM_ZOOM_UPDATED,
                (event: DiagramZoomEvent) => onDiagramZoomUpdated(event, editor));
            return () => {
                unsubscribeZoomUpdated();
            };
        } else {
            return undefined;
        }
    }, [editor, onDiagramZoomUpdated]);

    useEffect(() => {
        if (openedChat && openedChat.chat.id !== NEW_CHAT_ID) {
            chatService.markAsRead(openedChat.chat.id)
                .then(() => {
                    setRefreshChatReads(new Date().getTime());
                })
                .catch((err) => Snackbar.error(_transl(ErrorTranslationKey.UNEXPECTED_ERROR_OCCURRED), err));
        }
    }, [openedChat]);

    function updateTranslation(editor: DiagramEditor) {
        const transform = editor.getElementZoomManager().getActualZoomTransform();
        if (transform) {
            setTranslation(new Point(transform.x, transform.y));
        }
    }

    function updateScaleFactor(editor: DiagramEditor) {
        const transform = editor.getElementZoomManager().getActualZoomTransform();
        if (transform) {
            setScaleFactor(transform.k);
        }
    }

    function filterOnlyRelevantChats(chats: ChatDto[], diagramId: string): ChatDto[] {
        return chats
            .filter(chat => chat.diagramId === diagramId);
    }

    function getDiagramNodes(editor: DiagramEditor | null): DiagramNode[] {
        if (editor !== null && editor.getDiagramEditorApi().getModelAccessor()) {
            const editorDiagrams = editor.getDiagramEditorApi().getModelAccessor().getDiagrams();
            const diagram = editorDiagrams.find(diagram => diagram.diagramInfo.identifier === diagramId);
            if (diagram) {
                return diagram.nodes;
            }
        }
        return [];
    }

    function calculateChatNodeCoordinates(chatNode: ChatNodeDto | undefined, diagramNodes: DiagramNode[]): NodeCoordinate | undefined {
        if (!chatNode) {
            return undefined;
        }

        const diagramNodeCoordinates = getDiagramNodeCoordinates(chatNode.nodeId, diagramNodes);
        if (!diagramNodeCoordinates) {
            return undefined;
        }

        const { x: chatNodeX = 0, y: chatNodeY = 0 } = chatNode;
        const { x: diagramNodeX = 0, y: diagramNodeY = 0 } = diagramNodeCoordinates;

        return {
            x: (chatNodeX + diagramNodeX) * scaleFactor + translation.x,
            y: (chatNodeY + diagramNodeY) * scaleFactor + translation.y,
        };
    }

    function getDiagramNodeCoordinates(diagramNodeId: string | undefined, diagramNodes: DiagramNode[]): NodeCoordinate | undefined {
        let diagramNodeX = 0;
        let diagramNodeY = 0;

        if (diagramNodeId) {
            const diagramNode = findDiagramNode(diagramNodeId, diagramNodes);
            if (diagramNode !== undefined) {
                diagramNodeX = diagramNode.x;
                diagramNodeY = diagramNode.y;
            } else {
                return undefined;
            }
        }

        return {
            x: diagramNodeX,
            y: diagramNodeY,
        };
    }

    function findDiagramNode(diagramNodeId: string, diagramNodes: DiagramNode[]): DiagramNode | undefined {
        for (const diagramNode of diagramNodes) {
            if (diagramNode.identifier === diagramNodeId) {
                return diagramNode;
            }
            if (diagramNode.childNodes) {
                const childNode = findDiagramNode(diagramNodeId, diagramNode.childNodes);
                if (childNode) {
                    return childNode;
                }
            }
        }
        return undefined;
    }

    function onChatUpdated(chatId: number, scrollDown: boolean) {
        openChatDialog(chatId, scrollDown);
        setRefreshChats(new Date().getTime());
        removeLastReadForChat(chatId);
        if (onChatsUpdated) {
            onChatsUpdated();
        }
    }

    function removeLastReadForChat(chatId: number) {
        const lastReadMap = new Map(chatLastReadMap);
        lastReadMap.delete(chatId);
        setChatLastReadMap(lastReadMap);
    }

    function onChatDeleted(chatId: number) {
        setRefreshChats(new Date().getTime());
        if (onChatsUpdated) {
            onChatsUpdated();
        }
    }

    useEffect(() => {
        const visibilityChangedEvent: ModalWindowVisibilityChangedEvent = {
            type: DiagramEditorEventType.MODAL_WINDOW_VISIBILITY_CHANGED,
            isVisible: openedChat !== undefined
        };
        eventManager.publishEvent(visibilityChangedEvent);
    }, [eventManager, openedChat]);

    function openChatDialog(chatId: number, scrollDown: boolean) {
        chatService.findChatById(chatId)
            .then(chat => {
                if (chat) {
                    setOpenedChat({chat: chat, scrollDown: scrollDown});
                } else {
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA));
                }
            })
            .catch(() => {
                Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA));
            });
    }

    function closeChatDialog() {
        setOpenedChat(undefined);
    }

    function navigateToNextChat(currentChatId: number) {
        const currentChatIndex = findChatIndexById(currentChatId);
        const nextChatIndex = currentChatIndex + 1;
        if (chats && chats.length > nextChatIndex) {
            const nextChat = chats[nextChatIndex];
            openChatDialog(nextChat.id, true);
        }
    }

    function navigateToPreviousChat(currentChatId: number) {
        const currentChatIndex = findChatIndexById(currentChatId);
        const previousChatIndex = currentChatIndex - 1;
        if (chats && chats.length > previousChatIndex && previousChatIndex >= 0) {
            const previousChat = chats[previousChatIndex];
            openChatDialog(previousChat.id, true);
        }
    }

    function getNavigationCallbacks(openedChat: ChatDto | undefined, chats: ChatDto[] | undefined) {
        let navigateToPreviousCallback = undefined;
        let navigateToNextCallback = undefined;
        if (openedChat && chats) {
            const currentChatIndex = findChatIndexById(openedChat.id);
            if (currentChatIndex > 0) {
                navigateToPreviousCallback = navigateToPreviousChat;
            }
            if (currentChatIndex + 1 < chats.length) {
                navigateToNextCallback = navigateToNextChat;
            }
        }
        return [navigateToPreviousCallback, navigateToNextCallback];
    }

    function findChatIndexById(chatId: number) {
        if (chats) {
            return chats.findIndex(chat => chat.id === chatId);
        } else {
            return -1;
        }
    }

    function onCreateNewChatRequested(event: CreateNewChatRequestEvent, diagramId: string) {
        let chatNode: ChatNodeDto;
        let elementId: string | undefined = undefined;
        if (event.node) {
            const node = event.node;
            elementId = node.elementIdentifier;
            chatNode = {x: event.transformedClientX - node.x, y: event.transformedClientY - node.y, nodeId: node.identifier};
        } else {
            chatNode = {x: event.transformedClientX, y: event.transformedClientY};
        }

        const chat = {
            id: NEW_CHAT_ID,
            diagramId: diagramId,
            elementId: elementId,
            chatComments: [],
            chatNode: chatNode,
            state: ChatState.UNRESOLVED,
            acl: {canChangeState: false, canDelete: false, canCreateComments: true}
        };

        setOpenedChat({chat: chat, scrollDown: true});
    }

    function getLastReadTime(chatId: number) {
        if (chatLastReadMap.has(chatId)) {
            const lastChatRead = chatLastReadMap.get(chatId);
            return lastChatRead ? new Date(lastChatRead.time) : undefined;
        } else {
            return new Date();
        }
    }

    if (!open) {
        return null;
    }

    const diagramNodes = getDiagramNodes(editor);
    const navigationCallbacks = getNavigationCallbacks(openedChat?.chat, relevantChats);

    return (
        <>
            {openedChat && <ChatDialog open={true}
                                       chat={openedChat.chat}
                                       scrollDown={openedChat.scrollDown}
                                       onChatUpdated={(chatId, scrollDown) => onChatUpdated(chatId, scrollDown)}
                                       onChatDeleted={(chatId) => onChatDeleted(chatId)}
                                       onNavigateToPrev={navigationCallbacks[0]}
                                       onNavigateToNext={navigationCallbacks[1]}
                                       onClosed={closeChatDialog} />
            }

            <div style={{display: 'block'}}>
                {
                    relevantChats.map((chat, index) => {
                        const coordinates = calculateChatNodeCoordinates(chat.chatNode, diagramNodes);
                        if (coordinates) {
                            const lastRead = getLastReadTime(chat.id);
                            return coordinates && <ChatPin key={index}
                                                           chat={chat}
                                                           highlighted={chat.id === openedChat?.chat.id}
                                                           lastRead={lastRead}
                                                           x={coordinates.x}
                                                           y={coordinates.y}
                                                           onClick={chat => openChatDialog(chat.id, true)}/>
                        } else {
                            return null;
                        }
                    })
                }
            </div>
        </>
    );
}
