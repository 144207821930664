import {TranslationFunction} from "../../store/localization/TranslMessasge";
import {ExtGridTranslationKey} from "./ExtGridTranslationKey";
import {GridLocaleText} from "@mui/x-data-grid-pro";

export default function provideGridLocaleText(transl: TranslationFunction): Partial<GridLocaleText> {
    return {
        // Root
        noRowsLabel: transl(ExtGridTranslationKey.NO_ROWS),
        noResultsOverlayLabel: transl(ExtGridTranslationKey.NO_RESULTS_OVERLAY_LABEL),
        errorOverlayDefaultLabel: transl(ExtGridTranslationKey.ERROR_OVERLAY_DAFAULT_LABEL),

        // Density selector toolbar button text
        toolbarDensity: transl(ExtGridTranslationKey.TOOLBAR_DENSITY),
        toolbarDensityLabel: transl(ExtGridTranslationKey.TOOLBAR_DENSITY_LABEL),
        toolbarDensityCompact: transl(ExtGridTranslationKey.TOOLBAR_DENSITY_COMPACT),
        toolbarDensityStandard: transl(ExtGridTranslationKey.TOOLBAR_DENSITY_STANDARD),
        toolbarDensityComfortable: transl(ExtGridTranslationKey.TOOLBAR_DENSITY_COMFORTABLE),

        // Columns selector toolbar button text
        toolbarColumns: transl(ExtGridTranslationKey.TOOLBAR_DENSITY_COLUMNS),
        toolbarColumnsLabel: transl(ExtGridTranslationKey.TOOLBAR_DENSITY_COLUMNS_LABEL),

        // Filters toolbar button text
        toolbarFilters: transl(ExtGridTranslationKey.TOOLBAR_FILTERS),
        toolbarFiltersLabel: transl(ExtGridTranslationKey.TOOLBAR_FILTERS_LABEL),
        toolbarFiltersTooltipHide: transl(ExtGridTranslationKey.TOOLBAR_FILTERS_TOOLTIP_HIDE),
        toolbarFiltersTooltipShow: transl(ExtGridTranslationKey.TOOLBAR_FILTERS_TOOLTIP_SHOW),
        toolbarFiltersTooltipActive: (count) => `${transl(ExtGridTranslationKey.TOOLBAR_FILTERS_TOOLTIP_ACTIVE)}: ${count}`,

        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: transl(ExtGridTranslationKey.TOOLBAR_QUICK_FILTER_PLACEHOLDER),
        toolbarQuickFilterLabel: transl(ExtGridTranslationKey.TOOLBAR_QUICK_FILTER_LABEL),
        toolbarQuickFilterDeleteIconLabel: transl(ExtGridTranslationKey.TOOLBAR_QUICK_FILTER_DELETE_ICON_LABEL),

        // Export selector toolbar button text
        toolbarExport: transl(ExtGridTranslationKey.TOOLBAR_EXPORT),
        toolbarExportLabel: transl(ExtGridTranslationKey.TOOLBAR_EXPORT_LABEL),
        toolbarExportCSV: transl(ExtGridTranslationKey.TOOLBAR_EXPORT_CSV),
        toolbarExportPrint: transl(ExtGridTranslationKey.TOOLBAR_EXPORT_PRINT),
        toolbarExportExcel: transl(ExtGridTranslationKey.TOOLBAR_EXPORT_EXCEL),

        // Columns panel text
        columnsPanelTextFieldLabel: transl(ExtGridTranslationKey.COLUMNS_PANEL_TEXTFIELD_LABEL),
        columnsPanelTextFieldPlaceholder: transl(ExtGridTranslationKey.COLUMNS_PANEL_TEXTFIELD_PLACEHOLDER),
        columnsPanelDragIconLabel: transl(ExtGridTranslationKey.COLUMNS_PANEL_DRAG_ICON_LABEL),
        columnsPanelShowAllButton: transl(ExtGridTranslationKey.COLUMNS_PANEL_SHOW_ALL_BUTTON),
        columnsPanelHideAllButton: transl(ExtGridTranslationKey.COLUMNS_PANEL_HIDE_ALL_BUTTON),

        // Filter panel text
        filterPanelAddFilter: transl(ExtGridTranslationKey.FILTER_PANEL_ADD_FILTER),
        filterPanelDeleteIconLabel: transl(ExtGridTranslationKey.FILTER_PANEL_DELETE_ICON_LABEL),
        filterPanelLinkOperator: transl(ExtGridTranslationKey.FILTER_PANEL_LINK_OPERATOR),
        filterPanelOperators: transl(ExtGridTranslationKey.FILTER_PANEL_OPERATORS),
        filterPanelOperatorAnd: transl(ExtGridTranslationKey.FILTER_PANEL_OPERATOR_AND),
        filterPanelOperatorOr: transl(ExtGridTranslationKey.FILTER_PANEL_OPERATOR_OR),
        filterPanelColumns: transl(ExtGridTranslationKey.FILTER_PANEL_COLUMNS),
        filterPanelInputLabel: transl(ExtGridTranslationKey.FILTER_PANEL_INPUT_LABEL),
        filterPanelInputPlaceholder: transl(ExtGridTranslationKey.FILTER_PANEL_INPUT_PLACEHOLDER),

        // Filter operators text
        filterOperatorContains: transl(ExtGridTranslationKey.FILTER_OPERATOR_CONTAINS),
        filterOperatorEquals: transl(ExtGridTranslationKey.FILTER_OPERATOR_EQUALS),
        filterOperatorStartsWith: transl(ExtGridTranslationKey.FILTER_OPERATOR_STARTS_WITH),
        filterOperatorEndsWith: transl(ExtGridTranslationKey.FILTER_OPERATOR_ENDS_WITH),
        filterOperatorIs: transl(ExtGridTranslationKey.FILTER_OPERATOR_IS),
        filterOperatorNot: transl(ExtGridTranslationKey.FILTER_OPERATOR_NOT),
        filterOperatorAfter: transl(ExtGridTranslationKey.FILTER_OPERATOR_AFTER),
        filterOperatorOnOrAfter: transl(ExtGridTranslationKey.FILTER_OPERATOR_ON_OR_AFTER),
        filterOperatorBefore: transl(ExtGridTranslationKey.FILTER_OPERATOR_BEFORE),
        filterOperatorOnOrBefore: transl(ExtGridTranslationKey.FILTER_OPERATOR_ON_OR_BEFORE),
        filterOperatorIsEmpty: transl(ExtGridTranslationKey.FILTER_OPERATOR_IS_EMPTY),
        filterOperatorIsNotEmpty: transl(ExtGridTranslationKey.FILTER_OPERATOR_IS_NOT_EMPTY),
        filterOperatorIsAnyOf: transl(ExtGridTranslationKey.FILTER_OPERATOR_IS_ANY_OF),

        // Filter values text
        filterValueAny: transl(ExtGridTranslationKey.FILTER_VALUE_ANY),
        filterValueTrue: transl(ExtGridTranslationKey.FILTER_VALUE_TRUE),
        filterValueFalse: transl(ExtGridTranslationKey.FILTER_VALUE_FALSE),

        // Column menu text
        columnMenuLabel: transl(ExtGridTranslationKey.COLUMN_MENU_LABEL),
        columnMenuShowColumns: transl(ExtGridTranslationKey.COLUMN_MENU_SHOW_COLUMNS),
        columnMenuFilter: transl(ExtGridTranslationKey.COLUMN_MENU_FILTER),
        columnMenuHideColumn: transl(ExtGridTranslationKey.COLUMN_MENU_HIDE_COLUMN),
        columnMenuUnsort: transl(ExtGridTranslationKey.COLUMN_MENU_UNSORT),
        columnMenuSortAsc: transl(ExtGridTranslationKey.COLUMN_MENU_SORT_ASC),
        columnMenuSortDesc: transl(ExtGridTranslationKey.COLUMN_MENU_SORT_DESC),

        // Column header text
        columnHeaderFiltersTooltipActive: (count) => `${transl(ExtGridTranslationKey.COLUMN_HEADER_FILTERS_TOOLTIP_ACTIVE)}: ${count}`,
        columnHeaderFiltersLabel: transl(ExtGridTranslationKey.COLUMN_HEADER_FILTERS_LABEL),
        columnHeaderSortIconLabel: transl(ExtGridTranslationKey.COLUMN_HEADER_SORT_ICON_LABEL),

        // Rows selected footer text
        footerRowSelected: (number) => `${transl(ExtGridTranslationKey.ROWS_SELECTED)}: ${number}`,

        // Total row amount footer text
        footerTotalRows: `${transl(ExtGridTranslationKey.ROWS_TOTAL)}:`,

        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} ${transl(ExtGridTranslationKey.FOOTER_VISIBLE_TOTAL_ROWS_FROM)} ${totalCount.toLocaleString()}`,

        // Checkbox selection text
        checkboxSelectionHeaderName: transl(ExtGridTranslationKey.CHECKBOX_SELECTION_HEADER_NAME),
        checkboxSelectionSelectAllRows: transl(ExtGridTranslationKey.CHECKBOX_SELECTION_SELECT_ALL_ROWS),
        checkboxSelectionUnselectAllRows: transl(ExtGridTranslationKey.CHECKBOX_SELECTION_UNSELECT_ALL_ROWS),
        checkboxSelectionSelectRow: transl(ExtGridTranslationKey.CHECKBOX_SELECTION_SELECT_ROW),
        checkboxSelectionUnselectRow: transl(ExtGridTranslationKey.CHECKBOX_SELECTION_UNSELECT_ROW),

        // Boolean cell text
        booleanCellTrueLabel: transl(ExtGridTranslationKey.BOOLEAN_CELL_TRUE_LABEL),
        booleanCellFalseLabel: transl(ExtGridTranslationKey.BOOLEAN_CELL_FALSE_LABEL),

        // Actions cell more text
        actionsCellMore: transl(ExtGridTranslationKey.ACTIONS_CELL_MORE),

        // Column pinning text
        pinToLeft: transl(ExtGridTranslationKey.PIN_TO_LEFT),
        pinToRight: transl(ExtGridTranslationKey.PIN_TO_RIGHT),
        unpin: transl(ExtGridTranslationKey.UNPIN),

        // Tree Data
        treeDataGroupingHeaderName: transl(ExtGridTranslationKey.TREE_DATA_GROUPING_HEADER_NAME),
        treeDataExpand: transl(ExtGridTranslationKey.TREE_DATA_EXPAND),
        treeDataCollapse: transl(ExtGridTranslationKey.TREE_DATA_COLLAPSE),

        // Grouping columns
        groupingColumnHeaderName: transl(ExtGridTranslationKey.GROUPING_COLUMN_HEADER_NAME),
        groupColumn: (name) => `${transl(ExtGridTranslationKey.GROUP_COLUMN)} ${name}`,
        unGroupColumn: (name) => `${transl(ExtGridTranslationKey.UNGROUP_COLUMN)} ${name}`,

        // Master/detail
        detailPanelToggle: transl(ExtGridTranslationKey.DETAIL_PANEL_TOGGLE),
        expandDetailPanel: transl(ExtGridTranslationKey.EXPAND_DETAIL_PANEL),
        collapseDetailPanel: transl(ExtGridTranslationKey.COLLAPSE_DETAIL_PANEL),

        // Used core components translation keys
        MuiTablePagination: {},

        // Row reordering text
        rowReorderingHeaderName: transl(ExtGridTranslationKey.ROW_REORDERING_HEADER_NAME),

        // Aggregation
        aggregationMenuItemHeader: transl(ExtGridTranslationKey.AGGREGATION_MENU_ITEM_HEADER),
        aggregationFunctionLabelSum: transl(ExtGridTranslationKey.AGGREGATION_FUNCTION_LABEL_SUM),
        aggregationFunctionLabelAvg: transl(ExtGridTranslationKey.AGGREGATION_FUNCTION_LABEL_AVG),
        aggregationFunctionLabelMin: transl(ExtGridTranslationKey.AGGREGATION_FUNCTION_LABEL_MIN),
        aggregationFunctionLabelMax: transl(ExtGridTranslationKey.AGGREGATION_FUNCTION_LABEL_MAX),
        aggregationFunctionLabelSize: transl(ExtGridTranslationKey.AGGREGATION_FUNCTION_LABEL_SIZE),
    };
}