import {useEffect, useState} from "react";
import {HierarchyTreeItemDetailDto} from "../service/HierarchyTreeItemDetailDto";
import hierarchyTreeService from "../service/HierarchyTreeService";
import {HierarchyTreeItemType} from "../service/HierarchyTreeItemType";
import Snackbar from "../../snackbar/Snackbar";
import {_transl, TranslationFunction} from "../../../../../store/localization/TranslMessasge";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import {GridColDef} from "@mui/x-data-grid";
import {PersistentStateId} from "../../../../../store/common/Grid";
import {HierarchyTreeDataTypeEnum} from "./subforms/HierarchyTreeDataTypeEnum";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import {HierarchyTreeTranslationKey} from "../HierarchyTreeTranslationKey";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import {GridRowOrderChangeParams} from "@mui/x-data-grid-pro";
import {CloseButton} from "../../../../../components/button/CloseButton";
import {SaveButton} from "../../../../../components/button/SaveButton";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import {CommonTranslation} from "../../CommonTranslation";
import ExtGridWrapper from "../../../../../components/grid/ExtGridWrapper";


export interface HierarchyTreeReorderDialogProps {
    open: boolean;
    parentTreeId?: string | null;
    onReordered?: (parentTreeId?: string) => void;
    onClose: () => void;
}

export default function HierarchyTreeReorderDialog(props: HierarchyTreeReorderDialogProps) {
    const {open, parentTreeId, onReordered, onClose} = props;

    const [subtreeItems, setSubtreeItems] = useState<HierarchyTreeItemDetailDto[]>([]);
    const [reordered, setReordered] = useState(false);

    useEffect(() => {
        if (open && parentTreeId) {
            setReordered(false);
            hierarchyTreeService.findTree(parentTreeId)
                .then((tree) => {
                    setSubtreeItems(tree.rootItems.filter(item => item.type === HierarchyTreeItemType.SUBTREE));

                })
                .catch((err) => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA), err));
        }
    }, [open, parentTreeId]);

    function onRowReorderChange(params: GridRowOrderChangeParams) {
        reorderItem(params.row as HierarchyTreeItemDetailDto, params.oldIndex, params.targetIndex);
        setReordered(true);
    }

    function reorderItem(item: HierarchyTreeItemDetailDto, oldIndex: number, targetIndex: number) {
        let reorderedItems = [...subtreeItems];
        reorderedItems.splice(oldIndex, 1);
        reorderedItems.splice(targetIndex, 0, item);
        setSubtreeItems(reorderedItems);
    }

    function saveOrder() {
        if (reordered) {
            const promises = subtreeItems.map(
                (treeItem, index) => hierarchyTreeService.updateTreeOrder(treeItem.id, index + 1));
            Promise.all(promises)
                .then(() => {
                    Snackbar.success(_transl(CommonTranslation.DATA_SAVED_SUCESSFULLY));
                    onReordered?.(parentTreeId!);
                    onClose();
                })
                .catch((err) => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA, err)));
        }
    }

    const columns: GridColDef<HierarchyTreeItemDetailDto>[] = [
        {field: 'title', headerName: _transl(HierarchyTreeTranslationKey.REORDER_DIALOG_COLUMN_NAME), width: 300},
        {field: 'treeData.type', headerName: _transl(HierarchyTreeTranslationKey.REORDER_DIALOG_COLUMN_DATA_TYPE), width: 250,
            valueGetter: (params) => params.row.treeData?.type,
            valueFormatter: (params) => formatDataType(params.value, _transl)},
    ];

    return (
        <>
            <Dialog open={open} maxWidth={"md"}>
                <DialogTitle title={_transl(HierarchyTreeTranslationKey.REORDER_DIALOG_TITLE)}
                             onDialogClosed={onClose} />
                <DialogContent>
                    <ExtGridWrapper columns={columns}
                             rows={subtreeItems}
                             rowCount={subtreeItems.length}
                             getRowId={row => row.id}
                             rowReordering={true}
                             onRowReorderChange={onRowReorderChange}
                             peristentStateId={PersistentStateId.HIERARCHY_TREE_REORDER_DIALOG_GRID}
                             resourceId={""} />
                </DialogContent>
                <DialogActions>
                    <SaveButton onClick={saveOrder} disabled={!reordered} />
                    <CloseButton onClick={onClose} variant={"outlined"} />
                </DialogActions>
            </Dialog>
        </>
    );
}

function formatDataType(dataType: string | undefined, _transl: TranslationFunction): string | undefined {
    if (dataType) {
        const dataTypeEnum = HierarchyTreeDataTypeEnum.getFromType(dataType);
        if (dataTypeEnum) {
            return _transl(dataTypeEnum.translationKey);
        }
    }
    return undefined;
}