import React, {useRef, useState} from "react";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {LabelsTranslationKey} from "./LabelsTranslationKey";
import {CommonTranslation} from "../CommonTranslation";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {ILabelDto} from "../../../../common/apis/label/ILabelDto";
import labelsService from "./service/LabelsService";
import Snackbar from "../snackbar/Snackbar";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import TextField from "../../../../components/fields/textfield/TextField";
import {Checkbox, FormControlLabel} from "@mui/material";
import DialogActions from "../../../../components/dialogs/DialogActions";
import constructLabelsController, {LabelsController} from "./controller/LabelsController";
import Grid from "../../../../components/dialogs/Grid";
import {LabelConstant} from "./service/LabelConstant";


interface UpdateLabelDialogProps {
    onClosed: () => void,
    label: ILabelDto,
    onPropertyUpdate: () => void
}

export default function UpdateLabelDialog(props: UpdateLabelDialogProps) {

    const labelController = useRef<LabelsController>(constructLabelsController(labelsService));
    const {onClosed, onPropertyUpdate} = props;

    const [label, setLabel] = useState<ILabelDto>(props.label);
    const [nameError, setNameError] = useState<string>();

    function validate(): boolean {
        let isValid = false;
        if (label.name.trim().length === 0) {
            setNameError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        } else if (label.name.trim().length > 100) {
            setNameError(_transl(CommonTranslation.FIELD_TOO_LONG));
        } else {
            setNameError(undefined);
            isValid = true;
        }
        return isValid;
    }

    function saveChanges() {
        if (validate()) {
            (async () => {
                try {
                    await labelController.current.update(label.code, label.name, label.description);
                    onDialogClosed();
                    onPropertyUpdate();
                    Snackbar.success(_transl(LabelsTranslationKey.UPDATE_SUCCEEDED));
                } catch (error) {
                    Snackbar.error(_transl(LabelsTranslationKey.UPDATE_FAILED));
                }
            })();

        }
    }

    function onDialogClosed() {
        setNameError(undefined);
        onClosed();
    }

    const isPrivate = !props.label.publicLabel;

    return (
        <Dialog
            open={true}
            onClose={onDialogClosed}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(LabelsTranslationKey.UPDATE_TITLE)}
                         onDialogClosed={onDialogClosed}
            />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="update-label-name-field"
                                   label={_transl(CommonTranslation.TITLE)}
                                   errorMessage={nameError}
                                   value={label.name}
                                   onChange={name => setLabel({...label, name: name})}
                                   maxLength={LabelConstant.NAME_MAX_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="update-label-type-field"
                                   label={_transl(CommonTranslation.CODE)}
                                   value={label.code}
                                   disabled={true}
                                   maxLength={LabelConstant.CODE_MAX_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="update-label-value-field"
                                   label={_transl(CommonTranslation.DESCRIPTION)}
                                   value={label.description}
                                   onChange={description => setLabel({...label, description: description})}
                                   maxLength={LabelConstant.DESCRIPTION_MAX_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label={_transl(LabelsTranslationKey.IS_PRIVATE)}
                            control={
                                <Checkbox
                                    checked={isPrivate}
                                    name="labelAccessModifier"
                                    color="primary"
                                    disabled={true}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={saveChanges}/>
                <CancelButton onClick={onDialogClosed}/>
            </DialogActions>
        </Dialog>
    );
}
