import React, {useEffect, useState} from "react";
import {GridColDef} from "@mui/x-data-grid";
import {PersistentStateId} from "../../../../store/common/Grid";
import Api from "../../../../common/Api";
import StereotypeCreateDialog from "./StereotypeCreateDialog";
import {
    GridAction,
    ActionButtonType,
    EnabledPolicy
} from "../../../../components/grid/GridAction";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SucceededAlert from "../../../../components/SucceededAlert";
import NotSucceededAlert from "../../../../components/NotSucceededAlert";
import Constants from "../../../../common/Constants";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {StereotypesTranslationKey} from "./StereotypesTranslationKey";
import {StereotypeDto} from "../../../../common/apis/stereotype/StereotypeDto";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";


function createColumnDef(): GridColDef[] {
    return [
        {field: 'id', headerName: _transl(StereotypesTranslationKey.GRID_HEADER_ID), width: 300},
        {field: 'name', headerName: _transl(StereotypesTranslationKey.GRID_HEADER_NAME), width: 300},
        {field: 'type', headerName: _transl(StereotypesTranslationKey.GRID_HEADER_DERIVED_FROM), width: 300},
    ]
};


function getAllStereotypes(setStereotypeDtos: React.Dispatch<React.SetStateAction<StereotypeDto[]>>) {
    Api.stereotypes.findAll()
        .then((stereotypes) => {
                setStereotypeDtos(stereotypes);
        });
}

enum StereotypeActionButton {
    ADD = "StereotypeAction.ADD",
    DELETE = "StereotypeAction.DELETE",
}

export default function StereotypesGrid() {

    const [stereotypeDtos, setStereotypeDtos] = useState<StereotypeDto[]>([]);
    const [selectedStereotypeId, setSelectedStereotypeId] = useState<string>();
    const [stereotypesAddDialogIsOpen, setStereotypesAddDialogIsOpen] = useState<boolean>(false);

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isDeleteSuccessfulAlertVisible, setDeleteSuccessfulAlertVisible] = useState<boolean>(false);
    const [isDeleteFailedAlertVisible, setDeleteFailedAlertVisible] = useState<boolean>(false);

    useEffect(() => {
        getAllStereotypes(setStereotypeDtos);
    }, []);

    function hideAddDialog() {
        setStereotypesAddDialogIsOpen(false)
    }

    function showAddDialog(addLabelsDialogIsOpen: boolean) {
        setStereotypesAddDialogIsOpen(true)
    }

    function openDeleteDialog(selectedRowIds: string[]) {
        if (selectedRowIds.length === 1) {
            setDeleteDialogOpen(true);
            setSelectedStereotypeId(selectedRowIds[0]);
        }
    }

    function closeDeleteDialog() {
        setDeleteDialogOpen(false);
    }

    function onDeleteConfirmed() {
        closeDeleteDialog();
        Api.stereotypes.deleteStereotypeById(selectedStereotypeId!).subscribe(
            () => onStereotypeDeleted(),
            () => onStereotypeDeleteFailed(),
        );
    }

    function onStereotypeDeleted() {
        getAllStereotypes(setStereotypeDtos);
        showDeleteSuccessfulAlert();
    }

    function showDeleteSuccessfulAlert() {
        setDeleteSuccessfulAlertVisible(true);
        setTimeout(() => setDeleteSuccessfulAlertVisible(false), Constants.FE_APP_ALERT_DELAY);
    }

    function onStereotypeDeleteFailed() {
        showDeleteFailedAlert();
    }

    function showDeleteFailedAlert() {
        setDeleteFailedAlertVisible(true);
        setTimeout(() => setDeleteFailedAlertVisible(false), Constants.FE_APP_ALERT_DELAY);
    }


    const rows = stereotypeDtos.map((stereotype) => {
        return {
            id: stereotype.id,
            name: stereotype.name,
            type: stereotype.elementType,
        }
    });

    return (
        <>
            <StereotypeCreateDialog open={stereotypesAddDialogIsOpen}
                                    onClosed={() => hideAddDialog()}
                                    onLabelAdded={() => getAllStereotypes(setStereotypeDtos)}/>

            <ConfirmationDialog open={isDeleteDialogOpen}
                                title={_transl(StereotypesTranslationKey.CONFIRMATION_DIALOG_DELETE_STEREOTYPE_TITLE)}
                                confirmationText={_transl(StereotypesTranslationKey.CONFIRMATION_DIALOG_DELETE_STEREOTYPE_TEXT)}
                                onConfirm={() => onDeleteConfirmed()}
                                onReject={() => closeDeleteDialog()}/>
            <SucceededAlert text={_transl(StereotypesTranslationKey.SUCEEDED_ALERT_DELETE_STEREOTYPE_TEXT)}
                            opened={isDeleteSuccessfulAlertVisible}
                            onClose={() => setDeleteSuccessfulAlertVisible(false)}/>
            <NotSucceededAlert text={_transl(StereotypesTranslationKey.NOT_SUCEEDED_ALERT_DELETE_STEREOTYPE_TEXT)}
                               opened={isDeleteFailedAlertVisible}
                               onClose={() => setDeleteFailedAlertVisible(false)}/>

            <ExtGridWrapper
                columns={createColumnDef()}
                rows={rows}
                rowCount={rows.length}
                getRowId={row => row.id}
                actions={[
                    GridAction.buttonBuilder(StereotypeActionButton.ADD, ActionButtonType.IMMEDIATE, _transl(StereotypesTranslationKey.GRID_BUTTONS_ADD_STEREOTYPE_TOOLTIP),
                        <AddIcon/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .onClick(() => showAddDialog(true))
                        .build(),
                    GridAction.buttonBuilder(StereotypeActionButton.DELETE, ActionButtonType.IMMEDIATE, _transl(StereotypesTranslationKey.GRID_BUTTONS_DELETE_STEREOTYPE_TOOLTIP),
                        <DeleteIcon/>)
                        .onClick(selectedRowIds => openDeleteDialog(selectedRowIds as string[]))
                        .build(),
                ]}
                peristentStateId={PersistentStateId.STEREOTYPES_PAGE_GRID}
                resourceId={""}
            />
        </>
    );

}
