export enum HierarchyTreeTranslationKey {
    COLLECTION_VIEW_MODE = "trees.dataType.collections.viewMode",
    COLLECTION_VIEW_MODE_FLAT = "trees.dataType.collections.viewMode.flat",
    COLLECTION_VIEW_MODE_FOLDERS = "trees.dataType.collections.viewMode.folders",
    COMMON_SUBFORM_TITLE = "trees.commonSubform.title",
    DIAGRAMS = "trees.folder.diagrams",
    DATA_TYPE_COLLECTIONS = "trees.dataType.collections",
    DATA_TYPE_FOLDER = "trees.dataType.folder",
    DATA_TYPE_LAYERS = "trees.dataType.layers",
    DATA_TYPE_LABELS = "trees.dataType.labels",
    DATA_TYPE_QUERIES = "trees.dataType.queries",
    DATA_TYPE_REFERENCE = "trees.dataType.reference",
    DATA_TYPE_SELECT_LABEL = "trees.dataTypeSelect.label",
    LABEL_VIEW_MODE = "trees.dataType.labels.viewMode",
    LABEL_VIEW_MODE_FLAT = "trees.dataType.labels.viewMode.flat",
    LABEL_VIEW_MODE_FOLDERS = "trees.dataType.labels.viewMode.folders",
    REFERENCE_ELEMENT_REPORT = "trees.reference.elementReport",
    REFERENCE_FIND_PRESETS = "trees.reference.findPresets",
    REFERENCE_NAME = "trees.reference.name",
    REFERENCE_PRESETS = "trees.reference.presets",
    REFERENCE_URL = "trees.reference.url",
    REFERENCE_URL_LINK = "trees.reference.urlLink",
    REORDER_DIALOG_COLUMN_DATA_TYPE = "trees.reorderDialog.column.dataType",
    REORDER_DIALOG_COLUMN_NAME = "trees.reorderDialog.column.name",
    REORDER_DIALOG_TITLE = "trees.reorderDialog.title",
    STRUCTURE_CREATE_TITLE = "treeStructure.hierarchyTreeChooser.createDialog.title",
    STRUCTURE_DELETING_CONFIRMATION_TEXT = "treeStructure.hierarchyTreeChooser.confirmationDialog.confirmationText",
    STRUCTURE_DELETING_TITLE = "treeStructure.hierarchyTreeChooser.confirmationDialog.title",
    STRUCTURE_UPDATE_TITLE = "treeStructure.hierarchyTreeChooser.updateDialog.title",
    SUBFORM_COLLECTIONS = "trees.subform.collections.label",
    SUBFORM_LABELS = "trees.subform.labels.label",
    SUBFORM_QUERIES_LEVEL = "trees.subform.queries.level",
    SUBFORM_QUERIES_LEVEL_ADD = "trees.subform.queries.level.add",
    SUBFORM_QUERIES_LEVEL_DELETE = "trees.subform.queries.level.delete",
    SUBFORM_QUERIES_LEVEL_QUERY = "trees.subform.queries.level.query",
    SUBFORM_QUERIES_LEVEL_QUERY_PREFILL = "trees.subform.queries.level.queryPrefill",
    SUBFORM_QUERIES_LEVEL_QUERY_PREFILL_TITLE = "trees.subform.queries.level.queryPrefill.title",
    TREE_ITEM_CONTEXT_MENU_ADD_ELEMENT_REPORT_REFERENCE = "trees.treeItemContextMenu.addElementReportReference",
    TREE_ITEM_CONTEXT_MENU_ADD_STRUCTURE = "trees.treeItemContextMenu.addStructure",
    TREE_ITEM_CONTEXT_MENU_ADD_LINK = "trees.treeItemContextMenu.addLink",
    TREE_ITEM_CONTEXT_MENU_REORDER_ITEMS = "trees.treeItemContextMenu.reorderItems",
}
