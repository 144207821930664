import {DiagramBackup} from "./DiagramBackup";
import {DiagramBackupStorage} from "./DiagramBackupStorage";

export interface DiagramBackupPersister {
    persist(backup: DiagramBackup): void,
    remove(backup: DiagramBackup): void,
    load: () => Map<string, DiagramBackup>,
}

export interface CheckIntervalFactory {
    nextCheckInterval(): number,
}

export class DefaultCheckIntervalFactory implements CheckIntervalFactory {
    public static readonly CONSTANT_CHECK_INTERVAL_IN_MS = 2000;
    public static readonly RANDOM_CHECK_INTERVAL_IN_MS_MIN = 30;
    public static readonly RANDOM_CHECK_INTERVAL_IN_MS_MAX = 150;

    constructor(private readonly checkIntervalInMs: number = DefaultCheckIntervalFactory.CONSTANT_CHECK_INTERVAL_IN_MS,
                private readonly minAdditionInclusive: number = DefaultCheckIntervalFactory.RANDOM_CHECK_INTERVAL_IN_MS_MIN,
                private readonly maxAdditionInclusive: number = DefaultCheckIntervalFactory.RANDOM_CHECK_INTERVAL_IN_MS_MAX){}

    public nextCheckInterval() {
        return this.checkIntervalInMs + this.nextRandomInInterval(this.minAdditionInclusive, this.maxAdditionInclusive);
    }

    private nextRandomInInterval(minInclusive: number, maxInclusive: number) {
        const min = Math.ceil(minInclusive);
        const max = Math.floor(maxInclusive);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}

export class IntervalBasedDiagramBackupPersister implements DiagramBackupPersister {

    private readonly backupCheckIntervalInMs;

    private storage: DiagramBackupStorage;
    private diagramsToBackup: Map<string, DiagramBackup> = new Map();

    constructor(storage: DiagramBackupStorage, backupCheckIntervalInMs: number) {
        this.storage = storage;
        this.backupCheckIntervalInMs = backupCheckIntervalInMs;
        setInterval(() => this.saveBackups(), this.backupCheckIntervalInMs);
    }

    persist(diagramToBackup: DiagramBackup) {
        this.diagramsToBackup.set(diagramToBackup.getDiagramId(), diagramToBackup);
    }

    remove(backup: DiagramBackup) {
        this.removeAndSaveBackups(backup);
    }

    private removeAndSaveBackups(backup: DiagramBackup) {
        const loadedBackups = this.load();
        loadedBackups.delete(backup.getDiagramId());
        this.diagramsToBackup.delete(backup.getDiagramId());
        const mergedBackups = IntervalBasedDiagramBackupPersister.mergeBackups(loadedBackups, this.diagramsToBackup);
        this.storage.save(mergedBackups);
    }

    load(): Map<string, DiagramBackup> {
        return this.storage.load();
    }

    getBackupCheckIntervalInMs() {
        return this.backupCheckIntervalInMs;
    }

    private saveBackups() {
        if (this.diagramsToBackup.size > 0) {
            const mergedBackups = IntervalBasedDiagramBackupPersister.mergeBackups(this.load(), this.diagramsToBackup);
            this.storage.save(mergedBackups);
            this.diagramsToBackup = new Map();
        }
    }

    public static mergeBackups(olderBackups: Map<string, DiagramBackup>,
                               newerBackups: Map<string, DiagramBackup>) {
        const merged = new Map(olderBackups);
        newerBackups.forEach((backup, diagramId) => {
            merged.set(diagramId, backup);
        });
        return merged;
    }
}

