import {ColorPicker, ColorPickerProps} from "./ColorPicker";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popover: {
            position: "absolute",
            zIndex: 2
        },
        cover: {
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
        }
    })
);

export interface ColorPickerPopoverProps extends ColorPickerProps {
    onClose?: () => void
}

export function ColorPickerPopover(props: ColorPickerPopoverProps) {
    const classes = useStyles();

    function onClose() {
        props.onClose && props.onClose();
    }

    return (
        <div className={classes.popover}>
            <div className={classes.cover} onClick={onClose}/>
            <ColorPicker {...props} />
        </div>
    );
}
