import {TopMenuId} from "./TopMenuId";
import React, {useEffect, useRef, useState} from "react";
import {ViewpointValidationDialog} from "../../../../diagram/editor/validation/dialog/ViewpointValidationDialog";
import viewpointService from "../../../../common/apis/ViewpointService";
import {
    ViewpointValidationController
} from "../../../../diagram/editor/validation/dialog/ViewpointValidationController";
import {DiagramEditorTranslationKey} from "../../DiagramEditorTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ModelProvider} from "../../../../common/diagrameditor/api/IDiagramEditorApi";
import {Menu} from "../../../menu/Menu";
import {MenuItem} from "../../../menu/MenuItem";
import {MenuButton} from "../../../menu/MenuButton";
import EventManager, {Unsubscriber} from "../../../../common/event/EventManager";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import {VisibilityOff} from "@mui/icons-material";
import {ValidationEventType} from "../../../../diagram/editor/validation/ValidationEvents";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";


interface ToolsMenuProps {
    open: boolean,
    onClick: (menuId: TopMenuId) => void,
    onClose: () => void,
    eventManager?: EventManager,
    modelProvider?: ModelProvider,
    mode: RenderMode,
}

export default function ToolsMenu(props: ToolsMenuProps) {

    const {open, onClick, onClose, eventManager, modelProvider} = props;

    let validationDisabled = false;
    if (open) {
        const viewpoint = modelProvider?.getModel().diagrams[0].diagramInfo.viewpoint;
        validationDisabled = viewpoint == null || viewpoint.trim().length === 0;
    }

    const [validationDialogOpen, setValidationDialogOpen] = useState(false);

    const [hideHighlightedDisabled, setHideHighlightedDisabled] = useState(true);
    useEffect(() => {
        let unsubscribeHighlighted: Unsubscriber | null = null;
        let unsubscribeUnhighlighted: Unsubscriber | null = null;
        if (eventManager) {
            unsubscribeHighlighted = eventManager.subscribeListener(ValidationEventType.REPORTED_OBJECTS_HIGHLIGHTED, () => {
                setHideHighlightedDisabled(false);
            });
            unsubscribeUnhighlighted = eventManager.subscribeListener(ValidationEventType.REPORTED_OBJECTS_UNHIGHLIGHTED, () => {
                setHideHighlightedDisabled(true);
            });
        }
        return () => {
            unsubscribeHighlighted && unsubscribeHighlighted();
            unsubscribeUnhighlighted && unsubscribeUnhighlighted();
        }
    }, [eventManager]);

    const viewpointValidationController = useRef(new ViewpointValidationController(viewpointService, eventManager, modelProvider));

    const anchorRef = useRef<HTMLButtonElement | null>(null);

    function onValidateClicked() {
        openValidationDialog();
        onClose();
    }

    function openValidationDialog() {
        setValidationDialogOpen(true);
    }

    function closeValidationDialog() {
        setValidationDialogOpen(false);
    }

    function onHideReported() {
        viewpointValidationController.current.publishUnhighlightRequestEvent();
        onClose();
    }

    return (
        <>
            <MenuButton label={_transl(DiagramEditorTranslationKey.TOP_MENU_TOOLS)}
                        ref={anchorRef}
                        onClick={() => onClick(TopMenuId.TOOLS_MENU)} />
            <Menu open={open}
                  anchorEl={anchorRef.current}
                  onClose={onClose}
            >
                <MenuItem label={_transl(DiagramEditorTranslationKey.TOP_MENU_TOOLS_VALIDATE_BY_VIEWPOINT)}
                          icon={<PlaylistAddCheckIcon />}
                          disabled={validationDisabled}
                          onClick={onValidateClicked} />
                <MenuItem label={_transl(DiagramEditorTranslationKey.TOP_MENU_TOOLS_UNHIGHLIGHT_VALIDATION_ERRORS)}
                          icon={<VisibilityOff />}
                          disabled={hideHighlightedDisabled}
                          onClick={onHideReported} />
            </Menu>

            <ViewpointValidationDialog open={validationDialogOpen}
                                       onClose={closeValidationDialog}
                                       viewpointValidationController={viewpointValidationController.current} />
        </>
    );
}
