import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import CommonCssStyles from "../../../css/CommonCssStyles";
import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import GraphDataChartPanel from "../../graphdatachart/GraphDataChartPanel";
import {IFilter} from "../../../store/elements/Elements";
import Api from "../../../common/Api";
import DiagramsElementChartListDialog from "../../graphdatachart/DiagramsElementChartListDialog";
import {ElementDto} from "../../../common/apis/element/ElementDto";

const margin = 3;

const styles = (theme: Theme) => createStyles({
    dialogTitle: {
        color: "gray",
        padding: 0,
        textAlign: "center",
        "& div": {
            padding: theme.spacing(margin)
        },
        "& hr": {
            marginLeft: theme.spacing(margin),
            marginRight: theme.spacing(margin),
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(margin),
        paddingTop: theme.spacing(margin + 1),

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
});

interface IProps extends WithStyles<typeof styles> {
    isOpened: boolean,
    entityId: string,
    title: string,
    elementIds: Array<string>,
    refreshGraphData: (elementIds: Array<string>) => Observable<AjaxResponse>,
    onShowElementDetail: (id: string) => void,

    // callbacks
    onDialogClosed: () => void,
}

interface IState {
    backendDataLastUpdateDate: Date;
    elementsGridIsOpen: boolean,
    elementDtos: Array<ElementDto>,
}

class GraphDataChartDialog extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            backendDataLastUpdateDate: new Date(),
            elementsGridIsOpen: false,
            elementDtos: [],
        }
    }

    render() {
        // states
        const {isOpened, elementIds, onShowElementDetail, refreshGraphData, title, entityId} = this.props;
        // styles
        const {classes} = this.props;
        // callbacks
        const {onDialogClosed} = this.props;
        // we don't track any backend changes here
        const {backendDataLastUpdateDate, elementsGridIsOpen, elementDtos} = this.state;

        return (
            <React.Fragment>
                {elementsGridIsOpen && <DiagramsElementChartListDialog isOpened={elementsGridIsOpen}
                                                                       elementDtos={elementDtos}
                                                                       onDialogClosed={() => this.onShowElementsGrid(false)}
                />}
                <Dialog
                    open={isOpened}
                    aria-labelledby="neighbours-chart-dialog-title"
                    onClose={onDialogClosed}
                    fullWidth={true}
                    maxWidth={"xl"}
                >
                    <DialogTitle id="neighbours-chart-dialog-title"
                                 className={classes.dialogTitle}>
                        <div>{title}</div>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onDialogClosed}
                            size="large">
                            <CloseIcon/>
                        </IconButton>
                        <Divider/>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.controlPageSegment} style={{height: "70vh"}}>
                            <GraphDataChartPanel entityId={entityId}
                                                 initialExplodedElementIds={elementIds}
                                                 onShowElementDetail={onShowElementDetail}
                                                 refreshGraphData={refreshGraphData}
                                                 backendGraphDataChangedDate={backendDataLastUpdateDate}
                                                 onShowElementsGrid={(elementDtoIds) => this.getElementSearchDtos(elementDtoIds)}
                                                 allowMaximize={false}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onDialogClosed}
                                variant={"outlined"}
                                color={"primary"}>
                            Zavřít
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    onShowElementsGrid(isOpen: boolean) {
        this.setState(state => {
            return {
                ...state,
                elementsGridIsOpen: isOpen,
            }
        })
    }

    getElementSearchDtos(elementDtoIds: Array<string>) {
        const iFilter: IFilter = {identifiers: elementDtoIds};

        Api.elements.doSearch(iFilter).subscribe(
            (response) => {
                this.setState(state => {
                    return {
                        ...state,
                        elementDtos: response.response.items as Array<ElementDto>,
                    }
                })
                this.onShowElementsGrid(true)
            },
            () => {
            }
        )
    }

}

export default withStyles(styles, {withTheme: true})(GraphDataChartDialog);
