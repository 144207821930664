import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React from "react";
import {DiagramEditor} from "../../../../../common/diagrameditor/DiagramEditor";
import {
    EventType,
    IChartEvent,
    IChartScrolledEvent, Event,
    IModelUpdatedEvent
} from "../../../../../common/event/Event";
import * as d3 from "d3";
import {Area, Point} from "../../../../../common/diagrameditor/util/GeometryUtils";
import {navigatorHeight} from "../../LeftMenu";
import Constants from "../../../../../common/Constants";
import {IEditMode} from "../../../../../common/diagrameditor/editor/IEditMode";

const scrollerBgColor = "rgba(200, 226, 246, 0.4)";
const scrollerBgColorDarker = d3.color(scrollerBgColor)?.darker(1).formatRgb();

const navigatorPanelId = "__diagram_editor_navigator_panel__";
const canvasId = "__diagram_editor_navigator_canvas__";
const scrollerId = "__diagram_editor_navigator_scroller__";
const diagramGroupSvgId = "__diagram_editor_navigator_svg__";
const dragAreaId = "__diagram_editor_navigator_drag_area__";
const paperId = "__diagram_editor_navigator_paper_area__";

const styles = (theme: Theme) => createStyles({
    navigatorPanel: {
        position: "relative",
        width: "100%",
        height: "100%",
        minHeight: navigatorHeight + "px",
        minWidth: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    canvas: {
        backgroundColor: "darkgray",
    },
    scroller: {
        position: "absolute",
        backgroundColor: scrollerBgColor,
        border: "1px solid " + scrollerBgColorDarker,
    },
    paper: {
        position: "absolute",
        backgroundColor: "white",
    },
    diagramGroupSvg: {
        position: "absolute",
    },
    dragArea: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    }
});

interface IProps extends WithStyles<typeof styles> {
    diagramId: string,
    mode: IEditMode,
    editor?: DiagramEditor;
}

interface IState {
}

class DiagramNavigator extends React.Component<IProps, IState> {

    private chartScrollListenerUnsubscriber?: () => void;
    private chartRenderedUnsubscriber?: () => void;
    private modelUpdatedUnsubscriber?: () => void;
    private diagramNode?: SVGGElement;
    private lastAppendedNode?: SVGGElement;
    private lastChartScrolledEvent?: IChartScrolledEvent;
    private lastScaleFactor: number;
    private lastCanvasScrollerArea?: Area;

    constructor(props: IProps) {
        super(props);
        this.lastScaleFactor = 1;
    }

    componentDidMount() {
        const {editor} = this.props;
        this.chartScrollListenerUnsubscriber = editor?.getDiagramEditorApi().addChartScrolledEventListener((event) => this.handleChartScrolledEvent(event));
        this.chartRenderedUnsubscriber = editor?.getDiagramEditorApi().addChartRenderedListener((event) => this.handleChartEvent(event));
        this.modelUpdatedUnsubscriber = editor?.getDiagramEditorApi().addModelUpdatedListener((event) => this.handleModelUpdatedEvent(event));
        this.attachDragAreaDragListener();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        this.updateNavigatorPanel();
        this.attachDragAreaDragListener();
    }

    componentWillUnmount() {
        this.chartScrollListenerUnsubscriber && this.chartScrollListenerUnsubscriber();
        this.chartRenderedUnsubscriber && this.chartRenderedUnsubscriber();
        this.modelUpdatedUnsubscriber && this.modelUpdatedUnsubscriber();
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return this.props.diagramId !== nextProps.diagramId;
    }

    handleChartScrolledEvent(event: IChartScrolledEvent) {
        if (event.type === EventType.CHART_SCROLLED) {
            this.lastChartScrolledEvent = event;
            this.updateNavigatorPanel(event);
        }
    }

    handleChartEvent(event: IChartEvent) {
        if (event.type === EventType.CHART_RENDERED) {
            this.updateNavigatorPanel(event);
        }
    }

    handleModelUpdatedEvent(event: IModelUpdatedEvent) {
        if (event.type === EventType.MODEL_UPDATED) {
            this.updateNavigatorPanel(event);
        }
    }

    updateNavigatorPanel(reason?: Event) {
        if (this.isRefreshDiagramCloneEvent(reason)) {
            this.diagramNode = this.props.editor?.getDiagramEditorApi().getDiagramCloneForPreview();
        }
        const navigatorPanel = window.document.getElementById(navigatorPanelId);
        if (navigatorPanel && this.diagramNode && this.lastChartScrolledEvent) {
            const rect = navigatorPanel.getBoundingClientRect();
            const navigatorPanelWidth = rect.width;
            const navigatorPanelHeight = rect.height;
            const scaleFactor = this.computeScaleFactor(navigatorPanelWidth, navigatorPanelHeight, this.lastChartScrolledEvent.scrollWidth, this.lastChartScrolledEvent.scrollHeight);
            this.updatePanel(scaleFactor, this.lastChartScrolledEvent, this.diagramNode);
        }
    }

    render() {
        const {classes} = this.props;

        return <div id={navigatorPanelId} className={classes.navigatorPanel}>
            <div style={{position: "relative"}}>
                <div id={canvasId} className={classes.canvas} />
                <div id={paperId} className={classes.paper} />
                <svg id={diagramGroupSvgId} className={classes.diagramGroupSvg} />
                <div id={scrollerId} className={classes.scroller} />
                <div id={dragAreaId} className={classes.dragArea} />
            </div>
        </div>
    }

    private updatePanel(scaleFactor: number, event: IChartScrolledEvent, diagramNode: SVGGElement) {
        this.lastScaleFactor = scaleFactor;
        const canvasWidth = (event.scrollWidth / scaleFactor);
        const canvasHeight = (event.scrollHeight / scaleFactor);

        d3.select(window.document.getElementById(canvasId))
            .style("background-color", Constants.EDITOR_BACKGROUND_COLOR)
            .style("width",  canvasWidth + "px")
            .style("height",  canvasHeight + "px");

        this.lastCanvasScrollerArea = this.computeScrollerArea(scaleFactor, event);
        d3.select(window.document.getElementById(scrollerId))
            .style("top", this.lastCanvasScrollerArea.y + "px")
            .style("left", this.lastCanvasScrollerArea.x + "px")
            .style("width", this.lastCanvasScrollerArea.w + "px")
            .style("height", this.lastCanvasScrollerArea.h + "px");

        if (this.lastAppendedNode !== diagramNode) {
            const diagramGroupSvgSelection = d3.select(window.document.getElementById(diagramGroupSvgId));
            diagramGroupSvgSelection.selectAll("*").remove();
            diagramGroupSvgSelection.node()?.appendChild(diagramNode);
            const bbox = diagramNode.getBBox();
            diagramGroupSvgSelection.attr("viewBox", `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);
            this.lastAppendedNode = diagramNode;
        }

        const lastPaperArea = this.computePaperArea(scaleFactor, event);
        d3.select(window.document.getElementById(paperId))
            .style("top", lastPaperArea.y + "px")
            .style("left", lastPaperArea.x + "px")
            .style("width", lastPaperArea.w + "px")
            .style("height", lastPaperArea.h + "px");

        const lastCanvasDiagramGroupSvgArea = this.computeDiagramGroupArea(scaleFactor, event);
        d3.select(window.document.getElementById(diagramGroupSvgId))
            .style("top", lastCanvasDiagramGroupSvgArea.y + "px")
            .style("left", lastCanvasDiagramGroupSvgArea.x + "px")
            .style("width", lastCanvasDiagramGroupSvgArea.w + "px")
            .style("height", lastCanvasDiagramGroupSvgArea.h + "px");
    }

    private computeScaleFactor(navigatorPanelWidth: number, navigatorPanelHeight: number, clientWidth: number, clientHeight: number) {
        const verticalScaleFactor = clientHeight / navigatorPanelHeight;
        const horizontalScaleFactor = clientWidth / navigatorPanelWidth;
        return Math.max(verticalScaleFactor, horizontalScaleFactor);
    }

    private computeScrollerArea(scaleFactor: number, event: IChartScrolledEvent): Area {
        const scrollerTop = event.scrollTop / scaleFactor;
        const scrollerLeft = event.scrollLeft / scaleFactor;
        const scrollerWidth = event.clientWidth / scaleFactor;
        const scrollerHeight = event.clientHeight / scaleFactor;

        return new Area(scrollerLeft, scrollerTop, scrollerWidth, scrollerHeight);
    }

    private computeDiagramGroupArea(scaleFactor: number, event: IChartScrolledEvent) {
        const diagramGroupArea = event.diagramGroupToSvgRelativeArea;
        const diagramGroupTop = diagramGroupArea.y / scaleFactor;
        const diagramGroupLeft = diagramGroupArea.x / scaleFactor;
        const diagramGroupWidth = diagramGroupArea.w / scaleFactor;
        const diagramGroupHeight = diagramGroupArea.h / scaleFactor;

        return new Area(diagramGroupLeft, diagramGroupTop, diagramGroupWidth, diagramGroupHeight);
    }

    private computePaperArea(scaleFactor: number, event: IChartScrolledEvent) {
        const paperArea = event.paperToSvgRelativeArea;
        const diagramGroupTop = paperArea.y / scaleFactor;
        const diagramGroupLeft = paperArea.x / scaleFactor;
        const diagramGroupWidth = paperArea.w / scaleFactor;
        const diagramGroupHeight = paperArea.h / scaleFactor;

        return new Area(diagramGroupLeft, diagramGroupTop, diagramGroupWidth, diagramGroupHeight);
    }

    private attachDragAreaDragListener() {
        const drag = d3.drag()
            .on("start", (event: any) => this.onDragStart(event))
            .on("drag", (event: any) => this.onDragProgress(event))
            .on("end", (event: any) => this.onDragEnd(event));

        const canvasSelection = d3.select(window.document.getElementById(dragAreaId));
        // @ts-ignore  <- TODO nevim jak to typove vyresit
        canvasSelection.call(drag);
        canvasSelection.on("click", (event) => this.scrollDiagramOnClick(event));
    }

    private onDragStart(event: any) {
    }

    private onDragProgress(event: any) {
        this.scrollDiagram(event);
    }

    private onDragEnd(event: any) {
        this.scrollDiagram(event);
    }

    private scrollDiagram(event: any) {
        this.props.editor?.getDiagramEditorApi().scrollDiagram(event.dx * this.lastScaleFactor, event.dy * this.lastScaleFactor, event);
    }

    private scrollDiagramOnClick(event: any) {
        if (this.lastCanvasScrollerArea) {
            const eventCanvasPoint = this.getPointerCoordinatesRelativeToCanvas(event);
            const newScrollerStartPoint = new Point(
                eventCanvasPoint.x - (this.lastCanvasScrollerArea.w / 2),
                eventCanvasPoint.y - (this.lastCanvasScrollerArea.h / 2)
            );
            this.props.editor?.getDiagramEditorApi().scrollDiagram(
                (newScrollerStartPoint.x - this.lastCanvasScrollerArea.x) * this.lastScaleFactor,
                (newScrollerStartPoint.y - this.lastCanvasScrollerArea.y) * this.lastScaleFactor,
                event);
        }
    }

    private getPointerCoordinatesRelativeToCanvas(canvasEvent: any) {
        const canvas = window.document.getElementById(canvasId) as HTMLElement;
        const clientX = canvasEvent.sourceEvent?.clientX != null ? canvasEvent.sourceEvent?.clientX : canvasEvent.clientX;
        const clientY = canvasEvent.sourceEvent?.clientY != null ? canvasEvent.sourceEvent?.clientY : canvasEvent.clientY;
        const rect = canvas.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;
        return new Point(x, y);
    }

    private isRefreshDiagramCloneEvent(reason?: Event) {
        return !reason || reason.type === EventType.CHART_RENDERED || reason.type === EventType.MODEL_UPDATED;

    }
}

export default withStyles(styles, { withTheme: true })(DiagramNavigator);
