import {_transl} from "../../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../ElementDetailTranslationKey";
import {PersistentStateId} from "../../../../../store/common/Grid";
import {TaskDto} from "@dain/tracker-client";
import {GridColDef} from "@mui/x-data-grid";
import ExtGridWrapper from "../../../../../components/grid/ExtGridWrapper";


export interface TrackerTasksGridProps {
    tasks: TaskDto[],
    onSelectionChange: (task: TaskDto) => void,
}

export default function TrackerTasksGrid({tasks, onSelectionChange}: TrackerTasksGridProps) {

    const COL_DEFS: GridColDef[] = [
        {field: 'key', headerName: _transl(ElementDetailTranslationKey.TASKS_KEY), width: 100},
        {field: 'caption', headerName: _transl(ElementDetailTranslationKey.TASKS_NAME), width: 250},
        {field: 'taskTypeName', headerName: _transl(ElementDetailTranslationKey.TASKS_TASK_TYPE), width: 150},
        {field: 'statusName', headerName: _transl(ElementDetailTranslationKey.TASKS_STATE), width: 150},
    ];

    function handleOnSelectionChanged(newTask: TaskDto): void {
        onSelectionChange(newTask);
    }

    return (
            <ExtGridWrapper
                    columns={COL_DEFS}
                    rows={tasks}
                    rowCount={tasks.length}
                    getRowId={row => row.key}
                    actions={[]}
                    disableMultipleSelection={true}
                    onSelectionChanged={(rowIds, rows) => handleOnSelectionChanged(rows[0] as TaskDto)}

                    peristentStateId={PersistentStateId.ELEMENT_DETAIL_PAGE_TRACKER_TASKS_PICK_GRID}
                    resourceId={PersistentStateId.ELEMENT_DETAIL_PAGE_TRACKER_TASKS_PICK_GRID}
            />
    );
}
