import {Dialog as MuiDialog, DialogProps as MuiDialogProps} from "@mui/material";

export interface DialogProps extends MuiDialogProps {
    maxWidth?: MuiDialogProps['maxWidth'];
}

export default function Dialog(props: DialogProps) {
    const {maxWidth = "xs"} = props;
    return (
        <MuiDialog
            fullWidth={true}
            maxWidth={maxWidth}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            {...props}
        >
            {props.children}
        </MuiDialog>
    );
}
