import {ValidationError} from "../../../../../../common/ValidationError";
import {SubmodelExchangeDto} from "./dto/SubmodelExchangeDto";
import {SubmodelExchangeErrorCodes} from "./SubmodelExchangeErrorCodes";

export const VERSION = "v1";

export class SubmodelExchangeEncryptor {

    encryptSubmodelExchangeDto(submodelExchangeDto: SubmodelExchangeDto): string {
        return JSON.stringify(submodelExchangeDto);
    }

    decryptSubmodelExchangeDto(inputString: string): SubmodelExchangeDto {
        try {
            const data = JSON.parse(inputString);
            if (data.version === VERSION) {
                return data as SubmodelExchangeDto;
            } else {
                throw new ValidationError({code: SubmodelExchangeErrorCodes.WRONG_VERSION});
            }
        } catch (error) {
            if (error instanceof ValidationError && error.error.code === SubmodelExchangeErrorCodes.WRONG_VERSION) {
                throw error;
            } else {
                throw new ValidationError({code: SubmodelExchangeErrorCodes.INVALID_INPUT});
            }
        }
    }

}

