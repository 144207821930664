import {SnackbarKey, useSnackbar, VariantType, WithSnackbarProps} from 'notistack'
import React from 'react'

interface SnackbarProps {
    setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarConfigurator: React.FC<SnackbarProps> = (props: SnackbarProps) => {
    props.setUseSnackbarRef(useSnackbar());
    return null;
}

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
    useSnackbarRef = useSnackbarRefProp;
}

export const SnackbarConfigurator = () => {
    return <InnerSnackbarConfigurator setUseSnackbarRef={setUseSnackbarRef}/>
}

const Snackbar = {
    success(msg: string) {
        this.toast(msg, 'success');
    },
    warning(msg: string) {
        this.toast(msg, 'warning');
    },
    info(msg: string) {
        this.toast(msg, 'info');
    },
    error(msg: string, err?: any) {
        this.toast(msg, 'error');
        if (err) {
            console.error(`An error has occured:`);
            console.error(err);
        }
    },
    toast(msg: string, variant: VariantType = 'default') {
        useSnackbarRef.enqueueSnackbar(msg, {variant});
    },

    onClose(key: SnackbarKey) {
        useSnackbarRef.closeSnackbar(key);
    }
}
export default Snackbar;