import React from "react";
import {makeStyles} from "@mui/styles";
import {StepIconProps} from "@mui/material";
import clsx from "clsx";
import {Theme} from "@mui/material/styles"

const useWizardIconStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function WizardIcon(props: StepIconProps) {
    const classes = useWizardIconStyles();
    const { active, completed, icon } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icon}
        </div>
    );
}
