import {IconButton, InputAdornment} from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";

interface ClearButtonInputAdornmentProps {
    onClick: (value: any) => void,
    visible?: boolean
}

export default function ClearButtonInputAdornment(props: ClearButtonInputAdornmentProps) {
    const {onClick} = props;
    const visible = props.visible === undefined ? true : props.visible;

    return <InputAdornment position="end">
        {visible &&
            <IconButton
                aria-label="clear button"
                onClick={onClick}
                edge="end"
                size="small">
                <ClearIcon fontSize="small"/>
            </IconButton>}
    </InputAdornment>
}
