import React from "react";
import {HierarchyTreeItemDetailDto} from "../service/HierarchyTreeItemDetailDto";
import Typography from "@mui/material/Typography";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

export interface DiagramTreeItemLabelProps {
    treeItem: HierarchyTreeItemDetailDto;
}

export function DiagramTreeItemLabel(props: DiagramTreeItemLabelProps) {
    const {treeItem} = props;

    return (
        <>
            <Typography component={"span"} sx={{display: "flex", alignItems: "center", mr: 1}}>
                <AccountTreeIcon/>
            </Typography>
            <Typography component={"span"} sx={{display: "flex", alignItems: "center"}}>
                {treeItem.title ?? ""}
            </Typography>
        </>
    );
}