import moment from "moment";

export function endOfTheDay(date: Date | string | undefined): Date | undefined {
    if (date === undefined  || date === "") {
        return undefined;
    } else {
        return moment(date).endOf("day").toDate();
    }
}

export function startOfTheDay(date: Date | string | undefined): Date | undefined {
    if (date === undefined  || date === "") {
        return undefined;
    } else {
        return moment(date).startOf("day").toDate();
    }
}