import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../Api";
import Elements from "./Elements";
import Relationships from "./Relationships";
import Constants from "../Constants";
import {FileFormat} from "../../pages/main/content/import/FileType";
import {OpenGroupExchangeFormatType} from "./OpenGroupExchangeFormat";

export interface IElementsImportMetadataDto {
    format: FileFormat,
    collectionCodes: Array<String>,
}

export interface IRelationshipsImportMetadataDto {
    format: FileFormat,
}

export interface IRelationshipsMatrixMetadataDto {
    format: FileFormat,
}

export interface IModelImportMetadataDto {
    format: OpenGroupExchangeFormatType,
    collectionCodes: Array<String>;
    labelCodes: Array<String>;
    importProperties: boolean;
    removeDeletedElementsAndRelationships: boolean;
    ignoreFontSettings: boolean;
    ignoreConcurrentUpdates?: boolean;
    skipSchemaValidation?: boolean;
}

export default class Imports {

    public static ELEMENTS_IMPORT_URL: string = `${Elements.ENDPOINT_URL}:import`;
    public static RELATIONSHIPS_IMPORT_URL: string = `${Relationships.ENDPOINT_URL}/import`;
    public static RELATIONSHIPS_MATRIX_IMPORT_URL: string = `${Relationships.ENDPOINT_URL}/import/matrix`;
    public static MODEL_IMPORT_URL: string = `${Constants.API_HOST}/rest-api/repository/model:import-xml`;
    public static MODEL_JSON_IMPORT_URL: string = `${Constants.API_HOST}/rest-api/repository/model:import-json`;
    public static USER_PERMISSIONS_IMPORT_URL = `${Constants.API_HOST}/rest-api/users/permissions:import`;

    importElements(file: File, metadata: IElementsImportMetadataDto): Observable<AjaxResponse> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('metadata', new Blob([JSON.stringify(metadata)], {type: "application/json"}));

        return Api.createAjax({
            url: Imports.ELEMENTS_IMPORT_URL,
            method: "POST",
            body: formData,
        }, true);
    }

    importRelationships(file: File, metadata: IRelationshipsImportMetadataDto): Observable<AjaxResponse> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('metadata', new Blob([JSON.stringify(metadata)], {type: "application/json"}));

        return Api.createAjax({
            url: Imports.RELATIONSHIPS_IMPORT_URL,
            method: "POST",
            body: formData,
        }, true);
    }

    importRelationshipsMatrix(file: File, metadata: IRelationshipsMatrixMetadataDto): Observable<AjaxResponse> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('metadata', new Blob([JSON.stringify(metadata)], {type: "application/json"}));

        return Api.createAjax({
            url: Imports.RELATIONSHIPS_MATRIX_IMPORT_URL,
            method: "POST",
            body: formData,
        }, true);
    }

    async importModel(file: File, metadata: IModelImportMetadataDto): Promise<{status: number}> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('metadata', new Blob([JSON.stringify(metadata)], {type: "application/json"}));

        return await Api.createAjax(
            {
                url: Imports.MODEL_IMPORT_URL,
                method: "POST",
                body: formData,
            }, true)
            .toPromise();
    }

    importUserPermissions(file: File): Observable<AjaxResponse> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return Api.createAjax({
            url: Imports.USER_PERMISSIONS_IMPORT_URL,
            method: "POST",
            body: formData,
        }, true);
    }
}
