import {IDiagramConnectionDto} from "../../../apis/diagram/IDiagramConnectionDto";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";
import CopyUtils from "../../../CopyUtils";
import {Diagram} from "../../model/Model";

export class SelfLoopBendpointAppender {

    constructor(private readonly loopSize = 25) {
    }

    public appendSelfLoopBendpoints(originalDiagram: Diagram): Diagram {
        if (!originalDiagram.connections || originalDiagram.connections.length === 0) {
            return originalDiagram;
        }

        const nodes = this.gatherAllNodes(originalDiagram.nodes);
        const nodeMap = new Map(nodes.map(node => [node.identifier, node]));

        const connections = [];
        for (const connection of originalDiagram.connections) {
            if (this.isSelfLoop(connection) && this.containsNoBendpoints(connection)) {
                if (nodeMap.has(connection.sourceIdentifier)) {
                    const sourceNode = nodeMap.get(connection.sourceIdentifier);
                    connections.push(this.addSelfLoopBendpoints(connection, sourceNode!));
                } else {
                    throw new Error(`Cannot find a connection source node (identifier: ${connection.sourceIdentifier}).`);
                }
            } else {
                connections.push(connection);
            }
        }
        originalDiagram.connections = connections;
        return originalDiagram;
    }

    private gatherAllNodes(nodes: IDiagramNodeDto[]): IDiagramNodeDto[] {
        return nodes.flatMap(node => this.gatherNodeIncludingChildren(node));
    }

    private gatherNodeIncludingChildren(node: IDiagramNodeDto): IDiagramNodeDto[] {
        const nodes = [node];
        if (node.childNodes) {
            const childNodes = node.childNodes.flatMap(childNode => this.gatherNodeIncludingChildren(childNode));
            nodes.push(...childNodes);
        }
        return nodes;
    }

    public isSelfLoop(connection: IDiagramConnectionDto): boolean {
        return (connection.sourceIdentifier === connection.targetIdentifier);
    }

    private containsNoBendpoints(connection: IDiagramConnectionDto): boolean {
        return (connection.bendpoints && connection.bendpoints.length === 0);
    }

    public addSelfLoopBendpoints(originalConnection: IDiagramConnectionDto, node: IDiagramNodeDto): IDiagramConnectionDto {
        const connection = CopyUtils.deepCopy(originalConnection);
        connection.bendpoints = [];
        connection.bendpoints.push({x: node.x + this.loopSize, y: node.y - this.loopSize});
        connection.bendpoints.push({x: node.x - this.loopSize, y: node.y - this.loopSize});
        connection.bendpoints.push({x: node.x - this.loopSize, y: node.y + this.loopSize});
        return connection;
    }

}