import {Slider} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingLeft: "3px",
            paddingRight: "3px"
        },
    })
);

const MIN = 8;
const MAX = 20;
const MARK_STEP = 4;

export function computeMarks(min: number, max: number, step: number) {
    if (step <= 0) {
        throw new Error("Step must be a positive number");
    }
    if (min > max) {
        throw new Error("Max must be greater than min value")
    }
    let marks = [];
    for (let i = min; i < max; i += step) {
        marks.push({
            value: i,
            label: "" + i
        });
    }
    marks.push({
        value: max,
        label: "" + max
    });
    return marks;
}

export interface FontSizeSliderProps {
    id?: string,
    value: number,
    disabled?: boolean,
    onChange: (size: number) => void,
}

export function FontSizeSlider(props: FontSizeSliderProps) {
    const classes = useStyles();

    function onValueChanged(event: any, value: number | number[]) {
        if (typeof value === "number") {
            props.onChange(value);
        }
    }

    return (
        <div className={classes.container}>
            <Slider id={props.id}
                    min={MIN} max={MAX} marks={computeMarks(MIN, MAX, MARK_STEP)}
                    value={props.value}
                    valueLabelDisplay={"auto"}
                    disabled={props.disabled ?? false}
                    onChange={onValueChanged}/>
        </div>
    );
}
