import {UserRoleType} from "./UserRoleType";
import userRoleHierarchy from "./UserRoleHierarchy";
import {UserDto} from "../apis/user/UserDto";

export class UserRole {

    public static readonly [UserRoleType.ROLE_ADMIN] = new UserRole(UserRoleType.ROLE_ADMIN, 1);
    public static readonly [UserRoleType.ROLE_SPRAVCE] = new UserRole(UserRoleType.ROLE_SPRAVCE, 2);
    public static readonly [UserRoleType.ROLE_OPERATOR] = new UserRole(UserRoleType.ROLE_OPERATOR, 3);


    public static values(): Array<UserRole> {
        return Object.keys(UserRoleType)
            .map(key => UserRole[key as UserRoleType]);
    }

    public static valueOf(formatTypeName: string) {
        const existingValues = Object.keys(UserRoleType)
            .filter((name) => name === formatTypeName);
        return existingValues.length === 1 ? UserRole[existingValues[0] as UserRoleType] : null;
    }

    public static isAtLeast(user: UserDto, requiredRole: UserRoleType) {
        return userRoleHierarchy.isAtLeast(user, requiredRole);
    }

    constructor(public readonly nameOfAuthority: UserRoleType,
                public readonly id: number,
    ) {
    }
}
