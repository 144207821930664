import React, {useState} from "react";
import {
    FormControlLabel,
    Radio,
} from "@mui/material";
import {OkButton} from "../../../../../components/button/OkButton";
import {CancelButton} from "../../../../../components/button/CancelButton";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../DiagramTranslationKey";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import Grid from "../../../../../components/dialogs/Grid";

interface RemoveObjectsConfirmationDialogProps {
    disableRemoveFromModel: boolean;
    onConfirm: (event: any, removeFromModel: boolean) => void;
    onCancel: (event: any) => void;
}

export default function RemoveObjectsConfirmationDialog({disableRemoveFromModel, onConfirm, onCancel}: RemoveObjectsConfirmationDialogProps) {
    const [removeFromModel, setRemoveFromModel] = useState<boolean>(false);

    return (
        <Dialog open={true} maxWidth={"md"}>
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(DiagramTranslationKey.REMOVE_OBJECTS_DIALOG_TITLE)}
                         onDialogClosed={() => onCancel(undefined)}/>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <span>{_transl(DiagramTranslationKey.REMOVE_OBJECTS_DIALOG_REMOVAL_TYPE)}: </span>
                    </Grid>
                    <Grid item xs={12} noStyle>
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={!removeFromModel}
                                    onChange={() => setRemoveFromModel(false)}
                                    value={false}
                                    name="reuse-group"
                                />
                            }
                            label={_transl(DiagramTranslationKey.REMOVE_OBJECTS_DIALOG_ONLY_FROM_DIAGRAM)}
                        />
                    </Grid>
                    <Grid item xs={12} noStyle>
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={removeFromModel}
                                    disabled={disableRemoveFromModel}
                                    onChange={() => setRemoveFromModel(true)}
                                    value={true}
                                    name="reuse-group"
                                />
                            }
                            label={_transl(DiagramTranslationKey.REMOVE_OBJECTS_DIALOG_FROM_BOTH_DIAGRAM_AND_MODEL)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <OkButton onClick={(e) => onConfirm(e, removeFromModel)}/>
                <CancelButton
                    onClick={(event) => onCancel(event)}
                />
            </DialogActions>
        </Dialog>
    );
}
