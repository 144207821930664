import React from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import EditableComponent, {EDIT_ROOT_SUBCOMPONENT_ROLE} from "../EditableComponent";
import Constants from "../../../common/Constants";
import DatePicker from "./DatePicker";
import {DateNullable} from "../../../common/Types";

const DEFAULT_CALLBACK_DELAY_IN_MS = 100;

const styles = (theme: Theme) => createStyles({
    root: {
        "& > *": {
            flexGrow: 1,
        },
        display: "flex",
    }
});

interface IProps extends WithStyles<typeof styles> {
    label: string,
    initialValue: DateNullable,
    doUpdate: (date: Date) => Promise<any>,
    onSuccessfulUpdate?: (date: Date) => void,
    id?: string,
    readOnly?: boolean,
    deleteDateErrorMsg?: string,
}

interface IState {
    value: DateNullable,
    datePickerOpened: boolean,
    updateInProgress: boolean,
    updateErrored: boolean,
}

class EditableDatePicker extends React.Component<IProps, IState> {

    editRootRole: string;

    constructor(props: IProps) {
        super(props);
        this.editRootRole = `${EDIT_ROOT_SUBCOMPONENT_ROLE}_${Math.random()}`;
        const {initialValue} = this.props;
        this.state = this.createInitialState(initialValue);
    }

    createInitialState(initialValue: DateNullable) {
        return {
            value: initialValue,
            datePickerOpened: false,
            updateInProgress: false,
            updateErrored: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {initialValue} = this.props;

        if (initialValue !== prevProps.initialValue) {
            this.updateState([{propertyName: "value", value: initialValue}]);
        }
    }

    updateState<V extends keyof IState>(newPropertyValues: Array<{ propertyName: V, value: IState[V] }>, callback?: (state: IState) => void) {
        const stateCopy: IState = {...this.state};
        newPropertyValues.forEach(propertyValue => stateCopy[propertyValue.propertyName] = propertyValue.value);
        this.setState(stateCopy, () => {
            if (callback) {
                callback(stateCopy)
            }
        });
    }

    render() {
        const {label, doUpdate, onSuccessfulUpdate, id, initialValue, readOnly, deleteDateErrorMsg} = this.props;
        const {value} = this.state;
        const formattedValue = (value?: Date) => value != null ? Constants.formatDate(value) : null;

        return (
            <EditableComponent label={label}
                               isViewDatePicker={true}
                               deleteDateErrorMsg={deleteDateErrorMsg}
                               initialValue={initialValue}
                               getEditedValue={() => value}
                               valueToViewString={(value) => formattedValue(value as Date)}
                               valuesEqual={(date, newDate) => {
                                   return (date == null && newDate == null) || (date === newDate);
                               }}
                               getEditComponent={(cancelUpdate, saveChanges) => this.renderEditComponent(cancelUpdate, saveChanges)}
                               onClearDate={(saveChanges) => {
                                   this.updateState([
                                       {propertyName: "value", value: null},
                                       {propertyName: "datePickerOpened", value: false},
                                   ], () => {
                                       saveChanges()
                                   })
                               }}
                               focusEditComponent={() => {
                               }}
                               onEditModeEntered={() => this.updateState([{
                                   propertyName: "datePickerOpened",
                                   value: true
                               }])}
                               doUpdate={doUpdate as (value: unknown) => Promise<any>}
                               onUpdateInProgress={() => this.updateState([{
                                   propertyName: "updateInProgress",
                                   value: true
                               }, {propertyName: "updateErrored", value: false}])}
                               onSuccessfulUpdate={(date) => {
                                   this.updateState([
                                       {propertyName: "updateInProgress", value: false},
                                       {propertyName: "updateErrored", value: false}]);
                                   if (onSuccessfulUpdate) {
                                       onSuccessfulUpdate(date as Date);
                                   }
                               }}
                               onFailedUpdate={() => this.updateState([
                                   {propertyName: "updateInProgress", value: false},
                                   {propertyName: "updateErrored", value: true}])
                               }
                               onCancelChanges={() => this.setState(this.createInitialState(initialValue))}
                               id={id}
                               editRootRole={this.editRootRole}
                               readonly={readOnly}
            />
        );
    }

    renderEditComponent(cancelUpdate: () => void, saveChanges: () => void) {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                {this.renderEditableDatePicker(cancelUpdate, saveChanges)}
            </div>
        );
    }

    private renderEditableDatePicker(cancelUpdate: () => void, saveChanges: () => void): JSX.Element {
        const {value, datePickerOpened} = this.state;
        const pickerCloseCallback = (state: IState) => {
            // if update not in progress and update didn't errored then set to view mode
            if (!(state.updateInProgress || state.updateErrored)) {
                setTimeout(() => cancelUpdate(), DEFAULT_CALLBACK_DELAY_IN_MS);
            }
        }

        return <DatePicker
            open={datePickerOpened}
            value={value}
            onChange={(newDate: DateNullable) => {
                this.updateState([
                    {propertyName: "value", value: newDate},
                    {propertyName: "datePickerOpened", value: false},
                ], () => {
                    saveChanges()
                })
            }}
            onClose={() => setTimeout(() => {
                this.updateState([{propertyName: "datePickerOpened", value: false}], pickerCloseCallback)
            }, DEFAULT_CALLBACK_DELAY_IN_MS)}
            DialogProps={{
                role: this.editRootRole,
            }}
        />
    }
}

export default withStyles(styles, {withTheme: true})(EditableDatePicker);
