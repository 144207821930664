import {SubmodelExchangeNodeDto} from "../dto/SubmodelExchangeNodeDto";
import {IDiagramNodeDto} from "../../../../../../../common/apis/diagram/IDiagramNodeDto";
import {ElementDto} from "../../../../../../../common/apis/element/ElementDto";

export class SubmodelExchangeNodeDtoBuilder {

    buildSubmodelExchangeNodeDto(node:IDiagramNodeDto, element: ElementDto): SubmodelExchangeNodeDto {
        return {
            x: node.x,
            y: node.y,
            w: node.w,
            h: node.h,

            lineColorR: node.style?.lineColor?.r,
            lineColorG: node.style?.lineColor?.g,
            lineColorB: node.style?.lineColor?.b,
            lineColorA: node.style?.lineColor?.a,

            fillColorR: node.style?.fillColor?.r,
            fillColorG: node.style?.fillColor?.g,
            fillColorB: node.style?.fillColor?.b,
            fillColorA: node.style?.fillColor?.a,

            fontColorR: node.style?.font?.color?.r,
            fontColorG: node.style?.font?.color?.g,
            fontColorB: node.style?.font?.color?.b,
            fontColorA: node.style?.font?.color?.a,
            fontName: node.style?.font?.name,
            fontSize: node.style?.font?.size,
            fontStyles: node.style?.font?.styles,

            elementIdentifier: node.elementIdentifier!,
            elementType: element.type,
            elementName: element.name ? element.name : "",
            elementStereotypeId: element.stereotype?.id,
            elementStereotypeElementType: element.stereotype?.elementType,
            elementStereotypeName: element.stereotype?.name,
        };
    }

}