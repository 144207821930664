import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React from "react";

export interface TitleHeaderProps {
    icon?: JSX.Element;
    title: string;
    left?: React.ReactElement | React.ReactElement[]
    right?: React.ReactElement | React.ReactElement[]
}

export default function TitleHeader(props: TitleHeaderProps) {
    const {icon, title, left, right} = props;

    return (
        <>
            <Grid container
                  sx={(theme) => ({
                      padding: theme.spacing(2)
                  })} >
                <Grid item xs={12}>
                    <Grid container alignItems={"center"} spacing={1}>
                        <Grid item xs={"auto"} sx={{padding: 0}}>
                            <Typography variant="h6" style={{display: "flex", alignItems: "center", gap: "12px"}}>
                                {icon}
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs sx={{padding: 0}}>
                            {left}
                        </Grid>
                        <Grid item xs={"auto"} sx={{padding: 0}}>
                            {right}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider/>
        </>
    );
}