import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import {grey} from "@mui/material/colors";

const LanguageButton = styled(Button)(({theme}) => ({
    borderColor: "white",
    backgroundColor: "white",
    "&:hover": {
        borderColor: "white",
        backgroundColor: grey[200]
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: "0px",
    minWidth: "30px",
}));

export default LanguageButton;