import {Fab, Grid} from "@mui/material";
import {Close, Send} from "@mui/icons-material";
import {UserDto} from "../../../../common/apis/user/UserDto";
import ChatAvatar from "../../../../components/chat/ChatAvatar";
import UserFormatter from "../users/UserFormatter";
import ChatCommentEditor from "./ChatCommentEditor";
import {useEffect, useRef, useState} from "react";
import chatService, {ChatDto} from "../../../../common/apis/chat/ChatService";
import Snackbar from "../snackbar/Snackbar";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import {CommonTranslation} from "../CommonTranslation";
import {ChatCommentValidator} from "./ChatCommentValidator";
import {TooltippedIconButton} from "../../../../components/button/TooltippedIconButton";

export interface ChatCommentCreateFooterProps {
    chatId: number;
    user: UserDto;
    onCommentCreated: (chatId: number) => void;
    chat: ChatDto;
    handleClose?: () => void;
    placeholder? : string;
    handleNewChat?: () => void;
}

export const NEW_CHAT_ID = -1;

export default function ChatCommentCreateFooter(props: ChatCommentCreateFooterProps) {
    const {chatId, user, onCommentCreated, chat, handleClose, placeholder, handleNewChat} = props;
    const name = UserFormatter.formatFullName(user.firstName, user.lastName);

    const [commentText, setCommentText] = useState<string>("");
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);

    const commentValidator = useRef(new ChatCommentValidator());

    function createComment() {
        if (chatId === NEW_CHAT_ID) {
            chatService.createChat({
                diagramId: chat.diagramId,
                elementId: chat.elementId,
                chatNode: chat.chatNode ? {x: chat.chatNode.x, y: chat.chatNode.y, nodeId: chat.chatNode.nodeId} : undefined,
                initialComment: {message: commentText},
            })
                .then(chat => {
                    chatService.markAsRead(chat.id)
                        .finally(() => {
                            setCommentText("");
                            onCommentCreated(chat.id);
                            if (handleNewChat) {
                                handleNewChat();
                            }
                        });
                })
                .catch(err =>
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA))
                );
        } else {
            chatService.createComment(chatId, {message: commentText})
                .then(comment => {
                    setCommentText("");
                    onCommentCreated(chatId);
                })
                .catch(err =>
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA))
                );
        }
    }

    useEffect(() => {
        setSaveButtonDisabled(!commentValidator.current.isCommentValid(commentText))
    }, [commentText]);

    return (
        <>
            <Grid container spacing={1} alignItems={"center"}>
                <Grid item xs={"auto"}>
                    <ChatAvatar name={name} />
                </Grid>
                <Grid item xs>
                    <ChatCommentEditor placeholder={placeholder}
                                       value={commentText}
                                       onValueChanged={(text) => setCommentText(text)}/>
                </Grid>
                {handleClose &&
                    <Grid item xs={"auto"}>
                        <TooltippedIconButton icon={<Close/>}
                                              tooltip={_transl(CommonTranslation.CANCEL)}
                                              onClick={handleClose}
                                              size={"medium"} />
                    </Grid>}
                <Grid item xs={"auto"}>
                    <Fab title={_transl(CommonTranslation.SAVE)}
                         onClick={createComment}
                         disabled={saveButtonDisabled}
                         color={"primary"}
                         size={"small"} >
                        <Send />
                    </Fab>
                </Grid>
            </Grid>
        </>
    );
}
