import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Wizard from "../../../../components/wizard/Wizard";
import {FormControl, FormGroup, IconButton, InputLabel, MenuItem, Select} from "@mui/material";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../../../../common/Api";
import ReorderIcon from '@mui/icons-material/Reorder';
import {WizardStep} from "../../../../components/wizard/WizardStep";
import SettingsIcon from '@mui/icons-material/Settings';
import {FileFormat, FileFormatType, IRelationshipMatrixExportDto} from "../../../../common/apis/Exports";
import {PersistentStateId} from "../../../../store/common/Grid";
import {GridColDef} from "@mui/x-data-grid";
import {
    GridAction,
    ActionButtonType,
    EnabledPolicy
} from "../../../../components/grid/GridAction";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ElementsPickDialog from "../elements/ElementsPickDialog";
import {BlobUtils} from "../../../../common/BlobUtils";
import ActionButtonUtils from "../../../../components/grid/ActionButtonUtils";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {ExportTranslationKey} from "./ExportTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../diagrams/DiagramTranslationKey";
import {stereotypeGetter} from "../stereotypes/StereotypeGetter";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

class GridDef {

    public static getGridColDef(): GridColDef[] {
        return [
            {field: 'identifier', headerName: _transl(DiagramTranslationKey.GRID_HEADER_IDENTIFIER), headerClassName: 'datagrid-column', width: 150},
            {field: 'type', headerName: _transl(DiagramTranslationKey.GRID_HEADER_TYPE), headerClassName: 'datagrid-column', width: 180},
            {field: 'stereotype', valueGetter: stereotypeGetter, headerName: _transl(DiagramTranslationKey.GRID_HEADER_STEREOTYPE), headerClassName: 'datagrid-column', width: 180},
            {field: 'name', headerName: _transl(DiagramTranslationKey.GRID_HEADER_NAME), headerClassName: 'datagrid-column', minWidth: 280, flex: 1},
        ];
    }

}

const COLUMNS_GRID_ID = PersistentStateId.EXPORT_RELATIONSHIP_MATRIX_COLUMN_GRID;
const ROWS_GRID_ID = PersistentStateId.EXPORT_RELATIONSHIP_MATRIX_ROW_GRID;

enum ButtonId {
    ADD_ELEMENTS = "ADD_ELEMENTS",
    REMOVE_ELEMENT = "REMOVE_ELEMENT",
}

const elementsGridDivBorderColor = "lightgray";
const elementsGridDivBackgroundColor = "white";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        elementsGridDiv: {
            color: "gray",
            backgroundColor: elementsGridDivBackgroundColor,
            padding: theme.spacing(1),
        },
        settingsDiv: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
            border: "1px solid " + elementsGridDivBorderColor,
            backgroundColor: elementsGridDivBackgroundColor,
            width: "100%",
            height: "100%",
        },
        wizardElementSelectionDescription: {
            display: "flex",
            alignItems: "center",
        },
    })
);

interface RelationshipMatrixExportWizardProps {
    resetExport: () => void,
}

export default function RelationshipMatrixExportWizard(props: RelationshipMatrixExportWizardProps) {

    const classes = useStyles();

    const [rowElements, setRowElements] = useState<Array<ElementDto>>([]);
    const [columnElements, setColumnElements] = useState<Array<ElementDto>>([]);
    const [format, setFormat] = useState<FileFormat>(FileFormat.EXCEL);
    const [selectRowElementsDialogOpened, setSelectRowElementsDialogOpened] = useState<boolean>(false);
    const [selectColumnElementsDialogOpened, setSelectColumnElementsDialogOpened] = useState<boolean>(false);

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(
                _transl(ExportTranslationKey.RELATIONSHIP_MATRIX_STEP_ELEMENTS_IN_COLUMNS_LABEL),
                <ReorderIcon style={{transform: "rotate(90deg)"}} />,
                <div className={classes.wizardElementSelectionDescription}>
                    <div>{_transl(ExportTranslationKey.RELATIONSHIP_MATRIX_STEP_ELEMENTS_IN_COLUMNS_DESCRIPTION)}</div>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={() => ActionButtonUtils.clickOnImmediateButton(ButtonId.ADD_ELEMENTS, COLUMNS_GRID_ID)}
                            size="large">
                            <AddIcon/>
                        </IconButton>
                    </div>
                </div>,
                false,
                () => renderSelectColumnElementsStep(),
                () => canProceedFromSelectColumnElementsStep()),
            new WizardStep(
                _transl(ExportTranslationKey.RELATIONSHIP_MATRIX_STEP_ELEMENTS_IN_ROWS_LABEL),
                <ReorderIcon />,
                <div className={classes.wizardElementSelectionDescription}>
                    <div>{_transl(ExportTranslationKey.RELATIONSHIP_MATRIX_STEP_ELEMENTS_IN_ROWS_DESCRIPTION)}</div>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={() => ActionButtonUtils.clickOnImmediateButton(ButtonId.ADD_ELEMENTS, ROWS_GRID_ID)}
                            size="large">
                            <AddIcon/>
                        </IconButton>
                    </div>
                </div>,
                false,
                () => renderSelectRowElementsStep(),
                () => canProceedFromSelectRowElementsStep()),
            new WizardStep(
                _transl(ExportTranslationKey.RELATIONSHIP_MATRIX_STEP_OTHER_OPTIONS_LABEL),
                <SettingsIcon />,
                _transl(ExportTranslationKey.RELATIONSHIP_MATRIX_STEP_OTHER_OPTIONS_DESCRIPTION),
                false,
                () => renderSettingsStep(),
                () => canProceedFromSettingsStep())
        ];
    }

    function removeElements(elements: Array<ElementDto>, rowIdsToRemove: Array<String>) {
        return elements.filter(element => rowIdsToRemove.indexOf(element.identifier) === -1);
    }

    function addElements(elements: Array<ElementDto>, elementsToBeAdded: Array<ElementDto>) {
        const newElements = removeElements(elementsToBeAdded, elements.map(element => element.identifier));
        return [...elements, ...newElements];
    }

    function renderSelectElementsGrid(rows: Array<ElementDto>,
                                      persistentStateId: PersistentStateId,
                                      selectDialogOpened: boolean,
                                      setSelectDialogOpenedCallback: (opened: boolean) => void,
                                      onAddCallback: (rows: Array<ElementDto>) => void,
                                      onDeleteCallback: (rowIds: Array<String>) => void) {
        return <div className={classes.elementsGridDiv}>
            {selectDialogOpened && <ElementsPickDialog isOpened={selectDialogOpened}
                                                       isMultiSelection={true}
                                                       onElementsPicked={(items) => {
                                                           onAddCallback(items);
                                                           setTimeout(() => setSelectDialogOpenedCallback(false), 1);
                                                       }}
                                                       onDialogClosed={() => setSelectDialogOpenedCallback(false)}/>
            }
            <ExtGridWrapper
                columns={GridDef.getGridColDef()}
                rows={rows}
                rowCount={rows.length}
                getRowId={row => row.identifier}
                actions={[
                    GridAction.buttonBuilder(ButtonId.ADD_ELEMENTS, ActionButtonType.IMMEDIATE, _transl(ExportTranslationKey.RELATIONSHIP_MATRIX_GRID_BUTTON_ADD),
                        <AddIcon/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .onClick(() => setSelectDialogOpenedCallback(true))
                        .build(),
                    GridAction.buttonBuilder(ButtonId.REMOVE_ELEMENT, ActionButtonType.IMMEDIATE, _transl(ExportTranslationKey.RELATIONSHIP_MATRIX_GRID_BUTTON_REMOVE),
                        <DeleteIcon/>)
                        .onClick((selectedRowIds => onDeleteCallback(selectedRowIds as Array<string>)))
                        .build(),
                ]}
                peristentStateId={persistentStateId}
                resourceId={"elements"}
            />
        </div>
    }

    function renderSelectColumnElementsStep(): JSX.Element {
        const onDelete = (rowIds: Array<String>) => setColumnElements(removeElements(columnElements, rowIds));
        const onAdd = (rows: Array<ElementDto>) => setColumnElements(addElements(columnElements, rows));

        return renderSelectElementsGrid(
            columnElements,
            COLUMNS_GRID_ID,
            selectColumnElementsDialogOpened,
            (opened: boolean) => setSelectColumnElementsDialogOpened(opened),
            onAdd,
            onDelete);
    }

    function canProceedFromSelectColumnElementsStep(): boolean {
        return columnElements.length > 0;
    }

    function renderSelectRowElementsStep(): JSX.Element {
        const onDelete = (rowIds: Array<String>) => setRowElements(removeElements(rowElements, rowIds));
        const onAdd = (rows: Array<ElementDto>) => setRowElements(addElements(rowElements, rows));

        return renderSelectElementsGrid(
            rowElements,
            ROWS_GRID_ID,
            selectRowElementsDialogOpened,
            (opened: boolean) => setSelectRowElementsDialogOpened(opened),
            onAdd,
            onDelete);
    }

    function canProceedFromSelectRowElementsStep(): boolean {
        return rowElements.length > 0;
    }

    function renderSettingsStep(): JSX.Element {
        return <div className={classes.settingsDiv}>
            <div>
                <FormGroup>
                    <FormControl>
                        <InputLabel id="outputFormat">{_transl(ExportTranslationKey.RELATIONSHIP_MATRIX_STEP_OTHER_OPTIONS_OUTPUT_FORMAT)}</InputLabel>
                        <Select
                            style={{minWidth: "10em"}}
                            labelId="outputFormat"
                            id="outputFormatId"
                            value={format.getFileFormatType()}
                            onChange={(event) => setFormat(FileFormat.findByFileFormatType(event.target.value as FileFormatType))}
                        >
                            <MenuItem value={FileFormat.EXCEL.getFileFormatType()}>{`${FileFormat.EXCEL.getCaption()}  (${FileFormat.EXCEL.getExtension()})`}</MenuItem>
                            <MenuItem value={FileFormat.CSV.getFileFormatType()}>{`${FileFormat.CSV.getCaption()}  (${FileFormat.CSV.getExtension()})`}</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
            </div>
        </div>
    }

    function canProceedFromSettingsStep(): boolean {
        return format != null;
    }

    function doExport(): Promise<any> {
        const dto: IRelationshipMatrixExportDto = {
            columnElementIdentifiers: columnElements.map(element => element.identifier),
            rowElementIdentifiers: rowElements.map(element => element.identifier),
            format: format.getFileFormatType(),
        }
        return Api.exports.exportRelationshipMatrix(dto).toPromise();
    }

    return (
        <Wizard steps={getSteps()}
                lastStepLabel={_transl(ExportTranslationKey.RELATIONSHIP_MATRIX_LAST_STEP_LABEL)}
                lastStepButtonLabel={_transl(ExportTranslationKey.RELATIONSHIP_MATRIX_LAST_STEP_BUTTON_LABEL)}
                lastStepAction={() => doExport()}
                lastStepActionSuccessProcessor={(result) => {
                    const response = result as AjaxResponse;
                    const disposition = response.xhr.getResponseHeader('Content-Disposition') as string;
                    const fileName = disposition.substring(disposition?.indexOf("filename=") + 9);
                    const blob = new Blob([response.response]);

                    BlobUtils.saveBlob(blob, fileName);
                }}
                lastStepActionInProgressText={_transl(ExportTranslationKey.RELATIONSHIP_MATRIX_LAST_STEP_IN_PROGRESS_TEXT)}
                lastStepActionSuccessfulText={_transl(ExportTranslationKey.RELATIONSHIP_MATRIX_LAST_STEP_SUCCESSFUL_TEXT)}
                lastStepActionFailedText={_transl(ExportTranslationKey.RELATIONSHIP_MATRIX_LAST_STEP_FAILED_TEXT)}
                cancelWizard={props.resetExport}
                wizardGridMinHeight={"25em"}
        />
    );

}
