import {NavLink} from "react-router-dom";
import RouteDefinitionUtils from "../../../common/routedefinition/RouteDefinitionUtils";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {MenuItem, styled} from "@mui/material";
import React from "react";
import MenuDefinition from "../../../common/menudefinition/MenuDefinition";
import {_transl} from "../../../store/localization/TranslMessasge";


export interface AppBarMenuItemProps {
    menuDefinition: MenuDefinition;
    onClick: () => void;
}

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    ".active &": {
        color: "white",
    }
}));

const StyledListItemText = styled(ListItemText)(({theme}) => ({
    ".active &": {
        color: "white",
    }
}));

const StyledNavLink = styled(NavLink)(({theme}) => ({
    "&.active": {
        backgroundColor: theme.palette.primary.main,
    },
    "&:hover.active": {
        backgroundColor: theme.palette.primary.main,
    }
}));

const AppBarMenuItem = (props: AppBarMenuItemProps) => {
    const {menuDefinition, onClick} = props;
    return (
        <MenuItem component={StyledNavLink} to={RouteDefinitionUtils.resolvePath(menuDefinition.route)} onClick={onClick}>
            <StyledListItemIcon>{menuDefinition.icon}</StyledListItemIcon>
            <StyledListItemText>{_transl(menuDefinition.label)}</StyledListItemText>
        </MenuItem>
    );
};

export default AppBarMenuItem;