import {ColorDto} from "../Color";
import {FontDto} from "../DiagramsApi";
import {StyleDto} from "./StyleDto";

export class StyleDtoFactory {

    createStyleDto(lineColorR?: number | undefined, lineColorG?: number | undefined, lineColorB?: number | undefined,
                   lineColorA?: number | undefined, fillColorR?: number | undefined, fillColorG?: number | undefined,
                   fillColorB?: number | undefined, fillColorA?: number | undefined, fontColorR?: number | undefined,
                   fontColorG?: number | undefined, fontColorB?: number | undefined, fontColorA?: number | undefined,
                   fontName?: string | null | undefined, fontSize?: number | null | undefined,
                   fontStyles?: Array<string> | null | undefined, lineWidthNumber?: number | null): StyleDto | undefined {
        let lineColor: ColorDto | null;
        let fillColor: ColorDto | null;
        let fontColor: ColorDto | null;
        let font: FontDto | null;
        let lineWidth = lineWidthNumber ?? null;

        let styleDto: StyleDto | undefined;

        if (lineColorR && lineColorG && lineColorB) {
            lineColor = {r: lineColorR, g: lineColorG, b: lineColorB, a: lineColorA};
        } else {
            lineColor = null;
        }
        if (fillColorR && fillColorG && fillColorB) {
            fillColor = {r: fillColorR, g: fillColorG, b: fillColorB, a: fillColorA};
        } else {
            fillColor = null;
        }
        if (fontColorR && fontColorG && fontColorB) {
            fontColor = {r: fontColorR, g: fontColorG, b: fontColorB, a: fontColorA};
        } else {
            fontColor = null;
        }
        if (fontColor || fontName || fontSize || fontStyles) {
            font = {
                color: fontColor, name: fontName ? fontName : null, size: fontSize ? fontSize : null,
                styles: fontStyles ? fontStyles : null
            }
        } else {
            font = null;
        }
        if (lineColor || fillColor || font || lineWidth) {
            styleDto = {lineColor: lineColor, fillColor: fillColor, font: font, lineWidth: lineWidth};
        }
        return styleDto;
    }
}

