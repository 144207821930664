import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {UpdateMode} from "../StyleSettings";
import {Lock, SettingsBackupRestore, Tune} from "@mui/icons-material";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {StyleSettingsTranslationKey} from "./StyleSettingsTranslationKey";

interface UpdateModeToggleProps {
    id?: string,
    value: UpdateMode,
    hideKeepUnchangedMode?: boolean,
    onChange: (mode: UpdateMode) => void,
}

export function UpdateModeToggle(props: UpdateModeToggleProps) {
    const showKeepUnchangedMode = !(props.hideKeepUnchangedMode);

    function onModeChanged(event: any, mode: UpdateMode) {
        if (mode !== null) {
            props.onChange(mode);
        }
    }

    return (
        <ToggleButtonGroup id={props.id}
                           value={props.value}
                           exclusive
                           size={"small"}
                           onChange={onModeChanged}>
            <ToggleButton value={UpdateMode.USE_CUSTOM} title={_transl(StyleSettingsTranslationKey.USE_CUSTOM)}>
                <Tune/>
            </ToggleButton>
            <ToggleButton value={UpdateMode.RESET_TO_DEFAULTS} title={_transl(StyleSettingsTranslationKey.RESET_TO_DEFAULTS)}>
                <SettingsBackupRestore/>
            </ToggleButton>
            {showKeepUnchangedMode &&
                <ToggleButton value={UpdateMode.KEEP_UNCHANGED}
                              title={_transl(StyleSettingsTranslationKey.KEEP_UNCHANGED)}>
                    <Lock/>
                </ToggleButton>
            }
        </ToggleButtonGroup>
    );
}
