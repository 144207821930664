import LocaleContext from "../../../../common/LocaleContext";
import {useContext, useRef, useState} from "react";
import LanguageMenu from "./LanguageMenu";
import {Language} from "../../../../common/Language";
import translationsService from "../../../../common/apis/TranslationsService";
import {getSaveTranslationsAction} from "../../../../store/localization/TranslationReducer";
import {useDispatch} from "react-redux";
import LanguageButton from "./LanguageButton";


export interface LanguageSwitchButtonProps {

}

export default function LanguageSwitcher(props: LanguageSwitchButtonProps) {
    const {language, setLanguage} = useContext(LocaleContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const dispatch = useDispatch();

    function changeLanguage(lang: Language) {
        setMenuOpen(false);
        translationsService.getTranslations(lang)
            .then(translations => {
                dispatch(getSaveTranslationsAction(translations));
                setLanguage(lang, true);
            });
    }

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }

    return (
        <>
            <LanguageButton variant={"outlined"}
                            ref={anchorRef}
                            onClick={() => toggleMenu()}>
                {language}
            </LanguageButton>

            <LanguageMenu open={menuOpen}
                          language={language}
                          changeLanguage={(lang) => changeLanguage(lang)}
                          anchorRef={anchorRef.current}
                          onMenuClose={() => setMenuOpen(false)} />
        </>
    );
}