import Constants from "../Constants";
import Api from "../Api";
import {Dashboard} from "../../store/dashboard/Dashboard";
import {map} from "rxjs/operators";


export enum WidgetType {
    DIAGRAM = "DIAGRAM",
    EMPTY = "EMPTY",
    URL = "URL",
}

export type WidgetUpdateDto = {
    type: WidgetType,
    diagramId: string | null,
    url: string | null
}

export interface DashboardService {
    getDashboard(): Promise<Dashboard>,
    updateWidget(widget:WidgetUpdateDto): Promise<any>,
}

const DASHBOARD_URL: string = Constants.REST_API_URL + "/dashboard";
const GET_DASHBOARD_URL: string = DASHBOARD_URL + "/";
const UPDATE_DASHBOARD_URL: string = DASHBOARD_URL + "/main-widget";

class RxJsDashboardService implements DashboardService {

    getDashboard(): Promise<Dashboard> {
        const request = {
            url: GET_DASHBOARD_URL,
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as Dashboard)
            )
            .toPromise();
    }

    updateWidget(widget:WidgetUpdateDto): Promise<any> {
        const request = {
            url: UPDATE_DASHBOARD_URL,
            method: "PATCH",
            body: widget,
        };
        return Api.createAjax(request)
            .toPromise();
    }
}

const dashboardService = new RxJsDashboardService();
export default dashboardService;