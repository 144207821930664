import Constants from "../../../../../common/Constants";
import {PagePresetsCreateDto} from "./PagePresetsCreateDto";
import {PagePresetsDto, PageSettingsDto} from "./PagePresetsDto";
import {PagePresetsUpdateDto} from "./PagePresetsUpdateDto";
import Api from "../../../../../common/Api";
import {catchError, map} from "rxjs/operators";
import {of, throwError} from "rxjs";

const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/grid-presets";

export interface PagePresetsClient {

    findAllPresets(pageId: string): Promise<PagePresetsDto[]>;

    find(pageId: string, pagePresetsId: number): Promise<PagePresetsDto>;

    create(pageId: string, pagePresetsCreate: PagePresetsCreateDto): Promise<string>;

    update(pageId: string, pagePresetsId: number, pagePresetsUpdate: PagePresetsUpdateDto): Promise<any>;

    delete(pageId: string, pagePresetsId: number): Promise<any>;

    findDefaultPresets(pageId: string): Promise<PageSettingsDto | undefined>;

    setDefaultPresets(pageId: string, settings: PageSettingsDto): Promise<void>;

    deleteDefaultPresets(pageId: string): Promise<void>;
}

class RxJsPagePresetsClient implements PagePresetsClient {

    async findAllPresets(pageId: string): Promise<PagePresetsDto[]> {
        const request = {
            url: ENDPOINT_URL + "/" + pageId + "/presets/",
            method: "GET",
        };

        return Api.createAjax(request)
                .pipe(
                        map(response => (response.response || []) as PagePresetsDto[])
                ).toPromise();
    }

    async create(pageId: string, pagePresetsCreate: PagePresetsCreateDto): Promise<string> {
        return Api.createAjax({
            url: ENDPOINT_URL + "/" + pageId + "/presets/",
            method: "POST",
            body: pagePresetsCreate
        })
                .pipe(map(response => response.response as string))
                .toPromise();
    }

    async delete(pageId: string, pagePresetsId: number): Promise<any> {
        const request = {
            url: ENDPOINT_URL + "/" + pageId + "/presets/" + pagePresetsId,
            method: "DELETE",
        };

        return Api.createAjax(request).toPromise();
    }

    async find(pageId: string, pagePresetsId: number): Promise<PagePresetsDto> {
        const request = {
            url: ENDPOINT_URL + "/" + pageId + "/presets/" + pagePresetsId,
            method: "GET"
        };

        return Api.createAjax(request)
                .pipe(
                    map(response => (response.response) as PagePresetsDto)
                ).toPromise();
    }

    async update(pageId: string, pagePresetsId: number, pagePresetsUpdate: PagePresetsUpdateDto): Promise<any> {
        return Api.createAjax({
            url: ENDPOINT_URL + "/" + pageId + "/presets/" + pagePresetsId,
            method: "PUT",
            body: pagePresetsUpdate
        }).toPromise();
    }

    async findDefaultPresets(pageId: string): Promise<PageSettingsDto | undefined> {
        const request = {
            url: ENDPOINT_URL + "/" + pageId + "/default-presets",
            method: "GET"
        };
        return Api.createAjax(request)
                .pipe(
                    map(response => response.response as PageSettingsDto),
                    catchError(error => {
                        if (error.status === 404) {
                            return of(undefined);
                        } else {
                            return throwError(error);
                        }
                    })
                )
                .toPromise();
    }

    async setDefaultPresets(pageId: string, settings: PageSettingsDto): Promise<void> {
        const request = {
            url: ENDPOINT_URL + "/" + pageId + "/default-presets",
            method: "PUT",
            body: settings
        };
        return Api.createAjax(request)
            .pipe(
                map(response => undefined),
            )
            .toPromise();
    }

    async deleteDefaultPresets(pageId: string): Promise<void> {
        const request = {
            url: ENDPOINT_URL + "/" + pageId + "/default-presets",
            method: "DELETE"
        };
        return Api.createAjax(request)
            .pipe(
                map(response => undefined),
            )
            .toPromise();
    }

}

const pagePresetsClient = new RxJsPagePresetsClient();
export default pagePresetsClient;
