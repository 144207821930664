import EventManager, {Unsubscriber} from "../../event/EventManager";
import {
    EventType,
    IChartEvent, IModelUpdatedOnConnectionLabelUpdateEvent,
    IModelUpdatedOnNodeLabelUpdateEvent,
    IUndoableRedoableEvent,
    ModelBackupEvent,
    ModelUpdatedOnNodesAlignedEvent,
    UndoRedoType
} from "../../event/Event";
import {ModelUpdatedOnNodesPositionUpdatedEvent} from "../../event/ModelUpdatedOnNodesPositionUpdatedEvent";
import {StyleEventType} from "../../../diagram/editor/style/StyleEvents";

export default class UndoRedoManager {

    private eventManager: EventManager;
    private undoEvents: Array<IUndoableRedoableEvent>;
    private redoEvents: Array<IUndoableRedoableEvent>;
    private lastSavedEvent: IUndoableRedoableEvent | null;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_SAVED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_MOVED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_RESIZED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_MOVED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_CREATED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_REMOVED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE, this.handleModelUpdatedOnNodeLabelUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE, this.handleModelUpdatedOnConnectionLabelUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_CREATED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_REMOVED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_CREATED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_REMOVED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(StyleEventType.STYLES_UPDATED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelUpdatedOnNodesAlignedEvent>(EventType.MODEL_UPDATED_ON_NODES_ALIGNED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelUpdatedOnNodesPositionUpdatedEvent>(EventType.MODEL_UPDATED_ON_NODES_POSITION_UPDATED, this.onItemReceived.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelBackupEvent>(EventType.MODEL_UPDATED_ON_MODEL_BACKUP_LOADED, this.onItemReceived.bind(this)));

        this.undoEvents = [];
        this.redoEvents = [];
        this.lastSavedEvent = null;
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    // PUBLIC API

    public undo(event: any) {
        if (this.undoEvents.length > 0) {
            const event = this.undoEvents[this.undoEvents.length - 1];
            event.undo();
        }
    }

    public redo(event: any) {
        if (this.redoEvents.length > 0) {
            const event = this.redoEvents[0];
            event.redo();
        }
    }

    private handleChartEvent(event: IChartEvent) {
        this.lastSavedEvent = this.getLastUndoEvent();
    }

    private handleModelUpdatedOnNodeLabelUpdateEvent(event: IModelUpdatedOnNodeLabelUpdateEvent) {
        if (!event.isNodeCreateResult) {
            this.onItemReceived(event);
        }
    }

    private handleModelUpdatedOnConnectionLabelUpdateEvent(event: IModelUpdatedOnConnectionLabelUpdateEvent) {
        if (!event.isConnectionCreateResult) {
            this.onItemReceived(event);
        }
    }

    private onItemReceived(event: IUndoableRedoableEvent) {
        const undoRedoType = (event as IUndoableRedoableEvent).undoRedoType;
        if (undoRedoType == null) {
            this.undoEvents.push(event);
            if (this.redoEvents.length > 0) {
                this.redoEvents = [];
            }
            this.eventManager.publishEvent({
                type: EventType.UNDO_REDO_UPDATED,
                undoEvents: this.undoEvents.map(event => event.description),
                redoEvents: this.redoEvents.map(event => event.description),
                saveNeeded: this.containsUnsavedChanges(),
            })
        } else {
            if (undoRedoType === UndoRedoType.UNDO) {
                const event = this.undoEvents.pop() as IUndoableRedoableEvent;
                this.redoEvents.unshift(event);
            } else {
                const event = this.redoEvents.shift() as IUndoableRedoableEvent;
                this.undoEvents.push(event);
            }

            this.eventManager.publishEvent({
                type: EventType.UNDO_REDO_UPDATED,
                undoEvents: this.undoEvents.map(event => event.description),
                redoEvents: this.redoEvents.map(event => event.description),
                saveNeeded: this.containsUnsavedChanges(),
                undoRedoType: undoRedoType,
            });
        }
    }

    private containsUnsavedChanges() {
        if (this.lastSavedEvent) {
            const isSaved = this.undoEvents.length > 0 && this.getLastUndoEvent() === this.lastSavedEvent;
            return !isSaved;
        } else{
            return this.undoEvents.length > 0;
        }
    }

    private getLastUndoEvent(): IUndoableRedoableEvent | null {
        return this.undoEvents.length > 0 ? this.undoEvents[this.undoEvents.length - 1] : null;
    }
}
