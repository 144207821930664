import {IconButton, Tooltip} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import {Reply} from "@mui/icons-material";
import ChatCommentCreateFooter from "../ChatCommentCreateFooter";
import {UserDto} from "../../../../../common/apis/user/UserDto";
import {ChatDto} from "../../../../../common/apis/chat/ChatService";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {ChatTranslationKey} from "../ChatTranslationKey";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatReplyBar: {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            paddingLeft: '40px',
        },
    })
);

export interface IProps {
    chat: ChatDto;
    user: UserDto;
    onCommentCreated: (chatId: number) => void;
}

export default function ChatReplyBar(props: IProps) {
    const {chat, user, onCommentCreated} = props;

    const classes = useStyles();

    const [replyBarVisible, setReplyBarVisible] = useState(false);

    return (
        <div className={classes.chatReplyBar}>
            {!replyBarVisible &&
                <Tooltip title={_transl(ChatTranslationKey.REPLY_TO_CHAT)} placement={"top"}>
                    <IconButton
                        color={"primary"}
                        component="span"
                        onClick={() => setReplyBarVisible(true)}
                        size={"medium"}>
                        <Reply/>
                    </IconButton>
                </Tooltip>
            }
            {replyBarVisible &&
                <ChatCommentCreateFooter chatId={chat.id}
                                         user={user}
                                         chat={chat}
                                         handleClose={() => setReplyBarVisible(false)}
                                         onCommentCreated={(chatId) => onCommentCreated(chatId)}/>
            }
        </div>
    );
}