import * as d3 from "d3";
import EventManager, {Unsubscriber} from "../../event/EventManager";
import {EventType, IChartEvent, IDiagramGroupMoveRequestEvent} from "../../event/Event";
import RenderContext from "../context/RenderContext";
import {Point} from "../util/GeometryUtils";
import {ZoomTransform} from "d3";

export default class SvgElementZoomManager {

    public static readonly FIXED_ZOOM_SCALES = [
        0.2,
        0.4,
        0.6,
        0.8,
        1.0,
        1.2,
        1.4,
        1.6,
        1.8,
        2.0,
        2.5,
        3.0,
        3.5,
        4.0,
        5.0,
        6.0,
        7.0,
        8.0
    ];

    public static readonly DEFAULT_FIXED_SCALE_INDEX = 4;
    public static readonly MIN_SCALE = SvgElementZoomManager.FIXED_ZOOM_SCALES[0];
    public static readonly MAX_SCALE = SvgElementZoomManager.FIXED_ZOOM_SCALES[SvgElementZoomManager.FIXED_ZOOM_SCALES.length - 1];
    public static readonly MOUSE_WHEEL_ZOOM_SCALE_FACTOR = 0.1;
    public static readonly WHEEL_ZOOM_INCREMENT = 0.01;

    private renderContext?: RenderContext;
    private eventManager: EventManager;
    private zoom?: d3.ZoomBehavior<SVGSVGElement, any>;
    private zoomScaleIndex: number = SvgElementZoomManager.DEFAULT_FIXED_SCALE_INDEX;
    private scale: number = SvgElementZoomManager.DEFAULT_FIXED_SCALE_INDEX;
    private lastChartGroupTransform?: ZoomTransform;
    private lastZoomInOutStartPoint: Point = new Point(0, 0);

    private initialTransform?: any;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_ZOOM_IN_CLICKED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_ZOOM_OUT_CLICKED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_ZOOM_SCALE_TO_FIT_CLICKED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.DIAGRAM_GROUP_MOVE_BY_REQUEST, this.handleDiagramGroupMoveRequestEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.DIAGRAM_GROUP_MOVE_TO_REQUEST, this.handleDiagramGroupMoveRequestEvent.bind(this)));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    public init(renderContext: RenderContext) {
        this.renderContext = renderContext;

        if (!renderContext.isPreviewOrDashboard()) {
            this.zoom = d3.zoom();
            this.zoom.constrain(function(transform, viewportExtent, translateExtent) {
                return transform;
            });
            this.zoom.scaleExtent([SvgElementZoomManager.MIN_SCALE, SvgElementZoomManager.MAX_SCALE]);
            this.zoom.wheelDelta((event) => {
                let delta: number = event.deltaY;
                if (Math.abs(event.deltaY) > 100) {
                    // it is a mouse wheel so factor the delta to make it more smooth
                    delta = event.deltaY * SvgElementZoomManager.MOUSE_WHEEL_ZOOM_SCALE_FACTOR;
                }
                return -Math.floor(delta) * SvgElementZoomManager.WHEEL_ZOOM_INCREMENT;
            });
            this.zoom.filter(e => {
                const isMouseWheelEvent = e.type === 'wheel';
                const isAltKeyPressed = e.altKey === true;
                const isCtrlKeyPressed = e.ctrlKey === true;
                if (isMouseWheelEvent && (isAltKeyPressed || isCtrlKeyPressed)) {
                    // prevent browser window from zooming whole page
                    e.preventDefault();
                    return true;
                } else {
                    return false;
                }
            });

            const diagramGroupElem = renderContext.svgElementManager.getDiagramGroupSelection();

            const svgElem = renderContext.svgElementManager.getSvgSelection();
            this.zoom.on("zoom", (e) => {
                this.lastChartGroupTransform = e.transform as ZoomTransform;
                if (this.initialTransform == null) {
                    this.initialTransform = e.transform;
                }
                const oldScale = this.scale;
                if (e.transform.k !== this.scale) {
                    // scale changed
                    this.scale = e.transform.k;
                }

                diagramGroupElem?.attr('transform', this.lastChartGroupTransform?.toString());
                this.eventManager.publishEvent({
                    type: EventType.DIAGRAM_ZOOM_UPDATED,
                    actualZoom: this.scale,
                    previousZoom: oldScale,
                    minZoom: SvgElementZoomManager.MIN_SCALE,
                    maxZoom: SvgElementZoomManager.MAX_SCALE,
                    event: e});
            });
            if (this.lastChartGroupTransform) {
                diagramGroupElem?.attr("transform", this.lastChartGroupTransform?.toString());
            }
            svgElem.call(this.zoom as any);
        }
    }

    public getActualZoomTransform() {
        return this.lastChartGroupTransform;
    }

    private zoomIn(referencePoint?: [number, number]) {
        if (this.renderContext) {
            const svg = this.renderContext.svgElementManager.getSvg();

            this.zoomScaleIndex = this.detectScaleIndex(this.scale, true);

            if (this.zoomScaleIndex + 1 < SvgElementZoomManager.FIXED_ZOOM_SCALES.length) {
                this.zoomScaleIndex += 1;
                const scale = SvgElementZoomManager.FIXED_ZOOM_SCALES[this.zoomScaleIndex];
                this.zoom?.scaleTo(d3.select(svg as SVGSVGElement), scale, referencePoint);
            }
        }
    }

    private zoomOut(referencePoint?: [number, number]) {
        if (this.renderContext) {
            const svg = this.renderContext.svgElementManager.getSvg();

            this.zoomScaleIndex = this.detectScaleIndex(this.scale, false);

            if (this.zoomScaleIndex > 0) {
                this.zoomScaleIndex -= 1;
                const scale = SvgElementZoomManager.FIXED_ZOOM_SCALES[this.zoomScaleIndex];
                this.zoom?.scaleTo(d3.select(svg as SVGSVGElement), scale, referencePoint);
            }
        }
    }

    private detectScaleIndex(scale: number, roundToGreater: boolean) {
        for (let i = 0; i < SvgElementZoomManager.FIXED_ZOOM_SCALES.length; i++) {
            if (scale <= SvgElementZoomManager.FIXED_ZOOM_SCALES[i]) {
                if (scale === SvgElementZoomManager.FIXED_ZOOM_SCALES[i] || !roundToGreater) {
                    return i;
                } else {
                    return i - 1;
                }
            }
        }
        return SvgElementZoomManager.FIXED_ZOOM_SCALES.length - 1;
    }

    handleChartEvent(event: IChartEvent): void {
        if (event.type === EventType.CHART_ZOOM_IN_CLICKED) {
            this.zoomIn([this.lastZoomInOutStartPoint.x, this.lastZoomInOutStartPoint.y]);
        }
        if (event.type === EventType.CHART_ZOOM_OUT_CLICKED) {
            this.zoomOut([this.lastZoomInOutStartPoint.x, this.lastZoomInOutStartPoint.y]);
        }
        if (event.type === EventType.CHART_ZOOM_SCALE_TO_FIT_CLICKED) {
            this.scaleToInitialTransform();
        }
    }

    handleDiagramGroupMoveRequestEvent(event: IDiagramGroupMoveRequestEvent) {
        if (event.type === EventType.DIAGRAM_GROUP_MOVE_BY_REQUEST) {
            if (this.zoom) {
                this.lastZoomInOutStartPoint = new Point(event.left, event.top);
                const svgElemSelection = this.renderContext?.svgElementManager.getSvgSelection();
                const currentZoomTransform = d3.zoomTransform(svgElemSelection?.node() as SVGGElement);
                svgElemSelection?.call(this.zoom.transform, d3.zoomIdentity.translate(currentZoomTransform.x + event.left, currentZoomTransform.y + event.top).scale(currentZoomTransform.k));
            }
        }
        if (event.type === EventType.DIAGRAM_GROUP_MOVE_TO_REQUEST) {
            if (this.zoom) {
                this.lastZoomInOutStartPoint = new Point(event.left, event.top);
                const svgElemSelection = this.renderContext?.svgElementManager.getSvgSelection();
                const currentZoomTransform = d3.zoomTransform(svgElemSelection?.node() as SVGGElement);
                svgElemSelection?.call(this.zoom.transform, d3.zoomIdentity.translate(event.left, event.top).scale(currentZoomTransform.k));
            }
        }
    }

    private scaleToInitialTransform() {
        if (this.renderContext) {
            const svg = this.renderContext.svgElementManager.getSvg();
            const svgElem = d3.select(svg);
            this.zoom?.transform(svgElem, this.initialTransform, [0, 0])
        }
    }

    private scaleViewBoxToFit(canvasPadding: number, preserveAspectRatio: string, isPreview: boolean) {
        // TODO zoom behavior not set -> set viewBox explicitly
        if (this.renderContext) {
            const svg = this.renderContext.svgElementManager.getSvg();
            const diagramGroupElem = this.renderContext.svgElementManager.getDiagramGroupSelection();
            const bbox = diagramGroupElem?.node()?.getBBox();

            if (bbox && svg) {
                const svgSelection = d3.select(svg);
                svgSelection
                    .attr("preserveAspectRatio", preserveAspectRatio)
                    .attr("viewBox", `${bbox.x - canvasPadding} ${bbox.y - canvasPadding} ${bbox.width + (2 * canvasPadding)} ${bbox.height + (2 * canvasPadding)}`);
            }
            if (isPreview) {
                this.eventManager.publishEvent({
                    type: EventType.DIAGRAM_ZOOM_UPDATED,
                    actualZoom: 1,
                    previousZoom: 1,
                    minZoom: SvgElementZoomManager.MIN_SCALE,
                    maxZoom: SvgElementZoomManager.MAX_SCALE,
                    event: {}
                });
            }
        }
    }

    initialScaleOnPreview() {
        this.scaleViewBoxToFit(10, "xMidYMid meet", true);
    }

    initialScaleOnView() {
    }

    initialScaleOnEdit() {
    }

    initialScaleOnExport() {
        if (this.renderContext) {
            const svg = this.renderContext.svgElementManager.getSvg();

            const scaleDoubleSize = 2;
            this.zoom?.scaleBy(d3.select(svg as SVGSVGElement), scaleDoubleSize, [0, 0]);
        }
    }

    initialScaleOnDashboard() {
        this.scaleViewBoxToFit(30, "xMinYMin meet", false);
    }
}