import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React, {useState} from "react";
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IDiagramEditorApi from "../../../../../common/diagrameditor/api/IDiagramEditorApi";
import ElementTypeIcon from "../../../../../components/fields/ElementTypeIcon";
import {DiagramEditorUtils} from "../../../../../common/diagrameditor/util/DiagramEditorUtils";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import IDiagramApi from "../../../../../common/diagrameditor/api/IDiagramApi";
import {PARENT_CHILD_RELATIONSHIP_TYPES} from "../../../../../common/diagrameditor/manager/ModelManager";
import {ArchimateElement, ArchimateElementType} from "../../../../../common/archimate/ArchimateElement";
import {ArchimateRelationship, ArchimateRelationshipType} from "../../../../../common/archimate/ArchimateRelationship";
import {IDiagramNodeDto} from "../../../../../common/apis/diagram/IDiagramNodeDto";
import {NodeType} from "../../../../../common/apis/diagram/NodeType";
import {ObjectType} from "../../../../../common/apis/editor/ObjectType";
import {RelationshipDto} from "../../../../../common/apis/relationship/RelationshipDto";
import {ConnectableConceptType} from "../../../../../common/apis/relationship/ConnectableConceptType";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../DiagramTranslationKey";
import {RelationshipAclDto} from "../../../../../common/apis/relationship/RelationshipAclDto";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../../components/dialogs/DialogActions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconWrapper: {
            display: "flex",
            minWidth: "1em",
        },
        icon: {
            width: "1em",
            height: "1em",
            display: "inline-block",
            fontSize: "1.5em",
        },
        relationshipSelect: {
            minWidth: "20em",
            "& .MuiSelect-root": {
                paddingTop: "4px",
                paddingBottom: "4px",
            }
        },
        nodeLabel: {
            display: "flex",
            alignItems: "center",
        },
    }));

interface Props {
    parentCandidate: IDiagramNodeDto,
    nodesToConnect: Array<IDiagramNodeDto>,
    onConfirm: (event: any, createdRelationships: Array<RelationshipDto>) => void,
    onCancel: (event: any) => void,
    diagramEditorApi: IDiagramEditorApi,
    diagramApi: IDiagramApi,
}

const EMPTY_SELECT_VALUE = "-";

export default function NestingConnectionCreationDialog(props: Props) {

    const classes = useStyles();

    const {onCancel, onConfirm, parentCandidate, nodesToConnect, diagramEditorApi, diagramApi} = props;

    const [nodeIdToRelEnumMap, setNodeIdToRelEnumMap] = useState<{
        [elementId: string]: ArchimateRelationshipType | typeof EMPTY_SELECT_VALUE;
    }>(() => {
        const map: {
            [nodeId: string]: ArchimateRelationshipType | typeof EMPTY_SELECT_VALUE;
        } = {};
        nodesToConnect.forEach((node) => (map[node.elementIdentifier as string] = PARENT_CHILD_RELATIONSHIP_TYPES[0]));
        return map;
    });

    const createRelationshipChoiceText = nodesToConnect.length > 1 ?
        _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_CREATE_RELATIONSHIPS) : _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_CREATE_RELATIONSHIP);
    const doNotCreateRelationshipChoiceText = nodesToConnect.length > 1
        ? _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_DO_NOT_CREATE_RELATIONSHIPS) : _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_DO_NOT_CREATE_RELATIONSHIP);

    function getAvailableRelationships(parentCandidate: IDiagramNodeDto, childNode: IDiagramNodeDto) {
        return PARENT_CHILD_RELATIONSHIP_TYPES;
    }

    function createRelationships(callback: (relationships: Array<RelationshipDto>) => void) {
        const parentElementId = parentCandidate.elementIdentifier as string;

        const idsArray: ObjectType[] = [];
        Object.keys(nodeIdToRelEnumMap).forEach((id, index) => {
            if (nodeIdToRelEnumMap[id] !== EMPTY_SELECT_VALUE) {
                idsArray.push(ObjectType.RELATIONSHIP);
            }
        });
        diagramApi.generateIdentifiers(
            idsArray,
            (ids) => {
                const relationships: RelationshipDto[] = [];
                Object.keys(nodeIdToRelEnumMap).forEach((elementId, index) => {
                    if (nodeIdToRelEnumMap[elementId] !== EMPTY_SELECT_VALUE) {
                        const relationshipEnum = nodeIdToRelEnumMap[elementId] as ArchimateRelationshipType;
                        const relationship: RelationshipDto = {
                            identifier: ids.pop() as string,
                            type: ArchimateRelationship[relationshipEnum].standardNames[0],
                            source: {
                                conceptType: ConnectableConceptType.ELEMENT,
                                name: "",
                                identifier: relationshipEnum === ArchimateRelationshipType.SPECIALIZATION ? elementId : parentElementId,
                            },
                            target: {
                                conceptType: ConnectableConceptType.ELEMENT,
                                name: "",
                                identifier: relationshipEnum === ArchimateRelationshipType.SPECIALIZATION ? parentElementId : elementId,
                            },
                            acl: RelationshipAclDto.builder().build(),
                        }
                        relationships.push(relationship);
                    }
                });
                callback(relationships);
            },
            () => {
            })
    }

    function getNodeLabel(node: IDiagramNodeDto) {
        let label = diagramEditorApi.getNodeLabel(node) || `[${getNodeTypeName(node)}]`;
        if (label.length > 150) {
            label = label.substring(0, 150) + " ...";
        }
        return label;
    }

    function getNodeTypeName(node: IDiagramNodeDto) {
        return diagramEditorApi.getNodeTypeName(node);
    }

    function getNodeIcon(node: IDiagramNodeDto) {
        if (node.type === NodeType.ELEMENT) {
            const standardName = diagramEditorApi.getNodeElementTypeStandardName(node);
            return standardName ? <ElementTypeIcon name={standardName} isMenuIcon={false} clazz={classes.icon}/> :
                <span/>
        } else if (node.type === NodeType.CONTAINER) {
            return <ElementTypeIcon name={ArchimateElement[ArchimateElementType.GROUPING].standardName}
                                    isMenuIcon={false} clazz={classes.icon}/>
        } else {
            // LABEL
            if (DiagramEditorUtils.isDiagramReferenceNode(node)) {
                return <AccountTreeIcon/>
            }
        }
    }

    const handleSelectChange = (event: SelectChangeEvent<unknown>, nodeId: string) => {
        setNodeIdToRelEnumMap((prevMap) => ({
            ...prevMap,
            [nodeId]: event.target.value as ArchimateRelationshipType,
        }));
    };

    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle title={_transl(DiagramTranslationKey.NESTING_RELATIONSHIP_CREATE_RELATIONSHIP_TITLE)}
                         onDialogClosed={onCancel}/>
            <DialogContent>
                <Grid container marginTop={2}>
                    <Grid item xs={12}>
                            <span>{nodesToConnect.length > 1 ?
                                _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_NEW_RELATIONSHIP_ELEMENTS)
                                : _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_NEW_RELATIONSHIP_ELEMENT)}
                            </span>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{maxHeight: "20em", overflow: "auto"}}>
                            <table style={{borderSpacing: ".5em .5em", width: "100%"}}>
                                <tr>
                                    <th>{_transl(DiagramTranslationKey.NESTING_RELATIONSHIP_NESTED_ELEMENT)}</th>
                                    <th>{_transl(DiagramTranslationKey.NESTING_RELATIONSHIP_RELATIONSHIP)}</th>
                                </tr>
                                {nodesToConnect.map(node => {
                                        return <tr>
                                            <td>
                                                <span className={classes.nodeLabel}>
                                                    <span className={classes.iconWrapper}>
                                                        {getNodeIcon(node)}
                                                    </span>
                                                    {getNodeLabel(node)}
                                                </span>
                                            </td>
                                            <td>
                                                <Select
                                                    value={nodeIdToRelEnumMap[node.elementIdentifier as string]}
                                                    onChange={(event) => handleSelectChange(event, node.elementIdentifier as string)}
                                                    variant={"outlined"}
                                                    className={classes.relationshipSelect}
                                                    fullWidth={true}
                                                >
                                                    <MenuItem value={EMPTY_SELECT_VALUE}>{_transl(DiagramTranslationKey.NESTING_RELATIONSHIP_NONE)}</MenuItem>
                                                    {getAvailableRelationships(parentCandidate, node).map(rel => {
                                                        const relationship = ArchimateRelationship[rel];
                                                        return <MenuItem
                                                            value={rel}>{relationship.standardNames[0]}</MenuItem>
                                                    })
                                                    }
                                                </Select>
                                            </td>
                                        </tr>
                                    }
                                )}
                            </table>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    autoFocus variant={"contained"}
                    onClick={(e) => {
                        createRelationships((relationships: Array<RelationshipDto>) => onConfirm(e, relationships))
                    }}
                >
                    {createRelationshipChoiceText}
                </Button>
                <Button
                    onClick={(event) => onCancel(event)}
                    variant={"outlined"}
                >
                    {doNotCreateRelationshipChoiceText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
