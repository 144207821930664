import {LocalStorageLogoutActionStorage, LogoutActionStorage} from "./LogoutActionStorage";
import {LogoutAction} from "./LogoutAction";

export interface LogoutActionProvider {
    setLogoutInProgress(inProgress: boolean): void,
    isLogoutInProgress(): boolean,
    clear(): void,
}

class DefaultLogoutActionProvider implements LogoutActionProvider {

    private logoutAction: LogoutAction;

    constructor(private storage: LogoutActionStorage) {
        const storedLogoutAction = storage.load();
        this.logoutAction = storedLogoutAction || this.initialLogoutAction();
    }

    setLogoutInProgress(inProgress: boolean) {
        this.logoutAction.inProgress = true;
        this.storage.store(this.logoutAction);
    }

    isLogoutInProgress(): boolean {
        return this.logoutAction.inProgress;
    }

    clear() {
        this.storage.clear();
    }

    private initialLogoutAction(): LogoutAction {
        return {inProgress: false};
    }
}

const storage = new LocalStorageLogoutActionStorage();
export default new DefaultLogoutActionProvider(storage) as LogoutActionProvider;





