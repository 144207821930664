import React, { Component } from "react";
import SingleselectComboBox from "./SingleselectComboBox";
import ElementTypeIcon from "./ElementTypeIcon";
import {IElementTypeDto} from "../../common/apis/Elements";
import {ArchimateLayer, ArchimateLayerType} from "../../common/archimate/ArchiMateLayer";
import {ArchimateElement} from "../../common/archimate/ArchimateElement";
import {_transl} from "../../store/localization/TranslMessasge";

interface IState { }

interface IProps {
    options: IElementTypeDto[],
    selectedValue?: IElementTypeDto | null,
    id: string,
    label: string,
    handleOnChange: (changedValue: IElementTypeDto) => void
    error?: boolean,
    size?: "small" | "medium" | undefined,
    errorMessage?: string,
}

export default class SingleselectElementTypesComboBox extends Component<IProps, IState> {
    render() {
        const { options, selectedValue, id, label, handleOnChange, error, size, errorMessage } = this.props;
        const sortedOptions: IElementTypeDto[] = [...options].sort((a, b) => {
            const aLayerName = this.getLayerName(a.name);
            const bLayerName = this.getLayerName(b.name);
            if (aLayerName === bLayerName) {
                return a.code.localeCompare(b.code)
            } else {
                return aLayerName.localeCompare(bLayerName);
            }
        });

        return (
            <SingleselectComboBox options={sortedOptions}
                                  selectedValue={selectedValue}
                                  id={id}
                                  label={label}
                                  handleOnChange={handleOnChange}
                                  required={true}
                                  errorMessage={errorMessage}
                                  error={error}
                                  size={size}
                                  getRenderLabel={(value) => {
                                      const label = ArchimateElement.findByStandardName(value.name)?.visibleName || "";
                                      return {
                                          label: label,
                                          component: <span><ElementTypeIcon name={value.name}/> <span>{label}</span></span>
                                      }
                                  }}
                                  groupBy={(option) => this.getLayerName(option.name)}
            />
        )
    }

    getLayerName(elementStandardName: string) {
        const layerType = ArchimateElement.findByStandardName(elementStandardName)?.layerType || ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS;
        return _transl(ArchimateLayer[layerType].translationKey);
    }

}
