import Constants from "../Constants";
import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../Api";
import {ObjectType} from "./editor/ObjectType";

export class Editor {

    public static ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/repository";

    public static GENERATE_ID_URL: string = Editor.ENDPOINT_URL + ":generate-identifier";
    public static GENERATE_IDS_URL: string = Editor.ENDPOINT_URL + ":generate-identifiers";

    generateIdentifier(objectType: ObjectType): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Editor.GENERATE_ID_URL,
            method: "POST",
            body: `"${objectType}"`,
        });
    }

    generateIdentifiers(objectTypes: Array<ObjectType>): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Editor.GENERATE_IDS_URL,
            method: "POST",
            body: objectTypes,
        });
    }

    generateIdentifiersUsingCallbacks(objectTypes: Array<ObjectType>, successCallback: (ids: string[]) => void, errorCallback: (error: any) => void) {
        this.generateIdentifiers(objectTypes).subscribe({
            next: (response) => {
                successCallback(response.response);
            },
            error: (error) => {errorCallback(error)},
        });
    }

}
