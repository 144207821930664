import React, {useEffect, useState} from "react";
import {Paper} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import DiagramsFilter from "./DiagramsFilter";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import RenderMode from "./RenderMode";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import {DiagramsGrid} from "./DiagramsGrid";
import {GridPresets} from "../../../../components/grid/presets/GridPresets";
import userSettingsService from "../../../../common/apis/user/UserSettingsService";


const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            page: CommonCssStyles.getRootPageStyles(theme),
            headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
            controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
        })
);

export default function DiagramsPage() {

    const classes = useStyles();
    const filter = useSelector((state: IApplicationState) => state.pages.diagrams.filter.submittedFilter.lastFilter);

    const [gridPresets, setGridPresets] = useState<GridPresets>();
    const [presetsLoaded, setPresetsLoaded] = useState<boolean>(false);

    function loadPresets(): void {
        userSettingsService.getDiagramsGridSettings()
                .then(value => {
                    setGridPresets(value);
                    setPresetsLoaded(true);
                });
    }

    function saveGridPresets(presets: GridPresets): void {
        userSettingsService.updateDiagramsGridSettings(presets)
                .then(() => setGridPresets(presets))
    }

    useEffect(() => loadPresets(), []);

    return (
            <Paper className={classes.page}>
                <div className={classes.headerPageSegment}>
                    <Typography variant="h6">
                        {_transl(DiagramTranslationKey.DIAGRAMS_TITLE)}
                    </Typography>
                </div>
                <Divider/>
                <div className={classes.controlPageSegment}>
                    <DiagramsFilter renderMode={RenderMode.PAGE}/>
                </div>
                <Divider/>
                <div className={classes.controlPageSegment}>
                    {presetsLoaded &&
                        <DiagramsGrid
                            filter={filter}
                            presets={gridPresets}
                            onPresetsChanged={saveGridPresets}
                        />
                    }
                </div>
            </Paper>
    )
}
