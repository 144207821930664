export enum ModelsComparisonTranslationKey {
    PAGE_TITLE = "tools.modelsComparison.pageTitle",
    SELECT_FIRST_MODEL_FILE_STEP_LABEL = "tools.modelsComparison.step.firstFile.label",
    SELECT_FIRST_MODEL_FILE_STEP_DESCRIPTION = "tools.modelsComparison.step.firstFile.description",
    SELECT_SECOND_MODEL_FILE_STEP_LABEL = "tools.modelsComparison.step.secondFile.label",
    SELECT_SECOND_MODEL_FILE_STEP_DESCRIPTION = "tools.modelsComparison.step.secondFile.description",
    LAST_STEP_LABEL = "tools.modelsComparison.step.last.label",
    LAST_STEP_BUTTON_LABEL = "tools.modelsComparison.step.last.buttonLabel",
    LAST_STEP_ACTION_IN_PROGRESS_TEXT = "tools.modelsComparison.step.last.inProgressText",
    LAST_STEP_ACTION_SUCCESSFUL_TEXT = "tools.modelsComparison.step.last.successfulText",
    LAST_STEP_ACTION_FAILED_TEXT = "tools.modelsComparison.step.last.failedText",
}
