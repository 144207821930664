import {IDiagramNodeDto} from "../../../../../../../common/apis/diagram/IDiagramNodeDto";
import {ElementDto} from "../../../../../../../common/apis/element/ElementDto";
import {SubmodelExchangeDto} from "../dto/SubmodelExchangeDto";
import {VERSION} from "../SubmodelExchangeEncryptor";
import {SubmodelExchangeNodeDto} from "../dto/SubmodelExchangeNodeDto";
import {SubmodelExchangeNodeDtoBuilder} from "./SubmodelExchangeNodeDtoBuilder";

export class SubmodelExchangeDtoBuilder {

    private submodelExchangeNodeDtoBuilder: SubmodelExchangeNodeDtoBuilder;

    constructor() {
        this.submodelExchangeNodeDtoBuilder = new SubmodelExchangeNodeDtoBuilder();
    }

    buildSubmodelExchangeDto(inputNodes: IDiagramNodeDto[], diagramId: string, url: string, inputElements: ElementDto[]): SubmodelExchangeDto {
        const elementDtoMap: { [key: string]: ElementDto } = {};
        for (const element of inputElements) {
            elementDtoMap[element.identifier] = element;
        }
        const submodelExchangeNodes: SubmodelExchangeNodeDto[] = inputNodes.map((node) => {
            const element = elementDtoMap[node.elementIdentifier!];
            return this.submodelExchangeNodeDtoBuilder.buildSubmodelExchangeNodeDto(node, element);
        });

        return {version: VERSION, diagramId: diagramId, url: url, nodes: submodelExchangeNodes}
    }
}

