import React, {Dispatch} from "react";
import RenderMode from "../common/diagrameditor/context/RenderMode";
import DiagramEditorComponent from "../components/diagrameditor/DiagramEditorComponent";
import {IMode} from "../common/diagrameditor/model/IMode";
import {Observable} from "rxjs";
import {IModelDto} from "../common/apis/model/IModelDto";
import {connect} from "react-redux";
import diagramsApi from "../common/apis/DiagramsApi";
import {createDiagramDefaultsUpdatedAction} from "../diagram/defaults/store/DiagramDefaultsReducer";

declare global {
    interface Window {
        diagramExportViewPage: DiagramExportViewPage | null;
    }
}

export interface DiagramExportViewPageProps {
    loadDiagramDefaults: () => void,
}

export interface DiagramExportViewPageState {
    model?: IModelDto;
}

class DiagramExportViewPage extends React.Component<DiagramExportViewPageProps, DiagramExportViewPageState> {

    constructor(props: DiagramExportViewPageProps) {
        super(props);
        this.state = {
            model: undefined
        };
    }

    componentDidMount() {
        window.diagramExportViewPage = this;
        this.props.loadDiagramDefaults();
    }

    setModel(model: IModelDto) {
        this.setState({model: model});
    }

    fetchDiagram(): Observable<IModelDto> {
        const model = this.state.model;
        return new Observable((subscriber) => {
            if (model) {
                subscriber.next(model);
                subscriber.complete();
            }
        })
    }

    render() {
        const mode: IMode = {
            mode: RenderMode.EXPORT,
        };

        if (this.state.model) {
            return (
                <DiagramEditorComponent diagramId={this.state.model.diagrams[0].diagramInfo.identifier}
                                        mode={mode}
                                        transparentPaper={true}
                                        fetchDiagram={() => this.fetchDiagram()} />
            );
        } else {
            return (
                <></>
            );
        }
    }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    loadDiagramDefaults: async () => {
        const diagramDefaults = await diagramsApi.getDiagramDefaults();
        dispatch(createDiagramDefaultsUpdatedAction(diagramDefaults));
    },
});

export default connect(null, mapDispatchToProps)(DiagramExportViewPage);

