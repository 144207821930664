import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import {AutoFixHigh} from "@mui/icons-material";

const style = {
    position: "absolute",
    bottom: 16,
    right: 8,
};

const buttonStyle = {
    ...style,
    opacity: 0.3,
    ":hover": {
        opacity: 1.0
    },
};

export interface GenerateDescriptionButtonProps {
    tooltip: string;
    onClick: () => void;
    inProgress?: boolean;
}

export default function GenerateDescriptionButton(props: GenerateDescriptionButtonProps) {

    const {tooltip, onClick, inProgress} = props;

    if (inProgress) {
        return (
            <CircularProgress sx={style} size={28}/>
        );
    } else {
        return (
            <Tooltip title={tooltip}>
                <IconButton color={"default"}
                     size={"small"}
                     onClick={onClick}
                     sx={buttonStyle}>
                    <AutoFixHigh fontSize={"small"}/>
                </IconButton>
            </Tooltip>
        );
    }
}