import TreeItem, {TreeItemProps} from "@mui/lab/TreeItem";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: any;
    labelIconColor?: string;
    labelIconBgColor?: string,
    labelInfo?: string;
    labelText: any;
    labelTitle?: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.primary,
            width: "fit-content"
        },
        content: {
            '$expanded > &': {
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
        group: {
            marginLeft: 0,
            '& $content': {
                paddingLeft: theme.spacing(1.5),
            },
            '& $group': {
                paddingLeft: theme.spacing(2),
            },
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.1, 0),
            userSelect: "none",
        },
        labelIcon: {
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontSize: ".8rem;",
            letterSpacing: "0.00938em",
            flexGrow: 1,
            whiteSpace: "nowrap",
        },
    }),
);

export default function ModelTreeViewItem(props: StyledTreeItemProps) {
    const classes = useTreeItemStyles();
    const { labelText, labelTitle, labelIcon, labelIconColor, labelIconBgColor, labelInfo, color, bgColor, ...other } = props;

    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <span className={classes.labelIcon} style={{color: labelIconColor || "inherit", backgroundColor: labelIconBgColor, display: "flex"}}>
                        {labelIcon}
                    </span>
                    <span className={classes.labelText} title={labelTitle || labelText}>
                        {labelText}
                    </span>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}
