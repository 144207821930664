import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../../../../../store/Store";
import React from "react";
import MultiselectComboBox from "../../../../../../../../components/fields/MultiselectComboBox";
import {_transl} from "../../../../../../../../store/localization/TranslMessasge";
import {HierarchyTreeTranslationKey} from "../../../../HierarchyTreeTranslationKey";
import {DataTypeSubformProps} from "../DataTypeSubformProps";
import {ILabelDto} from "../../../../../../../../common/apis/label/ILabelDto";
import {LabelsTreeDataDto} from "../../../../service/HierarchyTreeDataDto";
import LabelViewModeSelect from "./LabelViewModeSelect";
import {LabelTreeViewMode} from "../../../../service/LabelTreeViewMode";
import Grid from "../../../../../../../../components/dialogs/Grid";

export default function LabelsSubform({data, onChange, validationResult}: DataTypeSubformProps) {
    const publicLabels = useSelector((state: IApplicationState) => {
        const labels = state.pages.common.options.labels.resource;
        return labels.filter(label => label.publicLabel);
    });

    const labelsTreeData = data as LabelsTreeDataDto;
    const viewMode = labelsTreeData.viewMode;
    const labels = labelsTreeData.labels.map(code => getLabelFromCode(code));
    const error = validationResult && validationResult.errors.length > 0 && labels.length === 0;

    function getLabelFromCode(labelCode: string): ILabelDto {
        return publicLabels.filter((label) => label.code === labelCode)[0];
    }

    function onLabelsAssigned(labels: ILabelDto[]): void {
        const labelCodes: string[] = labels.map((label) => label.code);
        const treeData = {...labelsTreeData, labels: labelCodes};
        onChange(treeData);
    }

    function onViewModeChanged(viewMode: LabelTreeViewMode): void {
        const treeData = {...labelsTreeData, viewMode: viewMode};
        onChange(treeData);
    }

    return (
        <>
            <Grid item xs={12} sm={6}>
                <MultiselectComboBox
                    label={_transl(HierarchyTreeTranslationKey.SUBFORM_LABELS)}
                    id="labels-field"
                    options={publicLabels}
                    selectedValues={labels}
                    getRenderLabel={(value) => value.name}
                    handleOnChange={onLabelsAssigned}
                    errorMessage={error ? _transl(validationResult.errors[0].errorMessage) : undefined}
                    required={true}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <LabelViewModeSelect
                    value={viewMode}
                    onChange={onViewModeChanged}
                />
            </Grid>
        </>
    );
}
