import React, {useCallback, useEffect} from "react";
import {IApplicationState} from "../../../../store/Store";
import {useDispatch, useSelector} from "react-redux";
import {getGridPersistentStateResetAction, PersistentStateId} from "../../../../store/common/Grid";
import {IFilter} from "../../../../store/elements/Elements";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import ElementsGrid, {ElementsGridType} from "./ElementsGrid";
import {GridPresets} from "../../../../components/grid/presets/GridPresets";

interface IProps {
    onSelectionChanged: (rowIds: string[], rows: ElementDto[]) => void;
    isMultiSelection: boolean;

    refetchData: () => void;
    gridPresets?: GridPresets;
    onGridPresetsChanged?: (gridPresets: GridPresets) => void;
}

export default function ElementsPickGrid({ onSelectionChanged, isMultiSelection, gridPresets, onGridPresetsChanged, refetchData }: IProps) {

    const filter = useSelector((application: IApplicationState) => application.pages.elements.dialogFilter.submittedFilter.lastFilter as IFilter);
    const dispatch = useDispatch();
    const resetGridPersistentState = useCallback(() => {
        dispatch(getGridPersistentStateResetAction(PersistentStateId.ELEMENTS_PICK_GRID));
    }, [dispatch]);

    useEffect(() => {
        return resetGridPersistentState();
    }, [resetGridPersistentState]);

    return (
        <ElementsGrid filter={filter}
                      elementsGridType={ElementsGridType.PICKER}
                      persistentStateId={PersistentStateId.ELEMENTS_PICK_GRID}
                      refetchData={refetchData}
                      gridPresets={gridPresets}
                      onGridPresetsChanged={onGridPresetsChanged}
                      bulkActionsOpenedPermanently={isMultiSelection}
                      onSelectionChanged={(rowIds, rows) => onSelectionChanged(rowIds as string[], rows as ElementDto[])} />
    )

}
