import {GridColDef} from "@mui/x-data-grid";
import { TranslationFunction } from "../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../../diagrams/DiagramTranslationKey";


export function createValidateModelGridColDef(_transl: TranslationFunction): GridColDef[] {
    return [
        {
            field: 'identifier',
            headerName: _transl(DiagramTranslationKey.GRID_HEADER_IDENTIFIER),
            headerClassName: 'datagrid-column',
            width: 200
        },
        {
            field: 'name',
            headerName: _transl(DiagramTranslationKey.GRID_HEADER_NAME),
            headerClassName: 'datagrid-column',
            width: 600
        },
    ];
}