import dayjs from "dayjs";

export class DateFormatter {
    static formatDate(date: Date | string | null | undefined): string | null {
        if (!date) {
            return null;
        }
        return dayjs(date).format("L");
    }

    static formatDateTime(date: Date | string | null | undefined) {
        if (!date) {
            return null;
        }
        return dayjs(date).format("L LT");
    }
}