import {Action, ActionCreator, Reducer} from "redux";
import {UserDto} from "../common/apis/user/UserDto";

export enum UserActionType {
    GET_USER_SUCCEEDED = "ACTION/PROFILE/GET_USER_IN_SUCCEEDED",
}

export interface IProfileAction extends Action<UserActionType> {
    type: UserActionType;
    user: UserDto;
}

export const getFetchUserSucceededAction: ActionCreator<IProfileAction> = (user: UserDto) => {
    return {
        type: UserActionType.GET_USER_SUCCEEDED,
        user: user
    }};

export interface IUserState {
    userData?: UserDto,
}

// Reducer

export const userReducer: Reducer<IUserState, IProfileAction> = (
    state = {},
    action
) => {
    switch(action.type) {
        case UserActionType.GET_USER_SUCCEEDED:
            return {
                userData: action.user,
            }
    }
    return state;
};
