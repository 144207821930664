import {Point} from "../../../../../common/diagrameditor/util/GeometryUtils";
import {Event} from "../../../../../common/event/Event";
import {SubmodelExchangeNodeDto} from "./submodelexchange/dto/SubmodelExchangeNodeDto";
import {IDiagramNodeDto} from "../../../../../common/apis/diagram/IDiagramNodeDto";

export enum ClipboardEventType {
    CREATE_ITEMS_FROM_CLIPBOARD = "CREATE_ITEMS_FROM_CLIPBOARD",
    PASTE_ITEMS_FROM_CLIPBOARD = "PASTE_ITEMS_FROM_CLIPBOARD",
    PASTE_ITEMS_FROM_CLIPBOARD_DUPLICATES_WARNING = "PASTE_ITEMS_FROM_CLIPBOARD_DUPLICATES_WARNING",
    COPY_ITEMS_TO_CLIPBOARD = "COPY_ITEMS_TO_CLIPBOARD",
}

export interface CreateItemsFromClipboardEvent extends Event {
    type: ClipboardEventType.CREATE_ITEMS_FROM_CLIPBOARD,
    itemsInfo: ItemsInfo;
}

export interface PasteItemsFromClipboardEvent extends Event {
    type: ClipboardEventType.PASTE_ITEMS_FROM_CLIPBOARD,
    itemsInfo: ItemsInfo;
}

export interface PasteItemsFromClipboardDuplicatesEvent extends Event {
    type: ClipboardEventType.PASTE_ITEMS_FROM_CLIPBOARD_DUPLICATES_WARNING,
    itemsInfo: ItemsInfo;
}

export interface ItemsInfo {
    nodes: SubmodelExchangeNodeDto[],
    sameDiagram: boolean,
    sameUrl: boolean,
    startPoint: Point;
}

export interface CopyItemsToClipboardEvent extends Event {
    type: ClipboardEventType.COPY_ITEMS_TO_CLIPBOARD,
    nodes: IDiagramNodeDto[],
}
