import React, {useEffect, useRef, useState} from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from '@mui/icons-material/Create';
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../../components/grid/GridAction";
import {PersistentStateId} from "../../../../../store/common/Grid";
import ConfirmationDialog from "../../../../../components/dialogs/ConfirmationDialog";
import CreateLabelDialog from "../CreateLabelDialog";
import UpdateLabelDialog from "../UpdateLabelDialog";
import {LabelsTranslationKey} from "../LabelsTranslationKey";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {UserDto} from "../../../../../common/apis/user/UserDto";
import constructLabelsController, {LabelsController} from "../controller/LabelsController";
import {ILabelDto} from "../../../../../common/apis/label/ILabelDto";
import {ButtonId} from "../ButtonId";
import {FetchableResourceType, getResourceFetchAction} from "../../../../../store/common/FetchableResource";
import {useDispatch, useSelector} from "react-redux";
import {IApplicationState} from "../../../../../store/Store";
import Snackbar from "../../snackbar/Snackbar";
import {createLabelsGridColDef} from "./LabelsGridColDef";
import labelsService from "../service/LabelsService";
import {LabelErrorCode} from "../service/LabelErrorCode";
import {ValidationError} from "../../../../../common/ValidationError";
import ExtGridWrapper from "../../../../../components/grid/ExtGridWrapper";

export default function LabelsGrid() {

    const dispatch = useDispatch();
    const labelController = useRef<LabelsController>(constructLabelsController(labelsService));
    const user = useSelector((state: IApplicationState) => state.user.userData as UserDto);

    const [labels, setLabels] = useState<ILabelDto[]>([]);
    const [createLabelsDialogIsOpen, setCreateLabelsDialogIsOpen] = useState<boolean>(false);
    const [changeLabelsDialogIsOpen, setChangeLabelsDialogIsOpen] = useState<boolean>(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [selectedLabel, setSelectedLabel] = useState<ILabelDto | null>(null);
    const [reload, setReload] = useState<number>(0);

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            try {
                const allLabels = await labelController.current.getAll();
                if (!isUnmounted) {
                    setLabels(allLabels);
                    dispatch(getResourceFetchAction(FetchableResourceType.COMMON_LABEL_OPTIONS));
                }
            } catch (error) {
                Snackbar.error(_transl(LabelsTranslationKey.NOT_FETCHED));
            }
        })();
        return () => {
            isUnmounted = true;
        }
    }, [reload, labelController, dispatch]);

    function hideDialog() {
        setChangeLabelsDialogIsOpen(false);
        setCreateLabelsDialogIsOpen(false);
    }

    function reloadLabels() {
        setReload(reload + 1);
    }

    async function deleteLabel() {
        if (selectedLabel !== undefined && selectedLabel !== null) {
            labelController.current.delete(selectedLabel.code)
                .then(() => {
                    reloadLabels();
                    Snackbar.success(_transl(LabelsTranslationKey.DELETE_SUCCEEDED));
                })
                .catch((err) => {
                    if (err instanceof ValidationError) {
                        if (err.error.code === LabelErrorCode.DIAGRAM_IS_USING_LABEL) {
                            Snackbar.warning(_transl(LabelsTranslationKey.DIAGRAM_IS_USING_LABEL));
                        } else if (err.error.code === LabelErrorCode.ELEMENT_IS_USING_LABEL) {
                            Snackbar.warning(_transl(LabelsTranslationKey.ELEMENT_IS_USING_LABEL));
                        } else {
                            Snackbar.error(_transl(LabelsTranslationKey.DELETE_FAILED));
                        }
                    } else {
                        Snackbar.error(_transl(LabelsTranslationKey.DELETE_FAILED));
                    }
                });
        }
        setIsConfirmationDialogOpen(false);
    }

    return (
        <React.Fragment>
            <ConfirmationDialog open={isConfirmationDialogOpen}
                                title={_transl(LabelsTranslationKey.DELETE_TITLE)}
                                confirmationText={_transl(LabelsTranslationKey.DELETE_CONFIRMATION)}
                                onConfirm={() => deleteLabel()}
                                onReject={() => setIsConfirmationDialogOpen(false)}
            />
            {createLabelsDialogIsOpen &&
                <CreateLabelDialog onClosed={() => hideDialog()}
                                   onLabelCreated={() => reloadLabels()}

                />}
            {selectedLabel && changeLabelsDialogIsOpen &&
                <UpdateLabelDialog onClosed={() => hideDialog()}
                                   label={selectedLabel}
                                   onPropertyUpdate={() => reloadLabels()}
                />}
            <ExtGridWrapper
                rows={labels}
                rowCount={labels.length}
                columns={createLabelsGridColDef(_transl)}
                getRowId={row => row.code}
                actions={[
                    GridAction.buttonBuilder(ButtonId.ITEMS_ADD_LABELS_BUTTON, ActionButtonType.IMMEDIATE, _transl(LabelsTranslationKey.ADD_BUTTON),
                        <AddIcon/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .onClick((_, selectedLabels) => {
                            setSelectedLabel(selectedLabels[0]);
                            setCreateLabelsDialogIsOpen(true);
                        }).build(),
                    GridAction.buttonBuilder(ButtonId.ITEMS_UPDATE_LABELS_BUTTON, ActionButtonType.IMMEDIATE, _transl(LabelsTranslationKey.UPDATE_BUTTON),
                        <CreateIcon/>)
                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                        .onClick((_, selectedLabels) => {
                            setSelectedLabel(selectedLabels[0]);
                            setChangeLabelsDialogIsOpen(true);
                        })
                        .isEnabled((selectedLabels) => selectedLabels.length === 1 && labelController.current.canUpdateLabel(user.userAcl, selectedLabels[0]))
                        .build(),
                    GridAction.buttonBuilder(ButtonId.ITEMS_DELETE_LABELS_BUTTON, ActionButtonType.IMMEDIATE, _transl(LabelsTranslationKey.DELETE_BUTTON),
                        <DeleteIcon/>)
                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                        .onClick((_, selectedLabels) => {
                            setSelectedLabel(selectedLabels[0]);
                            setIsConfirmationDialogOpen(true);
                        })
                        .isEnabled((selectedLabels) => selectedLabels.length === 1 && labelController.current.canRemoveLabel(user.userAcl, selectedLabels[0]))
                        .build(),
                ]}
                peristentStateId={PersistentStateId.LABELS_PAGE_GRID}
                resourceId={"labels"}
            />
        </React.Fragment>
    );
}

