import {ArchimateLayerType} from "../../../archimate/ArchiMateLayer";
import {ArchimateElement, ArchimateElementType} from "../../../archimate/ArchimateElement";
import {ArchimateRelationship, ArchimateRelationshipType} from "../../../archimate/ArchimateRelationship";
import {IDiagramConnectionDto} from "../../../apis/diagram/IDiagramConnectionDto";
import {RelationshipDto} from "../../../apis/relationship/RelationshipDto";
import {ElementDto} from "../../../apis/element/ElementDto";
import {IGraphDto} from "../../../apis/model/IGraphDto";
import CacheLocator from "./CacheLocator";
import {ParentNodePosition} from "./ParentNodePosition";
import {Model, DiagramNode, Diagram} from "../../model/Model";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";

export default class ModelAccessor {

    private initialModel?: Model;
    private model?: Model;

    private cacheLocator?: CacheLocator;

    constructor(initialModel: Model, cacheLocator: CacheLocator) {
        this.reinitModel(initialModel, cacheLocator);
    }

    public reinitModel(initialModel: Model, cacheLocator: CacheLocator) {
        this.initialModel = JSON.parse(JSON.stringify(initialModel));
        this.model = initialModel;
        this.cacheLocator = cacheLocator;
        this.initCaches();
    }

    private initCaches() {
        for (const node of this.getTopLevelDiagramNodes()) {
            this.initCachesForNode(node, undefined);
        }

        for (const connection of this.getDiagramConnections()) {
            this.initCachesForConnection(connection);
        }

        for (const element of this.getElements()) {
            this.initCachesForElement(element);
        }

        for (const relationship of this.getRelationships()) {
            this.initCachesForRelationship(relationship);
        }
    }

    private initCachesForNode(node: DiagramNode, parentNode: DiagramNode | undefined) {
        this.cacheLocator!.getNodeIdToNodeCache().set(node.identifier, node);
        this.cacheLocator!.getNodeIdToParentNodeCache().set(node.identifier, parentNode);
        this.cacheLocator!.getNodeIdToChildNodesCache().set(node.identifier, this.computeChildNodes(node));
        if (node.childNodes) {
            for (const childNode of node.childNodes) {
                this.initCachesForNode(childNode, node);
            }
        }
    }

    private initCachesForElement(element: ElementDto) {
        this.cacheLocator!.getElementIdToElementCache().set(element.identifier, element);
    }

    private initCachesForConnection(connection: IDiagramConnectionDto) {
        this.cacheLocator!.getConnectionIdToConnectionCache().set(connection.identifier, connection);
    }

    private initCachesForRelationship(relationship: RelationshipDto) {
        this.cacheLocator!.getRelationshipIdToRelationshipCache().set(relationship.identifier, relationship);
    }

    /*
        PUBLIC API
    */

    getModel() {
        return this.model!;
    }

    isInitialised() {
        return this.initialModel != null;
    }

    getDiagram(): Diagram {
        return this.model!.diagrams[0];
    }

    getDiagrams(): Array<Diagram> {
        return this.model!.diagrams;
    }

    getGraph(): IGraphDto {
        return this.model!.graph;
    }

    getElements(): Array<ElementDto> {
        return this.getGraph().elements;
    }

    getRelationships(): Array<RelationshipDto> {
        return this.getGraph().relationships;
    }

    getTopLevelDiagramNodes(): Array<DiagramNode> {
        return this.getDiagram().nodes;
    }

    getAllNodes(): Array<DiagramNode> {
        return this.getTopLevelDiagramNodes()
            .flatMap(node => this.getChildNodesInclusive(node));
    }

    computeDiagramNodes(): Array<DiagramNode> {
        return this.getDiagram().nodes.flatMap(node => this.computeChildNodesInclusive(node));
    }

    private computeChildNodesInclusive(node: DiagramNode): Array<DiagramNode> {
        const nodes = [node];
        if (node.childNodes != null && node.childNodes.length > 0) {
            nodes.push(...node.childNodes.flatMap(childNode => [...this.computeChildNodesInclusive(childNode)]));
        }
        return nodes;
    }

    private computeChildNodes(node: DiagramNode): Array<DiagramNode> {
        return this.computeChildNodesInclusive(node)
            .filter(child => child.identifier !== node.identifier);
    }

    getChildNodesInclusive(node: DiagramNode) : Array<DiagramNode> {
        const childNodes = this.cacheLocator!.getNodeIdToChildNodesCache().get(node.identifier) || [];
        return [node, ...childNodes];
    }

    getParentNode(node: DiagramNode): DiagramNode | undefined {
        return this.cacheLocator!.getNodeIdToParentNodeCache().get(node.identifier);
    }

    isRelationshipUsedInAnyDiagram(relationshipId: string) {
        return this.getDiagramConnections()
            .filter(connection => connection.relationshipIdentifier === relationshipId)
            .length > 0;
    }

    isElementUsedInAnyDiagram(elementId: string) {
        return this.computeDiagramNodes()
            .filter(node => node.elementIdentifier === elementId)
            .length > 0;
    }

    getDiagramConnections(): Array<IDiagramConnectionDto> {
        return this.getDiagram().connections;
    }

    getDiagramConnectionById(id: string): IDiagramConnectionDto {
        return this.cacheLocator!.getConnectionIdToConnectionCache().get(id) as IDiagramConnectionDto;
    }

    getElementById(id: string) {
        return this.cacheLocator!.getElementIdToElementCache().get(id) as ElementDto;
    }

    getRelationshipById(id: string | undefined) {
        let relationship = null;
        if (id) {
            relationship = this.cacheLocator!.getRelationshipIdToRelationshipCache().get(id) as RelationshipDto;
        }
        return relationship;
    }

    getRelationshipTypeByReationshipId(relationshipId: string | undefined) {
        let type: ArchimateRelationshipType | null = null;
        const relationship = this.getRelationshipById(relationshipId);
        if (relationship) {
            const archimateRelationship = ArchimateRelationship.findByStandardName(relationship.type);
            if (archimateRelationship != null && archimateRelationship.relationshipType != null) {
                type = archimateRelationship.relationshipType;
            }
        }
        return type;
    }

    getDiagramNodeById(id: string) {
        return this.cacheLocator!.getNodeIdToNodeCache().get(id) as DiagramNode;
    }

    getDiagramNodeConnectionsIncludeNodeChildren(node: DiagramNode) {
        const nodes = this.computeChildNodesInclusive(node);
        const nodesConnections = nodes.flatMap(node => this.getNodeConnections(node));

        const connections = new Array<IDiagramConnectionDto>();
        nodesConnections.forEach(connection => {
            if (connections.indexOf(connection) === -1) {
                connections.push(connection);
            }
        });
        return connections;
    }

    getNodeConnections(node: DiagramNode) {
        return this.getDiagramConnections()
            .filter(connection => connection.sourceIdentifier === node.identifier || connection.targetIdentifier === node.identifier);
    }

    getElementLayerType(elementId: string | undefined) {
        let type: ArchimateLayerType | null = null;
        if (elementId) {
            const element = ArchimateElement.findByStandardName(this.getElementById(elementId).type);
            type = element && element.layerType;
        }
        return type;
    }

    getElementType(elementId: string | undefined): ArchimateElementType | null {
        let type: ArchimateElementType | null = null;
        if (elementId) {
            const element = ArchimateElement.findByStandardName(this.getElementById(elementId).type);
            type = element && element.elementType;
        }
        return type;
    }

    getConnectionsBetweenNodes(firstNode: DiagramNode, secondNode: DiagramNode) {
        return this.getNodeConnections(firstNode).filter(connection =>
            connection.sourceIdentifier === secondNode.identifier ||
            connection.targetIdentifier === secondNode.identifier);
    }

    getRelationshipsBetweenElements(firstElement: ElementDto, secondElement: ElementDto) {
        return this.getRelationships().filter(relationship => {
            const sourceElementId = relationship.source?.identifier;
            const targetElementId = relationship.target?.identifier;

            return (sourceElementId === firstElement.identifier && targetElementId === secondElement.identifier) ||
                (sourceElementId === secondElement.identifier && targetElementId === firstElement.identifier)
        })
    }

    getHiddenRelationshipsBetweenNodes(firstNode: DiagramNode, secondNode: DiagramNode) {
        if (firstNode.elementIdentifier == null || secondNode.elementIdentifier == null) {
            return [];
        } else {
            const firstElement = this.getElementById(firstNode.elementIdentifier);
            const secondElement = this.getElementById(secondNode.elementIdentifier);

            const connections = this.getConnectionsBetweenNodes(firstNode, secondNode);
            const relationships = this.getRelationshipsBetweenElements(firstElement, secondElement);

            const visualizedRelationshipIds = connections.filter(connection => connection.relationshipIdentifier != null).map(connection => connection.relationshipIdentifier);
            return relationships.filter(relationship => relationship.identifier != null && visualizedRelationshipIds.indexOf(relationship.identifier) === -1);
        }
    }

    getHiddenRelationships() {
        const visualizedRelationshipIds = this.getDiagramConnections()
            .filter(connection => connection.relationshipIdentifier)
            .map(connection => connection.relationshipIdentifier);
        return this.getRelationships().filter(relationship => visualizedRelationshipIds.indexOf(relationship.identifier) === -1);
    }

    addRelationshipToModel(relationship: RelationshipDto) {
        this.model!.graph.relationships.push(relationship);
        this.initCachesForRelationship(relationship);
    }

    removeRelationshipFromModel(relationship: RelationshipDto) {
        this.getGraph().relationships = this.getGraph().relationships.filter(rel => rel.identifier !== relationship.identifier);
        this.cacheLocator!.getRelationshipIdToRelationshipCache().delete(relationship.identifier);
    }

    addElementToModel(element: ElementDto) {
        this.getElements().push(element);
        this.initCachesForElement(element);
    }

    removeElementFromModel(element: ElementDto) {
        this.getGraph().elements = this.getGraph().elements.filter(elem => elem.identifier !== element.identifier);
        this.cacheLocator!.getElementIdToElementCache().delete(element.identifier);
    }

    addConnectionToModel(connection: IDiagramConnectionDto) {
        this.getDiagramConnections().push(connection);
        this.initCachesForConnection(connection);
    }

    removeConnectionFromModel(connection: IDiagramConnectionDto) {
        this.getDiagram().connections = this.getDiagram().connections.filter(conn => conn.identifier !== connection.identifier);
        this.cacheLocator!.getConnectionIdToConnectionCache().delete(connection.identifier);
    }

    addNodeToModel(node: DiagramNode,
                   parentNodeId: string | undefined,
                   parentNodeChildrenIndex: number) {
        const parentNode = parentNodeId ? this.cacheLocator!.getNodeIdToNodeCache().get(parentNodeId) : undefined;
        this.addNodeToParentInModel(node, parentNode, parentNodeChildrenIndex);
        this.initCachesForNode(node, parentNode);
    }

    removeNodeFromModel(node: DiagramNode) {
        if (node.childNodes) {
            for (const childNode of node.childNodes) {
                this.removeNodeFromModel(childNode);
            }
        }
        const parent = this.cacheLocator!.getNodeIdToParentNodeCache().get(node.identifier);
        this.removeNodeFromParentInModel(node, parent);
        this.cacheLocator!.getNodeIdToNodeCache().delete(node.identifier);
        this.cacheLocator!.getNodeIdToChildNodesCache().delete(node.identifier);
    }

    updateNodeParentInModel(node: DiagramNode, parentNew: DiagramNode | undefined, parentNewChildIndex: number) {
        const parentOld = this.cacheLocator!.getNodeIdToParentNodeCache().get(node.identifier);

        if (parentOld !== parentNew) {
            this.removeNodeFromParentInModel(node, parentOld);
            this.addNodeToParentInModel(node, parentNew, parentNewChildIndex);
        }
    }

    private removeNodeFromParentInModel(node: DiagramNode, parent: DiagramNode | undefined) {
        if (parent) {
            parent.childNodes = parent.childNodes?.filter(childNode => childNode.identifier !== node.identifier);
            this.refreshParentNodeHierarchy(parent);
        } else {
            this.getDiagram().nodes = this.getDiagram().nodes.filter(modelNode => modelNode.identifier !== node.identifier);
        }
        this.cacheLocator!.getNodeIdToParentNodeCache().delete(node.identifier);
    }

    private refreshParentNodeHierarchy(parent: DiagramNode) {
        let node: DiagramNode | undefined = parent;
        while (node != null) {
            this.cacheLocator!.getNodeIdToChildNodesCache().set(node.identifier, this.computeChildNodes(node));
            node = this.cacheLocator!.getNodeIdToParentNodeCache().get(node.identifier);
        }
    }

    private addNodeToParentInModel(node: DiagramNode, parent: DiagramNode | undefined, parentNewChildIndex: number) {
        if (parent) {
            const cachedParent = this.cacheLocator!.getNodeIdToNodeCache().get(parent.identifier) as DiagramNode;
            if (cachedParent.childNodes == null) {
                cachedParent.childNodes = [];
            }
            if (parentNewChildIndex != null) {
                cachedParent.childNodes.splice(parentNewChildIndex, 0, node);
            } else {
                cachedParent.childNodes.push(node);
            }
            this.refreshParentNodeHierarchy(cachedParent);
        } else {
            if (parentNewChildIndex != null) {
                this.getDiagram().nodes.splice(parentNewChildIndex, 0, node);
            } else {
                this.getDiagram().nodes.push(node);
            }
        }
        this.cacheLocator!.getNodeIdToParentNodeCache().set(node.identifier, parent);
    }

    public updateNodePosition(node: DiagramNode, newNodePosition: ParentNodePosition) {
        if (newNodePosition.parentNodeId != null) {
            const parentNode = this.getDiagramNodeById(newNodePosition.parentNodeId) as DiagramNode;
            parentNode.childNodes = parentNode.childNodes?.filter(child => child.identifier !== node.identifier);
            parentNode.childNodes?.splice(newNodePosition.parentNodeChildrenIndex, 0, node);

            const grandparentNode = this.getParentNode(parentNode);
            this.initCachesForNode(parentNode, grandparentNode);
        } else {
            this.getDiagram().nodes = this.getDiagram().nodes.filter(child => child.identifier !== node.identifier);
            this.getDiagram().nodes?.splice(newNodePosition.parentNodeChildrenIndex, 0, node);
        }
    }

    getElementsForNodes(nodes: IDiagramNodeDto[]): ElementDto[] {
        const elementIdentifiers = new Set(nodes.map(value => value.elementIdentifier));
        const elements = this.getElements().filter(element => elementIdentifiers.has(element.identifier))!;
        if (elements.length !== elementIdentifiers.size) {
            throw new Error("Missing elements.");
        }
        return elements;
    }

}
