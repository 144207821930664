import {UserRole} from "../../../../common/access/UserRole";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import {UserDto} from "../../../../common/apis/user/UserDto";

class ImportPageAccessResolver {

    public hasPageAccess(user?: UserDto): boolean {
        if (user != null) {
            if (UserRole.isAtLeast(user, UserRoleType.ROLE_SPRAVCE)) {
                return true;
            } else {
                return user.isEditor !== undefined && user.isEditor;
            }
        }
        return false;
    }

}

const importPageAccessResolver = new ImportPageAccessResolver();
export default importPageAccessResolver;