import React, {useEffect, useState} from "react";
import {FetchStatusType} from "../../../../store/common/FetchableResource";
import EditableTextField from "../../../../components/fields/EditableTextField";
import EditableRoleComponent from "../../../../components/fields/EditableRoleComponent";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {UsersTranslationKey} from "../users/UsersTranslationKey";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import {UserDto} from "../../../../common/apis/user/UserDto";
import {IApplicationState} from "../../../../store/Store";
import {useSelector} from "react-redux";
import {UserDetailController} from "./controller/UserDetailController";
import {UserValidator} from "../users/UserValidation";
import TextField from "../../../../components/fields/textfield/TextField";
import Grid from "../../../../components/dialogs/Grid";
import Snackbar from "../snackbar/Snackbar";
import {CommonTranslation} from "../CommonTranslation";
import {ValidationError} from "../../../../common/ValidationError";
import {UserErrorCode} from "../users/UserErrorCode";


interface UserFetchState {
    user?: UserDto;
    userFetchStatus: {
        status: FetchStatusType;
        date: Date,
        error?: string;
    }
}

interface BasicInfoPanelProps {
    login: string,
    onPropertyUpdate?: () => void,
    controller: UserDetailController,
    userValidator: UserValidator
}

export default function BasicInfoPanel({login, onPropertyUpdate, controller, userValidator}: BasicInfoPanelProps) {

    // STATE
    const [userFetchState, setUserFetchState] = useState<UserFetchState>(createUserFetchState(FetchStatusType.NOT_STARTED));
    const loggedUser = useSelector((application: IApplicationState) => application.user.userData as UserDto);
    const {user, userFetchStatus} = userFetchState;

    useEffect(() => {
        let isUnmounted = false;

        setUserFetchState(createUserFetchState(FetchStatusType.STARTED, undefined));
        (async () => {
            try {
                const user = await controller.getUserByLogin(login);
                if (!isUnmounted) {
                    setUserFetchState(createUserFetchState(FetchStatusType.SUCCESSFUL, user));
                }
            } catch (error) {
                if (!isUnmounted) {
                    setUserFetchState(createUserFetchState(FetchStatusType.FAILED, undefined));
                }
            }
        })();

        return () => {
            isUnmounted = true;
        }
    }, [login, controller]);

    function createUserFetchState(status: FetchStatusType, user?: UserDto, date?: Date, error?: string): UserFetchState {
        return {
            user: user,
            userFetchStatus: {
                status: status,
                date: date || new Date(),
                error: error,
            },
        }
    }

    function onPropertyUpdated() {
        Snackbar.success(_transl(CommonTranslation.DATA_SAVED_SUCESSFULLY));
        onPropertyUpdate && onPropertyUpdate();
    }

    return (
        <Grid container spacing={2}>

            {userFetchStatus.status === FetchStatusType.SUCCESSFUL && user != null &&
                <React.Fragment>
                    <Grid item xs={6}>
                        <TextField
                            id="user-detail-basic-panel-login"
                            label={_transl(UsersTranslationKey.USER_LOGIN)}
                            defaultValue={user.login}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size={"small"}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <EditableRoleComponent id={"user-detail-basic-panel-role"}
                                               label={_transl(UsersTranslationKey.USER_ROLE)}
                                               isOptional={false}
                                               selectedRole={user.role}
                                               doUpdate={(role: UserRoleType) => controller.updateRoleByLogin(user.login, role)}
                                               onSuccessfulUpdate={onPropertyUpdated}
                                               initialValues={controller.findInitialValues(loggedUser, user.role, user.login)}
                                               readonly={!loggedUser.userAcl.canUpdateRole}/>
                    </Grid>
                    <Grid item xs={6}>
                        <EditableTextField id={"user-detail-basic-panel-firstname"}
                                               label={_transl(UsersTranslationKey.USER_FIRST_NAME)}
                                               initialValue={user.firstName || ""}
                                               doUpdate={(text) => controller.updateFirstNameByLogin(user.login, text)}
                                               onSuccessfulUpdate={onPropertyUpdated}
                                               readonly={!loggedUser.userAcl.canUpdate}/>
                    </Grid>
                    <Grid item xs={6}>
                        <EditableTextField id={"user-detail-basic-panel-lastname"}
                                               label={_transl(UsersTranslationKey.USER_LAST_NAME)}
                                               initialValue={user.lastName || ""}
                                               doUpdate={(text) => controller.updateLastNameByLogin(user.login, text)}
                                               onSuccessfulUpdate={onPropertyUpdated}
                                               readonly={!loggedUser.userAcl.canUpdate}/>
                    </Grid>
                    <Grid item xs={4}>
                        <EditableTextField id={"user-detail-basic-panel-email"}
                                           label={_transl(UsersTranslationKey.USER_EMAIL)}
                                           initialValue={user.email || ""}
                                           doUpdate={(text) => {
                                               return controller.updateEmailByLogin(user.login, text)
                                                   .catch((error) => {
                                                       if (error instanceof ValidationError && error.error.code === UserErrorCode.EMAIL_ALREADY_IN_USE) {
                                                           Snackbar.error(_transl(UsersTranslationKey.USER_EMAIL_ALREADY_IN_USE));
                                                       }
                                                       throw error;
                                                   });
                                           }
                                           }
                                           onSuccessfulUpdate={onPropertyUpdated}
                                           validator={{
                                               isValid: (email) => !userValidator.isEmailError(email),
                                               getValidationErrorMessage: () =>
                                                   <span>{_transl(UsersTranslationKey.USER_EMAIL_INVALID)}</span>,
                                           }}
                                           readonly={!loggedUser.userAcl.canUpdate}/>
                    </Grid>
                    <Grid item xs={4}>
                        <EditableTextField id={"user-detail-basic-panel-phone"}
                                               label={_transl(UsersTranslationKey.USER_PHONE)}
                                               initialValue={user.phone || ""}
                                               doUpdate={(text) => controller.updatePhoneByLogin(user.login, text)}
                                               onSuccessfulUpdate={onPropertyUpdated}
                                               readonly={!loggedUser.userAcl.canUpdate}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="user-detail-basic-panel-active"
                            label={_transl(UsersTranslationKey.USER_STATE)}
                            defaultValue={user.userActive ? _transl(UsersTranslationKey.USER_IS_ACTIVE) : _transl(UsersTranslationKey.USER_IS_INACTIVE)}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size={"small"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EditableTextField id={"user-detail-basic-panel-description"}
                                               label={_transl(UsersTranslationKey.NOTE)}
                                               initialValue={user.description || ""}
                                               doUpdate={(text) => controller.updateDescriptionByLogin(user.login, text)}
                                               rows={6}
                                               onSuccessfulUpdate={onPropertyUpdated}
                                               readonly={!loggedUser.userAcl.canUpdate}/>
                    </Grid>
                </React.Fragment>
            }

            {userFetchStatus.status === FetchStatusType.STARTED &&
                <Grid item xs={12}>
                    {_transl(UsersTranslationKey.BASIC_INFO_LOAD)}
                </Grid>
            }

            {userFetchStatus.status === FetchStatusType.FAILED &&
                <Grid item xs={12}>
                    {_transl(UsersTranslationKey.BASIC_INFO_FAILED)}
                </Grid>
            }

        </Grid>
    );

}
