export enum ModelDocumentationTranslationKey {
    TITLE = "modelDocumentation.title",
    STEP_DIAGRAMS_SELECTION_LABEL = "modelDocumentation.step.diagram.selectionLabel",
    STEP_DIAGRAMS_SELECTION_DESCRIPTION = "modelDocumentation.step.diagram.selectionDescription",
    OPTION_INCLUDE_ELEMENT_DESCRIPTION = "modelDocumentation.option.includeElementDesciption",
    OPTION_INCLUDE_DIAGRAM_DESCRIPTION = "modelDocumentation.option.includeDiagramDesciption",
    DIAGRAMS_GRID_BUTTON_ADD = "modelDocumentation.diagramsGrid.button.add",
    DIAGRAMS_GRID_BUTTON_REMOVE = "modelDocumentation.diagramsGrid.button.remove",
    GENERATE_MODEL_DOCUMENTATION_RESULT = "modelDocumentation.generateModelDocumentation.result",
    GENERATE_MODEL_DOCUMENTATION_BUTTON = "modelDocumentation.generateModelDocumentation.button",
    GENERATE_MODEL_DOCUMENTATION_IN_PROGRESS = "modelDocumentation.generateModelDocumentation.inProgress",
    GENERATE_MODEL_DOCUMENTATION_IS_DONE = "modelDocumentation.generateModelDocumentation.isDone",
    GENERATE_MODEL_DOCUMENTATION_IS_DONE_ADDITIONAL_INFO = "modelDocumentation.generateModelDocumentation.isDoneAdditionalInfo",
    GENERATE_MODEL_DOCUMENTATION_FAILED = "modelDocumentation.generateModelDocumentation.failed",
}