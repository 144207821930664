import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Wizard from "../../../../components/wizard/Wizard";
import UploadFilePanel from "../../../../components/UploadFilePanel";
import Api from "../../../../common/Api";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {WizardStep} from "../../../../components/wizard/WizardStep";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uploadContentFileName: {
            fontSize: "1.4em",
            marginBottom: theme.spacing(5),
        },
        uploadContentFileTypeLabel: {
            marginRight: theme.spacing(2),
            fontWeight: "bolder",
        },
    })
);

interface Props {
    // router props
    resetImport: () => void,
}

export default function UserPermissionsImportWizard(props: Props) {

    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState<File>();

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(_transl(ImportTranslationKey.CHOOSE_FILE_TITLE), <CloudUploadIcon/>, _transl(ImportTranslationKey.CHOOSE_FILE), false,
                () => renderSelectFileStep(), () => canProceedFromSelectFileStep())
        ];
    }

    function renderSelectFileStep(): JSX.Element {
        const uploadPanelContentGetter = selectedFile != null ? () => createFileDescription() : undefined;

        return <UploadFilePanel uploadAttachment={file => setSelectedFile(file)}
                                uploadPanelContent={uploadPanelContentGetter}/>
    }

    function createFileDescription(): JSX.Element {
        return <div className={classes.uploadContentFileName}>{selectedFile?.name}</div>
    }

    function canProceedFromSelectFileStep(): boolean {
        return selectedFile != null;
    }

    function doImport(): Promise<any> {
        return Api.imports.importUserPermissions(selectedFile as File).toPromise();
    }

    return (
        <Wizard steps={getSteps()}
                lastStepLabel={_transl(ImportTranslationKey.LAST_STEP_LABEL)}
                lastStepButtonLabel={_transl(ImportTranslationKey.LAST_STEP_BUTTON_LABEL)}
                lastStepAction={() => doImport()}
                lastStepActionInProgressText={_transl(ImportTranslationKey.LAST_STEP_ACTION_IN_PROGRESS)}
                lastStepActionSuccessfulText={_transl(ImportTranslationKey.LAST_STEP_ACTION_SUCCESSFUL)}
                lastStepActionFailedText={_transl(ImportTranslationKey.LAST_STEP_FAILED)}
                cancelWizard={props.resetImport}
                wizardGridMinHeight={"25em"}
        />
    );

}
