import UserAclDto from "../../../../../common/apis/user/UserAclDto";
import {ElementDto} from "../../../../../common/apis/element/ElementDto";

export class ElementsController implements ElementsController {

    canDeleteAllElements(elements: ElementDto[]): boolean {
        return elements.every(element => element.acl.canDelete);
    }

    canUpdateCollectionsOfAllElements(elements: ElementDto[]): boolean {
        return elements.every(element => element.acl.canUpdateCollections);
    }

    canUpdatePublicLabelsOfAllElements(elements: ElementDto[]): boolean {
        return elements.every(element => element.acl.canUpdatePublicLabels);
    }

    canMergeAllElements(elements: ElementDto[]): boolean {
        return elements.every(element => element.acl.canMerge);
    }

    canAlterTypeOfAllElements(elements: ElementDto[]): boolean {
        return elements.every(element => element.acl.canAlterType);
    }

    canCreateDiagramWithElements(elements: ElementDto[], userAcl?: UserAclDto): boolean {
        return userAcl ? userAcl.canCreateDiagrams : false;
    }

}
