const PATH_DELIMITER = ",";

class HierarchyTreePathParser {

    getParentPath(path: string) {
        return path.substring(0, path.lastIndexOf(PATH_DELIMITER));
    }

    getLastPathItem(path: string) {
        return path.substring(path.lastIndexOf(PATH_DELIMITER) + 1);
    }

    containMultipleItems(path: string) {
        return path.indexOf(PATH_DELIMITER) !== -1;
    }

    getPathItems(path: string) {
        return path.split(PATH_DELIMITER);
    }

    appendPathDelimiter(path: string) {
        return path + PATH_DELIMITER;
    }

    createPath(items: string[]) {
        return items.join(PATH_DELIMITER);
    }

}

const hierarchyTreeParser = new HierarchyTreePathParser();
export default hierarchyTreeParser;