import {FileFormatType} from "../../../../common/apis/Exports";
import {DiagramVisualizationInputWithModelDto, DiagramVisualizationInputDto} from "../../../../common/apis/DiagramsApi";
import Api from "../../../../common/Api";
import {AjaxResponse} from "rxjs/ajax";
import {BlobUtils} from "../../../../common/BlobUtils";
import {IModelDto} from "../../../../common/apis/model/IModelDto";

export function visualiseDiagram(identifier: string, format: FileFormatType,
                                 closeLoading: () => void, closeLoadingWithError: () => void) {
    const dto: DiagramVisualizationInputDto = {
        identifier: identifier,
        format: format,
        imageSizeInPixels: 0,
    };
    Api.diagrams.visualizeDiagram(dto).subscribe({
            next: (resp) => {
                handleSuccessfullResponse(resp);
            },
            complete: () => {
                closeLoading();
            },
            error: (err) => {
                closeLoadingWithError();
            }
        }
    )
}

export function visualiseDiagramSendingModel(format: FileFormatType, model: IModelDto,
                                             closeLoading: () => void, closeLoadingWithError: () => void) {
    const dto: DiagramVisualizationInputWithModelDto = {
        format: format,
        model: model,
    };
    Api.diagrams.visualizeDiagramSendingModel(dto).subscribe({
            next: (resp) => {
                handleSuccessfullResponse(resp);
            },
            complete: () => {
                closeLoading();
            },
            error: (err) => {
                closeLoadingWithError();
            }
        }
    )
}

function handleSuccessfullResponse(resp: AjaxResponse) {
    const response = resp as AjaxResponse;
    const disposition = response.xhr.getResponseHeader('Content-Disposition') as string;
    const fileName = disposition.split('filename="')[1].split('"')[0]
    const blob = new Blob([response.response]);

    BlobUtils.saveBlob(blob, fileName);
}