import {Theme} from "@mui/material/styles";
import {StyleRules} from "@mui/styles/withStyles";
import {CSSProperties} from "@mui/styles/withStyles/withStyles";

export default class CommonCssStyles {
    public static readonly STANDARD_PADDING = 2;
    public static readonly STANDARD_SPACING = 1;

    public static readonly PAGE_SEGMENT_PADDING = 2;
    public static readonly CONTROL_SEGMENT_PADDING = 3;

    public static getHeaderPageSegmentStyles(theme: Theme, overrides?: CSSProperties): CSSProperties {
        return {
            padding: theme.spacing(CommonCssStyles.PAGE_SEGMENT_PADDING, CommonCssStyles.PAGE_SEGMENT_PADDING, CommonCssStyles.PAGE_SEGMENT_PADDING, CommonCssStyles.PAGE_SEGMENT_PADDING),
            ...overrides,
        };
    }

    public static getControlPageSegmentStyles(theme: Theme, overrides?: CSSProperties): CSSProperties {
        return {
            padding: theme.spacing(CommonCssStyles.CONTROL_SEGMENT_PADDING, CommonCssStyles.CONTROL_SEGMENT_PADDING, CommonCssStyles.CONTROL_SEGMENT_PADDING, CommonCssStyles.CONTROL_SEGMENT_PADDING),
            ...overrides,
        }
    }

    public static getRootPageStyles(theme: Theme, overrides?: CSSProperties): CSSProperties {
        return {
            margin: CommonCssStyles.getRootPageMargin(theme),
            padding: CommonCssStyles.getRootPagePadding(theme),
            minWidth: "100%",
            minHeight: "100%",
            ...overrides,
        }
    }

    public static getFullHeightStyle(): CSSProperties {
        return {
            height: "100%"
        }
    }

    public static getHeaderCloseButtonStyle(theme: Theme): CSSProperties {
        return {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        }
    }

    public static getRootPageMargin(theme: Theme): string {
        return theme.spacing(1, 1, 1, 1);
    }

    public static getRootPagePadding(theme: Theme): string {
        return theme.spacing(0, 0, 0, 0);
    }

    public static getGridItemPadding(theme: Theme) {
        return theme.spacing(2, 1, 2, 1);
    }

    public static getBasicInfoGridStyles(theme: Theme): StyleRules {
        return {
            "& .MuiGrid-item": {
                padding: CommonCssStyles.getGridItemPadding(theme),
            },
            "& .MuiOutlinedInput-inputMarginDense": {
                paddingTop: "15.5px",
                paddingBottom: "13.5px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.10)",
            },
        }
    }
}
