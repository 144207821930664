import {default as MuiButton} from "@mui/material/Button";
import React from "react";
import {ButtonPropTypes} from "./ButtonPropTypes";
import {IconButton, useTheme} from "@mui/material";
import {makeStyles} from "@mui/styles";

interface ButtonProps {
    label: string,
    onClick: (event?: any) => void,
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    variant?: ButtonPropTypes.Variant,
    autoFocus?: boolean,
    disabled?: boolean;
    icon?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    iconButton: {
        padding: '0',
    },
}));

export function Button({color, autoFocus, onClick, variant, label, disabled, icon}: ButtonProps) {

    const classes = useStyles();
    const theme = useTheme();

    const iconColor = color && color !== 'inherit' ? theme.palette.getContrastText(theme.palette[color].main) : "inherit";

    return (
        <MuiButton
            color={color}
            autoFocus={autoFocus}
            onClick={onClick}
            variant={variant}
            disabled={disabled}
            startIcon={icon && (
                <IconButton
                    aria-label="icon"
                    size={"small"}
                    className={classes.iconButton}
                    style={{color: iconColor}}
                >
                    {icon}
                </IconButton>
            )}
        >
            {label}
        </MuiButton>
    )
}
