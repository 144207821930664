import {ElementDto} from "../../common/apis/element/ElementDto";
import {Point} from "../../common/diagrameditor/util/GeometryUtils";
import {IDiagramNodeDto} from "../../common/apis/diagram/IDiagramNodeDto";
import {DiagramDefaultsDto} from "../../common/apis/diagram/DiagramDefaultsDto";
import {ArchimateElement} from "../../common/archimate/ArchimateElement";
import {ArchimateLayerType} from "../../common/archimate/ArchiMateLayer";
import {NodeDimensionsExtractor} from "../../common/diagrameditor/manager/nodeposition/NodeDimensionsExtractor";
import {NodeType} from "../../common/apis/diagram/NodeType";

export class DiagramLayoutGenerator {
    static NODES_PER_LINE = 6;

    static ORDERED_LAYERS: ArchimateLayerType[] = [
        ArchimateLayerType.MOTIVATION,
        ArchimateLayerType.STRATEGY,
        ArchimateLayerType.BUSINESS,
        ArchimateLayerType.APPLICATION,
        ArchimateLayerType.TECHNOLOGY,
        ArchimateLayerType.PHYSICAL,
        ArchimateLayerType.IMPLEMENTATION_AND_MIGRATION,
        ArchimateLayerType.COMPOSITE_ELEMENTS,
        ArchimateLayerType.VIRTUAL_LAYER_CONNECTORS
    ];

    private nodeDimensionsExtractor: NodeDimensionsExtractor;

    constructor(private readonly diagramDefaults: DiagramDefaultsDto,
                private readonly nodesPerLine = DiagramLayoutGenerator.NODES_PER_LINE) {
        this.nodeDimensionsExtractor = new NodeDimensionsExtractor(diagramDefaults);
    }

    layoutNodes(elements: ElementDto[], identityPoint: Point): IDiagramNodeDto[] {
        const width = this.diagramDefaults.nodeDimensions.width;
        const height = this.diagramDefaults.nodeDimensions.height;
        const spacing = this.diagramDefaults.spacing;

        const elementsByLayers = this.groupElementsByLayers(elements);

        const nodes: IDiagramNodeDto[] = [];

        let yOffset = identityPoint.y;

        for (const layer of DiagramLayoutGenerator.ORDERED_LAYERS) {
            if (elementsByLayers.has(layer)) {
                let index = 0;
                for (const element of elementsByLayers.get(layer)!) {

                    const x = (index % this.nodesPerLine) * (width + spacing);
                    const y = Math.floor(index / this.nodesPerLine) * (height + spacing) + yOffset;
                    const dimensions = this.nodeDimensionsExtractor.extractDimensions(element.type);
                    nodes.push(this.createNode(element, x, y, dimensions.width, dimensions.height));
                    index++;
                }
                var lines = Math.floor((index - 1) / this.nodesPerLine) + 1;

                yOffset = yOffset + lines * (height + spacing);
            }
        }
        return nodes;
    }

    private groupElementsByLayers(elements: ElementDto[]): Map<ArchimateLayerType, ElementDto[]> {
        const elementsByLayers: Map<ArchimateLayerType, ElementDto[]> = new Map();

        for (const element of elements) {
            const layer = ArchimateElement.findByStandardName(element.type)?.layerType;
            if (layer) {
                if (!elementsByLayers.has(layer)) {
                    elementsByLayers.set(layer, []);
                }
                elementsByLayers.get(layer)?.push(element);
            }
        }

        return elementsByLayers;
    }

    private createNode(element: ElementDto, x: number, y: number, w: number, h: number): IDiagramNodeDto {
        return {
            identifier: "",
            type: NodeType.ELEMENT,
            elementIdentifier: element.identifier,
            x: x,
            y: y,
            w: w,
            h: h
        };
    }

}