import {useState} from "react";
import {ColorPickerPopover} from "./ColorPickerPopover";
import {ColorSwatchButton} from "./ColorSwatchButton";
import {ColorDto} from "../../common/apis/Color";

interface ColorPickerButtonProps {
    id?: string,
    disabled?: boolean,
    title: string,
    color: ColorDto,
    onChange: (color: ColorDto) => void,
}

export function ColorPickerButton(props: ColorPickerButtonProps) {
    const [showPicker, setShowPicker] = useState<boolean>(false);

    const disabled = props.disabled ?? false;

    function onButtonClick() {
        setShowPicker(true);
    }

    function onColorChange(color: ColorDto) {
        props.onChange(color);
    }

    function closePicker() {
        setShowPicker(false);
    }

    return (
        <div>
            <ColorSwatchButton title={props.title} color={props.color} disabled={disabled} onClick={onButtonClick} />
            { showPicker && <ColorPickerPopover color={props.color} onChange={onColorChange} onClose={closePicker}/> }
        </div>
    );
}