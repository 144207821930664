
export enum GraphChartTranslationKey {
    EXPLODE = "graphChart.explode",
    ANCHOR = "graphChart.anchor",
    UNANCHOR = "graphChart.unanchor",
    LIST_ELEMENTS = "graphChart.listElements",
    MAXIMIZE = "graphChart.maximize",
    MINIMIZE = "graphChart.minimize",
    RESET_VIEW = "graphChart.resetView",
    SHOW_DETAIL = "graphChart.showDetail",
    SOURCE = "graphChart.source",
    TARGET = "graphChart.target",
    ZOOM_IN = "graphChart.zoomIn",
    ZOOM_OUT = "graphChart.zoomOut",
}