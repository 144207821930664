import EventManager, {Unsubscriber} from "../../event/EventManager";
import RenderContext from "../context/RenderContext";
import ConnectionRendererFactory, {ConnectionModifiers} from "./connection/ConnectionRendererFactory";
import {
    ConnectionsEvent,
    EventType,
    IModelUpdatedOnBendpointCreatedEvent,
    IModelUpdatedOnBendpointMovedEvent,
    IModelUpdatedOnBendpointRemovedEvent,
    IModelUpdatedOnConnectionCreatedEvent, IModelUpdatedOnConnectionLabelUpdateEvent,
    IModelUpdatedOnConnectionRemovedEvent,
    IModelUpdatedOnItemsCreatedEvent,
    IModelUpdatedOnItemsRemovedEvent,
    IModelUpdatedOnNodesMovedEvent,
    IModelUpdatedOnNodesResizedEvent, ModelBackupEvent, ModelUpdatedOnNodesAlignedEvent,
} from "../../event/Event";
import ConnectionRendererUtils from "./connection/ConnectionRendererUtils";
import * as d3 from "d3";
import {ArchimateRelationshipType} from "../../archimate/ArchimateRelationship";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";
import {RelationshipDto} from "../../apis/relationship/RelationshipDto";
import {StyleEventType, StylesUpdatedEvent} from "../../../diagram/editor/style/StyleEvents";
import {DiagramNode} from "../model/Model";

export default class ConnectionsRenderer {

    private eventManager: EventManager;
    private connectionRendererFactory: ConnectionRendererFactory;
    private renderContext?: RenderContext;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_MOVED, this.handleModelUpdatedOnNodesMovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_RESIZED, this.handleModelUpdatedOnNodesResizedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_MOVED, this.handleModelUpdatedOnBendpointMovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_CREATED, this.handleModelUpdatedOnBendpointCreatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_REMOVED, this.handleModelUpdatedOnBendpointRemovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_CREATED, this.handleModelUpdatedOnConnectionCreatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_REMOVED, this.handleModelUpdatedOnConnectionRemovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE, this.handleModelUpdatedOnConnectionLabelUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_REMOVED, this.handleModelUpdatedOnItemsRemovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_CREATED, this.handleModelUpdatedOnItemsCreatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.HIDDEN_CONNECTIONS_UPDATED, this.handleConnectionsEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_ALIGNED, this.handleModelUpdatedOnNodesAlignedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(StyleEventType.STYLES_UPDATED, this.handleStylesUpdated.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelBackupEvent>(EventType.MODEL_UPDATED_ON_MODEL_BACKUP_LOADED, this.handleModelUpdatedOnModelBackupLoadedEvent.bind(this)));

        this.connectionRendererFactory = ConnectionRendererFactory.getInstance();
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    public init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        this.clearConnectionsGroups();
    }

    private clearConnectionsGroups() {
        if (this.renderContext) {
            d3.select(ConnectionRendererUtils.createConnectionsGroupId(true))
                .selectAll("*")
                .remove();
            d3.select(ConnectionRendererUtils.createHiddenConnectionsGroupId(true))
                .selectAll("*")
                .remove();
        }
    }

    public render() {
        if (this.renderContext) {
            this.renderContext.modelManager.getDiagramConnections()
                .forEach(connection => this.initialRenderConnection(connection))
        }
    }

    handleModelUpdatedOnNodesMovedEvent(event: IModelUpdatedOnNodesMovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODES_MOVED) {
            // rerender all connections related to moved nodes -> although none of connection bendpoints moved
            // the node start / end should be updated
            const nodes = event.nodeDimensionsNew.map(nodeDimension => nodeDimension.node);
            if (event.parentNode) {
                nodes.push(event.parentNode);
            }
            this.rerenderNodeConnections(nodes);
            // remove removed connections
            event.connectionsToRemove.forEach(connection => ConnectionsRenderer.removeConnectionOrderGroup(connection));

            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
        }
    }

    handleModelUpdatedOnNodesResizedEvent(event: IModelUpdatedOnNodesResizedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODES_RESIZED) {
            this.rerenderNodeConnections(event.nodeDimensionsNew.map(dimensions => dimensions.node));
            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
        }
    }

    handleModelUpdatedOnBendpointMovedEvent(event: IModelUpdatedOnBendpointMovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_MOVED) {
            this.rerenderConnections([event.connection]);
            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
        }
    }

    handleModelUpdatedOnBendpointCreatedEvent(event: IModelUpdatedOnBendpointCreatedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_CREATED) {
            this.rerenderConnections([event.connection]);
            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
        }
    }

    handleModelUpdatedOnBendpointRemovedEvent(event: IModelUpdatedOnBendpointRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_REMOVED) {
            this.rerenderConnections([event.connection]);
            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
        }
    }

    handleModelUpdatedOnConnectionCreatedEvent(event: IModelUpdatedOnConnectionCreatedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_CREATED) {
            if (!event.isNestedConnection) {
                this.initialRenderConnection(event.connection);
            }
            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
        }
    }

    handleModelUpdatedOnConnectionLabelUpdateEvent(event: IModelUpdatedOnConnectionLabelUpdateEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE) {
            this.rerenderConnections([event.connection]);
            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}})
        }
    }

    handleModelUpdatedOnConnectionRemovedEvent(event: IModelUpdatedOnConnectionRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_REMOVED) {
            ConnectionsRenderer.removeConnectionOrderGroup(event.connection);
            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
        }
    }

    handleModelUpdatedOnItemsCreatedEvent(event: IModelUpdatedOnItemsCreatedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_CREATED) {
            event.createdConnections.forEach(connection => this.initialRenderConnection(connection));
            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
        }
    }

    handleModelUpdatedOnItemsRemovedEvent(event: IModelUpdatedOnItemsRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_REMOVED) {
            event.removedConnections?.forEach(connection => ConnectionsRenderer.removeConnectionOrderGroup(connection));
            this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
        }
    }

    handleConnectionsEvent(event: ConnectionsEvent) {
        if (event.type === EventType.HIDDEN_CONNECTIONS_UPDATED) {
            this.renderHiddenConnections(event.connections);
        }
    }

    handleModelUpdatedOnNodesAlignedEvent(event: ModelUpdatedOnNodesAlignedEvent) {
        this.rerenderNodeConnections(event.nodes);
    }

    handleStylesUpdated(event: StylesUpdatedEvent) {
        this.rerenderConnections(event.connections);
    }

    private handleModelUpdatedOnModelBackupLoadedEvent(event: ModelBackupEvent) {
        this.clearConnectionsGroups();
        for (const connection of event.connectionsToAdd) {
            this.initialRenderConnection(connection);
        }
        this.eventManager.publishEvent({type: EventType.CHART_CONNECTIONS_RERENDERED, event: {}});
    }

    private initialRenderConnection(connection: IDiagramConnectionDto) {
        const connectionOrderGroup = ConnectionsRenderer.renderConnectionOrderGroup(connection)
        this.renderConnection(connection, connectionOrderGroup, false);
    }

    // CONNECTION ORDER GROUPS

    private static renderConnectionOrderGroup(
        connection: IDiagramConnectionDto,
    ) {
        const group = ConnectionRendererUtils.getConnectionsGroup();
        const connectionGroup: d3.Selection<SVGGElement, IDiagramConnectionDto, any, undefined> = group.append("g")
            .datum(connection)
            .attr("id", ConnectionRendererUtils.createConnectionOrderGroupId(connection));
        return connectionGroup;
    }

    private static renderHiddenConnectionOrderGroup(
        connection: IDiagramConnectionDto,
    ) {
        const group = ConnectionRendererUtils.getHiddenConnectionsGroup();
        const connectionGroup: d3.Selection<SVGGElement, IDiagramConnectionDto, any, undefined> = group.append("g")
            .datum(connection)
            .attr("id", ConnectionRendererUtils.createHiddenConnectionOrderGroupId(connection));
        return connectionGroup;
    }

    private static removeConnectionOrderGroup(connection: IDiagramConnectionDto) {
        ConnectionRendererUtils.getConnectionOrderGroup(connection)
            .remove();
    }

    // connections

    private rerenderConnections(connections: Array<IDiagramConnectionDto>) {
        const rerenderedIds: Array<string> = [];
        connections.forEach(connection => {
            if (rerenderedIds.indexOf(connection.identifier) === -1) {
                rerenderedIds.push(connection.identifier);
                let orderGroup = ConnectionRendererUtils.getConnectionOrderGroup(connection);
                if (orderGroup.empty()) {
                    orderGroup = ConnectionsRenderer.renderConnectionOrderGroup(connection);
                } else {
                    orderGroup.select("*").remove();
                }
                this.renderConnection(connection, orderGroup, false);
            }
        })
    }

    private rerenderNodeConnections(nodes: Array<DiagramNode>) {
        if (this.renderContext) {
            // rerender related connections
            nodes.forEach(node => {
                const connections = this.renderContext?.modelManager.getNodeConnections(node);
                if (connections) {
                    this.rerenderConnections(connections);
                }
            });
        }
    }

    private renderHiddenConnections(connections: Array<IDiagramConnectionDto>) {
        ConnectionRendererUtils.getHiddenConnectionsGroup()
            .selectAll("*")
            .remove();
        connections.forEach(connection => {
            const orderGroup = ConnectionsRenderer.renderHiddenConnectionOrderGroup(connection);
            this.renderConnection(connection, orderGroup, true);
        })
    }

    private renderConnection(
        connection: IDiagramConnectionDto,
        connectionOrderGroup: d3.Selection<SVGGElement, IDiagramConnectionDto, any, undefined>,
        isHidden: boolean,
    ) {
        if (this.renderContext) {
            let relationshipType: ArchimateRelationshipType | null = null;
            let modifiers: ConnectionModifiers | null = null;
            if (connection.relationshipIdentifier) {
                const relationshipId = connection.relationshipIdentifier;
                const relationship = this.renderContext.modelManager.getRelationshipById(relationshipId) as RelationshipDto;
                relationshipType = this.renderContext.modelManager.getRelationshipType(relationshipId);
                modifiers = new ConnectionModifiers(relationship.accessType, relationship.influenceModifier, relationship.associationDirected)
            }
            const connectionRenderer = this.connectionRendererFactory.get(relationshipType, modifiers);
            connectionRenderer.render(connection, connectionOrderGroup, isHidden, this.renderContext, this.eventManager);
        }
    }

}
