import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React from "react";
import Button from "@mui/material/Button";
import NotSucceededAlert from "../../../../components/NotSucceededAlert";
import {IApplicationState} from "../../../../store/Store";
import {connect} from "react-redux";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {ElementsGridDef} from "./RenderMode";
import {PersistentStateId} from "../../../../store/common/Grid";
import {GridRowParams} from "@mui/x-data-grid";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import Api from "../../../../common/Api";
import Constants from "../../../../common/Constants";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "./ElementTranslationKey";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

const styles = (theme: Theme) => createStyles({
    dialogLabelDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "4em",
        fontWeight: "bolder",
        color: "gray",
        fontSize: "1.1em",
    },
});

interface IProps extends WithStyles<typeof styles> {
    //router props
    dialogIsOpen: boolean,
    onClosed: () => void,
    selectedRows: ElementDto[],
    selectedRowIds: Array<string>,
    mergeIsDone: () => void,
}

interface IState {
    value: string,
    showBackendCallFailed: boolean,
    rowToMerge: string,
    alertDialog?: {
        open: boolean,
        onClose: () => void,
        title: string,
        text: string,
        type: AlertDialogType,
    }
}

class MergeElementsDialog extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = this.createInitialState();
    }

    createInitialState() {
        return {
            value: "",
            showBackendCallFailed: false,
            rowToMerge: "",
        }
    }

    handleChange<P extends keyof IState>(property: P, value: IState[P]) {
        this.setState(state => {
            const copy = {...state};
            copy[property] = value;
            return copy;
        })
    }

    changeShowBackendCallFailedToFalse() {
        this.setState(state => {
            return {
                ...state,
                showBackendCallFailed: false,
            }
        });
    }

    saveChanges() {
        const {selectedRows, selectedRowIds, mergeIsDone, onClosed} = this.props;
        const {rowToMerge} = this.state;
        if (rowToMerge === "" || rowToMerge === undefined) {
            this.setState(state => {
                return {
                    ...state,
                    alertDialog: {
                        open: true,
                        onClose: () => this.closeAlertDialog(),
                        title: _transl(ElementTranslationKey.DIALOG_MERGE_ELEMENTS_NOT_SELECTED_TITLE),
                        text: _transl(ElementTranslationKey.DIALOG_MERGE_ELEMENTS_NOT_SELECTED_MESSAGE),
                        type: AlertDialogType.INFO,
                    }
                }
            });
        } else {
            const rowIds: Array<string> = selectedRows.map((value) => value.identifier)
                .filter((value) => value !== rowToMerge);
            Api.elements.mergeElements(rowToMerge, rowIds).subscribe(
                () => {
                    onClosed();
                    this.synchronizeSelectedRowsIds(selectedRowIds, rowToMerge);
                    mergeIsDone();
                },
                () => {
                    this.setState(state => {
                        return {
                            ...state,
                            showBackendCallFailed: true,
                        }
                    });
                    setTimeout(() => this.changeShowBackendCallFailedToFalse(), Constants.FE_APP_ALERT_DELAY);
                })
        }
    }

    private synchronizeSelectedRowsIds(selectedRowIds: Array<string>, rowToMerge: string) {
        selectedRowIds.splice(0, selectedRowIds.length);
        selectedRowIds.push(rowToMerge);
    }

    private onDialogClosed() {
        this.setState(this.createInitialState())
        this.props.onClosed();
    }

    setSelectedRowToMerge(param: GridRowParams) {
        this.setState(state => {
            return {
                ...state,
                rowToMerge: param.row.identifier as string,
            }
        });
    }

    closeAlertDialog() {
        this.setState(state => {
            return {
                ...state,
                alertDialog: undefined,
            }
        });
    }


    render() {

        const {classes, dialogIsOpen, selectedRows} = this.props;
        const {alertDialog} = this.state;
        const alertNotSuccessMessage = _transl(ElementTranslationKey.DIALOG_MERGE_ELEMENTS_MERGE_NOT_SUCCESSFUL);
        const targetItem = _transl(ElementTranslationKey.DIALOG_MERGE_ELEMENTS_CHOOSING_TARGET);

        return (
            <React.Fragment>
                {alertDialog && <AlertDialog open={alertDialog.open}
                                             onClose={alertDialog.onClose}
                                             title={alertDialog.title}
                                             text={alertDialog.text}
                                             type={alertDialog.type}
                />}
                <Dialog
                    open={dialogIsOpen}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    onClose={() => this.onDialogClosed()}
                    fullWidth={true}
                    maxWidth={"xl"}
                >
                    <DialogTitle
                        id="scroll-dialog-title"
                        title={_transl(ElementTranslationKey.DIALOG_MERGE_ELEMENTS_MERGING_OF_ELEMENTS)}
                        onDialogClosed={() => this.onDialogClosed()}
                    />
                    <DialogContent>
                        <div>
                            <NotSucceededAlert opened={this.state.showBackendCallFailed}
                                               text={alertNotSuccessMessage}
                                               onClose={() => this.handleChange("showBackendCallFailed", false)}/>
                        </div>
                        <div className={classes.dialogLabelDiv}>
                            {targetItem}
                        </div>
                        <div>
                            <ExtGridWrapper
                                columns={ElementsGridDef.getGridColDef()}
                                rows={selectedRows}
                                rowCount={selectedRows.length}
                                getRowId={row => row.identifier}
                                onRowClick={(param: GridRowParams) => this.setSelectedRowToMerge(param)}
                                peristentStateId={PersistentStateId.MERGE_ELEMENTS_GRID}
                                resourceId={""}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            autoFocus
                            variant={"contained"}
                            onClick={(e) => this.saveChanges()}
                        >
                            {_transl(ElementTranslationKey.DIALOG_MERGE_ELEMENTS_MERGE_ELEMENTS)}
                        </Button>
                        <CancelButton onClick={() => this.onDialogClosed()}/>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({})

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(MergeElementsDialog));
