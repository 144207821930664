import {OpenReason, SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import {GridAction} from "./GridAction";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme, alpha} from "@mui/material/styles"
import {TooltipProps} from "@mui/material/Tooltip";
import {SyntheticEvent} from "react";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            width: "48px",
            height: "48px",
        },
        speedDial: {
            position: "absolute",
            paddingTop: "6px",
            "& > .MuiFab-root": {
                width: "36px",
                height: "36px"
            }
        },
        staticTooltipLabel: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[800],
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.button.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            padding: "4px 8px",
            width: "max-content",
            boxShadow: theme.shadows[6],
        },
        fab: {
            "&.Mui-disabled": {
                color: alpha(theme.palette.action.disabled, 0.5),
                backgroundColor: alpha(theme.palette.action.disabledBackground, 0.4)
            }
        },
        staticTooltipDisabled: {
            "& $staticTooltipLabel": {
                opacity: 0.68
            }
        },
    })
);

export interface GridActionsMenuProps extends GridAction {
    open: boolean,
    onOpen: (event: SyntheticEvent<{}>, reason: OpenReason) => void,
    onClose: () => void,
    visibleResolver: (action: GridAction) => boolean,
    disabledResolver: (action: GridAction) => boolean,
    onActionClick: (action: GridAction) => void,
    actionTooltipPlacement?: TooltipProps['placement'],
}

export function GridActionsMenu(props: GridActionsMenuProps) {
    const onOpen = props.onOpen ?? (() => {});
    const onClose = props.onClose ?? (() => {});
    const tooltipPlacement = props.actionTooltipPlacement ?? "right";

    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <SpeedDial open={props.open}
                       ariaLabel={props.tooltip || "menu"}
                       title={props.tooltip}
                       icon={<SpeedDialIcon icon={props.icon} />}
                       direction={"down"}
                       onOpen={onOpen}
                       onClose={onClose}
                       className={classes.speedDial}
                       key={props.tooltip}>
                {props.childActions
                    .filter((action) => props.visibleResolver(action))
                    .map((action) => {
                    const disabled = props.disabledResolver(action);
                    const onClick = disabled
                            ? () => {}
                            : () => props.onActionClick(action);
                    return <SpeedDialAction classes={{
                                                // @ts-ignore
                                                staticTooltip: (disabled && props.open) ? classes.staticTooltipDisabled : undefined,
                                                staticTooltipLabel: classes.staticTooltipLabel,
                                                fab: classes.fab
                                            }}
                                            icon={action.icon}
                                            tooltipTitle={action.tooltip}
                                            tooltipOpen={true}
                                            tooltipPlacement={tooltipPlacement}
                                            onClick={onClick}
                                            FabProps={{
                                                disabled: disabled
                                            }}
                                            key={action.tooltip} />
                    })
                }
            </SpeedDial>
        </div>
    );
}
