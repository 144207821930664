import {UsersTranslationKey} from "./UsersTranslationKey";
import {_transl, TranslationFunction} from "../../../../store/localization/TranslMessasge";

export class CollectionPermissionLevelTranslator {

    private static TRANSLATION_KEY_MAP: {[index: string]: string} = {
        'E': UsersTranslationKey.PERMISSION_EDITOR,
        'R': UsersTranslationKey.PERMISSION_READER,
    }

    private static readonly DEFAULT_INSTANCE = new CollectionPermissionLevelTranslator(_transl);

    public static get() {
        return CollectionPermissionLevelTranslator.DEFAULT_INSTANCE;
    }

    constructor(private readonly translate: TranslationFunction) {}

    translateCollectionPermissionLevel(collectionPermissionLevel: string | null): string | null {
        if (collectionPermissionLevel) {
            return this.translate(CollectionPermissionLevelTranslator.TRANSLATION_KEY_MAP[collectionPermissionLevel as string]) ?? null;
        } else {
            return null;
        }
    }
}