export interface RelationshipAclDto {
    canUpdate: boolean,
    canDelete: boolean,
}

export class RelationshipAclDto {
    static builder(): RelationshipAclDtoBuilder {
        return new RelationshipAclDtoBuilder();
    }
}

export class RelationshipAclDtoBuilder {
    _canUpdate: boolean;
    _canDelete: boolean;

    constructor() {
        this._canUpdate = false;
        this._canDelete = false;
    }

    canUpdate(canUpdate: boolean) {
        this._canUpdate = canUpdate;
        return this;
    }

    canDelete(canDelete: boolean) {
        this._canDelete = canDelete;
        return this;
    }

    build(): RelationshipAclDto {
        return {
            canUpdate: this._canUpdate,
            canDelete: this._canDelete,
        }
    }
}
