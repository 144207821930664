import {withStyles} from "@mui/styles";
import {StepConnector} from "@mui/material";
import {Theme} from "@mui/material/styles"

const WizardStepConnector = withStyles((theme: Theme) => ({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
}))(StepConnector);

export default WizardStepConnector;
