import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";
import {ResizeHandleType} from "./PositionHandle";
import {PointIncrement} from "../../common/PointIncrement";
import {ISnappingFunction} from "../GridManager";
import {ModelManager} from "../ModelManager";
import {Area} from "../../util/GeometryUtils";
import {ArchimateElement} from "../../../archimate/ArchimateElement";
import {UpdateInfo} from "./PositionManagerUtils";
import {Dimensions, NodeDimensionsExtractor} from "./NodeDimensionsExtractor";
import {DiagramDefaultsDto} from "../../../apis/diagram/DiagramDefaultsDto";

class XDim {
    constructor(public x: number,
                public width: number) {
    }
}

class YDim {
    constructor(public y: number,
                public height: number) {
    }
}

export class NodeResizeCalculator {

    private readonly nodeDimensionsExtractor: NodeDimensionsExtractor;

    constructor(diagramDefaults: DiagramDefaultsDto) {
        this.nodeDimensionsExtractor = new NodeDimensionsExtractor(diagramDefaults);
    }

    public computeDimensionsOnNodesResize(nodes: Array<IDiagramNodeDto>,
                                          resizeType: ResizeHandleType,
                                          increment: PointIncrement,
                                          snappingFunction: ISnappingFunction,
                                          modelManager: ModelManager): {[id: string]: Area} {
        const dimensionsMap: {[id: string]: Area} = {};
        nodes.forEach(node => {
            dimensionsMap[node.identifier] = this.computeUpdateInfoOnNodeResize(node, resizeType, increment, snappingFunction, modelManager).updatedArea;
        })
        return dimensionsMap;
    }

    public computeUpdateInfoOnNodeResize(node: IDiagramNodeDto,
                                         resizeType: ResizeHandleType,
                                         increment: PointIncrement,
                                         snappingFunction: ISnappingFunction,
                                         modelManager: ModelManager): UpdateInfo {
        const minDimension = this.extractMinDimensions(node, modelManager);
        let pointerPositionIncrementX = increment.incrementX;
        let pointerPositionIncrementY = increment.incrementY;

        let area: Area = Area.fromNode(node);
        const nX = node.x, nY = node.y, nW = node.w, nH = node.h;

        let incrementX: number = 0;
        let incrementY: number = 0;

        const keepAspectRatio = this.isJunctionElement(node, modelManager);

        switch (resizeType) {
            case ResizeHandleType.N: {
                const dim = this.computeN(node, pointerPositionIncrementY, minDimension.height, snappingFunction);
                area = Area.from(nX, dim.y, nW, dim.height);

                incrementY = dim.y - node.y;
            } break;
            case ResizeHandleType.S: {
                const h = this.computeS(node, pointerPositionIncrementY, minDimension.height, snappingFunction);
                area = Area.from(nX, nY, nW, h);

                incrementY = h - node.h;
            } break;
            case ResizeHandleType.E: {
                const w = this.computeE(node, pointerPositionIncrementX, minDimension.width, snappingFunction);
                area = Area.from(nX, nY, w, nH);

                incrementX = w - node.w;
            } break;
            case ResizeHandleType.W: {
                const dim = this.computeW(node, pointerPositionIncrementX, minDimension.width, snappingFunction);
                area = Area.from(dim.x, nY, dim.width, nH)

                incrementX = dim.x - node.x;
            } break;
            case ResizeHandleType.NW: {
                let dimN = this.computeN(node, pointerPositionIncrementY, minDimension.height, snappingFunction);
                let dimW = this.computeW(node, pointerPositionIncrementX, minDimension.width, snappingFunction);

                if (keepAspectRatio) {
                    const max = Math.max(dimW.width, dimN.height);
                    dimW.x = dimW.x - (max - dimW.width);
                    dimW.width = max;
                    dimN.y = dimN.y - (max - dimN.height);
                    dimN.height = max;
                }

                area = Area.from(dimW.x, dimN.y, dimW.width, dimN.height);

                incrementY = dimN.y - node.y;
                incrementX = dimW.x - node.x;
            } break;
            case ResizeHandleType.NE: {
                let dimN = this.computeN(node, pointerPositionIncrementY, minDimension.height, snappingFunction);
                let w = this.computeE(node, pointerPositionIncrementX, minDimension.width, snappingFunction);

                if (keepAspectRatio) {
                    const max = Math.max(w, dimN.height);
                    dimN.y = dimN.y - (max - dimN.height);
                    dimN.height = max;
                    w = max;
                }

                area = Area.from(nX, dimN.y, w, dimN.height);

                incrementY = dimN.y - node.y;
                incrementX = w - node.w;
            } break;
            case ResizeHandleType.SW: {
                let dimW = this.computeW(node, pointerPositionIncrementX, minDimension.width, snappingFunction);
                let h = this.computeS(node, pointerPositionIncrementY, minDimension.height, snappingFunction);

                if (keepAspectRatio) {
                    const max = Math.max(dimW.width, h);
                    dimW.x = dimW.x - (max - dimW.width);
                    dimW.width = max;
                    h = max;
                }

                area = Area.from(dimW.x, nY, dimW.width, h);

                incrementX = dimW.x - node.x;
                incrementY = h - node.h;
            } break;
            case ResizeHandleType.SE: {
                let h = this.computeS(node, pointerPositionIncrementY, minDimension.height, snappingFunction);
                let w = this.computeE(node, pointerPositionIncrementX, minDimension.width, snappingFunction);

                if (keepAspectRatio) {
                    const max = Math.max(w, h);
                    w = h = max;
                }

                area = Area.from(nX, nY, w, h);

                incrementY = h - node.h;
                incrementX = w - node.w;
            }
        }

        return new UpdateInfo(new PointIncrement(incrementX, incrementY), area);
    }

    private extractMinDimensions(node: IDiagramNodeDto,
                                 modelManager: ModelManager): Dimensions {
        const elementTypeCode = modelManager.getElementType(node.elementIdentifier);
        const elementType = elementTypeCode && ArchimateElement.valueOf(elementTypeCode);
        return this.nodeDimensionsExtractor.extractMinDimensions(elementType?.standardName);
    }

    private isJunctionElement(node: IDiagramNodeDto, modelManager: ModelManager): boolean {
        if (node.elementIdentifier) {
            const elementType = modelManager.getElementType(node.elementIdentifier);
            if (elementType && ArchimateElement.isJunction(elementType)) {
                return true;
            }
        }
        return false;
    }

    private computeN(node: IDiagramNodeDto, incrementY: number, minHeight: number, snappingFunction: ISnappingFunction) {
        const nY = node.y;
        const nYH = node.y + node.h;

        const actualY = snappingFunction.snap(nY + incrementY);
        const minY = nYH - minHeight;
        const y = Math.min(actualY, minY);
        const h = nYH - y;

        return new YDim(y, h);
    }

    private computeS(node: IDiagramNodeDto, incrementY: number, minHeight: number, snappingFunction: ISnappingFunction) {
        const nH = node.h;
        const actualH = snappingFunction.snap(node.y + nH + incrementY) - node.y;

        return Math.max(actualH, minHeight);
    }

    private computeE(node: IDiagramNodeDto, incrementX: number, minWidth: number, snappingFunction: ISnappingFunction) {
        const nW = node.w;
        const actualW = snappingFunction.snap(node.x + nW + incrementX) - node.x;

        return Math.max(actualW, minWidth);
    }

    private computeW(node: IDiagramNodeDto, incrementX: number, minWidth: number, snappingFunction: ISnappingFunction) {
        const nX = node.x;
        const nXW = node.x + node.w;

        const actualX = snappingFunction.snap(nX + incrementX);
        const minX = nXW - minWidth;
        const x = Math.min(actualX, minX);
        const w = nXW - x;

        return new XDim(x, w);
    }

}