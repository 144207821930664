import { Avatar } from "@mui/material";
import {AvatarUtils} from "./AvatarUtils";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatAvatar: {
            fontSize: "1rem",
        },
    })
);

export const DEFAULT_AVATAR_SIZE = 32;

export interface ChatAvatarProps {
    name: string;
    avatarSize?: number;
}

export default function ChatAvatar({name, avatarSize}: ChatAvatarProps) {

    const classes = useStyles();
    avatarSize = avatarSize ?? DEFAULT_AVATAR_SIZE;

    return (
        <Avatar className={classes.chatAvatar} {...AvatarUtils.stringAvatar(name, avatarSize, avatarSize)} />
    );
}