import React, {useEffect, useState} from "react";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {
    BulkOperationsCollectionsTranslation
} from "../../diagrams/bulk/BulkOperationsCollectionsTranslation";
import Snackbar from "../../snackbar/Snackbar";
import Api from "../../../../../common/Api";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import PickList from "../../../../../components/picklist/PickList";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import {CancelButton} from "../../../../../components/button/CancelButton";
import Dialog from "../../../../../components/dialogs/Dialog";
import {CollectionDto} from "../../../../../common/apis/collection/CollectionDto";
import collectionsService from "../../collections/service/CollectionsService";
import {CollectionsTranslationKey} from "../../collections/CollectionsTranslationKey";
import {SaveButton} from "../../../../../components/button/SaveButton";
import {ElementDto} from "../../../../../common/apis/element/ElementDto";

interface AddBulkCollectionsToElementsDialogProps {
    isOpened: boolean,
    onClosed: () => void,
    elements: ElementDto[]
    refetchFilter: () => void,
}

export default function AddBulkCollectionsToElementsDialog(props: AddBulkCollectionsToElementsDialogProps) {
    const {isOpened, onClosed, elements, refetchFilter} = props;

    const [collections, setCollections] = useState<CollectionDto[]>([]);
    const [pickedOptions, setPickedOptions] = useState<CollectionDto[]>([]);

    useEffect(() => {
        let isUnmounted = false;
        collectionsService.getAll()
            .then((allCollections) => {
                if (!isUnmounted) {
                    setCollections(allCollections.filter((option) => option.acl.canAssignObjectsToCollection));
                }
            })
            .catch((error) => Snackbar.error(_transl(CollectionsTranslationKey.NOT_FETCHED)))
        return () => {
            isUnmounted = true;
        }
    }, []);

    function saveChanges() {
        const collectionCodes = (pickedOptions as CollectionDto[]).map(options => options.code);
        Api.elements.bulkAssignCollections(collectionCodes, elements.map(value => value.identifier))
            .subscribe(
                () => {
                    onClosed();
                    refetchFilter();
                },
                (err) => {
                    Snackbar.error(_transl(BulkOperationsCollectionsTranslation.COLLECTIONS_ADD_FAILED_TEXT), err);
                }
            );
    }

    return (
        <Dialog open={isOpened}
                aria-labelledby="picklist-dialog-title"
                onClose={onClosed}
                fullWidth={true}
                maxWidth={'md'}>
            <DialogTitle id="picklist-dialog-title"
                         title={_transl(BulkOperationsCollectionsTranslation.COLLECTIONS_ADD_TITLE)}
                         onDialogClosed={onClosed}/>
            <DialogContent>
                <PickList
                    initialAvailableOptions={collections}
                    initialPickedOptions={[]}
                    getOptionText={(item: unknown) => item ? (item as CollectionDto).name : ""}
                    availableOptionsTitle={_transl(BulkOperationsCollectionsTranslation.COLLECTIONS_AVAILABLE_OPTIONS)}
                    pickedOptionsTitle={_transl(BulkOperationsCollectionsTranslation.COLLECTIONS_ADD_TITLE_PICKED_OPTIONS)}
                    onPickedOptionsUpdated={(pickedOptions) => setPickedOptions(pickedOptions as Array<CollectionDto>)}/>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={saveChanges} disabled={pickedOptions.length === 0}/>
                <CancelButton onClick={onClosed}/>
            </DialogActions>
        </Dialog>
    );
}