import {Button} from "@mui/material";
import {ColorSwatch} from "./ColorSwatch";
import {ColorDto} from "../../common/apis/Color";

export interface ColorSwatchButtonProps {
    color: ColorDto,
    title?: string,
    disabled?: boolean,
    onClick: (event: any) => void
}

export function ColorSwatchButton(props: ColorSwatchButtonProps) {
    const color = props.color;
    const brightness = props.disabled ? 0.5 : 1.0;

    return (
        <Button title={props.title} disabled={props.disabled} onClick={props.onClick} variant={"outlined"}>
            <ColorSwatch color={color} brightness={brightness} />
        </Button>
    );
}
