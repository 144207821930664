import React, {useEffect, useState} from "react";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {HierarchyTreeTranslationKey} from "../HierarchyTreeTranslationKey";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import Grid from "../../../../../components/dialogs/Grid";
import {SaveButton} from "../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../components/button/CancelButton";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import hierarchyTreeService from "../service/HierarchyTreeService";
import Snackbar from "../../snackbar/Snackbar";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import {ReferenceTreeDataDto, ReferenceType} from "../service/HierarchyTreeDataDto";
import {PagePresetsDto} from "../../presets/client/PagePresetsDto";
import pagePresetsClient from "../../presets/client/PagePresetsClient";
import {ELEMENTS_PAGE_ID} from "../../elements/ElementsPage";
import {TextFieldWithMoreIcon} from "../../../../../components/TextFieldWithAdornmentIcon";
import TextField from "../../../../../components/fields/textfield/TextField";
import {CommonTranslation} from "../../CommonTranslation";
import PagePresetsSelectDialog from "../../presets/PagePresetsSelectDialog";
import {HierarchyTreeCreateDto} from "../service/HierarchyTreeCreateDto";
import {HierarchyTreeUpdateDto} from "../service/HierarchyTreeUpdateDto";

export interface HierarchyTreeElementReportReferenceUpdateDialogProps {
    open: boolean;
    treeId?: string;
    parentTreeId?: string | null;
    onUpdated?: (parentTreeId?: string | null) => void;
    onClose: () => void;
}

export default function HierarchyTreeElementReportReferenceUpdateDialog(props: HierarchyTreeElementReportReferenceUpdateDialogProps) {
    const {open, treeId, parentTreeId, onUpdated, onClose} = props;

    const [name, setName] = useState<string>("");
    const [nameErrorMessage, setNameErrorMessage] = useState<string>();
    const [presets, setPresets] = useState<PagePresetsDto>();
    const [presetsErrorMessage, setPresetsErrorMessage] = useState<string>();

    const [presetsSelectDialogOpen, setPresetsSelectDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            setName("");
            setPresets(undefined);

            if (treeId) {
                hierarchyTreeService.findTree(treeId)
                    .then((tree) => {
                        setName(tree.title);
                        const treeData = tree.data as ReferenceTreeDataDto;
                        return pagePresetsClient.find(ELEMENTS_PAGE_ID, Number(treeData.reference));
                    })
                    .then((presets) => {
                        setPresets(presets);
                    })
                    .catch((err) => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA), err));
            }
        }
    }, [open, treeId]);

    function save() {
        if (validate()) {
            if (!treeId) {
                createTree();
            } else {
                updateTree(treeId);
            }
        }
    }

    function validate(): boolean {
        let isValid = true;
        if (name === "") {
            setNameErrorMessage(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isValid = false;
        } else {
            setNameErrorMessage(undefined);
        }

        if (presets === undefined) {
            setPresetsErrorMessage(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isValid = false;
        } else {
            setPresetsErrorMessage(undefined);
        }

        return isValid;
    }

    function createTree() {
        const treeCreate: HierarchyTreeCreateDto<ReferenceTreeDataDto> = {
            parentId: parentTreeId ?? undefined,
            title: name,
            data: buildTreeData(presets!.id)
        };
        hierarchyTreeService.createTree(treeCreate)
            .then(() => {
                onSuccess();
            })
            .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), err));
    }

    function updateTree(treeId: string) {
        const treeUpdate: HierarchyTreeUpdateDto<ReferenceTreeDataDto> = {
            title: name,
            data: buildTreeData(presets!.id)
        };
        hierarchyTreeService.updateTree(treeId, treeUpdate)
            .then(() => {
                onSuccess();
            })
            .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), err));
    }

    function buildTreeData(presetsId: number): ReferenceTreeDataDto {
        return {
            type: "Reference",
            referenceType: ReferenceType.ELEMENT_PAGE_REPORT,
            reference: presetsId.toString(),
        };
    }

    function onSuccess() {
        onUpdated?.(parentTreeId);
        onClose();
        Snackbar.success(_transl(CommonTranslation.DATA_SAVED_SUCESSFULLY));
    }

    return (
        <>
            <PagePresetsSelectDialog open={presetsSelectDialogOpen}
                                     gridId={ELEMENTS_PAGE_ID}
                                     onClose={() => setPresetsSelectDialogOpen(false)}
                                     onPresetsSelected={setPresets}
            />

            <Dialog
                open={open}
                onClose={() => onClose}
                maxWidth={"sm"}
            >
                <DialogTitle
                    title={_transl(HierarchyTreeTranslationKey.REFERENCE_ELEMENT_REPORT)}
                    onDialogClosed={onClose}
                />

                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField label={_transl(HierarchyTreeTranslationKey.REFERENCE_NAME)}
                                       required={true}
                                       value={name}
                                       errorMessage={nameErrorMessage}
                                       onChange={value => setName(value.trim())} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldWithMoreIcon label={_transl(HierarchyTreeTranslationKey.REFERENCE_PRESETS)}
                                                   tooltipTitle={_transl(HierarchyTreeTranslationKey.REFERENCE_FIND_PRESETS)}
                                                   key={presets?.name || ""}
                                                   defaultValue={presets?.name ?? ""}
                                                   required={true}
                                                   errorMessage={presetsErrorMessage}
                                                   onClick={() => setPresetsSelectDialogOpen(true)} />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <SaveButton onClick={save}/>
                    <CancelButton onClick={onClose}/>
                </DialogActions>
            </Dialog>
        </>
    );
}
