import {MenuItem, TextField} from "@mui/material";
import React, {useContext} from "react";
import {HierarchyTreeDataType} from "../../service/HierarchyTreeDataType";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {HierarchyTreeTranslationKey} from "../../HierarchyTreeTranslationKey";
import {HierarchyTreeDataTypeEnum} from "./HierarchyTreeDataTypeEnum";
import ConfigurationContext, {Configuration} from "../../../../../../common/ConfigurationContext";

export interface DataTypeSelectProps {
    initialValue: HierarchyTreeDataType,
    onChange: (treeDataType: HierarchyTreeDataType) => void
}

export default function DataTypeSelect({initialValue, onChange}: DataTypeSelectProps) {

    const {featureStatuses} = useContext<Configuration>(ConfigurationContext);

    function onDataTypeSelected(newDataType: HierarchyTreeDataType): void {
        onChange(newDataType);
    }

    return (
        <>
            <TextField
                size="small"
                label={_transl(HierarchyTreeTranslationKey.DATA_TYPE_SELECT_LABEL)}
                select
                value={initialValue}
                onChange={e => onDataTypeSelected(e.target.value as HierarchyTreeDataType)}
                fullWidth={true}
                variant="outlined"
            >
                {Object.values(HierarchyTreeDataType).map((treeDataType) => {
                    if (treeDataType === HierarchyTreeDataType.REFERENCE) {
                        return null;
                    }
                    if (treeDataType === HierarchyTreeDataType.QUERIES && !featureStatuses.graphQueryEnabled) {
                        return null;
                    }
                    return (
                        <MenuItem key={treeDataType} value={treeDataType}>
                            {_transl(HierarchyTreeDataTypeEnum[treeDataType].translationKey)}
                        </MenuItem>
                    );
                })}
            </TextField>
        </>
    )
}


