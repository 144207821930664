import Constants from "../../Constants";
import Api from "../../Api";
import {catchError, map} from "rxjs/operators";
import {Observable, of, throwError} from "rxjs";

import {EditorSettingsDto, GeneralSettingsDto, UserSettingsDto} from "./UserSettingsDto";
import {GridPresets} from "../../../components/grid/presets/GridPresets";

enum SettingsComponent {
    EDITOR = "editor",
    GENERAL = "general",
    GRID_ELEMENTS = "grid_elements",
    GRID_DIAGRAMS = "grid_diagrams",
}

const USERS_ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/users";

export interface UserSettingsService {

    getGeneralSettings(): Promise<GeneralSettingsDto | undefined>;
    updateGeneralSettings(settings: GeneralSettingsDto): Promise<void>;

    getEditorSettings(): Promise<EditorSettingsDto | undefined>;
    updateEditorSettings(settings: EditorSettingsDto): Promise<void>;

    getElementsGridSettings(): Promise<GridPresets | undefined>;
    updateElementsGridSettings(settings: GridPresets): Promise<void>;

    getDiagramsGridSettings(): Promise<GridPresets | undefined>;
    updateDiagramsGridSettings(settings: GridPresets): Promise<void>;
}

class RxJsUserSettingsService implements UserSettingsService {

    async getGeneralSettings(): Promise<GeneralSettingsDto | undefined> {
        return this.getUserSettingsForComponent(SettingsComponent.GENERAL)
            .pipe(map(userSettings => userSettings?.settings as GeneralSettingsDto))
            .toPromise();
    }

    async updateGeneralSettings(settings: GeneralSettingsDto): Promise<void> {
        return this.updateUserSettingsForComponent(SettingsComponent.GENERAL, settings)
            .toPromise();
    }

    async getEditorSettings(): Promise<EditorSettingsDto | undefined> {
        return this.getUserSettingsForComponent(SettingsComponent.EDITOR)
            .pipe(map(userSettings => userSettings?.settings as EditorSettingsDto))
            .toPromise();
    }

    async updateEditorSettings(settings: EditorSettingsDto): Promise<void> {
        return this.updateUserSettingsForComponent(SettingsComponent.EDITOR, settings)
            .toPromise();
    }

    async getElementsGridSettings(): Promise<GridPresets | undefined> {
        return this.getUserSettingsForComponent(SettingsComponent.GRID_ELEMENTS)
            .pipe(map(userSettings => userSettings?.settings as GridPresets))
            .toPromise();
    }

    async updateElementsGridSettings(settings: GridPresets): Promise<void> {
        return this.updateUserSettingsForComponent(SettingsComponent.GRID_ELEMENTS, settings)
            .toPromise();
    }

    async getDiagramsGridSettings(): Promise<GridPresets | undefined> {
        return this.getUserSettingsForComponent(SettingsComponent.GRID_DIAGRAMS)
                .pipe(map(userSettings => userSettings?.settings as GridPresets))
                .toPromise();
    }

    async updateDiagramsGridSettings(settings: GridPresets): Promise<void> {
        return this.updateUserSettingsForComponent(SettingsComponent.GRID_DIAGRAMS, settings)
                .toPromise();
    }

    private getUserSettingsForComponent(component: SettingsComponent): Observable<UserSettingsDto | undefined> {
        return Api.createAjax({
            url: USERS_ENDPOINT_URL + "/settings/" + component,
            method: "GET",
        }).pipe(
            map(response => response.response as UserSettingsDto),
            catchError(error => {
                if (error.status === 404) {
                    return of(undefined);
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private updateUserSettingsForComponent(component: SettingsComponent, settings: any): Observable<void> {
        return Api.createAjax({
            url: USERS_ENDPOINT_URL + "/settings/" + component,
            method: "PUT",
            body: {
                settings: settings
            }
        }).pipe(
            map(response => {})
        );
    }
}

const userSettingsService: UserSettingsService = new RxJsUserSettingsService();
export default userSettingsService;
