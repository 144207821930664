export default class CopyUtils {

    public static shallowCopy(object: any) {
        return Object.assign({}, object);
    };

    public static deepCopy(object: any) {
        return JSON.parse(JSON.stringify(object));
    };

}
