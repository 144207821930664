import DiagramAclDto from "../../../../../common/apis/diagram/DiagramAclDto";
import {DiagramInfoDto} from "../../../../../common/apis/diagram/DiagramInfoDto";


export interface DiagramController {
    isDiagramReadOnly(diagramAcl: DiagramAclDto | undefined): boolean;
    canUpdateCollections(diagramInfoDtos: Array<DiagramInfoDto>): boolean;
    canUpdateAllDiagrams(diagrams: DiagramInfoDto[]): boolean;
    canDeleteAllDiagrams(diagrams: DiagramInfoDto[]): boolean;
}

class DiagramControllerImpl implements DiagramController {

    isDiagramReadOnly(diagramAcl: DiagramAclDto | undefined): boolean {
        let isReadOnly = true;
        if (diagramAcl) {
            isReadOnly = !diagramAcl.canUpdate;
        }
        return isReadOnly;
    }

    canUpdateCollections(diagramInfoDtos: Array<DiagramInfoDto>) {
        return diagramInfoDtos
            .map(dto => dto.acl)
            .map((acl) => acl.canUpdateCollection)
            .every(value => value);
    }

    canUpdateAllDiagrams(diagrams: DiagramInfoDto[]): boolean {
        return diagrams.every(diagram => diagram.acl.canUpdate);
    }

    canDeleteAllDiagrams(diagrams: DiagramInfoDto[]): boolean {
        return diagrams.every(diagram => diagram.acl.canDelete);
    }

}

export default function constructDiagramController(): DiagramController {
    return new DiagramControllerImpl();
}
