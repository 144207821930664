import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Wizard from "../../../../../components/wizard/Wizard";
import UploadFilePanel from "../../../../../components/UploadFilePanel";
import {MenuItem, Paper, Select} from "@mui/material";
import {from} from "rxjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {WizardStep} from "../../../../../components/wizard/WizardStep";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {GenerateMetamodelTranslationKey} from "./GenerateMetamodelTranslationKey";
import generateMetamodelService from "./MetamodelService";
import {AjaxResponse} from "rxjs/ajax";
import {BlobUtils} from "../../../../../common/BlobUtils";
import RouteDefinitionUtils from "../../../../../common/routedefinition/RouteDefinitionUtils";
import CommonCssStyles from "../../../../../css/CommonCssStyles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import clsx from "clsx";
import {OpenGroupExchangeFormat, OpenGroupExchangeFormatType} from "../../../../../common/apis/OpenGroupExchangeFormat";
import {useNavigate} from "react-router-dom";
import {generateMetamodelPage} from "../../../../../common/routes/AppRoutes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uploadContentFileName: {
            fontSize: "1.4em",
            marginBottom: theme.spacing(5),
        },
        uploadContentFileTypeLabel: {
            marginRight: theme.spacing(2),
            fontWeight: "bolder",
        },
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {paddingTop: theme.spacing(7)}),
    })
);

export default function GenerateMetamodelPage() {

    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [modelFormatType, setModelFormatType] = useState<OpenGroupExchangeFormatType | null>(OpenGroupExchangeFormatType.ARCHIMATE_VERSION_3_1);

    const navigate = useNavigate();

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(_transl(GenerateMetamodelTranslationKey.CHOOSE_MODEL_TITLE), <CloudUploadIcon/>, _transl(GenerateMetamodelTranslationKey.CHOOSE_ARCHIMATE_MODEL), false,
                () => renderSelectFileStep(), () => canProceedFromSelectFileStep())
        ];
    }

    function resetImport() {
        navigate(RouteDefinitionUtils.resolvePath(generateMetamodelPage));
    }

    function renderSelectFileStep(): JSX.Element {
        const uploadPanelContentGetter = selectedFile != null ? () => createFileTypeSelect() : undefined;

        return <UploadFilePanel uploadAttachment={file => setSelectedFile(file)}
                                uploadPanelContent={uploadPanelContentGetter}/>
    }

    function createFileTypeSelect(): JSX.Element {
        return <React.Fragment>
            <div className={classes.uploadContentFileName}>{selectedFile?.name}</div>
            <div>
                <span
                    className={classes.uploadContentFileTypeLabel}>{_transl(GenerateMetamodelTranslationKey.CHOOSE_FILE_TYPE)}</span>
                <span>
                    <Select
                        label={_transl(GenerateMetamodelTranslationKey.TYPE)}
                        id="file-type"
                        value={`${modelFormatType}`}
                        style={{minWidth: "12em"}}
                        onChange={event => {
                            const formatName = event.target.value as string;
                            const modelFormatType = OpenGroupExchangeFormat.valueOf(formatName)?.archimateFileFormat || null;
                            setModelFormatType(modelFormatType);
                        }}
                    >
                        {OpenGroupExchangeFormat.values()
                            .map(format =>
                                <MenuItem value={`${format.archimateFileFormat}`}>
                                    {`${format.caption} (${format.fileExtension})`}
                                </MenuItem>
                            )
                        }
                    </Select>
                </span>
            </div>
        </React.Fragment>
    }

    function canProceedFromSelectFileStep(): boolean {
        return modelFormatType != null && selectedFile != null;
    }

    function doExport(): Promise<any> {
        return from(generateMetamodelService.generateMetamodel(selectedFile as File, OpenGroupExchangeFormat[modelFormatType as OpenGroupExchangeFormatType].id))
            .toPromise();
    }

    return <Paper className={classes.page}>
        <div className={classes.headerPageSegment}>
            <Typography variant="h6">
                {_transl(GenerateMetamodelTranslationKey.GENERATED_METAMODEL)}
            </Typography>
        </div>
        <Divider />
        <div className={clsx(classes.controlPageSegment)}>
            <Wizard steps={getSteps()}
                    lastStepLabel={_transl(GenerateMetamodelTranslationKey.GENERATED_METAMODEL)}
                    lastStepButtonLabel={_transl(GenerateMetamodelTranslationKey.GENERATE_METAMODEL)}
                    lastStepAction={() => doExport()}
                    lastStepActionSuccessProcessor={(result) => {
                        const response = result as AjaxResponse;
                        const blob = new Blob([response.response], {type: "text/xml"});

                        BlobUtils.saveBlob(blob, "metamodel.xml");
                    }}
                    lastStepActionInProgressText={_transl(GenerateMetamodelTranslationKey.METAMODEL_GENERATING_IN_PROGRESS)}
                    lastStepActionSuccessfulText={_transl(GenerateMetamodelTranslationKey.METAMODEL_WAS_EXPORTED)}
                    lastStepActionFailedText={_transl(GenerateMetamodelTranslationKey.METAMODEL_EXPORT_FAILED)}
                    cancelWizard={() => resetImport()}
                    wizardGridMinHeight={"25em"}
            />
        </div>
    </Paper>;

}
