import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {TransitionGroup} from "react-transition-group";
import {default as MuiAccordion} from "@mui/material/Accordion";
import * as React from "react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../../store/Store";
import {UserDto} from "../../../../../common/apis/user/UserDto";
import chatService, {ChatDto} from "../../../../../common/apis/chat/ChatService";
import diagramService from "../../diagrams/service/DiagramService";
import ChatComment from "../ChatComment";
import ChatReplyBar from "./ChatReplyBar";
import ChatAccordionSummary from "./ChatAccordionSummary";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {Collapse} from "@mui/material";
import elementService from "../../elements/service/ElementService";
import Snackbar from "../../snackbar/Snackbar";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accordionSummary: {
            ' & .MuiAccordionSummary-content': {
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            },
        },
        accordionDetails: {
            paddingLeft: '40px'
        }
    })
);

interface ChatAccordionProps {
    chat: ChatDto,
    onCommentEdited: (chatId: number, commentId: number, message: string) => void;
    onChatsUpdated: () => void;
    openElementDetail?: (id: string) => void;
    elementId?: string;
    diagramId?: string;
}

export interface Info {
    id: string,
    title?: string,
    viewDiagram?: boolean,
    viewElement?: boolean,
}

export default function ChatAccordion(props: ChatAccordionProps) {
    const {chat, onCommentEdited, onChatsUpdated, elementId, diagramId, openElementDetail} = props;
    const classes = useStyles();
    const user = useSelector<IApplicationState>(state => state.user.userData) as UserDto;

    const [expanded, setExpanded] = React.useState(false);
    const [info, setInfo] = useState<Info | undefined>(undefined);

    useEffect((() => {
            if (elementId && chat.diagramId) {
                diagramService.findDiagramById(chat.diagramId)
                    .then(diagram => setInfo({
                        id: diagram.diagramInfo.identifier,
                        title: diagram.diagramInfo.name,
                        viewDiagram: true
                    }));
            } else if (diagramId && chat.elementId) {
                elementService.findElementById(chat.elementId).then(element => {
                    if (element) {
                        setInfo({
                            id: element.identifier,
                            title: element.name,
                            viewElement: true
                        });
                    }
                });
            }
        }
    ), [elementId, chat.diagramId, chat.elementId, diagramId]);

    useEffect(() => {
        if (expanded || chat.chatComments.length === 1) {
            chatService.markAsRead(chat.id)
                .catch((err) => Snackbar.error(_transl(ErrorTranslationKey.UNEXPECTED_ERROR_OCCURRED), err));
        }
    }, [expanded, chat]);

    function onChatCommentEdited(commentId: number, message: string) {
        onCommentEdited(chat.id, commentId, message);
    }

    return (
        <>
            <MuiAccordion expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
                <AccordionSummary className={classes.accordionSummary} style={{backgroundColor: 'white'}}>
                    <ChatAccordionSummary chat={chat}
                                          expanded={expanded}
                                          info={info}
                                          onCommentEdited={onChatCommentEdited}
                                          onChatsUpdated={onChatsUpdated}
                                          openElementDetail={openElementDetail}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.accordionDetails}>
                        <TransitionGroup>
                            {
                                chat.chatComments.map((comment, index) =>
                                    <Collapse key={comment.id}>
                                        {index !== 0 && (
                                            <ChatComment
                                                key={comment.id}
                                                chatId={chat.id}
                                                comment={comment}
                                                onEdited={onChatCommentEdited}
                                                onDeleted={onChatsUpdated}
                                            />
                                        )}
                                    </Collapse>
                                )
                            }
                        </TransitionGroup>
                    </div>
                    {chat.acl.canCreateComments &&
                        <ChatReplyBar
                            chat={chat}
                            user={user}
                            onCommentCreated={onChatsUpdated}/>
                    }
                </AccordionDetails>
            </MuiAccordion>
        </>
    );
}