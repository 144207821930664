import {IconButton, styled, Tooltip} from "@mui/material";
import React, {ElementType} from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";

export interface AppBarIconButtonProps {
    tooltip?: string;
    isActive?: boolean;
    onClick?: () => void;
    icon: ElementType<SvgIconProps>;
}

const StyledIconButton = styled(IconButton)(({theme}) => ({
    "&": {
        color: "white"
    },
    "&.active": {
        color: theme.palette.primary.main,
        backgroundColor: "white"
    }
}));

const AppBarIconButton = React.forwardRef<any, AppBarIconButtonProps>((props, ref) => {
    const {tooltip, isActive, onClick, icon : Icon} = props;

    const className = isActive ? "active" : undefined;

    return (
        <Tooltip title={tooltip ?? ""} arrow>
            <StyledIconButton ref={ref} className={className} size={"medium"} onClick={onClick}>
                <Icon />
            </StyledIconButton>
        </Tooltip>
    );
});

export default AppBarIconButton;