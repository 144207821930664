import {IDiagramNodeDto} from "../../../../../apis/diagram/IDiagramNodeDto";
import PositionDirection from "./PositionDirection";
import NodeChildNodes from "../../NodeChildNodes";

function findNodeIndex(node: IDiagramNodeDto, nodes: Array<IDiagramNodeDto>) {
    return nodes.map(node => node.identifier)
        .indexOf(node.identifier);
}

export default function isPositionUpdatable(nodes: Array<IDiagramNodeDto>, nodeIdToParentNodeChildrenMap: Map<string, NodeChildNodes>, direction: PositionDirection) {
    if (nodes.length !== 1) {
        return false;
    }
    const node = nodes[0];
    const parentNodes = nodeIdToParentNodeChildrenMap.get(node.identifier) as NodeChildNodes;
    const nodeIndex = findNodeIndex(node, parentNodes.childNodes);

    if (direction === PositionDirection.TO_FRONT) {
        return nodeIndex < (parentNodes.childNodes.length - 1);
    } else if (direction === PositionDirection.TO_BACK) {
        return nodeIndex > 0;
    } else {
        return false;
    }
}