import {_transl} from "../../../../store/localization/TranslMessasge";
import {PropertyTypeTranslationKey} from "./PropertyTranslationTranslationKey";

export const propertyTypes = [
    { value: "string", translationKey: PropertyTypeTranslationKey.TYPE_STRING },
    { value: "currency", translationKey: PropertyTypeTranslationKey.TYPE_CURRENCY },
    { value: "boolean", translationKey: PropertyTypeTranslationKey.TYPE_BOOLEAN },
    { value: "date", translationKey: PropertyTypeTranslationKey.TYPE_DATE },
    { value: "time", translationKey: PropertyTypeTranslationKey.TYPE_TIME },
    { value: "number", translationKey: PropertyTypeTranslationKey.TYPE_NUMBER },
];

export function getLabelForPropertyType(type?: string) {
    var translationKey = propertyTypes.filter((propType) => propType.value === type)
        .map(propType => propType.translationKey);
    return (translationKey.length === 1) ? _transl(translationKey[0]) : type;
}
