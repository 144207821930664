import * as d3 from "d3";
import {GraphDataChart} from "../GraphDataChart";
import EventManager from "./EventManager";

export default class MarkersManager {

    private eventManager: EventManager;


    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
    }

    public init(defsElement: d3.Selection<SVGDefsElement, unknown, any, any>) {
        defsElement.selectAll("marker")
            .data(["arrow"], (elem) => {
                return "id" + elem;
            })
            .join("marker")
            .attr("id", d => `arrow`)
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 38)
            .attr("refY", 0)
            .attr("markerWidth", 6)
            .attr("markerHeight", 6)
            .attr("orient", "auto")
            .append("path")
            .attr("fill", GraphDataChart.LINK_COLOR)
            .attr("d", 'M0,-5L10,0L0,5');
    }

}