import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {StateColorResolver} from "./StateColorResolver";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "inline-block",
            borderRadius: '50%',
            border: "1px solid black",
            width: "1em",
            height: "1em",
        }
    })
);

interface Props {
    stateCode: string,
    stateColorResolver: StateColorResolver,
}

export default function StateCircle({stateCode, stateColorResolver}: Props) {
    const classes = useStyles();
    const backgroundColor = stateColorResolver.resolveColorByCode(stateCode);
    return <span className={classes.root} style={{backgroundColor: backgroundColor}}></span>
}
