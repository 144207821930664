export default class UserFormatter {
    static formatFullName(firstname: string | null | undefined, lastname: string | null | undefined): string {
        let parts = [];
        if (firstname && firstname.length > 0) {
            parts.push(firstname);
        }
        if (lastname && lastname.length > 0) {
            parts.push(lastname);
        }
        return parts.join(" ");
    }
}