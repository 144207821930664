import {INodeEventListener} from "../event/Listener";
import {EventType, INodeEvent} from "../event/Event";
import EventManager from "./EventManager";
import {INode} from "./ModelManager";

export default class NodeAnchorManager implements INodeEventListener {

    private eventManager: EventManager;

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.eventManager.subscribeNodeListener(this, EventType.NODE_DRAG_START);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_DRAG_IN_PROGRESS);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_DRAG_END);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_ANCHOR_MENUITEM_CLICKED);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_UNANCHOR_MENUITEM_CLICKED);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MENU_OPENED);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MENU_CLOSED);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MOUSE_ENTER);
        this.eventManager.subscribeNodeListener(this, EventType.NODE_MOUSE_LEAVE);
    }

    handleNodeEvent(event: INodeEvent): void {
        const jsEvent = event.event;
        const node = event.node;
        if (event.type === EventType.NODE_DRAG_START) {
            node.anchoringStartPoint = {
                x: jsEvent.x,
                y: jsEvent.y,
            };
        }
        if (event.type === EventType.NODE_DRAG_IN_PROGRESS) {
            NodeAnchorManager.anchor(node, jsEvent.x, jsEvent.y);
        }
        if (event.type === EventType.NODE_DRAG_END) {
            if (node.anchoringStartPoint) {
                const startPoint = node.anchoringStartPoint;
                const distance = Math.sqrt(Math.pow(jsEvent.x - startPoint.x, 2) + Math.pow(jsEvent.y - startPoint.y, 2));
                if (distance > 5) {
                    node.anchored = true;
                    NodeAnchorManager.anchor(node, jsEvent.x, jsEvent.y);
                    this.eventManager.publishNodeEvent({type: EventType.NODE_ANCHORED_ON_DRAG_END, event: jsEvent, node: node})
                }
            }
        }
        if (event.type === EventType.NODE_UNANCHOR_MENUITEM_CLICKED) {
            node.anchored = false;
            NodeAnchorManager.unanchorWhenPossible(node);
        }
        if (event.type === EventType.NODE_ANCHOR_MENUITEM_CLICKED) {
            node.anchored = true;
            NodeAnchorManager.anchor(node, node.x, node.y);
        }
        if (event.type === EventType.NODE_MENU_OPENED) {
            node.anchoredOnMenuOpened = true;
            NodeAnchorManager.anchor(node, node.x, node.y);
        }
        if (event.type === EventType.NODE_MENU_CLOSED) {
            node.anchoredOnMenuOpened = false;
            NodeAnchorManager.unanchorWhenPossible(node);
        }
        if (event.type === EventType.NODE_MOUSE_ENTER) {
            node.anchorOnMouseOver = true;
            NodeAnchorManager.anchor(node, node.x, node.y);
        }
        if (event.type === EventType.NODE_MOUSE_LEAVE) {
            node.anchorOnMouseOver = false;
            NodeAnchorManager.unanchorWhenPossible(node);
        }
    }

    private static unanchorWhenPossible(node: INode) {
        if (!node.anchored && !node.anchorOnMouseOver && !node.anchoredOnMenuOpened) {
            node.fx = null;
            node.fy = null;
        }
    }

    private static anchor(node: INode, x?: number, y?: number) {
        node.fx = x;
        node.fy = y;
    }
}