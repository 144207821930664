export class CreatePropertyDialogValidator {
    validateName(name: string) {
        const isNameEmpty = name.trim().length === 0;
        return !isNameEmpty;
    }

    validateValue(value: string) {
        const isValueEmpty = value.trim().length === 0;
        return !isValueEmpty;
    }

    validateType(type: string) {
        const isTypeEmpty = type.trim().length === 0;
        return !isTypeEmpty;
    }
}
