import {GridColumns, GridColumnVisibilityModel, GridSortModel} from "@mui/x-data-grid-pro";
import {ColumnPresets, GridPresets, OrderDirection} from "./GridPresets";
import {GridColDef} from "@mui/x-data-grid";
import CopyUtils from "../../../common/CopyUtils";
import {GridSortDirection} from "@mui/x-data-grid/models/gridSortModel";

interface GridPresetsNormalizationResult {
    normalizedPresets: GridPresets;
    updated: boolean;
}

export class GridPresetsProcessor {

    normalizePresets(columns: GridColumns, presets?: GridPresets): GridPresetsNormalizationResult {
        presets = presets || {columns: []};
        const defFieldsSet = new Set(columns.map(column => column.field));
        const filteredColumnPresets = presets.columns
                .filter(presets => defFieldsSet.has(presets.field));

        const presetFieldsSet = new Set(filteredColumnPresets.map(columnPresets => columnPresets.field));
        const missingPresets = columns.filter(colDef => !presetFieldsSet.has(colDef.field))
                .map(colDef => this.buildDefaultPresetsForMissingColumn(colDef));
        filteredColumnPresets.push(...missingPresets);

        const updated = (filteredColumnPresets.length !== presets.columns.length || missingPresets.length > 0);

        return {
            normalizedPresets: {
                columns: filteredColumnPresets
            },
            updated: updated
        };
    }

    private buildDefaultPresetsForMissingColumn(columnDef: GridColDef): ColumnPresets {
        return {
            field: columnDef.field,
            hidden: columnDef.hide,
            width: columnDef.width
        };
    }

    applyGridPresets(presets: GridPresets, columns: GridColumns): GridColumns {
        const colDefMap = new Map();
        columns.forEach(colDef => colDefMap.set(colDef.field, CopyUtils.shallowCopy(colDef)));
        return presets.columns
                .map(columnPresets => this.applyColumnPresets(colDefMap.get(columnPresets.field), columnPresets));
    }

    getSortModel(presets: GridPresets | undefined): GridSortModel {
        if (!presets) {
            return [];
        }

        const sortModel: GridSortModel = presets.columns
            .filter(column => column.orderDirection && column.sortOrder !== undefined)
            .sort((a, b) => a.sortOrder! - b.sortOrder!)
            .map(column => ({
                field: column.field,
                sort: this.transformOrderDirectionToGridSortDirection(column.orderDirection)
            }));

        return (sortModel && sortModel.length > 0) ? sortModel : [];
    }

    transformOrderDirectionToGridSortDirection(orderDirection: OrderDirection | undefined): GridSortDirection {
        if (orderDirection === undefined) {
            return null;
        } else if (orderDirection === OrderDirection.ASCENDING) {
            return 'asc';
        } else if (orderDirection === OrderDirection.DESCENDING) {
            return 'desc';
        }
    }

    changeColumnSort(presets: GridPresets, gridSortModel: GridSortModel): GridPresets {
        for (let i = 0; i < presets.columns.length; i++) {
            presets.columns[i].orderDirection = undefined;
        }
        for (let i = 0; i < gridSortModel.length; i++) {
            const columnPresets = this.getColumnPresetsByField(presets, gridSortModel[i].field);
            columnPresets.orderDirection = this.transformGridSortDirectionToOrderDirection(gridSortModel[i].sort);
            columnPresets.sortOrder = i;
        }
        return presets;
    }

    transformGridSortDirectionToOrderDirection(gridSortDirection: GridSortDirection): OrderDirection | undefined {
        if (!gridSortDirection) {
            return undefined;
        } else if (gridSortDirection === 'asc') {
            return OrderDirection.ASCENDING;
        } else if (gridSortDirection === 'desc') {
            return OrderDirection.DESCENDING;
        }
    }

    private applyColumnPresets(colDef: GridColDef, presets: ColumnPresets) {
        if (presets) {
            colDef.hide = presets.hidden;
            if (presets.width) {
                colDef.width = presets.width;
            }
        }
        return colDef;
    }

    changeColumnOrder(presets: GridPresets, oldIndex: number, targetIndex: number): GridPresets {
        const columns = [...presets.columns];

        if (oldIndex >= columns.length) {
            throw new Error("Old index is out of bounds");
        }
        if (targetIndex >= columns.length) {
            throw new Error("Target index is out of bounds");
        }

        const columnPresets = columns.splice(oldIndex, 1);
        columns.splice(targetIndex, 0, ...columnPresets);
        return {
            ...presets,
            columns: columns
        };
    }

    applyColumnResize(presets: GridPresets, field: string, width: number): GridPresets {
        const columnPresets = this.getColumnPresetsByField(presets, field);
        columnPresets.width = width;
        return presets;
    }

    private getColumnPresetsByField(presets: GridPresets, field: string) {
        const columns = presets.columns;
        const columnPresets = columns.find(columnPresets => columnPresets.field === field);
        if (!columnPresets) {
            throw new Error(`No column for field '${field}' found in the presets.`);
        }
        return columnPresets;
    }

    public applyColumnVisibilityModelOnPresets(newGridColumnVisibilityModel: GridColumnVisibilityModel, presets: GridPresets): GridPresets {
        return {
            ...presets,
            columns: presets.columns.map((col) => {
                if (newGridColumnVisibilityModel.hasOwnProperty(col.field)) {
                    return {
                        ...col,
                        hidden: !newGridColumnVisibilityModel[col.field]
                    };
                } else {
                    return {
                        ...col,
                        hidden: false
                    };
                }
            }),
        };
    }
}
