import React from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {Paper} from '@mui/material';
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {Configuration, ConfigurationId} from "./Configuration";
import {ImportConfigurationNullable} from "../../../../common/Types";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";
import ImportPanel from "./ImportPanel";
import {useParams} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {paddingTop: theme.spacing(7)}),
    })
);

function getInitialConfiguration(initialConfigurationId: string | undefined): ImportConfigurationNullable {
    let initialConfiguration = null;
    if (initialConfigurationId) {
        initialConfiguration = Configuration.findById(Configuration.getConfigurations(), initialConfigurationId as ConfigurationId);
    }
    return initialConfiguration;
}

export default function ImportPage() {

    const classes = useStyles();

    const {initialConfigurationId} = useParams();
    const initialConfiguration = getInitialConfiguration(initialConfigurationId);
    const pageTitle = initialConfiguration != null ? initialConfiguration.pageLabel : _transl(ImportTranslationKey.TITLE);

    return (
        <Paper className={classes.paper}>
            <div className={classes.headerPageSegment}>
                <Typography variant="h6">
                    {pageTitle}
                </Typography>
            </div>
            <Divider />
            <div className={classes.controlPageSegment}>
                <ImportPanel initialConfigurationId={initialConfigurationId as (ConfigurationId | undefined)}/>
            </div>
        </Paper>
    );
}
