import {EventProperty} from "./Event";

export default class EventUtils {

    static getTransformedCoordinates(event: any): [number, number] {
        return [event[EventProperty.TRANSFORMED_X_COORDINATE], event[EventProperty.TRANSFORMED_Y_COORDINATE]];
    }

    static getClientCoordinates(event: any): Array<number> | null {
        if (event) {
            if (event.clientX != null && event.clientY != null) {
                return [event.clientX, event.clientY];
            } else {
                return EventUtils.getClientCoordinates(event.event);
            }
        }
        return null;
    }

}