import {IDiagramNodeDto} from "../../../common/apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../../common/apis/diagram/IDiagramConnectionDto";
import {ISyntheticEvent, IUndoableRedoableEvent} from "../../../common/event/Event";

export enum StyleEventType {
    STYLES_UPDATED = "STYLES_UPDATED",
}

export type StylesUpdatedEventType = StyleEventType.STYLES_UPDATED;

export interface StylesUpdatedEvent extends ISyntheticEvent, IUndoableRedoableEvent {
    type: StylesUpdatedEventType,
    nodes: Array<IDiagramNodeDto>,
    connections: Array<IDiagramConnectionDto>
}