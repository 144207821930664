import React, { useCallback } from "react";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";
import DiagramEditorComponent from "../../../../components/diagrameditor/DiagramEditorComponent";
import {IModelDataExportDto} from "../../../../common/apis/Exports";
import Api from "../../../../common/Api";
import {Observable} from "rxjs";
import {IPreviewMode, IViewMode, IDashboardMode, IExportMode} from "../../../../common/diagrameditor/DiagramEditor";
import {IMode} from "../../../../common/diagrameditor/model/IMode";
import {IModelDto} from "../../../../common/apis/model/IModelDto";

interface DiagramViewerProps {
    diagramId: string;
    renderMode?: RenderMode;
}

export default function DiagramViewer(props: DiagramViewerProps) {
    const {diagramId, renderMode} = props;
    const mode = createMode(renderMode);

    function createMode(renderMode?: RenderMode): IMode {
        let mode: IMode;
        if (RenderMode.VIEW === renderMode) {
            const viewMode: IViewMode = {
                mode: RenderMode.VIEW,
                viewDiagramById: (diagramId) => fetchEditedDiagram(),
            };
            mode = viewMode;
        } else if (RenderMode.EXPORT === renderMode) {
            const exportMode: IExportMode = {
                mode: RenderMode.EXPORT,
                viewDiagramById: (diagramId) => fetchEditedDiagram(),
            };
            mode = exportMode;
        } else if (RenderMode.DASHBOARD === renderMode) {
            const dashboardMode: IDashboardMode = {
                mode: RenderMode.DASHBOARD,
            };
            mode = dashboardMode;
        } else {
            const previewMode: IPreviewMode = {
                mode: RenderMode.PREVIEW,
            };
            mode = previewMode;
        }
        return mode;
    }

    const fetchEditedDiagram = useCallback((): Observable<IModelDto> => {
        const dto: IModelDataExportDto = {
            diagramIdentifiers: [diagramId],
            elementIdentifiers: [],
            exportOrganizations: true,
            exportUnreferencedElementsAndRelationships: false,
        };
        return Api.exports.exportModelData(dto);
    }, [diagramId]);

    return (
        <DiagramEditorComponent diagramId={diagramId}
                                fetchDiagram={fetchEditedDiagram}
                                mode={mode} />
    );
}
