import React from "react";

const ICON_VIEWBOX_SIZE = 24;

export enum Orientation {
    TO_LEFT,
    TO_RIGHT,
    TO_TOP,
    TO_BOTTOM,
}

const orientationToRotateDegrees: Record<Orientation, number> = {
    [Orientation.TO_LEFT]: -90,
    [Orientation.TO_RIGHT]: 90,
    [Orientation.TO_TOP]: 0,
    [Orientation.TO_BOTTOM]: 180,
}

interface Props {
    orientation: Orientation,
}

export default function AlignIcon(props: Props) {
    const {orientation} = props;
    const viewBoxCenter = ICON_VIEWBOX_SIZE / 2;
    const transform = `rotate(${orientationToRotateDegrees[orientation]} ${viewBoxCenter} ${viewBoxCenter})`;

    return <svg viewBox={`0 0 ${ICON_VIEWBOX_SIZE} ${ICON_VIEWBOX_SIZE}`} width={15} height={15}>
        <g fill={"currentColor"} transform={transform}>
            <path d={"M8 11h3v10h2V11h3l-4-4-4 4zM4 3v2h16V3H4z"} />
        </g>
    </svg>
}