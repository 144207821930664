import EventManager, {Unsubscriber} from "../../event/EventManager";
import {
    BendpointEvent,
    ConnectionHandlesHideEvent,
    ConnectionHandlesShowEvent,
    EventProperty,
    EventType,
    HiddenConnectionSelectionChangedEvent,
    IBendpointCreateEvent,
    IBendpointMoveEvent,
    IChartGridSnappingFunctionUpdatedEvent,
    IConnectionEvent,
    IModelUpdatedEvent,
    IModelUpdatedOnBendpointCreatedEvent,
    IModelUpdatedOnBendpointMovedEvent,
    IModelUpdatedOnBendpointRemovedEvent,
    IModelUpdatedOnItemsRemovedEvent,
    INodesMovedEvent,
    INodesResizedEvent,
    ISelectionChangedEvent,
    ModelUpdatedOnNodesAlignedEvent,
} from "../../event/Event";
import RenderContext from "../context/RenderContext";
import {Point} from "../util/GeometryUtils";
import {ISnappingFunction} from "./GridManager";
import ChartGroup, {ChartGroupType} from "../common/ChartGroup";
import {PointIncrement} from "../common/PointIncrement";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";
import {IDiagramPoint} from "../../apis/diagram/IDiagramPoint";
import * as d3 from "d3";
import {PAPER_RECT_ID} from "./SvgPaperManager";

export default class ConnectionHandleOverlayManager {

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private selectedConnections: Array<IDiagramConnectionDto>;

    private startPointerPoint: Point;
    private pointerPositionIncrement: PointIncrement;

    private snappingFunction?: ISnappingFunction;
    private newBendpointConnection?: IDiagramConnectionDto;

    private nonSelectedConnectionWithShownHandles?: IDiagramConnectionDto;
    private nonSelectedConnectionWithShownHandlesHideTimeout?: number;
    private readonly HIDE_TIMEOUT_IN_MS = 600;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_RENDERED, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_MOUSE_CLICKED, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_MOUSE_ENTER, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_MOUSE_LEAVE, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_CREATE_HANDLE_MOUSE_LEAVE, this.handleConnectionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<IModelUpdatedEvent>(EventType.MODEL_UPDATED, this.handleModelUpdatedEvent.bind(this)));
        // bendpoint
        this.unsubscribers.push(this.eventManager.subscribeListener<BendpointEvent>(EventType.CONNECTION_BENDPOINT_MOUSE_ENTER, this.handleBendpointEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<BendpointEvent>(EventType.CONNECTION_BENDPOINT_MOUSE_LEAVE, this.handleBendpointEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<BendpointEvent>(EventType.CONNECTION_BENDPOINT_REMOVE_MOUSE_ENTER, this.handleBendpointEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<BendpointEvent>(EventType.CONNECTION_BENDPOINT_REMOVE_MOUSE_LEAVE, this.handleBendpointEvent.bind(this)));
        // bendpoint move
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_STARTED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_IN_PROGRESS, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_FINISHED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_CANCELLED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_MOVED, this.handleModelUpdatedOnBendpointMovedEvent.bind(this)));
        // bendpoint create
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_STARTED, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_IN_PROGRESS, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_FINISHED, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_CANCELLED, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_CREATED, this.handleModelUpdatedOnBendpointCreatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_REMOVED, this.handleModelUpdatedOnBendpointRemovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_ITEMS_REMOVED, this.handleModelUpdatedOnItemsRemovedEvent.bind(this)));
        // other
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_GRID_SNAPPING_FUNCTION_UPDATED, this.handleChartGridSnappingFunctionUpdatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.SELECTION_CHANGED, this.handleSelectionChangedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODES_RESIZED, this.handleNodesResizedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODES_MOVED, this.handleNodesMovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.HIDDEN_CONNECTION_SELECTION_CHANGED, this.handleHiddenConnectionSelectionChangedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODES_ALIGNED, this.handleModelUpdatedOnNodesAlignedEvent.bind(this)));

        this.selectedConnections = [];

        this.startPointerPoint = new Point(0, 0);
        this.pointerPositionIncrement = new PointIncrement(0, 0);
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    private handleConnectionEvent(event: IConnectionEvent) {
        const connection = event.connection;

        if (event.type === EventType.CONNECTION_RENDERED) {
            if (this.renderContext?.isEditOrPreEdit()) {
                this.publishConnectionHandlesInitEvent([connection]);
                this.publishConnectionOverlayInitEvent([connection])
            }
        } else if (event.type === EventType.CONNECTION_MOUSE_ENTER) {
            if (this.renderContext?.isEdit()) {
                if (this.nonSelectedConnectionWithShownHandles && this.nonSelectedConnectionWithShownHandles.identifier !== connection.identifier) {
                    // another connection has been entered -> hide currently shown handles
                    this.hideNonSelectedConnectionWithShownHandles();
                }
                if (!this.isConnectionSelected(connection)) {
                    this.showHandlesForNonSelectedConnection(connection);
                }
            }
        } else if (event.type === EventType.CONNECTION_MOUSE_LEAVE) {
            if (event.event?.relatedTarget) {
                const relatedTargetId = d3.select(event.event.relatedTarget).attr("id");
                if (relatedTargetId === PAPER_RECT_ID && this.nonSelectedConnectionWithShownHandles) {
                    // mouse leaved non-selected connection before handles could be rendered
                    // (CONNECTION_CREATE_HANDLE_MOUSE_LEAVE would never be published) -> hide non-selected connection handles
                    if (this.nonSelectedConnectionWithShownHandlesHideTimeout == null) {
                        this.hideNonSelectedConnectionWithShownHandles(true);
                    }
                }
            }
            if (this.nonSelectedConnectionWithShownHandles && this.nonSelectedConnectionWithShownHandles.identifier === connection.identifier) {
                if (event.event?.target) {
                    const id = d3.select(event.event.target).attr("id");
                    if (id && id.indexOf(this.nonSelectedConnectionWithShownHandles.identifier) !== -1) {
                        // we entered another layer of current non-selected connection -> do not hide handles
                        return;
                    }
                }
                if (event.connection.identifier === this.nonSelectedConnectionWithShownHandles.identifier && this.nonSelectedConnectionWithShownHandlesHideTimeout) {
                    // hide already planned
                    return;
                } else {
                    this.hideNonSelectedConnectionWithShownHandles();
                }
            }
        } else if (event.type === EventType.CONNECTION_CREATE_HANDLE_MOUSE_LEAVE) {
            if (this.renderContext?.isEdit()) {
                if (!this.isConnectionSelected(connection)) {
                    const isNew = connection.identifier !== this.nonSelectedConnectionWithShownHandles?.identifier;
                    if (event.connection.identifier === this.nonSelectedConnectionWithShownHandles?.identifier) {
                        if (this.nonSelectedConnectionWithShownHandlesHideTimeout != null) {
                            // hide already planned
                            return;
                        } else {
                            this.hideNonSelectedConnectionWithShownHandles(true);
                        }
                    }
                    if (isNew) {
                        this.publishConnectionBendpointHandlesHideEvent([connection]);
                    }
                }
            }
        }
    }

    private showHandlesForNonSelectedConnection(connection: IDiagramConnectionDto) {
        if (connection.identifier === this.nonSelectedConnectionWithShownHandles?.identifier && this.nonSelectedConnectionWithShownHandlesHideTimeout) {
            window.clearTimeout(this.nonSelectedConnectionWithShownHandlesHideTimeout);
            this.nonSelectedConnectionWithShownHandlesHideTimeout = undefined;
        }
        this.nonSelectedConnectionWithShownHandles = connection;
        this.publishConnectionBendpointHandlesShowEvent([connection]);
    }

    private hideNonSelectedConnectionWithShownHandles(delay?: boolean) {
        const hideAction = () => {
            if (this.nonSelectedConnectionWithShownHandles) {
                this.publishConnectionBendpointHandlesHideEvent([this.nonSelectedConnectionWithShownHandles]);
                this.nonSelectedConnectionWithShownHandles = undefined;
                this.nonSelectedConnectionWithShownHandlesHideTimeout = undefined;
            }
        }
        if (delay) {
            this.nonSelectedConnectionWithShownHandlesHideTimeout = window.setTimeout(hideAction, this.HIDE_TIMEOUT_IN_MS);
        } else {
            hideAction();
        }
    }

    private handleModelUpdatedEvent(event: IModelUpdatedEvent) {
        if (event.type === EventType.MODEL_UPDATED) {
            if (this.nonSelectedConnectionWithShownHandles && !this.containsConnectionWithShownHandles(this.selectedConnections)) {
                this.hideNonSelectedConnectionWithShownHandles();
            }
        }
    }

    private handleBendpointEvent(event: BendpointEvent) {
        if (event.type === EventType.CONNECTION_BENDPOINT_MOUSE_ENTER || event.type === EventType.CONNECTION_BENDPOINT_REMOVE_MOUSE_ENTER) {
            if (event.connection.identifier === this.nonSelectedConnectionWithShownHandles?.identifier) {
                if (this.nonSelectedConnectionWithShownHandlesHideTimeout != null) {
                    window.clearTimeout(this.nonSelectedConnectionWithShownHandlesHideTimeout);
                    this.nonSelectedConnectionWithShownHandlesHideTimeout = undefined;
                }
            }
        } else if (event.type === EventType.CONNECTION_BENDPOINT_REMOVE_MOUSE_LEAVE) {
            if (event.connection.identifier === this.nonSelectedConnectionWithShownHandles?.identifier) {
                this.hideNonSelectedConnectionWithShownHandles(true);
            }
        }
    }

    private publishConnectionHandlesInitEvent(connections: Array<IDiagramConnectionDto>) {
        this.eventManager.publishEvent({
            type: EventType.CONNECTION_HANDLES_INIT,
            initialisedConnections: connections,
            selectedConnections: this.selectedConnections,
        });
    }

    private publishConnectionOverlayInitEvent(connections: Array<IDiagramConnectionDto>) {
        this.eventManager.publishEvent({
            type: EventType.CONNECTION_OVERLAY_INIT,
            initialisedConnections: connections,
            selectedConnections: this.selectedConnections,
        });
    }

    private handleModelUpdatedOnItemsRemovedEvent(event: IModelUpdatedOnItemsRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_ITEMS_REMOVED) {
            this.publishConnectionHandlesRemoveEvent(event.removedConnections);
            this.publishConnectionOverlayRemoveEvent(event.removedConnections);
        }
    }

    private publishConnectionHandlesRemoveEvent(removedConnections: Array<IDiagramConnectionDto>) {
        this.eventManager.publishEvent({
            type: EventType.CONNECTION_HANDLES_REMOVE,
            removedConnections: removedConnections,
        });
    }

    private handleBendpointMoveEvent(event: IBendpointMoveEvent) {
        if (this.renderContext) {
            const jsEvent = event.event;
            const connection = event.connection;

            if (event.type === EventType.CONNECTION_BENDPOINT_MOVE_STARTED) {
                this.setStartPointerPoint(jsEvent);
            }
            if (event.type === EventType.CONNECTION_BENDPOINT_MOVE_IN_PROGRESS) {
                this.updateMovementIncrement(jsEvent);
                const snappedIncrement = ConnectionHandleOverlayManager.snapPositionIncrement(connection.bendpoints[event.bendpointIndex], this.pointerPositionIncrement, this.snappingFunction as ISnappingFunction);

                this.publishConnectionOverlayBendpointUpdateEvent(
                    connection,
                    event.bendpointIndex,
                    snappedIncrement,
                    false);
            }
            if (event.type === EventType.CONNECTION_BENDPOINT_MOVE_FINISHED) {
                if (event.connection.identifier === this.nonSelectedConnectionWithShownHandles?.identifier) {
                    this.hideNonSelectedConnectionWithShownHandles();
                }
                this.updateMovementIncrement(jsEvent);
                const snappedIncrement = ConnectionHandleOverlayManager.snapPositionIncrement(connection.bendpoints[event.bendpointIndex], this.pointerPositionIncrement, this.snappingFunction as ISnappingFunction);
                this.publishConnectionOverlayRemoveEvent([connection]);

                this.eventManager.publishEvent({
                    type: EventType.CONNECTION_BENDPOINT_MOVED,
                    event: event.event,
                    connection: connection,
                    bendpointIndex: event.bendpointIndex,
                    increment: snappedIncrement,
                });
            }
            if (event.type === EventType.CONNECTION_BENDPOINT_MOVE_CANCELLED) {
                if (event.connection.identifier === this.nonSelectedConnectionWithShownHandles?.identifier) {
                    this.hideNonSelectedConnectionWithShownHandles();
                }
                this.publishConnectionOverlayRemoveEvent([connection]);
            }
        }
    }

    private publishConnectionOverlayBendpointUpdateEvent(
        connection: IDiagramConnectionDto,
        bendpointIndex: number,
        positionIncrement: PointIncrement,
        isNewBendpoint: boolean
    ) {
        this.eventManager.publishEvent({
            type: EventType.CONNECTION_OVERLAY_BENDPOINT_UPDATE,
            connection: connection,
            bendpointIndex: bendpointIndex,
            positionIncrement: positionIncrement,
            isNewBendpoint: isNewBendpoint,
        })
    }

    private publishConnectionOverlayRemoveEvent(removedConnections: Array<IDiagramConnectionDto>) {
        this.eventManager.publishEvent({
            type: EventType.CONNECTION_OVERLAY_REMOVE,
            removedConnections: removedConnections,
        })
    }

    private handleBendpointCreateEvent(event: IBendpointCreateEvent) {
        if (this.renderContext) {
            const jsEvent = event.event;
            const connection = event.connection;
            const bendpointIndex = event.bendpointIndex;

            if (event.type === EventType.CONNECTION_BENDPOINT_CREATE_STARTED) {
                this.setStartPointerPoint(jsEvent);
                const bendpoint = new Point(this.startPointerPoint.x, this.startPointerPoint.y);
                this.newBendpointConnection = ConnectionHandleOverlayManager.copyConnectionAndAddBendpoint(connection, bendpointIndex, bendpoint);
            }
            if (event.type === EventType.CONNECTION_BENDPOINT_CREATE_IN_PROGRESS) {
                this.updateMovementIncrement(jsEvent);
                const updatedConnection = this.newBendpointConnection as IDiagramConnectionDto;
                const snappedIncrement = ConnectionHandleOverlayManager.snapPositionIncrement(updatedConnection.bendpoints[event.bendpointIndex], this.pointerPositionIncrement, this.snappingFunction as ISnappingFunction);

                this.publishConnectionOverlayBendpointUpdateEvent(
                    updatedConnection,
                    event.bendpointIndex,
                    snappedIncrement,
                    true);
            }
            if (event.type === EventType.CONNECTION_BENDPOINT_CREATE_FINISHED) {
                this.updateMovementIncrement(jsEvent);
                const updatedConnection = this.newBendpointConnection as IDiagramConnectionDto;
                this.publishConnectionOverlayRemoveEvent([updatedConnection]);

                const snappedIncrement = ConnectionHandleOverlayManager.snapPositionIncrement(updatedConnection.bendpoints[event.bendpointIndex], this.pointerPositionIncrement, this.snappingFunction as ISnappingFunction);
                const updatedBendpoint = updatedConnection.bendpoints[event.bendpointIndex];
                const snappedBendpoint: [number, number] = [updatedBendpoint.x + snappedIncrement.incrementX, updatedBendpoint.y + snappedIncrement.incrementY];

                this.eventManager.publishEvent({
                    type: EventType.CONNECTION_BENDPOINT_CREATED,
                    event: jsEvent,
                    connection: connection,
                    bendpointIndex: bendpointIndex,
                    bendpoint: snappedBendpoint,
                });
            }
            if (event.type === EventType.CONNECTION_BENDPOINT_CREATE_CANCELLED) {
                const updatedConnection = this.newBendpointConnection as IDiagramConnectionDto;
                this.publishConnectionOverlayRemoveEvent([updatedConnection]);
            }
        }
    }

    private handleModelUpdatedOnBendpointMovedEvent(event: IModelUpdatedOnBendpointMovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_MOVED) {
            this.publishConnectionHandlesUpdateEvent([event.connection]);
        }
    }

    private publishConnectionHandlesUpdateEvent(updatedConnections: Array<IDiagramConnectionDto>) {
        this.eventManager.publishEvent({
            type: EventType.CONNECTION_HANDLES_UPDATE,
            updatedConnections: updatedConnections,
            selectedConnections: this.selectedConnections,
        })
    }

    private handleNodesResizedEvent(event: INodesResizedEvent) {
        if (event.type === EventType.NODES_RESIZED) {
            this.publishConnectionHandlesUpdateEvent(this.selectedConnections);
        }
    }

    private handleNodesMovedEvent(event: INodesMovedEvent) {
        if (event.type === EventType.NODES_MOVED) {
            this.publishConnectionHandlesUpdateEvent(this.selectedConnections);
        }
    }

    handleModelUpdatedOnBendpointRemovedEvent(event: IModelUpdatedOnBendpointRemovedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_REMOVED) {
            this.publishConnectionHandlesUpdateEvent([event.connection]);
        }
    }

    handleModelUpdatedOnBendpointCreatedEvent(event: IModelUpdatedOnBendpointCreatedEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_CREATED) {
            this.publishConnectionHandlesUpdateEvent([event.connection]);
        }
    }

    private handleChartGridSnappingFunctionUpdatedEvent(event: IChartGridSnappingFunctionUpdatedEvent) {
        this.snappingFunction = event.snappingFunction;
    }

    private handleHiddenConnectionSelectionChangedEvent(event: HiddenConnectionSelectionChangedEvent) {
        if (event.selectedConnection != null) {
            this.eventManager.publishEvent({
                type: EventType.HIDDEN_CONNECTION_HANDLES_SHOW,
                connection: event.selectedConnection,
            });
        }
        if (event.deselectedConnection != null) {
            this.eventManager.publishEvent({
                type: EventType.HIDDEN_CONNECTION_HANDLES_HIDE,
                connection: event.deselectedConnection,
            })
        }
    }

    private handleModelUpdatedOnNodesAlignedEvent(event: ModelUpdatedOnNodesAlignedEvent) {
        this.publishConnectionHandlesUpdateEvent(this.selectedConnections);
    }

    handleSelectionChangedEvent(event: ISelectionChangedEvent) {
        if (event.type === EventType.SELECTION_CHANGED) {
            this.selectedConnections = event.selectedConnections;
            if (this.nonSelectedConnectionWithShownHandles && this.containsConnectionWithShownHandles(event.selectedConnections)) {
                this.nonSelectedConnectionWithShownHandles = undefined;
            }
            if (this.nonSelectedConnectionWithShownHandles && !this.containsConnectionWithShownHandles(event.selectedConnections)) {
                this.hideNonSelectedConnectionWithShownHandles();
            }
            this.publishConnectionHandlesShowEvent(event.newlySelectedConnections);
            this.publishConnectionHandlesHideEvent(event.newlyDeselectedConnections);
        }
    }

    private publishConnectionHandlesShowEvent(connections: Array<IDiagramConnectionDto>) {
        this.eventManager.publishEvent({
            type: EventType.CONNECTION_HANDLES_SHOW,
            connections: connections,
        });
    }

    private publishConnectionHandlesHideEvent(connections: Array<IDiagramConnectionDto>) {
        this.eventManager.publishEvent({
            type: EventType.CONNECTION_HANDLES_HIDE,
            connections: connections,
        });
    }

    private publishConnectionBendpointHandlesShowEvent(connections: Array<IDiagramConnectionDto>) {
        this.eventManager.publishEvent<ConnectionHandlesShowEvent>({
            type: EventType.CONNECTION_HANDLES_SHOW,
            connections: connections,
            bendpointsOnly: true,
        });
    }

    private publishConnectionBendpointHandlesHideEvent(connections: Array<IDiagramConnectionDto>) {
        this.eventManager.publishEvent<ConnectionHandlesHideEvent>({
            type: EventType.CONNECTION_HANDLES_HIDE,
            connections: connections,
            bendpointsOnly: true,
        });
    }

    private setStartPointerPoint(event: any) {
        this.startPointerPoint.x = event[EventProperty.TRANSFORMED_X_COORDINATE];
        this.startPointerPoint.y = event[EventProperty.TRANSFORMED_Y_COORDINATE];
    }

    private updateMovementIncrement(event: any) {
        const x = event[EventProperty.TRANSFORMED_X_COORDINATE];
        const y = event[EventProperty.TRANSFORMED_Y_COORDINATE];
        this.pointerPositionIncrement.incrementX = x - this.startPointerPoint.x;
        this.pointerPositionIncrement.incrementY = y - this.startPointerPoint.y;
    }

    private static createConnectionsHandleOverlayGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.CONNECTIONS_HANDLE_OVERLAY_GROUP, selector);
    }

    private static copyConnectionAndAddBendpoint(connection: IDiagramConnectionDto, newBendpointIndex: number, newBendpoint: Point): IDiagramConnectionDto {
        const newBendpoints = [...(connection.bendpoints || [])];
        newBendpoints.splice(newBendpointIndex, 0, newBendpoint);
        const connectionCopy = Object.assign({}, connection);
        connectionCopy.bendpoints = newBendpoints;
        return connectionCopy;
    }

    private static snapPositionIncrement(bendpoint: IDiagramPoint, increment: PointIncrement, snappingFunction: ISnappingFunction): PointIncrement {
        const snappedX = snappingFunction.snap(bendpoint.x + increment.incrementX);
        const snappedY = snappingFunction.snap(bendpoint.y + increment.incrementY);
        const snappedIncrementX = snappedX - bendpoint.x;
        const snappedIncrementY = snappedY - bendpoint.y;
        return new PointIncrement(snappedIncrementX, snappedIncrementY);
    }

    private isConnectionSelected(connection: IDiagramConnectionDto): boolean {
        return this.selectedConnections && this.selectedConnections.filter(selected => selected.identifier === connection.identifier).length > 0;
    }

    private containsConnectionWithShownHandles(connections: IDiagramConnectionDto[]) {
        return !!(this.nonSelectedConnectionWithShownHandles && (connections || []).filter(connection => connection.identifier === this.nonSelectedConnectionWithShownHandles?.identifier).length > 0);
    }

}
