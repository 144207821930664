import EventManager, {Unsubscriber} from "../../../event/EventManager";
import RenderContext from "../../context/RenderContext";
import * as d3 from "d3";
import PositionManagerUtils from "./PositionManagerUtils";
import {Area} from "../../util/GeometryUtils";
import {OVERLAY_VISIBLE_OPACITY} from "../../common/UIConstants";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";
import {EventType, ModelBackupEvent} from "../../../event/Event";

export default class PositionOverlay {

    private eventManager: EventManager;
    private renderContext?: RenderContext;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener<ModelBackupEvent>(EventType.MODEL_UPDATED_ON_MODEL_BACKUP_LOADED, this.handleModelUpdatedOnModelBackupLoadedEvent.bind(this)));
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(renderContext: RenderContext) {
        this.renderContext = renderContext;
    }

    private handleModelUpdatedOnModelBackupLoadedEvent(event: ModelBackupEvent) {
        for (const node of event.oldModelAccessor.getAllNodes()) {
            this.removePositionOverlay(node);
        }
        for (const node of event.newModelAccessor.getAllNodes()) {
            this.initPositionOverlay(node);
        }
    }

    public initPositionOverlay(node: IDiagramNodeDto) {
        if (this.renderContext) {
            const positionOverlay = d3.select("rect"+PositionManagerUtils.createNodeOverlayId(node, true)) as d3.Selection<SVGRectElement, IDiagramNodeDto, HTMLElement, unknown>;
            if (positionOverlay.empty()) {
                // let mouse enter, leave events be captured by underlying node group
                const nodeMouseEventsSpace = 2;

                const positionOverlayGroup = PositionManagerUtils.getNodesOverlayGroup();
                positionOverlayGroup.append("rect")
                    .datum(node)
                    .attr("id", PositionManagerUtils.createNodeOverlayId(node))
                    .attr("fill", "lightgray")
                    .attr("stroke", "darkgray")
                    .attr("stroke-width", 1)
                    .attr("fill-opacity", 0)
                    .attr("stroke-opacity", 0)
                    .attr("stroke-dasharray", "4 1")
                    .attr("x", node.x + nodeMouseEventsSpace)
                    .attr("y", node.y + nodeMouseEventsSpace)
                    .attr("width", node.w - nodeMouseEventsSpace)
                    .attr("height", node.h - nodeMouseEventsSpace)
                    .attr("pointer-events", "none");
            }
        }
    }

    public removePositionOverlay(node: IDiagramNodeDto) {
        if (this.renderContext) {
            d3.select("rect"+PositionManagerUtils.createNodeOverlayId(node, true)).remove();
        }
    }

    public updatePositionOverlaysOnMove(nodes: Array<IDiagramNodeDto>, dimensions: {[id: string]: Area}) {
        nodes.forEach(node => {
            const area = dimensions[node.identifier];
            const positionOverlay = this.showPositionOverlay(node);
            positionOverlay
                .attr("x", area.x)
                .attr("y", area.y);
        });
    }

    public showPositionOverlays(nodes: Array<IDiagramNodeDto>) {
        nodes.forEach(node => {
            this.showPositionOverlay(node);
        })
    }

    public showPositionOverlay(node: IDiagramNodeDto) {
        this.initPositionOverlay(node);
        const positionOverlay = d3.select("rect"+PositionManagerUtils.createNodeOverlayId(node, true)) as d3.Selection<SVGRectElement, IDiagramNodeDto, HTMLElement, unknown>;
        if (positionOverlay.attr("fill-opacity") === "0") {
            positionOverlay.transition()
                .duration(100)
                .attr("fill-opacity", OVERLAY_VISIBLE_OPACITY)
                .attr("stroke-opacity", OVERLAY_VISIBLE_OPACITY);
        }
        return positionOverlay;
    }

    public updatePositionOverlaysOnResize(nodes: Array<IDiagramNodeDto>, dimensions: {[nodeId: string]: Area}) {
        nodes.forEach(node => {
            const dims = dimensions[node.identifier];
            this.initPositionOverlay(node);
            const positionOverlay = d3.select("rect"+PositionManagerUtils.createNodeOverlayId(node, true)) as d3.Selection<SVGRectElement, IDiagramNodeDto, HTMLElement, unknown>;
            if (positionOverlay.attr("fill-opacity") === "0") {
                positionOverlay.transition()
                    .duration(100)
                    .attr("fill-opacity", OVERLAY_VISIBLE_OPACITY)
                    .attr("stroke-opacity", OVERLAY_VISIBLE_OPACITY)
            }
            positionOverlay
                .attr("x", dims.x)
                .attr("y", dims.y)
                .attr("width", dims.w)
                .attr("height", dims.h);
        });
    }

    public synchronizePositionOverlays(nodes: Array<IDiagramNodeDto>, selectedNodes: Array<IDiagramNodeDto>) {
        nodes.forEach(node => {
            this.initPositionOverlay(node);
            const positionOverlay = d3.select("rect"+PositionManagerUtils.createNodeOverlayId(node, true)) as d3.Selection<SVGRectElement, IDiagramNodeDto, HTMLElement, unknown>;
            positionOverlay
                .attr("x", node.x)
                .attr("y", node.y)
                .attr("width", node.w)
                .attr("height", node.h);
        });
    }

    public hidePositionOverlays(nodes: Array<IDiagramNodeDto>) {
        nodes.forEach(node => {
            const positionOverlay = d3.select("rect"+PositionManagerUtils.createNodeOverlayId(node, true)) as d3.Selection<SVGRectElement, IDiagramNodeDto, HTMLElement, unknown>;
            if (!positionOverlay.empty()) {
                positionOverlay.transition()
                    .duration(100)
                    .attr("fill-opacity", 0)
                    .attr("stroke-opacity", 0);
            }
        })
    }

    public cancelPositionOverlays(nodes: Array<IDiagramNodeDto>) {
        if (this.renderContext) {
            const nodeGroup = this.renderContext.svgElementManager.getDiagramGroupSelection();
            nodes.forEach(node => {
                const positionOverlay = nodeGroup.select("rect" + PositionManagerUtils.createNodeOverlayId(node, true));
                if (!positionOverlay.empty()) {
                    positionOverlay.transition()
                        .ease(d3.easeCubicInOut)
                        .duration(700)
                        .attr("fill-opacity", 0)
                        .attr("stroke-opacity", 0)
                        .attr("x", node.x)
                        .attr("y", node.y)
                        .attr("width", node.w)
                        .attr("height", node.h);
                }
            })
        }
    }

}
