
export enum DiagramEditorTranslationKey {
    ELEMENT_PALETTE_GROUP = "diagrams.diagramEditor.elementPalette.group",
    ELEMENT_PALETTE_NOTE = "diagrams.diagramEditor.elementPalette.note",
    DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ADD_DIAGRAM_REFERENCE = "diagrams.diagramEditor.topMenu.addDiagramReference",
    DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ADD_ELEMENTS_FROM_REPOSITORY = "diagrams.diagramEditor.topMenu.addElementsFromRepository",
    DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_LEFT = "diagrams.diagramEditor.topMenu.alignLeft",
    DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_RIGHT = "diagrams.diagramEditor.topMenu.alignRight",
    DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_TOP = "diagrams.diagramEditor.topMenu.alignTop",
    DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_BOTTOM = "diagrams.diagramEditor.topMenu.alignBottom",
    DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_CENTER = "diagrams.diagramEditor.topMenu.alignCenter",
    DIAGRAMS_DIAGRAMEDITOR_TOPMENU_ALIGN_MIDDLE = "diagrams.diagramEditor.topMenu.alignMiddle",
    CHOICE_OF_DIAGRAMS_TITLE = "diagrams.diagramsPickDialog.resolvedDialogTitle",
    CHOOSE_BUTTON_LABEL = "diagrams.diagramsPickDialog.saveButtonLabel",
    MODEL_TREE_RELATIONSHIPS = "diagrams.diagramEditor.modelTree.relationships",
    MODEL_TREE_FILTER = "diagrams.diagramEditor.modelTree.filter",
    MODEL_TREE_SELECT_IN_DIAGRAM_MODEL = "diagrams.diagramEditor.modelTree.selectInDiagramModel",
    FOLDER_VIEW_ELEMENTS = "diagrams.diagramEditor.folderView.elements",
    FOLDER_VIEW_STEREOTYPES = "diagrams.diagramEditor.folderView.stereotypes",
    FOLDER_VIEW_LAYERS = "diagrams.diagramEditor.folderView.layers",
    FOLDER_VIEW_TOOLTIP_TEXT = "diagrams.diagramEditor.folderView.tooltipText",
    LEFT_MENU_OPTIONS_VIEW_HIDE_FILTER = "diagrams.diagramEditor.leftMenuOptions.viewHideFilter",
    TOP_MENU_ALIGNMENT = "diagrams.diagramEditor.topMenu.alignment",
    TOP_MENU_DIAGRAM = "diagrams.diagramEditor.topMenu.diagram",
    TOP_MENU_SAVE_CHANGES = "diagrams.diagramEditor.topMenu.saveChanges",
    TOP_MENU_SAVE_IN_PROGRESS = "diagrams.diagramEditor.topMenu.saveInProgress",
    TOP_MENU_SAVE_FAILED = "diagrams.diagramEditor.topMenu.saveFailed",
    TOP_MENU_DIAGRAM_SAVED = "diagrams.diagramEditor.topMenu.diagramSaved",
    TOP_MENU_DEFAULT_SAVE = "diagrams.diagramEditor.topMenu.defaultSave",
    TOP_MENU_INCREASE = "diagrams.diagramEditor.topMenu.increase",
    TOP_MENU_DECREASE = "diagrams.diagramEditor.topMenu.decrease",
    TOP_MENU_DEFAULT_SIZE = "diagrams.diagramEditor.topMenu.defaultSize",
    TOP_MENU_COMMENTS_SHOW = "diagrams.diagramEditor.topMenu.comments.show",
    TOP_MENU_COMMENTS_HIDE = "diagrams.diagramEditor.topMenu.comments.hide",
    TOP_MENU_VISUALIZATION = "diagrams.diagramEditor.topMenu.visualization",
    TOP_MENU_SHOW_GRID = "diagrams.diagramEditor.topMenu.showGrid",
    TOP_MENU_CONNECT_TO_GRID = "diagrams.diagramEditor.topMenu.connectToGrid",
    TOP_MENU_GRID_WIDTH = "diagrams.diagramEditor.topMenu.gridWidth",
    TOP_MENU_BACK = "diagrams.diagramEditor.topMenu.back",
    TOP_MENU_REPEAT = "diagrams.diagramEditor.topMenu.repeat",
    TOP_MENU_TOOLS = "diagrams.diagramEditor.topMenu.tools",
    TOP_MENU_TOOLS_VALIDATE_BY_VIEWPOINT = "diagrams.diagramEditor.topMenu.tools.validateByViewpoint",
    TOP_MENU_TOOLS_UNHIGHLIGHT_VALIDATION_ERRORS = "diagrams.diagramEditor.topMenu.tools.unhighlightValidationErrors",
    LOADING_FAILED = "diagrams.diagramEditor.loading.failed",
    LOADING_IN_PROGRESS = "diagrams.diagramEditor.loading.inProgress",
}
