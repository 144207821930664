import {Divider, Slider} from "@mui/material";
import {TopMenuId} from "./TopMenuId";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {TranslationKey} from "../../../../store/localization/TranslationKey";
import React, {useRef} from "react";
import {DiagramEditorTranslationKey} from "../../DiagramEditorTranslationKey";
import {MenuButton} from "../../../menu/MenuButton";
import {CheckBoxMenuItem} from "../../../menu/CheckBoxMenuItem";
import {MenuItem} from "../../../menu/MenuItem";
import {Menu} from "../../../menu/Menu";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sliderMenuItem: {
            overflow: "visible"
        },
        slider: {
            width: 120, marginLeft: "10px", marginRight: "5px", flexGrow: 1
        },
    })
);

interface ViewMenuProps {
    open: boolean,
    onClick: (menuId: TopMenuId) => void,
    onClose: () => void,
    showGrid: boolean,
    onShowGridMenuClicked: (event: any) => void,
    snapToGrid: boolean,
    onSnapToGridMenuClicked: (event: any) => void,
    gridUnits: number,
    onGridUnitsChange: (event: any, gridUnits: number) => void,
    showHiddenConnections: boolean,
    onShowHiddenConnectionsMenuClicked: () => void,
    moveToFrontMenuDisabled: boolean,
    onMoveToFrontClicked: () => void,
    moveToBackMenuDisabled: boolean,
    onMoveToBackClicked: () => void,
    mode: RenderMode,
}

export default function ViewMenu(props: ViewMenuProps) {

    const {open, onClick, onClose, showGrid, onShowGridMenuClicked,
        snapToGrid, onSnapToGridMenuClicked, gridUnits, onGridUnitsChange, onMoveToFrontClicked, onMoveToBackClicked,
        moveToFrontMenuDisabled, moveToBackMenuDisabled, showHiddenConnections, onShowHiddenConnectionsMenuClicked, mode} = props;

    const viewMenuAnchorRef = useRef<HTMLButtonElement | null>(null);

    const classes = useStyles();

    return <>
        <MenuButton label={_transl(DiagramEditorTranslationKey.TOP_MENU_VISUALIZATION)}
                    ref={viewMenuAnchorRef}
                    onClick={() => onClick(TopMenuId.VIEW_MENU)} />
        <Menu open={open}
              anchorEl={viewMenuAnchorRef.current}
              onClose={onClose}
        >
            <CheckBoxMenuItem label={_transl(DiagramEditorTranslationKey.TOP_MENU_SHOW_GRID)}
                              checked={showGrid}
                              onClick={(e) => {
                                  onShowGridMenuClicked(e);
                                  onClose();
                              }}
                              key={"show-grid"} />
            <CheckBoxMenuItem label={_transl(DiagramEditorTranslationKey.TOP_MENU_CONNECT_TO_GRID)}
                              checked={snapToGrid}
                              onClick={(e) => {
                                  onSnapToGridMenuClicked(e);
                                  onClose();
                              }}
                              key={"connect-to-grid"} />
            <MenuItem label={_transl(DiagramEditorTranslationKey.TOP_MENU_GRID_WIDTH)}
                      onClick={() => {}}
                      className={classes.sliderMenuItem}
                      key={"grid-width-menu"}
            >
                <Slider aria-labelledby="discrete-slider"
                        valueLabelDisplay={"auto"}
                        className={classes.slider}
                        onMouseDown={(event: any) => event.stopPropagation()}
                        value={gridUnits}
                        onChange={(e, value) => onGridUnitsChange(e, value as number)}
                        onChangeCommitted={() => onClose()}
                        step={2}
                        marks
                        min={4}
                        max={24} />
            </MenuItem>
            <Divider key={"grid-width-divider"} />
            <CheckBoxMenuItem label={_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_MENUITEM_SHOW_HIDDEN_CONNECTIONS)}
                              checked={showHiddenConnections}
                              onClick={() => {
                                  onShowHiddenConnectionsMenuClicked();
                                  onClose();
                              }}
                              key={"hidden-connections"}  />

            {mode === RenderMode.EDIT ? <Divider key={"move-divider"} /> : <div />}
            {mode === RenderMode.EDIT ? <MenuItem label={_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_MENUITEM_MOVE_TO_FRONT)}
                      disabled={moveToFrontMenuDisabled}
                      onClick={(e) => {
                          onMoveToFrontClicked();
                          onClose();
                      }}
                      key={"move-to-front"} /> : <div />
            }
            {mode === RenderMode.EDIT ?
                <MenuItem label={_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_MENUITEM_MOVE_TO_BACK)}
                          disabled={moveToBackMenuDisabled}
                          onClick={(e) => {
                              onMoveToBackClicked();
                              onClose();
                          }}
                          key={"move-to-back"}/> : <div />
            }
        </Menu>
    </>
}
