import React, {useRef, useState} from "react";
import {IPropertyDto} from "../../../../../../common/apis/CommonTypes";
import {SaveButton} from "../../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../../components/button/CancelButton";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../../ElementDetailTranslationKey";
import {CommonTranslation} from "../../../CommonTranslation";
import Dialog from "../../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../../components/dialogs/DialogContent";
import TextField from "../../../../../../components/fields/textfield/TextField";
import DialogActions from "../../../../../../components/dialogs/DialogActions";
import Snackbar from "../../../snackbar/Snackbar";
import {LabelsTranslationKey} from "../../../labels/LabelsTranslationKey";
import constructElementDetailController, {ElementDetailController} from "../../controller/ElementDetailController";
import elementService from "../../service/ElementService";
import diagramService from "../../../diagrams/service/DiagramService";
import Grid from "../../../../../../components/dialogs/Grid";
import {getLabelForPropertyType} from "../../../property/PropertyType";

interface UpdatePropertyDialogProps {
    opened: boolean,
    onClosed: () => void,
    elementId: string,
    onPropertyChanged: () => void,
    selectedProperty: IPropertyDto,
}

export default function UpdatePropertyDialog(props: UpdatePropertyDialogProps) {
    const elementDetailController = useRef<ElementDetailController>(constructElementDetailController(elementService, diagramService));
    const {onClosed, elementId, onPropertyChanged, selectedProperty} = props
    const identifier = selectedProperty.definition.identifier;
    const name = selectedProperty.definition.name;
    const type = selectedProperty.definition.type;
    const [value, setValue] = useState<string>(selectedProperty.value);

    const onDialogSave = () => {
        (async () => {
            try {
                await elementDetailController.current.updateProperty(elementId, identifier, value);
                onClosed()
                onPropertyChanged()
                Snackbar.success(_transl(LabelsTranslationKey.UPDATE_PROPERTY_SUCCEEDED));
            } catch (e) {
                Snackbar.error(_transl(LabelsTranslationKey.UPDATE_PROPERTY_FAILED));
            }
        })();
    }

    return (
        <Dialog open onClose={onClosed}>

            <DialogTitle id="scroll-dialog-title"
                         title={_transl(ElementDetailTranslationKey.ATTRIBUTES_EDIT_TITLE)}
                         onDialogClosed={onClosed}/>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="name-field"
                                   label={_transl(CommonTranslation.TITLE)}
                                   value={name}
                                   disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="type-field"
                                   label={_transl(ElementDetailTranslationKey.TYPE)}
                                   value={getLabelForPropertyType(type)}
                                   disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="value-field"
                                   label={_transl(ElementDetailTranslationKey.ATTRIBUTES_VALUE)}
                                   value={value}
                                   onChange={(atributeValue) => setValue(atributeValue)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={onDialogSave}/>
                <CancelButton onClick={onClosed} variant={"outlined"}/>
            </DialogActions>
        </Dialog>
    );
}
