export interface StateColorResolver {
    resolveColorByCode(state: string): string,
}

class StateColorResolverImpl implements StateColorResolver {

    private static readonly DEFAULT_COLOR: string = "white";

    private static readonly STATE_TO_COLOR_MAP: Map<string, string> = new Map([
        ["A", "green"],
        ["B", "yellow"],
        ["C", "blue"],
        ["D", "black"],
        ["E", "red"],
    ]);

    public resolveColorByCode(stateCode: string): string {
        return StateColorResolverImpl.STATE_TO_COLOR_MAP.get(stateCode) || StateColorResolverImpl.DEFAULT_COLOR;
    }
}

export default new StateColorResolverImpl() as StateColorResolver;
