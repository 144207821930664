import {ClickAwayListener, Grow, MenuList, Paper, Popper} from "@mui/material";
import {ReactElement} from "react";
import {MenuItemProps} from "./MenuItem";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            zIndex: 2000,
        },
        paper: {
            zIndex: 2000,
        },
        menu: {
            "& .MuiDivider-root": {
                margin: theme.spacing(1),
            }
        },
    })
);

export interface MenuProps {
    open: boolean;
    onClose: () => void;
    anchorEl?: Element | null | undefined;
    children: ReactElement<MenuItemProps> | ReactElement<MenuItemProps>[];
    id?: string;
}

export function Menu({open, onClose, anchorEl, children, id}: MenuProps) {
    const classes = useStyles();

    return (
        <Popper id={id}
                open={open}
                anchorEl={anchorEl}
                placement={"bottom-start"}
                transition
                className={classes.popper}
        >
            {({ TransitionProps}) => (
                <Grow {...TransitionProps}>
                    <Paper elevation={2} className={classes.paper}>
                        <ClickAwayListener onClickAway={() => open ? onClose(): {}}>
                            <MenuList autoFocusItem={open} dense={true} className={classes.menu}>
                                {children}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}
