import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {FormatBold, FormatItalic, FormatUnderlined} from "@mui/icons-material";
import {FontStyle} from "../StyleSettings";

interface FontStyleToggleProps {
    id?: string,
    fontStyles: FontStyle[],
    disabled?: boolean,
    onChange: (fontStyles: FontStyle[]) => void;
}

export function FontStyleToggle(props: FontStyleToggleProps) {

    const styles = props.fontStyles;
    const disabled = props.disabled ?? false;

    function onStyleChanged(event: any, styles: FontStyle[]) {
        props.onChange(styles);
    }
    
    return (
        <ToggleButtonGroup id={props.id}
                           value={styles}
                           size={"small"}
                           onChange={onStyleChanged}>
            <ToggleButton value={FontStyle.BOLD} disabled={disabled}>
                <FormatBold/>
            </ToggleButton>
            <ToggleButton value={FontStyle.ITALIC} disabled={disabled}>
                <FormatItalic/>
            </ToggleButton>
            <ToggleButton value={FontStyle.UNDERLINE} disabled={disabled}>
                <FormatUnderlined/>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
