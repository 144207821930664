export enum MarkerType {
    ARROW_FILLED_SMALL_BLACK_END = "ARROW_FILLED_SMALL_BLACK_END",
    ARROW_FILLED_LARGE_WHITE_END = "ARROW_FILLED_LARGE_WHITE_END",
    ARROW_STROKED_START = "ARROW_STROKED_START",
    ARROW_STROKED_END = "ARROW_STROKED_END",
    ARROW_TOP_STROKED_END = "ARROW_TOP_STROKED_END",
    CIRCLE_SMALL_BLACK_START = "CIRCLE_SMALL_BLACK_START",
    DIAMOND_LARGE_BLACK_START = "DIAMOND_LARGE_BLACK_START",
    DIAMOND_LARGE_WHITE_START = "DIAMOND_LARGE_WHITE_START",
}
export const USE_LINE_COLOR_FLAG = "__USE_LINE_COLOR_FLAG__";

const SMALL_MARKER_SIZE = 9;
const LARGE_MARKER_SIZE = 14;

class MarkerDefinition {
    constructor(public readonly viewBox: string,
                public readonly refX: number,
                public readonly refY: number,
                public readonly markerWidth: number,
                public readonly markerHeight: number,
                public readonly fill: string | null,
                public readonly stroke: string | null,
                public readonly d: string) {}
}

type DefinitionMap = {
    [prop in keyof typeof MarkerType]: MarkerDefinition;
}

export default class MarkerDefinitionFactory {

    private static factory: MarkerDefinitionFactory;

    private definitionsMap: DefinitionMap;

    constructor() {
        this.definitionsMap = {
            ARROW_TOP_STROKED_END: new MarkerDefinition("0 -5 10 10", 10, 0, LARGE_MARKER_SIZE, LARGE_MARKER_SIZE, null, USE_LINE_COLOR_FLAG, "M0,-5L10,0"),
            ARROW_FILLED_LARGE_WHITE_END: new MarkerDefinition("0 -5 10 10", 10, 0, LARGE_MARKER_SIZE, LARGE_MARKER_SIZE, "white", USE_LINE_COLOR_FLAG, "M0.5,-4.5L9.5,0L0.5,4.5Z"),
            ARROW_FILLED_SMALL_BLACK_END: new MarkerDefinition("0 -5 10 10", 10, 0, SMALL_MARKER_SIZE, SMALL_MARKER_SIZE, USE_LINE_COLOR_FLAG, null, "M0,-5L10,0L0,5Z"),
            ARROW_STROKED_START: new MarkerDefinition("0 -5 10 10", 0, 0, SMALL_MARKER_SIZE, SMALL_MARKER_SIZE, null, USE_LINE_COLOR_FLAG, "M10,-5L0,0L10,5"),
            ARROW_STROKED_END: new MarkerDefinition("0 -5 10 10", 10, 0, SMALL_MARKER_SIZE, SMALL_MARKER_SIZE, null, USE_LINE_COLOR_FLAG, "M0,-5L10,0L0,5"),
            CIRCLE_SMALL_BLACK_START: new MarkerDefinition("0 -5 10 10", 0, 0, SMALL_MARKER_SIZE, SMALL_MARKER_SIZE, USE_LINE_COLOR_FLAG, USE_LINE_COLOR_FLAG, "M5,0m-4,0a4,4 0 1,0 8,0a4,4 0 1,0 -8,0"),
            DIAMOND_LARGE_WHITE_START: new MarkerDefinition("0 -5 16 10", 0, 0, LARGE_MARKER_SIZE, LARGE_MARKER_SIZE, "white", USE_LINE_COLOR_FLAG, "M8,-5L16,0L8,5L0,0Z"),
            DIAMOND_LARGE_BLACK_START: new MarkerDefinition("0 -5 16 10", 0, 0, LARGE_MARKER_SIZE, LARGE_MARKER_SIZE, USE_LINE_COLOR_FLAG, USE_LINE_COLOR_FLAG, "M8,-5L16,0L8,5L0,0Z"),
        }
    }

    get(markerType: MarkerType) {
        return MarkerDefinitionFactory.factory.definitionsMap[markerType];
    }

    static getInstance() {
        if (!MarkerDefinitionFactory.factory) {
            MarkerDefinitionFactory.factory = new MarkerDefinitionFactory();
        }
        return MarkerDefinitionFactory.factory;
    }

}