import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapperDiv: {
        paddingInline: "12px",
    },
    attrNameSpan: {
        fontStyle: "italic",
    },
}));

export default function PropertyFieldInputComponent(props: any) {
    const classes = useStyles();

    const {value} = props;

    return (
        <div className={classes.wrapperDiv}>
            {value && value.value !== "" ? (
                <span className={classes.attrNameSpan}>{value.name}={value.value}</span>
            ) : value ? (
                <span className={classes.attrNameSpan}>{value.name}=*</span>
            ) : null}
        </div>
    );
}