import {DiagramNode} from "../../model/Model";
import * as d3 from "d3";
import {ModelManager} from "../../manager/ModelManager";
import {HORIZONTAL_LINE_HEIGHT, ROUNDED_RECTANGLE_RADIUS, ShapeType} from "./AbstractNodeRenderer";
import {StateColorResolver} from "../../../StateColorResolver";

export default class StateIndicatorRenderer {

    public static readonly STATE_INDICATOR_RADIUS = 4;

    constructor(private stateColorResolver: StateColorResolver) {
    }

    public render(node: DiagramNode,
                  shapeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                  shapeType: ShapeType,
                  modelManager: ModelManager) {
        const element = node.elementIdentifier ? modelManager.getElementById(node.elementIdentifier) : null;
        const stateColor = element?.state ? this.resolveStateColor(element.state) : null;
        if (stateColor) {
            const {cx, cy} = this.getStateIndicatorCenterPoint(shapeType);
            this.appendStateIndicator(shapeGroup, node, cx, cy, stateColor);
        }
    }

    private resolveStateColor(state: string): string | null {
        return this.stateColorResolver.resolveColorByCode(state);
    }

    private getStateIndicatorCenterPoint(shapeType: ShapeType) {
        const distance = StateIndicatorRenderer.STATE_INDICATOR_RADIUS + 3;
        let cx = distance
        let cy = distance;
        switch (shapeType) {
            case ShapeType.BLOCK:
                cy += HORIZONTAL_LINE_HEIGHT;
                break;
            case ShapeType.BEVELLED_RECTANGLE:
                cx += (ROUNDED_RECTANGLE_RADIUS / 2);
                cy += (ROUNDED_RECTANGLE_RADIUS / 2);
                break;
            default: break;
        }
        return {
            cx: cx,
            cy: cy,
        }
    }

    private appendStateIndicator(shapeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                                 node: DiagramNode,
                                 cx: number,
                                 cy: number,
                                 stateColor: string) {
        shapeGroup.append("circle")
            .attr("r", StateIndicatorRenderer.STATE_INDICATOR_RADIUS)
            .attr("cx", node.x + cx)
            .attr("cy", node.y + cy)
            .attr("fill", stateColor)
            .attr("stroke", "black");
    }

}
