import {ElementDto} from "../../../../../common/apis/element/ElementDto";
import Api from "../../../../../common/Api";
import {catchError, map} from "rxjs/operators";
import Constants from "../../../../../common/Constants";
import {ResultListDto} from "../../../../../common/apis/ResultListDto";
import {IFilter} from "../../../../../store/elements/Elements";
import Elements from "../../../../../common/apis/Elements";
import {Language} from "../../../../../common/Language";
import {of, throwError} from "rxjs";

const ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/repository/elements";
const ELEMENT_ID_PARAM = ":elementId";
const CREATE_RELATIONSHIP: string = `${ENDPOINT_URL}/${ELEMENT_ID_PARAM}:add-relationship`;
const DELETE_ELEMENTS_BY_ID_URL: string = `${ENDPOINT_URL}:delete`;

export interface BulkElementTypeUpdateDto {
    type: string;
    stereotype?: string;
    identifiers: string[];
}

export interface DescriptionGenerationPropertiesDto {
    languageCode: Language;
    messagesOverrides?: {
        assistantRoleDesc?: string;
        elementSummary?: string;
        relationshipsProlog?: string;
        relationshipSummary?: string;
        directionTo?: string;
        directionFrom?: string;
        finalInstructions?: string;
    }
}

export interface DescriptionGenerationResultDto {
    description: string;
}

export interface ElementService {
    doSearch(elementIds: string[]): Promise<ResultListDto<ElementDto>>;
    searchByFilter(filter: IFilter): Promise<ResultListDto<ElementDto>>;
    findElementById(elementId: string): Promise <ElementDto | undefined>;
    createRelationship(identifier: string, otherElementIdentifiers: Array<string>, relationshipType: string, isOutgoing: boolean): Promise<any>;
    bulkUpdateElementType(elementTypeUpdate: BulkElementTypeUpdateDto): Promise<undefined>;
    deleteElementsById(elementIds: string[]): Promise<any>;
    generateDescriptionUsingAi(elementId: string, properties: DescriptionGenerationPropertiesDto): Promise<DescriptionGenerationResultDto>;
}

class ElementServiceImpl implements ElementService {

    async doSearch(elementIds: string[]): Promise<ResultListDto<ElementDto>> {
        const request = {
            url: `${ENDPOINT_URL}:search`,
            method: "POST",
            body: {
                identifiers: elementIds
            },
        };
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response || []) as ResultListDto<ElementDto>)
            )
            .toPromise();
    }

    findElementById(elementId: string): Promise<ElementDto | undefined> {
        const request = {
            url: `${ENDPOINT_URL}/${elementId}`,
            method: "GET",
        };
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response) as ElementDto),
                catchError(error => {
                    if (error.status === 404) {
                        return of(undefined);
                    } else {
                        return throwError(error);
                    }
                })
            )
            .toPromise();
    }

    async searchByFilter(filter: IFilter): Promise<ResultListDto<ElementDto>> {
        const request = {
            url: `${ENDPOINT_URL}:search`,
            method: "POST",
            body: Elements.createSearchFilter(filter),
        };
        return Api.createAjax(request)
            .pipe(
                map(response => (response.response || []) as ResultListDto<ElementDto>)
            )
            .toPromise();
    }

    async createRelationship(elementId: string, otherElementIdentifiers: Array<string>, relationshipType: string, isOutgoing: boolean): Promise<any> {
        const request = {
            url: Api.replaceUrlParams(CREATE_RELATIONSHIP, {[ELEMENT_ID_PARAM]: elementId}),
            method: "POST",
            body: {
                identifiers: otherElementIdentifiers,
                relationshipType: relationshipType,
                outgoing: isOutgoing,
            }
        }
        return Api.createAjax(request).toPromise();
    }

    async bulkUpdateElementType(elementTypeUpdate: BulkElementTypeUpdateDto): Promise<undefined> {
        const request = {
            url: ENDPOINT_URL + "/bulk/type:update",
            method: "PUT",
            body: elementTypeUpdate,
        };

        return Api.createAjax(request)
            .pipe(
                map(() => undefined)
            )
            .toPromise();
    }

    async deleteElementsById(elementIds: string[]): Promise<any> {
        const request = {
            url: DELETE_ELEMENTS_BY_ID_URL,
            method: "DELETE",
            body: elementIds,
        };
        return Api.createAjax(request).toPromise();
    }

    async generateDescriptionUsingAi(elementId: string, properties: DescriptionGenerationPropertiesDto): Promise<DescriptionGenerationResultDto> {
        const request = {
            url: `${ENDPOINT_URL}/${elementId}:generate-description-using-ai`,
            method: "POST",
            body: properties,
        };
        return Api.createAjax(request)
            .pipe(
                map(response => response.response as DescriptionGenerationResultDto)
            )
            .toPromise();
    }
}

export default new ElementServiceImpl() as ElementServiceImpl;
