import {ValidationResult} from "../../../../../../../common/validation/ValidationResult";
import {CommonTranslation} from "../../../../CommonTranslation";

export class TitleSubformValidator {
    static TREE_CREATION_TITLE_MAX_LENGTH = 40;

    validate(title: string): ValidationResult {
        const errors = [];

        if (title.length === 0) {
            errors.push({ errorMessage: CommonTranslation.FILL_OUT_THIS_FIELD });
        } else if (title.length > TitleSubformValidator.TREE_CREATION_TITLE_MAX_LENGTH) {
            errors.push({ errorMessage: CommonTranslation.FIELD_TOO_LONG });
        }

        return { errors: errors };
    }
}
