import {Collapse} from "@mui/material";
import DrawerListItem from "./DrawerListItem";
import DrawerList, {DrawerMenuModel} from "./DrawerList";
import {_transl} from "../../store/localization/TranslMessasge";
import React, {useState} from "react";

export interface DrawerMenuProps {
    onClose: () => void;
    menu: DrawerMenuModel;
    level: number;
}

export default function DrawerMenu(props: Readonly<DrawerMenuProps>) {
    const {onClose, menu, level} = props;

    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <DrawerListItem icon={menu.icon}
                            text={_transl(menu.label)}
                            expanded={expanded}
                            onClick={() => setExpanded(!expanded)} />
            <Collapse in={expanded}
                      timeout="auto"
                      unmountOnExit>
                <DrawerList onClose={onClose}
                            level={level + 1}
                            items={menu.items}
                            disablePadding={true} />
            </Collapse>
        </>
    );
}