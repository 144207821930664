import {ArchimateElement} from "../../../archimate/ArchimateElement";
import {DiagramDefaultsDto} from "../../../apis/diagram/DiagramDefaultsDto";

export interface Dimensions {
    width: number,
    height: number,
}

export class NodeDimensionsExtractor {

    constructor(private readonly diagramDefaults: DiagramDefaultsDto) {}

    extractDimensions(elementTypeStandardName?: string): Dimensions {
        const elementType = elementTypeStandardName ? ArchimateElement.findByStandardName(elementTypeStandardName) : null;
        if (elementType?.isJunction()) {
            return this.diagramDefaults.junctionDimensions;
        } else {
            return this.diagramDefaults.nodeDimensions;
        }
    }

    extractMinDimensions(elementTypeStandardName?: string): Dimensions {
        const elementType = elementTypeStandardName ? ArchimateElement.findByStandardName(elementTypeStandardName) : null;
        if (elementType?.isJunction()) {
            return this.diagramDefaults.minJunctionDimensions;
        } else {
            return this.diagramDefaults.minNodeDimensions;
        }
    }

}