import {default as MaterialAppBar} from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {Box, SxProps, useTheme} from "@mui/material";
import {Check, Menu} from "@mui/icons-material";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {IApplicationState} from "../../../store/Store";
import {useDispatch, useSelector} from "react-redux";
import {getSaveTranslationsAction} from "../../../store/localization/TranslationReducer";
import translationsService from "../../../common/apis/TranslationsService";
import {Language} from "../../../common/Language";
import LocaleContext from "../../../common/LocaleContext";
import AppBarIconButton from "./AppBarIconButton";
import {Dashboard} from "../../../store/dashboard/Dashboard";
import AppLogo from "./AppLogo";
import {UserDto} from "../../../common/apis/user/UserDto";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
    administrationMenu,
    appInfoMenu,
    collectionsMenu,
    dashboardAdministrationMenu,
    dashboardMenu,
    diagramsMenu,
    elementsMenu,
    exportMenu,
    generateMetamodelMenu,
    importMenu,
    labelsMenu,
    logoutMenu,
    modelDocumentationMenu,
    modelsComparisonMenu,
    profileMenu,
    queriesMenu,
    stereotypesMenu,
    structuresMenu,
    toolsMenu,
    usersMenu,
    validateModelMenu
} from "../../../common/menudefinition/MenuDefinitionUtils";
import {_transl} from "../../../store/localization/TranslMessasge";
import Drawer from "../../../components/drawer/Drawer";
import {DrawerMenuItemModel, MobileMenuItems} from "../../../components/drawer/DrawerList";
import MenuDefinition from "../../../common/menudefinition/MenuDefinition";
import ConfigurationContext from "../../../common/ConfigurationContext";
import {KeycloakHolder} from "../../../keycloak/KeycloakHolder";
import LanguageIcon from "@mui/icons-material/Language";
import {buildTranslationKeyForLanguage} from "../../../store/localization/LanguageTranslationKey";
import importPageAccessResolver from "../content/import/ImportPageAccessResolver";
import collectionsPageAccessResolver from "../content/collections/CollectionsPageAccessResolver";
import {AppBarTranslationKey} from "./AppBarTranslationKey";

interface MobileAppBarProps {
    sx?: SxProps;
}

export default function MobileAppBar(props: Readonly<MobileAppBarProps>) {
    const {sx} = props;
    const {language, setLanguage} = useContext(LocaleContext);
    const dispatch = useDispatch();

    const user = useSelector<IApplicationState, UserDto | undefined>((state) => state.user.userData);

    const [mainMenuDrawerOpen, setMainMenuDrawerOpen] = useState(false);
    const [accountMenuDrawerOpen, setAccountMenuDrawerOpen] = useState(false);

    const dashboard = useSelector<IApplicationState, Dashboard>((state) => state.dashboard);
    const dashboardNotEmpty = Boolean(dashboard.mainWidget.url ?? dashboard.mainWidget.diagramIdentifier);

    const {featureStatuses} = useContext(ConfigurationContext);

    const changeLanguage = useCallback((language: Language) => {
        translationsService.getTranslations(language)
                .then(translations => {
                    dispatch(getSaveTranslationsAction(translations));
                    setLanguage(language, true);
                });
    }, [dispatch, setLanguage]);

    function toggleMainMenuDrawer() {
        setMainMenuDrawerOpen(!mainMenuDrawerOpen);
        setAccountMenuDrawerOpen(false);
    }

    function closeMainMenuDrawer() {
        setMainMenuDrawerOpen(false);
    }

    function toggleAccountMenuDrawer() {
        setAccountMenuDrawerOpen(!accountMenuDrawerOpen);
        setMainMenuDrawerOpen(false);
    }

    function closeAccountMenuDrawer() {
        setAccountMenuDrawerOpen(false);
    }

    function logout() {
        KeycloakHolder.logout();
    }

    const mainMenuItems: MobileMenuItems = [
        buildMenuItem(dashboardMenu, dashboardNotEmpty),
        buildMenuItem(structuresMenu),
        buildMenuItem(elementsMenu),
        buildMenuItem(diagramsMenu),
        {
            icon: toolsMenu.icon,
            label: _transl(toolsMenu.label),
            items: [
                buildMenuItem(queriesMenu, featureStatuses.graphQueryEnabled),
                buildMenuItem(exportMenu),
                buildMenuItem(importMenu, importPageAccessResolver.hasPageAccess(user)),
                buildMenuItem(modelDocumentationMenu),
                buildMenuItem(modelsComparisonMenu),
                buildMenuItem(generateMetamodelMenu),
                buildMenuItem(validateModelMenu)
            ]
        },
        {
            icon: administrationMenu.icon,
            label: _transl(administrationMenu.label),
            items: [
                buildMenuItem(usersMenu),
                buildMenuItem(labelsMenu),
                buildMenuItem(collectionsMenu, collectionsPageAccessResolver.hasPageAccess(user)),
                buildMenuItem(stereotypesMenu, collectionsPageAccessResolver.hasPageAccess(user)),
                buildMenuItem(dashboardAdministrationMenu)
            ]
        }
    ];

    const languageItems: DrawerMenuItemModel[] = useMemo(() => Object.entries(Language)
            .sort()
            .map(([key, value]) => ({
                icon: language === value ? <Check/> : undefined,
                label: _transl(buildTranslationKeyForLanguage(value)),
                navigateTo: "",
                onClick: () => changeLanguage(value)
            })), [language, changeLanguage]);

    const accountMenuItems = [
        buildMenuItem(profileMenu),
        {
            icon: <LanguageIcon/>,
            label: _transl(AppBarTranslationKey.LANGUAGE),
            items: languageItems
        },
        buildMenuItem(appInfoMenu),
        buildMenuItem(logoutMenu, true, () => logout())
    ];

    function buildMenuItem(menuDefinition: MenuDefinition, visible?: boolean, onClick?: () => void): DrawerMenuItemModel {
        return {
            icon: menuDefinition.icon,
            label: _transl(menuDefinition.label),
            navigateTo: menuDefinition.path,
            visible: visible,
            onClick: onClick,
        };
    }

    const theme = useTheme();

    return (
        <>
            <MaterialAppBar position={"fixed"}
                            sx={{zIndex: theme.zIndex.drawer + 1, ...sx}}>
                <Toolbar>
                    <AppBarIconButton tooltip={""}
                                      icon={Menu}
                                      onClick={() => toggleMainMenuDrawer()} />

                    <Box sx={{flexGrow: 1}} />

                    <AppLogo size={"small"} />

                    <Box sx={{flexGrow: 1}} />

                    <AppBarIconButton tooltip={""}
                                      icon={AccountCircle}
                                      onClick={() => toggleAccountMenuDrawer()} />
                </Toolbar>
            </MaterialAppBar>

            <Drawer open={mainMenuDrawerOpen}
                    onClose={() => closeMainMenuDrawer()}
                    items={mainMenuItems} />

            <Drawer open={accountMenuDrawerOpen}
                    onClose={() => closeAccountMenuDrawer()}
                    items={accountMenuItems} />
        </>
    );
}
