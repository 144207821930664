import {AttachmentInfoDto} from "../../common/apis/CommonTypes";
import {
    createCustomActionFetchableResourceEpic,
    createFetchableResourceReducer,
    FetchableResourceType,
    getResourceFetchCustomAction,
    IFetchableResourceAction
} from "./FetchableResource";
import Api from "../../common/Api";
import {
    createDownloadableResourceArrayReducer, createDownloadableResourceEpic,
    DownloadableResourceType,
    getResourceDownloadAction,
    getResourceDownloadRemoveItemAction, IDownloadableResourceAction
} from "./DownloadableResource";
import {
    createDeletableResourceArrayReducer, createDeletableResourceEpic,
    DeletableResourceType,
    getResourceDeleteAction,
    getResourceDeleteRemoveItemAction, IDeletableResourceAction
} from "./DeletableResource";
import {
    createUploadableResourceArrayReducer, createUploadableResourceEpic,
    getResourceUploadAction,
    getResourceUploadRemoveItemAction,
    IUploadableResourceAction,
    UploadableResourceType
} from "./UploadableResource";
import {Observable, Subscriber} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import {Action} from "redux";

export type AttachmentDeleteResourceIdType = { resourceId: string, attachmentId: string};
export type AttachmentDownloadResourceIdType = { resourceId: string, attachmentId: string};
export type AttachmentResourceType = AttachmentInfoDto[] | null;
export enum AttachmentType {
    ELEMENTS = "ELEMENTS",
    DIAGRAMS = "DIAGRAMS",
}

// ### ITEM ATTACHMENTS ###

// Actions & action creators

export interface IAttachmentFetchAction extends IFetchableResourceAction<AttachmentResourceType> {
    itemId: string,
}

export function getAttachmentFetchAction(resourceType: FetchableResourceType, itemId: string): IAttachmentFetchAction {
    return getResourceFetchCustomAction<AttachmentResourceType, IAttachmentFetchAction>(resourceType, {itemId: itemId});
}

// Reducers & epics

function createAttachmentReducer(resourceType: FetchableResourceType) {
    return createFetchableResourceReducer<AttachmentResourceType>(resourceType, null);
}

function createAttachmentEpic(
    resourceType: FetchableResourceType,
    apiCall: (action: IAttachmentFetchAction) => Observable<AjaxResponse>
) {
    return createCustomActionFetchableResourceEpic<IAttachmentFetchAction>(resourceType, apiCall)
}

// Resource specific reducers and epics

const diagramFetchableResourceType = FetchableResourceType.DIAGRAM_DETAIL_PAGE_ATTACHMENTS;

export const diagramAttachmentReducer = createAttachmentReducer(diagramFetchableResourceType)
export const diagramAttachmentEpic = createAttachmentEpic(
    diagramFetchableResourceType,
    (action) => Api.diagrams.getAttachmentsByIdentifier(action.itemId)
)

const elementFetchableResourceType = FetchableResourceType.ELEMENTS_DETAIL_PAGE_ATTACHMENTS;

export const elementAttachmentReducer = createAttachmentReducer(elementFetchableResourceType)
export const elementAttachmentEpic = createAttachmentEpic(
    elementFetchableResourceType,
    (action) => Api.elements.getAttachmentsByIdentifier(action.itemId)
)



// ### ITEM DOWNLOADS ATTACHMENTS ###

// Actions & action creators

export function getDownloadAttachmentAction(resourceType: DownloadableResourceType, resourceId: AttachmentDownloadResourceIdType, resourceFileName: string) {
    return getResourceDownloadAction<AttachmentDownloadResourceIdType>(resourceType, resourceId, resourceFileName);
}

export function getDownloadAttachmentRemoveItemAction(resourceType: DownloadableResourceType, resourceId: AttachmentDownloadResourceIdType) {
    return getResourceDownloadRemoveItemAction<AttachmentDownloadResourceIdType>(resourceType, resourceId);
}

// Reducers & epics

function createAttachmentDownloadReducer(resourceType: DownloadableResourceType) {
    return createDownloadableResourceArrayReducer<AttachmentDownloadResourceIdType>(resourceType, []);
}

function createAttachmentDownloadEpic(
    resourceType: DownloadableResourceType,
    apiCall: (action: IDownloadableResourceAction<AttachmentDownloadResourceIdType>, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) => Observable<AjaxResponse>
) {
    return createDownloadableResourceEpic<AttachmentDownloadResourceIdType>(resourceType, apiCall);
}

// Resource specific reducers and epics

const diagramDownloadResourceType = DownloadableResourceType.DIAGRAM_DETAIL_PAGE_ATTACHMENT;

export const diagramAttachmentDownloadArrayReducer = createAttachmentDownloadReducer(diagramDownloadResourceType);
export const diagramAttachmentDownloadArrayEpic = createAttachmentDownloadEpic(
    diagramDownloadResourceType,
    (action, responseType, progressSubscriber?) => {
        const { resourceId, attachmentId } = action.resourceId;
        return Api.diagrams.downloadAttachment(resourceId, attachmentId, responseType, progressSubscriber);
    });

const elementDownloadResourceType = DownloadableResourceType.ELEMENTS_DETAIL_PAGE_ATTACHMENT;

export const elementAttachmentDownloadArrayReducer = createAttachmentDownloadReducer(elementDownloadResourceType);
export const elementAttachmentDownloadArrayEpic = createAttachmentDownloadEpic(
    elementDownloadResourceType,
    (action, responseType, progressSubscriber?) => {
        const { resourceId, attachmentId } = action.resourceId;
        return Api.elements.downloadAttachment(resourceId, attachmentId, responseType, progressSubscriber);
    });




// ### ITEM DELETE ATTACHMENTS ###

// Actions & action creators

export function getDeleteAttachmentAction(resourceType: DeletableResourceType, resourceId: AttachmentDeleteResourceIdType) {
    return getResourceDeleteAction<AttachmentDeleteResourceIdType>(resourceType, resourceId);
}

export function getDeleteAttachmentRemoveItemAction(resourceType: DeletableResourceType, resourceId: AttachmentDeleteResourceIdType) {
    return getResourceDeleteRemoveItemAction<AttachmentDeleteResourceIdType>(resourceType, resourceId);
}

// Reducers & epics

function createAttachmentDeleteArrayReducer(resourceType: DeletableResourceType) {
    return createDeletableResourceArrayReducer<AttachmentDeleteResourceIdType>(resourceType, []);
}

function createAttachmentDeleteArrayEpic(
    resourceType: DeletableResourceType,
    apiCall: (action: IDeletableResourceAction<AttachmentDeleteResourceIdType>) => Observable<AjaxResponse>
) {
    return createDeletableResourceEpic<AttachmentDeleteResourceIdType>(resourceType, apiCall);
}

// Resource specific reducers and epics

const elementDeleteResourceType = DeletableResourceType.ELEMENTS_DETAIL_PAGE_ATTACHMENT;

export const elementAttachmentDeleteArrayReducer = createAttachmentDeleteArrayReducer(elementDeleteResourceType);
export const elementAttachmentDeleteArrayEpic = createAttachmentDeleteArrayEpic(elementDeleteResourceType,
    (action) => {
        const { resourceId, attachmentId } = action.resourceId;
        return Api.elements.deleteAttachment(resourceId, attachmentId);
    });

const diagramDeleteResourceType = DeletableResourceType.DIAGRAM_DETAIL_PAGE_ATTACHMENT;

export const diagramAttachmentDeleteArrayReducer = createAttachmentDeleteArrayReducer(diagramDeleteResourceType);
export const diagramAttachmentDeleteArrayEpic = createAttachmentDeleteArrayEpic(diagramDeleteResourceType,
    (action) => {
        const { resourceId, attachmentId } = action.resourceId;
        return Api.diagrams.deleteAttachment(resourceId, attachmentId);
    });




// ### ITEM UPLOAD ATTACHMENTS ###

// Types
interface IUploadAttachmentAction extends IUploadableResourceAction<unknown> {
    resourceId: string,
}

// Actions & action creators

export function getUploadAttachmentAction(resourceType: UploadableResourceType, resourceId: string, resource: File) {
    return getResourceUploadAction(resourceType, resourceId, resource);
}

export function getUploadAttachmentRemoveItemAction(resourceType: UploadableResourceType, resourceId: string, resource: File) {
    return getResourceUploadRemoveItemAction(resourceType, resourceId, resource);
}

// Reducers & epics

function createAttachmentUploadArrayReducer(resourceType: UploadableResourceType) {
    return createUploadableResourceArrayReducer<unknown, IUploadableResourceAction<unknown>>(resourceType, []);
}

function createAttachmentUploadArrayEpic(
    resourceType: UploadableResourceType,
    apiCall: (action: IUploadAttachmentAction, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) => Observable<AjaxResponse>,
    onSuccessfulUploadAction: (action: IUploadAttachmentAction) => Action<unknown>
) {
    return createUploadableResourceEpic<unknown, IUploadAttachmentAction>(resourceType, apiCall, onSuccessfulUploadAction);
}

// Resource specific reducers and epics

const diagramUploadResourceType = UploadableResourceType.DIAGRAM_DETAIL_PAGE_ATTACHMENT;
const diagramUploadFetchResourceType = FetchableResourceType.DIAGRAM_DETAIL_PAGE_ATTACHMENTS;

export const diagramAttachmentUploadArrayReducer = createAttachmentUploadArrayReducer(diagramUploadResourceType);
export const diagramAttachmentUploadArrayEpic = createAttachmentUploadArrayEpic(
    diagramUploadResourceType,
    (action, responseType, progressSubscriber?) => {
        return Api.diagrams.uploadAttachment(action.itemId, action.resource, responseType, progressSubscriber);
    },
    action => getAttachmentFetchAction(diagramUploadFetchResourceType, action.itemId),
);

const elementUploadResourceType = UploadableResourceType.ELEMENTS_DETAIL_PAGE_ATTACHMENT;
const elementUploadFetchResourceType = FetchableResourceType.ELEMENTS_DETAIL_PAGE_ATTACHMENTS;

export const elementAttachmentUploadArrayReducer = createAttachmentUploadArrayReducer(elementUploadResourceType);
export const elementAttachmentUploadArrayEpic = createAttachmentUploadArrayEpic(
    elementUploadResourceType,
    (action, responseType, progressSubscriber?) => {
        return Api.elements.uploadAttachment(action.itemId, action.resource, responseType, progressSubscriber);
    },
    action => getAttachmentFetchAction(elementUploadFetchResourceType, action.itemId),
);
