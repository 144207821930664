import Constants from "../common/Constants";
import Api from "../common/Api";
import {map} from "rxjs/operators";
import {TrackerConfigDto} from "./TrackerConfigDto";
import {FeatureStatusesDto} from "./FeatureStatusesDto";


export interface KeycloakConfigDto {
    url: string,
    clientId: string,
    realm: string,
}

export class ConfigurationService {

    getKeycloakConfig(): Promise<KeycloakConfigDto> {
        return Api.createAjaxWithoutToken({
                url: Constants.REST_API_URL + "/config/auth",
                method: "GET",
        })
            .pipe(
                map(response => response.response as KeycloakConfigDto)
            ).toPromise();
    }

    getTrackerConfig(): Promise<TrackerConfigDto> {
        return Api.createAjax({
            url: Constants.REST_API_URL + "/config/tracker",
            method: "GET",
        })
            .pipe(
                map(response => response.response as TrackerConfigDto)
            ).toPromise();
    }

    getFeatureStatuses(): Promise<FeatureStatusesDto> {
        return Api.createAjax({
            url: Constants.REST_API_URL + "/config/feature-statuses",
            method: "GET",
        })
            .pipe(
                map(response => response.response as FeatureStatusesDto)
            ).toPromise();
    }
}

export default new ConfigurationService();