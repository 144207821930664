import RenderContext from "../../context/RenderContext";
import * as d3 from 'd3';
import PositionerFactory, {Orientation} from "./PositionerFactory";
import ChartGroup, {ChartGroupType} from "../../common/ChartGroup";
import EventManager from "../../../event/EventManager";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {EventProperty, EventType} from "../../../event/Event";
import TranslationKey from "../../TranslationKey";
import {HANDLE_FILL_COLOR} from "../../common/UIConstants";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";
import {CUSTOM_DRAG_HANDLER, ICustomDragHandler} from "../SvgElementDragManager";
import {DiagramEditorUtils} from "../../util/DiagramEditorUtils";
import {Area, Point} from "../../util/GeometryUtils";

export const CONNECTION_HANDLE_TEMPLATE_SIZE = 30;
const HANDLE_REMOVE_UNDERLAY_LENGTH = CONNECTION_HANDLE_TEMPLATE_SIZE + 5;
const CREATE_HANDLE_FILL_COLOR = HANDLE_FILL_COLOR;
const CREATE_HANDLE_FILL_COLOR_TRANSPARENT = d3.color(CREATE_HANDLE_FILL_COLOR)?.copy({opacity: .33}).formatRgb() as string;
const CREATE_HANDLE_PADDING = 10;
const DRAG_START_THRESHOLD = 0;
const HANDLE_ARROW_CLASS = "__diagram-editor-connection-create-handle__";
const HANDLE_REMOVE_UNDERLAY_CLASS_NAME = "__diagram-editor-connection-create-handle-remove-underlay__";

export default class ConnectionHandle {

    private renderContext?: RenderContext;
    private eventManager?: EventManager;
    private handlesShown: boolean = false;
    private lastSelectedNode?: IDiagramNodeDto;
    private node?: IDiagramNodeDto;

    init(renderContext: RenderContext, eventManager: EventManager) {
        this.renderContext = renderContext;
        this.eventManager = eventManager;
    }

    public showHandles(node: IDiagramNodeDto) {
        if (this.renderContext?.isEdit()) {
            ConnectionHandle.removeHandles();
            this.renderHandleRemoveUnderlay(node);
            this.renderConnectionHandles(node);
            this.node = node;
            this.lastSelectedNode = node;
            this.handlesShown = true;
        }
    }

    static removeHandles() {
        ChartGroup.getSelection(ChartGroupType.NODES_CREATE_CONNECTION_HANDLE_GROUP)
            .selectAll("*")
            .remove();
    }

    static removeHandleRemoveUnderlay() {
        ChartGroup.getSelection(ChartGroupType.NODES_UNDERLAY_GROUP)
            .selectAll(`.${HANDLE_REMOVE_UNDERLAY_CLASS_NAME}`)
            .remove();
    }

    private renderConnectionHandles(node: IDiagramNodeDto) {
        this.renderConnectionHandle(node, Orientation.N);
        this.renderConnectionHandle(node, Orientation.S);
        this.renderConnectionHandle(node, Orientation.E);
        this.renderConnectionHandle(node, Orientation.W);
    }

    private renderConnectionHandle(node: IDiagramNodeDto, orientation: Orientation) {
        const arrow = ConnectionHandle.appendConnectionArrow(
            ChartGroup.getSelection(ChartGroupType.NODES_CREATE_CONNECTION_HANDLE_GROUP),
            orientation,
            node);
        const handle = ConnectionHandle.appendConnectionHandle(
            ChartGroup.getSelection(ChartGroupType.NODES_CREATE_CONNECTION_HANDLE_GROUP),
            arrow.node()?.getBoundingClientRect() as DOMRect,
            this.renderContext as RenderContext);
        this.registerHandleEventHandlers(handle, arrow, node);
    }

    private static appendConnectionArrow(selection: d3.Selection<SVGGElement, unknown, any, undefined>,
                                          orientation: Orientation,
                                          node: IDiagramNodeDto) {
        const arrow = selection
            .append("path")
            .attr("viewBox", `0 0 ${CONNECTION_HANDLE_TEMPLATE_SIZE} ${CONNECTION_HANDLE_TEMPLATE_SIZE}`)
            .attr("d", "M15,0 L4,15 L10,15 L10,30 L20,30 L20,15 L26,15z")
            .attr("fill", CREATE_HANDLE_FILL_COLOR_TRANSPARENT)
            .attr("pointer-events", "none")
            .attr("stroke", "white")
            .style("cursor", "copy");
        const positioner = PositionerFactory.get(orientation);
        arrow
            .attr("transform", positioner.computeTransform(node));
        return arrow;
    }

    private static appendConnectionHandle(selection: d3.Selection<SVGGElement, unknown, any, undefined>,
                                          arrowBRect: DOMRect,
                                          renderContext: RenderContext) {
        const handleArea = ConnectionHandle.computeHandleArea(selection, arrowBRect, renderContext);

        const handle = selection
            .append("rect")
            .attr("x", handleArea.x)
            .attr("y", handleArea.y)
            .attr("width", handleArea.w)
            .attr("height", handleArea.h)
            .attr("pointer-events", "all")
            .attr("fill", "black")
            .attr("opacity", 0)
            .style("cursor", "copy");
        handle
            .append("title")
            .text(_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_EDITOR_CREATE_CONNECTION_HANDLE));
        return handle;
    }

    private static computeHandleArea(selection: d3.Selection<SVGGElement, unknown, any, undefined>,
                                     arrowBRect: DOMRect,
                                     renderContext: RenderContext) {
        const arrowStartPoint = DiagramEditorUtils.convertOuterPointToGroup(
            new Point(arrowBRect.x, arrowBRect.y),
            selection.node() as SVGGElement,
            renderContext.svgElementManager.getSvg());

        return new Area(
            arrowStartPoint.x - CREATE_HANDLE_PADDING,
            arrowStartPoint.y - CREATE_HANDLE_PADDING,
            arrowBRect.width + (2 * CREATE_HANDLE_PADDING),
            arrowBRect.height + (2 * CREATE_HANDLE_PADDING));
    }

    private renderHandleRemoveUnderlay(node: IDiagramNodeDto) {
        ConnectionHandle.removeHandleRemoveUnderlay();
        const removeUnderlay = ChartGroup.getSelection(ChartGroupType.NODES_UNDERLAY_GROUP)
            .append("rect")
            .classed(HANDLE_REMOVE_UNDERLAY_CLASS_NAME, true)
            .attr("x", node.x - HANDLE_REMOVE_UNDERLAY_LENGTH)
            .attr("y", node.y - HANDLE_REMOVE_UNDERLAY_LENGTH)
            .attr("width", node.w + (2 * HANDLE_REMOVE_UNDERLAY_LENGTH))
            .attr("height", node.h + (2 * HANDLE_REMOVE_UNDERLAY_LENGTH))
            .attr("fill", "none")
            .attr("stroke", "none")
            .attr("pointer-events", "all");
        removeUnderlay.on("mouseenter", (event) => {

        });
        removeUnderlay.on("mouseover", () => {
            if (!this.handlesShown && this.lastSelectedNode) {
                this.showHandles(this.lastSelectedNode);
            }
        });
        removeUnderlay.on("mouseleave", (event) => {
            if (event.relatedTarget && d3.select(event.relatedTarget).classed(HANDLE_ARROW_CLASS)) {
                return;
            }
            this.removeHandles();
        });
    }

    private registerHandleEventHandlers(handle: d3.Selection<SVGRectElement, unknown, any, undefined>,
                                        arrow: d3.Selection<SVGPathElement, unknown, any, undefined>,
                                        node: IDiagramNodeDto) {
        handle
            .attr("pointer-events", "visiblePainted")
            .classed(HANDLE_ARROW_CLASS, true)
            .on("mouseenter", () => this.onHandleMouseEnter(arrow))
            .on("mouseleave", () => this.onHandleMouseLeave(arrow))
        const dragHandler: ICustomDragHandler = {
            useSnappingFunction: false,
            publishStartEvent: (event: DragEvent) => this.onHandleClicked(node, event),
            publishInProgressEvent: (event: DragEvent) => this.onHandleDragInProgress(node, event),
            publishEndEvent: (event: DragEvent) => this.onHandleDragFinished(node, event),
            publishCancelEvent: (event: DragEvent) => this.onHandleDragCancelled(node, event),
            dragStartThreshold: DRAG_START_THRESHOLD,
            setTransformedEventCoordinates: (event: any, transformedX: number, transformedY: number) => {
                event[EventProperty.TRANSFORMED_X_COORDINATE] = transformedX;
                event[EventProperty.TRANSFORMED_Y_COORDINATE] = transformedY;
            }
        };
        (handle.node() as any)[CUSTOM_DRAG_HANDLER] = dragHandler;
    }

    private onHandleMouseEnter(createHandle: d3.Selection<SVGPathElement, unknown, any, undefined>) {
        createHandle
            .attr("fill", CREATE_HANDLE_FILL_COLOR);
    }

    private onHandleMouseLeave(createHandle: d3.Selection<SVGPathElement, unknown, any, undefined>) {
        createHandle
            .attr("fill", CREATE_HANDLE_FILL_COLOR_TRANSPARENT);
        this.removeHandles();
    }

    private onHandleClicked(node: IDiagramNodeDto, event: any) {
        this.removeHandles();

        this.eventManager?.publishEvent({
            type: EventType.NODE_CONNECTION_CREATE_BY_HANDLE_ACTIVATED,
            node: node,
            event: event,
        });
    }

    public removeHandles() {
        ConnectionHandle.removeHandles();
        ConnectionHandle.removeHandleRemoveUnderlay();
        this.node = undefined;
        this.handlesShown = false;
    }

    public onConnectionCreateStarted() {
        this.removeHandles();
        ConnectionHandle.removeHandleRemoveUnderlay();
    }

    private onHandleDragInProgress(node: IDiagramNodeDto, event: any) {
        this.eventManager?.publishEvent({
            type: EventType.NODE_CONNECTION_CREATE_BY_HANDLE_IN_PROGRESS,
            node: node,
            event: event,
        });
    }

    private onHandleDragFinished(node: IDiagramNodeDto, event: any) {
        this.eventManager?.publishEvent({
            type: EventType.NODE_CONNECTION_CREATE_BY_HANDLE_FINISHED,
            node: node,
            event: event,
        });
    }

    private onHandleDragCancelled(node: IDiagramNodeDto, event: any) {
        this.eventManager?.publishEvent({
            type: EventType.NODE_CONNECTION_CREATE_BY_HANDLE_CANCELLED,
            node: node,
            event: event,
        });
    }

}
