import {PropertyFilterDto} from "../../../store/elements/Elements";
import TextField from "../textfield/TextField";
import React, {useEffect, useState} from "react";
import PropertyFilterDialog from "./PropertyFilterDialog";
import PropertyFieldInputComponent from "./PropertyFieldInputComponent";
import {_transl} from "../../../store/localization/TranslMessasge";
import {PropertyTranslation} from "./PropertyTranslation";

interface Props {
    propertyFilters: PropertyFilterDto[] | undefined;
    handleOnChange: (changedValues: PropertyFilterDto[]) => void;
    type: "elements" | "diagrams";
}

export default function PropertyField(props: Props) {

    const {propertyFilters, handleOnChange, type} = props;

    const [propertyFilterDialogOpen, setPropertyFilterDialogOpen] = useState<boolean>(false);
    const [propertyFilterDto, setPropertyFilterDto] = useState<PropertyFilterDto>();

    useEffect(() => {
        if (propertyFilters && propertyFilters.length > 0) {
            setPropertyFilterDto(propertyFilters[0]);
        }
    }, [propertyFilters]);

    return (
        <React.Fragment>
            <PropertyFilterDialog open={propertyFilterDialogOpen}
                                  type={type}
                                  propertyFilterDto={propertyFilterDto}
                                  onSave={(name: string, value: string) => {
                                      const newPropertyFilterDto: PropertyFilterDto = {
                                          name: name,
                                          value: value,
                                          contains: true
                                      };
                                      setPropertyFilterDto(newPropertyFilterDto);
                                      handleOnChange([newPropertyFilterDto]);
                                  }}
                                  onClosed={() => setPropertyFilterDialogOpen(false)}
            />
            <TextField
                id="attribute-field"
                label={_transl(PropertyTranslation.ATTRIBUTE)}
                value={propertyFilterDto}
                onClick={event => setPropertyFilterDialogOpen(true)}
                InputProps={{
                    inputComponent: PropertyFieldInputComponent,
                    style: {
                        justifyContent: 'space-between',
                        minHeight: '40px'
                    },
                }}
                clearable
                onClearButtonClick={() => {
                    setPropertyFilterDto(undefined)
                    handleOnChange([]);
                }}
            />
        </React.Fragment>
    );
}