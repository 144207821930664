import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {TreeView} from "@mui/lab";
import * as React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import HierarchyTreeItems from "./HierarchyTreeItems";
import {HierarchyTreeItemDetailDto} from "./service/HierarchyTreeItemDetailDto";
import {HierarchyTreeController} from "./controller/HierarchyTreeController";
import hierarchyTreePathParser from "./controller/HierarchyTreePathParser";
import {HierarchyTreeDto} from "./service/HierarchyTreeDto";
import {Box, CircularProgress} from "@mui/material";
import {ValidationError} from "../../../../common/ValidationError";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import Snackbar from "../snackbar/Snackbar";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {GraphQueryExecutionErrorCode} from "../graphquery/GraphQueryExecutionErrorCode";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import {GraphQueryTranslationKey} from "../graphquery/GraphQueryTranslationKey";
import hierarchyTreeService from "./service/HierarchyTreeService";
import {HierarchyTreeItemType} from "./service/HierarchyTreeItemType";
import HierarchyTreeItemContextMenu from "./HierarchyTreeItemContextMenu";
import EventManagerContext from "../../../../common/event/EventManagerContext";
import {HierarchyTreeEventType, SubtreeReloadRequestEvent} from "./HierarchyTreeEvents";

interface HierarchyTreeProps {
    hierarchyTree?: HierarchyTreeDto;
    onTreeItemLeafClicked: (dto: HierarchyTreeItemDetailDto) => void;
    hierarchyTreeController: HierarchyTreeController;
}

interface TreeItemContextMenuAttrs {
    open: boolean;
    treeItem?: HierarchyTreeItemDetailDto;
    treeItemPath?: string;
    x: number;
    y: number;
}

export default function HierarchyTree(props: HierarchyTreeProps) {

    const {hierarchyTree, onTreeItemLeafClicked, hierarchyTreeController} = props;

    const [expanded, setExpanded] = useState<string[]>([]);
    const [selected, setSelected] = useState<string>("");
    const [treeModelItems, setTreeModelItems] = useState<HierarchyTreeItemDetailDto[]>(hierarchyTree?.rootItems || []);
    const [graphQueryErrorMessage, setGraphQueryErrorMessage] = useState<string>();
    const [treeItemContextMenuAttrs, setTreeItemContextMenuAttrs] = useState<TreeItemContextMenuAttrs>({open: false, x: 0, y: 0});

    const eventManager = useContext(EventManagerContext);

    useEffect(() => initialize(hierarchyTree), [hierarchyTree]);

    function initialize(tree?: HierarchyTreeDto) {
        setTreeModelItems(tree?.rootItems ?? []);
        setSelected("");
        setExpanded([]);
    }

    const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        const newExpanded = hierarchyTreeController.filterOutChildrenOfCollapsedParents(expanded, nodeIds);
        setExpanded(newExpanded);
    };

    function fetchTreeItemByPath(treeId: string, treePath: string, rootTreePath: string) {
        const pathParameter = hierarchyTreePathParser.getParentPath(treePath);
        const nodeId = hierarchyTreePathParser.getLastPathItem(treePath);

        hierarchyTreeService.findItemChildren(treeId, nodeId, pathParameter)
            .then((treeItems) => {
                const updatedTreeModelItems = hierarchyTreeController.appendTreeItemsToPath(treeItems, rootTreePath, treeModelItems);
                setTreeModelItems(updatedTreeModelItems);
            })
            .catch((error) => {
                if (error instanceof ValidationError && error.error.code === GraphQueryExecutionErrorCode.QUERY_EXECUTION_FAILED) {
                    setGraphQueryErrorMessage(error.error.message);
                } else {
                    if (error.status && error.status !== 404 && hierarchyTreePathParser.containMultipleItems(treePath)) {
                        const parentTreePath = hierarchyTreePathParser.getParentPath(treePath);
                        fetchTreeItemByPath(treeId, parentTreePath, rootTreePath);
                    } else {
                        Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA), error);
                        setExpanded(expanded.filter(expandedId => expandedId !== nodeId));
                    }
                }
            });
    }

    const fetchSubtreeById = useCallback((treeId: string, treePath: string) => {
        hierarchyTreeService.findTree(treeId)
            .then(tree => {
                const updatedTreeModelItems = hierarchyTreeController.appendTreeItemsToPath(tree.rootItems, treePath, treeModelItems);
                setTreeModelItems(updatedTreeModelItems);
            })
            .catch((error) => {
                if (error instanceof ValidationError && error.error.code === GraphQueryExecutionErrorCode.QUERY_EXECUTION_FAILED) {
                    setGraphQueryErrorMessage(error.error.message);
                } else {
                    Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA), error);
                }
            });
    }, [treeModelItems, hierarchyTreeController]);

    useEffect(() => {
        const unsubscribe = eventManager.subscribeListener(HierarchyTreeEventType.SUBTREE_RELOAD_REQUEST, (event: SubtreeReloadRequestEvent) => {
                fetchSubtreeById(event.subtreeId, event.subtreePath);
                setExpanded(expanded.filter(nodeId => !nodeId.startsWith(event.subtreePath) || nodeId === event.subtreePath));
            });

        return () => {
            unsubscribe();
        };
    }, [eventManager, fetchSubtreeById, expanded]);

    function handleSelect(pathFromRoot: string) {
        const clickedTreeModelItem = hierarchyTreeController.findTreeItemByPath(pathFromRoot, treeModelItems);
        if (clickedTreeModelItem?.leaf) {
            onTreeItemLeafClicked(clickedTreeModelItem);
        } else {
            if (clickedTreeModelItem?.type === HierarchyTreeItemType.SUBTREE) {
                fetchSubtreeById(clickedTreeModelItem.id, pathFromRoot);
            } else {
                const [parentTreeId, parentTreeRelativePath] = hierarchyTreeController.findParentTreeAndRelativePath(hierarchyTree!.id, pathFromRoot, treeModelItems);
                fetchTreeItemByPath(parentTreeId, parentTreeRelativePath, pathFromRoot);
            }
        }
        const nodeId = hierarchyTreePathParser.getLastPathItem(pathFromRoot);
        setSelected(nodeId);
    }

    function onContextMenu(treeItem: HierarchyTreeItemDetailDto, treeItemPath: string, event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        setTreeItemContextMenuAttrs({
            open: true,
            treeItem: treeItem,
            treeItemPath: treeItemPath,
            x: event.clientX,
            y: event.clientY
        });
    }

    function closeContextMenu() {
        setTreeItemContextMenuAttrs({open: false, x: 0, y: 0});
    }

    if (!hierarchyTree) {
        return (
            <Box width={"100%"}>
                <CircularProgress size={20}/>
            </Box>
        );
    }

    return (
        <>
            <AlertDialog open={graphQueryErrorMessage !== undefined}
                         onClose={() => setGraphQueryErrorMessage(undefined)}
                         title={_transl(GraphQueryTranslationKey.ERROR_QUERY_EXECUTION_TITLE)}
                         text={`${_transl(GraphQueryTranslationKey.ERROR_QUERY_EXECUTION_MESSAGE)}: \n${graphQueryErrorMessage}`}
                         type={AlertDialogType.ERROR}
                         maxWidth={"sm"}
            />

            <HierarchyTreeItemContextMenu open={treeItemContextMenuAttrs.open}
                                          anchorPosition={{left: treeItemContextMenuAttrs.x, top: treeItemContextMenuAttrs.y}}
                                          treeItem={treeItemContextMenuAttrs.treeItem}
                                          treeItemPath={treeItemContextMenuAttrs.treeItemPath}
                                          tree={hierarchyTree}
                                          onClose={closeContextMenu} />

            <TreeView aria-label="controlled"
                      defaultCollapseIcon={<ExpandMoreIcon/>}
                      defaultExpandIcon={<ChevronRightIcon/>}
                      expanded={expanded}
                      selected={selected}
                      onNodeToggle={handleToggle}
                      onNodeSelect={(event: React.ChangeEvent<{}>, nodeId: string) => handleSelect(nodeId)}
                      multiSelect={false} >
                <HierarchyTreeItems treeModelItems={treeModelItems} onContextMenu={onContextMenu} />
            </TreeView>
        </>
    )
}
