import {Action, Reducer} from "redux";
import {IFilter} from "./Elements";

// ### ITEMS DIALOG FILTER ###

    // Actions

export enum FilterActionType {
    UPDATE = "ACTION/ELEMENTS/DIALOGFILTER/UPDATE",
    RESET = "ACTION/ELEMENTS/DIALOGFILTER/RESET",
}

interface IFilterAction extends Action<FilterActionType> {
    type: FilterActionType;
    filter?: IFilter,
}

export function getDialogFilterUpdateAction(filter: IFilter): IFilterAction {
    return {
        type: FilterActionType.UPDATE,
        filter: filter,
    }
}

export function getDialogFilterResetAction(): IFilterAction {
    return {
        type: FilterActionType.RESET,
    }
}

    // State

export interface IFilterState {
    lastFilter: IFilter;
    lastFilterUpdate: Date,
}

const initialFilterState: IFilterState = {
    lastFilter: {},
    lastFilterUpdate: new Date(),
}

    // Reducer

export const dialogFilterReducer: Reducer<IFilterState, IFilterAction> = (
    state = initialFilterState,
    action
) => {
    switch(action.type) {
        case FilterActionType.UPDATE :
            return {
                ...state,
                lastFilter: action.filter as IFilter,
                lastFilterUpdate: new Date(),
            };
        case FilterActionType.RESET :
            return {
                ...state,
                lastFilter: {},
                lastFilterUpdate: new Date(),
            }
    }
    return state;
};
