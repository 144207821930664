import chatService, {ChatDto, ChatState} from "../../../../common/apis/chat/ChatService";
import {Grid} from "@mui/material";
import ChatComment from "./ChatComment";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogContent from "../../../../components/dialogs/DialogContent";
import {IApplicationState} from "../../../../store/Store";
import {useSelector} from "react-redux";
import {UserDto} from "../../../../common/apis/user/UserDto";
import ChatCommentCreateFooter from "./ChatCommentCreateFooter";
import ChatResolvingButton from "./ChatResolvingButton";
import Snackbar from "../snackbar/Snackbar";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import React, {useEffect, useRef, useState} from "react";
import ActionDialogTitle from "../../../../components/dialogs/ActionDialogTitle";
import {Delete, NavigateBefore, NavigateNext} from "@mui/icons-material";
import {ActionButton} from "../../../../components/button/ActionButton";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import {ChatTranslationKey} from "./ChatTranslationKey";
import {CommonTranslation} from "../CommonTranslation";

export interface ChatDialogProps {
    open: boolean;
    chat: ChatDto;
    scrollDown: boolean;
    onChatUpdated: (chatId: number, scrollDown: boolean) => void;
    onChatDeleted: (chatId: number) => void;
    onNavigateToPrev?: (currentChatId: number) => void;
    onNavigateToNext?: (currentChatId: number) => void;
    onClosed: () => void;
}

export default function ChatDialog({open, chat, scrollDown, onChatUpdated, onChatDeleted, onNavigateToPrev, onNavigateToNext, onClosed}: ChatDialogProps) {
    const user = useSelector<IApplicationState>(state => state.user.userData) as UserDto;

    const [deleteConfirmationDialogOpened, setDeleteConfirmationDialogOpened] = useState(false);

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollDown && scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [scrollDown, chat.chatComments]);

    function onStateChange(chatState: ChatState) {
        chatService.updateChatState(chat.id, chatState)
            .then(() => {
                onChatUpdated(chat.id, false);
            })
            .catch(() => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA)));
    }

    function deleteChat() {
        chatService.deleteChat(chat.id)
            .then(() => {
                setDeleteConfirmationDialogOpened(false);
                onChatDeleted(chat.id);
                onClosed();
            })
            .catch(() => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_REMOVE_DATA)));
    }

    function showDeleteConfirmationDialog() {
        setDeleteConfirmationDialogOpened(true);
    }

    function onNavigateToPrevClicked() {
        if (onNavigateToPrev) {
            onNavigateToPrev(chat.id);
        }
    }

    function onNavigateToNextClicked() {
        if (onNavigateToNext) {
            onNavigateToNext(chat.id);
        }
    }

    function leftButtons() {
        return (
            <>
                <ActionButton id="previous-chat-button"
                              icon={<NavigateBefore/>}
                              color={"default"}
                              onClick={onNavigateToPrevClicked}
                              disabled={onNavigateToPrev === undefined}/>
                <ActionButton id="next-chat-button"
                              icon={<NavigateNext/>}
                              color={"default"}
                              onClick={onNavigateToNextClicked}
                              disabled={onNavigateToNext === undefined}/>
            </>
        );
    }

    function rightButtons() {
        return (
            <>
                <ChatResolvingButton chatState={chat.state}
                                     onChatStateChange={(newState) => onStateChange(newState)}
                                     disabled={!chat.acl.canChangeState} />
                <ActionButton id="delete-chat-button"
                              onClick={showDeleteConfirmationDialog}
                              icon={<Delete/>}
                              tooltip={_transl(CommonTranslation.REMOVE)}
                              color={"default"}
                              disabled={!chat.acl.canDelete}/>
            </>
        );
    }

    return (
        <>
            <Dialog open={open}>
                <ActionDialogTitle leftButtons={leftButtons()}
                                   rightButtons={rightButtons()}
                                   onDialogClosed={onClosed} />
                <div style={{overflow: 'auto'}} ref={scrollRef}>
                    <DialogContent style={{overflow: 'unset'}}>
                        <Grid container paddingTop={1}>
                            <Grid item xs={12}>
                                {
                                    chat.chatComments.map((comment, index) =>
                                        <ChatComment key={comment.id}
                                                     chatId={chat.id}
                                                     comment={comment}
                                                     hideDeleteButton={index === 0}
                                                     onDeleted={(commentId) => onChatUpdated(chat.id, false)}
                                                     onEdited={(commentId) => onChatUpdated(chat.id, false)}/>
                                    )
                                }
                            </Grid>
                            {chat.acl.canCreateComments &&
                                <Grid item xs={12}>
                                    <ChatCommentCreateFooter key={"footer"}
                                                             chatId={chat.id}
                                                             user={user}
                                                             chat={chat}
                                                             onCommentCreated={(chatId) => onChatUpdated(chatId, true)}/>
                                </Grid>
                            }
                        </Grid>
                    </DialogContent>
                </div>
            </Dialog>

            <ConfirmationDialog open={deleteConfirmationDialogOpened}
                                title={_transl(ChatTranslationKey.DELETE_CONFIRMATION_TITLE)}
                                confirmationText={_transl(ChatTranslationKey.DELETE_CONFIRMATION_TEXT)}
                                onConfirm={() => deleteChat()}
                                onReject={() => setDeleteConfirmationDialogOpened(false)} />
        </>
    );
}
