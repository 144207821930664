import React from "react";

const ICON_VIEWBOX_SIZE = 24;

export enum Orientation {
    HORIZONTAL,
    VERTICAL,
}

const orientationToRotateDegrees: Record<Orientation, number> = {
    [Orientation.VERTICAL]: 0,
    [Orientation.HORIZONTAL]: -90,
}

interface Props {
    orientation: Orientation;
}

export default function AlignCenterIcon(props: Props) {
    const {orientation} = props;
    const viewBoxCenter = ICON_VIEWBOX_SIZE / 2;
    const transform = `rotate(${orientationToRotateDegrees[orientation]} ${viewBoxCenter} ${viewBoxCenter})`;

    return <svg viewBox={`0 0 ${ICON_VIEWBOX_SIZE} ${ICON_VIEWBOX_SIZE}`} width={15} height={15}>
        <g fill={"currentColor"} transform={transform}>
            <path d={"M8 19h3v4h2v-4h3l-4-4-4 4zm8-14h-3V1h-2v4H8l4 4 4-4zM4 11v2h16v-2H4z"} />
        </g>
    </svg>
}