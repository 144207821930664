import React from "react";

import DetailHeader from "./DetailHeader";
import ElementTypeIcon from "../../../../components/fields/ElementTypeIcon";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "./ElementDetailTranslationKey";
import CopyUrlIcon from "../../../../components/CopyUrlIcon";
import RouteDefinitionUtils from "../../../../common/routedefinition/RouteDefinitionUtils";
import Constants from "../../../../common/Constants";

interface ElementDetailHeaderProps {
    element: ElementDto,
    onClosed?: () => void,
}

export default function ElementDetailHeader({element, onClosed}: ElementDetailHeaderProps) {

    function buildElementDetailUrl(elementId: string) {
        const path = RouteDefinitionUtils.resolveElementDetailPath(elementId);
        return Constants.attachAppHost(path);
    }

    return (
        <DetailHeader
            name={element.name || ""}
            icon={<ElementTypeIcon name={element.type}/>}
            onClosed={onClosed}
        >
            <CopyUrlIcon title={_transl(ElementDetailTranslationKey.COPY_URL)}
                         successTitle={_transl(ElementDetailTranslationKey.URL_COPIED)}
                         url={buildElementDetailUrl(element.identifier)}
            />
        </DetailHeader>
    )
}
