
export const DEFAULT_COLOR = "#555";
export const DEFAULT_LINE_COLOR = DEFAULT_COLOR;
export const HIDDEN_CONNECTION_LINE_COLOR = "rgb(173,188,230)"
export const DEFAULT_FONT_COLOR = DEFAULT_COLOR;
export const DEFAULT_FONT_FAMILY = ["Roboto", "sans-serif"];
export const DEFAULT_LINE_HEIGHT = "1.2";
export const DEFAULT_FONT_SIZE = 12;
export const DEFAULT_LINE_WIDTH = 1;
export const DEFAULT_FONT_STYLE = "normal";
export const OVERLAY_STROKE_COLOR = "gray";
export const OVERLAY_VISIBLE_OPACITY = 0.5;
export const DISABLE_ON_NODE_MOVE_POINTER_EVENTS_STROKE_CLASS_NAME = "__pointer_events_stroke__";
export const DISABLE_ON_NODE_MOVE_POINTER_EVENTS_ALL_CLASS_NAME = "__pointer_events_all__";
export const DISABLE_ON_NODE_MOVE_POINTER_EVENTS_AUTO_CLASS_NAME = "__pointer_events_auto__";
export const HANDLE_COLOR_TRANSPARENT_OPACITY = 0.3;
export const HANDLE_FILL_COLOR = "rgb(100, 100, 255)";
export const HANDLE_STROKE_COLOR = "rgb(0, 0, 0)";
export const HANDLE_REMOVE_FILL_COLOR_SECONDARY = "rgb(100, 100, 100)";
export const HANDLE_ADD_FILL_COLOR_PRIMARY = "rgb(100, 100, 255)";
