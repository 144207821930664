import ConnectionPredicate from "./ConnectionPredicate";
import {IDiagramNodeDto} from "../../../../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../../../apis/diagram/IDiagramConnectionDto";

export default class IgnoreParentChildConnectionPredicate implements ConnectionPredicate {

    test(connection: IDiagramConnectionDto,
         sourceNode: IDiagramNodeDto,
         targetNode: IDiagramNodeDto): boolean {
        return !this.existsParentChildRelationship(sourceNode, targetNode);
    }

    private existsParentChildRelationship(sourceNode: IDiagramNodeDto,
                                                targetNode: IDiagramNodeDto): boolean {
        const sourceNodeChildIds = sourceNode.childNodes?.map(node => node.identifier) || [];
        const targetNodeChildIds = targetNode.childNodes?.map(node => node.identifier) || [];

        return sourceNodeChildIds.indexOf(targetNode.identifier) !== -1 ||
            targetNodeChildIds.indexOf(sourceNode.identifier) !== -1;
    }
}