export enum DiagramTranslationKey {
    GRID_HEADER_TYPE = "diagrams.grid.header.type",
    GRID_HEADER_IDENTIFIER = "diagrams.grid.header.identifier",
    GRID_HEADER_STEREOTYPE = "diagrams.grid.header.stereotype",
    GRID_HEADER_NAME = "diagrams.grid.header.name",
    GRID_HEADER_VALUE = "diagrams.grid.header.value",
    GRID_HEADER_COLLECTIONS = "diagrams.grid.header.collections",
    GRID_HEADER_READ_WRITE = "diagrams.grid.header.readWrite",

    DIALOG_DIAGRAM_CREATE_TITLE = "diagrams.diagramCreateDialog.title",
    BUTTON_CREATE_DIAGRAM_LABEL = "diagrams.createDiagramButton.label",

    DIALOG_DIAGRAM_CREATE_FIELD_NAME = "diagrams.dialogDiagramCreate.field.name",
    DIALOG_DIAGRAM_CREATE_FIELD_DESCRIPTION = "diagrams.dialogDiagramCreate.field.description",
    DIALOG_DIAGRAM_CREATE_FIELD_ELEMENTS_SHOWN = "diagrams.dialogDiagramCreate.field.elementsShown",

    DUPLICATE_DIALOG_TITLE = "diagrams.duplicateDialog.title",
    DUPLICATE_DIALOG_BUTTON_DUPLICATE = "diagrams.duplicateDialog.button.duplicate",
    DUPLICATE_DIALOG_DIAGRAM_SUCCESSFULLY_DUPLICATED = "diagrams.duplicateDialog.message.diagramSuccessfullyDuplicated",
    DUPLICATE_DIALOG_FIELD_NAME = "diagrams.duplicateDialog.field.name",
    DUPLICATE_DIALOG_FIELD_COLLECTIONS = "diagrams.duplicateDialog.field.collections",
    DUPLICATE_DIALOG_FIELD_DUPLICATE_ELEMENTS_AND_RELATIONSHIPS = "diagrams.duplicateDialog.field.duplicateElementsAndRelationships",

    ALERT_DIALOG_CREATE_DIAGRAM_TITLE = "diagrams.createDiagramAlertDialog.title",
    ALERT_DIALOG_CREATE_DIAGRAM_TEXT = "diagrams.createDiagramAlertDialog.text",

    ADD_ELEMENT = "diagrams.addElement",
    REMOVE_ELEMENT = "diagrams.removeElement",

    BACKUP_ACTION_LOAD="diagrams.diagramEditor.backup.action.load",
    DETAIL_IDENTIFIER = "diagrams.detail.identifier",
    DETAIL_NAME = "diagrams.detail.name",
    DETAIL_DESCRIPTION = "diagrams.detail.description",
    DETAIL_VIEWPOINT = "diagrams.detail.viewpoint",
    DETAIL_ATTRIBUTE_DELETE_SUCCESS = "diagrams.detail.attribute.delete.success",
    DETAIL_ATTRIBUTE_DELETE_FAILURE = "diagrams.detail.attribute.delete.failure",
    DETAIL_TAB_DETAIL = "diagrams.detail.tab.detail",
    DETAIL_TAB_COMMENTS = "diagrams.detail.tab.comments",
    DETAIL_TAB_ATTACHMENTS = "diagrams.detail.tab.attachments",
    DETAIL_TAB_PREVIEW = "diagrams.detail.tab.preview",
    DETAIL_TAB_ATTRIBUTES = "diagrams.detail.tab.attributes",
    DETAIL_LOADING_IN_PROCESS = "diagrams.detail.loadingInProcess",
    DETAIL_LOADING_FAILED = "diagrams.detail.loadingFailed",
    DETAIL_VIEW_DIAGRAM = "diagrams.detail.viewDiagram",
    DETAIL_EDIT_DIAGRAM = "diagrams.detail.editDiagram",
    DETAIL_DIALOG_DELETE_ATTRIBUTE_TITLE = "diagrams.detail.deleteAttributeDialog.title",
    DETAIL_DIALOG_DELETE_ATTRIBUTE_TEXT = "diagrams.detail.deleteAttributeDialog.text",
    DETAIL_ADD_ATTRIBUTE = "diagrams.detail.addAttribute",
    DETAIL_UPDATE_ATTRIBUTE = "diagrams.detail.updateAttribute",
    DETAIL_REMOVE_ATTRIBUTE = "diagrams.detail.removeAttribute",
    DETAIL_ADD_ATTRIBUTE_FAILED = "diagrams.detail.addAttributeFailed",
    DETAIL_ATTRIBUTE_ADDITION = "diagrams.detail.attributeAddition",
    DETAIL_ATTRIBUTE_NAME = "diagrams.detail.attribute.name",
    DETAIL_ATTRIBUTE_TYPE = "diagrams.detail.attribute.type",
    DETAIL_ATTRIBUTE_VALUE = "diagrams.detail.attribute.value",
    DETAIL_COPY_URL = "diagrams.detail.copyUrl",
    DETAIL_COPIED_URL = "diagrams.detail.urlCopied",
    DETAIL_ACTION_EXPORT_PNG_TITLE = "diagrams.detail.actions.exportToPNG",
    DETAIL_ACTION_EXPORT_PNG_IN_PROGRESS_MESSAGE = "diagrams.detail.actions.exportToPNG.exportInProgress",
    DETAIL_ACTION_EXPORT_PNG_FINISHED_MESSAGE = "diagrams.detail.actions.exportToPNG.exportFinished",
    REMOVE_OBJECTS_DIALOG_TITLE = "diagrams.diagramEditor.removeObjectsConfirmationDialog.title",
    REMOVE_OBJECTS_DIALOG_REMOVAL_TYPE = "diagrams.diagramEditor.removeObjectsConfirmationDialog.removalType",
    REMOVE_OBJECTS_DIALOG_ONLY_FROM_DIAGRAM = "diagrams.diagramEditor.removeObjectsConfirmationDialog.onlyFromDiagram",
    REMOVE_OBJECTS_DIALOG_FROM_BOTH_DIAGRAM_AND_MODEL = "diagrams.diagramEditor.removeObjectsConfirmationDialog.fromBothDiagramAndModel",
    HIDDEN_RELATIONSHIP_EXISTING = "diagrams.diagramEditor.hiddenRelationship.existing",
    HIDDEN_RELATIONSHIP_REUSE = "diagrams.diagramEditor.hiddenRelationship.reuse",
    HIDDEN_RELATIONSHIP_DO_NOT_REUSE = "diagrams.diagramEditor.hiddenRelationship.doNotReuse",
    HIDDEN_RELATIONSHIP_RELATIONSHIP_EXISTS = "diagrams.diagramEditor.hiddenRelationship.relationshipExists",
    HIDDEN_RELATIONSHIP_WITH_NAME = "diagrams.diagramEditor.hiddenRelationship.withName",
    HIDDEN_RELATIONSHIP_SELECT_ACTION = "diagrams.diagramEditor.hiddenRelationship.selectAction",
    NESTING_RELATIONSHIP_CREATE_RELATIONSHIPS = "diagrams.diagramEditor.nestingRelationship.createRelationships",
    NESTING_RELATIONSHIP_CREATE_RELATIONSHIP = "diagrams.diagramEditor.nestingRelationship.createRelationship",
    NESTING_RELATIONSHIP_DO_NOT_CREATE_RELATIONSHIPS = "diagrams.diagramEditor.nestingRelationship.doNotCreateRelationships",
    NESTING_RELATIONSHIP_DO_NOT_CREATE_RELATIONSHIP = "diagrams.diagramEditor.nestingRelationship.doNotCreateRelationship",
    NESTING_RELATIONSHIP_CREATE_RELATIONSHIP_TITLE = "diagrams.diagramEditor.nestingRelationship.dialogTitle",
    NESTING_RELATIONSHIP_NEW_RELATIONSHIP_ELEMENTS = "diagrams.diagramEditor.nestingRelationship.newRelationshipElements",
    NESTING_RELATIONSHIP_NEW_RELATIONSHIP_ELEMENT = "diagrams.diagramEditor.nestingRelationship.newRelationshipElement",
    NESTING_RELATIONSHIP_NESTED_ELEMENT = "diagrams.diagramEditor.nestingRelationship.nestedElement",
    NESTING_RELATIONSHIP_RELATIONSHIP = "diagrams.diagramEditor.nestingRelationship.relationship",
    NESTING_RELATIONSHIP_NONE = "diagrams.diagramEditor.nestingRelationship.none",
    CHANGE_DIAGRAM_PROPERTY_FAILED_TO_EDIT = "diagrams.changeDiagramProperty.failedToEdit",
    CHANGE_DIAGRAM_PROPERTY_DIALOG_TITLE = "diagrams.changeDiagramProperty.dialogTitle",
    CHANGE_DIAGRAM_PROPERTY_TITLE = "diagrams.changeDiagramProperty.title",
    CHANGE_DIAGRAM_PROPERTY_TYPE = "diagrams.changeDiagramProperty.type",
    CHANGE_DIAGRAM_PROPERTY_VALUE = "diagrams.changeDiagramProperty.value",
    DIAGRAM_EDITOR_PAGE_ERROR = "diagrams.diagramEditorPage.error",
    DIAGRAM_EDITOR_PAGE_SAVE_CHANGES = "diagrams.diagramEditorPage.saveChanges",
    DIAGRAM_EDITOR_PAGE_SAVE_CHANGES_CONFIRM = "diagrams.diagramEditorPage.saveChangesConfirmation",
    DIAGRAM_EDITOR_PAGE_EDIT_DIAGRAM = "diagrams.diagramEditorPage.editDiagram",
    DIAGRAM_EDITOR_PAGE_FINISH_EDITING_DIAGRAM = "diagrams.diagramEditorPage.finishEditingDiagram",
    DIAGRAM_EDITOR_PAGE_LOADING_DIAGRAM = "diagrams.diagramEditorPage.loadingDiagram",
    DIAGRAM_EDITOR_PAGE_SELECTED_ELEMENTS_GRID = "diagrams.diagramEditorPage.grid.selectedElements",
    DIAGRAM_EDITOR_PAGE_ELEMENT_PERMISSIONS_ALERT_TITLE = "diagrams.diagramEditorPage.elementPermissions.alert.title",
    DIAGRAM_EDITOR_PAGE_ELEMENT_PERMISSIONS_ALERT_MESSAGE = "diagrams.diagramEditorPage.elementPermissions.alert.message",
    DIAGRAM_EDITOR_PAGE_RELATION_TYPE_DIRECTED = "diagrams.diagramEditorPage.relationshipType.directed",
    DIAGRAM_EDITOR_PAGE_RELATION_TYPE_NON_UNDIRECTED = "diagrams.diagramEditorPage.relationshipType.undirected",
    ELEMENT_LIST_DIALOG_TITLE = "diagrams.elementList.dialogTitle",
    ELEMENT_LIST_CLOSE = "diagrams.elementList.close",
    DIAGRAMS_TITLE = "diagrams.title",
    FILTER_ADD="diagrams.filter.add",
    FILTER_SEARCH = "diagrams.filter.search",
    FILTER_IDENTIFIER = "diagrams.filter.identifier",
    FILTER_TITLE = "diagrams.filter.title",
    FILTER_LABELS = "diagrams.filter.labels",
    FILTER_COLLECTIONS = "diagrams.filter.collections",
    FILTER_VIEWPOINTS = "diagrams.filter.viewpoints",
    RENDER_MODE_IDENTIFIER = "diagrams.renderMode.identifier",
    RENDER_MODE_NAME = "diagrams.renderMode.name",
    RENDER_MODE_LABEL_CODES = "diagrams.renderMode.labelCodes",
    RENDER_MODE_COLLECTION_CODES = "diagrams.renderMode.collectionCodes",
    RENDER_MODE_VIEWPOINT = "diagrams.renderMode.viewpoint",
    RENDER_MODE_LAST_COMMENTED_ON = "diagrams.renderMode.lastCommentedOn",
    RENDER_MODE_ELEMENT_COUNT = "diagrams.renderMode.elementCount",
    RENDER_MODE_RELATIONSHIP_COUNT = "diagrams.renderMode.relationshipCount",
    RENDER_MODE_ATTACHMENTS_COUNT = "diagrams.renderMode.attachmentsCount",
    RENDER_MODE_DESCRIPTION = "diagrams.renderMode.description",
    RENDER_MODE_CREATED = "diagrams.renderMode.created",
    RENDER_MODE_UPDATED = "diagrams.renderMode.updated",
    RENDER_MODE_USER_CREATED = "diagrams.renderMode.userCreated",
    RENDER_MODE_USER_UPDATED = "diagrams.renderMode.userUpdated",
    GRID_ACTION_ADD_DIAGRAM = "diagrams.grid.action.addDiagram",
    GRID_ACTION_DELETE_DIAGRAM = "diagrams.grid.action.deleteDiagram",
    GRID_ACTION_DUPLICATE_DIAGRAM = "diagrams.grid.action.duplicateDiagram",
    GRID_ACTION_SHOW_DIAGRAM = "diagrams.grid.action.showDiagram",
    GRID_ACTION_EDIT_DIAGRAM = "diagrams.grid.action.editDiagram",
    GRID_ACTION_BULK_LABELS = "diagrams.grid.action.bulkLabels",
    GRID_ACTION_ADD_BULK_LABELS = "diagrams.grid.action.addBulkLabels",
    GRID_ACTION_REMOVE_BULK_LABELS = "diagrams.grid.action.removeBulkLabels",
    GRID_ACTION_BULK_COLLECTIONS = "diagrams.grid.action.bulkCollections",
    GRID_ACTION_ADD_BULK_COLLECTIONS = "diagrams.grid.action.addBulkCollections",
    GRID_ACTION_REMOVE_BULK_COLLECTIONS = "diagrams.grid.action.removeBulkCollections",
    GRID_ACTION_SHOW_ELEMENTS_LIST = "diagrams.grid.action.showElementsList",
    GRID_ACTION_DELETE_DIAGRAMS = "diagrams.grid.action.deleteDiagrams",
    GRID_ADD_BULK_LABELS_DIALOG = "diagrams.grid.addBulkLabels.dialog",

    GRID_DELETE_DIAGRAM_TITLE = "diagrams.grid.deleteDiagram.title",
    GRID_DELETE_DIAGRAM_CONFIRMATION = "diagrams.grid.deleteDiagram.confirmation",
    GRID_DELETE_DIAGRAMS_TITLE = "diagrams.grid.deleteDiagrams.title",
    GRID_DELETE_DIAGRAMS_CONFIRMATION = "diagrams.grid.deleteDiagrams.confirmation",
    GRID_DIAGRAM_ELEMENT_LIST_TITLE = "diagrams.grid.diagramElementList.title",
    GRID_DIAGRAM_ELEMENT_LIST_TEXT = "diagrams.grid.diagramElementList.text",
    GRID_DELETE_DIAGRAM_FAILED_ALERT_TITLE = "diagrams.grid.deleteDiagram.failedAlertTitle",
    GRID_DELETE_DIAGRAM_FAILED_ALERT_TEXT = "diagrams.grid.deleteDiagram.failedAlertText",
    GRID_DELETE_DIAGRAMS_FAILED_ALERT_TITLE = "diagrams.grid.deleteDiagrams.failedAlertTitle",
    GRID_DELETE_DIAGRAMS_FAILED_ALERT_TEXT = "diagrams.grid.deleteDiagrams.failedAlertText",
    GRID_REMOVE_BULK_COLLECTIONS_ALERT_TITLE = "diagrams.grid.removeBulkCollections.alertTitle",
    GRID_REMOVE_BULK_COLLECTIONS_ALERT_TEXT = "diagrams.grid.removeBulkCollections.alertText",
    REMOVE_DIAGRAM_SUCCESS_TEXT = "diagrams.grid.removeDiagram.successText",
    REMOVE_DIAGRAMS_SUCCESS_TEXT = "diagrams.grid.removeDiagrams.successText",

}
