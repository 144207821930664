import {MenuItem} from "@mui/material";
import React from "react";
import TextField from "../../../../../../../../components/fields/textfield/TextField";
import {HierarchyTreeTranslationKey} from "../../../../HierarchyTreeTranslationKey";
import {_transl} from "../../../../../../../../store/localization/TranslMessasge";
import {CollectionTreeViewMode} from "../../../../service/CollectionTreeViewMode";

class CollectionViewModeOption {
    public static readonly [CollectionTreeViewMode.FOLDERS] = new CollectionViewModeOption(
        HierarchyTreeTranslationKey.COLLECTION_VIEW_MODE_FOLDERS, CollectionTreeViewMode.FOLDERS);

    public static readonly [CollectionTreeViewMode.FLAT] = new CollectionViewModeOption(
        HierarchyTreeTranslationKey.COLLECTION_VIEW_MODE_FLAT, CollectionTreeViewMode.FLAT);

    translationKey: string;
    viewMode: CollectionTreeViewMode;

    constructor(translationKey: string, viewMode: CollectionTreeViewMode) {
        this.translationKey = translationKey;
        this.viewMode = viewMode;
    }

    public static values(): CollectionViewModeOption[] {
        return Object.keys(CollectionTreeViewMode)
            .map(key => CollectionViewModeOption[key as CollectionTreeViewMode]);
    }
}

export interface CollectionViewModeSelectProps {
    value: CollectionTreeViewMode,
    onChange: (viewMode: CollectionTreeViewMode) => void
}

export default function CollectionViewModeSelect({value, onChange}: CollectionViewModeSelectProps) {
    return (
        <>
            <TextField
                label={_transl(HierarchyTreeTranslationKey.COLLECTION_VIEW_MODE)}
                select
                required={true}
                value={value}
                onChange={e => onChange(e as CollectionTreeViewMode)}
            >
                {CollectionViewModeOption.values().map((viewModeOption) => (
                    <MenuItem key={viewModeOption.viewMode} value={viewModeOption.viewMode}>
                        {_transl(viewModeOption.translationKey)}
                    </MenuItem>
                ))}
            </TextField>
        </>
    )
}


