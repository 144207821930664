import {Event} from "../../../event/Event";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";

export enum NodeResizeEventType {
    RESIZE_TO_MATCH_WIDTH = "NODE_RESIZE_TO_MATCH_WIDTH",
    RESIZE_TO_MATCH_HEIGHT = "NODE_RESIZE_TO_MATCH_HEIGHT",
    RESIZE_TO_MATCH_SIZE = "NODE_RESIZE_TO_MATCH_SIZE",
    RESIZE_TO_DEFAULT_SIZE = "NODE_RESIZE_TO_DEFAULT_SIZE",
}

interface NodeResizeEvent extends Event {
    nodes: IDiagramNodeDto[];
}

export interface NodeResizeToMatchWidthEvent extends NodeResizeEvent {
    type: NodeResizeEventType.RESIZE_TO_MATCH_WIDTH;
}

export interface NodeResizeToMatchHeightEvent extends NodeResizeEvent {
    type: NodeResizeEventType.RESIZE_TO_MATCH_HEIGHT;
}

export interface NodeResizeToMatchSizeEvent extends NodeResizeEvent {
    type: NodeResizeEventType.RESIZE_TO_MATCH_SIZE;
}

export interface NodeResizeToDefaultSizeEvent extends NodeResizeEvent {
    type: NodeResizeEventType.RESIZE_TO_DEFAULT_SIZE;
}