import React, {useEffect, useState} from "react";
import Dialog from "../../../../components/dialogs/Dialog";
import {CloseButton} from "../../../../components/button/CloseButton";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {PagePresetsTranslationKey} from "./PagePresetsTranslationKey";
import {SaveButton} from "../../../../components/button/SaveButton";
import Grid from "../../../../components/dialogs/Grid";
import TextField from "../../../../components/fields/textfield/TextField";
import {CommonTranslation} from "../CommonTranslation";
import {LabelConstant} from "../labels/service/LabelConstant";
import {Checkbox, FormControlLabel} from "@mui/material";
import {PagePresetsUpdateDto} from "./client/PagePresetsUpdateDto";
import pagePresetsClient from "./client/PagePresetsClient";
import {PagePresetsCreateDto} from "./client/PagePresetsCreateDto";
import Snackbar from "../snackbar/Snackbar";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import {PagePresetsDto} from "./client/PagePresetsDto";
import {GridPresets} from "../../../../components/grid/presets/GridPresets";
import {IFilter} from "../../../../store/elements/Elements";


interface PagePresetsUpdateDialogProps {
    pageId: string;
    pagePresets?: PagePresetsDto;
    gridPresets?: GridPresets;
    filter?: any;
    open: boolean;
    onDialogClosed: (saved: boolean) => void;
}

export default function PagePresetsUpdateDialog(props: PagePresetsUpdateDialogProps) {

    const {pageId, pagePresets, gridPresets, filter, open, onDialogClosed} = props;

    const [presets, setPresets] = useState<PagePresetsUpdateDto | undefined>(buildPagePresetsUpdate(pagePresets, gridPresets, filter));

    useEffect(() => {
        if (open && pagePresets === undefined && gridPresets === undefined) {
            throw new Error("Either pagePresets or gridPresets must be defined");
        }
        setPresets(buildPagePresetsUpdate(pagePresets, gridPresets, filter));
    }, [open, pageId, pagePresets, gridPresets, filter]);

    function buildPagePresetsUpdate(pagePresets?: PagePresetsDto, gridPresets?: GridPresets, filter?: IFilter): PagePresetsUpdateDto | undefined {
        if (pagePresets === undefined && gridPresets === undefined) {
            return undefined;
        }

        const resultGridPresets = gridPresets ? gridPresets : pagePresets!.settings.gridPresets;

        return {
            name: pagePresets ? pagePresets.name : "",
            description: pagePresets ? pagePresets.description : "",
            isPrivate: pagePresets ? pagePresets.isPrivate : true,
            settings: {
                gridPresets: resultGridPresets,
                filter: filter
            }
        };
    }

    function setName(name: string) {
        if (presets) {
            setPresets({
                ...presets,
                name
            });
        }
    }

    function setDescription(description: string) {
        if (presets) {
            setPresets({
                ...presets,
                description
            });
        }
    }

    function setPrivate(isPrivate: boolean) {
        if (presets) {
            setPresets({
                ...presets,
                isPrivate
            });
        }
    }

    function save() {
        if (presets) {
            if (pagePresets === undefined) {
                const presetsCreate: PagePresetsCreateDto = {
                    ...presets
                };
                pagePresetsClient.create(pageId, presetsCreate)
                    .then((id) => {
                        Snackbar.success(_transl(CommonTranslation.DATA_SAVED_SUCESSFULLY));
                        onDialogClosed(true);
                    })
                    .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), err));
            } else {
                pagePresetsClient.update(pageId, pagePresets.id, presets)
                    .then((id) => {
                        Snackbar.success(_transl(CommonTranslation.DATA_SAVED_SUCESSFULLY));
                        onDialogClosed(true);
                    })
                    .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), err));
            }
        }
    }

    return (
            <Dialog open={open}
                    aria-labelledby="page-presets-update-dialog"
                    onClose={() => onDialogClosed(false)}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <DialogTitle
                        id="page-presets-update-dialog"
                        title={_transl(PagePresetsTranslationKey.PAGE_RESETS)}
                        onDialogClosed={() => onDialogClosed(false)}
                />
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField id="page-presets-update-name-field"
                                       label={_transl(CommonTranslation.TITLE)}
                                       value={presets?.name ?? ""}
                                       onChange={name => setName(name)}
                                       required={true}
                                       maxLength={LabelConstant.NAME_MAX_LENGTH}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="page-presets-update-description-field"
                                       label={_transl(CommonTranslation.DESCRIPTION)}
                                       value={presets?.description ?? ""}
                                       onChange={description => setDescription(description)}
                                       multiline={true}
                                       rows={3}
                                       maxLength={LabelConstant.DESCRIPTION_MAX_LENGTH}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel id="page-presets-update-is-private-field"
                                              label={_transl(PagePresetsTranslationKey.PRESETS_ARE_PRIVATE)}
                                              control={
                                                  <Checkbox
                                                      checked={presets?.isPrivate ?? true}
                                                      onChange={(_, checked) => setPrivate(checked)}
                                                      color="primary"
                                                      disabled={false}
                                                  />
                                              }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton onClick={save} />
                    <CloseButton variant={"outlined"} onClick={() => onDialogClosed(false)}/>
                </DialogActions>
            </Dialog>
    );

}
