import {
    OutlinedInput as MuiOutlinedInput,
    OutlinedInputProps as MuiOutlinedInputProps,
} from "@mui/material";
import React, {useState} from "react";
import ClearButtonInputAdornment from "../adornment/ClearButtonInputAdornment";


export interface OutlinedInputProps extends MuiOutlinedInputProps {
    onClearButtonClick?: () => void;
}

export default function OutlinedInput(props: OutlinedInputProps) {
    const {
        onClearButtonClick,
        onChange,
        ...rest
    } = props;

    const [inputValue, setInputValue] = useState<string>("");
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const isClearable = !!onClearButtonClick;
    const handleClearButtonClick = () => {
        setInputValue("");
        if (onClearButtonClick) {
            onClearButtonClick();
        }
    };
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <MuiOutlinedInput
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onChange={handleChange}
            endAdornment={
                <ClearButtonInputAdornment
                    visible={isClearable && inputValue.length > 0 && (isFocused || isHovered)}
                    onClick={handleClearButtonClick}
                />
            }
            {...rest}
        />
    );
}
