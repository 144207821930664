import React from "react";

export default class StickIcon extends React.Component<any, any> {
    render() {
        return <svg viewBox={"0 0 24 24"} width={15} height={15}>
            <g fill={"black"}>
                <path d={"m 22 2 l -2.5 1.4 L 17 2 l 1.4 2.5 L 17 7 l 2.5 -1.4 L 22 7 l -1.4 -2.5 Z m -7.63 5.29 a 0.9959 0.9959 0 0 0 -1.41 0 L 1.29 18.96 c -0.39 0.39 -0.39 1.02 0 1.41 l 2.34 2.34 c 0.39 0.39 1.02 0.39 1.41 0 L 16.7 11.05 c 0.39 -0.39 0.39 -1.02 0 -1.41 l -2.33 -2.35 Z m -1.03 5.49 l -2.12 -2.12 l 2.44 -2.44 l 2.12 2.12 l -2.44 2.44 Z"} />
            </g>
        </svg>
    }
}