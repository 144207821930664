import ClassIcon from '@mui/icons-material/Class';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Constants from "../../../../../common/Constants";
import {FolderViewType} from "./FolderViewType";
import {useRef, useState} from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {Tooltip} from "@mui/material";
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import * as d3 from 'd3';
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "../../../DiagramEditorTranslationKey";
import {Menu} from "../../../../menu/Menu";
import {MenuItem} from "../../../../menu/MenuItem";

const useFolderViewTypeStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            margin: 1,
            "& .MuiSvgIcon-root": {
                fontSize: "1rem",
                fill: d3.color(Constants.MODEL_FOLDER_ICON_COLOR)?.brighter(.2).formatRgb(),
                stroke: "rgb(150, 150, 150)",
            },
            cursor: "default",
            userSelect: "none",
        },
        label: {
            display: "inline-block",
            fontSize: ".8em",
            marginLeft: "3px"
        },
        emptyIcon: {
            display: "inline-block",
            width: "1.2rem",
            marginRight: "10px",
        },
        menu: {
            position: "absolute",
            top: "5px"
        }
    })
);

interface IProps {
    initialFolderViewType: FolderViewType,
    onFolderViewTypeChanged?: (folderViewType: FolderViewType) => void,
}

function getFolderViewTypeName(viewType: FolderViewType) {
    switch (viewType) {
        case FolderViewType.TYPES: return _transl(DiagramEditorTranslationKey.FOLDER_VIEW_ELEMENTS);
        case FolderViewType.STEREOTYPES: return _transl(DiagramEditorTranslationKey.FOLDER_VIEW_STEREOTYPES);
        default: return _transl(DiagramEditorTranslationKey.FOLDER_VIEW_LAYERS);
    }
}

export default function FolderViewTypeSelect(props: IProps) {
    const classes = useFolderViewTypeStyles();
    const {initialFolderViewType, onFolderViewTypeChanged} = props;

    // state
    const [folderType, setFolderType] = useState<FolderViewType>(initialFolderViewType);
    const [folderTypeMenuOpened, setFolderTypeMenuOpened] = useState<boolean>(false);

    const folderTypeRef = useRef<HTMLDivElement | null>(null);

    function onFolderViewTypeSelected(folderViewType: FolderViewType) {
        setFolderType(folderViewType);
        setFolderTypeMenuOpened(false);
        onFolderViewTypeChanged && onFolderViewTypeChanged(folderViewType);
    }

    return (
        <>
            <Tooltip title={_transl(DiagramEditorTranslationKey.FOLDER_VIEW_TOOLTIP_TEXT)} PopperProps={{disablePortal: true}}>
                <div className={classes.root} onClick={() => setFolderTypeMenuOpened(!folderTypeMenuOpened)} ref={folderTypeRef}>
                    <ClassIcon fontSize={"small"}/>
                    <span className={classes.label}>{getFolderViewTypeName(folderType)}</span>
                    {folderTypeMenuOpened ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </div>
            </Tooltip>

            <Menu open={folderTypeMenuOpened}
                  onClose={() => setFolderTypeMenuOpened(false)}
                  anchorEl={folderTypeRef.current}
            >
                {[FolderViewType.LAYERS, FolderViewType.TYPES, FolderViewType.STEREOTYPES].map(viewType =>
                    <MenuItem label={getFolderViewTypeName(viewType)}
                              icon={folderType === viewType ? <CheckSharpIcon /> : <span className={classes.emptyIcon} />}
                              key={viewType}
                              selected={folderType === viewType}
                              onClick={() => onFolderViewTypeSelected(viewType)} />
                )}
            </Menu>
        </>
    );
}
