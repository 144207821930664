import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React, {ChangeEvent} from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import clsx from "clsx";
import {_transl} from "../store/localization/TranslMessasge";
import {UploadFileTranslationKey} from "./UploadFileTranslationKey";

const styles = (theme: Theme) => createStyles({
    dropArea: {
        color: "darkgray",
        border: "dashed",
        borderWidth: "2",
        borderColor: "lightgray",
        backgroundColor: "whitesmoke",
        minHeight: "300px",
        height: "100%",
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    dropAreaFilesOver: {
        borderWidth: "4",
    },
    dropAreaSelectFileButton: {
        backgroundColor: theme.palette.success.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.success.main,
        }
    },
    dropAreaIcon: {
        color: theme.palette.success.main,
    },
    dropAreaDragLabel: {
        fontSize: "1.2em",
    },
    dropAreaOrLabel: {
        fontSize: "0.9em",
        margin: theme.spacing(2),
    },
});

interface IProps extends WithStyles<typeof styles> {
    uploadAttachment: (file: File) => void,
    uploadPanelContent?: () => JSX.Element,
}

interface IState {
    filesOverDropArea: boolean,
}

class UploadDialog extends React.Component<IProps, IState>{

    private upload: HTMLInputElement | null;

    constructor(props: IProps) {
        super(props);
        this.state = {
            filesOverDropArea: false,
        };
        this.upload = null;
    }

    updateState<T extends keyof IState>(propertyName: T, value: IState[T], onStateUpdated?: () => void) {
        const stateCopy = {...this.state};
        stateCopy[propertyName] = value;
        this.setState(stateCopy, onStateUpdated);
    }

    render() {
        const {classes} = this.props;
        const { uploadPanelContent } = this.props;
        const {filesOverDropArea} = this.state;

        const content = (uploadPanelContent != null) ? uploadPanelContent() : this.createUploadPanelContent();

        return (
            <div className={clsx(classes.dropArea, {[classes.dropAreaFilesOver]: filesOverDropArea})}
                 onDrop={e => this.onDragAreaChanged(e)}
                 onDragEnter={e => {
                     e.stopPropagation();
                     e.preventDefault();
                     this.activateDropArea();
                 }}
                 onDragOver={e => {
                     e.stopPropagation();
                     e.preventDefault();
                 }}
                 onDragLeave={e => {
                     e.stopPropagation();
                     e.preventDefault();
                     this.deactivateDropArea();
                 }}
                 onDragEnd={e => {
                     e.stopPropagation();
                     e.preventDefault();
                     this.deactivateDropArea();
                 }}
            >
                {content}
            </div>
        )
    }

    createUploadPanelContent(): JSX.Element {
        const { classes } = this.props;

        return <React.Fragment>
            <CloudUploadIcon className={classes.dropAreaIcon} />
            <div className={classes.dropAreaDragLabel}>
                {_transl(UploadFileTranslationKey.DRAG_FILE)}
            </div>
            <div className={classes.dropAreaOrLabel}>
                {_transl(UploadFileTranslationKey.OR)}
            </div>
            <input type="file"
                   ref={(ref) => this.upload = ref}
                   style={{display: 'none'}}
                   onChange={(event) => this.onFileInputChanged(event)}
            />
            <Button variant="contained" className={classes.dropAreaSelectFileButton} size={"small"} onClick={()=>{this.upload?.click()}}>
                {_transl(UploadFileTranslationKey.CHOOSE_FILE)}
            </Button>
        </React.Fragment>
    }

    onFileInputChanged(event: ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        event.preventDefault();
        this.onFilesSelected(event.target.files);
    }

    onDragAreaChanged(event: React.DragEvent<HTMLDivElement>) {
        event.stopPropagation();
        event.preventDefault();
        this.deactivateDropArea();
        const files = event.dataTransfer.files;
        this.onFilesSelected(files);
    }

    onFilesSelected(files: FileList | null) {
        const {uploadAttachment} = this.props;

        if (files != null && files.length > 0) {
            for (let i=0; i < files.length; i++) {
                let file = files.item(i);
                if (file != null && uploadAttachment != null) {
                    uploadAttachment(file);
                }
            }
        }
    }

    private activateDropArea() {
        this.updateState("filesOverDropArea", true);
    }

    private deactivateDropArea() {
        this.updateState("filesOverDropArea", false);
    }
}

export default withStyles(styles, { withTheme: true })(UploadDialog);
